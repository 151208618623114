import React from 'react';
import PropTypes from 'prop-types';
import UserDisplay from '../displays/UserDisplay';

const FieldDisplayUser = props => {
  const { label, value } = props;
  return (
    <>
      <div className="h7">{label}</div>
      <UserDisplay {...value} />
    </>
  );
};

FieldDisplayUser.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
};

export default FieldDisplayUser;
