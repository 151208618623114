import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppBar, Grid, Toolbar, Typography } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';
import { openDialog } from '../../store/dialogActions';
import { createAssessmentRevision } from '../../store/features/assessmentsActions';
import PrimaryButton from '../shared/buttons/PrimaryButton';
import AvatarMenu from '../shared/AvatarMenu';
import { getAssets } from '../../store/features/assetsActions';

import AssessmentConfirmationDialog from './AssessmentConfirmationDialog';

const AssessmentHeader = props => {
  const { page } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  // fetch asset; in case not in store yet
  useEffect(() => {
    if (!page.source_options.asset_id) {
      console.warn('No asset_id in page.source_options (likely need to rebuild page object)', page.id);
      return;
    }
    dispatch(getAssets(page.source_options.asset_id));
  }, [page.id, page.source_options.asset_id, dispatch]);

  const assetStore = useSelector(state => state.assets.each);
  const assessmentStore = useSelector(state => state.assessments.each);
  const asset = assetStore.data;

  const firebaseUser = JSON.parse(window.localStorage.getItem('firebaseui::rememberedAccounts'));

  const handlePublish = () => {
    dispatch(
      openDialog(
        'Publish Assessment',
        <AssessmentConfirmationDialog
          confirmLabel="Publish Assessment"
          onConfirm={() => {
            dispatch(
              createAssessmentRevision(asset.assessment.id, { routeAfterSuccess: `/assets/${asset.id}/assessments` })
            );
          }}
        />
      )
    );
  };

  const handleBack = () => {
    dispatch(
      openDialog(
        'Exit Assessment',
        <AssessmentConfirmationDialog
          confirmLabel="Exit Assessment"
          onConfirm={() => {
            history.push(`/assets/${asset.id}/assessments`);
          }}
        />
      )
    );
  };

  if (assetStore.loading || assessmentStore.loading) {
    // (already showing loading screen in `RBLXIFramePage`)
    //  assessmentStore will be loading upon publish
    return <></>;
  }

  return (
    <AppBar className="app-bar" elevation={1}>
      <Toolbar>
        <Grid container direction="row" justifyContent="space-between" spacing={4}>
          <Grid item>
            <ArrowBackIos onClick={handleBack} />
          </Grid>
          <Grid item>
            <Typography variant="h6" noWrap>
              {asset.asset_path}
            </Typography>
          </Grid>
          <Grid item>
            <PrimaryButton label="Publish Assessment" onClick={handlePublish} />
          </Grid>
          <Grid item>
            <AvatarMenu imageSrc={firebaseUser ? firebaseUser[0]['photoUrl'] : undefined} />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

AssessmentHeader.defaultProps = {
  title: null,
  menu: null,
  back: null,
};

AssessmentHeader.propTypes = {
  title: PropTypes.node,
  menu: PropTypes.node,
  back: PropTypes.node,
  page: PropTypes.object.isRequired,
};

export default AssessmentHeader;
