import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CheckIcon from '@mui/icons-material/Check';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';

const useStyles = makeStyles(theme => ({
  buttons: {
    padding: theme.spacing(1, 0),
  },
  listContainer: {
    padding: theme.spacing(0, 2),
  },
  list: {
    listStyleType: 'none',
    fontSize: theme.spacing(2),
    fontWeight: 'bold',
  },
  dialogContent: {
    padding: theme.spacing(0, 2),
  },
}));

const SubmitWithErrorsDialog = props => {
  const { confirmAction, cancelAction, isOpen, errors } = props;
  const classes = useStyles();
  const handleCloseDialog = () => {
    cancelAction();
  };

  const handleConfirm = () => {
    confirmAction();
    handleCloseDialog();
  };
  return (
    <>
      <Dialog open={isOpen} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className={classes.dialogContent}>
          <p className={classes.title}>The following fields have errors:</p>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <ul className={classes.listContainer}>
            {errors &&
              errors?.map(error => (
                <li key={error} className={classes.list}>
                  {error}
                </li>
              ))}
          </ul>
          <Grid container direction="column" justifyContent="space-between">
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                className={classes.buttons}>
                <Grid item>
                  <SecondaryButton icon Icon={CheckIcon} label="Save with Errors" onClick={handleConfirm} />
                </Grid>
                <Grid item>
                  <PrimaryButton onClick={handleCloseDialog} label="Fix Errors" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

SubmitWithErrorsDialog.defaultProps = {};

SubmitWithErrorsDialog.propTypes = {
  confirmAction: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  cancelAction: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
};

export default SubmitWithErrorsDialog;
