import { all, put, takeLatest } from 'redux-saga/effects';
import {
  RESERVATION_STATE_REQUEST,
  RESERVATION_STATE_SUCCESS,
  RESERVATION_STATE_FAILURE,
} from './reservationStateActions';
import {
  fetchReservationState,
} from '../../api/getters/reservationState';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';

export function* reservationStateFetch() {
  try {
    const { data, error, response } = yield fetchReservationState();
    if (data) {
      yield put({ type: RESERVATION_STATE_SUCCESS, data });
    } else if (error) {
      yield put({ type: RESERVATION_STATE_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, RESERVATION_STATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, RESERVATION_STATE_FAILURE);
  }
}

export function* watchReservationStateFetch() {
  yield takeLatest(RESERVATION_STATE_REQUEST, reservationStateFetch);
}

export default function* reservationsSaga() {
  yield all([
    watchReservationStateFetch(),
  ]);
}