import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { Divider, Drawer, Tab, Tabs, Grid, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InfoIcon from '@mui/icons-material/Info';
import { getInspectionMedia } from '../../store/features/inspectionMediaActions';
import SecondaryButton from '../shared/buttons/SecondaryButton';
import Error from '../shared/displays/Error';
import Loading from '../shared/displays/Loading';
import PrimaryButton from '../shared/buttons/PrimaryButton';
import StyledLink from '../shared/StyledLink';
import InspectionMediaView from './InspectionMediaView';
import InspectionMediaExifView from './InspectionMediaExifView';
import InspectionMediaExtractTextView from './InspectionMediaExtractTextView';
import ErrorBoundary from '../shared/displays/ErrorBoundary';
import TabPanel from '../shared/TabPanel';
import { isEmpty } from '../../utilities/objects';

import FileViewer from '../shared/fileViewer/FileViewer';
import { getProjectIsReadOnly, inspectionEnabled } from '../../utilities/projects';
import { locationWithBack } from '../../utilities/route';
import { appBarHeightFinder } from '../../utilities/styling';
import { useFeatureFlags, usePermissions } from '../../hooks/settingsHooks';

const drawerWidth = 650;

const InspectionMediaDetail = () => {
  const id = useParams().id;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { hasChecklistEdit } = usePermissions();
  const { hasInspectionMediaMetaView, hasInspectionMediaExtractTextView } = useFeatureFlags();
  const [infoDrawerOpen, toggleInfoDrawer] = useState(false);
  const [selectedTab, setSelectedTab] = useState('info');
  const workspace = useSelector(state => state.settings.features.workspace);
  const { data, error } = useSelector(state => state.inspectionMedia.each);
  const appBarHeight = appBarHeightFinder(workspace.alert_status);
  // used by ExtractTextView but stored here to avoid losing the data on tab change
  const [extractedText, setExtractedText] = useState('');

  const useStyles = makeStyles(theme => ({
    root: {
      margin: theme.spacing(2, 'auto'),
      padding: theme.spacing(2),
      textAlign: 'left',
    },
    appBar: {
      width: '100%',
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginRight: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    header: {
      // shift
      padding: theme.spacing(3),
    },
    title: {
      flexGrow: 1,
      // justify: 'left',
    },
    button: {
      padding: theme.spacing(0, 1),
    },
    // include useStyles when moving to own file
    mediaGallery: {
      margin: theme.spacing(3, 4),
    },
    mediaTitle: {
      margin: theme.spacing(1),
    },
    checklists: {
      margin: theme.spacing(1, 4),
    },
    drawer: {
      width: drawerWidth,
      minWidth: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      minWidth: drawerWidth,
      height: '95vh',
      marginTop: appBarHeight,
    },
    image: {
      maxHeight: '100%',
      maxWidth: '100%',
      backgroundSize: 'contain',
    },
    viewerContainer: {
      // height: '50vh',
      // margin: theme.spacing(0, 0, 2, 0),
      // width: '98vw',
      height: '85vh',
      margin: theme.spacing(2, 0, 0, 0),
    },
  }));

  useEffect(() => {
    dispatch(getInspectionMedia(id));
  }, [dispatch, id]);
  const classes = useStyles();

  if (isEmpty(data)) {
    return <Loading />;
  }
  if (error) return <Error error={error} />;

  const handleBack = () => {
    if (location.state && location.state.back) {
      history.push(location.state.back);
    } else {
      let next = '/';
      if (data?.project?.id) {
        next = `/projects/${data.project.id}`;
      } else if (data?.checklist?.id) {
        next = `/forms/${data.checklist.id}`;
      }
      history.push(next);
    }
  };

  const handleInspect = () => {
    history.push(locationWithBack({ pathname: `/projects/${data.project.id}/inspect/${id}` }, location));
  };

  const handleEdit = () => {
    history.push(`/inspection-media/${id}/edit`);
  };

  const handleShowInfo = () => {
    toggleInfoDrawer(!infoDrawerOpen);
  };

  const handleTabChange = (event, newValue) => {
    event.preventDefault();
    setSelectedTab(newValue);
  };

  const namePath = () => {
    const items = [];
    if (data.project) {
      items.push(<StyledLink to={`/projects/${data.project.id}`} value={data.project.name} />);
    }
    if (data.checklist) {
      items.push(<StyledLink to={`/checklists/${data.checklist.id}`} value={data.checklist.template.name} />);
    }
    if (data.component_display) {
      items.push(<>{data.component_display}</>);
    }
    if (data.location_zone_display) {
      items.push(<>{data.location_zone_display}</>);
    }
    if (data.location_code_display) {
      items.push(<>{data.location_code_display}</>);
    }
    items.push(<>{data.name}</>);
    return items.map((item, index) => <React.Fragment key={index}>/ {item} </React.Fragment>);
  };
  const isVisualInspectionProject = inspectionEnabled(data.project, data);
  const isReadOnly = getProjectIsReadOnly(data?.project?.status, hasChecklistEdit);

  const tabs = [
    {
      value: 'info',
      label: 'Info',
      Component: <InspectionMediaView data={data} fullHeight />,
    },
    {
      value: 'exif',
      label: 'EXIF Data',
      Component: <InspectionMediaExifView data={data.file_meta.exif} />,
      hide: !hasInspectionMediaMetaView || !data.file_meta.exif,
    },
    {
      value: 'text',
      label: 'Text',
      Component: <InspectionMediaExtractTextView extractedText={extractedText} setExtractedText={setExtractedText} />,
      hide: !hasInspectionMediaExtractTextView || !(data.document_category === 'image'), // only show tab for images
    },
  ];

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: infoDrawerOpen,
            })}
          >
            <Grid item>
              <IconButton onClick={handleBack} size="large">
                <ArrowBackIosIcon className={classes.button} />
              </IconButton>
            </Grid>
            <Grid item className={classes.title}>
              <Typography variant="h5">{namePath()}</Typography>
            </Grid>
            {isVisualInspectionProject && (
              <Grid>
                <div className={classes.button}>
                  <PrimaryButton label="Inspect" onClick={handleInspect} disabled={isReadOnly} />
                </div>
              </Grid>
            )}
            {hasChecklistEdit && (
              <Grid item>
                <div className={classes.button}>
                  <PrimaryButton label="Edit" icon Icon={EditIcon} onClick={handleEdit} disabled={isReadOnly} />
                </div>
              </Grid>
            )}
            <Grid item>
              <SecondaryButton label="Info" icon variant="text" Icon={InfoIcon} onClick={handleShowInfo} />
            </Grid>
          </Grid>
        </Grid>
        <Drawer
          variant="persistent"
          anchor="right"
          open={infoDrawerOpen}
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary">
            {tabs
              .filter(tab => !tab.hide)
              .map(tab => (
                <Tab
                  id={`inspection-media-tab-${tab.value}`}
                  aria-controls={`inspection-media-tabpanel-${tab.value}`}
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                />
              ))}
          </Tabs>
          <Divider className={classes.divider} />
          {tabs.map(tab => (
            <TabPanel key={tab.value} value={tab.value} selectedTab={selectedTab} name="inspection-media">
              <Grid item key={tab.value} className={classes.table}>
                {tab.Component}
              </Grid>
            </TabPanel>
          ))}
        </Drawer>
      </Grid>
      <Grid container direction="column" alignItems="stretch">
        <Grid item className={classes.viewerContainer}>
          <ErrorBoundary message={'Unable to display file'}>
            <FileViewer media={data} />
          </ErrorBoundary>
        </Grid>
      </Grid>
    </div>
  );
};

export default InspectionMediaDetail;
