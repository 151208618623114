import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AssessmentTypesTable from './AssessmentTypesTable';
import { getAllAssessmentTypes } from '../../store/features/assessmentTypesActions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const AssessmentTypesTablePage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(getAllAssessmentTypes());
  }, []); // eslint-disable-line

  return (
    <Paper className={classes.root}>
      <AssessmentTypesTable title="Assessment Types" />
    </Paper>
  );
};

export default AssessmentTypesTablePage;
