import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Container } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

/**
 * A layout with only a Container component to make sure styling is correct for light/dark mode.
 */

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '100vw',
    maxHeight: '100vh',
  },
}));

const BasicLayout = props => {
  const { pageTitle, children } = props;
  const classes = useStyles();
  const renderTitle = () => {
    if (pageTitle) {
      return (
        <Helmet>
          <title>{`${pageTitle} - HUVRdata`}</title>
        </Helmet>
      );
    }
    return <></>;
  };

  return (
    <Container maxWidth="xs" disableGutters className={classes.root}>
      {renderTitle()}
      {children}
    </Container>
  );
};

BasicLayout.defaultProps = {
  pageTitle: '',
};

BasicLayout.propTypes = {
  pageTitle: PropTypes.string,
  /** for rendering the children of this component */
  children: PropTypes.node.isRequired,
};

export default BasicLayout;
