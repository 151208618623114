// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import { combineReducers } from 'redux';
import {
  PROJECT_TYPES_REQUEST,
  PROJECT_TYPES_SUCCESS,
  PROJECT_TYPES_FAILURE,
  PROJECT_TYPES_CREATE_REQUEST,
  PROJECT_TYPES_CREATE_SUCCESS,
  PROJECT_TYPES_CREATE_FAILURE,
  PROJECT_TYPES_UPDATE_REQUEST,
  PROJECT_TYPES_UPDATE_SUCCESS,
  PROJECT_TYPES_UPDATE_FAILURE,
  PROJECT_TYPES_DELETE_REQUEST,
  PROJECT_TYPES_DELETE_SUCCESS,
  PROJECT_TYPES_DELETE_FAILURE,
  PROJECT_TYPES_ALL_REQUEST,
  PROJECT_TYPES_ALL_SUCCESS,
  PROJECT_TYPES_ALL_FAILURE,
  PROJECT_TYPES_CLEAR,
} from './projectTypesActions';

export const initialStateProjectTypes = {
  loading: false,
  error: '',
  formError: {},
  id: 0,
  body: {},
  data: {},
};

export const initialStateAllProjectTypes = {
  loading: false,
  error: '',
  query: {},
  dataAll: { count: 0, next: null, previous: null, results: [] },
  lastFetchedAt: null,
};

export const projectTypesReducer = (state = initialStateProjectTypes, action) => {
  switch (action.type) {
    case PROJECT_TYPES_REQUEST:
      return {
        ...initialStateProjectTypes,
        loading: true,
        id: action.id,
      };
    case PROJECT_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case PROJECT_TYPES_FAILURE:
      return {
        ...initialStateProjectTypes,
        error: action.error,
      };
    case PROJECT_TYPES_CREATE_REQUEST:
      return {
        ...initialStateProjectTypes,
        loading: true,
        body: action.body,
      };
    case PROJECT_TYPES_CREATE_SUCCESS:
      return {
        ...initialStateProjectTypes,
        loading: false,
        data: action.data,
      };
    case PROJECT_TYPES_CREATE_FAILURE:
      return {
        ...initialStateProjectTypes,
        error: action.error,
        formError: action.formError,
      };
    case PROJECT_TYPES_UPDATE_REQUEST:
      return {
        ...state,
        error: '',
        formError: {},
        loading: true,
        id: action.id,
        body: action.body,
      };
    case PROJECT_TYPES_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case PROJECT_TYPES_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case PROJECT_TYPES_DELETE_REQUEST:
      return {
        ...state,
        error: '',
        formError: {},
        deleting: true,
        id: action.id,
      };
    case PROJECT_TYPES_DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
      };
    case PROJECT_TYPES_DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        error: action.error,
        formError: action.formError,
      };
    case PROJECT_TYPES_CLEAR:
      return initialStateProjectTypes;
    default:
      return state;
  }
};

export const projectTypesAllReducer = (state = initialStateAllProjectTypes, action) => {
  switch (action.type) {
    case PROJECT_TYPES_ALL_REQUEST:
      return {
        ...initialStateAllProjectTypes,
        loading: true,
        query: action.query,
        lastFetchedAt: Date.now(),
      };
    case PROJECT_TYPES_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        dataAll: action.data,
      };
    case PROJECT_TYPES_ALL_FAILURE:
      return {
        ...initialStateAllProjectTypes,
        error: action.error,
      };
    default:
      return state;
  }
};

export default combineReducers({
  each: projectTypesReducer,
  all: projectTypesAllReducer,
});
