import { requestFactory, responseHandlerForm } from '../base';

const get = requestFactory('get');
const post = requestFactory('post', { handler: responseHandlerForm });
const patch = requestFactory('patch', { handler: responseHandlerForm });
const del = requestFactory('del');

export const fetchNotificationRulesList = async query => get('/api/notification-rules/', query);
export const fetchNotificationRules = async id => get(`/api/notification-rules/${id}/`);
export const createNotificationRules = async body => post('/api/notification-rules/', body);
export const updateNotificationRules = async (id, body) => patch(`/api/notification-rules/${id}/`, body);
export const deleteNotificationRules = async id => del(`/api/notification-rules/${id}/`);
