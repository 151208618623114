import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import InspectionMediaTable from '../../inspectionMedia/InspectionMediaTable';
import SlideshowViewer from '../../shared/slideshowViewer/SlideshowViewer';
import SlideshowGallery from '../../shared/slideshowGallery/SlideshowGallery';
import { useMediaStore } from '../../../hooks/mediaHooks';
import { getAllInspectionMedia } from '../../../store/features/inspectionMediaActions';
import { tableViews } from '../../../utilities/tables';
import { useTableViewSettings } from '../../../hooks/settingsHooks';

const FormMediaGallery = props => {
  const { id, form, isReadOnly } = props;
  const dispatch = useDispatch();
  const [showGallery, setShowGallery] = useState(false);
  const { media, total, previous, next, loading } = useMediaStore();
  const [showSlides, setShowSlides] = useState(false);
  const [startingId, setStartingId] = useState(null);
  const { inspectionMediaTableViewKey } = tableViews;
  const { embeddedQuery, tableQuery, page } = useTableViewSettings(inspectionMediaTableViewKey, { checklist: id });
  const lastIMQueryChecklist = useSelector(state => state.inspectionMedia.all.query)?.checklist;

  useEffect(() => {
    if (id !== lastIMQueryChecklist) {
      // if the checklist id is different than the last inspectionMedia query's checklist id
      // then the user navigated to another checklist so get latest media
      dispatch(getAllInspectionMedia(tableQuery));
    }
  }, [id, lastIMQueryChecklist]); // eslint-disable-line react-hooks/exhaustive-deps

  const tableChangeHandler = query => {
    dispatch(getAllInspectionMedia({ ...embeddedQuery, ...query }));
  };

  const handleClose = () => {
    setShowGallery(false);
  };

  const setMediaSlide = id => {
    setStartingId(id);
    setShowSlides(true);
  };
  return (
    <>
      <InspectionMediaTable
        title={'Media'}
        setMediaSlide={setMediaSlide}
        showGallery={showGallery}
        showSlides={showSlides}
        setShowGallery={setShowGallery}
        embedded
        formId={id}
        isReadOnly={isReadOnly}
        filename={`${form.name} Media`}
        tableChangeHandler={tableChangeHandler}
        queryParamObj={tableQuery}
      />
      {showSlides && (
        <SlideshowViewer
          paginate
          mediaArray={media}
          showSlides={showSlides}
          setShowSlides={setShowSlides}
          startingId={startingId}
          form={form}
          total={total}
          itemsPerPage={page}
          queryParamObj={tableQuery}
          tableChangeHandler={tableChangeHandler}
          previous={previous}
          next={next}
          loading={loading}
        />
      )}
      {showGallery && (
        <SlideshowGallery
          showGallery={showGallery}
          setShowGallery={setShowGallery}
          setMediaSlide={setMediaSlide}
          isReadOnly={isReadOnly}
          closeGallery={handleClose}
          tableChangeHandler={tableChangeHandler}
          embedded
        />
      )}
    </>
  );
};

FormMediaGallery.propTypes = {
  id: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired,
  isReadOnly: PropTypes.bool,
  tableChangeHandler: PropTypes.func,
  queryParamObj: PropTypes.object,
  pageSize: PropTypes.number,
};

export default FormMediaGallery;
