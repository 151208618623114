import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { apiDateToString } from '../../utilities/strings';
import FieldDisplayTable from '../shared/form/FieldDisplayTable';
import ProjectsStatusDisplay from './ProjectsStatusDisplay';
import UserDisplay from '../shared/displays/UserDisplay';

const useStyles = makeStyles(theme => ({
  projectHistory: {
    margin: theme.spacing(3, 4, 5),
  },
}));

const ProjectHistory = props => {
  const { statusHistory } = props;
  const colOrder = ['status', 'modified_by', 'created_on', 'note'];
  const data = statusHistory
    ? statusHistory.map(row => ({
        note: row.note,
        modified_by: <UserDisplay {...row.modified_by} />,
        status: <ProjectsStatusDisplay status={row.status} />,
        created_on: apiDateToString(row.created_on, 'date'),
      }))
    : [];
  const classes = useStyles();

  return (
    <div className={classes.projectHistory}>
      <FieldDisplayTable title label={'Status History'} data={data} colOrder={colOrder} />
    </div>
  );
};

ProjectHistory.propTypes = {
  statusHistory: PropTypes.array.isRequired,
};
export default ProjectHistory;
