import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { AppBar, ButtonGroup, Button, Toolbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import NavigateNext from '@mui/icons-material/NavigateNext';
import NavigateBefore from '@mui/icons-material/NavigateBefore';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import { truncate } from '../../../utilities/strings';
import { pixelToChar } from '../../../api/features/constants';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'sticky',
    top: '64px',
    width: '100%',
    zIndex: '10',
  },
  buttonGroup: {
    display: 'inline',
  },
  currentSection: {
    width: '60%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const SectionNav = props => {
  const classes = useStyles();
  const {
    currentSection,
    setSection,
    setOpenSections,
    openSections,
    submitting,
    pristine,
    formSubmit,
    isUploadingMedia,
  } = props;
  const { template } = props.checklist;
  const { sections } = template;
  const [navLength, setNavLength] = useState(85);
  const labelButtonRef = useRef(null);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickListItem = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, value) => {
    setSection(value);
    if (openSections.indexOf(value) === -1) {
      const newList = openSections.concat(value);
      setOpenSections(newList);
    }
    setAnchorEl(null);

    // scroll to top when user navigates section
    window.scroll(0, 0);
  };

  const handleNavClick = direction => {
    let currentIdx = sections.findIndex(section => section.key === currentSection);
    if (currentIdx === -1) {
      currentIdx = 0; // start at beginning
    }
    if (currentIdx + direction >= sections.length) {
      return;
    }
    if (currentIdx + direction < 0) {
      return;
    }

    // currentSection
    const newKey = sections[currentIdx + direction].key;
    if (openSections.indexOf(newKey) === -1) {
      const newList = openSections.concat(newKey);
      setOpenSections(newList);
    }
    setSection(newKey);

    // scroll to top when user navigates section
    window.scroll(0, 0);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setNavLength(labelButtonRef.current.clientWidth);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const hasNext = () => {
    const currentIdx = sections.findIndex(section => section.key === currentSection);
    if (currentIdx + 1 >= sections.length) {
      return false;
    }
    return true;
  };
  const hasPrev = () => {
    const currentIdx = sections.findIndex(section => section.key === currentSection);
    if (currentIdx - 1 < 0) {
      return false;
    }
    return true;
  };

  const FWD = 1;
  const REV = -1;

  const foundSection = sections.find(section => section.key === currentSection);
  const currentLabel = foundSection ? truncate(foundSection.label, Math.floor(navLength * pixelToChar), 'mid') : '';
  return (
    <AppBar position="sticky" className={classes.root}>
      <Toolbar>
        <ButtonGroup aria-label="Section Selector" style={{ flex: 1 }} className={classes.buttonGroup}>
          <Button
            className={classes.currentSection}
            ref={labelButtonRef}
            aria-haspopup="true"
            aria-controls="section-menu"
            aria-label="Section Selector"
            label={currentLabel}
            onClick={handleClickListItem}
            endIcon={<ArrowDropDownIcon />}>
            {currentLabel}
          </Button>
          <Button label="Prev" onClick={() => handleNavClick(REV)} startIcon={<NavigateBefore />} disabled={!hasPrev()}>
            Prev
          </Button>
          <Button onClick={() => handleNavClick(FWD)} endIcon={<NavigateNext />} disabled={!hasNext()}>
            Next
          </Button>
        </ButtonGroup>
        <PrimaryButton
          label="Save"
          onClick={() => formSubmit()}
          disabled={submitting || pristine || isUploadingMedia}
        />
      </Toolbar>

      <Menu id="section-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {sections.map((section, index) => (
          <MenuItem
            key={section.key}
            selected={section.key === currentSection}
            onClick={event => handleMenuItemClick(event, section.key)}>
            {section.label}
          </MenuItem>
        ))}
      </Menu>
    </AppBar>
  );
};

SectionNav.propTypes = {
  currentSection: PropTypes.string,
  setSection: PropTypes.func.isRequired,
  checklist: PropTypes.object.isRequired,
  openSections: PropTypes.array.isRequired,
  setOpenSections: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  formSubmit: PropTypes.func.isRequired,
  isUploadingMedia: PropTypes.bool.isRequired,
};
SectionNav.defaultProps = { currentSection: '' };
export default SectionNav;
