import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, CircularProgress, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Form } from 'react-final-form';
import PrimaryButton from '../../components/shared/buttons/PrimaryButton';
import SecondaryButton from '../../components/shared/buttons/SecondaryButton';
import { closeDialog } from '../../store/dialogActions';
import FieldEditDateTime from '../../components/shared/form/FieldEditDateTime';
import { generateScheduleEvent, resetSchedulePlan } from '../../store/features/schedulePlanActions';
import { dateToApiDateTime } from '../../utilities/strings';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    height: '100%',
    textAlign: 'left',
  },
  form: {
    margin: theme.spacing(3),
    height: '90%',
  },
  buttonGroup: {
    marginTop: theme.spacing(5),
  },
  loadingContainer: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
}));

const EventForm = props => {
  const { id } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const { formError, generateEventIsLoading } = useSelector(state => state.schedulePlan.each);

  const handleCloseDialog = () => {
    dispatch(closeDialog());
    dispatch(resetSchedulePlan());
  };

  const handleDialogSubmit = ({ start }) => {
    const body = {
      ...(start && { start: dateToApiDateTime(start) }),
    };
    dispatch(generateScheduleEvent(id, body));
  };

  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        errors[key] = value;
      }
    }
    return errors;
  };

  const LoadingIndicator = () => {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress size={45} />
        <Typography>Generating Events -- this may take a few moments...</Typography>
      </div>
    );
  };

  const HelpText = () => {
    return (
      <div>
        <p>Events are automatically generated by the system.</p>
        <p>In normal operation, you do not need to manually generate events.</p>
        <p>
          Use this form to:
          <ul>
            <li>create event(s) with a start date in the past</li>
            <li>create the next event(s) early</li>
          </ul>
        </p>
        <p>Note: the creation window and the event count are still applied to manual generation.</p>
        <br />
      </div>
    );
  };

  return (
    <Form
      onSubmit={handleDialogSubmit}
      validate={validate}
      initialValues={{}}
      render={({ handleSubmit, form }) => {
        return (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Grid container direction="column" justifyContent="space-between" className={classes.formControl}>
              <Grid item>
                <Grid container spacing={2} direction="column">
                  {generateEventIsLoading && <LoadingIndicator />}
                  {!generateEventIsLoading && <HelpText />}
                  <FieldEditDateTime
                    name="start"
                    label="Start After Time"
                    disabled={generateEventIsLoading}
                    helperText="Select the time you want start generating from. Leave blank to default to now."
                  />
                </Grid>
              </Grid>
              <Grid item className={classes.buttonGroup}>
                <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
                  <Grid item xs={2}>
                    <SecondaryButton label="Cancel" onClick={handleCloseDialog} disabled={generateEventIsLoading} />
                  </Grid>
                  <Grid item xs={2}>
                    <PrimaryButton type="submit" label="Generate" disabled={generateEventIsLoading} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};

EventForm.propTypes = {
  id: PropTypes.number.isRequired,
};

export default EventForm;
