// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import React from 'react';
import CommonRoutes from './utilities/CommonRoutes';
import NotificationRulesForm from '../../components/notificationRules/NotificationRulesForm';
import NotificationRulesDetail from '../../components/notificationRules/NotificationRulesDetail';
import NotificationRulesTablePage from '../../components/notificationRules/NotificationRulesTablePage';
import MainLayout from '../layouts/MainLayout';

const NotificationRulesRoutes = props => {
  return (
    <CommonRoutes
      pageTitle="Notification Rules"
      layout={MainLayout}
      Edit={<NotificationRulesForm update />}
      Create={<NotificationRulesForm />}
      View={<NotificationRulesDetail />}
      All={<NotificationRulesTablePage />}
    />
  );
};

export default NotificationRulesRoutes;
