// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import { combineReducers } from 'redux';
import {
  SCHEDULEWORK_REQUEST,
  SCHEDULEWORK_SUCCESS,
  SCHEDULEWORK_FAILURE,
  SCHEDULEWORK_CREATE_REQUEST,
  SCHEDULEWORK_CREATE_SUCCESS,
  SCHEDULEWORK_CREATE_FAILURE,
  SCHEDULEWORK_UPDATE_REQUEST,
  SCHEDULEWORK_UPDATE_SUCCESS,
  SCHEDULEWORK_UPDATE_FAILURE,
  SCHEDULEWORK_DELETE_REQUEST,
  SCHEDULEWORK_DELETE_SUCCESS,
  SCHEDULEWORK_DELETE_FAILURE,
  SCHEDULEWORK_ALL_REQUEST,
  SCHEDULEWORK_ALL_SUCCESS,
  SCHEDULEWORK_ALL_FAILURE,
} from './scheduleWorkActions';

export const initialStateScheduleWork = {
  loading: false,
  error: '',
  formError: {},
  id: 0,
  body: {},
  data: {},
};

export const initialStateAllScheduleWork = {
  loading: false,
  error: '',
  query: {},
  dataAll: { count: 0, next: null, previous: null, results: [] },
};

export const resetStateScheduleWork = {
  loading: false,
  error: '',
  formError: {},
  data: {},
};
export const resetStateAllScheduleWork = {
  loading: false,
  error: '',
  query: {},
  dataAll: [],
};

export const scheduleWorkEachReducer = (state = initialStateScheduleWork, action) => {
  switch (action.type) {
    case SCHEDULEWORK_REQUEST:
      return {
        ...initialStateScheduleWork,
        loading: true,
        id: action.id,
      };
    case SCHEDULEWORK_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case SCHEDULEWORK_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case SCHEDULEWORK_CREATE_REQUEST:
      return {
        ...initialStateScheduleWork,
        loading: true,
        planId: action.planId,
        body: action.body,
      };
    case SCHEDULEWORK_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case SCHEDULEWORK_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case SCHEDULEWORK_UPDATE_REQUEST:
      return {
        ...initialStateScheduleWork,
        loading: true,
        id: action.id,
        planId: action.planId,
        body: action.body,
      };
    case SCHEDULEWORK_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case SCHEDULEWORK_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case SCHEDULEWORK_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        formError: {},
        id: action.id,
      };
    case SCHEDULEWORK_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SCHEDULEWORK_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    default:
      return state;
  }
};

export const scheduleWorkAllReducer = (state = initialStateAllScheduleWork, action) => {
  switch (action.type) {
    case SCHEDULEWORK_ALL_REQUEST:
      return {
        ...initialStateAllScheduleWork,
        loading: true,
        query: action.query,
      };
    case SCHEDULEWORK_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        dataAll: action.data,
      };
    case SCHEDULEWORK_ALL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export default combineReducers({
  each: scheduleWorkEachReducer,
  all: scheduleWorkAllReducer,
});
