import { buildEndpointActionTypes } from './actions';

/**
 * @param {*=} defaultData
 */
export const buildEndpointInitialState = defaultData => {
  return {
    loading: false,
    error: '',
    data: defaultData || {},
    formError: null, // only present on `post` / `patch` requests if valid json error response
    apiRequestArgs: [], // as passed directly to the apiRequest function
  };
};

/**
 * @param {String} endpointName
 * @param {Object} options
 * @param {*} options.defaultData
 * @returns {Function} - the reducer function
 */
export const buildEndpointReducer = (endpointName, options) => {
  const actionTypes = buildEndpointActionTypes(endpointName);
  const initialState = buildEndpointInitialState(options.defaultData);

  /**
   * Redux Reducer for the endpoint
   *  - handles the request, success, and failure actions
   *
   *  see redux docs for understanding reducers:
   *    https://redux.js.org/tutorials/essentials/part-1-overview-concepts#reducers
   *
   * @param {Object} state
   * @param {Object} action
   *
   * @returns {Object} - the new state
   */
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.request:
        return {
          ...state,
          loading: true,
          apiRequestArgs: action.apiRequestArgs,
        };
      case actionTypes.success:
        return {
          ...state,
          loading: false,
          data: action.data,
        };
      case actionTypes.failure:
        return {
          ...state,
          loading: false,
          error: action.error || '',
          formError: action.formError || null,
          data: initialState.data,
        };
      case actionTypes.resetState:
        return initialState;
      default:
        return state;
    }
  };

  return reducer;
};
