import React from 'react';
import PropTypes from 'prop-types';

import Description from '../shared/table/Description';
import Table, { SELECTABLE_ROWS_NONE } from '../shared/table/Table';
import { getColumnNames } from '../../utilities/tables';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const ChecklistTemplateSectionsTable = props => {
  const { title, sections, loading, embedded, setCurrentSection } = props;

  /** @type {Column[]} */
  const columns = [
    {
      name: 'order',
      label: 'Order',
      options: {
        filter: false,
        sort: true,
      },
    },

    {
      name: 'label',
      label: 'Label',
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: 'lineCount',
      label: 'Line Count',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'key',
      label: 'Key',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        filter: true,
        sort: true,
        filterType: 'textField',
        customBodyRender: value => {
          if (!value) return <></>;
          return <Description value={value} />;
        },
      },
    },
  ];

  // Explicitly set this, normally this is added in `setColumns`
  columns.findIndexByName = getColumnNames(columns);

  const handleRowClick = (rowData, rowMeta) => {
    setCurrentSection(rowData[columns.findIndexByName['key']]);
  };

  /** @type {TableOptions} */
  const options = {
    enableNestedDataAccess: '.',
    searchOpen: false,
    customToolbar: () => {
      return null;
    },
    search: false,
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
    selectableRows: SELECTABLE_ROWS_NONE,
    rowsPerPage: 50,
    onRowClick: handleRowClick,
  };

  // className={styleProjectDetail}
  return (
    <div>
      <Table
        title={title}
        simpleSearch
        columns={columns}
        data={sections}
        options={options}
        loading={loading}
        embedded={embedded}
      />
    </div>
  );
};

ChecklistTemplateSectionsTable.defaultProps = {
  title: 'Sections',
  sections: [],
  embedded: false,
  loading: false,
  setCurrentSection: () => {},
};

ChecklistTemplateSectionsTable.propTypes = {
  title: PropTypes.string,
  sections: PropTypes.array,
  embedded: PropTypes.bool,
  loading: PropTypes.bool,
  setCurrentSection: PropTypes.func,
};

export default ChecklistTemplateSectionsTable;
