import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { Container } from '@mui/material';

import Checklist from './Checklist';
import LoadingPage from '../shared/displays/LoadingPage';
import { getProjects } from '../../store/features/projectsActions';
import { getChecklists } from '../../store/features/checklistsActions';
import { isEmpty } from '../../utilities/objects';
import { shouldFetchData } from '../projects/projectsShared';
import Error from '../shared/displays/Error';
import { useTableViews } from '../../hooks/settingsHooks';
import { tableViews } from '../../utilities/tables';
import { useQuery } from '../../hooks/locationHooks';
import { inspectionMediaListBatchFetchAllEndpoint } from '../../store/apiV2/inspectionMedia';
import ChecklistBreadcrumbs from './nav/ChecklistBreadcrumbs';

const ProjectChecklist = props => {
  const { projectId, id: checklistId } = useParams();
  const projectState = useSelector(state => state.projects.each);
  const checklistState = useSelector(state => state.checklists.each);

  const dispatch = useDispatch();
  const location = useLocation();

  const { inspectionMediaTableViewKey } = tableViews;
  const { pageSize: mediaTableSize } = useTableViews(inspectionMediaTableViewKey);
  const { query: inspectionMediaQuery } = useSelector(state => state.inspectionMedia.all);
  const { dispatchRequest: batchFetchAllMedia } = inspectionMediaListBatchFetchAllEndpoint.useEndpoint();

  const [query, setQuery] = useQuery();
  const revisionId = useSelector(state => state.revisions.selected);

  useEffect(() => {
    setQuery({ ...query, revisionId });
  }, [revisionId]); // eslint-disable-line react-hooks/exhaustive-deps

  const isLoading = (() => {
    if (projectState.loading) {
      return true;
    }
    if (checklistState.loading) {
      return true;
    }
    return false;
  })();

  /**
   * Refresh if checklist in redux no longer matches the project id in the url
   */
  const shouldRefresh = (() => {
    if (isLoading) {
      return false; // do not trigger refresh if loading
    }
    if (!shouldFetchData(location, projectId)) {
      return false;
    }
    if (isEmpty(projectState.data)) {
      return true;
    }
    if (projectState.data.id.toString() !== projectId.toString()) {
      return true;
    }
    if (projectState.data.id !== checklistState.data?.project?.id) {
      return true;
    }
    if (checklistState.data.id.toString() !== checklistId.toString()) {
      return true;
    }
    return false;
  })();

  useEffect(() => {
    console.debug('useEffect: check should refresh');

    // Fetches all inspection media for the project
    const defaultInspectionMediaQuery = {
      limit: mediaTableSize,
      project: projectId,
      hidden: false,
      ordering: '-created_on',
    };
    const mediaQuery = isEmpty(inspectionMediaQuery) ? defaultInspectionMediaQuery : inspectionMediaQuery;

    if (shouldRefresh) {
      dispatch(getProjects(projectId));
      dispatch(getChecklists(checklistId));
      batchFetchAllMedia({ ...mediaQuery, limit: 200 });
    }
  }, [projectId, checklistId, dispatch, shouldRefresh, inspectionMediaQuery, mediaTableSize, batchFetchAllMedia]);

  const refresh = () => {
    dispatch(getProjects(projectId));
  };

  if (isLoading || shouldRefresh) {
    return <LoadingPage />;
  }

  const error = projectState.error || checklistState.error;
  if (error) {
    return <Error error={`Project: ${error}`} style={{ marginTop: '56px', fontSize: '40px' }} />;
  }
  if (checklistState?.error) {
    return <Error error={`Checklist: ${checklistState.error}`} style={{ marginTop: '56px', fontSize: '40px' }} />;
  }

  const title = <ChecklistBreadcrumbs checklist={checklistState?.data} />;

  return (
    <Container maxWidth={false} disableGutters>
      <div className="container">
        <Checklist
          parentId={projectId}
          checklist={checklistState?.data}
          title={title}
          refresh={refresh}
          selectedRevisionId={revisionId}
          backLink={`/projects/${projectId}`}
        />
      </div>
    </Container>
  );
};

export default ProjectChecklist;
