import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { jsonKeyToLabel } from '../../../utilities/strings';
import CommonForm from '../../shared/form/Common';
import { checklistTemplatesFillableListEndpoint } from '../../../store/apiV2/checklistTemplates';

export const fieldOrder = {
  info: {
    display: 'Select the form template',
    fields: ['categories', 'checklist_templates'],
  },
};

const ALL_CATEGORIES = 'All categories';

const FormChecklistPicker = () => {
  const history = useHistory();
  const location = useLocation();
  const [category, setCategory] = useState(ALL_CATEGORIES);
  const {
    data: { results } = { results: {} }, // setting default in case data is an empty object
    loading,
    error,
  } = useSelector(state => checklistTemplatesFillableListEndpoint.selector(state));

  const dispatch = useDispatch();

  const formSettings = {
    fieldOrder,
    removeField: () => {},
    hideField: () => {},
  };

  useEffect(() => {
    dispatch(checklistTemplatesFillableListEndpoint.actionCreators.request());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const sortAlpha = (a, b) => {
    const nameA = a.label.trim().toLowerCase();
    const nameB = b.label.trim().toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  };

  const categoryOptions = [...new Set(results?.flatMap(item => item.categories))]
    .map(item => {
      return { value: item, label: item };
    })
    .sort(sortAlpha);

  const sortedCategoryOptions = [{ label: ALL_CATEGORIES, value: ALL_CATEGORIES }].concat(categoryOptions);

  const labelMaker = item => {
    if (item.state === 'DRAFT') {
      return `${item.name} ${item.version} (Draft)`;
    }
    return `${item.name} ${item.version}`;
  };

  const optionsReducer = (filteredList, item) => {
    if (category === ALL_CATEGORIES) {
      filteredList.push({ value: item.id, label: labelMaker(item) });
    }
    if (category && item.categories.includes(category)) {
      filteredList.push({ value: item.id, label: labelMaker(item) });
    }
    return filteredList;
  };

  const checklistTemplateOptions = results?.reduce(optionsReducer, []);

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */ const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      switch (key) {
        /* ---------- Required Fields ---------- */
        case 'categories':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: jsonKeyToLabel(key),
              labelwidth: 70,
              name: key,
              helperText: 'Filter by category.',
              options: sortedCategoryOptions,
              onChange: e => {
                setCategory(e.target.value);
              },
            },
          };
          break;
        case 'checklist_templates':
          fieldSettings[key] = {
            type: 'autocomplete',
            fieldProps: {
              label: 'Templates',
              name: key,
              labelwidth: 70,
              helperText: 'Select the template for your new form.',
              options: checklistTemplateOptions,
              placeHolder: 'Select a template',
            },
          };
          break;
        /* ---------- Regular Fields ---------- */

        /* ----------  Disabled Fields ---------- */

        /* ----------  Default ---------- */
        default:
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
      }
    });
  }

  const title = 'New Form';
  const onSubmit = values => {
    if (values.checklist_templates) {
      history.push(`/forms/new/${values.checklist_templates}${location.search}`); // pass any additional params
    } else {
      history.push(`/forms/new`);
    }
  };

  /**
   * Decorators are used for setting the values of other fields based off of a field.
   * Decorator format can be found here: src/components/shared/form/common.js
   */
  const decorators = [];
  const initialValues = {
    categories: ALL_CATEGORIES,
  };
  const customToolbarProps = {
    saveButtonProps: { label: 'New' },
  };

  return (
    <CommonForm
      title={title}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      onSubmit={onSubmit}
      decorators={decorators}
      validate={() => {}}
      loading={loading}
      error={error}
      initialValues={initialValues}
      customToolbarProps={customToolbarProps}
    />
  );
};

export default FormChecklistPicker;
