import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import PrimaryButton from '../shared/buttons/PrimaryButton';
import SecondaryButton from '../shared/buttons/SecondaryButton';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(7, 'auto'),
    padding: theme.spacing(2),
    maxWidth: '600px',
  },
  body: {
    padding: theme.spacing(2, 4),
    textAlign: 'left',
  },
}));

const InspectorMediaFormToolbar = props => {
  const { cancel, submitDisabled, dirty, reset } = props;
  const classes = useStyles();

  const handleCancel = () => {
    reset();
    cancel();
  };

  return (
    <React.Fragment>
      <Grid container justifyContent="space-between" direction="row-reverse" className={classes.buttonGroup}>
        <PrimaryButton label="Save" type="submit" disabled={submitDisabled} />
        {dirty && <SecondaryButton label="Cancel" onClick={handleCancel} />}
      </Grid>
    </React.Fragment>
  );
};

InspectorMediaFormToolbar.defaultProps = {
  submitDisabled: false,
  component: undefined,
  cancel: undefined,
  dirty: false,
  reset: undefined,
};

InspectorMediaFormToolbar.propTypes = {
  submitDisabled: PropTypes.bool,
  component: PropTypes.func,
  cancel: PropTypes.func,
  dirty: PropTypes.bool,
  reset: PropTypes.func,
};

export default InspectorMediaFormToolbar;
