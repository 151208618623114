import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { jsonKeyToLabel, apiDateToString, dateToApiDateTime } from '../../utilities/strings';
import { getSchedulePlan, createSchedulePlan, updateSchedulePlan } from '../../store/features/schedulePlanActions';
import CommonForm from '../shared/form/Common';
import { fieldOrder, removeField, hideField } from './SchedulePlanShared';
import { useCrews } from '../../hooks/optionsHooks';
import { getAllCrews } from '../../store/features/crewsActions';

const getInitialAsset = (update, data) => {
  if (update) {
    return {
      label: data?.asset?.name,
      value: data?.asset?.id,
    };
  }
  return {
    label: '',
    value: '',
  };
};

const SchedulePlanForm = props => {
  const { update } = props;
  const params = useParams();
  const { data, loading, error, formError } = useSelector(state => state.schedulePlan.each);
  const [submittedValues, setSubmittedValues] = useState({});
  const dispatch = useDispatch();
  const crewOptions = useCrews();

  useEffect(() => {
    if (update && params.id) {
      dispatch(getSchedulePlan(params.id));
    }
    dispatch(getAllCrews({ is_active: true }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formSettings = {
    fieldOrder,
    removeField: removeField(update ? 'UPDATE' : 'CREATE'),
    hideField,
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      switch (key) {
        /* ---------- Required Fields ---------- */
        case 'name':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              required: true,
            },
          };
          break;
        case 'crew':
          fieldSettings[key] = {
            type: 'autocomplete',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: 'crew',
              options: crewOptions,
              required: true,
              helperText: 'Default crew to be assigned work from this schedule.',
            },
          };
          break;
        /* ---------- Regular Fields ---------- */
        case 'start':
          fieldSettings[key] = {
            type: 'datetime',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              helperText: 'Leave blank to default to the 1st of the month.',
              format: 'YYYY-MM-DDThh:mm:ssZ',
            },
          };
          break;
        case 'asset':
          fieldSettings[key] = {
            type: 'asset-autocomplete',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: 'asset',
              valueInitial: getInitialAsset(update, data),
              helperText: 'Asset associated with this schedule. (Optional)',
            },
          };
          break;
        case 'local_timezone':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              placeholder: 'US/Central',
              helperText: 'Timezone used for event generation. Leave blank to default to your timezone. (Optional)',
            },
          };
          break;
        case 'creation_window':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              placeholder: 'P1W',
              helperText:
                'Pauses event creation until the defined duration before upcoming event, defined as ISO8601 duration format: P5D (5 days). (Optional)',
            },
          };
          break;
        case 'event_duration':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              placeholder: 'P1M',
              helperText: 'Defined duration of the event, defined as ISO8601 duration format: P1M (1 month).',
            },
          };
          break;
        case 'event_count':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              placeholder: '1',
              helperText: 'Number of upcoming events created in the system. Must be 1 or more.',
            },
          };
          break;
        case 'event_name_template':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              placeholder: '{{event.plan.name}} {{event.plan.start|date:"Y"}}',
              helperText: 'Template to dynamically build event name (Optional).',
            },
          };
          break;
        case 'recurrence_type':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              helperText: 'Recurrence type for future events.',
            },
          };
          break;
        case 'recurrence_rule':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              helperText: 'Recurrence rule for future events.',
              placeholder: 'FREQ=MONTHLY;BYMONTHDAY=1;INTERVAL=1',
            },
          };
          break;
        case 'is_active':
          fieldSettings[key] = {
            type: 'checkbox',
            cellProps: {
              xs: 12,
            },
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          break;
        case 'description':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              multiline: true,
              rows: 3,
              maxRows: 10,
            },
          };
          break;

        /* ----------  Disabled Fields ---------- */
        case 'created_on':
        case 'updated_on':
          fieldSettings[key] = {
            type: 'display',
            fieldProps: {
              disabled: true,
              label: jsonKeyToLabel(key),
              name: key,
              value: apiDateToString(data[key], 'date'),
            },
          };
          break;
        /* ----------  Default ---------- */
        default:
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
      }
    });
  }

  const title = update ? 'Edit Schedule Plan' : 'New Schedule Plan';

  let initialValues = {};
  if (update) {
    // The form cannot handle data coming in as objects.  (see ProjectsForm as an example)
    initialValues = {
      ...data,
      crew: data.crew ? data.crew.id.toString() : undefined,
      asset: data.asset ? data.asset.id : undefined,
    };
  } else {
    // assign values from url queries here
    initialValues = {};
  }

  const onSubmit = changedValues => {
    let localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // need to check for all browsers
    if ('local_timezone' in changedValues) {
      localTimezone = changedValues['local_timezone'];
    }
    const updatedValues = { ...changedValues, local_timezone: localTimezone };
    if ('start' in updatedValues) updatedValues['start'] = dateToApiDateTime(updatedValues['start']);
    setSubmittedValues(changedValues);
    if (update) {
      dispatch(updateSchedulePlan(updatedValues.id, updatedValues));
    } else {
      dispatch(createSchedulePlan(updatedValues));
    }
  };

  /**
   * Decorators are used for setting the values of other fields based off of a field.
   * Decorator format can be found here: src/components/shared/form/common.js
   */
  const decorators = [];

  /**
   *  Validations that are per field.
   *  Errors associated with the form are passed back through formError in Redux.
   */
  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        if (values[key] === submittedValues[key]) {
          errors[key] = value.non_field_errors ? value.non_field_errors : value;
        } else {
          errors[key] = undefined;
        }
      }
    }
    return errors;
  };

  return (
    <CommonForm
      update={update}
      title={title}
      initialValues={initialValues}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      onSubmit={onSubmit}
      decorators={decorators}
      validate={validate}
      loading={loading}
      error={error}
    />
  );
};

SchedulePlanForm.defaultProps = {
  update: false,
};

SchedulePlanForm.propTypes = {
  update: PropTypes.bool,
};

export default SchedulePlanForm;
