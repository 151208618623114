import React from 'react';
import PropTypes from 'prop-types';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { PersistGate } from 'redux-persist/integration/react';
import { createMigrate, persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import { migrations } from '../store/migrations';
import { rootReducer } from '../store/reducers';
import rootSaga from '../store/sagas';
import { default as preloadedState } from '../store/data/initialData';

export const history = createBrowserHistory();
history.listen(info => {
  console.log('StoreProvider:history.listen', { info });
});

const StoreProvider = props => {
  const { children } = props;
  let initialState = {};
  const composeEnhancers = composeWithDevTools;
  const middlewares = [];

  if (process.env.NODE_ENV !== 'production') {
    initialState = preloadedState;
    const logger = createLogger();
    // don't use the logger when running tests.
    if (process.env.REACT_APP_DISABLE_LOGGER === undefined && process.env.NODE_ENV !== 'test') {
      middlewares.push(logger);
    }
  }

  const persistConfig = {
    key: 'root',
    version: 2,
    storage,
    whitelist: ['auth', 'features', 'settings', 'libraries'],
    migrate: createMigrate(migrations, { debug: false }),
  };

  const sagaMiddleware = createSagaMiddleware();
  middlewares.push(sagaMiddleware);
  middlewares.push(routerMiddleware(history));

  const persistedReducer = persistReducer(persistConfig, rootReducer(history));
  const store = createStore(persistedReducer, initialState, composeEnhancers(applyMiddleware(...middlewares)));
  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
};

StoreProvider.propTypes = {
  children: PropTypes.node,
};

export default StoreProvider;
