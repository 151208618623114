// lib
import React, { useState, useEffect } from 'react';
import { useFormState } from 'react-final-form';
import { Grid, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import JSONTree from 'react-json-tree';

// component
import CalculationLineItem from './CalculationLineItem';

// util
import { buildExpressionContext } from '../../../utilities/checklist';

const useStyles = makeStyles(theme => ({
  expressionEditorRoot: {
    fontSize: '14px',
    padding: '6px',
    margin: '6px',
  },
  contextWrapper: {},
  contextJSONCode: {
    backgroundColor: '#eee',
    padding: '4px',
    whiteSpace: 'pre-wrap',
    margin: '0px',
  },
  editCheckboxLabel: {
    fontSize: '14px',
  },
}));

/**
 * For template editing mode, to allow playing with the expression
 *  wraps the `CalculationLineItem`
 */
const CalculationExpressionEditorLineItem = props => {
  // hooks / state
  const { values } = useFormState(); // https://final-form.org/docs/final-form/types/FormState
  const [expression, setExpression] = useState(props.expression);
  const [isEditingExpression, setIsEditingExpression] = useState(false);
  const classes = useStyles();

  //

  const updatedProps = { ...props, expression };
  const context = buildExpressionContext(values);

  // effects

  useEffect(() => setExpression(props.expression), [props.expression, isEditingExpression]);

  return (
    <Grid>
      <label className={classes.editCheckboxLabel}>
        <input type="checkbox" checked={isEditingExpression} onChange={e => setIsEditingExpression(e.target.checked)} />
        Edit Expression
      </label>
      {isEditingExpression && (
        <Paper className={classes.expressionEditorRoot} elevation="2">
          <Grid>
            <p>
              Expression | &nbsp;
              <a href="https://github.com/huvrdata/evaluate" target="_blank" rel="noreferrer">
                see documentation for expression syntax and usage &#8599;
              </a>
            </p>
            <p>To save, copy expression and re-upload template xls file</p>
            <textarea
              cols="80"
              rows="4"
              value={expression}
              spellCheck={false}
              onChange={e => setExpression(e.target.value)}
            />
          </Grid>
          <Grid>
            <p>Available Context</p>
            <JSONTree data={context} />
          </Grid>
        </Paper>
      )}
      <CalculationLineItem {...updatedProps} />
    </Grid>
  );
};

CalculationExpressionEditorLineItem.propTypes = { ...CalculationLineItem.propTypes };

export default CalculationExpressionEditorLineItem;
