import queryString from 'query-string';
import saveAs from 'save-as';
import moment from 'moment';
import JSZipUtils from 'jszip-utils';
export const baseRoute = '';
export const buildRoute = route => baseRoute.concat(route);
export const defaultLocation = {
  pathname: '/',
  search: '',
  hash: '',
};
// Latitudes between +/- 90 degrees and longitudes between +/-180 degrees.
export const latLongRegex =
  /^(-?([0-8]?[0-9](\.\d+)?|90(.[0]+)?)\s{0,3}[,]+\s{0,3})(-?([0-9]{2}(\.\d+)?|[1]?[0-7]?[0-9]?(\.\d+)?|180((.[0]+)?)))$/;
export const replaceCharWithSpace =
  (char = '_') =>
  str =>
    str.split(char).join(' ');
export const capitalizeFirstChar = str => {
  if (!str || typeof str !== 'string') return str;
  if (str.length > 1) {
    return `${str[0].toUpperCase()}${str.slice(1)}`;
  } else {
    return str.toUpperCase();
  }
};
export const camelToSnake = str => {
  // converts a string that's in camel or pascal case to snake case.
  // adds a dash infront of every capital letter and makes every character lowercase.
  return str
    .split(/(?=[A-Z])/)
    .map(word => word[0].toLowerCase() + word.slice(1))
    .join('-');
};
export const camelCaseToDisplay = str => {
  // take camel case and return proper(?) string
  // camelCase => Camel Case
  return str.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
};
export const formatError = error => {
  if (typeof error === 'string') {
    return error;
  }
  if (error.status) {
    return `${error.status} - ${error.message}`;
  }
  if (error.code) {
    return `${error.code} - ${error.message}`;
  }
  return error.message;
};
export const getCookie = (name, testCookie = '') => {
  const matchString = '(^|;) ?' + name + '=([^;]*)(;|$)';
  let v = null;
  // testCookie is just for running unit tests.
  if (testCookie) {
    v = testCookie.match(matchString);
  } else {
    v = document.cookie.match(matchString);
  }
  return v ? v[2] : null;
};

export const deleteCookie = name => {
  if (getCookie(name)) {
    document.cookie = name + '=; expires=Sat, 26 Oct 1985 00:00:00 UTC; path=/;';
  }
};

export const formatApiError = error => (Array.isArray(error) ? error.map(e => e) : error);

// Pass in a shallow object of queries since query-string does not support nested queries.
export const toQueryString = queryParams => {
  let query = '';
  if (typeof queryParams === 'object') {
    const queryStringified = queryString.stringify(queryParams, {
      skipNull: true,
      arrayFormat: 'comma',
    });
    query = `?${queryStringified}`;
  }
  return query;
};
// Return an object of queries.  This does not check that all keys are returned.
export const queriesFromString = (query, keys = [], noArray = false) => {
  const subQuery = {};
  for (const [key, value] of Object.entries(queryString.parse(query, { arrayFormat: noArray ? 'none' : 'comma' }))) {
    if (keys.includes(key) || keys.length === 0) {
      subQuery[key] = value;
    }
  }
  return subQuery;
};
// convert snake case variable names to human readable string (space separated with capitalized first letter)
export const jsonKeyToLabel = key => {
  return key
    .split('_')
    .map(str => {
      if (str) return capitalizeFirstChar(str);
      return '';
    })
    .join(' ');
};

// this will need to handle longer paths (inspection media)
export const pathToPage = pathname => {
  if (pathname === '/') return 'DASHBOARD';

  const splitPath = pathname.split('/');
  const firstLevelPage = splitPath[1].replace('-', '_').toUpperCase();

  if (splitPath.length === 2) {
    return firstLevelPage; // table page
  } else if (splitPath.length > 2) {
    if (!splitPath[2]) {
      return firstLevelPage;
    }
    return `${firstLevelPage}_DETAIL`; // detail page
  }
  return undefined; // ???
};

export const getDate = (date, format) => {
  let _date = date;
  if (!_date) {
    _date = new Date();
  }
  switch (format) {
    case 'date':
      return moment(_date).format('L');
    case 'date-time':
      return moment(_date).format('MMMM Do YYYY, h:mm:ss A');
    case 'timestamp':
      return moment(_date).format('LLL');
    case 'api-datetime':
      return moment(_date).format('YYYY-MM-DDThh:mm:ssZ');
    case 'short-timestamp':
    default:
      return moment(_date).format('L LTS');
  }
};

// Gets range from start of day on start date to end of day on end date
export const getDateRangeDateTime = (date, offset) => {
  return offset === 'end'
    ? moment(date).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ')
    : moment(date).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ');
};

export const apiDateToString = (iso8601Str, format) => {
  if (!iso8601Str) return '';
  const dateObj = moment(iso8601Str).toDate();
  return getDate(dateObj, format);
};

// API's Datetime Formatting
export const dateToApiDateTime = date => {
  return getDate(date, 'api-datetime');
};

export const getCurrentTimeWithOffset = offset => {
  return Date.now() + offset;
};

export const stringToArray = (str, char = ',') => {
  return str.split(char);
};
/** seconds to MM:SS.S string */
export const secondsToDuration = (seconds, trim = false, decimals = 0) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);
  const secs = seconds - hours * 3600 - minutes * 60;
  // const fsecs = seconds - hours * 3600 - minutes * 60 - secs;
  const h = hours.toString().padStart(2, '0');
  const m = minutes.toString().padStart(2, '0');
  // Handle formatting secs to show decimals or not
  const formatSeconds = () => {
    if (decimals && secs % 1 !== 1) {
      // pad for the decimal place
      return `${secs.toFixed(decimals).padStart(3 + decimals, '0')}`;
    } else {
      return `${Math.floor(secs).toString().padStart(2, '0')}`;
    }
  };
  const s = formatSeconds();
  if (trim) {
    if (hours > 0) {
      return `${h}:${m}:${s}`;
    }
    if (minutes > 0) {
      return `${m}:${s}`;
    }
    return `${s}`;
  }
  return `${h}:${m}:${s}`;
};
// Constants
export const REQUIRED = -1;
export const HIDE = 0;
export const SHOW = 1;

export const downloadFile = async (filename, url) => {
  const file = await JSZipUtils.getBinaryContent(url);
  const blob = new Blob([file]);
  saveAs(blob, filename);
};
export const displayOption = (item, options, placeholder = '--') => {
  const option = options.find(option => option.value === item);
  if (option) {
    return option.label;
  }
  return placeholder;
};

// Truncates a string to a maximum visible length
// str: the string to truncate
// maxVisLength: the maximum number of characters to show
// location: where to truncate. 'end' truncates to the end of the string, 'mid' truncates from the middle of the string.

export function truncate(str, maxVisLength, location = 'end') {
  if (str?.length > maxVisLength) {
    if (location === 'mid') {
      const sectionLength = Math.floor(str.length / 4); // get into roughly quarters to get small sections
      return str.substring(0, sectionLength) + '...' + str.substring(str.length - sectionLength - 1, str.length);
    }
    return str.substring(0, maxVisLength) + '...';
  }
  return str;
}

export const compareFilterMapOptions = (key, filterMap) => {
  if (key in filterMap) {
    return filterMap[key].toLowerCase();
  }
};

// remove illegal characters from a filename
export const cleanUpFileString = str =>
  str
    ? str
        .replace(/null|undefined|[\\?%*:|"<>]|/g, '')
        .replace(/\//g, '-')
        .replace(/(\[\])/g, '')
    : str;

export const removeQuotes = str => {
  if (typeof str === 'number') {
    str = str.toString();
  }
  if (!str) {
    return '';
  }
  if (typeof str !== 'string') {
    console.warn('unexpected type - expected string', str);
    return '';
  }
  return str.replace(/"/g, '');
};

export const noneOption = '-- None --';

export const setTableParamStr = (location, size) => {
  if (!location.search && size) {
    return `?limit=${size}&offset=0`;
  }
  return location.search;
};

// avoids duplicate queries
export const cleanQueryString = str => toQueryString({ ...queriesFromString(str) });

export const markdownLink = (label, url, hasAccess = false) => (hasAccess ? `[${label}](${url})` : label);

export const updateQueryStringOffset = (q, offset) => {
  let query;
  // check for next/previous url vs. query object from store
  if (q && typeof q === 'string') {
    query = queriesFromString(`?${q.split('?')[1]}`);
  } else {
    query = q;
  }

  try {
    return { ...query, ...(offset !== undefined && { offset: offset }) };
  } catch {
    return {};
  }
};

export const oneDayAgo = () => new Date().getTime() - 24 * 60 * 60 * 1000;

export const removeHTMLTags = val => {
  if (typeof val === 'number') {
    return val.toString();
  }
  if (typeof val === 'string') {
    return val.replace(/<[^>]*>?/gm, '');
  }
  return '';
};

/**
 * @param {Number} seconds
 * @return {String} "HH:MM:SS"
 */
export const formatSourceMediaTimestamp = seconds => {
  if (!seconds) return '00:00:00';

  const date = new Date(1970, 0, 1);
  date.setSeconds(seconds);

  return date.toTimeString().slice(0, 8);
};

// https://stackoverflow.com/questions/9640266/convert-hhmmss-string-to-seconds-only-in-javascript
export const convertToSeconds = timeString => {
  const a = timeString.split(':'); // split it at the colons
  const seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
  return seconds;
};
