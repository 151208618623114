// lib
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Grid, Tabs, Tab, Divider } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

// store
import { getProjects, getAllProjects } from '../../store/features/projectsActions';
import { getAllDefects } from '../../store/features/defectsActions';
import { getAllInspectionMedia } from '../../store/features/inspectionMediaActions';
import { getAllChecklistTasks } from '../../store/features/checklistTasksActions';
import { getAllMeasurements } from '../../store/features/measurementsActions';
import { getAllChecklists } from '../../store/features/checklistsActions';

// components
import ProjectOverview from './ProjectOverview';
import Loading from '../shared/displays/Loading';
import DefectsTable from '../defects/DefectsTable';
import Error from '../shared/displays/Error';
import StyledLink from '../shared/StyledLink';
import TabPanel from '../shared/TabPanel';
import AssetConditionHistory from './AssetConditionHistory';
import ProjectHistory from './ProjectHistory';
import ProjectToolbar from './ProjectToolbar';
import ProjectMediaGallery from './ProjectMediaGallery';
import ProjectScope from './ProjectScope';
import WatchersTable from './WatchersTable';
import MeasurementsTable from '../measurements/MeasurementsTable';
import ChecklistsTable from '../checklists/ChecklistsTable';
import Bookmarks from '../shared/bookmarks/Bookmarks';
import ProjectMap from './ProjectMap';

// utils
import { isEmpty } from '../../utilities/objects';
import { getProjectIsReadOnly, inspectionEnabled } from '../../utilities/projects';
import { usePermissions, useFeatureFlags, useTableViews, useTableViewSettings } from '../../hooks/settingsHooks';
import { tableViews } from '../../utilities/tables';
import { shouldFetchData } from './projectsShared';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2, 'auto'),
    padding: theme.spacing(2),
    marginTop: theme.spacing(12),
    textAlign: 'left',
  },
  embeddedTableStyle: {
    margin: theme.spacing(0, 4),
  },
  textWarning: {
    margin: theme.spacing(1, 2),
    color: theme.palette.primary.main,
  },
  tabPanel: {
    marginTop: 0,
  },
  bookmarks: {
    margin: theme.spacing(2, 0, -2, 5.5),
  },
}));

const getProjectChildrenControls = (project, hasProjectCreate, hasProjectChildren) => {
  const displayProjectChildren =
    hasProjectChildren &&
    (project.type?.project_children_mode === 'FIXED' || project.type?.project_children_mode === 'DYNAMIC');
  const addProjectChildren =
    project.status !== 'PUBLISHED' && hasProjectCreate && project.type?.project_children_mode === 'DYNAMIC';
  return { displayProjectChildren, addProjectChildren };
};

const getProjectMonitoringMode = project => {
  const monitoringMode = project.type?.monitoring_mode || 'DISABLED';

  const isMonitoringModeDisabled = monitoringMode === 'DISABLED' || project.asset?.type?.cml_mode === 'DISABLED'; // disabled monitoringMode should hide measurements table but have extra check here

  return { monitoringMode, isMonitoringModeDisabled };
};

const ProjectDetail = () => {
  const dispatch = useDispatch();
  const { id, tab: selectedTab = 'overview' } = useParams();

  const relatedChecklistTasksQuery = { project: id };
  const history = useHistory();
  const location = useLocation();
  const [selectedReport, setReport] = useState('');

  const { hasConfigurationManagementBuild, hasChecklistEdit, hasProjectManageAll, hasProjectCreate } = usePermissions();
  const {
    hasAssetCondition,
    hasBookmarks,
    hasConditionMonitoring,
    hasProjectTasks,
    hasProjectChildren,
    hasProjectReportInstance,
    hasProjectSecondaryForms,
    hasProjectMap,
  } = useFeatureFlags();

  const { data, loading, error, currentAssetType } = useSelector(state => {
    const { data, loading, error } = state.projects.each;
    const currentAssetType = data.asset?.type || {};

    return {
      data,
      loading,
      error,
      currentAssetType,
    };
  });

  const { displayProjectChildren } = getProjectChildrenControls(data, hasProjectCreate, hasProjectChildren);
  const isReadOnly = getProjectIsReadOnly(data.status, hasChecklistEdit);
  const { monitoringMode, isMonitoringModeDisabled } = getProjectMonitoringMode(data);
  const showCmlWarning = data?.type?.monitoring_mode !== 'DISABLED' && currentAssetType.cml_mode === 'DISABLED';

  const trackTasks = hasProjectTasks && data.type?.track_tasks;

  const overviewPermissions = {
    displayProjectChildren,
    enableReportsTable: hasProjectReportInstance,
    hasChecklistEdit,
    isReadOnly,
    trackTasks,
  };

  const { query: inspectionMediaQuery } = useSelector(state => state.inspectionMedia.all);
  const {
    checklistsTableViewKey,
    defectsTableViewKey,
    inspectionMediaTableViewKey,
    projectsTableViewKey,
    measurementsTableViewKey,
  } = tableViews;
  const { pageSize: mediaTableSize } = useTableViews(inspectionMediaTableViewKey);
  const { pageSize: measurementsTableSize } = useTableViews(measurementsTableViewKey);

  const defaultProjectChildQuery = { parent: id };
  const defaultDefectsQuery = { project: id };
  const defaultChecklistsQuery = { project: id };
  const defaultInspectionMediaQuery = { limit: mediaTableSize, project: id, hidden: false };
  const defaultMeasurementsQuery = { limit: measurementsTableSize, project: id };

  const {
    tableQuery: storedProjectsQuery,
    embeddedQuery: projectsEmbeddedQuery,
    page: projectsPage,
    views: projectsView,
  } = useTableViewSettings(projectsTableViewKey, defaultProjectChildQuery);
  const {
    tableQuery: storedDefectsQuery,
    embeddedQuery: defectsEmbeddedQuery,
    page: defectsPage,
    views: defectsView,
  } = useTableViewSettings(defectsTableViewKey, defaultDefectsQuery);
  const {
    tableQuery: storedChecklistsQuery,
    // embeddedQuery: checklistsEmbeddedQuery,
    page: checklistsPage,
    views: checklistsView,
  } = useTableViewSettings(checklistsTableViewKey, defaultChecklistsQuery);
  const {
    tableQuery: storedMeasurementsQuery,
    page: measurementsPage,
    views: measurementsView,
  } = useTableViewSettings(measurementsTableViewKey, defaultMeasurementsQuery);

  const parentIdQuery = `parent_id=${id}`; // query string used to route push

  const classes = useStyles();
  const refresh = ({ skipRefreshInspectionMedia } = {}) => {
    const isNewProject = inspectionMediaQuery['project'] !== defaultInspectionMediaQuery['project'];
    const mediaQuery = isNewProject || !inspectionMediaQuery ? defaultInspectionMediaQuery : inspectionMediaQuery;

    dispatch(getProjects(id));
    if (!skipRefreshInspectionMedia) {
      dispatch(getAllInspectionMedia(mediaQuery));
    }
  };

  useEffect(() => {
    // While routing to and from children of this component, don't pull from the api.
    // If routing from somewhere else, run the dispatches.
    if (shouldFetchData(location, id) || isEmpty(data) || data.id.toString() !== id) {
      refresh();
    }
  }, [id, defaultDefectsQuery.project, defaultInspectionMediaQuery.project]); // eslint-disable-line

  // after the project data is loaded, load the other data that is conditionally loaded
  useEffect(() => {
    if (inspectionEnabled(data)) {
      dispatch(getAllDefects(storedDefectsQuery));
    }
    if (trackTasks) {
      dispatch(getAllChecklistTasks(relatedChecklistTasksQuery));
    }
    if (monitoringMode !== 'DISABLED') {
      dispatch(getAllMeasurements(defaultMeasurementsQuery));
    }
    if (displayProjectChildren) {
      dispatch(getAllProjects(storedProjectsQuery));
    }
    // feature flag
    dispatch(getAllChecklists({ project: id, is_project_primary: false }));
  }, [data]); // eslint-disable-line

  const defectTableChangeHandler = query => {
    const updatedQuery = { ...defectsEmbeddedQuery, ...query };
    dispatch(getAllDefects(updatedQuery));
  };
  const mediaTableChangeHandler = query => {
    const updatedQuery = { ...defaultInspectionMediaQuery, ...query };
    dispatch(getAllInspectionMedia(updatedQuery));
  };
  const projectTableChangeHandler = query => {
    const updatedQuery = { ...projectsEmbeddedQuery, ...query };
    dispatch(getAllProjects(updatedQuery));
  };
  const measurementsTableChangeHandler = query => {
    const updatedQuery = { ...defaultMeasurementsQuery, ...query };
    dispatch(getAllMeasurements(updatedQuery));
  };
  const checklistsTableChangeHandler = query => {
    const updatedQuery = { ...defaultChecklistsQuery, ...query };
    dispatch(getAllChecklists(updatedQuery));
  };

  // tab render functions
  const renderOverview = () => (
    <ProjectOverview
      id={id}
      data={data}
      loading={loading}
      error={error}
      overviewPermissions={overviewPermissions}
      refresh={refresh}
      reportProps={{
        setReport,
        selectedReport,
      }}
      projectTableProps={{
        projectTableChangeHandler,
        storedProjectsQuery,
        parentIdQuery,
        projectsPage,
        projectsView,
      }}
    />
  );
  const renderFindings = () => (
    <DefectsTable
      embedded
      isProject
      tableChangeHandler={defectTableChangeHandler}
      styleProjectDetail={classes.embeddedTableStyle}
      queryParamObj={storedDefectsQuery}
      isReadOnly={isReadOnly}
      page={defectsPage}
      views={defectsView}
      filename={`${data.name} Findings`}
    />
  );
  const renderMedia = () => (
    <ProjectMediaGallery
      id={data.id}
      mediaCount={data.media_count}
      project={data}
      isReadOnly={isReadOnly}
      tableChangeHandler={mediaTableChangeHandler}
      queryParamObj={inspectionMediaQuery}
      pageSize={mediaTableSize}
    />
  );
  const renderMeasurements = () => (
    <MeasurementsTable
      embedded
      title={'Measurements'}
      projectId={data.id}
      styleProjectDetail={classes.embeddedTableStyle}
      isReadOnly={isReadOnly}
      filename={`${data.name} Measurements`}
      tableChangeHandler={measurementsTableChangeHandler}
      // pageSize={measurementsTableSize}
      page={measurementsPage}
      views={measurementsView}
      queryParamObj={storedMeasurementsQuery}
      hideProject
    />
  );
  const renderWatchersAndHistory = () => (
    <>
      <ProjectHistory statusHistory={data.status_history} />
      {data.type?.affects_asset_condition && hasAssetCondition && (
        <AssetConditionHistory history={data.asset_condition_history} />
      )}
      {hasProjectManageAll && (
        <WatchersTable
          embedded
          title="Watchers"
          styleProjectDetail={classes.embeddedTableStyle}
          clientData={{ clientData: data?.watchers, projectId: id }}
        />
      )}
    </>
  );

  const renderFormsTable = () => {
    return (
      <ChecklistsTable
        embedded
        projectDetail
        title="Forms"
        tableChangeHandler={checklistsTableChangeHandler}
        queryParamObj={storedChecklistsQuery}
        filename={`${data.name} Forms`}
        page={checklistsPage}
        views={checklistsView}
        createQuery={{ project_id: data.id, asset_id: data?.asset?.id }}
        isReadOnly={data.status === 'PUBLISHED'}
      />
    );
  };

  const renderMap = () => {
    return <ProjectMap project={data} />;
  };

  const showScope = data.scope !== null;

  const tabs = [
    {
      value: 'overview',
      label: 'Overview',
      Component: renderOverview(),
      show: true,
    },
    {
      value: 'scope',
      label: 'Scope',
      Component: <ProjectScope project={data} />,
      show: showScope,
    },
    {
      value: 'map',
      label: 'Map',
      Component: renderMap(),
      show: hasProjectMap,
    },
    {
      value: 'findings',
      label: 'Findings',
      Component: renderFindings(),
      show: inspectionEnabled(data),
    },
    {
      value: 'media',
      label: 'Media',
      Component: renderMedia(),
      show: true,
    },
    {
      value: 'measurements',
      label: 'Measurements',
      Component: renderMeasurements(),
      show: hasConditionMonitoring && !isMonitoringModeDisabled,
    },
    {
      value: 'forms',
      label: 'Forms',
      Component: renderFormsTable(),
      show: hasProjectSecondaryForms,
    },
    {
      value: 'other',
      label: 'Other',
      Component: renderWatchersAndHistory(),
      show: true,
    },
  ];

  let readyToRender = false;

  const handleTabChange = (event, value) => {
    history.push(`/projects/${id}/` + value);
  };

  switch (selectedTab) {
    case 'overview':
    case 'scope':
    case 'findings':
    case 'media':
    case 'measurements':
    case 'forms':
    case 'map':
    case 'other':
      readyToRender = true;
      break;
    default:
      history.replace(`/projects/${id}`);
  }

  // the Tabs component doesn't handle value=undefined
  if (!readyToRender) return <></>;

  if (loading) {
    return <Loading />;
  }
  if (isEmpty(data) && !error) {
    return <Loading />;
  }

  if (error) return <Error error={error} style={{ marginTop: '56px', fontSize: '35px', lineHeight: 'normal' }} />;

  const showWarning =
    (data.type && !data.type.defect_profile && data.type.layer_profile) ||
    (!data.type.layer_profile && data.type.defect_profile);

  const warningMessage = data => {
    let missing;
    if (!data.type.defect_profile && data.type.layer_profile) {
      missing = 'Finding Profile';
    }
    if (!data.type.layer_profile && data.type.defect_profile) {
      missing = 'Location Layer Profile';
    }
    return (
      <div className={classes.textWarning}>
        {showWarning ? (
          <>
            <p>WARNING - This project type requires a {missing} to enable Visual Inspector.</p>
            {hasConfigurationManagementBuild && (
              <StyledLink to={`/project-types/${data.type.id}`} value="View Project Type" />
            )}
          </>
        ) : (
          <></>
        )}
        {showCmlWarning ? (
          <>
            <p>WARNING - Asset Type {`"${currentAssetType.name}"`} CML Mode is Disabled.</p>
            {hasConfigurationManagementBuild && (
              <StyledLink to={`/asset-types/${currentAssetType.id}`} value="View Asset Type" />
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <ProjectToolbar
        id={data.id}
        title={`${data.name}`}
        path={data.path_cache}
        project={data}
        refresh={refresh}
        isReadOnly={isReadOnly}
      />
      {(showWarning || showCmlWarning) && <div className={classes.textWarning}>{warningMessage(data)}</div>}
      <Grid container>
        {hasBookmarks && (
          <Grid item xs={12} className={classes.bookmarks}>
            <Bookmarks id={data.id} />
          </Grid>
        )}

        <Grid item xs={12}>
          <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary">
            {tabs
              .filter(tab => tab.show)
              .map(tab => (
                <Tab
                  id={`project-tab-${tab.value}`}
                  aria-controls={`project-tabpanel-${tab.value}`}
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                />
              ))}
          </Tabs>
          <Divider className={`${classes.divider} ${classes.tabPanel}`} />
          {tabs.map(tab => (
            <TabPanel key={tab.value} value={tab.value} selectedTab={selectedTab} name="project">
              <Grid item className={classes.table}>
                {tab.Component}
              </Grid>
            </TabPanel>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default ProjectDetail;
