import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { FormControl, FormGroup, FormControlLabel, Switch, InputLabel, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { camelToSnake, apiDateToString, getDateRangeDateTime, getDate } from '../../../utilities/strings';

const useStyles = makeStyles(theme => ({
  inputLabel: {
    margin: theme.spacing(-3, 0),
  },
}));
const DateRangeFilter = props => {
  const { title, filter, onChange, index, column, update: updateFilter, isDate, disableFuture } = props;
  const classes = useStyles();

  const handleChange = event => {
    const { name, checked } = event.target;
    const date = new Date();
    if (name.match(/after/i)) {
      const afterValue = checked ? (isDate ? getDate(date, 'date') : getDateRangeDateTime(date)) : undefined;
      filter[0] = afterValue;
    }
    if (name.match(/before/i)) {
      const beforeValue = checked ? (isDate ? getDate(date, 'date') : getDateRangeDateTime(date)) : undefined;
      filter[1] = beforeValue;
    }
    onChange(filter, index, column);
    updateFilter(filter);
  };

  return (
    <FormControl>
      <InputLabel shrink className={classes.inputLabel}>
        {title}
      </InputLabel>
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch size="small" checked={!!filter[0]} onChange={handleChange} name="dateAfter" color="primary" />
          }
          label="Start"
        />
        {!!filter[0] && (
          <DatePicker
            disableToolbar
            disableFuture={disableFuture}
            autoOk
            format="yyyy-MM-dd"
            margin="normal"
            renderInput={props => <TextField {...props} />}
            id={`${camelToSnake(title)}-date-after`}
            value={apiDateToString(filter[0])}
            onChange={date => {
              const dateString = isDate ? getDate(date, 'date') : getDateRangeDateTime(date);
              onChange([dateString, filter[1]], index, column);
              updateFilter([dateString, filter[1]]);
            }}
          />
        )}
        <FormControlLabel
          control={
            <Switch size="small" checked={!!filter[1]} onChange={handleChange} name="dateBefore" color="primary" />
          }
          label="End"
        />
        {!!filter[1] && (
          <DatePicker
            disableToolbar
            disableFuture={disableFuture}
            format="yyyy-MM-dd"
            margin="normal"
            id={`${camelToSnake(title)}-date-before`}
            value={apiDateToString(filter[1])}
            renderInput={props => <TextField {...props} />}
            onChange={date => {
              const dateString = isDate ? getDate(date, 'date') : getDateRangeDateTime(date, 'end');
              onChange([filter[0], dateString], index, column);
              updateFilter([filter[0], dateString]);
            }}
          />
        )}
      </FormGroup>
    </FormControl>
  );
};
DateRangeFilter.defaultProps = {
  title: 'Filter',
  isDate: false,
  disableFuture: true,
};

DateRangeFilter.propTypes = {
  title: PropTypes.string,
  index: PropTypes.number.isRequired,
  column: PropTypes.object.isRequired,
  filter: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  isDate: PropTypes.bool,
  disableFuture: PropTypes.bool,
};

export default DateRangeFilter;
