const REQUIRED = 'required';

export const scopeModes = {
  DEFAULT: 'DEFAULT',
  SELECT: 'SELECT',
};

/**
 * fieldOrder
 *
 * An object where the keys define the sections of the form.   Each section requires the key display (string) and
 * fields (array of strings). Fields are the api keys (in snake case) for each field that will be displayed in the form.
 * Any fields that are never shown should not be added to this array.
 *
 * @return {object}
 */
export const fieldOrder = {
  project_info: {
    display: 'Project Info',
    fields: ['name', 'description', 'type', 'asset', 'status', 'asset_condition'],
  },
  assignment: {
    display: 'Work Assignment',
    fields: ['management_company', 'crews'],
  },
  scope: {
    display: 'Scope',
    fields: ['scope.description', 'scope.selected_cmls'],
  },
  schedule: {
    display: 'Schedule',
    fields: ['start', 'end', 'historical_project', 'work_done_on', 'priority'],
  },
  status: {
    display: 'Status',
    fields: ['parent', 'event', 'created_on', 'updated_on'],
  },
  extra: {
    display: 'Extra',
    fields: ['labels', 'labels_by_key'],
  },
};

// fieldOrder object for <ProjectBulkShareForm />
export const bulkFieldOrder = {
  ...fieldOrder,
  share: {
    display: 'Share',
    fields: ['unshare', 'users', 'notify', 'note'],
  },
};
/**
 *  removeField
 *
 *  removes a field depending on if a user is creating, updating or viewing.
 *
 *  @param {string} state - enum for creating, updating or viewing ['CREATE', 'UPDATE', 'VIEW']
 *  @param {string} item - api key (in snake case) to check whether it should be hidden.
 *  @return {boolean} - returns true if the item will not be shown
 */
export const removeField = state => item => {
  let fields = [];
  switch (state) {
    case 'CREATE':
      fields = [
        'parent',
        'event',
        'created_on',
        'updated_on',
        'status',
        'asset_condition',
        'reports',
        'checklist_instances',
        'unshare',
        'users',
        'note',
        'notify',
      ];
      break;
    case 'UPDATE':
      fields = [
        'asset_condition',
        'type',
        'asset',
        'status',
        'created_on',
        'updated_on',
        'reports',
        'checklist_instances',
        'parent',
        'event',
        'unshare',
        'users',
        'note',
        'notify',
      ];
      break;
    case 'BULK-UPDATE':
      fields = [
        'asset_condition',
        'type',
        'asset',
        'status',
        'created_on',
        'updated_on',
        'reports',
        'checklist_instances',
        'parent',
        'event',
        'unshare',
        'users',
        'notify',
        'note',
      ];
      break;
    case 'BULK-SHARE':
      fields = [
        'name',
        'description',
        'type',
        'asset',
        'status',
        'asset_condition',
        'management_company',
        'crews',
        'scope.description',
        'scope.selected_cmls',
        'start',
        'end',
        'historical_project',
        'work_done_on',
        'priority',
        'parent',
        'event',
        'created_on',
        'updated_on',
      ];
      break;
    case 'VIEW':
      fields = ['asset_condition', 'status', 'reports', 'checklist_instances'];
      break;
    default:
      return false;
  }
  return fields.includes(item);
};

/**
 *
 * @param {*} values
 * @param {*} item
 * @param {*} options (same as `hideField`)
 *
 * @returns {Boolean}
 */
const _hideSelectedCmls = (values, item, options = {}) => {
  const { hasProjectScopes, selectedCmls, update, projectTypeOptions } = options;

  if (!hasProjectScopes) {
    // if feature disabled - always hide
    return true;
  }
  if (update) {
    // updating selected_cmls is not supported
    return true;
  }
  if (selectedCmls?.length > 0) {
    return false;
  }

  const selectedProjectType = projectTypeOptions?.find(pt => pt.value === values.type);
  if (!selectedProjectType) {
    // hide until they've selected a valid project type
    return true;
  }

  const selectedCMLsRequired = selectedProjectType.projectScopeSettings?.selected_cmls === REQUIRED;

  return !selectedCMLsRequired;
};

/**
 * hideField
 *
 * This function is for hiding a field depending on the value of another field.
 *
 * @param {Object} values - the values of all the fields in the form.
 * @param {String} item - api key (in snake case) to check whether it should be removed.
 * @param {Object} options
 * @param {Boolean} options.hasProjectPriority - feature flag
 * @param {Boolean} options.hasProjectScopes - feature flag
 * @param {Boolean} options.update - true if updating an existing project
 * @param {Array<Object>} options.projectTypesOptions - from useProjectTypes hook
 * @param {Array<Number>} options.selectedCmls - cml ids (passed in via URL params or CSV upload)
 * @param {Boolean} options.hasProjectEditCompany - permission
 *
 * @return {boolean} - returns true if the item will not be shown
 */
export const hideField = (values, item, options = {}) => {
  const {
    hasProjectPriority,
    hasProjectScopes,
    selectedCmls,
    update,
    projectTypeOptions,
    hasProjectEditCompany,
    hasProjectLabels,
  } = options;
  switch (item) {
    case 'labels': // hide labels in DetailView, we want to see labels_by_key
      return true;
    case 'priority':
      return !hasProjectPriority;
    case 'work_done_on':
      if (!update && !values.historical_project) return true;
      break;
    case 'scope.description': // TODO - enable description for selected_findings ??
    case 'scope.selected_cmls':
      return _hideSelectedCmls(values, item, { hasProjectScopes, selectedCmls, update, projectTypeOptions });
    case 'note':
      if (!values.notify) return true;
      if (values.unshare) return true;
      break;
    case 'notify':
      if (values.unshare) return true;
      break;
    case 'management_company':
      if (!hasProjectEditCompany) return true;
      break;
    case 'labels_by_key':
      return !hasProjectLabels;
    default:
      return false;
  }
};

export const shouldFetchData = (location, id) => {
  if (location.state && location.pathname) {
    // If navigating within the project and to the Visual Inspector, don't reload
    //  _should_ reload for checklists, as the percent complete may have changed
    if (location.state.back.pathname.startsWith(`/projects/${id}`) && !location.pathname.includes('checklists')) {
      return false;
    }
    if (location.state.back.pathname.startsWith('/projects/new')) {
      return true;
    }
    if (location.state.back.pathname.startsWith('/findings')) {
      // set this to true for now.
      // the defects reducer needs to separate updating data and dataAll for this to work.
      return true;
    }
    if (location.state.back.pathname.startsWith('/inspection-media')) {
      // set this to true for now.
      // the inspection-media reducer needs to separate updating data and dataAll for this to work.
      return true;
    }
  }
  return true;
};
