export const SNACKBAR_OPEN = 'SNACKBAR_OPEN';
export const SNACKBAR_CLOSE = 'SNACKBAR_CLOSE';

export const openSnackbar = (message, variant = 'info', closeSnackbar = null, duration = 10000, action = null) => ({
  type: SNACKBAR_OPEN,
  open: true,
  message,
  variant,
  closeSnackbar,
  duration,
  action,
});

export const closeSnackbar = () => ({
  type: SNACKBAR_CLOSE,
});
