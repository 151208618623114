import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Alert } from '@mui/material';
import { getProjectTypes } from '../../store/features/projectTypesActions';
import { fieldOrder, removeField, hideField } from './projectTypesShared';
import { apiDateToString, displayOption, jsonKeyToLabel } from '../../utilities/strings';
import CommonFormView from '../shared/form/CommonView';
import { useFeatureFlags } from '../../hooks/settingsHooks';
import { monitoringModeOptions } from '../../api/features/constants';

import { ChecklistTemplateLink, FindingProfileLink, LocationLayerProfileLink } from '../shared/links/InternalLinks';

const ProjectTypes = () => {
  const id = useParams().id;
  const dispatch = useDispatch();
  const featureFlags = useFeatureFlags();
  useEffect(() => {
    dispatch(getProjectTypes(id));
  }, [dispatch, id]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data, loading, error } = useSelector(state => state.projectTypes.each);
  const disabled = 'DISABLED';

  const cmlWarnings = useMemo(() => {
    if (data.monitoring_mode === disabled) {
      return [];
    }

    const warnings = [];
    for (const assetType of data.asset_types || []) {
      if (assetType.cml_mode === disabled) {
        warnings.push(`Asset Type '${assetType.name}' CML Mode is Disabled.`);
      }
    }
    return warnings;
  }, [data.monitoring_mode, data.asset_types]);

  const formSettings = {
    fieldOrder,
    removeField: removeField('VIEW'),
    hideField: (values, item) => {
      return hideField(values, item, featureFlags);
    },
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      // cast to string because booleans do not show up in the field
      let displayLabel = jsonKeyToLabel(key); // eslint-disable-line prefer-const
      let displayValue = '';
      if (data[key] != null) {
        switch (key) {
          case 'asset_types':
          case 'report_types':
            displayValue = data[key].map(item => item.name).join(', ');
            break;
          case 'defect_profile':
            displayLabel = 'Finding Profile';
            fieldSettings[key] = {
              type: 'component',
              fieldProps: {
                label: displayLabel,
                value: () => (
                  <p>
                    <FindingProfileLink {...data[key]} />
                  </p>
                ),
              },
            };
            return;
          case 'checklist_template':
            fieldSettings[key] = {
              type: 'component',
              fieldProps: {
                label: displayLabel,
                value: () => (
                  <p>
                    <ChecklistTemplateLink {...data[key]} />
                  </p>
                ),
              },
            };
            return;
          case 'layer_profile':
            fieldSettings[key] = {
              type: 'component',
              fieldProps: {
                label: displayLabel,
                value: () => (
                  <p>
                    <LocationLayerProfileLink {...data[key]} />
                  </p>
                ),
              },
            };
            return;
          case 'monitoring_mode':
            displayValue = displayOption(data[key], monitoringModeOptions);
            break;
          case 'updated_on':
          case 'created_on':
            displayValue = apiDateToString(data[key], 'date');
            break;
          default:
            displayValue = data[key].toString();
        }
      }
      fieldSettings[key] = {
        type: 'text',
        fieldProps: {
          label: displayLabel,
          value: displayValue,
        },
      };
    });
  }
  return (
    <>
      {cmlWarnings.length > 0 ? (
        cmlWarnings.map((warning, i) => (
          <Alert
            severity="error"
            key={i}
            sx={{
              marginTop: '18px',
              display: 'inline-flex',
              width: '90%',
            }}>
            {warning}
          </Alert>
        ))
      ) : (
        <></>
      )}
      <CommonFormView
        title={'Project Type Details'}
        values={data}
        fieldSettings={fieldSettings}
        formSettings={formSettings}
        editLink={`/project-types/${id}/edit`}
        loading={loading}
        error={error}
      />
    </>
  );
};

export default ProjectTypes;
