import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { jsonKeyToLabel, apiDateToString, getDate, dateToApiDateTime } from '../../utilities/strings';
import { updateProjectsBulk } from '../../store/features/projectsActions';
import { getAllCrews } from '../../store/features/crewsActions';
import { getAllCompanies } from '../../store/features/companiesActions';
import { useCrews, useCompanies } from '../../hooks/optionsHooks';
import CommonBulkForm from '../shared/form/CommonBulkForm';
import { fieldOrder, removeField, hideField } from './projectsShared';
import { useFeatureFlags, usePermissions } from '../../hooks/settingsHooks';
import Loading from '../shared/displays/Loading';

const useStyles = makeStyles(theme => ({
  message: {
    color: 'red',
  },
  center: {
    textAlign: 'center',
  },
}));

const ProjectsBulkEditForm = props => {
  const { projects } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  const [submittedValues, setSubmittedValues] = useState({});
  const crewOptions = useCrews();
  const companiesOptions = useCompanies();

  const { hasProjectEditCompany } = usePermissions();
  const { hasProjectPriority } = useFeatureFlags();

  const { data, loading, error, formError } = useSelector(state => {
    const { data, loading, error, formError } = state.projects.each;

    return {
      data,
      loading: loading || state.crews.all.loading || state.companies.all.loading,
      error,
      formError,
    };
  });

  useEffect(() => {
    dispatch(getAllCrews({ is_active: true }));
    if (hasProjectEditCompany) {
      dispatch(getAllCompanies({ role: 'SUBCONTRACTOR' }));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formSettings = {
    fieldOrder,
    removeField: removeField('BULK-UPDATE'),
    hideField: (values, item) => {
      return hideField(values, item, { hasProjectPriority, hasProjectEditCompany }, false);
    },
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      switch (key) {
        /* ---------- Required Fields ---------- */
        case 'name':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          break;

        /* ---------- Regular Fields ---------- */
        case 'description':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              multiline: true,
              rows: 3,
              maxRows: 10,
            },
          };
          break;
        case 'crews':
          fieldSettings[key] = {
            type: 'multi',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: 'crews',
              options: crewOptions,
              labelwidth: 100,
            },
          };
          break;
        case 'management_company':
          fieldSettings[key] = {
            type: 'autocomplete',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              options: companiesOptions,
              disabled: !hasProjectEditCompany,
              labelwidth: 150,
            },
          };
          break;
        case 'start':
        case 'end':
          fieldSettings[key] = {
            type: 'date',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              value: data[key] ? getDate(data[key], 'date') : '',
            },
          };
          break;
        case 'historical_project':
          fieldSettings[key] = {
            type: 'checkbox',
            cellProps: {
              xs: 12,
            },
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          break;
        case 'is_active':
          fieldSettings[key] = {
            type: 'checkbox',
            cellProps: {
              xs: 6,
            },
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          break;
        /* ----------  Disabled Fields ---------- */
        case 'created_on':
        case 'updated_on':
          fieldSettings[key] = {
            type: 'display',
            fieldProps: {
              disabled: true,
              label: jsonKeyToLabel(key),
              name: key,
              value: apiDateToString(data[key], 'date'),
            },
          };
          break;
        case 'work_done_on':
          if (data.historical_project) {
            fieldSettings[key] = {
              type: 'date',
              fieldProps: {
                label: jsonKeyToLabel(key),
                name: key,
              },
            };
          } else {
            fieldSettings[key] = {
              type: 'date',
              fieldProps: {
                label: jsonKeyToLabel(key),
                name: key,
                value: data.historical_project === false ? getDate(undefined, 'date') : '',
              },
            };
          }
          break;
        /* ----------  Default ---------- */
        default:
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
      }
    });
  }

  const title = 'Edit Projects';

  // The form cannot handle data coming in as objects.  Get id so select field can render the selected option
  // when new, check if we need to apply any values from the queries
  const initialValues = {};

  const onSubmit = changedValues => {
    const updatedValues = { ...changedValues };
    if ('work_done_on' in updatedValues)
      updatedValues['work_done_on'] = dateToApiDateTime(updatedValues['work_done_on']);
    setSubmittedValues(updatedValues);

    dispatch(updateProjectsBulk(projects.ids, updatedValues, location.pathname));
    props.onSubmit();
  };

  /**
   * Decorators are used for setting the values of other fields based off of a field.
   * Decorator format can be found here: src/components/shared/form/common.js
   */
  const decorators = [];

  /**
   *  Validations that are per field.
   *  Errors associated with the form are passed back through formError in Redux.
   */
  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        if (values[key] === submittedValues[key]) {
          errors[key] = value;
        } else {
          errors[key] = undefined;
        }
      }
    }
    return errors;
  };

  if (loading) {
    return <Loading />;
  }

  // To prevent users accessing through url '/edit'
  const permissionCallback = data => {
    if (data.status === 'PUBLISHED') {
      return { message: 'Published projects cannot be edited.', backLink: `/projects/${data.id}` };
    }
  };

  return (
    <div className={classes.root}>
      <CommonBulkForm
        update
        title={title}
        initialValues={initialValues}
        fieldSettings={fieldSettings}
        formSettings={formSettings}
        onSubmit={onSubmit}
        decorators={decorators}
        validate={validate}
        loading={loading}
        // dirty={dirty}
        error={error}
        keepDirtyOnReinitialize
        createPermissionName="hasProjectCreate"
        customToolbarProps={{ cancel: true }}
        updatePermissionName="hasProjectEdit"
        permissionCallback={() => permissionCallback(false, data)}
      />
    </div>
  );
};

ProjectsBulkEditForm.defaultProps = {
  projects: {},
};

ProjectsBulkEditForm.propTypes = {
  projects: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default ProjectsBulkEditForm;
