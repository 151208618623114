import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { Helmet } from 'react-helmet';
import { isEmpty } from '../../../utilities/objects';

import { getPageByPath } from '../../../store/getters/pagesActions';

import BasicLayout from '../../../routes/layouts/BasicLayout';
import SimpleLayout from '../../../routes/layouts/SimpleLayout';
import MainLayout from '../../../routes/layouts/MainLayout';

// pages
import IFramePage from './IFramePage';
import LookerIFramePage from './LookerIFramePage';
import MarkdownPage from './MarkdownPage';
import RBLXIFramePage from './RBLXIFramePage';

// headers
import RBLXHeader from '../../assessments/AssessmentHeader';

import NotFound from '../../../routes/NotFound';
import Loading from '../../shared/displays/Loading';
import Error from '../displays/Error';
import { PAGE_SOURCE_TYPES } from '../../../api/features/constants';

const useStyles = makeStyles(theme => ({
  errorMessage: {
    padding: theme.spacing(4),
  },
}));

const DynamicPage = props => {
  const { data, loading, error } = useSelector(state => {
    return state.pages;
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const path = `${location.pathname}?${location.search}`;
    dispatch(getPageByPath(path));
  }, [dispatch, location.pathname, location.search]);

  if (error) {
    return (
      <div className={classes.errorMessage}>
        <Error error={error} />
      </div>
    );
  }

  if (loading || isEmpty(data)) {
    return <Loading />;
  }

  const { IFRAME, LOOKER, MARKDOWN, RBLX } = PAGE_SOURCE_TYPES;

  const pageName = PAGE_SOURCE_TYPES[data.source_type] ? data.name : 'Page Not Found';

  const helmetTitle = (
    <Helmet>
      <title>{pageName} - HUVRdata</title>
    </Helmet>
  );

  switch (data.source_type) {
    case IFRAME:
      return (
        <MainLayout>
          {helmetTitle}
          <IFramePage data={data} />
        </MainLayout>
      );
    case LOOKER:
      return (
        <MainLayout>
          {helmetTitle}
          <LookerIFramePage data={data} />
        </MainLayout>
      );
    case MARKDOWN:
      return (
        <MainLayout>
          {helmetTitle}
          <MarkdownPage data={data} />
        </MainLayout>
      );
    case RBLX:
      return (
        <SimpleLayout customHeader={() => <RBLXHeader page={data} />}>
          {helmetTitle}
          <RBLXIFramePage data={data} />
        </SimpleLayout>
      );
    default:
      console.warn(`Unknown DynamicPage Source Type: ${data.source_type}`, data);
      return (
        <BasicLayout>
          <div className={classes.errorMessage}>
            {helmetTitle}
            <NotFound />
          </div>
        </BasicLayout>
      );
  }
};

export default DynamicPage;
