import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { getNotificationRules } from '../../store/features/notificationRulesActions';
import { getAllNotificationRuleSubscribers } from '../../store/features/notificationRuleSubscribersActions';

import Error from '../shared/displays/Error';
import Loading from '../shared/displays/Loading';
import NotificationRuleSubscribersTable from '../notificationRuleSubscribers/NotificationRuleSubscribersTable';
import NotificationRulesToolbar from './NotificationRulesToolbar';
import NotificationRulesView from './NotificationRulesView';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(7, 'auto'),
    padding: theme.spacing(2),
    textAlign: 'left',
  },
}));

const NotificationRulesDetail = () => {
  const id = useParams().id;
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(getNotificationRules(id));
    dispatch(getAllNotificationRuleSubscribers({ rule: id }));
  }, []); // eslint-disable-line

  const { data, loading, error } = useSelector(state => state.notificationRules.each);

  if (loading) {
    return <Loading />;
  }
  if (Object.keys(data).length === 0 && data.constructor === Object && !error) {
    return <Loading />;
  }
  if (error) return <Error error={error} />;

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Grid item className={classes.title}>
            <NotificationRulesToolbar id={id} data={data} />
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="column" alignItems="stretch">
        <Grid item>
          <NotificationRulesView data={data} loading={loading} error={error} />
        </Grid>
        <Grid item>
          <NotificationRuleSubscribersTable title="Subscribers" embedded hideRule />
        </Grid>
      </Grid>
    </div>
  );
};

export default NotificationRulesDetail;
