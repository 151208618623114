import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonIcon from '../shared/buttons/ButtonIcon';
import CmlsToolbarSelect from './CmlsTableToolbarSelect';
import Table from '../shared/table/Table';
import MultiSelectFilter from '../shared/table/MultiSelectFilter';
import { setTableSize, setTableView } from '../../store/settings/tableActions';
import { deleteCmls } from '../../store/features/cmlsActions';
import { openDialog } from '../../store/dialogActions';
import { AssetLink, ProjectLink } from '../shared/links/InternalLinks';
import StyledLink from '../shared/StyledLink';
import DeleteDialog from '../shared/Dialog/DeleteDialog';
import { setColumns } from '../shared/table/columns';
import TableImportToolbar from '../shared/table/TableImportToolbar';
import { customHeadLabelWithTooltipRenderer } from '../shared/displays/TableHeaderLabelTooltip';
import Description from '../shared/table/Description';
import { useFeatureFlags, usePermissions } from '../../hooks/settingsHooks';
import { useTopLevelAssets } from '../../hooks/assetHooks';
import ViewDisplayKeyValue from '../shared/form/ViewDisplayKeyValue';
import { compare } from '../../utilities/arrays';
import { apiDateToString, compareFilterMapOptions, queriesFromString } from '../../utilities/strings';
import { getCustomCSVData, tableViews } from '../../utilities/tables';
import useDateRangeColumnHook from '../../hooks/table/dateRangeColumnHook';
import ScopeToolbar from './ScopeToolbar';
import { scopeModes } from '../projects/projectsShared';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const CmlsTable = props => {
  const {
    title,
    embedded,
    createWithAsset,
    filename,
    queryParamObj,
    tableChangeHandler,
    serverSide,
    scopeData,
    scopeCount,
    isReadOnly,
    page,
    views,
    scopeMode,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { topLevelAssetOptions, topLevelAssetMap } = useTopLevelAssets();

  const queryFilters = queryParamObj;

  // Top Level Asset
  const queryAssetFilters = queryFilters?.asset ? [].concat(queryFilters.asset.toString().split(',')) : [];
  const [assetFilterList, setAssetFilterList] = useState(queryAssetFilters);

  const { loading, data, count } = useSelector(state => ({
    loading: state.cmls.all.loading,
    data: state.cmls.all.dataAll.results,
    count: state.cmls.all.dataAll.count,
  }));
  const { cmlsTableViewKey } = tableViews;
  const { hasAccessDisplay, hasCmlImport } = useFeatureFlags();

  const { hasAssetCreate } = usePermissions();

  /**
  how CML table data is populated will vary depending on use case
    - project scope cmls will be directly passed from parent component via scopeData prop
    - non-scope cmls will be populated via redux
   */
  const tableData = scopeData || data;
  const tableCount = scopeCount || count;

  /** @type {Column[]} */
  const columns = [
    {
      // This needs to be first and excluded so customBodyRender can access the id to create the link
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        filterType: 'textField',
        customBodyRender: (value, tableMeta) => {
          return <StyledLink to={`/cmls/${tableMeta.rowData[columns.findIndexByName['id']]}`} value={value} />;
        },
      },
    },
    {
      // excluded column to access asset id
      name: 'asset.id',
      options: {
        filter: false,
        label: 'Asset',
        display: 'excluded',
        download: false,
      },
    },
    {
      name: 'asset.asset_path',
      label: 'Asset Path',
      options: {
        filter: true,
        sort: true,
        filterType: 'textField',
        filterField: 'asset__asset_path_cache__path',
        sortField: 'asset__asset_path_cache__path,name', // sort by asset path and then name
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.rowData[columns.findIndexByName['asset.id']];
          return <AssetLink id={id} asset_path={value} />;
        },
      },
    },
    {
      name: 'descendants',
      label: 'Asset',
      options: {
        filter: true,
        filterType: 'custom',
        display: 'excluded',
        filterList: assetFilterList,
        download: false,
        customFilterListOptions: {
          // customize filter display chips
          render: values => {
            return values.map(v => {
              return topLevelAssetMap[v];
            });
          },
          // customize deleting filter display chip
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            setAssetFilterList(filterList[index]);
            return filterList;
          },
        },
        filterOptions: {
          names: topLevelAssetOptions,
          // format values in the multiselect input
          render: v => topLevelAssetMap[v],
          logic: (asset, filters) => {
            if (filters.length) return !filters.includes(asset);
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            const formatValue = item => {
              // format values in the multiselect menu
              return topLevelAssetMap[item];
            };

            const getComparable = value => compareFilterMapOptions(value, topLevelAssetMap);
            // Sort top level filter options
            filterData[index] = filterData[index].sort(compare(getComparable));
            return (
              <MultiSelectFilter
                title="Top Level Asset"
                filterList={filterList}
                localFilterList={assetFilterList}
                onChange={onChange}
                index={index}
                column={column}
                filterData={filterData}
                updateFilters={setAssetFilterList}
                formatValue={formatValue}
              />
            );
          },
        },
      },
    },
    {
      name: 'component_display',
      label: 'Component',
      options: {
        filterType: 'textField',
        sortField: 'component_display',
        display: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'access_display',
      label: 'Access',
      options: {
        filterType: 'textField',
        sortField: 'access_display',
        display: hasAccessDisplay ? true : 'excluded',
        filter: true,
        sort: true,
      },
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        filter: false,
        sort: true,
        filterType: 'textField',
        customBodyRender: value => {
          if (!value) return <></>;
          return <Description value={value} />;
        },
      },
    },
    {
      name: 'measurements_summary.base.value',
      label: 'Base',
      options: {
        customHeadLabelRender: customHeadLabelWithTooltipRenderer('Initial Measurement Value'),
        filter: true,
        sort: true,
        sortField: 'measurements_summary__base',
        filterType: 'textField',
      },
    },
    {
      name: 'measurements_summary.near.value',
      label: 'Near',
      options: {
        customHeadLabelRender: customHeadLabelWithTooltipRenderer('2nd to Last Measurement Value'),
        filter: true,
        sort: true,
        sortField: 'measurements_summary__near',
        filterType: 'textField',
      },
    },
    {
      name: 'measurements_summary.last.value',
      label: 'Last',
      options: {
        customHeadLabelRender: customHeadLabelWithTooltipRenderer('Most Recent Measurement Value'),
        filter: true,
        sort: true,
        sortField: 'measurements_summary__last',
        filterType: 'textField',
      },
    },
    {
      name: 'measurements_summary.last.collected_on',
      label: 'Last Inspection',
      options: {
        filter: false,
        sort: false,
        filterType: 'textField',
        customBodyRender: value => apiDateToString(value, 'date'),
      },
    },
    // filter column
    useDateRangeColumnHook({
      name: 'next_inspection_date',
      label: 'Next Inspection',
      queryParamObj,
      queryStartKey: 'next_inspection_date_after',
      queryEndKey: 'next_inspection_date_before',
      display: 'excluded',
      filter: !scopeData,
      download: false, // omit from CSV download
    }),
    // display only column
    {
      name: 'measurements_summary.next_inspection_date',
      label: 'Next Inspection Date',
      options: {
        filter: false,
        sort: true,
        sortField: 'measurements_summary__next_inspection_date',
        customBodyRender: value => apiDateToString(value, 'date'),
        customHeadLabelRender: () => 'Next Inspection', // override table header
      },
    },
    // filter column
    {
      name: 'next_inspection_reason',
      label: 'Next Inspection Reason',
      options: {
        filter: true,
        sort: false,
        filterType: 'textField',
        display: 'excluded',
        download: false, // omit from CSV download
      },
    },
    // display only column
    {
      name: 'measurements_summary.next_inspection_reason',
      label: 'Next Inspection Reason',
      options: {
        filter: false,
        sort: true,
        sortField: 'measurements_summary__next_inspection_reason',
      },
    },
    {
      name: 'planned_project',
      label: 'Planned Inspection',
      options: {
        // planned_project is a DRF SerializerMethodField on the backend, it cannot be filtered or sorted without more work
        filter: false,
        sort: false,
        customBodyRender: (value, info) => {
          if (!value) return <>--</>;
          return <ProjectLink id={value.id} name={value.name} />;
        },
        downloadBody: value => getCustomCSVData('simple', value, 'name'),
      },
    },
    {
      name: 'is_active',
      label: 'Is Active',
      options: {
        filter: true,
        sort: false,
        customBodyRender: value => (value ? 'True' : 'False'),
      },
    },
    {
      name: 'labels', // only used for CSV download
      label: 'Labels',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
        customBodyRender: value => <ViewDisplayKeyValue value={value} />,
        downloadBody: values => getCustomCSVData('labels', values),
      },
    },
    {
      name: 'labels_by_key',
      label: 'Labels',
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => <ViewDisplayKeyValue value={value} />,
        download: false, // download `labels` instead because those are the CML labels
      },
    },
    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        print: false,
        viewColumns: false,
        customBodyRender: (_, info) => {
          // 0 is the first element in column
          const id = info.rowData[columns.findIndexByName['id']];
          const name = info.rowData[columns.findIndexByName['name']];
          return (
            <>
              <ButtonIcon
                disabled={isReadOnly}
                history={history}
                icon={EditIcon}
                location={location}
                to={`/cmls/${id}/edit`}
              />
              <ButtonIcon
                disabled={isReadOnly}
                icon={DeleteIcon}
                onClick={() => {
                  dispatch(openDialog('Delete Cmls?', <DeleteDialog id={id} deleteAction={deleteCmls} name={name} />));
                }}
              />
            </>
          );
        },
      },
    },
  ];

  // handle columns display
  setColumns(columns, views);

  const getCreateCmlRoute = () => {
    if (createWithAsset) {
      const addRoute = `/cmls/new?${createWithAsset}`;
      return addRoute;
    }
    return '/cmls/new';
  };

  /** @type {TableOptions} */
  const options = {
    // if the column view changes, update redux with either 'add' or 'remove'
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setTableView(changedColumn, action, page, cmlsTableViewKey));
    },
    onChangeRowsPerPage: numberOfRows => {
      dispatch(setTableSize(numberOfRows, page, cmlsTableViewKey));
    },
    customToolbarSelect: (selectedRows, displayData) => {
      const { asset_id: assetId } = queriesFromString(createWithAsset);
      return (
        <CmlsToolbarSelect
          selectedRows={selectedRows}
          displayData={displayData}
          columns={columns}
          assetId={assetId}
          scopeMode={scopeMode}
        />
      );
    },
    customToolbar: () => {
      if (scopeMode === scopeModes.SELECT) return null;
      if (scopeMode === scopeModes.DEFAULT) {
        return <ScopeToolbar type="CML" />;
      }
      if (hasAssetCreate && !isReadOnly) {
        return (
          <TableImportToolbar importAction={'IMPORT_CMLS'} addRoute={getCreateCmlRoute()} featureFlag={hasCmlImport} />
        );
      }
    },
    enableNestedDataAccess: '.',
    searchOpen: !embedded,
  };

  return (
    <Table
      title={title}
      serverSide={serverSide}
      columns={columns}
      addRoute={getCreateCmlRoute()}
      queryParamObj={queryParamObj}
      tableChangeHandler={tableChangeHandler}
      data={tableData}
      count={tableCount}
      options={options}
      loading={loading}
      filename={filename}
      embedded={embedded}
      page={page}
      views={views}
    />
  );
};

CmlsTable.defaultProps = {
  embedded: false,
  createWithAsset: '',
  filename: null,
  page: '',
  views: {},
  serverSide: true,
  scopeData: undefined,
  scopeCount: undefined,
  isReadOnly: false,
};

CmlsTable.propTypes = {
  title: PropTypes.string.isRequired,
  embedded: PropTypes.bool,
  createWithAsset: PropTypes.string,
  filename: PropTypes.string,
  queryParamObj: PropTypes.object,
  tableChangeHandler: PropTypes.func.isRequired,
  page: PropTypes.string,
  views: PropTypes.object,
  serverSide: PropTypes.bool,
  scopeData: PropTypes.array,
  scopeCount: PropTypes.number,
  isReadOnly: PropTypes.bool,
  scopeMode: PropTypes.string.isRequired,
  project: PropTypes.string,
};

export default CmlsTable;
