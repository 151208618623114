import React, { useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Grid, Typography, IconButton, Tabs, Tab, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanies } from '../../store/features/companiesActions';
import { getAllCrews } from '../../store/features/crewsActions';
import { getAllUsers } from '../../store/features/usersActions';
import { getCompanyStats } from '../../store/getters/statsActions';
import { locationWithBack } from '../../utilities/route';
import { usePermissions } from '../../hooks/settingsHooks';
import CompanyOverview from './CompanyOverview';
import UsersTable from '../users/UsersTable';
import CrewsTable from '../crews/CrewsTable';
import Loading from '../shared/displays/Loading';
import TabPanel from '../shared/TabPanel';
import PrimaryButton from '../shared/buttons/PrimaryButton';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 'auto'),
    padding: theme.spacing(2),
    textAlign: 'left',
  },
  header: {
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
  },
  button: {
    padding: theme.spacing(0, 1),
  },
}));

const CompanyDetail = () => {
  const { data, loading, error } = useSelector(state => state.companies.each);
  let { id, tab: selectedTab } = useParams();
  selectedTab = selectedTab || 'overview'; // default to overview tab
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { hasCompanyEdit } = usePermissions();
  const relatedUsersQuery = { company: id };
  useEffect(() => {
    dispatch(getCompanies(id));
    dispatch(getAllUsers(relatedUsersQuery));
    dispatch(getCompanyStats(id));
    dispatch(getAllCrews(relatedUsersQuery));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const classes = useStyles();

  const { query: usersQuery } = useSelector(state => state.users.all);

  const usersTableChangeHandler = query => {
    const updatedQuery = { ...relatedUsersQuery, ...query };
    dispatch(getAllUsers(updatedQuery));
  };

  const handleBack = () => {
    if (location.state && location.state.back) {
      history.push(location.state.back);
    } else {
      const next = location.pathname.match(/(.*?)\/\w+$/)[1];
      history.push(next);
    }
  };

  const handleEdit = () => {
    history.push(locationWithBack({ pathname: `/companies/${id}/edit` }, location));
  };

  const tabs = [
    {
      value: 'overview',
      label: 'Overview',
      Component: <CompanyOverview id={id} data={data} loading={loading} error={error} />,
    },
    {
      value: 'users',
      label: 'Users',
      Component: (
        <UsersTable
          embedded
          title="Users"
          tableChangeHandler={usersTableChangeHandler}
          styleProjectDetail={classes.embeddedTableStyle}
          queryParamObj={usersQuery}
          company={id}
        />
      ),
    },
    { value: 'crews', label: 'Crews', Component: <CrewsTable /> },
  ];

  let readyToRender = false;

  const handleTabChange = (event, value) => {
    history.push(`/companies/${id}/` + value);
  };

  switch (selectedTab) {
    case 'overview':
    case 'users':
    case 'crews':
      readyToRender = true;
      break;
    default:
      history.replace(`/companies/${id}`);
  }
  // the Tabs component doesn't handle value=undefined
  if (!readyToRender) return <></>;

  return (
    <div className={classes.root}>
      <Loading loading={loading} />
      <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.header}>
            <Grid item>
              <IconButton onClick={handleBack} size="large">
                <ArrowBackIosIcon className={classes.button} />
              </IconButton>
            </Grid>
            <Grid item className={classes.title}>
              <Typography variant="h3">{data.name}</Typography>
            </Grid>
            <Grid item>
              <div className={classes.button}>
                {hasCompanyEdit && <PrimaryButton label="Edit" icon Icon={EditIcon} onClick={handleEdit} />}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary">
            {tabs
              .filter(tab => !tab.hide)
              .map(tab => (
                <Tab
                  id={`company-tab-${tab.value}`}
                  aria-controls={`company-tabpanel-${tab.value}`}
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                />
              ))}
          </Tabs>
          <Divider className={classes.divider} />
          {tabs.map(tab => (
            <TabPanel key={tab.value} value={tab.value} selectedTab={selectedTab} name={'company'}>
              <Grid item className={classes.table}>
                {tab.Component}
              </Grid>
            </TabPanel>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default CompanyDetail;
