import { all, put, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  PROJECTS_REQUEST,
  PROJECTS_SUCCESS,
  PROJECTS_FAILURE,
  PROJECTS_CREATE_REQUEST,
  PROJECTS_CREATE_SUCCESS,
  PROJECTS_CREATE_FAILURE,
  PROJECTS_UPDATE_REQUEST,
  PROJECTS_UPDATE_SUCCESS,
  PROJECTS_UPDATE_FAILURE,
  PROJECT_STATUS_UPDATE_REQUEST,
  PROJECT_STATUS_UPDATE_SUCCESS,
  PROJECT_STATUS_UPDATE_FAILURE,
  PROJECT_STATUS_BULK_UPDATE_REQUEST,
  PROJECT_STATUS_BULK_UPDATE_SUCCESS,
  PROJECT_STATUS_BULK_UPDATE_FAILURE,
  ASSET_CONDITION_UPDATE_REQUEST,
  ASSET_CONDITION_UPDATE_SUCCESS,
  ASSET_CONDITION_UPDATE_FAILURE,
  PROJECT_SHARE_UPDATE_REQUEST,
  PROJECT_SHARE_UPDATE_SUCCESS,
  PROJECT_SHARE_UPDATE_FAILURE,
  PROJECTS_DELETE_REQUEST,
  PROJECTS_DELETE_SUCCESS,
  PROJECTS_DELETE_FAILURE,
  PROJECTS_BULK_DELETE_REQUEST,
  PROJECTS_BULK_DELETE_SUCCESS,
  PROJECTS_BULK_DELETE_FAILURE,
  PROJECTS_BULK_UPDATE_REQUEST,
  PROJECTS_BULK_UPDATE_SUCCESS,
  PROJECTS_BULK_UPDATE_FAILURE,
  PROJECTS_ALL_REQUEST,
  PROJECTS_ALL_SUCCESS,
  PROJECTS_ALL_FAILURE,
  PROJECTS_COUNTS_BY_STATUS_REQUEST,
  PROJECTS_COUNTS_BY_STATUS_SUCCESS,
  PROJECTS_COUNTS_BY_STATUS_FAILURE,
} from './projectsActions';

import {
  fetchProjects,
  createProjects,
  updateProjects,
  updateProjectsBulk,
  updateProjectStatus,
  updateProjectStatusBulk,
  updateAssetCondition,
  updateProjectShare,
  deleteProjects,
  deleteProjectsBulk,
  fetchProjectsList,
  fetchProjectCountsByStatus,
} from '../../api/features/projects';
import { history } from '../../providers/StoreProvider';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';
import { DIALOG_CLOSE } from '../../store/dialogActions';
import { pathToPage } from '../../utilities/strings';
import { tableViews } from '../../utilities/tables';
import { SET_TABLE_QUERY } from '../../store/settings/tableActions';
import { DEFECTS_ALL_REQUEST } from './defectsActions';
import { SNACKBAR_OPEN } from '../snackbarActions';

const { projectsTableViewKey: tableType } = tableViews;

export function* projectsFetch(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield fetchProjects(id);
    if (data) {
      yield put({ type: PROJECTS_SUCCESS, data });
    } else if (error) {
      yield put({ type: PROJECTS_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, PROJECTS_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, PROJECTS_FAILURE);
  }
}

export function* watchProjectsFetch() {
  yield takeLatest(PROJECTS_REQUEST, projectsFetch);
}

export function* projectsCreate(action) {
  const { body, nextRoute, options } = action;
  try {
    const { data, error, formError, response } = yield createProjects(body);
    if (data) {
      yield put({ type: PROJECTS_CREATE_SUCCESS, data });
      if (options.displaySnackbar) {
        yield put({
          type: SNACKBAR_OPEN,
          key: data.id,
          message: `New Project Created - ${data.name}`,
          variant: 'success',
          duration: 7000,
          open: true,
          link: options.link ? `/projects/${data.id}` : undefined,
        });
      }
      if (nextRoute) {
        yield put(push(nextRoute));
      } else {
        if (!options.preventDefault) {
          yield put(push(`/projects/${data.id}`));
        }
      }
    } else if (error) {
      yield put({ type: PROJECTS_CREATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: PROJECTS_CREATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, PROJECTS_CREATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, PROJECTS_CREATE_FAILURE);
  }
}

export function* watchProjectsCreate() {
  yield takeLatest(PROJECTS_CREATE_REQUEST, projectsCreate);
}

export function* projectsUpdate(action) {
  const { id, body, nextRoute } = action;
  try {
    const { data, error, formError, response } = yield updateProjects(id, body);
    if (data) {
      yield put({ type: PROJECTS_UPDATE_SUCCESS, data });
      if (nextRoute) {
        yield put(push(nextRoute));
      }
    } else if (error) {
      yield put({ type: PROJECTS_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: PROJECTS_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, PROJECTS_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, PROJECTS_UPDATE_FAILURE);
  }
}

export function* watchProjectsUpdate() {
  yield takeLatest(PROJECTS_UPDATE_REQUEST, projectsUpdate);
}

export function* projectsUpdateBulk(action) {
  const { ids, body, nextRoute } = action;
  try {
    const { data, error, formError, response } = yield updateProjectsBulk(ids, body);
    if (data) {
      yield put({ type: PROJECTS_BULK_UPDATE_SUCCESS, data });
      if (nextRoute) {
        yield put(push(nextRoute));
      }
    } else if (error) {
      yield put({ type: PROJECTS_BULK_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: PROJECTS_BULK_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, PROJECTS_BULK_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, PROJECTS_BULK_UPDATE_FAILURE);
  }
}

export function* watchProjectsUpdateBulk() {
  yield takeLatest(PROJECTS_BULK_UPDATE_REQUEST, projectsUpdateBulk);
}

export function* projectStatusUpdate(action) {
  const { id, body } = action;
  const query = `?project=${id}`;
  try {
    const { data, error, formError, response } = yield updateProjectStatus(id, body);
    if (data) {
      yield put({ type: PROJECT_STATUS_UPDATE_SUCCESS, data });
      yield put({ type: DEFECTS_ALL_REQUEST, query });
      yield put({ type: DIALOG_CLOSE });
    } else if (error) {
      yield put({ type: PROJECT_STATUS_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: PROJECT_STATUS_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, PROJECT_STATUS_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, PROJECT_STATUS_UPDATE_FAILURE);
  }
}

export function* watchProjectStatusUpdate() {
  yield takeLatest(PROJECT_STATUS_UPDATE_REQUEST, projectStatusUpdate);
}

export function* projectStatusUpdateBulk(action) {
  const { ids, body, nextRoute } = action;
  try {
    const { data, error, formError, response } = yield updateProjectStatusBulk(ids, body);
    if (data) {
      yield put({ type: PROJECT_STATUS_BULK_UPDATE_SUCCESS, data });
      yield put({ type: DIALOG_CLOSE });
      if (nextRoute) {
        yield put(push(nextRoute));
      }
    } else if (error) {
      yield put({ type: PROJECT_STATUS_BULK_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: PROJECT_STATUS_BULK_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, PROJECT_STATUS_BULK_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, PROJECT_STATUS_BULK_UPDATE_FAILURE);
  }
}

export function* watchProjectStatusUpdateBulk() {
  yield takeLatest(PROJECT_STATUS_BULK_UPDATE_REQUEST, projectStatusUpdateBulk);
}


export function* assetConditionUpdate(action) {
  const { id, body } = action;
  try {
    const { data, error, formError, response } = yield updateAssetCondition(id, body);
    if (data) {
      yield put({ type: ASSET_CONDITION_UPDATE_SUCCESS });
      yield put({ type: PROJECTS_REQUEST, id });
      yield put({ type: DIALOG_CLOSE });
    } else if (error) {
      yield put({ type: ASSET_CONDITION_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: ASSET_CONDITION_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, ASSET_CONDITION_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, ASSET_CONDITION_UPDATE_FAILURE);
  }
}

export function* watchAssetConditionUpdate() {
  yield takeLatest(ASSET_CONDITION_UPDATE_REQUEST, assetConditionUpdate);
}

export function* projectShareUpdate(action) {
  const { id, body } = action;
  try {
    const { data, error, formError, response } = yield updateProjectShare(id, body);
    if (data) {
      yield put({ type: DIALOG_CLOSE });
      yield put({ type: PROJECT_SHARE_UPDATE_SUCCESS, data });
    } else if (error) {
      yield put({ type: PROJECT_SHARE_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: PROJECT_SHARE_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, PROJECT_SHARE_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, PROJECT_SHARE_UPDATE_FAILURE);
  }
}

export function* watchProjectShareUpdate() {
  yield takeLatest(PROJECT_SHARE_UPDATE_REQUEST, projectShareUpdate);
}

export function* projectsDelete(action) {
  const { id, options } = action;
  try {
    const { data, error, response } = yield deleteProjects(id);
    if (data) {
      yield put({ type: PROJECTS_DELETE_SUCCESS });
      const query = yield select(state => state.projects.all.query);
      yield put({ type: PROJECTS_ALL_REQUEST, query });
      yield put({ type: DIALOG_CLOSE });
    } else if (error) {
      yield put({ type: PROJECTS_DELETE_FAILURE, error });
      if (options.displaySnackbar) {
        yield put({
          type: SNACKBAR_OPEN,
          message: error,
          variant: 'error',
          open: true,
        });
      }
    } else {
      yield defaultErrorHandler(response, PROJECTS_DELETE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, PROJECTS_DELETE_FAILURE);
  }
}

export function* watchProjectsDelete() {
  yield takeLatest(PROJECTS_DELETE_REQUEST, projectsDelete);
}

export function* projectsBulkDelete(action) {
  const { ids } = action;
  try {
    const { data, error, response } = yield deleteProjectsBulk(ids);
    if (data) {
      yield put({ type: PROJECTS_BULK_DELETE_SUCCESS });
      const query = yield select(state => state.projects.all.query);
      yield put({ type: PROJECTS_ALL_REQUEST, query });
      yield put({ type: DIALOG_CLOSE });
    } else if (error) {
      yield put({ type: PROJECTS_BULK_DELETE_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, PROJECTS_BULK_DELETE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, PROJECTS_BULK_DELETE_FAILURE);
  }
}

export function* watchProjectsBulkDelete() {
  yield takeLatest(PROJECTS_BULK_DELETE_REQUEST, projectsBulkDelete);
}

export function* projectsFetchAll(action) {
  const { query } = action;
  const location = history.location;
  try {
    const { data, error, response } = yield fetchProjectsList(action.query);
    if (data) {
      yield put({ type: PROJECTS_ALL_SUCCESS, data });
    } else if (error) {
      yield put({ type: PROJECTS_ALL_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, PROJECTS_ALL_FAILURE);
    }
    // save the query in the store
    if (pathToPage(location.pathname)) {
      const page = pathToPage(location.pathname);
      yield put({ type: SET_TABLE_QUERY, page, query, tableType });
    }
  } catch (error) {
    yield defaultErrorCatch(error, PROJECTS_ALL_FAILURE);
  }
}

export function* watchProjectsFetchAll() {
  yield takeLatest(PROJECTS_ALL_REQUEST, projectsFetchAll);
}

export function* projectsFetchCountsByStatus(action) {
  try {
    const { data, error, response } = yield fetchProjectCountsByStatus(action.query);
    if (data) {
      yield put({ type: PROJECTS_COUNTS_BY_STATUS_SUCCESS, data });
    } else if (error) {
      yield put({ type: PROJECTS_COUNTS_BY_STATUS_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, PROJECTS_COUNTS_BY_STATUS_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, PROJECTS_COUNTS_BY_STATUS_FAILURE);
  }
}

export function* watchProjectsFetchCountsByStatus() {
  yield takeLatest(PROJECTS_COUNTS_BY_STATUS_REQUEST, projectsFetchCountsByStatus);
}

export default function* projectsSaga() {
  yield all([
    watchProjectsFetch(),
    watchProjectsCreate(),
    watchProjectsUpdate(),
    watchProjectsUpdateBulk(),
    watchProjectStatusUpdate(),
    watchProjectStatusUpdateBulk(),
    watchAssetConditionUpdate(),
    watchProjectShareUpdate(),
    watchProjectsDelete(),
    watchProjectsFetchAll(),
    watchProjectsBulkDelete(),
    watchProjectsFetchCountsByStatus(),
  ]);
}
