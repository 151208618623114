export const DRAWER_OPEN = 'DRAWER_OPEN';
export const DRAWER_CLOSE = 'DRAWER_CLOSE';

export const openDrawer = () => ({
  type: DRAWER_OPEN,
  // open: true,
});

export const closeDrawer = () => ({
  type: DRAWER_CLOSE,
});
