export const SCHEDULE_EVENTS_REQUEST = 'SCHEDULE_EVENTS_REQUEST';
export const SCHEDULE_EVENTS_SUCCESS = 'SCHEDULE_EVENTS_SUCCESS';
export const SCHEDULE_EVENTS_FAILURE = 'SCHEDULE_EVENTS_FAILURE';
export const SCHEDULE_EVENTS_CREATE_REQUEST = 'SCHEDULE_EVENTS_CREATE_REQUEST';
export const SCHEDULE_EVENTS_CREATE_SUCCESS = 'SCHEDULE_EVENTS_CREATE_SUCCESS';
export const SCHEDULE_EVENTS_CREATE_FAILURE = 'SCHEDULE_EVENTS_CREATE_FAILURE';
export const SCHEDULE_EVENTS_UPDATE_REQUEST = 'SCHEDULE_EVENTS_UPDATE_REQUEST';
export const SCHEDULE_EVENTS_UPDATE_SUCCESS = 'SCHEDULE_EVENTS_UPDATE_SUCCESS';
export const SCHEDULE_EVENTS_UPDATE_FAILURE = 'SCHEDULE_EVENTS_UPDATE_FAILURE';
export const SCHEDULE_EVENTS_DELETE_REQUEST = 'SCHEDULE_EVENTS_DELETE_REQUEST';
export const SCHEDULE_EVENTS_DELETE_SUCCESS = 'SCHEDULE_EVENTS_DELETE_SUCCESS';
export const SCHEDULE_EVENTS_DELETE_FAILURE = 'SCHEDULE_EVENTS_DELETE_FAILURE';
export const SCHEDULE_EVENTS_ALL_REQUEST = 'SCHEDULE_EVENTS_ALL_REQUEST';
export const SCHEDULE_EVENTS_ALL_SUCCESS = 'SCHEDULE_EVENTS_ALL_SUCCESS';
export const SCHEDULE_EVENTS_ALL_FAILURE = 'SCHEDULE_EVENTS_ALL_FAILURE';

export const getScheduleEvents = id => {
  return {
    type: SCHEDULE_EVENTS_REQUEST,
    id,
  };
};

export const createScheduleEvents = body => {
  return {
    type: SCHEDULE_EVENTS_CREATE_REQUEST,
    body,
  };
};

export const updateScheduleEvents = (id, body) => {
  return {
    type: SCHEDULE_EVENTS_UPDATE_REQUEST,
    id,
    body,
  };
};

export const deleteScheduleEvents = id => {
  return {
    type: SCHEDULE_EVENTS_DELETE_REQUEST,
    id,
  };
};

export const getAllScheduleEvents = query => {
  return {
    type: SCHEDULE_EVENTS_ALL_REQUEST,
    query,
  };
};
