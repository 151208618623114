import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Table, { SELECTABLE_ROWS_NONE } from '../shared/table/Table';
import { setActivityTableView, setActivityTableSize } from '../../store/settings/views/activityTableViewRedux';
import { toQueryString } from '../../utilities/strings';
import { setTableParamObj } from '../../utilities/objects';
import UserDisplay from '../shared/displays/UserDisplay';
import { getAudit } from '../../store/getters/auditActions';
import { getCustomCSVData, tableViews } from '../../utilities/tables';
import useDateRangeColumnHook from '../../hooks/table/dateRangeColumnHook';
import { useTableViews } from '../../hooks/settingsHooks';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const ActivityLog = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const { activityTableViewKey } = tableViews;
  const { pageSize } = useTableViews(activityTableViewKey);

  const queryParams = setTableParamObj(location, pageSize);
  useEffect(() => {
    dispatch(getAudit(queryParams));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { count, data, loading } = useSelector(state => ({
    count: state.audit.data.count || 0,
    data: state.audit.data.results || [],
    loading: state.audit.loading,
  }));

  const queryActivityLogCategoryFilter = queryParams?.category ? [].concat(queryParams.category) : [];
  const queryActivityLogEntryFilter = queryParams?.entry ? [].concat(queryParams.entry) : [];
  const queryActivityLogUploadedByFilter = queryParams?.uploaded_by ? [].concat(queryParams.uploaded_by) : [];

  /** @type {Column[]} */
  const columns = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        sort: true,
        display: 'excluded',
      },
    },
    {
      name: 'category',
      label: 'Category',
      options: {
        filter: true,
        sort: true,
        filterType: 'textField',
        filterList: queryActivityLogCategoryFilter,
      },
    },
    {
      name: 'entry',
      label: 'Entry',
      options: {
        filter: true,
        sort: true,
        filterType: 'textField',
        filterList: queryActivityLogEntryFilter,
      },
    },
    {
      name: 'uploaded_by',
      label: 'Uploaded By',
      options: {
        filter: true,
        sort: true,
        filterType: 'textField',
        filterList: queryActivityLogUploadedByFilter,
        downloadBody: value => getCustomCSVData('simple', value),
        customBodyRender: value => {
          if (!value) return <></>;
          return <UserDisplay {...value} />;
        },
      },
    },
    useDateRangeColumnHook({
      name: 'created_on',
      label: 'Created On',
      queryParams,
      queryStartKey: 'created_on_after',
      queryEndKey: 'created_on_before',
      renderApiDateTime: true,
      options: {
        display: 'true',
      },
    }),
  ];

  const tableChangeHandler = query => {
    // no need to fetch data if the query is the same.
    const newSearch = toQueryString(query);
    if (location.search === newSearch) return;
    const newLocation = location;
    newLocation.search = newSearch;
    history.push(newLocation);
    dispatch(getAudit(query));
  };

  /** @type {TableOptions} */
  const options = {
    selectableRows: SELECTABLE_ROWS_NONE,
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setActivityTableView(changedColumn, action));
    },
    onChangeRowsPerPage: numberOfRows => {
      dispatch(setActivityTableSize(numberOfRows));
    },
    rowsPerPage: pageSize,
  };

  return (
    <Grid container spacing={3} direction="column" className={classes.root}>
      <Grid item>
        <Table
          title="Activity Log"
          serverSide
          columns={columns}
          count={count}
          data={data}
          loading={loading}
          options={options}
          tableChangeHandler={tableChangeHandler}
          queryParamObj={queryParams}
        />
      </Grid>
    </Grid>
  );
};

export default ActivityLog;
