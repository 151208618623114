export const CREWS_REQUEST = 'CREWS_REQUEST';
export const CREWS_SUCCESS = 'CREWS_SUCCESS';
export const CREWS_FAILURE = 'CREWS_FAILURE';
export const CREWS_CREATE_REQUEST = 'CREWS_CREATE_REQUEST';
export const CREWS_CREATE_SUCCESS = 'CREWS_CREATE_SUCCESS';
export const CREWS_CREATE_FAILURE = 'CREWS_CREATE_FAILURE';
export const CREWS_UPDATE_REQUEST = 'CREWS_UPDATE_REQUEST';
export const CREWS_UPDATE_SUCCESS = 'CREWS_UPDATE_SUCCESS';
export const CREWS_UPDATE_FAILURE = 'CREWS_UPDATE_FAILURE';
export const CREWS_DELETE_REQUEST = 'CREWS_DELETE_REQUEST';
export const CREWS_DELETE_SUCCESS = 'CREWS_DELETE_SUCCESS';
export const CREWS_DELETE_FAILURE = 'CREWS_DELETE_FAILURE';
export const CREWS_ALL_REQUEST = 'CREWS_ALL_REQUEST';
export const CREWS_ALL_SUCCESS = 'CREWS_ALL_SUCCESS';
export const CREWS_ALL_FAILURE = 'CREWS_ALL_FAILURE';
export const CREWS_CLEAR_EACH_ERROR = 'CREWS_CLEAR_EACH_ERROR';
export const CREWS_EACH_CLEAR = 'CREWS_EACH_CLEAR';

export const getCrews = id => {
  return {
    type: CREWS_REQUEST,
    id,
  };
};

export const createCrews = body => {
  return {
    type: CREWS_CREATE_REQUEST,
    body,
  };
};

export const updateCrews = (id, body) => {
  return {
    type: CREWS_UPDATE_REQUEST,
    id,
    body,
  };
};

export const deleteCrews = id => {
  return {
    type: CREWS_DELETE_REQUEST,
    id,
  };
};

export const getAllCrews = query => {
  return {
    type: CREWS_ALL_REQUEST,
    query,
  };
};

export const clearEachError = () => {
  return {
    type: CREWS_CLEAR_EACH_ERROR,
  };
};

export const clearCrews = () => {
  return {
    type: CREWS_EACH_CLEAR,
  };
};
