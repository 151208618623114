import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { getDateRangeDateTime, camelToSnake } from '../../utilities/strings';

const FilterBarDateRangeFilter = props => {
  const { title, onChange, disabled, resetValue } = props;
  const [value, setValue] = useState();
  const formatSelected = value => (value ? [{ name: title, value: value }] : []);
  return (
    <DatePicker
      label={title}
      disableToolbar
      disableFuture
      variant="inline"
      id={`${camelToSnake(title)}-date`}
      format="yyyy-MM-dd"
      margin="normal"
      disabled={disabled}
      value={!resetValue && value ? value : null}
      renderInput={props => <TextField {...props} />}
      onChange={(event, date) => {
        const dateString = (title ?? '').toLowerCase().includes('end')
          ? getDateRangeDateTime(date, 'end')
          : getDateRangeDateTime(date);
        const selectedDate = formatSelected(dateString);
        onChange(event, selectedDate);
        setValue(dateString);
      }}
    />
  );
};
FilterBarDateRangeFilter.defaultProps = {
  title: 'Filter',
  disabled: false,
  resetValue: false,
};

FilterBarDateRangeFilter.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  resetValue: PropTypes.bool,
};

export default FilterBarDateRangeFilter;
