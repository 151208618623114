import { combineReducers } from 'redux';
import {
  COMPANY_STATS_REQUEST,
  COMPANY_STATS_SUCCESS,
  COMPANY_STATS_FAILURE,
  COMPANIES_STATS_REQUEST,
  COMPANIES_STATS_SUCCESS,
  COMPANIES_STATS_FAILURE,
} from './statsActions';

export const initialStateStats = {
  loading: false,
  error: '',
  data: {
    crew: 0,
    user: 0,
    asset_type: 0,
    project_type: 0,
    asset: 0,
    project: 0,
    company: 0,
  },
};

export const companyStatsReducer = (state = initialStateStats, action) => {
  switch (action.type) {
    case COMPANY_STATS_REQUEST:
      return {
        ...initialStateStats,
        loading: true,
        id: action.id,
      };
    case COMPANY_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case COMPANY_STATS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        data: {
          crew: 0,
          user: 0,
          asset_type: 0,
          project_type: 0,
          asset: 0,
          project: 0,
          company: 0,
        },
      };
    default:
      return state;
  }
};

export const companiesStatsReducer = (state = initialStateStats, action) => {
  switch (action.type) {
    case COMPANIES_STATS_REQUEST:
      return {
        ...initialStateStats,
        loading: true,
      };
    case COMPANIES_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case COMPANIES_STATS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        data: {
          crew: 0,
          user: 0,
          asset_type: 0,
          project_type: 0,
          asset: 0,
          project: 0,
          company: 0,
        },
      };
    default:
      return state;
  }
};

export default combineReducers({
  company: companyStatsReducer,
  companies: companiesStatsReducer,
});
