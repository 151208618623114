import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { AppBar, ButtonGroup, Toolbar } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import makeStyles from '@mui/styles/makeStyles';

import ChecklistLock from '../ChecklistLock';
import ButtonIcon from '../../shared/buttons/ButtonIcon';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import { appBarHeightFinder } from '../../../utilities/styling';
import { useFeatureFlags } from '../../../hooks/settingsHooks';
import { AssetLink, ChecklistTemplateLink, ProjectLink } from '../../shared/links/InternalLinks';

const FormToolbar = React.forwardRef((props, ref) => {
  const workspace = useSelector(state => state.settings.features.workspace);
  const open = useSelector(state => state.drawer.open);
  const { data, displayProjectButton } = props;
  const drawerWidth = 270;
  const appBarHeight = appBarHeightFinder(workspace.alert_status);

  const { hasChecklistLocking } = useFeatureFlags();

  const useStyles = makeStyles(theme => ({
    appBar: {
      marginTop: appBarHeight,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      marginTop: appBarHeight,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    toolBar: {
      paddingLeft: theme.spacing(1),
      fontSize: theme.spacing(3),
      display: 'flex',
    },
    buttonGroup: {
      backgroundColor: theme.palette.secondary.main,
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const handleBack = () => {
    if (location.state && location.state.back) {
      history.push(location.state.back);
    } else {
      const next = location.pathname.match(/(.*?)\/\w+$/)[1];
      history.push(next);
    }
  };

  const handleNewProjectButton = () => {
    const search = `?checklist_id=${data.id}&template_id=${data.template.id}`;
    history.push({
      pathname: '/projects/new',
      search: search,
    });
  };

  const renderFormPath = () => {
    // permission checks handled by the individual link components
    return (
      <>
        {data?.asset && (
          <span key={data.asset.id}>
            <AssetLink {...data.asset} />
            {' / '}
          </span>
        )}
        {data?.project && (
          <span key={data.project.id}>
            <ProjectLink {...data.project} />
            {' / '}
          </span>
        )}
        {data?.template && (
          <span key={data.template.id}>
            <ChecklistTemplateLink {...data.template} />
          </span>
        )}
      </>
    );
  };

  return (
    <AppBar
      ref={ref}
      elevation={0}
      position="fixed"
      style={{ zIndex: 200 }}
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar>
        <ButtonIcon buttonSize="medium" iconSize="medium" icon={ArrowBackIosIcon} onClick={handleBack} />
        <p style={{ flex: 1 }} className={`h7 ${classes.toolBar}`}>
          {renderFormPath()}
        </p>
        <ButtonGroup className={classes.buttonGroup}>
          {hasChecklistLocking && <ChecklistLock data={data} />}
          {displayProjectButton && (
            <PrimaryButton
              onClick={() => {
                handleNewProjectButton();
              }}
              label={'New Project'}
              icon
              Icon={AddIcon}
            />
          )}
        </ButtonGroup>
      </Toolbar>
    </AppBar>
  );
});

FormToolbar.propTypes = {
  data: PropTypes.number,
  displayProjectButton: PropTypes.bool,
  displayLockButton: PropTypes.bool,
};
FormToolbar.defaultProps = {
  data: undefined,
  displayProjectButton: false,
  displayLockButton: false,
};

export default FormToolbar;
