import React from 'react';
import PropTypes from 'prop-types';

import Description from '../shared/table/Description';
import Table, { SELECTABLE_ROWS_NONE } from '../shared/table/Table';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const ChecklistTemplateLinesTable = props => {
  const { title, lines, loading, embedded } = props;

  /** @type {Column[]} */
  const columns = [
    {
      name: 'order',
      label: 'Order',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'key',
      label: 'Key',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'label',
      label: 'Label',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'lineType',
      label: 'Line Type',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        filter: true,
        sort: true,
        filterType: 'textField',
        customBodyRender: value => {
          if (!value) return <></>;
          return <Description value={value} />;
        },
      },
    },
  ];

  /** @type {TableOptions} */
  const options = {
    enableNestedDataAccess: '.',
    searchOpen: false,
    customToolbar: () => {
      return null;
    },
    search: false,
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
    selectableRows: SELECTABLE_ROWS_NONE,
    rowsPerPage: 50,
  };

  // className={styleProjectDetail}
  return (
    <div>
      <Table
        title={title}
        simpleSearch
        columns={columns}
        data={lines}
        options={options}
        loading={loading}
        embedded={embedded}
      />
    </div>
  );
};

ChecklistTemplateLinesTable.defaultProps = {
  title: 'Lines',
  lines: [],
  embedded: false,
  loading: false,
};

ChecklistTemplateLinesTable.propTypes = {
  title: PropTypes.string,
  lines: PropTypes.array,
  embedded: PropTypes.bool,
  loading: PropTypes.bool,
};

export default ChecklistTemplateLinesTable;
