import { requestFactory, responseHandlerForm } from '../base';

const get = requestFactory('get');
const post = requestFactory('post', { handler: responseHandlerForm });
const patch = requestFactory('patch', { handler: responseHandlerForm });
const del = requestFactory('del');

export const fetchUsersList = async query => get('/api/users/', query);
export const fetchUsers = async id => get(`/api/users/${id}/`);
export const createUsers = async body => post('/api/users/', body);
export const createUsersBulk = async body => post('/api/users/import/', body);
export const updateUsers = async (id, body) => patch(`/api/users/${id}/`, body);
export const deleteUsers = async id => del(`/api/users/${id}/`);
