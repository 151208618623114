// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import { combineReducers } from 'redux';
import {
  CHECKLIST_TEMPLATES_EXPORT_FAILURE,
  CHECKLIST_TEMPLATES_EXPORT_REQUEST,
  CHECKLIST_TEMPLATES_EXPORT_SUCCESS,
  CHECKLIST_TEMPLATES_DOWNLOAD_YAML_REQUEST,
  CHECKLIST_TEMPLATES_DOWNLOAD_YAML_SUCCESS,
  CHECKLIST_TEMPLATES_DOWNLOAD_YAML_FAILURE,
  CHECKLIST_TEMPLATES_REQUEST,
  CHECKLIST_TEMPLATES_SUCCESS,
  CHECKLIST_TEMPLATES_FAILURE,
  CHECKLIST_TEMPLATES_CREATE_REQUEST,
  CHECKLIST_TEMPLATES_CREATE_SUCCESS,
  CHECKLIST_TEMPLATES_CREATE_FAILURE,
  CHECKLIST_TEMPLATES_UPDATE_REQUEST,
  CHECKLIST_TEMPLATES_UPDATE_SUCCESS,
  CHECKLIST_TEMPLATES_UPDATE_FAILURE,
  CHECKLIST_TEMPLATES_DELETE_REQUEST,
  CHECKLIST_TEMPLATES_DELETE_SUCCESS,
  CHECKLIST_TEMPLATES_DELETE_FAILURE,
  CHECKLIST_TEMPLATES_ALL_REQUEST,
  CHECKLIST_TEMPLATES_ALL_SUCCESS,
  CHECKLIST_TEMPLATES_ALL_FAILURE,
  CHECKLIST_TEMPLATES_CLEAR_EACH_ERROR,
} from './checklistTemplatesActions';

export const initialStateChecklistTemplates = {
  loading: false,
  error: '',
  formError: {},
  id: 0,
  body: {},
  data: {},
};

export const initialStateAllChecklistTemplates = {
  loading: false,
  error: '',
  query: {},
  dataAll: { count: 0, next: null, previous: null, results: [] },
};

export const checklistTemplatesReducer = (state = initialStateChecklistTemplates, action) => {
  switch (action.type) {
    case CHECKLIST_TEMPLATES_REQUEST:
      return {
        ...initialStateChecklistTemplates,
        loading: true,
        id: action.id,
      };
    case CHECKLIST_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case CHECKLIST_TEMPLATES_FAILURE:
      return {
        ...initialStateChecklistTemplates,
        error: action.error,
      };
    case CHECKLIST_TEMPLATES_CREATE_REQUEST:
      return {
        ...initialStateChecklistTemplates,
        loading: true,
        body: action.body,
      };
    case CHECKLIST_TEMPLATES_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case CHECKLIST_TEMPLATES_CREATE_FAILURE:
      return {
        ...initialStateChecklistTemplates,
        error: action.error,
        formError: action.formError,
      };
    case CHECKLIST_TEMPLATES_UPDATE_REQUEST:
      return {
        ...state,
        error: '',
        formError: {},
        loading: true,
        id: action.id,
        body: action.body,
      };
    case CHECKLIST_TEMPLATES_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case CHECKLIST_TEMPLATES_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case CHECKLIST_TEMPLATES_DELETE_REQUEST:
      return {
        ...state,
        error: '',
        formError: {},
        deleting: true,
        id: action.id,
      };
    case CHECKLIST_TEMPLATES_DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
      };
    case CHECKLIST_TEMPLATES_DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        error: action.error,
        formError: action.formError,
      };
    case CHECKLIST_TEMPLATES_EXPORT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CHECKLIST_TEMPLATES_EXPORT_REQUEST:
      return {
        ...state,
        loading: true,
        id: action.id,
      };
    case CHECKLIST_TEMPLATES_EXPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case CHECKLIST_TEMPLATES_DOWNLOAD_YAML_REQUEST:
      return {
        ...state,
        loading: true,
        id: action.id,
      };
    case CHECKLIST_TEMPLATES_DOWNLOAD_YAML_FAILURE:
      return {
        ...state,
        loading: false,
        id: action.error,
      };
    case CHECKLIST_TEMPLATES_DOWNLOAD_YAML_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CHECKLIST_TEMPLATES_CLEAR_EACH_ERROR:
      return {
        ...state,
        error: '',
        formError: {},
      };
    default:
      return state;
  }
};
export const checklistTemplatesAllReducer = (state = initialStateAllChecklistTemplates, action) => {
  switch (action.type) {
    case CHECKLIST_TEMPLATES_ALL_REQUEST:
      return {
        ...initialStateAllChecklistTemplates,
        loading: true,
        query: action.query,
      };
    case CHECKLIST_TEMPLATES_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        dataAll: action.data,
      };
    case CHECKLIST_TEMPLATES_ALL_FAILURE:
      return {
        ...initialStateAllChecklistTemplates,
        error: action.error,
      };
    default:
      return state;
  }
};

export default combineReducers({
  each: checklistTemplatesReducer,
  all: checklistTemplatesAllReducer,
});
