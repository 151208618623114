import React from 'react';
import PropTypes from 'prop-types';
import { Select as MuiSelect, FormControl, InputLabel, MenuItem } from '@mui/material';
import { Field } from 'react-final-form';
import { ErrorMessage, showError, useFieldForErrors } from './Util';

/**
 * Select component copied from mui-rff.
 * Added onChange prop to get the selected value from the Field component
 */

const Select = props => {
  const {
    name,
    label,
    data,
    children,
    required,
    multiple,
    helperText,
    fieldProps,
    inputLabelProps,
    formControlProps,
    formHelperTextProps,
    menuItemProps,
    onChange,
    ...restSelectProps
  } = props;

  if (!data && !children) {
    throw new Error('Please specify either children or data as an attribute.');
  }

  const field = useFieldForErrors(name);
  const isError = showError(field);

  return (
    <FormControl required={required} error={isError} fullWidth={true} {...formControlProps}>
      {!!label && <InputLabel {...inputLabelProps}>{label}</InputLabel>}
      <Field
        name={name}
        render={({ input: { name, value, onChange: onChangeField, checked, ...restInput } }) => {
          // prevents an error that happens if you don't have initialValues defined in advance
          const finalValue = multiple && !value ? [] : value;
          // added the ability to pass onChange funciton to select and get the currently selected value
          // outside the Field component.
          const onChangeLocal = e => {
            if (onChange) onChange(e);
            onChangeField(e);
          };
          return (
            <MuiSelect
              name={name}
              value={finalValue}
              onChange={onChangeLocal}
              multiple={multiple}
              label={label}
              inputProps={{ required, ...restInput }}
              {...restSelectProps}>
              {data
                ? data.map(item => (
                    <MenuItem value={item.value} key={item.value} disabled={item.disabled} {...menuItemProps}>
                      {item.label}
                    </MenuItem>
                  ))
                : children}
            </MuiSelect>
          );
        }}
        {...fieldProps}
      />
      <ErrorMessage
        showError={isError}
        meta={field.meta}
        formHelperTextProps={{ variant: 'outlined', ...formHelperTextProps }}
        helperText={helperText}
      />
    </FormControl>
  );
};

Select.defaultProps = {
  data: [],
  children: null,
  required: false,
  multiple: false,
  helperText: '',
  fieldProps: {},
  inputLabelProps: {},
  formControlProps: {},
  formHelperTextProps: {},
  menuItemProps: {},
  onChange: null,
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.element.isRequired,
  data: PropTypes.array,
  children: PropTypes.element,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
  helperText: PropTypes.string,
  fieldProps: PropTypes.object,
  inputLabelProps: PropTypes.object,
  formControlProps: PropTypes.object,
  formHelperTextProps: PropTypes.object,
  menuItemProps: PropTypes.object,
  onChange: PropTypes.func,
};

export default Select;
