import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { jsonKeyToLabel, apiDateToString, queriesFromString } from '../../utilities/strings';
import { getScheduleWork, createScheduleWork, updateScheduleWork } from '../../store/features/scheduleWorkActions';
import CommonForm from '../shared/form/Common';
import { fieldOrder, removeField, hideField } from './ScheduleWorkShared';
import { useChecklistTemplates, useCrews } from '../../hooks/optionsHooks';
import { useCachedProjectTypes } from '../../hooks/projectTypesHooks';
import { getAllCrews } from '../../store/features/crewsActions';
import { checklistTemplatesBatchListEndpoint } from '../../store/apiV2/checklistTemplates';
import { taskCreateModes, workTypeOptions } from '../../api/features/constants';
import { getUserOptionLabel } from '../../utilities/users';

const ScheduleWorkForm = props => {
  const { update } = props;
  const params = useParams();
  const { data, loading, error, formError } = useSelector(state => state.scheduleWork.each);
  const [submittedValues, setSubmittedValues] = useState({});
  const dispatch = useDispatch();
  const { projectTypeOptions } = useCachedProjectTypes();
  const crewOptions = useCrews();
  const checklistTemplateOptions = useChecklistTemplates();
  const checklistTemplatesAll = checklistTemplatesBatchListEndpoint.useEndpoint();

  const ownerOptions = data.owner ? [getUserOptionLabel(data.owner)] : [];
  let queries = {};
  if (location.search) {
    queries = queriesFromString(location.search);
  }
  const [taskCreateMode, setTaskCreateMode] = useState('AS_NEEDED');
  useEffect(() => {
    if (update && params.id) {
      dispatch(getScheduleWork(params.id));
    }

    checklistTemplatesAll.dispatchRequest();
    dispatch(getAllCrews({ is_active: true }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formSettings = {
    fieldOrder,
    removeField: removeField(update ? 'UPDATE' : 'CREATE'),
    hideField,
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      switch (key) {
        /* ---------- Required Fields ---------- */
        case 'name':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              required: true,
            },
          };
          break;

        /* ---------- Regular Fields ---------- */
        case 'work_type':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              options: workTypeOptions,
              // onChange: e => setTaskCreateMode(e.target.value),
              labelwidth: 115,
              helperText: 'Form or Project based schedule.',
            },
          };
          break;
        case 'checklist_template':
          fieldSettings[key] = {
            type: 'autocomplete',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              options: checklistTemplateOptions,
              labelwidth: 36,
              helperText: 'Template to be filled out.',
            },
          };
          break;
        case 'project_type':
          fieldSettings[key] = {
            type: 'autocomplete',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              options: projectTypeOptions,
              labelwidth: 36,
              helperText: 'Project type to be created.',
            },
          };
          break;
        case 'crew':
          fieldSettings[key] = {
            type: 'autocomplete',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              options: crewOptions,
              helperText: 'Special crew assigned to complete this work. (Optional)',
            },
          };
          break;
        case 'owner':
          fieldSettings[key] = {
            type: 'user-autocomplete',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              initialUsers: ownerOptions,
              labelwidth: 80,
              multiple: false,
              queryParams: '?is_active=true&ordering=name',
              helperText: 'Responsible party for created work.',
            },
          };
          break;
        case 'task_create_mode':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              options: taskCreateModes,
              onChange: e => setTaskCreateMode(e.target.value),
              labelwidth: 115,
              helperText: 'Create a fixed number of tasks or dynamically create a task for all of the assigned crew.',
            },
          };
          break;
        case 'task_create_count':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              disabled: taskCreateMode === 'AS_NEEDED', // TODO look into rff Conditions to hide fields
              labelwidth: 64,
              helperText: 'Number of tasks to create.',
            },
          };
          break;
        case 'work_name_template':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              labelwidth: 64,
              helperText: 'Template to dynamically build project or task naming.',
            },
          };
          break;
        case 'instructions':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              labelwidth: 64,
              multiline: true,
              rows: 3,
              maxRows: 10,
              helperText: 'Advanced settings to control project creation.',
            },
          };
          break;
        case 'is_active':
          fieldSettings[key] = {
            type: 'checkbox',
            cellProps: {
              xs: 12,
            },
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          break;
        /* ----------  Disabled Fields ---------- */
        case 'created_on':
        case 'updated_on':
          fieldSettings[key] = {
            type: 'display',
            fieldProps: {
              disabled: true,
              label: jsonKeyToLabel(key),
              name: key,
              value: apiDateToString(data[key], 'date'),
            },
          };
          break;

        /* ----------  Default ---------- */
        default:
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
      }
    });
  }

  const title = update ? 'Edit Plan Work' : 'New Plan Work';

  let initialValues = {};
  if (update) {
    // The form cannot handle data coming in as objects.  (see ProjectsForm as an example)
    initialValues = {
      ...data,
      crew: data?.crew?.id?.toString(),
      checklist_template: data?.checklist_template?.id?.toString(),
      project_type: data?.project_type?.id?.toString(),
      instructions: JSON.stringify(data.instructions, null, 2),
    };
  } else {
    // assign values from url queries here
    initialValues = {};
  }

  const onSubmit = changedValues => {
    setSubmittedValues(changedValues);
    const updatedValues = { ...changedValues, plan: queries.planId };
    if (changedValues.instructions) {
      updatedValues.instructions = JSON.parse(changedValues.instructions);
    }
    if (update) {
      dispatch(updateScheduleWork(updatedValues.id, queries.planId, updatedValues));
    } else {
      dispatch(createScheduleWork(queries.planId, updatedValues));
    }
  };

  /**
   * Decorators are used for setting the values of other fields based off of a field.
   * Decorator format can be found here: src/components/shared/form/common.js
   */
  const decorators = [];

  /**
   *  Validations that are per field.
   *  Errors associated with the form are passed back through formError in Redux.
   */
  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        if (values[key] === submittedValues[key]) {
          errors[key] = value;
        } else {
          errors[key] = undefined;
        }
      }
    }
    return errors;
  };

  return (
    <CommonForm
      update={update}
      title={title}
      initialValues={initialValues}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      onSubmit={onSubmit}
      decorators={decorators}
      validate={validate}
      loading={loading}
      error={error}
    />
  );
};

ScheduleWorkForm.defaultProps = {
  update: false,
};

ScheduleWorkForm.propTypes = {
  update: PropTypes.bool,
};

export default ScheduleWorkForm;
