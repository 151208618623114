// --------------------------------------------------------------------------------
//
// Generated File
//
// !! Do not change this file by hand.                                       !!
//   !! This file is automatically generated from the reducers in this folder. !!
//
// --------------------------------------------------------------------------------
import assetsGetterReducer from './assetsGetterReducer';
import auditReducer from './auditReducer';
import helpReducer from './helpReducer';
import leadFiltersReducer from './leadFiltersReducer';
import observationsReducer from './observationsReducer';
import pagesReducer from './pagesReducer';
import profileReducer from './profileReducer';
import projectReportReducer from './projectReportReducer';
import projectSelectedFindingsReducer from './projectSelectedFindingsReducer';
import projectSummaryReducer from './projectSummaryReducer';
import reservationStateReducer from './reservationStateReducer';
import revisionsReducer from './revisionsReducer';
import statsReducer from './statsReducer';
import usageStatsReducer from './usageStatsReducer';
import usersGetterReducer from './usersGetterReducer';

export default {
  assetsGetter: assetsGetterReducer,
  audit: auditReducer,
  help: helpReducer,
  leadFilters: leadFiltersReducer,
  observations: observationsReducer,
  pages: pagesReducer,
  profile: profileReducer,
  projectReport: projectReportReducer,
  projectSelectedFindings: projectSelectedFindingsReducer,
  projectSummary: projectSummaryReducer,
  reservationState: reservationStateReducer,
  revisions: revisionsReducer,
  stats: statsReducer,
  usageStats: usageStatsReducer,
  usersGetter: usersGetterReducer,
};
