import { createTheme } from '@mui/material/styles';
import palette from './palette';
import typography from './typography';
import overrides from './overrides';

// Huvr designs: https://app.zeplin.io/project/5d7f94677c7ef49fb21c6bee/screen/5ece7b090a196a49cf5b9ea1
// default theme values can be found here: https://material-ui.com/customization/default-theme/

const setTheme = darkMode => {
  return createTheme({
    components: {
      ...overrides(darkMode),
    },
    palette: palette(darkMode),
    typography: typography(darkMode),
    zIndex: {
      overlay: 900,
    },
  });
};

export default setTheme;
