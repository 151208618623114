import { all, put, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  DEFECTS_REQUEST,
  DEFECTS_SUCCESS,
  DEFECTS_FAILURE,
  DEFECTS_CREATE_REQUEST,
  DEFECTS_CREATE_SUCCESS,
  DEFECTS_CREATE_FAILURE,
  DEFECTS_UPDATE_REQUEST,
  DEFECTS_UPDATE_SUCCESS,
  DEFECTS_UPDATE_FAILURE,
  DEFECTS_DELETE_REQUEST,
  FINDINGS_BULK_UPDATE_REQUEST,
  FINDINGS_BULK_UPDATE_SUCCESS,
  FINDINGS_BULK_UPDATE_FAILURE,
  DEFECTS_DELETE_SUCCESS,
  DEFECTS_DELETE_FAILURE,
  DEFECTS_LINKING_REQUEST,
  DEFECTS_LINKING_SUCCESS,
  DEFECTS_LINKING_FAILURE,
  DEFECTS_ALL_REQUEST,
  DEFECTS_ALL_SUCCESS,
  DEFECTS_ALL_FAILURE,
} from './defectsActions';
import { INSPECTION_MEDIA_ALL_REQUEST } from './inspectionMediaActions';

import {
  fetchDefects,
  createDefects,
  updateDefects,
  updateFindingsBulk,
  linkDefects,
  deleteDefects,
  fetchDefectsList,
} from '../../api/features/defects';
import { history } from '../../providers/StoreProvider';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';
import { pathToPage } from '../../utilities/strings';
import { tableViews } from '../../utilities/tables';
import { SET_TABLE_QUERY } from '../../store/settings/tableActions';
import { DIALOG_CLOSE } from '../../store/dialogActions';
import { SNACKBAR_OPEN } from '../snackbarActions';

const { defectsTableViewKey: tableType } = tableViews;

export function* defectsFetch(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield fetchDefects(id);
    if (data) {
      yield put({ type: DEFECTS_SUCCESS, data });
    } else if (error) {
      yield put({ type: DEFECTS_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, DEFECTS_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, DEFECTS_FAILURE);
  }
}

export function* watchDefectsFetch() {
  yield takeLatest(DEFECTS_REQUEST, defectsFetch);
}

export function* defectsCreate(action) {
  const { body } = action;
  try {
    const { data, error, formError, response } = yield createDefects(body);
    if (data) {
      yield put({ type: DEFECTS_CREATE_SUCCESS, data });
      yield put(push('/findings'));
    } else if (error) {
      yield put({ type: DEFECTS_CREATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: DEFECTS_CREATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, DEFECTS_CREATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, DEFECTS_CREATE_FAILURE);
  }
}

export function* watchDefectsCreate() {
  yield takeLatest(DEFECTS_CREATE_REQUEST, defectsCreate);
}

export function* defectsUpdate(action) {
  const { id, body, location } = action;
  try {
    const { data, error, formError, response } = yield updateDefects(id, body);
    if (data) {
      yield put({ type: DEFECTS_UPDATE_SUCCESS, data });
      yield put(push(location));
      yield put({ type: DIALOG_CLOSE });
    } else if (error) {
      yield put({ type: DEFECTS_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: DEFECTS_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, DEFECTS_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, DEFECTS_UPDATE_FAILURE);
  }
}

export function* watchDefectsUpdate() {
  yield takeLatest(DEFECTS_UPDATE_REQUEST, defectsUpdate);
}

export function* findingsUpdateBulk(action) {
  const { ids, body } = action;
  try {
    const { data, error, formError, response } = yield updateFindingsBulk(ids, body);
    if (data) {
      yield put({ type: FINDINGS_BULK_UPDATE_SUCCESS, data });
    } else if (error) {
      yield put({ type: FINDINGS_BULK_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: FINDINGS_BULK_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, FINDINGS_BULK_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, FINDINGS_BULK_UPDATE_FAILURE);
  }
}

export function* watchFindingsUpdateBulk() {
  yield takeLatest(FINDINGS_BULK_UPDATE_REQUEST, findingsUpdateBulk);
}

export function* defectsDelete(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield deleteDefects(id);
    if (data) {
      yield put({ type: DEFECTS_DELETE_SUCCESS });
      const query = yield select(state => state.defects.all.query) || '';
      yield put({ type: DEFECTS_ALL_REQUEST, query });
      const mediaQuery = yield select(state => state.inspectionMedia.all.query || '');
      yield put({ type: INSPECTION_MEDIA_ALL_REQUEST, query: mediaQuery });
    } else if (error) {
      yield put({ type: DEFECTS_DELETE_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, DEFECTS_DELETE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, DEFECTS_DELETE_FAILURE);
  }
}

export function* watchDefectsDelete() {
  yield takeLatest(DEFECTS_DELETE_REQUEST, defectsDelete);
}

export function* defectsMerge(action) {
  const { defectIds } = action;
  try {
    const { data, error, formError, response } = yield linkDefects(defectIds);
    /* data will be an array of defects */
    if (data) {
      yield put({ type: DEFECTS_LINKING_SUCCESS, data });
      yield put({
        type: SNACKBAR_OPEN,
        message: `Merged ${defectIds.length} findings into Finding ${defectIds[0]}`,
        variant: 'success',
        open: true,
      });
      const mergedId = data[0].id;
      yield put(push(`/findings/${mergedId}`));

      // TODO -  uncomment / implement after https://github.com/huvrdata/huvr/pull/6522
      //    unable to deselect selected rows,
      //    they  no longer exist so we cannot stay on the same page.
      // if (nextRoute) {
      //   yield put(push(nextRoute));
      // } else {
      // }
      // // refreshing the table
      // const query = yield select(state => state.defects.all.query) || '';
      // yield put({ type: DEFECTS_ALL_REQUEST, query });
    } else if (error) {
      yield put({ type: DEFECTS_LINKING_FAILURE, error });
    } else if (formError) {
      yield put({ type: DEFECTS_LINKING_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, DEFECTS_LINKING_FAILURE);
      yield put({
        type: SNACKBAR_OPEN,
        message: response,
        variant: 'error',
        open: true,
      });
    }
  } catch (error) {
    yield defaultErrorCatch(error, DEFECTS_LINKING_FAILURE);
  }
}

export function* watchDefectsMerge() {
  yield takeLatest(DEFECTS_LINKING_REQUEST, defectsMerge);
}

export function* defectsFetchAll(action) {
  const { query } = action; // pageInfo
  const location = history.location;
  try {
    const { data, error, response } = yield fetchDefectsList(action.query);
    if (data) {
      yield put({ type: DEFECTS_ALL_SUCCESS, data });
    } else if (error) {
      yield put({ type: DEFECTS_ALL_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, DEFECTS_ALL_FAILURE);
    }
    // save the query in the store
    if (pathToPage(location.pathname)) {
      const page = pathToPage(location.pathname);
      yield put({ type: SET_TABLE_QUERY, page, query, tableType });
    }
  } catch (error) {
    yield defaultErrorCatch(error, DEFECTS_ALL_FAILURE);
  }
}

export function* watchDefectsFetchAll() {
  yield takeLatest(DEFECTS_ALL_REQUEST, defectsFetchAll);
}

export default function* defectsSaga() {
  yield all([
    watchDefectsFetch(),
    watchDefectsCreate(),
    watchDefectsUpdate(),
    watchFindingsUpdateBulk(),
    watchDefectsDelete(),
    watchDefectsMerge(),
    watchDefectsFetchAll(),
  ]);
}
