// lib
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

// components
import PrimaryButton from '../shared/buttons/PrimaryButton';
import ButtonIcon from '../shared/buttons/ButtonIcon';

// util
import { locationWithBack } from '../../utilities/route';
import { useFeatureFlags, usePermissions } from '../../hooks/settingsHooks';
import { getIdFromRow } from '../shared/table/columns';
import { getProjects } from '../../store/features/projectsActions';
import { projectsScopeUpdateEndpoint } from '../../store/apiV2/projects';
import { ProjectScopeContext } from '../projects/projectScope-context';
import { openSnackbar } from '../../store/snackbarActions';
import { scopeModes } from '../projects/projectsShared';

const useStyles = makeStyles(theme => ({
  inheritDisplay: {
    display: 'inherit',
    marginRight: theme.spacing(2),
  },
  saveMessage: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
}));

const CmlsToolBarSelect = props => {
  const { selectedRows, displayData, disableNewProject, columns, assetId, scopeMode } = props;

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { hasProjectCreate } = usePermissions();
  const { hasProjectScopes } = useFeatureFlags();
  const projectsScopeUpdate = projectsScopeUpdateEndpoint.useEndpoint();
  const { scopeData, projectId, setOpenScopeDialog } = useContext(ProjectScopeContext);

  // New project depends on table settings, feature flag AND permissions
  const showNewProject = !disableNewProject && hasProjectCreate && hasProjectScopes;

  // Get array of cml IDs out of the table data
  const selectedCmlIds = selectedRows.data.map(item => getIdFromRow(item, displayData, columns));

  // cmls already in the project
  const existingCmlIds = scopeData?.selected_cmls_ids;

  const handleNewProject = () => {
    const newLocation = {
      pathname: '/projects/new',
    };
    const cmlIds = selectedCmlIds.join();
    if (assetId) {
      newLocation.search = `?asset_id=${assetId}&cmls=${cmlIds}`;
    } else {
      newLocation.search = `?cmls=${cmlIds}`;
    }
    const next = locationWithBack(newLocation, location);
    history.push(next);
  };

  const updateProject = ids => {
    projectsScopeUpdate
      .dispatchRequest(projectId, { selected_cmls_ids: [...ids] })
      .then(() => setOpenScopeDialog(false))
      .then(() => dispatch(openSnackbar('Project has been updated.', 'success', null, 2000)))
      .then(() => dispatch(getProjects(projectId)))
      .catch(error => {
        console.error(error);
        dispatch(openSnackbar('Error updating project', 'error', null, 2000));
      });
  };
  const handleRemove = () => {
    const payloadIds = existingCmlIds.filter(x => !selectedCmlIds.includes(x)); // remove selected ids
    updateProject(payloadIds);
  };

  const handleSubmit = () => {
    updateProject([...existingCmlIds, ...selectedCmlIds]);
  };

  const renderToolbarButtons = () => {
    switch (scopeMode) {
      case scopeModes.DEFAULT:
        return (
          <ButtonIcon
            iconSize="large"
            icon={RemoveCircleOutlineIcon}
            onClick={handleRemove}
            tooltip="Remove CMLs from existing project."
          />
        );
      case scopeModes.SELECT:
        return (
          <>
            <div className={classes.saveMessage}>
              After saving, anyone working on this project will need to sync again to get the latest changes.
            </div>
            <PrimaryButton icon label="Save" tooltip="Add Selected CMLs" onClick={handleSubmit} />
          </>
        );
      default:
        return (
          <>
            {showNewProject ? (
              <PrimaryButton
                icon
                Icon={AddIcon}
                label="New Project"
                tooltip="New Project(s) using Selected CMLs"
                onClick={handleNewProject}
              />
            ) : null}
          </>
        );
    }
  };

  return <div className={classes.inheritDisplay}>{renderToolbarButtons()}</div>;
};

CmlsToolBarSelect.defaultProps = {
  disableNewProject: false,
};

CmlsToolBarSelect.propTypes = {
  assetId: PropTypes.string,
  selectedRows: PropTypes.shape({
    data: PropTypes.array,
    dataIndex: PropTypes.Integer,
  }).isRequired,
  displayData: PropTypes.array.isRequired,
  disableNewProject: PropTypes.bool, // override the feature flag to hide new project button
  getFilesFn: PropTypes.func, // function to extract files for download or zip
  columns: PropTypes.arrayOf(PropTypes.object).isRequired, // Table column definitions
  scopeProject: PropTypes.string,
  scopeMode: PropTypes.string.isRequired,
};

export default CmlsToolBarSelect;
