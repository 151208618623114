import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: '500px',
    margin: 'auto',
  },
  viewImage: {
    width: '100%',
  },
}));

const ViewDisplayImage = props => {
  const { label, value } = props;
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className="h7">{label}</div>
      <img className={classes.viewImage} src={value} alt={label} />
    </div>
  );
};

ViewDisplayImage.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

ViewDisplayImage.defaultProps = {
  label: '',
  value: '',
};

export default ViewDisplayImage;
