import React from 'react';
import * as Sentry from '@sentry/browser';
import { useLocation } from 'react-router-dom';
import { Container } from '@mui/material';

/**
 * 404 page specifically for React<br>
 * This page will also create a Sentry issue
 */

const NotFound = () => {
  const location = useLocation();
  Sentry.withScope(scope => {
    scope.setTag('search', location.search);
    scope.setTag('hash', location.hash);
    scope.setTag('state', location.state);
    Sentry.captureMessage(`404: Page Not Found (React): ${location.pathname}`);
  });
  return (
    <Container maxWidth={false} disableGutters>
      <h1>404: Page Not Found (React).</h1>
    </Container>
  );
};

export default NotFound;
