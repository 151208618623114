import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/react';
import App from './App';
import * as serviceWorker from './serviceWorker';
// necessary for setting up @font-face in index.css
import './assets/fonts/Poppins-SemiBold.ttf';
import './assets/fonts/Poppins-Medium.ttf';
import './assets/fonts/Roboto-Regular.ttf';
import './assets/fonts/Roboto-Italic.ttf';
import './assets/fonts/Roboto-Bold.ttf';
import './assets/fonts/Roboto-BoldItalic.ttf';
import './index.scss';

const rateBasedOnEnvironment = environment => {
  if (environment === 'production') {
    return 0.2; // record 20% of errors/replays
  } else if (environment === 'local') {
    return 0.0; // do NOT record local errors/replays, otherwise drive up Sentry costs
  } else {
    // record everything on staging/testing for now
    return 1.0;
  }
};

Sentry.init({
  environment: process.env.REACT_APP_SENTRY_ENV,
  dsn: 'https://579fee3b7478468486225675138d395c@o269624.ingest.sentry.io/5303911',
  release: process.env.REACT_APP_BUILD_ID ? process.env.REACT_APP_BUILD_ID : 'local-build',
  integrations: [Sentry.replayIntegration(), Sentry.browserTracingIntegration()],
  // Session Replay
  replaysSessionSampleRate: rateBasedOnEnvironment(process.env.REACT_APP_SENTRY_ENV),
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // Performance Tracing
  tracesSampleRate: rateBasedOnEnvironment(process.env.REACT_APP_SENTRY_ENV),

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/huvrdata\.dev\/api/, /^https:\/\/huvrdata\.app\/api/],
});

const trackPageView = location => {
  // Send pageview with a custom path
  ReactGA.send({ hitType: 'pageview', page: location.pathname, title: 'Page View' });
};

const initializeGA = history => {
  console.log({ history });
  // Use Google Analytics Measurement ID
  ReactGA.initialize('G-XJP8YV2L3L');
  trackPageView(history.location);
  history.listen(location => {
    trackPageView(location);
  });
};
ReactDOM.render(<App initializeGA={initializeGA} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
