import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import ReactPlayer from 'react-player/lazy';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import SnapshotIcon from '@mui/icons-material/CameraEnhanceOutlined';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
import SpeedIcon from '@mui/icons-material/Speed';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import screenfull from 'screenfull';

import { secondsToDuration, formatSourceMediaTimestamp } from '../../utilities/strings';
import { captureVideoFrame, removeExtension } from '../../utilities/files';
import { openSnackbar } from '../../store/snackbarActions';
import { inspectionMediaCreateEndpoint } from '../../store/apiV2/inspectionMedia';
import { usePermissions, useFeatureFlags } from '../../hooks/settingsHooks';
import SecondaryButton from '../shared/buttons/SecondaryButton';

/**
 * VideoPlayer component.
 */

const useStyles = makeStyles(theme => ({
  videoContainer: {
    height: '85%',
    width: '95%',
    margin: 'auto',
    // paddingBottom: theme.spacing(2),
  },
}));

const VideoPlayer = props => {
  const { media: data, timestamp } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [playing, setPlaying] = useState(false); // set to true to auto-play
  const [isReady, setIsReady] = useState(false);
  const [volume, setVolume] = useState(0.8);
  const [muted, setMuted] = useState(false);
  const [duration, setDuration] = useState(0);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { hasChecklistEdit } = usePermissions();
  const { hasVideoExtractFrame } = useFeatureFlags();
  const { dispatchRequest: createInspectionMedia } = inspectionMediaCreateEndpoint.useEndpoint();

  const [progress, setProgress] = useState({
    seeking: false,
    played: 0, // fraction 0 - 1
    loaded: 0, // fraction 0 - 1
    duration: 0,
    loadedSeconds: 0,
  });
  const [settings, setSettings] = useState({
    controls: false,
    light: true,
    playbackRate: 1.0,
    loop: false,
  });

  const ref = useRef();

  const handleVolumeChange = (e, value) => {
    setVolume(parseFloat(value) / 100.0);
  };

  const handleToggleMuted = () => {
    setMuted(!muted);
  };

  const handleSetPlaybackRate = value => {
    setSettings({ ...settings, playbackRate: parseFloat(value) });
  };

  const handleReady = () => {
    // only go to timestamp from query when player is initially ready
    if (ref.current && !isReady) {
      ref.current.seekTo(timestamp);
      setIsReady(true);
    }
  };

  const handleSeek = e => {
    // console.log('onSeek', e);
  };

  const handlePlay = () => {
    setPlaying(true);
  };

  const handlePause = () => {
    setPlaying(false);
  };

  const handleSeekChange = (e, value) => {
    setProgress({ ...progress, seeking: true, played: parseFloat(value) });
  };
  const handleSeekMouseUp = (e, value) => {
    // handle SeekMouseUp input is value 0 - 1, store and set a fraction vs trying to convert to seconds
    ref.current.seekTo(parseFloat(value), 'fraction');
    setProgress({ ...progress, seeking: false, played: value });
  };

  const handleProgress = state => {
    // We only want to update time slider if we are not currently seeking

    if (!progress.seeking) {
      setProgress({
        ...progress,
        played: state.played,
        loaded: state.loaded,
        loadedSeconds: state.loadedSeconds,
        playedSeconds: state.playedSeconds,
      });
    }
  };

  const handleEnded = () => {
    console.log('onEnded');
    setPlaying(settings.loop);
  };

  const handleDuration = duration => {
    setDuration(duration);
  };

  const handleClickFullscreen = () => {
    if (screenfull.isEnabled) {
      // wrapper property accesses ReactPlayer DOM element
      screenfull.request(ref.current.getInternalPlayer() ?? ref.current.wrapper);
    } else {
      console.log('Full screen is not allowed');
    }
  };

  const displayProgress = value => {
    // convert value from 0-1 to seconds
    if (ref.current) {
      return secondsToDuration(parseFloat(value) * ref.current.getDuration(), true, 1);
    }
    return secondsToDuration(0, true, 1);
  };

  const showPlaybackSpeedMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const setPlaybackSpeed = value => {
    handleSetPlaybackRate(value);
    setAnchorEl(null);
  };

  const actionComponent = id => (
    <SecondaryButton label={'View'} onClick={() => history.push(`/inspection-media/${id}`)} />
  );

  const handleCaptureFrame = (video, media) => {
    const dataUri = captureVideoFrame(video);
    const timestamp = formatSourceMediaTimestamp(progress.playedSeconds);
    const filename = `${removeExtension(media.name)}-${timestamp}`;

    const payloadObject = {
      file_data: dataUri,
      filename,
      source_media: media.id,
      source_media_timestamp: timestamp,
      ...(media.project?.id && { project: media.project.id }),
      ...(media.checklist?.id && { checklist: media.checklist.id }),
    };

    const onSuccess = ({ data }) => {
      const action = actionComponent(data.id);
      dispatch(openSnackbar('Media successfully created.', 'info', null, 5000, action));
    };

    createInspectionMedia(payloadObject).then(onSuccess);
  };

  const speeds = [0.5, 0.75, 1, 1.25, 1.5, 2.0];
  const speedMenu = speeds.map(speed => {
    return (
      <MenuItem
        key={speed}
        onClick={() => {
          setPlaybackSpeed(speed);
        }}
        selected={speed === settings.playbackRate}>
        {speed}
      </MenuItem>
    );
  });

  const showSnapshotButton = hasChecklistEdit && hasVideoExtractFrame;

  return (
    <div className={classes.videoContainer} data-testid="video-player">
      <ReactPlayer
        ref={player => {
          ref.current = player;
        }}
        className="react-player"
        width="100%"
        height="100%"
        url={data.file}
        playing={playing}
        controls={settings.controls}
        playbackRate={settings.playbackRate}
        volume={volume}
        muted={muted}
        onReady={handleReady}
        onStart={() => console.log('onStart')}
        onPlay={handlePlay}
        onPause={handlePause}
        onBuffer={() => console.log('onBuffer')}
        onSeek={handleSeek}
        onEnded={handleEnded}
        onError={e => console.log('onError', e)}
        onProgress={handleProgress}
        onDuration={handleDuration}
        config={{
          file: {
            attributes: {
              // prevents CORS restrictions
              crossOrigin: 'anonymous',
            },
          },
        }}
      />

      <Grid container alignItems="center">
        <Grid item>
          {playing ? (
            <IconButton color="primary" aria-label="pause video" component="span" onClick={handlePause} size="large">
              <PauseIcon />
            </IconButton>
          ) : (
            <IconButton color="primary" aria-label="play video" component="span" onClick={handlePlay} size="large">
              <PlayArrowIcon />
            </IconButton>
          )}
        </Grid>
        <Grid item>
          {displayProgress(progress.played)} / {secondsToDuration(duration, true, 1)}
        </Grid>
        <Grid item>
          <IconButton color="primary" aria-label="mute" component="span" onClick={handleToggleMuted} size="large">
            {muted ? <VolumeOffIcon /> : <VolumeMuteIcon />}
          </IconButton>
        </Grid>
        <Grid item xs={1}>
          <Slider
            value={volume * 100}
            onChangeCommitted={handleVolumeChange}
            aria-label="volume"
            aria-labelledby="continuous-slider"
          />
        </Grid>
        <Grid item>
          <IconButton
            color="primary"
            aria-label="fullscreen"
            component="span"
            onClick={showPlaybackSpeedMenu}
            size="large">
            <SpeedIcon />
          </IconButton>
          <Menu
            id="playback-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(null);
            }}>
            <MenuItem disabled>Set the playback speed</MenuItem>
            {speedMenu}
          </Menu>
          <IconButton
            color="primary"
            aria-label="fullscreen"
            component="span"
            onClick={handleClickFullscreen}
            size="large">
            <FullscreenIcon />
          </IconButton>
          {showSnapshotButton && (
            <IconButton
              color="primary"
              aria-label="capture"
              component="span"
              onClick={() => handleCaptureFrame(ref.current.getInternalPlayer(), data)}
              size="large">
              <SnapshotIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs>
          <Slider
            disabled={!progress.loadedSeconds}
            value={progress.played}
            aria-labelledby="continuous-slider"
            min={0}
            max={1}
            step={0.0005}
            onChange={handleSeekChange}
            onChangeCommitted={handleSeekMouseUp}
            valueLabelFormat={displayProgress}
            valueLabelDisplay="auto"
          />
        </Grid>
      </Grid>
    </div>
  );
};

VideoPlayer.defaultProps = {
  timestamp: 0,
};

VideoPlayer.propTypes = {
  media: PropTypes.object.isRequired,
  timestamp: PropTypes.number,
};

export default VideoPlayer;
