import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { closeDialog } from '../../store/dialogActions';
import PrimaryButton from '../shared/buttons/PrimaryButton';
import SecondaryButton from '../shared/buttons/SecondaryButton';

const useStyles = makeStyles(theme => ({
  buttons: {
    marginTop: theme.spacing(2),
  },
}));

const AssessmentConfirmationDialog = props => {
  const { confirmLabel, onConfirm } = props;

  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <>
      <Typography variant="subtitle2">
        {`Please make sure you have saved your work by clicking on "Save Changes".
        You will lose any work that has not been saved upon exiting. Continue?`}
      </Typography>
      <Grid container justifyContent="flex-end" className={classes.buttons}>
        <Grid item xs={2}>
          <SecondaryButton label="Cancel" onClick={() => dispatch(closeDialog())} />
        </Grid>
        <Grid item xs={4}>
          <PrimaryButton
            type="submit"
            label={confirmLabel}
            onClick={() => {
              dispatch(closeDialog());
              onConfirm();
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

AssessmentConfirmationDialog.propTypes = {
  confirmLabel: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default AssessmentConfirmationDialog;
