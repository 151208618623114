import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { jsonKeyToLabel, apiDateToString } from '../../utilities/strings';
import { getReservation, createReservation, updateReservation } from '../../store/features/reservationsActions';
import { getReservationState } from '../../store/getters/reservationStateActions';
import CommonForm from '../shared/form/Common';
import { getAllUsers } from '../../store/features/usersActions';
import { fieldOrder, removeField, hideField, supportMsg } from './reservationsShared';
import { getUserOptionLabel } from '../../utilities/users';

const ReservationsForm = props => {
  const { update } = props;
  const params = useParams();
  const [submittedValues, setSubmittedValues] = useState({});
  const { data, loading, error, formError } = useSelector(state => state.reservations.each);
  const reservationUserOptions = data.assigned_to ? [getUserOptionLabel(data.assigned_to)] : [];
  const reservationStateOptions = useSelector(state => state.reservationState.data);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getReservationState());
    dispatch(getAllUsers({ is_staff: true }));
    if (update && params.id) {
      dispatch(getReservation(params.id));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const reservationPlaceholders = {
    phone_number: 'Enter the best phone number to contact you. Your account email will be included in this request.',
    work_scope:
      'Enter as much detail about work. For example: Repair list development - Perform UAS flight mission, 360 visual of topside and bottom side of wind girder',
    work_schedule: 'Enter timeframe for work to be completed.',
    work_site: 'Enter Site Name',
    work_provider: 'Approved 3rd Party Operator name, include contact number and email',
    asset_type: 'e.g. Tank Floating Roof',
    asset_number: 'e.g. Tank 007',
    special_instructions:
      'Enter any other details. (Optional) For example: Perform mid-day/reduce shadowing/sun. Site access POC: Name/email/number.',
    business_line: 'Enter business line (Optional)',
    note: 'Comment or updates (Optional)',
  };

  const businessLineOptions = [
    { label: 'Upstream', value: 'Upstream' },
    { label: 'Midstream', value: 'Midstream' },
    { label: 'Chemicals', value: 'Chemicals' },
    { label: 'Fuels & Lubes', value: 'Fuels & Lubes' },
  ];
  const formSettings = {
    fieldOrder,
    removeField: removeField(update ? 'UPDATE' : 'CREATE'),
    hideField,
  };

  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      // Object.keys(fieldOrder).forEach(sectionKey => fieldOrder[sectionKey].fields.forEach(key => {
      switch (key) {
        /* ---------- Required Fields ---------- */
        case 'work_schedule':
        case 'work_site':
        case 'phone_number':
        case 'asset_type':
        case 'asset_number':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              required: true,
              label: jsonKeyToLabel(key),
              name: key,
              placeholder: reservationPlaceholders[key],
            },
          };
          break;
        case 'state':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              required: true,
              label: 'State',
              name: 'state',
              options: reservationStateOptions,
              labelwidth: 35,
            },
          };
          break;
        case 'work_scope':
        case 'work_provider':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              required: true,
              label: jsonKeyToLabel(key),
              name: key,
              multiline: true,
              placeholder: reservationPlaceholders[key],
            },
          };
          break;
        /* ---------- Regular Fields ---------- */
        case 'business_line':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              required: true,
              label: jsonKeyToLabel(key),
              name: key,
              options: businessLineOptions,
              placeholder: reservationPlaceholders[key],
              labelwidth: 90,
            },
          };
          break;
        case 'special_instructions':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              multiline: true,
              placeholder: reservationPlaceholders[key],
            },
          };
          break;
        case 'assigned_to':
          fieldSettings[key] = {
            type: 'user-autocomplete',
            fieldProps: {
              label: 'Assigned To',
              name: key,
              initialUsers: reservationUserOptions,
              labelwidth: 80,
              multiple: false,
              queryParams: '?is_active=true&ordering=name',
            },
          };
          break;
        case 'created_on':
        case 'updated_on':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              disabled: true,
              label: jsonKeyToLabel(key),
              name: key,
              value: apiDateToString(data[key], 'date'),
            },
          };
          break;
        case 'note':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              multiline: true,
              placeholder: reservationPlaceholders[key],
            },
          };
          break;
        default:
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
      }
    });
  }

  const title = update ? 'Edit Reservation' : 'New Reservation';

  let initialValues = {};
  if (update) {
    initialValues = {
      ...data,
      note: '',
      assigned_to: String(data.assigned_to?.id),
    };
  }

  const onSubmit = changedValues => {
    const updatedValues = { ...changedValues };

    setSubmittedValues(updatedValues);
    if (update) {
      dispatch(updateReservation(updatedValues.id, updatedValues));
    } else {
      dispatch(createReservation(updatedValues));
    }
  };

  const decorators = [];

  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        if (values[key] === submittedValues[key]) {
          errors[key] = value;
        } else {
          errors[key] = undefined;
        }
      }
    }
    return errors;
  };

  return (
    <CommonForm
      update={update}
      title={title}
      initialValues={initialValues}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      onSubmit={onSubmit}
      decorators={decorators}
      validate={validate}
      loading={loading}
      error={error}
      helperText={supportMsg}
    />
  );
};

ReservationsForm.deafultProps = {
  update: false,
};

ReservationsForm.propTypes = {
  update: PropTypes.bool,
};

export default ReservationsForm;
