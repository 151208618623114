export const LOOKUP_RESET = 'LOOKUP_RESET';
export const LOOKUP_REQUEST = 'LOOKUP_REQUEST';
export const LOOKUP_SUCCESS = 'LOOKUP_SUCCESS';
export const LOOKUP_SET_ROUTE = 'LOOKUP_SET_ROUTE';
export const LOOKUP_CLEAR_ROUTE = 'LOOKUP_CLEAR_ROUTE';
export const LOOKUP_FAILURE = 'LOOKUP_FAILURE';

export const LOGINOUT_RESET = 'LOGINOUT_RESET';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const SET_LANDING_URL = 'SET_LANDING_URL';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const HANDLE_UNAUTHORIZED = 'HANDLE_UNAUTHORIZED';
export const HANDLE_UNAUTHORIZED_COMPLETED = 'HANDLE_UNAUTHORIZED_COMPLETED';

export const REFRESH_SESSION_COOKIE_REQUEST = 'REFRESH_SESSION_COOKIE_REQUEST';
export const REFRESH_SESSION_COOKIE_SUCCESS = 'REFRESH_SESSION_COOKIE_SUCCESS';
export const REFRESH_SESSION_COOKIE_FAILURE = 'REFRESH_SESSION_COOKIE_FAILURE';

export const resetLookup = () => {
  return {
    type: LOOKUP_RESET,
  };
};

export const requestLookup = (email, route = '') => {
  return {
    type: LOOKUP_REQUEST,
    email,
    route,
  };
};

export const setRoute = route => {
  return {
    type: LOOKUP_SET_ROUTE,
    route,
  };
};

export const resetLogInOut = () => {
  return {
    type: LOGINOUT_RESET,
  };
};

export const requestLogin = route => {
  return {
    type: LOGIN_REQUEST,
    route,
  };
};

export const loginFailure = error => {
  return {
    type: LOGIN_FAILURE,
    error,
  };
};

export const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST,
    resetLookup: true,
  };
};

export const requestRefreshSessionCookie = () => {
  return {
    type: REFRESH_SESSION_COOKIE_REQUEST,
    resetLookup: true,
  };
};

export const setLandingUrl = url => ({
  type: SET_LANDING_URL,
  url,
});
