import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Chip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import StyledLink from '../StyledLink';

const useStyles = makeStyles(theme => ({
  chips: {
    marginTop: '3px',
  },
}));

const makeItems = value => {
  // handle case when data is string vs array
  if (typeof value === 'string') {
    return value.split(',').map(item => item.trim());
  } else if (Array.isArray(value)) {
    // Check if it's an array, Use filter to remove null values
    return value.filter(item => item !== null);
  }
  return value;
};

const displayValue = value => {
  if (typeof value === 'string') {
    return value;
  }
  if (Object.prototype.hasOwnProperty.call(value, 'name')) {
    return value['name'];
  }
  return value;
};

const ViewDisplayChips = props => {
  const { label, value, display, placeholder, link } = props;
  const classes = useStyles();
  const items = makeItems(value);

  return (
    <>
      {label ? <div className="h7">{label}</div> : <></>}
      <Grid container spacing={1} className={classes.chips}>
        {items.length ? (
          items.map((item, index) => (
            <Grid item key={`${item}-${index}`}>
              {link ? (
                <StyledLink to={`${link}/${item.id}`}>
                  <Chip size="small" variant="outlined" label={display(item)} />
                </StyledLink>
              ) : (
                <Chip size="small" variant="outlined" label={display(item)} />
              )}
            </Grid>
          ))
        ) : (
          <Grid item>
            <Chip size="small" variant="outlined" label={placeholder} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

ViewDisplayChips.defaultProps = {
  value: [],
  label: '',
  display: displayValue,
  placeholder: '--',
};

ViewDisplayChips.propTypes = {
  /** Optional label to add in front of the chips */
  label: PropTypes.string,
  /** Optional link on the chip */
  link: PropTypes.string,
  /** Array of strings or comma separated string */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object),
  ]),
  /** Set placeholder for empty value */
  placeholder: PropTypes.string,
  /** Set the contents of the chip with a callback */
  display: PropTypes.func,
};

export default ViewDisplayChips;
