import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Loading from '../displays/Loading';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  iframeStyle: {
    width: '100%',
    height: 'calc(100vh - 70px)',
  },
}));

const RBLXIFramePage = props => {
  const classes = useStyles();
  const assetStore = useSelector(state => state.assets.each);
  const assessmentStore = useSelector(state => state.assessments.each);

  // (these will be triggered by the `AssessmentHeader` , which contains most of the logic)
  if (assetStore.loading || assessmentStore.loading) {
    return <Loading />;
  }

  return (
    <div className={classes.root}>
      <iframe
        className={classes.iframeStyle}
        title="RBLX Embed"
        src={props.data.source_url}
        data-testid="rblx-iframe-element"></iframe>
    </div>
  );
};

RBLXIFramePage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default RBLXIFramePage;
