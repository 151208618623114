import { requestFactory, responseHandlerForm } from '../base';

const get = requestFactory('get');
const post = requestFactory('post', { handler: responseHandlerForm });
const patch = requestFactory('patch', { handler: responseHandlerForm });
const del = requestFactory('del');

export const fetchScheduleWorkList = async query => get('/api/schedule/plan-work/', query);
export const fetchScheduleWork = async id => get(`/api/schedule/plan-work/${id}/`);
export const createScheduleWork = async body => post(`/api/schedule/plan-work/`, body);
export const updateScheduleWork = async (id, body) => patch(`/api/schedule/plan-work/${id}/`, body);
export const deleteScheduleWork = async id => del(`/api/schedule/plan-work/${id}/`);
