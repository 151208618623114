import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Toc } from '@mui/icons-material';
import HistoryIcon from '@mui/icons-material/History';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { Toolbar, Badge, Typography, AppBar, Link } from '@mui/material';
import Button from '@mui/material/Button';
import RevisionDrawer from './RevisionDrawer';
import SectionDrawer from './nav/SectionDrawer';
import ButtonIcon from '../shared/buttons/ButtonIcon';
import RefreshButton from '../shared/buttons/RefreshButton';

const ChecklistToolbar = props => {
  const history = useHistory();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const logo = useSelector(state => state.settings.logo.src);

  const { revisions, selectedRevision, setRevisionId, title, checklist, backLink } = props;

  const toggleDrawer = (anchor, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const handleBack = () => history.push(backLink);

  return (
    <AppBar className="app-bar" elevation={1}>
      <Toolbar>
        {backLink ? (
          <ButtonIcon buttonSize="medium" iconSize="medium" icon={ArrowBackIosIcon} onClick={handleBack} />
        ) : (
          <Link href="/">
            <img src={logo} height="32" style={{ marginRight: '5px' }} />
          </Link>
        )}
        <Typography align="left" variant="h6" noWrap style={{ flex: 1 }}>
          {' '}
          {title}
        </Typography>
        <Button
          aria-label="Show Table of Contents"
          color="inherit"
          onClick={toggleDrawer('left', true)}
          startIcon={<Toc />}>
          Outline
        </Button>
        <Button aria-label="Show Version History" color="inherit" onClick={toggleDrawer('right', true)}>
          <Badge badgeContent={revisions.length} invisible={revisions.length === 1} color="primary">
            <HistoryIcon />
          </Badge>
        </Button>
        <RefreshButton showMenu refresh={props.refresh} />
      </Toolbar>
      <RevisionDrawer
        revisions={revisions}
        selectedRevision={selectedRevision}
        setRevisionId={setRevisionId}
        anchor="right"
        open={state['right']}
        closeDrawer={() => toggleDrawer('right', false)}
      />
      {checklist && (
        <SectionDrawer
          anchor="left"
          open={state['left']}
          closeDrawer={() => toggleDrawer('left', false)}
          checklist={checklist}
          currentSection={props.currentSection}
          setSection={props.setSection}
          openSections={props.openSections}
          setOpenSections={props.setOpenSections}
        />
      )}
    </AppBar>
  );
};

ChecklistToolbar.propTypes = {
  selectedRevision: PropTypes.object.isRequired,
  revisions: PropTypes.array.isRequired,
  setRevisionId: PropTypes.func.isRequired,
  title: PropTypes.object,
  checklist: PropTypes.object,
  currentSection: PropTypes.string,
  setSection: PropTypes.func.isRequired,
  openSections: PropTypes.array.isRequired,
  setOpenSections: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  backLink: PropTypes.string,
};
ChecklistToolbar.defaultProps = {
  selectedRevision: { id: 'latest' },
  revisions: [],
  title: <></>,
  currentSection: '',
  backLink: '',
};

export default ChecklistToolbar;
