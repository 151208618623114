import React from 'react';
import PropTypes from 'prop-types';
import StopIcon from '@mui/icons-material/Stop';
import { useSelector } from 'react-redux';
import { conditionLookup } from '../../../utilities/assetCondition';

/**
 * AssetConditionDisplay component that takes an asset condition number and renders a colored Materal-UI
 * Icon and the display text of an the AssetCondition
 * @module AssetConditionDisplay
 * @param {number} condition - the numeric value of the assetCondition.
 */

const AssetConditionDisplay = props => {
  const { condition } = props;
  const cond = conditionLookup(
    condition,
    useSelector(state => state.settings.features.assetConditions)
  );
  if (condition !== -1 && cond) {
    return (
      <span>
        <StopIcon style={{ color: cond.color, verticalAlign: 'middle' }} /> {cond.description}
      </span>
    );
  }
  return '';
};

AssetConditionDisplay.propTypes = {
  /** the numeric value of the assetCondition */
  condition: PropTypes.number.isRequired,
};

export default AssetConditionDisplay;
