import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Grid, IconButton, ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

// Components
import Table from './Table';

// Util/Store
import { noThumbnail } from '../../../utilities/files';

const ORANGE = '#f26822';

const useStyles = () => () =>
  makeStyles(theme => ({
    root: {
      // inspector
      display: 'flex',
      overflow: 'hidden',
    },
    gridItem: {
      width: '400px',
    },
    thumbnail: {
      width: '100%',
      height: 'auto',
      maxHeight: '300px',
    },
    titleBar: {
      background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
  }));

const GalleryTableBody = props => {
  const classes = useStyles();
  const { options } = props;
  const { onSelectMediaObject, isMediaObjectSelected, numMediaPerRow, getMediaObject } =
    options.galleryTableOptions || {};

  /**
   * Copied from mui-datatables
   * https://github.com/gregnb/mui-datatables/blob/02980525e1cb72cc895fa73ad3504567855fab3c/src/utils.js#L32-L37
   */
  const _getPageValue = (count, rowsPerPage, page) => {
    //
    const totalPages = count <= rowsPerPage ? 1 : Math.ceil(count / rowsPerPage);

    // `page` is 0-indexed
    return page >= totalPages ? totalPages - 1 : page;
  };

  /**
   * Copied from mui-datatables
   * https://github.com/gregnb/mui-datatables/blob/91160b220187ad5366a89f476c42ed94c1c34cdd/src/components/TableBody.js#L68-L87
   */
  const getPaginatedData = () => {
    const { data, page, rowsPerPage, count } = props;

    const rows = [];
    const highestPageInRange = _getPageValue(count, rowsPerPage, page);
    const fromIndex = highestPageInRange === 0 ? 0 : highestPageInRange * rowsPerPage;
    const toIndex = Math.min(count, (highestPageInRange + 1) * rowsPerPage);

    if (page > highestPageInRange) {
      console.warn('Current page is out of range, using the highest page that is in range instead.');
    }

    for (let rowIndex = fromIndex; rowIndex < count && rowIndex < toIndex; rowIndex++) {
      if (data[rowIndex] !== undefined) rows.push(data[rowIndex]);
    }

    return rows;
  };

  /**
   * @description Render the image list item
   * @param {Object} mediaObj
   * @param {Boolean} isSelected
   * @returns {JSX}
   */
  const renderImageListItem = (mediaObj, isSelected) => {
    return (
      <ImageListItem className={classes.gridItem}>
        <img src={mediaObj.thumbnail || noThumbnail(mediaObj.document_category)} alt={mediaObj.name} />
        <ImageListItemBar
          title={mediaObj.name}
          className={classes.titleBar}
          actionIcon={
            <IconButton
              aria-label={mediaObj.name}
              onClick={() => {
                if (onSelectMediaObject) {
                  onSelectMediaObject(mediaObj);
                }
              }}>
              {isSelected ? <CheckBoxIcon color="primary" /> : <CheckBoxOutlineBlankIcon color="primary" />}
            </IconButton>
          }
        />
      </ImageListItem>
    );
  };

  /**
   * @description Build the image list
   * @returns {JSX}
   */
  const renderImageList = rows => {
    return (
      <ImageList className={classes.gridList} cols={numMediaPerRow} gap={10}>
        {rows.map(({ data: rowData }) => {
          const mediaObj = getMediaObject(rowData);
          if (!mediaObj) {
            console.warn('No media object found for row data', rowData);
            return null;
          }

          const isSelected = isMediaObjectSelected && isMediaObjectSelected(mediaObj);
          return (
            <div
              key={mediaObj.id}
              style={{
                border: isSelected ? `5px solid ${ORANGE}` : null,
              }}>
              {renderImageListItem(mediaObj, isSelected)}
            </div>
          );
        })}
      </ImageList>
    );
  };

  return (
    <tbody>
      <tr>
        <td>
          <Grid container>{renderImageList(getPaginatedData())}</Grid>
        </td>
      </tr>
    </tbody>
  );
};

GalleryTableBody.propTypes = {
  /** Data used to describe table */
  data: PropTypes.array.isRequired,
  /** Columns used to describe table */
  columns: PropTypes.array.isRequired,
  /** Options used to describe table */
  options: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
};

export const GalleryTable = props => {
  const { options, onSelectMediaObject, numMediaPerRow, isMediaObjectSelected, getMediaObject, ...restProps } = props;
  return (
    <Table
      {...restProps}
      options={{
        ...options,
        galleryTableOptions: {
          onSelectMediaObject,
          isMediaObjectSelected,
          getMediaObject,
          numMediaPerRow,
        },
      }}
      components={{
        TableBody: GalleryTableBody,
        // Don't render the table head
        TableHead: props => {
          return <thead></thead>;
        },
      }}
    />
  );
};

GalleryTable.propTypes = {
  ...Table.propTypes,

  /** number of items per row in the gallery */
  numMediaPerRow: PropTypes.number.isRequired,

  /** function to call to get the media object */
  getMediaObject: PropTypes.func.isRequired,

  /** function to call when a media object is selected */
  onSelectMediaObject: PropTypes.func.isRequired,

  /** function to call to determine if a media object is selected */
  isMediaObjectSelected: PropTypes.func.isRequired,
};

export default GalleryTable;
