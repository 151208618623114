import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// lib
import moment from 'moment';
import { DatePicker } from 'mui-rff';
import { useForm, useField } from 'react-final-form';

// components
import Markdown from '../../shared/Markdown';

const ISO_DATE = 'YYYY-MM-DD';

/**
 *
 */
const DateLineItem = props => {
  const { name, label, value, placeholder, loading, disabled, multiline, helperText, validator } = props;

  // subscribe to form state,
  //  mutate to use only date string rather than full object
  //  (see `useEffect` below)
  const field = useField(name); // https://final-form.org/docs/react-final-form/api/useField
  const form = useForm(); // https://final-form.org/docs/final-form/types/FormApi

  // to use the form values, the value prop needs to be missing (can't be undefined or empty string)
  // only show the value if it is passed in as a prop.
  const moreProps = {};
  if (value) moreProps.value = value;

  //
  // ---- effects

  useEffect(() => {
    // Do not store as a moment object, (which contains date, time, and time-zone)
    //  only store DATE as string
    if (moment.isMoment(field?.input?.value)) {
      form.mutators.setFieldValue(name, field.input.value.format(ISO_DATE));
    }
  }, [field?.input?.value]); // eslint-disable-line react-hooks/exhaustive-deps

  //
  // --- render

  return (
    <DatePicker
      label={label}
      name={name}
      placeholder={placeholder}
      format="MM/DD/yyyy"
      multiline={multiline}
      helperText={<Markdown>{helperText}</Markdown>}
      disabled={disabled || loading}
      inputVariant="filled"
      InputLabelProps={{ shrink: true }}
      fieldProps={{ validate: validator }}
      {...moreProps}
    />
  );
};

DateLineItem.defaultProps = {
  disabled: false,
  required: false,
  multiline: false,
  value: undefined,
  placeholder: '',
  helperText: '',
  loading: false,
  validator: () => {},
};

DateLineItem.propTypes = {
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  multiline: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  loading: PropTypes.bool,
  validator: PropTypes.func,
};

export default DateLineItem;
