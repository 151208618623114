import React, { useEffect } from 'react';
import { Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';

// components
import ChecklistResultLineTable from './ChecklistResultLineTable';

// util
import { checklistResultLinesListEndpoint } from '../../store/apiV2/checklistResultLines';
import { tableViews } from '../../utilities/tables';
import { useTableViews } from '../../hooks/settingsHooks';
import { setTableParamObj } from '../../utilities/objects';
import { toQueryString } from '../../utilities/strings';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const ChecklistResultLineTablePage = () => {
  const checklistResultLineList = checklistResultLinesListEndpoint.useEndpoint();
  const history = useHistory();

  const classes = useStyles();

  const { checklistResultLinesTableViewKey } = tableViews;
  const { pageSize } = useTableViews(checklistResultLinesTableViewKey);
  const queryParams = setTableParamObj(location, pageSize);

  useEffect(() => {
    checklistResultLineList.dispatchRequest(queryParams);
  }, []); // eslint-disable-line

  const tableChangeHandler = query => {
    history.push({ search: toQueryString(query) });
    checklistResultLineList.dispatchRequest(query);
  };

  return (
    <Paper className={classes.root}>
      <ChecklistResultLineTable
        tableChangeHandler={tableChangeHandler}
        title="Checklist Results"
        queryParams={queryParams}
      />
    </Paper>
  );
};

export default ChecklistResultLineTablePage;
