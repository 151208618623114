import { all, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  ASSET_TYPES_REQUEST,
  ASSET_TYPES_SUCCESS,
  ASSET_TYPES_FAILURE,
  ASSET_TYPES_CREATE_REQUEST,
  ASSET_TYPES_CREATE_SUCCESS,
  ASSET_TYPES_CREATE_FAILURE,
  ASSET_TYPES_UPDATE_REQUEST,
  ASSET_TYPES_UPDATE_SUCCESS,
  ASSET_TYPES_UPDATE_FAILURE,
  ASSET_TYPES_DELETE_REQUEST,
  ASSET_TYPES_DELETE_SUCCESS,
  ASSET_TYPES_DELETE_FAILURE,
  ASSET_TYPES_ALL_REQUEST,
  ASSET_TYPES_ALL_SUCCESS,
  ASSET_TYPES_ALL_FAILURE,
} from './assetTypesActions';

import {
  fetchAssetTypes,
  createAssetTypes,
  updateAssetTypes,
  deleteAssetTypes,
  fetchAssetTypesList,
} from '../../api/features/assetTypes';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';
import { buildRoute } from '../../utilities/strings';
import { fetchListAll } from '../../utilities/sagaHelpers';
import { DIALOG_CLOSE } from '../../store/dialogActions';

export function* assetTypesFetch(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield fetchAssetTypes(id);
    if (data) {
      yield put({ type: ASSET_TYPES_SUCCESS, data });
    } else if (error) {
      yield put({ type: ASSET_TYPES_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, ASSET_TYPES_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, ASSET_TYPES_FAILURE);
  }
}

export function* watchAssetTypesFetch() {
  yield takeLatest(ASSET_TYPES_REQUEST, assetTypesFetch);
}

export function* assetTypesCreate(action) {
  const { body } = action;
  try {
    const { data, error, formError, response } = yield createAssetTypes(body);
    if (data) {
      yield put({ type: ASSET_TYPES_CREATE_SUCCESS, data });
      yield put(push(buildRoute('/asset-types')));
    } else if (error) {
      yield put({ type: ASSET_TYPES_CREATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: ASSET_TYPES_CREATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, ASSET_TYPES_CREATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, ASSET_TYPES_CREATE_FAILURE);
  }
}

export function* watchAssetTypesCreate() {
  yield takeLatest(ASSET_TYPES_CREATE_REQUEST, assetTypesCreate);
}

export function* assetTypesUpdate(action) {
  const { id, body } = action;
  try {
    const { data, error, formError, response } = yield updateAssetTypes(id, body);
    if (data) {
      yield put({ type: ASSET_TYPES_UPDATE_SUCCESS, data });
      yield put(push(buildRoute('/asset-types')));
    } else if (error) {
      yield put({ type: ASSET_TYPES_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: ASSET_TYPES_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, ASSET_TYPES_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, ASSET_TYPES_UPDATE_FAILURE);
  }
}

export function* watchAssetTypesUpdate() {
  yield takeLatest(ASSET_TYPES_UPDATE_REQUEST, assetTypesUpdate);
}

export function* assetTypesDelete(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield deleteAssetTypes(id);
    if (data) {
      yield put({ type: ASSET_TYPES_DELETE_SUCCESS });
      yield put({ type: ASSET_TYPES_ALL_REQUEST });
      yield put({ type: DIALOG_CLOSE });
      yield put(push(buildRoute('/asset-types')));
    } else if (error) {
      yield put({ type: ASSET_TYPES_DELETE_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, ASSET_TYPES_DELETE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, ASSET_TYPES_DELETE_FAILURE);
  }
}

export function* watchAssetTypesDelete() {
  yield takeLatest(ASSET_TYPES_DELETE_REQUEST, assetTypesDelete);
}

export function* assetTypesFetchAll(action) {
  try {
    const { data, error, response } = yield fetchListAll(fetchAssetTypesList, action.query);
    if (data) {
      yield put({ type: ASSET_TYPES_ALL_SUCCESS, data });
    } else if (error) {
      yield put({ type: ASSET_TYPES_ALL_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, ASSET_TYPES_ALL_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, ASSET_TYPES_ALL_FAILURE);
  }
}

export function* watchAssetTypesFetchAll() {
  yield takeLatest(ASSET_TYPES_ALL_REQUEST, assetTypesFetchAll);
}

export default function* assetTypesSaga() {
  yield all([
    watchAssetTypesFetch(),
    watchAssetTypesCreate(),
    watchAssetTypesUpdate(),
    watchAssetTypesDelete(),
    watchAssetTypesFetchAll(),
  ]);
}
