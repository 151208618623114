import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  selectedList: {
    listStyleType: 'circle',
    // margin: theme.spacing(1),
  },
  findingsText: {
    fontSize: theme.spacing(2),
    fontWeight: 'bold',
  },
}));

const FindingsProjectSummary = ({ findings, projectsToCreate, isSingleProject, parent }) => {
  const classes = useStyles();

  const renderFinding = id => {
    const finding = findings.find(d => d.id.toString() === id);
    return (
      <Typography gutterBottom className={classes.findingsText}>
        {`Finding: ID ${finding.id} / ${finding.asset.name} / ${finding.component_display}`}
      </Typography>
    );
  };

  return (
    <Grid container direction="column">
      <Typography gutterBottom variant="h5">
        Summary
      </Typography>
      <br />
      <Typography variant="h6" gutterBottom>
        {`Creating ${projectsToCreate.length} Project${isSingleProject ? '' : 's'}`}
        {parent && ` with Parent ${parent.parentName}`}
      </Typography>
      {projectsToCreate.map((p, i) => (
        <ul key={i} className={classes.selectedList}>
          <Typography gutterBottom variant="h6">{`Project: ${p.name}`}</Typography>
          {
            isSingleProject
              ? findings.map((f, i) => (
                  <Typography key={i} className={classes.findingsText} gutterBottom>
                    {`Finding: ID ${f.id} / ${f.asset.name} / ${f.component_display}`}
                  </Typography>
                ))
              : renderFinding(p.scope.selected_findings[0]) // currently one finding per project
          }
        </ul>
      ))}
    </Grid>
  );
};

FindingsProjectSummary.propTypes = {
  findings: PropTypes.array.isRequired,
  projectsToCreate: PropTypes.array.isRequired,
  isSingleProject: PropTypes.bool.isRequired,
  parent: PropTypes.object,
};

export default FindingsProjectSummary;
