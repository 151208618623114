const DECIMAL_PLACES = 6;

/** Format float to a set number of decimal places or fewer.
 * Ex: 10.11122233 -> 10.111222, 10.123 -> 10.123
 */
function toFixedOrFewerDecimalPlaces(num) {
  if (typeof num !== 'number') return num;
  const decimals = num.toString().split('.')[1]?.length;
  return decimals > DECIMAL_PLACES ? num.toFixed(DECIMAL_PLACES) : num;
}

class GeoPoint {
  constructor(coordinates) {
    // handle GeoJSON object that has coordinates property
    if (typeof coordinates === 'object' && Array.isArray(coordinates?.coordinates)) {
      coordinates = coordinates.coordinates;
    }
    // handle string of coordinates
    if (typeof coordinates === 'string') {
      const [lat, lon, alt] = coordinates.split(',').map(coord => parseFloat(coord));
      coordinates = [lon, lat, alt]; // reorder to lon, lat, alt
    }
    if (!Array.isArray(coordinates) || coordinates.length < 2 || coordinates.length > 3) {
      return;
    }
    [this.lon, this.lat, this.alt] = coordinates;
  }

  /** Display as a string of format 'lat, lon' or 'lat, lon, alt' */
  toString() {
    if (this.lat === undefined || this.lon === undefined) return null;
    if (this.alt !== undefined) {
      return `${this.lat}, ${this.lon}, ${this.alt}`;
    }
    return `${this.lat}, ${this.lon}`;
  }

  /** Display as user-friendly shortened values (lat, lon, alt) */
  toShortString() {
    if (this.lat === undefined || this.lon === undefined) return '';
    const lat = toFixedOrFewerDecimalPlaces(this.lat);
    const lon = toFixedOrFewerDecimalPlaces(this.lon);
    if (this.alt !== undefined) {
      const alt = toFixedOrFewerDecimalPlaces(this.alt);
      return `${lat}, ${lon}, ${alt}`;
    }
    return `${lat}, ${lon}`;
  }

  /** Display as GeoJSON format for huvr_api */
  toGeoJSON() {
    const coord = this.alt !== undefined ? [this.lon, this.lat, this.alt] : [this.lon, this.lat];
    return {
      type: 'Point',
      coordinates: coord,
    };
  }
}

export default GeoPoint;
