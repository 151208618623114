import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';
import ChecklistSection from '../checklistEditor/ChecklistSection';
import ChecklistSectionPlacement from './ChecklistSectionPlacement';

function ChecklistPanel(props) {
  const { index, section, value, currentSection, defaultActions, readOnly, override, ...other } = props; // ...other

  const renderSection = (section, index) => {
    if (section.type === 'placement') {
      return (
        <Paper style={{ height: '80vh' }}>
          <ChecklistSectionPlacement
            {...section}
            sectionId={section.key}
            defaultActions={defaultActions}
            readOnly={readOnly}
            override={override}
          />
        </Paper>
      );
    }
    return (
      <Paper>
        <ChecklistSection
          {...section}
          sectionId={section.key}
          defaultActions={defaultActions}
          readOnly={readOnly}
          override={override}
        />
      </Paper>
    );
  };
  return (
    <div
      role="tabpanel"
      hidden={value !== currentSection}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      {renderSection(section, index)}
    </div>
  );
}

ChecklistPanel.propTypes = {
  index: PropTypes.number,
  currentSection: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  section: PropTypes.object.isRequired,
  defaultActions: PropTypes.array,
  readOnly: PropTypes.bool.isRequired,
  override: PropTypes.bool.isRequired,
};
ChecklistPanel.defaultProps = { defaultActions: [] };

export default ChecklistPanel;
