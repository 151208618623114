import React from 'react';
import PropTypes from 'prop-types';
import { fieldOrder, removeField, hideField, displayRole } from '../users/usersShared';
import { apiDateToString, jsonKeyToLabel } from '../../utilities/strings';
import CommonFormView from '../shared/form/CommonView';

const User = ({ data, loading, error }) => {
  const formSettings = {
    fieldOrder,
    removeField: removeField('VIEW'),
    hideField,
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      // cast to string because booleans do not show up in the field
      let displayLabel = jsonKeyToLabel(key); // eslint-disable-line prefer-const
      let displayValue = '';
      if (data && data[key] != null) {
        switch (key) {
          // add cases for each key that needs to be customized.
          case 'company':
            fieldSettings[key] = {
              type: 'chips',
              fieldProps: {
                label: displayLabel,
                value: data[key],
                display: value => {
                  return value.name;
                },
              },
            };
            return;

          case 'roles':
            fieldSettings[key] = {
              type: 'chips',
              fieldProps: {
                label: displayLabel,
                value: data[key],
                display: value => {
                  return displayRole(value);
                },
              },
            };
            return;
          case 'last_login':
          case 'date_joined':
            fieldSettings[key] = {
              type: 'text',
              fieldProps: {
                label: displayLabel,
                value: apiDateToString(data[key], 'short-timestamp'),
              },
            };
            return;
          default:
            displayValue = data[key].toString();
        }
      }
      fieldSettings[key] = {
        type: 'text',
        fieldProps: {
          label: displayLabel,
          value: displayValue,
        },
      };
    });
  }

  return (
    <CommonFormView
      bodyOnly
      title={'User Profile'}
      values={data}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      // editLink={buildRoute(`/users/${id}/edit`)}
      loading={loading}
      error={error}
    />
  );
};

User.defaultProps = {
  data: {},
  loading: false,
  error: '',
};

User.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
};
export default User;
