import { isNil } from 'lodash';
import { arrayFunctionCheck } from './arrays';
import { latLongRegex } from './strings';
import { CHECKLIST_LINE_TYPES } from '../utilities/checklist';

export const defaultErrors = {
  required: 'Required field.',
  number: 'Please enter a number.',
  integer: 'Please enter an integer.',
  float: 'Please enter a decimal number.',
  max: 'Maximum value exceeded.',
  min: 'Beneath minimum value.',
};

export const validateLatLongFn = value => {
  if (value && !value.match(latLongRegex)) {
    return 'Please enter a valid value for Lat/Long.';
  }
  return undefined;
};

export const requiredFn = required => value => {
  if (required) {
    return value ? undefined : defaultErrors.required;
  }
  return undefined;
};

export const requiredValidator = (value, validatorArgs, lineType) => {
  let invalid = false;
  switch (lineType) {
    case CHECKLIST_LINE_TYPES.MULTIPLE_CHOICE:
      invalid = isNil(value) || !value?.length;
      break;
    case CHECKLIST_LINE_TYPES.SIGNATURE:
      if (isNil(value)) {
        invalid = true;
      }
      break;
    default:
      invalid = !value;
  }
  return invalid ? validatorArgs.message || defaultErrors.required : undefined;
};

export const numberValidator = (value, validatorArgs) => {
  if (isNil(value) || value === '') return undefined;

  const failedReturn = validatorArgs?.message || defaultErrors.number;

  if (!['string', 'number'].includes(typeof value)) {
    return failedReturn;
  }

  if (value === '') return undefined;

  if (isNaN(value) || isNaN(parseFloat(value))) {
    return failedReturn;
  }

  return undefined;
};

export const integerValidator = (value, validatorArgs) => {
  if (isNil(value) || value === '') return undefined;

  if (value && !Number.isInteger(parseFloat(value))) {
    return validatorArgs?.message || defaultErrors.integer;
  }
  return undefined;
};

export const floatValidator = (value, validatorArgs) => {
  if (isNil(value)) return undefined;

  const failedReturn = validatorArgs?.message || defaultErrors.float;
  if (!['string', 'number'].includes(typeof value)) {
    return failedReturn;
  }

  if (value === '') return undefined;

  if (isNaN(value) || isNaN(parseFloat(value))) {
    return failedReturn;
  }

  return undefined;
};

export const maxValidator = (value, validatorArgs) => {
  if (isNil(value) || value === '') return undefined;

  const numberValidatorError = numberValidator(value);
  if (numberValidatorError) return numberValidatorError;

  if (value > validatorArgs.value) {
    return validatorArgs?.message || defaultErrors.max;
  }

  return undefined;
};

export const minValidator = (value, validatorArgs) => {
  if (isNil(value) || value === '') return undefined;

  const numberValidatorError = numberValidator(value);
  if (numberValidatorError) return numberValidatorError;

  if (value < validatorArgs.value) {
    return validatorArgs.message || defaultErrors.min;
  }
  return undefined;
};

export const getValidators = (validators, lineType) =>
  arrayFunctionCheck(validators)
    ? validators.map(validator => {
        switch (validator.type) {
          case 'number':
            return value => numberValidator(value, validator);
          case 'float':
            return value => floatValidator(value);
          case 'geo':
            return value => validateLatLongFn(value);
          case 'integer':
            return value => integerValidator(value, validator);
          case 'required':
            return value => requiredValidator(value, validator, lineType);
          case 'max':
            return value => maxValidator(value, validator);
          case 'min':
            return value => minValidator(value, validator);
          default:
            return () => undefined;
        }
      })
    : [];

export const composeValidators =
  (...validatorFns) =>
  value => {
    if (!arrayFunctionCheck(validatorFns)) return undefined;
    const validated = validatorFns.reduce((error, validator) => {
      if (validator) {
        const acc = validator(value) || error;
        return acc;
      }
      return error;
    }, undefined);
    return validated;
  };
