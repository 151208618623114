import React from 'react';
import PropTypes from 'prop-types';
import StyledLink from '../StyledLink';

const ProjectTitle = props => {
  const { id, asset, name, type, media, current, count, link } = props;
  if (!name || !asset || !type) return <>...</>;

  const projectType = type ? `(${type.name})` : '';
  const projectName = link && id ? <StyledLink to={`/projects/${id}`} value={name} /> : name;

  const namePath = media => {
    if (!media) return <></>;
    const items = [];
    if (media.component) {
      items.push(<>{media.component}</>);
    }
    if (media.location_zone) {
      items.push(<>{media.location_zone}</>);
    }
    if (media.location_code) {
      items.push(<>{media.location_code}</>);
    }
    items.push(<>{media.name}</>);
    return items.map((item, index) => <React.Fragment key={index}>/ {item} </React.Fragment>);
  };

  return (
    <>
      {asset ? asset.asset_path : ''} / {projectName} {projectType} {namePath(media)} ({current}/{count})
    </>
  );
};

ProjectTitle.defaultProps = {
  media: null,
  name: undefined,
  asset: undefined,
  type: undefined,
  link: false,
};
ProjectTitle.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  asset: PropTypes.object,
  type: PropTypes.object,
  media: PropTypes.object,
  link: PropTypes.bool,
  current: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
};

export default ProjectTitle;
