import { USAGE_STATS_REQUEST, USAGE_STATS_SUCCESS, USAGE_STATS_FAILURE } from './usageStatsActions';

export const initialStateUsageStats = {
  loading: false,
  error: '',
  data: [],
};

export const usageStatsReducer = (state = initialStateUsageStats, action) => {
  switch (action.type) {
    case USAGE_STATS_REQUEST:
      return {
        ...initialStateUsageStats,
        loading: true,
      };
    case USAGE_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case USAGE_STATS_FAILURE:
      return {
        ...initialStateUsageStats,
        error: action.error,
      };
    default:
      return state;
  }
};

export default usageStatsReducer;
