// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import React from 'react';
import CommonRoutes from './utilities/CommonRoutes';
import MeasurementsForm from '../../components/measurements/MeasurementsForm';
import MeasurementsView from '../../components/measurements/MeasurementsView';
import MeasurementsTablePage from '../../components/measurements/MeasurementsTablePage';
import MainLayout from '../layouts/MainLayout';

const MeasurementsRoutes = props => {
  return (
    <CommonRoutes
      pageTitle="Measurements"
      layout={MainLayout}
      Edit={<MeasurementsForm update />}
      Create={<MeasurementsForm />}
      View={<MeasurementsView />}
      All={<MeasurementsTablePage />}
    />
  );
};

export default MeasurementsRoutes;
