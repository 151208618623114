// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import { all, put, takeLatest } from 'redux-saga/effects';

import {
  PROJECT_SELECTED_FINDINGS_GETTER_REQUEST,
  PROJECT_SELECTED_FINDINGS_GETTER_SUCCESS,
  PROJECT_SELECTED_FINDINGS_GETTER_FAILURE,
} from './projectSelectedFindingsActions';

import { fetchProjectSelectedFindings } from '../../api/getters/projectSelectedFindings.js';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';

export function* projectSelectedFindingsFetch(action) {
  const { query } = action;
  try {
    const { data, error, response } = yield fetchProjectSelectedFindings(query);
    if (data) {
      yield put({ type: PROJECT_SELECTED_FINDINGS_GETTER_SUCCESS, data });
    } else if (error) {
      yield put({ type: PROJECT_SELECTED_FINDINGS_GETTER_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, PROJECT_SELECTED_FINDINGS_GETTER_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, PROJECT_SELECTED_FINDINGS_GETTER_FAILURE);
  }
}

export function* watchProjectSelectedFindingsFetch() {
  yield takeLatest(PROJECT_SELECTED_FINDINGS_GETTER_REQUEST, projectSelectedFindingsFetch);
}

export default function* projectSelectedFindingsSaga() {
  yield all([watchProjectSelectedFindingsFetch()]);
}
