// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import { all, put, takeLatest } from 'redux-saga/effects';

import {
  LEAD_FILTERS_REQUEST,
  LEAD_FILTERS_SUCCESS,
  LEAD_FILTERS_FAILURE,
} from './leadFiltersActions';

import { fetchLeadFilters } from '../../api/getters/leadFilters.js';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';

export function* leadFiltersFetch(action) {
  const { query } = action;
  try {
    const { data, error, response } = yield fetchLeadFilters(query);
    if (data) {
      yield put({ type: LEAD_FILTERS_SUCCESS, data });
    } else if (error) {
      yield put({ type: LEAD_FILTERS_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, LEAD_FILTERS_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, LEAD_FILTERS_FAILURE);
  }
}

export function* watchLeadFiltersFetch() {
  yield takeLatest(LEAD_FILTERS_REQUEST, leadFiltersFetch);
}

export default function* leadFiltersSaga() {
  yield all([watchLeadFiltersFetch()]);
}
