import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import arrayMutators from 'final-form-arrays';
import { jsonKeyToLabel, apiDateToString, queriesFromString } from '../../utilities/strings';
import { getRoute } from '../../utilities/route';
import { getAssets, createAssets, updateAssets } from '../../store/features/assetsActions';
import CommonForm from '../shared/form/Common';
import GeoPoint from '../shared/form/GeoPoint';
import { fieldOrder, removeField, hideField } from './assetsShared';
import { getAllAssetTypes } from '../../store/features/assetTypesActions';
import { getAllCompanies } from '../../store/features/companiesActions';
import Loading from '../shared/displays/Loading';

const AssetsForm = props => {
  const { update, rename } = props;
  const params = useParams();
  const location = useLocation();
  const { data, loading, error, formError, query } = useSelector(state => {
    const { data, loading, error, formError } = state.assets.each;
    const { query } = state.assets.all;
    return {
      data,
      loading: loading || state.assetTypes.each.loading || state.companies.all.loading,
      error,
      formError,
      query,
    };
  });
  const [submittedValues, setSubmittedValues] = useState({});
  let queries = {};
  if (location.search) {
    queries = queriesFromString(location.search);
  }

  const assetTypesOptions = useSelector(state => {
    const { results } = state.assetTypes.all.dataAll;
    return results
      ? results.map(({ name, id }) => {
          const label = name == null ? '' : name;
          const value = id.toString();
          return { label, value };
        })
      : [];
  });
  const companiesOptions = useSelector(state => {
    const { results } = state.companies.all.dataAll;
    return results
      ? results
          .filter(({ role }, index) => role === 'OWNER')
          .map(({ name, id }, index) => {
            // if the name is null, the option will not be displayed in the select field
            const label = name == null ? '' : name;
            const value = id.toString();
            return { label, value };
          })
      : [{ label: '', value: '' }];
  });

  const dispatch = useDispatch();

  const getState = () => {
    if (update) return 'UPDATE';
    if (rename) return 'RENAME';
    return 'CREATE';
  };

  useEffect(() => {
    if (getState() === 'CREATE') {
      dispatch(getAllAssetTypes({ is_active: true }));
    }
    if (getState() === 'CREATE' || getState() === 'RENAME') {
      dispatch(getAllCompanies());
    }
    if (update && params.id) {
      dispatch(getAssets(params.id));
    } else if (queries.asset_id) {
      dispatch(getAssets(queries.asset_id));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formSettings = {
    fieldOrder,
    removeField: removeField(getState()),
    hideField,
  };

  // When creating an asset with parent specified in the query, we need to wait for the asset to load before rendering the form
  if (getState() === 'CREATE' && queries?.asset_id && queries.asset_id !== String(data?.id)) {
    return <Loading />;
  }

  /*
    Construct and return an object setting the values for the Parent field autocomplete field.

    If the original route is from the asset creation link on Related Assets Table on Asset Detail,
    we want to populate parent with the originating asset, not its parent. If we are on the Rename page,
    we want to populate parent with the current asset's parent.
    Otherwise, just leave a blank.
  */
  const getParentValues = () => {
    const parentValueObject = { label: '', value: '' };
    if (queries?.asset_id && String(data?.id) === queries.asset_id) {
      parentValueObject.label = data?.name ?? '';
      parentValueObject.value = data?.id ?? '';
    } else if (rename) {
      parentValueObject.label = data?.parent?.name ?? '';
      parentValueObject.value = data?.parent?.id ?? '';
    }
    return parentValueObject;
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      switch (key) {
        /* ---------- Required Fields ---------- */
        case 'name':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: 'Name',
              name: 'name',
              required: true,
            },
          };
          break;
        case 'type':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              required: true,
              disabled: update,
              options: assetTypesOptions,
              labelwidth: 40,
            },
          };
          break;
        case 'owner':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              required: true,
              options: companiesOptions,
              labelwidth: 40,
            },
          };
          break;
        case 'parent':
          fieldSettings[key] = {
            type: 'asset-autocomplete',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              labelwidth: 42,
              valueInitial: getParentValues(),
            },
          };
          break;
        /* ---------- Regular Fields ---------- */
        case 'description':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              multiline: true,
              rows: 3,
              maxRows: 10,
            },
          };
          break;
        case 'manufacturer':
        case 'model':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          break;
        case 'manufactured_on':
        case 'installed_on':
          fieldSettings[key] = {
            type: 'date',
            cellProps: {
              xs: 12,
            },
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          break;
        case 'age':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: 'Age',
              name: 'age',
            },
          };
          break;
        case 'external_id':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: 'External ID',
              name: 'external_id',
            },
          };
          break;
        case 'labels':
          fieldSettings[key] = {
            type: 'key-value',
            fieldProps: {
              label: 'Labels',
              name: 'labels',
            },
          };
          break;
        case 'location':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          break;
        case 'geo_point':
          fieldSettings[key] = {
            type: 'geo',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          break;
        /* ----------  Disabled Fields ---------- */
        case 'created_on':
        case 'updated_on':
          fieldSettings[key] = {
            type: 'display',
            fieldProps: {
              disabled: true,
              label: jsonKeyToLabel(key),
              name: key,
              value: apiDateToString(data[key], 'date'),
            },
          };
          break;
        case 'is_active':
          fieldSettings[key] = {
            type: 'checkbox',
            cellProps: {
              xs: 6,
            },
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          break;
        /* ----------  Default ---------- */
        default:
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
      }
    });
  }
  const title = update ? `Edit Asset ${data.name}` : rename ? 'Rename Asset' : 'New Asset';

  let initialValues = { labels: [] };
  if (update || rename) {
    initialValues = {
      ...data,
      type: data.type ? data.type.id : undefined,
      owner: data.owner ? data.owner.id : undefined,
      parent: data.parent ? data.parent.id : undefined,
      geo_point: data.geo_point ? new GeoPoint(data.geo_point).toString() : undefined,
      labels: Array.isArray(data.labels) ? data.labels : [],
    };
  } else {
    initialValues = {
      parent: queries.asset_id ? data.id : undefined,
    };
  }

  const onSubmit = changedValues => {
    const updatedValues = { ...changedValues };
    if ('geo_point' in updatedValues) updatedValues['geo_point'] = new GeoPoint(updatedValues['geo_point']).toGeoJSON();
    setSubmittedValues(updatedValues);
    const defaultRoute = `/assets/${updatedValues.id}`;
    if (update || rename) {
      dispatch(updateAssets(updatedValues.id, updatedValues, getRoute(location, defaultRoute, query)));
    } else {
      dispatch(createAssets(updatedValues, getRoute(location, defaultRoute)));
    }
  };

  /**
   * Decorators are used for setting the values of other fields based off of a field.
   * Decorator format can be found here: src/components/shared/form/common.js
   */
  const decorators = [];

  /**
   *  Validations that are per field.
   *  Errors associated with the form are passed back through formError in Redux.
   */
  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        if (values[key] === submittedValues[key]) {
          errors[key] = value;
        } else {
          errors[key] = undefined;
        }
      }
    }
    return errors;
  };

  return (
    <CommonForm
      update={update}
      rename={rename}
      title={title}
      initialValues={initialValues}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      onSubmit={onSubmit}
      decorators={decorators}
      mutators={{ ...arrayMutators }}
      validate={validate}
      loading={loading}
      // dirty={dirty}
      error={error}
      keepDirtyOnReinitialize
    />
  );
};

AssetsForm.defaultProps = {
  update: false,
  rename: false,
};

AssetsForm.propTypes = {
  update: PropTypes.bool,
  rename: PropTypes.bool,
};

export default AssetsForm;
