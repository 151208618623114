import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { Paper, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Error from '../displays/Error';
import ViewDisplayGeoPoint from './ViewDisplayGeoPoint';
import ViewDisplayString from './ViewDisplayString';
import ViewDisplayKeyValue from './ViewDisplayKeyValue';
import ViewDisplayChips from './ViewDisplayChips';
import ViewDisplayImage from './ViewDisplayImage';
import ViewDisplayUser from './ViewDisplayUser';
import FieldDisplayTable from './FieldDisplayTable';
import AssetConditionDisplay from '../../analytics/assetCondition/AssetConditionDisplay';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import PrettyBytes from '../displays/PrettyBytes';
import StatusOverlay from '../displays/StatusOverlay';
import { locationWithBack } from '../../../utilities/route';

/**
 * Displays the settings for a common feature<br>
 * This shares settings with the CommonForm component<br><br>
 * Examples: Reservations.js and ReportTypes.js
 */

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(3, 'auto'),
    padding: theme.spacing(3),
    maxWidth: '90%',
  },
  bodyOnly: {
    margin: theme.spacing(0),
  },
  form: {
    maxHeight: `calc(100vh - ${theme.spacing(20)})`,
  },
  body: {
    width: '100%',
    padding: theme.spacing(2, 7),
    textAlign: 'left',
    display: 'flex',
  },
  embedded: {
    padding: theme.spacing(1),
  },
  bodyScroll: {
    flexGrow: 1,
    overflow: 'auto',
  },
  section: {
    padding: theme.spacing(2, 3),
  },
  sectionHeader: {
    paddingBottom: theme.spacing(2),
  },
  buttonGroup: {
    padding: theme.spacing(2),
  },
  field: {
    margin: theme.spacing(1, 0),
  },
}));

const CommonFormView = props => {
  const {
    title,
    values,
    fieldSettings,
    formSettings,
    editLink,
    backLink,
    loading,
    error,
    bodyOnly,
    embedded,
    fullHeight,
    readOnly,
  } = props;
  const { fieldOrder, removeField, hideField } = formSettings;
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();

  const renderField = (key, index, value) => {
    if (!fieldSettings[key]) return null;
    const { type, fieldProps, cellProps } = fieldSettings[key];
    switch (type) {
      case 'text':
        return (
          <Grid item xs={6} {...cellProps} key={index} className={classes.field}>
            <ViewDisplayString key={key} {...fieldProps} />
          </Grid>
        );
      case 'geo':
        return (
          <Grid item xs={6} {...cellProps} key={index} className={classes.field}>
            <ViewDisplayGeoPoint key={key} {...fieldProps} />
          </Grid>
        );
      case 'key-value':
        return (
          <Grid item xs={6} {...cellProps} key={index} className={classes.field}>
            <ViewDisplayKeyValue key={key} {...fieldProps} />
          </Grid>
        );
      case 'chips':
        return (
          <Grid item xs={6} {...cellProps} key={index} className={classes.field}>
            <ViewDisplayChips key={key} {...fieldProps} />
          </Grid>
        );
      case 'table':
        return (
          <Grid item xs={12} {...cellProps} key={index} className={classes.field}>
            <FieldDisplayTable key={key} {...fieldProps} />
          </Grid>
        );
      case 'asset-condition':
        return (
          <Grid item xs={6} {...cellProps} key={index} className={classes.field}>
            <div className="h7">{fieldProps.label}</div>
            <AssetConditionDisplay condition={fieldProps.value} />
          </Grid>
        );
      case 'image':
        return (
          <Grid item xs={12} {...cellProps} key={index} className={classes.field}>
            <ViewDisplayImage {...fieldProps} />
          </Grid>
        );
      case 'size':
        return (
          <Grid item xs={6} {...cellProps} key={index} className={classes.field}>
            <PrettyBytes {...fieldProps} />
          </Grid>
        );
      case 'user':
        return (
          <Grid item xs={6} {...cellProps} key={index} className={classes.field}>
            <ViewDisplayUser {...fieldProps} />
          </Grid>
        );
      case 'component':
        // generic case where the View component can pass in a component.
        // needs to be in the format `value: () => <DisplayComponent />` in the fieldProps
        const Component = fieldProps.value; // eslint-disable-line
        return (
          <Grid item xs={12} {...cellProps} key={index} className={classes.field}>
            <div className="h7">{fieldProps.label}</div>
            <Component />
          </Grid>
        );
      default:
        return null;
    }
  };

  const handleEditClick = function () {
    history.push(locationWithBack({ pathname: editLink }, location));
  };

  const handleBackClick = function () {
    if (backLink) {
      history.push(locationWithBack({ pathname: backLink }, location));
    } else if (location.state && location.state.back) {
      // ideally, everythings that routes to this path, should have state.back in it's location.
      history.push(locationWithBack(location.state.back, location));
    } else {
      // this is a last resort for if we don't know where the user came from
      // remove '/<id>' from the end of the url and push that path.
      const next = location.pathname.match(/(.*?)\/\w+$/)[1];
      history.push(locationWithBack({ pathname: next }, location));
    }
  };

  return (
    <Paper className={bodyOnly ? classes.bodyOnly : classes.root}>
      <StatusOverlay on={loading} message="Loading....">
        <form>
          <Grid
            container
            direction="column"
            alignItems="stretch"
            wrap="nowrap"
            className={!fullHeight ? classes.form : null}>
            <Grid item>
              {bodyOnly ? null : (
                <>
                  <Grid container justifyContent="space-between" className={classes.buttonGroup}>
                    <Grid item>
                      <SecondaryButton
                        label="Back"
                        onClick={handleBackClick}
                        variant="text"
                        icon
                        Icon={ChevronLeftIcon}
                      />
                    </Grid>
                    <Grid item>
                      <h3>{title}</h3>
                    </Grid>
                    <Grid item>
                      {readOnly ? null : <PrimaryButton label="Edit" type="button" onClick={handleEditClick} />}
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item className={classes.bodyScroll}>
              <Error error={error} />
              <Grid
                container
                alignItems="flex-start"
                className={embedded ? `${classes.body} ${classes.embedded}` : classes.body}>
                {Object.keys(fieldOrder).map(sectionKey => {
                  const formFields = fieldOrder[sectionKey].fields
                    .filter(item => !removeField(item) && !hideField(values, item))
                    .map((item, index) => renderField(item, index, values[item]));
                  if (formFields.length === 0) return null;
                  return (
                    <Grid key={sectionKey} xs={12} item className={classes.section}>
                      <h4 className={classes.sectionHeader}>
                        <strong>{fieldOrder[sectionKey].display}</strong>
                      </h4>
                      <Grid container alignItems="flex-start">
                        {formFields}
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </form>
      </StatusOverlay>
    </Paper>
  );
};

CommonFormView.defaultProps = {
  values: {},
  fieldSettings: {},
  backLink: '',
  editLink: '',
  title: '',
  bodyOnly: false,
  embedded: false,
  readOnly: false,
  fullHeight: false,
};

CommonFormView.propTypes = {
  /** title to display */
  title: PropTypes.string,
  /** object for defining the field order, fields to remove and fields to hide */
  formSettings: PropTypes.object.isRequired,
  /** route to follow when the edit button is clicked */
  editLink: PropTypes.string,
  /** shows a loading message when true */
  loading: PropTypes.bool.isRequired,
  /** shows an error message when ot emptyp string */
  error: PropTypes.string.isRequired,
  /** current values for the feature */
  values: PropTypes.object,
  /** object for defining how each field is displayed (hides the field by default) */
  fieldSettings: PropTypes.object,
  /** route to follow when the back button is clicked */
  backLink: PropTypes.string,
  /** if true, remove header and footer */
  bodyOnly: PropTypes.bool,
  /** if true, more compact */
  embedded: PropTypes.bool,
  /** optional action buttons/displays */
  children: PropTypes.array,
  /** optional height for drawer view */
  fullHeight: PropTypes.bool,
  /** if true, remove edit button */
  readOnly: PropTypes.bool,
};

export default CommonFormView;
