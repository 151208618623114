import { all, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import mime from 'mime';
import saveAs from 'save-as';
import {
  CHECKLIST_TEMPLATES_EXPORT_REQUEST,
  CHECKLIST_TEMPLATES_EXPORT_SUCCESS,
  CHECKLIST_TEMPLATES_EXPORT_FAILURE,
  CHECKLIST_TEMPLATES_DOWNLOAD_YAML_REQUEST,
  CHECKLIST_TEMPLATES_DOWNLOAD_YAML_SUCCESS,
  CHECKLIST_TEMPLATES_DOWNLOAD_YAML_FAILURE,
  CHECKLIST_TEMPLATES_REQUEST,
  CHECKLIST_TEMPLATES_SUCCESS,
  CHECKLIST_TEMPLATES_FAILURE,
  CHECKLIST_TEMPLATES_CREATE_REQUEST,
  CHECKLIST_TEMPLATES_CREATE_SUCCESS,
  CHECKLIST_TEMPLATES_CREATE_FAILURE,
  CHECKLIST_TEMPLATES_UPDATE_REQUEST,
  CHECKLIST_TEMPLATES_UPDATE_SUCCESS,
  CHECKLIST_TEMPLATES_UPDATE_FAILURE,
  CHECKLIST_TEMPLATES_DELETE_REQUEST,
  CHECKLIST_TEMPLATES_DELETE_SUCCESS,
  CHECKLIST_TEMPLATES_DELETE_FAILURE,
  CHECKLIST_TEMPLATES_ALL_REQUEST,
  CHECKLIST_TEMPLATES_ALL_SUCCESS,
  CHECKLIST_TEMPLATES_ALL_FAILURE,
} from './checklistTemplatesActions';

import {
  fetchChecklistTemplates,
  importChecklistTemplates,
  updateChecklistTemplates,
  deleteChecklistTemplates,
  fetchChecklistTemplatesList,
  exportChecklistTemplates,
  getChecklistTemplateYAML,
} from '../../api/features/checklistTemplates';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';
import { buildRoute, cleanUpFileString, pathToPage } from '../../utilities/strings';
import { DIALOG_CLOSE } from '../dialogActions';
import { SNACKBAR_OPEN } from '../snackbarActions';

import { history } from '../../providers/StoreProvider';
import { tableViews } from '../../utilities/tables';
import { SET_TABLE_QUERY } from '../../store/settings/tableActions';

const { checklistTemplatesTableViewKey: tableType } = tableViews;

export function* checklistTemplatesFetch(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield fetchChecklistTemplates(id);
    if (data) {
      yield put({ type: CHECKLIST_TEMPLATES_SUCCESS, data });
    } else if (error) {
      yield put({ type: CHECKLIST_TEMPLATES_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, CHECKLIST_TEMPLATES_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, CHECKLIST_TEMPLATES_FAILURE);
  }
}

export function* watchChecklistTemplatesFetch() {
  yield takeLatest(CHECKLIST_TEMPLATES_REQUEST, checklistTemplatesFetch);
}

export function* checklistTemplatesCreate(action) {
  const { body } = action;
  try {
    const { data, error, formError, response } = yield importChecklistTemplates(body);
    if (data) {
      yield put({ type: CHECKLIST_TEMPLATES_CREATE_SUCCESS, data });
      yield put(push(buildRoute(`/checklist-templates/${data.checklist.id}`)));
    } else if (error) {
      yield put({ type: CHECKLIST_TEMPLATES_CREATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: CHECKLIST_TEMPLATES_CREATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, CHECKLIST_TEMPLATES_CREATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, CHECKLIST_TEMPLATES_CREATE_FAILURE);
  }
}

export function* watchChecklistTemplatesCreate() {
  yield takeLatest(CHECKLIST_TEMPLATES_CREATE_REQUEST, checklistTemplatesCreate);
}

export function* checklistTemplatesUpdate(action) {
  const { id, body } = action;
  try {
    const { data, error, formError, response } = yield updateChecklistTemplates(id, body);
    if (data) {
      yield put({ type: CHECKLIST_TEMPLATES_UPDATE_SUCCESS, data });
      yield put(push(buildRoute('/checklist-templates')));
    } else if (error) {
      yield put({ type: CHECKLIST_TEMPLATES_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: CHECKLIST_TEMPLATES_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, CHECKLIST_TEMPLATES_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, CHECKLIST_TEMPLATES_UPDATE_FAILURE);
  }
}

export function* watchChecklistTemplatesUpdate() {
  yield takeLatest(CHECKLIST_TEMPLATES_UPDATE_REQUEST, checklistTemplatesUpdate);
}

export function* checklistTemplatesDelete(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield deleteChecklistTemplates(id);
    if (data) {
      yield put({ type: CHECKLIST_TEMPLATES_DELETE_SUCCESS });
      yield put({ type: CHECKLIST_TEMPLATES_ALL_REQUEST });
      yield put({ type: DIALOG_CLOSE });
      yield put(push(buildRoute('/checklist-templates')));
    } else if (error) {
      yield put({ type: CHECKLIST_TEMPLATES_DELETE_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, CHECKLIST_TEMPLATES_DELETE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, CHECKLIST_TEMPLATES_DELETE_FAILURE);
  }
}

export function* watchChecklistTemplatesDelete() {
  yield takeLatest(CHECKLIST_TEMPLATES_DELETE_REQUEST, checklistTemplatesDelete);
}

export function* checklistTemplatesFetchAll(action) {
  const { query } = action;
  const location = history.location;
  try {
    const { data, error, response } = yield fetchChecklistTemplatesList(action.query);
    if (data) {
      yield put({ type: CHECKLIST_TEMPLATES_ALL_SUCCESS, data });
    } else if (error) {
      yield put({ type: CHECKLIST_TEMPLATES_ALL_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, CHECKLIST_TEMPLATES_ALL_FAILURE);
    }
    // save the query in the store
    if (pathToPage(location.pathname)) {
      const page = pathToPage(location.pathname);
      yield put({ type: SET_TABLE_QUERY, page, query, tableType });
    }
  } catch (error) {
    yield defaultErrorCatch(error, CHECKLIST_TEMPLATES_ALL_FAILURE);
  }
}

export function* watchChecklistTemplatesFetchAll() {
  yield takeLatest(CHECKLIST_TEMPLATES_ALL_REQUEST, checklistTemplatesFetchAll);
}

export function* checklistTemplatesExport({ id, filename }) {
  try {
    const { error, response, body } = yield exportChecklistTemplates(id, filename);
    if (body) {
      const fileExtension = mime.getExtension(body.type);
      const safeFilename = cleanUpFileString(filename);
      saveAs(body, `${safeFilename}.${fileExtension}`);
      yield put({ type: CHECKLIST_TEMPLATES_EXPORT_SUCCESS });
      yield put({
        type: SNACKBAR_OPEN,
        message: 'Checklist template download successful.',
        variant: 'success',
        open: true,
      });
    } else if (error) {
      yield put({ type: CHECKLIST_TEMPLATES_EXPORT_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, CHECKLIST_TEMPLATES_EXPORT_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, CHECKLIST_TEMPLATES_EXPORT_FAILURE);
  }
}

export function* checklistTemplatesDownloadYAML({ id, filename }) {
  try {
    const { error, response, body } = yield getChecklistTemplateYAML(id, filename);
    if (body) {
      const contentType = response.headers.get('content-type') || 'application/yaml';
      const fileExtension = mime.getExtension(contentType) || 'yaml';
      const safeFilename = cleanUpFileString(filename);
      const blob = new Blob([body], { type: contentType }); // saveAs requires blob
      saveAs(blob, `${safeFilename}.${fileExtension}`);
      yield put({ type: CHECKLIST_TEMPLATES_DOWNLOAD_YAML_SUCCESS });
      yield put({
        type: SNACKBAR_OPEN,
        message: 'Checklist template download successful.',
        variant: 'success',
        open: true,
      });
    } else if (error) {
      yield put({ type: CHECKLIST_TEMPLATES_DOWNLOAD_YAML_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, CHECKLIST_TEMPLATES_DOWNLOAD_YAML_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, CHECKLIST_TEMPLATES_DOWNLOAD_YAML_FAILURE);
  }
}

export function* watchChecklistTemplatesExport() {
  yield takeLatest(CHECKLIST_TEMPLATES_EXPORT_REQUEST, checklistTemplatesExport);
}

export function* watchChecklistTemplatesDownloadYAML() {
  yield takeLatest(CHECKLIST_TEMPLATES_DOWNLOAD_YAML_REQUEST, checklistTemplatesDownloadYAML);
}

export default function* checklistTemplatesSaga() {
  yield all([
    watchChecklistTemplatesFetch(),
    watchChecklistTemplatesExport(),
    watchChecklistTemplatesDownloadYAML(),
    watchChecklistTemplatesCreate(),
    watchChecklistTemplatesUpdate(),
    watchChecklistTemplatesDelete(),
    watchChecklistTemplatesFetchAll(),
  ]);
}
