// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import { combineReducers } from 'redux';
import {
  DEFECTS_REQUEST,
  DEFECTS_SUCCESS,
  DEFECTS_FAILURE,
  DEFECTS_CREATE_REQUEST,
  DEFECTS_CREATE_SUCCESS,
  DEFECTS_CREATE_FAILURE,
  DEFECTS_UPDATE_REQUEST,
  DEFECTS_UPDATE_SUCCESS,
  DEFECTS_UPDATE_FAILURE,
  DEFECTS_LINKING_REQUEST,
  DEFECTS_LINKING_SUCCESS,
  DEFECTS_LINKING_FAILURE,
  DEFECTS_DELETE_REQUEST,
  DEFECTS_DELETE_SUCCESS,
  DEFECTS_DELETE_FAILURE,
  FINDINGS_BULK_UPDATE_REQUEST,
  FINDINGS_BULK_UPDATE_SUCCESS,
  FINDINGS_BULK_UPDATE_FAILURE,
  DEFECTS_ALL_REQUEST,
  DEFECTS_ALL_SUCCESS,
  DEFECTS_ALL_CLEAR,
  DEFECTS_ALL_FAILURE,
} from './defectsActions';
import { mergeNewItemsIntoExisting } from '../../utilities/reducerHelpers';

export const initialStateDefects = {
  loading: false,
  error: '',
  formError: {},
  id: 0,
  body: {},
  data: {},
};

export const initialStateAllDefects = {
  loading: false,
  error: '',
  query: {},
  dataAll: { count: 0, next: null, previous: null, results: [] },
};

export const defectsReducer = (state = initialStateDefects, action) => {
  switch (action.type) {
    case DEFECTS_REQUEST:
      return {
        ...initialStateDefects,
        loading: true,
        id: action.id,
        query: state.query,
      };
    case DEFECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case DEFECTS_FAILURE:
      return {
        ...initialStateDefects,
        error: action.error,
      };
    case DEFECTS_CREATE_REQUEST:
      return {
        ...initialStateDefects,
        loading: true,
        body: action.body,
      };
    case DEFECTS_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case DEFECTS_CREATE_FAILURE:
      return {
        ...initialStateDefects,
        error: action.error,
        formError: action.formError,
      };
    case DEFECTS_UPDATE_REQUEST:
      return {
        ...state,
        error: '',
        formError: {},
        loading: true,
        id: action.id,
        body: action.body,
        location: action.location,
      };
    case DEFECTS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case DEFECTS_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case DEFECTS_DELETE_REQUEST:
      return {
        ...state,
        error: '',
        formError: {},
        deleting: true,
        id: action.id,
      };
    case DEFECTS_DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
      };
    case DEFECTS_DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        error: action.error,
        formError: action.formError,
      };
    default:
      return state;
  }
};

export const defectsAllReducer = (state = initialStateAllDefects, action) => {
  switch (action.type) {
    case DEFECTS_ALL_REQUEST:
      return {
        ...initialStateAllDefects,
        loading: true,
        query: action.query,
      };
    case DEFECTS_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        dataAll: action.data,
      };
    case DEFECTS_ALL_CLEAR:
      return {
        ...state,
        dataAll: {},
      };
    case DEFECTS_ALL_FAILURE:
      return {
        ...initialStateAllDefects,
        error: action.error,
      };
    case DEFECTS_LINKING_REQUEST:
      return {
        ...state,
        loading: true,
        // defectIds: action.defectIds, passed in saga
        // nextRoute: action.nextRoute, passed in saga
      };
    case DEFECTS_LINKING_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DEFECTS_LINKING_FAILURE:
      return {
        ...state,
        error: action.error,
        formError: action.formError,
      };
    case FINDINGS_BULK_UPDATE_REQUEST:
      return {
        ...state,
        error: '',
        loading: true,
        ids: action.ids,
      };
    case FINDINGS_BULK_UPDATE_SUCCESS:
      return {
        ...state,
        dataAll: {
          ...state.dataAll,
          results: mergeNewItemsIntoExisting(state.dataAll.results, action.data),
        },
        error: '',
        loading: false,
      };
    case FINDINGS_BULK_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default combineReducers({
  each: defectsReducer,
  all: defectsAllReducer,
});
