export const getUserOptionLabel = ({ id, name, email, company }) => {
  if (!id) return undefined;
  let comp = '';
  if ((company ?? []).length > 0) {
    comp = `[${company[0].name}]`;
  }
  const label = `${name} (${email}) ${comp}`;
  const value = id.toString();
  return { label, value };
};
