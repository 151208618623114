import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import Drawer from '@mui/material/Drawer';
import { Grid, IconButton, Paper, Tooltip } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import ImageAspectRatioIcon from '@mui/icons-material/ImageAspectRatio';
import GetAppIcon from '@mui/icons-material/GetApp';
import InfoIcon from '@mui/icons-material/Info';
import { getProjectIsReadOnly, inspectionEnabled } from '../../../utilities/projects';
import { downloadFile } from '../../../utilities/strings';
import { isEmpty } from '../../../utilities/objects';
import InspectionMediaView from '../../inspectionMedia/InspectionMediaView';
import SecondaryButton from '../buttons/SecondaryButton';
import PrimaryButton from '../buttons/PrimaryButton';
import { usePermissions } from '../../../hooks/settingsHooks';
import ProjectTitle from '../displays/ProjectTitle';
import { isEven, getMaxIndex } from '../../../utilities/media.js';
import { openDialog } from '../../../store/dialogActions';
import MediaDescriptionForm from '../../checklistEditor/lineItems/MediaDescriptionForm';

import FileViewer from '../fileViewer/FileViewer';
import ErrorBoundary from '../displays/ErrorBoundary';
import FullScreenDialog from '../FullScreenDialog';

const drawerWidth = 350;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    placeItems: 'center',
  },
  appBar: {
    width: '100%',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginRight: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    width: '100%',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
    width: `calc(100% - ${drawerWidth}px)`,
  },
  descriptionContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    padding: theme.spacing(1),
    justifyContent: 'center',
  },
  description: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  drawer: {
    width: drawerWidth,
    minWidth: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    minWidth: drawerWidth,
  },
  gridItem: {
    width: '98vw',
    height: '85vh',
    margin: theme.spacing(2, 0, 0, 0),
  },
  mediaTitle: {
    padding: theme.spacing(2),
    fontSize: '16px',
  },
  navButton: {
    marginLeft: theme.spacing(4),
  },
  inspectButton: {
    marginTop: theme.spacing(1),
  },
}));

let eventHandler = event => {};
const eventHandlerWrapper = event => {
  eventHandler(event);
};
export default function SlideshowViewer(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    mediaArray,
    showSlides,
    setShowSlides,
    startingId,
    project,
    form,
    itemsPerPage,
    queryParamObj,
    tableChangeHandler,
    total,
    previous,
    next,
    loading,
    paginate,
  } = props;
  const history = useHistory();
  const { hasChecklistEdit } = usePermissions();
  let mediaIndex = mediaArray.findIndex(media => media.id === startingId);
  if (mediaIndex === -1) {
    mediaIndex = 0;
  }

  const { offset, limit } = queryParamObj;

  const [displayIndex, setDisplayIndex] = useState(mediaIndex); // index of data array
  const currentIndex = parseInt(offset || 0) + displayIndex;

  const isFirstImage = currentIndex === 0;
  const isLastImage = currentIndex === total - 1;
  const shouldLoadMoreNext = !!(isLastImage || (displayIndex === mediaArray.length - 1 && next));
  const shouldLoadMorePrev = !!(isFirstImage || (displayIndex === 0 && previous));

  const load = pageIndex => {
    const queryParams = { ...queryParamObj, offset: pageIndex * itemsPerPage };
    tableChangeHandler(queryParams);
  };

  const media = mediaArray[displayIndex];

  const [infoDrawerOpen, toggleInfoDrawer] = useState(false);

  const handleClose = () => {
    setShowSlides(false);
  };

  const handleInspect = () => {
    if (project?.id) {
      history.push(`/projects/${project.id}/inspect/${media.id}`);
    } else if (form?.id) {
      history.push(`/forms/${form.id}/inspect/${media.id}`);
    } else {
      console.error('expected project or form');
    }
  };

  const resetDisplayIndexToFirst = () => setDisplayIndex(0);
  const resetDisplayIndexToLast = () => setDisplayIndex(itemsPerPage - 1);
  const handleNext = () => setDisplayIndex(displayIndex + 1);
  const handlePrev = () => setDisplayIndex(displayIndex - 1);

  const handleLoader = type => {
    let updatedPageIndex;
    const pageIndex = offset ? offset / limit : 0;
    const checkIsEven = isEven(total, itemsPerPage);
    const maxIndex = getMaxIndex(checkIsEven, total, itemsPerPage);
    if (pageIndex === 0 && type === 'prev') {
      updatedPageIndex = maxIndex;
    } else {
      const direction = type === 'prev' ? -1 : 1;
      const calcIndex = pageIndex + direction;
      updatedPageIndex = calcIndex > maxIndex ? 0 : calcIndex;
    }
    if (type === 'prev') {
      if (pageIndex === 0) {
        setDisplayIndex(total - updatedPageIndex * itemsPerPage - 1);
      } else {
        resetDisplayIndexToLast();
      }
    }
    if (type === 'next') {
      resetDisplayIndexToFirst();
    }

    load(updatedPageIndex);
  };

  const paginatedNext = () => {
    if (shouldLoadMoreNext) {
      handleLoader('next');
    } else {
      handleNext();
    }
  };

  const paginatedPrev = () => {
    if (shouldLoadMorePrev) {
      handleLoader('prev');
    } else {
      handlePrev();
    }
  };

  const handleNextImage = () => {
    if (currentIndex === mediaArray.length - 1) {
      resetDisplayIndexToFirst();
    } else {
      handleNext();
    }
  };

  const handlePrevImage = () => {
    if (currentIndex === 0) {
      setDisplayIndex(mediaArray.length - 1);
    } else {
      handlePrev();
    }
  };

  const handleShowInfo = () => {
    toggleInfoDrawer(!infoDrawerOpen);
  };

  eventHandler = e => {
    if (e.defaultPrevented) {
      return; // Should do nothing if the default action has been cancelled
    }

    let handled = false;
    if (e.key === 'ArrowRight') {
      if (paginate) {
        paginatedNext();
      } else {
        handleNextImage();
      }
      handled = true;
    } else if (e.key === 'ArrowLeft') {
      if (paginate) {
        paginatedPrev();
      } else {
        handlePrevImage();
      }
      handled = true;
    }

    if (handled) {
      // Suppress "double action" if event handled
      e.preventDefault();
    }
  };

  const handleEdit = (description, id) => {
    dispatch(openDialog('Edit Media Description', <MediaDescriptionForm description={description} id={id} />));
  };

  window.removeEventListener('keydown', eventHandlerWrapper);
  window.addEventListener('keydown', eventHandlerWrapper);

  if (!showSlides) return null;

  const isVisualInspectionProject = project?.id && inspectionEnabled(project, media);
  const isReadOnly = getProjectIsReadOnly(project?.status, hasChecklistEdit);

  return (
    <FullScreenDialog open={showSlides} onClose={handleClose}>
      <div>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: infoDrawerOpen,
          })}>
          <Toolbar>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <Grid container>
                  <Grid item>
                    <SecondaryButton label="Close" icon variant="text" onClick={handleClose} />
                  </Grid>
                  <Grid item className={classes.mediaTitle}>
                    {!isEmpty(project) ? (
                      <ProjectTitle {...project} media={media} current={currentIndex + 1} count={total} />
                    ) : (
                      media && (
                        <>
                          {media.name} ({currentIndex + 1}/{total})
                        </>
                      )
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item>
                    <SecondaryButton
                      label="Prev"
                      icon
                      Icon={SkipPreviousIcon}
                      variant="text"
                      onClick={paginate ? paginatedPrev : handlePrevImage}
                    />
                  </Grid>
                  <Grid item className={classes.navButton}>
                    <SecondaryButton
                      label="Next"
                      icon
                      Icon={SkipNextIcon}
                      variant="text"
                      onClick={paginate ? paginatedNext : handleNextImage}
                    />
                  </Grid>
                  <Grid item className={classes.navButton}>
                    <SecondaryButton
                      label=""
                      icon
                      variant="text"
                      Icon={GetAppIcon}
                      onClick={() => {
                        const { name, file } = mediaArray[displayIndex];
                        downloadFile(name, file);
                      }}
                    />
                  </Grid>
                  {isVisualInspectionProject && (
                    <Grid item className={classes.inspectButton}>
                      <PrimaryButton
                        label="Inspect"
                        icon
                        // variant="text"
                        Icon={ImageAspectRatioIcon}
                        onClick={handleInspect}
                        disabled={isReadOnly}
                      />
                    </Grid>
                  )}
                  <Grid item>
                    <SecondaryButton label="Info" icon variant="text" Icon={InfoIcon} onClick={handleShowInfo} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="persistent"
          anchor="right"
          open={infoDrawerOpen}
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}>
          {media && <InspectionMediaView data={media} fullHeight />}
        </Drawer>
        <Toolbar />
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={clsx(classes.content, {
            [classes.contentShift]: infoDrawerOpen,
          })}>
          {!mediaArray.length && (
            <Grid item className={classes.gridItem}>
              <p>{loading ? 'Loading...' : 'No Media Available.'}</p>
            </Grid>
          )}
          <Grid item className={classes.gridItem}>
            <ErrorBoundary message={'Unable to display file'}>
              <FileViewer media={media} />
            </ErrorBoundary>
            <Paper className={classes.descriptionContainer}>
              {media && media.description ? (
                <>
                  <IconButton onClick={() => handleEdit(media.description, media.id)} size="small">
                    <Tooltip title="Edit media description">
                      <EditIcon />
                    </Tooltip>
                  </IconButton>
                  <div className={classes.description}>{media.description}</div>
                </>
              ) : (
                <div>
                  <IconButton onClick={() => handleEdit(media.description, media.id)} size="small">
                    <Tooltip title="Add media description">
                      <AddIcon />
                    </Tooltip>
                  </IconButton>
                  <span className={classes.description}>Add Media Description</span>
                </div>
              )}
            </Paper>
          </Grid>
        </Grid>
      </div>
    </FullScreenDialog>
  );
}

SlideshowViewer.propTypes = {
  mediaArray: PropTypes.array.isRequired,
  showSlides: PropTypes.bool.isRequired,
  setShowSlides: PropTypes.func.isRequired,
  startingId: PropTypes.number.isRequired,
  project: PropTypes.object,
  form: PropTypes.object,
  itemsPerPage: PropTypes.number,
  total: PropTypes.number.isRequired,
  queryParamObj: PropTypes.object,
  previous: PropTypes.string,
  next: PropTypes.string,
  tableChangeHandler: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  paginate: PropTypes.bool,
};
SlideshowViewer.defaultProps = {
  project: {},
  form: {},
  previous: null,
  next: null,
  paginate: false,
  itemsPerPage: 50,
  queryParamObj: {},
};
