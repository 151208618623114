import React from 'react';
import PropTypes from 'prop-types';

import FieldEditAssetAutocomplete from './FieldEditAssetAutocomplete';
import FieldEditDefectAutocomplete from './FieldEditDefectAutocomplete';
import FieldEditAutocomplete from './FieldEditAutocomplete';
import FieldEditUserAutocomplete from './FieldEditUserAutocomplete';
import FieldEditMultiSelect from './FieldEditMultiSelect';
import FieldEditString from './FieldEditString';
import FieldEditGeoPoint from './FieldEditGeoPoint';
import FieldEditFile from './FieldEditFile';
import FieldParseFile from './FieldParseFile';
import FieldEditSelect from './FieldEditSelect';
import FieldEditCheckbox from './FieldEditCheckbox';
import FieldEditCheckboxControlled from './FieldEditCheckboxControlled';
import FieldEditDate from './FieldEditDate';
import FieldEditDateTime from './FieldEditDateTime';
import FieldEditKeyValue from './FieldEditKeyValue';
import FieldEditArray from './FieldEditArray';
import FieldDisplayTable from './FieldDisplayTable';
import FieldDisplayString from './FieldDisplayString';
import ViewDisplayImage from './ViewDisplayImage';

import Markdown from '../Markdown';

export const FIELD_TYPES = {
  MULTI: 'multi',
  TEXT: 'text',
  GEO: 'geo',
  FILE: 'file',
  PARSE_FILE: 'parse-file',
  AUTOCOMPLETE: 'autocomplete',
  ASSET_AUTOCOMPLETE: 'asset-autocomplete',
  DEFECT_AUTOCOMPLETE: 'defect-autocomplete',
  SELECT: 'select',
  CHECKBOX: 'checkbox',
  CHECKBOX_CONTROLLED: 'checkboxControlled',
  DATE: 'date',
  DATETIME: 'datetime',
  KEY_VALUE: 'key-value',
  ARRAY: 'array',
  TABLE: 'table',
  DISPLAY: 'display',
  USER_AUTOCOMPLETE: 'user-autocomplete',
  IMAGE: 'image',
  COMPONENT: 'component',
  MARKDOWN: 'markdown',
};

/**
 * used for explicitly setting value as empty
 *
 * @param {string} type one of FIELD_TYEPS
 * @return {*}
 */
export function getEmptyValueForType(type) {
  switch (type) {
    case FIELD_TYPES.TEXT:
      return '';
    case FIELD_TYPES.CHECKBOX:
      return false;
    case FIELD_TYPES.ARRAY:
    case FIELD_TYPES.MULTI:
      return [];
    default:
      return null;
  }
}

/**
 * Shared component for simple forms that either creates or updates a feature.<br>
 * This allows forms to be created by passing in objects that defines how the form is displayed and handled<br><br>
 * Examples: Reservations.js and ReportTypes.js
 */

const ComponentField = props => {
  // generic case where the View component can pass in a component.
  // either one of:
  // fieldProps: {
  //   component: () => MyComponent
  //   // or:
  //   value: () => <MyComponent />
  // }
  if (props.component) {
    const Component = props.component(); // eslint-disable-line no-case-declarations
    return <Component {...props} />;
  } else if (props.value) {
    return props.value;
  } else {
    console.warn('ComponentField: no component or value passed in', props);
    return null;
  }
};

ComponentField.propTypes = {
  component: PropTypes.func,
  value: PropTypes.func,
};

/**
 *
 */
const CommonField = ({ type, ...fieldProps }) => {
  switch (type) {
    case FIELD_TYPES.MULTI:
      return <FieldEditMultiSelect {...fieldProps} />;
    case FIELD_TYPES.TEXT:
      return <FieldEditString {...fieldProps} />;
    case FIELD_TYPES.GEO:
      return <FieldEditGeoPoint {...fieldProps} />;
    case FIELD_TYPES.FILE:
      return <FieldEditFile {...fieldProps} />;
    case FIELD_TYPES.PARSE_FILE:
      return <FieldParseFile {...fieldProps} />;
    case FIELD_TYPES.AUTOCOMPLETE:
      return <FieldEditAutocomplete {...fieldProps} />;
    case FIELD_TYPES.ASSET_AUTOCOMPLETE:
      return <FieldEditAssetAutocomplete {...fieldProps} />;
    case FIELD_TYPES.DEFECT_AUTOCOMPLETE:
      return <FieldEditDefectAutocomplete {...fieldProps} />;
    case FIELD_TYPES.SELECT:
      return <FieldEditSelect {...fieldProps} />;
    case FIELD_TYPES.CHECKBOX:
      return <FieldEditCheckbox {...fieldProps} />;
    case FIELD_TYPES.CHECKBOX_CONTROLLED:
      return <FieldEditCheckboxControlled {...fieldProps} />;
    case FIELD_TYPES.DATE:
      return <FieldEditDate {...fieldProps} />;
    case FIELD_TYPES.DATETIME:
      return <FieldEditDateTime {...fieldProps} />;
    case FIELD_TYPES.KEY_VALUE:
      return <FieldEditKeyValue {...fieldProps} />;
    case FIELD_TYPES.ARRAY:
      return <FieldEditArray {...fieldProps} />;
    case FIELD_TYPES.TABLE:
      return <FieldDisplayTable {...fieldProps} />;
    case FIELD_TYPES.DISPLAY:
      return <FieldDisplayString {...fieldProps} />;
    case FIELD_TYPES.USER_AUTOCOMPLETE:
      return <FieldEditUserAutocomplete {...fieldProps} />;
    case FIELD_TYPES.IMAGE:
      return <ViewDisplayImage {...fieldProps} />;
    case FIELD_TYPES.COMPONENT:
      return <ComponentField {...fieldProps} />;
    case FIELD_TYPES.MARKDOWN:
      return (
        <Markdown linkTarget="_blank" className={fieldProps.className}>
          {fieldProps.source}
        </Markdown>
      );
    default:
      console.warn(`unhandled form type: "${type}"`);
      return null;
  }
};

CommonField.propTypes = {
  // Should Map to one of FIELD_TYPES
  type: PropTypes.string.isRequired,
  // Passed to inner field
  // ...fieldProps
};

export default CommonField;
