import React from 'react';
import PropTypes from 'prop-types';

/**
 * Loading component for displaying a loading message
 */

const Loading = props => {
  const { loading, style } = props;
  return loading ? (<h3 style={{ marginTop: '45px', padding: '20px', ...style }}>Loading...</h3>) : <></>;
};

Loading.defaultProps = {
  loading: true,
  style: {},
};

Loading.propTypes = {
  /** is loading if true */
  loading: PropTypes.bool,
  /** style override */
  style: PropTypes.object,
};

export default Loading;
