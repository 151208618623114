import React from 'react';
import { useSelector } from 'react-redux';
import { generateUniqueArray } from './usageShared';
import UsageTable from './UsageTable';

const ProjectUsage = () => {
  const { data, loading } = useSelector(state => ({
    data: state.usageStats.data.results || [],
    loading: state.usageStats.loading,
  }));

  const projectTypeNames = data.map(obj => obj.project_type_usage).map(x => x.map(obj => obj.type__name));
  const filteredProjectTypeNames = generateUniqueArray(projectTypeNames);

  return <UsageTable usageData={data} loading={loading} typeNames={filteredProjectTypeNames} type={'Project'} />;
};

export default ProjectUsage;
