import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Chart, Bar, defaults } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { makeStyles, useTheme } from '@mui/styles';
// import { colors } from '@mui/material';

Chart.register(ChartDataLabels); // register plugin for data labels

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
}));

const ObservationChart = props => {
  const { data: dataProp, labels, className, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();

  defaults.font.family = 'Roboto';
  defaults.color = theme.palette.text.primary;
  // defaults.elements.bar.barThickness = 10;

  const stackKey = '1';

  const maxTotal = Math.max.apply(
    Math,
    dataProp.map(function (o) {
      return o.total;
    })
  );

  const shouldShowDataLabel = (value, max) => {
    return (value / max) * 100 > 2;
  };

  const data = {
    datasets: [
      {
        stack: stackKey,
        label: 'Good Catch',
        backgroundColor: 'rgb(243, 93, 11)',
        data: dataProp.map(d => d.unsafe),
        datalabels: {
          display: function (context) {
            const value = context.dataset.data[context.dataIndex];
            return shouldShowDataLabel(value, maxTotal);
          },
        },
      },
      // elements: {
      //   bar: {
      //     barThickness: 10,
      //   },
      // },
      {
        stack: stackKey,
        label: 'Safe',
        backgroundColor: 'rgb(107, 237, 0)',
        data: dataProp.map(d => d.safe),
        datalabels: {
          display: function (context) {
            const value = context.dataset.data[context.dataIndex];
            return shouldShowDataLabel(value, maxTotal);
          },
        },
      },
      {
        stack: stackKey,
        label: 'Other',
        backgroundColor: 'rgb(183, 189, 199)',
        data: dataProp.map(d => d.total - (d.safe + d.unsafe)),
        datalabels: {
          display: function (context) {
            const value = context.dataset.data[context.dataIndex];
            return shouldShowDataLabel(value, maxTotal);
          },
        },
      },
    ],
    labels,
  };

  // if value is less than 5% of total dont display

  const options = {
    indexAxis: 'y',
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    elements: {
      bar: {
        // barThickness: 2,
      },
    },
    responsive: true,
    animation: true,
    // cornerRadius: 20,
    // legend: {
    //   display: true,
    // },
    plugins: {
      datalabels: {
        color: theme.palette.text.primary,
        anchor: 'center',
        align: 'center',
        font: { weight: 'bold' },
      },
      legend: {
        display: true,
        labels: {
          color: theme.palette.text.primary,
        },
        position: 'right',
      },
      title: {
        display: true,
        text: 'Safe vs. Good Catch Findings',
        color: theme.palette.text.primary,
      },
      tooltip: {
        interaction: {
          intersect: false,
          mode: 'index',
          axis: 'y',
        },
      },
    },
    layout: {
      padding: 0,
    },
    scales: {
      xAxes: [
        {
          barThickness: 3, // number (pixels) or 'flex'
          maxBarThickness: 5, // number (pixels),          // barPercentage: 0.2,
          // categoryPercentage: 0.5,
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            // padding: 20,
            fontColor: theme.palette.text.secondary,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider,
          },
          ticks: {
            // padding: 20,
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
            maxTicksLimit: 5,
            callback: value => {
              return value > 0 ? value + 'K' : value;
            },
          },
        },
      ],
    },
    tooltip: {
      intersect: false,
      caretSize: 10,
      yPadding: 20,
      xPadding: 20,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
      callbacks: {
        title: () => {},
      },
    },
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Bar data={data} options={options} />
    </div>
  );
};

ObservationChart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
  labels: PropTypes.array.isRequired,
};

export default ObservationChart;
