import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import makeStyles from '@mui/styles/makeStyles';
import { removeHTMLTags } from '../../utilities/strings';

const useStyles = makeStyles(theme => ({
  multilineBlockMargins: {
    '& p': {
      'margin-block-start': '1em',
      'margin-block-end': '1em',
    },
    '& h1': {
      'margin-block-start': '0.67em',
      'margin-block-end': '0.67em',
    },
    '& h2': {
      'margin-block-start': '0.83em',
      'margin-block-end': '0.83em',
    },
    '& h3': {
      'margin-block-start': '1em',
      'margin-block-end': '1em',
    },
    '& h4': {
      'margin-block-start': '1.33em',
      'margin-block-end': '1.33em',
    },
    '& h5': {
      'margin-block-start': '1.67em',
      'margin-block-end': '1.67em',
    },
    '& h6': {
      'margin-block-start': '2.33em',
      'margin-block-end': '2.33em',
    },
  },
}));

const Markdown = props => {
  let { children, className, multilineBlockMargins, ...otherProps } = props;
  const classes = useStyles();

  if (multilineBlockMargins) {
    className = classes.multilineBlockMargins;
  }

  return (
    <ReactMarkdown skipHtml className={className} {...otherProps}>
      {removeHTMLTags(children)}
    </ReactMarkdown>
  );
};

Markdown.defaultProps = {
  multilineBlockMargins: false,
};

Markdown.propTypes = {
  // markdown body / content
  children: PropTypes.any,

  // pass `className` to revert styles
  className: PropTypes.string,

  // revert margin changes applied by `/frontend/src/index.scss`
  //
  // using standard "user agent" stylesheet for chrome:
  //  https://trac.webkit.org/browser/trunk/Source/WebCore/css/html.css
  //
  multilineBlockMargins: PropTypes.bool,
};

export default Markdown;
