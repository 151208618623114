import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { Alert } from '@mui/material';
import arrayMutators from 'final-form-arrays';
import {
  jsonKeyToLabel,
  apiDateToString,
  queriesFromString,
  getDate,
  dateToApiDateTime,
} from '../../utilities/strings';
import { getProjects, createProjects, updateProjects } from '../../store/features/projectsActions';
import { getAllAssets } from '../../store/features/assetsActions';
import { getAllCrews } from '../../store/features/crewsActions';
import { getAllCompanies } from '../../store/features/companiesActions';
import { useCachedProjectTypes } from '../../hooks/projectTypesHooks';
import { useAssets, useCrews, useCompanies } from '../../hooks/optionsHooks';
import CommonForm from '../shared/form/Common';
import { fieldOrder, removeField, hideField } from './projectsShared';
import { useFeatureFlags, usePermissions } from '../../hooks/settingsHooks';
import { getLabel } from '../../utilities/objects';
import { convertCSVMatrixToObjects, buildCSVFileReader } from '../../utilities/csv';
import { getRoute } from '../../utilities/route';
import { castBool } from '../../utilities/castBool';
import Loading from '../shared/displays/Loading';
import ProjectToolbar from './ProjectToolbar';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(15, 2),

    padding: theme.spacing(2),
    textAlign: 'left',
  },
  message: {
    color: 'red',
  },
  center: {
    textAlign: 'center',
  },
  selectedCmlsCount: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    borderRadius: '3px',
    padding: '0.5rem',
  },
}));

export const getAssetTypes = (value, projectTypeOptions) => {
  try {
    const projectType = projectTypeOptions.find(item => item.value === value.value);
    return projectType ? projectType.assetTypes.map(item => item.id) : undefined;
  } catch {
    return undefined;
  }
};

const ProjectsForm = props => {
  const { update } = props;
  const params = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const [asset, setAsset] = useState();
  const [type, setType] = useState();
  const [assetTypes, setAssetTypes] = useState();
  const [dirty, setDirty] = useState(false);
  const [submittedValues, setSubmittedValues] = useState({});
  const [selectedCmls, setSelectedCmls] = useState([]);
  const [warnings, setWarnings] = useState([]); // [{ severity: 'info', message: 'message' }]
  const assetsOptions = useAssets();
  const crewOptions = useCrews();
  const companiesOptions = useCompanies();
  const { projectTypeOptions } = useCachedProjectTypes();

  const { hasProjectEditCompany } = usePermissions();
  const { hasProjectPriority, hasProjectScopes, hasProjectLabels } = useFeatureFlags();

  useEffect(() => {
    if (selectedCmls.length === 0) {
      return setWarnings([]);
    }
    return setWarnings([{ severity: 'info', message: `Creating Project Scoped to ${selectedCmls.length} CML(s)` }]);
  }, [selectedCmls]);

  const selectedCMLsCSVFileReader = useMemo(() => {
    // Read CSV file and set selectedCmls
    return buildCSVFileReader(
      data => {
        const cmls = convertCSVMatrixToObjects(data, {
          id: /^id$/i,
          isSelected: /select/i, // NOTE - this will take the FIRST column containing "select"
        });

        const selectedCmlsResults = cmls.filter(c => castBool(c.isSelected)).map(c => c.id);

        setSelectedCmls(selectedCmlsResults);
      },
      error => {
        console.error('Error reading CSV file: ', error);

        setWarnings([{ message: 'Error reading CSV file ' + error.toString(), severity: 'error' }]);
      }
    );
  }, [setSelectedCmls]);

  const { data, loading, error, formError, query } = useSelector(state => {
    const { data, loading, error, formError } = state.projects.each;
    const { query } = state.projects.all;

    return {
      data,
      query,
      loading:
        loading ||
        state.projectTypes.all.loading ||
        state.assets.all.loading ||
        state.crews.all.loading ||
        state.companies.all.loading,
      error,
      formError,
    };
  });

  const [isHistoricalProject, setIsHistoricalProject] = useState(data?.historical_project);

  useEffect(() => {
    // get settings from url query for option filtering
    // quietly ignore invalid settings.
    let queries = {};
    if (location.search) {
      queries = queriesFromString(location.search);
      if (queries.asset_id) {
        setAsset(queries.asset_id);
      }
      if (queries.type) {
        setType(queries.type);
        setAssetTypes(getAssetTypes(queries.type, projectTypeOptions));
      }
      if (queries.cmls) {
        const cmlsArray = [].concat(queries.cmls); // ensures selectedCmls is an array
        setSelectedCmls(cmlsArray);
      }
    }

    dispatch(getAllCrews({ is_active: true }));
    if (hasProjectEditCompany) {
      dispatch(getAllCompanies({ role: 'SUBCONTRACTOR' }));
    }
    if (update && params.id) {
      dispatch(getProjects(params.id));
    } else {
      // these items are only needed when creating a new project
      typeof queries.asset_id !== 'undefined'
        ? dispatch(getAllAssets({ asset_id: queries.asset_id, is_active: true }))
        : dispatch(getAllAssets({ is_active: true }));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateAsset = e => {
    setDirty(true);
    setAsset(e.target.value);
  };

  const updateType = (_, value) => {
    setDirty(true);
    setType(value);
    const assetType = getAssetTypes(value, projectTypeOptions);
    if (assetType) {
      setAssetTypes(assetType);
    }
  };
  const initialValueSetter = type => {
    if (asset !== null) {
      if (type === 'label') {
        return getLabel(asset, assetsOptions);
      } else {
        return asset;
      }
    } else {
      return '';
    }
  };

  const getFilteredProjectTypes = projectTypes => {
    // filter on checklist template if creating from form
    const { template_id: template } = queriesFromString(location.search);
    if (template) {
      return projectTypes.filter(projectType => projectType.checklistTemplate?.id?.toString() === template);
    }
    // don't filter anything if we don't have all the data or if type is already set
    if (!(asset && assetsOptions.length !== 0) || type) {
      return projectTypes;
    }
    const selectedAsset = assetsOptions.find(assetOption => assetOption.value === asset);
    if (!selectedAsset) {
      // does this happen? asset not found, but selected?
      return [];
    }
    return projectTypes.filter(projectType =>
      projectType.assetTypes.some(assetType => assetType.id === selectedAsset.type.id)
    );
  };

  const formSettings = {
    fieldOrder,
    removeField: removeField(update ? 'UPDATE' : 'CREATE'),
    hideField: (values, item) => {
      return hideField(values, item, {
        hasProjectLabels,
        hasProjectPriority,
        hasProjectScopes,
        selectedCmls,
        update,
        projectTypeOptions,
        hasProjectEditCompany,
      });
    },
  };

  const handleOnChange = event => {
    setIsHistoricalProject(!isHistoricalProject);
    setDirty(true);
  };
  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      switch (key) {
        /* ---------- Required Fields ---------- */
        case 'name':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              required: true,
            },
          };
          break;
        case 'type':
          fieldSettings[key] = {
            type: 'autocomplete',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: 'type',
              required: true,
              options: getFilteredProjectTypes(projectTypeOptions),
              labelwidth: 36,
              onChange: updateType,
            },
          };
          break;
        case 'asset':
          fieldSettings[key] = {
            type: 'asset-autocomplete',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: 'asset',
              required: true,
              labelwidth: 42,
              // disabled if project type is not set
              disabled: !type,
              valueInitial: { label: initialValueSetter('label', asset), value: initialValueSetter('value', asset) },
              filterQuery: { asset_type__in: assetTypes },
              onChange: updateAsset,
            },
          };
          break;

        /* ---------- Regular Fields ---------- */
        case 'description':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              multiline: true,
              rows: 3,
              maxRows: 10,
            },
          };
          break;
        case 'crews':
          fieldSettings[key] = {
            type: 'multi',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: 'crews',
              options: crewOptions,
              labelwidth: 100,
            },
          };
          break;
        case 'management_company':
          fieldSettings[key] = {
            type: 'autocomplete',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              options: companiesOptions,
              disabled: !hasProjectEditCompany,
              labelwidth: 150,
            },
          };
          break;
        case 'start':
        case 'end':
          fieldSettings[key] = {
            type: 'date',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              value: data[key] ? getDate(data[key], 'date') : '',
            },
          };
          break;
        case 'historical_project':
          fieldSettings[key] = {
            type: 'checkbox',
            cellProps: {
              xs: 12,
            },
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          if (update) {
            fieldSettings[key].type = 'checkboxControlled';
            fieldSettings[key].fieldProps.checked = isHistoricalProject;
            fieldSettings[key].fieldProps.onChange = event => {
              handleOnChange(event);
            };
          }
          break;
        case 'is_active':
          fieldSettings[key] = {
            type: 'checkbox',
            cellProps: {
              xs: 6,
            },
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          break;
        case 'labels_by_key':
          fieldSettings[key] = {
            type: 'key-value',
            fieldProps: {
              label: 'Labels',
              name: 'labels',
            },
          };
          break;
        case 'scope.description':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: 'Description',
              name: key,
              multiline: true,
              rows: 3,
              maxRows: 10,
            },
          };
          break;
        case 'scope.selected_cmls':
          if (selectedCmls.length > 0) {
            // if there are selected CMLs, display the count
            fieldSettings[key] = {
              type: 'markdown',
              fieldProps: {
                source: 'Number of CMLs included: ' + selectedCmls.length,
                className: classes.selectedCmlsCount,
              },
            };
          } else {
            // if there are no selected CMLs, allow user to upload CSV
            fieldSettings[key] = {
              type: 'parse-file',
              fieldProps: {
                label: 'Selected CMLs',
                name: 'scope.selected_cmls',
                accept: '.csv',
                uploadText: 'Click to upload selected CMLs.',
                fileReader: selectedCMLsCSVFileReader,
              },
            };
          }
          break;

        /* ----------  Disabled Fields ---------- */
        case 'created_on':
        case 'updated_on':
          fieldSettings[key] = {
            type: 'display',
            fieldProps: {
              disabled: true,
              label: jsonKeyToLabel(key),
              name: key,
              value: apiDateToString(data[key], 'date'),
            },
          };
          break;
        case 'work_done_on':
          fieldSettings[key] = {
            type: 'date',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          if (update) {
            fieldSettings[key].fieldProps.disabled = !isHistoricalProject;
            fieldSettings[key].fieldProps.value = !isHistoricalProject ? getDate(undefined, 'date') : '';
          }
          break;
        /* ----------  Default ---------- */
        default:
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
      }
    });
  }

  const title = update ? 'Edit Project' : 'New Project';

  // The form cannot handle data coming in as objects.  Get id so select field can render the selected option
  // when new, check if we need to apply any values from the queries
  let initialValues = {};
  if (update) {
    initialValues = {
      ...data,
      crews: data.crews ? data.crews.map(crew => crew.id.toString()) : [],
      management_company: data.management_company ? data.management_company.id.toString() : '',
      labels: Array.isArray(data.labels) ? data.labels : [],
    };
  } else {
    const queryObj = queriesFromString(location.search);
    initialValues = {
      type: queryObj.type,
      asset: queryObj.asset_id,
      parent: queryObj.parent_id,
      is_active: true,
    };
  }

  const onSubmit = changedValues => {
    const updatedValues = { ...changedValues };
    // only set work done on if user sets it manually
    if (isHistoricalProject && 'work_done_on' in updatedValues) {
      updatedValues['work_done_on'] = dateToApiDateTime(updatedValues['work_done_on']);
    }
    setSubmittedValues(updatedValues);
    const defaultRoute = `/projects/${updatedValues.id}`;

    if (update) {
      updatedValues.historical_project = isHistoricalProject;
      dispatch(updateProjects(updatedValues.id, updatedValues, getRoute(location, defaultRoute, query)));
    } else {
      let nextRoute = getRoute(location, defaultRoute);

      // send checklist id if creating from form
      if (queriesFromString(location.search)['checklist_id']) {
        updatedValues.checklist_id = queriesFromString(location.search)['checklist_id'];
        nextRoute = null;
      }

      if (selectedCmls.length || updatedValues.scope?.selected_cmls) {
        updatedValues.scope = { ...changedValues.scope, selected_cmls_ids: selectedCmls };
        nextRoute = null; // allows navigation to new project page
      }

      dispatch(createProjects(updatedValues, nextRoute));
    }
  };

  /**
   * Decorators are used for setting the values of other fields based off of a field.
   * Decorator format can be found here: src/components/shared/form/common.js
   */
  const decorators = [];

  /**
   *  Validations that are per field.
   *  Errors associated with the form are passed back through formError in Redux.
   */
  const validate = values => {
    const errors = {};
    if (!update && !values.asset) {
      errors.asset = 'Please select a valid asset.';
    }
    if (values.name && values.name.length > 200) {
      errors.name = 'Ensure this field has no more than 200 characters.';
    }
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        if (values[key] === submittedValues[key]) {
          errors[key] = value;
        } else {
          errors[key] = undefined;
        }
      }
    }
    return errors;
  };

  if (loading) {
    return <Loading />;
  }

  // To prevent users accessing through url '/edit'
  const permissionCallback = (update, data) => {
    if (update && data.status === 'PUBLISHED') {
      return { message: 'Published projects cannot be edited.', backLink: `/projects/${data.id}` };
    }
  };

  return (
    <div className={update ? classes.root : {}}>
      {update && <ProjectToolbar id={data.id} title={data.name} project={data} isReadOnly={false} showEdit={false} />}
      {warnings.map((warning, i) => (
        <Alert
          severity={warning.severity || 'warning'}
          key={i}
          sx={{
            marginTop: '1.5rem',
            display: 'inline-flex',
            width: '100%',
          }}>
          {warning.message}
        </Alert>
      ))}
      <CommonForm
        update={update}
        title={title}
        initialValues={initialValues}
        fieldSettings={fieldSettings}
        formSettings={formSettings}
        onSubmit={onSubmit}
        decorators={decorators}
        validate={validate}
        loading={loading}
        dirty={dirty}
        error={error}
        keepDirtyOnReinitialize
        createPermissionName="hasProjectCreate"
        updatePermissionName="hasProjectEdit"
        permissionCallback={() => permissionCallback(update, data)}
        mutators={{ ...arrayMutators }}
      />
    </div>
  );
};

ProjectsForm.defaultProps = {
  update: false,
};

ProjectsForm.propTypes = {
  update: PropTypes.bool,
};

export default ProjectsForm;
