import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { jsonKeyToLabel } from '../../utilities/strings';
import { getAllUsers } from '../../store/features/usersActions';
import CommonForm from '../shared/form/Common';
import { bulkFieldOrder as fieldOrder, removeField, hideField } from './projectsShared';
import { useFeatureFlags } from '../../hooks/settingsHooks';
import Loading from '../shared/displays/Loading';
import { getUserOptionLabel } from '../../utilities/users';
import { projectsBulkShareEndpoint } from '../../store/apiV2/projects';
import { openSnackbar } from '../../store/snackbarActions';

const useStyles = makeStyles(theme => ({
  message: {
    color: 'red',
  },
  center: {
    textAlign: 'center',
  },
}));

const ProjectsBulkShareForm = props => {
  const { projects } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const { hasProjectPriority } = useFeatureFlags();
  const [submittedValues, setSubmittedValues] = useState({});

  const {
    data,
    loading,
    error,
    formError,
    dispatchRequest: postProjectsBulkShare,
  } = projectsBulkShareEndpoint.useEndpoint();

  const { initialUsers } = useSelector(state => {
    const { dataAll } = state.users.all;
    const initialUsers = dataAll.results?.map(getUserOptionLabel) ?? [];
    return { initialUsers };
  });

  useEffect(() => {
    dispatch(getAllUsers());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formSettings = {
    fieldOrder,
    removeField: removeField('BULK-SHARE'),
    hideField: (values, item) => {
      return hideField(values, item, hasProjectPriority, false);
    },
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      switch (key) {
        /* ---------- Required Fields ---------- */

        /* ---------- Regular Fields ---------- */
        case 'unshare':
          fieldSettings[key] = {
            type: 'checkbox',
            cellProps: {
              xs: 12,
            },
            fieldProps: {
              label: 'Unshare',
              name: key,
              helperText: 'Remove the selected users from the project watcher list.',
            },
          };
          break;
        case 'users':
          fieldSettings[key] = {
            type: 'user-autocomplete',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              required: true,
              multiple: true,
              initialUsers,
              labelwidth: 40,
              helperText: 'Search by name or email.',
            },
          };
          break;
        case 'note':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              multiline: true,
              placeholder: 'Enter a note to share with the selected users.',
            },
          };
          break;
        case 'notify':
          fieldSettings[key] = {
            type: 'checkbox',
            cellProps: {
              xs: 12,
            },
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              helperText: 'Send an email notifying users they have been added to the projects.',
            },
          };
          break;
        /* ----------  Disabled Fields ---------- */

        /* ----------  Default ---------- */
        default:
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
      }
    });
  }

  const title = 'Share Projects';

  // The form cannot handle data coming in as objects.  Get id so select field can render the selected option
  // when new, check if we need to apply any values from the queries
  const initialValues = {};

  const onSubmit = async changedValues => {
    const updatedValues = { ...changedValues };
    setSubmittedValues(updatedValues);
    // default value for action is to share { action: 'ADD'}
    let bulkSharePayload = {
      user_ids: updatedValues.users,
      project_ids: projects.ids,
      note: updatedValues.note,
      notify: updatedValues.notify,
    };

    if (updatedValues.unshare) {
      bulkSharePayload = {
        action: 'REMOVE',
        ...bulkSharePayload,
      };
    }

    try {
      await postProjectsBulkShare(bulkSharePayload);
      dispatch(openSnackbar('Form submitted successfully!', 'success'));
      props.onSubmit(true);
    } catch (error) {
      console.error('Error sharing projects: ', error);
      dispatch(openSnackbar('Error submitting the form.', 'error'));
      props.onSubmit(false);
    }
  };

  /**
   * Decorators are used for setting the values of other fields based off of a field.
   * Decorator format can be found here: src/components/shared/form/common.js
   */
  const decorators = [];

  /**
   *  Validations that are per field.
   *  Errors associated with the form are passed back through formError in Redux.
   */
  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        if (values[key] === submittedValues[key]) {
          errors[key] = value;
        } else {
          errors[key] = undefined;
        }
      }
    }
    return errors;
  };

  if (loading) {
    return <Loading />;
  }

  // To prevent users accessing through url '/edit'
  const permissionCallback = data => {
    if (data.status === 'PUBLISHED') {
      return { message: 'Published projects cannot be edited.', backLink: `/projects/${data.id}` };
    }
  };

  return (
    <div className={classes.root}>
      <CommonForm
        title={title}
        initialValues={initialValues}
        fieldSettings={fieldSettings}
        formSettings={formSettings}
        onSubmit={onSubmit}
        decorators={decorators}
        validate={validate}
        loading={loading}
        // dirty={dirty}
        error={error}
        keepDirtyOnReinitialize
        customToolbarProps={{ cancel: true }}
        createPermissionName="hasProjectCreate"
        updatePermissionName="hasProjectEdit"
        permissionCallback={() => permissionCallback(false, data)}
      />
    </div>
  );
};

ProjectsBulkShareForm.defaultProps = {
  projects: {},
};

ProjectsBulkShareForm.propTypes = {
  projects: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default ProjectsBulkShareForm;
