import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { TextField } from 'mui-rff';
import { FieldArray } from 'react-final-form-arrays';

const LocationLayerProfileZonesForm = props => {
  const { name, label, loading, disabled } = props;

  return (
    <FieldArray name={name}>
      {({ fields }) => (
        <>
          {' '}
          {label}
          <sup>*</sup>
          <Grid container spacing={1} direction="column">
            <Grid item xs={12}>
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item xs>
                  Value
                </Grid>
                <Grid item xs>
                  Label
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
            </Grid>
            {fields.map((name, index) => (
              <Grid item key={index} xs={12}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs>
                    <TextField
                      name={`${name}.value`}
                      placeholder="Enter the key value"
                      required
                      disabled={disabled || loading}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      name={`${name}.label`}
                      placeholder="Enter the display value"
                      disabled={disabled || loading}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      size="small"
                      disabled={disabled}
                      onClick={() => fields.remove(index)}
                      aria-label="remove label/value">
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            ))}
            <Grid item>
              <IconButton
                type="button"
                size="small"
                disabled={disabled}
                onClick={() => fields.push({ label: '', value: '' })}
                aria-label="add label/value">
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>
        </>
      )}
    </FieldArray>
  );
};

LocationLayerProfileZonesForm.defaultProps = {
  disabled: false,
  loading: false,
};

LocationLayerProfileZonesForm.propTypes = {
  /** the api key for the feature */
  name: PropTypes.string.isRequired,
  /** label for all the keys */
  label: PropTypes.string.isRequired,
  /** disables the field while true */
  disabled: PropTypes.bool,
  /** disables the filed while true */
  loading: PropTypes.bool,
};

export default LocationLayerProfileZonesForm;
