import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';

const TooltipWrapper = props => {
  const { title, placement, children } = props;
  return (
    <Tooltip placement={placement} title={title}>
      <div>{children}</div>
    </Tooltip>
  );
};

TooltipWrapper.defaultProps = {
  placement: 'top-start',
};
TooltipWrapper.propTypes = {
  placement: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default TooltipWrapper;
