// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import React from 'react';
import CommonRoutes from './utilities/CommonRoutes';
import ProjectTypesForm from '../../components/projectTypes/ProjectTypesForm';
import ProjectTypesView from '../../components/projectTypes/ProjectTypesView';
import ProjectTypesTable from '../../components/projectTypes/ProjectTypesTable';
import MainLayout from '../layouts/MainLayout';

const ProjectTypesRoutes = props => {
  return (
    <CommonRoutes
      pageTitle="Project Type"
      layout={MainLayout}
      Edit={<ProjectTypesForm update />}
      Create={<ProjectTypesForm />}
      View={<ProjectTypesView />}
      All={<ProjectTypesTable />}
    />
  );
};

export default ProjectTypesRoutes;
