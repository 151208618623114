import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import InspectionMediaTable from './InspectionMediaTable';
import SlideshowViewer from '../shared/slideshowViewer/SlideshowViewer';
import SlideshowGallery from '../shared/slideshowGallery/SlideshowGallery';
import { getAllInspectionMedia } from '../../store/features/inspectionMediaActions';
import { useMediaStore } from '../../hooks/mediaHooks';
import { toQueryString } from '../../utilities/strings';
import { setTableParamObj } from '../../utilities/objects';
import { useTableViews } from '../../hooks/settingsHooks';
import { tableViews } from '../../utilities/tables';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(7, '3vw'),
    padding: theme.spacing(2),
  },
}));

const InspectionMediaTablePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const { inspectionMediaTableViewKey } = tableViews;
  const { pageSize } = useTableViews(inspectionMediaTableViewKey);
  const { media, total, previous, next, loading } = useMediaStore();

  const queryParams = setTableParamObj(location, pageSize);

  useEffect(() => {
    dispatch(getAllInspectionMedia(queryParams));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const tableChangeHandler = query => {
    // no need to fetch data if the query is the same.
    const newSearch = toQueryString(query);
    if (location.search === newSearch) return;
    const newLocation = location;
    newLocation.search = newSearch;
    history.push(newLocation);
    dispatch(getAllInspectionMedia(query));
  };

  const [showGallery, setShowGallery] = useState(false);
  const [showSlides, setShowSlides] = useState(false);
  const [startingId, setStartingId] = useState(null);

  const setMediaSlide = id => {
    setStartingId(id);
    setShowSlides(true);
  };

  return (
    <Paper className={classes.root}>
      <InspectionMediaTable
        title="Inspection Media"
        queryParamObj={queryParams}
        tableChangeHandler={tableChangeHandler}
        embedded={false}
        setMediaSlide={setMediaSlide}
        showGallery={showGallery}
        setShowGallery={setShowGallery}
        showSlides={showSlides}
        isReadOnly
      />
      {showSlides && (
        <SlideshowViewer
          paginate
          mediaArray={media}
          showSlides={showSlides}
          setShowSlides={setShowSlides}
          startingId={startingId}
          total={total}
          itemsPerPage={pageSize}
          queryParamObj={queryParams}
          tableChangeHandler={tableChangeHandler}
          next={next}
          previous={previous}
          loading={loading}
        />
      )}
      {showGallery && (
        <SlideshowGallery
          showGallery={showGallery}
          setShowGallery={setShowGallery}
          setMediaSlide={setMediaSlide}
          tableChangeHandler={tableChangeHandler}
          isReadOnly
        />
      )}
    </Paper>
  );
};

export default InspectionMediaTablePage;
