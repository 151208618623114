// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
export const PROJECT_REPORT_GETTER_REQUEST = 'PROJECT_REPORT_GETTER_REQUEST';
export const PROJECT_REPORT_GETTER_SUCCESS = 'PROJECT_REPORT_GETTER_SUCCESS';
export const PROJECT_REPORT_GETTER_FAILURE = 'PROJECT_REPORT_GETTER_FAILURE';

export const getProjectReport = (id, reportType) => {
  return {
    type: PROJECT_REPORT_GETTER_REQUEST,
    id,
    reportType,
  };
};
