import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
// import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { BottomNavigation, Drawer, Grid } from '@mui/material';
import Button from '@mui/material/Button';
// import { useHotkeys } from 'react-hotkeys-hook';

import { getProjects } from '../../store/features/projectsActions';
import { getAllInspectionMedia, deleteInspectionMediaOverlay } from '../../store/features/inspectionMediaActions';
import { getAllDefects } from '../../store/features/defectsActions';
import { usePermissions, useFeatureFlags, useTableViews } from '../../hooks/settingsHooks';
import { queriesFromString, updateQueryStringOffset, toQueryString } from '../../utilities/strings';
import { galleryFilters } from './defectsShared';
import LoadingPage from '../shared/displays/LoadingPage';
import Error from '../shared/displays/Error';
import StyledLink from '../shared/StyledLink';
import InspectorToolbar from './InspectorToolbar';
import InspectorSidebar from './InspectorSidebar';
import InspectorSummary from './InspectorSummary';
import InspectorMap from './InspectorMap';
import InspectorGallery from './InspectorGallery';
import ImageAnnotator from '../shared/imageTools/ImageAnnotator2';
import ProjectTitle from '../shared/displays/ProjectTitle';
import FileUploader from '../projects/ProjectFileUploader';
import { getAllAssets } from '../../store/features/assetsActions';
import { tableViews } from '../../utilities/tables';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  sidebarItem: {
    width: '100%',
  },
  bottomDrawer: {
    height: 'auto',
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: 1,
  },
  summary: {
    padding: '16px 16px',
    flexDirection: 'row-reverse',
  },
  mediaTitle: {
    marginLeft: 12,
    marginRight: 10,
  },
  filter: {
    float: 'left',
  },
}));

const ProjectInspector = props => {
  const { projectId, mediaId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { hasConfigurationManagementBuild } = usePermissions();
  const { hasVIMapView } = useFeatureFlags();

  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [overlayInfo, setOverlayInfo] = useState({ overlays: [], index: 0 });
  const [flags, setFlags] = useState({ formNew: false, formDirty: false, overlayDirty: false });
  const [showDirtyError, setShowDirtyError] = useState({ state: false, from: '' });
  const [showSummary, setShowSummary] = useState(false);
  const lastMedia = useRef(false);
  const [filterValue, updateFilterValue] = useState(galleryFilters.ALL); // will need to refactor when we add more filters
  const { loading, error, data: project } = useSelector(state => state.projects.each);
  const { loading: mediaEdit } = useSelector(state => state.inspectionMedia.each);
  const { pageSize: mediaTableSize } = useTableViews(tableViews.inspectionMediaTableViewKey);
  const { loading: mediaLoading, dataAll: media, query } = useSelector(state => state.inspectionMedia.all);
  const mediaArray = media.results;
  const { limit, offset } = queriesFromString(query);
  const DEFAULT_OFFSET = 0;
  // gets offset of last page of data used in navigating visual inspector
  const lastOffset = media?.count && limit ? Math.floor(media?.count / limit) * limit : DEFAULT_OFFSET;

  const MAX_DEFECTS = 4;

  if (error) {
    console.error({ error }); // TODO: Handle error state
  }

  // TODO: handle filtering better
  const queryFilters = queriesFromString(location.search);
  const mobile = queryFilters?.mobile === 'true';
  const map = queryFilters?.map;

  // ------
  //
  // Memos
  //
  //

  const buildInspectionMediaFilters = useCallback(() => {
    const query = {
      project: projectId,
      document_category: 'image',
      hidden: false,
      ordering: 'name',
      defect: '',
      limit: mediaTableSize,
    };
    switch (filterValue) {
      case galleryFilters.DEFECTS_ONLY:
        // https://github.com/huvrdata/huvr/pull/7013
        query.defect = 'ANY';
        break;
      case galleryFilters.ALL:
      default:
        // noop
        break;
    }

    return query;
  }, [projectId, filterValue, mediaTableSize]);

  const activeMedia = mediaArray[currentMediaIndex];
  const mediaWithGPS = useMemo(() => {
    return mediaArray.reduce((mediaWithGPS, m) => {
      if (m.geo_point?.coordinates) {
        mediaWithGPS.push({
          id: m.id,
          geo_point: m.geo_point,
          // isActive: m.id === activeMedia.id,
          exif: m.file_meta?.exif,
        });
      }
      return mediaWithGPS;
    }, []);
  }, [mediaArray]);

  // --------
  //
  // Effects
  //
  //

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (project.id != projectId) {
      dispatch(getProjects(projectId));
      // change to batch fetch?
      dispatch(getAllDefects({ project: projectId }));

      const query = buildInspectionMediaFilters();
      getAllInspectionMedia(query);
    }
  }, [project.id, projectId, dispatch, buildInspectionMediaFilters]);

  useEffect(() => {
    if (project?.asset?.id) {
      dispatch(getAllAssets({ descendants: project?.asset?.id }));
    }
  }, [project?.asset?.id, dispatch]);

  useEffect(() => {
    if (activeMedia) {
      // add update key so we know which overlays need to be updated and which are new
      setOverlayInfo(prev => ({
        ...prev,
        overlays: activeMedia.overlays.map(overlay => ({ overlay })),
      }));
    }
  }, [activeMedia]);

  useEffect(() => {
    // if the media is loading, reset the dirty flags.
    if (mediaLoading) {
      setFlags(prevState => ({ ...prevState, formNew: false, formDirty: false, overlayDirty: false }));
    }
  }, [mediaLoading, mediaEdit]);

  /**
   * Whenever Inspection Media Filters change, Refetch Inspection Media
   */
  useEffect(() => {
    const query = buildInspectionMediaFilters();
    dispatch(getAllInspectionMedia(query));
  }, [buildInspectionMediaFilters, dispatch]);

  // --------
  //
  // Utils / Handlers
  //
  //

  const resetState = () => {
    setFlags({ formNew: false, formDirty: false, overlayDirty: false });
    setShowDirtyError({
      state: false,
      from: 'InspectorSidebar:handleTabChange',
      formDirty: flags.formDirty,
      overlayDirty: flags.overlayDirty,
      formNew: flags.formNew,
    });
  };

  const selectMedia = id => {
    const persistedQueries = toQueryString({
      ...(filterValue === galleryFilters.DEFECTS_ONLY && { [galleryFilters.DEFECTS_ONLY]: true }),
      ...(mobile && { mobile: true }),
      ...(map && { map }),
    });
    if (mediaId !== id) {
      history.push(`/projects/${projectId}/inspect/${id}${persistedQueries}`);
    }
    const searchFor = parseInt(id);
    const mediaIndex = mediaArray.findIndex(media => media.id === searchFor);
    if (mediaIndex !== currentMediaIndex) {
      setCurrentMediaIndex(mediaIndex);
    }
  };

  const addDefect = overlay => {
    if (flags.formDirty || flags.overlayDirty || flags.formNew) return;
    // when an overlay is created inside the ImageAnnotator
    // overlay object should be missing the key update, or have { update: false }
    // overlays needs to be spread into a new array or useState won't trigger a rerender.
    const updatedOverlays = [...overlayInfo.overlays];
    const overlayObject = { overlay };
    updatedOverlays.push(overlayObject);
    setOverlayInfo({ overlays: updatedOverlays, index: updatedOverlays.length - 1 });
    if (overlay.geometry == null) {
      setFlags(prevState => ({ ...prevState, formDirty: true }));
    } else {
      setFlags(prevState => ({ ...prevState, formDirty: true, overlayDirty: true }));
    }
  };
  const updateDefect = (overlayIndex, overlay) => {
    // when an overlay is moved/scaled inside the ImageAnnotator
    // make sure the update key is maintained in the ImageAnnotator
    const updatedOverlays = [...overlayInfo.overlays];
    const overlayObject = {
      ...updatedOverlays[overlayIndex],
      overlay,
    };
    updatedOverlays[overlayIndex] = overlayObject;
    setOverlayInfo(prev => ({ ...prev, overlays: updatedOverlays }));
    setFlags(prevState => ({ ...prevState, overlayDirty: true }));
  };
  const removeDefect = overlayIndex => {
    // when an overlay is deleted inside the ImageAnnotator or a new tab is canceled in the sidebar
    const newIndex = overlayInfo.index >= 0 ? overlayInfo.index - 1 : 0;
    const updatedOverlays = [...overlayInfo.overlays];
    updatedOverlays.splice(overlayIndex, 1);
    setOverlayInfo(prev => ({ overlays: updatedOverlays, index: newIndex }));
    resetState();
  };
  const resetDefect = overlayIndex => {
    // when an existing tab is canceled in the sidebar
    const updatedOverlays = [...overlayInfo.overlays];
    const overlayObject = { ...overlayInfo.overlays[overlayIndex] };
    overlayObject.overlay = { ...activeMedia.overlays[overlayIndex] };
    updatedOverlays[overlayIndex] = overlayObject;
    setOverlayInfo(prev => ({ ...prev, overlays: updatedOverlays }));
    resetState();
  };
  const deleteDefect = id => {
    if (id) {
      dispatch(deleteInspectionMediaOverlay(id));
    }
  };
  const nextMedia = () => {
    // clicking next inside the ImageAnnotator
    if (currentMediaIndex + 1 >= mediaArray.length) {
      // use in case we don't know the following media id since data hasn't loaded yet
      lastMedia.current = false;
      if (media?.next) {
        dispatch(getAllInspectionMedia(updateQueryStringOffset(media.next)));
      } else if (media?.previous) {
        // need to get data again if resetting to first item if we know this is a part of a set of several pages of data
        dispatch(getAllInspectionMedia(updateQueryStringOffset(query, DEFAULT_OFFSET)));
      } else {
        selectMedia(mediaArray[0].id);
      }
    } else {
      selectMedia(mediaArray[currentMediaIndex + 1].id);
    }
  };
  const prevMedia = () => {
    // clicking prev inside the ImageAnnotator
    if (currentMediaIndex - 1 < 0) {
      // use in case we don't know the following media id since data hasn't loaded yet
      lastMedia.current = true;
      if (media?.previous) {
        dispatch(getAllInspectionMedia(updateQueryStringOffset(media.previous)));
      } else if (media?.next) {
        // need to get data again if setting to last item if we know this is a part of a set of several pages of data
        dispatch(getAllInspectionMedia(updateQueryStringOffset(media.next, lastOffset)));
      } else {
        selectMedia(mediaArray.at(-1).id);
      }
    } else {
      selectMedia(mediaArray[currentMediaIndex - 1].id);
    }
  };

  const refresh = () => {
    dispatch(getProjects(projectId));
  };

  // Once the media is loaded, and the mediaId is set on the url page, select it if available
  if (mediaArray && mediaArray.length) {
    if (mediaId && mediaArray.find(item => item.id === parseInt(mediaId))) {
      selectMedia(mediaId);
    }
    // if media id is not available due to loading a new page of data, use 'selected' ref
    else {
      lastMedia.current ? selectMedia(mediaArray.at(-1).id) : selectMedia(mediaArray[0].id);
    }
  }

  // TODO: make arrows work
  // useHotkeys('right', () => handleMediaNext());
  // useHotkeys('left', () => handleMediaPrev());

  // --------
  //
  // Render Funcs
  //
  //

  const title = (
    <ProjectTitle
      link
      {...project}
      media={activeMedia}
      current={parseInt(offset ?? 0) + currentMediaIndex + 1}
      count={media ? media.count : 0}
    />
  );

  if (loading) {
    return <LoadingPage />;
  }

  const projectSetupCorrect = project.type && project.type.defect_profile && project.type.layer_profile;

  if (!projectSetupCorrect) {
    return (
      <>
        <Error error={`Configure project type to enable Visual Inspector.`} />
        {hasConfigurationManagementBuild && project.type && (
          <StyledLink to={`/project-types/${project.type.id}`} value="View Project Type" />
        )}
      </>
    );
  }

  const showMap = hasVIMapView && mediaWithGPS?.length;
  const heightOffset = showMap => {
    if (showMap) {
      return '492'; // height of the header (64), the map (300), the tab header (48) and the media header (80).
    }
    return '';
  };

  const renderEmptyState = () => {
    // show "reset filters" button
    if (filterValue === galleryFilters.DEFECTS_ONLY) {
      return (
        <Grid item xs={12}>
          No Findings have been made.
          <Button onClick={() => updateFilterValue(galleryFilters.ALL)}>Reset filters</Button>
        </Grid>
      );
    }

    // show "add media" uploader
    return (
      <Grid item xs={12}>
        {project.id && <FileUploader projectId={project.id} />}
      </Grid>
    );
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item>
        {!mobile && (
          <InspectorToolbar title={title} refresh={refresh} showSummary={showSummary} setShowSummary={setShowSummary} />
        )}
      </Grid>
      <Grid item>
        <Grid container direction="row" justifyContent="space-between" alignItems="stretch">
          {!media.count && renderEmptyState()}
          <div>
            <Drawer anchor="left" open={showSummary} onClose={() => setShowSummary(false)}>
              <InspectorSummary projectId={projectId} setShowSummary={setShowSummary} />
            </Drawer>
          </div>
          <Grid item xs={10}>
            {activeMedia && (
              <ImageAnnotator
                maxDefects={MAX_DEFECTS}
                showNavArrows
                image={activeMedia.file}
                overlays={overlayInfo.overlays}
                overlayIndex={overlayInfo.index}
                setOverlayInfo={setOverlayInfo}
                addDefect={addDefect}
                updateDefect={updateDefect}
                nextMedia={nextMedia}
                prevMedia={prevMedia}
                flags={flags}
                setFlags={setFlags}
                setShowDirtyError={setShowDirtyError}
                fileName={activeMedia.name}
              />
            )}
          </Grid>
          <Grid item xs={2}>
            <Grid container>
              {showMap ? (
                <Grid item className={classes.sidebarItem}>
                  <InspectorMap
                    mediaWithGPS={mediaWithGPS}
                    onMediaCircleClick={selectMedia}
                    activeMedia={activeMedia}
                    project={project}
                  />
                </Grid>
              ) : null}
              <Grid item className={classes.sidebarItem}>
                {media.count > 0 && (
                  <InspectorSidebar
                    maxDefects={MAX_DEFECTS}
                    media={activeMedia}
                    asset={project.asset}
                    defectProfile={project.type.defect_profile}
                    locationLayerProfile={project.type.layer_profile}
                    overlayInfo={overlayInfo}
                    addDefect={addDefect}
                    removeDefect={removeDefect}
                    resetDefect={resetDefect}
                    setOverlayInfo={setOverlayInfo}
                    flags={flags}
                    setFlags={setFlags}
                    showDirtyError={showDirtyError}
                    setShowDirtyError={setShowDirtyError}
                    deleteDefect={deleteDefect}
                    selectMedia={selectMedia}
                    heightOffset={heightOffset(showMap)}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <BottomNavigation className={classes.bottomDrawer}>
          <InspectorGallery
            filterValue={filterValue}
            onMediaFilterChange={e => updateFilterValue(e.target.value)}
            media={mediaArray}
            selectMedia={selectMedia}
            isSelected={activeMedia ? activeMedia.id : undefined}
            mediaFetch={q => dispatch(getAllInspectionMedia(q))}
          />
        </BottomNavigation>
      </Grid>
    </Grid>
  );
};

export default ProjectInspector;
