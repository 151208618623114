import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Toolbar, Typography, Link, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RefreshButton from '../shared/buttons/RefreshButton';
import ButtonIcon from '../shared/buttons/ButtonIcon';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100vw',
  },
  shrinkGridItem: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const InspectorToolbar = props => {
  const classes = useStyles();
  const logo = useSelector(state => state.settings.logo.src);

  const { title, showSummary, setShowSummary } = props;

  const setShowSidebar = () => {
    setShowSummary(true);
  };

  return (
    <Toolbar className={classes.root}>
      <Grid container alignItems="center" wrap="nowrap">
        <Grid item>
          <Link href="/">
            <img src={logo} height="32" style={{ marginRight: '5px' }} />
          </Link>
        </Grid>
        <Grid item>
          <ButtonIcon
            buttonSize="medium"
            iconSize="large"
            icon={InfoOutlinedIcon}
            onClick={setShowSidebar}
            disabled={showSummary}
            tooltip="Display Location Summary"
          />
        </Grid>
        <Grid item className={classes.shrinkGridItem}>
          <Typography variant="h6" noWrap>
            {' '}
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <RefreshButton showMenu refresh={props.refresh} />
        </Grid>
      </Grid>
    </Toolbar>
  );
};

InspectorToolbar.propTypes = {
  selectedRevision: PropTypes.number,
  revisions: PropTypes.array.isRequired,
  title: PropTypes.object,
  checklist: PropTypes.object,
  currentSection: PropTypes.string,
  refresh: PropTypes.func.isRequired,
  showSummary: PropTypes.bool,
  setShowSummary: PropTypes.func,
};
InspectorToolbar.defaultProps = {
  selectedRevision: -1,
  revisions: [],
  title: <></>,
  currentSection: '',
};

export default InspectorToolbar;
