import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';
import { getLibraryMedia } from '../../store/features/libraryMediaActions';
import { fieldOrder, removeField, hideField } from './libraryMediaShared';
import { apiDateToString, jsonKeyToLabel } from '../../utilities/strings';
import CommonFormView from '../shared/form/CommonView';

const LibraryMediaView = () => {
  const id = useParams().id;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLibraryMedia(id));
  }, [dispatch, id]);

  const { data, loading, error } = useSelector(state => state.libraryMedia.each);
  const { libraryId } = useSelector(state => state.settings.local);
  const formSettings = {
    fieldOrder,
    removeField: removeField('VIEW'),
    hideField,
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      // cast to string because booleans do not show up in the field
      let displayLabel = jsonKeyToLabel(key); // eslint-disable-line prefer-const
      let displayValue = '';
      if (data[key] != null) {
        switch (key) {
          // add cases for each key that needs to be customized.
          case 'preview':
            fieldSettings[key] = {
              type: 'image',
              fieldProps: {
                label: '',
                value: data[key],
              },
            };
            return;
          case 'file':
            fieldSettings[key] = {
              type: 'component',
              fieldProps: {
                label: '',
                value: () => (
                  <IconButton aria-label="download file" href={data[key]} download size="large">
                    <GetAppIcon />
                  </IconButton>
                ),
              },
            };
            return;
          case 'size':
            fieldSettings[key] = {
              type: 'size',
              fieldProps: {
                label: 'Size',
                num: data[key],
              },
            };
            return;
          case 'updated_on':
          case 'created_on':
            displayValue = apiDateToString(data[key], 'date');
            break;
          default:
            displayValue = data[key].toString();
        }
      }
      fieldSettings[key] = {
        type: 'text',
        fieldProps: {
          label: displayLabel,
          value: displayValue,
        },
      };
    });
  }

  return (
    <CommonFormView
      title={'Media Detail'}
      values={data}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      editLink={`/library-media/${id}/edit`}
      backLink={`/libraries/${libraryId}`}
      loading={loading}
      error={error}
    />
  );
};

export default LibraryMediaView;
