import isEqual from 'lodash/isEqual';

const CACHE_LIMIT = 5 * 60 * 1000; // 5 minutes in millisec

/**
 * shouldFetchState
 *
 * This function is used to determine whether or not to re-fetch data, usually for larger datasets
 *
 * @param {object} passedQuery - new query passed to fn
 * @param {string} reduxQuery - current query existing in redux
 * @param {string} lastFetch - timestamp of most recent api call
 * @return {boolean} - returns false if no conditions are met
 */
export const shouldFetchState = (passedQuery, reduxQuery, lastFetch) => {
  if (!isEqual(passedQuery, reduxQuery)) {
    return true;
  }
  if (!lastFetch) {
    return true;
  }
  if (lastFetch) {
    return Date.now() - lastFetch > CACHE_LIMIT;
  }
  return false;
};

export const setLastFetchAt = localStorageKey => {
  if (window.localStorage) {
    window.localStorage.setItem(localStorageKey, Date.now());
  } else {
    console.error(`localStorage not enabled - unable to set key "${localStorageKey}"`);
  }
};

export const getLastFetchAt = localStorageKey => {
  if (window.localStorage) {
    return window.localStorage.getItem(localStorageKey);
  } else {
    console.error(`localStorage not enabled - unable to set key "${localStorageKey}"`);
  }
};
