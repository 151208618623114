export const USERS_REQUEST = 'USERS_REQUEST';
export const USERS_SUCCESS = 'USERS_SUCCESS';
export const USERS_FAILURE = 'USERS_FAILURE';
export const USERS_CREATE_REQUEST = 'USERS_CREATE_REQUEST';
export const USERS_CREATE_SUCCESS = 'USERS_CREATE_SUCCESS';
export const USERS_CREATE_FAILURE = 'USERS_CREATE_FAILURE';
export const USERS_BULK_CREATE_REQUEST = 'USERS_BULK_CREATE_REQUEST';
export const USERS_BULK_CREATE_SUCCESS = 'USERS_BULK_CREATE_SUCCESS';
export const USERS_BULK_CREATE_FAILURE = 'USERS_BULK_CREATE_FAILURE';
export const USERS_UPDATE_REQUEST = 'USERS_UPDATE_REQUEST';
export const USERS_UPDATE_SUCCESS = 'USERS_UPDATE_SUCCESS';
export const USERS_UPDATE_FAILURE = 'USERS_UPDATE_FAILURE';
export const USERS_DELETE_REQUEST = 'USERS_DELETE_REQUEST';
export const USERS_DELETE_SUCCESS = 'USERS_DELETE_SUCCESS';
export const USERS_DELETE_FAILURE = 'USERS_DELETE_FAILURE';
export const USERS_ALL_REQUEST = 'USERS_ALL_REQUEST';
export const USERS_ALL_SUCCESS = 'USERS_ALL_SUCCESS';
export const USERS_ALL_FAILURE = 'USERS_ALL_FAILURE';

export const getUsers = id => {
  return {
    type: USERS_REQUEST,
    id,
  };
};

export const createUsers = body => {
  return {
    type: USERS_CREATE_REQUEST,
    body,
  };
};

/**
 * @param {Object} body - {address_list, company_id, roles, is_admin }
 *
 * address_list - supports RFC 2822 standard (copy-paste format from email client)
 * example: 'John Doe <email>' or can pass as list: [[display_name, email], [display_name, email]]
 * https://superuser.com/questions/1625686/what-is-the-email-address-format-name-email-called
 *
 * company_id - integer
 *
 * roles - array of strings ['technician', 'viewer']
 *
 * is_admin - boolean
 *
 */

export const createUsersBulk = body => {
  return {
    type: USERS_BULK_CREATE_REQUEST,
    body,
  };
};

export const updateUsers = (id, body, refresh = false) => {
  return {
    type: USERS_UPDATE_REQUEST,
    id,
    body,
    refresh,
  };
};

export const deleteUsers = id => {
  return {
    type: USERS_DELETE_REQUEST,
    id,
  };
};

export const getAllUsers = query => {
  return {
    type: USERS_ALL_REQUEST,
    query,
  };
};
