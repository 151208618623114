// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import React from 'react';
import CommonRoutes from './utilities/CommonRoutes';
import AssessmentsView from '../../components/assessments/AssessmentsView';
import AssessmentsTablePage from '../../components/assessments/AssessmentsTablePage';
import MainLayout from '../layouts/MainLayout';

const AssessmentsRoutes = props => {
  return (
    <CommonRoutes
      pageTitle="Assessments"
      layout={MainLayout}
      View={<AssessmentsView />}
      All={<AssessmentsTablePage />}
    />
  );
};

export default AssessmentsRoutes;
