import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { AppBar, Toolbar, Typography, Button, ButtonGroup, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ShareIcon from '@mui/icons-material/Share';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import StyledLink from '../shared/StyledLink';
import ProjectsStatusDisplay from './ProjectsStatusDisplay';
import AssetConditionDisplay from '../analytics/assetCondition/AssetConditionDisplay';
import ProjectStatusForm from './ProjectStatusForm';
import ChangeAssetConditionForm from './ChangeAssetConditionForm';
import ShareProjectForm from './ShareProjectForm';
import RefreshButton from '../shared/buttons/RefreshButton';
import TitleDescription from '../../components/shared/table/TitleDescription';
import { updateShareProject } from '../../store/features/projectsActions';
import { openDialog } from '../../store/dialogActions';
import { openSnackbar } from '../../store/snackbarActions';
import { useFeatureFlags, usePermissions } from '../../hooks/settingsHooks';
import AssetPathDisplay from '../assets/AssetPathDisplay';
import { appBarHeightFinder } from '../../utilities/styling';
import { makeURL } from '../../api/base';

const ProjectToolbar = React.forwardRef((props, ref) => {
  const workspace = useSelector(state => state.settings.features.workspace);
  const { id, title, project, refresh, isReadOnly, showEdit } = props;
  const drawerWidth = 270;
  const appBarHeight = appBarHeightFinder(workspace.alert_status);
  const { hasAssetCondition, hasProjectExport } = useFeatureFlags();

  const useStyles = makeStyles(theme => ({
    appBar: {
      marginTop: appBarHeight,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      marginTop: appBarHeight,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    toolBar: {
      paddingLeft: theme.spacing(1),
      fontSize: theme.spacing(3),
      display: 'flex',
    },
    assetLinks: {
      paddingLeft: theme.spacing(4),
      fontSize: theme.spacing(2),
      margin: theme.spacing(0, 0, 2),
    },
    projectType: {
      fontStyle: 'italic',
      paddingLeft: theme.spacing(4),
      fontSize: theme.spacing(2),
    },
    buttonGroup: {
      backgroundColor: theme.palette.secondary.main,
    },
    buttonToggle: {
      backgroundColor: theme.palette.secondary.contrastText,
      color: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.contrastText,
        color: theme.palette.secondary.main,
      },
    },
    iconButton: {
      display: 'inherit',
      padding: '3px',
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    hasCompanyManageAll,
    hasProjectEdit,
    hasProjectAssetConditionEdit,
    hasProjectStatusEdit,
    hasProjectStatusPublish,
    hasProjectStatusUnpublish,
    hasProjectShare,
  } = usePermissions();

  const showProjectExport = hasProjectEdit && hasProjectExport; // Who should have download capability?
  const open = useSelector(state => state.drawer.open);

  if (Object.keys(project).length === 0 && project.constructor === Object) {
    return <></>;
  }

  const allowProjectStatusEdit = () => {
    if (project.status === 'PUBLISHED') {
      return hasProjectStatusUnpublish;
    }
    return hasProjectStatusEdit || hasProjectStatusPublish || hasProjectStatusUnpublish;
  };
  const editLink = `/projects/${id}/edit`;
  const assetConditionEnabled =
    hasAssetCondition && project.asset.type.track_asset_condition && project.type.affects_asset_condition;
  const projectAssetCondition = project.asset_condition;
  const handleEditClick = function () {
    history.push(editLink);
  };
  const handleExcelDownloadClick = function () {
    const fullUrl = makeURL(`/api/projects/${id}/export/`);
    window.open(`${fullUrl}`, '_blank');
  };

  const owner = () => {
    if (project.asset.owner) {
      return (
        <StyledLink
          to={hasCompanyManageAll ? `/companies/${project.asset.owner.id}` : ''}
          value={project.asset.owner.name}
        />
      );
    }
    return <></>;
  };

  const changeStatus = () => {
    dispatch(openDialog('Project Status', <ProjectStatusForm status={project.status} id={id} />));
  };

  const changeAssetCondition = () => {
    dispatch(openDialog('Asset Condition', <ChangeAssetConditionForm status={projectAssetCondition} id={id} />));
  };

  const watchProject = () => {
    dispatch(updateShareProject(id, { watch: !project.watching }));
    dispatch(openSnackbar(!project.watching ? 'Watching Project' : 'Unwatching Project', 'info'));
  };

  const shareProject = () => {
    dispatch(openDialog('Share Project', <ShareProjectForm id={id} />));
  };

  const renderProjectPath = path => {
    return path.path_object.map((x, index) => {
      return (
        <span key={x.id}>
          <StyledLink to={`/projects/${x.id}`}>
            <TitleDescription value={x.name} />
          </StyledLink>
        </span>
      );
    });
  };

  return (
    <AppBar
      ref={ref}
      elevation={0}
      position="fixed"
      style={{ zIndex: 200 }}
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar>
        <p style={{ flex: 1 }} className={`h7 ${classes.toolBar}`}>
          {project.path_cache ? renderProjectPath(project.path_cache) : title}
          <RefreshButton showMenu refresh={refresh} />
        </p>
        <ButtonGroup className={classes.buttonGroup}>
          <Button sx={{ boxShadow: 2 }} onClick={changeStatus} disabled={!allowProjectStatusEdit()}>
            <ProjectsStatusDisplay status={project.status} />
          </Button>
          {assetConditionEnabled && (
            <Button
              sx={{ boxShadow: 2 }}
              onClick={changeAssetCondition}
              disabled={!hasProjectAssetConditionEdit || isReadOnly}
            >
              <AssetConditionDisplay condition={projectAssetCondition} />
            </Button>
          )}
          {hasProjectEdit && showEdit && (
            <Button sx={{ boxShadow: 2 }} startIcon={<EditIcon />} onClick={handleEditClick} disabled={isReadOnly}>
              Edit
            </Button>
          )}
          <Tooltip title={project.watching ? 'Unwatch' : 'Watch'}>
            <Button
              sx={{ boxShadow: 2 }}
              className={project.watching ? classes.buttonToggle : null}
              onClick={watchProject}
            >
              <span className={classes.iconButton}>
                {project.watching ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </span>
            </Button>
          </Tooltip>
          {hasProjectShare && (
            <Tooltip title="Share">
              <Button sx={{ boxShadow: 2 }} onClick={shareProject}>
                <span className={classes.iconButton}>
                  <ShareIcon />
                </span>
              </Button>
            </Tooltip>
          )}
          {showProjectExport && (
            <Tooltip title="Export">
              <Button sx={{ boxShadow: 2 }} onClick={handleExcelDownloadClick}>
                <span className={classes.iconButton}>
                  <CloudDownloadIcon />
                </span>
              </Button>
            </Tooltip>
          )}
        </ButtonGroup>
      </Toolbar>
      <Typography variant="body1" className={classes.projectType}>
        {project.type.name}
      </Typography>
      <Typography variant="h6" className={classes.assetLinks}>
        {owner()} / <AssetPathDisplay asset={project.asset} allLinks />
      </Typography>
    </AppBar>
  );
});

ProjectToolbar.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  project: PropTypes.object,
  refresh: PropTypes.func,
  isReadOnly: PropTypes.bool,
  showEdit: PropTypes.bool,
};
ProjectToolbar.defaultProps = {
  id: undefined,
  title: '',
  project: {},
  refresh: () => {},
  showEdit: true,
};

export default ProjectToolbar;
