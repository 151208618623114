import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '../shared/table/Table';
import { setNotificationRuleSubscribersTableView } from '../../store/settings/views/notificationRuleSubscribersTableViewRedux';
import { deleteNotificationRuleSubscribers } from '../../store/features/notificationRuleSubscribersActions';
import { openDialog } from '../../store/dialogActions';
import { apiDateToString } from '../../utilities/strings';
import DeleteDialog from '../shared/Dialog/DeleteDialog';
import { CrewLink, UserLink } from '../shared/links/InternalLinks';
import { setColumns } from '../shared/table/columns';
import { useTableViews } from '../../hooks/settingsHooks';
import { tableViews } from '../../utilities/tables';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const NotificationRuleSubscribersTable = props => {
  const { title, hideUser, hideRule } = props;
  const dispatch = useDispatch();
  const { loading, data } = useSelector(state => ({
    loading: state.notificationRuleSubscribers.all.loading,
    data: state.notificationRuleSubscribers.all.dataAll.results,
  }));
  const { notificationRuleSubscribersTableViewKey } = tableViews;
  const views = useTableViews(notificationRuleSubscribersTableViewKey);

  /** @type {Column[]} */
  const columns = [
    {
      // This needs to be first and excluded so customBodyRender can access the id to create the link.
      name: 'id',
      label: 'Id',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'rule.name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        display: !hideRule,
      },
    },
    {
      name: 'user',
      label: 'User',
      options: {
        filter: true,
        sort: true,
        display: !hideUser,
        customBodyRender: value => {
          if (!value) return '--';
          return <UserLink {...value} />;
        },
      },
    },
    {
      name: 'crew',
      label: 'Crew',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => {
          if (!value) return '--';
          return <CrewLink {...value} />;
        },
      },
    },
    {
      name: 'created_on',
      label: 'Created On',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => apiDateToString(value, 'date'),
      },
    },
    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        print: false,
        viewColumns: false,
        customBodyRender: (_, info) => {
          const id = info.rowData[columns.findIndexByName['id']];
          // determine whether subscriber is user or crew
          const subscriber =
            info.rowData[columns.findIndexByName['user']] || info.rowData[columns.findIndexByName['crew']];
          const ruleName = info.rowData[columns.findIndexByName['rule.name']];
          // determine which name to display in confirmation dialog
          const itemToBeDeleted = hideUser ? ruleName : subscriber.name;
          return (
            <>
              <DeleteIcon
                fontSize="small"
                onClick={() => {
                  dispatch(
                    openDialog(
                      'Delete Notification Rule Subscriber?',
                      <DeleteDialog id={id} deleteAction={deleteNotificationRuleSubscribers} name={itemToBeDeleted} />
                    )
                  );
                }}
              />
            </>
          );
        },
      },
    },
  ];

  // handle columns display
  setColumns(columns, views);

  /** @type {TableOptions} */
  const options = {
    // if the column view changes, update redux with either 'add' or 'remove'
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setNotificationRuleSubscribersTableView(changedColumn, action));
    },
    enableNestedDataAccess: '.',
    selectableRowsHideCheckboxes: true,
  };

  return (
    <Table
      title={title}
      simpleSearch
      columns={columns}
      // addRoute="/notification-rule-subscribers/new"
      data={data}
      options={options}
      loading={loading}
    />
  );
};

NotificationRuleSubscribersTable.defaultProps = {
  hideRule: false,
  hideUser: false,
};

NotificationRuleSubscribersTable.propTypes = {
  title: PropTypes.string.isRequired,
  hideRule: PropTypes.bool,
  hideUser: PropTypes.bool,
};

export default NotificationRuleSubscribersTable;
