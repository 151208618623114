import { all, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  ASSESSMENTS_REQUEST,
  ASSESSMENTS_SUCCESS,
  ASSESSMENTS_FAILURE,
  ASSESSMENTS_ALL_REQUEST,
  ASSESSMENTS_ALL_SUCCESS,
  ASSESSMENTS_ALL_FAILURE,
  ASSESSMENT_REVISION_CREATE_FAILURE,
  ASSESSMENT_REVISION_CREATE_REQUEST,
  ASSESSMENT_REVISION_CREATE_SUCCESS,
} from './assessmentsActions';

import { fetchAssessments, fetchAssessmentsList, createAssessmentRevision } from '../../api/features/assessments';
import { history } from '../../providers/StoreProvider';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';

import { pathToPage } from '../../utilities/strings';
import { tableViews } from '../../utilities/tables';
import { SET_TABLE_QUERY } from '../../store/settings/tableActions';

const { assessmentsTableViewKey: tableType } = tableViews;

export function* assessmentsFetch(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield fetchAssessments(id);
    if (data) {
      yield put({ type: ASSESSMENTS_SUCCESS, data });
    } else if (error) {
      yield put({ type: ASSESSMENTS_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, ASSESSMENTS_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, ASSESSMENTS_FAILURE);
  }
}

export function* watchAssessmentsFetch() {
  yield takeLatest(ASSESSMENTS_REQUEST, assessmentsFetch);
}

export function* assessmentsFetchAll(action) {
  const { query } = action; // pageInfo
  const location = history.location;
  try {
    const { data, error, response } = yield fetchAssessmentsList(action.query);
    if (data) {
      yield put({ type: ASSESSMENTS_ALL_SUCCESS, data });
    } else if (error) {
      yield put({ type: ASSESSMENTS_ALL_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, ASSESSMENTS_ALL_FAILURE);
    }
    // save the query in the store
    if (pathToPage(location.pathname)) {
      const page = pathToPage(location.pathname);
      yield put({ type: SET_TABLE_QUERY, page, query, tableType });
    }
  } catch (error) {
    yield defaultErrorCatch(error, ASSESSMENTS_ALL_FAILURE);
  }
}

export function* watchAssessmentsFetchAll() {
  yield takeLatest(ASSESSMENTS_ALL_REQUEST, assessmentsFetchAll);
}

export function* assessmentRevisionCreate(action) {
  const { id, options } = action;
  try {
    const { data, error, formError, response } = yield createAssessmentRevision(id);
    if (data) {
      if (options.routeAfterSuccess) {
        // display snackbar?
        yield put(push(options.routeAfterSuccess));
      }
      yield put({ type: ASSESSMENT_REVISION_CREATE_SUCCESS, data });
    } else if (error) {
      yield put({ type: ASSESSMENT_REVISION_CREATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: ASSESSMENT_REVISION_CREATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, ASSESSMENT_REVISION_CREATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, ASSESSMENT_REVISION_CREATE_FAILURE);
  }
}

export function* watchAssessmentRevisionCreate() {
  yield takeLatest(ASSESSMENT_REVISION_CREATE_REQUEST, assessmentRevisionCreate);
}

export default function* assessmentsSaga() {
  yield all([watchAssessmentsFetch(), watchAssessmentsFetchAll(), watchAssessmentRevisionCreate()]);
}
