export const isDjango = () => {
  return process.env.REACT_APP_API_FLAG === 'django';
};

export const reloadPage = () => {
  if (window.location) {
    window.location.reload(true);
  }
};

export const setLastPopupTime = () => {
  if (window.localStorage) {
    window.localStorage.setItem('lastPopupTime', Date.now());
  }
};

export const getLastPopupTime = () => {
  if (window.localStorage) {
    return window.localStorage.getItem('lastPopupTime');
  }
};

export const setServerBuildId = headers => {
  const serverBuildId = headers.get('x-huvr-api-build');
  if (window.localStorage && serverBuildId) {
    const payload = { buildId: serverBuildId, lastServerContactTime: Date.now() };
    window.localStorage.setItem('currentServerBuildId', JSON.stringify(payload));
  }
};

export const getServerBuildId = () => {
  return JSON.parse(window.localStorage.getItem('currentServerBuildId'));
};

export const getRefreshBool = (lastRefresh, refreshInterval) => {
  if (!lastRefresh) {
    return true;
  }
  const timeDifference = Date.now() - lastRefresh;
  if (timeDifference >= refreshInterval) {
    return true;
  }
  return false;
};
