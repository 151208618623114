import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { fieldOrder, removeField, hideField } from './projectsShared';
import { apiDateToString, jsonKeyToLabel, getDate, capitalizeFirstChar } from '../../utilities/strings';
import { ProjectLink, EventLink } from '../shared/links/InternalLinks';
import CommonFormView from '../shared/form/CommonView';
import ProjectsStatusDisplay from './ProjectsStatusDisplay';
import { useFeatureFlags } from '../../hooks/settingsHooks';

const useStyles = makeStyles(theme => ({
  projectsView: {
    margin: theme.spacing(0, 3.5),
  },
}));

const ProjectsView = props => {
  const { id, data, loading, error } = props;
  const classes = useStyles();
  const { hasProjectPriority, hasProjectLabels } = useFeatureFlags();
  const formSettings = {
    fieldOrder,
    removeField: removeField('VIEW'),
    hideField: (values, item) => {
      return hideField(values, item, { hasProjectPriority, hasProjectLabels });
    },
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      // cast to string because booleans do not show up in the field
      let displayLabel = jsonKeyToLabel(key); // eslint-disable-line prefer-const
      let displayValue = '';
      if (data[key] != null) {
        switch (key) {
          // add cases for each key that needs to be customized.
          case 'type':
          case 'asset':
          case 'management_company':
            displayValue = data[key].name;
            break;
          case 'asset_condition':
            fieldSettings[key] = {
              type: 'asset-condition',
              fieldProps: {
                label: displayLabel,
                value: data[key],
              },
            };
            return;
          case 'crews':
            fieldSettings[key] = {
              type: 'chips',
              fieldProps: {
                label: displayLabel,
                value: data[key],
                display: value => {
                  return value.name;
                },
              },
            };
            return;
          case 'status':
            fieldSettings[key] = {
              type: 'component',
              fieldProps: {
                label: displayLabel,
                value: () => <ProjectsStatusDisplay status={data[key]} />,
              },
            };
            return;
          case 'start':
          case 'end':
            displayValue = data[key] ? getDate(data[key], 'date') : '';
            break;
          case 'historical_project':
            displayValue = capitalizeFirstChar(String(data[key]));
            break;
          case 'work_done_on':
          case 'updated_on':
          case 'created_on':
            displayValue = apiDateToString(data[key], 'date');
            break;
          case 'parent':
            fieldSettings[key] = {
              type: 'component',
              cellProps: {
                xs: 6,
              },
              fieldProps: {
                label: displayLabel,
                value: () => <ProjectLink {...data[key]} />,
              },
            };
            return;
          case 'event':
            fieldSettings[key] = {
              type: 'component',
              fieldProps: {
                label: displayLabel,
                value: () => <EventLink {...data[key]} />,
              },
            };
            return;
          case 'labels_by_key':
            fieldSettings[key] = {
              type: 'key-value',
              fieldProps: {
                label: 'Labels', // rename Labels By Key -> Labels for display
                value: data[key],
              },
            };
            return;
          default:
            displayValue = data[key].toString();
        }
      }
      fieldSettings[key] = {
        type: 'text',
        fieldProps: {
          label: displayLabel,
          value: displayValue,
        },
      };
    });
  }

  return (
    <div className={classes.projectsView}>
      <CommonFormView
        bodyOnly
        title={'Project Details'}
        values={data}
        fieldSettings={fieldSettings}
        formSettings={formSettings}
        editLink={`/projects/${id}/edit`}
        loading={loading}
        error={error}
      />
    </div>
  );
};

ProjectsView.defaultProps = {
  data: {},
};

ProjectsView.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ProjectsView;
