import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, Redirect } from 'react-router-dom';
import { Grid, Paper, Typography, Radio, RadioGroup, FormControl, FormControlLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import HorizontalLinearStepper from '../measurements/HorizontalLinearStepper'; // move to shared
import StepperToolbar from '../measurements/StepperToolbar'; // move to shared
import FindingsProjectForm from './FindingsProjectForm';
import FindingsProjectSummary from './FindingsProjectSummary';
import { queriesFromString } from '../../utilities/strings';
import { getRoute } from '../../utilities/route';
import { getAllProjectTypes } from '../../store/features/projectTypesActions';
import { createProjects } from '../../store/features/projectsActions';
import { useFeatureFlags } from '../../hooks/settingsHooks';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(7, 'auto'),
    padding: theme.spacing(2),
    textAlign: 'left',
    width: '80vw',
  },
  form: {
    margin: theme.spacing(1),
    height: '90%',
  },
  selectedHeader: {
    fontWeight: 'bold',
  },
  selectedList: {
    listStyleType: 'circle',
    // margin: theme.spacing(1),
  },
  buttonGroup: {
    marginTop: theme.spacing(2),
  },
  stepContainer: {
    padding: theme.spacing(4),
  },
  promptContainer: {
    margin: theme.spacing(2),
  },
}));

const FindingsProjectWizard = props => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const [promptValue, setPromptValue] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [formIndex, setFormIndex] = useState(0);
  const [projectsToCreate, setProjectsToCreate] = useState([]);
  const [createWithParentRaw, setCreateWithParentRaw] = useState('');
  const [parent, setParent] = useState(null);

  const { hasProjectChildren } = useFeatureFlags();

  useEffect(() => {
    dispatch(getAllProjectTypes());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const SINGLE = 'single';
  const MULTI = 'multi';
  const isSingleProject = promptValue === SINGLE;
  const isMultiProjects = promptValue === MULTI;

  const createWithParent = createWithParentRaw === 'yes';

  // step constants
  const PROMPTS = 0;
  // const FORM = 1;
  const SUMMARY = 2;

  const { findings: selectedFindingIds } = queriesFromString(location.search);
  const { defects, selectedFindings } = useSelector(state => {
    const { results: defects } = state.defects.all.dataAll;
    const selectedFindings = defects.length
      ? [].concat(selectedFindingIds).map(id => defects.find(d => d.id.toString() === id))
      : [];
    return {
      defects,
      selectedFindings,
    };
  });

  const createMultipleProjects = (projects, route, options) => {
    projects.forEach(p =>
      dispatch(
        createProjects(
          {
            ...p,
            ...(createWithParent && { parent: parent.parentId }),
          },
          route,
          options
        )
      )
    );
  };

  const handleNext = () => {
    // form is contained to step 2 (index 1)
    if (activeStep < SUMMARY) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }

    const options = {
      preventDefault: true,
      displaySnackbar: true,
    };

    const defaultRoute = `/findings`;
    if (activeStep === SUMMARY) {
      if (isSingleProject) {
        const projectToCreate = projectsToCreate[0];
        dispatch(createProjects(projectToCreate, getRoute(location, defaultRoute), options));
      } else {
        const shouldRoute = formIndex === selectedFindings.length - 1;
        const routeValue = shouldRoute ? getRoute(location, defaultRoute) : undefined;
        createMultipleProjects(projectsToCreate, routeValue, options);
      }
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const currentFindingProject = selectedFindings[formIndex];

  const shouldNextDisable = step => {
    switch (step) {
      case 0:
        return !promptValue || (isMultiProjects && hasProjectChildren && !createWithParentRaw);
      case 1:
        return (
          (isSingleProject && !projectsToCreate.length) ||
          (isMultiProjects && projectsToCreate.length !== selectedFindings.length)
        );
      default:
        return false;
    }
  };

  // hide "back"/"next" buttons once the user steps into the form
  const back = {
    onClick: handleBack,
    label: 'Back',
    disabled: activeStep === PROMPTS,
    hide: formIndex > 0 || activeStep === SUMMARY,
  };
  const next = {
    onClick: handleNext,
    label: activeStep === SUMMARY ? 'Create' : 'Next',
    disabled: shouldNextDisable(activeStep),
    // hide: activeStep !== 0,
  };

  const Prompt = () => (
    <Grid item className={classes.promptContainer}>
      <Typography variant="subtitle1">
        Would you like to create a single project with all selected findings, or multiple projects?{' '}
      </Typography>
      <FormControl>
        <RadioGroup row value={promptValue} onChange={e => setPromptValue(e.target.value)}>
          <FormControlLabel
            value="single"
            control={<Radio color="primary" />}
            label="Single Project"
            labelPlacement="end"
          />
          <FormControlLabel
            value="multi"
            control={<Radio color="primary" disabled={selectedFindings.length === 1} />}
            label="Multiple Projects"
            labelPlacement="end"
          />
        </RadioGroup>
        {hasProjectChildren && isMultiProjects && (
          <>
            <Typography variant="subtitle1">
              Do you want to organize your new projects inside a new top-level project?
            </Typography>
            <RadioGroup row value={createWithParentRaw} onChange={e => setCreateWithParentRaw(e.target.value)}>
              <FormControlLabel value={'no'} control={<Radio color="primary" />} label="No" labelPlacement="end" />
              <FormControlLabel
                value={'yes'}
                control={<Radio color="primary" disabled={selectedFindings.length === 1} />}
                label="Yes"
                labelPlacement="end"
              />
            </RadioGroup>
          </>
        )}
      </FormControl>
    </Grid>
  );

  const getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <Paper className={classes.stepContainer}>
            <Typography variant="h6" gutterBottom>
              Selected Findings
            </Typography>
            <ul className={classes.selectedList}>
              {selectedFindings.map((finding, i) => (
                <li key={i}>
                  <Typography variant="h6">{`${finding.id} / ${finding.asset.name} / ${finding.component_display}`}</Typography>
                </li>
              ))}
            </ul>
            <Prompt />
          </Paper>
        );
      case 1:
        return (
          <Paper className={classes.stepContainer}>
            <FindingsProjectForm
              current={currentFindingProject}
              findings={selectedFindings}
              promptValue={promptValue}
              createWithParent={createWithParent}
              handleFormIndex={setFormIndex}
              formIndex={formIndex}
              setProjectsToCreate={setProjectsToCreate}
              handleNextStep={handleNext}
              isSingleProject={isSingleProject}
              setParent={setParent}
              projectsToCreate={projectsToCreate}
            />
          </Paper>
        );
      case 2:
        return (
          <Paper className={classes.stepContainer}>
            <FindingsProjectSummary
              handleFormIndex={setFormIndex}
              formIndex={formIndex}
              findings={selectedFindings}
              projectsToCreate={projectsToCreate}
              handleNextStep={handleNext}
              isSingleProject={isSingleProject}
              parent={parent}
            />
          </Paper>
        );
      default:
        return 'Unknown step';
    }
  };

  const isStepOptional = step => {
    return false;
  };

  const getSteps = () => {
    return ['Confirm Selected Findings', 'Select Project Type and Create Projects', 'Summary']; // ???
  };

  // redirect to findings if page is refreshed and/or data is lost
  if (!location.state || !defects.length) {
    return <Redirect to={'/findings'} />;
  }

  return (
    <Grid container direction="column" justifyContent="space-between" className={classes.formControl}>
      <HorizontalLinearStepper
        getSteps={getSteps}
        getStepContent={getStepContent}
        isStepOptional={isStepOptional}
        activeStep={activeStep}
      />
      <StepperToolbar back={back} next={next} />
    </Grid>
  );
};

FindingsProjectWizard.defaultProps = {};
FindingsProjectWizard.propTypes = {};
export default FindingsProjectWizard;
