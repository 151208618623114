import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import arrayMutators from 'final-form-arrays';
import { jsonKeyToLabel, apiDateToString } from '../../utilities/strings';
import { numberValidator } from '../../utilities/validators';
import {
  reportModes,
  titleModes,
  coverModes,
  measurementModes,
  densityOptions,
  layoutOptions,
  checklistMediaWidthOptions,
} from '../../api/features/constants';
import {
  reportTypesTemplatesEndpoint,
  reportTypesReadEndpoint,
  reportTypesCreateEndpoint,
  reportTypesPartialUpdateEndpoint,
} from '../../store/apiV2/reportTypes';
import { useFeatureFlags, useFeatureThemes } from '../../hooks/settingsHooks';

import CommonForm from '../shared/form/Common';
import {
  fieldOrder,
  removeField,
  hideField,
  getTemplateName,
  getTemplateOptions,
  paperSizeOptions,
  getPaperSize,
  PAPER_SIZE_DIMENSIONS,
} from './reportTypesShared';
import AppendixBlockForm from './AppendixBlockForm';

const ReportTypeForm = props => {
  const { update } = props;
  const params = useParams();
  const history = useHistory();

  const reportTypesTemplates = reportTypesTemplatesEndpoint.useEndpoint();
  const reportTypesRead = reportTypesReadEndpoint.useEndpoint();
  const reportTypesCreate = reportTypesCreateEndpoint.useEndpoint();
  const reportTypesPartialUpdate = reportTypesPartialUpdateEndpoint.useEndpoint();
  const { data, error, formError, loading } = reportTypesRead; // loading/errors shared with update and create

  const [submittedValues, setSubmittedValues] = useState({});

  const templates = reportTypesTemplates.data;
  const initialMode = update && data?.mode ? data.mode : 'PROJECT';
  const [templateOptions, setTemplateOptions] = useState(getTemplateOptions(initialMode, templates));
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  useEffect(() => {
    reportTypesTemplates.dispatchRequest();
    if (update && params.id) {
      reportTypesRead.dispatchRequest(params.id);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const options = getTemplateOptions(initialMode, templates);
    setTemplateOptions(options);
  }, [initialMode, templates]);

  const { hasCustomThemes } = useFeatureFlags();
  const themeOptions = useFeatureThemes();

  const title = update ? 'Edit Report Type' : 'New Report Type';

  const supportedVersionsOptions = useMemo(() => {
    const template = templates.find(t => t.name === selectedTemplate);
    if (!template) {
      return [];
    }
    return template.supported_versions.map(v => ({ label: v, value: v }));
  }, [selectedTemplate, templates]);

  const LETTER = 'LETTER';
  // set the defaults
  const initialValues = update
    ? {
        ...data,
        template_name: getTemplateName(data, templates),
        templates: templates,
        print_options: {
          ...data.print_options,
          ...(data.print_options?.paper_height && {
            paper_size: getPaperSize(data.print_options.paper_height),
          }),
        },
      }
    : {
        title_mode: 'DEFAULT',
        density: 'DEFAULT',
        layout: 'DEFAULT',
        mode: 'PROJECT',
        cover_mode: 'OFF',
        measurement_mode: 'DEFAULT',
        theme: 'default',
        template_name: getTemplateName(
          {
            template: 'reports/report_project_standard.html',
            uri: '/report/project/standard/{project_id}/{report_type_slug}',
            version: 2,
          },
          templates
        ),
        templates: templates,
        is_active: true,
        print_options: {
          paper_size: LETTER,
          margin_top: 0.39,
          margin_bottom: 0.39,
          margin_left: 0.39,
          margin_right: 0.39,
          scale: 1.0,
          print_background: false,
          omit_background: false,
          landscape: false,
        },
      };

  const onSubmit = values => {
    const updatedValues = { ...values };
    delete updatedValues.templates;
    setSubmittedValues(updatedValues);

    // send entire print_options object if updated
    if ('print_options' in updatedValues) {
      const widthHeight = { ...PAPER_SIZE_DIMENSIONS[updatedValues.print_options.paper_size] };
      updatedValues.print_options = {
        ...updatedValues.print_options,
        ...widthHeight,
      };
      delete updatedValues.print_options.paper_size;
    }

    const onSuccess = ({ data }) => history.push(`/report-types/${data.id}`);
    if (update) {
      reportTypesPartialUpdate.dispatchRequest(updatedValues.id, updatedValues).then(onSuccess);
    } else {
      reportTypesCreate.dispatchRequest(updatedValues).then(onSuccess);
    }
  };

  const updateTemplateFields = {
    field: 'template_name',
    updates: {
      template: (templateNameValue, formValues) => {
        const found = formValues.templates.find(template => template.name === templateNameValue);
        if (found) return found.template;
        return '';
      },
      uri: (templateNameValue, formValues) => {
        const found = formValues.templates.find(template => template.name === templateNameValue);
        if (found) return found.uri;
        return '';
      },
    },
  };
  const decorators = [updateTemplateFields];

  const validate = values => {
    const errors = {};
    if (formError) {
      for (const [key, value] of Object.entries(formError)) {
        if (values[key] === submittedValues[key]) {
          errors[key] = value;
        } else {
          errors[key] = undefined;
        }
      }
    }
    return errors;
  };

  const formSettings = {
    fieldOrder,
    removeField: removeField(update ? 'UPDATE' : 'CREATE'),
    hideField: (values, item) => {
      return hideField(values, item, hasCustomThemes);
    },
  };

  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      switch (key) {
        /* ---------- Required Fields ---------- */
        case 'name':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              required: true,
            },
          };
          break;
        /* ---------- Regular Fields ---------- */
        case 'mode':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              options: reportModes,
              labelwidth: 60,
              onChange: e => setTemplateOptions(getTemplateOptions(e.target.value, templates)),
            },
          };
          break;
        case 'density':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              options: densityOptions,
              labelwidth: 60,
            },
          };
          break;
        case 'checklist_media_width':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              options: checklistMediaWidthOptions,
              labelwidth: 135,
            },
          };
          break;
        case 'layout':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              options: layoutOptions,
              labelwidth: 60,
              helperText: 'Contents layout: 1 or 2 columns.',
            },
          };
          break;
        case 'theme':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              options: themeOptions,
              labelwidth: 60,
              helperText: 'Select a custom theme.',
            },
          };
          break;
        case 'cover_mode':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              options: coverModes,
              labelwidth: 75,
              helperText: 'Location to display a cover image.',
            },
          };
          break;
        case 'cover_option':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              helperText: 'Filter to select the cover image.',
            },
          };
          break;
        case 'title_mode':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              options: titleModes,
              labelwidth: 60,
            },
          };
          break;
        case 'measurement_mode':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              options: measurementModes,
              labelwidth: 125,
            },
          };
          break;
        case 'summary_option':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              helperText: 'Field look up for the summary, e.g. project.description',
              placeholder: 'e.g. project.description',
            },
          };
          break;
        case 'appendices':
          fieldSettings[key] = {
            type: 'component',
            fieldProps: {
              label: 'Appendices',
              name: 'appendices',
              component: () => AppendixBlockForm,
            },
          };
          break;
        case 'css':
        case 'description':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              multiline: true,
              rows: 3,
              maxRows: 10,
            },
          };
          break;
        case 'trim_work_instructions':
        case 'is_active':
          fieldSettings[key] = {
            type: 'checkbox',
            cellProps: {
              xs: 6,
            },
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          break;
        case 'footer':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              multiline: true,
              rows: 5,
              maxRows: 10,
            },
          };
          break;
        case 'version':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              options: supportedVersionsOptions,
              labelwidth: 60,
            },
          };
          break;
        case 'print_options.paper_size':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: 'Paper Size',
              name: key,
              options: paperSizeOptions,
              labelwidth: 125,
            },
          };
          break;
        case 'print_options.margin_top':
        case 'print_options.margin_bottom':
        case 'print_options.margin_left':
        case 'print_options.margin_right':
        case 'print_options.scale':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key.replace('print_options.', '')),
              name: key,
              validator: value => numberValidator(value),
              helperText: 'inches',
            },
          };
          break;
        case 'print_options.print_background':
        case 'print_options.omit_background':
        case 'print_options.landscape':
          fieldSettings[key] = {
            type: 'checkbox',
            cellProps: {
              xs: 6,
            },
            fieldProps: {
              label: jsonKeyToLabel(key.replace('print_options.', '')),
              name: key,
            },
          };
          break;
        /* ----------  Disabled Fields ---------- */
        case 'template_name':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              required: true,
              options: templateOptions,
              labelwidth: 103,
            },
          };
          break;
        case 'template':
        case 'uri':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              disabled: true,
            },
          };
          break;
        case 'created_on':
        case 'updated_on':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              disabled: true,
              value: value => apiDateToString(value),
            },
          };
          break;
        default:
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
      }
    });
  }
  return (
    <CommonForm
      update={update}
      title={title}
      initialValues={initialValues}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      onSubmit={onSubmit}
      decorators={decorators}
      mutators={{ ...arrayMutators }}
      validate={validate}
      loading={loading}
      error={error}
      onChangeValues={({ values }) => setSelectedTemplate(values.template_name)}
    />
  );
};

ReportTypeForm.defaultProps = {
  update: false,
};

ReportTypeForm.propTypes = {
  update: PropTypes.bool,
};

export default ReportTypeForm;
