import React from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import HelpIcon from '@mui/icons-material/Help';

import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer,
  ListSubheader,
  Tooltip,
} from '@mui/material';
import { apiDateToString } from '../../utilities/strings';

import UserDisplay from '../shared/displays/UserDisplay';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

const RevisionDrawer = props => {
  const classes = useStyles();

  const { revisions, selectedRevision, setRevisionId, anchor, open, closeDrawer } = props;

  const handleListItemClick = (event, revId) => {
    setRevisionId(revId);
  };

  // TODO: Allow change/edits to be applied/unapplied. (Using the revert flag.)
  const list = revisions.map(rev => (
    <ListItemButton
      key={rev.id}
      test-id={`revision-${rev.id}`}
      selected={selectedRevision?.id === rev.id}
      onClick={event => handleListItemClick(event, rev.id)}>
      <ListItemIcon>
        {rev.revert ? (
          <Tooltip title="Ignored.">
            <CheckBoxOutlineBlankIcon color="disabled" />
          </Tooltip>
        ) : (
          <CheckBoxIcon color="disabled" />
        )}
      </ListItemIcon>
      <Tooltip title={rev.revert ? 'Ignored. Changes after locking are ignored.' : 'Applied.'}>
        <ListItemText
          primary={`${apiDateToString(rev.updated_on, 'short-timestamp')}`}
          secondary={<UserDisplay {...rev.uploaded_by} />}
        />
      </Tooltip>
    </ListItemButton>
  ));

  return (
    <Drawer anchor={anchor} open={open} onClose={closeDrawer()}>
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === 'top' || anchor === 'bottom',
        })}
        role="presentation"
        onClick={closeDrawer()}
        onKeyDown={closeDrawer()}>
        <List dense="true">
          <ListSubheader>
            Version History{' '}
            <Tooltip title="Ignored changes do not appear in report. Select an item to view the contents.">
              <HelpIcon />
            </Tooltip>
          </ListSubheader>

          <Divider />
          {list?.slice().reverse()}
        </List>
      </div>
    </Drawer>
  );
};

RevisionDrawer.propTypes = {
  selectedRevision: PropTypes.object.isRequired, // will be {id: "new"} / {id: 1234, ...}
  revisions: PropTypes.array.isRequired,
  setRevisionId: PropTypes.func.isRequired,
  anchor: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired,
};

export default RevisionDrawer;
