import React from 'react';
import { Grid, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ChecklistTemplateBuilderToolbar from './ChecklistTemplateBuilderToolbar';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(10),
  },
}));

const ChecklistTemplateBuilderPage = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ChecklistTemplateBuilderToolbar title="Checklist Template Title Placeholder" />
      <Grid container spacing={1}>
        <Grid item container direction="column" xs={2} spacing={1}>
          <Grid item>
            <Paper>Nav Tree View</Paper>
          </Grid>
          <Grid item>
            <Paper>Sections</Paper>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Paper>Main Pane</Paper>
        </Grid>
        <Grid item xs={5}>
          <Paper>Checklist Preview</Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default ChecklistTemplateBuilderPage;
