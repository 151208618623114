import { all, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  CREWS_REQUEST,
  CREWS_SUCCESS,
  CREWS_FAILURE,
  CREWS_CREATE_REQUEST,
  CREWS_CREATE_SUCCESS,
  CREWS_CREATE_FAILURE,
  CREWS_UPDATE_REQUEST,
  CREWS_UPDATE_SUCCESS,
  CREWS_UPDATE_FAILURE,
  CREWS_DELETE_REQUEST,
  CREWS_DELETE_SUCCESS,
  CREWS_DELETE_FAILURE,
  CREWS_ALL_REQUEST,
  CREWS_ALL_SUCCESS,
  CREWS_ALL_FAILURE,
} from './crewsActions';

import { fetchCrews, createCrews, updateCrews, deleteCrews, fetchCrewsList } from '../../api/features/crews';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';
import { fetchListAll } from '../../utilities/sagaHelpers';
import { DIALOG_CLOSE } from '../dialogActions';

export function* crewsFetch(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield fetchCrews(id);
    if (data) {
      yield put({ type: CREWS_SUCCESS, data });
    } else if (error) {
      yield put({ type: CREWS_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, CREWS_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, CREWS_FAILURE);
  }
}

export function* watchCrewsFetch() {
  yield takeLatest(CREWS_REQUEST, crewsFetch);
}

export function* crewsCreate(action) {
  const { body } = action;
  try {
    const { data, error, formError, response } = yield createCrews(body);
    if (data) {
      yield put({ type: CREWS_CREATE_SUCCESS, data });
      yield put(push(`/crews/${data.id}`));
    } else if (error) {
      yield put({ type: CREWS_CREATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: CREWS_CREATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, CREWS_CREATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, CREWS_CREATE_FAILURE);
  }
}

export function* watchCrewsCreate() {
  yield takeLatest(CREWS_CREATE_REQUEST, crewsCreate);
}

export function* crewsUpdate(action) {
  const { id, body } = action;
  try {
    const { data, error, formError, response } = yield updateCrews(id, body);
    if (data) {
      yield put({ type: CREWS_UPDATE_SUCCESS, data });
      yield put(push('/crews'));
    } else if (error) {
      yield put({ type: CREWS_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: CREWS_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, CREWS_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, CREWS_UPDATE_FAILURE);
  }
}

export function* watchCrewsUpdate() {
  yield takeLatest(CREWS_UPDATE_REQUEST, crewsUpdate);
}

export function* crewsDelete(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield deleteCrews(id);
    if (data) {
      yield put({ type: CREWS_DELETE_SUCCESS });
      yield put({ type: CREWS_ALL_REQUEST });
      yield put({ type: DIALOG_CLOSE });
      yield put(push('/crews'));
    } else if (error) {
      yield put({ type: CREWS_DELETE_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, CREWS_DELETE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, CREWS_DELETE_FAILURE);
  }
}

export function* watchCrewsDelete() {
  yield takeLatest(CREWS_DELETE_REQUEST, crewsDelete);
}

export function* crewsFetchAll(action) {
  try {
    const { data, error, response } = yield fetchListAll(fetchCrewsList, action.query);
    if (data) {
      yield put({ type: CREWS_ALL_SUCCESS, data });
    } else if (error) {
      yield put({ type: CREWS_ALL_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, CREWS_ALL_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, CREWS_ALL_FAILURE);
  }
}

export function* watchCrewsFetchAll() {
  yield takeLatest(CREWS_ALL_REQUEST, crewsFetchAll);
}

export default function* crewsSaga() {
  yield all([watchCrewsFetch(), watchCrewsCreate(), watchCrewsUpdate(), watchCrewsDelete(), watchCrewsFetchAll()]);
}
