import React from 'react';
import PropTypes from 'prop-types';
// import { Select } from 'mui-rff';
import { FormHelperText } from '@mui/material';
import Select from './InputSelect';
import { requiredFn } from '../../../utilities/validators';

/**
 * Styling a select field for a form
 */

const FieldEditSelect = props => {
  const { disabled, required, name, label, value, options, loading, MenuProps, onChange, helperText } = props;

  const moreProps = {};
  // material-ui will show a warning if the value is loaded before the options
  if (options.length === 0) moreProps.value = '';
  // if we need to display a value or format the a value
  if (value) moreProps.value = value;

  let modifiedLabel = <span>{label}</span>;
  if (required) {
    modifiedLabel = (
      <span>
        {label}
        <sup>*</sup>
      </span>
    );
  }

  return (
    <>
      <Select
        disabled={disabled || loading}
        name={name}
        data-testid={`${name}-select`}
        label={modifiedLabel}
        data={options}
        fieldProps={required ? { validate: requiredFn(required) } : {}}
        formControlProps={{ className: 'select-input' }} // class defined in Common.scss
        inputLabelProps={{ shrink: true, disabled: loading }}
        onChange={onChange}
        MenuProps={MenuProps}
        {...moreProps}
      />
      <FormHelperText variant="outlined">{helperText}</FormHelperText>
    </>
  );
};

FieldEditSelect.defaultProps = {
  value: undefined,
  options: [],
  disabled: false,
  required: false,
  loading: false,
  MenuProps: {},
  onChange: null,
  helperText: '',
};

FieldEditSelect.propTypes = {
  /** label for the select field */
  label: PropTypes.string.isRequired,
  /** the api key for the feature */
  name: PropTypes.string.isRequired,
  /** possible values to select from in the shape of: */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  /** initial value for the field */
  value: PropTypes.string,
  /** sets the field as required for the form */
  required: PropTypes.bool,
  /** disables the field while true */
  disabled: PropTypes.bool,
  /** disables the field while true */
  loading: PropTypes.bool,
  MenuProps: PropTypes.object,
  onChange: PropTypes.func,
  /** adds helper text below the form field */
  helperText: PropTypes.string,
};

export default FieldEditSelect;
