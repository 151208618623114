import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import { jsonKeyToLabel, apiDateToString, queriesFromString } from '../../utilities/strings';
import { getBulkImports, createBulkImports, updateBulkImports } from '../../store/features/bulkImportsActions';
import CommonForm from '../shared/form/Common';
import { fieldOrder, removeField, hideField } from './bulkImportsShared';
import { importActionOptions } from '../../api/features/constants';

const BulkImportsForm = props => {
  const { update } = props;
  const params = useParams();
  const { data, loading, error, formError } = useSelector(state => state.bulkImports.each);
  const [submittedValues, setSubmittedValues] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (update && params.id) {
      dispatch(getBulkImports(params.id));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const queries = queriesFromString(location.search);
  const formSettings = {
    fieldOrder,
    removeField: removeField(update ? 'UPDATE' : 'CREATE'),
    hideField,
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      switch (key) {
        /* ---------- Required Fields ---------- */
        case 'upload':
          fieldSettings[key] = {
            type: 'file',
            fieldProps: {
              label: 'Import',
              name: 'upload',
              accept: '.xls, .xlsx',
              uploadText: 'Click or drop in the file to import.',
            },
          };
          break;

        /* ---------- Regular Fields ---------- */
        case 'action':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: 'Action',
              name: 'action',
              options: importActionOptions,
              labelwidth: 64,
            },
          };
          break;

        /* ----------  Disabled Fields ---------- */
        case 'created_on':
        case 'updated_on':
          fieldSettings[key] = {
            type: 'display',
            fieldProps: {
              disabled: true,
              label: jsonKeyToLabel(key),
              name: key,
              value: apiDateToString(data[key], 'date'),
            },
          };
          break;
        /* ----------  Default ---------- */
        default:
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
      }
    });
  }

  // UPDATE ME: Replace with singular version of feature name
  const title = update ? 'Edit Import' : 'New Import';

  let initialValues = {};
  if (update) {
    // The form cannot handle data coming in as objects.  (see ProjectsForm as an example)
    initialValues = { ...data };
  } else {
    // assign values from url queries here
    initialValues = {
      action: queries.action ? queries.action : undefined,
      flavor: queries.flavor ? queries.flavor : 'internal',
    };
  }

  const onSubmit = changedValues => {
    setSubmittedValues(changedValues);
    const updatedValues = { ...changedValues };
    if (update) {
      dispatch(updateBulkImports(updatedValues.id, updatedValues));
    } else {
      const formData = new FormData();
      const file = changedValues.upload[0];
      formData.append('action', updatedValues.action);
      formData.append('flavor', updatedValues.flavor);
      formData.append('upload', file);
      dispatch(createBulkImports(formData));
    }
  };

  /**
   * Decorators are used for setting the values of other fields based off of a field.
   * Decorator format can be found here: src/components/shared/form/common.js
   */
  const decorators = [];

  /**
   *  Validations that are per field.
   *  Errors associated with the form are passed back through formError in Redux.
   */
  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        if (values[key] === submittedValues[key]) {
          errors[key] = value;
        } else {
          errors[key] = undefined;
        }
      }
    }
    return errors;
  };

  return (
    <>
      <CommonForm
        update={update}
        title={title}
        initialValues={initialValues}
        fieldSettings={fieldSettings}
        formSettings={formSettings}
        onSubmit={onSubmit}
        decorators={decorators}
        validate={validate}
        loading={loading}
        error={error}
      />
      <Typography color="textPrimary">
        <a href="/templates/HUVR-project-import-template.xlsx">Project Import</a> Excel template.
        <br />
        <a href="/templates/HUVR-asset-import-template.xlsx">Asset Import</a> Excel template.
        <br />
        <a href="/templates/HUVR-cml-import-template.xlsx">CML Import</a> Excel template.
        <br />
        <a href="/templates/HUVR-measurement-import-template.xlsx">Measurement Import</a> Excel template.
      </Typography>
    </>
  );
};

BulkImportsForm.defaultProps = {
  update: false,
};

BulkImportsForm.propTypes = {
  update: PropTypes.bool,
};

export default BulkImportsForm;
