// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import { combineReducers } from 'redux';
import {
  CMLS_REQUEST,
  CMLS_SUCCESS,
  CMLS_FAILURE,
  CMLS_CREATE_REQUEST,
  CMLS_CREATE_SUCCESS,
  CMLS_CREATE_FAILURE,
  CMLS_UPDATE_REQUEST,
  CMLS_UPDATE_SUCCESS,
  CMLS_UPDATE_FAILURE,
  CMLS_DELETE_REQUEST,
  CMLS_DELETE_SUCCESS,
  CMLS_DELETE_FAILURE,
  CMLS_ALL_REQUEST,
  CMLS_ALL_SUCCESS,
  CMLS_ALL_FAILURE,
} from './cmlsActions';

export const initialStateCmls = {
  loading: false,
  error: '',
  formError: {},
  id: 0,
  body: {},
  data: {},
};

export const initialStateAllCmls = {
  loading: false,
  error: '',
  query: {},
  dataAll: { count: -1, next: null, previous: null, results: [] },
};

export const resetStateCmls = {
  loading: false,
  error: '',
  formError: {},
  data: {},
};
export const resetStateAllCmls = {
  loading: false,
  error: '',
  query: {},
  dataAll: [],
};

export const cmlsEachReducer = (state = initialStateCmls, action) => {
  switch (action.type) {
    case CMLS_REQUEST:
      return {
        ...initialStateCmls,
        loading: true,
        id: action.id,
      };
    case CMLS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case CMLS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case CMLS_CREATE_REQUEST:
      return {
        ...initialStateCmls,
        loading: true,
        body: action.body,
        nextRoute: action.nextRoute,
      };
    case CMLS_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case CMLS_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case CMLS_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        id: action.id,
        body: action.body,
        nextRoute: action.nextRoute,
        error: '',
        formError: {},
      };
    case CMLS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        nextRoute: action.nextRoute,
      };
    case CMLS_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case CMLS_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        formError: {},
        id: action.id,
      };
    case CMLS_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CMLS_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    default:
      return state;
  }
};

export const cmlsAllReducer = (state = initialStateAllCmls, action) => {
  switch (action.type) {
    case CMLS_ALL_REQUEST:
      return {
        ...initialStateAllCmls,
        loading: true,
        query: action.query,
      };
    case CMLS_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        dataAll: action.data,
      };
    case CMLS_ALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default combineReducers({
  each: cmlsEachReducer,
  all: cmlsAllReducer,
});
