import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import withStyles from '@mui/styles/withStyles';
import variables from '../../config.module.scss';

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    background: theme.palette.mode === 'dark' ? variables.darkestDark : variables.lightestGrey,
    color: theme.palette.mode === 'dark' ? variables.textPrimaryDark : variables.textPrimaryLight,
    maxWidth: 450,
    fontSize: theme.typography.pxToRem(14),
    border: `1px solid ${variables.grey}`,
  },
}))(Tooltip);

const PreviewTooltip = props => {
  const { title, children, placement } = props;
  return (
    <StyledTooltip arrow title={title} placement={placement} interactive="true">
      {children}
    </StyledTooltip>
  );
};

PreviewTooltip.defaultProps = {
  placement: 'top',
  componentKey: undefined,
};

PreviewTooltip.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
  placement: PropTypes.string,
};

export default PreviewTooltip;
