// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import React from 'react';
import CommonRoutes from './utilities/CommonRoutes';
// import AnytimeAnywhereFormsForm from '../../components/checklists/forms/AnytimeAnywhereFormsForm';
import FormDetail from '../../components/checklists/forms/FormDetail';
import ChecklistsTablePage from '../../components/checklists/ChecklistsTablePage';
import MainLayout from '../layouts/MainLayout';
import FeatureRoute from '../FeatureRoute';
import FormChecklistPicker from '../../components/checklists/forms/FormChecklistPicker';
import SimpleLayout from '../layouts/SimpleLayout';
import FormNewChecklist from '../../components/checklists/forms/FormNewChecklist';
import FormChecklist from '../../components/checklists/forms/FormChecklist';

const FormsRoutes = props => {
  return (
    <CommonRoutes
      pageTitle="Form"
      layout={MainLayout}
      // Edit={<AnytimeAnywhereFormsForm update />} will be modified later
      // Create={<AnytimeAnywhereFormsForm />}
      View={<FormDetail />}
      All={<ChecklistsTablePage />}>
      <FeatureRoute
        exact
        path={'/forms/new'}
        pageTitle="Form Create"
        component={FormChecklistPicker}
        layout={SimpleLayout}
      />
      <FeatureRoute
        exact
        path={'/forms/new/:templateId/:taskId?'}
        pageTitle="Form Create"
        component={FormNewChecklist}
        layout={SimpleLayout}
      />
      <FeatureRoute
        path={'/forms/:formId/checklist'}
        pageTitle="Form Edit"
        layout={SimpleLayout}
        component={FormChecklist}
      />
    </CommonRoutes>
  );
};

export default FormsRoutes;
