import { all, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  RECOMMENDED_WORK_PLAN_REQUEST,
  RECOMMENDED_WORK_PLAN_SUCCESS,
  RECOMMENDED_WORK_PLAN_FAILURE,
  RECOMMENDED_WORK_PLAN_CREATE_REQUEST,
  RECOMMENDED_WORK_PLAN_CREATE_SUCCESS,
  RECOMMENDED_WORK_PLAN_CREATE_FAILURE,
  RECOMMENDED_WORK_PLAN_UPDATE_REQUEST,
  RECOMMENDED_WORK_PLAN_UPDATE_SUCCESS,
  RECOMMENDED_WORK_PLAN_UPDATE_FAILURE,
  RECOMMENDED_WORK_PLAN_BULK_UPDATE_REQUEST,
  RECOMMENDED_WORK_PLAN_BULK_UPDATE_SUCCESS,
  RECOMMENDED_WORK_PLAN_BULK_UPDATE_FAILURE,
  RECOMMENDED_WORK_PLAN_DELETE_REQUEST,
  RECOMMENDED_WORK_PLAN_DELETE_SUCCESS,
  RECOMMENDED_WORK_PLAN_DELETE_FAILURE,
  RECOMMENDED_WORK_PLAN_ALL_REQUEST,
  RECOMMENDED_WORK_PLAN_ALL_SUCCESS,
  RECOMMENDED_WORK_PLAN_ALL_FAILURE,
} from './recommendedWorkPlanActions';

import {
  fetchRecommendedWorkPlan,
  createRecommendedWorkPlan,
  updateRecommendedWorkPlan,
  updateRecommendedWorkPlanBulk,
  deleteRecommendedWorkPlan,
  fetchRecommendedWorkPlanList,
} from '../../api/features/recommendedWorkPlan';
import { history } from '../../providers/StoreProvider';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';
import { fetchListAll } from '../../utilities/sagaHelpers';
import { DIALOG_CLOSE } from '../../store/dialogActions';
import { pathToPage } from '../../utilities/strings';
import { tableViews } from '../../utilities/tables';
import { SET_TABLE_QUERY } from '../../store/settings/tableActions';

const { recommendedWorkPlanTableViewKey: tableType } = tableViews;

export function* recommendedWorkPlanFetch(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield fetchRecommendedWorkPlan(id);
    if (data) {
      yield put({ type: RECOMMENDED_WORK_PLAN_SUCCESS, data });
    } else if (error) {
      yield put({ type: RECOMMENDED_WORK_PLAN_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, RECOMMENDED_WORK_PLAN_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, RECOMMENDED_WORK_PLAN_FAILURE);
  }
}

export function* watchRecommendedWorkPlanFetch() {
  yield takeLatest(RECOMMENDED_WORK_PLAN_REQUEST, recommendedWorkPlanFetch);
}

export function* recommendedWorkPlanCreate(action) {
  const { body } = action;
  try {
    const { data, error, formError, response } = yield createRecommendedWorkPlan(body);
    if (data) {
      yield put({ type: RECOMMENDED_WORK_PLAN_CREATE_SUCCESS, data });
      yield put(push(`/recommended-work-plan/${data.id}`));
    } else if (error) {
      yield put({ type: RECOMMENDED_WORK_PLAN_CREATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: RECOMMENDED_WORK_PLAN_CREATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, RECOMMENDED_WORK_PLAN_CREATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, RECOMMENDED_WORK_PLAN_CREATE_FAILURE);
  }
}

export function* watchRecommendedWorkPlanCreate() {
  yield takeLatest(RECOMMENDED_WORK_PLAN_CREATE_REQUEST, recommendedWorkPlanCreate);
}

export function* recommendedWorkPlanUpdate(action) {
  const { id, body } = action;
  try {
    const { data, error, formError, response } = yield updateRecommendedWorkPlan(id, body);
    if (data) {
      yield put({ type: RECOMMENDED_WORK_PLAN_UPDATE_SUCCESS, data });
      yield put(push(`/recommended-work-plan/${id}`));
    } else if (error) {
      yield put({ type: RECOMMENDED_WORK_PLAN_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: RECOMMENDED_WORK_PLAN_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, RECOMMENDED_WORK_PLAN_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, RECOMMENDED_WORK_PLAN_UPDATE_FAILURE);
  }
}

export function* watchRecommendedWorkPlanUpdate() {
  yield takeLatest(RECOMMENDED_WORK_PLAN_UPDATE_REQUEST, recommendedWorkPlanUpdate);
}

export function* recommendedWorkPlanDelete(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield deleteRecommendedWorkPlan(id);
    if (data) {
      yield put({ type: RECOMMENDED_WORK_PLAN_DELETE_SUCCESS });
      yield put({ type: RECOMMENDED_WORK_PLAN_ALL_REQUEST });
      yield put({ type: DIALOG_CLOSE });
      yield put(push('/recommended-work-plan'));
    } else if (error) {
      yield put({ type: RECOMMENDED_WORK_PLAN_DELETE_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, RECOMMENDED_WORK_PLAN_DELETE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, RECOMMENDED_WORK_PLAN_DELETE_FAILURE);
  }
}

export function* watchRecommendedWorkPlanDelete() {
  yield takeLatest(RECOMMENDED_WORK_PLAN_DELETE_REQUEST, recommendedWorkPlanDelete);
}

export function* recommendedWorkPlanBulkUpdate(action) {
  const { ids, body } = action;
  try {
    const { data, error, formError, response } = yield updateRecommendedWorkPlanBulk(ids, body);
    if (data) {
      yield put({ type: RECOMMENDED_WORK_PLAN_BULK_UPDATE_SUCCESS, data });
    } else if (error) {
      yield put({ type: RECOMMENDED_WORK_PLAN_BULK_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: RECOMMENDED_WORK_PLAN_BULK_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, RECOMMENDED_WORK_PLAN_BULK_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, RECOMMENDED_WORK_PLAN_BULK_UPDATE_FAILURE);
  }
}

export function* watchRecommendedWorkPlanBulkUpdate() {
  yield takeLatest(RECOMMENDED_WORK_PLAN_BULK_UPDATE_REQUEST, recommendedWorkPlanBulkUpdate);
}

export function* recommendedWorkPlanFetchAll(action) {
  const { query } = action; // pageInfo
  const location = history.location;
  try {
    const { data, error, response } = yield fetchListAll(fetchRecommendedWorkPlanList, action.query);
    if (data) {
      yield put({ type: RECOMMENDED_WORK_PLAN_ALL_SUCCESS, data });
    } else if (error) {
      yield put({ type: RECOMMENDED_WORK_PLAN_ALL_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, RECOMMENDED_WORK_PLAN_ALL_FAILURE);
    }
    // save the query in the store
    if (pathToPage(location.pathname)) {
      const page = pathToPage(location.pathname);
      yield put({ type: SET_TABLE_QUERY, page, query, tableType });
    }
  } catch (error) {
    yield defaultErrorCatch(error, RECOMMENDED_WORK_PLAN_ALL_FAILURE);
  }
}

export function* watchRecommendedWorkPlanFetchAll() {
  yield takeLatest(RECOMMENDED_WORK_PLAN_ALL_REQUEST, recommendedWorkPlanFetchAll);
}

export default function* recommendedWorkPlanSaga() {
  yield all([
    watchRecommendedWorkPlanFetch(),
    watchRecommendedWorkPlanCreate(),
    watchRecommendedWorkPlanUpdate(),
    watchRecommendedWorkPlanBulkUpdate(),
    watchRecommendedWorkPlanFetchAll(),
    watchRecommendedWorkPlanDelete(),
  ]);
}
