import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { jsonKeyToLabel, apiDateToString } from '../../utilities/strings';
import { getUserOptionLabel } from '../../utilities/users';
import { isEmpty } from '../../utilities/objects';

import {
  getChecklistTasks,
  createChecklistTasks,
  updateChecklistTasks,
} from '../../store/features/checklistTasksActions';
import CommonForm from '../shared/form/Common';
import { fieldOrder, removeField, hideField } from './checklistTasksShared';
import { useCrews } from '../../hooks/optionsHooks';
import { getAllCrews } from '../../store/features/crewsActions';

const ChecklistTasksForm = props => {
  const { update, taskId, projectId, handleNext, handleBack, checklist } = props;
  const { data, loading, error, formError } = useSelector(state => state.checklistTasks.each);
  const [submittedValues, setSubmittedValues] = useState({});

  const crewOptions = useCrews();
  const dispatch = useDispatch();

  const getAssignedUserOptions = () => {
    if (update) {
      return data.assigned_to && getUserOptionLabel(data.assigned_to);
    }
    return checklist.createdBy && getUserOptionLabel(checklist.createdBy);
  };

  useEffect(() => {
    if (update && taskId) {
      dispatch(getChecklistTasks(taskId));
    }
  }, [dispatch, update, taskId]);

  useEffect(() => {
    dispatch(getAllCrews({ is_active: true }));
  }, [dispatch, taskId]);

  const customToolbarProps = {
    backButtonProps: { label: 'Back', onClick: handleBack },
    saveButtonProps: { label: 'Save' },
  };
  if (update && isEmpty(data)) {
    // handle some extra crashes before there is data on autocomplete
    return null;
  }

  const formSettings = {
    fieldOrder,
    removeField: removeField(update ? 'UPDATE' : 'CREATE'),
    hideField,
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      switch (key) {
        case 'task':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              required: true,
            },
          };
          break;
        case 'assigned_to':
          fieldSettings[key] = {
            type: 'user-autocomplete',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              multiple: false,
              initialUsers: getAssignedUserOptions() || [],
              labelwidth: 40,
              queryParams: { is_active: true, ordering: name },
              helperText: 'Search by name or email. User will have access to entire Project.',
            },
          };
          break;
        case 'assigned_crew':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: 'assigned_crew',
              options: crewOptions,
              helperText: 'Crew will be assigned to Project and members will have access to entire Project.',
            },
          };
          break;
        /* ----------  Disabled Fields ---------- */
        case 'created_on':
        case 'updated_on':
          fieldSettings[key] = {
            type: 'display',
            fieldProps: {
              disabled: true,
              label: jsonKeyToLabel(key),
              name: key,
              value: apiDateToString(data[key], 'date'),
            },
          };
          break;
        /* ----------  Default ---------- */
        default:
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
      }
    });
  }

  const title = update ? 'Edit Task' : 'New Task';

  let initialValues = {};
  if (update) {
    // The form cannot handle data coming in as objects.  (see ProjectsForm as an example)
    initialValues = {
      ...data,
      assigned_to: String(data.assigned_to?.id),
    };
  } else {
    // assign values from url queries here
    if (projectId) {
      initialValues['project'] = projectId;
    }
    if (checklist && checklist !== ChecklistTasksForm.defaultProps.checklist) {
      initialValues['checklist'] = checklist.id;
      initialValues['assigned_to'] = checklist.createdBy?.id;
    }
  }
  const onSubmit = changedValues => {
    setSubmittedValues(changedValues);
    const updatedValues = { ...changedValues };
    if (update) {
      dispatch(updateChecklistTasks(updatedValues.id, updatedValues));
    } else {
      dispatch(createChecklistTasks(updatedValues));
    }
    handleNext();
  };

  /**
   * Decorators are used for setting the values of other fields based off of a field.
   * Decorator format can be found here: src/components/shared/form/common.js
   */
  const decorators = [];

  /**
   *  Validations that are per field.
   *  Errors associated with the form are passed back through formError in Redux.
   */
  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        if (values[key] === submittedValues[key]) {
          errors[key] = value;
        } else {
          errors[key] = undefined;
        }
      }
    }
    return errors;
  };

  return (
    <CommonForm
      embed
      update={update}
      title={title}
      initialValues={initialValues}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      onSubmit={onSubmit}
      decorators={decorators}
      validate={validate}
      loading={loading}
      error={error}
      customToolbarProps={customToolbarProps}
    />
  );
};

ChecklistTasksForm.defaultProps = {
  update: false,
  taskId: null,
  projectId: null,
  checklist: { createdBy: {} },
};

ChecklistTasksForm.propTypes = {
  update: PropTypes.bool,
  taskId: PropTypes.number,
  projectId: PropTypes.number,
  checklist: PropTypes.object,
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
};

export default ChecklistTasksForm;
