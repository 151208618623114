export const SET_DARK_MODE = 'SET_DARK_MODE';
export const SET_CURRENT_LIBRARY = 'SET_CURRENT_LIBRARY';
export const SAVE_REFRESH_INTERVAL = 'SAVE_REFRESH_INTERVAL';
export const SET_ASSET_TREE_VIEW = 'SET_ASSET_TREE_VIEW';
export const SELECT_ASSET_TREE_ASSET = 'SET_ASSET_TREE_ASSET';
export const EXPAND_ASSET_TREE = 'EXPAND_ASSET_TREE';

export const setDarkMode = darkModeEnabled => {
  return {
    type: SET_DARK_MODE,
    darkMode: darkModeEnabled,
  };
};

export const setCurrentLibrary = (libraryId, libraryName) => {
  return {
    type: SET_CURRENT_LIBRARY,
    libraryId,
    libraryName,
  };
};

export const storeRefreshInterval = refreshInterval => {
  return {
    type: SAVE_REFRESH_INTERVAL,
    refreshInterval,
  };
};

export const setAssetTreeView = assetTreeViewEnabled => {
  return {
    type: SET_ASSET_TREE_VIEW,
    assetTreeView: assetTreeViewEnabled,
  };
};

export const selectAssetTreeAsset = payload => {
  return {
    type: SELECT_ASSET_TREE_ASSET,
    selected: { id: payload.id, name: payload.name },
  };
};

export const expandAssetTree = ids => {
  return {
    type: EXPAND_ASSET_TREE,
    expanded: [...ids],
  };
};
