import { ROLES, rolesOptions as allRoles } from '../../api/features/constants';

/**
 * fieldOrder
 *
 * An object where the keys define the sections of the form.   Each section requires the key display (string) and
 * fields (array of strings). Fields are the api keys (in snake case) for each field that will be displayed in the form.
 * Any fields that are never shown should not be added to this array.
 *
 * @return {object}
 */
export const fieldOrder = {
  info: {
    display: 'Info',
    fields: ['name', 'first_name', 'last_name', 'email', 'is_active'],
  },
  permissions: {
    display: 'Permissions',
    fields: ['company', 'roles'],
  },
  status: {
    display: 'Status',
    fields: ['last_login', 'date_joined'],
  },
};

/**
 *  removeField
 *
 *  removes a field depending on if a user is creating, updating or viewing.
 *
 *  @param {string} state - enum for creating, updating or viewing ['CREATE', 'UPDATE', 'VIEW']
 *  @param {string} item - api key (in snake case) to check whether it should be hidden.
 *  @return {boolean} - returns true if the item will not be shown
 */
export const removeField = state => item => {
  let fields = [];
  switch (state) {
    case 'CREATE':
      fields = ['name', 'last_login', 'date_joined', 'display_name', 'is_active'];
      break;
    case 'UPDATE':
      fields = ['name', 'last_login', 'date_joined', 'display_name'];
      break;
    case 'VIEW':
      fields = [];
      break;
    default:
      return false;
  }
  return fields.includes(item);
};

/**
 * hideField
 *
 * This function is for hiding a field depending on the value of another field.
 *
 * @param {object} values - the values of all the fields in the form.
 * @param {string} item - api key (in snake case) to check whether it should be removed.
 * @return {boolean} - returns true if the item will not be shown
 */
export const hideField = (values, item, options = {}) => {
  switch (item) {
    case 'company':
      return options.hasUsersAssignCompany && options.update;
    default:
      return false;
  }
};

/**
 * roleOptions
 *
 * An array of objects that define a user's role
 * @param {string} isSubcontractor - is populated user a subcontractor
 * @param {object} permissions - (and features)
 * @param {boolean=} permissions.hasUserRolesAll
 * @param {boolean=} permissions.hasAssetAssessments
 * @param {boolean=} permissions.hasCompanyManagerRoles
 *
 * @return {array}
 */
export const roleOptions = (
  isSubcontractor,
  { hasUserRolesAll, hasAssetAssessments, hasProcessData, hasCompanyManagerRoles }
) => {
  return allRoles
    .filter(role => {
      switch (role.value) {
        case ROLES.subcontractor:
          return isSubcontractor;

        case ROLES.technician:
        case ROLES.project_manager:
          return !isSubcontractor;

        case ROLES.user_admin:
        case ROLES.workspace_admin:
        case ROLES.scheduler:
        case ROLES.asset_manager:
        case ROLES.configuration_builder:
        case ROLES.findings_manager:
          return hasUserRolesAll && !isSubcontractor;

        case ROLES.assessment_builder:
        case ROLES.assessment_viewer:
          return hasUserRolesAll && hasAssetAssessments && !isSubcontractor;

        case ROLES.process_data_manager:
        case ROLES.process_data_viewer:
          return hasUserRolesAll && hasProcessData && !isSubcontractor;

        case ROLES.company_asset_manager:
        case ROLES.company_project_manager:
        case ROLES.company_findings_manager:
          return hasUserRolesAll && hasCompanyManagerRoles && !isSubcontractor;

        case ROLES.viewer:
          return true;

        default:
          console.warn('Unhandled role', role.value);
          return false;
      }
    })
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const displayRole = role => {
  const index = allRoles.findIndex(roleOption => roleOption.value === role);

  if (index === -1) {
    return role;
  }
  return allRoles[index].label;
};
