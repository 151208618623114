import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Route as RRDRoute, Redirect } from 'react-router-dom';
import firebase from 'firebase/compat/app';

import BasicLayout from './layouts/BasicLayout';
import { storeRefreshInterval } from '../store/settings/settingsActions';
import { getAuthTokenId, setAuthTokenId, AUTH_COOKIE_NAME } from '../utilities/auth';
import { requestRefreshSessionCookie } from '../store/authActions';
import { getCookie } from '../utilities/strings';

/** Custom Route component
 * Renders a route with a layout and permissions
 * It can render either it's children or a component passed in through props
 * If this component has children ignore the component prop and just renders the children
 */

const FeatureRoute = props => {
  const { path, exact, permissions, pageTitle, layout: Layout, component: Component, deferLayout, children } = props;
  const { apiKey, authDomain, projectId, tenantId } = useSelector(state => state.auth.lookup);
  const dispatch = useDispatch();
  const token = getAuthTokenId();

  useEffect(() => {
    if (path !== '/login') {
      const interval = setInterval(stayLoggedIn, 5000, apiKey, authDomain, projectId, tenantId);
      dispatch(storeRefreshInterval(interval));
      return () => clearInterval(interval);
    }
  }, [apiKey, authDomain, dispatch, path, projectId, tenantId]); // eslint-disable-line

  function stayLoggedIn(apiKey, authDomain, projectId, tenantId) {
    try {
      if (firebase.apps.length === 0) {
        if (apiKey && authDomain && projectId) {
          firebase.initializeApp({ apiKey, authDomain, projectId });
          if (tenantId) {
            firebase.auth().tenantId = tenantId;
          }
        }
      }
      if (firebase.auth().currentUser) {
        firebase
          .auth()
          .currentUser.getIdToken()
          .then(idToken => {
            if (
              idToken !== getAuthTokenId() ||
              !getCookie(AUTH_COOKIE_NAME) // should exist, but extra check for smooth roll-out deployment
            ) {
              setAuthTokenId(idToken);
              dispatch(requestRefreshSessionCookie());
            }
          });
      }
    } catch (error) {
      console.error(error);
    }
  }

  if (permissions) {
    // check permission against current user.  return <NotFound /> if permissions don't match
    // if no permissions are set, anyone can access this route
  }

  const renderContent = () => {
    if (children) return children;
    if (Component) return <Component />;
    console.error('Route requires either a child component or a component prop.');
    return <></>;
  };

  const renderLayout = () => {
    if (Layout) {
      return <Layout pageTitle={pageTitle}>{renderContent()}</Layout>;
    } else if (deferLayout) {
      return renderContent();
    }
    return <BasicLayout pageTitle={pageTitle}>{renderContent()}</BasicLayout>;
  };

  // Avoid initially rendering a protected page before redirecting to the login page
  if (!token && permissions !== 'public') {
    return <Redirect to="/login" />;
  } else {
    return (
      <RRDRoute path={path} exact={exact}>
        {renderLayout()}
      </RRDRoute>
    );
  }
};

FeatureRoute.defaultProps = {
  exact: false,
  permissions: '',
  pageTitle: '',
  layout: undefined,
  component: undefined,
  children: undefined,
  deferLayout: false, // deferLayout allows the component to supply it's own layout
};

FeatureRoute.propTypes = {
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  exact: PropTypes.bool,
  permissions: PropTypes.string,
  pageTitle: PropTypes.string,
  layout: PropTypes.func,
  component: PropTypes.func,
  children: PropTypes.element,
  deferLayout: PropTypes.bool,
};

export default FeatureRoute;
