// see https://docs.huvrdata.app/reference/api_heat-data_read
export const FIELDS = {
  id: 'id',
  source: {
    $: 'source',
    id: 'source.id',
    name: 'source.name',
  },
  heat_number: 'heat_number',
  grade: 'grade',
  grade_type: 'grade_type',
  size: 'size',
  shift: 'shift',
  crew: 'crew',
  charge_weight: 'charge_weight',
  billet_weight: 'billet_weight',
  tap_start_time: 'tap_start_time',
  tap_start: 'tap_start',
  time_since_last_tap: 'time_since_last_tap',
  tapping_prep: 'tapping_prep',
  power_on: 'power_on',
  power_off: 'power_off',
  all_delay: 'all_delay',
  downday_delay: 'downday_delay',
  external_delay: 'external_delay',
  free_time: 'free_time',
  eaf_duration: 'eaf_duration',
  eaf_energy: 'eaf_energy',
  lmf_energy: 'lmf_energy',
  energy_density: 'energy_density',
  tap_temperature: 'tap_temperature',
  tap_oxy: 'tap_oxy',
  ladle_temperature: 'ladle_temperature',
  ladle: 'ladle',
  uploaded_by: {
    $: 'uploaded_by',
    id: 'uploaded_by.id',
    name: 'uploaded_by.name',
  },
  modified_by: {
    $: 'modified_by',
    id: 'modified_by.id',
    name: 'modified_by.name',
  },
  created_on: 'created_on',
  extras: 'extras',
};

const F = FIELDS; // alias

/**
 * fieldOrder
 *
 * An object where the keys define the sections of the form.   Each section requires the key display (string) and
 * fields (array of strings). Fields are the api keys (in snake case) for each field that will be displayed in the form.
 * Any fields that are never shown should not be added to this array.
 *
 * @return {object}
 */
export const fieldOrder = {
  status: {
    display: 'Status',
    fields: [F.modified_by.$, F.uploaded_by.$, F.created_on],
  },
};

/**
 *  removeField
 *
 *  removes a field depending on if a user is creating, updating or viewing.
 *
 *  @param {string} state - enum for creating, updating or viewing ['CREATE', 'UPDATE', 'VIEW']
 *  @param {string} item - api key (in snake case) to check whether it should be hidden.
 *  @return {boolean} - returns true if the item will not be shown
 */
export const removeField = state => item => {
  let fields = [];
  switch (state) {
    case 'VIEW':
      fields = [];
      break;
    default:
      return false;
  }
  return fields.includes(item);
};

/**
 * hideField
 *
 * This function is for hiding a field depending on the value of another field.
 *
 * @param {object} values - the values of all the fields in the form.
 * @param {string} item - api key (in snake case) to check whether it should be removed.
 * @return {boolean} - returns true if the item will not be shown
 */
export const hideField = (values, item) => {
  return false;
};
