// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
export const PROJECT_SELECTED_FINDINGS_GETTER_REQUEST = 'PROJECT_SELECTED_FINDINGS_GETTER_REQUEST';
export const PROJECT_SELECTED_FINDINGS_GETTER_SUCCESS = 'PROJECT_SELECTED_FINDINGS_GETTER_SUCCESS';
export const PROJECT_SELECTED_FINDINGS_GETTER_FAILURE = 'PROJECT_SELECTED_FINDINGS_GETTER_FAILURE';

/**
 * Special case of "defects" query to enable loading 2 sets of data on same page
 *
 *    Will trigger:
 *      GET /api/defects/?project_selected_findings=24
 *
 *    And populate:
 *      state.projectSelectedFindings.data
 *
 *    See expected data type returned from api:
 *      - https://docs.huvrdata.app/reference/api_defects_list
 *
 *    Additional context:
 *      - https://github.com/huvrdata/huvr/issues/6442
 *      - https://github.com/huvrdata/huvr/issues/6440
 *
 *
 * @param {Object} query { project_selected_findings: 24 }
 *
 */
export const getProjectSelectedFindings = (query = {}) => {
  return {
    type: PROJECT_SELECTED_FINDINGS_GETTER_REQUEST,
    query,
  };
};
