// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import React from 'react';
import CommonRoutes from './utilities/CommonRoutes';
import ScheduleEventsForm from '../../components/scheduleEvents/ScheduleEventsForm';
import MainLayout from '../layouts/MainLayout';
import ScheduleEventsTablePage from '../../components/scheduleEvents/ScheduleEventsTablePage';
import ScheduleEventsDetail from '../../components/scheduleEvents/ScheduleEventsDetail';

const ScheduleEventsRoutes = props => {
  return (
    <CommonRoutes
      pageTitle="Schedule Events"
      layout={MainLayout}
      Edit={<ScheduleEventsForm update />}
      Create={<ScheduleEventsForm />}
      View={<ScheduleEventsDetail />}
      All={<ScheduleEventsTablePage />}
    />
  );
};

export default ScheduleEventsRoutes;
