import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// util / store
import { useFileUploader } from '../../providers/FileUploaderProvider';
import { getAllLibraryMedia } from '../../store/features/libraryMediaActions';
import { createLibraryMedia } from '../../api/features/libraryMedia';

/**
 *
 */
const LibraryFileUploader = props => {
  const dispatch = useDispatch();

  const { libraryId, libraryName } = props;
  const { uploadOptionsRef, renderDragNDrop } = useFileUploader();

  /**
   * @name useEffect-setUploadOptions
   */
  useEffect(() => {
    uploadOptionsRef.current = {
      /**
       * @name getUploadParameters
       * @param {Object} file
       * @returns {Promise<{ method, url, fields, headers }>}
       * @description create a signed url for uploading to GCP bucket
       *   creates a record in the database for the file
       *
       * See:
       *  https://uppy.io/docs/aws-s3-multipart/#getuploadparametersfile-options
       */
      getUploadParameters: file => {
        // Send a request to our signing endpoint.
        const payload = {
          filename: file.name,
          media_type: file.type,
          library: libraryId,
        };

        // if uploaded in folder structure, send that along
        if (file.meta.relativePath) {
          payload.file_meta = { relative_path: file.meta.relativePath };
        }

        return createLibraryMedia(payload).then(resp => {
          // Return an object in the correct shape.
          // save the database id, to update after upload

          file.remoteId = resp.data.id;
          return {
            method: 'PUT',
            url: resp.data.upload.url,
            fields: [],
            headers: resp.data.upload.headers,
          };
        });
      },

      /**
       * @name onUploadSuccess
       * @description callback to run after all files have been uploaded
       *    update the inspection media list
       */
      onUploadSuccess: () => {
        dispatch(getAllLibraryMedia(`?library=${libraryId}`));
      },
    };

    return () => {
      // on unmount unset uploadOptions
      uploadOptionsRef.current = {};
    };
  }, [dispatch, uploadOptionsRef, libraryId, libraryName]);

  return <>{renderDragNDrop({ note: `Add media to ${libraryName}` })}</>;
};

LibraryFileUploader.defaultProps = {};

LibraryFileUploader.propTypes = {
  libraryId: PropTypes.number.isRequired,
  libraryName: PropTypes.string.isRequired,
};

export default LibraryFileUploader;
