import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { IconButton, SnackbarContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { default as MUISnackbar } from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { closeSnackbar } from '../../store/snackbarActions';
import variables from '../../config.module.scss';

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: variables.green,
  },
  info: {},
  warning: {
    backgroundColor: variables.yellow,
  },
  error: {
    backgroundColor: variables.red,
  },
}));

/**
 * Component that sets up and displays the snackbar.
 */

export const Snackbar = () => {
  const { open, message, variant, duration, action, link } = useSelector(state => state.snackbar);
  const dispatch = useDispatch();
  const classes = useStyles();
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    dispatch(closeSnackbar());
  };

  return (
    <MUISnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}>
      <SnackbarContent
        className={classes[variant]}
        message={
          <span id="message-id">
            {message}
            {link ? <Link to={link}> - View Here</Link> : null}
          </span>
        }
        action={[
          action,
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={handleClose}
            size="large">
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </MUISnackbar>
  );
};

Snackbar.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  variant: PropTypes.string,
};

export default Snackbar;
