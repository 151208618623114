import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@mui/material';
import Refresh from '@mui/icons-material/Refresh';
import ButtonIcon from './ButtonIcon';

const RefreshButton = props => {
  const { refresh, showMenu } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleRefreshClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleRefreshClose = () => {
    setAnchorEl(null);
  };

  if (showMenu) {
    return (
      <>
        <ButtonIcon
          buttonSize="medium"
          iconSize="medium"
          onClick={handleRefreshClick}
          icon={Refresh}
          color={props.color}
        />
        <Menu id="clear-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleRefreshClose}>
          <MenuItem disabled={true}>Refresh Page?</MenuItem>
          <MenuItem
            onClick={() => {
              refresh();
              handleRefreshClose();
            }}
          >
            Refresh
          </MenuItem>
        </Menu>
      </>
    );
  }
  return <ButtonIcon buttonSize="medium" iconSize="medium" onClick={refresh} icon={Refresh} color={props.color} />;
};

RefreshButton.defaultProps = {
  showMenu: false,
  color: 'inherit',
};

RefreshButton.propTypes = {
  refresh: PropTypes.func.isRequired,
  showMenu: PropTypes.bool,
  color: PropTypes.string,
};

export default RefreshButton;
