import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import MeasurementsTable from '../../measurements/MeasurementsTable';
import { useTableViewSettings } from '../../../hooks/settingsHooks';

import { getAllMeasurements } from '../../../store/features/measurementsActions';
import { tableViews } from '../../../utilities/tables';

const MeasurementsTab = props => {
  const { detailPageQuery, filename, hideCML } = props;
  const { measurementsTableViewKey } = tableViews;
  const dispatch = useDispatch();
  const defaultQuery = { ...detailPageQuery }; // same for now, but more complex queries may be needed in the future
  const measurementsQuery = useTableViewSettings(measurementsTableViewKey, defaultQuery);
  const measurementsTableChangeHandler = query => {
    const updatedQuery = { ...measurementsQuery.embeddedQuery, ...query };
    dispatch(getAllMeasurements(updatedQuery));
  };

  useEffect(() => {
    dispatch(getAllMeasurements(measurementsQuery.tableQuery));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MeasurementsTable
      title="Measurements"
      embedded
      filename={filename}
      tableChangeHandler={measurementsTableChangeHandler}
      queryParamObj={measurementsQuery.tableQuery}
      page={measurementsQuery.page}
      views={measurementsQuery.views}
      hideCML={hideCML}
    />
  );
};

MeasurementsTab.defaultProps = {
  filename: 'Measurements',
  hideCML: false,
};

MeasurementsTab.propTypes = {
  detailPageQuery: PropTypes.object.isRequired,
  filename: PropTypes.string,
  hideCML: PropTypes.bool,
};

export default MeasurementsTab;
