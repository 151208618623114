import React, { useEffect, createContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Tabs, Tab, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { getAllChecklists } from '../../../store/features/checklistsActions';
import ChecklistsTable from '../../checklists/ChecklistsTable';
import Loading from '../../shared/displays/Loading';
import TabPanel from '../../shared/TabPanel';
import { getObservations } from '../../../store/getters/observationsActions';
import ObservationChart from './ObservationChart';
import FilterBarAssets from '../FilterBarAssets';
import { useTopLevelAssets } from '../../../hooks/assetHooks';
import { assetsFiltersEndpoint } from '../../../store/apiV2/assets';
import CategoryChart from './CategoryChart';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    textAlign: 'left',
  },
  chart: {
    padding: theme.spacing(4, 2, 0, 2),
    height: 'auto',
  },
}));

// context for passing stuff to Observations createdTable
export const ObservationsContext = createContext(null);
const ObservationAnalytics = () => {
  const classes = useStyles();
  const [selectedTab, setTab] = React.useState('observations');
  const { results: assets } = useTopLevelAssets();

  const handleTabChange = (event, newValue) => {
    event.preventDefault();
    setTab(newValue);
  };
  const dispatch = useDispatch();
  const checklistsCategoriesQuery = { template__categories: 'observation' };
  const noDataMessage = <Typography align="center">No Data. Please clear your filters.</Typography>;

  const {
    loading,
    results /** , count,**/,
    checklistNext,
    assetFilter,
    checklistResults,
    checklistCount,
    checklistsQuery,
  } = useSelector(state => {
    const { data, error } = state.observations;
    const assetFilter = assetsFiltersEndpoint.selector(state).data;

    const {
      dataAll: checklistResults,
      loading,
      count: checklistCount,
      query: checklistsQuery,
      next: checklistNext,
    } = state.checklists.all;

    return {
      results: data && data.results ? state.observations.data.results : [],
      checklistResults,
      checklistNext,
      assetFilter,
      loading,
      error,
      checklistCount,
      checklistsQuery,
      count: data ? data.count : 0,
    };
  });

  const labels = results.map(d => d.name);

  const noData = !loading && assets && assetFilter && checklistResults.length === 0;
  // wait for all data to load before enabling filters especially checklists due to batch fetching
  const disableFilters = checklistResults && assetFilter && results && checklistNext;

  const tabs = [
    {
      value: 'observations',
      label: 'Good Catch Findings',
      Component: noData ? noDataMessage : <ObservationChart data={results} labels={labels} className={classes.chart} />,
      show: true,
    },
    {
      value: 'categories',
      label: 'Category Breakdown',
      Component: noData ? noDataMessage : <CategoryChart data={results} labels={labels} className={classes.chart} />,
      show: true,
    },
  ];

  const formatObservationsQuery = query => {
    const { limit, offset, ...rest } = query;
    return rest;
  };

  useEffect(() => {
    dispatch(getObservations(formatObservationsQuery(checklistsCategoriesQuery)));
    dispatch(getAllChecklists(checklistsCategoriesQuery));
    dispatch(assetsFiltersEndpoint.actionCreators.request());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formTableChangeHandler = query => {
    const updatedQuery = { ...checklistsCategoriesQuery, ...query };
    dispatch(getAllChecklists(updatedQuery));
    dispatch(getObservations(formatObservationsQuery(updatedQuery))); // handle table filter changes
  };

  const filterEntries = ['descendants', 'labels', 'created_on_after', 'created_on_before'];
  const filtersInfo = filterEntries.reduce((result, key) => {
    result[key] = {
      selected: [],
      options: key in assetFilter ? assetFilter[key] : [],
      dependencies: [],
      label: '',
      inFilter: false, // all observation filters at this time don't use __in but can be updated to true if new field is added
      type: '',
    };
    return result;
  }, {});
  filtersInfo['descendants'] = { ...filtersInfo.descendants, options: assets || [], label: 'Site' };
  filtersInfo['labels'] = { ...filtersInfo.labels, label: 'Asset Labels' };
  filtersInfo['created_on_after'] = { ...filtersInfo.created_on_after, label: 'Start Date', type: 'date' };
  filtersInfo['created_on_before'] = { ...filtersInfo.created_on_before, label: 'End Date', type: 'date' };

  const renderData = () => {
    if (loading && assets && assetFilter && checklistNext && checklistResults.length === 0) {
      return <Loading />;
    }

    return (
      <>
        <div>
          <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary">
            {tabs
              .filter(tab => tab.show)
              .map(tab => (
                <Tab
                  id={`analytics-${tab.value}`}
                  aria-controls={`analytics-tabpanel-${tab.value}`}
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                />
              ))}
          </Tabs>
          {tabs.map(tab => (
            <TabPanel key={tab.value} value={tab.value} selectedTab={selectedTab} name="observation-analytics">
              {tab.Component}
            </TabPanel>
          ))}
        </div>
        <ChecklistsTable
          title="Forms"
          tableChangeHandler={formTableChangeHandler}
          data={checklistResults}
          queryParamObj={checklistsQuery}
          embedded
        />
      </>
    );
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.root}>
        <h1>Observation Analytics</h1>
        <FilterBarAssets
          type="checklists"
          defaultQueryParam={{ template__categories: 'observation' }}
          onChangeFilters={queries => {
            dispatch(getAllChecklists(queries));
            dispatch(getObservations(formatObservationsQuery(queries)));
          }}
          totalCount={checklistCount}
          filters={assetFilter}
          filtersInfo={filtersInfo}
          disabled={loading || disableFilters} // if no next batch for assets and not loading, enable filter bar
        />
        {renderData()}
      </Paper>
    </div>
  );
};

export default ObservationAnalytics;
