import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PrimaryButton from '../buttons/PrimaryButton';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(3, 2),
    padding: theme.spacing(2),
    textAlign: 'left',
  },
  message: {},
  center: {
    textAlign: 'center',
  },
}));

const NoPreview = props => {
  const { url } = props;
  const classes = useStyles();

  const downloadUrl = function () {
    if (url) {
      window.location.assign(url, '_blank');
    }
  };

  return (
    <Paper className={`${classes.root} ${classes.center}`}>
      <h3 className={classes.message}>No Preview Available</h3>
      <Divider />
      <PrimaryButton label="Download" onClick={downloadUrl} />
    </Paper>
  );
};

NoPreview.defaultProps = {
  url: '',
};

NoPreview.propTypes = {
  url: PropTypes.string,
};

export default NoPreview;
