import { requestFactory, responseHandlerForm } from '../base';

const get = requestFactory('get');
const post = requestFactory('post', { handler: responseHandlerForm });
const patch = requestFactory('patch', { handler: responseHandlerForm });
const del = requestFactory('del');

export const fetchCmlsList = async query => get('/api/cmls/', query);
export const fetchCmls = async id => get(`/api/cmls/${id}/`);
export const createCmls = async body => post('/api/cmls/', body);
export const updateCmls = async (id, body) => patch(`/api/cmls/${id}/`, body);
export const deleteCmls = async id => del(`/api/cmls/${id}/`);
