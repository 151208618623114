import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import NotificationRulesTable from './NotificationRulesTable';
import { getAllNotificationRules } from '../../store/features/notificationRulesActions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const NotificationRulesTablePage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(getAllNotificationRules());
  }, []); // eslint-disable-line

  return (
    <div className={classes.root}>
      <NotificationRulesTable title="Notification Rules" />
    </div>
  );
};

export default NotificationRulesTablePage;
