import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MeasurementsTable from './MeasurementsTable';
import { getAllMeasurements } from '../../store/features/measurementsActions';
import { getParams } from '../../utilities/route';
import { toQueryString } from '../../utilities/strings';
import { useTableViewSettings } from '../../hooks/settingsHooks';
import { tableViews } from '../../utilities/tables';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(7, '3vw'),
    padding: theme.spacing(2),
  },
}));

const MeasurementsTablePage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { measurementsTableViewKey } = tableViews;
  const { tableQuery: measurementsQuery, page, views } = useTableViewSettings(measurementsTableViewKey);

  // check for page size settings
  const queryParams = getParams(location, measurementsQuery);

  useEffect(() => {
    dispatch(getAllMeasurements(queryParams));
  }, []); // eslint-disable-line

  const tableChangeHandler = query => {
    // no need to fetch data if the query is the same.
    const newSearch = toQueryString(query);
    if (location.search === newSearch) return;
    const newLocation = location;
    newLocation.search = newSearch;
    history.push(newLocation);

    dispatch(getAllMeasurements(query));
  };

  return (
    <Paper className={classes.root}>
      <MeasurementsTable
        title="Measurements"
        queryParamObj={queryParams}
        tableChangeHandler={tableChangeHandler}
        page={page}
        views={views}
      />
    </Paper>
  );
};

export default MeasurementsTablePage;
