import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { Autocomplete, Paper, Divider, Grid, TextField, CircularProgress } from '@mui/material';
import Pill from '../shared/Pill';
import PrimaryButton from '../shared/buttons/PrimaryButton';
import SecondaryButton from '../shared/buttons/SecondaryButton';
import {
  replaceCharWithSpace,
  toQueryString,
  queriesFromString,
  apiDateToString,
  capitalizeFirstChar,
} from '../../utilities/strings';
import './FilterBarAssets.scss';
import { filterToQueries, queriesToFilter, sortFn } from '../../utilities/analytics';
import FilterBarDateRangeFilter from './FilterBarDateRangeFilter';

// special format for any filter not standard autocomplete such as DateRange
export const formatSelected = (filter, filterType) => {
  try {
    if (filterType === 'asset_condition')
      return filter.map(item => {
        return 'description' in item ? { id: item.id, name: item.description } : item;
      });
    else {
      return [{ ...filter[0], name: filterType }];
    }
  } catch {
    console.error('Selected items filter not in proper format');
    return [];
  }
};

const FilterBarAssets = props => {
  const { totalCount, filters, filtersInfo, type, defaultQueryParam, onChangeFilters, disabled } = props;
  const { count, loading } = useSelector(state => {
    // type is either `state.assets` or `state.checklists` (as of 2023-08-17)
    // NOTE - this will not work after migrating to apiV2 and will need `switch` statement
    const data = state[type] || {};

    return {
      count: data.all.dataAll.count,
      loading: data.all.loading,
    };
  });

  const [disableApplyButton, updateDisableApplyButton] = useState(true);
  // forceUpdate not recommended, but is a workaround to trigger the rendering of the Pills upon update
  const [_, forceUpdate] = useReducer(x => x + 1, 0);
  const location = useLocation();
  const history = useHistory();
  const inFilters = filtersInfo
    ? Object.keys(filtersInfo).flatMap(key => {
        if (filtersInfo[key].inFilter) {
          return key;
        }
        return [];
      })
    : [];
  // if dependencies set, need to filter their lists accordingly
  // For example, when an owner is selected, the parents list needs to show parents with only the selected owner
  const setDependentFilteredState = (dependency, filterType, filterValue, filterState) => {
    if (filterValue && filterValue.length > 0) {
      const filteredOptions = filterState[dependency].options.filter(filter => {
        return filterValue.some(value => value.name === filter[filterType].name);
      });
      if (filteredOptions) {
        // check that the dependent field value that was already selected is valid.
        const currentSelected = filterState[dependency].selected;
        const newSelection = currentSelected.filter(item => currentSelected.indexOf(item) > -1) || [];
        return { options: filteredOptions, selected: newSelection };
      }
    } else {
      return { options: filters[dependency], selected: filterState[dependency].selected };
    }
  };

  // handles all of the state changes for any filter changes
  // returns a new filterState upon modification
  const filterReducer = (draft, action) => {
    switch (action.type) {
      case 'set':
        draft = action.payload;
        return draft;
      case 'update': {
        if (draft[action.payload.filterType] && draft[action.payload.filterType].dependencies.length > 0) {
          draft[action.payload.filterType].dependencies.forEach(item => {
            draft[item] = {
              ...draft[item],
              ...setDependentFilteredState(item, action.payload.filterType, action.payload.selected, draft),
            };
          });
        }
        // date type filters need different formatting
        draft[action.payload.filterType].selected =
          action.payload.filterForm === 'date' || action.payload.filterType === 'asset_condition'
            ? formatSelected(action.payload.selected, action.payload.filterType)
            : [...action.payload.selected];
        return draft;
      }
      case 'setFiltersFromParams':
        if (draft[action.payload.filterType] && draft[action.payload.filterType].dependencies.length > 0) {
          draft[action.payload.filterType].dependencies.forEach(item => {
            draft[item] = {
              ...draft[item],
              ...setDependentFilteredState(item, action.payload.filterType, action.payload.selected, draft),
            };
          });
        }
        draft[action.payload.filterType].selected = [
          ...draft[action.payload.filterType].selected,
          ...action.payload.selected,
        ];
        return draft;
      case 'clear': {
        Object.keys(draft).forEach(filterType => {
          draft[filterType].selected = [];
        });
        return draft;
      }
      default:
        return draft;
    }
  };

  const [filterState, filterUpdate] = useReducer(filterReducer, filtersInfo);
  useEffect(() => {
    // download data with the url query
    const query = location.search;
    const queryObject = { ...queriesFromString(query), ...defaultQueryParam }; //
    onChangeFilters(queryObject);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // When the component run useEffect filterState has not been populated, so this function uses filters
    // to determine what values to set in local state.
    filterUpdate({ type: 'set', payload: filtersInfo });
    // Set the filter state with the query values.
    const parsed = queriesFromString(queriesToFilter(location.search), Object.keys(filtersInfo));
    Object.keys(parsed).forEach(filter => {
      let filterValues = parsed[filter];
      // if there is only one value in a query, it comes in as a string instead of an array
      if (typeof parsed[filter] === 'string') {
        filterValues = [parsed[filter]];
      }
      if (filter === 'labels') {
        filterValues = filterValues.flatMap(item =>
          item ? { key: '', name: item.split('::')[0], value: item.split('::')[1] } : []
        );
      }
      filterValues.forEach(item => {
        let id = parseInt(item);
        if (isNaN(id)) {
          id = item;
        }
        // Date filters are not contained in filters state and have to be set separately
        let selected =
          filterState[filter]?.type === 'date'
            ? [{ name: filter, value: item }]
            : filtersInfo[filter].options.filter(option => {
                // does the current parsed filter value match with possible asset filters

                if (option.id === id) {
                  return true;
                }
                if (option?.value && (option.value === id || option.value === item.value)) {
                  return true;
                } // special case for 'asset_condition'
                return false;
              });
        if (selected === -1) return undefined; // return undefined so later filter can remove it
        if (filter === 'asset_condition') {
          // reshape data if asset_condition
          selected = selected.map(item => {
            return { id: item.value, name: item.description };
          });
        }
        if (selected && selected.length) {
          filterUpdate({ type: 'setFiltersFromParams', payload: { filterType: filter, selected: selected } });
        }
      });
    });

    updateDisableApplyButton(true);
  }, [filtersInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  // after applying or clearing filters update the query in the url
  const updateLocation = queryParams => {
    const newLocation = history.location;
    // The map data is also stored in the URL.  Make sure that persists.
    const queryObj = queriesFromString(queriesToFilter(newLocation.search));
    Object.keys(filterState).forEach(key => {
      if (queryParams[key]) {
        queryObj[key] = queryParams[key];
      } else if (queryObj[key]) {
        delete queryObj[key];
      }
    });
    newLocation.search = filterToQueries(toQueryString(queryObj), inFilters, true); //
    history.push(newLocation);
  };

  const handleFilterChange = (event, value, filterType, componentType = '') => {
    filterUpdate({ type: 'update', payload: { filterType: filterType, selected: value, filterForm: componentType } });
    updateDisableApplyButton(false);
    forceUpdate(_); // TODO figure out better way to do this
  };

  const clearFilters = () => {
    filterUpdate({ type: 'clear' });
    onChangeFilters(defaultQueryParam);
    updateLocation({});
    updateDisableApplyButton(true);
  };

  const applyFilters = () => {
    const queryParams = {};
    Object.keys(filterState).forEach(filterType => {
      if (filterState[filterType].selected) {
        if (filterType === 'labels') {
          queryParams[filterType] = filterState[filterType].selected.map(name => name.name + '::' + name.value);
        } else if (filterState[filterType].type === 'date') {
          queryParams[filterType] = filterState[filterType].selected.map(item => item.value);
        } else {
          queryParams[filterType] = filterState[filterType].selected.map(name => name.id);
        }
      }
    });
    const queryStringified = filterToQueries(toQueryString({ ...queryParams, ...defaultQueryParam }), inFilters);
    onChangeFilters(queryStringified);
    if (queryStringified) {
      updateLocation(queryParams);
    }
    updateDisableApplyButton(true);
  };

  const removeFilter = (type, filter, list) => () => {
    const tempList = [...list];
    const index = tempList.findIndex(item => item.name === filter);
    tempList.splice(index, 1);
    filterUpdate({ type: 'update', payload: { filterType: type, selected: tempList } });
    updateDisableApplyButton(false);
    forceUpdate(_); // TODO figure out better way to do this
  };

  const formatLabel = (filter, label = '') => {
    if (label && label !== '') {
      return label;
    }
    return capitalizeFirstChar(replaceCharWithSpace('_')(filter));
  };

  const formatPillLabel = filter => {
    let formattedPillLabel = '';
    if (filter.value) {
      const key = filter.name;
      formattedPillLabel =
        formatLabel(filter.name, filterState[key]?.label) +
        ' : ' +
        (filterState[key]?.type === 'date' ? apiDateToString(filter.value, 'date') : filter.value);
    } else {
      formattedPillLabel = filter.name;
    }
    return formattedPillLabel;
  };

  // don't render pill if value is invalid
  const validFilter = value => {
    return typeof value === 'string' ? !value.includes('Invalid') : true;
  };

  const renderPills = () => {
    const Pills = [];
    // key is the filter type.  value holds the selected values and the update function for that type.
    Object.entries(filterState).forEach(([key, value = {}]) => {
      if (value.selected && value.selected.length > 0) {
        Pills.push(
          value.selected.map(
            (filter, index) =>
              validFilter(filter?.value) && (
                <Pill
                  key={`pill-${filter.name}-${index}`}
                  label={formatPillLabel(filter)}
                  remove={removeFilter(key, filter.name, value.selected)}
                  disabled={disabled}
                />
              )
          )
        );
      }
    });
    return Pills;
  };

  const getOptionLabel = (option, filterType) =>
    filterType === 'labels' ? option.name + ' : ' + option.value : option.name;

  const getFilterOptions = (filterType, options = []) =>
    filterType === 'asset_condition'
      ? options.map(filter => ({ ...filter, id: filter.value, name: filter.description })).sort(sortFn)
      : options.sort(sortFn);

  // compare function for filterSelectedOptions
  const getSelectedOptions = (filterType, a, b) =>
    filterType === 'labels'
      ? a.name + ' : ' + a.value === b.name + ' : ' + b.value
      : a.name === b.name && a.id === b.id;

  const renderFilters = () => {
    const Filters = [];
    Object.keys(filterState).forEach(filterType => {
      const label = formatLabel(filterType, filterState[filterType].label).toUpperCase();
      Filters.push(
        <Grid item key={`grid-item-${filterType}`} style={{ width: 200 }}>
          {filterState[filterType].type === 'date' ? (
            <FilterBarDateRangeFilter
              title={label}
              filter={filterState[filterType].selected}
              onChange={(event, value) => handleFilterChange(event, value, filterType, 'date')}
              disabled={disabled}
              resetValue={filterState[filterType].selected && !filterState[filterType].selected.length}
            />
          ) : (
            <Autocomplete
              multiple
              disableClearable
              disableCloseOnSelect
              disabled={disabled}
              filterSelectedOptions
              noOptionsText="No Options"
              options={getFilterOptions(filterType, filterState[filterType].options)}
              getOptionLabel={option => getOptionLabel(option, filterType)}
              isOptionEqualToValue={(a, b) => getSelectedOptions(filterType, a, b)}
              value={filterState[filterType].selected}
              onChange={(event, value) => handleFilterChange(event, value, filterType)}
              renderInput={params => {
                return <TextField {...params} label={label.toUpperCase()} />;
              }}
              renderTags={() => null}
            />
          )}
        </Grid>
      );
    });
    return Filters;
  };

  return (
    <Paper className="filter-container">
      <Grid container spacing={0} justifyContent="space-between" alignItems="center" wrap="nowrap" className="pill-row">
        <Grid item>{renderPills()}</Grid>
        <Grid item className="count">
          {count != null ? count : <CircularProgress size={14} />} of {totalCount}
        </Grid>
      </Grid>
      <Divider />
      <Grid container wrap="nowrap" className="filter-row">
        <Grid item className="filter-group">
          <Grid container spacing={4}>
            {renderFilters()}
          </Grid>
        </Grid>
        <Grid item key="grid-item-buttons" className="button-group">
          <Grid container spacing={1} wrap="nowrap">
            <Grid item>
              <PrimaryButton spin icon onClick={applyFilters} loading={loading} disabled={disableApplyButton} />
            </Grid>
            <Grid item>
              <SecondaryButton icon onClick={clearFilters} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

FilterBarAssets.defaultProps = {
  type: 'assets',
  defaultQueryParam: {},
  disabled: false,
  totalCount: 0,
};

FilterBarAssets.propTypes = {
  type: PropTypes.string,
  defaultQueryParam: PropTypes.object,
  onChangeFilters: PropTypes.func.isRequired,
  totalCount: PropTypes.number,
  filters: PropTypes.object.isRequired,
  filtersInfo: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default FilterBarAssets;
