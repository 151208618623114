import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { getRiskColor } from './assessmentsShared';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 3, 4, 3),
  },
  cell: {
    height: '75px',
    width: '75px',
    textAlign: 'center',
    fontSize: 26,
    fontWeight: 'bold',
    color: 'black',
    border: '1px solid black',
  },
  tableContainer: {
    borderSpacing: 0,
  },
  pofHeader: {
    writingMode: 'vertical-lr',
    transform: 'scale(-1, -1)',
    fontSize: 16,
  },
  cofHeader: {
    fontSize: 16,
    marginLeft: '140px',
  },
}));

const AssessmentsRiskRollupMatrix = props => {
  const classes = useStyles();

  const { matrix } = props;

  if (!matrix.length) {
    return <></>; // Assume handle this case in parent component
  }

  return (
    <div className={classes.root}>
      <table data-testid="risk-matrix-table" className={classes.tableContainer}>
        {matrix.map((row, i) => (
          <tr key={i}>
            {/* POF Label w/ Numbers Across Left of Table */}
            {i === 0 && (
              <th rowSpan={matrix.length + 1}>
                <span className={classes.pofHeader}>Probability of Failure</span>
              </th>
            )}
            <th style={{ padding: 10 }}>{row[0].pof}</th>

            {/* Risk Matrix Cells for Row */}
            {row.map(({ color, count, cof, pof }) => {
              return (
                <td
                  data-testid={`${pof}-${cof}-cell`}
                  key={cof}
                  className={classes.cell}
                  style={{
                    background: getRiskColor(color),
                  }}>
                  {count || null}
                </td>
              );
            })}
          </tr>
        ))}

        {/* COF Label w/ Numbers Across Bottom of Table */}
        <tr>
          <th></th>
          {matrix[0].map(({ cof }) => {
            return (
              <th key={cof} style={{ padding: 10, textAlign: 'center' }}>
                {cof}
              </th>
            );
          })}
        </tr>
        <tr>
          <th colSpan={matrix[0].length + 1}>
            <span className={classes.cofHeader}>Consequence of Failure</span>
          </th>
        </tr>
      </table>
    </div>
  );
};

AssessmentsRiskRollupMatrix.defaultProps = {
  matrix: [],
};

AssessmentsRiskRollupMatrix.propTypes = {
  matrix: PropTypes.array,
};

export default AssessmentsRiskRollupMatrix;
