import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ButtonGroup } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Description from '../shared/table/Description';
import Table, { SELECTABLE_ROWS_NONE } from '../shared/table/Table';
import { getAllLibraryMedia, deleteLibraryMedia } from '../../store/features/libraryMediaActions';
import {
  setLibraryMediaTableView,
  setLibraryMediaTableSize,
} from '../../store/settings/views/libraryMediaTableViewRedux';
import ButtonIcon from '../shared/buttons/ButtonIcon';
import StyledLink from '../shared/StyledLink';
import PrettyBytes from '../shared/displays/PrettyBytes';
import { openDialog } from '../../store/dialogActions';
import DeleteDialog from '../shared/Dialog/DeleteDialog';
import { setColumns } from '../shared/table/columns';
import LibraryFileUploader from './LibraryFileUploader';
import CustomToolbarFileUploader from '../shared/table/CustomToolbarFileUploader';
import { usePermissions, useTableViews } from '../../hooks/settingsHooks';
import { noThumbnail } from '../../utilities/files';
import SlideshowViewer from '../shared/slideshowViewer/SlideshowViewer';
import { tableViews } from '../../utilities/tables';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const useStyles = makeStyles(theme => ({
  formControl: {
    marginBottom: theme.spacing(2),
  },
  uploader: {
    padding: theme.spacing(0, 3),
    textAlign: 'left',
  },
  thumbnailImage: {
    width: theme.spacing(10),
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const LibraryMediaTable = props => {
  const { libraryId, libraryName, filename, embedded } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [deleteCalled, setDeleteCalled] = useState(false);
  const [showFileUploader, setShowFileUploader] = useState(false);
  const { loading, data, count } = useSelector(state => ({
    loading: state.libraryMedia.all.loading,
    data: state.libraryMedia.all.dataAll.results,
    count: state.libraryMedia.all.dataAll.count,
  }));
  const { libraryMediaTableViewKey } = tableViews;
  const views = useTableViews(libraryMediaTableViewKey);
  const { hasLibraryMediaCreate } = usePermissions();
  const [showSlides, setShowSlides] = useState(false);
  const [startingId, setStartingId] = useState(null);
  const libraryMediaQuery = { library: libraryId };

  useEffect(() => {
    dispatch(getAllLibraryMedia(libraryMediaQuery));
  }, [libraryId]); // eslint-disable-line
  useEffect(() => {
    if (deleteCalled) {
      dispatch(getAllLibraryMedia(libraryMediaQuery));
      setDeleteCalled(false);
    }
  }, [deleteCalled, dispatch, libraryMediaQuery.library]); // eslint-disable-line react-hooks/exhaustive-deps

  const classes = useStyles();
  const handleThumbnailClick = id => {
    setShowSlides(true);
    setStartingId(id);
  };

  /** @type {Column[]} */
  const columns = [
    {
      // This needs to be first and excluded so customBodyRender can access the id to create the link.
      name: 'id',
      label: 'Id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        filterType: 'textField',
        customBodyRender: (value, tableMeta) => {
          return <StyledLink to={`/library-media/${tableMeta.rowData[columns.findIndexByName['id']]}`} value={value} />;
        },
      },
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        filter: true,
        sort: true,
        filterType: 'textField',
        customBodyRender: value => {
          if (!value) return <></>;
          return <Description value={value} />;
        },
      },
    },
    {
      name: 'document_category',
      label: 'Category',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'size',
      label: 'Size',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => {
          return <PrettyBytes num={value || 0} />;
        },
      },
    },
    {
      name: 'thumbnail',
      label: 'Thumbnail',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const src = noThumbnail(tableMeta.rowData[columns.findIndexByName['document_category']]);
          return value ? (
            <img
              src={value}
              className={classes.thumbnailImage}
              onClick={() => {
                handleThumbnailClick(tableMeta.rowData[columns.findIndexByName['id']]);
              }}
            />
          ) : (
            <img
              src={src}
              className={classes.thumbnailImage}
              onClick={() => {
                handleThumbnailClick(tableMeta.rowData[columns.findIndexByName['id']]);
              }}
            />
          );
        },
      },
    },
    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        print: false,
        viewColumns: false,
        customBodyRender: (_, info) => {
          // 0 is the first element in column
          const id = info.rowData[columns.findIndexByName['id']];
          const name = info.rowData[columns.findIndexByName['name']];
          return (
            <>
              <ButtonGroup>
                <ButtonIcon
                  history={history}
                  icon={EditIcon}
                  location={location}
                  to={`/library-media/${id}/edit${location.search}`}
                />
                <ButtonIcon
                  icon={DeleteIcon}
                  onClick={() => {
                    dispatch(
                      openDialog(
                        'Delete Library Media?',
                        <DeleteDialog id={id} deleteAction={deleteLibraryMedia} name={name} query={libraryMediaQuery} />
                      )
                    );
                    setDeleteCalled(true);
                  }}
                />
              </ButtonGroup>
            </>
          );
        },
      },
    },
  ];

  // handle columns display
  setColumns(columns, views);

  /** @type {TableOptions} */
  const options = {
    // if the column view changes, update redux with either 'add' or 'remove'
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setLibraryMediaTableView(changedColumn, action));
    },
    onChangeRowsPerPage: numberOfRows => {
      dispatch(setLibraryMediaTableSize(numberOfRows));
    },
    customToolbar: () => {
      if (hasLibraryMediaCreate) {
        return (
          <CustomToolbarFileUploader showFileUploader={showFileUploader} setShowFileUploader={setShowFileUploader} />
        );
      }
    },
    rowsPerPage: views.pageSize,
    enableNestedDataAccess: '.',
    selectableRows: SELECTABLE_ROWS_NONE,
  };

  return (
    <>
      <div className={classes.root}>
        {showFileUploader && (
          <div>
            <LibraryFileUploader libraryId={libraryId} libraryName={libraryName} />
          </div>
        )}
        <Table
          title={libraryName}
          columns={columns}
          data={data}
          options={options}
          loading={loading}
          embedded={embedded}
          count={count}
          filename={filename}
        />
      </div>
      {showSlides && (
        <SlideshowViewer
          mediaArray={data}
          showSlides={showSlides}
          setShowSlides={setShowSlides}
          startingId={startingId}
        />
      )}
    </>
  );
};

LibraryMediaTable.defaultProps = {
  libraryName: '',
};

LibraryMediaTable.propTypes = {
  libraryId: PropTypes.number.isRequired,
  libraryName: PropTypes.string,
  filename: PropTypes.string,
  embedded: PropTypes.bool,
};

export default LibraryMediaTable;
