import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import DataCell from './DataCell';
import StyledLink from '../../shared/StyledLink';
import Table, { SELECTABLE_ROWS_NONE } from '../../shared/table/Table';
import ViewDisplayKeyValue from '../../shared/form/ViewDisplayKeyValue';
import { formatProp, calculateSum } from './usageShared';
import { getColumnNames, getCustomCSVData } from '../../../utilities/tables';

/** @typedef {import('../../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../../shared/table/types').Column} Column */

const useStyles = makeStyles(theme => ({
  sum: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  totalCell: {
    whiteSpace: 'nowrap',
    position: 'sticky',
    left: '0',
    zIndex: 100,
    fontSize: 16,
    padding: 25,
  },
}));

const UsageTable = ({ usageData: data, loading, typeNames, type }) => {
  const classes = useStyles();

  const usageType = type === 'Asset' ? 'asset_type_usage' : 'project_type_usage';
  const typeProp = type === 'Asset' ? 'asset__type__name' : 'type__name';

  const generateColumnData = arr => {
    return arr.map(type => ({
      name: formatProp(type),
      label: type,
      options: {
        customBodyRender: value => {
          return <DataCell value={value} />;
        },
      },
    }));
  };

  const formatData = useMemo(() => {
    const formatted = data.map(companyObj => {
      const { id, name, labels, [usageType]: usageArray } = companyObj;

      const calculateTypes = calculateSum(usageArray);

      const generateTypeCounts = () =>
        typeNames.reduce((acc, name) => {
          return { ...acc, [formatProp(name)]: calculateTypes(typeProp, name) };
        }, {});

      return {
        id,
        name,
        labels,
        total: calculateTypes(),
        published: calculateTypes('status', 'PUBLISHED'),
        ...generateTypeCounts(),
      };
    });

    return formatted;
  }, [data, typeNames, typeProp, usageType]);

  /** @type {Column[]} */
  const columns = [
    {
      // This needs to be first and excluded so customBodyRender can access the id to create the link.
      name: 'id',
      label: 'Id',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        setCellProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: '0',
            zIndex: 100,
          },
        }),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return <StyledLink to={`/assets/${tableMeta.rowData[columns.findIndexByName['id']]}`} value={value} />;
        },
      },
    },
    {
      name: 'labels',
      label: 'Labels',
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => <ViewDisplayKeyValue value={value} />,
        downloadBody: values => getCustomCSVData('labels', values),
      },
    },
    {
      name: 'total',
      label: 'Total',
      options: {
        customBodyRender: value => <DataCell value={value} />,
      },
    },
    {
      name: 'published',
      label: 'Published',
      options: {
        customBodyRender: value => <DataCell value={value} />,
      },
    },
    ...generateColumnData(typeNames),
  ];
  // set columns.findIndexByName here since we don't use setColumns
  columns.findIndexByName = getColumnNames(columns);

  /** @type {TableOptions} */
  const options = {
    rowsPerPage: 50,
    selectableRows: SELECTABLE_ROWS_NONE,
    customTableBodyFooterRender: opts => {
      const hasTotal = column => column.name !== 'name' && column.name !== 'labels';
      const renderSum = index =>
        opts.data.reduce((acc, { data }) => {
          if (!data[index].props) {
            return acc + data[index];
          }
          // value is in props object in custom body renders
          return acc + data[index].props.value;
        }, 0);

      return (
        <TableFooter>
          <TableRow>
            <TableCell className={classes.totalCell} colSpan={2}>
              Totals
            </TableCell>
            {opts.columns.map((column, i) => {
              if (column.display === 'true' && hasTotal(column)) {
                return (
                  <TableCell key={i} className={classes.sum}>
                    {renderSum(i)}
                  </TableCell>
                );
              } else {
                return null;
              }
            })}
          </TableRow>
        </TableFooter>
      );
    },
  };

  return (
    <div style={{ marginTop: 25 }}>
      <Table
        title={`Projects by ${type} Type`}
        data={formatData}
        columns={columns}
        loading={loading}
        options={options}
      />
    </div>
  );
};
UsageTable.propTypes = {
  usageData: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  typeNames: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
};

export default UsageTable;
