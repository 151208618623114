// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import { combineReducers } from 'redux';
import {
  COMPANIES_REQUEST,
  COMPANIES_SUCCESS,
  COMPANIES_FAILURE,
  COMPANIES_CREATE_REQUEST,
  COMPANIES_CREATE_SUCCESS,
  COMPANIES_CREATE_FAILURE,
  COMPANIES_UPDATE_REQUEST,
  COMPANIES_UPDATE_SUCCESS,
  COMPANIES_UPDATE_FAILURE,
  COMPANIES_DELETE_REQUEST,
  COMPANIES_DELETE_SUCCESS,
  COMPANIES_DELETE_FAILURE,
  COMPANIES_ALL_REQUEST,
  COMPANIES_ALL_SUCCESS,
  COMPANIES_ALL_FAILURE,
} from './companiesActions';

export const initialStateCompanies = {
  loading: false,
  deleting: false,
  error: '',
  formError: {},
  id: 0,
  body: {},
  data: {},
};

export const initialStateAllCompanies = {
  loading: false,
  deleting: false,
  error: '',
  query: {},
  dataAll: { count: 0, next: null, previous: null, results: [] },
};

export const companiesReducer = (state = initialStateCompanies, action) => {
  switch (action.type) {
    case COMPANIES_REQUEST:
      return {
        ...initialStateCompanies,
        loading: true,
        id: action.id,
      };
    case COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case COMPANIES_FAILURE:
      return {
        ...initialStateCompanies,
        error: action.error,
      };
    case COMPANIES_CREATE_REQUEST:
      return {
        ...initialStateCompanies,
        loading: true,
        body: action.body,
      };
    case COMPANIES_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case COMPANIES_CREATE_FAILURE:
      return {
        ...initialStateCompanies,
        error: action.error,
        formError: action.formError,
      };
    case COMPANIES_UPDATE_REQUEST:
      return {
        ...state,
        error: '',
        formError: {},
        loading: true,
        id: action.id,
        body: action.body,
      };
    case COMPANIES_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case COMPANIES_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case COMPANIES_DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
        id: action.id,
      };
    case COMPANIES_DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
      };
    case COMPANIES_DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        error: action.error,
        formError: action.formError,
      };
    default:
      return state;
  }
};

export const companiesAllReducer = (state = initialStateAllCompanies, action) => {
  switch (action.type) {
    case COMPANIES_ALL_REQUEST:
      return {
        ...initialStateAllCompanies,
        loading: true,
        query: action.query,
      };
    case COMPANIES_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        dataAll: action.data,
      };
    case COMPANIES_ALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default combineReducers({
  each: companiesReducer,
  all: companiesAllReducer,
});
