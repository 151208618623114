import { all, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  BULK_IMPORTS_REQUEST,
  BULK_IMPORTS_SUCCESS,
  BULK_IMPORTS_FAILURE,
  BULK_IMPORTS_CREATE_REQUEST,
  BULK_IMPORTS_CREATE_SUCCESS,
  BULK_IMPORTS_CREATE_FAILURE,
  BULK_IMPORTS_UPDATE_REQUEST,
  BULK_IMPORTS_UPDATE_SUCCESS,
  BULK_IMPORTS_UPDATE_FAILURE,
  BULK_IMPORTS_DELETE_REQUEST,
  BULK_IMPORTS_DELETE_SUCCESS,
  BULK_IMPORTS_DELETE_FAILURE,
  BULK_IMPORTS_ALL_REQUEST,
  BULK_IMPORTS_ALL_SUCCESS,
  BULK_IMPORTS_ALL_FAILURE,
} from './bulkImportsActions';

import {
  fetchBulkImports,
  createBulkImports,
  updateBulkImports,
  deleteBulkImports,
  fetchBulkImportsList,
} from '../../api/features/bulkImports';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';
import { buildRoute } from '../../utilities/strings';
import { fetchListAll } from '../../utilities/sagaHelpers';
import { DIALOG_CLOSE } from '../../store/dialogActions';

export function* bulkImportsFetch(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield fetchBulkImports(id);
    if (data) {
      yield put({ type: BULK_IMPORTS_SUCCESS, data });
    } else if (error) {
      yield put({ type: BULK_IMPORTS_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, BULK_IMPORTS_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, BULK_IMPORTS_FAILURE);
  }
}

export function* watchBulkImportsFetch() {
  yield takeLatest(BULK_IMPORTS_REQUEST, bulkImportsFetch);
}

export function* bulkImportsCreate(action) {
  const { body } = action;
  try {
    const { data, error, formError, response } = yield createBulkImports(body);
    if (data) {
      yield put({ type: BULK_IMPORTS_CREATE_SUCCESS, data });
      yield put(push(`/bulk-imports/${data.id}`));
    } else if (error) {
      yield put({ type: BULK_IMPORTS_CREATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: BULK_IMPORTS_CREATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, BULK_IMPORTS_CREATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, BULK_IMPORTS_CREATE_FAILURE);
  }
}

export function* watchBulkImportsCreate() {
  yield takeLatest(BULK_IMPORTS_CREATE_REQUEST, bulkImportsCreate);
}

export function* bulkImportsUpdate(action) {
  const { id, body } = action;
  try {
    const { data, error, formError, response } = yield updateBulkImports(id, body);
    if (data) {
      yield put({ type: BULK_IMPORTS_UPDATE_SUCCESS, data });
      yield put(push(buildRoute('/bulk-imports')));
    } else if (error) {
      yield put({ type: BULK_IMPORTS_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: BULK_IMPORTS_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, BULK_IMPORTS_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, BULK_IMPORTS_UPDATE_FAILURE);
  }
}

export function* watchBulkImportsUpdate() {
  yield takeLatest(BULK_IMPORTS_UPDATE_REQUEST, bulkImportsUpdate);
}

export function* bulkImportsDelete(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield deleteBulkImports(id);
    if (data) {
      yield put({ type: BULK_IMPORTS_DELETE_SUCCESS });
      yield put({ type: BULK_IMPORTS_ALL_REQUEST });
      yield put({ type: DIALOG_CLOSE });
      yield put(push(buildRoute('/bulk-imports')));
    } else if (error) {
      yield put({ type: BULK_IMPORTS_DELETE_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, BULK_IMPORTS_DELETE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, BULK_IMPORTS_DELETE_FAILURE);
  }
}

export function* watchBulkImportsDelete() {
  yield takeLatest(BULK_IMPORTS_DELETE_REQUEST, bulkImportsDelete);
}

export function* bulkImportsFetchAll(action) {
  try {
    const { data, error, response } = yield fetchListAll(fetchBulkImportsList, action.query);
    if (data) {
      yield put({ type: BULK_IMPORTS_ALL_SUCCESS, data });
    } else if (error) {
      yield put({ type: BULK_IMPORTS_ALL_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, BULK_IMPORTS_ALL_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, BULK_IMPORTS_ALL_FAILURE);
  }
}

export function* watchBulkImportsFetchAll() {
  yield takeLatest(BULK_IMPORTS_ALL_REQUEST, bulkImportsFetchAll);
}

export default function* bulkImportsSaga() {
  yield all([
    watchBulkImportsFetch(),
    watchBulkImportsCreate(),
    watchBulkImportsUpdate(),
    watchBulkImportsFetchAll(),
    watchBulkImportsDelete(),
  ]);
}
