export const inspectionEnabled = (project, media) => {
  try {
    if (media) {
      return project.type.defect_profile && project.type.layer_profile && media.document_category === 'image';
    }
    return project.type.defect_profile && project.type.layer_profile;
  } catch (e) {
    return false;
  }
};

export const getProjectIsReadOnly = (status, hasChecklistEdit) => {
  if (status === 'PUBLISHED') {
    return true;
  }
  return hasChecklistEdit === undefined; // no permission is isReadOnly
};

export const makeProjectTypesMap = projectTypes => {
  if (projectTypes) {
    const projectTypesMap = projectTypes.reduce(
      (accumulator, project) => ({ ...accumulator, [project.id]: project.name }),
      {}
    );
    return projectTypesMap;
  }
};

export const makeProjectTypesOptions = projectTypesMap => {
  if (projectTypesMap) {
    const projectTypesOptions = Object.keys(projectTypesMap);
    return projectTypesOptions;
  }
};
