import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import { Paper, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LockIcon from '@mui/icons-material/Lock';
import SecondaryButton from '../buttons/SecondaryButton';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(3, 2),
    padding: theme.spacing(2),
    textAlign: 'left',
  },
  message: {
    color: 'red',
  },
  center: {
    textAlign: 'center',
  },
}));

const Denied = props => {
  const { backLink, message } = props;
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation;

  const handleBackClick = function () {
    if (backLink) {
      history.push(backLink);
    } else if (location.state && location.state.back) {
      // ideally, everythings that routes to this path, should have state.back in it's location.
      history.push(location.state.back);
    } else {
      // this is a last resort for if we don't know where the user came from
      // remove '/new' or '/<id>' from the end of the url and push that path.
      const next = window.location.pathname.match(/(.*?)\/\w+$/)[1];
      history.push(next);
    }
  };

  return (
    <Paper className={`${classes.root} ${classes.center}`}>
      <LockIcon fontSize="large" />
      <h2 className={classes.message}>{message}</h2>
      <Divider />
      <p>
        You don&apos;t have permission to view this link, or the link may not be available. Please contact the owner and
        ask to invite you to this link or switch accounts
      </p>
      <SecondaryButton label="Go Back" onClick={handleBackClick} />
    </Paper>
  );
};

Denied.defaultProps = {
  backLink: '',
  message: '',
};

Denied.propTypes = {
  backLink: PropTypes.string,
  message: PropTypes.string,
};

export default Denied;
