import React from 'react';
import PropTypes from 'prop-types';
import { Canvas, useLoader } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import Loader from './Loader';

function Scene({ file }) {
  const obj = useLoader(OBJLoader, file);
  return (
    <>
      <primitive object={obj} />
    </>
  );
}

Scene.propTypes = {
  file: PropTypes.string.isRequired,
};

function ObjViewer({ file }) {
  return (
    <Canvas>
      <React.Suspense fallback={<Loader />}>
        <Scene file={file} />
        <OrbitControls />
      </React.Suspense>
      <ambientLight />
    </Canvas>
  );
}

ObjViewer.propTypes = {
  file: PropTypes.string.isRequired,
};

export default ObjViewer;
