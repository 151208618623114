// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import React from 'react';
import CommonRoutes from './utilities/CommonRoutes';
import LibrariesForm from '../../components/libraries/LibrariesForm';
import LibrariesDetail from '../../components/libraries/LibrariesDetail';
import LibrariesTable from '../../components/libraries/LibrariesTable';
import MainLayout from '../layouts/MainLayout';

const LibrariesRoutes = () => {
  return (
    <CommonRoutes
      pageTitle="Library"
      layout={MainLayout}
      Edit={<LibrariesForm update />}
      Create={<LibrariesForm />}
      View={<LibrariesDetail />}
      All={<LibrariesTable />}
    />
  );
};

export default LibrariesRoutes;
