import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { jsonKeyToLabel, queriesFromString } from '../../utilities/strings';
import { getUsers, createUsers, updateUsers } from '../../store/features/usersActions';
import { getUserProfile } from '../../store/getters/profileActions';
import { getAllCompanies } from '../../store/features/companiesActions';
import CommonForm from '../shared/form/Common';
import { usePermissions, useFeatureFlags } from '../../hooks/settingsHooks';
import { fieldOrder, removeField, hideField, roleOptions } from './usersShared';
import { useCompanies } from '../../hooks/optionsHooks';
import { COMPANY_ROLES, ROLES } from '../../api/features/constants';

const UsersForm = props => {
  const { update } = props;
  const params = useParams();
  const location = useLocation();
  const { hasUserManageAll, hasUserRolesAll } = usePermissions();
  const { hasAssetAssessments, hasProcessData, hasCompanyManagerRoles, hasUsersAssignCompany } = useFeatureFlags();
  const dispatch = useDispatch();

  const [company, setCompany] = useState({ role: '' });
  const [dirty, setDirty] = useState();
  const [submittedValues, setSubmittedValues] = useState({});
  const companyOptions = useCompanies(true);

  const { data, loading, error, formError, editorCompany, authUserIsSubcontractor } = useSelector(state => {
    const { data, loading, error, formError } = state.users.each;
    const {
      data: { roles: editorRoles, company: editorCompany },
    } = state.profile;

    const authUserIsSubcontractor = editorRoles.includes(ROLES.subcontractor) && !hasUserRolesAll;

    return {
      data,
      loading: loading || state.companies.all.loading || state.profile.loading,
      error,
      formError,
      editorCompany,
      authUserIsSubcontractor,
    };
  });

  useEffect(() => {
    if (update && params.id) {
      dispatch(getUsers(params.id));
    }
    dispatch(getUserProfile());
    if (location.search) {
      const queries = queriesFromString(location.search);
      if (queries.company) {
        setCompany({ value: queries.company });
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!authUserIsSubcontractor) {
      dispatch(getAllCompanies());
    }
  }, [dispatch, authUserIsSubcontractor]);

  const isSubcontractor =
    company.role === COMPANY_ROLES.SUBCONTRACTOR || (update && data.roles?.includes(ROLES.subcontractor));
  const roleOpts = roleOptions(isSubcontractor, {
    hasUserRolesAll,
    hasAssetAssessments,
    hasProcessData,
    hasCompanyManagerRoles,
  });

  // use company role to set role options once data is loaded
  useEffect(() => {
    if (update && data.company) {
      if (data.company.length) {
        setCompany({ role: data.company[0].role });
      }
    }
  }, [data, update]);

  const updateCompany = e => {
    setDirty(true);
    const foundCo = companyOptions.find(co => co.value === e.target.value);
    if (foundCo) {
      setCompany(foundCo);
    }
  };

  const formSettings = {
    fieldOrder,
    removeField: removeField(update ? 'UPDATE' : 'CREATE', hasUserManageAll),
    hideField: (values, item) => hideField(values, item, { update, hasUsersAssignCompany }),
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      switch (key) {
        /* ---------- Required Fields ---------- */

        case 'email':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              required: true,
            },
          };
          break;
        /* ---------- Regular Fields ---------- */
        case 'roles':
          if (!authUserIsSubcontractor) {
            fieldSettings[key] = {
              type: 'multi',
              fieldProps: {
                label: jsonKeyToLabel(key),
                name: key,
                required: true,
                options: roleOpts,
                labelwidth: 80,
              },
            };
          }
          break;
        case 'company':
          if (!authUserIsSubcontractor) {
            fieldSettings[key] = {
              type: 'select',
              fieldProps: {
                label: jsonKeyToLabel(key),
                name: key,
                required: false,
                options: companyOptions,
                labelwidth: 60,
                onChange: updateCompany,
              },
            };
          }
          break;
        case 'is_active':
          fieldSettings[key] = {
            type: 'checkbox',
            cellProps: {
              xs: 12,
            },
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          break;
        /* ----------  Disabled Fields ---------- */
        /* ----------  Default ---------- */
        default:
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
      }
    });
  }

  const title = update ? 'Edit User' : 'New User';

  let queries = {};
  if (location.search) {
    queries = queriesFromString(location.search);
  }

  const getCompany = () => {
    if (queries?.company) {
      return queries.company;
    } else if (update && Array.isArray(data?.company) && data.company.length > 0) {
      return data.company[0]?.id;
    } else return '';
  };

  let initialValues = { roles: [] };
  if (update) {
    initialValues = {
      ...data,
      roles: data.roles || [],
    };
  }
  initialValues.company = getCompany();

  const onSubmit = changedValues => {
    const updatedValues = { ...changedValues };
    setSubmittedValues(updatedValues);
    if (changedValues.email) {
      updatedValues.username = changedValues.email;
    }
    if (changedValues.company) {
      updatedValues.company_id = parseInt(updatedValues.company);
      delete updatedValues.company;
    }
    if (update) {
      dispatch(updateUsers(updatedValues.id, updatedValues));
    } else {
      if (authUserIsSubcontractor) {
        dispatch(createUsers({ ...updatedValues, company_id: editorCompany[0].id, roles: [ROLES.subcontractor] }));
      } else {
        dispatch(createUsers(updatedValues));
      }
    }
  };

  /**
   * Decorators are used for setting the values of other fields based off of a field.
   * Decorator format can be found here: src/components/shared/form/common.js
   */
  const decorators = [];

  /**
   *  Validations that are per field.
   *  Errors associated with the form are passed back through formError in Redux.
   */
  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        if (key === 'username') {
          if (submittedValues.username === values.email) {
            errors['email'] = value[0].replace(/username/g, 'email');
          } else {
            errors['email'] = undefined;
          }
        }
        if (values[key] === submittedValues[key]) {
          errors[key] = value;
        } else {
          errors[key] = undefined;
        }
      }
    }
    return errors;
  };

  return (
    <CommonForm
      update={update}
      title={title}
      initialValues={initialValues}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      onSubmit={onSubmit}
      decorators={decorators}
      validate={validate}
      loading={loading}
      error={error}
      dirty={dirty}
      keepDirtyOnReinitialize
    />
  );
};

UsersForm.defaultProps = {
  update: false,
};

UsersForm.propTypes = {
  update: PropTypes.bool,
};

export default UsersForm;
