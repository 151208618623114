export const RECOMMENDED_WORK_PLAN_REQUEST = 'RECOMMENDED_WORK_PLAN_REQUEST';
export const RECOMMENDED_WORK_PLAN_SUCCESS = 'RECOMMENDED_WORK_PLAN_SUCCESS';
export const RECOMMENDED_WORK_PLAN_FAILURE = 'RECOMMENDED_WORK_PLAN_FAILURE';
export const RECOMMENDED_WORK_PLAN_CREATE_REQUEST = 'RECOMMENDED_WORK_PLAN_CREATE_REQUEST';
export const RECOMMENDED_WORK_PLAN_CREATE_SUCCESS = 'RECOMMENDED_WORK_PLAN_CREATE_SUCCESS';
export const RECOMMENDED_WORK_PLAN_CREATE_FAILURE = 'RECOMMENDED_WORK_PLAN_CREATE_FAILURE';
export const RECOMMENDED_WORK_PLAN_UPDATE_REQUEST = 'RECOMMENDED_WORK_PLAN_UPDATE_REQUEST';
export const RECOMMENDED_WORK_PLAN_UPDATE_SUCCESS = 'RECOMMENDED_WORK_PLAN_UPDATE_SUCCESS';
export const RECOMMENDED_WORK_PLAN_UPDATE_FAILURE = 'RECOMMENDED_WORK_PLAN_UPDATE_FAILURE';
export const RECOMMENDED_WORK_PLAN_DELETE_REQUEST = 'RECOMMENDED_WORK_PLAN_DELETE_REQUEST';
export const RECOMMENDED_WORK_PLAN_DELETE_SUCCESS = 'RECOMMENDED_WORK_PLAN_DELETE_SUCCESS';
export const RECOMMENDED_WORK_PLAN_DELETE_FAILURE = 'RECOMMENDED_WORK_PLAN_DELETE_FAILURE';
export const RECOMMENDED_WORK_PLAN_BULK_UPDATE_REQUEST = 'RECOMMENDED_WORK_PLAN_BULK_UPDATE_REQUEST';
export const RECOMMENDED_WORK_PLAN_BULK_UPDATE_SUCCESS = 'RECOMMENDED_WORK_PLAN_BULK_UPDATE_SUCCESS';
export const RECOMMENDED_WORK_PLAN_BULK_UPDATE_FAILURE = 'RECOMMENDED_WORK_PLAN_BULK_UPDATE_FAILURE';

export const RECOMMENDED_WORK_PLAN_ALL_REQUEST = 'RECOMMENDED_WORK_PLAN_ALL_REQUEST';
export const RECOMMENDED_WORK_PLAN_ALL_SUCCESS = 'RECOMMENDED_WORK_PLAN_ALL_SUCCESS';
export const RECOMMENDED_WORK_PLAN_ALL_FAILURE = 'RECOMMENDED_WORK_PLAN_ALL_FAILURE';

export const getRecommendedWorkPlan = id => {
  return {
    type: RECOMMENDED_WORK_PLAN_REQUEST,
    id,
  };
};

export const createRecommendedWorkPlan = (body, nextRoute) => {
  return {
    type: RECOMMENDED_WORK_PLAN_CREATE_REQUEST,
    body,
    nextRoute,
  };
};

export const updateRecommendedWorkPlan = (id, body, nextRoute) => {
  return {
    type: RECOMMENDED_WORK_PLAN_UPDATE_REQUEST,
    id,
    body,
    nextRoute,
  };
};

export const deleteRecommendedWorkPlan = id => {
  return {
    type: RECOMMENDED_WORK_PLAN_DELETE_REQUEST,
    id,
  };
};
export const updateRecommendedWorkPlanBulk = (ids, body, nextRoute) => {
  return {
    type: RECOMMENDED_WORK_PLAN_BULK_UPDATE_REQUEST,
    ids,
    body,
    nextRoute,
  };
};

export const getAllRecommendedWorkPlan = query => {
  return {
    type: RECOMMENDED_WORK_PLAN_ALL_REQUEST,
    query,
  };
};
