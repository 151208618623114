import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAssetTypes } from '../../store/features/assetTypesActions';
import { fieldOrder, removeField, hideField } from './assetTypesShared';
import { buildRoute, apiDateToString, jsonKeyToLabel, displayOption } from '../../utilities/strings';
import CommonFormView from '../shared/form/CommonView';
import { cmlModeOptions, geoModeOptions, libraryModeOptions } from '../../api/features/constants';
import { useFeatureFlags } from '../../hooks/settingsHooks';

const AssetTypes = () => {
  const id = useParams().id;
  const dispatch = useDispatch();
  const { hasConditionMonitoring, hasAssetLibrary } = useFeatureFlags();

  useEffect(() => {
    dispatch(getAssetTypes(id));
  }, [dispatch, id]);

  const { data, loading, error } = useSelector(state => state.assetTypes.each);

  const formSettings = {
    fieldOrder,
    removeField: removeField('VIEW'),
    hideField: (values, items) => {
      return hideField(values, items, { hasConditionMonitoring, hasAssetLibrary });
    },
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      // cast to string because booleans do not show up in the field
      let displayLabel = jsonKeyToLabel(key); // eslint-disable-line prefer-const
      let displayValue = '';
      if (data[key] != null) {
        switch (key) {
          // add cases for each key that needs to be customized.
          case 'track_asset_condition':
          case 'is_active':
            displayValue = data[key] ? 'True' : 'False';
            break;
          case 'geo_mode':
            displayValue = displayOption(data[key], geoModeOptions);
            break;
          case 'cml_mode':
            displayValue = displayOption(data[key], cmlModeOptions);
            break;
          case 'library_mode':
            displayValue = displayOption(data[key], libraryModeOptions);
            break;
          case 'updated_on':
          case 'created_on':
          case 'deactivated_on':
            displayValue = apiDateToString(data[key], 'date');
            break;
          default:
            displayValue = data[key].toString();
        }
      }
      fieldSettings[key] = {
        type: 'text',
        fieldProps: {
          label: displayLabel,
          value: displayValue,
        },
      };
    });
  }

  return (
    <CommonFormView
      title={'Asset Type Details'}
      values={data}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      editLink={buildRoute(`/asset-types/${id}/edit`)}
      loading={loading}
      error={error}
    />
  );
};

export default AssetTypes;
