import { parse } from 'csv-parse/dist/esm';
/**
 * @param {Function} handleParsedData
 * @param {Function} handleError
 *
 * @returns {FileReader}
 */
export function buildCSVFileReader(handleParsedData, handleError) {
  const reader = new FileReader();
  reader.onabort = e => handleError(e);
  reader.onerror = e => handleError(e);
  reader.onload = () => {
    parse(
      // replace the BOM character 'ï»¿' and the BOM character '\ufeff'
      //  (so says github copilot)
      reader.result.replace(/[\u{0080}-\u{FFFF}]/gu, ''),
      { bom: true, ltrim: true, rtrim: true },
      (err, data) => {
        if (err) {
          handleError(err);
        } else {
          handleParsedData(data);
        }
      }
    );
  };
  return reader;
}

/**
 *
 * @param {Array<Array>} data [[header1, header2, ...], [row1col1, row1col2, ...], ...]
 * @param {Object<String, Regex>} headerMap (optional) {header1: /regex1/, header2: /regex2/, ...}
 */
export function convertCSVMatrixToObjects(data, headerMap = {}) {
  const headers = data[0];
  const rows = data.slice(1);

  /*
    headers = ['First Name', 'Last Name', 'Email']
    headerMap = {
      first: /first/i,
      last: /last/i,
      email: /email/i,
    }

    invertedHeaderMap = {
      'First Name': 'first',
      'Last Name': 'last',
      'Email': 'email',
    }
  */
  const invertedHeaderMap = headers.reduce((map, rawHeader) => {
    const headerMapping = Object.entries(headerMap).find(([_header, regexExpression]) =>
      rawHeader.match(regexExpression)
    );
    if (headerMapping) {
      const [cleanedHeader /* regexExpression */] = headerMapping;
      return {
        ...map,
        [rawHeader]: cleanedHeader,
      };
    } else {
      return {
        ...map,
        [rawHeader]: rawHeader,
      };
    }
  }, {});

  const objects = rows.map(row => {
    return headers.reduce((obj, header, index) => {
      const cleanedHeader = invertedHeaderMap[header];
      return {
        ...obj,
        [cleanedHeader]: row[index],
      };
    }, {});
  });

  return objects;
}
