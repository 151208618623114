import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ButtonGroup } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Table, { SELECTABLE_ROWS_NONE } from '../shared/table/Table';
import { getAllAssetTypes, deleteAssetTypes } from '../../store/features/assetTypesActions';
import { setAssetTypesTableView, setAssetTypesTableSize } from '../../store/settings/views/assetTypesTableViewRedux';
import { buildRoute, apiDateToString } from '../../utilities/strings';
import ButtonIcon from '../shared/buttons/ButtonIcon';
import StyledLink from '../shared/StyledLink';
import { setColumns } from '../shared/table/columns';
import { openDialog } from '../../store/dialogActions';
import DeleteDialog from '../shared/Dialog/DeleteDialog';
import { useFeatureFlags, usePermissions, useTableViews } from '../../hooks/settingsHooks';
import { tableViews } from '../../utilities/tables';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  centerText: {
    textAlign: 'center',
  },
}));

const AssetTypes = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { loading, data } = useSelector(state => ({
    loading: state.assetTypes.all.loading,
    data: state.assetTypes.all.dataAll.results,
  }));
  const { assetTypesTableViewKey } = tableViews;
  const views = useTableViews(assetTypesTableViewKey);
  const { hasConfigurationManagementBuild } = usePermissions();
  const { hasConditionMonitoring } = useFeatureFlags();
  useEffect(() => {
    dispatch(getAllAssetTypes());
  }, []); // eslint-disable-line

  const classes = useStyles();

  /** @type {Column[]} */
  const columns = [
    {
      // This needs to be first and excluded so customBodyRender can access the id to create the link.
      name: 'id',
      label: 'Id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        filterType: 'textField',
        customBodyRender: (value, tableMeta) => {
          return <StyledLink to={`/asset-types/${tableMeta.rowData[columns.findIndexByName['id']]}`} value={value} />;
        },
      },
    },
    {
      name: 'manufacturer',
      label: 'Manufacturer',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'model',
      label: 'Model',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'track_asset_condition',
      label: 'Track Asset Condition',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => {
          return <div className={classes.centerText}>{value ? 'True' : 'False'}</div>;
        },
      },
    },
    {
      name: 'is_active',
      label: 'Is Active',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => (value ? 'True' : 'False'),
      },
    },
    {
      name: 'updated_on',
      label: 'Updated',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => apiDateToString(value, 'date'),
      },
    },
    {
      name: 'created_on',
      label: 'Created',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => apiDateToString(value, 'date'),
      },
    },
    // {
    //   name: 'deactivated_on',
    //   label: 'Deactived On',
    //   options: {
    //     filter: true,
    //     sort: true,
    //     customBodyRender: value => apiDateToString(value, 'date'),
    //   },
    // },
    {
      name: 'cml_mode',
      label: 'CML Mode',
      options: {
        filter: true,
        sort: true,
        display: hasConditionMonitoring ? true : 'exclude',
      },
    },
    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        print: false,
        viewColumns: false,
        customBodyRender: (_, info) => {
          // 0 is the first element in column
          const id = info.rowData[columns.findIndexByName['id']];
          const name = info.rowData[columns.findIndexByName['name']];
          return (
            <>
              {hasConfigurationManagementBuild && (
                <ButtonGroup>
                  <ButtonIcon history={history} icon={EditIcon} location={location} to={`/asset-types/${id}/edit`} />
                  <ButtonIcon
                    icon={DeleteIcon}
                    onClick={() => {
                      dispatch(
                        openDialog(
                          'Delete Asset Type?',
                          <DeleteDialog id={id} deleteAction={deleteAssetTypes} name={name} />
                        )
                      );
                    }}
                  />
                </ButtonGroup>
              )}
            </>
          );
        },
      },
    },
  ];

  // handle columns display
  setColumns(columns, views);

  /** @type {TableOptions} */
  const options = {
    // if the column view changes, update redux with either 'add' or 'remove'
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setAssetTypesTableView(changedColumn, action));
    },
    onChangeRowsPerPage: numberOfRows => {
      dispatch(setAssetTypesTableSize(numberOfRows));
    },
    rowsPerPage: views.pageSize,
    enableNestedDataAccess: '.',
    selectableRows: SELECTABLE_ROWS_NONE,
  };

  return (
    <div className={classes.root}>
      <Table
        title="Asset Types"
        simpleSearch
        columns={columns}
        addRoute={buildRoute('/asset-types/new')}
        data={data}
        options={options}
        loading={loading}
      />
    </div>
  );
};

export default AssetTypes;
