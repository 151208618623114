import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAssetTypes } from '../store/features/assetTypesActions';
import { getLastFetchAt, setLastFetchAt, shouldFetchState } from './shared';

const assetTypeQuery = { is_active: true, ordering: 'name' };
const localStorageKey = 'lastAssetTypeTime';

export const useCachedAssetTypes = (query = assetTypeQuery) => {
  const dispatch = useDispatch();
  const { dataAll: { results } = { results: [] }, query: assetTypesQuery } = useSelector(state => state.assetTypes.all);
  useEffect(() => {
    if (!query) return;

    const lastFetch = getLastFetchAt(localStorageKey);

    const shouldFetch = shouldFetchState(query, assetTypesQuery, lastFetch);
    if (shouldFetch) {
      dispatch(getAllAssetTypes(assetTypeQuery));
      setLastFetchAt(localStorageKey);
    }
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  const assetTypeMap = {};
  const options = results?.reduce(
    (acc, { id, name }) => {
      const value = String(id);
      assetTypeMap[value] = name;
      acc.assetTypeOptions = [...acc.assetTypeOptions, value];

      return acc;
    },
    { assetTypeOptions: [] } // may add more in the future
  );

  const { assetTypeOptions } = { ...options }; // may add more in the future
  return {
    results,
    assetTypeOptions,
    assetTypeMap,
  };
};
