// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import { all, put, takeLatest } from 'redux-saga/effects';

import {
  OBSERVATIONS_GETTER_REQUEST,
  OBSERVATIONS_GETTER_SUCCESS,
  OBSERVATIONS_GETTER_FAILURE,
} from './observationsActions';

import { fetchObservations } from '../../api/getters/observations.js';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';

export function* observationsFetch(action) {
  const { query } = action;
  try {
    const { data, error, response } = yield fetchObservations(query);
    if (data) {
      yield put({ type: OBSERVATIONS_GETTER_SUCCESS, data });
    } else if (error) {
      yield put({ type: OBSERVATIONS_GETTER_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, OBSERVATIONS_GETTER_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, OBSERVATIONS_GETTER_FAILURE);
  }
}

export function* watchObservationsFetch() {
  yield takeLatest(OBSERVATIONS_GETTER_REQUEST, observationsFetch);
}

export default function* observationsSaga() {
  yield all([watchObservationsFetch()]);
}
