import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { getRiskColor } from './assessmentsShared';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 3, 4, 3),
  },
  cell: {
    height: '75px',
    width: '75px',
    textAlign: 'center',
    fontSize: 26,
    fontWeight: 'bold',
  },
  tableContainer: {
    borderSpacing: 0,
  },
  pofHeader: {
    writingMode: 'vertical-lr',
    transform: 'scale(-1, -1)',
    fontSize: 16,
  },
  cofHeader: {
    fontSize: 16,
    marginLeft: '140px',
  },
}));

const AssessmentsRiskMatrix = props => {
  const classes = useStyles();

  const { cof, pof, matrixConfig } = props;

  if (!matrixConfig.length) {
    return <></>; // Assume handle this case in parent component
  }

  return (
    <div className={classes.root}>
      <table data-testid="risk-matrix-table" className={classes.tableContainer}>
        {matrixConfig.map((rowConfig, i) => (
          <tr key={i}>
            {/* POF Label w/ Numbers Across Left of Table */}
            {i === 0 && (
              <th rowSpan={matrixConfig.length + 1}>
                <span className={classes.pofHeader}>Probability of Failure</span>
              </th>
            )}
            <th style={{ padding: 10 }}>{matrixConfig.length - i}</th>

            {/* Risk Matrix Cells for Row */}
            {rowConfig.map((color, j) => {
              const _pof = matrixConfig.length - i;
              const _cof = j + 1;
              const isSelected = Number(pof) === _pof && Number(cof) === _cof;
              let count;

              return (
                <td
                  data-testid={`${_cof}-${_pof}-cell`}
                  key={j}
                  className={classes.cell}
                  style={{
                    border: isSelected ? '5px solid black' : '1px solid black',
                    background: getRiskColor(color),
                  }}>
                  {count}
                </td>
              );
            })}
          </tr>
        ))}

        {/* COF Label w/ Numbers Across Bottom of Table */}
        <tr>
          <th></th>
          {matrixConfig[0].map((color, j) => {
            return (
              <th key={j} style={{ padding: 10, textAlign: 'center' }}>
                {j + 1}
              </th>
            );
          })}
        </tr>
        <tr>
          <th colSpan={matrixConfig[0].length + 1}>
            <span className={classes.cofHeader}>Consequence of Failure</span>
          </th>
        </tr>
      </table>
    </div>
  );
};

AssessmentsRiskMatrix.defaultProps = {
  matrixConfig: [],
};

AssessmentsRiskMatrix.propTypes = {
  matrixConfig: PropTypes.array,
  cof: PropTypes.number,
  pof: PropTypes.number,
};

export default AssessmentsRiskMatrix;
