export const PROJECT_TYPES_REQUEST = 'PROJECT_TYPES_REQUEST';
export const PROJECT_TYPES_SUCCESS = 'PROJECT_TYPES_SUCCESS';
export const PROJECT_TYPES_FAILURE = 'PROJECT_TYPES_FAILURE';
export const PROJECT_TYPES_CREATE_REQUEST = 'PROJECT_TYPES_CREATE_REQUEST';
export const PROJECT_TYPES_CREATE_SUCCESS = 'PROJECT_TYPES_CREATE_SUCCESS';
export const PROJECT_TYPES_CREATE_FAILURE = 'PROJECT_TYPES_CREATE_FAILURE';
export const PROJECT_TYPES_UPDATE_REQUEST = 'PROJECT_TYPES_UPDATE_REQUEST';
export const PROJECT_TYPES_UPDATE_SUCCESS = 'PROJECT_TYPES_UPDATE_SUCCESS';
export const PROJECT_TYPES_UPDATE_FAILURE = 'PROJECT_TYPES_UPDATE_FAILURE';
export const PROJECT_TYPES_DELETE_REQUEST = 'PROJECT_TYPES_DELETE_REQUEST';
export const PROJECT_TYPES_DELETE_SUCCESS = 'PROJECT_TYPES_DELETE_SUCCESS';
export const PROJECT_TYPES_DELETE_FAILURE = 'PROJECT_TYPES_DELETE_FAILURE';
export const PROJECT_TYPES_ALL_REQUEST = 'PROJECT_TYPES_ALL_REQUEST';
export const PROJECT_TYPES_ALL_SUCCESS = 'PROJECT_TYPES_ALL_SUCCESS';
export const PROJECT_TYPES_ALL_FAILURE = 'PROJECT_TYPES_ALL_FAILURE';
export const PROJECT_TYPES_CLEAR = 'PROJECT_TYPES_CLEAR';

export const getProjectTypes = id => {
  return {
    type: PROJECT_TYPES_REQUEST,
    id,
  };
};

export const createProjectTypes = body => {
  return {
    type: PROJECT_TYPES_CREATE_REQUEST,
    body,
  };
};

export const updateProjectTypes = (id, body, options = {}) => {
  return {
    type: PROJECT_TYPES_UPDATE_REQUEST,
    id,
    body,
    options,
  };
};

export const deleteProjectTypes = id => {
  return {
    type: PROJECT_TYPES_DELETE_REQUEST,
    id,
  };
};

export const clearProjectTypes = () => {
  return {
    type: PROJECT_TYPES_CLEAR,
  };
};

export const getAllProjectTypes = query => {
  return {
    type: PROJECT_TYPES_ALL_REQUEST,
    query,
  };
};
