import React from 'react';
import PropTypes from 'prop-types';
import StopIcon from '@mui/icons-material/Stop';
import variables from '../../config.module.scss';

export const defectStates = ['PENDING', 'OPEN', 'MONITOR', 'REPAIR', 'REPAIRED', 'CLOSED'];

export const getStatus = defectState => {
  const stateOptions = {
    PENDING: {
      label: 'Pending',
      color: variables.textSecondaryLight,
    },
    OPEN: {
      label: 'Open',
      color: variables.lightBlue,
    },
    MONITOR: {
      label: 'Monitor',
      color: variables.lightYellow,
    },
    REPAIR: {
      label: 'Repair',
      color: variables.orange,
    },
    REPAIRED: {
      label: 'Repaired',
      color: variables.lightOrange,
    },
    CLOSED: {
      label: 'Closed',
      color: variables.green,
    },
  };

  if (!stateOptions[defectState]) {
    return {
      color: variables.black,
      label: 'UNKNOWN LABEL',
    };
  }
  return stateOptions[defectState];
};

const DefectStatesDisplay = props => {
  const display = getStatus(props.state);

  return (
    <span>
      <StopIcon style={{ color: display.color, verticalAlign: 'middle' }} /> {display.label}
    </span>
  );
};

DefectStatesDisplay.defaultProps = {
  state: '',
};

DefectStatesDisplay.propTypes = {
  state: PropTypes.string,
};

export default DefectStatesDisplay;
