import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { getDefects, getAllDefects } from '../../store/features/defectsActions';
import { getAllProjects } from '../../store/features/projectsActions';
import Error from '../shared/displays/Error';
import Loading from '../shared/displays/Loading';
import StyledLink from '../shared/StyledLink';
import DefectsView from './DefectsView';
import DefectToolbar from './DefectToolbar';
import DefectHistory from './DefectHistory';
import DefectsTable from './DefectsTable';
import ProjectsTable from '../projects/ProjectsTable';
import { useFeatureFlags, usePermissions, useTableViewSettings } from '../../hooks/settingsHooks';
import { getProjectIsReadOnly } from '../../utilities/projects';
import { tableViews } from '../../utilities/tables';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(6, 'auto'),
    padding: theme.spacing(2),
    marginTop: theme.spacing(12),
    textAlign: 'left',
  },
  header: {
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
  },
  button: {
    padding: theme.spacing(0, 1),
  },
  assetLinks: {
    margin: theme.spacing(0, 0, 2),
  },
  defectsView: {
    margin: theme.spacing(2, 4),
  },
  embeddedTableStyle: {
    margin: theme.spacing(2, 4),
  },
}));

const getDefaultBackLink = defect => {
  try {
    const projectId = defect.project;
    return `/projects/${projectId}`;
  } catch (error) {
    return `/assets/${defect.asset.id}`;
  }
};

const DefectDetail = () => {
  const id = useParams().id;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { hasDefectEdit, hasAssetView } = usePermissions();
  const { hasFindingsLinking } = useFeatureFlags();
  const { defectsTableViewKey, projectsTableViewKey } = tableViews;

  const defaultDefectsQuery = { related_to: id };
  const {
    tableQuery: storedDefectsQuery,
    embeddedQuery: defectsEmbeddedQuery,
    page: defectsPage,
    views: defectsView,
  } = useTableViewSettings(defectsTableViewKey, defaultDefectsQuery);

  const relatedProjectsQuery = { scope__selected_findings: id };
  const {
    tableQuery: storedProjectsQuery,
    embeddedQuery: projectsEmbeddedQuery,
    page: projectsPage,
    views: projectsView,
  } = useTableViewSettings(projectsTableViewKey, relatedProjectsQuery);

  useEffect(() => {
    dispatch(getDefects(id));
    if (hasFindingsLinking) {
      dispatch(getAllDefects(defaultDefectsQuery));
    }
    dispatch(getAllProjects(relatedProjectsQuery));
  }, [dispatch, id]); // eslint-disable-line react-hooks/exhaustive-deps

  const { data, loading, error, status } = useSelector(state => {
    const { data, loading, error } = state.defects.each;
    const { status } = state.projects.each.data;
    return { data, loading, error, status };
  });

  const classes = useStyles();

  if (loading) {
    return <Loading />;
  }
  if (Object.keys(data).length === 0 && data.constructor === Object && !error) {
    return <Loading />;
  }
  if (error) return <Error error={error} />;

  const handleBack = () => {
    if (location.state && location.state.back) {
      history.push(location.state.back);
    } else {
      const next = getDefaultBackLink(data);
      history.push(next);
    }
  };

  const namePath = () => {
    const items = [];
    if (data.asset) {
      items.push(<StyledLink to={hasAssetView ? `/assets/${data.asset.id}` : ''} value={data.asset.name} />);
    }
    if (data.component_display) {
      items.push(<>{data.component_display}</>);
    }
    if (data.location_zone_display) {
      items.push(<>{data.location_zone_display}</>);
    }
    if (data.location_code_display) {
      items.push(<>{data.location_code_display}</>);
    }
    items.push(<>Finding {data.id}</>);
    return items.map((item, index) => <React.Fragment key={index}>/ {item} </React.Fragment>);
  };

  // TODO: need to do a mroe complete fix.  putting this in as a temporary fix.
  // This doesn't completely work.  If a user refreshes the page, the project data will be cleared from redux and the
  // user will be able to click the edit button.  We need a better way to figure out if the project is published.
  const projectReadOnly = getProjectIsReadOnly(status, hasDefectEdit);

  const defectTableChangeHandler = query => {
    const updatedQuery = { ...defectsEmbeddedQuery, ...query };
    dispatch(getAllDefects(updatedQuery));
  };
  const projectTableChangeHandler = query => {
    const updatedQuery = { ...projectsEmbeddedQuery, ...query };
    dispatch(getAllProjects(updatedQuery));
  };

  return (
    <div className={classes.root}>
      <DefectToolbar
        id={id}
        asset={data.asset}
        state={data.state}
        handleBack={handleBack}
        namePath={namePath}
        hasDefectEdit={hasDefectEdit}
        projectReadOnly={projectReadOnly}
      />
      <Grid container>
        <Grid item xs={12} className={classes.defectsView}>
          <DefectsView data={data} loading={loading} error={error} />
        </Grid>
        <Grid item xs={12}>
          <DefectsTable
            embedded
            title={'Related Findings'}
            tableChangeHandler={defectTableChangeHandler}
            styleProjectDetail={classes.embeddedTableStyle}
            queryParamObj={storedDefectsQuery}
            page={defectsPage}
            views={defectsView}
            filename={`Finding-${id}`}
          />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.embeddedTableStyle}>
            <ProjectsTable
              title="Related Projects"
              tableChangeHandler={projectTableChangeHandler}
              queryParamObj={storedProjectsQuery}
              page={projectsPage}
              views={projectsView}
              embedded
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <DefectHistory stateHistory={data.history} />
        </Grid>
      </Grid>
    </div>
  );
};

export default DefectDetail;
