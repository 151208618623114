// lib
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Divider } from '@mui/material';

import { getAllLibraryMedia } from '../../store/features/libraryMediaActions';
// components

// TODO: Replace with the new layers component
import ImageAnnotator2 from '../shared/imageTools/ImageAnnotator2';

// utils / styles

import './Checklist.scss';

function ChecklistSectionPlacement(props) {
  const { label, description } = props;
  const dispatch = useDispatch();

  // HACK to get some media to display as a layer
  // This should come from the template (or the project library)
  const libraryId = '1002';

  const { data } = useSelector(state => ({
    loading: state.libraryMedia.all.loading,
    data: state.libraryMedia.all.dataAll.results,
  }));
  const libraryMediaQuery = { library: libraryId };

  useEffect(() => {
    dispatch(getAllLibraryMedia(libraryMediaQuery));
  }, [libraryId]); // eslint-disable-line

  // Fix this for real. This is just a hack to get some media to display as a layer
  // would want to select load layers
  const renderLayers = () => {
    return data.map((item, index) => {
      return <ImageAnnotator2 key={item.id} image={item.file} fileName={item.name} />;
    });
  };

  // const prefix = props.sectionId; // this is needed when we save data

  return (
    <>
      <h1 className="add-margin-top-bottom">{label}</h1>
      <h4 className="margin-sides">{description} - Placement</h4>
      <Divider />
      {renderLayers()}
    </>
  );
}

ChecklistSectionPlacement.propTypes = {
  sectionId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  lines: PropTypes.array.isRequired,
  defaultActions: PropTypes.array,
  description: PropTypes.string,
  readOnly: PropTypes.bool.isRequired,
  override: PropTypes.bool.isRequired,
};
ChecklistSectionPlacement.defaultProps = { defaultActions: [] };

export default ChecklistSectionPlacement;
