import { combineModuleReducers, getEndpointsFromModules } from './util/helpers';

// import endpoints here and add to `allModules` array
import * as assets from './assets';
import * as bookmarks from './bookmarks';
import * as checklistResultLines from './checklistResultLines';
import * as checklistTemplates from './checklistTemplates';
import * as checklists from './checklists';
import * as defectProfiles from './defectProfiles';
import * as inspectionMedia from './inspectionMedia';
import * as reportTypes from './reportTypes';
import * as projects from './projects';
import * as heatData from './heatData';
import * as companies from './companies';
import * as user from './user';

const allModules = [
  assets,
  bookmarks,
  checklistResultLines,
  checklistTemplates,
  checklists,
  companies,
  inspectionMedia,
  reportTypes,
  defectProfiles,
  projects,
  heatData,
  user,
];

export const sagas = getEndpointsFromModules(allModules).map(endpoint => endpoint.saga());

export const reducer = combineModuleReducers(allModules);
