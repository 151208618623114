import { all, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  USERS_REQUEST,
  USERS_SUCCESS,
  USERS_FAILURE,
  USERS_CREATE_REQUEST,
  USERS_CREATE_SUCCESS,
  USERS_CREATE_FAILURE,
  USERS_BULK_CREATE_REQUEST,
  USERS_BULK_CREATE_SUCCESS,
  USERS_BULK_CREATE_FAILURE,
  USERS_UPDATE_REQUEST,
  USERS_UPDATE_SUCCESS,
  USERS_UPDATE_FAILURE,
  USERS_DELETE_REQUEST,
  USERS_DELETE_SUCCESS,
  USERS_DELETE_FAILURE,
  USERS_ALL_REQUEST,
  USERS_ALL_SUCCESS,
  USERS_ALL_FAILURE,
} from './usersActions';

import {
  fetchUsers,
  createUsers,
  updateUsers,
  deleteUsers,
  fetchUsersList,
  createUsersBulk,
} from '../../api/features/users';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';
import { DIALOG_CLOSE } from '../dialogActions';
import { SNACKBAR_OPEN } from '../snackbarActions';

export function* usersFetch(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield fetchUsers(id);
    if (data) {
      yield put({ type: USERS_SUCCESS, data });
    } else if (error) {
      yield put({ type: USERS_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, USERS_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, USERS_FAILURE);
  }
}

export function* watchUsersFetch() {
  yield takeLatest(USERS_REQUEST, usersFetch);
}

export function* usersCreate(action) {
  const { body } = action;
  try {
    const { data, error, formError, response } = yield createUsers(body);
    if (data) {
      yield put({ type: USERS_CREATE_SUCCESS, data });
      yield put(push(`/users/${data.id}`));
    } else if (error) {
      yield put({ type: USERS_CREATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: USERS_CREATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, USERS_CREATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, USERS_CREATE_FAILURE);
  }
}

export function* watchUsersCreate() {
  yield takeLatest(USERS_CREATE_REQUEST, usersCreate);
}
export function* usersBulkCreate(action) {
  const { body } = action;
  try {
    const { data, error, formError, response } = yield createUsersBulk(body);
    const createdUserLength = data.created_users.length;
    if (data) {
      yield put({ type: USERS_BULK_CREATE_SUCCESS, data });
      yield put(push(`/users/`));
      if (data.created_users.length > 0) {
        yield put({
          type: SNACKBAR_OPEN,
          message: `${createdUserLength} ${createdUserLength > 1 ? 'users' : 'user'}  created successfully`,
          variant: 'success',
          open: true,
        });
      }
      if (data.warnings.length > 0) {
        // Warning message:"Skipping .: odict_values([[ErrorDetail(string='Enter a valid email address.', code='invalid')]])"
        const message = data.warnings[0].split("'");

        yield put({
          type: SNACKBAR_OPEN,
          message: `User not created: ${message[1]}`,
          variant: 'warning',
          open: true,
        });
      }
    } else if (error) {
      yield put({ type: USERS_BULK_CREATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: USERS_BULK_CREATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, USERS_BULK_CREATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, USERS_BULK_CREATE_FAILURE);
  }
}

export function* watchUsersBulkCreate() {
  yield takeLatest(USERS_BULK_CREATE_REQUEST, usersBulkCreate);
}

export function* usersUpdate(action) {
  const { id, body } = action;
  try {
    const { data, error, formError, response } = yield updateUsers(id, body);
    if (data) {
      yield put({ type: USERS_UPDATE_SUCCESS, data });
      if (action.refresh) {
        yield put({ type: USERS_ALL_REQUEST });
      } else {
        yield put(push('/users'));
      }
    } else if (error) {
      yield put({ type: USERS_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: USERS_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, USERS_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, USERS_UPDATE_FAILURE);
  }
}

export function* watchUsersUpdate() {
  yield takeLatest(USERS_UPDATE_REQUEST, usersUpdate);
}

export function* usersDelete(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield deleteUsers(id);
    if (data) {
      yield put({ type: USERS_DELETE_SUCCESS });
      yield put({ type: USERS_ALL_REQUEST });
      yield put({ type: DIALOG_CLOSE });
      yield put(push('/users'));
    } else if (error) {
      yield put({ type: USERS_DELETE_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, USERS_DELETE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, USERS_DELETE_FAILURE);
  }
}

export function* watchUsersDelete() {
  yield takeLatest(USERS_DELETE_REQUEST, usersDelete);
}

export function* usersFetchAll(action) {
  try {
    const { data, error, response } = yield fetchUsersList(action.query);
    if (data) {
      yield put({ type: USERS_ALL_SUCCESS, data });
    } else if (error) {
      yield put({ type: USERS_ALL_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, USERS_ALL_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, USERS_ALL_FAILURE);
  }
}

export function* watchUsersFetchAll() {
  yield takeLatest(USERS_ALL_REQUEST, usersFetchAll);
}

export default function* usersSaga() {
  yield all([
    watchUsersFetch(),
    watchUsersCreate(),
    watchUsersUpdate(),
    watchUsersDelete(),
    watchUsersFetchAll(),
    watchUsersBulkCreate(),
  ]);
}
