import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { jsonKeyToLabel, apiDateToString, queriesFromString } from '../../utilities/strings';
import { createBulkImports, updateBulkImports } from '../../store/features/bulkImportsActions';
import CommonForm from '../shared/form/Common';
import { removeField, hideField } from '../bulkImports/bulkImportsShared';
import { getLabel } from '../../utilities/objects';
import { useAssets } from '../../hooks/optionsHooks';
import Loading from '../shared/displays/Loading';

// included the 'asset' in the fields array,
// ( otherwise this is the same as bulkImportsShared.fieldOrder )
const fieldOrder = {
  import: {
    display: 'Import',
    fields: ['asset', 'action', 'flavor', 'upload'],
  },
  status: {
    display: 'Status',
    fields: ['state', 'messages', 'errors', 'warnings', 'uploaded_by', 'completed_on', 'created_on', 'updated_on'],
  },
};

const HeatDataImportsForm = props => {
  const { update } = props;
  const [submittedValues, setSubmittedValues] = useState({});
  const [assetPath, setAssetPath] = useState('');
  const [asset, setAsset] = useState(null);
  const location = useLocation();

  const defaultAction = 'IMPORT_HEATDATA';
  const QMOS_HEAT_FLAVOR = '775';
  const defaultFlavor = QMOS_HEAT_FLAVOR;

  const dispatch = useDispatch();
  const assetsOptions = useAssets();
  const queries = queriesFromString(location.search);

  const data = {};
  const formError = undefined;
  const loading = undefined;
  const error = undefined;

  const initialValueSetter = type => {
    if (asset !== null) {
      if (type === 'label') {
        return getLabel(asset, assetsOptions);
      } else {
        return asset;
      }
    } else {
      return '';
    }
  };

  const updateAsset = e => {
    // We are going to see this event twice on the asset select
    // ignore the one without the data.
    if (e.target.innerText) {
      setAsset(e.target.value);
      setAssetPath(e.target.innerText);
    }
  };

  const formSettings = {
    fieldOrder,
    removeField: removeField(update ? 'UPDATE' : 'CREATE'),
    hideField,
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      switch (key) {
        /* ---------- Required Fields ---------- */
        case 'upload':
          fieldSettings[key] = {
            type: 'file',
            fieldProps: {
              label: 'Import',
              name: 'upload',
              accept: '.xls, .xlsx',
              uploadText: 'Click or drop in the file to import.',
            },
          };
          break;

        /* ---------- Regular Fields ---------- */

        /* ----------  Disabled Fields ---------- */
        case 'created_on':
        case 'updated_on':
          fieldSettings[key] = {
            type: 'display',
            fieldProps: {
              disabled: true,
              label: jsonKeyToLabel(key),
              name: key,
              value: apiDateToString(data[key], 'date'),
            },
          };
          break;
        /* ----------  Asset ---------- */
        case 'asset':
          fieldSettings[key] = {
            type: 'asset-autocomplete',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: 'asset',
              required: true,
              labelwidth: 42,
              valueInitial: { label: initialValueSetter('label', asset), value: initialValueSetter('value', asset) },
              filterQuery: { no_parent: true },
              onChange: updateAsset,
            },
          };
          break;

        /* ----------  Default ---------- */
        default:
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
      }
    });
  }

  // UPDATE ME: Replace with singular version of feature name
  const title = update ? 'Edit Import' : 'New Import';

  let initialValues = {};
  if (update) {
    // The form cannot handle data coming in as objects.  (see ProjectsForm as an example)
    initialValues = { ...data };
  } else {
    // assign values from url queries here
    initialValues = {
      action: queries.action ? queries.action : defaultAction,
      flavor: queries.flavor ? queries.flavor : defaultFlavor,
      asset: undefined,
    };
    // initialValues = { action: defaultAction, flavor: defaultFlavor, asset: undefined };
  }

  const onSubmit = changedValues => {
    setSubmittedValues(changedValues);
    const updatedValues = { ...changedValues };
    if (update) {
      dispatch(updateBulkImports(updatedValues.id, updatedValues));
    } else {
      const formData = new FormData();
      const file = changedValues.upload[0];
      formData.append('action', updatedValues.action);
      formData.append('flavor', updatedValues.flavor);
      formData.append('source_asset_path', assetPath);
      formData.append('upload', file);
      dispatch(createBulkImports(formData));
    }
  };

  /**
   * Decorators are used for setting the values of other fields based off of a field.
   * Decorator format can be found here: src/components/shared/form/common.js
   */
  const decorators = [];

  /**
   *  Validations that are per field.
   *  Errors associated with the form are passed back through formError in Redux.
   */
  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        if (values[key] === submittedValues[key]) {
          errors[key] = value;
        } else {
          errors[key] = undefined;
        }
      }
    }
    return errors;
  };
  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <CommonForm
        update={update}
        title={title}
        initialValues={initialValues}
        fieldSettings={fieldSettings}
        formSettings={formSettings}
        onSubmit={onSubmit}
        decorators={decorators}
        validate={validate}
        loading={loading}
        error={error}
        keepDirtyOnReinitialize
      />
    </>
  );
};

HeatDataImportsForm.defaultProps = {
  update: false,
};

HeatDataImportsForm.propTypes = {
  update: PropTypes.bool,
};

export default HeatDataImportsForm;
