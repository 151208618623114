import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCrews } from '../../store/features/crewsActions';
import { fieldOrder, removeField, hideField } from './crewsShared';
import { apiDateToString, capitalizeFirstChar, jsonKeyToLabel } from '../../utilities/strings';
import CommonFormView from '../shared/form/CommonView';
import { usePermissions } from '../../hooks/settingsHooks';

const Crews = () => {
  const id = useParams().id;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCrews(id));
  }, [dispatch, id]);

  const { hasCrewEdit } = usePermissions();

  const { data, loading, error } = useSelector(state => state.crews.each);

  const formSettings = {
    fieldOrder,
    removeField: removeField('VIEW'),
    hideField,
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      // cast to string because booleans do not show up in the field
      let displayLabel = jsonKeyToLabel(key); // eslint-disable-line prefer-const
      let displayValue = '';
      if (data[key] != null) {
        switch (key) {
          case 'company':
            displayValue = data[key].name;
            break;
          case 'users':
            displayValue = data[key]
              .map(user => user.name)
              .filter(user => user)
              .join(', ');
            break;
          // add cases for each key that needs to be customized.
          case 'updated_on':
          case 'created_on':
            displayValue = apiDateToString(data[key], 'date');
            break;
          case 'is_active':
            displayValue = capitalizeFirstChar(String(data[key]));
            break;
          default:
            displayValue = data[key].toString();
        }
      }
      fieldSettings[key] = {
        type: 'text',
        fieldProps: {
          label: displayLabel,
          value: displayValue,
        },
      };
    });
  }

  return (
    <CommonFormView
      title={'Crew Details'}
      values={data}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      editLink={hasCrewEdit && `/crews/${id}/edit`}
      loading={loading}
      error={error}
    />
  );
};

export default Crews;
