import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { Autocomplete } from 'mui-rff';
import { Checkbox as MuiCheckbox } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { getUsers } from '../../../store/getters/usersGetterActions';
import { getUserOptionLabel } from '../../../utilities/users';

const useStyles = makeStyles(theme => ({
  // make menu positioning relative
  popperDisablePortal: {
    position: 'relative',
  },
}));

/**
 * Autocomplete form that looks up users form the server and allows multiple selections
 */
const FieldEditUserAutocomplete = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { disabled, required, name, label, loading, helperText, initialUsers, queryParams, textFieldProps, multiple } =
    props;
  const [selectedUsers, setSelectedUsers] = useState(initialUsers);
  const { loadingUsers, userOptions } = useSelector(state => {
    const { results: users } = state.usersGetter.data || [];
    const userSearched = users?.map(getUserOptionLabel) ?? [];
    const _initialUsers = initialUsers.length ? initialUsers : [initialUsers];
    const userOptions = _.uniqBy(_initialUsers.concat(selectedUsers, userSearched), 'value');

    return {
      loadingUsers: loading || state.usersGetter.loading,
      userOptions,
    };
  });

  useEffect(() => {
    dispatch(getUsers(queryParams));
  }, []); // eslint-disable-line

  // fetches all users again after clearing the input
  useEffect(() => {
    if (!selectedUsers.length) {
      dispatch(getUsers(queryParams));
    }
  }, [selectedUsers]); // eslint-disable-line

  const requiredFnMultiSelect = required => value => {
    if (required) {
      if (value) {
        return value.length !== 0 ? undefined : 'Required';
      }
      return 'Required';
    }
    return undefined;
  };
  let modifiedLabel = label;
  if (required) {
    modifiedLabel = (
      <span>
        {label}
        <sup>*</sup>
      </span>
    );
  }

  const handleOnChangeFunc = value => {
    const updatedValue = { ...queryParams, search: value };
    dispatch(getUsers(updatedValue));
  };

  const handleOnInputChange = (value, reason) => {
    if (value !== '') {
      handleOnChangeFunc(value);
    }
    // if empty string, do nothing
  };

  const debounce = func => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const debounceInput = debounce(handleOnInputChange); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnChange = (event, values) => {
    if (values === null) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(values);
    }
  };

  return (
    <Autocomplete
      autocomplete
      multiple={multiple}
      disableCloseOnSelect
      disablePortal
      defaultValue={multiple ? [] : initialUsers} // set default value when multiple is false
      classes={classes}
      disabled={disabled || loading}
      name={name}
      label={modifiedLabel}
      options={userOptions}
      onChange={handleOnChange}
      onInputChange={(event, value, reason) => {
        debounceInput(value, reason);
      }}
      helperText={helperText}
      getOptionValue={option => option?.value || ''}
      getOptionLabel={option => option?.label || ''}
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      renderOption={(optionProps, option, { selected }) => (
        <li {...optionProps}>
          {userOptions.length === 0 ? <></> : <MuiCheckbox style={{ marginRight: 8 }} checked={selected} />}
          {option.label}
        </li>
      )}
      loading={loadingUsers}
      noOptionsText={loadingUsers ? 'Loading Users...' : 'No users found.'}
      fieldProps={required ? { validate: requiredFnMultiSelect(required) } : {}}
      textFieldProps={{
        variant: 'outlined',
        InputLabelProps: { shrink: true },
        ...textFieldProps,
      }}
    />
  );
};

FieldEditUserAutocomplete.defaultProps = {
  value: undefined,
  required: false,
  disabled: false,
  loading: false,
  helperText: '',
  textFieldProps: {},
  multiple: false,
};

FieldEditUserAutocomplete.propTypes = {
  /** label for the autocomplete field */
  label: PropTypes.string.isRequired,
  /** sets the label width so the border does not overlap the label */
  labelwidth: PropTypes.number.isRequired,
  /** the api key for the feature */
  name: PropTypes.string.isRequired,
  /** initial value for the field */
  value: PropTypes.string,
  /** sets the field as required for the form */
  required: PropTypes.bool,
  /** disables the field while true */
  disabled: PropTypes.bool,
  /** disables the field while true */
  loading: PropTypes.bool,
  /** adds helper text below the form field */
  helperText: PropTypes.string,
  /** array of users that are already selected */
  initialUsers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  /** query params passed to the API */
  queryParams: PropTypes.string.isRequired,
  /** optional props passed into the display component */
  textFieldProps: PropTypes.object,
  multiple: PropTypes.bool,
};

export default FieldEditUserAutocomplete;
