// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import { LEAD_FILTERS_REQUEST, LEAD_FILTERS_SUCCESS, LEAD_FILTERS_FAILURE } from './leadFiltersActions';

export const initialStateLeadFilters = {
  loading: false,
  error: '',
  data: {
    asset_type: [],
    count: 0,
    owner: [],
    parent: [],
  },
  query: {},
};

export const leadFiltersReducer = (state = initialStateLeadFilters, action) => {
  switch (action.type) {
    case LEAD_FILTERS_REQUEST:
      return {
        ...state,
        loading: true,
        query: action.query,
      };
    case LEAD_FILTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case LEAD_FILTERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        data: {},
        query: {},
      };
    default:
      return state;
  }
};

export default leadFiltersReducer;
