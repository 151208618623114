import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { locationWithBack } from '../../../utilities/route';

const ButtonIcon = props => {
  const {
    buttonSize,
    iconSize,
    disabled,
    onClick,
    icon: Icon,
    history,
    location,
    to: pathname,
    search,
    iconclass,
    href,
    download,
    tooltip,
    color,
    ...extraProps // DOM items like aria-label, etc
  } = props;

  const iconProps = {};
  const iconButtonProps = {};
  if (iconSize === 'small') {
    // if iconSize is medium don't include the prop
    iconButtonProps.size = iconSize;
    iconProps.fontSize = iconSize;
  }
  if (iconSize === 'large') {
    iconProps.fontSize = iconSize;
  }
  if (color) {
    iconProps.color = color;
  }
  if (href) {
    iconButtonProps.href = href;
    iconButtonProps.download = download;
  } else if (onClick) {
    iconButtonProps.onClick = onClick;
  } else {
    iconButtonProps.onClick = () => {
      history.push(locationWithBack({ pathname, search }, location));
    };
  }

  const renderButton = () => (
    <IconButton disabled={disabled} size={buttonSize} {...iconButtonProps} {...extraProps}>
      <Icon className={iconclass} {...iconProps} />
    </IconButton>
  );

  if (tooltip) {
    return (
      <Tooltip title={tooltip}>
        {/*
          need to wrap in span
            see https://stackoverflow.com/questions/61115913/is-it-possible-to-render-a-tooltip-on-a-disabled-mui-button-within-a-buttongroup
        */}
        <span>{renderButton()}</span>
      </Tooltip>
    );
  } else {
    return renderButton();
  }
};

ButtonIcon.defaultProps = {
  buttonSize: 'small',
  iconSize: 'small',
  icon: EditIcon,
  disabled: false,
  onClick: undefined,
  history: {},
  location: {},
  search: undefined,
  to: '',
  iconclass: '',
  download: false,
  href: '',
  tooltip: undefined,
  color: 'inherit',
};

ButtonIcon.propTypes = {
  buttonSize: PropTypes.oneOf(['small', 'medium']),
  iconSize: PropTypes.oneOf(['small', 'medium', 'large']),
  disabled: PropTypes.bool,
  color: PropTypes.string,
  /** callback for passed functions unrelated to routing */
  onClick: PropTypes.func,
  /** icon displayed on button */
  icon: PropTypes.object,

  /** router-specific props  */
  history: PropTypes.object,
  location: PropTypes.object,
  to: PropTypes.string,
  search: PropTypes.string,
  iconclass: PropTypes.string,
  download: PropTypes.bool,
  href: PropTypes.string,
  tooltip: PropTypes.string,
};

export default ButtonIcon;
