import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Paper } from '@mui/material';

import ChecklistViewer from '../checklistViewer/ChecklistViewer';

import ChecklistToolbar from './ChecklistToolbar';
import GettingStarted from './GettingStarted';

import ChecklistForm from './ChecklistForm';
import { ChecklistContext } from './checklist-context';

import './Checklist.scss';

import { useQuery } from '../../hooks/locationHooks';

import ChecklistMap from './ChecklistMap';
import { ensureArray } from '../../utilities/arrays';
import useSelectedRevision from './useSelectedRevision';
import TaskList from '../checklistTasks/TaskList';

export const panelModes = { COMPARE: 'compare', MAP: 'map', TASKS: 'tasks' };

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
    // width: '100vw',
    textAlign: 'left',
    fontSize: theme.spacing(3),
  },
  scrollable: {
    maxHeight: '100vh',
    overflowY: 'auto',
  },
  mainContent: {
    width: '100%',
    maxWidth: '900px',
    margin: 'auto',
  },

  errorLink: {
    fontSize: theme.spacing(1),
  },
  imitateLink: {
    color: 'red',
    backgroundColor: 'transparent',
    fontSize: theme.spacing(2),
    textDecoration: 'underline',
    padding: '0',
    height: 'auto',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
}));

const Checklist = props => {
  const { isForm, parentId, readOnly, refresh, setRevisionId } = props;

  const { template } = props.checklist;
  const { sections } = template;

  const revisionsState = useSelector(state => state.revisions);
  // TODO: This should be handled in redux to always be an array, but do not want to break existing code right now
  const revisions = ensureArray(revisionsState.data);

  const [openSections, setOpenSections] = useState([]);
  const [secondaryPanelMode, setSecondaryPanelMode] = useState(panelModes.COMPARE);

  const [query, setQuery] = useQuery();

  const classes = useStyles();

  const selectedRevision = useSelectedRevision(revisions);

  /**
   * sections are stored in the query params so we can maintain state after refresh
   */
  const setSection = useCallback(
    // useCallback to prevent useEffect from running on every render
    section => {
      setQuery({ ...query, section });
    },
    [setQuery, query]
  );

  const currentSection = query.section || '';

  /**
   * refreshing page should set default to the first section
   *  - also make sure the section is in the openSections array
   */
  useEffect(() => {
    if (sections?.length > 0) {
      if (!currentSection || !sections.find(s => s.key === currentSection)) {
        setSection(sections[0].key);
      } else if (currentSection && !openSections.find(s => s === currentSection)) {
        setOpenSections([...openSections, currentSection]);
      }
    }
  }, [currentSection, setSection, sections, openSections, setOpenSections]);

  const displaySidePanel = () => {
    if (!props.checklist) {
      return null;
    }
    switch (secondaryPanelMode) {
      case panelModes.COMPARE:
        // TODO: This needs to handle viewing different versions
        return <ChecklistViewer checklist={props.checklist} selectedLine={query.line} revision={selectedRevision} />;
      case panelModes.MAP:
        return <ChecklistMap checklist={props.checklist} />;
      case panelModes.TASKS:
        return <TaskList checklist={props.checklist} projectId={isForm ? null : parentId} />;
      default:
        return null;
    }
  };

  const context = { containerType: isForm ? 'checklist' : 'project', containerId: parentId };

  return (
    <ChecklistContext.Provider value={context}>
      <div className="header">
        <ChecklistToolbar
          title={props.title}
          setRevisionId={setRevisionId}
          checklist={props.checklist}
          refresh={refresh}
          selectedRevision={selectedRevision}
          currentSection={currentSection}
          setSection={setSection}
          revisions={revisions}
          setSecondaryPanelMode={setSecondaryPanelMode}
        />
      </div>
      <Grid container columnSpacing={4}>
        <Grid item xs={8}>
          <Paper className={`${classes.root} ${classes.scrollable}`}>
            <ChecklistForm
              readOnly={readOnly}
              checklist={props.checklist}
              setRevisionId={setRevisionId}
              refresh={refresh}
              revisions={revisions}
            />
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={`${classes.root} ${classes.scrollable}`}>
            <GettingStarted checklist={props.checklist} selectedRevision={selectedRevision} />
            {displaySidePanel()}
          </Paper>
        </Grid>
      </Grid>
    </ChecklistContext.Provider>
  );
};
Checklist.propTypes = {
  title: PropTypes.object.isRequired,
  setRevisionId: PropTypes.func.isRequired,
  selectedRevisionId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string, // ('latest', 'new')
  ]),
  revisions: PropTypes.array,
  parentId: PropTypes.string.isRequired,
  checklist: PropTypes.object.isRequired,
  refresh: PropTypes.func.isRequired,
  isForm: PropTypes.bool,
  readOnly: PropTypes.bool,
  backLink: PropTypes.string,
};
Checklist.defaultProps = {
  isForm: false,
  revisions: [],
  selectedRevisionId: 'latest',
  readOnly: false,
};

export default Checklist;
