import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { requestFeatures } from '../../store/settings/featuresActions';
import { openSnackbar } from '../../store/snackbarActions';
import {
  getServerBuildId,
  setLastPopupTime,
  getLastPopupTime,
  getRefreshBool,
  reloadPage,
} from '../../utilities/app.js';
import OppositeButton from './buttons/OppositeButton';

/**
 * Component that sets up and displays the RefreshHandler
 * This allows for checking response headers
 */
const HOURLY_REFRESH_INTERVAL = 60 * 60 * 1000; // 1 hour in milliseconds

export const RefreshHandler = () => {
  const serverBuildId = getServerBuildId();
  const lastPopupTime = getLastPopupTime();
  const refresh = getRefreshBool(lastPopupTime, HOURLY_REFRESH_INTERVAL); // don't refresh more than once every sixty minutes
  const dispatch = useDispatch();
  const handleSnackbarClick = () => {
    reloadPage();
  };
  const refreshActionComponent = (
    <OppositeButton key="refresh" label="REFRESH" size="small" onClick={handleSnackbarClick} />
  );
  useEffect(() => {
    if (serverBuildId?.buildId && refresh && process.env.REACT_APP_BUILD_ID !== serverBuildId.buildId) {
      setLastPopupTime();
      dispatch(requestFeatures());
      dispatch(openSnackbar('A new version is available.', 'info', null, null, refreshActionComponent));
    }
  }, []); // eslint-disable-line

  return <></>;
};

export default RefreshHandler;
