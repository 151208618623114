// eslint-disable-next-line no-unused-vars
import { buildEndpoint, get, patch, post, del } from './util';

export const userProfileEndpoint = buildEndpoint('userProfileFetch', () => get('/api/users/me'), {
  reducerKey: 'profile',
});

/** auth user preferences  */
export const userPreferencesUpdateEndpoint = buildEndpoint(
  'userPreferencesUpdate',
  body => patch('/api/users/me/preferences/', body),
  { reducerKey: 'profile' }
);
