import React from 'react';
import PropTypes from 'prop-types';
import { Radios } from 'mui-rff';
import { Divider, FormHelperText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useField } from 'react-final-form';
import { formatNumToStringValue } from '../../../utilities/checklist';
import Markdown from '../../shared/Markdown';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  divider: {
    margin: '0',
  },
}));

const RadioLineItem = props => {
  const { name, label, options, helperText, validator } = props;
  const classes = useStyles();
  const field = useField(name);

  return (
    <div className={classes.root}>
      <Radios
        color="primary"
        name={name}
        label={label}
        data={formatNumToStringValue(options)}
        fieldProps={{ validate: validator }}
        formControlProps={{ className: 'checkbox-radio-input' }} // class defined in Checklist.scss
        variant="filled"
        onClick={e => {
          if (field.input.value === e.target.value) {
            // deselect option if clicking on selected option
            e.preventDefault(); // prevent click event triggering select
            field.input.onChange(undefined);
          }
        }}
      />
      <Divider className={classes.divider} />
      <FormHelperText>
        <Markdown>{helperText}</Markdown>
      </FormHelperText>
    </div>
  );
};

RadioLineItem.defaultProps = {
  required: false,
  name: '',
  label: '',
  value: undefined,
  options: [],
  helperText: '',
  validator: () => {},
};

RadioLineItem.propTypes = {
  required: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array,
  helperText: PropTypes.string,
  validator: PropTypes.func,
};

export default RadioLineItem;
