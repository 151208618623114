import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';

import { getBulkImports } from '../../store/features/bulkImportsActions';
import { fieldOrder, removeField, hideField } from './bulkImportsShared';
import { apiDateToString, jsonKeyToLabel } from '../../utilities/strings';
import CommonFormView from '../shared/form/CommonView';

const BulkImportsView = () => {
  const id = useParams().id;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBulkImports(id));
  }, [dispatch, id]);

  const { data, loading, error } = useSelector(state => state.bulkImports.each);

  // auto reload
  useEffect(() => {
    if (data && data.state === 'PENDING') {
      const timer = setTimeout(() => dispatch(getBulkImports(id)), 5000);
      return () => clearTimeout(timer);
    }
    return null;
  });

  const formSettings = {
    fieldOrder,
    removeField: removeField('VIEW'),
    hideField,
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      // cast to string because booleans do not show up in the field
      let displayLabel = jsonKeyToLabel(key); // eslint-disable-line prefer-const
      let displayValue = '';
      if (data[key] != null) {
        switch (key) {
          // add cases for each key that needs to be customized.
          case 'upload':
            fieldSettings[key] = {
              type: 'component',
              cellProps: {
                xs: 6,
              },
              fieldProps: {
                label: '',
                value: () => (
                  <IconButton
                    aria-label="download file"
                    href={`${data[key]}&response-content-disposition=attachment`}
                    download
                    size="large">
                    <GetAppIcon />
                  </IconButton>
                ),
              },
            };
            return;

          case 'uploaded_by':
            displayValue = data[key].name;
            break;
          case 'updated_on':
          case 'created_on':
          case 'completed_on':
            displayValue = apiDateToString(data[key]);
            break;
          case 'errors':
            fieldSettings[key] = {
              type: 'table',
              fieldProps: {
                label: displayLabel,
                colOrder: ['message'],
                data: data.errors
                  ? data.errors.map(row => {
                      return { message: row };
                    })
                  : [],
              },
            };
            return;
          case 'warnings':
            fieldSettings[key] = {
              type: 'table',
              fieldProps: {
                label: displayLabel,
                colOrder: ['message'],
                data: data.warnings
                  ? data.warnings.map(row => {
                      return { message: row };
                    })
                  : [],
              },
            };
            return;

          default:
            displayValue = data[key].toString();
        }
      }
      fieldSettings[key] = {
        type: 'text',
        fieldProps: {
          label: displayLabel,
          value: displayValue,
        },
      };
    });
  }
  return (
    <CommonFormView
      title={'Import Details'}
      values={data}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      readOnly
      loading={loading}
      error={error}
    />
  );
};

export default BulkImportsView;
