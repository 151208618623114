import { requestFactory, responseHandlerForm } from '../base';

const get = requestFactory('get');
const post = requestFactory('post', { handler: responseHandlerForm });
const patch = requestFactory('patch', { handler: responseHandlerForm });
const del = requestFactory('del');

export const fetchLocationLayerProfilesList = async query => get('/api/location-layer-profiles/', query);
export const fetchLocationLayerProfiles = async id => get(`/api/location-layer-profiles/${id}/`);
export const createLocationLayerProfiles = async body => post('/api/location-layer-profiles/', body);
export const updateLocationLayerProfiles = async (id, body) => patch(`/api/location-layer-profiles/${id}/`, body);
export const deleteLocationLayerProfiles = async id => del(`/api/location-layer-profiles/${id}/`);
