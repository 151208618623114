import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import { StyledEngineProvider, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { setDarkMode } from '../store/settings/settingsActions';
import { selectDarkMode } from '../store/settings/settingsReducer';
import setTheme from '../theme/theme.js';

const ThemeProvider = props => {
  const { children } = props;
  const dispatch = useDispatch();
  const userDarkMode = useSelector(state => selectDarkMode(state));
  // If the user dark mode is not set, use the system dark mode setting
  let darkMode = useMediaQuery('(prefers-color-scheme: dark)');
  // if darkmode is undefined by us, use the system setting and then set the redux store to match
  // otherwise just use dark mode setting from redux
  if (userDarkMode !== -1) {
    darkMode = userDarkMode;
  } else {
    dispatch(setDarkMode(darkMode));
  }
  const theme = setTheme(darkMode);
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </StyledEngineProvider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default ThemeProvider;
