import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Chip } from '@mui/material';
import { capitalizeFirstChar } from '../../utilities/strings';

const getDisplay = (key, value) => {
  if (value === null && key.toLowerCase() === 'observation') {
    return { name: capitalizeFirstChar(key), display: 'Other' };
  }
  if (key.toLowerCase() === 'results_template_mismatch') {
    console.error('Results Template Mismatch: see admin for details:' + value);
    return { name: 'Error', display: 'Template Mismatch' };
  }

  if (typeof value === 'string') {
    return { name: capitalizeFirstChar(key), display: capitalizeFirstChar(value) };
  }

  return { name: capitalizeFirstChar(key), display: value };
};

const makeItems = flags => {
  if (flags === undefined) {
    return [];
  }

  const items = [];
  for (const [key, value] of Object.entries(flags)) {
    const { name, display } = getDisplay(key, value);
    items.push({
      key,
      name: name,
      value: display,
    });
  }

  return items;
};

const ChecklistFlagsColumn = props => {
  const { flags, placeholder } = props;

  const items = makeItems(flags);
  return (
    <>
      <Grid container>
        {items.length ? (
          items.map((item, index) => (
            <Grid item key={`${item.key}-${index}`}>
              <Chip size="small" variant="outlined" label={`${item.name}: ${item.value}`} />
            </Grid>
          ))
        ) : (
          <Grid item>
            <Chip size="small" variant="outlined" label={placeholder} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

ChecklistFlagsColumn.defaultProps = {
  flags: null,
  placeholder: '--',
};

ChecklistFlagsColumn.propTypes = {
  flags: PropTypes.object,
  placeholder: PropTypes.string,
};

export default ChecklistFlagsColumn;
