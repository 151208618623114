import React from 'react';
import PropTypes from 'prop-types';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { jsonKeyToLabel } from '../../../utilities/strings';

/**
 * Styles a form field when displaying a table<br>
 * It uses the api key as the label for the table<br>
 * It uses the keys in the child object for the col headers
 */

const useStyles = makeStyles(theme => ({
  title: {
    margin: theme.spacing(1, 0),
    fontSize: theme.spacing(3),
  },
  tableBody: {
    fontSize: 14,
  },
}));

const FieldDisplayTable = props => {
  const { label, colOrder, data, title, showHeaderCol } = props;
  const classes = useStyles();
  return (
    <>
      {label ? <div className={title ? `${classes.title} h7` : 'h7'}>{label}</div> : <></>}
      <TableContainer key={`${colOrder[0]}-table`} component={Paper}>
        <Table aria-label="simple table">
          {showHeaderCol && (
            <TableHead>
              <TableRow>
                {colOrder.map(col => (
                  <TableCell padding={col === 'selected' ? 'checkbox' : 'none'} key={col}>
                    {col !== 'selected' ? jsonKeyToLabel(col) : ''}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {data.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {colOrder.map((col, colIndex) => (
                  <TableCell
                    className={classes.tableBody}
                    padding={col === 'selected' ? 'checkbox' : 'none'}
                    key={`${rowIndex}-${colIndex}`}>
                    {row[col]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

FieldDisplayTable.defaultProps = {
  label: '',
  colOrder: [],
  title: false,
  showHeaderCol: true,
};

FieldDisplayTable.propTypes = {
  label: PropTypes.string,
  /** data from the feature api to fill in the table */
  data: PropTypes.array.isRequired,
  /** change the column order */
  colOrder: PropTypes.array,
  title: PropTypes.bool,
  showHeaderCol: PropTypes.bool,
};

export default FieldDisplayTable;
