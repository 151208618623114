// eslint-disable-next-line no-unused-vars
import { buildEndpoint, get, patch, post, del } from './util';

export const EACH_REDUCER = 'projectsEach';

/**
 * https://docs.huvrdata.app/reference/api_projects_bulk_share
 */
export const projectsBulkShareEndpoint = buildEndpoint('projectsBulkShare', data =>
  post('/api/projects/bulk-share/', data)
);

export const projectsScopeUpdateEndpoint = buildEndpoint(
  'projectsScopeUpdate',
  (id, body) => patch(`/api/projects/${id}/scope/`, body),
  { reducerKey: EACH_REDUCER }
);
