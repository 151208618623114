// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
export const AUDIT_REQUEST = 'AUDIT_REQUEST';
export const AUDIT_SUCCESS = 'AUDIT_SUCCESS';
export const AUDIT_FAILURE = 'AUDIT_FAILURE';

export const getAudit = (query = {}) => {
  return {
    type: AUDIT_REQUEST,
    query,
  };
};
