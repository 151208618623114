import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/CancelRounded';

/**
 * Pills for showing selected values.
 */

// add themeing to button - use correct color, use capitalization
const useStyles = makeStyles(theme => {
  return {
    root: {
      margin: theme.spacing(0.5),
      textTransform: 'none',
      fontWeight: '400',
    },
  };
});
const Pill = props => {
  const { label, remove, disabled } = props;
  const classes = useStyles();

  const handleClick = event => {
    event.preventDefault();
    remove();
  };
  return (
    <Button
      onClick={handleClick}
      size="small"
      color="secondary"
      variant="contained"
      disabled={disabled}
      style={{ borderRadius: 25 }}
      className={classes.root}
      endIcon={<CancelIcon color="action" />}>
      {label}
    </Button>
  );
};

Pill.defaultProps = {
  disabled: false,
};

Pill.propTypes = {
  /** label for the Pill */
  label: PropTypes.string.isRequired,
  /** callback for when the x is clicked */
  remove: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default Pill;
