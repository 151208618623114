import React from 'react';
import { Switch } from 'react-router-dom';
import FeatureRoute from './FeatureRoute';
import BasicLayout from './layouts/BasicLayout';
import SimpleLayout from './layouts/SimpleLayout';
import MainLayout from './layouts/MainLayout';
import FeatureSubRoutes from './featureRoutes/FeatureSubRoutes';
import Login from '../components/login/Login';
import LoginLoadingMobile from '../components/login/LoginLoadingMobile';
import Analytics from '../components/analytics/Analytics';
import Dashboard from '../components/dashboard/Dashboard';
import Lead from '../components/analytics/lead/Lead';
import ObservationAnalytics from '../components/analytics/observationAnalytics/ObservationAnalytics';
import ProjectChecklist from '../components/checklistEditor/ProjectChecklist';
import ProjectChecklist2 from '../components/checklistEditorV2/ProjectChecklist';
import FindingsProjectWizard from '../components/defects/FindingsProjectWizard';
import ProjectInspector from '../components/visualInspector/ProjectInspector';
import PreviewChecklist from '../components/checklistEditor/PreviewChecklist';
import ChecklistViewerPage from '../components/checklistViewer/ChecklistViewerPage';
import History from '../components/history/History';
import Support from '../components/support/Support';
import Workspace from '../components/workspace/Workspace';
import UserProfile from '../components/profile/UserProfile';
import ActivityLog from '../components/workspace/ActivityLog';
import NotificationPreferences from '../components/notificationPreferences/NotificationPreferences';
import NotFound from './NotFound';
import ScheduleEventsTablePage from '../components/scheduleEvents/ScheduleEventsTablePage';
import DynamicPage from '../components/shared/dynamicPage/DynamicPage';
// import MeasurementsForm from '../components/measurements/MeasurementsForm';

import { useFeatureFlags, usePermissions } from '../hooks/settingsHooks';
import BulkUsersForm from '../components/users/BulkUsersForm';
import ChecklistTasksTablePage from '../components/checklistTasks/ChecklistTasksTablePage';
import ChecklistTemplateBuilderPage from '../components/checklistTemplates/checklistTemplateBuilder/ChecklistTemplateBuilderPage';

/**
 * Defines all the routes<br>
 * Route components must be directly below the Switch component<br>
 * A Route component can have a nested Switch component<br>
 * The path prop on Route can be an array of routes<br>
 * */

const Routes = () => {
  const { hasDynamicPages, hasChecklistTasksPage, hasChecklistEditorBeta } = useFeatureFlags();
  const { hasChecklistEdit } = usePermissions();
  const showTasks = hasChecklistTasksPage && hasChecklistEdit;
  return (
    <Switch>
      <FeatureRoute
        exact
        permissions="public"
        path={'/login'}
        pageTitle="Login"
        layout={BasicLayout}
        component={Login}
      />
      <FeatureRoute
        exact
        permissions="public"
        path={'/f/login'}
        pageTitle="Login"
        layout={BasicLayout}
        component={Login}
      />
      <FeatureRoute
        exact
        path={'/login-loading-mobile'}
        pageTitle="LoginLoadingMobile"
        component={LoginLoadingMobile}
      />
      <FeatureRoute exact path={'/analytics/lead'} pageTitle="LEAD" layout={MainLayout} component={Lead} />
      <FeatureRoute
        exact
        path={'/analytics/observations'}
        pageTitle="Observation Analytics"
        layout={MainLayout}
        component={ObservationAnalytics}
      />
      <FeatureRoute
        path={['/analytics/:tab', '/analytics']}
        pageTitle="Analytics"
        layout={MainLayout}
        component={Analytics}
      />
      <FeatureRoute path={'/workspace/activity'} pageTitle="Workspace" layout={MainLayout} component={ActivityLog} />
      <FeatureRoute
        path={['/workspace/usage/:tab', '/workspace']}
        pageTitle="Workspace"
        layout={MainLayout}
        component={Workspace}
      />
      {hasChecklistEditorBeta && (
        <FeatureRoute
          path={'/projects/:projectId/checklists/:id'}
          pageTitle="Checklist"
          layout={SimpleLayout}
          component={ProjectChecklist2}
        />
      )}
      {!hasChecklistEditorBeta && (
        <FeatureRoute
          path={'/projects/:projectId/checklists/:id'}
          pageTitle="Checklist"
          layout={SimpleLayout}
          component={ProjectChecklist}
        />
      )}
      <FeatureRoute
        path={'/findings/add-to-project/:findings?'}
        pageTitle="New Project Wizard"
        layout={props => <SimpleLayout {...props} customBack />}
        component={FindingsProjectWizard}
      />
      <FeatureRoute
        path={'/users/bulk-new'}
        pageTitle="Bulk New Users"
        layout={props => <SimpleLayout {...props} customBack />}
        component={BulkUsersForm}
      />
      <FeatureRoute
        path={'/projects/:projectId/inspect/:mediaId?'}
        pageTitle="Visual Inspector"
        layout={BasicLayout}
        component={ProjectInspector}
      />
      <FeatureRoute
        path={'/checklist-preview/:id'}
        pageTitle="Checklist Preview"
        layout={SimpleLayout}
        component={PreviewChecklist}
      />
      <FeatureRoute
        path={'/checklist-viewer/:id'}
        pageTitle="Checklist Viewer"
        layout={SimpleLayout}
        component={ChecklistViewerPage}
      />
      <FeatureRoute
        path={'/checklist-template-builder/:id'}
        pageTitle="Checklist Template Builder"
        layout={SimpleLayout}
        component={ChecklistTemplateBuilderPage}
      />

      {FeatureSubRoutes()}
      <FeatureRoute path={'/history'} pageTitle="history" layout={MainLayout} component={History} />
      <FeatureRoute
        path={['/profile/:tab', '/profile']}
        pageTitle="Profile"
        layout={MainLayout}
        component={UserProfile}
      />
      <FeatureRoute
        path={'/notification-preferences'}
        pageTitle="Profile"
        layout={MainLayout}
        component={NotificationPreferences}
      />
      <FeatureRoute
        path={'/schedule-events'}
        pageTitle="Events"
        layout={MainLayout}
        component={ScheduleEventsTablePage}
      />
      {showTasks && (
        <FeatureRoute
          path={'/checklist-tasks'}
          pageTitle="Tasks"
          layout={MainLayout}
          component={ChecklistTasksTablePage}
        />
      )}
      <FeatureRoute path={'/support'} pageTitle="Support" layout={MainLayout} component={Support} />
      <FeatureRoute exact path={'/'} pageTitle="Dashboard" layout={MainLayout}>
        <Dashboard />
      </FeatureRoute>
      {hasDynamicPages && (
        <FeatureRoute path={'/*'} pageTitle="Dynamic Page" deferLayout>
          <DynamicPage />
        </FeatureRoute>
      )}
      <FeatureRoute path={'/*'}>
        <NotFound />
      </FeatureRoute>
    </Switch>
  );
};

export default Routes;
