// lib
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Paper, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

// components
import InspectorMap from '../visualInspector/InspectorMap';
import InspectionMediaView from '../inspectionMedia/InspectionMediaView';

const useStyles = makeStyles(theme => ({
  projectMap: {
    margin: theme.spacing(1, 4, 3),
    padding: theme.spacing(1),
  },
  infoBox: {
    height: '390px',
    overflow: 'scroll',
  },
  media: {
    width: '100%',
    objectFit: 'contain',
    height: '300px',
  },
}));

const ProjectMap = props => {
  const { project } = props;
  const classes = useStyles();

  const { data } = useSelector(state => {
    const data = state.inspectionMedia.all.dataAll.results;
    const count = state.inspectionMedia.all.dataAll.count || null;
    return {
      data,
      count,
    };
  });

  const [selectedMedia, setSelectedMedia] = useState(null);

  const mediaWithGPS = useMemo(() => {
    return data.reduce((mediaWithGPS, m) => {
      if (m.geo_point?.coordinates) {
        mediaWithGPS.push({
          id: m.id,
          geo_point: m.geo_point,
          exif: m.file_meta?.exif,
        });
      }
      return mediaWithGPS;
    }, []);
  }, [data]);

  const selectMedia = mediaId => {
    const media = data.find(m => m.id === mediaId);
    setSelectedMedia(media);
  };

  return (
    <Grid container>
      <Grid item xs={9}>
        <Paper className={classes.projectMap}>
          <Grid container>
            <InspectorMap
              mediaWithGPS={mediaWithGPS}
              onMediaCircleClick={selectMedia}
              activeMedia={selectedMedia}
              project={project}
              mapContainerStyle={{
                height: '60vh',
                width: '95%',
                margin: 'auto',
                borderRadius: '3px',
              }}
            />
          </Grid>
        </Paper>
      </Grid>
      {selectedMedia && (
        <Grid container item xs={3} direction="column" spacing={2}>
          <Grid item className={classes.imageBox}>
            <Paper>
              <img src={selectedMedia.thumbnail} className={classes.media} />
            </Paper>
          </Grid>
          <Grid item className={classes.infoBox}>
            <InspectionMediaView data={selectedMedia} />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

ProjectMap.defaultProps = {
  project: undefined,
};

ProjectMap.propTypes = {
  project: PropTypes.object,
};

export default ProjectMap;
