// eslint-disable-next-line no-unused-vars
import { buildEndpoint, get, patch, post, del } from './util';

// /**
//  * Backwards compatibility - combining Read, Create, Update, and Delete to share state
//  */
// export const EACH_REDUCER = 'checklistTemplatesFillableEach';

/**
 * https://docs.huvrdata.app/reference/api_checklist-templates-fillable_list
 */
export const checklistTemplatesFillableListEndpoint = buildEndpoint(
  'checklistTemplatesFillableList',
  query => get('/api/checklist-templates-fillable/', query),
  { batchFetchAll: true, defaultData: { results: [] } }
);

/**
 * https://docs.huvrdata.app/reference/api_checklist-templates-fillable_read
 */
export const checklistTemplatesFillableReadEndpoint = buildEndpoint(
  'checklistTemplatesFillableRead',
  id => get(`/api/checklist-templates-fillable/${id}/`),
  { defaultData: { id: -1 } }
);

// /**
//  * https://docs.huvrdata.app/reference/api_checklist-templates_list
//  */
export const checklistTemplatesBatchListEndpoint = buildEndpoint(
  'checklistTemplatesList',
  query => get('/api/checklist-templates/', query),
  { batchFetchAll: true }
);

// /**
//  * https://docs.huvrdata.app/reference/api_checklist-templates_create
//  */
// export const checklistTemplatesCreateEndpoint = buildEndpoint(
//     'checklistTemplatesCreate',
//     data => post('/api/checklist-templates/', data),
//     {reducerKey: EACH_REDUCER},
// );

// /**
//  * https://docs.huvrdata.app/reference/api_checklist-templates_import_template
//  */
// export const checklistTemplatesImportTemplateEndpoint = buildEndpoint(
//     'checklistTemplatesImportTemplate',
//     () => post('/api/checklist-templates/import-template/'),
//     {},
// );

// /**
//  * https://docs.huvrdata.app/reference/api_checklist-templates_read
//  */
// export const checklistTemplatesReadEndpoint = buildEndpoint(
//     'checklistTemplatesRead',
//     id => get(`/api/checklist-templates/${id}/`),
//     {reducerKey: EACH_REDUCER},
// );

// /**
//  * https://docs.huvrdata.app/reference/api_checklist-templates_partial_update
//  */
// export const checklistTemplatesPartialUpdateEndpoint = buildEndpoint(
//     'checklistTemplatesPartialUpdate',
//     (id, data) => patch(`/api/checklist-templates/${id}/`, data),
//     {reducerKey: EACH_REDUCER},
// );

// /**
//  * https://docs.huvrdata.app/reference/api_checklist-templates_delete
//  */
// export const checklistTemplatesDeleteEndpoint = buildEndpoint(
//     'checklistTemplatesDelete',
//     id => del(`/api/checklist-templates/${id}/`),
//     {reducerKey: EACH_REDUCER},
// );

// /**
//  * https://docs.huvrdata.app/reference/api_checklist-templates_export_template
//  */
// export const checklistTemplatesExportTemplateEndpoint = buildEndpoint(
//     'checklistTemplatesExportTemplate',
//     () => get('/api/checklist-templates/{id}/export-template/'),
//     {},
// );
