import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ButtonGroup, FormControl, ListItemText, Checkbox, Select, MenuItem, InputLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Table, { SELECTABLE_ROWS_NONE } from '../shared/table/Table';
import { getAllReservation, deleteReservation } from '../../store/features/reservationsActions';
import {
  setReservationsTableView,
  setReservationsTableSize,
} from '../../store/settings/views/reservationsTableViewRedux';
import { buildRoute, apiDateToString } from '../../utilities/strings';
import ButtonIcon from '../shared/buttons/ButtonIcon';
import StyledLink from '../shared/StyledLink';
import { setColumns } from '../shared/table/columns';
import UserDisplay from '../shared/displays/UserDisplay';
import { openDialog } from '../../store/dialogActions';
import DeleteDialog from '../shared/Dialog/DeleteDialog';
import { supportMsg } from './reservationsShared';
import { getCustomCSVData, tableViews } from '../../utilities/tables';
import { useTableViews } from '../../hooks/settingsHooks';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  helperText: {
    textAlign: 'left',
    padding: theme.spacing(0, 2),
  },
}));

const ReservationsTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [deleteCalled, setDeleteCalled] = useState(false);
  const { loading, deleting, data } = useSelector(state => ({
    loading: state.reservations.all.loading,
    data: state.reservations.all.dataAll.results,
  }));
  const { reservationsTableViewKey } = tableViews;
  const views = useTableViews(reservationsTableViewKey);
  useEffect(() => {
    dispatch(getAllReservation());
  }, []); // eslint-disable-line
  useEffect(() => {
    if (deleteCalled && !deleting) {
      dispatch(getAllReservation());
      setDeleteCalled(false);
    }
  }, [deleteCalled, deleting, dispatch]);

  const classes = useStyles();

  /** @type {Column[]} */
  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return <StyledLink to={`/reservations/${value}`} value={value.toString()} />;
        },
      },
    },
    {
      name: 'requestor',
      label: 'Requestor',
      options: {
        filter: true,
        sort: true,
        display: 'true',
        filterType: 'custom',
        downloadBody: value => getCustomCSVData('simple', value),
        customBodyRender: value => {
          if (!value) return <></>;
          return <UserDisplay {...value} />;
        },
        filterOptions: {
          logic: (requestor, filters) => {
            if (filters.length) return !filters.includes(requestor.name);
            return false;
          },
          display: (filterList, onChange, index, column) => {
            const optionValues = data
              ? [...new Set(data.map(value => (value.requestor ? value.requestor.name : '')))]
              : ['All'];
            const allChecked = !filterList[index].length;
            return (
              <FormControl>
                <InputLabel shrink>Requestor</InputLabel>
                <Select
                  value={filterList[index]}
                  multiple
                  disablePortal
                  displayEmpty
                  renderValue={selected => {
                    if (selected.length === 0) {
                      return ['All'];
                    }
                    return selected.join(', ');
                  }}
                  onChange={event => {
                    if (event.target.value[event.target.value.length - 1] === 'All') {
                      filterList[index] = [];
                    } else {
                      filterList[index] = event.target.value.filter(item => item !== 'All');
                    }
                    onChange(filterList[index], index, column);
                  }}>
                  <MenuItem key="All" value="All">
                    <Checkbox color="primary" checked={allChecked} />
                    <ListItemText primary="All" />
                  </MenuItem>
                  {optionValues.map(item => (
                    <MenuItem key={item} value={item}>
                      <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          },
        },
      },
    },
    {
      name: 'phone_number',
      label: 'Phone Number',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'business_line',
      label: 'Business Line',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'work_site',
      label: 'Work Site',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'work_scope',
      label: 'Work Scope',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'work_provider',
      label: 'Work Provider',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'asset_type',
      label: 'Asset Type',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'asset_number',
      label: 'Asset Number',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'special_instructions',
      label: 'Special Instructions',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            maxWidth: '30vw',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }),
      },
    },
    {
      name: 'state',
      label: 'State',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'assigned_to',
      label: 'Assignee',
      options: {
        filter: true,
        sort: true,
        display: 'true',
        filterType: 'custom',
        downloadBody: value => getCustomCSVData('simple', value),
        customBodyRender: value => {
          if (!value) return <></>;
          return <UserDisplay {...value} />;
        },
        filterOptions: {
          /* eslint-disable camelcase */
          logic: (assigned_to, filters) => {
            if (filters.length && assigned_to) return !filters.includes(assigned_to.name);
            return false;
          },
          /* eslint-enable */
          display: (filterList, onChange, index, column) => {
            const optionValues = data
              ? [...new Set(data.map(value => (value.assigned_to ? value.assigned_to.name : 'No assignee')))]
              : ['All'];
            const allChecked = !filterList[index].length;
            return (
              <FormControl>
                <InputLabel shrink>Assignee</InputLabel>
                <Select
                  value={filterList[index]}
                  multiple
                  displayEmpty
                  disablePortal
                  renderValue={selected => {
                    if (selected.length === 0) {
                      return ['All'];
                    }
                    return selected.join(', ');
                  }}
                  onChange={event => {
                    if (event.target.value[event.target.value.length - 1] === 'All') {
                      filterList[index] = [];
                    } else {
                      filterList[index] = event.target.value.filter(item => item !== 'All');
                    }
                    onChange(filterList[index], index, column);
                  }}>
                  <MenuItem key="All" value="All">
                    <Checkbox color="primary" checked={allChecked} />
                    <ListItemText primary="All" />
                  </MenuItem>
                  {optionValues.map(item => (
                    <MenuItem key={item} value={item}>
                      <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          },
        },
      },
    },
    {
      name: 'created_on',
      label: 'Created',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => apiDateToString(value, 'date'),
      },
    },
    {
      name: 'updated_on',
      label: 'Updated',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => apiDateToString(value, 'date'),
      },
    },
    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        print: false,
        viewColumns: false,
        customBodyRender: (_, info) => {
          const id = info.rowData[columns.findIndexByName['id']];
          // const name = info.rowData[columns.findIndexByName['name']];  // reservations don't have a name, so passing id to the delete dialog
          return (
            <>
              <ButtonGroup>
                <ButtonIcon history={history} icon={EditIcon} location={location} to={`/reservations/${id}/edit`} />
                <ButtonIcon
                  icon={DeleteIcon}
                  onClick={() => {
                    dispatch(
                      openDialog(
                        'Delete Reservation?',
                        <DeleteDialog id={id} deleteAction={deleteReservation} name={id} />
                      )
                    );
                    setDeleteCalled(true);
                  }}
                />
              </ButtonGroup>
            </>
          );
        },
      },
    },
  ];

  // handle columns display
  setColumns(columns, views);

  /** @type {TableOptions} */
  const options = {
    // if the column view changes, update redux with either 'add' or 'remove'
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setReservationsTableView(changedColumn, action));
    },
    onChangeRowsPerPage: numberOfRows => {
      dispatch(setReservationsTableSize(numberOfRows));
    },
    rowsPerPage: views.pageSize,
    enableNestedDataAccess: '.',
    selectableRows: SELECTABLE_ROWS_NONE,
  };

  return (
    <div className={classes.root}>
      <Table
        title="Reservations"
        simpleSearch
        columns={columns}
        addRoute={buildRoute('/reservations/new')}
        data={data}
        options={options}
        loading={loading}
      />
      <div className={classes.helperText}>{supportMsg}</div>
    </div>
  );
};

export default ReservationsTable;
