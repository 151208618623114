import React, { useState, useEffect } from 'react';
import DateRangeFilter from '../../components/shared/table/DateRangeFilter';
import { apiDateToString } from '../../utilities/strings';

/**
 * useDateRangeColumnHook hook creates a custom Date Range Column for a table
 * @param {string} name - The name of the column
 * @param {string} label - The label for the column
 * @param {Object} queryFilters - The query filters from the URL
 * @param {string} queryStartKey - The start key for the date range
 * @param {string} queryEndKey - The end key for the date range
 * @param {boolean} isDate - Whether we should use a date or datetime in the request to the backend filter
 * @param {boolean} renderApiDateTime - Whether to render the date in API format
 * @returns {Object} - The column configuration object
 */

const defaultFilterValue = [undefined, undefined];

const useDateRangeColumnHook = ({
  name,
  label,
  queryFilters,
  queryStartKey,
  queryEndKey,
  isDate = false,
  renderApiDateTime = false,
  ...options
}) => {
  const getInitialFilterList = () => {
    const startFilter = queryFilters?.[queryStartKey];
    const endFilter = queryFilters?.[queryEndKey];

    return [startFilter, endFilter];
  };

  const [filterList, setFilterList] = useState(getInitialFilterList);

  const dateRenderFormat = renderApiDateTime ? 'api-datetime' : 'date';

  useEffect(() => {
    const shouldResetFilterList = () => {
      const noFiltersSet = !filterList[0] && !filterList[1];
      const startOrEndFilterExists = queryFilters?.[queryStartKey] || queryFilters?.[queryEndKey];

      return noFiltersSet && startOrEndFilterExists;
    };

    if (shouldResetFilterList()) {
      setFilterList(defaultFilterValue);
    }
  }, [filterList, queryFilters, queryStartKey, queryEndKey]);

  const customFilterListOptions = {
    render: v => {
      const dateRange = (v || []).map(item => (item ? apiDateToString(item, 'date') : ''));
      if (dateRange[0] && dateRange[1]) {
        return `${label} Between ${dateRange[0]} - ${dateRange[1]}`;
      } else if (dateRange[0]) {
        return `${label} Start Date ${dateRange[0]}`;
      } else if (dateRange[1]) {
        return `${label} End Date ${dateRange[1]}`;
      } else {
        console.warn(`${label} not set for row`, v);
        return '';
      }
    },
    update: (filterList, filterPos, index) => {
      filterList[index] = [undefined, undefined];
      setFilterList(filterList[index]);
      return filterList;
    },
  };

  const filterOptions = {
    display: (filterList, onChange, index, column) => {
      return (
        <DateRangeFilter
          title={label}
          filter={filterList[index]}
          onChange={onChange}
          index={index}
          isDate={isDate}
          column={column}
          update={setFilterList}
        />
      );
    },
  };

  // allows conditional filtering for a dateRange column
  if (options.filter === false) {
    return {
      name,
      label,
      options,
    };
  }

  return {
    name,
    label,
    filterType: 'dateRange',
    options: {
      filter: true,
      filterField: name,
      filterList,
      sort: true,
      filterType: 'custom',
      type: 'date',
      customBodyRender: value => apiDateToString(value, dateRenderFormat),
      customFilterListOptions,
      filterOptions,
      ...options,
    },
  };
};

export default useDateRangeColumnHook;
