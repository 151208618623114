import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import { closeDialog } from '../../../store/dialogActions';

const useStyles = makeStyles(theme => ({
  buttons: {
    padding: theme.spacing(1, 0),
  },
  listContainer: {
    textAlign: 'left',
  },
  list: {
    listStyleType: 'none',
    '& li': {
      margin: theme.spacing(1),
    },
  },
}));

const DeleteDialog = props => {
  const { deleteAction, id, name, query, routeTo, confirm, displaySnackbar, onDelete } = props;

  const [deleting, setDeleting] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();
  const handleCloseDialog = () => {
    dispatch(closeDialog());
    setDeleting(false);
  };

  const renderItemsToDelete = () => {
    if (Array.isArray(name)) {
      return (
        <ul className={classes.list}>
          {name.map((n, i) => (
            <p variant="subtitle1" key={i}>
              &bull; {n}
            </p>
          ))}
        </ul>
      );
    }
    return name || 'this item';
  };

  const handleDeleteConfirm = () => {
    setDeleting(true);
    /** if onDelete is passed, use it instead of deleteAction dispatch **/
    if (onDelete && typeof onDelete === 'function') {
      onDelete();
    } else {
      const action = deleteAction(id, query, routeTo, { displaySnackbar });
      // check if deleteAction returned an action object
      //  and needs to be dispatched
      if (typeof action === 'object' && typeof action.type === 'string') {
        dispatch(action);
      }
    }
    setTimeout(() => {
      handleCloseDialog();
    }, 1500);
    confirm(true);
  };
  return (
    <Grid container direction="column">
      <Grid item className={classes.listContainer}>
        <p>{`Are you sure you want to delete the following item(s)?`}</p>
        {renderItemsToDelete()}
      </Grid>
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          className={classes.buttons}>
          <Grid item xs={2}>
            <SecondaryButton disabled={deleting} label="Cancel" onClick={handleCloseDialog} />
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}>
            <PrimaryButton
              icon={true}
              Icon={DeleteIcon}
              loading={deleting}
              onClick={handleDeleteConfirm}
              label="Confirm"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

DeleteDialog.defaultProps = {
  query: {},
  routeTo: '/',
  name: '',
  confirm: () => {},
  displaySnackbar: false,
  deleteAction: undefined,
  onDelete: undefined,
};

DeleteDialog.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.objectOf(PropTypes.arrayOf(PropTypes.number))]).isRequired,
  deleteAction: PropTypes.func,
  // overrides deleteAction prop as the handler
  onDelete: PropTypes.func,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  query: PropTypes.string,
  routeTo: PropTypes.string,
  confirm: PropTypes.func,
  displaySnackbar: PropTypes.bool,
};

export default DeleteDialog;
