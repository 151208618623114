import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { getAssets } from '../../store/features/assetsActions';
import { openSnackbar } from '../../store/snackbarActions';

import AssetsView from './AssetsView';
import AssetMap from './AssetMap';

export default function AssetTreeDetail({ id, mode }) {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(state => state.assets.each);

  useEffect(() => {
    dispatch(getAssets(id));
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (mode === 'MAP' && String(data?.id) === String(id) && !data?.geo_point) {
      dispatch(openSnackbar('No asset location data - map display not available', 'info'));
    }
  }, [mode, data?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (mode === 'MAP' && data?.geo_point) {
    return (
      <Grid container direction="row" alignItems="stretch" style={{ height: '70vh' }}>
        <Grid item xs={12}>
          <AssetMap id={String(id)} location={data.geo_point} />
        </Grid>
      </Grid>
    );
  }
  return <AssetsView id={id} data={data} loading={loading} error={error} />;
}
AssetTreeDetail.defaultProps = {
  mode: 'VIEW',
};

AssetTreeDetail.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  mode: PropTypes.string,
};
