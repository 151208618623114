import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText } from '@mui/material';
import { Field } from 'react-final-form';
import { showError } from './Util';
import Dropzone from './Dropzone';
import DropzoneParseFile from './DropzoneParseFile';

export function File(props) {
  const { name, type, fieldProps, ...rest } = props;
  return (
    <Field
      name={name}
      type="file"
      render={({ input, meta }) => <FileWrapper input={input} meta={meta} name={name} type={type} {...rest} />}
      {...fieldProps}
    />
  );
}

File.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  fieldProps: PropTypes.object,
};

export function FileWrapper(props) {
  const { input, meta, required, helperText, accept, uploadText, parseFile, ...rest } = props;
  const isError = showError({ meta });
  return (
    <FormControl required={required} error={isError} fullWidth={true}>
      <Field
        name={input.name}
        render={({ input /*: { name, value, onChange, checked, ...restInput } */ }) => {
          // prevents an error that happens if you don't have initialValues defined in advance
          // added the ability to pass onChange function to select and get the currently selected value
          // outside the Field component.
          return (
            <>
              {parseFile ? (
                <DropzoneParseFile accept={accept} uploadText={uploadText} {...input} {...rest} />
              ) : (
                <Dropzone accept={accept} uploadText={uploadText} {...input} {...rest} />
              )}
              <FormHelperText variant="outlined"> {helperText}</FormHelperText>
            </>
          );
        }}
      />
    </FormControl>
  );
}
FileWrapper.defaultProps = {
  input: 'file',
  required: false,
  fullWidth: true,
  helperText: '',
  accept: '',
  uploadText: 'Click to upload file.',
  parseFile: false,
};

FileWrapper.propTypes = {
  input: PropTypes.string.isRequired,
  meta: PropTypes.object,
  required: PropTypes.boolean,
  fullWidth: PropTypes.function,
  helperText: PropTypes.string,
  accept: PropTypes.string,
  uploadText: PropTypes.string,
  parseFile: PropTypes.boolean,
};

export default File;
