import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Select,
  MenuItem,
  Grid,
  FormControl,
  Popover,
  InputLabel,
  IconButton,
  Chip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterListIcon from '@mui/icons-material/FilterList';
// import MediaGallery from '../projects/MediaGallery';
import MediaGallery from '../shared/gallery/MediaGallery';
import { camelCaseToDisplay } from '../../utilities/strings.js';
import { galleryFilters } from './defectsShared';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  summary: {
    flexDirection: 'row-reverse',
    height: '80px',
    '&.Mui-expanded': {
      minHeight: '2.5rem',
      height: '2rem',
    },
  },
  mediaTitle: {
    marginLeft: 12,
    marginRight: 10,
    marginTop: 9,
  },
  filter: {
    width: '500px',
    display: 'flex',
    padding: '24px 24px 36px 24px',
  },
  selectField: {
    width: '300px',
  },
  filterChips: {
    margin: theme.spacing(1, 1, 0, 0),
  },
}));

const InspectorGallery = props => {
  const { isSelected: selected, selectMedia, onMediaFilterChange, mediaFetch, filterValue } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const anchorRef = React.useRef();

  const handleSelect = e => {
    e.stopPropagation();
    onMediaFilterChange(e);
  };

  const handleExpand = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  // Popover handles
  const handleClick = event => {
    if (!expanded) {
      setTimeout(() => {
        setAnchorEl(anchorRef.current);
      }, 400);
    }
    if (expanded) {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleDeleteChip = () => {
    // onMediaFilterChange needs an event, create event object with desired value;
    const eventObj = { target: { value: galleryFilters.ALL } };
    onMediaFilterChange(eventObj);
  };

  return (
    <div className={classes.root}>
      <Accordion expanded={expanded} onChange={handleExpand}>
        <AccordionSummary aria-label="Expand" className={classes.summary} expandIcon={<ExpandMoreIcon />}>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Typography variant="h6" className={classes.mediaTitle}>
                Media
              </Typography>
            </Grid>
            <Grid item>
              <Grid container direction="row" justifyContent="flex-start">
                {filterValue !== galleryFilters.ALL ? (
                  <Grid item className={classes.filterChips}>
                    <Chip size="medium" label={camelCaseToDisplay(filterValue)} onDelete={handleDeleteChip} />
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid item>
                  <IconButton onClick={handleClick} ref={anchorRef} size="large">
                    <FilterListIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails style={{ padding: '0 0 1rem 0' }}>
          <Popover
            elevation={4}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}>
            <Grid container direction="row" spacing={4} className={classes.filter}>
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Typography variant="body2">FILTERS</Typography>
              </Grid>
              <Grid item>
                <FormControl fullWidth>
                  <InputLabel>Show Media</InputLabel>
                  <Select
                    label="Show Media"
                    onChange={handleSelect}
                    value={filterValue}
                    className={classes.selectField}>
                    <MenuItem value={galleryFilters.ALL}>All</MenuItem>
                    <MenuItem value={galleryFilters.DEFECTS_ONLY}>Findings Only</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Popover>
          <MediaGallery
            preview
            pageHandler={mediaFetch}
            selected={!!selected}
            selectMedia={selectMedia}
            galleryType="inspector"
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

InspectorGallery.propTypes = {
  filterValue: PropTypes.string,
  onMediaFilterChange: PropTypes.func,
  media: PropTypes.array,
  selectMedia: PropTypes.func,
  mediaFetch: PropTypes.func,
  isSelected: PropTypes.number,
};

export default InspectorGallery;
