import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import variables from '../../../config.module.scss';

const useStyles = makeStyles(theme => {
  let bgColor = variables.backgroundDark;
  if (theme.palette.mode === 'light') {
    bgColor = '#FFFFFF';
  }

  return {
    root: {
      position: 'relative',
    },
    overlay: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: '0px',
      left: '0px',
      zIndex: theme.zIndex.overlay,
      opacity: 1,
      // D9 is the opacity.  I got D9 by taking xFF (totally opaque in hex) and converting to decimal which is 255.
      // I then took 85% of that (255 * .85).  Take that number and convert back to hex.
      background: `${bgColor}D9`,
    },
    content: {
      width: '100%',
      height: '100%',
    },
    cell: {
      width: '100%',
    },
    cellSpace: {
      flexGrow: 1,
    },
  };
});

const StatusOverlay = props => {
  const { on, dual, message, children } = props;
  const classes = useStyles();

  const renderOverlay = () => {
    if (on) {
      return (
        <div className={classes.overlay}>
          <Grid container direction="column" alignItems="stretch" spacing={2} className={classes.content}>
            <Grid item className={classes.cell}>
              <LinearProgress color="primary" />
            </Grid>
            <Grid item className={classes.cell}>
              <Typography variant="h3">{message}</Typography>
            </Grid>
            <Grid item className={classes.cellSpace} />
            <Grid item className={classes.cell}>
              {dual && <LinearProgress color="primary" />}
            </Grid>
          </Grid>
        </div>
      );
    }
    return <></>;
  };

  return (
    <div className={classes.root}>
      {renderOverlay()}
      {children}
    </div>
  );
};

StatusOverlay.defaultProps = {
  dual: false,
  message: '',
};

StatusOverlay.propTypes = {
  on: PropTypes.bool.isRequired,
  dual: PropTypes.bool,
  message: PropTypes.string,
  children: PropTypes.element.isRequired,
};

export default StatusOverlay;
