import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { heatDataReadEndpoint } from '../../store/apiV2/heatData';
import { fieldOrder, removeField, hideField } from './heatDataShared';
import { apiDateToString, jsonKeyToLabel } from '../../utilities/strings';
import CommonFormView from '../shared/form/CommonView';

const HeatDataView = () => {
  const id = useParams().id;
  const { data, loading, error, dispatchRequest: fetchHeatData } = heatDataReadEndpoint.useEndpoint();

  useEffect(() => {
    fetchHeatData(id);
  }, [id, fetchHeatData]);

  const formSettings = {
    fieldOrder,
    removeField: removeField('VIEW'),
    hideField,
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      // cast to string because booleans do not show up in the field
      let displayLabel = jsonKeyToLabel(key); // eslint-disable-line prefer-const
      let displayValue = '';
      if (data[key] != null) {
        switch (key) {
          // add cases for each key that needs to be customized.
          case 'updated_on':
          case 'created_on':
            displayValue = apiDateToString(data[key], 'date');
            break;
          default:
            displayValue = data[key].toString();
        }
      }
      fieldSettings[key] = {
        type: 'text',
        fieldProps: {
          label: displayLabel,
          value: displayValue,
        },
      };
    });
  }

  // UPDATE ME: Replace feature name with singular version.
  return (
    <CommonFormView
      title={'Heat Data Details'}
      values={data}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      // editLink={`/heat-data/${id}/edit`}
      loading={loading}
      error={error}
    />
  );
};

export default HeatDataView;
