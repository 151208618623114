import {
  requestFactory,
  responseHandlerForm,
} from '../base';

const get = requestFactory('get');
const post = requestFactory('post', { handler: responseHandlerForm });
const patch = requestFactory('patch', { handler: responseHandlerForm });
const del = requestFactory('del');

export const fetchReservationList = async query => get('/api/reservations/', query);
export const fetchReservation = async id => get(`/api/reservations/${id}/`);
export const createReservation = async body => post('/api/reservations/', body);
export const updateReservation = async (id, body) => patch(`/api/reservations/${id}/`, body);
export const deleteReservation = async id => del(`/api/reservations/${id}/`);
