import { all, put, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  SCHEDULEWORK_REQUEST,
  SCHEDULEWORK_SUCCESS,
  SCHEDULEWORK_FAILURE,
  SCHEDULEWORK_CREATE_REQUEST,
  SCHEDULEWORK_CREATE_SUCCESS,
  SCHEDULEWORK_CREATE_FAILURE,
  SCHEDULEWORK_UPDATE_REQUEST,
  SCHEDULEWORK_UPDATE_SUCCESS,
  SCHEDULEWORK_UPDATE_FAILURE,
  SCHEDULEWORK_DELETE_REQUEST,
  SCHEDULEWORK_DELETE_SUCCESS,
  SCHEDULEWORK_DELETE_FAILURE,
  SCHEDULEWORK_ALL_REQUEST,
  SCHEDULEWORK_ALL_SUCCESS,
  SCHEDULEWORK_ALL_FAILURE,
} from './scheduleWorkActions';

import {
  fetchScheduleWork,
  createScheduleWork,
  updateScheduleWork,
  deleteScheduleWork,
  fetchScheduleWorkList,
} from '../../api/features/scheduleWork';

import { mainRedirectPath } from './schedulePlanSaga';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';
import { fetchListAll } from '../../utilities/sagaHelpers';
import { DIALOG_CLOSE } from '../dialogActions';

export function* scheduleWorkFetch(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield fetchScheduleWork(id);
    if (data) {
      yield put({ type: SCHEDULEWORK_SUCCESS, data });
    } else if (error) {
      yield put({ type: SCHEDULEWORK_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, SCHEDULEWORK_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, SCHEDULEWORK_FAILURE);
  }
}

export function* watchScheduleWorkFetch() {
  yield takeLatest(SCHEDULEWORK_REQUEST, scheduleWorkFetch);
}

export function* scheduleWorkCreate(action) {
  const { planId, body } = action;
  try {
    const { data, error, formError, response } = yield createScheduleWork(body);
    if (data) {
      yield put({ type: SCHEDULEWORK_CREATE_SUCCESS, data });
      yield put(push(`${mainRedirectPath}/${planId}`));
    } else if (error) {
      yield put({ type: SCHEDULEWORK_CREATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: SCHEDULEWORK_CREATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, SCHEDULEWORK_CREATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, SCHEDULEWORK_CREATE_FAILURE);
  }
}

export function* watchScheduleWorkCreate() {
  yield takeLatest(SCHEDULEWORK_CREATE_REQUEST, scheduleWorkCreate);
}

export function* scheduleWorkUpdate(action) {
  const { id, body } = action;
  try {
    const { data, error, formError, response } = yield updateScheduleWork(id, body);
    if (data) {
      yield put({ type: SCHEDULEWORK_UPDATE_SUCCESS, data });
      yield put(push(`${mainRedirectPath}/${data.plan}`));
    } else if (error) {
      yield put({ type: SCHEDULEWORK_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: SCHEDULEWORK_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, SCHEDULEWORK_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, SCHEDULEWORK_UPDATE_FAILURE);
  }
}

export function* watchScheduleWorkUpdate() {
  yield takeLatest(SCHEDULEWORK_UPDATE_REQUEST, scheduleWorkUpdate);
}

export function* scheduleWorkDelete(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield deleteScheduleWork(id);
    if (data) {
      yield put({ type: SCHEDULEWORK_DELETE_SUCCESS });
      const query = yield select(state => state.scheduleWork.all.query) || '';
      yield put({ type: SCHEDULEWORK_ALL_REQUEST, query });
      yield put({ type: DIALOG_CLOSE });
    } else if (error) {
      yield put({ type: SCHEDULEWORK_DELETE_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, SCHEDULEWORK_DELETE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, SCHEDULEWORK_DELETE_FAILURE);
  }
}

export function* watchScheduleWorkDelete() {
  yield takeLatest(SCHEDULEWORK_DELETE_REQUEST, scheduleWorkDelete);
}

export function* scheduleWorkFetchAll(action) {
  try {
    const { data, error, response } = yield fetchListAll(fetchScheduleWorkList, action.query);
    if (data) {
      yield put({ type: SCHEDULEWORK_ALL_SUCCESS, data });
    } else if (error) {
      yield put({ type: SCHEDULEWORK_ALL_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, SCHEDULEWORK_ALL_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, SCHEDULEWORK_ALL_FAILURE);
  }
}

export function* watchScheduleWorkFetchAll() {
  yield takeLatest(SCHEDULEWORK_ALL_REQUEST, scheduleWorkFetchAll);
}

export default function* scheduleWorkSaga() {
  yield all([
    watchScheduleWorkFetch(),
    watchScheduleWorkCreate(),
    watchScheduleWorkUpdate(),
    watchScheduleWorkFetchAll(),
    watchScheduleWorkDelete(),
  ]);
}
