import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

// lib
import { Grid, IconButton } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';

// components
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import GalleryTable from '../../shared/table/GalleryTable';
import FullScreenDialog from '../../shared/FullScreenDialog';

// util
import { compareArrayEquality } from '../../../utilities/arrays';
import { inspectionMediaListBatchFetchAllEndpoint } from '../../../store/apiV2/inspectionMedia';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

/**
 * @description A gallery for selecting (already uploaded) media objects
 *  NOTE - unlike legacy gallery, this does NOT support uploading new media
 */
export default function ChecklistGallery2(props) {
  const [enableSave, setEnableSave] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]); // modified selections
  const { fields, open, setOpen } = props;
  const [numMediaPerRow, setNumMediaPerRow] = useState(4);

  const { data, count, loading } = inspectionMediaListBatchFetchAllEndpoint.useEndpoint();

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    const currentSelections = fields.value?.map(x => x.id);
    setSelectedValues(currentSelections);
    handleClose();
    setEnableSave(false);
  };

  /**
   * @description Check if a media object is selected
   * @param {Object} mediaObj
   * @returns {boolean}
   */
  const isMediaObjectSelected = mediaObj => {
    if (!fields.value) {
      return false;
    }
    return Boolean(fields.value.find(selectedValues => mediaObj.id === selectedValues.id));
  };

  /**
   * @description Get the media object from the data array
   * @param {any[]} rowData
   * @returns {Object} mediaObj
   */
  const getMediaObject = rowData => {
    return data.results.find(mediaObj => mediaObj.id === rowData[0]);
  };

  /**
   * @description Select or deselect a media object
   * @param {Object} mediaObj
   */
  const onSelectMediaObject = mediaObj => {
    if (isMediaObjectSelected(mediaObj)) {
      const indexToRemove = fields.value.findIndex(item => item.id === mediaObj.id);
      fields.remove(indexToRemove);
    } else {
      fields.push(mediaObj);
    }
  };

  const refSelectedValues = useRef(fields.value?.map(x => x.id)); // grab ids from initial field value
  useEffect(() => {
    const currentSelections = fields.value?.map(x => x.id);
    // if selections have been modified, check against modified state
    if (!selectedValues?.length) {
      setEnableSave(!compareArrayEquality(currentSelections, refSelectedValues.current));
    } else {
      setEnableSave(!compareArrayEquality(currentSelections, selectedValues));
    }
  }, [fields.value]); // eslint-disable-line react-hooks/exhaustive-deps

  // All columns will be hidden, but we need to specify the filter field
  //   and filter type for the search bar to work

  /** @type {Column[]} */
  const columns = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        display: 'excluded',
        filter: false,
      },
    },

    // TODO - pull over the rest of the columns from the InspectionMediaTable
  ];

  const NUM_MEDIA_PER_ROW_OPTIONS = [2, 3, 4, 5, 6];

  /** @type {TableOptions} */
  const options = {
    download: false,
    print: false,
    viewColumns: false,
    filter: false, // can add back in, but for now none are supported
    customToolbar: () => (
      <>
        <select
          onChange={e => {
            setNumMediaPerRow(parseInt(e.target.value));
          }}>
          {NUM_MEDIA_PER_ROW_OPTIONS.map(num => (
            <option key={num} selected={numMediaPerRow === num} value={num}>
              {num} Per Row
            </option>
          ))}
        </select>
      </>
    ),
  };

  /**
   * @description Sort fetched media by whether it is selected or not
   * ONLY RUN ONCE when component mounts
   */
  const sortedFilteredResults = React.useMemo(() => {
    if (!data?.results) {
      return [];
    }
    return data.results.sort((a, b) => {
      const aSelected = isMediaObjectSelected(a);
      const bSelected = isMediaObjectSelected(b);
      if (aSelected && !bSelected) {
        return -1;
      }
      if (!aSelected && bSelected) {
        return 1;
      }
      return 0;
    });
  }, [loading, open]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FullScreenDialog open={open} onClose={handleClose}>
      <FullScreenDialog.AppBar>
        <Grid container justifyContent="space-between">
          <Grid item>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              style={{ flex: 1 }}
              disabled={enableSave}>
              <ArrowBack />
            </IconButton>
          </Grid>
          <Grid item>
            <PrimaryButton label="Save Selections" onClick={handleSave} disabled={!enableSave} />
          </Grid>
        </Grid>
      </FullScreenDialog.AppBar>
      <FullScreenDialog.Container>
        <GalleryTable
          simpleSearch
          columns={columns}
          data={sortedFilteredResults}
          options={options}
          loading={loading}
          count={count}
          numMediaPerRow={numMediaPerRow}
          getMediaObject={getMediaObject}
          isMediaObjectSelected={isMediaObjectSelected}
          onSelectMediaObject={onSelectMediaObject}
        />
      </FullScreenDialog.Container>
    </FullScreenDialog>
  );
}

ChecklistGallery2.propTypes = {
  /** RFF FieldArray */
  fields: PropTypes.object.isRequired,
  /** Show or hide the gallery */
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
