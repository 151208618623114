export const fabricRectToCoords = (obj, scaleFactor, offset) => {
  // image scale and positioning relative to canvas used to calculate saved overlay coordinates
  const { top, left } = offset;
  return {
    type: 'Polygon',
    coordinates: [
      [
        // order is important for canvas application; we might want to calculate this ourselves
        [(obj.aCoords.tl.x - left) / scaleFactor, (obj.aCoords.tl.y - top) / scaleFactor],
        [(obj.aCoords.tr.x - left) / scaleFactor, (obj.aCoords.tr.y - top) / scaleFactor],
        [(obj.aCoords.bl.x - left) / scaleFactor, (obj.aCoords.bl.y - top) / scaleFactor],
        [(obj.aCoords.br.x - left) / scaleFactor, (obj.aCoords.br.y - top) / scaleFactor],
        [(obj.aCoords.tl.x - left) / scaleFactor, (obj.aCoords.tl.y - top) / scaleFactor],
      ],
    ],
  };
};

export const coordsToFabricRect = (geometry, scaleFactor, offset) => {
  // current image scale and positioning relative to canvas used to display overlays
  const { top, left } = offset;
  const coords = geometry.coordinates[0];
  const fabricRect = {
    left: coords[0][0] * scaleFactor + left,
    top: coords[0][1] * scaleFactor + top,
    width: (coords[1][0] - coords[0][0]) * scaleFactor,
    height: (coords[2][1] - coords[0][1]) * scaleFactor,
  };
  return fabricRect;
};
