export const LOCATION_LAYER_PROFILES_REQUEST = 'LOCATION_LAYER_PROFILES_REQUEST';
export const LOCATION_LAYER_PROFILES_SUCCESS = 'LOCATION_LAYER_PROFILES_SUCCESS';
export const LOCATION_LAYER_PROFILES_FAILURE = 'LOCATION_LAYER_PROFILES_FAILURE';
export const LOCATION_LAYER_PROFILES_CREATE_REQUEST = 'LOCATION_LAYER_PROFILES_CREATE_REQUEST';
export const LOCATION_LAYER_PROFILES_CREATE_SUCCESS = 'LOCATION_LAYER_PROFILES_CREATE_SUCCESS';
export const LOCATION_LAYER_PROFILES_CREATE_FAILURE = 'LOCATION_LAYER_PROFILES_CREATE_FAILURE';
export const LOCATION_LAYER_PROFILES_UPDATE_REQUEST = 'LOCATION_LAYER_PROFILES_UPDATE_REQUEST';
export const LOCATION_LAYER_PROFILES_UPDATE_SUCCESS = 'LOCATION_LAYER_PROFILES_UPDATE_SUCCESS';
export const LOCATION_LAYER_PROFILES_UPDATE_FAILURE = 'LOCATION_LAYER_PROFILES_UPDATE_FAILURE';
export const LOCATION_LAYER_PROFILES_DELETE_REQUEST = 'LOCATION_LAYER_PROFILES_DELETE_REQUEST';
export const LOCATION_LAYER_PROFILES_DELETE_SUCCESS = 'LOCATION_LAYER_PROFILES_DELETE_SUCCESS';
export const LOCATION_LAYER_PROFILES_DELETE_FAILURE = 'LOCATION_LAYER_PROFILES_DELETE_FAILURE';
export const LOCATION_LAYER_PROFILES_ALL_REQUEST = 'LOCATION_LAYER_PROFILES_ALL_REQUEST';
export const LOCATION_LAYER_PROFILES_ALL_SUCCESS = 'LOCATION_LAYER_PROFILES_ALL_SUCCESS';
export const LOCATION_LAYER_PROFILES_ALL_FAILURE = 'LOCATION_LAYER_PROFILES_ALL_FAILURE';

export const getLocationLayerProfiles = id => {
  return {
    type: LOCATION_LAYER_PROFILES_REQUEST,
    id,
  };
};

export const createLocationLayerProfiles = body => {
  return {
    type: LOCATION_LAYER_PROFILES_CREATE_REQUEST,
    body,
  };
};

export const updateLocationLayerProfiles = (id, body) => {
  return {
    type: LOCATION_LAYER_PROFILES_UPDATE_REQUEST,
    id,
    body,
  };
};

export const deleteLocationLayerProfiles = id => {
  return {
    type: LOCATION_LAYER_PROFILES_DELETE_REQUEST,
    id,
  };
};

export const getAllLocationLayerProfiles = query => {
  return {
    type: LOCATION_LAYER_PROFILES_ALL_REQUEST,
    query,
  };
};
