import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, LinearProgress } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import SecondaryButton from '../buttons/SecondaryButton';
import Title from '../displays/Title';
import FullScreenDialog from '../FullScreenDialog';

const useStyles = makeStyles(theme => ({
  table: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  loadingIcon: {
    marginLeft: theme.spacing(2),
    position: 'relative',
    top: '3px',
  },
}));

export default function FullScreenTable(props) {
  const classes = useStyles();
  const { fullScreen, setFullScreen, title, children, loading } = props;

  const handleClose = () => {
    setFullScreen(false);
  };

  return (
    <FullScreenDialog open={fullScreen} onClose={handleClose}>
      <div>
        <Toolbar>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item className={classes.something}>
              <SecondaryButton label="Close" icon variant="text" onClick={handleClose} />
            </Grid>
          </Grid>
        </Toolbar>
        <div className={classes.table}>
          <Title title={title} />
          {loading && <LinearProgress size={18} color="primary" className={classes.loadingIcon} />}
          {children}
        </div>
      </div>
    </FullScreenDialog>
  );
}

FullScreenTable.propTypes = {
  fullScreen: PropTypes.bool,
  setFullScreen: PropTypes.func,
  children: PropTypes.element,
  title: PropTypes.string,
  loading: PropTypes.bool,
};

FullScreenTable.defaultProps = {
  fullScreen: false,
  setFullScreen: () => {},
  children: null,
  title: '',
  loading: false,
};
