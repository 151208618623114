import { grey } from '@mui/material/colors';

import variables from '../config.module.scss';
import layout from './overrides/layout';
import buttons from './overrides/buttons';
import tables from './overrides/tables';

const MuiCssBaseline = darkMode => ({
  '*::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
  },
  /* Track */
  '*::-webkit-scrollbar-track': {
    background: darkMode ? variables.baseDark : variables.baseLight,
  },
  /* Handle */
  '*::-webkit-scrollbar-thumb': {
    background: darkMode ? variables.darkGrey : variables.grey,
  },
  /* Handle on hover */
  '*::-webkit-scrollbar-thumb:hover': {
    background: darkMode ? variables.darkestGrey : variables.lightOrange,
  },
});

const MuiLinearProgress = {
  styleOverrides: {
    barColorPrimary: {
      backgroundColor: variables.primary || grey[500],
    },
  },
};

const MuiCheckbox = {
  styleOverrides: {
    root: {
      padding: 0,
      color: variables.darkGrey,
    },
    colorPrimary: {
      // This makes more than the checkmark white.
      // TODO: figure out how to make just the check white.
      // '&$checked': {
      //   backgroundColor: variables.white,
      // },
    },
  },
};

const MuiTextField = {
  styleOverrides: {
    root: {
      marginTop: '0',
    },
  },
  defaultProps: {
    size: 'small',
  },
};

const MuiFormControlLabel = {
  styleOverrides: {
    root: {
      marginLeft: '0px',
    },
    label: {
      paddingRight: '8px',
    },
  },
};

const MuiInputLabel = {
  styleOverrides: {
    root: {
      // the border is overlapping the label without this.
      // possibly move this to shared/Select.js if it doesn't work with other elements.
      paddingRight: '8px',
    },
  },
};

const MuiInput = {
  styleOverrides: {
    root: {
      margin: 0,
    },
    formControl: {
      margin: 0,
    },
  },
};

const MuiMenuItem = {
  styleOverrides: {
    root: {
      paddingTop: 0,
      paddingBottom: 0,
      margin: 0,
    },
  },
};

const MuiAutocomplete = {
  option: {
    textAlign: 'left',
  },
};

const MUIDataTableHeadCell = {
  styleOverrides: {
    root: {
      fontSize: '14px',
    },
  },
};

const MUIDataTableSelectCell = {
  styleOverrides: {
    expandDisabled: {
      // hide the button if expand functionality is disabled
      visibility: 'hidden',
    },
  },
};

const MuiTab = {
  styleOverrides: {
    root: {
      fontSize: '0.875rem',
      minWidth: '160px',
      '&.Mui-selected': {
        color: 'inherit',
      },
    },
  },
};

const overrides = darkMode => ({
  MuiCssBaseline: MuiCssBaseline(darkMode),
  MuiLinearProgress,
  MuiCheckbox,
  MuiTextField,
  MuiInputLabel,
  MuiInput,
  MuiFormControlLabel,
  MuiMenuItem,
  MuiAutocomplete,
  MUIDataTableHeadCell,
  MUIDataTableSelectCell,
  MuiTab,
  ...layout(darkMode),
  ...buttons(darkMode),
  ...tables(darkMode),
});

export default overrides;
