import { all, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  CHECKLISTS_REQUEST,
  CHECKLISTS_SUCCESS,
  CHECKLISTS_FAILURE,
  CHECKLISTS_CREATE_REQUEST,
  CHECKLISTS_CREATE_SUCCESS,
  CHECKLISTS_CREATE_FAILURE,
  CHECKLISTS_UPDATE_REQUEST,
  CHECKLISTS_UPDATE_SUCCESS,
  CHECKLISTS_UPDATE_FAILURE,
  CHECKLISTS_DELETE_REQUEST,
  CHECKLISTS_DELETE_SUCCESS,
  CHECKLISTS_DELETE_FAILURE,
  CHECKLISTS_ALL_REQUEST,
  CHECKLISTS_ALL_SUCCESS,
  CHECKLISTS_ALL_FAILURE,
  CHECKLISTS_BULK_LOCK_REQUEST,
  CHECKLISTS_BULK_LOCK_SUCCESS,
  CHECKLISTS_BULK_LOCK_FAILURE,
} from './checklistsActions';

import {
  fetchChecklists,
  createChecklists,
  updateChecklists,
  deleteChecklists,
  fetchChecklistsList,
  bulkLockChecklists,
} from '../../api/features/checklists';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';
import { DIALOG_CLOSE } from '../../store/dialogActions';

import { history } from '../../providers/StoreProvider';
import { pathToPage } from '../../utilities/strings';
import { tableViews } from '../../utilities/tables';
import { SET_TABLE_QUERY } from '../../store/settings/tableActions';
import { SNACKBAR_OPEN } from '../snackbarActions';

const { checklistsTableViewKey: tableType } = tableViews;

export function* checklistsFetch(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield fetchChecklists(id);
    if (data) {
      yield put({ type: CHECKLISTS_SUCCESS, data });
    } else if (error) {
      yield put({ type: CHECKLISTS_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, CHECKLISTS_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, CHECKLISTS_FAILURE);
  }
}

export function* watchChecklistsFetch() {
  yield takeLatest(CHECKLISTS_REQUEST, checklistsFetch);
}

export function* checklistsCreate(action) {
  const { body } = action;
  try {
    const { data, error, formError, response } = yield createChecklists(body);
    if (data) {
      yield put({ type: CHECKLISTS_CREATE_SUCCESS, data });
    } else if (error) {
      yield put({ type: CHECKLISTS_CREATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: CHECKLISTS_CREATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, CHECKLISTS_CREATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, CHECKLISTS_CREATE_FAILURE);
  }
}

export function* watchChecklistsCreate() {
  yield takeLatest(CHECKLISTS_CREATE_REQUEST, checklistsCreate);
}

export function* checklistsUpdate(action) {
  const { id, body, onUpdate } = action;
  try {
    const { data, error, formError, response } = yield updateChecklists(id, body);
    if (data) {
      yield put({ type: CHECKLISTS_UPDATE_SUCCESS, data });
      if (typeof onUpdate === 'function') {
        onUpdate(data);
      }
    } else if (error) {
      yield put({ type: CHECKLISTS_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: CHECKLISTS_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, CHECKLISTS_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, CHECKLISTS_UPDATE_FAILURE);
  }
}

export function* watchChecklistsUpdate() {
  yield takeLatest(CHECKLISTS_UPDATE_REQUEST, checklistsUpdate);
}

export function* checklistsDelete(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield deleteChecklists(id);
    if (data) {
      yield put({ type: CHECKLISTS_DELETE_SUCCESS });
      yield put({ type: CHECKLISTS_ALL_REQUEST });
      yield put({ type: DIALOG_CLOSE });
      yield put(push('/forms'));
    } else if (error) {
      yield put({ type: CHECKLISTS_DELETE_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, CHECKLISTS_DELETE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, CHECKLISTS_DELETE_FAILURE);
  }
}

export function* watchChecklistsDelete() {
  yield takeLatest(CHECKLISTS_DELETE_REQUEST, checklistsDelete);
}

export function* checklistsFetchAll(action) {
  const { query } = action; // pageInfo
  const location = history.location;
  try {
    const { data, error, response } = yield fetchChecklistsList(action.query);
    if (data) {
      yield put({ type: CHECKLISTS_ALL_SUCCESS, data });
    } else if (error) {
      yield put({ type: CHECKLISTS_ALL_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, CHECKLISTS_ALL_FAILURE);
    }
    // save the query in the store
    if (pathToPage(location.pathname)) {
      const page = pathToPage(location.pathname);
      yield put({ type: SET_TABLE_QUERY, page, query, tableType });
    }
  } catch (error) {
    yield defaultErrorCatch(error, CHECKLISTS_ALL_FAILURE);
  }
}

export function* watchChecklistsFetchAll() {
  yield takeLatest(CHECKLISTS_ALL_REQUEST, checklistsFetchAll);
}

export function* checklistsBulkLock(action) {
  const { ids, lockAction } = action;
  try {
    const { data, error, response } = yield bulkLockChecklists(ids, lockAction);
    if (data) {
      yield put({ type: CHECKLISTS_BULK_LOCK_SUCCESS });
      console.log('bulk lock success', data, action);
      const forms = data?.modified === 1 ? 'form' : 'forms';
      const actionWord = action.lockAction === 'LOCK' ? 'locked' : 'unlocked';
      yield put({
        type: SNACKBAR_OPEN,
        message: `${data?.modified} ${forms} ${actionWord}`,
        variant: 'success',
        duration: 3000,
        open: true,
      });
      const query = yield select(state => state.checklists.all.query) || '';
      yield put({ type: CHECKLISTS_ALL_REQUEST, query }); // refresh the list to show change
    } else if (error) {
      yield put({ type: CHECKLISTS_BULK_LOCK_FAILURE, error });
      yield put({
        type: SNACKBAR_OPEN,
        message: error,
        variant: 'error',
        duration: 5000,
        open: true,
      });
    } else {
      yield defaultErrorHandler(response, CHECKLISTS_BULK_LOCK_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, CHECKLISTS_BULK_LOCK_FAILURE);
  }
}

export function* watchChecklistsBulkLock() {
  yield takeLatest(CHECKLISTS_BULK_LOCK_REQUEST, checklistsBulkLock);
}

export default function* checklistsSaga() {
  yield all([
    watchChecklistsFetch(),
    watchChecklistsCreate(),
    watchChecklistsUpdate(),
    watchChecklistsDelete(),
    watchChecklistsFetchAll(),
    watchChecklistsBulkLock(),
  ]);
}
