import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { default as MuiSwitch } from '@mui/material/Switch';
import { FormControl, FormGroup, FormControlLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  inputLabel: {
    margin: theme.spacing(-3, 0),
  },
}));

/**
 * Toggle type button.
 * This button has a indicator that slides back and forth to display the value
 */

const Switch = props => {
  const { disabled, color, label, initialValue, onChange } = props;
  const [value, setValue] = useState(initialValue);
  const classes = useStyles();

  // handle state setting with initial value prop
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = event => {
    const newValue = event.target.checked;
    setValue(newValue);
    if (onChange) {
      // event.preventDefault();
      onChange(newValue);
    }
  };

  return (
    <FormControl>
      <FormGroup row>
        <FormControlLabel
          control={<MuiSwitch disabled={disabled} color={color} checked={value} onChange={handleChange} />}
          label={label}
          labelPlacement="start"
          className={classes.inputLabel}
        />
      </FormGroup>
    </FormControl>
  );
};

Switch.defaultProps = {
  label: '',
  color: 'primary',
  initialValue: false,
  disabled: false,
  onChange: null,
};

Switch.propTypes = {
  /** sets the button label */
  label: PropTypes.string,
  /** sets the color theme of the component */
  color: PropTypes.string,
  /** initialValue for Switch's internal state */
  initialValue: PropTypes.bool,
  /** disables button */
  disabled: PropTypes.bool,
  /** callback for onChange event */
  onChange: PropTypes.func,
};

export default Switch;
