import { toQueryString, queriesFromString } from './strings';
import { compare } from './arrays';

const getValue = value => value.name.toLowerCase();
export const sortFn = compare(getValue);

export const filterToQueries = (query, inFilters = [], returnString = false) => {
  // add __in to all the queries unless it's already there or the query is 'map'
  const newQueryObj = {};
  const queryObj = queriesFromString(query, [], true);
  for (const [key, value] of Object.entries(queryObj)) {
    if (key.includes('__in') || (Array.isArray(inFilters) && !inFilters.includes(key))) {
      newQueryObj[key] = value;
    } else {
      newQueryObj[`${key}__in`] = value;
    }
  }
  if (returnString) {
    return toQueryString(newQueryObj);
  }
  return newQueryObj;
};

export const queriesToFilter = query => {
  // remove '__in' so parse query will have the correct variables locally
  return query.replace(/__in=/g, '=');
};
