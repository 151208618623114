export const COMPANIES_REQUEST = 'COMPANIES_REQUEST';
export const COMPANIES_SUCCESS = 'COMPANIES_SUCCESS';
export const COMPANIES_FAILURE = 'COMPANIES_FAILURE';
export const COMPANIES_CREATE_REQUEST = 'COMPANIES_CREATE_REQUEST';
export const COMPANIES_CREATE_SUCCESS = 'COMPANIES_CREATE_SUCCESS';
export const COMPANIES_CREATE_FAILURE = 'COMPANIES_CREATE_FAILURE';
export const COMPANIES_UPDATE_REQUEST = 'COMPANIES_UPDATE_REQUEST';
export const COMPANIES_UPDATE_SUCCESS = 'COMPANIES_UPDATE_SUCCESS';
export const COMPANIES_UPDATE_FAILURE = 'COMPANIES_UPDATE_FAILURE';
export const COMPANIES_DELETE_REQUEST = 'COMPANIES_DELETE_REQUEST';
export const COMPANIES_DELETE_SUCCESS = 'COMPANIES_DELETE_SUCCESS';
export const COMPANIES_DELETE_FAILURE = 'COMPANIES_DELETE_FAILURE';
export const COMPANIES_ALL_REQUEST = 'COMPANIES_ALL_REQUEST';
export const COMPANIES_ALL_SUCCESS = 'COMPANIES_ALL_SUCCESS';
export const COMPANIES_ALL_FAILURE = 'COMPANIES_ALL_FAILURE';

export const getCompanies = id => {
  return {
    type: COMPANIES_REQUEST,
    id,
  };
};

export const createCompanies = body => {
  return {
    type: COMPANIES_CREATE_REQUEST,
    body,
  };
};

export const updateCompanies = (id, body) => {
  return {
    type: COMPANIES_UPDATE_REQUEST,
    id,
    body,
  };
};

export const deleteCompanies = id => {
  return {
    type: COMPANIES_DELETE_REQUEST,
    id,
  };
};

export const getAllCompanies = query => {
  return {
    type: COMPANIES_ALL_REQUEST,
    query,
  };
};
