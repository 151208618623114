// --------------------------------------------------------------------------------
//
// Generated File
//
// !! Do not change this file by hand.                                     !!
// !! This file is automatically generated from the routes in this folder. !!
//
// --------------------------------------------------------------------------------
import React from 'react';
import { Route } from 'react-router-dom';
import AssessmentTypesRoutes from './AssessmentTypesRoutes';
import AssessmentsRoutes from './AssessmentsRoutes';
import AssetTypesRoutes from './AssetTypesRoutes';
import AssetsRoutes from './AssetsRoutes';
import BulkImportsRoutes from './BulkImportsRoutes';
import ChecklistResultLineRoutes from './ChecklistResultLineRoutes';
import ChecklistTemplatesRoutes from './ChecklistTemplatesRoutes';
import CmlsRoutes from './CmlsRoutes';
import CompaniesRoutes from './CompaniesRoutes';
import CrewsRoutes from './CrewsRoutes';
import DefectProfilesRoutes from './DefectProfilesRoutes';
import DefectsRoutes from './DefectsRoutes';
import FormsRoutes from './FormsRoutes';
import HeatDataRoutes from './HeatDataRoutes';
import HeatDataImportsRoutes from './HeatDataImportsRoutes';
import InspectionMediaRoutes from './InspectionMediaRoutes';
import LibrariesRoutes from './LibrariesRoutes';
import LibraryMediaRoutes from './LibraryMediaRoutes';
import LocationLayerProfilesRoutes from './LocationLayerProfilesRoutes';
import MeasurementsRoutes from './MeasurementsRoutes';
import NotificationRulesRoutes from './NotificationRulesRoutes';
import ProjectTypesRoutes from './ProjectTypesRoutes';
import ProjectsRoutes from './ProjectsRoutes';
import RecommendedWorkPlanRoutes from './RecommendedWorkPlanRoutes';
import ReportTypesRoutes from './ReportTypesRoutes';
import ReservationsRoutes from './ReservationsRoutes';
import ScheduleEventsRoutes from './ScheduleEventsRoutes';
import SchedulePlanRoutes from './SchedulePlanRoutes';
import ScheduleWorkRoutes from './ScheduleWorkRoutes';
import UsersRoutes from './UsersRoutes';

const FeatureSubRoutes = () => {
  return [
    <Route key="assessment-types" path="/assessment-types">
      <AssessmentTypesRoutes />
    </Route>,
    <Route key="assessments" path="/assessments">
      <AssessmentsRoutes />
    </Route>,
    <Route key="asset-types" path="/asset-types">
      <AssetTypesRoutes />
    </Route>,
    <Route key="assets" path="/assets">
      <AssetsRoutes />
    </Route>,
    <Route key="bulk-imports" path="/bulk-imports">
      <BulkImportsRoutes />
    </Route>,
    <Route key="heat-data-imports" path="/heat-data-imports">
      <HeatDataImportsRoutes />
    </Route>,
    <Route key="checklist-result-lines" path="/checklist-result-lines">
      <ChecklistResultLineRoutes />
    </Route>,
    <Route key="checklist-templates" path="/checklist-templates">
      <ChecklistTemplatesRoutes />
    </Route>,
    <Route key="cmls" path="/cmls">
      <CmlsRoutes />
    </Route>,
    <Route key="companies" path="/companies">
      <CompaniesRoutes />
    </Route>,
    <Route key="crews" path="/crews">
      <CrewsRoutes />
    </Route>,
    <Route key="finding-profiles" path="/finding-profiles">
      <DefectProfilesRoutes />
    </Route>,
    <Route key="findings" path="/findings">
      <DefectsRoutes />
    </Route>,
    <Route key="forms" path="/forms">
      <FormsRoutes />
    </Route>,
    <Route key="heat-data" path="/heat-data">
      <HeatDataRoutes />
    </Route>,
    <Route key="inspection-media" path="/inspection-media">
      <InspectionMediaRoutes />
    </Route>,
    <Route key="libraries" path="/libraries">
      <LibrariesRoutes />
    </Route>,
    <Route key="library-media" path="/library-media">
      <LibraryMediaRoutes />
    </Route>,
    <Route key="location-layer-profiles" path="/location-layer-profiles">
      <LocationLayerProfilesRoutes />
    </Route>,
    <Route key="measurements" path="/measurements">
      <MeasurementsRoutes />
    </Route>,
    <Route key="notification-rules" path="/notification-rules">
      <NotificationRulesRoutes />
    </Route>,
    <Route key="project-types" path="/project-types">
      <ProjectTypesRoutes />
    </Route>,
    <Route key="projects" path="/projects">
      <ProjectsRoutes />
    </Route>,
    <Route key="recommended-work-plan" path="/recommended-work-plan">
      <RecommendedWorkPlanRoutes />
    </Route>,
    <Route key="report-types" path="/report-types">
      <ReportTypesRoutes />
    </Route>,
    <Route key="reservations" path="/reservations">
      <ReservationsRoutes />
    </Route>,
    <Route key="schedule-events" path="/schedule-events">
      <ScheduleEventsRoutes />
    </Route>,
    <Route key="schedule-plan" path="/schedule-plan">
      <SchedulePlanRoutes />
    </Route>,
    <Route key="schedule-work" path="/schedule-work">
      <ScheduleWorkRoutes />
    </Route>,
    <Route key="users" path="/users">
      <UsersRoutes />
    </Route>,
  ];
};

export default FeatureSubRoutes;
