import React from 'react';
import PropTypes from 'prop-types';
import { Grid, AppBar, Toolbar } from '@mui/material';
import SecondaryButton from '../buttons/SecondaryButton';
import MediaGallery from '../gallery/MediaGallery';
import FullScreenDialog from '../FullScreenDialog';

export default function SlideshowGallery(props) {
  const { showGallery, setShowGallery, setMediaSlide, isReadOnly, tableChangeHandler } = props;

  const handleClose = () => {
    setShowGallery(false);
  };
  if (!showGallery) return null;

  return (
    <FullScreenDialog open={showGallery} onClose={handleClose}>
      <div>
        <AppBar position="fixed">
          <Toolbar>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <SecondaryButton label="Close" icon variant="text" onClick={handleClose} />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Toolbar />
        <MediaGallery
          galleryType={'media'}
          pageHandler={tableChangeHandler}
          setMediaSlide={setMediaSlide}
          preview
          isReadOnly={isReadOnly}
        />
      </div>
    </FullScreenDialog>
  );
}

SlideshowGallery.defaultProps = {
  isReadOnly: true,
};
SlideshowGallery.propTypes = {
  showGallery: PropTypes.bool.isRequired,
  setShowGallery: PropTypes.func.isRequired,
  setMediaSlide: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
  tableChangeHandler: PropTypes.func.isRequired,
};
