/**
 * fieldOrder
 *
 * An object where the keys define the sections of the form.   Each section requires the key display (string) and
 * fields (array of strings). Fields are the api keys (in snake case) for each field that will be displayed in the form.
 * Any fields that are never shown should not be added to this array.
 *
 * @return {object}
 */
export const fieldOrder = {
  info: {
    display: 'Info',
    fields: ['name', 'asset_types', 'report_types', 'checklist_template', 'defect_profile', 'layer_profile'],
  },
  options: {
    display: 'Options',
    fields: [
      'affects_asset_condition',
      'allow_asset_selection_on_findings',
      'track_tasks',
      'monitoring_mode',
      'project_children_mode',
      'project_name_template',
      'project_description_template',
      'planned_duration',
    ],
  },
  status: {
    display: 'Status',
    fields: ['is_active', 'created_on', 'updated_on'],
  },
};

/**
 *  removeField
 *
 *  removes a field depending on if a user is creating, updating or viewing.
 *
 *  @param {string} state - enum for creating, updating or viewing ['CREATE', 'UPDATE', 'VIEW']
 *  @param {string} item - api key (in snake case) to check whether it should be hidden.
 *  @return {boolean} - returns true if the item will not be shown
 */
export const removeField = state => item => {
  let fields = [];
  switch (state) {
    case 'CREATE':
      fields = ['created_on', 'updated_on', 'deactivated_on'];
      break;
    case 'UPDATE':
      fields = ['created_on', 'updated_on', 'deactivated_on'];
      break;
    case 'VIEW':
      fields = ['deactivated_on'];
      break;
    default:
      return false;
  }
  return fields.includes(item);
};

/**
 * hideField
 *
 * This function is for hiding a field depending on the value of another field.
 *
 * @param {object} values - the values of all the fields in the form.
 * @param {string} item - api key (in snake case) to check whether it should be removed.
 * @return {boolean} - returns true if the item will not be shown
 */
export const hideField = (values, item, features = {}) => {
  // can not use hook in normal function, so pass values in
  const { hasConditionMonitoring, hasProjectTasks, hasProjectChildren } = features;
  switch (item) {
    case 'monitoring_mode':
      return !hasConditionMonitoring;
    case 'track_tasks':
      return !hasProjectTasks;
    case 'project_children_mode':
      return !hasProjectChildren;
    default:
      return false;
  }
};
