import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { jsonKeyToLabel, apiDateToString, noneOption } from '../../utilities/strings';
import {
  getProjectTypes,
  createProjectTypes,
  updateProjectTypes,
  clearProjectTypes,
} from '../../store/features/projectTypesActions';
import CommonForm from '../shared/form/Common';
import { fieldOrder, removeField, hideField } from './projectTypesShared';
import { getAllAssetTypes } from '../../store/features/assetTypesActions';
import { reportTypesListAllEndpoint } from '../../store/apiV2/reportTypes';
import { defectProfilesListAllEndpoint } from '../../store/apiV2/defectProfiles';
import { getAllLocationLayerProfiles } from '../../store/features/locationLayerProfilesActions';
import { checklistTemplatesBatchListEndpoint } from '../../store/apiV2/checklistTemplates';
import {
  useAssetTypes,
  useReportTypes,
  useChecklistTemplates,
  useLocationLayerProfiles,
  useDefectProfiles,
} from '../../hooks/optionsHooks';
import { useFeatureFlags } from '../../hooks/settingsHooks';
import { monitoringModeOptions, projectChildrenModes } from '../../api/features/constants';

const ProjectTypesForm = props => {
  const { update } = props;
  const params = useParams();
  const { data, loading, error, formError } = useSelector(state => state.projectTypes.each);
  const [submittedValues, setSubmittedValues] = useState({});

  const defectProfilesListAll = defectProfilesListAllEndpoint.useEndpoint();
  const defectProfileOptions = useDefectProfiles();
  const assetTypesOptions = useAssetTypes();
  const { reportTypeOptions } = useReportTypes();
  const checklistTemplatesOptions = useChecklistTemplates();
  const locationLayerProfileOptions = useLocationLayerProfiles();
  const featureFlags = useFeatureFlags();
  const reportTypeQuery = { mode: 'PROJECT', is_active: true };
  const dispatch = useDispatch();
  const reportTypesListAll = reportTypesListAllEndpoint.useEndpoint();
  const checklistTemplatesAll = checklistTemplatesBatchListEndpoint.useEndpoint();

  useEffect(() => {
    dispatch(getAllAssetTypes());
    reportTypesListAll.dispatchRequest(reportTypeQuery);
    checklistTemplatesAll.dispatchRequest({ type: ['ALL', 'PROJECT'] });
    defectProfilesListAll.dispatchRequest();
    dispatch(getAllLocationLayerProfiles());
    if (update && params.id) {
      dispatch(getProjectTypes(params.id));
    } else {
      dispatch(clearProjectTypes());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formSettings = {
    fieldOrder,
    removeField: removeField(update ? 'UPDATE' : 'CREATE'),
    hideField: (values, item) => {
      return hideField(values, item, featureFlags);
    },
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      switch (key) {
        /* ---------- Required Fields ---------- */
        case 'asset_types':
          fieldSettings[key] = {
            type: 'multi',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              required: true,
              options: assetTypesOptions,
              labelwidth: 80,
            },
          };
          break;
        case 'checklist_template':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              required: true,
              options: checklistTemplatesOptions,
              labelwidth: 120,
            },
          };
          break;
        case 'defect_profile':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: 'Finding Profile',
              name: key,
              options: defectProfileOptions.concat(data.defect_profile ? { label: noneOption, value: undefined } : []),
              labelwidth: 85,
            },
          };
          break;

        case 'layer_profile':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              options: locationLayerProfileOptions.concat(
                data.layer_profile ? { label: noneOption, value: undefined } : []
              ),
              labelwidth: 80,
            },
          };
          break;
        case 'monitoring_mode':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              options: monitoringModeOptions,
              labelwidth: 110,
            },
          };
          break;
        /* ---------- Regular Fields ---------- */
        case 'is_active':
        case 'track_tasks':
          fieldSettings[key] = {
            type: 'checkbox',
            cellProps: {
              xs: 6,
            },
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          break;
        case 'affects_asset_condition':
          fieldSettings[key] = {
            type: 'checkbox',
            cellProps: {
              xs: 6,
            },
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          break;
        case 'allow_asset_selection_on_findings':
          fieldSettings[key] = {
            type: 'checkbox',
            cellProps: {
              xs: 6,
            },
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          break;
        case 'report_types':
          fieldSettings[key] = {
            type: 'multi',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              options: reportTypeOptions,
              labelwidth: 84,
            },
          };
          break;
        case 'project_children_mode':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              options: projectChildrenModes,
              labelwidth: 140,
            },
          };
          break;
        case 'project_name_template':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              labelwidth: 150,
            },
          };
          break;
        case 'project_description_template':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              labelwidth: 180,
            },
          };
          break;
        case 'planned_duration':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              labelwidth: 100,
            },
          };
          break;

        /* ----------  Disabled Fields ---------- */
        case 'created_on':
        case 'updated_on':
          fieldSettings[key] = {
            type: 'display',
            fieldProps: {
              disabled: true,
              label: jsonKeyToLabel(key),
              name: key,
              value: apiDateToString(data[key], 'date'),
            },
          };
          break;
        /* -----------  Default ----------- */
        default:
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
      }
    });
  }

  const title = update ? 'Edit Project Type' : 'New Project Type';

  let initialValues = { asset_types: [], report_types: [] };
  if (update) {
    initialValues = {
      ...data,
      // eslint-disable-next-line camelcase
      asset_types: data.asset_types ? data.asset_types.map(asset_type => asset_type.id.toString()) : [],
      // eslint-disable-next-line camelcase
      report_types: data.report_types ? data.report_types.map(report_type => report_type.id.toString()) : [],
      // eslint-disable-next-line camelcase
      checklist_template: data.checklist_template && data.checklist_template.id.toString(),
      defect_profile: data.defect_profile && data.defect_profile.id.toString(),
      layer_profile: data.layer_profile && data.layer_profile.id.toString(),
      affects_asset_condition: update ? data.affects_asset_condition : true,
    };
  }

  const onSubmit = changedValues => {
    const updatedValues = { ...changedValues };
    setSubmittedValues(updatedValues);
    if (update) {
      dispatch(updateProjectTypes(updatedValues.id, updatedValues));
    } else {
      dispatch(createProjectTypes(updatedValues));
    }
  };

  /**
   * Decorators are used for setting the values of other fields based off of a field.
   * Decorator format can be found here: src/components/shared/form/common.js
   */
  const decorators = [];

  /**
   *  Validations that are per field.
   *  Errors associated with the form are passed back through formError in Redux.
   */
  const validate = values => {
    const errors = {};
    if (values.defect_profile && !values.layer_profile) {
      errors.layer_profile = '*Required when Findings Profile Set.';
    } else if (!values.defect_profile && values.layer_profile) {
      errors.defect_profile = '*Required when Location Layer Profile Set.';
    }
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        if (values[key] === submittedValues[key]) {
          errors[key] = value;
        } else {
          errors[key] = undefined;
        }
      }
    }
    return errors;
  };

  return (
    <CommonForm
      update={update}
      title={title}
      initialValues={initialValues}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      onSubmit={onSubmit}
      decorators={decorators}
      validate={validate}
      loading={loading}
      error={error}
    />
  );
};

ProjectTypesForm.defaultProps = {
  update: false,
};

ProjectTypesForm.propTypes = {
  update: PropTypes.bool,
};

export default ProjectTypesForm;
