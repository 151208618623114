import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import { getAllAssessments } from '../../store/features/assessmentsActions';
import AssessmentsTable from './AssessmentsTable';
import AssessmentsRiskRollupMatrix from './AssessmentsRiskRollupMatrix';
import { tableViews } from '../../utilities/tables';
import { useTableViewSettings } from '../../hooks/settingsHooks';

const AssessmentDescendants = ({ assessment }) => {
  const dispatch = useDispatch();
  const assetId = assessment.asset.id;

  const { assessmentsTableViewKey } = tableViews;
  const defaultAssessmentsQuery = {
    asset__descendants: assetId,
  };

  const {
    tableQuery: storedAssessmentsQuery,
    embeddedQuery: assessmentsEmbeddedQuery,
    page: assessmentsPage,
    views: assessmentsView,
  } = useTableViewSettings(assessmentsTableViewKey, defaultAssessmentsQuery);

  // eslint-disable-next-line camelcase
  const { loading, risk_matrix_rollup } = useSelector(state => state.assessments.all.dataAll);

  useEffect(() => {
    dispatch(getAllAssessments(storedAssessmentsQuery));
  }, [dispatch, assetId]); // eslint-disable-line react-hooks/exhaustive-deps

  const assessmentsTableChangeHandler = query => {
    const updatedQuery = { ...assessmentsEmbeddedQuery, ...query };
    dispatch(getAllAssessments(updatedQuery));
  };

  const renderMatrix = (matrix, label) => {
    if (matrix?.length) {
      return (
        <Grid item>
          <h3>{label}</h3>
          <AssessmentsRiskRollupMatrix matrix={matrix} />
        </Grid>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div data-testid="assessment-descendants">
      {loading ? (
        <>Loading...</>
      ) : (
        <Grid container>
          {renderMatrix(risk_matrix_rollup?.quantitative, 'Quantitative')}
          {renderMatrix(risk_matrix_rollup?.area, 'Area')}
          {renderMatrix(risk_matrix_rollup?.financial, 'Financial')}
        </Grid>
      )}
      <AssessmentsTable
        title="Assessments"
        embedded
        tableChangeHandler={assessmentsTableChangeHandler}
        queryParamObj={storedAssessmentsQuery}
        views={assessmentsView}
        page={assessmentsPage}
        // className={classes.table}  TODO />
      />
    </div>
  );
};

AssessmentDescendants.propTypes = {
  assessment: PropTypes.object.isRequired,
};

export default AssessmentDescendants;
