import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { fieldOrder, removeField, hideField } from './FormsShared';
import { apiDateToString, capitalizeFirstChar, jsonKeyToLabel } from '../../../utilities/strings';
import CommonFormView from '../../shared/form/CommonView';
import ChecklistFlagsColumn from '../ChecklistFlagsColumn';
import { ensureArray } from '../../../utilities/arrays';
import { getNestedProperty } from '../../../utilities/objects';
import { useFeatureFlags } from '../../../hooks/settingsHooks';
import ProjectLinkDisplay from '../ProjectLinkDisplay';

const useStyles = makeStyles(theme => ({
  formsView: {
    margin: theme.spacing(0, 4, 5),
  },
}));

const FormView = props => {
  const { id, data, loading, error } = props;
  const classes = useStyles();
  const { hasChecklistLocking } = useFeatureFlags();
  const formSettings = {
    fieldOrder,
    removeField: removeField('VIEW'),
    hideField: (values, item) => {
      return hideField(values, item, { hasChecklistLocking });
    },
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  const customLabels = { 'template.name': 'Name', 'template.categories': 'Categories', 'asset.owner': 'Company' };
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      // cast to string because booleans do not show up in the field
      let displayLabel = key in customLabels ? customLabels[key] : jsonKeyToLabel(key); // eslint-disable-line prefer-const
      let displayValue = '';
      if (data[key] != null || (key.includes('.') && getNestedProperty(data, key) != null)) {
        switch (key) {
          // add cases for each key that needs to be customized.
          case 'template.name':
            displayValue = data.template.name;
            break;
          case 'template.categories':
            displayValue = ensureArray(data.template.categories).join(',');
            break;
          case 'asset':
            displayValue = data[key].name;
            break;
          case 'asset.owner':
            displayValue = data.asset.owner.name;
            break;
          case 'project':
            fieldSettings[key] = {
              type: 'component',
              cellProps: {
                xs: 6,
              },
              fieldProps: {
                label: 'Project',
                value: () => (
                  <ProjectLinkDisplay orderIndex={data.project_order_index} id={data[key].id} name={data[key].name} />
                ),
              },
            };
            return;
          case 'flags':
            fieldSettings[key] = {
              type: 'component',
              cellProps: {
                xs: 6,
              },
              fieldProps: {
                label: 'Flags',
                value: () => <ChecklistFlagsColumn flags={data[key]} />,
              },
            };
            return;

          case 'created_by':
            displayValue = data[key].name;
            break;
          case 'work_done_on':
          case 'updated_on':
          case 'created_on':
            displayValue = apiDateToString(data[key], 'date');
            break;
          case 'is_locked':
            displayLabel = 'Locked';
            displayValue = capitalizeFirstChar(String(data[key]));
            break;
          default:
            displayValue = key.includes('.') ? getNestedProperty(data, key).toString() : data[key].toString();
        }
      }
      fieldSettings[key] = {
        type: 'text',
        fieldProps: {
          label: displayLabel,
          value: displayValue,
        },
      };
    });
  }

  return (
    <div className={classes.formsView}>
      <CommonFormView
        bodyOnly
        title={'Form Details'}
        values={data}
        fieldSettings={fieldSettings}
        formSettings={formSettings}
        editLink={`/forms/${id}/edit`}
        loading={loading}
        error={error}
      />
    </div>
  );
};

FormView.defaultProps = {
  data: { template: {} },
};

FormView.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default FormView;
