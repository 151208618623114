import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getLocationLayerProfiles } from '../../store/features/locationLayerProfilesActions';
import { fieldOrder, removeField, hideField } from './locationLayerProfilesShared';
import {
  zoneModeOptions,
  componentModeOptions,
  geoModeOptions,
  documentStateOptions,
} from '../../api/features/constants';
import { apiDateToString, jsonKeyToLabel, displayOption } from '../../utilities/strings';
import CommonFormView from '../shared/form/CommonView';
import { useFeatureFlags } from '../../hooks/settingsHooks';

const LocationLayerProfilesView = () => {
  const id = useParams().id;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLocationLayerProfiles(id));
  }, [dispatch, id]);
  const { hasAccessDisplay } = useFeatureFlags();

  const { data, loading, error } = useSelector(state => state.locationLayerProfiles.each);

  const formSettings = {
    fieldOrder,
    removeField: removeField('VIEW'),
    hideField: (values, item) => {
      return hideField(values, item, hasAccessDisplay);
    },
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      // cast to string because booleans do not show up in the field
      let displayLabel = jsonKeyToLabel(key); // eslint-disable-line prefer-const
      let displayValue = '';
      if (data[key] != null) {
        switch (key) {
          // add cases for each key that needs to be customized.
          case 'geo_mode':
            displayValue = displayOption(data[key], geoModeOptions);
            break;
          case 'component_mode':
            displayValue = displayOption(data[key], componentModeOptions);
            break;
          case 'zone_mode':
          case 'code_mode':
          case 'access_mode':
            displayValue = displayOption(data[key], zoneModeOptions);
            break;
          case 'zone_options':
            fieldSettings[key] = {
              type: 'table',
              fieldProps: {
                label: displayLabel,
                colOrder: ['value', 'label'],
                data: data.zone_options
                  ? data.zone_options.map(row => ({
                      value: row.value,
                      label: row.label,
                    }))
                  : [],
              },
            };
            return;

          case 'code_options':
            fieldSettings[key] = {
              type: 'table',
              fieldProps: {
                label: displayLabel,
                colOrder: ['value', 'label'],
                data: data.code_options
                  ? data.code_options.map(row => ({
                      value: row.value,
                      label: row.label,
                    }))
                  : [],
              },
            };
            return;
          case 'access_options':
            fieldSettings[key] = {
              type: 'table',
              fieldProps: {
                label: displayLabel,
                colOrder: ['value', 'label'],
                data: data.access_options
                  ? data.access_options.map(row => ({
                      value: row.value,
                      label: row.label,
                    }))
                  : [],
              },
            };
            return;
          case 'component_options':
            fieldSettings[key] = {
              type: 'table',
              fieldProps: {
                label: displayLabel,
                colOrder: ['value', 'label'],
                data: data.component_options
                  ? data.component_options.map(row => ({
                      value: row.value,
                      label: row.label,
                    }))
                  : [],
              },
            };
            return;
          case 'state':
            displayValue = displayOption(data[key], documentStateOptions);
            break;
          case 'updated_on':
          case 'created_on':
            displayValue = apiDateToString(data[key], 'date');
            break;
          default:
            displayValue = data[key].toString();
        }
      }
      fieldSettings[key] = {
        type: 'text',
        fieldProps: {
          label: displayLabel,
          value: displayValue,
        },
      };
    });
  }

  return (
    <CommonFormView
      title={'Location Layer Profile Details'}
      values={data}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      editLink={`/location-layer-profiles/${id}/edit`}
      loading={loading}
      error={error}
    />
  );
};

export default LocationLayerProfilesView;
