import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { getAllInspectionMedia } from '../../../store/features/inspectionMediaActions';

import { useMediaStore } from '../../../hooks/mediaHooks';
import { tableViews } from '../../../utilities/tables';
import { useTableViewSettings, useTableViews } from '../../../hooks/settingsHooks';
import InspectionMediaTable from '../../inspectionMedia/InspectionMediaTable';

const MediaTab = props => {
  const { detailPageQuery, filename } = props;
  const { media, total, loading: mediaLoading } = useMediaStore();
  const dispatch = useDispatch();

  const { inspectionMediaTableViewKey } = tableViews;
  const { pageSize: mediaTableSize } = useTableViews(inspectionMediaTableViewKey);
  const defaultQuery = { limit: mediaTableSize, hidden: false, ...detailPageQuery };

  const inspectionMediaQuery = useTableViewSettings(inspectionMediaTableViewKey, defaultQuery);
  const mediaTableChangeHandler = query => {
    const updatedQuery = { ...inspectionMediaQuery.embeddedQuery, ...query };
    dispatch(getAllInspectionMedia(updatedQuery));
  };
  useEffect(() => {
    dispatch(getAllInspectionMedia(inspectionMediaQuery.tableQuery));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <InspectionMediaTable
      title="Inspection Media"
      loading={mediaLoading}
      data={media}
      count={total}
      embedded
      enableGallery={false}
      filename={filename}
      tableChangeHandler={mediaTableChangeHandler}
      page={inspectionMediaQuery.page}
      views={inspectionMediaQuery.views}
      queryParamObj={inspectionMediaQuery.tableQuery}
      isReadOnly
    />
  );
};

MediaTab.defaultProps = {
  filename: 'Media',
};

MediaTab.propTypes = {
  detailPageQuery: PropTypes.object.isRequired,
  filename: PropTypes.string,
};

export default MediaTab;
