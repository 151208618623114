import { queriesFromString } from './strings';

export const isEmpty = val => {
  if (val == null || val === undefined) {
    return true;
  }
  switch (val.constructor) {
    case Number:
      return true;
    case Array:
    case String:
      return val.length === 0;
    case Object:
      return Object.keys(val).length === 0;
  }
};

export const getLabel = (value, options) => {
  const optionObject = options ? options.find(item => String(item.value) === String(value)) : {};
  if (optionObject) {
    return optionObject.label;
  }
  return '';
};

export const getNestedProperty = (obj, path) => {
  if (typeof path !== 'string' || typeof obj !== 'object' || isEmpty(obj) || !path.length) {
    return null;
  }
  return path.split('.').reduce((acc, partialKey) => acc && acc[partialKey], obj) || null;
};

export const setTableParamObj = (location, size) => {
  if (!location.search && size) {
    return { limit: size, offset: 0 };
  }
  return queriesFromString(location.search, [], true);
};
