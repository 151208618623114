import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { DEFAULT_PAGE_SIZE } from '../utilities/tables';
import { queriesFromString, pathToPage } from '../utilities/strings';

/**
 * @typedef {Object} PERMISSIONS
 *
 *  to regenerate this list, open python shell (`make shell_plus`) and run:
 *
 *    ```python
 *    from huvr_api.common.roles import PERMISSIONS
 *    for p, d in PERMISSIONS:
 *        print(f" * @property {{boolean=}} has{p.title().replace('_', '')} {d}")
 *    ```
 *
 *  (`{boolean=}` means it is optional, will be either `true` or not set)
 *
 * @property {boolean=} hasAssessmentCreate Assessment Create - (i.e. can access RBLX)
 * @property {boolean=} hasAssessmentDelete Assessment Delete
 * @property {boolean=} hasAssessmentEdit Assessment Edit
 * @property {boolean=} hasAssessmentView Assessment View - (i.e. can view embedded RBLX dashboards)
 * @property {boolean=} hasAssetCreate Asset Create
 * @property {boolean=} hasAssetDelete Asset Delete
 * @property {boolean=} hasAssetEdit Asset Edit
 * @property {boolean=} hasAssetManageAll Asset Manage All -- asset viewership across companies
 * @property {boolean=} hasAssetView Asset View - Browse assigned assets
 * @property {boolean=} hasChecklistCreate Checklist Create -- Allows creation of Forms
 * @property {boolean=} hasChecklistEdit Checklist Edit - Fill out checklist
 * @property {boolean=} hasChecklistLock Checklist Lock - Lock Checklist and Prevent others from editing
 * @property {boolean=} hasChecklistReview Checklist Review - View old revisions
 * @property {boolean=} hasChecklistUnlock Checklist Unlock - Unlock Checklist and Allow others to edit
 * @property {boolean=} hasCompanyCreate Company Create
 * @property {boolean=} hasCompanyDelete Company Delete
 * @property {boolean=} hasCompanyEdit Company Edit
 * @property {boolean=} hasCompanyManageAll Company Manage All - Access to all companies
 * @property {boolean=} hasCompanyView Company View
 * @property {boolean=} hasConfigurationManagementBuild Configuration Management Build
 * @property {boolean=} hasConfigurationManagementView Configuration Management View
 * @property {boolean=} hasCrewCreate Crew Create
 * @property {boolean=} hasCrewDelete Crew Delete
 * @property {boolean=} hasCrewEdit Crew Edit
 * @property {boolean=} hasCrewManage Crew Manage for assigned company
 * @property {boolean=} hasCrewManageAll Crew Manage All - Access across companies
 * @property {boolean=} hasDashboardView Dashboard access for assigned company
 * @property {boolean=} hasDashboardViewAll Dashboard Access All
 * @property {boolean=} hasDefectCreate Defect Create
 * @property {boolean=} hasDefectDelete Defect Delete
 * @property {boolean=} hasDefectEdit Defect Edit
 * @property {boolean=} hasDefectEditProtected Defect Link/Unlink, Edit after marked 'OPEN'
 * @property {boolean=} hasDefectManageAll Defect Manage All
 * @property {boolean=} hasDefectView Defect View - Browse defects
 * @property {boolean=} hasInspectionMediaBulkEdit Inspection Media Bulk Edit
 * @property {boolean=} hasInspectionMediaExtractTextView Inspection Media Extract Text View
 * @property {boolean=} hasInspectionMediaMetaView Inspection Media Meta View
 * @property {boolean=} hasLibraryMediaCreate Library Media Create
 * @property {boolean=} hasLibraryMediaDelete Library Media Delete
 * @property {boolean=} hasLibraryMediaEdit Library Media Edit
 * @property {boolean=} hasLibraryMediaView Library Media View
 * @property {boolean=} hasNotificationRuleCreate Notification Rule Create
 * @property {boolean=} hasNotificationRuleDelete Notification Rule Delete
 * @property {boolean=} hasNotificationRuleManage Notification Rule Manage
 * @property {boolean=} hasProcessDataEdit Process Data Edit -            (i.e. HWI Heat Data)
 * @property {boolean=} hasProcessDataImport Process Data Import -        (i.e. HWI Heat Data)
 * @property {boolean=} hasProcessDataManageAll Process Data Manage All - (i.e. HWI Heat Data)
 * @property {boolean=} hasProcessDataView Process Data View -            (i.e. HWI Heat Data)
 * @property {boolean=} hasProjectAssetConditionEdit Project Asset Condition Edit
 * @property {boolean=} hasProjectCreate Project Create
 * @property {boolean=} hasProjectDelete Project Delete
 * @property {boolean=} hasProjectEdit Project Edit General Info
 * @property {boolean=} hasProjectEditCompany Project Assign Service Company
 * @property {boolean=} hasProjectManage Project Manage assigned company
 * @property {boolean=} hasProjectManageAll Project Manage - across companies
 * @property {boolean=} hasProjectShare Project Share
 * @property {boolean=} hasProjectStatusEdit Project Status Change
 * @property {boolean=} hasProjectStatusPublish Project Status Publish
 * @property {boolean=} hasProjectStatusUnpublish Project Status Un-Publish
 * @property {boolean=} hasReservationCreate Reservation Create
 * @property {boolean=} hasReservationManage Reservation Manage
 * @property {boolean=} hasScheduleEventManage Schedule Event Manage
 * @property {boolean=} hasScheduleEventView Schedule Event View-only
 * @property {boolean=} hasSchedulePlanCreate Schedule Plan Create
 * @property {boolean=} hasSchedulePlanDelete Schedule Plan Delete
 * @property {boolean=} hasSchedulePlanEdit Schedule Plan Edit
 * @property {boolean=} hasSchedulePlanManage Schedule Plan Manage
 * @property {boolean=} hasScheduleTaskEdit Schedule Task Edit
 * @property {boolean=} hasTaskManage Checklist Task Manage - delete other users tasks
 * @property {boolean=} hasUnzipMedia Unzip Media
 * @property {boolean=} hasUserCreate User Create
 * @property {boolean=} hasUserDelete User Delete
 * @property {boolean=} hasUserEdit User Edit
 * @property {boolean=} hasUserManageAll User Manage All
 * @property {boolean=} hasUserRolesAll User Roles All
 * @property {boolean=} hasWorkspaceView Workspace View
 */

/**
 * @returns {PERMISSIONS}
 */
export function usePermissions() {
  return useSelector(state => state.settings.features.workspace.permissions);
}

/**
 * @typedef {Object} FEATURES

 *  to regenerate this list, open python shell (`make shell_plus`) and run:
 *
 *    ```python
 *    from huvr_api.workspaces.choices import FEATURES
 *    for f, d in FEATURES:
 *        print(f' * @property {{boolean=}} has{f.title().replace("_", "")} {d}')
 *    ```
 *
 *  (`{boolean=}` means it is optional, will be either `true` or not set)
 *
 *
 * @property {boolean=} hasAccessDisplay Show access value and access display value
 * @property {boolean=} hasAllReports All Reports - enable all reports for demo accounts
 * @property {boolean=} hasCustomThemes Custom Theme - enable reports to select a custom theme.
 * @property {boolean=} hasAssetAssessments Asset Assessments - enable asset assessments (i.e. RBLX integration)
 * @property {boolean=} hasAssetCondition Asset Condition - enable asset condition tracking
 * @property {boolean=} hasAssetImport Asset Import Wizard
 * @property {boolean=} hasAssetLibrary Asset Library - enable asset specific media sync
 * @property {boolean=} hasAssetSearchSpeedup Search Assets without using FullTextSearch for better speed
 * @property {boolean=} hasAssetTreeView Asset Tree View
 * @property {boolean=} hasBookmarks Bookmarks
 * @property {boolean=} hasBulkProjectShare Bulk Project Share
 * @property {boolean=} hasChecklistAny Checklists Anytime/Anywhere (Forms)
 * @property {boolean=} hasChecklistCalculationLineType Calculation line types for checklist
 * @property {boolean=} hasChecklistCollectPositionWeb Collect Position for web
 * @property {boolean=} hasChecklistDelete Checklist Deletion
 * @property {boolean=} hasChecklistEditorBeta Checklist Editor BETA
 * @property {boolean=} hasChecklistLocking Checklist Locking
 * @property {boolean=} hasChecklistLockAuto Nightly Auto-Lock Checklists
 * @property {boolean=} hasChecklistTasks Checklist Follow Up Tasking
 * @property {boolean=} hasChecklistTasksPage Checklist Tasks Page
 * @property {boolean=} hasChecklistTemplateEditYaml Checklist Template Edit Yaml
 * @property {boolean=} hasChecklistInstancePermissions Checklist Instance Permissions enhancements
 * @property {boolean=} hasChecklistViewer Checklist Viewer UI
 * @property {boolean=} hasCompanyManagerRoles Company Manager Roles
 * @property {boolean=} hasCmlDashboard CML Dashboard
 * @property {boolean=} hasCmlImport CML Import Wizard
 * @property {boolean=} hasCmlMedia CML Media
 * @property {boolean=} hasConditionMonitoring Condition Monitoring includes Ultratransonic Testing
 * @property {boolean=} hasDebugAuth Debug Auth - Log extra AUTH related data! (DEBUG only)
 * @property {boolean=} hasDicomViewer DICOM File Viewer
 * @property {boolean=} hasDynamicPages Dynamic Pages
 * @property {boolean=} hasDefectDashboard Findings/Defect Dashboard
 * @property {boolean=} hasFindingsBulkEdit Findings Bulk Edit
 * @property {boolean=} hasFindingsLinking Findings Linking
 * @property {boolean=} hasFindingsPrioritization Findings Prioritization
 * @property {boolean=} hasFindingsToProjects Findings To Projects
 * @property {boolean=} hasFindingsInInspectflow Findings Enabled in InspectFlow
 * @property {boolean=} hasFindingsSelectedInInspectflow Selected Findings Enabled in InspectFlow
 * @property {boolean=} hasFormsToProjects Forms to Projects
 * @property {boolean=} hasFormsReportInstance Forms Report Instance
 * @property {boolean=} hasInspectflowBluetoothLogin InspectFlow: Enable Bluetooth Login for RealWear
 * @property {boolean=} hasInspectflowBleDeviceMeasurement InspectFlow: Enable BLE Measurement Devices
 * @property {boolean=} hasInspectionMediaGalleryTable Inspection Media Gallery Table // deprecated: will be removed
 * @property {boolean=} hasInspectionMediaMap Inspection Media Map
 * @property {boolean=} hasInspectionMediaBulkEdit Inspection Media Bulk Edit
 * @property {boolean=} hasInspectionMediaExtractTextView Inspection Media Extract Text View
 * @property {boolean=} hasInspectionMediaMetaView Inspection Media Meta View
 * @property {boolean=} hasLead LEAD Analytics - Leading Edge Erosion Diagnostics
 * @property {boolean=} hasMapLinkGoogle Map Link Google Maps for Geo Points
 * @property {boolean=} hasMeasurementImport Measurement Import Wizard
 * @property {boolean=} hasNotificationsInInspectflow In App Notifications Enabled in InspectFlow
 * @property {boolean=} hasNotifyRuleManagement Notification Rule Management
 * @property {boolean=} hasProcessData Process Data
 * @property {boolean=} hasProcessDataImport Process Data Import
 * @property {boolean=} hasProjectBulkEdit Project Bulk Edit
 * @property {boolean=} hasProjectBulkEditStatus Project Bulk Edit Status
 * @property {boolean=} hasProjectFilterCompletedV2 Project Filter Completed V2
 * @property {boolean=} hasProjectChildren Project Children Enabled
 * @property {boolean=} hasProjectChildrenInInspectflow Project Children Enabled in InspectFlow
 * @property {boolean=} hasProjectSecondaryForms Project Secondary Forms
 * @property {boolean=} hasProjectDetailInInspectflow Project Detail Page Enabled in InspectFlow
 * @property {boolean=} hasProjectExport Project Export
 * @property {boolean=} hasProjectImport Project Import Wizard
 * @property {boolean=} hasProjectLabels Project Labels
 * @property {boolean=} hasProjectMap Project Map
 * @property {boolean=} hasProjectPriority Project Priority
 * @property {boolean=} hasProjectReportInstance Project Report Instance
 * @property {boolean=} hasProjectScopes Project Scopes
 * @property {boolean=} hasProjectScopeLibrary Project Scope Library
 * @property {boolean=} hasProjectSecondaryForms Project Secondary Forms
 * @property {boolean=} hasProjectTasks Project Follow Up Tasking
 * @property {boolean=} hasReservations Reservations - Scheduling
 * @property {boolean=} hasRweObservationAnalytics Observation Analytics (RWE Custom)
 * @property {boolean=} hasSchedule Schedule
 * @property {boolean=} hasTransferJobs Transfer Jobs (SFTP)
 * @property {boolean=} hasUnzipMedia Unzip Media
 * @property {boolean=} hasUserRegistration Allow User Registration
 * @property {boolean=} hasUsersAssignCompany Users Assign Company
 * @property {boolean=} hasUsersBulkCreate Users Bulk Create
 * @property {boolean=} hasValidatorsInInspectflow Validators InspectFlow - Enable Validators in Checklists on InspectFlow
 * @property {boolean=} hasViInInspectflow Visual Inspector - Enable in Inspect Flow
 * @property {boolean=} hasVIMapView Visual Inspector - Location Context Map View
 * @property {boolean=} hasViVersion2 Visual Inspector Version 2
 * @property {boolean=} hasVideoEncoding Video conversion to streaming
 * @property {boolean=} hasVideoExtractFrame Capture video frames to image
 * @property {boolean=} hasVoiceToText Convert audio streams to text transcripts
 */

/**
 * @returns {FEATURES}
 */
export function useFeatureFlags() {
  return useSelector(state => state.settings.features.workspace.features);
}

export function useFeaturePages() {
  return useSelector(state => state.settings.features.workspace.pages);
}

export function useFeatureThemes() {
  const themes = useSelector(state => state.settings.features.workspace.themes);
  if (Array.isArray(themes)) {
    const themeOptions = [{ label: 'default', value: 'default' }];
    themes.forEach(theme => {
      themeOptions.push({ label: theme, value: theme });
    });
    return themeOptions;
  }
  return [];
}

export function useTableViews(view, page = '') {
  const tableView = useSelector(state => {
    try {
      return state.settings[view] || { pageSize: DEFAULT_PAGE_SIZE };
    } catch (error) {
      Sentry.captureMessage(
        `${error} Error retrieving tableView ${view}. Make sure this view has been added to views reducer.`
      );
      return { pageSize: DEFAULT_PAGE_SIZE };
    }
  });
  if (tableView[page]) {
    return tableView[page];
  } else {
    return tableView;
  }
}

// this will eventually replace/integrate useTableViews
const useTableSettings = (view, page) => {
  return useSelector(state => {
    try {
      const tableSetting = state.settings.tables[view][page];
      if (tableSetting) return tableSetting;
      return { query: {}, pageSize: DEFAULT_PAGE_SIZE };
    } catch (error) {
      return { query: {}, pageSize: DEFAULT_PAGE_SIZE };
    }
  });
};

export const checkForQueryString = storedQuery => {
  if (typeof storedQuery === 'string') {
    return queriesFromString(storedQuery, [], true);
  }
  return storedQuery;
};

/**
 * helps prevent old default values from getting "stuck" in redux store table settings
 *
 * @param {Array<string>} excludeArray - filters that should be removed/excluded from the request
 * @param {Object} queries - query object to be turned into querystring
 * @return {Object} new sanitized query object
 */
export const sanitizeQuery = (excludeArry, queries) => {
  if (!excludeArry || !excludeArry.length) return queries;
  const obj = { ...queries };
  excludeArry.forEach(filter => {
    if (filter in obj) {
      delete obj[filter];
    }
  });
  return obj;
};

export function useTableViewSettings(view, defaultQuery = {}, excludedFilters) {
  const location = useLocation();
  const page = pathToPage(location.pathname);
  const views = useTableSettings(view, page); // rename to settings?
  const { query: storedQuery, pageSize } = views;

  const embeddedQuery = { limit: pageSize, ...defaultQuery };

  // check for lingering stored query strings
  const reduxQuery = checkForQueryString(storedQuery);

  const tableQuery = { ...reduxQuery, ...embeddedQuery };
  let _tableQuery = tableQuery;
  if (excludedFilters) {
    _tableQuery = sanitizeQuery(excludedFilters, tableQuery);
  }

  return {
    embeddedQuery,
    tableQuery: _tableQuery,
    views,
    page,
  };
}
