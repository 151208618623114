import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(1),
    fontSize: theme.spacing(3),
    textAlign: 'left',
  },
  loadingIcon: {
    margin: theme.spacing(0, 2, 0, 2),
    position: 'relative',
    top: '3px',
  },
}));

const Title = props => {
  const { title, loading } = props;
  const classes = useStyles();
  return (
    <>
      <p className={`h7 ${classes.root}`}>
        <span>{title}</span>
      </p>
      {loading && <LinearProgress size={18} color="primary" className={classes.loadingIcon} />}
    </>
  );
};

Title.defaultProps = {
  loading: false,
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

export default Title;
