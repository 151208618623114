import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ButtonGroup } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonIcon from '../shared/buttons/ButtonIcon';
import Table from '../shared/table/Table';
import { setWatchersTableView, setWatchersTableSize } from '../../store/settings/views/watchersTableViewRedux';
import StyledLink from '../shared/StyledLink';
import { setColumns } from '../shared/table/columns';
import { openDialog } from '../../store/dialogActions';
import DeleteDialog from '../shared/Dialog/DeleteDialog';
import { useTableViews } from '../../hooks/settingsHooks';
import { tableViews } from '../../utilities/tables';
import { updateShareProject } from '../../store/features/projectsActions';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const Watchers = props => {
  const { title, clientData, styleProjectDetail, embedded } = props;
  const dispatch = useDispatch();
  const { clientData: data, loading, count, projectId } = { ...clientData };

  const { watchersTableViewKey } = tableViews;
  const views = useTableViews(watchersTableViewKey);

  /** @type {Column[]} */
  const columns = [
    {
      // This needs to be first and excluded so customBodyRender can access the id to create the link.
      name: 'id',
      label: 'Id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        filterType: 'textField',
        customBodyRender: (value, tableMeta) => {
          return <StyledLink to={`/users/${tableMeta.rowData[columns.findIndexByName['id']]}`} value={value} />;
        },
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: true,
        filterType: 'textField',
      },
    },
    {
      name: 'first_name',
      label: 'First Name',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'last_name',
      label: 'Last Name',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'is_active',
      label: 'Is Active',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => (value ? 'True' : 'False'),
      },
    },
    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        print: false,
        viewColumns: false,
        customBodyRender: (_, info) => {
          const id = info.rowData[columns.findIndexByName['id']];
          const name = info.rowData[columns.findIndexByName['display_name']];
          return (
            <>
              <ButtonGroup>
                {
                  <ButtonIcon
                    icon={DeleteIcon}
                    onClick={() => {
                      dispatch(
                        openDialog(
                          'Remove this user from watchers?',
                          <DeleteDialog
                            id={projectId}
                            deleteAction={updateShareProject}
                            name={name}
                            query={{ user_id: id, watch: false }}
                          />
                        )
                      );
                    }}
                  />
                }
              </ButtonGroup>
            </>
          );
        },
      },
    },
  ];

  // handle columns display
  setColumns(columns, views);

  /** @type {TableOptions} */
  const options = {
    // if the column view changes, update redux with either 'add' or 'remove'
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setWatchersTableView(changedColumn, action));
    },
    onChangeRowsPerPage: numberOfRows => {
      dispatch(setWatchersTableSize(numberOfRows));
    },
    enableNestedDataAccess: '.',
    searchOpen: !embedded,
  };

  return (
    <div className={styleProjectDetail}>
      <Table
        simpleSearch
        title={title}
        columns={columns}
        data={data}
        count={count}
        options={options}
        loading={loading}
        embedded={embedded}
      />
    </div>
  );
};

Watchers.defaultProps = {
  clientData: undefined,
  embedded: true,
  styleProjectDetail: '',
};

Watchers.propTypes = {
  title: PropTypes.string.isRequired,
  clientData: PropTypes.object,
  styleProjectDetail: PropTypes.string,
  embedded: PropTypes.bool,
};

export default Watchers;
