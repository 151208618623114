import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { truncate } from '../../../utilities/strings';
import Markdown from '../Markdown';

const useStyles = makeStyles(theme => ({
  description: {
    fontSize: '14px',
    lineHeight: '1.8',
  },
  textContainer: {
    width: '15vw',
    '& > *': {
      fontSize: '12px',
    },
    // ellipsis effect on table cell for long descriptions
    maxWidth: 200,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const Description = ({ value }) => {
  const classes = useStyles();

  return (
    <Tooltip placement="bottom" title={<span className={classes.description}>{<Markdown>{value}</Markdown>}</span>}>
      <div className={classes.textContainer}>
        <Markdown>{truncate(value, 120)}</Markdown>
      </div>
    </Tooltip>
  );
};

Description.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Description;
