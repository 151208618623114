export const CMLS_REQUEST = 'CMLS_REQUEST';
export const CMLS_SUCCESS = 'CMLS_SUCCESS';
export const CMLS_FAILURE = 'CMLS_FAILURE';
export const CMLS_CREATE_REQUEST = 'CMLS_CREATE_REQUEST';
export const CMLS_CREATE_SUCCESS = 'CMLS_CREATE_SUCCESS';
export const CMLS_CREATE_FAILURE = 'CMLS_CREATE_FAILURE';
export const CMLS_UPDATE_REQUEST = 'CMLS_UPDATE_REQUEST';
export const CMLS_UPDATE_SUCCESS = 'CMLS_UPDATE_SUCCESS';
export const CMLS_UPDATE_FAILURE = 'CMLS_UPDATE_FAILURE';
export const CMLS_DELETE_REQUEST = 'CMLS_DELETE_REQUEST';
export const CMLS_DELETE_SUCCESS = 'CMLS_DELETE_SUCCESS';
export const CMLS_DELETE_FAILURE = 'CMLS_DELETE_FAILURE';
export const CMLS_ALL_REQUEST = 'CMLS_ALL_REQUEST';
export const CMLS_ALL_SUCCESS = 'CMLS_ALL_SUCCESS';
export const CMLS_ALL_FAILURE = 'CMLS_ALL_FAILURE';

export const getCmls = id => {
  return {
    type: CMLS_REQUEST,
    id,
  };
};

export const createCmls = (body, nextRoute) => {
  return {
    type: CMLS_CREATE_REQUEST,
    body,
    nextRoute,
  };
};

export const updateCmls = (id, body, nextRoute) => {
  return {
    type: CMLS_UPDATE_REQUEST,
    id,
    body,
    nextRoute,
  };
};

export const deleteCmls = id => {
  return {
    type: CMLS_DELETE_REQUEST,
    id,
  };
};

export const getAllCmls = query => {
  return {
    type: CMLS_ALL_REQUEST,
    query,
  };
};
