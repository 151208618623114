import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '../shared/table/Table';
import { setAssessmentTypesTableView } from '../../store/settings/views/assessmentTypesTableViewRedux';
import { deleteAssessmentTypes } from '../../store/features/assessmentTypesActions';
import { openDialog } from '../../store/dialogActions';
import { locationWithBack } from '../../utilities/route';
import StyledLink from '../shared/StyledLink';
import DeleteDialog from '../shared/Dialog/DeleteDialog';
import { setColumns } from '../shared/table/columns';
import { useTableViews } from '../../hooks/settingsHooks';
import { tableViews } from '../../utilities/tables';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const AssessmentTypesTable = props => {
  const { title } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { loading, data } = useSelector(state => ({
    loading: state.assessmentTypes.all.loading,
    data: state.assessmentTypes.all.dataAll.results,
  }));
  const { assessmentTypesTableViewKey } = tableViews;
  const views = useTableViews(assessmentTypesTableViewKey);

  /** @type {Column[]} */
  const columns = [
    {
      // This needs to be first and excluded so customBodyRender can access the id to create the link.
      name: 'id',
      label: 'Id',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <StyledLink to={`/assessment-types/${tableMeta.rowData[columns.findIndexByName['id']]}`} value={value} />
          );
        },
      },
    },
    {
      name: 'engine',
      label: 'Engine',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'risk_mtrix',
      label: 'Risk Matrix',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        print: false,
        viewColumns: false,
        customBodyRender: (_, info) => {
          // 0 is the first element in column
          const id = info.rowData[columns.findIndexByName['id']];
          const name = info.rowData[columns.findIndexByName['name']];
          return (
            <>
              <EditIcon
                fontSize="small"
                onClick={() => {
                  history.push(locationWithBack({ pathname: `/assessment-types/${id}/edit` }, location));
                }}
              />
              <DeleteIcon
                fontSize="small"
                onClick={() => {
                  dispatch(
                    openDialog(
                      'Delete Assessment Types?',
                      <DeleteDialog id={id} deleteAction={deleteAssessmentTypes} name={name} />
                    )
                  );
                }}
              />
            </>
          );
        },
      },
    },
  ];

  // handle columns display
  setColumns(columns, views);

  /** @type {TableOptions} */
  const options = {
    // if the column view changes, update redux with either 'add' or 'remove'
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setAssessmentTypesTableView(changedColumn, action));
    },
    enableNestedDataAccess: '.',
  };

  return (
    <Table
      title={title}
      simpleSearch
      columns={columns}
      addRoute="/assessment-types/new"
      data={data}
      options={options}
      loading={loading}
    />
  );
};

AssessmentTypesTable.propTypes = {
  title: PropTypes.string.isRequired,
};

export default AssessmentTypesTable;
