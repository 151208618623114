// lib
import React from 'react';
import PropTypes from 'prop-types';

// util
import { tableViews } from '../../utilities/tables';
import { useTableViewSettings } from '../../hooks/settingsHooks';

// components
import CmlsTable from '../cmls/CmlsTable';
import { scopeModes } from './projectsShared';

/**
 * @param {*} props
 *
 * @returns {React.Component}
 */
const ProjectSelectedCmlsTable = props => {
  const { project } = props;
  const data = project.scope.selected_cmls;
  const count = project.scope.selected_cmls.length;
  const projectId = project.id;
  const { page, views } = useTableViewSettings(tableViews.cmlsTableViewKey);

  return (
    <CmlsTable
      embedded
      isReadOnly
      scopeMode={scopeModes.DEFAULT}
      scopeCount={count}
      scopeData={data}
      project={projectId}
      filename={`Project ${project.id} Selected CMLs`}
      serverSide={false}
      title="Selected CMLs"
      views={views}
      page={page}
    />
  );
};

ProjectSelectedCmlsTable.propTypes = {
  project: PropTypes.object,
};

export default ProjectSelectedCmlsTable;
