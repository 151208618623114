import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getProjects } from '../../store/features/projectsActions';
import { getAllCmls } from '../../store/features/cmlsActions';
import { measurementTypes, measurementStateOptions, unitOptions } from '../../api/features/constants';
import { jsonKeyToLabel, apiDateToString, queriesFromString, dateToApiDateTime } from '../../utilities/strings';
import { isEmpty } from '../../utilities/objects';
import { getMeasurements, createMeasurements, updateMeasurements } from '../../store/features/measurementsActions';
import CommonForm from '../shared/form/Common';
import { fieldOrder, removeField, hideField, offsetOptions } from './measurementsShared';

const MeasurementsForm = props => {
  const { update } = props;
  const params = useParams();
  const location = useLocation();
  const [submittedValues, setSubmittedValues] = useState({});
  const dispatch = useDispatch();
  const { data, loading, error, formError, cmlOptions, project } = useSelector(state => {
    const { data, loading, error, formError } = state.measurements.each;
    const { data: project } = state.projects.each;
    const { results: cmls } = state.cmls.all.dataAll;
    const cmlOptions = cmls
      ? cmls.map(cml => {
          const label = cml.name == null ? '' : cml.name;
          const value = cml.id.toString();
          return { label, value };
        })
      : [];

    return {
      data,
      loading: loading || state.projects.each.loading || state.cmls.all.loading,
      error,
      project,
      formError,
      cmlOptions,
    };
  });

  const offsetSelectOptions = offsetOptions.map(o => {
    const label = o;
    const value = o;
    return { label, value };
  });

  useEffect(() => {
    let queries = {};
    if (location.search) {
      queries = queriesFromString(location.search);
    }

    if (update && params.id) {
      dispatch(getMeasurements(params.id));
    }
    if (isEmpty(project) && queries.project_id) {
      dispatch(getProjects(queries.project_id));
    }
    if (project && project.asset) {
      dispatch(getAllCmls({ asset__asset_path_cache__path: project.asset.asset_path }));
    }
  }, [project]); // eslint-disable-line react-hooks/exhaustive-deps

  const formSettings = {
    fieldOrder,
    removeField: removeField(update ? 'UPDATE' : 'CREATE'),
    hideField,
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      switch (key) {
        /* ---------- Required Fields ---------- */
        case 'cml':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              options: cmlOptions,
              labelwidth: 72,
            },
          };
          break;
        case 'offset':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              options: offsetSelectOptions,
              labelwidth: 72,
            },
          };
          if (update) {
            fieldSettings[key].type = 'text';
          }
          break;
        case 'type':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              options: measurementTypes,
              labelwidth: 72,
            },
          };
          break;
        case 'units':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              options: unitOptions,
              labelwidth: 72,
            },
          };
          break;
        case 'collected_on':
          fieldSettings[key] = {
            type: 'datetime',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          break;
        /* ---------- Regular Fields ---------- */
        case 'state':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              options: measurementStateOptions,
              labelwidth: 72,
            },
          };
          break;
        case 'nominal':
          fieldSettings[key] = {
            type: 'checkbox',
            cellProps: {
              xs: 6,
            },
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          break;
        /* ----------  Disabled Fields ---------- */
        case 'created_on':
        case 'updated_on':
          fieldSettings[key] = {
            type: 'display',
            fieldProps: {
              disabled: true,
              label: jsonKeyToLabel(key),
              name: key,
              value: apiDateToString(data[key], 'date'),
            },
          };
          break;
        case 'app_key':
        case 'external_id':
        case 'source_checklist':
        case 'project':
        case 'readings':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              disabled: true,
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          break;
        /* ----------  Default ---------- */
        default:
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
      }
    });
  }

  const title = update ? 'Edit Measurement' : 'New Measurement';

  let initialValues = {};
  if (update) {
    // The form cannot handle data coming in as objects.  (see ProjectsForm as an example)
    initialValues = {
      ...data,
      project: data.project ? data.project.id : '',
    };
  } else {
    // assign values from url queries here
    initialValues = {
      units: 'in',
      project: queriesFromString(location.search).project_id,
    };
  }

  const onSubmit = changedValues => {
    const route = location.state && location.state.back ? location.state.back : `/projects/${project.id}`;
    const updatedValues = { ...changedValues };

    setSubmittedValues(changedValues);

    if ('collected_on' in updatedValues) {
      updatedValues.collected_on = dateToApiDateTime(updatedValues.collected_on);
    }
    if (update) {
      dispatch(updateMeasurements(updatedValues.id, updatedValues, route));
    } else {
      dispatch(createMeasurements(updatedValues, route));
    }
  };

  /**
   * Decorators are used for setting the values of other fields based off of a field.
   * Decorator format can be found here: src/components/shared/form/common.js
   */
  const decorators = [];

  /**
   *  Validations that are per field.
   *  Errors associated with the form are passed back through formError in Redux.
   */
  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        if (values[key] === submittedValues[key]) {
          errors[key] = value;
        } else {
          errors[key] = undefined;
        }
      }
    }
    return errors;
  };

  return (
    <CommonForm
      update={update}
      title={title}
      initialValues={initialValues}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      onSubmit={onSubmit}
      decorators={decorators}
      validate={validate}
      loading={loading}
      error={error}
    />
  );
};

MeasurementsForm.defaultProps = {
  update: false,
};

MeasurementsForm.propTypes = {
  update: PropTypes.bool,
};

export default MeasurementsForm;
