import { all, put, takeLatest } from 'redux-saga/effects';

import { USAGE_STATS_REQUEST, USAGE_STATS_SUCCESS, USAGE_STATS_FAILURE } from './usageStatsActions';

import { fetchUsageStats } from '../../api/getters/stats.js';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';

export function* usageStatsFetch() {
  try {
    const { data, error, response } = yield fetchUsageStats();
    if (data) {
      yield put({ type: USAGE_STATS_SUCCESS, data });
    } else if (error) {
      yield put({ type: USAGE_STATS_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, USAGE_STATS_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, USAGE_STATS_FAILURE);
  }
}

export function* watchUsageStatsFetch() {
  yield takeLatest(USAGE_STATS_REQUEST, usageStatsFetch);
}

export default function* usageStatsSaga() {
  yield all([watchUsageStatsFetch()]);
}
