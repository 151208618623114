import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import { Form, FormSpy } from 'react-final-form';
import createDecorator from 'final-form-calculate';
import moment from 'moment';
import { diff } from 'deep-object-diff';
import { Paper, Grid, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CommonField from './CommonField';
import DefaultFormToolbar from './DefaultFormToolbar';
import Error from '../displays/Error';
import StatusOverlay from '../displays/StatusOverlay';
import { usePermissions } from '../../../hooks/settingsHooks';
import Denied from '../displays/Denied';
/**
 * Shared component for simple forms that either creates or updates a feature.<br>
 * This allows forms to be created by passing in objects that defines how the form is displayed and handled<br><br>
 * Examples: Reservations.js and ReportTypes.js
 */

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(4, 'auto'),
    padding: theme.spacing(4, 2),
    maxWidth: theme.spacing(75),
  },
  embed: {
    margin: 0,
    padding: 0,
  },
  form: {
    maxHeight: `calc(100vh - ${theme.spacing(20)})`,
  },
  title: {
    paddingTop: theme.spacing(2),
  },
  body: {
    padding: theme.spacing(2, 4),
    textAlign: 'left',
  },
  bodyScroll: {
    flexGrow: 1,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
  },
  sectionHeader: {
    padding: theme.spacing(1, 0),
  },
  buttonGroup: {
    padding: theme.spacing(2),
  },
}));

export const getNonFieldError = (errors = {}) => {
  if (errors && 'non_field_errors' in errors) {
    return errors.non_field_errors;
  }
  return '';
};

// For edits to an object we want to send the difference
export const buildPatch = (initialValues, values) => {
  const changedValues = {
    id: values.id,
  };
  const changes = diff(initialValues, values);
  Object.keys(changes).forEach(key => {
    if (moment.isMoment(values[key])) {
      changedValues[key] = moment(values[key]).format('YYYY-MM-DD');
    } else if (values[key] === undefined) {
      // for some reason a cleared field does not show up in values in the onSubmit function for React Final Form
      // apparently there are issues for wanting the field there and others that want the field removed:
      // https://github.com/final-form/react-final-form/issues/430
      if (typeof initialValues[key] === 'string') {
        changedValues[key] = '';
      } else if (typeof initialValues[key] === 'boolean') {
        changedValues[key] = false;
      } else if (typeof initialValues[key] === 'number') {
        changedValues[key] = null;
      } else if (typeof initialValues[key] === 'object' && key === 'labels') {
        changedValues[key] = [];
      } else if (typeof initialValues[key] === 'object' && key === 'categories') {
        changedValues[key] = [];
      } else {
        console.error(`Handle Common Form returning undefined value for ${typeof initialValues[key]}`);
      }
    } else {
      // the diff returns objects vs arrays, so send the whole array
      // we do not currently support patching parts of an array on the server.
      changedValues[key] = values[key];
    }
  });
  return changedValues;
};

const CommonForm = props => {
  const {
    update,
    rename,
    title,
    initialValues,
    fieldSettings,
    formSettings,
    onSubmit,
    decorators,
    mutators,
    validate,
    loading,
    dirty,
    isDirty,
    error,
    backLink,
    keepDirtyOnReinitialize,
    toolbar: Toolbar,
    customToolbarProps,
    embed,
    divider,
    helperText,
    createPermissionName,
    updatePermissionName,
    permissionCallback,
    onChangeValues,
  } = props;
  const { fieldOrder, removeField, hideField } = formSettings;
  const classes = useStyles();

  const permissions = usePermissions();

  const checkPermissions = () => {
    if (update && updatePermissionName) {
      return permissions[updatePermissionName];
    }
    if (createPermissionName) {
      return permissions[createPermissionName];
    }
    return true;
  };

  const isFormAllowed = checkPermissions();
  const permissionError = permissionCallback();

  // decorators need to be unchanging across renders.
  const rffDecorators = useMemo(
    // decorators are in the form:
    // {
    //   field: <name of field to check>
    //   updates: {
    //     <name of field to update>: (<value of field to check>, <form values>) => { return <updated field value> }
    //   }
    // }
    () => decorators.map(decorator => createDecorator(decorator)),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (!isFormAllowed || permissionError) {
    return <Denied message={permissionError?.message} backLink={backLink || permissionError?.backLink} />;
  }

  /**
   *
   * CAN OVERRIDE THIS BY PASSING `props.renderField`
   *
   * @param {String} key - as defined by formSettings.fieldOrder
   * @param {Number} index - field index
   * @param {*} value - field value provided by react-final-form
   *
   * @returns {Component}
   *
   */
  const _defaultRenderField = (key, index, value) => {
    // do not show any keys from the api that does not have a setting passed in.
    if (!fieldSettings[key]) return null;
    const { type, fieldProps, cellProps } = fieldSettings[key];

    // if a fieldSettings needs to set a value for the field,
    // pass in a function that accepts the current value from the form.
    if (typeof fieldProps.value === 'function') fieldProps.value = fieldProps.value(value);

    return (
      <Grid item container xs={12} alignItems="center" {...cellProps} key={index}>
        <Grid xs={12}>
          <CommonField type={type} {...fieldProps} />
        </Grid>
      </Grid>
    );
  };

  const renderField = props.renderField || _defaultRenderField;

  const handleSpyChange = values => {
    isDirty(!values.pristine);
  };

  const localOnSubmit = (values, form) => {
    if (update || rename) {
      // determine what fields have changed and only pass along those fields
      // the api expects to be called with a PATCH and only pass the updated fields in the body
      // if updating, pass along the id, otherwise id will be undefined
      const changedValues = buildPatch(initialValues, values);
      onSubmit(changedValues, form); // pass form object to feature forms that need access to form methods
    } else {
      for (const [key, value] of Object.entries(values)) {
        if (moment.isMoment(value)) {
          values[key] = moment(value).format('YYYY-MM-DD');
        }
      }
      onSubmit(values, form); // pass form object to feature forms that need access to form methods
    }
  };

  const renderForm = () => {
    return (
      <Form
        keepDirtyOnReinitialize={keepDirtyOnReinitialize}
        initialValues={initialValues}
        onSubmit={localOnSubmit}
        decorators={rffDecorators}
        mutators={mutators}
        validate={validate}
        render={({ handleSubmit, submitting, pristine, values, errors, submitFailed, submitSucceeded, form }) => {
          const checkValidAfterSubmit = submitFailed && Object.entries(errors).length !== 0;
          const nonFieldError = getNonFieldError(errors);

          const submitDisabled =
            props.disableSubmit || submitting || (pristine && !dirty) || loading || checkValidAfterSubmit;

          const defaultToolbarProps = {
            handleSubmit,
            title,
            values,
            submitDisabled,
            backLink,
            reset: form.reset,
          };
          let toolbarProps = defaultToolbarProps;
          if (customToolbarProps) {
            toolbarProps = { ...defaultToolbarProps, ...customToolbarProps };
          }
          return (
            <>
              <Prompt
                // when={(!pristine || dirty) && !submitting}
                when={false}
                message="You have unsubmitted changes, are you sure you want to continue?"
              />
              <form onSubmit={handleSubmit}>
                <Grid container direction="column" alignItems="stretch" wrap="nowrap" className={classes.form}>
                  <Grid item>
                    <FormSpy subscription={{ pristine: true }} onChange={handleSpyChange} />
                    <Toolbar {...toolbarProps} />
                    {helperText !== '' && <div className={classes.body}>{helperText}</div>}
                    {divider && <Divider />}
                    {onChangeValues && (
                      <FormSpy subscription={{ values: true }} onChange={({ values }) => onChangeValues({ values })} />
                    )}
                  </Grid>
                  <Grid item className={classes.bodyScroll}>
                    <Error error={error || nonFieldError} />
                    <Grid container alignItems="flex-start" className={classes.body}>
                      {Object.keys(fieldOrder).map(fieldKey => {
                        const formFields = fieldOrder[fieldKey].fields
                          .filter(item => {
                            const keepField = !removeField(item) && !hideField(values, item);
                            return keepField;
                          })
                          .map((item, index) => renderField(item, index, values[item]));
                        if (formFields.length === 0) return null;
                        return (
                          <Grid key={fieldKey} xs={12} item className={classes.section}>
                            <h4 className={classes.sectionHeader}>
                              <strong>{fieldOrder[fieldKey].display}</strong>
                            </h4>
                            <Grid container alignItems="flex-start" spacing={2}>
                              {formFields}
                            </Grid>
                          </Grid>
                        );
                      })}
                      {Array.isArray(error) ? error.map(e => <Error key={e} error={e} />) : <Error error={error} />}
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </>
          );
        }}
      />
    );
  };

  return (
    <Paper className={embed ? classes.embed : classes.root}>
      <StatusOverlay on={loading} dual>
        {renderForm()}
      </StatusOverlay>
    </Paper>
  );
};

CommonForm.defaultProps = {
  update: false,
  rename: false,
  divider: true,
  title: '',
  initialValues: {},
  fieldSettings: {},
  decorators: [],
  mutators: {},
  loading: false,
  dirty: false,
  isDirty: () => {},
  error: '',
  backLink: '',
  keepDirtyOnReinitialize: true,
  toolbar: DefaultFormToolbar,
  customToolbarProps: undefined,
  embed: false,
  helperText: '',
  bulk: false,
  createPermissionName: undefined,
  updatePermissionName: undefined,
  permissionCallback: () => {},
};

CommonForm.propTypes = {
  /** if the form is updating the feature */
  update: PropTypes.bool,
  /** if the form is renaming the feature */
  rename: PropTypes.bool,
  /** title for the form */
  title: PropTypes.string,
  /** object for defining the field order, fields to remove and fields to hide */
  formSettings: PropTypes.object.isRequired,
  /** callback for submit event */
  onSubmit: PropTypes.func.isRequired,
  /** if updating, pull in the current values of the feature */
  initialValues: PropTypes.object,
  /** object for defining how each field is displayed (does not show the field by default) */
  fieldSettings: PropTypes.object,
  /** defines fields that change depending on other fields */
  decorators: PropTypes.array,
  /** passed in mutators (i.e. arrayMutators) */
  mutators: PropTypes.object,
  /** shows loading message and disables submit button when true */
  loading: PropTypes.bool,
  /** shows disables submit button when true */
  disableSubmit: PropTypes.bool,
  /** override internal pristine setting
   * (i.e. user has changed a field, which causes a re-render and the submit button should be active) */
  dirty: PropTypes.bool,
  /** callback to determine if the form is dirty.
   * allows setting the status of something outside the form if the form is dirty */
  isDirty: PropTypes.func,
  /** shows error message if not empty string */
  error: PropTypes.string,
  /** defines custom validations per field */
  validate: PropTypes.func.isRequired,
  backLink: PropTypes.string,
  /** if the form needs to be rerendered, this keeps the form values between renders */
  keepDirtyOnReinitialize: PropTypes.bool,
  /** Allows for custom toolbar component */
  toolbar: PropTypes.func,
  /** Allows for custom toolbar component props */
  customToolbarProps: PropTypes.object,
  /** Switch the style from standalone to embedded inside a page */
  embed: PropTypes.bool,
  /** display divider above the main form content */
  divider: PropTypes.bool,
  /** Custom text above the main form content */
  helperText: PropTypes.string,
  /* Custom field render logic */
  renderField: PropTypes.func,
  /** Permission name for creating instances (projects, assets, checklists, etc. )**/
  createPermissionName: PropTypes.string,
  /** Permission name for updating instance (projects, assets, checklists, etc. )**/
  updatePermissionName: PropTypes.string,
  /** Optional function to return error message based on more data **/
  permissionCallback: PropTypes.func,
  /** Optional to handle form value changes: object containing the updated form values **/
  onChangeValues: PropTypes.func,
};

export default CommonForm;
