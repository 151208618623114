// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import React from 'react';
import CommonRoutes from './utilities/CommonRoutes';
import ProjectsForm from '../../components/projects/ProjectsForm';
import ProjectDetail from '../../components/projects/ProjectDetail';
import ProjectsTablePage from '../../components/projects/ProjectsTablePage';
import MainLayout from '../layouts/MainLayout';

const ProjectsRoutes = props => {
  return (
    <CommonRoutes
      pageTitle="Project"
      layout={MainLayout}
      Edit={<ProjectsForm update />}
      Create={<ProjectsForm />}
      View={<ProjectDetail />}
      All={<ProjectsTablePage />}
    />
  );
};

export default ProjectsRoutes;
