import { combineReducers } from 'redux';

/**
 * @params reducers - array of reducers to squash
 * @returns {Function} - reducer that calls each reducer in the array
 */
const _squashReducers = (...reducers) => {
  const squashedReducer = (state, action) => {
    let newState = state;
    for (const reducer of reducers) {
      newState = reducer(newState, action);
    }
    return newState;
  };
  return squashedReducer;
};

/**
 * @param {Array} modules  - import * as myEndpoints from './myEndpoints'
 * @returns {Array} - [endpoint, ... ]
 */
export const getEndpointsFromModules = modules => {
  return modules
    .reduce((acc, m) => [...acc, ...Object.values(m)], [])
    .filter(endpoint => {
      try {
        return endpoint.isEndpoint;
      } catch (e) {
        return false;
      }
    });
};

/**
 * @param {Array} modules - array of:  import * as myEndpoints from './myEndpoints'
 * @returns {Function} single reducer
 */
export const combineModuleReducers = modules => {
  return combineReducers(
    getEndpointsFromModules(modules).reduce((acc, endpoint) => {
      const existingReducer = acc[endpoint.reducerKey];
      if (existingReducer) {
        // there is already a reducer for this endpoint,
        //   combine the existing reducer with the new one.
        //
        // NOTE - this will cause them to share state !
        return {
          ...acc,
          [endpoint.reducerKey]: _squashReducers(existingReducer, endpoint.reducer),
        };
      } else {
        return {
          ...acc,
          [endpoint.reducerKey]: endpoint.reducer,
        };
      }
    }, {})
  );
};
