import { all, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  PROJECT_TYPES_REQUEST,
  PROJECT_TYPES_SUCCESS,
  PROJECT_TYPES_FAILURE,
  PROJECT_TYPES_CREATE_REQUEST,
  PROJECT_TYPES_CREATE_SUCCESS,
  PROJECT_TYPES_CREATE_FAILURE,
  PROJECT_TYPES_UPDATE_REQUEST,
  PROJECT_TYPES_UPDATE_SUCCESS,
  PROJECT_TYPES_UPDATE_FAILURE,
  PROJECT_TYPES_DELETE_REQUEST,
  PROJECT_TYPES_DELETE_SUCCESS,
  PROJECT_TYPES_DELETE_FAILURE,
  PROJECT_TYPES_ALL_REQUEST,
  PROJECT_TYPES_ALL_SUCCESS,
  PROJECT_TYPES_ALL_FAILURE,
} from './projectTypesActions';

import {
  fetchProjectTypes,
  createProjectTypes,
  updateProjectTypes,
  deleteProjectTypes,
  fetchProjectTypesList,
} from '../../api/features/projectTypes';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';
import { fetchListAll } from '../../utilities/sagaHelpers';
import { DIALOG_CLOSE } from '../dialogActions';

export function* projectTypesFetch(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield fetchProjectTypes(id);
    if (data) {
      yield put({ type: PROJECT_TYPES_SUCCESS, data });
    } else if (error) {
      yield put({ type: PROJECT_TYPES_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, PROJECT_TYPES_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, PROJECT_TYPES_FAILURE);
  }
}

export function* watchProjectTypesFetch() {
  yield takeLatest(PROJECT_TYPES_REQUEST, projectTypesFetch);
}

export function* projectTypesCreate(action) {
  const { body } = action;
  try {
    const { data, error, formError, response } = yield createProjectTypes(body);
    if (data) {
      yield put({ type: PROJECT_TYPES_CREATE_SUCCESS, data });
      yield put(push(`/project-types/${data.id}`));
    } else if (error) {
      yield put({ type: PROJECT_TYPES_CREATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: PROJECT_TYPES_CREATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, PROJECT_TYPES_CREATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, PROJECT_TYPES_CREATE_FAILURE);
  }
}

export function* watchProjectTypesCreate() {
  yield takeLatest(PROJECT_TYPES_CREATE_REQUEST, projectTypesCreate);
}

export function* projectTypesUpdate(action) {
  const { id, body, options } = action;
  try {
    const { data, error, formError, response } = yield updateProjectTypes(id, body);
    if (data) {
      yield put({ type: PROJECT_TYPES_UPDATE_SUCCESS, data });
      if (options.noRoutePush) {
        const query = yield select(state => state.projectTypes.all.query) || '';
        yield put({ type: PROJECT_TYPES_ALL_REQUEST, query });
      } else {
        yield put(push('/project-types'));
      }
    } else if (error) {
      yield put({ type: PROJECT_TYPES_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: PROJECT_TYPES_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, PROJECT_TYPES_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, PROJECT_TYPES_UPDATE_FAILURE);
  }
}

export function* watchProjectTypesUpdate() {
  yield takeLatest(PROJECT_TYPES_UPDATE_REQUEST, projectTypesUpdate);
}

export function* projectTypesDelete(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield deleteProjectTypes(id);
    if (data) {
      yield put({ type: PROJECT_TYPES_DELETE_SUCCESS });
      yield put({ type: PROJECT_TYPES_ALL_REQUEST });
      yield put({ type: DIALOG_CLOSE });
      yield put(push('/project-types'));
    } else if (error) {
      yield put({ type: PROJECT_TYPES_DELETE_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, PROJECT_TYPES_DELETE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, PROJECT_TYPES_DELETE_FAILURE);
  }
}

export function* watchProjectTypesDelete() {
  yield takeLatest(PROJECT_TYPES_DELETE_REQUEST, projectTypesDelete);
}

export function* projectTypesFetchAll(action) {
  try {
    const { data, error, response } = yield fetchListAll(fetchProjectTypesList, action.query);
    if (data) {
      yield put({ type: PROJECT_TYPES_ALL_SUCCESS, data });
    } else if (error) {
      yield put({ type: PROJECT_TYPES_ALL_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, PROJECT_TYPES_ALL_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, PROJECT_TYPES_ALL_FAILURE);
  }
}

export function* watchProjectTypesFetchAll() {
  yield takeLatest(PROJECT_TYPES_ALL_REQUEST, projectTypesFetchAll);
}

export default function* projectTypesSaga() {
  yield all([
    watchProjectTypesFetch(),
    watchProjectTypesCreate(),
    watchProjectTypesUpdate(),
    watchProjectTypesDelete(),
    watchProjectTypesFetchAll(),
  ]);
}
