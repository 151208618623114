// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import React from 'react';
import CommonRoutes from './utilities/CommonRoutes';
import CompaniesForm from '../../components/companies/CompaniesForm';
import CompanyDetail from '../../components/companies/CompanyDetail';
import CompaniesTable from '../../components/companies/CompaniesTable';
import MainLayout from '../layouts/MainLayout';

const CompaniesRoutes = props => {
  return (
    <CommonRoutes
      pageTitle="Company"
      layout={MainLayout}
      Edit={<CompaniesForm update />}
      Create={<CompaniesForm />}
      View={<CompanyDetail />}
      All={<CompaniesTable />}
    />
  );
};

export default CompaniesRoutes;
