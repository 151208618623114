import React from 'react';
import PropTypes from 'prop-types';
import { ImageList, ImageListItem, ImageListItemBar, Tooltip } from '@mui/material';
import { noThumbnail } from '../../utilities/files';

const ChecklistViewerMedia = ({ media }) => {
  if (!media.length) return;

  return (
    <ImageList cols={6}>
      {media.map(item => (
        <ImageListItem key={item.id}>
          <img src={item.thumbnail || noThumbnail(item.document_category)} alt={item.title} />
          <ImageListItemBar
            title={
              <Tooltip title={item.description}>
                <span>{item.description || item.name}</span>
              </Tooltip>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
};

ChecklistViewerMedia.propTypes = {
  media: PropTypes.array.isRequired,
};

export default ChecklistViewerMedia;
