import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Clear from '@mui/icons-material/Clear';
import { makeStyles } from '@mui/styles';
import variables from '../../../config.module.scss';

/**
 * Button for Cancel, Back or similar buttons using a theme that contrasts with the main theme.
 * It can show an icon.
 * */

const OppositeButton = props => {
  const { type, label, icon, Icon, disabled, onClick, variant, fullWidth, endIcon } = props;
  const useStyles = makeStyles(theme => ({
    root: {
      border: theme.palette.mode === 'dark' ? variables.secondaryLight : variables.secondaryDark,
      backgroundColor: theme.palette.mode === 'dark' ? variables.secondaryLight : variables.secondaryDark,
      color: theme.palette.mode === 'dark' ? variables.textSecondaryLight : variables.textSecondaryDark,
      '&:hover': {
        border: theme.palette.mode === 'dark' ? variables.secondaryHoverLight : variables.secondaryHoverDark,
        backgroundColor: theme.palette.mode === 'dark' ? variables.secondaryHoverLight : variables.secondaryHoverDark,
      },
      '&:disabled': {
        border: theme.palette.mode === 'dark' ? variables.secondaryDisabledLight : variables.secondaryDisabledDark,
        backgroundColor:
          theme.palette.mode === 'dark' ? variables.secondaryDisabledLight : variables.secondaryDisabledDark,
      },
    },
  }));
  const classes = useStyles();
  const moreProps = {};
  if (icon) {
    let StartIcon = Icon;
    if (!StartIcon) {
      StartIcon = Clear;
    }
    moreProps.startIcon = <StartIcon />;
  }
  if (onClick) {
    moreProps.onClick = onClick;
  }
  if (fullWidth) {
    moreProps.fullWidth = true;
  }

  return (
    <Button
      type={type}
      variant={variant}
      disabled={disabled}
      className={classes.root}
      endIcon={endIcon}
      onClick={() => {}}
      {...moreProps}>
      {label}
    </Button>
  );
};

OppositeButton.defaultProps = {
  label: 'Clear',
  type: 'button',
  variant: 'contained',
  fullWidth: false,
  icon: false,
  Icon: null,
  disabled: false,
  onClick: null,
  endIcon: null,
};

OppositeButton.propTypes = {
  /** sets the button label */
  label: PropTypes.string,
  /** sets the button type */
  type: PropTypes.string,
  /** sets the button variant */
  variant: PropTypes.string,
  /** sets the button to fill the container */
  fullWidth: PropTypes.bool,
  /** enable or disable <em>Icon</em> prop */
  icon: PropTypes.bool,
  /** if <em>icon</em> is true, sets the custom svg icon */
  Icon: PropTypes.object,
  /** disables button */
  disabled: PropTypes.bool,
  /** callback for onClick event */
  onClick: PropTypes.func,
  /* end icon */
  endIcon: PropTypes.node,
};

export default OppositeButton;
