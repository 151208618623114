import React, { useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Grid, Typography, Tabs, Tab, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useDispatch, useSelector } from 'react-redux';
import AssetOverview from './AssetOverview';
import { getAssets, getAllAssets } from '../../store/features/assetsActions';
import { getAllProjects } from '../../store/features/projectsActions';
import { getAllDefects } from '../../store/features/defectsActions';
import { getAllCmls } from '../../store/features/cmlsActions';
import { getAllChecklists } from '../../store/features/checklistsActions';
import ProjectsTable from '../projects/ProjectsTable';
import AssetsTable from '../assets/AssetsTable';
import DefectsTable from '../defects/DefectsTable';
import LibraryMediaTable from '../libraryMedia/LibraryMediaTable';
import ChecklistsTable from '../checklists/ChecklistsTable';
import CmlsTable from '../cmls/CmlsTable';
import Loading from '../shared/displays/Loading';
import ButtonIcon from '../shared/buttons/ButtonIcon';
import PrimaryButton from '../shared/buttons/PrimaryButton';
import TabPanel from '../shared/TabPanel';

import { usePermissions, useFeatureFlags, useTableViewSettings } from '../../hooks/settingsHooks';
import { tableViews } from '../../utilities/tables';
import AssetPathDisplay from './AssetPathDisplay';
import AssessmentDisplay from '../assessments/AssessmentDisplay';
import Bookmarks from '../shared/bookmarks/Bookmarks';
import AssetActionBar from './AssetActionBar';

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'left',
  },
  header: {
    padding: theme.spacing(3, 3, 0, 3),
  },
  title: {
    flexGrow: 1,
    // justify: 'left',
  },
  table: {
    padding: theme.spacing(3, 3),
  },
  assetNameTitle: {
    fontSize: theme.spacing(5),
  },
  assetPath: {
    fontSize: theme.spacing(2),
    fontWeight: '400',
  },
  assessmentLinkButton: {
    padding: theme.spacing(0, 2),
  },
  bookmarkContainer: {
    marginTop: theme.spacing(0.5),
  },
}));

const AssetDetail = () => {
  const { data, loading, error } = useSelector(state => state.assets.each);
  let { id, tab: selectedTab } = useParams();
  selectedTab = selectedTab || 'overview'; // default to overview tab
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { hasAssetLibrary, hasConditionMonitoring, hasChecklistAny, hasAssetAssessments, hasBookmarks } =
    useFeatureFlags();
  const { hasAssessmentView, hasAssessmentEdit } = usePermissions();
  const { assetsTableViewKey, cmlsTableViewKey, defectsTableViewKey, projectsTableViewKey, checklistsTableViewKey } =
    tableViews;
  const showCmls = hasConditionMonitoring && data.type && data.type.cml_mode !== 'DISABLED';
  const showLibrary = hasAssetLibrary && data.library;
  const showForms = hasChecklistAny;
  const showAssessments = hasAssessmentView && data.assessment;
  const showLaunchAssessmentsLink = hasAssessmentEdit && hasAssetAssessments && data?.assessment?.page?.path;

  const descendantsQuery = { descendants: id };
  const relatedDefectsQuery = { asset__asset_path_cache__path: data.asset_path, latest: true };
  const relatedCmlsQuery = { asset__asset_path_cache__path: data.asset_path };
  const relatedChecklistsQuery = { asset: id, project: 'none' };

  const excludedAssetQueries = ['related', 'related_asset'];

  const {
    tableQuery: storedProjectsQuery,
    embeddedQuery: projectsEmbeddedQuery,
    page: projectsPage,
    views: projectsView,
  } = useTableViewSettings(projectsTableViewKey, descendantsQuery, excludedAssetQueries);
  const {
    tableQuery: storedAssetsQuery,
    embeddedQuery: assetsEmbeddedQuery,
    page: assetsPage,
    views: assetsView,
  } = useTableViewSettings(assetsTableViewKey, descendantsQuery, excludedAssetQueries);
  const excludedDefectQueries = ['asset_id'];
  const {
    tableQuery: storedDefectsQuery,
    embeddedQuery: defectsEmbeddedQuery,
    page: defectsPage,
    views: defectsView,
  } = useTableViewSettings(defectsTableViewKey, relatedDefectsQuery, excludedDefectQueries);
  const {
    tableQuery: storedCmlsQuery,
    embeddedQuery: cmlsEmbeddedQuery,
    page: cmlsPage,
    views: cmlsView,
  } = useTableViewSettings(cmlsTableViewKey, relatedCmlsQuery);
  const {
    tableQuery: storedChecklistsQuery,
    embeddedQuery: checklistsEmbeddedQuery,
    page: checklistsPage,
    views: checklistsView,
  } = useTableViewSettings(checklistsTableViewKey, relatedChecklistsQuery);

  const assetIdQuery = `asset_id=${id}`; // query string used to route push

  useEffect(() => {
    dispatch(getAssets(id));
    dispatch(getAllAssets(storedAssetsQuery));
    dispatch(getAllProjects(storedProjectsQuery));
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  // cmls and defects fetch dependent on asset path
  useEffect(() => {
    dispatch(getAllDefects(storedDefectsQuery));
    if (showCmls) {
      dispatch(getAllCmls(storedCmlsQuery));
    }
  }, [showCmls, data.id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getAllChecklists(storedChecklistsQuery));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const classes = useStyles();

  const handleBack = () => {
    if (location.state && location.state.back) {
      history.push(location.state.back);
    } else {
      const next = location.pathname.match(/(.*?)\/\w+$/)[1];
      history.push(next);
    }
  };

  const handleAssessmentClick = path => {
    history.push(path);
  };

  const tableChangeHandlerFactory = (embeddedQuery, dispatchFunction) => {
    return query => {
      const updatedQuery = { ...embeddedQuery, ...query };
      dispatch(dispatchFunction(updatedQuery));
    };
  };

  const projectTableChangeHandler = tableChangeHandlerFactory(projectsEmbeddedQuery, getAllProjects);
  const assetTableChangeHandler = tableChangeHandlerFactory(assetsEmbeddedQuery, getAllAssets);
  const defectTableChangeHandler = tableChangeHandlerFactory(defectsEmbeddedQuery, getAllDefects);
  const cmlsTableChangeHandler = tableChangeHandlerFactory(cmlsEmbeddedQuery, getAllCmls);
  const checklistsTableChangeHandler = tableChangeHandlerFactory(checklistsEmbeddedQuery, getAllChecklists);

  const renderProjectsTable = () => {
    return (
      <ProjectsTable
        embedded
        title="Related Projects"
        tableChangeHandler={projectTableChangeHandler}
        queryParamObj={storedProjectsQuery}
        createWithAsset={assetIdQuery}
        page={projectsPage}
        views={projectsView}
        className={classes.table}
        filename={`${data.name} Related Projects`}
      />
    );
  };

  const renderDefectsTable = () => {
    return (
      <DefectsTable
        title="Findings"
        tableChangeHandler={defectTableChangeHandler}
        queryParamObj={storedDefectsQuery}
        page={defectsPage}
        views={defectsView}
        embedded
        filename={`${data.name} Findings`}
      />
    );
  };

  const renderAssetsTable = () => {
    return (
      <AssetsTable
        embedded
        title="Related Assets"
        tableChangeHandler={assetTableChangeHandler}
        queryParamObj={storedAssetsQuery}
        createWithAsset={assetIdQuery}
        page={assetsPage}
        views={assetsView}
        filename={`${data.name} Related Assets`}
      />
    );
  };

  const renderCmlsTable = () => {
    return (
      <CmlsTable
        title="CMLs"
        tableChangeHandler={cmlsTableChangeHandler}
        queryParamObj={storedCmlsQuery}
        page={cmlsPage}
        views={cmlsView}
        createWithAsset={assetIdQuery}
        embedded
        filename={`${data.name} CMLs`}
      />
    );
  };

  const renderLibraryTable = () => {
    return (
      <LibraryMediaTable
        embedded
        title="Library"
        libraryId={data.library?.id}
        libraryName={data.library?.name}
        filename={`${data.name} Library`}
      />
    );
  };

  const renderFormsTable = () => {
    return (
      <ChecklistsTable
        embedded
        title="Forms"
        tableChangeHandler={checklistsTableChangeHandler}
        queryParamObj={storedChecklistsQuery}
        filename={`${data.name} Forms`}
        page={checklistsPage}
        views={checklistsView}
        createQuery={{ asset_id: data.id }}
      />
    );
  };

  const renderAssessments = () => {
    if (data?.assessment) {
      return <AssessmentDisplay assessmentId={data.assessment.id} />;
    }
  };

  const renderAssessmentLink = () => {
    if (showLaunchAssessmentsLink) {
      const path = data?.assessment?.page?.path;
      if (!path) {
        console.warn('expected path for asset assessment:', data);
        return null;
      }
      return (
        <Grid item>
          <div className={classes.assessmentLinkButton}>
            <PrimaryButton label=" Launch Assessment" onClick={() => handleAssessmentClick(path)} />
          </div>
        </Grid>
      );
    }
  };

  const tabs = [
    {
      value: 'overview',
      label: 'Overview',
      Component: <AssetOverview id={id} data={data} loading={loading} error={error} />,
      hide: false,
    },
    { value: 'projects', label: 'Related Projects', Component: renderProjectsTable() },
    { value: 'findings', label: 'Findings', Component: renderDefectsTable() },
    { value: 'related_assets', label: 'Related Assets', Component: renderAssetsTable() },
    { value: 'cmls', label: 'CMLs', Component: renderCmlsTable(), hide: !showCmls },
    { value: 'library', label: 'Library', Component: renderLibraryTable(), hide: !showLibrary },
    { value: 'forms', label: 'Forms', Component: renderFormsTable(), hide: !showForms },
    { value: 'assessments', label: 'Assessments', Component: renderAssessments(), hide: !showAssessments },
  ];

  let readyToRender = false;

  const handleTabChange = (event, value) => {
    history.push(`/assets/${id}/` + value);
  };

  switch (selectedTab) {
    case 'overview':
    case 'projects':
    case 'findings':
    case 'related_assets':
    case 'cmls':
    case 'library':
    case 'forms':
    case 'assessments':
      readyToRender = true;
      break;
    default:
      history.replace(`/assets/${id}`);
  }

  // the Tabs component doesn't handle value=undefined
  if (!readyToRender) return <></>;

  return (
    <div className={classes.root}>
      <Loading loading={loading} />
      <Grid container>
        <Grid item xs={12} className={classes.headerGrid}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.header}>
            <Grid item>
              <ButtonIcon buttonSize="medium" iconSize="medium" icon={ArrowBackIosIcon} onClick={handleBack} />
            </Grid>
            <Grid item className={classes.title}>
              <p className={`h7 ${classes.assetNameTitle}`}>{data.name ? `${data.name} (${data.type.name})` : ''}</p>
              <Typography variant="h6" className={classes.assetPath}>
                <AssetPathDisplay asset={data} />
              </Typography>
              {hasBookmarks && <Bookmarks id={id} />}
            </Grid>

            {renderAssessmentLink()}
            <Grid item>
              <AssetActionBar id={id} name={data?.name} size={'large'} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary">
            {tabs
              .filter(tab => !tab.hide)
              .map(tab => (
                <Tab
                  id={`asset-tab-${tab.value}`}
                  aria-controls={`asset-tabpanel-${tab.value}`}
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                />
              ))}
          </Tabs>
          <Divider className={classes.divider} />
          {tabs.map(tab => (
            <TabPanel key={tab.value} value={tab.value} selectedTab={selectedTab} name="asset">
              <Grid item className={classes.table}>
                {tab.Component}
              </Grid>
            </TabPanel>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default AssetDetail;
