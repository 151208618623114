import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MapIcon from '@mui/icons-material/Map';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import RefreshButton from '../shared/buttons/RefreshButton';

import AssetTreeDetail from './AssetTreeDetail';
import LoadingPage from '../shared/displays/LoadingPage';
import Error from '../shared/displays/Error';
import Bookmarks from '../shared/bookmarks/Bookmarks';
import { useCachedAssetsTree } from '../../hooks/assetsTreeHooks';
import { useFeatureFlags } from '../../hooks/settingsHooks';
import { assetsTreeEndpoint } from '../../store/apiV2/assets';
import { selectAssetTreeAsset, expandAssetTree } from '../../store/settings/settingsActions';
import AssetActionBar from './AssetActionBar';
import { apiDateToString } from '../../utilities/strings';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTreeItem-label': {
      '& .MuiTypography-root': {
        fontSize: '1.1rem',
        lineHeight: '1.35rem',
      },
    },
    '& .MuiGrid-item': {
      padding: theme.spacing(0),
    },
  },
  paper: {
    width: '100%',
    padding: theme.spacing(1, 2),
    textAlign: 'left',
    display: 'flex',
  },
  toolbar: {
    textAlign: 'right',
    // margin: theme.spacing(0, 2)
  },
  bookmarks: {
    margin: theme.spacing(0.5, 0, 0.5, 0),
  },
  detailSideBar: {
    position: 'sticky',
    top: theme.spacing(8), // so it doesn't overlap with the top gutter when scrolling
  },
  refresh: {
    marginLeft: theme.spacing(4),
  },
}));

export default function ControlledTreeView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [viewMode, setViewMode] = useState('VIEW'); // ['VIEW', 'MAP']

  const { selected, expanded } = useSelector(state => state.settings.assetTreeView);
  const { hasBookmarks } = useFeatureFlags();
  const { loading, assets, error, lastFetch } = useCachedAssetsTree();

  const renderSubTree = asset => (
    <TreeItem
      key={asset.id}
      nodeId={asset.id}
      label={<Typography variant="h6">{`${asset.name} (${asset.type__name})`}</Typography>}
    >
      {asset.children ? asset.children.map(child => renderSubTree(child)) : null}
    </TreeItem>
  );

  const handleToggle = (event, nodeIds) => {
    dispatch(expandAssetTree(nodeIds));
  };

  const handleSelect = (event, nodeIds) => {
    dispatch(selectAssetTreeAsset({ id: nodeIds, name: event.target.innerText }));
  };

  const handleRefresh = () => {
    dispatch(assetsTreeEndpoint.actionCreators.request([{ is_active: true }]));
  };

  if (loading) {
    return <LoadingPage />;
  }
  if (error) {
    return <Error error={error} />;
  }

  const extraActions = [
    {
      key: 'view-map',
      icon: viewMode === 'VIEW' ? MapIcon : MapOutlinedIcon,
      onClick: () => {
        setViewMode(viewMode === 'VIEW' ? 'MAP' : 'VIEW');
      },
      tooltip: `View Asset ${viewMode === 'VIEW' ? 'Map' : 'Info'}`,
      selected: viewMode === 'MAP',
      show: true,
      disableElevation: viewMode === 'MAP',
    },
  ];

  return (
    <Grid container spacing={4} direction="row" data-testid="asset-tree-container">
      <Grid item container direction="column" xs={5}>
        <Grid item>
          <Paper className={classes.paper}>
            <TreeView
              className={classes.root}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              expanded={expanded}
              selected={selected?.id}
              onNodeToggle={handleToggle}
              onNodeSelect={handleSelect}
            >
              {assets.map(asset => renderSubTree(asset))}
            </TreeView>
          </Paper>
        </Grid>
        <Paper>
          <Grid item container direction="row" alignItems="center" className={classes.refresh}>
            {lastFetch && (
              <>
                <Grid item>
                  <Typography>Last Fetched at {apiDateToString(Number(lastFetch))}</Typography>
                </Grid>
                <Grid item>
                  <RefreshButton refresh={handleRefresh} />
                </Grid>
              </>
            )}
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={7}>
        {selected?.id && (
          <Grid container spacing={0} className={classes.detailSideBar}>
            <Grid item xs={12}>
              <Paper className={classes.toolbar}>
                <Typography class="h4">{selected?.name}</Typography>
                <AssetActionBar id={selected?.id} name={selected?.name} extraActions={extraActions} />
              </Paper>
            </Grid>
            {hasBookmarks && (
              <Grid item xs={12} className={classes.bookmarks}>
                <Bookmarks id={selected?.id} />
              </Grid>
            )}
            <Grid item xs={12}>
              <AssetTreeDetail id={selected?.id} mode={viewMode} />
            </Grid>
          </Grid>
        )}
        {!selected?.id && (
          <Paper style={{ height: '80vh', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
            <Typography variant="h6">Select an asset to view details</Typography>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
}
