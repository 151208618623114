import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { truncate } from '../../../utilities/strings';
import { pixelToChar } from '../../../api/features/constants';

const useStyles = makeStyles(theme => ({
  description: {
    fontSize: '14px',
    lineHeight: '1.8',
  },
  textContainer: {
    fontSize: '24px',
    textOverflow: 'ellipsis',
  },
  alertStatus: {
    marginTop: '3rem',
  },
}));

const TitleDescription = ({ value, className }) => {
  const classes = useStyles();
  const { alert_status: alertStatus } = useSelector(state => state.settings.features.workspace); // eslint-disable-line camelcase
  const alert = alertStatus !== 'OFF'; // need to push down if alert is set

  const [navLength, setNavLength] = useState(120);

  useEffect(() => {
    function handleResize() {
      setNavLength(window.innerWidth * pixelToChar);
    }

    window.addEventListener('resize', handleResize);
  });
  return (
    <Tooltip placement="bottom" title={<span className={classes.description}>{value}</span>}>
      <div className={`${classes.textContainer} ${className}` + `${alert ? classes.alertStatus : ''}`}>
        {truncate(value, navLength)} /
      </div>
    </Tooltip>
  );
};

TitleDescription.propTypes = {
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
};

TitleDescription.defaultProps = {
  className: '',
};

export default TitleDescription;
