// see https://docs.huvrdata.app/reference/api_checklist-result-lines_read
export const FIELDS = {
  id: 'id',
  line_key: 'line_key',
  section_key: 'section_key',
  value: 'value',
  notes: 'notes',
  media: 'media',

  project: {
    $: 'project',
    id: 'project.id',
    name: 'project.name',
  },

  checklist: {
    $: 'checklist',
    id: 'checklist.id',
  },

  template: {
    $: 'template',
    id: 'template.id',
    name: 'template.name',
  },

  template_line: {
    $: 'template_line',
    label: 'template_line.label',
    type: 'template_line.type',
    description: 'template_line.description',
    choices: 'template_line.choices',
    section_label: 'template_line.section_label',
    section_description: 'template_line.section_description',
  },
};

const F = FIELDS; // alias

/**
 * fieldOrder
 *
 * An object where the keys define the sections of the form.   Each section requires the key display (string) and
 * fields (array of strings). Fields are the api keys (in snake case) for each field that will be displayed in the form.
 * Any fields that are never shown should not be added to this array.
 *
 * @return {object}
 */
export const fieldOrder = {
  form: {
    display: 'Form Details',
    fields: [F.template.$, F.project.$, F.checklist.$],
  },
  section: {
    display: 'Section Details',
    fields: [F.section_key, F.template_line.section_label, F.template_line.section_description],
  },
  line: {
    display: 'Line Details',
    fields: [
      F.line_key,
      F.template_line.label,
      F.template_line.type,
      F.template_line.description,
      F.template_line.choices,
    ],
  },
  results: {
    display: 'Result Entry',
    fields: [F.value, F.notes, F.media],
  },
};

/**
 *  removeField
 *
 *  removes a field depending on if a user is creating, updating or viewing.
 *
 *  @param {string} state - enum for creating, updating or viewing ['CREATE', 'UPDATE', 'VIEW']
 *  @param {string} item - api key (in snake case) to check whether it should be hidden.
 *  @return {boolean} - returns true if the item will not be shown
 */
export const removeField = state => item => {
  let fields = [];
  switch (state) {
    case 'VIEW':
      fields = [];
      break;
    default:
      return false;
  }
  return fields.includes(item);
};

/**
 * hideField
 *
 * This function is for hiding a field depending on the value of another field.
 *
 * @param {object} values - the values of all the fields in the form.
 * @param {string} item - api key (in snake case) to check whether it should be removed.
 * @return {boolean} - returns true if the item will not be shown
 */
export const hideField = (values, item) => {
  return false;
};
