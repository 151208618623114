import React from 'react';
import PropTypes from 'prop-types';
import { Canvas, useLoader } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { PCDLoader } from 'three/examples/jsm/loaders/PCDLoader.js';
import Loader from './Loader';

function Points({ file }) {
  const cloud = useLoader(PCDLoader, file);
  // cloud.material.size = 0.1;
  cloud.material.color.setHex(Math.random() * 0xffffff);
  cloud.geometry.center();
  cloud.geometry.rotateX(Math.PI);

  return (
    <>
      <primitive object={cloud} />
    </>
  );
}

Points.propTypes = {
  file: PropTypes.string.isRequired,
};

function PointCloudViewer({ file }) {
  return (
    <Canvas>
      <React.Suspense fallback={<Loader />}>
        <Points file={file} />
        <OrbitControls />
      </React.Suspense>
      <ambientLight />
    </Canvas>
  );
}

PointCloudViewer.propTypes = {
  file: PropTypes.string.isRequired,
};

export default PointCloudViewer;
