import { requestFactory, responseHandlerForm } from '../base';

const get = requestFactory('get');
const post = requestFactory('post', { handler: responseHandlerForm });
const patch = requestFactory('patch', { handler: responseHandlerForm });
const del = requestFactory('del');

export const fetchSchedulePlanList = async query => get('/api/schedule/plans/', query);
export const fetchSchedulePlan = async id => get(`/api/schedule/plans/${id}/`);
export const createSchedulePlan = async body => post('/api/schedule/plans/', body);
export const updateSchedulePlan = async (id, body) => patch(`/api/schedule/plans/${id}/`, body);
export const deleteSchedulePlan = async id => del(`/api/schedule/plans/${id}/`);
export const generateSchedulePlanEvent = async (id, body) => post(`/api/schedule/plans/${id}/generate-events/`, body);
