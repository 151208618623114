import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import arrayMutators from 'final-form-arrays';
import { useHistory } from 'react-router';

import { defectModeOptions, documentStateOptions } from '../../api/features/constants';

import { jsonKeyToLabel, apiDateToString } from '../../utilities/strings';
import { integerValidator } from '../../utilities/validators';
import CommonForm from '../shared/form/Common';
import { fieldOrder, removeField, hideField } from './defectProfilesShared';
import DefectProfileTypesForm from './DefectProfileTypesForm';
import {
  defectProfilesReadEndpoint,
  defectProfilesCreateEndpoint,
  defectProfilesPartialUpdateEndpoint,
} from '../../store/apiV2/defectProfiles';

const DefectProfilesForm = props => {
  const { update } = props;
  const params = useParams();
  const history = useHistory();

  // data/loading/error/formError are shared across Read, Create, and Update endpoints
  const {
    data,
    loading,
    error,
    formError,
    dispatchRequest: fetchDefectProfile,
  } = defectProfilesReadEndpoint.useEndpoint();
  const { dispatchRequest: createDefectProfile } = defectProfilesCreateEndpoint.useEndpoint();
  const { dispatchRequest: updateDefectProfile } = defectProfilesPartialUpdateEndpoint.useEndpoint();

  const [submittedValues, setSubmittedValues] = useState({});

  useEffect(() => {
    if (update && params.id) {
      fetchDefectProfile(params.id);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formSettings = {
    fieldOrder,
    removeField: removeField(update ? 'UPDATE' : 'CREATE'),
    hideField,
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      switch (key) {
        /* ---------- Required Fields ---------- */
        case 'name':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: 'Name',
              name: 'name',
              required: true,
            },
          };
          break;
        case 'version':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: 'Version',
              name: 'version',
              required: true,
            },
          };
          break;

        /* ---------- Regular Fields ---------- */
        case 'description':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: 'Description',
              name: 'description',
              multiline: true,
              rows: 3,
              maxRows: 10,
            },
          };
          break;
        case 'categories':
          fieldSettings[key] = {
            type: 'array',
            fieldProps: {
              label: 'Categories',
              name: 'categories',
            },
          };
          break;
        case 'severity_mode':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: 'Severity Mode',
              name: 'severity_mode',
              options: defectModeOptions,
              labelwidth: 90,
            },
          };
          break;
        case 'type_mode':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: 'Type Mode',
              name: 'type_mode',
              options: defectModeOptions,
              labelwidth: 70,
            },
          };
          break;
        case 'sub_type_mode':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: 'Sub-type Mode',
              name: 'sub_type_mode',
              options: defectModeOptions,
              labelwidth: 100,
            },
          };
          break;
        case 'severity_options':
          fieldSettings[key] = {
            type: 'component',
            fieldProps: {
              label: 'Severity Options',
              name: 'severity_options',
              component: () => DefectProfileTypesForm,
              keyValidator: integerValidator,
            },
          };
          break;
        case 'type_options':
          fieldSettings[key] = {
            type: 'component',
            fieldProps: {
              label: 'Type Options',
              name: 'type_options',
              component: () => DefectProfileTypesForm,
            },
          };
          break;
        case 'sub_type_options':
          fieldSettings[key] = {
            type: 'component',
            fieldProps: {
              label: 'Sub-Type Options',
              name: 'sub_type_options',
              component: () => DefectProfileTypesForm,
            },
          };
          break;
        case 'labels_mode':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: 'Labels Mode',
              name: 'labels_mode',
              options: defectModeOptions,
              labelwidth: 80,
            },
          };
          break;
        case 'label_options':
          fieldSettings[key] = {
            type: 'component',
            fieldProps: {
              label: 'Label Options',
              name: 'label_options',
              component: () => DefectProfileTypesForm,
            },
          };
          break;
        case 'state':
          fieldSettings[key] = {
            type: 'autocomplete',
            fieldProps: {
              label: 'State',
              name: 'state',
              options: documentStateOptions,
              labelwidth: 40,
            },
          };
          break;
        case 'guide':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: 'Guide',
              name: 'guide',
              multiline: true,
              rows: 3,
              maxRows: 10,
            },
          };
          break;
        /* ----------  Disabled Fields ---------- */
        case 'created_on':
        case 'updated_on':
          fieldSettings[key] = {
            type: 'display',
            fieldProps: {
              disabled: true,
              label: jsonKeyToLabel(key),
              name: key,
              value: apiDateToString(data[key], 'date'),
            },
          };
          break;
        /* ----------  Default ---------- */
        default:
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
      }
    });
  }

  const title = update ? 'Edit Finding Profile' : 'New Finding Profile';

  let initialValues = {};
  if (update) {
    // The form cannot handle data coming in as objects.  (see ProjectsForm as an example)
    initialValues = { ...data };
  } else {
    // assign values from url queries here
    initialValues = {};
  }

  const onSubmit = changedValues => {
    const updatedValues = { ...changedValues };

    setSubmittedValues(updatedValues);
    const onSuccess = ({ data }) => history.push(`/finding-profiles/${data.id}`);
    if (update) {
      updateDefectProfile(updatedValues.id, updatedValues).then(onSuccess);
    } else {
      createDefectProfile(updatedValues).then(onSuccess);
    }
  };

  /**
   * Decorators are used for setting the values of other fields based off of a field.
   * Decorator format can be found here: src/components/shared/form/common.js
   */
  const decorators = [];

  /**
   *  Validations that are per field.
   *  Errors associated with the form are passed back through formError in Redux.
   */
  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        if (values[key] === submittedValues[key]) {
          errors[key] = value;
        } else {
          errors[key] = undefined;
        }
      }
    }
    return errors;
  };

  return (
    <CommonForm
      update={update}
      title={title}
      initialValues={initialValues}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      onSubmit={onSubmit}
      decorators={decorators}
      mutators={{ ...arrayMutators }}
      validate={validate}
      loading={loading}
      error={error}
    />
  );
};

DefectProfilesForm.defaultProps = {
  update: false,
};

DefectProfilesForm.propTypes = {
  update: PropTypes.bool,
};

export default DefectProfilesForm;
