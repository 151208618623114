// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import { AUDIT_REQUEST, AUDIT_SUCCESS, AUDIT_FAILURE } from './auditActions';

export const initialStateAudit = {
  loading: false,
  error: '',
  data: {},
  query: {},
};

export const auditReducer = (state = initialStateAudit, action) => {
  switch (action.type) {
    case AUDIT_REQUEST:
      return {
        ...state,
        loading: true,
        query: action.query,
      };
    case AUDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case AUDIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        data: {},
        query: {},
      };
    default:
      return state;
  }
};

export default auditReducer;
