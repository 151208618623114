import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import CmlsTable from './CmlsTable';
import { getAllCmls } from '../../store/features/cmlsActions';
import { getParams } from '../../utilities/route';
import { tableViews } from '../../utilities/tables';

import { useTableViewSettings } from '../../hooks/settingsHooks';
import { toQueryString } from '../../utilities/strings';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const CmlsTablePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const { cmlsTableViewKey } = tableViews;
  const { tableQuery: cmlsQuery, page, views } = useTableViewSettings(cmlsTableViewKey);

  // check for url
  const queryParams = getParams(location, cmlsQuery);

  useEffect(() => {
    dispatch(getAllCmls(queryParams));
  }, []); // eslint-disable-line

  const tableChangeHandler = query => {
    // no need to fetch data if the query is the same.
    const newSearch = toQueryString(query);
    if (location.search === newSearch) return;
    const newLocation = location;
    newLocation.search = newSearch;
    history.push(newLocation);
    dispatch(getAllCmls(query));
  };
  return (
    <div className={classes.root}>
      <CmlsTable
        title="Condition Monitoring Locations"
        queryParamObj={queryParams}
        tableChangeHandler={tableChangeHandler}
        page={page}
        views={views}
      />
    </div>
  );
};

export default CmlsTablePage;
