import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CmlsView from './CmlsView';
import Title from '../shared/displays/Title';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 3, 4, 3),
  },
  title: {
    padding: theme.spacing(0, 0, 1, 1),
  },
}));

const CmlsOverview = props => {
  const { id, data, loading, error } = props;
  const classes = useStyles();
  return (
    <Grid container direction="row" alignItems="stretch" className={classes.root}>
      <Grid item xs={12} className={classes.title}>
        <Title title="Overview" />
      </Grid>
      <Grid item xs={12}>
        <CmlsView id={id} data={data} loading={loading} error={error} />
      </Grid>
    </Grid>
  );
};

CmlsOverview.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
};

export default CmlsOverview;
