export const SET_USER_NAV_VIEW = 'SET_USER_NAV_VIEW';

export const setUserNavView = (settings) => {
  return {
    type: SET_USER_NAV_VIEW,
    settings,
  };
};

export const userNavViewReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_USER_NAV_VIEW:
      return {
        ...state,
        ...action.settings,
      };
    default:
      return state;
  }
};
