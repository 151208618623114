/**
 * fieldOrder
 *
 * An object where the keys define the sections of the form.   Each section requires the key display (string) and
 * fields (array of strings). Fields are the api keys (in snake case) for each field that will be displayed in the form.
 * Any fields that are never shown should not be added to this array.
 *
 * @return {object}
 */
export const fieldOrder = {
  info: {
    display: 'Info',
    fields: ['name', 'description', 'preview', 'file', 'size'],
  },
  location: {
    display: 'Location',
    fields: [
      'layer',
      'component',
      'component_display',
      'location_zone',
      'location_zone_display',
      'location_code',
      'location_code_display',
      'access',
      'access_display',
      'geo_point',
      'heading',
      'pitch',
      'local_coordinates',
    ],
  },
  status: {
    display: 'Status',
    fields: ['state', 'created_on', 'updated_on', 'captured_on', 'document_category', 'media_type', 'size'],
  },
  reference: {
    display: 'Reference',
    fields: ['project', 'checklist', 'source_media', 'local_id'],
  },
};

/**
 *  removeField
 *
 *  removes a field depending on if a user is creating, updating or viewing.
 *
 *  @param {string} state - enum for creating, updating or viewing ['CREATE', 'UPDATE', 'VIEW']
 *  @param {string} item - api key (in snake case) to check whether it should be hidden.
 *  @return {boolean} - returns true if the item will not be shown
 */
export const removeField = state => item => {
  let fields = [];
  switch (state) {
    case 'CREATE':
      fields = [
        'layer',
        'document_category',
        'media_type',
        'size',
        'created_on',
        'updated_on',
        'project',
        'checklist',
        'source',
        'component_display',
        'location_zone_display',
        'location_code_display',
        'access_display',
        'source_media',
      ];
      break;
    case 'UPDATE':
      fields = [
        'layer',
        'document_category',
        'media_type',
        'size',
        'preview',
        'file',
        'project',
        'checklist',
        'source',
        'component_display',
        'location_zone_display',
        'location_code_display',
        'access_display',
        'source_media',
      ];
      break;
    case 'BULK-UPDATE':
      fields = [
        'layer',
        'document_category',
        'media_type',
        'size',
        'preview',
        'file',
        'project',
        'checklist',
        'source',
        'component_display',
        'location_zone_display',
        'location_code_display',
        'access_display',
        'created_on',
        'updated_on',
        'captured_on',
        'name',
        'local_id',
        'state',
        'source_media',
      ];
      break;
    case 'VIEW':
      fields = ['preview', 'component', 'location_zone', 'location_code', 'access'];
      break;
    default:
      return false;
  }
  return fields.includes(item);
};

/**
 * hideField
 *
 * This function is for hiding a field depending on the value of another field.
 *
 * @param {object} values - the values of all the fields in the form.
 * @param {string} item - api key (in snake case) to check whether it should be removed.
 * @return {boolean} - returns true if the item will not be shown
 */
export const hideField = (values, item, hasAccessDisplay) => {
  switch (item) {
    case 'access':
    case 'access_display':
      return !hasAccessDisplay;
    default:
      return false;
  }
};
