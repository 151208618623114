import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from '@mui/icons-material/Edit';

// components
import ButtonIcon from '../shared/buttons/ButtonIcon';
import RecommendedWorkPlanBulkEditDialog from './RecommendedWorkPlanBulkEditDialog';

// util
import { openDialog } from '../../store/dialogActions';
import { useFeatureFlags } from '../../hooks/settingsHooks';
import { getIdFromRow } from '../shared/table/columns';

const useStyles = makeStyles(theme => ({
  inheritDisplay: {
    display: 'inherit',
  },
}));

const RecommendedWorkPlanToolBar = props => {
  const { selectedRows, displayData, columns } = props;
  const dispatch = useDispatch();

  const classes = useStyles();
  const { hasAssetAssessments } = useFeatureFlags();

  // Get array of recommended work plan IDs out of the table data
  const selectedrecommendedWorkPlanIds = selectedRows.data.map(item => getIdFromRow(item, displayData, columns));

  const handleBulkEdit = () => {
    dispatch(
      openDialog(
        'Edit Recommended Work Plan',
        <RecommendedWorkPlanBulkEditDialog recommendedWorkPlanIds={selectedrecommendedWorkPlanIds} />,
        {
          fullScreen: true,
        }
      )
    );
  };

  return (
    <div className={classes.inheritDisplay}>
      {hasAssetAssessments && (
        <ButtonIcon
          icon={EditIcon}
          tooltip="Edit Recommended Work Plan"
          onClick={handleBulkEdit}
          buttonSize="medium"
          iconSize="medium"
        />
      )}
    </div>
  );
};
RecommendedWorkPlanToolBar.defaultProps = {};

RecommendedWorkPlanToolBar.propTypes = {
  selectedRows: PropTypes.shape({
    data: PropTypes.array,
    dataIndex: PropTypes.Integer,
  }).isRequired,
  displayData: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired, // Table column definitions
};

export default RecommendedWorkPlanToolBar;
