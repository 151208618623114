// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import React from 'react';
import CommonRoutes from './utilities/CommonRoutes';
import ScheduleWorkForm from '../../components/scheduleWork/ScheduleWorkForm';
import ScheduleWorkView from '../../components/scheduleWork/ScheduleWorkView';
import MainLayout from '../layouts/MainLayout';

const ScheduleWorkRoutes = props => {
  return (
    <CommonRoutes
      pageTitle="Schedule Work"
      layout={MainLayout}
      Edit={<ScheduleWorkForm update />}
      Create={<ScheduleWorkForm />}
      View={<ScheduleWorkView />}
    />
  );
};

export default ScheduleWorkRoutes;
