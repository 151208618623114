import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Chart, Bar, defaults } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { makeStyles, useTheme } from '@mui/styles';
// import { colors } from '@mui/material';

Chart.register(ChartDataLabels); // register plugin for data labels

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
}));

const CategoryChart = props => {
  const { data: dataProp, labels, className, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();

  defaults.font.family = 'Roboto';
  defaults.color = theme.palette.text.primary;

  const totalsSum = dataProp.reduce((acc, curr) => {
    return acc + curr.total;
  }, 0);

  const data = {
    datasets: [
      {
        label: 'Observations',
        backgroundColor: 'rgb(107, 237, 0)',
        data: dataProp.map(d => ((d.total / totalsSum) * 100).toFixed(2)),
      },
    ],
    labels,
  };

  const options = {
    indexAxis: 'y',
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    elements: {
      bar: {
        barThickness: 2,
      },
    },
    responsive: true,
    // maintainAspectRatio: false,
    animation: true,
    // cornerRadius: 20,
    // legend: {
    //   display: true,
    // },
    plugins: {
      datalabels: {
        display: function (context) {
          const value = context.dataset.data[context.dataIndex];
          return value > 0;
        },
        formatter: function (value, context) {
          return value + '%';
        },
        color: theme.palette.text.primary,
        align: 'end',
        anchor: 'center',
        font: { size: '16', weight: 'bold' },
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'Observation Category Breakdown',
        color: theme.palette.text.primary,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const { dataIndex } = context;
            const label = `Total: ${dataProp[dataIndex].total}`;
            return label;
          },
        },
      },
    },
    layout: {
      padding: 0,
    },
    scales: {
      xAxes: [
        {
          barThickness: 3, // number (pixels) or 'flex'
          maxBarThickness: 5, // number (pixels),          // barPercentage: 0.2,
          // categoryPercentage: 0.5,
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            // padding: 20,
            fontColor: theme.palette.text.secondary,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider,
          },
          ticks: {
            // padding: 20,
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
            maxTicksLimit: 5,
            callback: value => {
              return value > 0 ? value + 'K' : value;
            },
          },
        },
      ],
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      caretSize: 10,
      yPadding: 20,
      xPadding: 20,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
      callbacks: {
        title: () => {},
        // label: tooltipItem => {
        //   let label = `This year: ${tooltipItem.yLabel}`;
        //
        //   if (tooltipItem.yLabel > 0) {
        //     label += 'K';
        //   }
        //
        //   return label;
        // },
      },
    },
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Bar data={data} options={options} />
    </div>
  );
};

CategoryChart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
  labels: PropTypes.array.isRequired,
};

export default CategoryChart;
