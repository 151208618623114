import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import * as Sentry from '@sentry/browser';

import PrimaryButton from '../shared/buttons/PrimaryButton';

import { inspectionMediaExtractTextEndpoint } from '../../store/apiV2/inspectionMedia';
import { isEmpty } from '../../utilities/objects';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(3, 'auto'),
    padding: theme.spacing(3),
    maxWidth: '90%',
  },
  body: {
    width: '100%',
    padding: theme.spacing(2, 7),
    textAlign: 'left',
    display: 'flex',
  },
  field: {
    margin: theme.spacing(2, 0),
  },
  header: {
    paddingBottom: theme.spacing(2),
    fontSize: '18px',
  },
  title: {
    fontSize: '14px',
  },
}));

const InspectionMediaExtractTextView = props => {
  const classes = useStyles();
  const id = useParams().id; // id of the inspection media
  const { extractedText, setExtractedText } = props;
  const [loading, setLoading] = useState(false); // used to control button disabling

  const { dispatchRequest: postInspectionMediaExtractText } = inspectionMediaExtractTextEndpoint.useEndpoint();

  /**
   * Makes the request to the backend endpoint
   */
  const postExtractTextRequest = async id => {
    try {
      // make request
      const requestResult = await postInspectionMediaExtractText(id);
      const fullText = requestResult.data.text.fullTextAnnotation.text;
      return fullText;
    } catch (error) {
      // api error
      const message = `Error during extract text: ${error}`;
      console.error(message);
      Sentry.captureMessage(message);
      return message;
    }
  };

  /**
   * Sets button to loading (disabled), makes async request to backend, sets the button
   * loading to false when response is received, and sets the extractedText state to the
   * result, which will re-render the component with the text.
   */
  const extractTextFromImage = async () => {
    setLoading(true);
    const text = await postExtractTextRequest(id);
    setLoading(false); // probably unnecessary
    setExtractedText(text);
  };

  const renderExtractText = () => {
    if (isEmpty(extractedText)) {
      // no text data yet
      return (
        <PrimaryButton
          spin
          icon
          Icon={ManageSearchOutlinedIcon}
          label="Extract text"
          onClick={extractTextFromImage}
          loading={loading}
        />
      );
    } else {
      // text data already known
      return (
        <pre>{extractedText}</pre> // using pre tag to preserve existing newlines
      );
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <strong>Extract image text</strong>
      </div>
      {renderExtractText()}
    </div>
  );
};
InspectionMediaExtractTextView.defaultProps = {
  extractedText: null,
  setExtractedText: () => {},
};

InspectionMediaExtractTextView.propTypes = {
  extractedText: PropTypes.string.isRequired,
  setExtractedText: PropTypes.func.isRequired,
};

export default InspectionMediaExtractTextView;
