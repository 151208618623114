import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Card, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import BallotIcon from '@mui/icons-material/Ballot';

import { getProjectsCountsByStatus } from '../../store/features/projectsActions';
import StyledLink from '../shared/StyledLink';

const STAT_LINKS = {
  MY_PROJECTS: { status: 'INITIALIZED,ASSIGNED,IN_PROGRESS,READY_FOR_REVIEW' },
  INITIALIZED_ASSIGNED: { status: 'INITIALIZED,ASSIGNED' },
  IN_PROGRESS: { status: 'IN_PROGRESS' },
  READY_FOR_REVIEW: { status: 'READY_FOR_REVIEW' },
};

// eslint-disable-next-line react/prop-types
const StatsCard = ({ icon, label, value, loading, onClick }) => {
  return (
    <Card>
      <StyledLink onClick={onClick}>
        <Typography variant="h3">
          {icon}
          {loading ? <>&nbsp;</> : value}
        </Typography>
        <StyledLink underline>
          <Typography variant="h6">{label}</Typography>
        </StyledLink>
      </StyledLink>
    </Card>
  );
};

const DashboardStats = props => {
  const { onProjectsTableChange } = props;

  // ---- REDUX

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { loading, error, data } = useSelector(state => state.projects.countsByStatus);

  useEffect(() => {
    dispatch(getProjectsCountsByStatus({ assigned: true }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // ---- RENDER FUNCS

  if (error) {
    return <div>{error}</div>;
  }

  const handleClick = filter => {
    // to go projects tab if not selected
    if (location.hash !== '#projects') {
      history.push(`/#projects`);
    }
    onProjectsTableChange(filter);
  };

  return (
    <Grid container direction="row" justifyContent="flex-start">
      <Grid item xs>
        <StatsCard
          loading={loading}
          icon={<BallotIcon />}
          label="My Projects"
          value={data?.total}
          onClick={() => handleClick(STAT_LINKS.MY_PROJECTS)}
        />
      </Grid>
      <Grid item xs>
        <StatsCard
          loading={loading}
          icon={<FolderSpecialIcon />}
          label="Initialized/Assigned"
          value={data?.initialized + data?.assigned}
          onClick={() => handleClick(STAT_LINKS.INITIALIZED_ASSIGNED)}
        />
      </Grid>
      <Grid item xs>
        <StatsCard
          loading={loading}
          icon={<FindReplaceIcon />}
          label="In Progress"
          value={data?.in_progress}
          onClick={() => handleClick(STAT_LINKS.IN_PROGRESS)}
        />
      </Grid>
      <Grid item xs>
        <StatsCard
          loading={loading}
          icon={<VisibilityIcon />}
          label="Ready for Review"
          value={data?.ready_for_review}
          onClick={() => handleClick(STAT_LINKS.READY_FOR_REVIEW)}
        />
      </Grid>
    </Grid>
  );
};

DashboardStats.propTypes = {
  onProjectsTableChange: PropTypes.func.isRequired,
};

export default DashboardStats;
