import React from 'react';
import PropTypes from 'prop-types';
import { AssetLink, FormLink, ProjectLink } from '../../shared/links/InternalLinks';

const ChecklistBreadcrumbs = ({ checklist }) => {
  const hasAssetBreadcrumb = checklist?.asset?.name;
  const hasProjectBreadcrumb = checklist?.project?.name;
  const isForm = !checklist?.project;
  return (
    <>
      <AssetLink id={checklist?.asset?.id} asset_path={checklist?.asset?.name} />
      {hasAssetBreadcrumb && <> / </>}
      <ProjectLink id={checklist?.project?.id} name={checklist?.project?.name} />
      {hasProjectBreadcrumb && <> / </>}
      {isForm && <FormLink id={checklist.id} name={checklist.template.name} version={checklist.template.version} />}
      {!isForm && <>{checklist.template.name}</>}
    </>
  );
};

ChecklistBreadcrumbs.propTypes = {
  checklist: PropTypes.object.isRequired,
};

export default ChecklistBreadcrumbs;
