import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ButtonGroup } from '@mui/material';
import ViewDisplayChips from '../shared/form/ViewDisplayChips';
import Table, { SELECTABLE_ROWS_NONE } from '../shared/table/Table';
import Description from '../shared/table/Description';
import {
  setDefectProfilesTableView,
  setDefectProfilesTableSize,
} from '../../store/settings/views/defectProfilesTableViewRedux';
import { openDialog } from '../../store/dialogActions';
import { locationWithBack } from '../../utilities/route';
import StyledLink from '../shared/StyledLink';
import { setColumns } from '../shared/table/columns';
import DeleteDialog from '../shared/Dialog/DeleteDialog';
import { usePermissions, useTableViews } from '../../hooks/settingsHooks';
import { tableViews } from '../../utilities/tables';
import { defectProfilesListAllEndpoint, defectProfilesDeleteEndpoint } from '../../store/apiV2/defectProfiles';
import { openSnackbar } from '../../store/snackbarActions';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const DefectProfilesTable = props => {
  const { title } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const defectProfilesListAll = defectProfilesListAllEndpoint.useEndpoint();
  const { dispatchRequest: deleteDefectProfile } = defectProfilesDeleteEndpoint.useEndpoint();
  const { loading } = defectProfilesListAll;
  const data = defectProfilesListAll.data.results;
  const { defectProfilesTableViewKey } = tableViews;
  const views = useTableViews(defectProfilesTableViewKey);
  const { hasConfigurationManagementBuild } = usePermissions();

  /** @type {Column[]} */
  const columns = [
    {
      // This needs to be first and excluded so customBodyRender can access the id to create the link.
      name: 'id',
      label: 'Id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        filterType: 'textField',
        customBodyRender: (value, tableMeta) => {
          return (
            <StyledLink to={`/finding-profiles/${tableMeta.rowData[columns.findIndexByName['id']]}`} value={value} />
          );
        },
      },
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        filter: true,
        sort: true,
        filterType: 'textField',
        customBodyRender: value => {
          if (!value) return <></>;
          return <Description value={value} />;
        },
      },
    },
    {
      name: 'version',
      label: 'Version',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'categories',
      label: 'Categories',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => <ViewDisplayChips value={value} />,
      },
    },
    {
      name: 'state',
      label: 'State',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'schema_version',
      label: 'Schema Version',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        print: false,
        viewColumns: false,
        customBodyRender: (_, info) => {
          // 0 is the first element in column
          const id = info.rowData[columns.findIndexByName['id']];
          const name = info.rowData[columns.findIndexByName['name']];
          return (
            <>
              {hasConfigurationManagementBuild && (
                <ButtonGroup>
                  <EditIcon
                    fontSize="small"
                    onClick={() => {
                      history.push(locationWithBack({ pathname: `/finding-profiles/${id}/edit` }, location));
                    }}
                  />
                  <DeleteIcon
                    fontSize="small"
                    onClick={() => {
                      dispatch(
                        openDialog(
                          'Delete Finding Profile?',
                          <DeleteDialog
                            id={id}
                            name={name}
                            deleteAction={async () => {
                              try {
                                await deleteDefectProfile(id);
                                defectProfilesListAll.dispatchRequest(); // refresh table
                              } catch (error) {
                                console.error('Error:', error);
                                dispatch(openSnackbar(error.error, 'error'));
                              }
                            }}
                          />
                        )
                      );
                    }}
                  />
                </ButtonGroup>
              )}
            </>
          );
        },
      },
    },
  ];

  // handle columns display
  setColumns(columns, views);

  /** @type {TableOptions} */
  const options = {
    // if the column view changes, update redux with either 'add' or 'remove'
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setDefectProfilesTableView(changedColumn, action));
    },
    // Add default column sort here, not in columns
    sortOrder: {
      name: 'state',
      direction: 'asc',
    },
    onChangeRowsPerPage: numberOfRows => {
      dispatch(setDefectProfilesTableSize(numberOfRows));
    },
    rowsPerPage: views.pageSize,
    enableNestedDataAccess: '.',
    selectableRows: SELECTABLE_ROWS_NONE,
  };

  return (
    <Table
      title={title}
      simpleSearch
      columns={columns}
      addRoute="/finding-profiles/new"
      data={data}
      options={options}
      loading={loading}
    />
  );
};

DefectProfilesTable.propTypes = {
  title: PropTypes.string.isRequired,
};

export default DefectProfilesTable;
