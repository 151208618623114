import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import PrimaryButton from '../buttons/PrimaryButton';

const CustomToolbarFileUploader = props => {
  const { showFileUploader, setShowFileUploader } = props;
  return (
    <>
      <PrimaryButton
        icon
        Icon={AddIcon}
        label="New"
        tooltip="Add Media Files"
        onClick={() => {
          setShowFileUploader(!showFileUploader);
        }}
      />
    </>
  );
};

CustomToolbarFileUploader.propTypes = {
  showFileUploader: PropTypes.bool.isRequired,
  setShowFileUploader: PropTypes.func.isRequired,
};

export default CustomToolbarFileUploader;
