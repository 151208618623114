import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { Alert } from '@mui/material';

import ChecklistsTable from './ChecklistsTable';
import { getAllChecklists } from '../../store/features/checklistsActions';
import { useTableViewSettings } from '../../hooks/settingsHooks';
import { tableViews } from '../../utilities/tables';
import { toQueryString } from '../../utilities/strings';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const ChecklistsTablePage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { checklistsTableViewKey } = tableViews;

  const error = useSelector(state => state.checklists.all.error);

  const {
    tableQuery: storedChecklistsQuery,
    embeddedQuery: checklistsEmbeddedQuery,
    page: checklistsPage,
    views: checklistsView,
  } = useTableViewSettings(checklistsTableViewKey);

  useEffect(() => {
    dispatch(getAllChecklists(storedChecklistsQuery));
  }, []); // eslint-disable-line

  const tableChangeHandler = query => {
    // no need to fetch data if the query is the same.
    const newSearch = toQueryString(query);
    if (location.search === newSearch) return;
    const newLocation = location;
    newLocation.search = newSearch;
    history.push(newLocation);
    const updatedQuery = { ...checklistsEmbeddedQuery, ...query };
    dispatch(getAllChecklists(updatedQuery));
  };

  return (
    <div className={classes.root}>
      {error && (
        <Alert severity="error" style={{ marginBottom: '1rem' }}>
          {error}
        </Alert>
      )}
      <ChecklistsTable
        title="Forms"
        queryParamObj={storedChecklistsQuery}
        tableChangeHandler={tableChangeHandler}
        views={checklistsView}
        page={checklistsPage}
      />
    </div>
  );
};

export default ChecklistsTablePage;
