import React, { useEffect } from 'react';
import { Grid, Toolbar, Button, ButtonGroup } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from 'react-redux';
import { getAllNotificationRuleSubscribers } from '../../store/features/notificationRuleSubscribersActions';
import NotificationRuleSubscribersTable from '../notificationRuleSubscribers/NotificationRuleSubscribersTable';
import Title from '../shared/displays/Title';
import { openDialog } from '../../store/dialogActions';
import SubscribeForm from './SubscribeForm';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(7, 'auto'),
    padding: theme.spacing(2),
  },
  container: {
    justifyContent: 'space-between',
  },
  toolBar: {
    paddingLeft: theme.spacing(1),
    fontSize: theme.spacing(3),
  },
  buttonGroup: {
    backgroundColor: theme.palette.secondary.main,
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
}));

const NotificationPreferences = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllNotificationRuleSubscribers({ mine: true }));
  }, []); // eslint-disable-line

  const handleSubscribeToRule = () => {
    dispatch(openDialog('Subscribe To Rule', <SubscribeForm />));
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Toolbar className={classes.container}>
          <Title title="Notification Preferences" />
          <ButtonGroup>
            <Button onClick={handleSubscribeToRule}>Subscribe +</Button>
          </ButtonGroup>
        </Toolbar>
      </Grid>
      <Grid item xs={12}>
        <NotificationRuleSubscribersTable hideUser />
      </Grid>
    </Grid>
  );
};

export default NotificationPreferences;
