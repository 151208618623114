
import {
  requestFactory,
  responseHandlerForm,
} from '../base';

const get = requestFactory('get');
const post = requestFactory('post', { handler: responseHandlerForm });
const patch = requestFactory('patch', { handler: responseHandlerForm });
const del = requestFactory('del');

export const fetchProjectTypesList = async query => get('/api/project-types/', query);
export const fetchProjectTypes = async id => get(`/api/project-types/${id}/`);
export const createProjectTypes = async body => post('/api/project-types/', body);
export const updateProjectTypes = async (id, body) => patch(`/api/project-types/${id}/`, body);
export const deleteProjectTypes = async id => del(`/api/project-types/${id}/`);

