// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import React from 'react';
import CommonRoutes from './utilities/CommonRoutes';
import DefectsForm from '../../components/defects/DefectsForm';
import DefectDetail from '../../components/defects/DefectDetail';
import DefectsTablePage from '../../components/defects/DefectsTablePage';
import MainLayout from '../layouts/MainLayout';

const DefectsRoutes = props => {
  return (
    <CommonRoutes
      pageTitle="Findings"
      layout={MainLayout}
      Edit={<DefectsForm update />}
      Create={<DefectsForm />}
      View={<DefectDetail />}
      All={<DefectsTablePage />}
    />
  );
};

export default DefectsRoutes;
