import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ButtonIcon from '../shared/buttons/ButtonIcon';
import { downloadFile, apiDateToString } from '../../utilities/strings';
import FieldDisplayTable from '../shared/form/FieldDisplayTable';
import UserDisplay from '../shared/displays/UserDisplay';
import { isEmpty } from '../../utilities/objects';

const useStyles = makeStyles(theme => ({
  reportInfo: {
    margin: theme.spacing(3, 4, 5),
  },
  title: {
    padding: theme.spacing(0, 0, 1, 1),
    margin: theme.spacing(1, 0),
    fontSize: theme.spacing(3),
  },
}));

const handleDownload = (instanceData = {}) => {
  if (!isEmpty(instanceData)) {
    downloadFile(instanceData.name, instanceData.file);
  }
};

const AssessmentReportInfo = props => {
  const { reports } = props;
  const colOrder = ['pdf_file', 'uploaded_by', 'generated_on'];
  const data = reports
    ? reports.map(row => ({
        pdf_file: (
          <ButtonIcon
            iconSize="large"
            icon={PictureAsPdfIcon}
            tooltip={'Download'}
            disabled={!reports.length}
            onClick={() => handleDownload(reports[0])}
          />
        ),
        uploaded_by: <UserDisplay {...row.uploaded_by} />,
        generated_on: apiDateToString(row.generated_on, 'date'),
      }))
    : [];
  const classes = useStyles();

  if (data.length === 0) {
    return (
      <div>
        <div className={`${classes.title} h7`}>Assessment</div>
        <div>No reports available</div>
      </div>
    );
  }
  return (
    <div className={classes.reportInfo} data-testid="assessment-reports">
      <FieldDisplayTable title label={'Assessment'} data={data} colOrder={colOrder} showHeaderCol={false} />
    </div>
  );
};

AssessmentReportInfo.propTypes = {
  reports: PropTypes.array.isRequired,
};
export default AssessmentReportInfo;
