import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Divider, List, ListItem, ListItemText, Drawer, ListSubheader } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LinearProgressWithLabel from '../../shared/displays/LinearProgressWithLabel';
import { calculateSectionProgress } from '../../../utilities/checklist';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

const SectionDrawer = props => {
  const { currentSection, setSection, anchor, closeDrawer, open, openSections, setOpenSections } = props;
  const { results, template } = props.checklist;
  const classes = useStyles();
  const handleListItemClick = (event, data) => {
    setSection(data);
    if (openSections.indexOf(data) === -1) {
      const newList = openSections.concat(data);
      setOpenSections(newList);
    }
  };

  const list = (anchor, sections) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={closeDrawer()}
      onKeyDown={closeDrawer()}>
      <List>
        <ListSubheader>Sections</ListSubheader>
        <Divider />
        {sections.map((section, index) => {
          const p = calculateSectionProgress(section, results);
          const secondary = (
            <>
              {`Lines: ${p.lineCount}, Results: ${p.resultCount}, Notes: ${p.noteCount}, Media: ${p.mediaCount}`}
              <LinearProgressWithLabel value={p.progress} />
            </>
          );
          return (
            <ListItem button key={section.key} selected={currentSection === section.key}>
              <ListItemText
                primary={section.label}
                secondary={secondary}
                onClick={event => handleListItemClick(event, section.key)}
              />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
  return (
    <Drawer anchor={anchor} open={open} onClose={closeDrawer()}>
      {list(anchor, template.sections)}
    </Drawer>
  );
};

SectionDrawer.propTypes = {
  currentSection: PropTypes.string,
  setSection: PropTypes.func.isRequired,
  checklist: PropTypes.object,
  openSections: PropTypes.array.isRequired,
  setOpenSections: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  anchor: PropTypes.string.isRequired,
  closeDrawer: PropTypes.func.isRequired,
};
SectionDrawer.defaultProps = { currentSection: '', checklist: undefined };
export default SectionDrawer;
