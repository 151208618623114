import { all, takeLatest, put } from 'redux-saga/effects';

import { FEATURES_REQUEST, FEATURES_SUCCESS, FEATURES_FAILURE } from './featuresActions';
import { SNACKBAR_OPEN } from '../snackbarActions';

import { fetchFeatures } from '../../api/settings/features';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';

export function* getFeatures(action) {
  const { options } = action;
  try {
    const { data, error, response } = yield fetchFeatures();
    if (data) {
      yield put({ type: FEATURES_SUCCESS, features: data });
      if (options?.notify) {
        yield put({
          type: SNACKBAR_OPEN,
          message: `Settings refreshed successfully.`,
          variant: 'success',
          open: true,
        });
      }
    } else if (error) {
      yield put({ type: FEATURES_FAILURE, error });
      if (options?.notify) {
        yield put({
          type: SNACKBAR_OPEN,
          message: error,
          variant: 'error',
          open: true,
        });
      }
    } else {
      yield defaultErrorHandler(response, FEATURES_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, FEATURES_FAILURE);
  }
}

function* watchGetFeatures() {
  yield takeLatest(FEATURES_REQUEST, getFeatures);
}

export default function* featuresSaga() {
  yield all([watchGetFeatures()]);
}
