import { buildEndpoint, get, post, del, patch } from './util';

export const EACH_REDUCER = 'bookmarksEach';

export const bookmarksListAllEndpoint = buildEndpoint('bookmarksListAll', query => get('/api/bookmarks/', query));

export const bookmarksCreateEndpoint = buildEndpoint('bookmarksCreate', data => post('/api/bookmarks/', data), {
  reducerKey: EACH_REDUCER,
});

export const bookmarksDeleteEndpoint = buildEndpoint('bookmarksDelete', id => del(`/api/bookmarks/${id}/`), {
  reducerKey: EACH_REDUCER,
});

export const bookmarksUpdateEndpoint = buildEndpoint(
  'bookmarksUpdate',
  (id, body) => patch(`/api/bookmarks/${id}/`, body),
  {
    reducerKey: EACH_REDUCER,
  }
);
