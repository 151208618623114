import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, FormHelperText } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { TextField } from 'mui-rff';
import { FieldArray } from 'react-final-form-arrays';

const FieldEditKeyValue = props => {
  const { name, label, loading, disabled, helperText } = props;

  return (
    <>
      <FieldArray name={name}>
        {({ fields }) => (
          <>
            <Grid container spacing={1} direction="column">
              <Grid item xs={12}>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item xs>
                    {label}
                    {fields.length ? <sup>*</sup> : <></>}
                  </Grid>
                  <Grid item xs>
                    Values
                  </Grid>
                  <Grid item xs={1}></Grid>
                </Grid>
              </Grid>
              {fields.map((name, index) => (
                <Grid item key={index} xs={12}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs>
                      <TextField
                        name={`${name}.name`}
                        placeholder="label"
                        required
                        disabled={disabled || loading}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        name={`${name}.value`}
                        placeholder="value"
                        disabled={disabled || loading}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        size="small"
                        disabled={disabled}
                        onClick={() => fields.remove(index)}
                        aria-label="remove key/value">
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              <Grid item>
                <IconButton
                  type="button"
                  size="small"
                  disabled={disabled}
                  onClick={() => fields.push({ name: '', value: '' })}
                  aria-label="add key/value">
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
          </>
        )}
      </FieldArray>
      <FormHelperText variant="outlined">{helperText}</FormHelperText>
    </>
  );
};

FieldEditKeyValue.defaultProps = {
  disabled: false,
  loading: false,
  helperText: '',
};

FieldEditKeyValue.propTypes = {
  /** the api key for the feature */
  name: PropTypes.string.isRequired,
  /** label for all the keys */
  label: PropTypes.string.isRequired,
  /** disables the field while true */
  disabled: PropTypes.bool,
  /** disables the filed while true */
  loading: PropTypes.bool,
  /** adds helper text below the form field */
  helperText: PropTypes.string,
};

export default FieldEditKeyValue;
