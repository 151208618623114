import { buildEndpoint, get, patch, post } from './util';

/**
 * https://docs.huvrdata.app/reference/api_inspection-media_list
 *
 * USE THIS WITH A FILTER !! otherwise you will get TOO MANY media objects
 *
 */
export const inspectionMediaListBatchFetchAllEndpoint = buildEndpoint(
  'inspectionMediaList',
  query => get('/api/inspection-media/', query),
  { batchFetchAll: true }
);

/**
 * https://docs.huvrdata.app/reference/api_inspection-media_process
 *
 * Use the `process` action on the InspectionMedia endpoint
 */
export const inspectionMediaProcessEndpoint = buildEndpoint(
  'inspectionMediaProcess',
  data => post('/api/inspection-media/process/', data),
  {}
);

export const inspectionMediaExtractTextEndpoint = buildEndpoint(
  'inspectionMediaExtractText',
  id => post(`/api/inspection-media/${id}/extract-text/`),
  {}
);

export const EACH_REDUCER = 'inspectionMediaEach';

export const inspectionMediaCreateEndpoint = buildEndpoint(
  'inspectionMediaCreate',
  data => post('/api/inspection-media/', data),
  { reducerKey: EACH_REDUCER }
);

export const inspectionMediaBulkDeleteEndpoint = buildEndpoint(
  'inspectionMediaBulkDeleteProcess',
  data => post('/api/inspection-media/bulk-delete/', data),
  { reducerKey: EACH_REDUCER }
);

export const inspectionMediaUpdateEndpoint = buildEndpoint(
  'inspectionMediaUpdate',
  (id, data) => patch(`/api/inspection-media/${id}/`, data),
  { reducerKey: EACH_REDUCER }
);
