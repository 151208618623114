/**
 * looks for items in existing array by _id_; overwrites
 *
 * @param {Array} existingItems
 * @param {Object} item { id, ... }
 * @param {String} alternateKey sometimes object might have another key name other than id
 */
const updateOrAppend = (existingItems, item, alternateKey = 'id') => {
  const idx = existingItems.findIndex(i => i.id === item[alternateKey]);
  // rename alternateKey to id
  const { [alternateKey]: id, ...rest } = item;
  if (idx === -1) {
    existingItems.push({id, ...rest});
  } else { 
    existingItems[idx] = { ...existingItems[idx], id, ...rest };
  }
};

/**
 *
 * @param {Array} existingItems
 * @param {Array|Object} newItems (if Object, will cast to array)
 *
 * @returns {Array} mergedItems (copy) - does not mutate input values
 */
export const mergeNewItemsIntoExisting = (existingItems, newItems, alternateKey='id') => {
  if (!Array.isArray(newItems)) {
    newItems = [newItems];
  }

  const mergedItems = [...existingItems];
  newItems.forEach(item => updateOrAppend(mergedItems, item, alternateKey));

  return mergedItems;
};



