// --------------------------------------------------------------------------------
//
// Generated File
//
// !! Do not change this file by hand.                                       !!
// !! This file is automatically generated from the reducers in this folder. !!
//
// --------------------------------------------------------------------------------
import assessmentTypesReducer from './assessmentTypesReducer';
import assessmentsReducer from './assessmentsReducer';
import assetTypesReducer from './assetTypesReducer';
import assetsReducer from './assetsReducer';
import bulkImportsReducer from './bulkImportsReducer';
import checklistTasksReducer from './checklistTasksReducer';
import checklistTemplatesReducer from './checklistTemplatesReducer';
import checklistsReducer from './checklistsReducer';
import cmlsReducer from './cmlsReducer';
import companiesReducer from './companiesReducer';
import crewsReducer from './crewsReducer';
import defectsReducer from './defectsReducer';
import inspectionMediaReducer from './inspectionMediaReducer';
import librariesReducer from './librariesReducer';
import libraryMediaReducer from './libraryMediaReducer';
import locationLayerProfilesReducer from './locationLayerProfilesReducer';
import measurementsReducer from './measurementsReducer';
import notificationRuleSubscribersReducer from './notificationRuleSubscribersReducer';
import notificationRulesReducer from './notificationRulesReducer';
import projectTypesReducer from './projectTypesReducer';
import projectsReducer from './projectsReducer';
import recommendedWorkPlanReducer from './recommendedWorkPlanReducer';
import reservationsReducer from './reservationsReducer';
import scheduleEventsReducer from './scheduleEventsReducer';
import schedulePlanReducer from './schedulePlanReducer';
import scheduleTasksReducer from './scheduleTasksReducer';
import scheduleWorkReducer from './scheduleWorkReducer';
import usersReducer from './usersReducer';

export default {
  assessmentTypes: assessmentTypesReducer,
  assessments: assessmentsReducer,
  assetTypes: assetTypesReducer,
  assets: assetsReducer,
  bulkImports: bulkImportsReducer,
  checklistTasks: checklistTasksReducer,
  checklistTemplates: checklistTemplatesReducer,
  checklists: checklistsReducer,
  cmls: cmlsReducer,
  companies: companiesReducer,
  crews: crewsReducer,
  defects: defectsReducer,
  inspectionMedia: inspectionMediaReducer,
  libraries: librariesReducer,
  libraryMedia: libraryMediaReducer,
  locationLayerProfiles: locationLayerProfilesReducer,
  measurements: measurementsReducer,
  notificationRuleSubscribers: notificationRuleSubscribersReducer,
  notificationRules: notificationRulesReducer,
  projectTypes: projectTypesReducer,
  projects: projectsReducer,
  recommendedWorkPlan: recommendedWorkPlanReducer,
  reservations: reservationsReducer,
  scheduleEvents: scheduleEventsReducer,
  schedulePlan: schedulePlanReducer,
  scheduleTasks: scheduleTasksReducer,
  scheduleWork: scheduleWorkReducer,
  users: usersReducer,
};
