// --------------------------------------------------------------------------------
//
// Generated File
//
// !! Do not change this file by hand.                                    !!
//   !! This file is automatically generated from the sagas in this folder. !!
//
// --------------------------------------------------------------------------------
import assetsGetterSaga from './assetsGetterSaga';
import auditSaga from './auditSaga';
import helpSaga from './helpSaga';
import leadFiltersSaga from './leadFiltersSaga';
import observationsSaga from './observationsSaga';
import pagesSaga from './pagesSaga';
import profileSaga from './profileSaga';
import projectReportSaga from './projectReportSaga';
import projectSelectedFindingsSaga from './projectSelectedFindingsSaga';
import projectSummarySaga from './projectSummarySaga';
import reservationStateSaga from './reservationStateSaga';
import revisionsSaga from './revisionsSaga';
import statsSaga from './statsSaga';
import usageStatsSaga from './usageStatsSaga';
import usersGetterSaga from './usersGetterSaga';

export default [
  assetsGetterSaga(),
  auditSaga(),
  helpSaga(),
  leadFiltersSaga(),
  observationsSaga(),
  pagesSaga(),
  profileSaga(),
  projectReportSaga(),
  projectSelectedFindingsSaga(),
  projectSummarySaga(),
  reservationStateSaga(),
  revisionsSaga(),
  statsSaga(),
  usageStatsSaga(),
  usersGetterSaga(),
];
