import { grey } from '@mui/material/colors';
import variables from '../../config.module.scss';

const MuiTable = {
  root: {
    minWidth: 0,
    width: '90%',
    margin: 'auto',
  },
};

const MuiTableHead = darkMode => ({
  styleOverrides: {
    root: {
      borderBottom: `1px solid ${darkMode ? variables.darkestGrey : variables.lightGrey}`,
    },
  },
});

const MuiTableCell = darkMode => ({
  styleOverrides: {
    sizeSmall: {
      padding: '4px 24px 4px 16px',
    },
    root: {
      borderBottom: `1px dashed ${darkMode ? variables.darkestGrey : variables.lightGrey}`,
    },
    footer: {
      borderBottom: 'none',
    },
  },
});

const tableHeaderStyles = darkMode => ({
  backgroundColor: darkMode ? grey[800] : variables.white,
});

const MUIDataTableHeadCell = darkMode => ({
  styleOverrides: {
    fixedHeader: tableHeaderStyles(darkMode),
  },
});

const MUIDataTableSelectCell = darkMode => ({
  styleOverrides: {
    headerCell: tableHeaderStyles(darkMode),
  },
});

const MUIDataTablePagination = darkMode => ({
  styleOverrides: {
    toolbar: { backgroundColor: 'transparent' },
  },
});

const tables = darkMode => ({
  MuiTable,
  MUIDataTableHeadCell: MUIDataTableHeadCell(darkMode),
  MUIDataTableSelectCell: MUIDataTableSelectCell(darkMode),
  MUIDataTablePagination: MUIDataTablePagination(darkMode),
  MuiTableHead: MuiTableHead(darkMode),
  MuiTableCell: MuiTableCell(darkMode),
});

export default tables;
