import { all, put, takeLatest } from 'redux-saga/effects';

import {
  COMPANY_STATS_REQUEST,
  COMPANY_STATS_SUCCESS,
  COMPANY_STATS_FAILURE,
  COMPANIES_STATS_REQUEST,
  COMPANIES_STATS_SUCCESS,
  COMPANIES_STATS_FAILURE,
} from './statsActions';

import { fetchCompanyStats, fetchCompaniesStats } from '../../api/getters/stats.js';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';

export function* companyStatsFetch(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield fetchCompanyStats(id);
    if (data) {
      yield put({ type: COMPANY_STATS_SUCCESS, data });
    } else if (error) {
      yield put({ type: COMPANY_STATS_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, COMPANY_STATS_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, COMPANY_STATS_FAILURE);
  }
}

export function* watchCompanyStatsFetch() {
  yield takeLatest(COMPANY_STATS_REQUEST, companyStatsFetch);
}

export function* companiesStatsFetch() {
  try {
    const { data, error, response } = yield fetchCompaniesStats();
    if (data) {
      yield put({ type: COMPANIES_STATS_SUCCESS, data });
    } else if (error) {
      yield put({ type: COMPANIES_STATS_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, COMPANIES_STATS_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, COMPANIES_STATS_FAILURE);
  }
}

export function* watchCompaniesStatsFetch() {
  yield takeLatest(COMPANIES_STATS_REQUEST, companiesStatsFetch);
}

export default function* statsSaga() {
  yield all([watchCompanyStatsFetch(), watchCompaniesStatsFetch()]);
}
