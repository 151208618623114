import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getReservation } from '../../store/features/reservationsActions';
import { fieldOrder, removeField, hideField } from './reservationsShared';
import { buildRoute, apiDateToString, jsonKeyToLabel } from '../../utilities/strings';
import UserDisplay from '../shared/displays/UserDisplay';
import CommonFormView from '../shared/form/CommonView';

const ReservationsView = () => {
  const id = useParams().id;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getReservation(id));
  }, [dispatch, id]);

  const { data, loading, error } = useSelector(state => state.reservations.each);

  const formSettings = {
    fieldOrder,
    removeField: removeField('VIEW'),
    hideField,
  };
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      // cast to string because booleans do not show up in the field
      let displayLabel = jsonKeyToLabel(key); // eslint-disable-line  prefer-const
      let displayValue = '';
      if (data[key] != null) {
        switch (key) {
          case 'work_scope':
          case 'work_provider':
          case 'special_instructions':
            fieldSettings[key] = {
              type: 'text',
              fieldProps: {
                label: displayLabel,
                value: data[key],
                multiline: true,
              },
            };
            return;
          case 'history':
            fieldSettings[key] = {
              type: 'table',
              fieldProps: {
                colOrder: ['state', 'modified_by', 'created_on', 'note'],
                data: data.history
                  ? data.history.map(row => ({
                      note: row.note,
                      modified_by: <UserDisplay {...row.modified_by} />,
                      state: row.state,
                      created_on: apiDateToString(row.created_on, 'date'),
                    }))
                  : [],
              },
            };
            return;
          case 'updated_on':
          case 'created_on':
            displayValue = apiDateToString(data[key], 'date');
            break;
          case 'assigned_to':
          case 'requestor':
            fieldSettings[key] = {
              type: 'user',
              fieldProps: {
                label: displayLabel,
                value: data[key],
              },
            };
            return;
          default:
            displayValue = data[key].toString();
        }
      }
      fieldSettings[key] = {
        type: 'text',
        fieldProps: {
          label: displayLabel,
          value: displayValue,
        },
      };
    });
  }

  return (
    <CommonFormView
      title="Reservation Details"
      values={data}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      editLink={buildRoute(`/reservations/${id}/edit`)}
      loading={loading}
      error={error}
    />
  );
};

export default ReservationsView;
