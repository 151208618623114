// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import React from 'react';
import CommonRoutes from './utilities/CommonRoutes';
import DefectProfilesForm from '../../components/defectProfiles/DefectProfilesForm';
import DefectProfilesView from '../../components/defectProfiles/DefectProfilesView';
import DefectProfilesTablePage from '../../components/defectProfiles/DefectProfilesTablePage';
import MainLayout from '../layouts/MainLayout';

const DefectProfilesRoutes = props => {
  return (
    <CommonRoutes
      pageTitle="Finding Profiles"
      layout={MainLayout}
      Edit={<DefectProfilesForm update />}
      Create={<DefectProfilesForm />}
      View={<DefectProfilesView />}
      All={<DefectProfilesTablePage />}
    />
  );
};

export default DefectProfilesRoutes;
