import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ButtonGroup, Button, Grid, IconButton, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

import AddSubscriberForm from './AddSubscriberForm';
import { openDialog } from '../../store/dialogActions';
import { historyPushWithBack } from '../../utilities/route';

const useStyles = makeStyles(theme => ({
  header: {
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
    // justify: 'left',
  },
  button: {
    padding: theme.spacing(0, 1),
  },
  buttonGroup: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const NotificationRulesToolbar = props => {
  const { id, data } = props;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleBack = () => {
    if (location.state && location.state.back) {
      history.push(location.state.back);
    } else {
      history.push('/notification-rules');
    }
  };

  const handleEdit = () => {
    historyPushWithBack(`/notification-rules/${id}/edit`, history, location);
  };

  const handleFormNew = () => {
    dispatch(openDialog('Add Subscriber', <AddSubscriberForm id={id} />));
  };

  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.header}>
      <Grid item>
        <IconButton onClick={handleBack} size="large">
          <ArrowBackIosIcon className={classes.button} />
        </IconButton>
      </Grid>
      <Grid item className={classes.title}>
        <Typography variant="h5">{data.name}</Typography>
      </Grid>
      <Grid item>
        <ButtonGroup className={classes.buttonGroup}>
          <Button startIcon={<EditIcon />} onClick={handleEdit}>
            Edit
          </Button>
          <Button startIcon={<AddIcon />} onClick={handleFormNew}>
            Add Subscriber
          </Button>
        </ButtonGroup>
      </Grid>
    </Grid>
  );
};

NotificationRulesToolbar.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export default NotificationRulesToolbar;
