import React from 'react';
import PropTypes from 'prop-types';
import { Checkboxes } from 'mui-rff';
import { FormHelperText } from '@mui/material';

const FieldEditCheckbox = props => {
  const { name, label, loading, disabled, helperText } = props;

  return (
    <>
      <Checkboxes
        disabled={loading || disabled}
        color="primary"
        variant="outlined"
        data={{ label }}
        name={name}
        formControlLabelProps={{ className: 'checkbox-input' }} // class defined in Common.scss
      />
      <FormHelperText variant="outlined">{helperText}</FormHelperText>
    </>
  );
};

FieldEditCheckbox.defaultProps = {
  loading: false,
  disabled: false,
  helperText: '',
};

FieldEditCheckbox.propTypes = {
  /** label for the string field */
  label: PropTypes.string.isRequired,
  /** the api key for the feature */
  name: PropTypes.string.isRequired,
  /** disables the filed while true */
  loading: PropTypes.bool,
  /** disables the fild while true */
  disabled: PropTypes.bool,
  /** adds helper text below the form field */
  helperText: PropTypes.string,
};

export default FieldEditCheckbox;
