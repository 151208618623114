import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import ScheduleEventsTable from './ScheduleEventsTable';
import { getAllScheduleEvents } from '../../store/features/scheduleEventsActions';
import { toQueryString } from '../../utilities/strings';
import { useTableViewSettings } from '../../hooks/settingsHooks';
import { tableViews } from '../../utilities/tables';
import { getParams } from '../../utilities/route';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(7, '3vw'),
    padding: theme.spacing(2),
  },
}));

const ScheduleEventsTablePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const { scheduleEventsTableViewKey } = tableViews;
  const { tableQuery: scheduleEventsQuery, page, views } = useTableViewSettings(scheduleEventsTableViewKey);

  // check for url queries
  const queryParams = getParams(location, scheduleEventsQuery);

  useEffect(() => {
    dispatch(getAllScheduleEvents(queryParams));
  }, []); // eslint-disable-line

  const tableChangeHandler = query => {
    // no need to fetch data if the query is the same.
    const newSearch = toQueryString(query);
    if (location.search === newSearch) return;
    const newLocation = location;
    newLocation.search = newSearch;
    history.push(newLocation);
    dispatch(getAllScheduleEvents(query));
  };

  return (
    <div className={classes.root}>
      <ScheduleEventsTable
        title="Schedule Events"
        queryParamObj={queryParams}
        tableChangeHandler={tableChangeHandler}
        page={page}
        views={views}
      />
    </div>
  );
};

export default ScheduleEventsTablePage;
