import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import { Container } from '@mui/material';

import Checklist from '../../checklistEditor/Checklist';
import LoadingPage from '../../shared/displays/LoadingPage';
import { checklistTemplatesFillableReadEndpoint } from '../../../store/apiV2/checklistTemplates';
import Error from '../../shared/displays/Error';
import { predefinedErrors } from '../../../utilities/errors';
import StyledLink from '../../shared/StyledLink';
import { inspectionMediaListBatchFetchAllEndpoint } from '../../../store/apiV2/inspectionMedia';

export const checklistTemplateError = () => {
  return <Error style={{ padding: '8rem', fontSize: '1.3rem' }} error={predefinedErrors.checklistTemplate} />;
};

const FormNewChecklist = () => {
  const { templateId } = useParams();
  const checklistTemplateState = useSelector(state => checklistTemplatesFillableReadEndpoint.selector(state));
  const checklistState = useSelector(state => state.checklists.each);
  const { dispatchRequest: batchFetchAllMedia } = inspectionMediaListBatchFetchAllEndpoint.useEndpoint();

  const { loading, error } = checklistTemplateState;
  const [revisionId, setRevisionId] = useState(); // will be set to 'latest' after checklist submitted

  const dispatch = useDispatch();
  useEffect(() => {
    // Fetches all inspection media for the new form (assume any "NOSOURCE" media can be used)
    //  required for the "ChecklistGallery";  accessed via the `useMediaStore` hook
    //  see: https://github.com/huvrdata/huvr/pull/8144#discussion_r1230064527
    const newFormQuery = {
      document_category: 'image',
      hidden: false,
      ordering: '-created_on',
      created_by_me: true,
      file: 'NOSOURCE',
      recent: 'last_24_hours',
    };
    if (templateId) {
      dispatch(checklistTemplatesFillableReadEndpoint.actionCreators.request([templateId]));
    }
    batchFetchAllMedia({ ...newFormQuery, limit: 200 });
  }, [templateId, dispatch, batchFetchAllMedia]);

  const refresh = () => {
    dispatch(checklistTemplatesFillableReadEndpoint.actionCreators.request([templateId]));
  };

  // After checklist is submitted, redirect to new checklist
  if (revisionId === 'latest' && checklistState?.data?.id && !checklistState.loading) {
    return <Redirect to={`/forms/${checklistState.data.id}`} />;
  }

  if (loading) {
    return <LoadingPage />;
  }
  if (error) {
    return checklistTemplateError();
  }

  if (checklistTemplateState.data.id === -1) {
    return <LoadingPage />;
  }

  // Reshape template into checklist instance so Checklist component is happy
  const checklist = { id: 'new', template: checklistTemplateState.data, flags: {}, results: {} };

  const title =
    checklist && checklist.template ? (
      <StyledLink
        to={`/checklist-templates/${checklist.template.id}`}
        value={`${checklist.template.name} / ${checklist.template.version}`}
      />
    ) : (
      <>Checklist Template</>
    );

  return (
    <Container maxWidth={false} disableGutters>
      <div className="container">
        <Checklist
          parentId={'new'}
          checklist={checklist}
          title={title}
          refresh={refresh}
          revisions={[]}
          selectedRevisionId="new"
          setRevisionId={id => {
            if (id !== 'latest') {
              console.warn('setRevisionId called with id other than "latest"');
            }
            setRevisionId(id);
          }}
          isForm
        />
      </div>
    </Container>
  );
};

export default FormNewChecklist;
