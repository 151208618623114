import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { apiDateToString } from '../../utilities/strings';
import FieldDisplayTable from '../shared/form/FieldDisplayTable';
import DefectStatesDisplay from './DefectStatesDisplay';
import UserDisplay from '../shared/displays/UserDisplay';

const useStyles = makeStyles(theme => ({
  defectHistory: {
    padding: theme.spacing(0, 3),
  },
}));

const DefectHistory = props => {
  const { stateHistory } = props;
  const colOrder = ['state', 'modified_by', 'created_on', 'note'];
  const data = stateHistory
    ? stateHistory.map(row => ({
        note: row.note,
        modified_by: <UserDisplay {...row.modified_by} />,
        state: <DefectStatesDisplay state={row.state} />,
        created_on: apiDateToString(row.created_on, 'date'),
      }))
    : [];
  const classes = useStyles();

  return (
    <div className={classes.defectHistory}>
      <FieldDisplayTable label={'State History'} data={data} colOrder={colOrder} title />
    </div>
  );
};

DefectHistory.propTypes = {
  stateHistory: PropTypes.array.isRequired,
};
export default DefectHistory;
