import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { Paper, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ImageAspectRatioIcon from '@mui/icons-material/ImageAspectRatio';

import LinearProgressWithLabel from '../shared/displays/LinearProgressWithLabel';
import { inspectionEnabled } from '../../utilities/projects';
import { locationWithBack } from '../../utilities/route';
import PrimaryButton from '../shared/buttons/PrimaryButton';
import { useFeatureFlags } from '../../hooks/settingsHooks';

const useStyles = makeStyles(theme => ({
  projectSummary: {
    margin: theme.spacing(1, 4, 3),
    padding: theme.spacing(1),
  },
  checklists: {
    margin: theme.spacing(1, 4),
    fontSize: theme.spacing(2),
  },
  list: {
    '& li': {
      margin: '5px 0',
    },
    listStyle: 'none',
    padding: theme.spacing(0, 2),
  },
  checklistInstance: {
    width: theme.spacing(30),
    marginRight: theme.spacing(2),
  },
  summaryButton: {
    margin: theme.spacing(2),
  },
}));

const VisualInspectorBlock = props => {
  const classes = useStyles();
  const { project, isReadOnly, handleInspection, showV1 } = props;

  // TODO: remove this switch once we are good with the new checklist viewer
  let gridLabel = 'Visual Inspection';
  let editLabel = project.type?.defect_profile?.name;
  if (!showV1) {
    gridLabel = project.type?.defect_profile?.name;
    editLabel = 'Inspect';
  }

  const progress = 0;
  const progressLabel = `Media: ${project.visual_inspection_status.media_count}, Findings: ${project.visual_inspection_status.defect_count}`;

  return (
    <Grid container direction="column" className={classes.checklistInstance} justifyContent="space-between">
      <Grid item>
        <p className="h7">{gridLabel}</p>
        <PrimaryButton
          onClick={() => {
            handleInspection();
          }}
          label={editLabel}
          icon
          Icon={ImageAspectRatioIcon}
          disabled={isReadOnly}
        />
      </Grid>
      <Grid item className={classes.progressBar}>
        {progressLabel}
        <LinearProgressWithLabel value={progress} />
      </Grid>
    </Grid>
  );
};

VisualInspectorBlock.propTypes = {
  project: PropTypes.object,
  isReadOnly: PropTypes.bool,
  handleInspection: PropTypes.func,
  showV1: PropTypes.bool,
};

const ChecklistBlock = props => {
  const classes = useStyles();
  const { instance, isReadOnly, handleChecklistView, handleChecklistEdit, showV1 } = props;
  const editDisabled = instance?.is_locked || isReadOnly; // for disabling checklist edit button
  // TODO: remove this switch once we are good with the new checklist viewer
  let gridLabel = 'Checklist';
  let editLabel = instance.template.name;
  let showViewButton = false;
  if (!showV1) {
    gridLabel = instance.template.name;
    editLabel = 'Edit';
    showViewButton = true;
  }
  // backend percent as as decimal (0.5 === 50%)
  const progress = instance.progress.progress * 100 || 0;
  const progressLabel = `Sections: ${instance.template.sections.length}, Notes: ${
    instance.progress.note_count || 0
  }, Media: ${instance.progress.media_count || 0}`;

  return (
    <Grid
      container
      direction="column"
      key={instance.id}
      className={classes.checklistInstance}
      justifyContent="space-between">
      <Grid item>
        <p className="h7">{gridLabel}</p>
        <PrimaryButton
          onClick={() => {
            handleChecklistEdit(instance.id);
          }}
          label={editLabel}
          icon
          Icon={PlaylistAddCheckIcon}
          disabled={editDisabled}
        />
        {showViewButton && (
          <span className={classes.summaryButton}>
            <PrimaryButton
              onClick={() => {
                handleChecklistView(instance.id);
              }}
              label="View"
              icon
              Icon={VisibilityIcon}
            />
          </span>
        )}
      </Grid>
      <Grid item className={classes.progressBar}>
        {progressLabel}
        <LinearProgressWithLabel value={progress} />
      </Grid>
    </Grid>
  );
};

ChecklistBlock.propTypes = {
  instance: PropTypes.object,
  isReadOnly: PropTypes.bool,
  handleChecklistEdit: PropTypes.func,
  handleChecklistView: PropTypes.func,
  showV1: PropTypes.bool,
};

const ProjectSummary = props => {
  const { project, isReadOnly } = props;
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();

  const { hasChecklistViewer } = useFeatureFlags();
  const handleChecklistEdit = function (id) {
    history.push(locationWithBack({ pathname: `/projects/${project.id}/checklists/${id}` }, location));
  };

  const handleChecklistView = function (id) {
    history.push(locationWithBack({ pathname: `/checklist-viewer/${id}` }, location));
  };

  const handleInspection = function (mediaId) {
    if (mediaId) {
      history.push(locationWithBack({ pathname: `/projects/${project.id}/inspect/${mediaId}` }, location));
      return;
    }
    history.push(locationWithBack({ pathname: `/projects/${project.id}/inspect` }, location));
  };

  const showV1 = !hasChecklistViewer; // TODO: remove this switch we are good with the new checklist viewer
  const isVisualInspectionProject = inspectionEnabled(project);
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <p className={`h7 ${classes.checklists}`}>Project Summary</p>
          <Paper className={classes.projectSummary}>
            <Grid container>
              {project.checklist_instances.map(instance => (
                <ChecklistBlock
                  instance={instance}
                  isReadOnly={isReadOnly}
                  key={instance.id}
                  handleChecklistEdit={handleChecklistEdit}
                  handleChecklistView={handleChecklistView}
                  showV1={showV1}
                />
              ))}
              {isVisualInspectionProject && (
                <VisualInspectorBlock
                  project={project}
                  isReadOnly={isReadOnly}
                  handleInspection={handleInspection}
                  showV1={showV1}
                />
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

ProjectSummary.defaultProps = {
  project: undefined,
};

ProjectSummary.propTypes = {
  project: PropTypes.object,
  isReadOnly: PropTypes.bool,
};

export default ProjectSummary;
