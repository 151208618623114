import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { getAuthTokenId, openMobileDeeplink } from '../../utilities/auth';
import PrimaryButton from '../shared/buttons/PrimaryButton';

const useStyles = makeStyles(theme => {
  return {
    mobileLoginButton: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  };
});

const LoginLoadingMobile = () => {
  const classes = useStyles();

  const openDeepLink = () => {
    const idToken = getAuthTokenId();
    openMobileDeeplink(idToken);
  };

  // This component is for when a user logs into a mobile device.
  // The mobile device uses a webview, so we want to avoid the api calls used by the dashboard
  return (
    <div className={classes.mobileLoginButton}>
      <PrimaryButton onClick={openDeepLink} label={'Complete Login'} />
    </div>
  );
};

export default LoginLoadingMobile;
