import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

// components
import CommonFormView from '../shared/form/CommonView';
import ChecklistValue from '../shared/checklist/ChecklistValue';
import StyledLink from '../shared/StyledLink';

// util
import { checklistResultLinesReadEndpoint } from '../../store/apiV2/checklistResultLines';
import { fieldOrder, removeField, hideField, FIELDS } from './checklistResultLineShared';
import { jsonKeyToLabel } from '../../utilities/strings';

const ChecklistResultLineView = () => {
  const id = useParams().id;
  const {
    data,
    loading,
    error,
    dispatchRequest: fetchChecklistResultLine,
  } = checklistResultLinesReadEndpoint.useEndpoint();

  useEffect(() => {
    fetchChecklistResultLine(id);
  }, [fetchChecklistResultLine, id]);

  const formSettings = {
    fieldOrder,
    removeField: removeField('VIEW'),
    hideField,
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    if (loading || isEmpty(data)) {
      continue;
    }
    fieldOrder[section].fields.forEach(key => {
      let displayLabel = jsonKeyToLabel(key); // eslint-disable-line prefer-const
      let value = data[key];
      let displayValue = '';
      let fieldType = 'text';

      if (key.includes('template_line.')) {
        displayLabel = jsonKeyToLabel(key.replace('template_line.', ''));
        value = data.template_line[key.replace('template_line.', '')];
      }

      if (value != null) {
        // check for null or undefined, not just falsy values
        switch (key) {
          // add cases for each key that needs to be customized.
          case FIELDS.value:
            fieldType = 'component';
            displayValue = () => <ChecklistValue lineType={data.template_line.type} value={value} />;
            break;

          case FIELDS.media:
            fieldType = 'component';
            displayValue = () => (
              <>
                {value.map(v => (
                  <img key={v.id} src={v.file} />
                ))}
              </>
            );
            break;

          case FIELDS.project.$:
            fieldType = 'component';
            displayValue = () => <StyledLink to={`/projects/${data.project.id}`}>{data.project.name}</StyledLink>;
            break;

          case FIELDS.checklist.$:
            displayLabel = 'Form';
            fieldType = 'component';
            displayValue = () => <StyledLink to={`/forms/${data.checklist.id}`}>{data.checklist.id}</StyledLink>;
            break;

          case FIELDS.template.$:
            fieldType = 'component';
            displayValue = () => <StyledLink to={`/templates/${data.template.id}`}>{data.template.name}</StyledLink>;
            break;

          default:
            displayValue = value.toString();
        }
      }
      fieldSettings[key] = {
        type: fieldType,
        fieldProps: {
          label: displayLabel,
          value: displayValue,
        },
      };
    });
  }

  // UPDATE ME: Replace feature name with singular version.
  return (
    <CommonFormView
      title={'Checklist Result Line Details'}
      values={data}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      readOnly
      editLink={`/checklist-result-line/${id}/edit`}
      loading={loading}
      error={error}
    />
  );
};

export default ChecklistResultLineView;
