import React from 'react';
import PropTypes from 'prop-types';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  helpIcon: {
    fontSize: '0.9rem',
    position: 'absolute',
    top: '0.4rem',
    right: '0.4rem',
  },
  helpIconReducePadding: {
    fontSize: '0.9rem',
    position: 'absolute',
    top: '0.2rem',
    right: '-0.1rem',
  },
}));

const TableHeaderLabelTooltip = ({ label, tooltipMessage, reducePadding }) => {
  const classes = useStyles();

  return (
    <>
      <span>{label}</span>
      <Tooltip title={tooltipMessage} placement="top">
        <HelpOutlineIcon className={reducePadding ? classes.helpIconReducePadding : classes.helpIcon} />
      </Tooltip>
    </>
  );
};

TableHeaderLabelTooltip.propTypes = {
  label: PropTypes.string.isRequired,
  tooltipMessage: PropTypes.string.isRequired,
  reducePadding: PropTypes.bool,
};

/**
 * convenience generator, returns customHeadLabelRender function
 *
 * @param {String} tooltipMessage
 * @returns {Function} pass to for mui-datatable `customHeadLabelRender`
 */
export function customHeadLabelWithTooltipRenderer(tooltipMessage) {
  /**
   * >
   * > customHeadLabelRender	function
   * > Function that returns a string or React component.
   * > Used for creating a custom header to a column.
   * > This method only affects the display in the table's header,
   * > other areas of the table (such as the View Columns popover),
   * > will use the column's label.
   * > function(columnMeta : object) => string| React Component
   * >
   * > https://www.npmjs.com/package/mui-datatables
   */
  return args => {
    const {
      display,
      label,
      sort,
      // download: true
      // empty : false
      // filter : false
      // filterType : "textField"
      // index : 7
      // name : "measurements_summary.base.value"
      // print : true
      // searchable : true
      // sortCompare : null
      // sortDescFirst : false
      // sortThirdClickReset : false
      // viewColumns : true
    } = args;

    if (!display) {
      return <></>;
    }
    return (
      <TableHeaderLabelTooltip
        label={label}
        tooltipMessage={tooltipMessage}
        // reduce padding if column is sortable, as the sort button adds extra padding
        reducePadding={Boolean(sort)}
      />
    );
  };
}

export default TableHeaderLabelTooltip;
