export const FIELDS = {
  ADDRESS_LIST: 'address_list',
  COMPANY: 'company',
  ROLES: 'roles',
  IS_ADMIN: 'is_admin',
  SEND_WELCOME_EMAIL: 'send_welcome_email',
  LAST_LOGIN: 'last_login',
  DATE_JOINED: 'date_joined',
  NAME: 'name',
  DISPLAY_NAME: 'display_name',
  IS_ACTIVE: 'is_active',
  ADMIN: 'admin',
  SUBCONTRACTOR: 'subcontractor',
};

export const {
  ADDRESS_LIST,
  COMPANY,
  ROLES,
  IS_ADMIN,
  SEND_WELCOME_EMAIL,
  LAST_LOGIN,
  DATE_JOINED,
  NAME,
  DISPLAY_NAME,
  IS_ACTIVE,
  ADMIN,
  SUBCONTRACTOR,
} = FIELDS; // convenience export

/**
 * fieldOrder
 *
 * An object where the keys define the sections of the form.   Each section requires the key display (string) and
 * fields (array of strings). Fields are the api keys (in snake case) for each field that will be displayed in the form.
 * Any fields that are never shown should not be added to this array.
 *
 * @return {object}
 */
export const fieldOrder = {
  info: {
    display: 'Info',
    fields: [ADDRESS_LIST],
  },
  permissions: {
    display: 'Permissions',
    fields: [COMPANY, ROLES, IS_ADMIN, SEND_WELCOME_EMAIL],
  },
  status: {
    display: 'Status',
    fields: [LAST_LOGIN, DATE_JOINED],
  },
};

/**
 *  removeField
 *
 *  removes a field depending on if a user is creating, updating or viewing.
 *
 *  @param {string} state - enum for creating, updating or viewing ['CREATE', 'UPDATE', 'VIEW']
 *  @param {string} item - api key (in snake case) to check whether it should be hidden.
 *  @return {boolean} - returns true if the item will not be shown
 */
export const removeField = state => item => {
  let fields = [];
  switch (state) {
    case 'CREATE':
      fields = [NAME, LAST_LOGIN, DATE_JOINED, DISPLAY_NAME, IS_ACTIVE];
      break;
    case 'UPDATE':
      fields = [NAME, LAST_LOGIN, DATE_JOINED, DISPLAY_NAME];
      break;
    case 'VIEW':
      fields = [];
      break;
    default:
      return false;
  }
  return fields.includes(item);
};

/**
 * hideField
 *
 * This function is for hiding a field depending on the value of another field.
 *
 * @param {object} values - the values of all the fields in the form.
 * @param {string} item - api key (in snake case) to check whether it should be removed.
 * @return {boolean} - returns true if the item will not be shown
 */
export const hideField = (values, item) => {
  return false;
};
