import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { buildEndpointActionTypes, buildEndpointActionCreators } from './actions';
import { buildEndpointReducer, buildEndpointInitialState } from './reducer';
import { buildEndpointSaga } from './saga';

/**
 * @typedef {Object} UseEndpointHook
 *
 * Access the endpoint state and dispatch the request from a component
 *
 *  example usage:
 *
 * import { myEndpoint } from '../store/apiV2/myEndpoint';
 *
 * const MyComponent = () => {
 *   const { data, loading, error, dispatchRequest } = myEndpoint.useEndpoint();
 *
 *   useEffect(() => {
 *     dispatchRequest(queryParams);
 *   }, []);
 *
 *   if (loading) { ... }
 *   if (error) { ... }
 *   return <>{data.results.map(asset => <div>{asset.name}</div>)}</>;
 * }
 *
 * @property {Function} dispatchRequest - call this to make the api request
 *    arguments passed to this function will be passed to the apiRequest function
 *
 *    returns a Promise
 *
 *    example:
 *      try {
 *        const { data } = myUpdateEndpoint.dispatchRequest(24, { newData })
 *        history.push(`/my-update/${data.id}`)
 *      } catch (e) {
 *        console.error(..., e)
 *      }
 * @property {Function} resetState - call this to clear any errors, etc
 * @property {Object} data - the data returned from the api request ex: { results: [] , count: 24, ... }
 * @property {*} error
 * @property {*} formError - if `post` / `patch`, and a valid json error response is returned, this will be set
 * @property {Boolean} loading
 * @property {Array} apiRequestArgs - the arguments passed to the apiRequest function (for referencing in another component, etc)
 *
 */

/**
 *
 * @param {String} endpointName - ex: `"assetsList"`
 * @param {Function} apiRequest - ex: `(id, data) => patch(`/api/assets/${id}/`, data)`
 * @param {Object} options
 * @param {*} options.defaultData - the default data for the endpoint (ex: `[]` - default to `{}`) convenience for backwards compat
 * @param {Boolean} options.batchFetchAll - if true, the endpoint will fetch all pages of data, and set a single array in `data.results`
 * @param {String} options.reducerKey - optional - default to endpointName (hint - can set multiple to same reducer to combine state)
 *
 */
export const buildEndpoint = (endpointName, apiRequest, options = {}) => {
  const reducerKey = options.reducerKey || endpointName;
  const actionTypes = buildEndpointActionTypes(endpointName);
  const actionCreators = buildEndpointActionCreators(endpointName);
  const reducer = buildEndpointReducer(endpointName, options);
  const saga = buildEndpointSaga(endpointName, apiRequest, options);
  const initialState = buildEndpointInitialState(options.defaultData);
  const selector = state => {
    return state.apiV2[reducerKey] || initialState;
  };

  /**
   * @returns {UseEndpointHook}
   *
   */
  const useEndpoint = () => {
    const dispatch = useDispatch();
    const { loading, error, formError, data, apiRequestArgs } = useSelector(selector);

    /**
     * @param {*} apiRequestArgs - spread to the apiRequest function
     * @returns {Promise} - resolves or rejects when the api request is complete
     */
    const dispatchRequest = useCallback(
      (...apiRequestArgs) => {
        return new Promise((resolve, reject) => {
          dispatch(actionCreators.request(apiRequestArgs, resolve, reject));
        });
      },
      [dispatch]
    );

    const resetState = useCallback(() => {
      dispatch(actionCreators.resetState());
    }, [dispatch]);

    return useMemo(
      () => ({
        dispatchRequest,
        resetState,
        data,
        error,
        formError,
        loading,
        apiRequestArgs,
      }),
      [dispatchRequest, resetState, data, error, formError, loading, apiRequestArgs]
    );
  };

  return {
    isEndpoint: true, // for type checking

    actionCreators,
    actionTypes,
    endpointName,
    reducer,
    reducerKey,
    saga,
    selector,
    useEndpoint,
  };
};
