import { requestFactory, responseHandlerForm } from '../base';

const get = requestFactory('get');
const post = requestFactory('post', { handler: responseHandlerForm });
const patch = requestFactory('patch', { handler: responseHandlerForm });
const del = requestFactory('del');

export const fetchNotificationRuleSubscribersList = async query => get('/api/notification-rule-subscribers/', query);
export const fetchNotificationRuleSubscribers = async id => get(`/api/notification-rule-subscribers/${id}/`);
export const createNotificationRuleSubscribers = async body => post('/api/notification-rule-subscribers/', body);
export const updateNotificationRuleSubscribers = async (id, body) =>
  patch(`/api/notification-rule-subscribers/${id}/`, body);
export const deleteNotificationRuleSubscribers = async id => del(`/api/notification-rule-subscribers/${id}/`);
