import { all, put, takeLatest } from 'redux-saga/effects';

import {
  PAGES_GETTER_REQUEST,
  PAGES_GETTER_SUCCESS,
  PAGES_GETTER_FAILURE,
  PAGE_BY_PATH_GETTER_REQUEST,
  PAGE_BY_PATH_GETTER_SUCCESS,
  PAGE_BY_PATH_GETTER_FAILURE,
} from './pagesActions';

import { fetchPages, fetchPageByPath } from '../../api/getters/pages.js';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';

export function* pagesFetch(action) {
  const { query } = action;
  try {
    const { data, error, response } = yield fetchPages(query);
    if (data) {
      yield put({ type: PAGES_GETTER_SUCCESS, data });
    } else if (error) {
      yield put({ type: PAGES_GETTER_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, PAGES_GETTER_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, PAGES_GETTER_FAILURE);
  }
}

export function* pageFetchByPath(action) {
  const { path } = action;
  try {
    const { data, error, response } = yield fetchPageByPath(path);
    if (data) {
      yield put({ type: PAGE_BY_PATH_GETTER_SUCCESS, data });
    } else if (error) {
      yield put({ type: PAGE_BY_PATH_GETTER_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, PAGE_BY_PATH_GETTER_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, PAGE_BY_PATH_GETTER_FAILURE);
  }
}

export function* watchPagesFetch() {
  yield takeLatest(PAGES_GETTER_REQUEST, pagesFetch);
}
export function* watchPageFetchByPath() {
  yield takeLatest(PAGE_BY_PATH_GETTER_REQUEST, pageFetchByPath);
}

export default function* pagesSaga() {
  yield all([watchPagesFetch(), watchPageFetchByPath()]);
}
