import { combineReducers } from 'redux';

import {
  LOOKUP_RESET,
  LOOKUP_REQUEST,
  LOOKUP_SUCCESS,
  LOOKUP_SET_ROUTE,
  LOOKUP_CLEAR_ROUTE,
  LOOKUP_FAILURE,
  LOGINOUT_RESET,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  HANDLE_UNAUTHORIZED,
  HANDLE_UNAUTHORIZED_COMPLETED,
  SET_LANDING_URL,
} from './authActions';

export const initialStateLookup = {
  isLookingUp: false,
  isDone: false,
  email: '',
  route: '',
  apiKey: '',
  authDomain: '',
  projectId: '',
  tenantId: '',
  providers: [],
  error: '',
  formError: {},
};

export const lookupReducer = (state = initialStateLookup, action) => {
  switch (action.type) {
    case LOOKUP_RESET:
      return {
        ...initialStateLookup,
      };
    case LOOKUP_REQUEST:
      return {
        ...initialStateLookup,
        isLookingUp: true,
        email: action.email,
        route: action.route,
      };
    case LOOKUP_SUCCESS:
      return {
        ...state,
        isLookingUp: false,
        isDone: true,
        apiKey: action.apiKey,
        authDomain: action.authDomain,
        projectId: action.projectId,
        tenantId: action.tenantId,
        email: action.email,
        route: action.route,
        providers: action.providers,
      };
    case LOOKUP_SET_ROUTE:
      return {
        ...state,
        route: action.route,
      };
    case LOOKUP_CLEAR_ROUTE:
      return {
        ...state,
        route: '',
      };
    case LOOKUP_FAILURE:
      return {
        ...state,
        isLookingUp: false,
        isDone: false,
        error: action.error,
        formError: action.formError || {},
      };
    default:
      return state;
  }
};

export const initialStateLogInOut = {
  isLoggingIn: false,
  isLoggingOut: false,
  resetLookup: false,
  user: {},
  loginError: '',
  logoutError: '',
  landingUrl: null,
};

export const logInOutReducer = (state = initialStateLogInOut, action) => {
  switch (action.type) {
    case LOGINOUT_RESET:
      return {
        ...initialStateLogInOut,
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoggingIn: true,
        user: {},
        loginError: '',
        logoutError: '',
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggingIn: false,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoggingIn: false,
        loginError: action.error,
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        isLoggingOut: true,
        logoutError: '',
        resetLookup: !!action.resetLookup,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isLoggingOut: false,
        resetLookup: false,
        user: {},
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        isLoggingOut: false,
        resetLookup: false,
        user: {},
        logoutError: action.error,
      };
    case SET_LANDING_URL:
      return {
        ...state,
        landingUrl: action.url,
      };
    default:
      return state;
  }
};

export const initialStateUnauthorized = {
  isUnauthorized: false,
  error: '',
};

export const unauthorizedReducer = (state = initialStateUnauthorized, action) => {
  switch (action.type) {
    case HANDLE_UNAUTHORIZED:
      return {
        ...initialStateUnauthorized,
        isUnauthorized: true,
      };
    case HANDLE_UNAUTHORIZED_COMPLETED:
      return {
        ...state,
        isUnauthorized: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default combineReducers({
  lookup: lookupReducer,
  logInOut: logInOutReducer,
  unauthorized: unauthorizedReducer,
});
