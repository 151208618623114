import React from 'react';
import PropTypes from 'prop-types';

// lib
import { Card, IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

// components
import ChecklistFileUploader from '../ChecklistFileUploader';
import FullScreenDialog from '../../shared/FullScreenDialog';

/**
 * @description Dialog for uploading/attaching files to checklist line items
 */
export const FileUploaderDialog = props => {
  const { fields, open, setOpen, containerId, containerType } = props;

  /**
   * @description Callback for when the upload is successful
   */
  const onUploadSuccess = () => {
    setOpen(false);
  };

  /**
   * @description Callback for when each upload is successful
   * @param {Object} _file
   * @param {Object} _response
   * @param {Object} mediaObj - inspection media object from create request
   */
  const onUploadEachSuccess = (_file, _response, mediaObj) => {
    fields.push(mediaObj);
  };

  return (
    <FullScreenDialog open={open} onClose={() => setOpen(false)}>
      <FullScreenDialog.AppBar>
        <IconButton onClick={() => setOpen(false)} aria-label="close">
          <ArrowBack />
        </IconButton>
      </FullScreenDialog.AppBar>
      <FullScreenDialog.Container>
        <Card>
          <ChecklistFileUploader
            containerId={containerId}
            containerType={containerType}
            onUploadSuccess={onUploadSuccess}
            onUploadEachSuccess={onUploadEachSuccess}
          />
        </Card>
      </FullScreenDialog.Container>
    </FullScreenDialog>
  );
};

FileUploaderDialog.propTypes = {
  /** RFF FieldArray */
  fields: PropTypes.object.isRequired,
  /** Show or hide open */
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  /** project / checklist id */
  containerId: PropTypes.string.isRequired,
  containerType: PropTypes.string.isRequired,
};

export default FileUploaderDialog;
