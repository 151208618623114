// https://github.com/huvrdata/huvr/blob/82f0cb5a7e0ad2b6d3aa965f598b5fd11b4972b4/huvr_api/huvr_api/checklists/choices.py#L56-L57
export const DEFECT_MODES = {
  LIST: 'LIST',
  FREE: 'FREE',
  OFF: 'OFF',
};

// https://github.com/huvrdata/huvr/blob/82f0cb5a7e0ad2b6d3aa965f598b5fd11b4972b4/huvr_api/huvr_api/checklists/choices.py#L58-L60
export const COMPONENT_MODES = {
  OFF: 'OFF',
  LIST: 'LIST',
  FREE: 'FREE',
  ASSET: 'ASSET',
};

// https://github.com/huvrdata/huvr/blob/82f0cb5a7e0ad2b6d3aa965f598b5fd11b4972b4/huvr_api/huvr_api/checklists/choices.py#L61
export const ZONE_MODES = {
  OFF: 'OFF',
  LIST: 'LIST',
  FREE: 'FREE',
};

// https://github.com/huvrdata/huvr/blob/82f0cb5a7e0ad2b6d3aa965f598b5fd11b4972b4/huvr_api/huvr_api/checklists/choices.py#L5-L10
export const DOCUMENT_STATES = {
  DRAFT: 'DRAFT',
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  // TODO: "CACHED"
};

// https://github.com/huvrdata/huvr/blob/82f0cb5a7e0ad2b6d3aa965f598b5fd11b4972b4/huvr_api/huvr_api/checklists/choices.py#L64-L71
export const MEDIA_STATES = {
  CREATED: 'CREATED',
  UPLOADED: 'UPLOADED',
  PROCESSING: 'PROCESSING',
  PROCESSED: 'PROCESSED',
  INSPECTED: 'INSPECTED',
};

// https://github.com/huvrdata/huvr/blob/82f0cb5a7e0ad2b6d3aa965f598b5fd11b4972b4/huvr_api/huvr_api/checklists/choices.py#L75-L86
export const GEO_MODES = {
  FIXED: 'FIXED',
  FIXED_LINEAR: 'FIXED_LINEAR',
  FIXED_ROTATING: 'FIXED_ROTATING',
  MOBILE: 'MOBILE',
  INTERIOR: 'INTERIOR',
  UNDERSEA: 'UNDERSEA',
  UNKNOWN: 'UNKNOWN',
};

// https://github.com/huvrdata/huvr/blob/ee4a1f8cbb014369388aa14d4509b56302b16437/huvr_api/huvr_api/assets/choices.py#L17-L21
export const CML_MODES = {
  DISABLED: 'DISABLED',
  PIPE: 'PIPE',
  TANK: 'TANK',
};

// https://github.com/huvrdata/huvr/blob/82f0cb5a7e0ad2b6d3aa965f598b5fd11b4972b4/huvr_api/huvr_api/checklists/choices.py#L90-L97
export const CML_TYPES = {
  OTHER: 'OTHER',
  PIPE: 'PIPE',
  TANK: 'TANK',
  // TODO "BOILER" / "VESSEL" / "PART"
};

// https://github.com/huvrdata/huvr/blob/4fb2293b1004ee6acf809b7a1ec75c1132bd0b5e/huvr_api/huvr_api/assets/choices.py#L23-L28
export const LIBRARY_MODES = {
  DISABLED: 'DISABLED',
  ON_CREATE: 'ON_CREATE',
  OPTIONAL: 'OPTIONAL',
};

// https://github.com/huvrdata/huvr/blob/82f0cb5a7e0ad2b6d3aa965f598b5fd11b4972b4/huvr_api/huvr_api/checklists/choices.py#L42-L49
export const DEFECT_STATES = {
  PENDING: 'PENDING',
  OPEN: 'OPEN',
  MONITOR: 'MONITOR',
  REPAIR: 'REPAIR',
  REPAIRED: 'REPAIRED',
  CLOSED: 'CLOSED',
};

// https://github.com/huvrdata/huvr/blob/2256b5070683860354cae33036788155fef59588/huvr_api/huvr_api/reports/choices.py#L4
export const REPORT_MODES = {
  PROJECT: 'PROJECT',
  FORM: 'FORM',
};

// https://github.com/huvrdata/huvr/blob/2256b5070683860354cae33036788155fef59588/huvr_api/huvr_api/reports/choices.py#L13
export const TITLE_MODES = {
  DEFAULT: 'DEFAULT',
  PAGE: 'PAGE',
  PAGE_SIMPLE: 'PAGE_SIMPLE',
  PAGE_SIMPLE_2: 'PAGE_SIMPLE_2',
};

// https://github.com/huvrdata/huvr/blob/2256b5070683860354cae33036788155fef59588/huvr_api/huvr_api/reports/choices.py#L8-L12
export const COVER_MODES = {
  OFF: 'OFF',
  TITLE: 'TITLE',
  CHECKLIST_INLINE: 'CHECKLIST_INLINE',
};

// https://github.com/huvrdata/huvr/blob/2256b5070683860354cae33036788155fef59588/huvr_api/huvr_api/reports/choices.py#L15-L17
export const MEASUREMENT_MODES = {
  DEFAULT: 'DEFAULT',
  UT_PIPE_CALC: 'UT_PIPE_CALC',
};

// https://github.com/huvrdata/huvr/blob/2256b5070683860354cae33036788155fef59588/huvr_api/huvr_api/reports/choices.py#L7
export const DENSITY_OPTIONS = {
  DEFAULT: 'DEFAULT',
  COMPACT: 'COMPACT',
};

// https://github.com/huvrdata/huvr/blob/2256b5070683860354cae33036788155fef59588/huvr_api/huvr_api/reports/choices.py#L6
export const LAYOUT_MODES = {
  DEFAULT: 'DEFAULT',
  '2COL': '2COL', // (unfortunately starts with number, can't actually use this)
  NOTESINLINE: 'NOTESINLINE',
};

// https://github.com/huvrdata/huvr/blob/aea580a3545387d00f165f18fa32a34d7c34b3c3/huvr_api/huvr_api/notify/choices.py#L4-L8
export const NOTIFICATION_EVENTS = {
  CHECKLIST_CHANGE: 'CHECKLIST_CHANGE',
  CHECKLIST_CREATE: 'CHECKLIST_CREATE',
  CHECKLIST_EDIT: 'CHECKLIST_EDIT',
  PROJECT_STATUS_CHANGE: 'PROJECT_STATUS_CHANGE',
};

// https://github.com/huvrdata/huvr/blob/33626dba3f4196ac111ad915a1f1a3a18732eac6/huvr_api/huvr_api/projects/choices.py#L21-L27
export const MONITORING_MODES = {
  DISABLED: 'DISABLED',
  UT_PIPE_STD: 'UT_PIPE_STD',
  UT_PIPE_MT: 'UT_PIPE_MT',
  UT_TANK_STD: 'UT_TANK_STD',
  UT_TANK_MT: 'UT_TANK_MT',
};

// https://github.com/huvrdata/huvr/blob/82f0cb5a7e0ad2b6d3aa965f598b5fd11b4972b4/huvr_api/huvr_api/checklists/choices.py#L98-L112
export const MEASUREMENT_TYPES = {
  UT_PIPE: 'UT_PIPE',
  UT_TANK: 'UT_TANK',
  UT_COAT: 'UT_COAT',
};

// https://github.com/huvrdata/huvr/blob/804745670ab066000ef383d5a34073c6d6dbb75c/huvr_api/huvr_api/schedules/choices.py#L8
export const TASK_CREATE_MODE = {
  AS_NEEDED: 'AS_NEEDED',
  FIXED_COUNT: 'FIXED_COUNT',
};

// https://github.com/huvrdata/huvr/blob/804745670ab066000ef383d5a34073c6d6dbb75c/huvr_api/huvr_api/schedules/choices.py#L9
export const WORK_TYPE = {
  CHECKLIST: 'CHECKLIST',
  PROJECT: 'PROJECT',
};

// https://github.com/huvrdata/huvr/blob/d00d8e04f2ac757a6e2312644743409a6ae60c59/huvr_api/huvr_api/common/models.py#L462-L469
export const DOCUMENT_CATEGORIES = {
  image: 'image',
  video: 'video',
  audio: 'audio',
  office: 'office',
  archive: 'archive',
  other: 'other',
};

// https://github.com/huvrdata/huvr/blob/33626dba3f4196ac111ad915a1f1a3a18732eac6/huvr_api/huvr_api/projects/choices.py#L31-L35
export const PROJECT_CHILDREN_MODES = {
  DISABLED: 'DISABLED',
  DYNAMIC: 'DYNAMIC',
  FIXED: 'FIXED',
};

// https://github.com/huvrdata/huvr/blob/82f0cb5a7e0ad2b6d3aa965f598b5fd11b4972b4/huvr_api/huvr_api/checklists/choices.py#L130-L132
export const UNIT_TYPES = {
  NOT_SET: 'NOT_SET',
  INCHES: 'in',
  MILLIMETERS: 'mm',
};

// https://github.com/huvrdata/huvr/blob/b3bd716cf62f9ac0ae60d0bbbd3dc40fbca9d620/huvr_api/huvr_api/checklists/choices.py#L92-L95
export const MEASUREMENT_STATES = {
  PENDING: 'PENDING',
  NEEDS_VERIFICATION: 'NEEDS_VERIFICATION',
  ACTIVE: 'ACTIVE',
  IGNORE: 'IGNORE',
};

// https://github.com/huvrdata/huvr/blob/b3bd716cf62f9ac0ae60d0bbbd3dc40fbca9d620/huvr_api/huvr_api/dashboards/choices.py#L6-L9
export const PAGE_SOURCE_TYPES = {
  IFRAME: 'IFRAME',
  LOOKER: 'LOOKER',
  MARKDOWN: 'MARKDOWN',
  SYSTEM: 'SYSTEM',
  RBLX: 'RBLX',
};

// https://github.com/huvrdata/huvr/blob/master/huvr_api/huvr_api/reports/models.py#L157
export const CHECKLIST_MEDIA_WIDTHS = {
  FOUR: 25,
  THREE: 33,
  TWO: 50,
  DEFAULT: 100,
};

export const COMPANY_ROLES = {
  SUBCONTRACTOR: 'SUBCONTRACTOR',
  OWNER: 'OWNER',
  SERVICE: 'SERVICE',
};

// https://github.com/huvrdata/huvr/blob/master/huvr_api/huvr_api/common/roles.py
export const ROLES = {
  assessment_builder: 'assessment_builder',
  assessment_viewer: 'assessment_viewer',
  asset_manager: 'asset_manager',
  company_asset_manager: 'company_asset_manager',
  company_findings_manager: 'company_findings_manager',
  company_project_manager: 'company_project_manager',
  configuration_builder: 'configuration_builder',
  findings_manager: 'findings_manager',
  process_data_manager: 'process_data_manager',
  process_data_viewer: 'process_data_viewer',
  project_manager: 'project_manager',
  scheduler: 'scheduler',
  subcontractor: 'subcontractor',
  technician: 'technician',
  user_admin: 'user_admin',
  viewer: 'viewer',
  workspace_admin: 'workspace_admin',
};

// https://github.com/huvrdata/huvr/blob/master/huvr_api/huvr_api/assessments/choices.py
export const WORK_STATUS = {
  RECOMMENDED: 'RECOMMENDED',
  PLANNED: 'PLANNED',
  COMPLETE: 'COMPLETE',
  DEFERRED: 'DEFERRED',
  CANCELLED: 'CANCELLED',
};

// https://github.com/huvrdata/huvr/blob/master/huvr_api/huvr_api/assessments/choices.py
export const ASSESSMENT_STATUS = {
  INITIALIZED: 'INITIALIZED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETE: 'COMPLETE',
};

export const RISK_SCORE = {
  LOW: 'LOW',
  MEDIUM: 'MEDIUM',
  MEDIUM_HIGH: 'MEDIUM_HIGH',
  HIGH: 'HIGH',
};

export const RISK_COLOR = {
  GREEN: '#6FBE44',
  YELLOW: '#eeea47',
  ORANGE: '#F5992D',
  RED: '#EE2A28',
};

// https://github.com/huvrdata/huvr/blob/master/huvr_api/huvr_api/assessments/choices.py
export const DISPLAY_MODES = {
  DETAIL: 'DETAIL',
  SUMMARY: 'SUMMARY',
  DETAIL_SUMMARY: 'DETAIL_SUMMARY',
};

// https://github.com/huvrdata/huvr/blob/master/huvr_api/huvr_api/ingest/importer.py
export const IMPORT_ACTIONS = {
  IMPORT_ASSETS: 'IMPORT_ASSETS',
  IMPORT_PROJECTS: 'IMPORT_PROJECTS',
  IMPORT_CMLS: 'IMPORT_CMLS',
  IMPORT_MEASUREMENTS: 'IMPORT_MEASUREMENTS',
  IMPORT_SCHEDULE_PLANS: 'IMPORT_SCHEDULE_PLANS',
  IMPORT_SCHEDULE_PLAN_WORK: 'IMPORT_SCHEDULE_PLAN_WORK',
};

// https://github.com/huvrdata/huvr/blob/master/huvr_api/huvr_api/projects/choices.py
export const PROJECT_STATUS = {
  INITIALIZED: 'INITIALIZED',
  ASSIGNED: 'ASSIGNED',
  IN_PROGRESS: 'IN_PROGRESS',
  READY_FOR_REVIEW: 'READY_FOR_REVIEW',
  PUBLISHED: 'PUBLISHED',
};

export const USER_PREFERENCES = [
  {
    key: 'automatically_watch_created_projects',
    label: 'Automatically Watch Projects',
    helpText: 'When you create a project, automatically watch it.',
  },
  {
    key: 'notify_personal_form_submissions',
    label: 'Personal Form Submission Notification',
    helpText: 'Receive a copy of each form you submit for your records.',
  },
  {
    key: 'notify_projects_status_change',
    label: 'Project Status Notification',
    helpText: 'Notifications for all watched and assigned projects.',
  },
];

// ------------------
//
// As Options
//
//

// Enumerations defined on the API
export const defectModeOptions = [
  { value: DEFECT_MODES.LIST, label: 'List' },
  { value: DEFECT_MODES.FREE, label: 'Free' },
  { value: DEFECT_MODES.OFF, label: 'Off' },
];
export const componentModeOptions = [
  { value: COMPONENT_MODES.OFF, label: 'Off' },
  { value: COMPONENT_MODES.LIST, label: 'List' },
  { value: COMPONENT_MODES.FREE, label: 'Free' },
];
export const zoneModeOptions = [
  { value: ZONE_MODES.OFF, label: 'Off' },
  { value: ZONE_MODES.LIST, label: 'List' },
  { value: ZONE_MODES.FREE, label: 'Free' },
];
export const documentStateOptions = [
  { value: DOCUMENT_STATES.DRAFT, label: 'Draft' },
  { value: DOCUMENT_STATES.ACTIVE, label: 'Active' },
  { value: DOCUMENT_STATES.ARCHIVED, label: 'Archived' },
];
export const mediaStateOptions = [
  { value: MEDIA_STATES.CREATED, label: 'Created' },
  { value: MEDIA_STATES.UPLOADED, label: 'Uploaded' },
  { value: MEDIA_STATES.PROCESSING, label: 'Processing' },
  { value: MEDIA_STATES.PROCESSED, label: 'Processed' },
  { value: MEDIA_STATES.INSPECTED, label: 'Inspected' },
];
export const geoModeOptions = [
  { value: GEO_MODES.FIXED, label: 'Fixed Position' },
  { value: GEO_MODES.FIXED_LINEAR, label: 'Fixed Linear' },
  { value: GEO_MODES.FIXED_ROTATING, label: 'Fixed Rotating' },
  { value: GEO_MODES.MOBILE, label: 'Mobile' },
  { value: GEO_MODES.INTERIOR, label: 'Interior' },
  { value: GEO_MODES.UNDERSEA, label: 'Undersea' },
  { value: GEO_MODES.UNKNOWN, label: 'Unknown' },
];
export const cmlModeOptions = [
  { value: CML_MODES.DISABLED, label: 'No CMLs' },
  { value: CML_MODES.PIPE, label: 'Pipe' },
  { value: CML_MODES.TANK, label: 'Tank' },
];
export const cmlTypeOptions = [
  { value: CML_TYPES.OTHER, label: 'Other' },
  { value: CML_TYPES.PIPE, label: 'Pipe' },
  { value: CML_TYPES.TANK, label: 'Tank' },
];
export const defectStateOptions = [
  { value: DEFECT_STATES.PENDING, label: 'Pending Review' },
  { value: DEFECT_STATES.OPEN, label: 'Open' },
  { value: DEFECT_STATES.MONITOR, label: 'Monitor' },
  { value: DEFECT_STATES.REPAIR, label: 'Repair' },
  { value: DEFECT_STATES.REPAIRED, label: 'Repaired' },
  { value: DEFECT_STATES.CLOSED, label: 'Closed' },
];

export const reportModes = [
  { value: REPORT_MODES.PROJECT, label: 'Project' },
  { value: REPORT_MODES.FORM, label: 'Form' },
];

export const titleModes = [
  { value: TITLE_MODES.DEFAULT, label: 'Default' },
  { value: TITLE_MODES.PAGE, label: 'Title Page (Standard)' },
  { value: TITLE_MODES.PAGE_SIMPLE, label: 'Title Page (Simple)' },
  { value: TITLE_MODES.PAGE_SIMPLE_2, label: 'Title Page 2 (Simple)' },
];
export const coverModes = [
  { value: COVER_MODES.OFF, label: 'Off' },
  { value: COVER_MODES.TITLE, label: 'Title' },
  { value: COVER_MODES.CHECKLIST_INLINE, label: 'Checklist Inline' },
];
export const measurementModes = [
  { value: MEASUREMENT_MODES.DEFAULT, label: 'Default' },
  { value: MEASUREMENT_MODES.UT_PIPE_CALC, label: 'UT Pipe Calculation' },
];
export const densityOptions = [
  { value: DENSITY_OPTIONS.DEFAULT, label: 'Default' },
  { value: DENSITY_OPTIONS.COMPACT, label: 'Compact' },
];
export const layoutOptions = [
  { value: LAYOUT_MODES.DEFAULT, label: 'Default' },
  { value: LAYOUT_MODES['2COL'], label: '2 Column' },
  { value: LAYOUT_MODES['NOTESINLINE'], label: 'Notes Inline' },
];
export const eventTypeOptions = [
  { value: NOTIFICATION_EVENTS.CHECKLIST_CREATE, label: 'Form Created' },
  { value: NOTIFICATION_EVENTS.CHECKLIST_EDIT, label: 'Form Updated' },
  { value: NOTIFICATION_EVENTS.CHECKLIST_CHANGE, label: 'Form Changed (created or updated)' },
  { value: NOTIFICATION_EVENTS.PROJECT_STATUS_CHANGE, label: 'Project Status Changes' },
];

export const monitoringModeOptions = [
  { value: MONITORING_MODES.DISABLED, label: 'Disabled' },
  { value: MONITORING_MODES.UT_PIPE_STD, label: 'UT Pipeline (Standard Units)' },
  { value: MONITORING_MODES.UT_PIPE_MT, label: 'UT Pipeline (Metric Units)' },
  { value: MONITORING_MODES.UT_TANK_STD, label: 'UT Tank (Standard Units)' },
  { value: MONITORING_MODES.UT_TANK_MT, label: 'UT Tank (Metric Units)' },
];

export const libraryModeOptions = [
  { value: LIBRARY_MODES.DISABLED, label: 'No reference library should be created' },
  { value: LIBRARY_MODES.ON_CREATE, label: 'Generate a reference library on asset creation' },
  { value: LIBRARY_MODES.OPTIONAL, label: 'A reference library is optional' },
];

export const measurementTypes = [
  { value: MEASUREMENT_TYPES.UT_PIPE, label: 'Thickness (Pipe)' },
  { value: MEASUREMENT_TYPES.UT_TANK, label: 'Thickness (Tank)' },
  { value: MEASUREMENT_TYPES.UT_COAT, label: 'Thickness (Coating)' },
];

export const taskCreateModes = [
  { value: TASK_CREATE_MODE.AS_NEEDED, label: 'As Needed' },
  { value: TASK_CREATE_MODE.FIXED_COUNT, label: 'Fixed Count' },
];

export const workTypeOptions = [
  { value: WORK_TYPE.CHECKLIST, label: 'Form' },
  { value: WORK_TYPE.PROJECT, label: 'Project' },
];

export const documentCategoryOptions = [
  { value: DOCUMENT_CATEGORIES.image, label: 'Image' },
  { value: DOCUMENT_CATEGORIES.video, label: 'Video' },
  { value: DOCUMENT_CATEGORIES.audio, label: 'Audio' },
  { value: DOCUMENT_CATEGORIES.office, label: 'Office' },
  { value: DOCUMENT_CATEGORIES.archive, label: 'Archive' },
  { value: DOCUMENT_CATEGORIES.other, label: 'Other' },
];

export const projectChildrenModes = [
  { value: PROJECT_CHILDREN_MODES.DISABLED, label: 'Disabled' },
  { value: PROJECT_CHILDREN_MODES.DYNAMIC, label: 'Dynamic' },
  { value: PROJECT_CHILDREN_MODES.FIXED, label: 'Fixed' },
];

export const unitOptions = [
  // TODO: ADD NOT_SET
  // { value: UNIT_TYPES.NOT_SET, label: 'Not Set' },
  { value: UNIT_TYPES.INCHES, label: 'in' },
  { value: UNIT_TYPES.MILLIMETERS, label: 'mm' },
];

export const measurementStateOptions = [
  { value: MEASUREMENT_STATES.PENDING, label: 'Pending' },
  { value: MEASUREMENT_STATES.NEEDS_VERIFICATION, label: 'Needs Verification' },
  { value: MEASUREMENT_STATES.ACTIVE, label: 'Active' },
  { value: MEASUREMENT_STATES.IGNORE, label: 'Ignore' },
];

export const pageSourceTypeOptions = [
  { value: PAGE_SOURCE_TYPES.IFRAME, label: 'IFRAME' },
  { value: PAGE_SOURCE_TYPES.LOOKER, label: 'Looker Dashboard' },
  { value: PAGE_SOURCE_TYPES.MARKDOWN, label: 'Markdown' },
  { value: PAGE_SOURCE_TYPES.SYSTEM, label: 'System Defined Page' },
];

export const checklistMediaWidthOptions = [
  { value: CHECKLIST_MEDIA_WIDTHS.FOUR, label: '25% (4 Images Per Row)' },
  { value: CHECKLIST_MEDIA_WIDTHS.THREE, label: '33% (3 Images Per Row)' },
  { value: CHECKLIST_MEDIA_WIDTHS.TWO, label: '50% (2 Images Per Row)' },
  { value: CHECKLIST_MEDIA_WIDTHS.DEFAULT, label: 'Full Width' },
];

export const rolesOptions = [
  { label: 'Assessment Builder', value: ROLES.assessment_builder },
  { label: 'Assessment Viewer', value: ROLES.assessment_viewer },
  { label: 'Asset Manager', value: ROLES.company_asset_manager },
  { label: 'Findings Manager', value: ROLES.company_findings_manager },
  { label: 'Configuration Builder', value: ROLES.configuration_builder },
  { label: 'Process Data Manager', value: ROLES.process_data_manager },
  { label: 'Process Data Viewer', value: ROLES.process_data_viewer },
  { label: 'Project Manager', value: ROLES.company_project_manager },
  { label: 'Scheduler', value: ROLES.scheduler },
  { label: 'Subcontractor', value: ROLES.subcontractor },
  { label: 'Technician', value: ROLES.technician },
  { label: 'Workspace User Admin', value: ROLES.user_admin },
  { label: 'Viewer', value: ROLES.viewer },
  { label: 'Workspace Admin', value: ROLES.workspace_admin },
  { label: 'Workspace Asset Manager', value: ROLES.asset_manager },
  { label: 'Workspace Findings Manager', value: ROLES.findings_manager },
  { label: 'Workspace Project Manager', value: ROLES.project_manager },
];

export const workStatusOptions = [
  { value: WORK_STATUS.RECOMMENDED, label: 'Recommended' },
  { value: WORK_STATUS.PLANNED, label: 'Planned' },
  { value: WORK_STATUS.COMPLETE, label: 'Complete' },
  { value: WORK_STATUS.DEFERRED, label: 'Deferred' },
  { value: WORK_STATUS.CANCELLED, label: 'Cancelled' },
];

export const assessmentStatusOptions = [
  { value: ASSESSMENT_STATUS.INITIALIZED, label: 'Initialized' },
  { value: ASSESSMENT_STATUS.IN_PROGRESS, label: 'In Progress' },
  { value: ASSESSMENT_STATUS.COMPLETE, label: 'Complete' },
];

export const riskScoreOptions = [
  { value: RISK_SCORE.LOW, label: 'Low', color: RISK_COLOR.GREEN },
  { value: RISK_SCORE.MEDIUM, label: 'Medium', color: RISK_COLOR.YELLOW },
  { value: RISK_SCORE.MEDIUM_HIGH, label: 'Medium-High', color: RISK_COLOR.ORANGE },
  { value: RISK_SCORE.HIGH, label: 'High', color: RISK_COLOR.RED },
];

export const displayModeOptions = [
  { value: DISPLAY_MODES.DETAIL, label: 'Detail' },
  { value: DISPLAY_MODES.SUMMARY, label: 'Summary' },
  { value: DISPLAY_MODES.DETAIL_SUMMARY, label: 'Detail Summary' },
];

export const importActionOptions = [
  { value: IMPORT_ACTIONS.IMPORT_ASSETS, label: 'Import Assets' },
  { value: IMPORT_ACTIONS.IMPORT_PROJECTS, label: 'Import Projects' },
  { value: IMPORT_ACTIONS.IMPORT_CMLS, label: 'Import CMLs' },
  { value: IMPORT_ACTIONS.IMPORT_MEASUREMENTS, label: 'Import Measurements' },
  { value: IMPORT_ACTIONS.IMPORT_SCHEDULE_PLANS, label: 'Import Plans' },
  { value: IMPORT_ACTIONS.IMPORT_SCHEDULE_PLAN_WORK, label: 'Import Plan Works' },
];

export const projectStatusOptions = [
  { value: PROJECT_STATUS.INITIALIZED, label: 'Initialized' },
  { value: PROJECT_STATUS.ASSIGNED, label: 'Assigned' },
  { value: PROJECT_STATUS.IN_PROGRESS, label: 'In Progress' },
  { value: PROJECT_STATUS.READY_FOR_REVIEW, label: 'Ready for Review' },
  { value: PROJECT_STATUS.PUBLISHED, label: 'Published' },
];

export const pofAndCofOptions = ['1', '2', '3', '4', '5']; // use in table only

export const pixelToChar = 0.125;

export const clearFlag = '__CLEAR__';
