export const ASSESSMENT_TYPES_REQUEST = 'ASSESSMENT_TYPES_REQUEST';
export const ASSESSMENT_TYPES_SUCCESS = 'ASSESSMENT_TYPES_SUCCESS';
export const ASSESSMENT_TYPES_FAILURE = 'ASSESSMENT_TYPES_FAILURE';
export const ASSESSMENT_TYPES_CREATE_REQUEST = 'ASSESSMENT_TYPES_CREATE_REQUEST';
export const ASSESSMENT_TYPES_CREATE_SUCCESS = 'ASSESSMENT_TYPES_CREATE_SUCCESS';
export const ASSESSMENT_TYPES_CREATE_FAILURE = 'ASSESSMENT_TYPES_CREATE_FAILURE';
export const ASSESSMENT_TYPES_UPDATE_REQUEST = 'ASSESSMENT_TYPES_UPDATE_REQUEST';
export const ASSESSMENT_TYPES_UPDATE_SUCCESS = 'ASSESSMENT_TYPES_UPDATE_SUCCESS';
export const ASSESSMENT_TYPES_UPDATE_FAILURE = 'ASSESSMENT_TYPES_UPDATE_FAILURE';
export const ASSESSMENT_TYPES_DELETE_REQUEST = 'ASSESSMENT_TYPES_DELETE_REQUEST';
export const ASSESSMENT_TYPES_DELETE_SUCCESS = 'ASSESSMENT_TYPES_DELETE_SUCCESS';
export const ASSESSMENT_TYPES_DELETE_FAILURE = 'ASSESSMENT_TYPES_DELETE_FAILURE';
export const ASSESSMENT_TYPES_ALL_REQUEST = 'ASSESSMENT_TYPES_ALL_REQUEST';
export const ASSESSMENT_TYPES_ALL_SUCCESS = 'ASSESSMENT_TYPES_ALL_SUCCESS';
export const ASSESSMENT_TYPES_ALL_FAILURE = 'ASSESSMENT_TYPES_ALL_FAILURE';

export const getAssessmentTypes = id => {
  return {
    type: ASSESSMENT_TYPES_REQUEST,
    id,
  };
};

export const createAssessmentTypes = body => {
  return {
    type: ASSESSMENT_TYPES_CREATE_REQUEST,
    body,
  };
};

export const updateAssessmentTypes = (id, body) => {
  return {
    type: ASSESSMENT_TYPES_UPDATE_REQUEST,
    id,
    body,
  };
};

export const deleteAssessmentTypes = id => {
  return {
    type: ASSESSMENT_TYPES_DELETE_REQUEST,
    id,
  };
};

export const getAllAssessmentTypes = query => {
  return {
    type: ASSESSMENT_TYPES_ALL_REQUEST,
    query,
  };
};
