import React, { useState } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';

const FirebaseResetPassword = props => {
  const { toggleCard } = props;
  const [passwordSubmitted, setPasswordSubmitted] = useState(false);
  const [inputEmail, setInputEmail] = useState('');
  const [userMessage, setUserMessage] = useState('');
  const handleToggleCard = () => {
    toggleCard();
    setInputEmail('');
  };

  const sendPasswordReset = email => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        setUserMessage('Password reset email sent!');
      })
      .catch(error => {
        console.error(error);
        setUserMessage('Password reset failed. Please contact us at HUVR Support for assistance');
      });
    setPasswordSubmitted(true);
  };

  return !passwordSubmitted ? (
    <div className="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-password-recovery">
      <div className="firebaseui-card-header">
        <h1 className="firebaseui-title">Recover password</h1>
      </div>
      <div className="firebaseui-card-content">
        <p className="firebaseui-text">Get instructions sent to this email that explain how to reset your password</p>
        <div
          className="firebaseui-textfield mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty is-upgraded"
          data-upgraded=",MaterialTextfield">
          <label className="mdl-textfield__label firebaseui-label">Email</label>
          <input
            type="email"
            name="email"
            id="ui-sign-in-email-input"
            className="mdl-textfield__input firebaseui-input firebaseui-id-email"
            onBlur={e => setInputEmail(e.target.value)}
          />
        </div>
        <div className="firebaseui-error-wrapper">
          <p className="firebaseui-error firebaseui-text-input-error firebaseui-hidden firebaseui-id-email-error"></p>
        </div>
      </div>
      <div className="firebaseui-card-actions">
        <div className="firebaseui-form-actions">
          <button
            className="firebaseui-id-secondary-link firebaseui-button mdl-button mdl-js-button mdl-button--primary"
            data-upgraded=",MaterialButton"
            onClick={handleToggleCard}>
            Cancel
          </button>
          <button
            type="submit"
            className="firebaseui-id-submit firebaseui-button mdl-button mdl-js-button mdl-button--raised mdl-button--colored"
            data-upgraded=",MaterialButton"
            onClick={() => sendPasswordReset(inputEmail)}>
            Send
          </button>
        </div>
      </div>
      <div className="firebaseui-card-footer"></div>
    </div>
  ) : (
    <div className="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-password-recovery">
      <div className="firebaseui-card-header">
        <h1 className="firebaseui-title">Recover password</h1>
      </div>
      <div className="firebaseui-card-content">
        <p className="firebaseui-text">{userMessage}</p>
        <div className="firebaseui-error-wrapper">
          <p className="firebaseui-error firebaseui-text-input-error firebaseui-hidden firebaseui-id-email-error"></p>
        </div>
      </div>
      <div className="firebaseui-card-footer"></div>
    </div>
  );
};

FirebaseResetPassword.propTypes = {
  toggleCard: PropTypes.func.isRequired,
};

export default FirebaseResetPassword;
