import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import SchedulePlanTable from './SchedulePlanTable';
import { getAllSchedulePlan } from '../../store/features/schedulePlanActions';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(7, '3vw'),
    padding: theme.spacing(2),
  },
}));

const SchedulePlanTablePage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(getAllSchedulePlan());
  }, []); // eslint-disable-line

  return (
    <div className={classes.root}>
      <SchedulePlanTable title="Schedule Plans" />
    </div>
  );
};

export default SchedulePlanTablePage;
