
import {
  requestFactory,
  responseHandlerForm,
} from '../base';

const get = requestFactory('get');
const post = requestFactory('post', { handler: responseHandlerForm });
const patch = requestFactory('patch', { handler: responseHandlerForm });
const del = requestFactory('del');

export const fetchAssetTypesList = async query => get('/api/asset-types/', query);
export const fetchAssetTypes = async id => get(`/api/asset-types/${id}/`);
export const createAssetTypes = async body => post('/api/asset-types/', body);
export const updateAssetTypes = async (id, body) => patch(`/api/asset-types/${id}/`, body);
export const deleteAssetTypes = async id => del(`/api/asset-types/${id}/`);

