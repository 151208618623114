import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ButtonGroup, Button, Grid, IconButton, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import EditIcon from '@mui/icons-material/Edit';
import GetAppIcon from '@mui/icons-material/GetApp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';

import { exportChecklistTemplates } from '../../store/features/checklistTemplatesActions';

import { historyPushWithBack } from '../../utilities/route';
import { usePermissions, useFeatureFlags } from '../../hooks/settingsHooks';

const useStyles = makeStyles(theme => ({
  header: {
    padding: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
    // justify: 'left',
  },
  button: {
    padding: theme.spacing(0, 1),
  },
  buttonGroup: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const ChecklistTemplateToolbar = ({ id, data }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { hasChecklistEdit } = usePermissions();
  const { hasChecklistAny } = useFeatureFlags();

  const classes = useStyles();

  const showPreview = data.state === 'ACTIVE' || data.state === 'DRAFT';
  const showCreate = hasChecklistAny && showPreview;

  const handleBack = () => {
    if (location.state && location.state.back) {
      history.push(location.state.back);
    } else {
      history.push('/checklist-templates');
    }
  };

  const handleEdit = () => {
    historyPushWithBack(`/checklist-templates/${id}/edit`, history, location);
  };

  const handlePreview = () => {
    historyPushWithBack(`/checklist-preview/${id}`, history, location);
  };

  const handleFormNew = () => {
    historyPushWithBack(`/forms/new/${id}`, history, location);
  };

  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.header}>
      <Grid item>
        <IconButton onClick={handleBack} size="large">
          <ArrowBackIosIcon className={classes.button} />
        </IconButton>
      </Grid>
      <Grid item className={classes.title}>
        <Typography variant="h5">{`${data.name} ${data.version}`}</Typography>
      </Grid>
      <Grid item>
        <ButtonGroup className={classes.buttonGroup}>
          {hasChecklistEdit && (
            <Button startIcon={<EditIcon />} onClick={handleEdit}>
              Edit
            </Button>
          )}
          <Button startIcon={<GetAppIcon />} onClick={() => dispatch(exportChecklistTemplates(id, data.name))}>
            Download
          </Button>
          <Button startIcon={<VisibilityIcon />} onClick={handlePreview} disabled={!showPreview}>
            Preview
          </Button>
          {showCreate && (
            <Button startIcon={<AddIcon />} onClick={handleFormNew}>
              New
            </Button>
          )}
        </ButtonGroup>
      </Grid>
    </Grid>
  );
};

ChecklistTemplateToolbar.propTypes = {
  id: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
};

export default ChecklistTemplateToolbar;
