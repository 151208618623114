import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { jsonKeyToLabel, apiDateToString, dateToApiDateTime } from '../../utilities/strings';
import { mediaStateOptions } from '../../api/features/constants';
import { updateInspectionMedia } from '../../store/features/inspectionMediaActions';
import CommonForm from '../shared/form/Common2';
import GeoPoint from '../shared/form/GeoPoint';
import { fieldOrder, removeField, hideField } from '../inspectionMedia/inspectionMediaShared';
import { useFeatureFlags } from '../../hooks/settingsHooks';

const getLocationLayer = (media, layer) => {
  if (layer) {
    return layer;
  }
  if (media.layer) {
    return media.layer;
  }
};

const InspectorMediaForm = props => {
  const { dirty, isDirty, update, cancel, media: data, heightOffset } = props;
  const { loading, error, formError } = useSelector(state => state.inspectionMedia.each);

  const [submittedValues, setSubmittedValues] = useState({});
  const dispatch = useDispatch();
  const { hasAccessDisplay } = useFeatureFlags();

  const formSettings = {
    fieldOrder,
    removeField: removeField(update ? 'UPDATE' : 'CREATE'),
    hideField: (values, item) => {
      return hideField(values, item, hasAccessDisplay);
    },
  };

  const locationLayerProfile = getLocationLayer(data, props.locationLayerProfile);

  const getLocationZone = () => {
    const field = {
      type: 'text',
      fieldProps: {
        label: 'Location Zone',
        name: 'location_zone',
        helperText: locationLayerProfile?.zone_help || '',
        labelwidth: 90,
      },
    };
    if (!locationLayerProfile) {
      field.disabled = true;
      return field;
    }
    if (locationLayerProfile.zone_mode === 'LIST') {
      field.type = 'autocomplete';
      field.fieldProps['options'] = locationLayerProfile.zone_options;
      return field;
    }

    if (locationLayerProfile.zone_mode === 'FREE') {
      return field;
    }
  };
  const getLocationCode = () => {
    const field = {
      type: 'text',
      fieldProps: {
        label: 'Location Code',
        name: 'location_code',
        helperText: locationLayerProfile?.code_help || '',
        labelwidth: 80,
      },
    };
    if (!locationLayerProfile) {
      field.disabled = true;
      return field;
    }
    if (locationLayerProfile.code_mode === 'LIST') {
      field.type = 'autocomplete';
      field.fieldProps['options'] = locationLayerProfile.code_options;
      return field;
    }
    if (locationLayerProfile.code_mode === 'FREE') {
      return field;
    }
  };

  const getAccess = () => {
    const field = {
      type: 'text',
      fieldProps: {
        label: 'Access',
        name: 'access',
        helperText: locationLayerProfile?.access_help || '',
        labelwidth: 80,
      },
    };
    if (!locationLayerProfile) {
      field.disabled = true;
      return field;
    }
    if (locationLayerProfile.access_mode === 'LIST') {
      field.type = 'autocomplete';
      field.fieldProps['options'] = locationLayerProfile.access_options;
      return field;
    }
    if (locationLayerProfile.access_mode === 'FREE') {
      return field;
    }
  };

  const getComponent = () => {
    const field = {
      type: 'text',
      fieldProps: {
        label: 'Component',
        name: 'component',
        helperText: locationLayerProfile?.component_help || '',
        labelwidth: 80,
      },
    };
    if (!locationLayerProfile) {
      field.disabled = true;
      return field;
    }
    if (locationLayerProfile.component_mode === 'LIST') {
      field.type = 'autocomplete';
      field.fieldProps['options'] = locationLayerProfile.component_options;
      return field;
    }
    if (locationLayerProfile.component_mode === 'FREE') {
      return field;
    }
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      switch (key) {
        /* ---------- Required Fields ---------- */
        case 'component':
          fieldSettings[key] = getComponent();
          break;
        case 'location_zone':
          fieldSettings[key] = getLocationZone();
          break;
        case 'location_code':
          fieldSettings[key] = getLocationCode();
          break;
        case 'access':
          fieldSettings[key] = getAccess();
          break;
        case 'state':
          fieldSettings[key] = {
            type: 'autocomplete',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              options: mediaStateOptions,
              labelwidth: 40,
            },
          };
          break;
        /* ---------- Regular Fields ---------- */
        case 'geo_point':
        case 'local_coordinates':
          fieldSettings[key] = {
            type: 'geo',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          break;
        case 'captured_on':
          fieldSettings[key] = {
            type: 'date',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          break;
        /* ----------  Disabled Fields ---------- */
        case 'created_on':
        case 'updated_on':
          fieldSettings[key] = {
            type: 'date',
            fieldProps: {
              disabled: true,
              label: jsonKeyToLabel(key),
              name: key,
              value: apiDateToString(data[key], 'short-timestamp'),
            },
          };
          break;
        /* ----------  Default ---------- */
        default:
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
      }
    });
  }

  let initialValues = {};
  if (update) {
    initialValues = {
      ...data,
      geo_point: data.geo_point ? new GeoPoint(data.geo_point).toString() : undefined,
      local_coordinates: data.local_coordinates ? new GeoPoint(data.local_coordinates).toString() : undefined,
      captured_on: data.captured_on ? apiDateToString(data.captured_on, 'date') : undefined,
    };
  }

  const onSubmit = changedValues => {
    if (props.onSubmit) {
      props.onSubmit();
    } // InspectorSidebar submit settings

    const updatedValues = { ...changedValues };
    setSubmittedValues(changedValues);
    if (!data.layer && locationLayerProfile) {
      updatedValues['layer_id'] = locationLayerProfile.id;
    }
    if ('geo_point' in updatedValues) {
      updatedValues['geo_point'] = new GeoPoint(updatedValues['geo_point']).toGeoJSON();
    }
    if ('captured_on' in updatedValues) {
      updatedValues['captured_on'] = dateToApiDateTime(updatedValues['captured_on']);
    }
    if ('local_coordinates' in updatedValues) {
      updatedValues['local_coordinates'] = new GeoPoint(updatedValues['local_coordinates']).toGeoJSON();
    }

    isDirty(false);

    dispatch(
      updateInspectionMedia(
        updatedValues.id,
        updatedValues,
        '' // no reroute
      )
    );
  };

  /**
   * Decorators are used for setting the values of other fields based off of a field.
   * Decorator format can be found here: src/components/shared/form/common.js
   */
  const decorators = [];

  /**
   *  Validations that are per field.
   *  Errors associated with the form are passed back through formError in Redux.
   */
  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        if (values[key] === submittedValues[key]) {
          errors[key] = value;
        } else {
          errors[key] = undefined;
        }
      }
    }
    return errors;
  };

  return (
    <CommonForm
      embed
      keepDirtyOnReinitialize={false} // allow fresh values on navigation thru media forms
      dirty={dirty}
      isDirty={isDirty}
      update={update}
      initialValues={initialValues}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      onSubmit={onSubmit}
      decorators={decorators}
      validate={validate}
      loading={loading}
      error={error}
      toolbar={props.toolbar}
      customToolbarProps={{ cancel, dirty }}
      heightOffset={heightOffset}
    />
  );
};

InspectorMediaForm.defaultProps = {
  dirty: false,
  isDirty: undefined,
  media: undefined,
  toolbar: undefined,
  update: true,
  locationLayerProfile: undefined,
  cancel: undefined,
  onSubmit: undefined,
  heightOffset: '',
};

InspectorMediaForm.propTypes = {
  media: PropTypes.object,
  update: PropTypes.bool,
  toolbar: PropTypes.func,
  locationLayerProfile: PropTypes.object,
  dirty: PropTypes.bool,
  isDirty: PropTypes.func,
  cancel: PropTypes.func,
  onSubmit: PropTypes.func,
  heightOffset: PropTypes.string,
};

export default InspectorMediaForm;
