// --------------------------------------------------------------------------------
//
// Generated File
//
// !! Do not change this file by hand.                                    !!
// !! This file is automatically generated from the sagas in this folder. !!
//
// --------------------------------------------------------------------------------
import assessmentTypesSaga from './assessmentTypesSaga';
import assessmentsSaga from './assessmentsSaga';
import assetTypesSaga from './assetTypesSaga';
import assetsSaga from './assetsSaga';
import bulkImportsSaga from './bulkImportsSaga';
import checklistTasksSaga from './checklistTasksSaga';
import checklistTemplatesSaga from './checklistTemplatesSaga';
import checklistsSaga from './checklistsSaga';
import cmlsSaga from './cmlsSaga';
import companiesSaga from './companiesSaga';
import crewsSaga from './crewsSaga';
import defectsSaga from './defectsSaga';
import inspectionMediaSaga from './inspectionMediaSaga';
import librariesSaga from './librariesSaga';
import libraryMediaSaga from './libraryMediaSaga';
import locationLayerProfilesSaga from './locationLayerProfilesSaga';
import measurementsSaga from './measurementsSaga';
import notificationRuleSubscribersSaga from './notificationRuleSubscribersSaga';
import notificationRulesSaga from './notificationRulesSaga';
import projectTypesSaga from './projectTypesSaga';
import projectsSaga from './projectsSaga';
import recommendedWorkPlanSaga from './recommendedWorkPlanSaga';
import reservationsSaga from './reservationsSaga';
import scheduleEventsSaga from './scheduleEventsSaga';
import schedulePlanSaga from './schedulePlanSaga';
import scheduleTasksSaga from './scheduleTasksSaga';
import scheduleWorkSaga from './scheduleWorkSaga';
import usersSaga from './usersSaga';

export default [
  assessmentTypesSaga(),
  assessmentsSaga(),
  assetTypesSaga(),
  assetsSaga(),
  bulkImportsSaga(),
  checklistTasksSaga(),
  checklistTemplatesSaga(),
  checklistsSaga(),
  cmlsSaga(),
  companiesSaga(),
  crewsSaga(),
  defectsSaga(),
  inspectionMediaSaga(),
  librariesSaga(),
  libraryMediaSaga(),
  locationLayerProfilesSaga(),
  measurementsSaga(),
  notificationRuleSubscribersSaga(),
  notificationRulesSaga(),
  projectTypesSaga(),
  projectsSaga(),
  recommendedWorkPlanSaga(),
  reservationsSaga(),
  scheduleEventsSaga(),
  schedulePlanSaga(),
  scheduleTasksSaga(),
  scheduleWorkSaga(),
  usersSaga(),
];
