import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory, useLocation } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useDispatch } from 'react-redux';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import { closeDialog } from '../../../store/dialogActions';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(7, 'auto'),
    padding: theme.spacing(2),
    maxWidth: '600px',
  },
  body: {
    padding: theme.spacing(2, 4),
    textAlign: 'left',
  },
  buttonGroup: {
    padding: theme.spacing(2),
  },
}));

const CustomFormToolbar = props => {
  const { component: Component, backLink, submitDisabled, title, cancel, saveButtonProps, backButtonProps } = props;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleBackClick = function () {
    if (cancel) {
      dispatch(closeDialog());
    } else {
      if (backLink) {
        history.push(backLink);
      } else if (location.state && location.state.back) {
        // ideally, everything that routes to this path, should have state.back in it's location.
        history.push(location.state.back);
      } else {
        // this is a last resort for if we don't know where the user came from
        // remove '/new' or '/<id>' from the end of the url and push that path.
        const next = location.pathname.match(/(.*?)\/\w+$/)[1];
        history.push(next);
      }
    }
  };

  // do not alter variables passed to function..
  const _backButtonProps = {
    ...backButtonProps,
  };
  if (!_backButtonProps.onClick) {
    _backButtonProps.onClick = handleBackClick;
  }

  if (Component) {
    return <Component />;
  } else {
    return (
      <React.Fragment>
        <Grid container justifyContent="space-between" className={classes.buttonGroup}>
          <Grid item>
            {!_backButtonProps.hide && (
              <SecondaryButton {..._backButtonProps} variant="text" icon Icon={ChevronLeftIcon} />
            )}
          </Grid>
          <Grid item>
            <h3>{title}</h3>
          </Grid>
          <Grid item>
            <PrimaryButton {...saveButtonProps} type="submit" disabled={submitDisabled} />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
};

CustomFormToolbar.defaultProps = {
  submitDisabled: false,
  component: undefined,
  backLink: '',
  title: undefined,
  cancel: false,
  saveButtonProps: { label: 'Save' },
  backButtonProps: { label: 'Cancel' },
};

CustomFormToolbar.propTypes = {
  submitDisabled: PropTypes.bool,
  component: PropTypes.func,
  backLink: PropTypes.string,
  title: PropTypes.string,
  cancel: PropTypes.bool,
  backButtonProps: PropTypes.object,
  saveButtonProps: PropTypes.object,
};

export default CustomFormToolbar;
