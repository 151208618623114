import { all, put, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  SCHEDULE_TASKS_REQUEST,
  SCHEDULE_TASKS_SUCCESS,
  SCHEDULE_TASKS_FAILURE,
  SCHEDULE_TASKS_CREATE_REQUEST,
  SCHEDULE_TASKS_CREATE_SUCCESS,
  SCHEDULE_TASKS_CREATE_FAILURE,
  SCHEDULE_TASKS_UPDATE_REQUEST,
  SCHEDULE_TASKS_UPDATE_SUCCESS,
  SCHEDULE_TASKS_UPDATE_FAILURE,
  SCHEDULE_TASKS_DELETE_REQUEST,
  SCHEDULE_TASKS_DELETE_SUCCESS,
  SCHEDULE_TASKS_DELETE_FAILURE,
  SCHEDULE_TASKS_ALL_REQUEST,
  SCHEDULE_TASKS_ALL_SUCCESS,
  SCHEDULE_TASKS_ALL_FAILURE,
} from './scheduleTasksActions';

import {
  fetchScheduleTasks,
  createScheduleTasks,
  updateScheduleTasks,
  deleteScheduleTasks,
  fetchScheduleTasksList,
} from '../../api/features/scheduleTasks';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';
import { buildRoute } from '../../utilities/strings';
import { fetchListAll } from '../../utilities/sagaHelpers';
import { DIALOG_CLOSE } from '../../store/dialogActions';

export function* scheduleTasksFetch(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield fetchScheduleTasks(id);
    if (data) {
      yield put({ type: SCHEDULE_TASKS_SUCCESS, data });
    } else if (error) {
      yield put({ type: SCHEDULE_TASKS_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, SCHEDULE_TASKS_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, SCHEDULE_TASKS_FAILURE);
  }
}

export function* watchScheduleTasksFetch() {
  yield takeLatest(SCHEDULE_TASKS_REQUEST, scheduleTasksFetch);
}

export function* scheduleTasksCreate(action) {
  const { body } = action;
  try {
    const { data, error, formError, response } = yield createScheduleTasks(body);
    if (data) {
      yield put({ type: SCHEDULE_TASKS_CREATE_SUCCESS, data });
      yield put(push(buildRoute('/schedule/tasks')));
    } else if (error) {
      yield put({ type: SCHEDULE_TASKS_CREATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: SCHEDULE_TASKS_CREATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, SCHEDULE_TASKS_CREATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, SCHEDULE_TASKS_CREATE_FAILURE);
  }
}

export function* watchScheduleTasksCreate() {
  yield takeLatest(SCHEDULE_TASKS_CREATE_REQUEST, scheduleTasksCreate);
}

export function* scheduleTasksUpdate(action) {
  const { id, body } = action;
  try {
    const { data, error, formError, response } = yield updateScheduleTasks(id, body);
    if (data) {
      yield put({ type: SCHEDULE_TASKS_UPDATE_SUCCESS, data });
      yield put(push(buildRoute('/schedule/tasks')));
    } else if (error) {
      yield put({ type: SCHEDULE_TASKS_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: SCHEDULE_TASKS_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, SCHEDULE_TASKS_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, SCHEDULE_TASKS_UPDATE_FAILURE);
  }
}

export function* watchScheduleTasksUpdate() {
  yield takeLatest(SCHEDULE_TASKS_UPDATE_REQUEST, scheduleTasksUpdate);
}

export function* scheduleTasksDelete(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield deleteScheduleTasks(id);
    if (data) {
      yield put({ type: SCHEDULE_TASKS_DELETE_SUCCESS });
      const query = yield select(state => state.scheduleTasks.all.query) || '';
      yield put({ type: SCHEDULE_TASKS_ALL_REQUEST, query });
      yield put({ type: DIALOG_CLOSE });
    } else if (error) {
      yield put({ type: SCHEDULE_TASKS_DELETE_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, SCHEDULE_TASKS_DELETE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, SCHEDULE_TASKS_DELETE_FAILURE);
  }
}

export function* watchScheduleTasksDelete() {
  yield takeLatest(SCHEDULE_TASKS_DELETE_REQUEST, scheduleTasksDelete);
}

export function* scheduleTasksFetchAll(action) {
  try {
    const { data, error, response } = yield fetchListAll(fetchScheduleTasksList, action.query);
    if (data) {
      yield put({ type: SCHEDULE_TASKS_ALL_SUCCESS, data });
    } else if (error) {
      yield put({ type: SCHEDULE_TASKS_ALL_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, SCHEDULE_TASKS_ALL_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, SCHEDULE_TASKS_ALL_FAILURE);
  }
}

export function* watchScheduleTasksFetchAll() {
  yield takeLatest(SCHEDULE_TASKS_ALL_REQUEST, scheduleTasksFetchAll);
}

export default function* scheduleTasksSaga() {
  yield all([
    watchScheduleTasksFetch(),
    watchScheduleTasksCreate(),
    watchScheduleTasksUpdate(),
    watchScheduleTasksFetchAll(),
    watchScheduleTasksDelete(),
  ]);
}
