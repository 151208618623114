import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { jsonKeyToLabel, apiDateToString } from '../../utilities/strings';
import { getLibraryMedia, createLibraryMedia, updateLibraryMedia } from '../../store/features/libraryMediaActions';
import CommonForm from '../shared/form/Common';
import { fieldOrder, removeField, hideField } from './libraryMediaShared';

const LibraryMediaForm = props => {
  const { update } = props;
  const params = useParams();
  const { data, loading, error, formError } = useSelector(state => state.libraryMedia.each);
  const [submittedValues, setSubmittedValues] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (update && params.id) {
      dispatch(getLibraryMedia(params.id));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const formSettings = {
    fieldOrder,
    removeField: removeField(update ? 'UPDATE' : 'CREATE'),
    hideField,
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      switch (key) {
        /* ---------- Required Fields ---------- */
        /* ---------- Regular Fields ---------- */
        case 'name':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: 'Name',
              name: 'name',
              value: '',
            },
          };
          break;
        case 'description':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              multiline: true,
              rows: 3,
              maxRows: 10,
            },
          };
          break;
        case 'file':
          fieldSettings[key] = {
            type: 'file',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: 'File',
              helperText: data[key],
            },
          };
          return;
        /* ----------  Disabled Fields ---------- */
        case 'created_on':
        case 'updated_on':
          fieldSettings[key] = {
            type: 'display',
            fieldProps: {
              disabled: true,
              label: jsonKeyToLabel(key),
              name: key,
              value: apiDateToString(data[key], 'date'),
            },
          };
          break;
        /* ----------  Default ---------- */
        default:
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
      }
    });
  }

  const title = update ? 'Edit Media' : 'New Media';

  let initialValues = {};
  if (update) {
    initialValues = { ...data };
  }

  const onSubmit = changedValues => {
    setSubmittedValues(changedValues);
    if (update) {
      dispatch(updateLibraryMedia(changedValues.id, changedValues));
    } else {
      const formData = new FormData();
      const file = changedValues.File[0];
      formData.append('file', file);
      formData.append('description', changedValues.description || '');
      if (changedValues.name) {
        formData.append('name', changedValues.name);
      } else {
        formData.append('name', file.name);
      }
      dispatch(createLibraryMedia(formData));
    }
  };

  /**
   * Decorators are used for setting the values of other fields based off of a field.
   * Decorator format can be found here: src/components/shared/form/common.js
   */
  const decorators = [];

  /**
   *  Validations that are per field.
   *  Errors associated with the form are passed back through formError in Redux.
   */
  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        if (values[key] === submittedValues[key]) {
          errors[key] = value;
        } else {
          errors[key] = undefined;
        }
      }
    }
    return errors;
  };
  return (
    <CommonForm
      update={update}
      title={title}
      initialValues={initialValues}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      onSubmit={onSubmit}
      decorators={decorators}
      validate={validate}
      loading={loading}
      error={error}
    />
  );
};

LibraryMediaForm.defaultProps = {
  update: false,
};

LibraryMediaForm.propTypes = {
  update: PropTypes.bool,
};

export default LibraryMediaForm;
