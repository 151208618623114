import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fieldOrder, removeField, hideField } from './defectProfilesShared';
import { defectModeOptions, documentStateOptions } from '../../api/features/constants';
import { apiDateToString, jsonKeyToLabel, displayOption } from '../../utilities/strings';
import CommonFormView from '../shared/form/CommonView';
import { defectProfilesReadEndpoint } from '../../store/apiV2/defectProfiles';

const DefectProfilesView = () => {
  const id = useParams().id;

  const { data, loading, error, dispatchRequest: fetchDefectProfile } = defectProfilesReadEndpoint.useEndpoint();

  useEffect(() => {
    fetchDefectProfile(id);
  }, [fetchDefectProfile, id]);

  const formSettings = {
    fieldOrder,
    removeField: removeField('VIEW'),
    hideField,
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      // cast to string because booleans do not show up in the field
      let displayLabel = jsonKeyToLabel(key); // eslint-disable-line prefer-const
      let displayValue = '';
      if (data[key] != null) {
        switch (key) {
          // add cases for each key that needs to be customized.
          case 'type_mode':
          case 'severity_mode':
          case 'sub_type_mode':
          case 'labels_mode':
            displayValue = displayOption(data[key], defectModeOptions);
            break;
          case 'state':
            displayValue = displayOption(data[key], documentStateOptions);
            break;
          case 'type_options':
            fieldSettings[key] = {
              type: 'table',
              fieldProps: {
                label: displayLabel,
                colOrder: ['value', 'label'],
                data: data.type_options
                  ? data.type_options.map(row => ({
                      value: row.value,
                      label: row.label,
                    }))
                  : [],
              },
            };
            return;
          case 'sub_type_options':
            fieldSettings[key] = {
              type: 'table',
              fieldProps: {
                label: displayLabel,
                colOrder: ['value', 'label'],
                data: data.sub_type_options
                  ? data.sub_type_options.map(row => ({
                      value: row.value,
                      label: row.label,
                    }))
                  : [],
              },
            };
            return;
          case 'severity_options':
            fieldSettings[key] = {
              type: 'table',
              fieldProps: {
                label: displayLabel,
                colOrder: ['value', 'label'],
                data: data.severity_options
                  ? data.severity_options.map(row => ({
                      value: row.value,
                      label: row.label,
                    }))
                  : [],
              },
            };
            return;
          case 'label_options':
            fieldSettings[key] = {
              type: 'table',
              fieldProps: {
                label: displayLabel,
                colOrder: ['value', 'label'],
                data: data.label_options
                  ? data.label_options.map(row => ({
                      value: row.value,
                      label: row.label,
                    }))
                  : [],
              },
            };
            return;
          case 'updated_on':
          case 'created_on':
            displayValue = apiDateToString(data[key], 'date');
            break;
          default:
            displayValue = data[key].toString();
        }
      }
      fieldSettings[key] = {
        type: 'text',
        fieldProps: {
          label: displayLabel,
          value: displayValue,
        },
      };
    });
  }

  return (
    <CommonFormView
      title={'Finding Profile Details'}
      values={data}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      editLink={`/finding-profiles/${id}/edit`}
      loading={loading}
      error={error}
    />
  );
};

export default DefectProfilesView;
