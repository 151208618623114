// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import { combineReducers } from 'redux';
import {
  ASSETS_REQUEST,
  ASSETS_SUCCESS,
  ASSETS_FAILURE,
  ASSETS_CREATE_REQUEST,
  ASSETS_CREATE_SUCCESS,
  ASSETS_CREATE_FAILURE,
  ASSETS_UPDATE_REQUEST,
  ASSETS_UPDATE_SUCCESS,
  ASSETS_UPDATE_FAILURE,
  ASSETS_DELETE_REQUEST,
  ASSETS_DELETE_SUCCESS,
  ASSETS_DELETE_FAILURE,
  ASSETS_ALL_REQUEST,
  ASSETS_ALL_SUCCESS,
  ASSETS_ALL_FAILURE,
  ASSETS_BATCH_SUCCESS,
  ASSETS_BATCH_FAILURE,
  ASSETS_BATCH_ALL_SUCCESS,
  ASSETS_BATCH_ALL_REQUEST,
  ASSETS_ABORT_BATCH_ALL,
} from './assetsActions';

export const initialStateAssets = {
  loading: false,
  error: '',
  formError: {},
  id: 0,
  body: {},
  data: {},
};

export const initialStateAllAssets = {
  loading: false,
  error: '',
  query: {},
  dataAll: { count: 0, next: null, previous: null, results: [] },
};

export const initialStateAssetConditionMapAssets = {
  loading: false,
  error: '',
  query: {},
  dataAll: { count: 0, next: null, previous: null, results: [] },
};

export const assetsReducer = (state = initialStateAssets, action) => {
  switch (action.type) {
    case ASSETS_REQUEST:
      return {
        ...initialStateAssets,
        loading: true,
        id: action.id,
      };
    case ASSETS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case ASSETS_FAILURE:
      return {
        ...initialStateAssets,
        error: action.error,
      };
    case ASSETS_CREATE_REQUEST:
      return {
        ...initialStateAssets,
        loading: true,
        body: action.body,
        next: action.next,
      };
    case ASSETS_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case ASSETS_CREATE_FAILURE:
      return {
        ...initialStateAssets,
        error: action.error,
        formError: action.formError,
      };
    case ASSETS_UPDATE_REQUEST:
      return {
        ...state,
        error: '',
        formError: {},
        loading: true,
        id: action.id,
        body: action.body,
      };
    case ASSETS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case ASSETS_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case ASSETS_DELETE_REQUEST:
      return {
        ...state,
        error: '',
        formError: {},
        deleting: true,
        id: action.id,
      };
    case ASSETS_DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
      };
    case ASSETS_DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        error: action.error,
        formError: action.formError,
      };
    default:
      return state;
  }
};

export const assetsAllReducer = (state = initialStateAllAssets, action) => {
  switch (action.type) {
    case ASSETS_ALL_REQUEST:
      return {
        ...initialStateAllAssets,
        loading: true,
        query: action.query,
      };
    case ASSETS_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        dataAll: action.data,
      };
    case ASSETS_ALL_FAILURE:
      return {
        ...initialStateAllAssets,
        error: action.error,
      };
    default:
      return state;
  }
};

export const assetConditionMapReducer = (state = initialStateAllAssets, action) => {
  switch (action.type) {
    case ASSETS_BATCH_SUCCESS:
      return {
        ...state,
        dataAll: {
          ...state.dataAll,
          count: action.data.count,
          next: action.data.next,
          previous: action.data.previous,
          results: (state.dataAll.results ? state.dataAll.results : []).concat(action.data.results),
        },
      };
    case ASSETS_BATCH_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case ASSETS_BATCH_ALL_SUCCESS:
      return {
        ...state,
        isBatchLoading: false,
      };
    case ASSETS_BATCH_ALL_REQUEST:
      return {
        ...initialStateAllAssets,
        isBatchLoading: true,
      };
    case ASSETS_ABORT_BATCH_ALL:
      return {
        ...state,
        isBatchLoading: false,
      };
    default:
      return state;
  }
};

export default combineReducers({
  each: assetsReducer,
  all: assetsAllReducer,
  assetConditionMap: assetConditionMapReducer,
});
