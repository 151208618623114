import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from 'react-redux';
import {
  bookmarksListAllEndpoint,
  bookmarksCreateEndpoint,
  bookmarksUpdateEndpoint,
} from '../../../store/apiV2/bookmarks';
import FieldEditString from '../form/FieldEditString';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import { closeDialog } from '../../../store/dialogActions';

const useStyles = makeStyles(theme => ({
  form: {
    margin: theme.spacing(1),
    height: '90%',
  },
  buttonGroup: {
    marginTop: theme.spacing(3),
  },
}));

const BookmarkForm = ({ id: objectId, contentType, bookmark }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [submittedValues, setSubmittedValues] = useState({});
  const bookmarksListAll = bookmarksListAllEndpoint.useEndpoint();
  const bookmarksCreate = bookmarksCreateEndpoint.useEndpoint();
  const bookmarksUpdate = bookmarksUpdateEndpoint.useEndpoint();
  const { dispatchRequest: createBookmark, formError } = bookmarksCreate;
  const { dispatchRequest: updateBookmark } = bookmarksUpdate;

  const handleCloseDialog = () => dispatch(closeDialog());

  const initialValues = {};

  // if bookmark prop is defined, populate form
  if (bookmark) {
    initialValues.name = bookmark.name;
    initialValues.link = bookmark.link;
  }

  const handleOnSubmit = async values => {
    setSubmittedValues({ ...values });

    const successQuery = { object_id: objectId, content_type: contentType };
    const onSuccess = () => {
      handleCloseDialog();
      bookmarksListAll.dispatchRequest(successQuery);
    };
    const onError = err => console.error('Error submitting the form: ', err);

    if (bookmark) {
      updateBookmark(bookmark.id, { ...values })
        .then(onSuccess)
        .catch(onError);
    } else {
      const updatedValues = {
        ...values,
        ...successQuery,
      };
      createBookmark(updatedValues).then(onSuccess).catch(onError);
    }
  };

  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        if (values[key] === submittedValues[key]) {
          errors[key] = value;
        } else {
          errors[key] = undefined;
        }
      }
    }
    return errors;
  };

  return (
    <Form
      onSubmit={handleOnSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, pristine }) => {
        return (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Grid container direction="column" justifyContent="flex-start" spacing={3}>
              <Grid item>
                <FieldEditString label="Name" name="name" helperText="Name to display" />
              </Grid>
              <Grid item>
                <FieldEditString
                  label="Link"
                  name="link"
                  placeholder="https://example.com"
                  helperText="This link is visible to everyone with access this asset or project."
                />
              </Grid>
            </Grid>
            <Grid className={classes.buttonGroup} container justifyContent="flex-end" alignItems="flex-end">
              <Grid item xs={2}>
                <SecondaryButton label="Cancel" onClick={handleCloseDialog} />
              </Grid>
              <Grid item xs={2}>
                <PrimaryButton type="submit" label="Submit" disabled={submitting || pristine} />
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};

BookmarkForm.defaultProps = {
  bookmark: undefined,
};

BookmarkForm.propTypes = {
  id: PropTypes.number.isRequired,
  contentType: PropTypes.oneOf(['asset', 'project']).isRequired,
  bookmark: PropTypes.object,
};

export default BookmarkForm;
