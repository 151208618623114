import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AssessmentsRiskMatrix from './AssessmentsRiskMatrix';
import AssessmentReportInfo from './AssessmentReportInfo';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 25, 4, 3),
  },
  title: {
    padding: theme.spacing(0, 0, 1, 1),
    margin: theme.spacing(1, 0),
    fontSize: theme.spacing(3),
  },
}));

const QUALITATIVE = 'qualitative';
const AREA = 'area';
const FINANCIAL = 'financial';

const getRiskMatrixConfig = (assessmentType, matrixType) => {
  for (const config of assessmentType?.risk_matrix || []) {
    if (config instanceof Array) {
      // TODO remove this after Golar config has been fixed
      //    currently `risk_matrix` can either be the matrix, or an object with keys { qualitative, area, financial }
      if (matrixType === QUALITATIVE) {
        return assessmentType?.risk_matrix;
      } else {
        return null;
      }
    } else {
      if (config.type === matrixType) {
        return config.matrix;
      }
    }
  }
};

const AssessmentDetail = ({ assessment }) => {
  const classes = useStyles();
  const reports = assessment?.report_instances || [];

  const _renderMatrix = (config, cof, pof, label) => {
    if (config?.length && pof != null && cof != null) {
      return (
        <Grid item>
          <h4>{label}</h4>
          <AssessmentsRiskMatrix matrixConfig={config} cof={cof} pof={pof} />
        </Grid>
      );
    } else {
      return <></>;
    }
  };

  const renderQualitativeRiskMatrix = () => {
    const config = getRiskMatrixConfig(assessment?.type, QUALITATIVE);
    const { cof, pof } = assessment?.risk_score || {};

    return _renderMatrix(config, cof, pof, 'Quantitative');
  };

  const renderAreaRiskMatrix = () => {
    const config = getRiskMatrixConfig(assessment?.type, AREA);
    const { cof, pof } = assessment?.risk_score?.area || {};

    return _renderMatrix(config, cof, pof, 'Area');
  };

  const renderFinancialRiskMatrix = () => {
    const config = getRiskMatrixConfig(assessment?.type, FINANCIAL);
    const { cof, pof } = assessment?.risk_score?.financial || {};

    return _renderMatrix(config, cof, pof, 'Financial');
  };

  return (
    <Grid container direction="column" alignItems="stretch" className={classes.root} data-testid="assessment-detail">
      <div className={`${classes.title} h7`}>Risk</div>
      <Grid container direction="row" alignItems="stretch" item>
        {renderQualitativeRiskMatrix()}
        {renderAreaRiskMatrix()}
        {renderFinancialRiskMatrix()}
        <Grid item>
          <AssessmentReportInfo reports={reports} />
        </Grid>
      </Grid>
    </Grid>
  );
};

AssessmentDetail.propTypes = {
  assessment: PropTypes.object.isRequired,
};

export default AssessmentDetail;
