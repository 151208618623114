import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import LaunchIcon from '@mui/icons-material/Launch';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ButtonGroup } from '@mui/material';
import ButtonIcon from '../shared/buttons/ButtonIcon';
import Table, { SELECTABLE_ROWS_NONE } from '../shared/table/Table';
import { setTableSize, setTableView } from '../../store/settings/tableActions';
import { setColumns } from '../shared/table/columns';
import { usePermissions } from '../../hooks/settingsHooks';
import { getCustomCSVData, tableViews } from '../../utilities/tables';
import { isEmpty } from '../../utilities/objects';
import { compareFilterMapOptions, downloadFile } from '../../utilities/strings';
import AssessmentRiskScoreDisplay from './AssessmentRiskScore';
import StyledLink from '../shared/StyledLink';
import {
  assessmentStatusOptions,
  riskScoreOptions,
  pofAndCofOptions,
  DISPLAY_MODES,
} from '../../api/features/constants';
import MultiSelectFilter from '../shared/table/MultiSelectFilter';
import { useCachedAssetTypes } from '../../hooks/assetTypesHooks';
import { compare } from '../../utilities/arrays';
// import ViewDisplayKeyValue from '../shared/form/ViewDisplayKeyValue';
// import MultiSelectFilter from '../shared/table/MultiSelectFilter';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const AssessmentsTable = props => {
  const { title, queryParamObj, tableChangeHandler, embedded, page, views } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const { loading, data, count } = useSelector(state => ({
    loading: state.assessments.all.loading,
    data: state.assessments.all.dataAll.results,
    count: state.assessments.all.dataAll.count,
  }));

  const { assetTypeOptions, assetTypeMap } = useCachedAssetTypes();

  const handleDownload = (instanceData = {}) => {
    if (!isEmpty(instanceData)) {
      downloadFile(instanceData.name, instanceData.file);
    }
  };

  const handleOpenLink = path => {
    history.push(path);
  };

  const { assessmentsTableViewKey } = tableViews;
  const queryFilters = queryParamObj;

  const queryAssessmentStatusInFilters = queryFilters?.status__in ? [].concat(queryFilters.status__in.split(',')) : [];
  const queryRiskScoreDisplayFilters = queryFilters?.risk_score__display
    ? [].concat(queryFilters.risk_score__display.split(','))
    : [];

  const queryAssetTypeFilters = queryFilters?.asset__type__in ? [].concat(queryFilters.asset__type__in.split(',')) : [];

  const [assessmentStatusFilterList, setAssessmentStatusFilterList] = useState(queryAssessmentStatusInFilters);
  const [riskScoreDisplayFilterList, setRiskScoreDisplayFilterList] = useState(queryRiskScoreDisplayFilters);
  const [assetTypeFilterList, setAssetTypeFilterList] = useState(queryAssetTypeFilters);

  const { hasAssetView, hasAssessmentEdit, hasConfigurationManagementView } = usePermissions();

  /** @type {Column[]} */
  const columns = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'asset',
      label: 'Asset Path',
      options: {
        filter: true,
        sort: true,
        sortField: 'asset__asset_path_cache__path',
        filterType: 'textField', // matching other tables
        downloadBody: value => getCustomCSVData('simple', value, 'asset_path'),
        customBodyRender: value => {
          if (!value) return <></>;
          if (!hasAssetView) return <>{value.asset_path}</>;
          return <StyledLink to={`/assets/${value.id}/assessments`} value={value.asset_path} />;
        },
      },
    },
    {
      name: 'asset.type',
      label: 'Asset Type',
      options: {
        filter: false,
        sort: true,
        sortField: 'asset__type__name',
        downloadBody: value => getCustomCSVData('simple', value, 'name'),
        customBodyRender: value => {
          if (!value) return <></>;
          return hasConfigurationManagementView ? (
            <StyledLink to={`/asset-types/${value.id}`} value={value.name} />
          ) : (
            <>{value.name}</>
          );
        },
      },
    },
    {
      name: 'asset__type__in',
      label: 'Asset Type',
      options: {
        filter: true,
        filterType: 'custom',
        sort: false,
        display: 'excluded',
        download: false,
        filterList: assetTypeFilterList,
        // chips functionality
        customFilterListOptions: {
          render: values => {
            return values.map(v => {
              return assetTypeMap[v];
            });
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            setAssetTypeFilterList(filterList[index]);
            return filterList;
          },
        },
        // filtering menu
        filterOptions: {
          names: assetTypeOptions,
          render: v => assetTypeMap[v],
          logic: (type, filters) => {
            if (filters.length) return !filters.includes(type);
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            const formatValue = item => {
              return assetTypeMap[item];
            };
            const getComparable = value => compareFilterMapOptions(value, assetTypeMap);
            // Sort top level filter options
            filterData[index] = filterData[index].sort(compare(getComparable));

            return (
              <MultiSelectFilter
                title="Asset Types"
                filterList={filterList}
                localFilterList={assetTypeFilterList}
                onChange={onChange}
                index={index}
                column={column}
                filterData={filterData}
                updateFilters={setAssetTypeFilterList}
                formatValue={formatValue}
              />
            );
          },
        },
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: false,
        filterField: 'status__in',
        filterType: 'custom',
        customBodyRender: (value, tableMeta) => {
          const display = tableMeta.rowData[columns.findIndexByName['display']];
          if (display === DISPLAY_MODES.DETAIL || display === DISPLAY_MODES.DETAIL_SUMMARY) {
            return <>{value}</>;
          }
          return '--';
        },
        filterList: assessmentStatusFilterList,
        // chips functionality
        customFilterListOptions: {
          render: values => {
            return values.map(v => {
              return assessmentStatusOptions.find(o => o.value === v)?.label;
            });
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            setAssessmentStatusFilterList(filterList[index]);
            return filterList;
          },
        },
        // filtering menu
        filterOptions: {
          names: assessmentStatusOptions,
          render: v => assessmentStatusOptions.find(o => o.label === v).label,
          logic: (type, filters) => {
            if (filters.length) return !filters.includes(type);
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            const formatValue = item => {
              return assessmentStatusOptions.find(o => o.value === item)?.label;
            };

            filterData[index] = assessmentStatusOptions.map(item => item.value); // need to maintain order of assessment status
            return (
              <MultiSelectFilter
                title="Assessment Status"
                filterList={filterList}
                localFilterList={assessmentStatusFilterList}
                onChange={onChange}
                index={index}
                column={column}
                filterData={filterData}
                updateFilters={setAssessmentStatusFilterList}
                formatValue={formatValue}
              />
            );
          },
        },
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        filter: true,
        sort: true,
        sortField: 'type__name',
        customBodyRender: value => value.name,
      },
    },
    {
      name: 'risk_score',
      label: 'Risk',
      options: {
        filter: true,
        sort: true,
        sortField: 'risk_score__level',
        filterField: 'risk_score__display',
        filterType: 'custom',
        downloadBody: value => getCustomCSVData('simple', value, 'display'),
        customBodyRender: value => (value.display ? <AssessmentRiskScoreDisplay score={value.display} /> : null),
        filterList: riskScoreDisplayFilterList,
        // chips functionality
        customFilterListOptions: {
          render: values => {
            return values.map(v => {
              return riskScoreOptions.find(o => o.value === v)?.label;
            });
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            setRiskScoreDisplayFilterList(filterList[index]);
            return filterList;
          },
        },
        // filtering menu
        filterOptions: {
          names: riskScoreOptions,
          render: v => riskScoreOptions.find(o => o.value === String(v.display)).label,
          logic: (type, filters) => {
            if (filters.length) return !filters.includes(type);
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            const formatValue = item => {
              return riskScoreOptions.find(o => o.value === item)?.label;
            };

            filterData[index] = riskScoreOptions.map(item => item.value); // need to maintain order of assessment status
            return (
              <MultiSelectFilter
                title="Risk Score"
                filterList={filterList}
                localFilterList={riskScoreDisplayFilterList}
                onChange={onChange}
                index={index}
                column={column}
                filterData={filterData}
                updateFilters={setRiskScoreDisplayFilterList}
                formatValue={formatValue}
              />
            );
          },
        },
      },
    },
    {
      name: 'risk_score.pof',
      label: 'POF',
      options: {
        filter: false,
        sort: true,
        sortField: 'risk_score__pof',
      },
    },
    {
      name: 'risk_score__pof',
      label: 'POF',
      options: {
        display: 'excluded',
        filter: true,
        filterType: 'dropdown',
        filterOptions: {
          names: pofAndCofOptions,
        },
      },
    },
    {
      name: 'risk_score.cof',
      label: 'COF',
      options: {
        filter: false,
        sort: true,
        sortField: 'risk_score__cof',
      },
    },
    {
      name: 'risk_score__cof',
      label: 'COF',
      options: {
        display: 'excluded',
        filter: true,
        filterType: 'dropdown',
        filterOptions: {
          names: pofAndCofOptions,
        },
      },
    },
    {
      name: 'report_instances',
      label: 'Reports',
      options: {
        display: 'excluded',
        filter: false,
        download: false,
      },
    },
    {
      name: 'page',
      label: 'Page',
      options: {
        display: 'excluded',
        filter: false,
        download: false,
      },
    },
    {
      name: 'display',
      label: 'Display',
      options: {
        display: 'excluded',
        filter: false,
        download: false,
      },
    },
    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
        download: false,
        print: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta) => {
          const asset = tableMeta.rowData[columns.findIndexByName['asset']];
          const assessmentLink = `/assets/${asset.id}/assessments`;
          const reports = tableMeta.rowData[columns.findIndexByName['report_instances']] || [];
          const page = tableMeta.rowData[columns.findIndexByName['page']];

          const showReport = reports.length > 0;
          const showLaunch = hasAssessmentEdit && page?.path;

          return (
            <>
              <ButtonGroup>
                <ButtonIcon
                  icon={VisibilityIcon}
                  tooltip={'View Assessment'}
                  onClick={() => handleOpenLink(assessmentLink)}
                />
                {showLaunch && (
                  <ButtonIcon
                    icon={LaunchIcon}
                    tooltip={'Launch Assessment'}
                    onClick={() => handleOpenLink(page.path)}
                  />
                )}
                {showReport && (
                  <ButtonIcon
                    icon={PictureAsPdfIcon}
                    tooltip={'Download'}
                    disabled={!reports.length}
                    onClick={() => handleDownload(reports[0])}
                  />
                )}
              </ButtonGroup>
            </>
          );
        },
      },
    },
  ];

  // handle columns display
  setColumns(columns, views);

  /** @type {TableOptions} */
  const options = {
    // if the column view changes, update redux with either 'add' or 'remove'
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setTableView(changedColumn, action, page, assessmentsTableViewKey));
    },
    onChangeRowsPerPage: numberOfRows => {
      dispatch(setTableSize(numberOfRows, page, assessmentsTableViewKey));
    },
    enableNestedDataAccess: '.',
    selectableRows: SELECTABLE_ROWS_NONE,
  };

  return (
    <Table
      title={title}
      serverSide
      columns={columns}
      data={data}
      options={options}
      loading={loading}
      embedded={embedded}
      queryParamObj={queryParamObj}
      tableChangeHandler={tableChangeHandler}
      views={views}
      count={count}
    />
  );
};

AssessmentsTable.defaultProps = {
  embedded: false,
  queryParamObj: {},
  page: '',
  views: {},
};

AssessmentsTable.propTypes = {
  title: PropTypes.string.isRequired,
  tableChangeHandler: PropTypes.func.isRequired,
  embedded: PropTypes.bool,
  queryParamObj: PropTypes.object,
  page: PropTypes.string,
  views: PropTypes.object,
};

export default AssessmentsTable;
