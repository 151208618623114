import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Delete } from '@mui/icons-material';

import PrimaryButton from '../shared/buttons/PrimaryButton';
import SecondaryButton from '../shared/buttons/SecondaryButton';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(7, 'auto'),
    padding: theme.spacing(2),
    maxWidth: '600px',
  },
  body: {
    padding: theme.spacing(2, 4),
    textAlign: 'left',
  },
  buttonGroup: {
    padding: theme.spacing(2, 2, 0, 2),
  },
}));

const InspectorDefectFormToolbar = props => {
  const { selectedOverlay, submitDisabled, onDelete, reset, cancel, resetDefect } = props;
  const classes = useStyles();

  const resetForm = () => {
    resetDefect();
    // this should only be run on an update form
    // reset the form so it's not dirty
    reset();
  };

  return (
    <React.Fragment>
      <Grid container justifyContent="space-between" className={classes.buttonGroup}>
        <Grid item>
          {selectedOverlay.id ? (
            <SecondaryButton label="Cancel" onClick={resetForm} disabled={submitDisabled} />
          ) : (
            <SecondaryButton label="Cancel" onClick={cancel} />
          )}
        </Grid>
        <Grid item>
          {selectedOverlay.id ? (
            <IconButton color="inherit" aria-label="delete" onClick={onDelete} size="large">
              <Delete />
            </IconButton>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item>
          <PrimaryButton label="Save" type="submit" disabled={submitDisabled} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

InspectorDefectFormToolbar.defaultProps = {
  submitDisabled: false,
  component: undefined,
  reset: undefined,
  resetDefect: undefined,
  selectedOverlay: {},
};

InspectorDefectFormToolbar.propTypes = {
  cancel: PropTypes.func,
  resetDefect: PropTypes.func,
  selectedOverlay: PropTypes.object,
  onDelete: PropTypes.func,
  submitDisabled: PropTypes.bool,
  reset: PropTypes.func,
  component: PropTypes.func,
};

export default InspectorDefectFormToolbar;
