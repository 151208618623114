import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from 'mui-rff';
import makeStyles from '@mui/styles/makeStyles';
import { requiredFn } from '../../../utilities/validators';

/**
 * Styling an autocomplete field for a form
 */

const useStyles = makeStyles(theme => ({
  // make menu positioning relative
  popperDisablePortal: {
    position: 'relative',
  },
}));

const FieldEditAutocomplete = props => {
  const classes = useStyles();
  const { disabled, required, name, label, value, multiple, options, loading, helperText, onChange, placeHolder } =
    props;

  const moreProps = {};
  const textFieldProps = {};
  // if we are using the autocomplete as a controlled component
  if (value) {
    if (typeof value === 'string') {
      moreProps.value = options.find(option => option.value === value);
    } else {
      moreProps.value = value;
    }
    moreProps.getOptionSelected = option => option === value;
  }
  if (onChange) moreProps.onChange = onChange;

  // material-ui will show a warning if the value is loaded before the options
  if (options.length === 0) {
    // avoid crashing if there are no options set.
    moreProps.options = [{ label: '', value: '' }];
    moreProps.disabled = true;
    textFieldProps.placeholder = placeHolder || 'no options';
  }

  let modifiedLabel = label;
  if (required) {
    modifiedLabel = (
      <span>
        {label}
        <sup>*</sup>
      </span>
    );
  }

  return (
    <Autocomplete
      classes={classes}
      autoComplete
      disabled={disabled || loading}
      name={name}
      label={modifiedLabel}
      multiple={multiple}
      selectOnFocus
      defaultValue={value}
      disableCloseOnSelect={multiple}
      disablePortal
      options={options}
      onChange={onChange}
      helperText={helperText}
      getOptionValue={option => option.value}
      getOptionLabel={option => option.label || ''}
      fieldProps={required ? { validate: requiredFn(required) } : {}}
      openOnFocus
      textFieldProps={{
        variant: 'outlined',
        InputLabelProps: { shrink: true },
        ...textFieldProps,
      }}
      {...moreProps}
    />
  );
};

FieldEditAutocomplete.defaultProps = {
  value: undefined,
  required: false,
  disabled: false,
  loading: false,
  multiple: false,
  helperText: '',
  placeHolder: null,
};

FieldEditAutocomplete.propTypes = {
  /** label for the autocomplete field */
  label: PropTypes.string.isRequired,
  /** sets the label width so the border does not overlap the label */
  labelwidth: PropTypes.number.isRequired,
  /** the api key for the feature */
  name: PropTypes.string.isRequired,
  /** possible values to select from in the shape of: */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  /** initial value for the field */
  value: PropTypes.string,
  /** sets the field as required for the form */
  required: PropTypes.bool,
  /** disables the field while true */
  disabled: PropTypes.bool,
  /** disables the field while true */
  loading: PropTypes.bool,
  /** multiselect */
  multiple: PropTypes.bool,
  /** onChange for controlled components */
  onChange: PropTypes.func,
  /** adds helper text below the form field */
  helperText: PropTypes.string,
  placeHolder: PropTypes.string,
};

export default FieldEditAutocomplete;
