import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import CmlsTable from './CmlsTable';
import SecondaryButton from '../shared/buttons/SecondaryButton';
import FullScreenDialog from '../shared/FullScreenDialog';
import { getAllCmls } from '../../store/features/cmlsActions';
import { ProjectScopeContext } from '../projects/projectScope-context';
import { scopeModes } from '../projects/projectsShared';

const CmlsDialog = () => {
  const dispatch = useDispatch();
  const { openScopeDialog, setOpenScopeDialog } = useContext(ProjectScopeContext);

  const tableChangeHandler = query => {
    dispatch(getAllCmls(query));
  };

  return (
    <FullScreenDialog open={openScopeDialog}>
      <FullScreenDialog.AppBar>
        <Grid item>
          <SecondaryButton label="Cancel" onClick={() => setOpenScopeDialog(false)}></SecondaryButton>
        </Grid>
      </FullScreenDialog.AppBar>
      <FullScreenDialog.Container>
        <CmlsTable
          title="Select Condition Monitoring Locations"
          scopeMode={scopeModes.SELECT}
          tableChangeHandler={tableChangeHandler}
        />
      </FullScreenDialog.Container>
    </FullScreenDialog>
  );
};

export default CmlsDialog;
