export const migrations = {
  3: state => ({
    ...state,
    settings: {
      ...state.settings,
      assetTreeView: {},
    },
  }),
  2: state => ({
    ...state,
    settings: {
      ...state.settings,
      tables: {},
    },
  }),
  1: state => ({
    ...state,
    libraries: {
      all: {
        loading: false,
        error: '',
        query: '',
        dataAll: { ...state.libraries.dataAll },
      },
      each: {
        loading: false,
        error: '',
        formError: {},
        body: {},
        data: { ...state.libraries.data },
      },
    },
  }),
  0: state => state,
};
