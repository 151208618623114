import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import DefectProfilesTable from './DefectProfilesTable';
import { defectProfilesListAllEndpoint } from '../../store/apiV2/defectProfiles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const DefectProfilesTablePage = () => {
  const { dispatchRequest: fetchAllDefectProfiles } = defectProfilesListAllEndpoint.useEndpoint();
  const classes = useStyles();

  useEffect(() => {
    fetchAllDefectProfiles();
  }, [fetchAllDefectProfiles]);

  return (
    <div className={classes.root}>
      <DefectProfilesTable title="Finding Profiles" />
    </div>
  );
};

export default DefectProfilesTablePage;
