import {
  requestFactory,
  responseHandlerForm,
} from '../base';

const get = requestFactory('get');
const post = requestFactory('post', { handler: responseHandlerForm });
const patch = requestFactory('patch', { handler: responseHandlerForm });
const del = requestFactory('del');

export const fetchCompaniesList = async query => get('/api/companies/', query);
export const fetchCompanies = async id => get(`/api/companies/${id}/`);
export const createCompanies = async body => post('/api/companies/', body);
export const updateCompanies = async (id, body) => patch(`/api/companies/${id}/`, body);
export const deleteCompanies = async id => del(`/api/companies/${id}/`);
