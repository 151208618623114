import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { FieldArray } from 'react-final-form-arrays';
import { TextField } from 'mui-rff';

const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(3),
  },
  deleteButton: {
    padding: theme.spacing(1),
  },
}));

const NumberListLineItem = props => {
  const classes = useStyles();
  const { name, value, placeholder, loading, disabled, helperText, inputPropClasses, validator } = props;
  const moreProps = {};
  if (value) moreProps.value = value;
  return (
    <FieldArray name={name}>
      {({ fields }) => (
        <div className={classes.container}>
          {fields.map((name, index) => (
            <Grid container key={index}>
              <Grid item xs={11}>
                <TextField
                  {...moreProps}
                  label={`Value ${index + 1}`}
                  name={name}
                  placeholder={placeholder}
                  helperText={helperText}
                  disabled={disabled || loading}
                  variant="filled"
                  fieldProps={{ validate: validator }}
                  InputProps={{
                    classes: {
                      input: inputPropClasses,
                    },
                  }}
                />
              </Grid>
              <Grid item className={classes.deleteButton}>
                <IconButton
                  size="small"
                  disabled={disabled}
                  onClick={() => fields.remove(index)}
                  aria-label="remove value">
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Tooltip title="Add Value">
            <IconButton
              type="button"
              size="small"
              disabled={disabled}
              onClick={() => fields.push('')}
              aria-label="add value">
              <AddIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </FieldArray>
  );
};

NumberListLineItem.defaultProps = {
  disabled: false,
  required: false,
  value: undefined,
  placeholder: '',
  loading: false,
  inputPropClasses: '',
  validator: () => {},
};

NumberListLineItem.propTypes = {
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  loading: PropTypes.bool,
  inputPropClasses: PropTypes.string,
  validator: PropTypes.func,
};

export default NumberListLineItem;
