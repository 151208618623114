// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import { combineReducers } from 'redux';
import {
  SCHEDULE_EVENTS_REQUEST,
  SCHEDULE_EVENTS_SUCCESS,
  SCHEDULE_EVENTS_FAILURE,
  SCHEDULE_EVENTS_CREATE_REQUEST,
  SCHEDULE_EVENTS_CREATE_SUCCESS,
  SCHEDULE_EVENTS_CREATE_FAILURE,
  SCHEDULE_EVENTS_UPDATE_REQUEST,
  SCHEDULE_EVENTS_UPDATE_SUCCESS,
  SCHEDULE_EVENTS_UPDATE_FAILURE,
  SCHEDULE_EVENTS_DELETE_REQUEST,
  SCHEDULE_EVENTS_DELETE_SUCCESS,
  SCHEDULE_EVENTS_DELETE_FAILURE,
  SCHEDULE_EVENTS_ALL_REQUEST,
  SCHEDULE_EVENTS_ALL_SUCCESS,
  SCHEDULE_EVENTS_ALL_FAILURE,
} from './scheduleEventsActions';

export const initialStateScheduleEvents = {
  loading: false,
  error: '',
  formError: {},
  id: 0,
  body: {},
  data: {},
};

export const initialStateAllScheduleEvents = {
  loading: false,
  error: '',
  query: {},
  dataAll: { count: 0, next: null, previous: null, results: [] },
};

export const resetStateScheduleEvents = {
  loading: false,
  error: '',
  formError: {},
  data: {},
};
export const resetStateAllScheduleEvents = {
  loading: false,
  error: '',
  query: {},
  dataAll: [],
};

export const scheduleEventsEachReducer = (state = initialStateScheduleEvents, action) => {
  switch (action.type) {
    case SCHEDULE_EVENTS_REQUEST:
      return {
        ...initialStateScheduleEvents,
        loading: true,
        id: action.id,
      };
    case SCHEDULE_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case SCHEDULE_EVENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SCHEDULE_EVENTS_CREATE_REQUEST:
      return {
        ...initialStateScheduleEvents,
        loading: true,
        body: action.body,
      };
    case SCHEDULE_EVENTS_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case SCHEDULE_EVENTS_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case SCHEDULE_EVENTS_UPDATE_REQUEST:
      return {
        ...initialStateScheduleEvents,
        loading: true,
        id: action.id,
        body: action.body,
      };
    case SCHEDULE_EVENTS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case SCHEDULE_EVENTS_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case SCHEDULE_EVENTS_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        formError: {},
        id: action.id,
      };
    case SCHEDULE_EVENTS_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SCHEDULE_EVENTS_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    default:
      return state;
  }
};

export const scheduleEventsAllReducer = (state = initialStateAllScheduleEvents, action) => {
  switch (action.type) {
    case SCHEDULE_EVENTS_ALL_REQUEST:
      return {
        ...initialStateAllScheduleEvents,
        loading: true,
        query: action.query,
      };
    case SCHEDULE_EVENTS_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        dataAll: action.data,
      };
    case SCHEDULE_EVENTS_ALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default combineReducers({
  each: scheduleEventsEachReducer,
  all: scheduleEventsAllReducer,
});
