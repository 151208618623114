import React from 'react';
import PropTypes from 'prop-types';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import saveAs from 'save-as';
import * as Sentry from '@sentry/browser';
import { IconButton, Tooltip } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useDispatch } from 'react-redux';
import ZipIcon from '../svgs/ZipOutlined';
import UnzipOutLined from '../svgs/UnzipOutlined';
import { useFeatureFlags } from '../../../hooks/settingsHooks';
import { downloadFile } from '../../../utilities/strings';
import { isEmpty } from '../../../utilities/objects';
import { openSnackbar } from '../../../store/snackbarActions';
import { inspectionMediaProcessEndpoint } from '../../../store/apiV2/inspectionMedia';

// This is used by all the tables that use this custom toolbar.
// Have to make sure that the filename is defined in the FILE_COL_INDEX of the columns array
// If things get more complicated, we can move declare getFiles in each table and pass it into this toolbar.
export const getFiles = (selectedRows, displayData) => {
  const FILE_COL_IDX = 1; // The file needs to be the second element in the column definition of the table
  const selected = [];
  for (let i = 0; i < selectedRows.length; i++) {
    const url = displayData[selectedRows[i].index].data[FILE_COL_IDX];
    if (url) {
      const split = url.split('?')[0].split('/'); // remove the query
      const filename = split[split.length - 1]; // The last element is the filename
      const dirname = split[split.length - 2]; // The second to last element is the dirname
      selected.push({ url, filename, dirname });
    }
    // This silently fails if there's no file to download.
    // on the standalone defects table, it's possible for a defect to not have media.
    // Not sure if it's better to silently fail or somehow show a message for each file that's not downloaded
  }
  return selected;
};

const CustomToolbarSelectMedia = props => {
  const { dispatchRequest: postInspectionMediaProcess } = inspectionMediaProcessEndpoint.useEndpoint();

  const { hasUnzipMedia } = useFeatureFlags();
  const { selectedRows, displayData, getInspectionMediaFiles, getSelectedRowIds, hideUnzip } = props;
  const displayUnzipMedia = hasUnzipMedia && !hideUnzip;
  const dispatch = useDispatch();

  const handleDownload = async () => {
    const files = getInspectionMediaFiles(selectedRows, displayData);
    for (let x = 0; x < files.length; x++) {
      try {
        dispatch(openSnackbar('Starting download...', 'info', null, 2000));
        await downloadFile(files[x].filename, files[x].url);
      } catch (error) {
        const message = `Error downloading media:${files[x].filename} ${error}`;
        console.error(message);
        Sentry.captureMessage(message);
      }
    }
  };

  const handleZip = async () => {
    const files = getInspectionMediaFiles(selectedRows, displayData);
    const zip = new JSZip();
    const zipFilename = `${files[0].dirname}.zip`;
    for (let x = 0; x < files.length; x++) {
      try {
        dispatch(openSnackbar('Starting download...', 'info', null, 3000));
        const file = await JSZipUtils.getBinaryContent(files[x].url);
        zip.file(`${files[x].dirname}/${files[x].filename}`, file, { binary: true });
      } catch (error) {
        const message = `Error zipping media:${files[x].filename} ${error}`;
        console.error(message);
        Sentry.captureMessage(message);
      }
    }
    try {
      zip.generateAsync({ type: 'blob' }).then(content => {
        saveAs(content, zipFilename);
      });
    } catch (error) {
      const message = `Error generating zip: ${error}`;
      console.error(message);
      Sentry.captureMessage(message);
    }
  };

  const postUnzipRequest = async filesToUnzip => {
    try {
      // make request
      const data = {
        inspection_media_ids: filesToUnzip,
        action: 'EXTRACT_COMPRESSED_MEDIA',
      };
      const requestResult = await postInspectionMediaProcess(data);
      const { files_processing: filesProcessing, errors } = requestResult.data;
      return { numFilesProcessing: filesProcessing.length, errors: errors };
    } catch (error) {
      // api error
      const message = `Error during unzip: ${error}`;
      console.error(message);
      Sentry.captureMessage(message);
      return { numFilesProcessing: 0, errors: message };
    }
  };

  const handleUnzip = async () => {
    const filesToUnzip = getSelectedRowIds(selectedRows, displayData);
    const { numFilesProcessing, errors } = await postUnzipRequest(filesToUnzip);

    let message = `Unzipping ${numFilesProcessing} files. `;
    if (numFilesProcessing > 0) {
      // user friendly message as they needs to refresh to show the files
      message += `Please check back later. `;
    }
    // checking for errors
    if (isEmpty(errors)) {
      dispatch(openSnackbar(message, 'success', null, 5000));
    } else {
      const errorMessage = JSON.stringify(errors, null, 0);
      console.warn('Error during unzipping: ', errorMessage);
      message += 'Encountered errors: ' + errorMessage;
      dispatch(openSnackbar(message, 'error', null));
    }
  };

  return (
    <div>
      <Tooltip title="Download Files">
        <IconButton onClick={handleDownload} size="large">
          <GetAppIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Zip Files">
        <IconButton onClick={handleZip} size="large">
          <ZipIcon />
        </IconButton>
      </Tooltip>
      {displayUnzipMedia && (
        <Tooltip title="Unzip Files">
          <IconButton onClick={handleUnzip}>
            <UnzipOutLined />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

CustomToolbarSelectMedia.defaultProps = {
  getInspectionMediaFiles: getFiles,
  hideUnzip: false,
};

CustomToolbarSelectMedia.propTypes = {
  selectedRows: PropTypes.object,
  displayData: PropTypes.array,
  getInspectionMediaFiles: PropTypes.func,
  getSelectedRowIds: PropTypes.func,
  hideUnzip: PropTypes.bool,
};

export default CustomToolbarSelectMedia;
