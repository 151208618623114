import { all, put, takeLatest } from 'redux-saga/effects';

import {
  LOGO_REQUEST,
  LOGO_SUCCESS,
  LOGO_FAILURE,
} from './logoActions';

import { fetchLogo } from '../../api/settings/logo.js';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';

export function* logoFetch() {
  try {
    const { data, error, response } = yield fetchLogo();
    if (data && data.logo) {
      yield put({ type: LOGO_SUCCESS, data });
    } else if (error) {
      yield put({ type: LOGO_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, LOGO_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, LOGO_FAILURE);
  }
}

export function* watchLogoFetch() {
  yield takeLatest(LOGO_REQUEST, logoFetch);
}

export default function* logoSaga() {
  yield all([watchLogoFetch()]);
}
