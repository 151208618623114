import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import ChecklistFlatTable from './ChecklistFlatTable';
import { flattenChecklist } from '../../utilities/checklist';

function ChecklistViewer(props) {
  const { checklist: data, selectedLine, revision } = props;
  const { flatLines } = useMemo(() => flattenChecklist(data, revision), [data, revision]);

  return <ChecklistFlatTable title={data.template.name} data={flatLines} selectedLine={selectedLine} />;
}

ChecklistViewer.defaultProps = {
  revision: undefined,
};

ChecklistViewer.propTypes = {
  checklist: PropTypes.object.isRequired,
  selectedLine: PropTypes.string,
  revision: PropTypes.object,
};

export default ChecklistViewer;
