import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Table, { SELECTABLE_ROWS_NONE } from '../shared/table/Table';
import {
  setSchedulePlanTableView,
  setSchedulePlanTableSize,
} from '../../store/settings/views/schedulePlanTableViewRedux';
import { deleteSchedulePlan } from '../../store/features/schedulePlanActions';
import { openDialog } from '../../store/dialogActions';
import { locationWithBack } from '../../utilities/route';
import StyledLink from '../shared/StyledLink';
import DeleteDialog from '../shared/Dialog/DeleteDialog';
import { setColumns, sortByKey } from '../shared/table/columns';
import { usePermissions, useTableViews } from '../../hooks/settingsHooks';
import { getCustomCSVData, tableViews } from '../../utilities/tables';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const schedulePlansUrl = '/schedule-plan';

const SchedulePlanTable = props => {
  const { title } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { hasAssetView } = usePermissions();
  const { loading, data } = useSelector(state => ({
    loading: state.schedulePlan.all.loading,
    data: state.schedulePlan.all.dataAll.results,
  }));
  const { schedulePlanTableViewKey } = tableViews;
  const views = useTableViews(schedulePlanTableViewKey);

  /** @type {Column[]} */
  const columns = [
    {
      // This needs to be first and excluded so customBodyRender can access the id to create the link.
      name: 'id',
      label: 'Id',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <StyledLink to={`${schedulePlansUrl}/${tableMeta.rowData[columns.findIndexByName['id']]}`} value={value} />
          );
        },
      },
    },
    {
      name: 'asset',
      label: 'Asset',
      options: {
        filter: true,
        sort: true,
        sortCompare: order => {
          return (obj1, obj2) => {
            return sortByKey(order, obj1, obj2, 'asset_path');
          };
        },
        sortField: 'asset__asset_path_cache__path',
        filterType: 'textField',
        //   filterList: queryAssetPathFilter,
        //   downloadBody: value => getCustomCSVData('simple', value, 'asset_path'),
        customBodyRender: value => {
          if (!value) return <></>;
          if (!hasAssetView) return <>{value.asset_path}</>;
          return <StyledLink to={`/assets/${value.id}`} value={value.asset_path} />;
        },
        downloadBody: value => getCustomCSVData('simple', value, 'asset_path'),
      },
    },
    {
      name: 'crew',
      label: 'Crew',
      options: {
        filter: true,
        sort: true,
        sortCompare: order => {
          return (obj1, obj2) => {
            return sortByKey(order, obj1, obj2, 'name');
          };
        },
        customBodyRender: value => {
          if (!value) return <></>;
          return <StyledLink to={`/crews/${value.id}`} value={value.display_name} />;
        },
        downloadBody: value => getCustomCSVData('simple', value, 'display_name'),
      },
    },
    {
      name: 'is_active',
      label: 'Is Active',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => (value ? 'True' : 'False'),
      },
    },
    {
      name: 'created_by',
      label: 'Created By',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => (value ? value.name : '--'),
      },
    },

    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        print: false,
        viewColumns: false,
        customBodyRender: (_, info) => {
          // 0 is the first element in column
          const id = info.rowData[columns.findIndexByName['id']];
          const name = info.rowData[columns.findIndexByName['name']];
          return (
            <>
              <EditIcon
                fontSize="small"
                onClick={() => {
                  history.push(locationWithBack({ pathname: `${schedulePlansUrl}/${id}/edit` }, location));
                }}
              />
              <DeleteIcon
                fontSize="small"
                onClick={() => {
                  dispatch(
                    openDialog(
                      'Delete Schedule Plan?',
                      <DeleteDialog id={id} deleteAction={deleteSchedulePlan} name={name} />
                    )
                  );
                }}
              />
            </>
          );
        },
      },
    },
  ];

  // handle columns display
  setColumns(columns, views);

  /** @type {TableOptions} */
  const options = {
    // if the column view changes, update redux with either 'add' or 'remove'
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setSchedulePlanTableView(changedColumn, action));
    },
    onChangeRowsPerPage: numberOfRows => {
      dispatch(setSchedulePlanTableSize(numberOfRows));
    },
    rowsPerPage: views.pageSize,
    enableNestedDataAccess: '.',
    selectableRows: SELECTABLE_ROWS_NONE,
  };

  return (
    <Table
      title={title}
      simpleSearch
      columns={columns}
      addRoute={`${schedulePlansUrl}/new`}
      data={data}
      options={options}
      loading={loading}
    />
  );
};

SchedulePlanTable.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SchedulePlanTable;
