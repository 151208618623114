/**
 * Mutators allow you to modify a `react-final-form`'s Form state,
 *  similar to how you would do in "vanilla" React
 *
 * See documentation / additional resources:
 *  https://final-form.org/docs/final-form/types/Mutator
 *  https://www.dtreelabs.com/blog/react-final-form-mutators
 *
 */

/**
 *
 * @param {Array} args [fieldname, value]
 * @param {*} state "MutableState"
 *
 * inspired by:
 *  https://github.com/final-form/final-form-set-field-data/blob/master/src/setFieldData.js
 *
 */
export const setFieldValue = (args, state) => {
  const [name, value] = args;
  const field = state.fields[name];
  if (field) {
    field.change(value);
  }
};
