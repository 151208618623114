// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import { combineReducers } from 'redux';
import {
  SCHEDULE_TASKS_REQUEST,
  SCHEDULE_TASKS_SUCCESS,
  SCHEDULE_TASKS_FAILURE,
  SCHEDULE_TASKS_CREATE_REQUEST,
  SCHEDULE_TASKS_CREATE_SUCCESS,
  SCHEDULE_TASKS_CREATE_FAILURE,
  SCHEDULE_TASKS_UPDATE_REQUEST,
  SCHEDULE_TASKS_UPDATE_SUCCESS,
  SCHEDULE_TASKS_UPDATE_FAILURE,
  SCHEDULE_TASKS_DELETE_REQUEST,
  SCHEDULE_TASKS_DELETE_SUCCESS,
  SCHEDULE_TASKS_DELETE_FAILURE,
  SCHEDULE_TASKS_ALL_REQUEST,
  SCHEDULE_TASKS_ALL_SUCCESS,
  SCHEDULE_TASKS_ALL_FAILURE,
} from './scheduleTasksActions';

export const initialStateScheduleTasks = {
  loading: false,
  error: '',
  formError: {},
  id: 0,
  body: {},
  data: {},
};

export const initialStateAllScheduleTasks = {
  loading: false,
  error: '',
  query: {},
  dataAll: { count: 0, next: null, previous: null, results: [] },
};

export const resetStateScheduleTasks = {
  loading: false,
  error: '',
  formError: {},
  data: {},
};
export const resetStateAllScheduleTasks = {
  loading: false,
  error: '',
  query: {},
  dataAll: [],
};

export const scheduleTasksEachReducer = (state = initialStateScheduleTasks, action) => {
  switch (action.type) {
    case SCHEDULE_TASKS_REQUEST:
      return {
        ...initialStateScheduleTasks,
        loading: true,
        id: action.id,
      };
    case SCHEDULE_TASKS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case SCHEDULE_TASKS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SCHEDULE_TASKS_CREATE_REQUEST:
      return {
        ...initialStateScheduleTasks,
        loading: true,
        body: action.body,
      };
    case SCHEDULE_TASKS_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case SCHEDULE_TASKS_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case SCHEDULE_TASKS_UPDATE_REQUEST:
      return {
        ...initialStateScheduleTasks,
        loading: true,
        id: action.id,
        body: action.body,
      };
    case SCHEDULE_TASKS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case SCHEDULE_TASKS_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case SCHEDULE_TASKS_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        formError: {},
        id: action.id,
      };
    case SCHEDULE_TASKS_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SCHEDULE_TASKS_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    default:
      return state;
  }
};

export const scheduleTasksAllReducer = (state = initialStateAllScheduleTasks, action) => {
  switch (action.type) {
    case SCHEDULE_TASKS_ALL_REQUEST:
      return {
        ...initialStateAllScheduleTasks,
        loading: true,
        query: action.query,
      };
    case SCHEDULE_TASKS_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        dataAll: action.data,
      };
    case SCHEDULE_TASKS_ALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default combineReducers({
  each: scheduleTasksEachReducer,
  all: scheduleTasksAllReducer,
});
