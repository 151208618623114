export const FEATURES_REQUEST = 'FEATURES_REQUEST';
export const FEATURES_SUCCESS = 'FEATURES_SUCCESS';
export const FEATURES_FAILURE = 'FEATURES_FAILURE';
export const FEATURES_CLEAR = 'FEATURES_CLEAR';

export const requestFeatures = (options = {}) => {
  return {
    type: FEATURES_REQUEST,
    options,
  };
};

export const clearFeatures = () => {
  return {
    type: FEATURES_CLEAR,
  };
};
