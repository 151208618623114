import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Badge, ButtonGroup, Grid, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import GetAppIcon from '@mui/icons-material/GetApp';
import CallMergeIcon from '@mui/icons-material/CallMerge';
import Description from '../shared/table/Description';
import Table from '../shared/table/Table';
import MultiSelectFilter from '../shared/table/MultiSelectFilter';
import { deleteDefects } from '../../store/features/defectsActions';
import { setTableSize, setTableView } from '../../store/settings/tableActions';
import ViewDisplayKeyValue from '../shared/form/ViewDisplayKeyValue';
import ButtonIcon from '../shared/buttons/ButtonIcon';
import StyledLink from '../shared/StyledLink';
import { setColumns } from '../shared/table/columns';
import { defectStateOptions } from '../../api/features/constants';
import { compareFilterMapOptions, displayOption, downloadFile, truncate } from '../../utilities/strings';
import { noThumbnail, getOverlayFilename } from '../../utilities/files';
import DefectToolBarSelect from './DefectToolbarSelect';
import { useTopLevelAssets } from '../../hooks/assetHooks';
import { usePermissions, useFeatureFlags } from '../../hooks/settingsHooks';
import { useTableRowSelectionManagerOptions } from '../../hooks/tableHooks';
import { openDialog } from '../../store/dialogActions';
import DeleteDialog from '../shared/Dialog/DeleteDialog';
import { compare, ensureArray } from '../../utilities/arrays';
import { getCustomCSVData, tableViews } from '../../utilities/tables';
import { useCachedAssetTypes } from '../../hooks/assetTypesHooks';
import ScopeToolbar from '../cmls/ScopeToolbar';
import { scopeModes } from '../projects/projectsShared';
import useDateRangeColumnHook from '../../hooks/table/dateRangeColumnHook';
import GeoPointDisplay from '../shared/form/GeoPointDisplay';
import GeoPoint from '../shared/form/GeoPoint';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

// displayId column truncates ID after 8 characters
const MAX_COL_LENGTH = 8;

const useStyles = makeStyles(theme => ({
  thumbnailImage: {
    width: theme.spacing(10),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  fullScreenIcon: {
    position: 'absolute',
    right: theme.spacing(5),
  },
  mergedId: {
    width: theme.spacing(5),
  },
}));

/**
 * Currently supports 3 use cases
 *  A. browsing all findings
 *  B. findings associated with a project (embedded within "ProjectDetails" view)
 *  C. findings associated with a project _scope_ (embedded within "ProjectDetails > ProjectScope" view)
 *
 *  Note that A/B make use of the `queryParamObj` param,
 *    but as C must be shown at the same time as B,
 *
 *  A/B can use `DefectsTable`
 *  C can use `_DefectsTable`
 */
const _DefectsTable = props => {
  const {
    count,
    data,
    embedded,
    filename,
    isProject,
    isReadOnly,
    loading,
    queryParamObj,
    queryStateFilters,
    queryAssetParentInFilters,
    queryAssetTypeInFilters,
    tableChangeHandler,
    title,
    views,
    page,
    disableNewProject,
    scopeMode,
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { defectsTableViewKey } = tableViews;

  const { hasAssetView } = usePermissions();
  const { hasAccessDisplay, hasFindingsPrioritization } = useFeatureFlags();

  const { topLevelAssetOptions, topLevelAssetMap } = useTopLevelAssets();
  const { assetTypeOptions, assetTypeMap } = useCachedAssetTypes();

  const [stateFilterList, setStateFilterList] = useState(queryStateFilters); // make an array if only one string is supplied
  const [topLevelAssetFilterList, setTopLevelAssetFilterList] = useState(queryAssetParentInFilters);
  const [assetTypeFilterList, setAssetTypeFilterList] = useState(queryAssetTypeInFilters);

  const getOverlayFiles = (overlays, rowData, columnNames) => {
    // see #4485
    const files = [];
    // convert row data object to a list of values ( for data uniformity )
    const rowDataArray = [];
    columnNames.forEach((name, idx) => rowDataArray.push(rowData[columnNames[idx].name]));
    overlays.forEach(overlay => {
      const url = overlay.media.file;
      const filename = getOverlayFilename(url, rowDataArray, columnNames, overlay);
      files.push({ filename: filename, url: url });
    });

    for (let x = 0; x < files.length; x++) {
      downloadFile(files[x].filename, files[x].url);
    }
  };

  const ThumbnailDisplay = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

  /** @type {Column[]} */
  const columns = [
    {
      name: 'id', // Keep the id as raw, to use in other columns
      label: 'ID',
      options: {
        filter: false,
        display: 'excluded',
        sort: false,
      },
    },
    {
      name: 'asset.id', // Keep the asset id as raw, to use in other columns
      label: 'assetID',
      options: {
        filter: false,
        display: 'excluded',
        sort: false,
        download: false,
      },
    },
    {
      name: 'displayId', // Render ID with links
      label: 'ID',
      options: {
        filter: false,
        sort: true,
        sortField: 'id',
        customBodyRender: (value, tableMeta) => {
          const linked = tableMeta.rowData[columns.findIndexByName['newer_defect']];
          const id = tableMeta.rowData[columns.findIndexByName['id']];
          // id is type number. {useGrouping: false} is used to remove commas from the id
          const formattedId = id.toLocaleString({}, { useGrouping: false });
          const isTruncated = formattedId.length > MAX_COL_LENGTH;
          const truncatedId = isTruncated ? truncate(formattedId, MAX_COL_LENGTH) : formattedId;

          return (
            <Tooltip title={formattedId} placement="bottom">
              <Grid container direction="row" justifyContent="space" className={classes.mergedId}>
                <Grid>
                  <StyledLink to={`/findings/${id}`} value={truncatedId} />
                </Grid>
                {linked && (
                  <Grid>
                    <a href={`/findings/${linked}`}>
                      <CallMergeIcon />
                    </a>
                  </Grid>
                )}
              </Grid>
            </Tooltip>
          );
        },
      },
    },
    {
      name: 'newer_defect',
      label: 'Newer Defect',
      options: {
        filter: false,
        display: 'excluded',
        sort: true,
      },
    },
    {
      // This needs to be second and excluded so CustomToolbarSelectedMedia knows what files have been selected
      name: 'overlays',
      label: 'Media',
      options: {
        filter: false,
        display: 'excluded',
        download: true,
        downloadBody: values => ensureArray(values.map(overlay => overlay?.media?.file)) ?? [],
        customBodyRender: value => {
          // Get the filename of the media out of the finding.  It's possible for a finding to not have media.
          if (Array.isArray(value) && value.length) {
            return value;
          }
          return [];
        },
      },
    },
    {
      name: 'asset',
      label: 'Asset Path',
      options: {
        filter: true,
        filterType: 'textField',
        sortField: 'asset__asset_path_cache__path',
        sort: true,
        downloadBody: value => getCustomCSVData('simple', value.asset_path),
        customBodyRender: (value, tableMeta) => {
          if (!value) return <></>;
          if (!hasAssetView) return <>{value.asset_path}</>;
          return <StyledLink to={`/assets/${value.id}`} value={value.asset_path} />;
        },
      },
    },
    {
      // invisible column for access to the project id
      name: 'project',
      label: 'Project ID',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: 'project_name',
      label: 'Project',
      options: {
        filter: true,
        sort: true,
        sortField: 'project__name',
        display: isProject ? 'excluded' : true,
        viewColumns: !isProject,
        filterType: 'textField',
        downloadBody: value => getCustomCSVData('simple', value),
        customBodyRender: (value, info) => {
          const projectId = info.rowData[columns.findIndexByName['project']];
          if (!value) return <>--</>;
          return <StyledLink to={`/projects/${projectId}`} value={value} />;
        },
      },
    },
    {
      name: 'descendants',
      label: 'Top Level Asset',
      options: {
        filter: true,
        sort: false,
        display: 'excluded',
        filterType: 'custom',
        download: false,
        filterList: queryAssetParentInFilters,
        customFilterListOptions: {
          // customize filter display chips
          render: values => {
            return values.map(v => {
              return topLevelAssetMap[v];
            });
          },
          // customize deleting filter display chip
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            setTopLevelAssetFilterList(filterList[index]);
            return filterList;
          },
        },
        filterOptions: {
          names: topLevelAssetOptions,
          // format values in the multiselect input
          render: v => topLevelAssetMap[v],
          logic: (topLevelAsset, filters) => {
            if (filters.length) return !filters.includes(topLevelAsset);
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            const formatValue = item => {
              // format values in the multiselect menu
              return topLevelAssetMap[item];
            };

            const getComparable = value => compareFilterMapOptions(value, topLevelAssetMap);
            // Sort top level filter options
            filterData[index] = filterData[index].sort(compare(getComparable));
            return (
              <MultiSelectFilter
                title="Top Level Asset"
                filterList={filterList}
                localFilterList={topLevelAssetFilterList}
                onChange={onChange}
                index={index}
                column={column}
                filterData={filterData}
                updateFilters={setTopLevelAssetFilterList}
                formatValue={formatValue}
              />
            );
          },
        },
      },
    },
    {
      name: 'asset_type__in',
      label: 'Asset Type',
      options: {
        filter: true,
        sort: false,
        display: 'excluded',
        download: false,
        filterType: 'custom',
        filterList: assetTypeFilterList,
        customFilterListOptions: {
          // customize filter display chips
          render: values => {
            return values.map(v => {
              return assetTypeMap[v];
            });
          },
          // customize deleting filter display chip
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            setAssetTypeFilterList(filterList[index]);
            return filterList;
          },
        },
        filterOptions: {
          names: assetTypeOptions,
          // format values in the multiselect input
          render: v => assetTypeMap[v],
          logic: (assetType, filters) => {
            if (filters.length) return !filters.includes(assetType);
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            const formatValue = item => {
              // format values in the multiselect menu
              return assetTypeMap[item];
            };
            const getComparable = value => compareFilterMapOptions(value, assetTypeMap);
            // Sort top level filter options
            filterData[index] = filterData[index].sort(compare(getComparable));
            return (
              <MultiSelectFilter
                title="Asset Type"
                filterList={filterList}
                localFilterList={assetTypeFilterList}
                onChange={onChange}
                index={index}
                column={column}
                filterData={filterData}
                updateFilters={setAssetTypeFilterList}
                formatValue={formatValue}
              />
            );
          },
        },
      },
    },
    {
      name: 'component_display',
      label: 'Component',
      options: {
        filter: true,
        filterType: 'textField',
        sort: true,
      },
    },
    {
      name: 'location_zone_display',
      label: 'Location Zone',
      options: {
        filter: true,
        filterType: 'textField',
        sort: true,
      },
    },
    {
      name: 'location_code_display',
      label: 'Location Code',
      options: {
        filter: true,
        filterType: 'textField',
        sort: true,
      },
    },
    {
      name: 'access_display',
      label: 'Access',
      options: {
        filter: true,
        filterType: 'textField',
        display: hasAccessDisplay ? 'true' : 'excluded',
        download: hasAccessDisplay,
        sort: true,
      },
    },
    {
      name: 'geo_point',
      label: 'Geo Point',
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => <GeoPointDisplay geoPoint={value} />,
        downloadBody: value => new GeoPoint(value).toString(),
      },
    },
    {
      name: 'severity_display',
      label: 'Severity',
      options: {
        filter: true,
        filterType: 'textField',
        sort: true,
      },
    },
    {
      name: 'type_display',
      label: 'Type',
      options: {
        filter: true,
        filterType: 'textField',
        sort: true,
      },
    },
    {
      name: 'sub_type_display',
      label: 'Sub Type',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'overlays',
      label: 'Thumbnail',
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value, tableMeta) => {
          if (!value) {
            return <>No media attached.</>;
          }
          try {
            return (
              <ThumbnailDisplay
                condition={value.length > 1}
                wrapper={children => (
                  <Badge badgeContent={value.length} color="primary">
                    {children}
                  </Badge>
                )}>
                <StyledLink
                  to={`/findings/${tableMeta.rowData[columns.findIndexByName['id']]}`}
                  value={
                    <img
                      className={classes.thumbnailImage}
                      src={value[0].media.thumbnail || noThumbnail(value[0].media.document_category)}
                    />
                  }
                />
              </ThumbnailDisplay>
            );
          } catch (error) {
            return <>No media attached.</>;
          }
        },
      },
    },

    {
      name: 'labels',
      label: 'Labels',
      options: {
        filter: false,
        sort: true,
        downloadBody: value => getCustomCSVData('labels', value),
        customBodyRender: value => {
          return <ViewDisplayKeyValue value={value} />;
        },
      },
    },
    {
      name: 'priority',
      label: 'Priority',
      options: {
        filter: !!hasFindingsPrioritization,
        download: !!hasFindingsPrioritization,
        viewColumns: !!hasFindingsPrioritization,
        sort: true,
        display: !hasFindingsPrioritization ? 'excluded' : true,
        filterType: 'textField',
      },
    },
    useDateRangeColumnHook({
      name: 'repair_by',
      label: 'Repair By',
      queryParamObj,
      queryStartKey: 'repair_by_after',
      queryEndKey: 'repair_by_before',
      options: {
        filter: !!hasFindingsPrioritization,
        download: !!hasFindingsPrioritization,
        viewColumns: !!hasFindingsPrioritization,
        display: !hasFindingsPrioritization ? 'excluded' : true,
      },
    }),
    useDateRangeColumnHook({
      name: 'next_inspection_date',
      label: 'Next Inspection',
      queryParamObj,
      queryStartKey: 'next_inspection_date_after',
      queryEndKey: 'next_inspection_date_before',
      options: {
        filter: !!hasFindingsPrioritization,
        download: !!hasFindingsPrioritization,
        viewColumns: !!hasFindingsPrioritization,
        display: !hasFindingsPrioritization ? 'excluded' : true,
      },
    }),
    useDateRangeColumnHook({
      name: 'resolved_on',
      label: 'Resolved On',
      queryParamObj,
      queryStartKey: 'resolved_on_date_after',
      queryEndKey: 'resolved_on_date_before',
      options: {
        filter: !!hasFindingsPrioritization,
        download: !!hasFindingsPrioritization,
        viewColumns: !!hasFindingsPrioritization,
        display: !hasFindingsPrioritization ? 'excluded' : true,
      },
    }),
    {
      name: 'state',
      label: 'State',
      options: {
        filter: true,
        sort: true,
        filterType: 'custom',
        filterList: stateFilterList,
        customFilterListOptions: {
          render: values => {
            return values.map(v => displayOption(v, defectStateOptions));
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            setStateFilterList(filterList[index]);
            return filterList;
          },
        },
        customBodyRender: value => {
          return displayOption(value, defectStateOptions);
        },
        filterOptions: {
          names: defectStateOptions.map(option => option.value),
          logic: (state, filters) => {
            if (filters.length) return !filters.includes(state);
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            const formatValue = item => displayOption(item, defectStateOptions);

            return (
              <MultiSelectFilter
                title="State"
                filterList={filterList}
                localFilterList={stateFilterList}
                onChange={onChange}
                index={index}
                column={column}
                filterData={filterData}
                updateFilters={setStateFilterList}
                formatValue={formatValue}
              />
            );
          },
        },
      },
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          if (!value) return <></>;
          return <Description value={value} />;
        },
      },
    },
    {
      name: 'resolution_duration',
      label: 'Resolution Duration',
      options: {
        filter: false,
        sort: true,
      },
    },
    useDateRangeColumnHook({
      name: 'created_on',
      label: 'Created',
      queryParamObj,
      queryStartKey: 'created_on_after',
      queryEndKey: 'created_on_before',
    }),
    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        print: false,
        viewColumns: false,
        customBodyRender: (_value, tableMeta) => {
          const id = tableMeta.rowData[columns.findIndexByName['id']];
          const defect = data.find(d => d.id === id);
          const allowDelete = defect.state === 'PENDING'; // see: #6314
          return (
            <>
              <ButtonGroup>
                {!isReadOnly && (
                  <ButtonIcon
                    history={history}
                    icon={EditIcon}
                    location={location}
                    to={`/findings/${id}/edit`}
                    tooltip="Edit Finding"
                  />
                )}
                {!isReadOnly && (
                  <ButtonIcon
                    disabled={!allowDelete}
                    tooltip={allowDelete ? 'Delete Finding' : `Cannot delete a Finding while it is "${defect.state}"`}
                    icon={DeleteIcon}
                    onClick={() => {
                      dispatch(
                        openDialog(
                          'Delete Finding?',
                          <DeleteDialog id={id} deleteAction={deleteDefects} name={`Finding ID: ${id}`} />
                        )
                      );
                    }}
                  />
                )}
                <ButtonIcon
                  icon={GetAppIcon}
                  onClick={() => {
                    getOverlayFiles(defect.overlays, data[0], columns);
                  }}
                  download
                  tooltip="Download"
                />
              </ButtonGroup>
            </>
          );
        },
      },
    },
  ];

  // handle columns display
  setColumns(columns, views);

  const getDefectFiles = (selectedRows, displayData) => {
    const selected = [];
    const columnNames = columns.map(column => column.name);
    for (let i = 0; i < selectedRows.data.length; i++) {
      const rowData = displayData[selectedRows.data[i].index].data;
      const overlays = rowData[columnNames.indexOf('overlays')];
      if (overlays && overlays.length) {
        overlays.forEach(overlay => {
          const url = overlay.media.file;
          const split = url.split('?')[0].split('/'); // remove the query
          const filename = getOverlayFilename(url, rowData, columns, overlay);
          const project = split[split.length - 2]; // The second to last element is the project
          selected.push({ url, filename, project });
        });
      }
      // This silently fails if there's no file to download.
      // on the standalone findings table, it's possible for a finding to not have media.
      // Not sure if it's better to silently fail or somehow show a message for each file that's not downloaded
    }
    return selected;
  };

  const { tableOptions } = useTableRowSelectionManagerOptions();

  /** @type {TableOptions} */
  const options = {
    // if the column view changes, update redux with either 'add' or 'remove'
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setTableView(changedColumn, action, page, defectsTableViewKey));
    },
    onChangeRowsPerPage: numberOfRows => {
      dispatch(setTableSize(numberOfRows, page, defectsTableViewKey));
    },
    customToolbar: () => {
      if (scopeMode === scopeModes.DEFAULT) {
        return <ScopeToolbar type="FINDINGS" />;
      }
    },
    customToolbarSelect: (selectedRows, displayData) => {
      return (
        <DefectToolBarSelect
          scopeMode={scopeMode}
          selectedRows={selectedRows}
          displayData={displayData}
          getFilesFn={getDefectFiles}
          disableNewProject={disableNewProject}
          columns={columns}
        />
      );
    },
    enableNestedDataAccess: '.',
    searchOpen: !embedded,
    ...tableOptions,
  };

  return (
    <Table
      title={title}
      serverSide
      columns={columns}
      data={data}
      options={options}
      loading={loading}
      queryParamObj={queryParamObj}
      tableChangeHandler={tableChangeHandler}
      embedded={embedded}
      count={count}
      filename={filename}
    />
  );
};

_DefectsTable.defaultProps = {
  title: 'Findings',
  disableNewProject: false,
};

_DefectsTable.propTypes = {
  count: PropTypes.number,
  data: PropTypes.array,
  embedded: PropTypes.bool,
  filename: PropTypes.string,
  isProject: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  loading: PropTypes.bool,
  tableChangeHandler: PropTypes.func,
  title: PropTypes.string,
  page: PropTypes.string,
  views: PropTypes.any,
  disableNewProject: PropTypes.bool, // override feature flag to turn off/hide New Project button on specific table

  // query
  //  (offset/limit/... "standard" queryset)
  queryParamObj: PropTypes.object,
  //  Clean the following before passing
  queryStateFilters: PropTypes.arrayOf(PropTypes.string),
  queryAssetParentInFilters: PropTypes.arrayOf(PropTypes.string),
  queryAssetTypeInFilters: PropTypes.arrayOf(PropTypes.string),
  queryCreatedStartFilter: PropTypes.string,
  queryCreatedEndFilter: PropTypes.string,
  queryRepairByStartFilter: PropTypes.string,
  queryRepairByEndFilter: PropTypes.string,
  queryNextInspectionDateStartFilter: PropTypes.string,
  queryNextInspectionDateEndFilter: PropTypes.string,
  scopeMode: PropTypes.string,
};

/**
 * Standard Defects (Findings) Table
 *
 * @param {*} props - see DefectsTable.propTypes
 *
 * @returns {React.Component}
 */
const DefectsTable = props => {
  const {
    scopeMode,
    queryParamObj,
    tableChangeHandler,
    styleProjectDetail,
    embedded,
    isReadOnly,
    filename,
    isProject,
    page,
    title,
    views,
  } = props;

  const { loading, data, count } = useSelector(state => {
    const loading = state.defects.all.loading;
    const data = state.defects.all.dataAll.results;
    const count = state.defects.all.dataAll.count;

    return {
      loading,
      data,
      count,
    };
  });

  const queryFilters = queryParamObj;
  const queryStateFilters = queryFilters?.state ? [].concat(queryFilters.state.split(',')) : [];
  const queryAssetParentInFilters = queryFilters?.descendants ? [].concat(queryFilters.descendants.split(',')) : [];
  const queryAssetTypeInFilters = queryFilters?.asset_type__in ? [].concat(queryFilters.asset_type__in.split(',')) : [];
  const queryCreatedStartFilter = queryFilters?.created_on_after || undefined;
  const queryCreatedEndFilter = queryFilters?.created_on_before || undefined;
  const queryRepairByStartFilter = queryFilters?.repair_by_after || undefined;
  const queryRepairByEndFilter = queryFilters?.repair_by_before || undefined;
  const queryNextInspectionDateStartFilter = queryFilters?.next_inspection_date_after || undefined;
  const queryNextInspectionDateEndFilter = queryFilters?.next_inspection_date_before || undefined;

  return (
    <div className={styleProjectDetail}>
      <_DefectsTable
        title={title}
        count={count}
        data={data}
        embedded={embedded}
        filename={filename}
        isProject={isProject}
        isReadOnly={isReadOnly}
        loading={loading}
        queryParamObj={queryParamObj}
        queryStateFilters={queryStateFilters}
        queryAssetParentInFilters={queryAssetParentInFilters}
        queryAssetTypeInFilters={queryAssetTypeInFilters}
        queryCreatedStartFilter={queryCreatedStartFilter}
        queryCreatedEndFilter={queryCreatedEndFilter}
        queryRepairByStartFilter={queryRepairByStartFilter}
        queryRepairByEndFilter={queryRepairByEndFilter}
        queryNextInspectionDateStartFilter={queryNextInspectionDateStartFilter}
        queryNextInspectionDateEndFilter={queryNextInspectionDateEndFilter}
        tableChangeHandler={tableChangeHandler}
        views={views}
        page={page}
        scopeMode={scopeMode}
      />
    </div>
  );
};

DefectsTable.defaultProps = {
  queryParamStr: '',
  styleProjectDetail: {},
  isProject: false,
  title: 'Findings',
  embedded: false,
  filename: null,
  views: {},
};

DefectsTable.propTypes = {
  queryParamStr: PropTypes.string,
  styleProjectDetail: PropTypes.string,
  tableChangeHandler: PropTypes.func.isRequired,
  embedded: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isProject: PropTypes.bool,
  filename: PropTypes.string,
  page: PropTypes.string,
  views: PropTypes.object,
  title: PropTypes.string,
  queryParamObj: PropTypes.object,
  scopeMode: PropTypes.string.isRequired,
};

export { _DefectsTable };
export default DefectsTable;
