import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Paper } from '@mui/material';
import { assetsFiltersEndpoint } from '../../../store/apiV2/assets';
import Loading from '../../shared/displays/Loading';
import Error from '../../shared/displays/Error';
import AssetConditionMap from './AssetConditionMap';
import AssetConditionTable from './AssetConditionTable';
import FilterBarAssets from '../FilterBarAssets';
import { getBatchAllAssets } from '../../../store/features/assetsActions';

// constant - to filter out any assets without valid geo points
const FULL_EARTH_BOUNDING_BOX = [-180, -90, 180, 90].join(',');

// context for passing stuff to AssetConditionTable
export const AssetConditionContext = React.createContext(null);

const AssetCondition = () => {
  // -----
  //
  // State
  //

  const dispatch = useDispatch();

  // gets entire asset directory, super light response
  const { loading, error, results, assetFilter, count } = useSelector(state => {
    const { dataAll, loading, error, isBatchLoading } = state.assets.assetConditionMap;
    return {
      results: dataAll.results,
      loading: loading || isBatchLoading,
      error,
      assetFilter: assetsFiltersEndpoint.selector(state).data,
      count: assetsFiltersEndpoint.selector(state).count,
    };
  });

  // set zoom to location to override map
  const [zoomToLocation, setZoomToLocation] = useState(null);

  const filtersInfo = Object.keys(assetFilter)
    .filter(key => !['count', 'loading', 'error', 'updated_on'].includes(key))
    .reduce((result, key) => {
      result[key] = {
        selected: [],
        options: assetFilter[key],
        dependencies: [],
        label: '',
        inFilter: true, // most asset condition filters use __in except labels
      };
      return result;
    }, {});
  filtersInfo['owner'].dependencies = { ...filtersInfo.owner, dependencies: ['parent'] };
  filtersInfo['labels'] = { ...filtersInfo.labels, inFilter: false };

  // ------
  //
  // Effects
  //

  useEffect(() => {
    dispatch(assetsFiltersEndpoint.actionCreators.request());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // ------
  //
  // Render
  //
  //

  if (error) return <Error error={error} />;

  const renderData = () => {
    if (results && results.length === 0) {
      if (loading) {
        return <Loading />;
      }
      return <p>No Data. Please clear your filters.</p>;
    }

    return (
      <>
        <Paper>
          <AssetConditionMap results={results} zoomToLocation={zoomToLocation} setZoomToLocation={setZoomToLocation} />
        </Paper>
        <Paper>
          <AssetConditionTable
            title="Assets"
            loading={loading}
            data={results}
            count={count}
            setZoomToLocation={setZoomToLocation}
          />
        </Paper>
      </>
    );
  };

  return (
    <AssetConditionContext.Provider value={setZoomToLocation}>
      <FilterBarAssets
        totalCount={count}
        filters={assetFilter}
        filtersInfo={filtersInfo}
        defaultQueryParam={{ geo_point__bounding_box: FULL_EARTH_BOUNDING_BOX }}
        onChangeFilters={queries => {
          dispatch(getBatchAllAssets({ ...queries, limit: 1000 })); // returns full list of "light" objects for map
        }}
      />
      {renderData()}
    </AssetConditionContext.Provider>
  );
};

export default AssetCondition;
