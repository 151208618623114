import { requestFactory, responseHandlerForm, responseHandlerFile } from '../base';

const get = requestFactory('get');
// VERY IMPORTANT TODO! need to fix headers and reconfigure.
const post = requestFactory('post', { headers: {}, handler: responseHandlerForm });
const patch = requestFactory('patch', { handler: responseHandlerForm });
const del = requestFactory('del');

export const fetchChecklistTemplatesList = async query => get('/api/checklist-templates/', query);
export const fetchChecklistTemplates = async id => get(`/api/checklist-templates/${id}/`);
export const createChecklistTemplates = async body => post('/api/checklist-templates/', body);
export const importChecklistTemplates = async body =>
  post('/api/checklist-templates/import-template/', body, { formBody: true });
export const updateChecklistTemplates = async (id, body) => patch(`/api/checklist-templates/${id}/`, body);
export const deleteChecklistTemplates = async id => del(`/api/checklist-templates/${id}/`);
export const exportChecklistTemplates = async id =>
  requestFactory('get', { handler: responseHandlerFile })(`/api/checklist-templates/${id}/export-template/`);
export const getChecklistTemplateYAML = async id =>
  requestFactory('get', { handler: responseHandlerFile })(`/api/checklist-templates/${id}/?format=yaml`);
