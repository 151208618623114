export const DIALOG_OPEN = 'DIALOG_OPEN';
export const DIALOG_CLOSE = 'DIALOG_CLOSE';

/**
 * @param {String} title My Dialog
 * @param {Component} body <Component />
 * @param {Object} options (optional) { fullscreen: true }
 */
export const openDialog = (title, body, options = {}) => ({
  type: DIALOG_OPEN,
  title,
  body,
  options,
});

export const closeDialog = () => ({
  type: DIALOG_CLOSE,
});
