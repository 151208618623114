export const INSPECTION_MEDIA_REQUEST = 'INSPECTION_MEDIA_REQUEST';
export const INSPECTION_MEDIA_SUCCESS = 'INSPECTION_MEDIA_SUCCESS';
export const INSPECTION_MEDIA_FAILURE = 'INSPECTION_MEDIA_FAILURE';
export const INSPECTION_MEDIA_CREATE_REQUEST = 'INSPECTION_MEDIA_CREATE_REQUEST';
export const INSPECTION_MEDIA_CREATE_SUCCESS = 'INSPECTION_MEDIA_CREATE_SUCCESS';
export const INSPECTION_MEDIA_CREATE_FAILURE = 'INSPECTION_MEDIA_CREATE_FAILURE';
export const INSPECTION_MEDIA_UPDATE_REQUEST = 'INSPECTION_MEDIA_UPDATE_REQUEST';
export const INSPECTION_MEDIA_UPDATE_SUCCESS = 'INSPECTION_MEDIA_UPDATE_SUCCESS';
export const INSPECTION_MEDIA_UPDATE_FAILURE = 'INSPECTION_MEDIA_UPDATE_FAILURE';
export const INSPECTION_MEDIA_DELETE_REQUEST = 'INSPECTION_MEDIA_DELETE_REQUEST';
export const INSPECTION_MEDIA_DELETE_SUCCESS = 'INSPECTION_MEDIA_DELETE_SUCCESS';
export const INSPECTION_MEDIA_DELETE_FAILURE = 'INSPECTION_MEDIA_DELETE_FAILURE';
export const INSPECTION_MEDIA_BULK_UPDATE_REQUEST = 'INSPECTION_MEDIA_BULK_UPDATE_REQUEST';
export const INSPECTION_MEDIA_BULK_UPDATE_SUCCESS = 'INSPECTION_MEDIA_BULK_UPDATE_SUCCESS';
export const INSPECTION_MEDIA_BULK_UPDATE_FAILURE = 'INSPECTION_MEDIA_BULK_UPDATE_FAILURE';
export const INSPECTION_MEDIA_BULK_DELETE_REQUEST = 'INSPECTION_MEDIA_BULK_DELETE_REQUEST';
export const INSPECTION_MEDIA_BULK_DELETE_SUCCESS = 'INSPECTION_MEDIA_BULK_DELETE_SUCCESS';
export const INSPECTION_MEDIA_BULK_DELETE_FAILURE = 'INSPECTION_MEDIA_BULK_DELETE_FAILURE';
export const INSPECTION_MEDIA_ALL_REQUEST = 'INSPECTION_MEDIA_ALL_REQUEST';
export const INSPECTION_MEDIA_ALL_SUCCESS = 'INSPECTION_MEDIA_ALL_SUCCESS';
export const INSPECTION_MEDIA_ALL_FAILURE = 'INSPECTION_MEDIA_ALL_FAILURE';
export const INSPECTION_MEDIA_OVERLAY_CREATE_REQUEST = 'INSPECTION_MEDIA_OVERLAY_CREATE_REQUEST';
export const INSPECTION_MEDIA_OVERLAY_CREATE_SUCCESS = 'INSPECTION_MEDIA_OVERLAY_CREATE_SUCCESS';
export const INSPECTION_MEDIA_OVERLAY_CREATE_FAILURE = 'INSPECTION_MEDIA_OVERLAY_CREATE_FAILURE';
export const INSPECTION_MEDIA_OVERLAY_UPDATE_REQUEST = 'INSPECTION_MEDIA_OVERLAY_UPDATE_REQUEST';
export const INSPECTION_MEDIA_OVERLAY_UPDATE_SUCCESS = 'INSPECTION_MEDIA_OVERLAY_UPDATE_SUCCESS';
export const INSPECTION_MEDIA_OVERLAY_UPDATE_FAILURE = 'INSPECTION_MEDIA_OVERLAY_UPDATE_FAILURE';
export const INSPECTION_MEDIA_OVERLAY_DELETE_REQUEST = 'INSPECTION_MEDIA_OVERLAY_DELETE_REQUEST';
export const INSPECTION_MEDIA_OVERLAY_DELETE_SUCCESS = 'INSPECTION_MEDIA_OVERLAY_DELETE_SUCCESS';
export const INSPECTION_MEDIA_OVERLAY_DELETE_FAILURE = 'INSPECTION_MEDIA_OVERLAY_DELETE_FAILURE';
export const INSPECTION_MEDIA_BATCH_SUCCESS = 'INSPECTION_MEDIA_BATCH_SUCCESS';
export const INSPECTION_MEDIA_BATCH_ALL_REQUEST = 'INSPECTION_MEDIA_BATCH_ALL_REQUEST';
export const INSPECTION_MEDIA_ALL_UPDATE_DATA = 'INSPECTION_MEDIA_ALL_UPDATE_DATA';
export const INSPECTION_MEDIA_BATCH_ALL_SUCCESS = 'INSPECTION_MEDIA_BATCH_ALL_SUCCESS';
export const INSPECTION_MEDIA_ABORT_BATCH_ALL = 'INSPECTION_MEDIA_ABORT_BATCH_ALL';

export const getInspectionMedia = id => {
  return {
    type: INSPECTION_MEDIA_REQUEST,
    id,
  };
};

export const createInspectionMedia = (body, options = {}) => {
  return {
    type: INSPECTION_MEDIA_CREATE_REQUEST,
    body,
    options,
  };
};

export const updateInspectionMedia = (id, body, nextRoute, options = {}) => {
  return {
    type: INSPECTION_MEDIA_UPDATE_REQUEST,
    id,
    body,
    nextRoute,
    options,
  };
};

export const deleteInspectionMedia = id => {
  return {
    type: INSPECTION_MEDIA_DELETE_REQUEST,
    id,
  };
};

export const updateInspectionMediaBulk = (ids, body, nextRoute) => {
  return {
    type: INSPECTION_MEDIA_BULK_UPDATE_REQUEST,
    ids,
    body,
    nextRoute,
  };
};

export const deleteInspectionMediaBulk = ids => {
  return {
    type: INSPECTION_MEDIA_BULK_DELETE_REQUEST,
    ids,
  };
};

export const createInspectionMediaOverlay = (mediaId, body) => {
  return {
    type: INSPECTION_MEDIA_OVERLAY_CREATE_REQUEST,
    id: mediaId,
    body,
  };
};

export const updateInspectionMediaOverlay = (id, body) => {
  return {
    type: INSPECTION_MEDIA_OVERLAY_UPDATE_REQUEST,
    id,
    body,
  };
};

export const deleteInspectionMediaOverlay = id => {
  return {
    type: INSPECTION_MEDIA_OVERLAY_DELETE_REQUEST,
    id,
  };
};

export const getAllInspectionMedia = query => {
  return {
    type: INSPECTION_MEDIA_ALL_REQUEST,
    query,
  };
};

export const getBatchAllInspectionMedia = query => {
  return {
    type: INSPECTION_MEDIA_BATCH_ALL_REQUEST,
    query,
  };
};
