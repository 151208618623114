// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import { combineReducers } from 'redux';
import {
  LIBRARY_MEDIA_REQUEST,
  LIBRARY_MEDIA_SUCCESS,
  LIBRARY_MEDIA_FAILURE,
  LIBRARY_MEDIA_CREATE_REQUEST,
  LIBRARY_MEDIA_CREATE_SUCCESS,
  LIBRARY_MEDIA_CREATE_FAILURE,
  LIBRARY_MEDIA_UPDATE_REQUEST,
  LIBRARY_MEDIA_UPDATE_SUCCESS,
  LIBRARY_MEDIA_UPDATE_FAILURE,
  LIBRARY_MEDIA_DELETE_REQUEST,
  LIBRARY_MEDIA_DELETE_SUCCESS,
  LIBRARY_MEDIA_DELETE_FAILURE,
  LIBRARY_MEDIA_ALL_REQUEST,
  LIBRARY_MEDIA_ALL_SUCCESS,
  LIBRARY_MEDIA_ALL_FAILURE,
} from './libraryMediaActions';

export const initialStateLibraryMedia = {
  loading: false,
  error: '',
  formError: {},
  id: 0,
  body: {},
  data: {},
};

export const initialStateAllLibraryMedia = {
  loading: false,
  error: '',
  query: {},
  dataAll: { count: 0, next: null, previous: null, results: [] },
};

export const libraryMediaReducer = (state = initialStateLibraryMedia, action) => {
  switch (action.type) {
    case LIBRARY_MEDIA_REQUEST:
      return {
        ...initialStateLibraryMedia,
        loading: true,
        id: action.id,
      };
    case LIBRARY_MEDIA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case LIBRARY_MEDIA_FAILURE:
      return {
        ...initialStateLibraryMedia,
        error: action.error,
      };
    case LIBRARY_MEDIA_CREATE_REQUEST:
      return {
        ...initialStateLibraryMedia,
        loading: true,
        body: action.body,
      };
    case LIBRARY_MEDIA_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case LIBRARY_MEDIA_CREATE_FAILURE:
      return {
        ...initialStateLibraryMedia,
        error: action.error,
        formError: action.formError,
      };
    case LIBRARY_MEDIA_UPDATE_REQUEST:
      return {
        ...state,
        error: '',
        formError: {},
        loading: true,
        id: action.id,
        body: action.body,
      };
    case LIBRARY_MEDIA_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case LIBRARY_MEDIA_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case LIBRARY_MEDIA_DELETE_REQUEST:
      return {
        ...state,
        error: '',
        formError: {},
        deleting: true,
        id: action.id,
        query: action.query,
      };
    case LIBRARY_MEDIA_DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
      };
    case LIBRARY_MEDIA_DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        error: action.error,
        formError: action.formError,
      };
    default:
      return state;
  }
};

export const libraryMediaAllReducer = (state = initialStateAllLibraryMedia, action) => {
  switch (action.type) {
    case LIBRARY_MEDIA_ALL_REQUEST:
      return {
        ...initialStateAllLibraryMedia,
        loading: true,
        query: action.query,
      };
    case LIBRARY_MEDIA_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        dataAll: action.data,
      };
    case LIBRARY_MEDIA_ALL_FAILURE:
      return {
        ...initialStateAllLibraryMedia,
        error: action.error,
      };
    default:
      return state;
  }
};

export default combineReducers({
  each: libraryMediaReducer,
  all: libraryMediaAllReducer,
});
