import variables from '../../config.module.scss';

const MuiButton = darkMode => ({
  styleOverrides: {
    root: {
      textTransform: 'none',
    },
    contained: {
      borderWidth: '1px',
      borderStyle: 'solid',
    },
    containedPrimary: {
      borderColor: variables.primary,
      color: variables.white,
      backgroundColor: variables.primary,
      '&:hover': {
        backgroundColor: variables.primaryHover,
      },
      '&:disabled': {
        borderColor: darkMode ? variables.primaryDisabledDark : variables.primaryDisabledLight,
        backgroundColor: darkMode ? variables.primaryDisabledDark : variables.primaryDisabledLight,
      },
    },
    containedSecondary: {
      border: darkMode ? variables.secondaryDark : variables.secondaryBorder,
      backgroundColor: darkMode ? variables.secondaryDark : variables.secondaryLight,
      color: darkMode ? variables.textSecondaryDark : variables.textSecondaryLight,
      '&:hover': {
        border: darkMode ? variables.secondaryHoverDark : variables.secondaryHoverLight,
        backgroundColor: darkMode ? variables.secondaryHoverDark : variables.secondaryHoverLight,
      },
      '&:disabled': {
        border: darkMode ? variables.secondaryDisabledDark : variables.secondaryDisabledLight,
        backgroundColor: darkMode ? variables.secondaryDisabledDark : variables.secondaryDisabledLight,
      },
    },
    text: {
      boxShadow: 'none',
      height: '45px',
    },
    textPrimary: {
      color: darkMode ? variables.orange : variables.textPrimaryLight,
    },
    textSecondary: {
      color: darkMode ? variables.textSecondaryDark : variables.textSecondaryLight,
    },
    outlinedPrimary: {
      color: darkMode ? variables.textSecondaryDark : variables.textSecondaryLight,
      borderColor: darkMode ? variables.secondaryDark : variables.secondaryBorder,
      '&:hover': {
        backgroundColor: variables.secondaryHover,
      },
      '&:disabled': {
        border: darkMode ? variables.secondaryDisabledDark : variables.secondaryDisabledLight,
      },
    },
    outlinedSecondary: {
      color: darkMode ? variables.textSecondaryDark : variables.textSecondaryLight,
      borderColor: darkMode ? variables.secondaryDark : variables.secondaryBorder,
      '&:hover': {
        backgroundColor: variables.secondaryHover,
      },
      '&:disabled': {
        border: darkMode ? variables.secondaryDisabledDark : variables.secondaryDisabledLight,
      },
    },
  },
});

const MuiToggleButton = {
  styleOverrides: {
    root: {
      height: '30px',
      // To change the background individually, I had to look for the class Mui-selected and background-<color>
      '&.Mui-selected': {
        color: variables.darkGrey,
        '&.background-blue': {
          backgroundColor: variables.blue15,
        },
        '&.background-green': {
          backgroundColor: variables.green15,
        },
        '&.background-red': {
          backgroundColor: variables.red15,
        },
      },
    },
  },
};

const MuiIconButton = darkMode => ({
  styleOverrides: {
    root: {
      color: darkMode ? variables.textPrimaryDark : variables.textPrimaryLight,
    },
  },
});

const buttons = darkMode => ({
  MuiButton: MuiButton(darkMode),
  MuiToggleButton,
  MuiIconButton: MuiIconButton(darkMode),
});

export default buttons;
