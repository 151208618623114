// Taken from https://github.com/firebase/firebaseui-web-react/pull/173#issuecomment-1151532176
import React, { useEffect, useRef, useState } from 'react';
import * as firebaseui from 'firebaseui';
import 'firebase/compat/auth';
import 'firebaseui/dist/firebaseui.css';
import PropTypes from 'prop-types';

const StyledFirebaseAuth = ({ uiConfig, firebaseAuth, className, uiCallback }) => {
  const [userSignedIn, setUserSignedIn] = useState(false);
  const elementRef = useRef(null);
  useEffect(() => {
    if (!elementRef.current) return;

    let unregisterAuthObserver;
    let firebaseUiWidget;
    // firebase ui start in event loop to solve react 18 strict requirement.
    const timeout = setTimeout(() => {
      // Get or Create a firebaseUI instance.
      firebaseUiWidget = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebaseAuth);
      if (uiConfig.signInFlow === 'popup') firebaseUiWidget.reset();

      // We track the auth state to reset firebaseUi if the user signs out.
      unregisterAuthObserver = firebaseAuth.onAuthStateChanged(user => {
        if (!user && userSignedIn) firebaseUiWidget.reset();
        setUserSignedIn(!!user);
      });

      // Trigger the callback if any was set.
      if (uiCallback) uiCallback(firebaseUiWidget);

      // Render the firebaseUi Widget.
      firebaseUiWidget.start(elementRef.current, uiConfig);
    });

    return () => {
      clearTimeout(timeout);
      if (unregisterAuthObserver) unregisterAuthObserver();
      if (firebaseUiWidget) firebaseUiWidget.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uiConfig]);
  return <div className={className} ref={elementRef} data-testid="firebaseauth-container" />;
};

StyledFirebaseAuth.propTypes = {
  /** https://firebase.google.com/docs/auth/web/firebaseui */
  uiConfig: PropTypes.object.isRequired,
  uiCallback: PropTypes.func,
  firebaseAuth: PropTypes.any,
  className: PropTypes.string,
};

export default StyledFirebaseAuth;
