import React from 'react';
import PropTypes from 'prop-types';

import GeoPoint from './GeoPoint';
import StyledLink from '../StyledLink';
import { useFeatureFlags } from '../../../hooks/settingsHooks';

/**
 * Component to display a geo point. Can optionally link to an external map website.
 */
const GeoPointDisplay = ({ geoPoint }) => {
  const { hasMapLinkGoogle } = useFeatureFlags();

  if (!geoPoint) return null;
  // instantiate new GeoPoint if input is an object with coordinates (i.e. a GeoJSON object)
  if (!(geoPoint instanceof GeoPoint)) geoPoint = new GeoPoint(geoPoint?.coordinates);

  // user-friendly display with 6 decimal places
  const displayCoordinates = geoPoint.toShortString();
  if (hasMapLinkGoogle) {
    // see https://developers.google.com/maps/documentation/urls/get-started#search-action
    const query = `${geoPoint.lat}%2C${geoPoint.lon}`; // url-encoded comma
    const url = `https://www.google.com/maps/search/?api=1&query=${query}`;
    return <StyledLink to={url} value={displayCoordinates} newTab />;
  } else {
    return displayCoordinates;
  }
};

GeoPointDisplay.propTypes = {
  geoPoint: PropTypes.oneOfType([
    PropTypes.instanceOf(GeoPoint),
    PropTypes.shape({
      coordinates: PropTypes.string.isRequired,
    }),
    PropTypes.oneOf([null]),
  ]),
};

GeoPointDisplay.defaultProps = {};

export default GeoPointDisplay;
