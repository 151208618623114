import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import arrayMutators from 'final-form-arrays';
import { jsonKeyToLabel, apiDateToString, dateToApiDateTime, queriesFromString } from '../../utilities/strings';
import { getCmls, createCmls, updateCmls } from '../../store/features/cmlsActions';
import { cmlTypeOptions } from '../../api/features/constants';
import CommonForm from '../shared/form/Common';
import GeoPoint from '../shared/form/GeoPoint';
import { fieldOrder, removeField, hideField } from './cmlsShared';
import { getAllAssets } from '../../store/features/assetsActions';
import { getLabel } from '../../utilities/objects';
import { useFeatureFlags } from '../../hooks/settingsHooks';

const CmlsForm = props => {
  const { update } = props;
  const params = useParams();
  const { data, loading, error, formError } = useSelector(state => state.cmls.each);
  const { assetsOptions } = useSelector(state => {
    const { results: assets } = state.assets.all.dataAll;
    const assetsOptions = assets
      ? assets.map(result => {
          const { asset_path, id, type } = result; // eslint-disable-line camelcase
          const label = asset_path != null ? asset_path : ''; // eslint-disable-line camelcase
          const value = id.toString();
          return { label, value, type };
        })
      : [];

    return {
      loading: state.assets.all.loading,
      error,
      formError,
      assetsOptions,
    };
  });
  const [submittedValues, setSubmittedValues] = useState({});
  const dispatch = useDispatch();
  const [asset, setAsset] = useState();

  const initialValueSetter = type => {
    if (asset !== null) {
      if (type === 'label') {
        return getLabel(asset, assetsOptions);
      } else {
        return asset;
      }
    } else {
      return '';
    }
  };

  useEffect(() => {
    let queries = {};
    if (location.search) {
      queries = queriesFromString(location.search);
      if (queries.asset_id) {
        setAsset(queries.asset_id);
      }
    }
    if (update && params.id) {
      dispatch(getCmls(params.id));
    } else {
      typeof queries.asset_id !== 'undefined'
        ? dispatch(getAllAssets({ asset_id: queries.asset_id, is_active: true }))
        : dispatch(getAllAssets({ is_active: true }));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { hasAccessDisplay } = useFeatureFlags();
  const formSettings = {
    fieldOrder,
    removeField: removeField(update ? 'UPDATE' : 'CREATE'),
    hideField: (values, item) => {
      return hideField(values, item, hasAccessDisplay);
    },
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      switch (key) {
        /* ---------- Required Fields ---------- */
        case 'name':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: 'Name',
              name: 'name',
              required: true,
              placeholder: 'CML-001',
              helperText: 'Name must be unique for the assigned asset.',
            },
          };
          break;
        case 'asset':
          fieldSettings[key] = {
            type: 'asset-autocomplete',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              required: true,
              labelwidth: 42,
              valueInitial: { label: initialValueSetter('label', asset), value: initialValueSetter('value', asset) },
            },
          };
          break;
        case 'type':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              required: false,
              options: cmlTypeOptions,
              labelwidth: 40,
            },
          };
          break;
        /* ---------- Regular Fields ---------- */
        case 'geo_point':
          fieldSettings[key] = {
            type: 'geo',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              helperText: 'Latitude and Longitude of the CML',
            },
          };
          break;
        case 'labels_by_key':
          fieldSettings[key] = {
            type: 'key-value',
            fieldProps: {
              label: 'Labels',
              name: 'labels',
            },
          };
          break;
        case 'component':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: 'Component',
              name: 'component',
              helperText: 'Feature the CML is monitoring.',
              placeholder: 'Elbow',
            },
          };
          break;
        case 'access':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: 'Access',
              name: 'access',
              helperText: 'Access requirement (Man Lift, Ladder, or Scaffold) for a location.',
            },
          };
          break;
        case 'location_code':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: 'Location',
              name: 'location_code',
              helperText: 'Information to help find the CML.',
            },
          };
          break;
        case 'is_active':
          fieldSettings[key] = {
            type: 'checkbox',
            cellProps: {
              xs: 6,
            },
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          break;

        case 'installed_on':
          fieldSettings[key] = {
            type: 'date',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          break;
        /* ----------  Disabled Fields ---------- */
        case 'created_on':
        case 'updated_on':
          fieldSettings[key] = {
            type: 'display',
            fieldProps: {
              disabled: true,
              label: jsonKeyToLabel(key),
              name: key,
              value: apiDateToString(data[key], 'date'),
            },
          };
          break;
        /* ----------  Default ---------- */
        default:
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
      }
    });
  }

  const title = update ? 'Edit CML' : 'New CML';

  let initialValues = { labels: [] };
  if (update) {
    // The form cannot handle data coming in as objects.  (see ProjectsForm as an example)
    initialValues = {
      ...data,
      geo_point: data.geo_point ? new GeoPoint(data.geo_point).toString() : undefined,
      labels: Array.isArray(data.labels) ? data.labels : [],
      labels_by_key: data.labels_by_key ? Object.values(data.labels_by_key) : [],
    };
  } else {
    // assign values from url queries here
    initialValues = {
      asset: queriesFromString(location.search).asset_id,
    };
  }

  const onSubmit = changedValues => {
    const assetIdString = update ? data?.asset?.id : (changedValues?.asset ?? '');
    const route = location.state && location.state.back ? location.state.back : `/assets/${assetIdString}`;
    const updatedValues = { ...changedValues };
    if ('installed_on' in updatedValues) {
      updatedValues.installed_on = dateToApiDateTime(updatedValues.installed_on);
    }

    if ('geo_point' in updatedValues) updatedValues['geo_point'] = new GeoPoint(updatedValues['geo_point']).toGeoJSON();
    setSubmittedValues(updatedValues);
    if (update) {
      dispatch(updateCmls(updatedValues.id, updatedValues, route));
    } else {
      dispatch(createCmls(updatedValues, route));
    }
  };

  /**
   * Decorators are used for setting the values of other fields based off of a field.
   * Decorator format can be found here: src/components/shared/form/common.js
   */
  const decorators = [];

  /**
   *  Validations that are per field.
   *  Errors associated with the form are passed back through formError in Redux.
   */
  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        if (values[key] === submittedValues[key]) {
          errors[key] = value;
        } else {
          errors[key] = undefined;
        }
      }
    }
    return errors;
  };

  return (
    <CommonForm
      update={update}
      title={title}
      initialValues={initialValues}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      onSubmit={onSubmit}
      decorators={decorators}
      mutators={{ ...arrayMutators }}
      validate={validate}
      loading={loading}
      error={error}
      keepDirtyOnReinitialize
    />
  );
};

CmlsForm.defaultProps = {
  update: false,
};

CmlsForm.propTypes = {
  update: PropTypes.bool,
};

export default CmlsForm;
