import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import variables from '../../../config.module.scss';

/**
 * Error component for displaying errors.
 */
const useStyles = makeStyles(theme => ({
  default: {
    color: variables.red,
    fontSize: theme.spacing(2),
  },
}));

// For some reason when the project is built and this code is minified,
// this component is rendered with error as something other than a string.
// If error is not a string, the project will throw a React Error #31,
// 'Objects are not valid as a React child (found: object with keys {}).'
// That is why we check that error is of type string.
const getErrorMessage = (error, message) => {
  if (error && typeof error === 'string') {
    // TODO: use error constants
    if (error.includes('404')) {
      return 'The data you are trying to access is missing or you do not have permissions to view.  (Error: 404)';
    }
    return error;
  }
  if (message && typeof message === 'string') {
    return message;
  }
  return '';
};

const Error = props => {
  const { error, message, style } = props;
  const classes = useStyles();

  const msg = getErrorMessage(error, message);

  if (msg) {
    return (
      <div data-testid="error-message" className={classes.default} style={{ ...style }}>
        {getErrorMessage(error, message)}
      </div>
    );
  }
  return null;
};

Error.defaultProps = {
  error: '',
  message: '',
  style: {},
};

Error.propTypes = {
  /** error message */
  error: PropTypes.string,
  /** message */
  message: PropTypes.string,
  /** style override */
  style: PropTypes.object,
};

export default Error;
