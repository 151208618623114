import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Tabs, Tab, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AssetCondition from './assetCondition/AssetCondition';
import InspectionMedia from './inspectionMedia/InspectionMedia';
import { usePermissions, useFeatureFlags } from '../../hooks/settingsHooks';
import TabPanel from '../shared/TabPanel';

const useStyles = makeStyles(theme => {
  return {
    root: {
      padding: theme.spacing(3),
    },
    tabStyles: {
      borderRadius: '3px',
    },
    divider: {
      margin: '0',
    },
  };
});

const Analytics = () => {
  const selectedTab = useParams().tab;

  const { hasAssetCondition, hasInspectionMediaMap } = useFeatureFlags();
  const { hasDashboardView, hasDashboardViewAll } = usePermissions();

  const showAssetCondition = hasAssetCondition && (hasDashboardView || hasDashboardViewAll);
  const showInspectionMediaMap = hasInspectionMediaMap && (hasDashboardView || hasDashboardViewAll);

  const classes = useStyles();
  const history = useHistory();
  const tabs = [
    { value: 'asset-condition', label: 'Asset Condition', Component: <AssetCondition />, show: showAssetCondition },
    {
      value: 'inspection-media',
      label: 'Inspection Media',
      Component: <InspectionMedia />,
      show: showInspectionMediaMap,
    },
  ];

  let readyToRender = false;

  const handleTabChange = (event, value) => {
    history.push('/analytics/' + value);
  };

  switch (selectedTab) {
    case 'asset-condition':
    case 'inspection-media':
      readyToRender = true;
      break;
    default:
      history.replace('/analytics/asset-condition');
  }

  // the Tabs component doesn't handle value=undefined
  if (!readyToRender) return <></>;

  return (
    <div className={classes.root}>
      <Tabs className={classes.tabStyles} value={selectedTab} onChange={handleTabChange}>
        {tabs
          .filter(tab => tab.show)
          .map(tab => (
            <Tab
              id={`asset-tab-${tab.value}`}
              aria-controls={`asset-tabpanel-${tab.value}`}
              key={tab.value}
              label={tab.label}
              value={tab.value}
            />
          ))}
      </Tabs>
      <Divider className={classes.divider} />
      {tabs.map(tab => (
        <TabPanel key={tab.value} value={tab.value} selectedTab={selectedTab} name="asset">
          {tab.Component}
        </TabPanel>
      ))}
    </div>
  );
};

export default Analytics;
