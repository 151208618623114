// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import { combineReducers } from 'redux';
import {
  CHECKLIST_TASKS_REQUEST,
  CHECKLIST_TASKS_SUCCESS,
  CHECKLIST_TASKS_FAILURE,
  CHECKLIST_TASKS_CREATE_REQUEST,
  CHECKLIST_TASKS_CREATE_SUCCESS,
  CHECKLIST_TASKS_CREATE_FAILURE,
  CHECKLIST_TASKS_UPDATE_REQUEST,
  CHECKLIST_TASKS_UPDATE_SUCCESS,
  CHECKLIST_TASKS_UPDATE_FAILURE,
  CHECKLIST_TASKS_DELETE_REQUEST,
  CHECKLIST_TASKS_DELETE_SUCCESS,
  CHECKLIST_TASKS_DELETE_FAILURE,
  CHECKLIST_TASKS_ALL_REQUEST,
  CHECKLIST_TASKS_ALL_SUCCESS,
  CHECKLIST_TASKS_ALL_FAILURE,
} from './checklistTasksActions';
import { addToArray } from '../../utilities/arrays';

export const initialStateChecklistTasks = {
  loading: false,
  error: '',
  formError: {},
  id: 0,
  body: {},
  data: {},
};

export const initialStateAllChecklistTasks = {
  loading: false,
  error: '',
  query: {},
  dataAll: { count: 0, next: null, previous: null, results: [] },
};

export const resetStateChecklistTasks = {
  loading: false,
  error: '',
  formError: {},
  data: {},
};
export const resetStateAllChecklistTasks = {
  loading: false,
  error: '',
  query: {},
  dataAll: [],
};

export const checklistTasksEachReducer = (state = initialStateChecklistTasks, action) => {
  switch (action.type) {
    case CHECKLIST_TASKS_REQUEST:
      return {
        ...initialStateChecklistTasks,
        loading: true,
        id: action.id,
      };
    case CHECKLIST_TASKS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case CHECKLIST_TASKS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case CHECKLIST_TASKS_CREATE_REQUEST:
      return {
        ...initialStateChecklistTasks,
        loading: true,
        body: action.body,
      };
    case CHECKLIST_TASKS_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case CHECKLIST_TASKS_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case CHECKLIST_TASKS_UPDATE_REQUEST:
      return {
        ...initialStateChecklistTasks,
        loading: true,
        id: action.id,
        body: action.body,
      };
    case CHECKLIST_TASKS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case CHECKLIST_TASKS_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case CHECKLIST_TASKS_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        formError: {},
        id: action.id,
      };
    case CHECKLIST_TASKS_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CHECKLIST_TASKS_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    default:
      return state;
  }
};

export const checklistTasksAllReducer = (state = initialStateAllChecklistTasks, action) => {
  switch (action.type) {
    case CHECKLIST_TASKS_ALL_REQUEST:
      return {
        ...initialStateAllChecklistTasks,
        loading: true,
        query: action.query,
      };
    case CHECKLIST_TASKS_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        dataAll: action.data,
      };
    case CHECKLIST_TASKS_ALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case CHECKLIST_TASKS_UPDATE_SUCCESS:
      return {
        ...state,
        dataAll: {
          ...state.dataAll,
          results: addToArray(state.dataAll.results, action.data),
        },
      };
    case CHECKLIST_TASKS_CREATE_SUCCESS:
      return {
        ...state,
        dataAll: {
          ...state.dataAll,
          count: state.dataAll.count + 1,
          results: state.dataAll.results.concat([action.data]),
        },
      };
    default:
      return state;
  }
};

export default combineReducers({
  each: checklistTasksEachReducer,
  all: checklistTasksAllReducer,
});
