import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CheckIcon from '@mui/icons-material/Check';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import { closeDialog } from '../../../store/dialogActions';

const useStyles = makeStyles(theme => ({
  buttons: {
    padding: theme.spacing(1, 0),
  },
}));

const FormDialog = props => {
  const { confirmAction } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const handleCloseDialog = () => {
    dispatch(closeDialog());
  };

  const handleConfirm = () => {
    confirmAction();
    handleCloseDialog();
  };
  return (
    <Grid container direction="column" justifyContent="space-between">
      <Grid item>{props.children}</Grid>
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          className={classes.buttons}>
          <Grid item xs={2}>
            <SecondaryButton label="Cancel" onClick={handleCloseDialog} />
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}>
            <PrimaryButton icon={true} Icon={CheckIcon} onClick={handleConfirm} label="Confirm" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

FormDialog.defaultProps = {
  children: <></>,
};

FormDialog.propTypes = {
  children: PropTypes.node,
  confirmAction: PropTypes.func.isRequired,
};

export default FormDialog;
