import { all, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  CMLS_REQUEST,
  CMLS_SUCCESS,
  CMLS_FAILURE,
  CMLS_CREATE_REQUEST,
  CMLS_CREATE_SUCCESS,
  CMLS_CREATE_FAILURE,
  CMLS_UPDATE_REQUEST,
  CMLS_UPDATE_SUCCESS,
  CMLS_UPDATE_FAILURE,
  CMLS_DELETE_REQUEST,
  CMLS_DELETE_SUCCESS,
  CMLS_DELETE_FAILURE,
  CMLS_ALL_REQUEST,
  CMLS_ALL_SUCCESS,
  CMLS_ALL_FAILURE,
} from './cmlsActions';

import { fetchCmls, createCmls, updateCmls, deleteCmls, fetchCmlsList } from '../../api/features/cmls';
import { history } from '../../providers/StoreProvider';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';
import { DIALOG_CLOSE } from '../../store/dialogActions';
import { pathToPage } from '../../utilities/strings';
import { tableViews } from '../../utilities/tables';
import { SET_TABLE_QUERY } from '../../store/settings/tableActions';

const { cmlsTableViewKey: tableType } = tableViews;

export function* cmlsFetch(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield fetchCmls(id);
    if (data) {
      yield put({ type: CMLS_SUCCESS, data });
    } else if (error) {
      yield put({ type: CMLS_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, CMLS_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, CMLS_FAILURE);
  }
}

export function* watchCmlsFetch() {
  yield takeLatest(CMLS_REQUEST, cmlsFetch);
}

export function* cmlsCreate(action) {
  const { body, nextRoute } = action;
  try {
    const { data, error, formError, response } = yield createCmls(body);
    if (data) {
      yield put({ type: CMLS_CREATE_SUCCESS, data });
      if (nextRoute) {
        yield put(push(nextRoute));
      }
    } else if (error) {
      yield put({ type: CMLS_CREATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: CMLS_CREATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, CMLS_CREATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, CMLS_CREATE_FAILURE);
  }
}

export function* watchCmlsCreate() {
  yield takeLatest(CMLS_CREATE_REQUEST, cmlsCreate);
}

export function* cmlsUpdate(action) {
  const { id, body, nextRoute } = action;
  try {
    const { data, error, formError, response } = yield updateCmls(id, body);
    if (data) {
      yield put({ type: CMLS_UPDATE_SUCCESS, data });
      if (nextRoute) {
        yield put(push(nextRoute));
      }
    } else if (error) {
      yield put({ type: CMLS_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: CMLS_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, CMLS_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, CMLS_UPDATE_FAILURE);
  }
}

export function* watchCmlsUpdate() {
  yield takeLatest(CMLS_UPDATE_REQUEST, cmlsUpdate);
}

export function* cmlsDelete(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield deleteCmls(id);
    if (data) {
      yield put({ type: CMLS_DELETE_SUCCESS });
      const query = yield select(state => state.cmls.all.query) || '';
      yield put({ type: CMLS_ALL_REQUEST, query });
      yield put({ type: DIALOG_CLOSE });
    } else if (error) {
      yield put({ type: CMLS_DELETE_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, CMLS_DELETE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, CMLS_DELETE_FAILURE);
  }
}

export function* watchCmlsDelete() {
  yield takeLatest(CMLS_DELETE_REQUEST, cmlsDelete);
}

export function* cmlsFetchAll(action) {
  const { query } = action; // pageInfo
  const location = history.location;
  try {
    const { data, error, response } = yield fetchCmlsList(action.query);
    if (data) {
      yield put({ type: CMLS_ALL_SUCCESS, data });
    } else if (error) {
      yield put({ type: CMLS_ALL_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, CMLS_ALL_FAILURE);
    }
    // save the query in the store
    if (pathToPage(location.pathname)) {
      const page = pathToPage(location.pathname);
      yield put({ type: SET_TABLE_QUERY, page, query, tableType });
    }
  } catch (error) {
    yield defaultErrorCatch(error, CMLS_ALL_FAILURE);
  }
}

export function* watchCmlsFetchAll() {
  yield takeLatest(CMLS_ALL_REQUEST, cmlsFetchAll);
}

export default function* cmlsSaga() {
  yield all([watchCmlsFetch(), watchCmlsCreate(), watchCmlsUpdate(), watchCmlsFetchAll(), watchCmlsDelete()]);
}
