import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Table from '../../shared/table/Table';
import MultiSelectFilter from '../../shared/table/MultiSelectFilter';
import AssetConditionDisplay from './AssetConditionDisplay';
import { setAssetConditionTableView } from '../../../store/settings/views/assetConditionTableViewRedux';
import { iconUrlLookup, makeAssetConditionsMap } from '../../../utilities/assetCondition';
import StyledLink from '../../shared/StyledLink';
import { setColumns } from '../../shared/table/columns';
import { getCustomCSVData, tableViews } from '../../../utilities/tables';
import { useTableViews } from '../../../hooks/settingsHooks';

/** @typedef {import('../../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../../shared/table/types').Column} Column */

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

/**
 *
 *  Super Light Table
 *    Used in conjunction with the Map View
 *  (loads the ENTIRE asset directory, so many columns are excluded)
 *
 *  See serializer used: https://github.com/huvrdata/huvr/blob/8373dba78f5332c96ef401356249ffc7b8ed56a4/huvr_api/huvr_api/assets/serializers.py#L311-L322
 *
 */
const AssetConditionTable = props => {
  const { title, data, count, loading, setZoomToLocation } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { assetConditionTableViewKey } = tableViews;
  const views = useTableViews(assetConditionTableViewKey);
  const assetConditions = useSelector(state => state.settings.features.assetConditions);
  const assetConditionsMap = makeAssetConditionsMap(assetConditions);
  const [selectedAssetConditionFilter, setSelectedAssetConditionFilter] = useState([]);

  /** @type {Column[]} */
  const columns = [
    {
      name: 'id',
      label: 'Id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'zoom_to_location',
      label: 'Find',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const assetCondition = tableMeta.rowData[columns.findIndexByName['asset_condition']];
          // use `geo_point`, not `location`
          //  https://github.com/huvrdata/huvr/pull/5402#issuecomment-1324006133
          const point = tableMeta.rowData[columns.findIndexByName['geo_point']];

          if (!point?.coordinates) {
            console.warn('Unexpected type for geo_point', { point });
            return;
          }

          return (
            <button onClick={() => setZoomToLocation(point.coordinates)}>
              <img
                src={iconUrlLookup(assetCondition, assetConditions)}
                style={{ verticalAlign: 'middle', paddingRight: '8px' }}
                height="30"
              />
            </button>
          );
        },
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return <StyledLink to={`/assets/${tableMeta.rowData[columns.findIndexByName['id']]}`} value={value} />;
        },
      },
    },
    {
      name: 'type',
      label: 'Asset Type',
      options: {
        filter: true,
        sort: true,
        downloadBody: value => getCustomCSVData('simple', value),
        customBodyRender: value => {
          if (!value) return '';
          return value.name;
        },
      },
    },
    {
      name: 'parent',
      label: 'Parent',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          if (!value) {
            return <></>;
          }
          return <StyledLink to={`/assets/${value.id}`} value={value.name} />;
        },
      },
    },
    {
      name: 'owner',
      label: 'Owner',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          if (!value) {
            return <></>;
          }
          return <StyledLink to={`/companies/${value.id}`} value={value.name} />;
        },
      },
    },
    {
      name: 'geo_point',
      label: 'Long/Lat',
      options: {
        filter: false,
        sort: false,
        display: 'excluded', // possibly different from `location`, (as location is address), but I think probably not valuable
      },
    },
    {
      name: 'location',
      label: 'Location',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'serial_number',
      label: 'Serial Number',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'age',
      label: 'Age',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'asset_condition',
      label: 'Asset Condition',
      options: {
        filter: true,
        filterType: 'custom',
        filterList: selectedAssetConditionFilter,
        // chips functionality
        customFilterListOptions: {
          render: values => {
            return values.map(v => {
              return assetConditionsMap[v];
            });
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            setSelectedAssetConditionFilter(filterList[index]);
            return filterList;
          },
        },
        // filtering menu
        filterOptions: {
          render: values => {
            return assetConditionsMap[values];
          },
          logic: (selectedAssetCondition, filters) => {
            if (filters.length) return !filters.includes(selectedAssetCondition);
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            const formatValue = item => {
              // format values in the multiselect menu
              return assetConditionsMap[item];
            };

            return (
              <MultiSelectFilter
                title="Asset Condition"
                filterList={filterList}
                localFilterList={selectedAssetConditionFilter}
                onChange={onChange}
                index={index}
                column={column}
                filterData={filterData}
                updateFilters={setSelectedAssetConditionFilter}
                formatValue={formatValue}
              />
            );
          },
        },
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return <AssetConditionDisplay condition={value} />;
        },
      },
    },
    {
      name: 'asset_condition_set_by',
      label: 'Asset Condition Set By',
      options: {
        filter: false,
        sort: false,
        downloadBody: value => getCustomCSVData('simple', value),
        customBodyRender: value => {
          if (!value) {
            return <></>;
          }
          return <StyledLink to={`/projects/${value.id}/`} value={value.name} />;
        },
      },
    },
    {
      name: 'model',
      label: 'Model',
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      name: 'is_active',
      label: 'Active',
      options: {
        display: 'excluded',
        filter: true,
        sort: true,
      },
    },
  ];

  // handle columns display
  setColumns(columns, views);

  /** @type {TableOptions} */
  const options = {
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setAssetConditionTableView(changedColumn, action));
    },
    enableNestedDataAccess: '.',
  };

  return (
    <div className={classes.root}>
      <Table
        title={title}
        simpleSearch
        columns={columns}
        data={data}
        options={options}
        count={count}
        loading={loading}
      />
    </div>
  );
};

AssetConditionTable.defaultProps = {
  title: '',
  loading: false,
};

AssetConditionTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  count: PropTypes.number,
  setZoomToLocation: PropTypes.func.isRequired,
};

export default AssetConditionTable;
