// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import React from 'react';
import CommonRoutes from './utilities/CommonRoutes';
import ChecklistResultLineView from '../../components/checklistResultLine/ChecklistResultLineView';
import ChecklistResultLineTablePage from '../../components/checklistResultLine/ChecklistResultLineTablePage';
import MainLayout from '../layouts/MainLayout';

const ChecklistResultLineRoutes = props => {
  return (
    <CommonRoutes
      pageTitle="Checklist Result Line"
      layout={MainLayout}
      View={<ChecklistResultLineView />}
      All={<ChecklistResultLineTablePage />}
    />
  );
};

export default ChecklistResultLineRoutes;
