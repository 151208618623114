import { buildEndpoint, get } from './util';

/**
 * Backwards compatibility - combining Read, Create, Update, and Delete to share state
 */

/**
 * https://docs.huvrdata.app/reference/api_heat-data_list
 */
export const heatDataListEndpoint = buildEndpoint('heatDataList', query => get('/api/heat-data/', query), {});

/**
 * https://docs.huvrdata.app/reference/api_heat-data_read
 */
export const heatDataReadEndpoint = buildEndpoint('heatDataRead', id => get(`/api/heat-data/${id}/`), {});
