import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

import { Grid, Typography } from '@mui/material';
import LinearProgressWithLabel from '../shared/displays/LinearProgressWithLabel';
import { calculateChecklistProgress } from '../../utilities/checklist';

const useStyles = makeStyles(theme => ({
  gettingStarted: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    fontSize: '18px',
  },
}));

function GettingStarted(props) {
  const { results, template } = props.checklist;
  const { name, sections, version } = template;
  // const defaultActions = metadata['default_actions'];
  const classes = useStyles();

  const p = calculateChecklistProgress(sections, results);
  const sectionCount = sections.length;

  return (
    <Grid container className={classes.gettingStarted}>
      <Grid item xs={12}>
        <Typography variant="h4">
          {name} ({version})
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h6">
          Status: Sections: {sectionCount}, Notes: {p.noteCount}, Media: {p.mediaCount}{' '}
        </Typography>
        <LinearProgressWithLabel value={p.progress} />
      </Grid>
    </Grid>
  );
}

GettingStarted.propTypes = {
  checklist: PropTypes.object.isRequired,
  results: PropTypes.object,
};
GettingStarted.defaultProps = { results: {} };

export default GettingStarted;
