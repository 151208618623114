import { cleanUpFileString } from './strings';
import { videoFile, audioFile, documentFile, imageFile, notSupported } from '../assets/thumbnails';

export function getExtensionFromUrl(url) {
  try {
    const filename = new URL(url).pathname.split('/').pop();
    return getExtension(filename);
  } catch (e) {
    // ignore empty/malformed url
    return '';
  }
}
// https://stackoverflow.com/a/12900504/1232030
export function getExtension(filename) {
  return typeof filename === 'string' ? filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2) : '';
}

export function removeExtension(filename) {
  return filename.substring(0, filename.lastIndexOf('.')) || filename;
}

export function changeExtension(filename, newExt) {
  const pos = filename.includes('.') ? filename.lastIndexOf('.') : filename.length;
  const fileRoot = filename.substr(0, pos);
  return `${fileRoot}.${newExt}`;
}
// Return thumbnail URL string
export const noThumbnail = mediaType => {
  switch (mediaType) {
    case 'image':
      return imageFile.default;
    case 'video':
      return videoFile.default;
    case 'audio':
      return audioFile.default;
    case 'application':
      return documentFile.default;
    default:
      return notSupported.default;
  }
};

export const getOverlayFilename = (url, rowData, columns, overlay) => {
  const split = url.split('?')[0].split('/'); // remove the query
  const extension = getExtension(split[split.length - 1]); // separate out extension from filename
  const origFilename = split[split.length - 1].replace(`.${extension}`, '');
  const columnNames = columns.map(column => column.name);
  // console.log("--- getOverlayFilename: rowData ---");
  // console.dir(rowData);
  // console.log("--- getOverlayFilename: columnNames ---");
  // console.dir(columnNames);
  // The following vals are different between callstacks:
  // id, asset, project_name, overlays, labels, description, actions
  // however we only care about id, and asset here
  let idVal = '';
  try {
    idVal = rowData[columnNames.indexOf('id')].props.value;
  } catch (e) {
    idVal = rowData[columnNames.indexOf('id')];
  }
  let assetPath = '';
  try {
    assetPath = rowData[columnNames.indexOf('asset')].props.value;
  } catch (e) {
    assetPath = rowData[columnNames.indexOf('asset')]['asset_path'];
  }

  const filename = cleanUpFileString(
    `[D${idVal}][${assetPath}][${rowData[columnNames.indexOf('component_display')]}][${
      rowData[columnNames.indexOf('severity_display')]
    }][${rowData[columnNames.indexOf('type_display')]}][${overlay.id}][${origFilename}].${extension}`
  ); // File name format [D<defect_id>][asset.asset_path][component_display][severity_display][type_display][overlay_id][original_filename].extension

  return filename;
};

// https://www.npmjs.com/package/capture-video-frame
export function captureVideoFrame(video, format = 'jpeg', quality = 0.92) {
  if (typeof video === 'string') {
    video = document.getElementById(video);
  }
  if (!video || (format !== 'png' && format !== 'jpeg')) {
    throw new Error('Format not surpported.');
  }

  const canvas = document.createElement('CANVAS');
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;
  canvas.getContext('2d').drawImage(video, 0, 0);

  const dataUri = canvas.toDataURL('image/' + format, quality);
  return dataUri;
}
