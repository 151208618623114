import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';

// lib
import Uppy from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import { StatusBar, DragDrop } from '@uppy/react';

// util / store
import { openSnackbar } from '../store/snackbarActions';

import './FileUploader.scss';

// Number of files that can be added to the upload queue
const MAX_FILES = 5000;
// Concurrent upload count, https://uppy.io/docs/aws-s3-multipart/#limit
const PARALLEL_UPLOADS = 3;

const useStyles = makeStyles(theme => ({
  floatingStatusBar: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10000,
  },
}));

export const FileUploaderContext = React.createContext({
  uppy: null,
  uploadOptionsRef: { current: null },
  isUploading: false,
  renderDragNDrop: () => {},
});

export const FileUploaderProvider = ({ children }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // Singleton objects to keep track of files
  //  in case user changes page while uploading
  //  and starts a new upload
  //  do _not_ need to trigger re-render, so use `useRef`
  //
  // WILL CAUSE ISSUES IF MULTIPLE UPLOAD COMPONENTS ARE MOUNTED AT ONCE
  //
  const uploadFileOptionsRef = useRef({
    /*
      [file.id]: { ...uploadOptionsRef.current }
      [file.id]: { ...uploadOptionsRef.current }
      [file.id]: { ...uploadOptionsRef.current }
    */
  });
  const uploadOptionsRef = useRef({
    getUploadParameters: null, // callback to get upload parameters
    onUploadSuccess: null, // callback to run after _batch_ upload complete
    onUploadEachSuccess: null, // callback to run after _single_ upload complete
  });
  const [isUploading, setIsUploading] = React.useState(false);

  const uppy = useMemo(() => {
    return (
      new Uppy({
        debug: true,
        // meta: { type: META_TYPE },  TODO - can we add this after the fact ?
        restrictions: { maxNumberOfFiles: MAX_FILES },
        autoProceed: true,
      })
        .use(AwsS3, {
          limit: PARALLEL_UPLOADS,

          /**
           * @name getUploadParameters
           * @param {Object} file
           * @returns {Promise<{ method, url, fields, headers }>}
           * @description  create a signed url for uploading to GCP bucket
           *   must be defined in the `uploadFileOptionsRef` !
           *
           * See:
           *  https://uppy.io/docs/aws-s3-multipart/#getuploadparametersfile-options
           *
           */
          getUploadParameters: file => {
            const fileOptions = uploadFileOptionsRef.current[file.id];

            if (!fileOptions.getUploadParameters) {
              throw new Error('No upload parameters found for file');
            }

            return fileOptions.getUploadParameters(file, mediaObject => {
              // set the media object on the file
              // (callback optional, only use if required for onUploadEachSuccess)
              uploadFileOptionsRef.current[file.id].mediaObject = mediaObject;
            });
          },
        })

        /**
         * @name upload
         * @param {Object} data object consists of `id` with upload ID and `fileIDs` array
         * @description Fired when the upload starts.
         *
         * Store copy of upload options
         *  the file in the `fileUploadMap`
         *  so we can access it later if the user navigates away from the page
         *
         * See:
         *  https://uppy.io/docs/uppy/#upload-1
         */
        .on('upload', data => {
          setIsUploading(true);
          for (const fileID of data.fileIDs) {
            uploadFileOptionsRef.current[fileID] = { ...uploadOptionsRef.current };
          }
        })

        /**
         * @name restriction-failed
         * @param {Object} file - The Uppy file that was restricted.
         * @param {Object} error - An object with error data.
         *
         * Display any restriction errors to the user
         *
         * See:
         *  https://uppy.io/docs/uppy/#restriction-failed
         */
        .on('restriction-failed', (file, error) => {
          dispatch(openSnackbar(`Unable to start upload for file - ${error}`, 'error'));
        })

        /**
         * @name upload-success
         * @param {Object} file - The Uppy file that was uploaded.
         * @param {Object} response - An object with response data from the remote endpoint
         * @description Fired each time a single upload is completed.
         *
         * Display a snackbar with the file name
         * Remove the file from the `fileUploadMap`
         *
         * See:
         *  https://uppy.io/docs/uppy/#upload-success
         */
        .on('upload-success', (file, response) => {
          const fileOptions = uploadFileOptionsRef.current[file.id];
          if (fileOptions && fileOptions.onUploadEachSuccess) {
            if (!fileOptions.mediaObject) {
              console.warning('No media object found for file; be sure to set in setMediaObject callback');
            }
            fileOptions.onUploadEachSuccess(file, response, fileOptions.mediaObject);
          }
          // remove the file from the map
          delete uploadFileOptionsRef.current[file.id];
        })

        /**
         * @name upload-error
         * @param {Object} file - The Uppy file which failed to upload.
         * @param {Object} error - An object with error data.
         * @param {Object} response - An object with response data from the remote endpoint
         * @description Fired each time a single upload failed.
         *
         * Display a snackbar with the error message
         *
         * See:
         *  https://uppy.io/docs/uppy/#upload-error
         */
        .on('upload-error', (file, error, response) => {
          if (error.isNetworkError) {
            // Let your users know that file upload could have failed
            // due to firewall or ISP issues
            dispatch(openSnackbar('Unable to connect to server', 'error'));
          } else {
            // TODO Add retry action in snackbar
            dispatch(openSnackbar(`Upload failed: ${file.name}`, 'error'));
          }
        })

        /**
         * @name complete
         * @param {Object} result object with arrays of `successful` and `failed` files
         * @description Fired when ALL uploads are complete (for a batch - if multiple batches, this will fire multiple times).
         *
         * Refetch the inspection media
         * Display snackbars
         * Reset Uppy progress bar back to 0%
         *
         */
        .on('complete', result => {
          const state = uppy.getState();
          if (state.info.isHidden && state.totalProgress === 100 && !state.error) {
            // reset Uppy progress bar back to 0%
            uppy.cancelAll();
            setIsUploading(false);
          }

          setTimeout(() => {
            const state = uppy.getState();
            //  - wait 5 seconds to allow the files to be processed, then call uploadOptions.onUploadSuccess callback
            // only do this once ALL upload batches are complete
            if (state.info.isHidden && result.successful.length > 0 && uploadOptionsRef.current.onUploadSuccess) {
              uploadOptionsRef.current.onUploadSuccess(result);
            }
          }, 5000);

          if (result.successful.length) {
            setTimeout(() => {
              dispatch(openSnackbar('Generating thumbnails.', 'info'));
            }, 2000);
          }

          // if multiple may have some pass/some fail
          if (result.failed.length && result.successful.length) {
            dispatch(
              openSnackbar(
                `${result.failed.length} files failed. ${result.successful.length} files uploaded.`,
                'warning'
              )
            );
          } else if (result.failed.length) {
            dispatch(openSnackbar(`${result.failed.length} files failed.`, 'warning'));
          } else {
            dispatch(openSnackbar(`${result.successful.length} files uploaded.`, 'success'));
          }
        })
    );
  }, [dispatch]);

  // close uppy on component unmount
  useEffect(() => {
    return () => uppy.close();
  }, [uppy]);

  const renderDragNDrop = props => {
    return (
      <DragDrop
        width="100%"
        uppy={uppy}
        locale={{
          strings: {
            // Text to show on the droppable area.
            // `%{browse}` is replaced with a link that opens the system file selection dialog.
            dropHereOr: 'Drop here or %{browse}',
            // Used as the label for the link that opens the system file selection dialog.
            browse: 'browse',
          },
        }}
        {...props}
      />
    );
  };

  return (
    <FileUploaderContext.Provider value={{ uploadOptionsRef, uppy, renderDragNDrop, isUploading }}>
      {children}
      <div className={classes.floatingStatusBar}>
        <StatusBar uppy={uppy} showProgressDetails />
      </div>
    </FileUploaderContext.Provider>
  );
};

FileUploaderProvider.propTypes = {
  children: PropTypes.node,
};

/**
 * @returns {{ uploadOptionsRef, uppy, isUploading, renderDragNDrop }}
 * @description Hook to access the FileUploaderContext
 */
export const useFileUploader = () => React.useContext(FileUploaderContext);

export default FileUploaderProvider;
