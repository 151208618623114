import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../store/dialogActions';

import InspectionMediaBulkEditForm from './InspectionMediaBulkEditForm';

const InspectionMediaBulkEditDialog = props => {
  const { inspectionMediaIds } = props;
  const dispatch = useDispatch();

  return (
    <Grid container direction="column" justifyContent="space-between">
      <Grid item>
        Select the fields you wish to update on the {inspectionMediaIds?.length} selected Inspection Media.
      </Grid>
      <Grid item>
        <InspectionMediaBulkEditForm
          inspectionMediaIds={inspectionMediaIds}
          onSubmit={() => {
            dispatch(closeDialog());
          }}
        />
      </Grid>
    </Grid>
  );
};

InspectionMediaBulkEditDialog.defaultProps = {};

InspectionMediaBulkEditDialog.propTypes = {
  inspectionMediaIds: PropTypes.array.isRequired,
};

export default InspectionMediaBulkEditDialog;
