// fields to show referenced by key from the api response.
// any field that is not in this array will not be shown.
export const fieldOrder = {
  generalOverview: {
    display: 'General Overview',
    fields: ['name', 'description', 'mode'],
  },
  templateInformation: {
    display: 'Template Information',
    fields: ['template_name', 'template', 'uri', 'version'],
  },
  layoutSettings: {
    display: 'Layout Settings',
    fields: ['title_mode', 'layout', 'density', 'theme', 'checklist_media_width'],
  },
  coverSettings: {
    display: 'Cover Settings',
    fields: ['cover_mode', 'cover_option', 'cover_caption'],
  },
  measurementSettings: {
    display: 'Measurement Settings',
    fields: ['measurement_mode'],
  },
  printOptions: {
    display: 'Print Options',
    fields: [
      'print_options.paper_size',
      'print_options.margin_top',
      'print_options.margin_bottom',
      'print_options.margin_left',
      'print_options.margin_right',
      'print_options.scale',
      'print_options.print_background',
      'print_options.omit_background',
      'print_options.landscape',
    ],
  },
  reportContents: {
    display: 'Report Contents',
    fields: ['summary_title', 'summary_option', 'contents', 'appendices', 'header', 'footer'],
  },
  reportFilters: {
    display: 'Report Filters',
    fields: ['filter_section', 'filter_line', 'filter_image', 'trim_work_instructions'],
  },
  legacySettings: {
    display: 'Legacy Settings',
    fields: ['css', 'sections_excluded', 'sections_per_page', 'sections_max_rows'],
  },
  status: {
    display: 'Status',
    fields: ['is_active', 'updated_on', 'created_on'],
  },
};

// fields to filter out depending on if the user is on the view, create or update page
export const removeField = state => item => {
  let fields = [];
  switch (state) {
    case 'CREATE':
      fields = ['created_on', 'updated_on'];
      break;
    case 'UPDATE':
      fields = ['mode', 'created_on', 'updated_on'];
      break;
    case 'VIEW':
      fields = [];
      break;
    default:
      fields = [];
  }
  return fields.includes(item);
};

export const hideField = (values, item, hasCustomThemes) => {
  switch (item) {
    case 'theme':
      return !hasCustomThemes;
    case 'filter_image':
      return values?.mode === 'FORM';
    case 'sections_excluded':
    case 'sections_per_page':
    case 'sections_max_rows':
    case 'css':
      // TODO: These fields were hidden when version1 was used (these should be removed in the future)
      return true;
    default:
      return false;
  }
};

export const getTemplateName = (data, templates) => {
  const { template, uri } = data;
  const templateObj = templates.find(element => {
    return element.template === template && element.uri === uri;
  });
  return templateObj ? templateObj.name : '';
};

export const getTemplateOptions = (mode = 'PROJECT', templates) => {
  if (!templates || !Array.isArray(templates) || !templates.length) {
    return [];
  }
  return templates.flatMap(item => {
    if (!item.mode) item.mode = 'PROJECT';
    if (item.mode === mode) {
      return { label: item.name, value: item.name };
    }
    return [];
  });
};

export const PAPER_SIZE_DIMENSIONS = {
  LETTER: { paper_width: 8.5, paper_height: 11 },
  LEGAL: { paper_width: 8.5, paper_height: 14 },
  TABLOID: { paper_width: 11, paper_height: 17 },
  AO: { paper_width: 33.1, paper_height: 46.8 },
  A1: { paper_width: 23.4, paper_height: 33.1 },
  A2: { paper_width: 16.5, paper_height: 23.4 },
  A3: { paper_width: 11.7, paper_height: 16.5 },
  A4: { paper_width: 8.3, paper_height: 11.7 },
  A5: { paper_width: 5.8, paper_height: 8.3 },
};

export const paperSizeOptions = [
  { value: 'LETTER', label: 'Letter -- 8.5 x 11in' },
  { value: 'LEGAL', label: 'Legal -- 8.5 x 14in' },
  { value: 'TABLOID', label: 'Tabloid -- 11 x 17in' },
  { value: 'AO', label: 'AO -- 33.1 x 46.8in' },
  { value: 'A1', label: 'A1 -- 23.4 x 33.1in' },
  { value: 'A2', label: 'A2 -- 16.5 x 23.4in' },
  { value: 'A3', label: 'A3 -- 11.7 x 16.5in' },
  { value: 'A4', label: 'A4 -- 8.3 x 11.7in' },
  { value: 'A5', label: 'A5 -- 5.8 x 8.3in' },
];

export const getPaperSize = ph => {
  // use paper height value to get corresponding paper size
  const paperSize = Object.keys(PAPER_SIZE_DIMENSIONS).find(x => PAPER_SIZE_DIMENSIONS[x]['paper_height'] === ph);
  return paperSize || 'LETTER';
};
