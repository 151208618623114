import { all, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  NOTIFICATION_RULES_REQUEST,
  NOTIFICATION_RULES_SUCCESS,
  NOTIFICATION_RULES_FAILURE,
  NOTIFICATION_RULES_CREATE_REQUEST,
  NOTIFICATION_RULES_CREATE_SUCCESS,
  NOTIFICATION_RULES_CREATE_FAILURE,
  NOTIFICATION_RULES_UPDATE_REQUEST,
  NOTIFICATION_RULES_UPDATE_SUCCESS,
  NOTIFICATION_RULES_UPDATE_FAILURE,
  NOTIFICATION_RULES_DELETE_REQUEST,
  NOTIFICATION_RULES_DELETE_SUCCESS,
  NOTIFICATION_RULES_DELETE_FAILURE,
  NOTIFICATION_RULES_ALL_REQUEST,
  NOTIFICATION_RULES_ALL_SUCCESS,
  NOTIFICATION_RULES_ALL_FAILURE,
} from './notificationRulesActions';

import {
  fetchNotificationRules,
  createNotificationRules,
  updateNotificationRules,
  deleteNotificationRules,
  fetchNotificationRulesList,
} from '../../api/features/notificationRules';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';
import { fetchListAll } from '../../utilities/sagaHelpers';
import { DIALOG_CLOSE } from '../../store/dialogActions';

export function* notificationRulesFetch(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield fetchNotificationRules(id);
    if (data) {
      yield put({ type: NOTIFICATION_RULES_SUCCESS, data });
    } else if (error) {
      yield put({ type: NOTIFICATION_RULES_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, NOTIFICATION_RULES_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, NOTIFICATION_RULES_FAILURE);
  }
}

export function* watchNotificationRulesFetch() {
  yield takeLatest(NOTIFICATION_RULES_REQUEST, notificationRulesFetch);
}

export function* notificationRulesCreate(action) {
  const { body } = action;
  try {
    const { data, error, formError, response } = yield createNotificationRules(body);
    if (data) {
      yield put({ type: NOTIFICATION_RULES_CREATE_SUCCESS, data });
      yield put(push(`/notification-rules/${data.id}`));
    } else if (error) {
      yield put({ type: NOTIFICATION_RULES_CREATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: NOTIFICATION_RULES_CREATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, NOTIFICATION_RULES_CREATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, NOTIFICATION_RULES_CREATE_FAILURE);
  }
}

export function* watchNotificationRulesCreate() {
  yield takeLatest(NOTIFICATION_RULES_CREATE_REQUEST, notificationRulesCreate);
}

export function* notificationRulesUpdate(action) {
  const { id, body } = action;
  try {
    const { data, error, formError, response } = yield updateNotificationRules(id, body);
    if (data) {
      yield put({ type: NOTIFICATION_RULES_UPDATE_SUCCESS, data });
      yield put(push('/notification-rules'));
    } else if (error) {
      yield put({ type: NOTIFICATION_RULES_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: NOTIFICATION_RULES_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, NOTIFICATION_RULES_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, NOTIFICATION_RULES_UPDATE_FAILURE);
  }
}

export function* watchNotificationRulesUpdate() {
  yield takeLatest(NOTIFICATION_RULES_UPDATE_REQUEST, notificationRulesUpdate);
}

export function* notificationRulesDelete(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield deleteNotificationRules(id);
    if (data) {
      yield put({ type: NOTIFICATION_RULES_DELETE_SUCCESS });
      yield put({ type: NOTIFICATION_RULES_ALL_REQUEST });
      yield put({ type: DIALOG_CLOSE });
      yield put(push('/notification-rules'));
    } else if (error) {
      yield put({ type: NOTIFICATION_RULES_DELETE_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, NOTIFICATION_RULES_DELETE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, NOTIFICATION_RULES_DELETE_FAILURE);
  }
}

export function* watchNotificationRulesDelete() {
  yield takeLatest(NOTIFICATION_RULES_DELETE_REQUEST, notificationRulesDelete);
}

export function* notificationRulesFetchAll(action) {
  try {
    const { data, error, response } = yield fetchListAll(fetchNotificationRulesList, action.query);
    if (data) {
      yield put({ type: NOTIFICATION_RULES_ALL_SUCCESS, data });
    } else if (error) {
      yield put({ type: NOTIFICATION_RULES_ALL_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, NOTIFICATION_RULES_ALL_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, NOTIFICATION_RULES_ALL_FAILURE);
  }
}

export function* watchNotificationRulesFetchAll() {
  yield takeLatest(NOTIFICATION_RULES_ALL_REQUEST, notificationRulesFetchAll);
}

export default function* notificationRulesSaga() {
  yield all([
    watchNotificationRulesFetch(),
    watchNotificationRulesCreate(),
    watchNotificationRulesUpdate(),
    watchNotificationRulesFetchAll(),
    watchNotificationRulesDelete(),
  ]);
}
