import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

import { Grid, Typography } from '@mui/material';
import LinearProgressWithLabel from '../shared/displays/LinearProgressWithLabel';
import { calculateChecklistProgress } from '../../utilities/checklist';
import { apiDateToString } from '../../utilities/strings';

const useStyles = makeStyles(theme => ({
  gettingStarted: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    fontSize: '12px',
  },
}));

function GettingStarted(props) {
  const classes = useStyles();
  const { selectedRevision } = props;
  const { results, template } = props.checklist;
  const { name, version, sections } = template;

  const _results = selectedRevision?.edit || results;
  const p = calculateChecklistProgress(sections, _results);

  return (
    <Grid container className={classes.gettingStarted} direction="column">
      <Grid item>
        <Typography variant="h5">
          {name} ({version})
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Updated: {apiDateToString(selectedRevision?.updated_on)} by {selectedRevision?.uploaded_by?.name}
        </Typography>
        <Typography>{`Lines: ${p.lineCount}, Results: ${p.resultCount}, Notes: ${p.noteCount}, Media: ${p.mediaCount}`}</Typography>
        <LinearProgressWithLabel value={p.progress} />
      </Grid>
    </Grid>
  );
}

GettingStarted.propTypes = {
  checklist: PropTypes.object.isRequired,
  results: PropTypes.object,
  selectedRevision: PropTypes.object.isRequired,
};
GettingStarted.defaultProps = { results: {} };

export default GettingStarted;
