import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningIcon from '@mui/icons-material/Warning';
import variables from '../../../config.module.scss';

const Banner = props => {
  const { alertStatus, alertMessage, bannerCss } = props;
  const { yellow, red } = variables;
  const useStyles = makeStyles(theme => ({
    banner: {
      backgroundColor: alertStatus === 'WARN' ? yellow : red,
      color: 'white',
      height: theme.spacing(5),
      borderTop: 'darkgray 1px solid',
      textAlign: 'left',
    },
    bannerIcon: {
      position: 'relative',
      display: 'inline-block',
      top: theme.spacing(1),
      margin: theme.spacing(0, 1),
    },
    bannerMessage: {
      verticalAlign: 'middle',
    },
    bannerCss,
  }));
  const classes = useStyles();
  if (alertStatus === 'OFF') return <></>;
  return (
    <div className={`${classes.banner} ${classes.bannerCss}`}>
      {alertStatus === 'WARN' ? (
        <WarningIcon className={classes.bannerIcon} />
      ) : (
        <ErrorOutlineIcon className={classes.bannerIcon} />
      )}
      <span className={classes.bannerMessage}>{alertMessage}</span>
    </div>
  );
};

Banner.propTypes = {
  alertStatus: PropTypes.string,
  alertMessage: PropTypes.string,
  bannerCss: PropTypes.object,
};

Banner.defaultProps = {
  alertStatus: 'OFF',
  alertMessage: '',
  bannerCss: {},
};

export default Banner;
