import React from 'react';
import PropTypes from 'prop-types';
import Markdown from '../Markdown';
import { CHECKLIST_LINE_TYPES } from '../../../utilities/checklist';

export const ChecklistValue = ({ lineType, value, lineData }) => {
  if (!value) {
    return null;
  }

  switch (lineType) {
    case CHECKLIST_LINE_TYPES.TEXT:
    case CHECKLIST_LINE_TYPES.NUMBER:
    case CHECKLIST_LINE_TYPES.TEXTAREA:
    case CHECKLIST_LINE_TYPES.TEXT_DATE:
    case CHECKLIST_LINE_TYPES.DATE:
    case CHECKLIST_LINE_TYPES.CALCULATION:
    case CHECKLIST_LINE_TYPES.CHOICE: // TODO - render choice label
    case CHECKLIST_LINE_TYPES.COLLECT_POSITION: // TODO: render geopoint
    case CHECKLIST_LINE_TYPES.NUMBER_LIST:
      return <Markdown>{value}</Markdown>;
    case CHECKLIST_LINE_TYPES.IMAGE:
      console.warn('image line type not yet implemented');
      return null;
    // return <img src={lineData.url} title={lineData.description} />;
    case CHECKLIST_LINE_TYPES.MULTIPLE_CHOICE:
    case CHECKLIST_LINE_TYPES.SIGNATURE: // not sure if/how yet we need to handle these
    case CHECKLIST_LINE_TYPES.LINE_GROUP:
    case CHECKLIST_LINE_TYPES.LINE_GROUP_END:
    case CHECKLIST_LINE_TYPES.TEXT_BLOCK:
      return null;
    default:
      return <Markdown>{`**Unsupported line type**: ${lineType}: ${value}`}</Markdown>;
  }
};

ChecklistValue.propTypes = {
  lineType: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  lineData: PropTypes.object.isRequired,
};

export default ChecklistValue;
