import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Check from '@mui/icons-material/Check';

/**
 * Button for Next, Apply, Submit or similar buttons using the primary color theme.
 * It can show an icon.
 */

const NavButton = props => {
  const { type, label, variant, icon, Icon, disabled, to } = props;

  const history = useHistory();
  const location = useLocation();

  const moreProps = {};
  if (icon) {
    const renderIcon = () => {
      let ButtonIcon = Icon;
      if (!ButtonIcon) {
        ButtonIcon = Check;
      }
      return <ButtonIcon />;
    };
    moreProps.startIcon = renderIcon();
  }

  const onClick = () => {
    if (location.pathname !== to) {
      history.push(to);
    }
  };

  return (
    <Button color="primary" type={type} variant={variant} onClick={onClick} disabled={disabled} {...moreProps}>
      {label}
    </Button>
  );
};

NavButton.defaultProps = {
  type: 'button',
  variant: 'contained',
  icon: false,
  Icon: null,
  disabled: false,
};

NavButton.propTypes = {
  /** sets the button type */
  type: PropTypes.string,
  /** sets the button variant */
  variant: PropTypes.string,
  /** sets the button label */
  label: PropTypes.string.isRequired,
  /** enable or disable the <em>Icon</em> prop */
  icon: PropTypes.bool,
  /** if <em>icon</em> is true, sets the custom svg icon */
  Icon: PropTypes.object,
  /** disables button and if <em>icon</em> show spinning loading icon */
  loading: PropTypes.bool,
  /** disables button */
  disabled: PropTypes.bool,
  /** callback for onClick event */
  to: PropTypes.string.isRequired,
};

export default NavButton;
