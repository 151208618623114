// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import React from 'react';
import CommonRoutes from './utilities/CommonRoutes';
import BulkImportsForm from '../../components/bulkImports/BulkImportsForm';
import BulkImportsView from '../../components/bulkImports/BulkImportsView';
import BulkImportsTablePage from '../../components/bulkImports/BulkImportsTablePage';
import MainLayout from '../layouts/MainLayout';

const BulkImportsRoutes = props => {
  return (
    <CommonRoutes
      pageTitle="Bulk Imports"
      layout={MainLayout}
      Edit={<BulkImportsForm update />}
      Create={<BulkImportsForm />}
      View={<BulkImportsView />}
      All={<BulkImportsTablePage />}
    />
  );
};

export default BulkImportsRoutes;
