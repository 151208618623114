import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'mui-rff';
import { FilledInput, FormHelperText, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import Markdown from '../../shared/Markdown';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  placeholderOption: {
    color: theme.palette.text.disabled,
    fontStyle: 'italic',
  },
}));

/**
 * @description Dropdown component for the Checklist Editor
 */
const ChoiceLineItem = props => {
  const { disabled, name, label, options, loading, multiple, inputPropClasses, helperText, validator } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Select
        disabled={disabled || loading}
        name={name}
        label={label}
        input={<FilledInput name={name} variant={'notched'} />}
        fieldProps={{ validate: validator }}
        formControlProps={{ className: 'select-input' }} // class defined in Checklist.scss
        inputLabelProps={{ disabled: loading }}
        multiple={multiple}
        variant="filled"
        inputProps={{
          classes: {
            select: inputPropClasses,
          },
        }}>
        {!multiple && (
          <MenuItem value="">
            <span className={classes.placeholderOption}>Select an option</span>
          </MenuItem>
        )}
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>
        <Markdown>{helperText}</Markdown>
      </FormHelperText>
    </div>
  );
};

ChoiceLineItem.defaultProps = {
  disabled: false,
  required: false,
  name: '',
  label: '',
  value: undefined,
  options: [],
  labelwidth: 50,
  loading: false,
  multiple: false,
  inputPropClasses: '',
  helperText: '',
  validator: () => {},
};

ChoiceLineItem.propTypes = {
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array,
  labelwidth: PropTypes.number,
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
  inputPropClasses: PropTypes.string,
  helperText: PropTypes.string,
  validator: PropTypes.func,
};

export default ChoiceLineItem;
