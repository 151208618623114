import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { getAllAssets } from '../../store/features/assetsActions';
import { getAllCompanies } from '../../store/features/companiesActions';
import { createProjects } from '../../store/features/projectsActions';
import Title from '../shared/displays/Title';
import CommonForm from '../shared/form/Common';
import { removeField, hideField } from '../projects/projectsShared';
import { jsonKeyToLabel } from '../../utilities/strings';
import { getLabel } from '../../utilities/objects';
import { useAssets, useCompanies } from '../../hooks/optionsHooks';
import { useCachedProjectTypes } from '../../hooks/projectTypesHooks';
import { usePrevious } from '../../hooks/renderHooks';

const useStyles = makeStyles(theme => ({
  gridItem: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    width: '50%',
    height: '100%',
    textAlign: 'left',
  },
  form: {
    margin: theme.spacing(2),
    height: '90%',
  },
  findingsText: {
    fontSize: theme.spacing(2.5),
    fontWeight: 'bold',
  },
  error: {
    fontSize: theme.spacing(2),
    color: 'red',
  },
}));

const FindingsProjectForm = ({
  current,
  findings,
  formIndex,
  handleFormIndex,
  setProjectsToCreate,
  projectsToCreate,
  handleNextStep,
  isSingleProject,
  createWithParent,
  setParent,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data: project, formError, error, loading } = useSelector(state => state.projects.each);
  const assetsOptions = useAssets();

  const [type, setType] = useState();
  const [asset, setAsset] = useState();
  const [assetTypes, setAssetTypes] = useState();

  const isParentProjectForm = createWithParent && formIndex === -1;

  const matchAssetTypeFilter = projectType => {
    return current ? projectType.assetTypes.some(elem => current && elem.id === current.asset.type.id) : [];
  };
  const allowChildProjectsFilter = projectType => {
    return projectType.childrenMode !== 'DISABLED';
  };
  const { projectTypeOptions } = useCachedProjectTypes(
    undefined,
    isParentProjectForm ? allowChildProjectsFilter : matchAssetTypeFilter
  );
  const companiesOptions = useCompanies();

  const getAssetTypes = (value, projectTypeOptions) => {
    try {
      const projectType = projectTypeOptions.find(item => item.value === value.value);
      return projectType ? projectType.assetTypes.map(item => item.id) : undefined;
    } catch {
      return undefined;
    }
  };

  // parent projects must have valid project-types for all children (see #6888)
  const canCreateProject = !isParentProjectForm || (isParentProjectForm && projectTypeOptions.length);

  const refValue = usePrevious(project.id);
  useEffect(() => {
    if (refValue !== project.id) {
      setParent({ parentId: project.id, parentName: project.name });
    }
  }, [project]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (createWithParent) {
      handleFormIndex(-1);
      dispatch(getAllAssets({ is_active: true }));
    } else {
      handleFormIndex(0);
    }
    dispatch(getAllCompanies({ role: 'SUBCONTRACTOR' }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fieldOrder = {
    project_info: {
      display: 'Project Info',
      fields: ['name', 'type', ...(isParentProjectForm ? ['asset'] : []), 'management_company', 'scope'],
    },
  };
  const formSettings = {
    fieldOrder,
    removeField: removeField('CREATE'),
    hideField,
  };

  // sticky values for multi project creation
  const defaultedValues = {
    management_company: projectsToCreate[0]?.management_company,
    type: projectsToCreate[0]?.type,
  };

  const setInitialProjectType = () => {
    const isTypeValid = projectTypeOptions.some(x => x.value === defaultedValues.type);
    const value = (isTypeValid && defaultedValues.type) || '';
    return value;
  };

  const initialValues = {
    asset: current ? current.asset.id : '',
    name: '',
    type: setInitialProjectType(),
    management_company: defaultedValues?.management_company, // don't send management company if it's not set
  };

  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        errors[key] = value;
      }
    }
    if (projectTypeOptions.length === 0) {
      errors['non_field_errors'] = `No project types available for Asset Type: ${current.asset.type.name}`;
    }
    return errors;
  };

  // adding to projectsToCreate array
  const onSubmit = (values, form) => {
    let selectedFindings = [];
    const shouldNextStep = isSingleProject || formIndex === findings.length - 1;

    if (isParentProjectForm) {
      handleFormIndex(prevIndex => prevIndex + 1);
      dispatch(
        createProjects({ ...values }, '', {
          preventDefault: true,
          displaySnackbar: true,
          link: false,
        })
      );
    } else {
      if (isSingleProject) {
        selectedFindings = [...findings.map(f => f.id)];
      } else {
        selectedFindings = [current.id.toString()];
      }
      const updatedValues = {
        ...values,
        scope: { description: values.scope, selected_findings: [...selectedFindings] },
      };
      setProjectsToCreate(prev => prev.concat(updatedValues));

      if (shouldNextStep) {
        handleNextStep();
      } else {
        handleFormIndex(prevIndex => prevIndex + 1);
      }
    }
    form.restart();
  };

  const initialValueSetter = type => {
    if (asset !== null) {
      if (type === 'label') {
        return getLabel(asset, assetsOptions);
      } else {
        return asset;
      }
    } else {
      return '';
    }
  };

  const updateAsset = e => {
    setAsset(e.target.value);
  };

  const updateType = e => {
    setType(e.target.value);
    const assetType = getAssetTypes(e.target.value, projectTypeOptions);
    if (assetType) {
      setAssetTypes(assetType);
    }
  };

  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      switch (key) {
        case 'name':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              required: true,
            },
          };
          break;
        case 'asset':
          fieldSettings[key] = {
            type: 'asset-autocomplete',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: 'asset',
              required: true,
              labelwidth: 42,
              // disabled if project type is not set
              disabled: !type,
              valueInitial: { label: initialValueSetter('label', asset), value: initialValueSetter('value', asset) },
              filterQuery: { asset_type__in: assetTypes },
              onChange: updateAsset,
            },
          };
          break;
        case 'type':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              options: projectTypeOptions,
              labelwidth: 72,
              required: true,
              onChange: updateType,
            },
          };
          break;
        case 'management_company':
          fieldSettings[key] = {
            type: 'autocomplete',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              options: companiesOptions,
              labelwidth: 150,
            },
          };
          break;
        case 'scope':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              labelwidth: 72,
              multiline: true,
              rows: 3,
              helperText: 'Work instructions or details that appear in the new project (optional)',
            },
          };
          break;
      }
    });
  }

  const customToolbarProps = {
    backButtonProps: { label: 'Back', hide: true },
    saveButtonProps: { label: isParentProjectForm ? 'Create' : 'Add', hide: true },
  };

  const renderFindings = findings => {
    if (isSingleProject) {
      return (
        <>
          {findings.map((f, i) => (
            <Typography key={i} className={classes.findingsText} gutterBottom>
              {` - ${f.id} / ${f.asset.name} / ${f.component_display}`}
            </Typography>
          ))}
        </>
      );
    }
    if (current) {
      return (
        <Typography className={classes.findingsText} gutterBottom>
          {' '}
          {` - ${current.id} / ${current.asset.name} / ${current.component_display}`}{' '}
        </Typography>
      );
    }
  };
  const renderForm = () => (
    <CommonForm
      divider={false}
      initialValues={initialValues}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      onSubmit={onSubmit}
      decorators={[]}
      validate={validate}
      loading={loading}
      error={error}
      customToolbarProps={customToolbarProps}
      keepDirtyOnReinitialize={false}
    />
  );
  const renderFormTitle = () => {
    if (isParentProjectForm) {
      return 'New Parent Project';
    }
    return `New Project${isSingleProject ? '' : 's'}
    (${formIndex + 1} / ${isSingleProject ? '1' : findings.length})`;
  };
  const renderProjectTypeError = () => (
    <span className={classes.error}>No existing valid project types. Create a project type that allows children.</span>
  );

  return (
    <Grid container direction="column">
      <Title title={renderFormTitle()} />
      <br />
      <Grid item className={classes.gridItem}>
        <Typography gutterBottom>
          {isSingleProject
            ? 'A New Project will be created for selected findings.'
            : 'New Projects will be created for each selected finding.'}
          You will be prompted to choose the project type for each selected finding.
        </Typography>
      </Grid>
      <Grid item>{renderFindings(findings)}</Grid>
      <Grid item className={classes.gridItem}>
        {canCreateProject ? renderForm() : renderProjectTypeError()}
      </Grid>
    </Grid>
  );
};

FindingsProjectForm.propTypes = {
  formIndex: PropTypes.number.isRequired,
  findings: PropTypes.array.isRequired,
  promptValue: PropTypes.string.isRequired,
  current: PropTypes.object.isRequired,
  handleFormIndex: PropTypes.func.isRequired,
  setProjectsToCreate: PropTypes.func.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  isSingleProject: PropTypes.bool.isRequired,
  createWithParent: PropTypes.bool.isRequired,
  resetProjectType: PropTypes.bool.isRequired,
  setParent: PropTypes.func.isRequired,
  projectsToCreate: PropTypes.array.isRequired,
};

export default FindingsProjectForm;
