import { buildEndpoint, get, patch, post, del } from './util';

/**
 * Backwards compatibility - combining Read, Create, Update, and Delete to share state
 */
export const EACH_REDUCER = 'defectProfilesEach';

/**
 * https://docs.huvrdata.app/reference/api_defect-profiles_list
 */
export const defectProfilesListAllEndpoint = buildEndpoint(
  'defectProfilesListAll',
  query => get('/api/defect-profiles/', query),
  { batchFetchAll: true }
);

/**
 * https://docs.huvrdata.app/reference/api_defect-profiles_create
 */
export const defectProfilesCreateEndpoint = buildEndpoint(
  'defectProfilesCreate',
  data => post('/api/defect-profiles/', data),
  { reducerKey: EACH_REDUCER }
);

/**
 * https://docs.huvrdata.app/reference/api_defect-profiles_read
 */
export const defectProfilesReadEndpoint = buildEndpoint(
  'defectProfilesRead',
  id => get(`/api/defect-profiles/${id}/`),
  { reducerKey: EACH_REDUCER }
);

/**
 * https://docs.huvrdata.app/reference/api_defect-profiles_partial_update
 */
export const defectProfilesPartialUpdateEndpoint = buildEndpoint(
  'defectProfilesPartialUpdate',
  (id, data) => patch(`/api/defect-profiles/${id}/`, data),
  { reducerKey: EACH_REDUCER }
);

/**
 * https://docs.huvrdata.app/reference/api_defect-profiles_delete
 */
export const defectProfilesDeleteEndpoint = buildEndpoint(
  'defectProfilesDelete',
  id => del(`/api/defect-profiles/${id}/`),
  { reducerKey: EACH_REDUCER }
);
