import React, { useEffect } from 'react';
import { Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory, useLocation } from 'react-router-dom';
import HeatDataTable from './HeatDataTable';

import { heatDataListEndpoint } from '../../store/apiV2/heatData';
import { useTableViewSettings } from '../../hooks/settingsHooks';
import { tableViews } from '../../utilities/tables';
import { toQueryString } from '../../utilities/strings';
import { getParams } from '../../utilities/route';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const HeatDataTablePage = () => {
  const { dispatchRequest: fetchAllHeatData } = heatDataListEndpoint.useEndpoint();

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { heatDataTableViewKey } = tableViews;
  const { tableQuery: heatDataQuery, page, views } = useTableViewSettings(heatDataTableViewKey);
  const queryParams = getParams(location, heatDataQuery);

  useEffect(() => {
    fetchAllHeatData(queryParams);
  }, [fetchAllHeatData]); // eslint-disable-line

  const tableChangeHandler = query => {
    history.push({ search: toQueryString(query) });
    fetchAllHeatData(query);
  };

  return (
    <Paper className={classes.root}>
      <HeatDataTable
        title="Heat Data"
        queryParamObj={queryParams}
        tableChangeHandler={tableChangeHandler}
        page={page}
        views={views}
      />
    </Paper>
  );
};

export default HeatDataTablePage;
