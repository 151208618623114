import React from 'react';
import PropTypes from 'prop-types';
import { Checkboxes } from 'mui-rff';
import { FormHelperText } from '@mui/material';

const FieldEditCheckboxControlled = props => {
  const { name, label, loading, disabled, helperText, checked, onChange } = props;

  return (
    <>
      <Checkboxes
        disabled={loading || disabled}
        color="primary"
        variant="outlined"
        data={{ label }}
        name={name}
        formControlLabelProps={{ className: 'checkbox-input' }} // class defined in Common.scss
        checked={checked}
        onChange={onChange}
      />
      <FormHelperText variant="outlined">{helperText}</FormHelperText>
    </>
  );
};

FieldEditCheckboxControlled.defaultProps = {
  loading: false,
  disabled: false,
  helperText: '',
};

FieldEditCheckboxControlled.propTypes = {
  /** label for the string field */
  label: PropTypes.string.isRequired,
  /** the api key for the feature */
  name: PropTypes.string.isRequired,
  /** disables the filed while true */
  loading: PropTypes.bool,
  /** disables the fild while true */
  disabled: PropTypes.bool,
  /** adds helper text below the form field */
  helperText: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FieldEditCheckboxControlled;
