import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Container } from '@mui/material';
import PropTypes from 'prop-types';

import Checklist from './Checklist';
import LoadingPage from '../shared/displays/LoadingPage';
import StyledLink from '../shared/StyledLink';

import { checklistTemplatesFillableReadEndpoint } from '../../store/apiV2/checklistTemplates';
import Error from '../shared/displays/Error';

const PreviewTitle = props => {
  const { id, name, version } = props;

  return (
    <>
      {'PREVIEW MODE'} / <StyledLink to={'/checklist-templates'} value={'Templates'} /> /{' '}
      <StyledLink to={`/checklist-templates/${id}`} value={name} /> {version}
    </>
  );
};
PreviewTitle.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
};

const PreviewChecklist = props => {
  const { id: checklistTemplateId } = useParams();

  const { data, loading, error } = useSelector(state => checklistTemplatesFillableReadEndpoint.selector(state));

  const checklistState = { id: data.id ? data.id : -1, template: data, results: {}, loading: loading, error: error };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(checklistTemplatesFillableReadEndpoint.actionCreators.request([checklistTemplateId]));
  }, [checklistTemplateId, dispatch]);

  const refresh = () => {
    dispatch(checklistTemplatesFillableReadEndpoint.actionCreators.request([checklistTemplateId]));
  };

  if (loading) {
    return <LoadingPage />;
  }
  if (error) {
    return <Error error={`Form: ${error}`} />;
  }
  if (checklistState.error) {
    return <Error error={`Checklist: ${checklistState.error}`} />;
  }
  if (checklistState.id === -1) {
    return <LoadingPage />;
  }

  const title = data.name ? <PreviewTitle {...data} /> : <></>;

  return (
    <Container maxWidth={false} disableGutters>
      <div className="container">
        <Checklist
          parentId={checklistTemplateId}
          checklist={checklistState}
          title={title}
          refresh={refresh}
          revisions={[]}
          selectedRevisionId="latest"
          setRevision={() => {
            console.warn('setRevision not implemented - read only mode');
          }}
          isForm
          readOnly
        />
      </div>
    </Container>
  );
};

PreviewChecklist.propTypes = {};
PreviewChecklist.defaultProps = {};

export default PreviewChecklist;
