// --------------------------------------------------------------------------------
//
// Generated File
//
// !! Do not change this file by hand.                                       !!
// !! This file is automatically generated from the reducers in this folder. !!
//
// --------------------------------------------------------------------------------
import { activityTableViewReducer } from './activityTableViewRedux';
import { assessmentTypesTableViewReducer } from './assessmentTypesTableViewRedux';
// import { assessmentsTableViewReducer } from './assessmentsTableViewRedux';
import { assetConditionTableViewReducer } from './assetConditionTableViewRedux';
import { assetTypesTableViewReducer } from './assetTypesTableViewRedux';
// import { assetsTableViewReducer } from './assetsTableViewRedux';
import { bulkImportsTableViewReducer } from './bulkImportsTableViewRedux';
import { checklistTasksTableViewReducer } from './checklistTasksTableViewRedux';
import { checklistTemplatesTableViewReducer } from './checklistTemplatesTableViewRedux';
import { checklistViewerTableViewReducer } from './checklistViewerTableViewRedux';
// import { checklistsTableViewReducer } from './checklistsTableViewRedux';
import { checklistResultLinesTableViewReducer } from './checklistResultLinesTableViewRedux';
import { companiesTableViewReducer } from './companiesTableViewRedux';
import { crewsTableViewReducer } from './crewsTableViewRedux';
import { defectProfilesTableViewReducer } from './defectProfilesTableViewRedux';
// import { defectsTableViewReducer } from './defectsTableViewRedux';
import { inspectionMediaTableViewReducer } from './inspectionMediaTableViewRedux';
import { librariesTableViewReducer } from './librariesTableViewRedux';
import { libraryMediaTableViewReducer } from './libraryMediaTableViewRedux';
import { locationLayerProfilesTableViewReducer } from './locationLayerProfilesTableViewRedux';
// import { measurementsTableViewReducer } from './measurementsTableViewRedux';
import { notificationRuleSubscribersTableViewReducer } from './notificationRuleSubscribersTableViewRedux';
import { notificationRulesTableViewReducer } from './notificationRulesTableViewRedux';
import { projectTypesTableViewReducer } from './projectTypesTableViewRedux';
// import { projectsTableViewReducer } from './projectsTableViewRedux';
// import { recommendedWorkPlanTableViewReducer } from './recommendedWorkPlanTableViewRedux';
import { reportTypesTableViewReducer } from './reportTypesTableViewRedux';
import { reservationsTableViewReducer } from './reservationsTableViewRedux';
// import { scheduleEventsTableViewReducer } from './scheduleEventsTableViewRedux';
import { schedulePlanTableViewReducer } from './schedulePlanTableViewRedux';
import { scheduleTasksTableViewReducer } from './scheduleTasksTableViewRedux';
import { scheduleWorkTableViewReducer } from './scheduleWorkTableViewRedux';
import { userNavViewReducer } from './userNavViewRedux';
import { usersTableViewReducer } from './usersTableViewRedux';
import { watchersTableViewReducer } from './watchersTableViewRedux';

export default {
  activityTableView: activityTableViewReducer,
  assessmentTypesTableView: assessmentTypesTableViewReducer,
  // assessmentsTableView: assessmentsTableViewReducer,
  assetConditionTableView: assetConditionTableViewReducer,
  assetTypesTableView: assetTypesTableViewReducer,
  // assetsTableView: assetsTableViewReducer,
  bulkImportsTableView: bulkImportsTableViewReducer,
  checklistResultLinesTableView: checklistResultLinesTableViewReducer,
  checklistTasksTableView: checklistTasksTableViewReducer,
  checklistTemplatesTableView: checklistTemplatesTableViewReducer,
  checklistViewerTableView: checklistViewerTableViewReducer,
  // checklistsTableView: checklistsTableViewReducer,
  companiesTableView: companiesTableViewReducer,
  crewsTableView: crewsTableViewReducer,
  defectProfilesTableView: defectProfilesTableViewReducer,
  // defectsTableView: defectsTableViewReducer,
  inspectionMediaTableView: inspectionMediaTableViewReducer,
  librariesTableView: librariesTableViewReducer,
  libraryMediaTableView: libraryMediaTableViewReducer,
  locationLayerProfilesTableView: locationLayerProfilesTableViewReducer,
  // measurementsTableView: measurementsTableViewReducer,
  notificationRuleSubscribersTableView: notificationRuleSubscribersTableViewReducer,
  notificationRulesTableView: notificationRulesTableViewReducer,
  projectTypesTableView: projectTypesTableViewReducer,
  // projectsTableView: projectsTableViewReducer,
  // recommendedWorkPlanTableView: recommendedWorkPlanTableViewReducer,
  reportTypesTableView: reportTypesTableViewReducer,
  reservationsTableView: reservationsTableViewReducer,
  // scheduleEventsTableView: scheduleEventsTableViewReducer,
  schedulePlanTableView: schedulePlanTableViewReducer,
  scheduleTasksTableView: scheduleTasksTableViewReducer,
  scheduleWorkTableView: scheduleWorkTableViewReducer,
  userNavView: userNavViewReducer,
  usersTableView: usersTableViewReducer,
  watchersTableView: watchersTableViewReducer,
};
