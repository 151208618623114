import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';
import { fieldOrder, removeField, hideField, FORM_TYPE } from './checklistTemplatesShared';
import { jsonKeyToLabel } from '../../utilities/strings';
import CommonFormView from '../shared/form/CommonView';

const ChecklistTemplates = props => {
  const { data, loading, error } = props;

  // const classes = useStyles();

  // check for checklist object on newly created template
  const viewData = data;
  const id = data.id;

  const formSettings = {
    fieldOrder,
    removeField: removeField(FORM_TYPE.VIEW),
    hideField,
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      // cast to string because booleans do not show up in the field
      let displayLabel = jsonKeyToLabel(key); // eslint-disable-line prefer-const
      let displayValue = '';
      if (viewData[key] != null || key === 'actions') {
        switch (key) {
          // add cases for each key that needs to be customized.
          case 'categories':
            fieldSettings[key] = {
              type: 'chips',
              fieldProps: {
                label: displayLabel,
                value: viewData[key],
              },
            };
            return;
          case 'default_actions':
            fieldSettings[key] = {
              type: 'chips',
              fieldProps: {
                label: displayLabel,
                value: viewData[key],
              },
            };
            return;
          case 'definitions':
          case 'sections':
            displayValue = JSON.stringify(viewData[key]);
            break;
          default:
            displayValue = viewData[key].toString();
        }
      }
      fieldSettings[key] = {
        type: 'text',
        fieldProps: {
          label: displayLabel,
          value: displayValue,
        },
        cellProps: {
          xs: 6,
        },
      };
    });
  }

  return (
    <>
      {data.warnings &&
        data.warnings.map(w => (
          <Typography color="primary" key={w}>
            {w}
          </Typography>
        ))}
      <CommonFormView
        title={'Checklist Template Detail'}
        bodyOnly
        values={viewData}
        fieldSettings={fieldSettings}
        formSettings={formSettings}
        editLink={`/checklist-templates/${id}/edit`}
        loading={loading}
        error={error}
      />
    </>
  );
};

ChecklistTemplates.defaultProps = {
  data: {},
  error: '',
  loading: false,
};

ChecklistTemplates.propTypes = {
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.string,
};

export default ChecklistTemplates;
