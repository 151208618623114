import React, { useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Grid, Tabs, Tab, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import CmlsOverview from './CmlsOverview';
import Loading from '../shared/displays/Loading';
import ButtonIcon from '../shared/buttons/ButtonIcon';
import PrimaryButton from '../shared/buttons/PrimaryButton';
import { useFeatureFlags, usePermissions } from '../../hooks/settingsHooks';
import StyledLink from '../shared/StyledLink';
import { getCmls } from '../../store/features/cmlsActions';
import TabPanel from '../shared/TabPanel';
import MediaTab from '../shared/TabComponents/MediaTab';
import MeasurementsTab from '../shared/TabComponents/MeasurementsTab';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 'auto'),
    padding: theme.spacing(2),
    textAlign: 'left',
    overflow: 'auto',
  },
  header: {
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
    // justify: 'left',
  },
  button: {
    padding: theme.spacing(0, 1),
  },
  table: {
    padding: theme.spacing(0, 3),
  },
  cmlNameTitle: {
    fontSize: theme.spacing(5),
  },
  tabPanel: {
    marginTop: 0,
  },
}));

const CmlsDetail = () => {
  const { data, loading, error } = useSelector(state => state.cmls.each);

  let { id, tab: selectedTab } = useParams();
  selectedTab = selectedTab || 'overview'; // default to overview tab
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { hasCmlMedia } = useFeatureFlags();
  const { hasAssetView } = usePermissions();

  useEffect(() => {
    dispatch(getCmls(id));
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const classes = useStyles();

  const handleBack = () => {
    if (location.state && location.state.back) {
      history.push(location.state.back);
    } else {
      const next = location.pathname.match(/(.*?)\/\w+$/)[1];
      history.push(next);
    }
  };

  const handleEdit = () => {
    history.push(`/cmls/${id}/edit`);
  };

  const assetPath = () => {
    if (Object.keys(data).length !== 0) {
      const assetLink = hasAssetView ? `/assets/${data?.asset.id}` : '';
      return (
        <>
          <StyledLink to={assetLink} value={data.asset.name} />
          {` / ${data.name}`}
        </>
      );
    }
  };

  const tabs = [
    {
      value: 'overview',
      label: 'Overview',
      Component: <CmlsOverview id={id} data={data} loading={loading} error={error} />,
      hide: false,
    },
    {
      value: 'measurements',
      label: 'Measurements',
      Component: <MeasurementsTab detailPageQuery={{ cml: id }} filename={`${data?.name} Measurements`} hideCML />,
      hide: false,
    },
    {
      value: 'media',
      label: 'Media',
      hide: !hasCmlMedia,
      Component: <MediaTab detailPageQuery={{ cml: id }} filename={`${data?.name} Media`} />,
    },
  ];

  let readyToRender = false;

  const handleTabChange = (event, value) => {
    history.push(`/cmls/${id}/` + value);
  };

  switch (selectedTab) {
    case 'overview':
    case 'measurements':
    case 'media':
      readyToRender = true;
      break;
    default:
      history.replace(`/cmls/${id}`);
  }
  // the Tabs component doesn't handle value=undefined
  if (!readyToRender) return <></>;

  return (
    <div className={classes.root}>
      <Loading loading={loading} />
      <Grid container>
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.header}>
            <Grid item>
              <ButtonIcon buttonSize="medium" iconSize="medium" icon={ArrowBackIosIcon} onClick={handleBack} />
            </Grid>
            <Grid item className={classes.title}>
              {<p className={`h7 ${classes.cmlNameTitle}`}>{assetPath()}</p>}
            </Grid>
            <Grid item>
              <PrimaryButton label="Edit" icon Icon={EditIcon} onClick={handleEdit} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary">
            {tabs
              .filter(tab => !tab.hide)
              .map(tab => (
                <Tab
                  id={`cml-tab-${tab.value}`}
                  aria-controls={`cml-tabpanel-${tab.value}`}
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                />
              ))}
          </Tabs>
          <Divider className={classes.divider} />
          {tabs.map(tab => (
            <TabPanel key={tab.value} value={tab.value} selectedTab={selectedTab} name={'cml'}>
              <Grid item className={classes.table}>
                {tab.Component}
              </Grid>
            </TabPanel>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default CmlsDetail;
