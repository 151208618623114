import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// import { getCompanies } from '../../store/features/companiesActions';
import { fieldOrder, removeField, hideField } from './companiesShared';
import { buildRoute, apiDateToString, jsonKeyToLabel } from '../../utilities/strings';
import CommonFormView from '../shared/form/CommonView';

const Companies = () => {
  const id = useParams().id;

  const { data, loading, error } = useSelector((state) => state.companies.each);

  const formSettings = {
    fieldOrder,
    removeField: removeField('VIEW'),
    hideField,
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach((key) => {
      // cast to string because booleans do not show up in the field
      let displayLabel = jsonKeyToLabel(key); // eslint-disable-line prefer-const
      let displayValue = '';
      if (data[key] != null) {
        switch (key) {
          case 'description':
            fieldSettings[key] = {
              type: 'text',
              cellProps: {
                xs: 12,
              },
              fieldProps: {
                label: displayLabel,
                value: data[key],
              },
            };
            return;
          case 'labels':
            fieldSettings[key] = {
              type: 'key-value',
              fieldProps: {
                label: displayLabel,
                value: data[key],
              },
            };
            return;
          case 'updated_on':
          case 'created_on':
            displayValue = apiDateToString(data[key], 'date');
            break;
          default:
            displayValue = data[key].toString();
        }
      }
      fieldSettings[key] = {
        type: 'text',
        cellProps: {
          xs: 6,
        },
        fieldProps: {
          label: displayLabel,
          value: displayValue,
        },
      };
    });
  }

  return (
    <CommonFormView
      bodyOnly
      title={'Company Details'}
      values={data}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      editLink={buildRoute(`/companies/${id}/edit`)}
      loading={loading}
      error={error}
    />
  );
};

export default Companies;
