import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@mui/material';
import PhotoAlbumIcon from '@mui/icons-material/PhotoAlbum';

const CustomToolbarMediaGallery = props => {
  const { showGallery, setShowGallery } = props;

  const handleMediaGalleryClick = () => {
    setShowGallery(!showGallery);
  };
  return (
    <Tooltip title="Show Gallery">
      <IconButton onClick={handleMediaGalleryClick} size="large">
        <PhotoAlbumIcon />
      </IconButton>
    </Tooltip>
  );
};

CustomToolbarMediaGallery.propTypes = {
  showGallery: PropTypes.bool,
  setShowGallery: PropTypes.func,
};

export default CustomToolbarMediaGallery;
