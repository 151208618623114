import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import DefectsTable from '../defects/DefectsTable';
import SecondaryButton from '../shared/buttons/SecondaryButton';
import FullScreenDialog from '../shared/FullScreenDialog';
import { getAllDefects } from '../../store/features/defectsActions';
import { ProjectScopeContext } from '../projects/projectScope-context';
import { scopeModes } from './projectsShared';

const SelectFindingsDialog = () => {
  const dispatch = useDispatch();
  const { openFindingsDialog, setOpenFindingsDialog } = useContext(ProjectScopeContext);

  const tableChangeHandler = query => {
    dispatch(getAllDefects(query));
  };

  return (
    <FullScreenDialog open={openFindingsDialog}>
      <FullScreenDialog.AppBar>
        <Grid item>
          <SecondaryButton label="Cancel" onClick={() => setOpenFindingsDialog(false)}></SecondaryButton>
        </Grid>
      </FullScreenDialog.AppBar>
      <FullScreenDialog.Container>
        <DefectsTable
          title="Select Findings"
          scopeSelect
          scopeMode={scopeModes.SELECT}
          tableChangeHandler={tableChangeHandler}
        />
      </FullScreenDialog.Container>
    </FullScreenDialog>
  );
};

export default SelectFindingsDialog;
