/**
 * fieldOrder
 *
 * An object where the keys define the sections of the form.   Each section requires the key display (string) and
 * fields (array of strings). Fields are the api keys (in snake case) for each field that will be displayed in the form.
 * Any fields that are never shown should not be added to this array.
 *
 * @return {object}
 */

export const fieldOrder = {
  defect: {
    display: 'Finding',
    fields: ['asset', 'description', 'overlays'],
  },
  location: {
    display: 'Location',
    fields: [
      'component',
      'component_display',
      'location_zone',
      'location_zone_display',
      'location_code',
      'location_code_display',
      'access',
      'access_display',
      'geo_point',
    ],
  },
  category: {
    display: 'Category',
    fields: ['severity', 'severity_display', 'type', 'type_display', 'sub_type', 'sub_type_display', 'labels'],
  },
  size: {
    display: 'Size',
    fields: ['length', 'width', 'area'],
  },
  priority: {
    display: 'Priority',
    fields: ['priority', 'repair_by', 'next_inspection_date'],
  },
  status: {
    display: 'Status',
    fields: ['state', 'state_note', 'captured_on', 'resolved_on', 'created_on', 'updated_on', 'resolution_duration'],
  },
  source: {
    display: 'Source',
    fields: ['profile', 'layer', 'project_name', 'newer_defect', 'external_id'],
  },
};
export const helperText = {
  captured_on: 'Date the finding was identified (may differ from creation date)',
  external_id: 'ID from external system (optional)',
  geo_point: 'Latitude,Longitude coordinates.',
  next_inspection_date: 'Next inspection due date (optional).',
  priority: 'Indicate importance (optional)',
  repair_by: 'Deadline for repair.',
  resolved_on: 'Date of resolution.',
  state_note: 'Reason for state change (optional).',
  state: 'The current state of the finding.',
};
// once `defect.is_locked`,
//  cannot edit, excluding the following:
const NON_PROTECTED_FIELDS = ['state', 'priority', 'repair_by', 'next_inspection_date', 'resolved_on', 'state_note'];

/**
 *  removeField
 *
 *  removes a field depending on if a user is creating, updating or viewing.
 *
 *  @param {string} state - enum for creating, updating or viewing ['CREATE', 'UPDATE', 'VIEW']
 *  @param {string} item - api key (in snake case) to check whether it should be hidden.
 *  @return {boolean} - returns true if the item will not be shown
 */
export const removeField = state => item => {
  let fields = [];
  switch (state) {
    case 'CREATE':
      fields = ['created_on', 'updated_on', 'newer_defect', 'state_note', 'resolution_duration', 'project_name'];
      break;
    case 'UPDATE':
      fields = [
        'access_display',
        'asset',
        'component_display',
        'created_on',
        'layer',
        'location_code_display',
        'location_zone_display',
        'newer_defect',
        'overlays',
        'profile',
        'project_name',
        'resolution_duration',
        'severity_display',
        'source',
        'sub_type_display',
        'type_display',
        'updated_on',
      ];
      break;
    case 'VIEW':
      fields = ['type', 'sub_type', 'severity', 'component', 'location_zone', 'location_code', 'access', 'state_note'];
      break;
    case 'BULK-UPDATE':
      fields = [
        'access_display',
        'area',
        'asset',
        'component_display',
        'created_on',
        'external_id',
        'layer',
        'length',
        'location_code_display',
        'location_zone_display',
        'newer_defect',
        'overlays',
        'profile',
        'project_name',
        'resolution_duration',
        'severity_display',
        'source',
        'sub_type_display',
        'type_display',
        'updated_on',
        'width',
      ];
      break;
    default:
      return false;
  }
  return fields.includes(item);
};

/**
 * See context https://github.com/huvrdata/huvr/issues/6723
 *
 * @param {Object} defect
 * @param {Boolean} isUpdating
 * @param {Boolean} hasDefectEditProtected
 *
 * @returns {Boolean}
 */
export const shouldHideProtectedFields = (defect, isUpdating, hasDefectEditProtected) => {
  return isUpdating && defect?.is_locked && !hasDefectEditProtected;
};

/**
 * hideField
 *
 * This function is for hiding a field depending on the value of another field.
 *
 * @param {object} values - the values of all the fields in the form.
 * @param {string} item - api key (in snake case) to check whether it should be removed.
 * @param {Object} options { defectProfile, locationLayerProfile, hasAccessDisplay, hideProtectedFields }
 * @param {Object} options.defectProfile
 * @param {Object} options.locationLayerProfile
 * @param {Boolean} options.hasAccessDisplay
 * @param {Boolean} options.hideProtectedFields pass result of `shouldHideProtectedFields()` helper
 * @return {boolean} - returns true if the item will not be shown
 */
export const hideField = (values, item, options = {}) => {
  const {
    defectProfile,
    locationLayerProfile,
    hasAccessDisplay,
    hasFindingsPrioritization,
    hideProtectedFields,
    multiple = false,
  } = options;

  // hide protected fields
  if (hideProtectedFields && !NON_PROTECTED_FIELDS.includes(item)) {
    return true;
  }

  if (NON_PROTECTED_FIELDS.includes(item)) {
    switch (item) {
      case 'priority':
      case 'repair_by':
      case 'next_inspection_date':
        return !hasFindingsPrioritization;
      default:
        return false; // don't hide the field
    }
  }

  // fields dependent on defect or location profile
  // must be only editing fields from one location layer or one defect profile, not multiple
  if (!defectProfile || !locationLayerProfile || multiple) {
    return true;
  }
  switch (item) {
    case 'location_code':
    case 'location_code_display':
      return locationLayerProfile.code_mode === 'OFF';
    case 'location_zone':
    case 'location_zone_display':
      return locationLayerProfile.zone_mode === 'OFF';
    case 'access':
    case 'access_display':
      return !hasAccessDisplay || locationLayerProfile.access_mode === 'OFF';
    case 'asset':
      return locationLayerProfile.component_mode !== 'ASSET';
    case 'severity':
    case 'severity_display':
      return defectProfile.severity_mode === 'OFF';
    case 'type':
    case 'type_display':
      return defectProfile.type_mode === 'OFF';
    case 'labels':
      return defectProfile.label_mode === 'OFF';
    case 'sub_type':
    case 'sub_type_display':
      return defectProfile.sub_type_mode === 'OFF';
    default:
      return false;
  }
};
