import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Divider, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { getHelp } from '../../store/getters/helpActions';
import Markdown from '../shared/Markdown';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(7, 'auto'),
    padding: theme.spacing(0, 5),
  },
  descriptionBody: {
    textAlign: 'justify',
    padding: theme.spacing(2, 10),
  },
  support: {
    width: '100%',
  },
  iframe: {
    margin: theme.spacing(4, 0),
  },
  title: {
    textAlign: 'left',
    padding: theme.spacing(3, 0, 0, 10),
  },
}));

const Support = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const helpTopics = useSelector(state => state.help.data.results || []);
  useEffect(() => {
    dispatch(getHelp({ help_type: 'TOPIC' }));
  }, []); // eslint-disable-line
  return (
    <Grid container className={classes.root} direction="column" alignItems="center">
      {helpTopics.length > 0 ? (
        helpTopics.map(helpTopic => {
          return (
            <Grid item key={helpTopic.id} className={classes.support}>
              <Paper>
                <h2 className={classes.title}>{helpTopic.title}</h2>
                <Divider />
                {helpTopic.video && (
                  <iframe
                    className={classes.iframe}
                    src={helpTopic.video}
                    width="640"
                    height="360"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen></iframe>
                )}
                <Markdown className={classes.descriptionBody}>{helpTopic.body}</Markdown>
              </Paper>
            </Grid>
          );
        })
      ) : (
        <Paper className={classes.support}>
          <h1>No media available.</h1>
          <p>Please contact support at (512)371-6231 or support@huvrdata.com</p>
        </Paper>
      )}
    </Grid>
  );
};

export default Support;
