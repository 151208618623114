import * as Sentry from '@sentry/browser';
import { getColumnNames } from '../../../utilities/tables';

export function setColumns(columns, views, columnsToExclude = {}) {
  columns.findIndexByName = getColumnNames(columns);
  for (let x = 0; x < columns.length; x++) {
    // hide a column if the corresponding name is 'remove' in redux.  This assumes that all columns are shown by default.
    // if the current column is in views, set display if the value is remove.
    const colName = columns[x].name;

    if (columnsToExclude[colName]) {
      columns[x].options.display = 'excluded';
      continue;
    }

    if (columns[x].options.display === 'excluded') {
      // allows feature flags to turn off columns even if the are in the view
      continue;
    }

    // if viewColumns is not enabled for a certain column, always show it.
    if (columns[x].options.viewColumns === false) continue;

    if (views && views[colName]) {
      columns[x].options.display = !(views[colName] === 'remove');
    }
  }
}

export const sortByKey = (order, obj1, obj2, key) => {
  const val1 = obj1.data ? obj1.data[key] : '';
  const val2 = obj2.data ? obj2.data[key] : '';
  if (order === 'asc') {
    return val1 > val2 ? 1 : -1;
  }
  if (order === 'desc') {
    return val1 < val2 ? 1 : -1;
  }
};

export const getIdFromRow = (rowObj, displayData = [], columns) => {
  const columnIdx = columns.findIndexByName['id'];
  if (columnIdx !== undefined) {
    return displayData[rowObj.dataIndex]?.data[columnIdx];
  } else {
    // someone took my column!
    Sentry.captureMessage('Toolbar missing column id');
  }
};
