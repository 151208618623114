import { put, takeLatest } from 'redux-saga/effects';

import { defaultErrorCatch, defaultErrorHandler } from '../../../api/base';
import { fetchListAll } from '../../../utilities/sagaHelpers';
import { buildEndpointActionTypes, buildEndpointActionCreators } from './actions';

/**
 * @param {String} endpointName
 * @param {Function} apiRequest
 * @param {Object} options
 * @param {Boolean=} options.batchFetchAll
 * @returns {Function} - the saga function
 */
export const buildEndpointSaga = (endpointName, apiRequest, options) => {
  const actionTypes = buildEndpointActionTypes(endpointName);
  const actionCreators = buildEndpointActionCreators(endpointName);

  /**
   * Handles the api request
   *  - calls the apiRequest function
   *  - dispatches the success or failure action
   *  - resolves or rejects the promise
   *
   * See redux-saga docs to understand any of the funky "yield"/"put"/"function*" stuff
   *   https://redux-saga.js.org
   */
  const saga = function* () {
    yield takeLatest(actionTypes.request, function* (action) {
      const { apiRequestArgs, resolve: _resolve, reject: _reject } = action;
      const resolve = (...args) => typeof _resolve === 'function' && _resolve(...args);
      const reject = (...args) => typeof _reject === 'function' && _reject(...args);

      // base request handler
      try {
        let data, error, formError, response;

        if (options.batchFetchAll) {
          const query = apiRequestArgs[0];
          ({ data, error, formError, response } = yield fetchListAll(query => apiRequest(query), query));
        } else {
          ({ data, error, formError, response } = yield apiRequest(...apiRequestArgs));
        }

        if (data) {
          yield put(actionCreators.success(data));
          resolve({ data, apiRequestArgs, response });
        } else if (error) {
          yield put(actionCreators.failure(error));
          reject({ error, apiRequestArgs, response });
        } else if (formError) {
          yield put(actionCreators.failure(null, formError));
          reject({ formError, apiRequestArgs, response });
        } else {
          yield defaultErrorHandler(response, actionTypes.failure);
          reject({ apiRequestArgs, response });
        }
      } catch (error) {
        yield defaultErrorCatch(error, actionTypes.failure);

        reject({ apiRequestArgs, error });
      }
    });
  };

  return saga;
};
