// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import { combineReducers } from 'redux';
import {
  ASSESSMENT_TYPES_REQUEST,
  ASSESSMENT_TYPES_SUCCESS,
  ASSESSMENT_TYPES_FAILURE,
  ASSESSMENT_TYPES_CREATE_REQUEST,
  ASSESSMENT_TYPES_CREATE_SUCCESS,
  ASSESSMENT_TYPES_CREATE_FAILURE,
  ASSESSMENT_TYPES_UPDATE_REQUEST,
  ASSESSMENT_TYPES_UPDATE_SUCCESS,
  ASSESSMENT_TYPES_UPDATE_FAILURE,
  ASSESSMENT_TYPES_DELETE_REQUEST,
  ASSESSMENT_TYPES_DELETE_SUCCESS,
  ASSESSMENT_TYPES_DELETE_FAILURE,
  ASSESSMENT_TYPES_ALL_REQUEST,
  ASSESSMENT_TYPES_ALL_SUCCESS,
  ASSESSMENT_TYPES_ALL_FAILURE,
} from './assessmentTypesActions';

export const initialStateAssessmentTypes = {
  loading: false,
  error: '',
  formError: {},
  id: 0,
  body: {},
  data: {},
};

export const initialStateAllAssessmentTypes = {
  loading: false,
  error: '',
  query: {},
  count: 0,
  dataAll: {},
};

export const resetStateAssessmentTypes = {
  loading: false,
  error: '',
  formError: {},
  data: {},
};
export const resetStateAllAssessmentTypes = {
  loading: false,
  error: '',
  query: {},
  dataAll: [],
};

export const assessmentTypesEachReducer = (state = initialStateAssessmentTypes, action) => {
  switch (action.type) {
    case ASSESSMENT_TYPES_REQUEST:
      return {
        ...initialStateAssessmentTypes,
        loading: true,
        id: action.id,
      };
    case ASSESSMENT_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case ASSESSMENT_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ASSESSMENT_TYPES_CREATE_REQUEST:
      return {
        ...initialStateAssessmentTypes,
        loading: true,
        body: action.body,
      };
    case ASSESSMENT_TYPES_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case ASSESSMENT_TYPES_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case ASSESSMENT_TYPES_UPDATE_REQUEST:
      return {
        ...initialStateAssessmentTypes,
        loading: true,
        id: action.id,
        body: action.body,
      };
    case ASSESSMENT_TYPES_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case ASSESSMENT_TYPES_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case ASSESSMENT_TYPES_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        formError: {},
        id: action.id,
      };
    case ASSESSMENT_TYPES_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ASSESSMENT_TYPES_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    default:
      return state;
  }
};

export const assessmentTypesAllReducer = (state = initialStateAllAssessmentTypes, action) => {
  switch (action.type) {
    case ASSESSMENT_TYPES_ALL_REQUEST:
      return {
        ...initialStateAllAssessmentTypes,
        loading: true,
        query: action.query,
      };
    case ASSESSMENT_TYPES_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        dataAll: action.data,
      };
    case ASSESSMENT_TYPES_ALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default combineReducers({
  each: assessmentTypesEachReducer,
  all: assessmentTypesAllReducer,
});
