import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { ButtonGroup, IconButton, Tooltip, Typography } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ShareIcon from '@mui/icons-material/Share';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
// import { locationWithBack } from '../../../utilities/route';
import { openDialog } from '../../store/dialogActions';
import DeleteDialog from '../shared/Dialog/DeleteDialog';
import ProjectsBulkEditDialog from './ProjectsBulkEditDialog';
import ProjectsBulkShareDialog from './ProjectsBulkShareDialog';
import { deleteProjectsBulk } from '../../store/features/projectsActions';
import { useFeatureFlags, usePermissions } from '../../hooks/settingsHooks';
import ProjectStatusBulkForm from './ProjectStatusBulkForm';

const ProjectToolBarDelete = props => {
  const { selectedRows, displayData, setSelectedRows, columnIndicesByName } = props;
  const dispatch = useDispatch();
  const [confirm, setConfirm] = useState();
  const [hasPublishedProjects, setHasPublishedProjects] = useState(false);
  const { hasProjectBulkEdit, hasProjectBulkEditStatus, hasBulkProjectShare } = useFeatureFlags();
  const { hasProjectDelete, hasProjectStatusUnpublish } = usePermissions();

  const getProjectIds = (rows, displayData) => {
    const selected = { ids: [], names: [], dataIndices: [] };
    for (let i = 0; i < rows.length; i++) {
      selected.ids.push(displayData[rows[i].dataIndex]?.data[0]);
      selected.names.push(displayData[rows[i].dataIndex]?.data[1]?.props?.value);
      selected.dataIndices.push(rows[i]?.dataIndex);
    }
    return selected;
  };

  const checkForPublishedProjects = Array.isArray(selectedRows)
    ? selectedRows.some(item => displayData[item.dataIndex]?.data[columnIndicesByName['status']]?.key === 'PUBLISHED')
    : false;

  const hasProjectStatusBulkEditDisabled = !hasProjectStatusUnpublish && hasPublishedProjects;

  useEffect(() => {
    checkForPublishedProjects ? setHasPublishedProjects(true) : setHasPublishedProjects(false);
  }, [selectedRows, checkForPublishedProjects]);

  useEffect(() => {
    if (confirm) {
      setSelectedRows([]);
    }
  }, [confirm, setSelectedRows]);

  const handleDelete = () => {
    const rowInfo = getProjectIds(selectedRows, displayData);
    const ids = {
      ids: rowInfo?.ids,
    };
    dispatch(
      openDialog(
        'Delete Projects?',
        <DeleteDialog id={ids} deleteAction={deleteProjectsBulk} confirm={setConfirm} name={rowInfo.names} />
      )
    );
    setSelectedRows(rowInfo.dataIndices);
  };

  const handleBulkEdit = () => {
    const rowInfo = getProjectIds(selectedRows, displayData);
    dispatch(
      openDialog(
        'Edit Projects',
        <ProjectsBulkEditDialog projects={rowInfo} deleteAction={deleteProjectsBulk} confirm={setConfirm} />,
        { fullScreen: true }
      )
    );
    setSelectedRows(rowInfo.dataIndices);
  };

  const handleBulkStatusEdit = () => {
    const rowInfo = getProjectIds(selectedRows, displayData);
    dispatch(openDialog('Project Status', <ProjectStatusBulkForm ids={rowInfo?.ids} />));
    setSelectedRows(rowInfo.dataIndices);
  };

  const handleBulkShare = () => {
    const rowInfo = getProjectIds(selectedRows, displayData);

    dispatch(
      openDialog(
        'Share Projects',
        <ProjectsBulkShareDialog projects={rowInfo} confirm={setConfirm} setSelectedRows={setSelectedRows} />,
        { fullScreen: true }
      )
    );
    setSelectedRows(rowInfo.dataIndices);
  };

  return (
    <>
      {hasPublishedProjects && (
        <Typography variant="subtitle1" color="error">
          Note: You cannot delete or edit Published projects.
        </Typography>
      )}
      <ButtonGroup>
        {hasBulkProjectShare && (
          <Tooltip title="Share Projects">
            <IconButton onClick={handleBulkShare}>
              <ShareIcon />
            </IconButton>
          </Tooltip>
        )}
        {hasProjectBulkEdit && (
          <>
            <Tooltip title="Edit Projects">
              <IconButton onClick={handleBulkEdit} disabled={hasPublishedProjects} size="large">
                <EditIcon />
              </IconButton>
            </Tooltip>
            {hasProjectBulkEditStatus && (
              <Tooltip title="Edit Project Status">
                <IconButton onClick={handleBulkStatusEdit} disabled={hasProjectStatusBulkEditDisabled} size="large">
                  <BorderColorIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
        {hasProjectDelete && (
          <Tooltip title="Delete Projects">
            <span>
              <IconButton onClick={handleDelete} disabled={hasPublishedProjects} size="large">
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </ButtonGroup>
    </>
  );
};

ProjectToolBarDelete.defaultProps = {
  selectedRows: [],
  displayData: [],
  setSelectedRows: () => {},
};

ProjectToolBarDelete.propTypes = {
  selectedRows: PropTypes.array,
  displayData: PropTypes.array,
  deleteAction: PropTypes.func.isRequired,
  setSelectedRows: PropTypes.func,
  columnIndicesByName: PropTypes.object.isRequired,
};

export default ProjectToolBarDelete;
