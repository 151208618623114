import React, { forwardRef } from 'react';
import { Container, Dialog, Slide, AppBar, Toolbar } from '@mui/material';

const SlideUpTransition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

/**
 * @description Full screen dialog, with slide up transition
 * @example
 *  <FullScreenDialog open={open} onClose={() => setOpen(false)}>
 *   <FullScreenDialog.AppBar>
 *     <Grid container justify="space-between">
 *        ...
 *      </Grid>
 *    </FullScreenDialog.AppBar>
 *    <FullScreenDialog.Container>
 *      ...
 *    </FullScreenDialog.Container>
 *  </FullScreenDialog>
 *
 * (using AppBar and Container components are optional - can use any components)
 */
export const FullScreenDialog = props => {
  return <Dialog fullScreen TransitionComponent={SlideUpTransition} {...props} />;
};

FullScreenDialog.propTypes = {
  ...Dialog.propTypes,
};

/**
 * @description AppBar / Toolbar for full screen dialog
 */
export const FullScreenDialogAppBar = props => {
  const { children, ...rest } = props;

  return (
    <AppBar position="relative" {...rest}>
      <Toolbar>{children}</Toolbar>
    </AppBar>
  );
};

FullScreenDialogAppBar.propTypes = {
  ...AppBar.propTypes,
};

/**
 * @description Container for full screen dialog
 */
export const FullScreenDialogContainer = props => {
  const { children, ...rest } = props;

  return (
    <Container style={{ position: 'relative' }} maxWidth={false} {...rest}>
      <br />
      {children}
    </Container>
  );
};

FullScreenDialogContainer.propTypes = {
  ...Container.propTypes,
};

export default FullScreenDialog;
FullScreenDialog.AppBar = FullScreenDialogAppBar;
FullScreenDialog.Container = FullScreenDialogContainer;
