import React, { useState, useEffect, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { CircularProgress, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import CornerstoneViewport from 'react-cornerstone-viewport';

import DicomToolbar from './DicomToolbar';
import cornerstone from '../../../utilities/cornerstone';
import { useFeatureFlags } from '../../../hooks/settingsHooks';

const useStyles = makeStyles(theme => ({
  dicomViewer: {
    height: '85%',
    width: '95%',
    margin: 'auto',
    position: 'relative',
    zIndex: 1,
  },
  permissionsText: {
    textAlign: 'center',
  },
  loadingContainer: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    position: 'absolute',
    zIndex: 2,
    top: '45%',
    left: '55%',
  },
}));

const DicomViewer = props => {
  const { file, id } = props;
  const { hasDicomViewer } = useFeatureFlags();

  const [viewerLoaded, setViewerLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  cornerstone.setOptions({
    onloadstart: () => setLoading(true),
    onloadend: () => setLoading(false),
  });

  useEffect(() => {
    cornerstone.init();
    setViewerLoaded(true);
  }, []);

  const classes = useStyles();
  const imageIds = useRef([`dicomweb:${file}`]);

  if (!hasDicomViewer) {
    return (
      <div className={classes.permissionsText}>
        Permissions required. Please contact support@huvrdata.com to view DICOM files.
      </div>
    );
  }

  const LoadingIndicator = () => {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress size={45} />
        <Typography>Loading...</Typography>
      </div>
    );
  };

  return (
    <div>
      <Grid container direction="column" justify="flex-start">
        <DicomToolbar id={id} cornerstoneTools={cornerstone} />
        {loading && <LoadingIndicator />}
        <Grid item>
          <div className={classes.dicomViewer}>
            {viewerLoaded && (
              <CornerstoneViewport
                imageIds={imageIds.current}
                imageIdIndex={0}
                style={{ minWidth: '100%', height: '512px', flex: '1' }}
                isOverlayVisible={true}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

DicomViewer.propTypes = {
  id: PropTypes.string.isRequired,
  file: PropTypes.string.isRequired,
};

export default DicomViewer;
