import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProjectTypes } from '../store/features/projectTypesActions';
import { shouldFetchState } from './shared';

const defaultProjectTypesQuery = { is_active: true, ordering: 'name' };

/**
 * helps prevent old default values from getting "stuck" in redux store table settings
 *
 * @param {Function} filterFn - component-specific filter function
 * @param {Object} query - query for project type request
 * @return {Object} - project type results and form options
 */

export const useCachedProjectTypes = (query = defaultProjectTypesQuery, filterFn) => {
  const dispatch = useDispatch();
  const {
    lastFetchedAt: lastFetch,
    dataAll: { results } = { results: [] },
    query: projectTypesQuery,
  } = useSelector(state => state.projectTypes.all);
  useEffect(() => {
    if (!query) return;

    const shouldFetch = shouldFetchState(query, projectTypesQuery, lastFetch);
    if (shouldFetch) {
      dispatch(getAllProjectTypes(query));
    }
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  const projectTypesMap = {};
  const options = results?.map(
    ({
      name,
      id,
      asset_types: assetTypes,
      project_children_mode: childrenMode,
      project_scope_settings: projectScopeSettings,
      checklist_template: checklistTemplate,
    }) => {
      const value = id.toString();
      projectTypesMap[value] = name;
      const label = name != null ? name : '';
      return { label, value, assetTypes, childrenMode, projectScopeSettings, checklistTemplate };
    }
  );

  let projectTypeOptions = options;
  if (filterFn) {
    projectTypeOptions = options.filter(filterFn);
  }
  return {
    results,
    projectTypeOptions,
    projectTypesMap,
  };
};
