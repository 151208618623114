import React from 'react';
import PropTypes from 'prop-types';
import { File } from './InputFile';
import { requiredFn } from '../../../utilities/validators';

/**
 * Component to add a file uploader field.
 */

const FieldEditFile = props => {
  const { name, label, value, placeholder, loading, disabled, required, helperText, accept, uploadText } = props;

  // to use the form values, the value prop needs to be missing (can't be undefined or empty string)
  // only show the value if it is passed in as a prop.
  const moreProps = {};
  if (value) moreProps.value = value;

  return (
    <File
      type="file"
      label={label}
      name={name}
      placeholder={placeholder}
      disabled={disabled || loading}
      variant="outlined"
      InputLabelProps={{ shrink: true }}
      fieldProps={{ validate: requiredFn(required) }}
      helperText={helperText}
      accept={accept}
      uploadText={uploadText}
      {...moreProps}
    />
  );
};

FieldEditFile.defaultProps = {
  disabled: false,
  required: false,
  value: undefined,
  placeholder: '',
  loading: false,
  helperText: '',
  fileChange: undefined,
  accept: '',
  uploadText: 'Click to upload file.',
};

FieldEditFile.propTypes = {
  /** label for the string field */
  label: PropTypes.string.isRequired,
  /** the api key for the feature */
  name: PropTypes.string.isRequired,
  /** initial value for the field */
  value: PropTypes.string,
  /** sets the placeholder for the string field */
  placeholder: PropTypes.string,
  /** sets the field as required for the form */
  required: PropTypes.bool,
  /** disables the field while true */
  disabled: PropTypes.bool,
  /** disables the filed while true */
  loading: PropTypes.bool,
  /** shows helper text */
  helperText: PropTypes.string,
  fileChange: PropTypes.func,
  /* Dropzone accepts all files unless accept is set. */
  accept: PropTypes.string,
  uploadText: PropTypes.string,
};

export default FieldEditFile;
