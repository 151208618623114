import {
  PAGES_GETTER_REQUEST,
  PAGES_GETTER_SUCCESS,
  PAGES_GETTER_FAILURE,
  PAGE_BY_PATH_GETTER_REQUEST,
  PAGE_BY_PATH_GETTER_SUCCESS,
  PAGE_BY_PATH_GETTER_FAILURE,
} from './pagesActions';

export const initialStatePages = {
  loading: false,
  error: '',
  data: {},
  query: {},
};

export const pagesReducer = (state = initialStatePages, action) => {
  switch (action.type) {
    case PAGES_GETTER_REQUEST:
    case PAGE_BY_PATH_GETTER_REQUEST:
      return {
        ...state,
        loading: true,
        query: action.query,
      };
    case PAGES_GETTER_SUCCESS:
    case PAGE_BY_PATH_GETTER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case PAGES_GETTER_FAILURE:
    case PAGE_BY_PATH_GETTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        data: {},
        query: {},
      };
    default:
      return state;
  }
};

export default pagesReducer;
