import { all, put, takeLatest, take, fork, cancel } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  ASSETS_REQUEST,
  ASSETS_SUCCESS,
  ASSETS_FAILURE,
  ASSETS_CREATE_REQUEST,
  ASSETS_CREATE_SUCCESS,
  ASSETS_CREATE_FAILURE,
  ASSETS_UPDATE_REQUEST,
  ASSETS_UPDATE_SUCCESS,
  ASSETS_UPDATE_FAILURE,
  ASSETS_DELETE_REQUEST,
  ASSETS_DELETE_SUCCESS,
  ASSETS_DELETE_FAILURE,
  ASSETS_ALL_REQUEST,
  ASSETS_ALL_SUCCESS,
  ASSETS_ALL_FAILURE,
  ASSETS_BATCH_ALL_SUCCESS,
  ASSETS_BATCH_ALL_REQUEST,
  ASSETS_BATCH_FAILURE,
  ASSETS_BATCH_SUCCESS,
  ASSETS_ABORT_BATCH_ALL,
} from './assetsActions';

import {
  fetchAssets,
  createAssets,
  updateAssets,
  deleteAssets,
  fetchAssetConditionMap,
  fetchAssetsList,
} from '../../api/features/assets';
import { history } from '../../providers/StoreProvider';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';
import { batchFetchAll } from '../../utilities/sagaHelpers';
import { DIALOG_CLOSE } from '../../store/dialogActions';
import { pathToPage } from '../../utilities/strings';
import { tableViews } from '../../utilities/tables';
import { SET_TABLE_QUERY } from '../../store/settings/tableActions';

const { assetsTableViewKey: tableType } = tableViews;

export function* assetsFetch(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield fetchAssets(id);
    if (data) {
      yield put({ type: ASSETS_SUCCESS, data });
    } else if (error) {
      yield put({ type: ASSETS_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, ASSETS_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, ASSETS_FAILURE);
  }
}

export function* watchAssetsFetch() {
  yield takeLatest(ASSETS_REQUEST, assetsFetch);
}

export function* assetsCreate(action) {
  const { body, nextRoute } = action;
  try {
    const { data, error, formError, response } = yield createAssets(body);
    if (data) {
      yield put({ type: ASSETS_CREATE_SUCCESS, data });
      if (nextRoute) {
        yield put(push(nextRoute));
      } else {
        yield put(push(`/assets/${data.id}`));
      }
    } else if (error) {
      yield put({ type: ASSETS_CREATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: ASSETS_CREATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, ASSETS_CREATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, ASSETS_CREATE_FAILURE);
  }
}

export function* watchAssetsCreate() {
  yield takeLatest(ASSETS_CREATE_REQUEST, assetsCreate);
}

export function* assetsUpdate(action) {
  const { id, body, nextRoute } = action;
  try {
    const { data, error, formError, response } = yield updateAssets(id, body);
    if (data) {
      yield put({ type: ASSETS_UPDATE_SUCCESS, data });
      if (nextRoute) {
        yield put(push(nextRoute));
      }
    } else if (error) {
      yield put({ type: ASSETS_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: ASSETS_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, ASSETS_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, ASSETS_UPDATE_FAILURE);
  }
}

export function* watchAssetsUpdate() {
  yield takeLatest(ASSETS_UPDATE_REQUEST, assetsUpdate);
}

export function* assetsDelete(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield deleteAssets(id);
    if (data) {
      yield put({ type: ASSETS_DELETE_SUCCESS });
      yield put({ type: ASSETS_ALL_REQUEST });
      yield put({ type: DIALOG_CLOSE });
      yield put(push('/assets'));
    } else if (error) {
      yield put({ type: ASSETS_DELETE_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, ASSETS_DELETE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, ASSETS_DELETE_FAILURE);
  }
}

export function* watchAssetsDelete() {
  yield takeLatest(ASSETS_DELETE_REQUEST, assetsDelete);
}

export function* assetsFetchAll(action) {
  const { query } = action; // pageInfo
  const location = history.location;
  try {
    const { data, error, response } = yield fetchAssetsList(action.query);
    if (data) {
      yield put({ type: ASSETS_ALL_SUCCESS, data });
    } else if (error) {
      yield put({ type: ASSETS_ALL_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, ASSETS_ALL_FAILURE);
    }
    // save the query in the store
    if (pathToPage(location.pathname)) {
      const page = pathToPage(location.pathname);
      yield put({ type: SET_TABLE_QUERY, page, query, tableType });
    }
  } catch (error) {
    yield defaultErrorCatch(error, ASSETS_ALL_FAILURE);
  }
}

export function* watchAssetsFetchAll() {
  yield takeLatest(ASSETS_ALL_REQUEST, assetsFetchAll);
}

export function* assetsBatchFetchAll(action) {
  try {
    const { data, error, response } = yield batchFetchAll(fetchAssetConditionMap, action.query, ASSETS_BATCH_SUCCESS);
    if (data) {
      yield put({ type: ASSETS_BATCH_ALL_SUCCESS });
    } else if (error) {
      yield put({ type: ASSETS_BATCH_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, ASSETS_BATCH_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, ASSETS_BATCH_FAILURE);
  } finally {
    // stops the task after success or failure
    yield put({ type: ASSETS_ABORT_BATCH_ALL });
  }
}

export function* watchAssetsBatchFetchAll() {
  /*
  this pattern deviation handles the cancellation of a batch request
  if another related action type is fired. in this case, a batch all request
  is canceled if 'running' when assetsFetchAll is called
  */
  while (true) {
    const action = yield take(ASSETS_BATCH_ALL_REQUEST);
    const task = yield fork(assetsBatchFetchAll, action);
    // task stops running on ASSETS_ALL_REQUEST or ASSETS_ABORT_BATCH_ALL
    const cancelAction = yield take([ASSETS_BATCH_ALL_REQUEST, ASSETS_ABORT_BATCH_ALL]);
    if (cancelAction.type === ASSETS_BATCH_ALL_REQUEST) {
      yield cancel(task);
    }
  }
}

export default function* assetsSaga() {
  yield all([
    watchAssetsFetch(),
    watchAssetsBatchFetchAll(),
    watchAssetsCreate(),
    watchAssetsUpdate(),
    watchAssetsDelete(),
    watchAssetsFetchAll(),
  ]);
}
