import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper, Divider, Checkbox, FormGroup, FormControlLabel, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';

import PrimaryButton from '../buttons/PrimaryButton';
import Markdown from '../Markdown';
import { getHelpTip } from '../../../store/getters/helpActions';

const useStyles = makeStyles(theme => ({
  helpTip: {
    margin: theme.spacing(3, 0, 3, 3),
    padding: theme.spacing(2),
    textAlign: 'left',
    width: '100%',
  },
  bodyPadding: {
    padding: theme.spacing(0, 4),
  },
  footerPadding: {
    padding: theme.spacing(0, 2),
  },
  iframe: {
    margin: 'auto',
  },
}));

const showHelpTipCheck = () => {
  const helpTipSnooze = Date.parse(window.localStorage.getItem('helpTipSnoozeDate'));
  const elapsed = Math.abs(Date.now() - helpTipSnooze) > 21 * 86400 * 1000;
  if (elapsed || isNaN(helpTipSnooze)) {
    // 3 weeks in Milliseconds
    // If snooze hasn't been checked, there will be no date, show
    window.localStorage.setItem('showHelpTip', true);
    return true;
  }
  window.localStorage.setItem('showHelpTip', false);
  return false;
};

const HelpTip = props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const showHelpTip = showHelpTipCheck();
  const [checked, setChecked] = useState(false);
  const [showHelp, setShowHelp] = useState(showHelpTip);

  const { helpTip, loading } = useSelector(state => ({
    helpTip: state.help.dataTIP.results,
    loading: state.help.loading,
  }));
  const message = (helpTip?.length > 0 && helpTip[0]) || null;

  useEffect(() => {
    if (showHelp) {
      dispatch(getHelpTip());
    }
  }, [dispatch, showHelp]);

  const handleClicked = () => {
    if (checked) {
      window.localStorage.setItem('helpTipSnoozeDate', Date());
    }
    window.localStorage.setItem('showHelpTip', false);
    setShowHelp(false);
  };
  const handleOnChange = event => {
    setChecked(!checked);
  };
  const isHelpHidden = () => {
    if (loading) return true;
    if (showHelp && message) return false;
    return true;
  };

  if (isHelpHidden()) return null;

  return (
    <Paper className={classes.helpTip}>
      <Grid container alignItems="stretch" direction="column">
        <Grid item>
          <Grid container justifyContent="space-between" className={classes.footerPadding}>
            <Grid item>&nbsp;</Grid>
            <Grid item>
              <h2>{message.title}</h2>
            </Grid>
            <Grid item>
              <CloseIcon onClick={handleClicked} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item className={classes.iframe}>
          {message.video && (
            <iframe
              src={message.video}
              width="640"
              height="360"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen></iframe>
          )}
        </Grid>
        <Grid item className={classes.bodyPadding}>
          <Markdown multilineBlockMargins>{message.body}</Markdown>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item className={classes.footerPadding}>
          <FormGroup row>
            <Grid container justifyContent="space-between">
              <Grid item>
                <FormControlLabel
                  control={<Checkbox checked={checked} color="primary" variant="outlined" onChange={handleOnChange} />}
                  label=" &nbsp; Remind me later"
                />
              </Grid>
              <Grid item>
                <PrimaryButton label="Close" onClick={handleClicked} />
              </Grid>
            </Grid>
          </FormGroup>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default HelpTip;
