import React from 'react';
import PropTypes from 'prop-types';
import prettyBytes from 'pretty-bytes';

const PrettyBytes = props => {
  const { num, label } = props;

  return (
    <>
      <div className="h7">{label}</div>
      <div>{prettyBytes(num)}</div>
    </>
  );
};

PrettyBytes.defaultProps = {
  num: 0,
  label: '',
};

PrettyBytes.propTypes = {
  num: PropTypes.number,
  label: PropTypes.string,
};

export default PrettyBytes;
