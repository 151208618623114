import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Check from '@mui/icons-material/Check';
import Autorenew from '@mui/icons-material/Autorenew';
import { makeStyles } from '@mui/styles';

/**
 * Button for Next, Apply, Submit or similar buttons using the primary color theme.
 * It can show an icon.
 */
const PrimaryButton = props => {
  const { type, variant, label, icon, Icon, loading, disabled, onClick, tooltip, tooltipProps, testid } = props;

  const useStyles = makeStyles(theme => ({
    'spin-icon': {
      animation: '$spin 1s infinite linear',
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  }));
  const classes = useStyles();

  const moreProps = {};
  if (icon) {
    const renderIcon = () => {
      let ButtonIcon = Icon;
      if (!ButtonIcon) {
        ButtonIcon = Check;
      }

      if (loading) {
        return <Autorenew className={loading ? classes['spin-icon'] : ''} />;
      }
      return <ButtonIcon />;
    };
    moreProps.startIcon = renderIcon();
  }

  const renderButton = () => (
    <Button
      color="primary"
      type={type}
      variant={variant}
      onClick={onClick}
      disabled={disabled || loading}
      data-testid={testid}
      {...moreProps}>
      <span className={classes.mylabel}>{label}</span>
    </Button>
  );

  if (tooltip) {
    // must wrap in span https://stackoverflow.com/questions/56347839/material-ui-v4-0-1-warning-expected-an-element-that-can-hold-a-ref/63521049#63521049
    return (
      <Tooltip title={tooltip} {...tooltipProps}>
        <span>{renderButton()}</span>
      </Tooltip>
    );
  } else {
    return renderButton();
  }
};

PrimaryButton.defaultProps = {
  type: 'button',
  variant: 'contained',
  label: 'Apply',
  icon: false,
  Icon: null,
  loading: false,
  disabled: false,
  onClick: null,
};

PrimaryButton.propTypes = {
  /** sets the button type */
  type: PropTypes.string,
  /** sets the button variant */
  variant: PropTypes.string,
  /** sets the button label */
  label: PropTypes.string,
  /** enable or disable the <em>Icon</em> prop */
  icon: PropTypes.bool,
  /** if <em>icon</em> is true, sets the custom svg icon */
  Icon: PropTypes.object,
  /** disables button and if <em>icon</em> show spinning loading icon */
  loading: PropTypes.bool,
  /** disables button */
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
  tooltipProps: PropTypes.object,
  /** callback for onClick event */
  onClick: PropTypes.func,
};

export default PrimaryButton;
