// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import React from 'react';
import CommonRoutes from './utilities/CommonRoutes';
import LibraryMediaForm from '../../components/libraryMedia/LibraryMediaForm';
import LibraryMediaView from '../../components/libraryMedia/LibraryMediaView';
// import LibraryMediaTable from '../../components/libraryMedia/LibraryMediaTable';
import MainLayout from '../layouts/MainLayout';

const LibraryMediaRoutes = props => {
  return (
    <CommonRoutes
      pageTitle="LibraryMedia"
      layout={MainLayout}
      Edit={<LibraryMediaForm update />}
      Create={<LibraryMediaForm />}
      View={<LibraryMediaView />}
      // All={<LibraryMediaTable />}
    />
  );
};

export default LibraryMediaRoutes;
