import React from 'react';
import PropTypes from 'prop-types';

// lib
import { Field } from 'react-final-form';
import { FormControl } from '@mui/material';
import Markdown from '../../shared/Markdown';

const CollectMediaLineItem = props => {
  const { label, name, helperText } = props;

  return (
    <FormControl fullWidth={true}>
      <Markdown>{label}</Markdown>
      <Field name={name} type="hidden" render={() => <></>} />
      {helperText}
    </FormControl>
  );
};

CollectMediaLineItem.defaultProps = {};

CollectMediaLineItem.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  value: PropTypes.string, // not used currently
};

export default CollectMediaLineItem;
