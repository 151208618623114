import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../store/dialogActions';

import RecommendedWorkPlanBulkEditForm from './RecommendedWorkPlanBulkEditForm';

const RecommendedWorkPlanBulkEditDialog = props => {
  const { recommendedWorkPlanIds } = props;
  const dispatch = useDispatch();

  return (
    <Grid container direction="column" justifyContent="space-between">
      <Grid item>
        Select the fields you wish to update on the {recommendedWorkPlanIds?.length} selected Recommended Work Plan.
      </Grid>
      <Grid item>
        <RecommendedWorkPlanBulkEditForm
          recommendedWorkPlanIds={recommendedWorkPlanIds}
          onSubmit={() => {
            dispatch(closeDialog());
          }}
        />
      </Grid>
    </Grid>
  );
};

RecommendedWorkPlanBulkEditDialog.defaultProps = {
  recommendedWorkPlanIds: [],
};

RecommendedWorkPlanBulkEditDialog.propTypes = {
  recommendedWorkPlanIds: PropTypes.array.isRequired,
};

export default RecommendedWorkPlanBulkEditDialog;
