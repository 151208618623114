// lib
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from '@mui/icons-material/Edit';

// components
import { ProjectScopeContext } from './projectScope-context';
import ProjectSelectedFindingsTable from './ProjectSelectedFindingsTable';
import ProjectSelectedCmlsTable from './ProjectSelectedCmlsTable';
import LibraryMediaTable from '../libraryMedia/LibraryMediaTable';
import ButtonIcon from '../shared/buttons/ButtonIcon';
import Title from '../shared/displays/Title';
import Markdown from '../shared/Markdown';
import FormDialog from '../shared/Dialog/FormDialog';

// utils
import { useFeatureFlags } from '../../hooks/settingsHooks';
import { openDialog } from '../../store/dialogActions';
import { updateProjects } from '../../store/features/projectsActions';

const useStyles = makeStyles(theme => ({
  projectScope: {
    marginRight: theme.spacing(4),
    padding: theme.spacing(0, 2, 2, 2),
  },
  projectScopeHeader: {
    margin: theme.spacing(1, 4),
    fontSize: theme.spacing(3),
  },
  projectScopeDescription: {
    margin: theme.spacing(1, 0, 4, 0.5),
    padding: theme.spacing(0.5),
  },
  projectScopeDescriptionPaper: {
    padding: theme.spacing(2),
  },
  projectScopeDescriptionEditButton: {
    marginLeft: theme.spacing(1), // so the button isn't attached to the title
  },
  projectScopeSubTable: {
    margin: theme.spacing(0.5),
    padding: theme.spacing(0.5),
  },
}));

const ProjectScope = props => {
  const { project } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { hasProjectScopeLibrary } = useFeatureFlags();

  const [openScopeDialog, setOpenScopeDialog] = useState(false);
  const [openFindingsDialog, setOpenFindingsDialog] = useState(false);
  const editableDescriptionRef = useRef(null);

  const context = {
    projectId: project.id,
    scopeData: project.scope,
    openScopeDialog,
    setOpenScopeDialog,
    openFindingsDialog,
    setOpenFindingsDialog,
  };

  /**
   * Opens a dialog to edit the project description
   */
  const editDescription = () => {
    const saveNewDescription = () => {
      const payload = { scope: { description: editableDescriptionRef.current.value } };
      dispatch(updateProjects(project.id, payload, null));
    };

    const editDescriptionDialog = (
      <FormDialog confirmAction={saveNewDescription}>
        <TextField multiline fullWidth defaultValue={project.scope.description} inputRef={editableDescriptionRef} />
      </FormDialog>
    );

    dispatch(openDialog('Edit Project Scope description', editDescriptionDialog));
  };

  return (
    <ProjectScopeContext.Provider value={context}>
      <Grid container className={classes.projectScope}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} className={classes.projectScopeDescription}>
              <Paper className={classes.projectScopeDescriptionPaper}>
                <Grid container>
                  <Title title={'Project Scope'} />
                  <ButtonIcon
                    icon={EditIcon}
                    onClick={editDescription}
                    className={classes.projectScopeDescriptionEditButton}
                  />
                </Grid>
                <Markdown multilineBlockMargins>{project.scope.description}</Markdown>
              </Paper>
            </Grid>
            <Grid item xs={12} className={classes.projectScopeSubTable}>
              <ProjectSelectedFindingsTable project={project} />
            </Grid>
            <Grid item xs={12} className={classes.projectScopeSubTable}>
              <ProjectSelectedCmlsTable project={project} />
            </Grid>
            {hasProjectScopeLibrary && project.scope.library && (
              <Grid item xs={12} className={classes.projectScopeSubTable}>
                <LibraryMediaTable
                  libraryId={project.scope.library}
                  libraryName={'Project Library'} // hardcoded as Library name doesn't matter
                  filename={`${project.name} Library`}
                  embedded
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </ProjectScopeContext.Provider>
  );
};

ProjectScope.defaultProps = {
  project: undefined,
};

ProjectScope.propTypes = {
  project: PropTypes.object,
  isReadOnly: PropTypes.bool,
};

export default ProjectScope;
