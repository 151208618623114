export const NOTIFICATION_RULES_REQUEST = 'NOTIFICATION_RULES_REQUEST';
export const NOTIFICATION_RULES_SUCCESS = 'NOTIFICATION_RULES_SUCCESS';
export const NOTIFICATION_RULES_FAILURE = 'NOTIFICATION_RULES_FAILURE';
export const NOTIFICATION_RULES_CREATE_REQUEST = 'NOTIFICATION_RULES_CREATE_REQUEST';
export const NOTIFICATION_RULES_CREATE_SUCCESS = 'NOTIFICATION_RULES_CREATE_SUCCESS';
export const NOTIFICATION_RULES_CREATE_FAILURE = 'NOTIFICATION_RULES_CREATE_FAILURE';
export const NOTIFICATION_RULES_UPDATE_REQUEST = 'NOTIFICATION_RULES_UPDATE_REQUEST';
export const NOTIFICATION_RULES_UPDATE_SUCCESS = 'NOTIFICATION_RULES_UPDATE_SUCCESS';
export const NOTIFICATION_RULES_UPDATE_FAILURE = 'NOTIFICATION_RULES_UPDATE_FAILURE';
export const NOTIFICATION_RULES_DELETE_REQUEST = 'NOTIFICATION_RULES_DELETE_REQUEST';
export const NOTIFICATION_RULES_DELETE_SUCCESS = 'NOTIFICATION_RULES_DELETE_SUCCESS';
export const NOTIFICATION_RULES_DELETE_FAILURE = 'NOTIFICATION_RULES_DELETE_FAILURE';
export const NOTIFICATION_RULES_ALL_REQUEST = 'NOTIFICATION_RULES_ALL_REQUEST';
export const NOTIFICATION_RULES_ALL_SUCCESS = 'NOTIFICATION_RULES_ALL_SUCCESS';
export const NOTIFICATION_RULES_ALL_FAILURE = 'NOTIFICATION_RULES_ALL_FAILURE';

export const getNotificationRules = id => {
  return {
    type: NOTIFICATION_RULES_REQUEST,
    id,
  };
};

export const createNotificationRules = body => {
  return {
    type: NOTIFICATION_RULES_CREATE_REQUEST,
    body,
  };
};

export const updateNotificationRules = (id, body) => {
  return {
    type: NOTIFICATION_RULES_UPDATE_REQUEST,
    id,
    body,
  };
};

export const deleteNotificationRules = id => {
  return {
    type: NOTIFICATION_RULES_DELETE_REQUEST,
    id,
  };
};

export const getAllNotificationRules = query => {
  return {
    type: NOTIFICATION_RULES_ALL_REQUEST,
    query,
  };
};
