import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { ButtonGroup } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import RefreshIcon from '@mui/icons-material/Refresh';

import ButtonIcon from '../shared/buttons/ButtonIcon';
import { setColumns } from '../shared/table/columns';
import Table, { SELECTABLE_ROWS_NONE } from '../shared/table/Table';
import StyledLink from '../shared/StyledLink';
import { openReportWindow, generatePDF } from '../../utilities/reports';
import { getDate, downloadFile } from '../../utilities/strings';
import { openSnackbar } from '../../store/snackbarActions';
import Markdown from '../shared/Markdown';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const useStyles = makeStyles(theme => ({
  reportSelector: {
    margin: theme.spacing(0, 4, 5),
  },
}));

const ReportsTable = props => {
  // Supports both project reports, and form/checklist reports
  const { project, form, report_types: data, isPublished, refresh } = props;
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const handleOpenNew = url => openReportWindow(url);

  const handleGeneratePDF = async instanceData => {
    setIsLoading(true);
    try {
      dispatch(openSnackbar('Generating a new PDF file', 'info'));
      const newReportInstanceData = await generatePDF(instanceData);
      dispatch(
        openSnackbar(
          'Generated a new PDF file' + newReportInstanceData.name + ', click the PDF icon to download',
          'success'
        )
      );
    } catch (error) {
      // TODO: start a background process to generate the PDF and notify the user when it is ready
      console.error('Error Generated report', error);
      dispatch(openSnackbar('Error Generated report', 'error'));
    } finally {
      setIsLoading(false);
      refresh();
    }
  };

  const handleDownload = async (url, instanceData = {}) => {
    setIsLoading(true);
    if (instanceData.file) {
      try {
        await downloadFile(instanceData.name, instanceData.file);
      } catch (error) {
        console.error('Error downloading report PDF file', error);
        dispatch(openSnackbar('Error downloading report PDF file', 'error'));
      } finally {
        setIsLoading(false);
      }
    } else {
      dispatch(
        openSnackbar('Error no report PDF created, click the refresh the PDF button to generate the PDF', 'error')
      );
      setIsLoading(false);
    }
  };

  /** @type {Column[]} */
  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        customBodyRender: (value, tableMeta) => {
          const url = tableMeta.rowData[columns.findIndexByName['url']];
          return <StyledLink underline value={value} onClick={() => handleOpenNew(url)} />;
        },
      },
    },
    // file column for data retrieval only
    {
      name: 'report_instance.file',
      label: 'File',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        customBodyRender: value => <Markdown>{value}</Markdown> || '--',
      },
    },
    {
      name: 'report_instance.generated_on',
      label: 'Generated On',
      options: {
        customBodyRender: value => (value ? getDate(value, 'date-time') : '--'),
      },
    },
    // url column for data retrieval only
    {
      name: 'url',
      label: 'URL',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'report_instance.name',
      label: 'Name',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'Actions',
      options: {
        customBodyRender: (value, tableMeta) => {
          const reportType = tableMeta.rowData[columns.findIndexByName['id']];
          const url = tableMeta.rowData[columns.findIndexByName['url']];
          const file = tableMeta.rowData[columns.findIndexByName['report_instance.file']];
          const name = tableMeta.rowData[columns.findIndexByName['report_instance.name']];

          const instanceData = {};
          if (project) {
            instanceData.project = project;
            instanceData.type = reportType;
          }
          if (form) {
            instanceData.form = form;
            instanceData.type = reportType;
          }

          if (file) {
            instanceData.file = file;
          }
          if (name) {
            instanceData.name = name;
          }
          return (
            <>
              <ButtonGroup>
                {!isPublished && (
                  <ButtonIcon
                    icon={RefreshIcon}
                    tooltip={'Refresh PDF'}
                    onClick={() => {
                      handleGeneratePDF(instanceData);
                    }}
                    disabled={isLoading}
                  />
                )}
                <ButtonIcon
                  icon={PictureAsPdfIcon}
                  tooltip={'Download'}
                  onClick={() => handleDownload(url, instanceData)}
                  disabled={isLoading}
                />
                <ButtonIcon
                  icon={VisibilityIcon}
                  tooltip={'Preview'}
                  onClick={() => handleOpenNew(url)}
                  disabled={isLoading}
                />
              </ButtonGroup>
            </>
          );
        },
      },
    },
  ];

  setColumns(columns);

  /** @type {TableOptions} */
  const options = {
    selectableRows: SELECTABLE_ROWS_NONE,
    enableNestedDataAccess: '.',
    download: false,
    filter: false,
    print: false,
    viewColumns: false,
  };

  return (
    <div className={classes.reportSelector}>
      <Table title="Reports" simpleSearch columns={columns} data={data} loading={isLoading} options={options} />
    </div>
  );
};

ReportsTable.defaultProps = {
  report_types: [],
  project: null,
  form: null,
};

ReportsTable.propTypes = {
  project: PropTypes.number,
  form: PropTypes.number,
  report_types: PropTypes.array,
  isPublished: PropTypes.bool,
  refresh: PropTypes.func.isRequired,
};
export default ReportsTable;
