import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { TextField } from 'mui-rff';
import { FieldArray } from 'react-final-form-arrays';

const AppendixBlockForm = props => {
  const { name, label, loading, disabled, keyValidator } = props;

  return (
    <FieldArray name={name}>
      {({ fields }) => (
        <>
          {' '}
          {label}
          <Grid container spacing={1} direction="row">
            <Grid item xs={12}>
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item xs>
                  Enter a title and body for each Appendix desired.
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
            </Grid>
            {fields.map((name, index) => (
              <Grid item key={index} xs={12}>
                <Grid container spacing={1} alignItems="top">
                  <Grid item xs>
                    Title
                    <TextField
                      name={`${name}.title`}
                      placeholder="Appendix Title (optional)"
                      disabled={disabled || loading}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      fieldProps={{ validate: keyValidator }}
                    />
                  </Grid>
                  <Grid item xs>
                    Body
                    <TextField
                      name={`${name}.body`}
                      helperText="Static content added at end of report. Accepts Markdown."
                      disabled={disabled || loading}
                      variant="outlined"
                      multiline
                      rows={3}
                      maxRows={10}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      size="small"
                      disabled={disabled}
                      onClick={() => fields.remove(index)}
                      aria-label="remove label/value">
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            ))}
            <Grid item>
              <IconButton
                type="button"
                size="small"
                disabled={disabled}
                onClick={() => fields.push({ label: '', value: '' })}
                aria-label="add label/value">
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>
        </>
      )}
    </FieldArray>
  );
};

AppendixBlockForm.defaultProps = {
  disabled: false,
  loading: false,
  keyValidator: () => undefined,
};

AppendixBlockForm.propTypes = {
  /** the api key for the feature */
  name: PropTypes.string.isRequired,
  /** label for all the keys */
  label: PropTypes.string.isRequired,
  /** disables the field while true */
  disabled: PropTypes.bool,
  /** disables the filed while true */
  loading: PropTypes.bool,
  /** validator function for the key field */
  keyValidator: PropTypes.func,
};

export default AppendixBlockForm;
