import { useMemo } from 'react';
import { useSelector } from 'react-redux';

/**
 * get the selected revision from the revisions array
 *  - if no revision is selected, use the last revision
 *  - if no revisions exist, use the default edit data
 */

const useSelectedRevision = revisions => {
  const selectedRevisionId = useSelector(state => state.revisions.selected);
  const selectedRevision = useMemo(() => {
    if (!revisions?.length || selectedRevisionId === 'new') {
      return { id: 'new', edit: {} };
    }
    if (!selectedRevisionId || selectedRevisionId === 'latest') {
      return revisions[revisions.length - 1];
    }
    const found = revisions.find(rev => rev.id.toString() === selectedRevisionId.toString()); // use toString() in case read from URL
    if (!found && revisions.length > 0) {
      console.warn(`Revision ${selectedRevisionId} not found in revisions array`);
      return revisions[revisions.length - 1];
    } else if (!found && revisions.length === 0) {
      console.warn(
        `Revision ${selectedRevisionId} not found in revisions array and no revisions exist (using default edit data)`
      );
      return { id: 'loading', edit: {} }; // (assume loading - this should hopefully never happen)
    }

    return found;
  }, [revisions, selectedRevisionId]);

  return selectedRevision;
};

export default useSelectedRevision;
