import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Divider, Grid, Tab, Tabs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProjects } from '../../store/features/projectsActions';
import { getAllChecklists } from '../../store/features/checklistsActions';
import { getAllChecklistTasks } from '../../store/features/checklistTasksActions';
import { getAllScheduleTasks } from '../../store/features/scheduleTasksActions';
import ProjectsTable from '../projects/ProjectsTable';
import ChecklistsTable from '../checklists/ChecklistsTable';
import ChecklistTasksTable from '../checklistTasks/ChecklistTasksTable';
import ScheduleTasksTable from '../scheduleTasks/ScheduleTasksTable';

import HelpTip from '../shared/displays/HelpTip';
import Title from '../shared/displays/Title';
import TabPanel from '../shared/TabPanel';
import DashboardStats from './DashboardStats.js';
import { useFeatureFlags, usePermissions, useTableViewSettings, useTableViews } from '../../hooks/settingsHooks';
import { tableViews } from '../../utilities/tables';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    textAlign: 'left',
  },
  title: {
    marginLeft: theme.spacing(6),
  },
  stats: {
    textAlign: 'center',
    width: '100%',
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const history = useHistory();

  const location = useLocation();
  let selectedTab = location.hash;
  selectedTab = selectedTab || '#checklist-tasks';

  const dispatch = useDispatch();
  const { checklistsQuery } = useSelector(state => ({
    checklistsQuery: state.checklists.all.query,
  }));
  const { checklistsTableViewKey, projectsTableViewKey } = tableViews;
  const { pageSize: checklistsTableSize } = useTableViews(checklistsTableViewKey);

  const { hasChecklistAny, hasChecklistTasks, hasProjectTasks, hasSchedule } = useFeatureFlags();
  const { hasChecklistEdit, hasChecklistCreate } = usePermissions();

  const defaultProjectsQuery = { assigned: true };
  const excludedProjectQueries = ['completed'];
  const {
    tableQuery: projectsQuery,
    embeddedQuery: projectsEmbeddedQuery,
    views,
    page,
  } = useTableViewSettings(projectsTableViewKey, defaultProjectsQuery, excludedProjectQueries);

  const showChecklistTasks = hasChecklistEdit && (hasProjectTasks || hasChecklistTasks);
  const showScheduleTasks = hasChecklistCreate && hasSchedule;
  const showForms = hasChecklistEdit && hasChecklistAny;

  // queries for serverside tables need to check for table size settings
  const defaultCreatedChecklistsQuery = { limit: checklistsTableSize, created_by_me: true, project: 'none' };
  const assignedChecklistTasksQuery = { assigned_or_created_by_me: true, done: false };
  const assignedTasksQuery = { assigned: true, current: true };

  useEffect(() => {
    dispatch(getAllProjects(projectsQuery));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hasChecklistAny && hasChecklistEdit) {
      dispatch(getAllChecklists(defaultCreatedChecklistsQuery));
    }
  }, [dispatch, hasChecklistAny, hasChecklistEdit]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hasSchedule && hasChecklistCreate) {
      dispatch(getAllScheduleTasks(assignedTasksQuery));
    }
  }, [dispatch, hasChecklistCreate, hasSchedule]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (showChecklistTasks) {
      dispatch(getAllChecklistTasks(assignedChecklistTasksQuery));
    }
  }, [dispatch, showChecklistTasks]); // eslint-disable-line react-hooks/exhaustive-deps

  const projectTableChangeHandler = query => {
    const updatedQuery = { ...projectsEmbeddedQuery, ...query };
    dispatch(getAllProjects(updatedQuery));
  };

  const formTableChangeHandler = query => {
    const updatedQuery = { ...defaultCreatedChecklistsQuery, ...query };
    dispatch(getAllChecklists(updatedQuery));
  };

  const renderChecklistTasks = () => {
    return <ChecklistTasksTable title="My Tasks" dashboardMode embedded />;
  };

  const renderScheduleTasks = () => {
    return <ScheduleTasksTable title="Scheduled Tasks" embedded />;
  };

  const renderForms = () => {
    return (
      <ChecklistsTable
        title="My Forms"
        tableChangeHandler={formTableChangeHandler}
        queryParamObj={checklistsQuery}
        embedded
      />
    );
  };

  const renderProjects = () => {
    return (
      <ProjectsTable
        title="My Projects"
        tableChangeHandler={projectTableChangeHandler}
        queryParamObj={projectsQuery}
        page={page}
        views={views}
        embedded
      />
    );
  };

  const tabs = [
    { value: '#checklist-tasks', label: 'My Tasks', Component: renderChecklistTasks(), hide: !showChecklistTasks },
    { value: '#schedule-tasks', label: 'Scheduled Tasks', Component: renderScheduleTasks(), hide: !showScheduleTasks },
    { value: '#projects', label: 'My Projects', Component: renderProjects() },
    { value: '#forms', label: 'My Forms', Component: renderForms(), hide: !showForms },
  ];

  let readyToRender = false;

  const handleTabChange = (event, value) => {
    history.push(`/${value}`);
  };

  switch (selectedTab) {
    case '#projects':
    case '#forms':
    case '#checklist-tasks':
    case '#schedule-tasks':
      readyToRender = true;
      break;
    default:
      history.replace(`/`);
  }

  // the Tabs component doesn't handle value=undefined
  if (!readyToRender) return <></>;

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <HelpTip />
        <Grid item></Grid>
        <Grid item className={classes.stats}>
          <Title title="Overview" />
          <DashboardStats onProjectsTableChange={projectTableChangeHandler} />
        </Grid>
        <Grid item xs={12}>
          <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary">
            {tabs
              .filter(tab => !tab.hide)
              .map(tab => (
                <Tab
                  id={`dashboard-tab-${tab.value}`}
                  aria-controls={`dashboard-tabpanel-${tab.value}`}
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                />
              ))}
          </Tabs>
          <Divider className={classes.divider} />
          {tabs.map(tab => (
            <TabPanel key={tab.value} value={tab.value} selectedTab={selectedTab} name="dashboard">
              <Grid item className={classes.table}>
                {tab.Component}
              </Grid>
            </TabPanel>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
