import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { List, Collapse, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Icon from '@mui/material/Icon';

import { isEmpty } from '../../utilities/objects';
import { requestFeatures } from '../../store/settings/featuresActions';
import { setUserNavView } from '../../store/settings/views/userNavViewRedux';

import { useFeaturePages, useTableViews } from '../../hooks/settingsHooks';
import NavButton from './buttons/NavButton';
import ListItemLink from './ListItemLink';
import { tableViews } from '../../utilities/tables';

const useStyles = makeStyles(theme => ({
  nested: {
    padding: '0px',
  },
  button: {
    margin: theme.spacing(2, 0),
  },
}));

/**
 * Main sidebar navigation set by the feature pages.
 */

const MENU_TYPES = {
  BUTTON: 'BUTTON',
  CONTAINER: 'CONTAINER',
  LINK: 'LINK',
};

const NavDynamic = props => {
  const { userNavViewKey } = tableViews;
  const expanded = useTableViews(userNavViewKey);

  const classes = useStyles();
  const pages = useFeaturePages();

  const dispatch = useDispatch();
  const handleExpandCollapse = menuItem => {
    dispatch(setUserNavView({ ...expanded, [menuItem]: !expanded[menuItem] }));
  };

  const renderMenuContainer = (page, expandKey) => {
    // Hide empty containers
    if (page.child_pages.length === 0) {
      return <></>;
    }

    const subItems = page.child_pages.map(nestedPage => {
      return (
        <ListItem key={nestedPage.id} button className={classes.nested}>
          <ListItemLink primary={nestedPage.menu_name} to={nestedPage.path} />
        </ListItem>
      );
    });
    return (
      <Fragment key={page.id}>
        <ListItem key={page.id} button onClick={() => handleExpandCollapse(expandKey)}>
          {page.menu_icon ? (
            <ListItemIcon>
              <Icon>{page.menu_icon}</Icon>
            </ListItemIcon>
          ) : (
            <></>
          )}
          <ListItemText primary={page.menu_name} />
          {expanded[expandKey] ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={expanded[expandKey]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {subItems}
          </List>
        </Collapse>
      </Fragment>
    );
  };

  const renderPage = (page, extraProps) => {
    const expandKey = page?.path;

    switch (page.menu_display) {
      case MENU_TYPES.CONTAINER:
        return renderMenuContainer(page, expandKey);

      case MENU_TYPES.BUTTON:
        return (
          <div key={page.id} className={classes.button}>
            <NavButton label={page.menu_name} to={page.path} />
          </div>
        );

      case MENU_TYPES.LINK:
        if (page.menu_icon) {
          extraProps['icon'] = <Icon>{page.menu_icon}</Icon>;
        }
        // if (nested) {
        //   return <ListItem button className={classes.nested}><ListItemLink primary={page.name} to={page.path} {...extraProps} /></ListItem>;
        // }
        // Home page link needs to have exact prop to prevent selection of two links at the same time
        if (page.path === '/?') {
          return <ListItemLink exact key={page.id} primary={page.menu_name} to={page.path} {...extraProps} />;
        }
        return <ListItemLink key={page.id} primary={page.menu_name} to={page.path} {...extraProps} />;

      default:
        return (
          <>
            {page.name} - Unknown: {page.source_type}
          </>
        );
    }
  };

  const handleRefreshClick = () => {
    dispatch(requestFeatures());
  };

  if (isEmpty(pages)) {
    return (
      <List component="nav" className={classes.root}>
        <>
          Missing navigation settings. Please contact: support@huvrdata.com
          <NavButton key="refresh" label="Refresh" size="small" onClick={handleRefreshClick} />
        </>
      </List>
    );
  } else if (!pages) {
    return <></>;
  } else {
    const navItems = pages.map(page => {
      const extraProps = {};
      return renderPage(page, extraProps);
    });

    return (
      <List component="nav" className={classes.root}>
        {navItems}
      </List>
    );
  }
};

// <OppositeButton key="refresh" label="REFRESH" size="small" onClick={handleRefreshClick} />
export default NavDynamic;
