import dicomParser from 'dicom-parser';
import cornerstone from 'cornerstone-core';
import cornerstoneDICOMImageLoader from '@cornerstonejs/dicom-image-loader';
import cornerstoneMath from 'cornerstone-math';
import cornerstoneTools from 'cornerstone-tools';
import Hammer from 'hammerjs';

/** cornerstone documentation
 * https://react.cornerstonejs.org/
 * https://tools.cornerstonejs.org/examples/
 */
const cornerstoneSingleton = {
  initialized: false,
  init: function () {
    if (this.initialized) {
      return;
    }
    cornerstoneTools.external.cornerstone = cornerstone;
    cornerstoneTools.external.Hammer = Hammer;
    cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
    cornerstoneTools.init({ globalToolSyncEnabled: true, showSVGCursors: true });
    cornerstoneTools.addTool(cornerstoneTools.LengthTool);
    cornerstoneTools.addTool(cornerstoneTools.PanTool);
    cornerstoneTools.addTool(cornerstoneTools.WwwcTool);
    cornerstoneTools.addTool(cornerstoneTools.RotateTool);
    cornerstoneTools.addTool(cornerstoneTools.ZoomMouseWheelTool, { configuration: { minScale: 0.1 } });
    cornerstoneTools.setToolActive('ZoomMouseWheel', { mouseButtonMask: 2 });

    // Preferences
    const fontFamily =
      'Work Sans, Roboto, OpenSans, HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif';
    cornerstoneTools.textStyle.setFont(`16px ${fontFamily}`);
    cornerstoneTools.toolStyle.setToolWidth(2);
    cornerstoneTools.toolColors.setToolColor('rgb(255, 255, 0)');
    cornerstoneTools.toolColors.setActiveColor('rgb(0, 255, 0)');
    cornerstoneTools.store.state.touchProximity = 40;

    // IMAGE LOADER
    cornerstoneDICOMImageLoader.external.cornerstone = cornerstone;
    cornerstoneDICOMImageLoader.external.dicomParser = dicomParser;
    const config = {
      maxWebWorkers: navigator.hardwareConcurrency || 1,
      startWebWorkersOnDemand: true,
      taskConfiguration: {
        decodeTask: {
          initializeCodecsOnStartup: false,
          usePDFJS: false,
          strict: false,
        },
      },
    };
    cornerstoneDICOMImageLoader.webWorkerManager.initialize(config);

    this.initialized = true;
  },
  setLengthActive: function () {
    cornerstoneTools.setToolActive('Length', { mouseButtonMask: 1 });
  },
  setPanActive: function () {
    cornerstoneTools.setToolActive('Pan', { mouseButtonMask: 1 });
  },
  setWwwcActive: function () {
    cornerstoneTools.setToolActive('Wwwc', { mouseButtonMask: 1 });
  },
  setRotateActive: function () {
    cornerstoneTools.setToolActive('Rotate', { mouseButtonMask: 1 });
  },
  setOptions: function (options) {
    cornerstoneDICOMImageLoader.internal.setOptions(options);
  },
};

export default cornerstoneSingleton;
