// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import {
  PROJECT_SUMMARY_GETTER_REQUEST,
  PROJECT_SUMMARY_GETTER_SUCCESS,
  PROJECT_SUMMARY_GETTER_FAILURE,
} from './projectSummaryActions';

export const initialStateProjectSummary = {
  loading: false,
  error: '',
  data: [],
};

export const projectSummaryReducer = (state = initialStateProjectSummary, action) => {
  switch (action.type) {
    case PROJECT_SUMMARY_GETTER_REQUEST:
      return {
        ...state,
        loading: true,
        id: action.id,
      };
    case PROJECT_SUMMARY_GETTER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case PROJECT_SUMMARY_GETTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        data: [],
      };
    default:
      return state;
  }
};

export default projectSummaryReducer;
