import React from 'react';
import PropTypes from 'prop-types';
import { Canvas } from '@react-three/fiber';
import { useGLTF, Bounds, OrbitControls } from '@react-three/drei';
import Loader from './Loader';

function Scene({ file }) {
  const gltf = useGLTF(file);

  return (
    <>
      <primitive object={gltf.scene} />
    </>
  );
}

Scene.propTypes = {
  file: PropTypes.string.isRequired,
};

function GTLFViewer({ file }) {
  return (
    <Canvas camera={{ position: [0, -10, 80], near: 0.5, far: 5000, fov: 75 }}>
      <React.Suspense fallback={<Loader />}>
        <Bounds fit margin={1.2}>
          <Scene file={file} />
          <OrbitControls makeDefault />
        </Bounds>
      </React.Suspense>
      <ambientLight />
    </Canvas>
  );
}

GTLFViewer.propTypes = {
  file: PropTypes.string.isRequired,
};

export default GTLFViewer;
