import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Clear from '@mui/icons-material/Clear';

/**
 * Button for Cancel, Back or similar buttons using the secondary theme.
 * It can show an icon.
 * */

const SecondaryButton = props => {
  const { type, label, icon, Icon, disabled, onClick, variant, fullWidth, endIcon, color } = props;

  const moreProps = {};
  if (icon) {
    let StartIcon = Icon;
    if (!StartIcon) {
      StartIcon = Clear;
    }
    moreProps.startIcon = <StartIcon />;
  }
  if (onClick) {
    moreProps.onClick = onClick;
  }
  if (fullWidth) {
    moreProps.fullWidth = true;
  }

  return (
    <Button
      type={type}
      color={color}
      variant={variant}
      disabled={disabled}
      endIcon={endIcon}
      onClick={() => {}}
      {...moreProps}>
      {label}
    </Button>
  );
};

SecondaryButton.defaultProps = {
  label: 'Clear',
  type: 'button',
  variant: 'contained',
  fullWidth: false,
  icon: false,
  Icon: null,
  disabled: false,
  onClick: null,
  endIcon: null,
  color: 'secondary',
};

SecondaryButton.propTypes = {
  /** sets the button label */
  label: PropTypes.string,
  /** sets the button type */
  type: PropTypes.string,
  /** sets the button variant */
  variant: PropTypes.string,
  /** sets the button to fill the container */
  fullWidth: PropTypes.bool,
  /** enable or disable <em>Icon</em> prop */
  icon: PropTypes.bool,
  /** if <em>icon</em> is true, sets the custom svg icon */
  Icon: PropTypes.object,
  /** disables button */
  disabled: PropTypes.bool,
  /** callback for onClick event */
  onClick: PropTypes.func,
  /* end icon */
  endIcon: PropTypes.node,
  color: PropTypes.string,
};

export default SecondaryButton;
