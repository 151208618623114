import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { fieldOrder, removeField, hideField } from './defectsShared';
import {
  apiDateToString,
  cleanUpFileString,
  downloadFile,
  jsonKeyToLabel,
  markdownLink,
} from '../../utilities/strings';
import CommonFormView from '../shared/form/CommonView';
import ImageAnnotator from '../shared/imageTools/ImageAnnotator';
import SlideshowViewer from '../shared/slideshowViewer/SlideshowViewer';
import StyledLink from '../shared/StyledLink';

import { openSnackbar } from '../../store/snackbarActions';
import { getExtension } from '../../utilities/files';
import { isEmpty } from '../../utilities/objects';
import Error from '../shared/displays/Error';
import Loading from '../shared/displays/Loading';
import { predefinedErrors } from '../../utilities/errors';
import { useFeatureFlags, usePermissions } from '../../hooks/settingsHooks';

const useStyles = makeStyles(theme => ({
  mediaGallery: {
    // margin: theme.spacing(1, 4, 5),
  },
}));

const DefectsView = props => {
  const { data, loading, error } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [showSlides, setShowSlides] = useState(false);
  const [startingId, setStartingId] = useState(null);
  const { hasConfigurationManagementView } = usePermissions();
  const { hasAccessDisplay, hasFindingsPrioritization } = useFeatureFlags();

  const defectProfile = data?.profile;
  const locationLayerProfile = data?.layer;
  if (isEmpty(data)) {
    return <Loading />;
  }
  if (!defectProfile || !locationLayerProfile) {
    return (
      <Error style={{ display: 'flex', 'justify-content': 'center' }} error={predefinedErrors.missingProfileError} />
    );
  }

  const getDefectFiles = () => {
    // naming convetion: [asset.asset_path][component_display][severity_display][type_display][original_filename].extension
    const selected =
      data && data.overlays
        ? data.overlays.flatMap(item => {
            if (item && item.media) {
              const url = item.media.file;
              if (url) {
                const extension = getExtension(item.media.name);
                const origFilename = item.media.name.replace(`.${extension}`, '');
                const filename = cleanUpFileString(
                  `[D${data.id}][${data.asset.asset_path}][${data.component_display}][${data.severity_display}][${data.type_display}][${item.id}][${origFilename}].${extension}`
                );
                const project = data?.project?.name; // The second to last element is the project
                return { url, filename, project };
              }
            }
            return [];
            // This silently fails if there's no file to download.
            // on the standalone findings table, it's possible for a finding to not have media.
            // Not sure if it's better to silently fail or somehow show a message for each file that's not downloaded
          })
        : [];
    return selected;
  };

  const handleDownload = async () => {
    const files = getDefectFiles();
    for (let x = 0; x < files.length; x++) {
      try {
        dispatch(openSnackbar('Starting download...', 'info', null, 2000));
        await downloadFile(files[x].filename, files[x].url);
      } catch (error) {
        const message = `Error downloading media:${files[x].filename} ${error}`;
        console.error(message);
        Sentry.captureMessage(message);
      }
    }
  };
  // reshape the overlays into a media array
  const media = [];
  try {
    for (let i = 0; i < data.overlays.length; i++) {
      media.push({
        ...data.overlays[i].media,
        // shape the overlay array so the ImageAnnotator knows how to handle it.
        overlays: [{ overlay: { id: data.overlays[i].id, geometry: data.overlays[i].geometry } }],
      });
    }
  } catch (error) {
    console.error(error);
  }

  const handleNext = () => {
    if (currentMediaIndex + 1 >= media.length) {
      setCurrentMediaIndex(0);
    } else {
      setCurrentMediaIndex(currentMediaIndex + 1);
    }
  };
  const handlePrev = () => {
    if (currentMediaIndex - 1 < 0) {
      setCurrentMediaIndex(media.length - 1);
    } else {
      setCurrentMediaIndex(currentMediaIndex - 1);
    }
  };
  const handleShow = () => {
    setStartingId(data.id);
    setShowSlides(true);
  };

  const activeMedia = !media || isEmpty(media) ? {} : media[currentMediaIndex];

  const formSettings = {
    fieldOrder,
    removeField: removeField('VIEW'),
    hideField: (values, item) => {
      return hideField(values, item, {
        hideProtectedFields: false, // (we're not updating)
        defectProfile,
        locationLayerProfile,
        hasAccessDisplay,
        hasFindingsPrioritization,
      });
    },
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      // cast to string because booleans do not show up in the field
      let displayLabel = jsonKeyToLabel(key); // eslint-disable-line prefer-const
      let displayValue = '';

      switch (key) {
        case 'component_display':
          displayLabel = 'Component';
          break;
        case 'location_zone_display':
          displayLabel = 'Location Zone';
          break;
        case 'location_code_display':
          displayLabel = 'Location Code';
          break;
        case 'access_display':
          displayLabel = 'Access';
          break;
        case 'severity_display':
          displayLabel = 'Severity';
          break;
        case 'type_display':
          displayLabel = 'Type';
          break;
        case 'sub_type_display':
          displayLabel = 'Sub-Type';
          break;
        case 'newer_defect':
          displayLabel = 'Newer Finding';
          break;
      }

      if (data[key] != null) {
        switch (key) {
          case 'overlays':
            fieldSettings[key] = {
              type: 'component',
              cellProps: {
                xs: 12,
              },
              fieldProps: {
                label: `Media`,
                value: () => (
                  <Paper className={classes.mediaGallery}>
                    <Typography variant="subtitle1">{`(${currentMediaIndex + 1} / ${media.length})`}</Typography>
                    <div style={{ float: 'right', display: 'flex' }}>
                      <IconButton onClick={handleShow} size="large">
                        <FullscreenIcon />
                      </IconButton>
                      <IconButton aria-label="download file" onClick={handleDownload} size="large">
                        <GetAppIcon />
                      </IconButton>
                    </div>
                    {activeMedia?.overlays && activeMedia.overlays.length && (
                      <ImageAnnotator
                        image={activeMedia?.file}
                        overlays={activeMedia?.overlays}
                        noTools
                        showNavArrows
                        navDisabled={media.length <= 1}
                        nextMedia={handleNext}
                        prevMedia={handlePrev}
                        key="image-annotator"
                      />
                    )}
                    {showSlides && (
                      <SlideshowViewer
                        mediaArray={media}
                        showSlides={showSlides}
                        setShowSlides={setShowSlides}
                        startingId={startingId}
                        project={{}}
                        total={media.length}
                      />
                    )}
                  </Paper>
                ),
              },
            };
            return;

          case 'asset':
            displayValue = data[key].asset_path;
            fieldSettings[key] = {
              type: 'component',
              cellProps: {
                xs: 6,
              },
              fieldProps: {
                label: 'Asset',
                value: () => <StyledLink to={`/assets/${data[key].id}/`} value={data[key].name} />,
              },
            };
            return;
          case 'captured_on':
          case 'created_on':
          case 'next_inspection_date':
          case 'repair_by':
          case 'resolved_on':
          case 'updated_on':
            displayValue = apiDateToString(data[key], 'datetime');
            break;
          case 'labels':
            fieldSettings[key] = {
              type: 'key-value',
              fieldProps: {
                label: displayLabel,
                value: data[key],
              },
            };
            return;
          case 'profile':
            displayValue = markdownLink(
              `${defectProfile.name} ${defectProfile.version}`,
              `/finding-profiles/${data[key].id}`,
              hasConfigurationManagementView
            );
            break;
          case 'layer':
            displayValue = markdownLink(
              `${locationLayerProfile.name} ${locationLayerProfile.version}`,
              `/location-layer-profiles/${data[key].id}`,
              hasConfigurationManagementView
            );
            break;
          case 'project_name':
            displayValue = markdownLink(`${data[key]}`, `/projects/${data.project}`, true);
            break;
          case 'newer_defect':
            displayValue = markdownLink(`${data[key]}`, `/findings/${data.newer_defect}`, true);
            break;
          case 'geo_point':
            fieldSettings[key] = {
              type: 'geo',
              cellProps: {
                md: 6,
                xs: 12,
              },
              fieldProps: {
                label: displayLabel,
                value: data[key],
              },
            };
            return;

          default:
            displayValue = data[key].toString();
        }
      }
      fieldSettings[key] = {
        type: 'text',
        fieldProps: {
          label: displayLabel,
          value: displayValue,
        },
      };
    });
  }

  return (
    <CommonFormView
      bodyOnly
      title={'Finding Details'}
      values={data}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      editLink={`/findings/${data.id}/edit`}
      loading={loading}
      error={error}
    />
  );
};

DefectsView.defaultProps = {
  data: {},
  error: '',
  loading: false,
};

DefectsView.propTypes = {
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.string,
};

export default DefectsView;
