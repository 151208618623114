import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CheckIcon from '@mui/icons-material/Check';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import { closeDialog } from '../../../store/dialogActions';

const useStyles = makeStyles(theme => ({
  buttons: {
    padding: theme.spacing(1, 0),
  },
  message: {
    margin: theme.spacing(1.5, 1.5),
    whiteSpace: 'pre-wrap',
  },
}));

const ConfirmDialog = props => {
  const { notes, confirmAction } = props;

  const dispatch = useDispatch();
  const classes = useStyles();
  const handleCloseDialog = () => {
    dispatch(closeDialog());
  };

  const handleConfirm = () => {
    confirmAction();
    handleCloseDialog();
  };
  return (
    <Grid container direction="column" justifyContent="space-between">
      {notes !== null ? (
        <Grid item className={classes.message}>
          <p>{notes}</p>
        </Grid>
      ) : (
        <Grid item>
          <p>Are you sure you want to proceed?</p>
        </Grid>
      )}
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          className={classes.buttons}>
          <Grid item xs={2}>
            <SecondaryButton label="Cancel" onClick={handleCloseDialog} />
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}>
            <PrimaryButton icon={true} Icon={CheckIcon} onClick={handleConfirm} label="Confirm" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ConfirmDialog.defaultProps = {
  notes: null,
};

ConfirmDialog.propTypes = {
  notes: PropTypes.string,
  confirmAction: PropTypes.func.isRequired,
};

export default ConfirmDialog;
