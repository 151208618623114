import React from 'react';
import PropTypes from 'prop-types';
import TooltipWrapper from '../shared/displays/Tooltip';
import StyledLink from '../shared/StyledLink';

const ProjectLinkDisplay = ({ id, orderIndex, name }) => {
  if (orderIndex > 0) {
    return (
      <TooltipWrapper title={'This form is linked to the project'}>
        <div>
          <StyledLink to={`/projects/${id}`} value={`${name}**`} />
        </div>
      </TooltipWrapper>
    );
  }
  return <StyledLink to={`/projects/${id}`} value={name} />;
};

ProjectLinkDisplay.propTypes = {
  id: PropTypes.string.isRequired,
  orderIndex: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
};

export default ProjectLinkDisplay;
