export const SET_SCHEDULEPLAN_TABLE_VIEW = 'SET_SCHEDULEPLAN_TABLE_VIEW';
export const SET_SCHEDULEPLAN_TABLE_SIZE = 'SET_SCHEDULEPLAN_TABLE_SIZE';

export const setSchedulePlanTableView = (column, action) => {
  return {
    type: SET_SCHEDULEPLAN_TABLE_VIEW,
    column,
    action,
  };
};

export const setSchedulePlanTableSize = size => {
  return {
    type: SET_SCHEDULEPLAN_TABLE_SIZE,
    size,
  };
};

export const schedulePlanTableViewReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_SCHEDULEPLAN_TABLE_VIEW:
      return {
        ...state,
        [action.column]: action.action,
      };
    case SET_SCHEDULEPLAN_TABLE_SIZE:
      return {
        ...state,
        pageSize: action.size,
      };
    default:
      return state;
  }
};
