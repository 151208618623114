export const fieldOrder = {
  basicinfo: {
    display: 'Contact Info',
    fields: ['requestor', 'phone_number', 'business_line'],
  },
  workinfo: {
    display: 'Work Info',
    fields: ['asset_type', 'asset_number', 'work_scope', 'work_schedule', 'work_site', 'work_provider'],
  },
  other: {
    display: 'Other (optional)',
    fields: ['special_instructions'],
  },
  status: {
    display: 'Status',
    fields: ['state', 'assigned_to', 'note', 'created_on', 'updated_on', 'history'],
  },
};

export const removeField = state => item => {
  let fields = [];
  switch (state) {
    case 'CREATE':
      fields = ['state', 'requestor', 'assigned_to', 'created_on', 'updated_on', 'history', 'note'];
      break;
    case 'UPDATE':
      fields = ['requestor', 'created_on', 'updated_on', 'history'];
      break;
    case 'VIEW':
      fields = ['note'];
      break;
    default:
      return false;
  }
  return fields.includes(item);
};

export const hideField = (values, item) => {
  return false;
};

export const supportMsg = 'For reservation assistance, please contact support@huvrdata.com';
