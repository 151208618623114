import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, ButtonGroup } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

// components
import Table from '../shared/table/Table';
import ChecklistValue from '../shared/checklist/ChecklistValue';
import StyledLink from '../shared/StyledLink';
import ButtonIcon from '../shared/buttons/ButtonIcon';

// util
import { setChecklistResultLinesTableView } from '../../store/settings/views/checklistResultLinesTableViewRedux';
import { setColumns } from '../shared/table/columns';
import { useTableViews } from '../../hooks/settingsHooks';
import { tableViews, getTableRowDataObject } from '../../utilities/tables';
import { checklistResultLinesListEndpoint } from '../../store/apiV2/checklistResultLines';
import { FIELDS } from './checklistResultLineShared';

const ChecklistResultLineTable = props => {
  const { queryParams, tableChangeHandler, title } = props;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { loading, data } = checklistResultLinesListEndpoint.useEndpoint();
  const { checklistResultLinesTableViewKey } = tableViews;
  const views = useTableViews(checklistResultLinesTableViewKey);
  const columns = [
    {
      // This needs to be first and excluded so customBodyRender can access the id to create the link.
      name: FIELDS.id,
      label: 'Id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: FIELDS.checklist.id,
      label: 'Form',
      options: {
        sortField: 'checklist',
        filterField: 'checklist',
        customBodyRender: (value, tableMeta) => {
          const lineObj = getTableRowDataObject(data.results, tableMeta.rowData);
          return <StyledLink to={`/checklist-viewer/${lineObj.checklist.id}?line=${lineObj.id}`}>{value}</StyledLink>;
        },
      },
    },
    {
      name: FIELDS.project.name,
      label: 'Project',
      options: {
        sortField: 'checklist__project__name',
        filterField: 'checklist__project__name',
        customBodyRender: (value, tableMeta) => {
          if (!value) {
            return null;
          }
          const lineObj = getTableRowDataObject(data.results, tableMeta.rowData);
          return <StyledLink to={`/projects/${lineObj.project.id}`}>{lineObj.project.name}</StyledLink>;
        },
      },
    },
    {
      name: FIELDS.value,
      label: 'Value',
      options: {
        filterField: 'value',
        customBodyRender: (value, tableMeta) => {
          if (!value) {
            return null;
          }
          const lineObj = getTableRowDataObject(data.results, tableMeta.rowData);
          return <ChecklistValue value={value} lineType={lineObj.template_line.type} />;
        },
      },
    },
    {
      name: FIELDS.template_line.section_label,
      label: 'Section',
      options: {
        sortField: 'template_line__section_label',
        // filterType: 'textField',
        filterField: 'template_line__section_label',
      },
    },
    {
      name: FIELDS.template_line.label,
      label: 'Line',
      options: {
        sortField: 'template_line__label',
        // filterType: 'textField',
        filterField: 'template_line__label',
      },
    },
    {
      name: FIELDS.template.name,
      label: 'Checklist Template',
      options: {
        sortField: 'template__name',
        filterField: 'template__name',
        filter: true,
      },
    },
    {
      name: FIELDS.media,
      label: 'Media',
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <Box display="flex" flexDirection="row">
              {value.map(v => (
                <img key={v.id} src={v.thumbnail} height={40} />
              ))}
            </Box>
          );
        },
        download: false,
        sort: false,
        filter: false,
        // TODO filter if media exists or not
        // filterField: 'media__exist',
        // filterType: 'custom',
      },
    },
    {
      name: FIELDS.notes,
      label: 'Notes',
      options: {
        sort: false,
        filter: false,
        // TODO filter if media exists or not
        // filterField: 'notes__exist',
        // filterType: 'custom',
      },
    },
    {
      name: 'actions',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        viewColumns: false,
        customBodyRender: (_, tableMeta) => {
          const lineObj = getTableRowDataObject(data.results, tableMeta.rowData);
          return (
            <ButtonGroup>
              <ButtonIcon
                history={history}
                location={location}
                icon={VisibilityIcon}
                to={`/checklist-result-lines/${lineObj.id}`}
              />
            </ButtonGroup>
          );
        },
      },
    },
  ];

  // handle columns display
  setColumns(columns, views);

  const options = {
    // if the column view changes, update redux with either 'add' or 'remove'
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setChecklistResultLinesTableView(changedColumn, action));
    },
    enableNestedDataAccess: '.',
    customToolbar: () => null, // disable 'New' button
    selectableRows: false,
  };

  return (
    <Table
      title={title}
      serverSide={true}
      columns={columns}
      addRoute="/checklist-result-line/new"
      data={data.results}
      count={data.count}
      options={options}
      queryParamObj={queryParams}
      tableChangeHandler={tableChangeHandler}
      loading={loading}
    />
  );
};

ChecklistResultLineTable.propTypes = {
  queryParams: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  tableChangeHandler: PropTypes.func.isRequired,
};

export default ChecklistResultLineTable;
