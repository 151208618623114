import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InspectionMediaTable from '../inspectionMedia/InspectionMediaTable';
import SlideshowViewer from '../shared/slideshowViewer/SlideshowViewer';
import SlideshowGallery from '../shared/slideshowGallery/SlideshowGallery';
import { useMediaStore } from '../../hooks/mediaHooks';

const ProjectMediaGallery = props => {
  const { id, project, isReadOnly, tableChangeHandler, queryParamObj, pageSize } = props;
  const [showGallery, setShowGallery] = useState(false);
  const { media, total, previous, next, loading } = useMediaStore();
  const [showSlides, setShowSlides] = useState(false);
  const [startingId, setStartingId] = useState(null);

  const handleClose = () => {
    setShowGallery(false);
  };

  const setMediaSlide = id => {
    setStartingId(id);
    setShowSlides(true);
  };
  return (
    <>
      <InspectionMediaTable
        title={'Media'}
        setMediaSlide={setMediaSlide}
        showGallery={showGallery}
        showSlides={showSlides}
        setShowGallery={setShowGallery}
        embedded
        projectId={id}
        isReadOnly={isReadOnly}
        filename={`${project.name} Media`}
        tableChangeHandler={tableChangeHandler}
        queryParamObj={queryParamObj}
      />
      {showSlides && (
        <SlideshowViewer
          paginate
          mediaArray={media}
          showSlides={showSlides}
          setShowSlides={setShowSlides}
          startingId={startingId}
          project={project}
          total={total}
          itemsPerPage={pageSize}
          queryParamObj={queryParamObj}
          tableChangeHandler={tableChangeHandler}
          previous={previous}
          next={next}
          loading={loading}
        />
      )}
      {showGallery && (
        <SlideshowGallery
          showGallery={showGallery}
          setShowGallery={setShowGallery}
          setMediaSlide={setMediaSlide}
          isReadOnly={isReadOnly}
          closeGallery={handleClose}
          tableChangeHandler={tableChangeHandler}
          embedded
        />
      )}
    </>
  );
};

ProjectMediaGallery.propTypes = {
  id: PropTypes.number.isRequired,
  project: PropTypes.object.isRequired,
  isReadOnly: PropTypes.bool,
  tableChangeHandler: PropTypes.func,
  queryParamObj: PropTypes.object,
  pageSize: PropTypes.number,
};

export default ProjectMediaGallery;
