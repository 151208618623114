/**
 * fieldOrder
 *
 * An object where the keys define the sections of the form.   Each section requires the key display (string) and
 * fields (array of strings). Fields are the api keys (in snake case) for each field that will be displayed in the form.
 * Any fields that are never shown should not be added to this array.
 *
 * @return {object}
 */
export const fieldOrder = {
  info: {
    display: 'Info',
    fields: ['name', 'manufacturer', 'model'],
  },
  options: {
    display: 'Options',
    fields: ['track_asset_condition', 'is_active', 'geo_mode', 'cml_mode', 'library_mode'],
  },
  status: {
    display: 'Status',
    fields: ['created_on', 'updated_on', 'deactivated_on'],
  },
};

/**
 *  removeField
 *
 *  removes a field depending on if a user is creating, updating or viewing.
 *
 *  @param {string} state - enum for creating, updating or viewing ['CREATE', 'UPDATE', 'VIEW']
 *  @param {string} item - api key (in snake case) to check whether it should be hidden.
 *  @return {boolean} - returns true if the item will not be shown
 */
export const removeField = state => item => {
  let fields = [];
  switch (state) {
    case 'CREATE':
      fields = ['created_on', 'updated_on', 'deactivated_on', 'is_active'];
      break;
    case 'UPDATE':
      fields = ['created_on', 'updated_on', 'deactivated_on'];
      break;
    case 'VIEW':
      fields = ['deactivated_on'];
      break;
    default:
      return false;
  }
  return fields.includes(item);
};

/**
 * hideField
 *
 * This function is for hiding a field depending on the value of another field.
 *
 * @param {object} values - the values of all the fields in the form.
 * @param {string} item - api key (in snake case) to check whether it should be removed.
 * @return {boolean} - returns true if the item will not be shown
 */
export const hideField = (values, item, options = {}) => {
  const { hasConditionMonitoring, hasAssetLibrary } = options;
  if (item === 'cml_mode') {
    return !hasConditionMonitoring;
  }
  if (item === 'library_mode') {
    return !hasAssetLibrary;
  }
  return false;
};
