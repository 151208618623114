export const CHECKLISTS_REQUEST = 'CHECKLISTS_REQUEST';
export const CHECKLISTS_SUCCESS = 'CHECKLISTS_SUCCESS';
export const CHECKLISTS_FAILURE = 'CHECKLISTS_FAILURE';
export const CHECKLISTS_CREATE_REQUEST = 'CHECKLISTS_CREATE_REQUEST';
export const CHECKLISTS_CREATE_SUCCESS = 'CHECKLISTS_CREATE_SUCCESS';
export const CHECKLISTS_CREATE_FAILURE = 'CHECKLISTS_CREATE_FAILURE';
export const CHECKLISTS_UPDATE_REQUEST = 'CHECKLISTS_UPDATE_REQUEST';
export const CHECKLISTS_UPDATE_SUCCESS = 'CHECKLISTS_UPDATE_SUCCESS';
export const CHECKLISTS_UPDATE_FAILURE = 'CHECKLISTS_UPDATE_FAILURE';
export const CHECKLISTS_DELETE_REQUEST = 'CHECKLISTS_DELETE_REQUEST';
export const CHECKLISTS_DELETE_SUCCESS = 'CHECKLISTS_DELETE_SUCCESS';
export const CHECKLISTS_DELETE_FAILURE = 'CHECKLISTS_DELETE_FAILURE';
export const CHECKLISTS_ALL_FAILURE = 'CHECKLISTS_ALL_FAILURE';
export const CHECKLISTS_ALL_REQUEST = 'CHECKLISTS_ALL_REQUEST';
export const CHECKLISTS_ALL_SUCCESS = 'CHECKLISTS_ALL_SUCCESS';
export const CHECKLISTS_BULK_LOCK_REQUEST = 'CHECKLISTS_BULK_LOCK_REQUEST';
export const CHECKLISTS_BULK_LOCK_SUCCESS = 'CHECKLISTS_BULK_LOCK_SUCCESS';
export const CHECKLISTS_BULK_LOCK_FAILURE = 'CHECKLISTS_BULK_LOCK_FAILURE';

export const getChecklists = id => {
  return {
    type: CHECKLISTS_REQUEST,
    id,
  };
};

export const createChecklists = body => {
  return {
    type: CHECKLISTS_CREATE_REQUEST,
    body,
  };
};

export const updateChecklists = (id, body, onUpdate) => {
  return {
    type: CHECKLISTS_UPDATE_REQUEST,
    id,
    body,
    onUpdate,
  };
};

export const deleteChecklists = id => {
  return {
    type: CHECKLISTS_DELETE_REQUEST,
    id,
  };
};

export const getAllChecklists = query => {
  return {
    type: CHECKLISTS_ALL_REQUEST,
    query: query,
  };
};

export const bulkLockChecklists = (ids, lockAction) => {
  return {
    type: CHECKLISTS_BULK_LOCK_REQUEST,
    ids,
    lockAction,
  };
};
