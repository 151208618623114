export const FORM_TYPE = {
  UPLOAD_INITIAL: 'UPLOAD_INITIAL',
  EDIT_META: 'EDIT_META',
  UPLOAD_UPDATE_VERSION: 'UPLOAD_UPDATE_VERSION',
  UPLOAD_UPDATE_VERSION_YAML: 'UPLOAD_UPDATE_VERSION_YAML',
  VIEW: 'VIEW',
};

export const typeOptions = [
  { label: 'Anytime', value: 'ANYTIME' },
  { label: 'Project', value: 'PROJECT' },
  { label: 'All', value: 'ALL' },
];

/**
 * fieldOrder
 *
 * An object where the keys define the sections of the form.   Each section requires the key display (string) and
 * fields (array of strings). Fields are the api keys (in snake case) for each field that will be displayed in the form.
 * Any fields that are never shown should not be added to this array.
 *
 * @return {object}
 */
export const fieldOrder = {
  info: {
    display: 'Info',
    fields: [
      'name',
      'description',
      'version',
      'categories',
      'type',
      'state',
      'default_actions',
      'definitions',
      'sections',
    ],
  },
  status: {
    display: 'Status',
    fields: ['schema_version', 'checklist_count'],
  },
  import: {
    display: 'Import',
    fields: ['download_xlsx', 'download_yaml', 'import_file'],
  },
};

/**
 *  removeField
 *
 *  removes a field depending on if a user is creating, updating or viewing.
 *
 *  @param {string} state - enum for creating, updating or viewing ['CREATE', 'UPDATE', 'VIEW']
 *  @param {string} item - api key (in snake case) to check whether it should be hidden.
 *  @return {boolean} - returns true if the item will not be shown
 */
export const removeField = state => item => {
  let fields = [];
  switch (state) {
    case FORM_TYPE.UPLOAD_INITIAL:
      fields = [
        'actions',
        'default_actions',
        'definitions',
        'sections',
        'schema_version',
        'name',
        'description',
        'state',
        'categories',
        'type',
        'version',
        'checklist_count',
        'download_xlsx',
        'download_yaml',
      ];
      break;
    case FORM_TYPE.UPLOAD_UPDATE_VERSION:
      fields = [
        'actions',
        'default_actions',
        'definitions',
        'sections',
        'schema_version',
        'name',
        'description',
        'state',
        'categories',
        'type',
        'version',
        'checklist_count',
      ];
      break;
    case FORM_TYPE.EDIT_META:
      fields = [
        'default_actions',
        'definitions',
        'sections',
        'schema_version',
        'checklist_count',
        'import_file',
        'download_xlsx',
        'download_yaml',
      ];
      break;
    case FORM_TYPE.VIEW:
      fields = ['definitions', 'sections', 'import_file', 'download_xlsx', 'download_yaml'];
      break;
    default:
      console.warn('removeField: unknown state', state);
      return false;
  }
  return fields.includes(item);
};

/**
 * hideField
 *
 * This function is for hiding a field depending on the value of another field.
 *
 * @param {object} values - the values of all the fields in the form.
 * @param {string} item - api key (in snake case) to check whether it should be removed.
 * @return {boolean} - returns true if the item will not be shown
 */
export const hideField = (values, item) => {
  if (item === 'import_file') {
    if (values.state === undefined || values.state === 'DRAFT') {
      return false;
    }
    return true;
  }
  return false;
};

/**
 * compareTemplate
 * take two objects and compare the keys to build a score of how similiar
 * they are.
 * if they have no matching keys they have no similarity.
 */
export const compareTemplate = (a, b) => {
  // This may not be the fastest way, but it works
  const allKeys = new Set(Object.keys(a).concat(Object.keys(b)));
  let missingKeys = 0;
  for (const key of allKeys.values()) {
    if (!Object.prototype.hasOwnProperty.call(a, key)) {
      missingKeys++;
    }
    if (!Object.prototype.hasOwnProperty.call(b, key)) {
      missingKeys++;
    }
  }
  return 1.0 - missingKeys / allKeys.size;
};
