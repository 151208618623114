// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import { all, put, takeLatest } from 'redux-saga/effects';

import {
  HELP_REQUEST,
  HELP_SUCCESS,
  HELP_FAILURE,
  HELP_TIP_REQUEST,
  HELP_TIP_SUCCESS,
  HELP_TIP_FAILURE,
} from './helpActions';

import { fetchHelp } from '../../api/getters/help.js';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';

export function* helpFetch(action) {
  const { query } = action;
  try {
    const { data, error, response } = yield fetchHelp(query);
    if (data) {
      yield put({ type: HELP_SUCCESS, data });
    } else if (error) {
      yield put({ type: HELP_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, HELP_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, HELP_FAILURE);
  }
}

export function* watchHelpFetch() {
  yield takeLatest(HELP_REQUEST, helpFetch);
}

export function* helpTipFetch(action) {
  const { query } = action;
  try {
    const { data, error, response } = yield fetchHelp(query);
    if (data) {
      yield put({ type: HELP_TIP_SUCCESS, data });
    } else if (error) {
      yield put({ type: HELP_TIP_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, HELP_TIP_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, HELP_TIP_FAILURE);
  }
}

export function* watchHelpTipFetch() {
  yield takeLatest(HELP_TIP_REQUEST, helpTipFetch);
}

export default function* helpSaga() {
  yield all([watchHelpFetch(), watchHelpTipFetch()]);
}
