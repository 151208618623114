import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../store/dialogActions';

import FindingsBulkEditForm from './FindingsBulkEditForm';

const FindingsBulkEditDialog = props => {
  const { findingsIds, selectedRows } = props;
  const dispatch = useDispatch();

  return (
    <Grid container direction="column" justifyContent="space-between">
      <Grid item>Select the fields you wish to update on the {findingsIds?.length} selected findings.</Grid>
      <Grid item>
        <FindingsBulkEditForm
          findingsIds={findingsIds}
          selectedRows={selectedRows}
          onSubmit={() => {
            dispatch(closeDialog());
          }}
        />
      </Grid>
    </Grid>
  );
};

FindingsBulkEditDialog.defaultProps = {};

FindingsBulkEditDialog.propTypes = {
  findingsIds: PropTypes.array.isRequired,
  selectedRows: PropTypes.array.isRequired,
};

export default FindingsBulkEditDialog;
