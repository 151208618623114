import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';

import AssetsTable from './AssetsTable';

import { getAllAssets } from '../../store/features/assetsActions';
import { setAssetTreeView } from '../../store/settings/settingsActions';
import { toQueryString } from '../../utilities/strings';
import { getParams } from '../../utilities/route';
import { useFeatureFlags, useTableViewSettings } from '../../hooks/settingsHooks';
import { tableViews } from '../../utilities/tables';
import AssetTreeViewer from './AssetTreeViewer';
import Switch from '../shared/buttons/Switch';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  switch: {
    textAlign: 'right',
    padding: theme.spacing(2),
  },
}));

const AssetsTablePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const { assetsTableViewKey } = tableViews;
  const { tableQuery: assetsQuery, page, views } = useTableViewSettings(assetsTableViewKey);
  const { hasAssetTreeView } = useFeatureFlags();
  const { assetTreeView } = useSelector(state => state.settings.assetTreeView);

  const handleToggle = value => {
    dispatch(setAssetTreeView(!!value));
  };
  // check for url
  const queryParams = getParams(location, assetsQuery);

  useEffect(() => {
    // want to be able to find any inactive assets, so leaving is_active param out for this fetch
    dispatch(getAllAssets(queryParams));
  }, []); // eslint-disable-line

  const tableChangeHandler = query => {
    // no need to fetch data if the query is the same.
    const newSearch = toQueryString(query);
    if (location.search === newSearch) return;
    const newLocation = location;
    newLocation.search = newSearch;
    history.push(newLocation);
    dispatch(getAllAssets(query));
  };

  return (
    <div className={classes.root} data-testid="asset-table-page">
      {hasAssetTreeView && (
        <div className={classes.switch}>
          <Switch label="Tree View" initialValue={assetTreeView} onChange={handleToggle} />
        </div>
      )}
      {assetTreeView ? (
        <AssetTreeViewer />
      ) : (
        <AssetsTable
          title="Assets"
          queryParamObj={queryParams}
          tableChangeHandler={tableChangeHandler}
          page={page}
          views={views}
        />
      )}
    </div>
  );
};

export default AssetsTablePage;
