import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, KmlLayer } from '@react-google-maps/api';
import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LoadingPage from '../shared/displays/LoadingPage';
import { useGoogleMapLoader } from '../../hooks/mapHooks';

const useStyles = makeStyles(theme => ({
  root: {
    height: `100%`,
  },
}));

const KmlLayerMap = props => {
  const { file } = props;
  const classes = useStyles();
  const [mapRef, setMapRef] = useState(null);

  // map only renders this center position if there is no KML data
  const latlng = {
    lat: 39.8283,
    lng: -98.5795,
  };

  useEffect(() => {
    if (mapRef) {
      mapRef.setCenter(latlng);
      mapRef.setZoom(5);
    }
    return () => {
      setMapRef(null);
    };
  }, [mapRef]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadMapHandler = map => {
    setMapRef(map);
  };

  const { isLoaded } = useGoogleMapLoader();

  const mapContainerStyle = {
    height: '100%',
  };

  const mapOptions = {
    MapTypeId: 'roadmap',
    streetViewControl: false,
  };

  if (!isLoaded) {
    return <LoadingPage />;
  }

  return (
    <Paper className={classes.root}>
      <GoogleMap id="map" onLoad={loadMapHandler} mapContainerStyle={mapContainerStyle} options={mapOptions}>
        <KmlLayer url={file} options={{ preserveViewport: false, screenOverlays: false }} />
      </GoogleMap>
    </Paper>
  );
};

KmlLayerMap.propTypes = {
  file: PropTypes.string.isRequired,
};

export default KmlLayerMap;
