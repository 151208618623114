import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Alert, ButtonGroup } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import GetAppIcon from '@mui/icons-material/GetApp';
import prettyBytes from 'pretty-bytes';
import Table from '../shared/table/Table';
import MultiSelectFilter from '../shared/table/MultiSelectFilter';
import SizeRangeFilter from '../shared/table/SizeRangeFilter';
import CustomToolbarMediaGallery from '../shared/table/CustomToolbarMediaGallery';
import CustomToolbarFileUploader from '../shared/table/CustomToolbarFileUploader';
import InspectionMediaToolBarSelect from '../inspectionMedia/InspectionMediaToolBarSelect';

import { inspectionMediaBulkDeleteEndpoint } from '../../store/apiV2/inspectionMedia';

import FileUploader from '../projects/ProjectFileUploader';
import {
  setInspectionMediaTableView,
  setInspectionMediaTableSize,
} from '../../store/settings/views/inspectionMediaTableViewRedux';
import { documentCategoryOptions, mediaStateOptions } from '../../api/features/constants';
import ButtonIcon from '../shared/buttons/ButtonIcon';
// import SecondaryButton from '../shared/buttons/SecondaryButton';
import StyledLink from '../shared/StyledLink';
import { setColumns } from '../shared/table/columns';
import { openDialog } from '../../store/dialogActions';
import DeleteDialog from '../shared/Dialog/DeleteDialog';
import PrettyBytes from '../shared/displays/PrettyBytes';
import { apiDateToString, displayOption } from '../../utilities/strings';
import { noThumbnail } from '../../utilities/files';
import Description from '../shared/table/Description';
import { useFeatureFlags, useTableViews } from '../../hooks/settingsHooks';
import { useTableRowSelectionManagerOptions } from '../../hooks/tableHooks';
import useDateRangeColumnHook from '../../hooks/table/dateRangeColumnHook';
import { tableViews } from '../../utilities/tables';
import { openSnackbar } from '../../store/snackbarActions';
import GeoPointDisplay from '../shared/form/GeoPointDisplay';
import GeoPoint from '../shared/form/GeoPoint';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const useStyles = makeStyles(theme => ({
  thumbnailImage: {
    width: theme.spacing(10),
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const InspectionMediaTable = props => {
  const {
    title,
    setMediaSlide,
    embedded,
    showGallery,
    setShowGallery,
    showSlides,
    projectId,
    formId,
    isReadOnly,
    filename,
    tableChangeHandler,
    queryParamObj,
    enableGallery,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();

  const { dispatchRequest: inspectionMediaBulkDelete, resetState: resetBulkDeleteErrors } =
    inspectionMediaBulkDeleteEndpoint.useEndpoint();

  useEffect(() => {
    resetBulkDeleteErrors();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { loading, data, count, error } = useSelector(state => {
    const loading = state.inspectionMedia.all.loading;
    const data = state.inspectionMedia.all.dataAll.results;
    const count = state.inspectionMedia.all.dataAll.count || null;
    const error = state.apiV2.inspectionMediaEach.error; // Get the error from apiV2 inspectionMediaBulkDelete
    return {
      loading,
      data,
      count,
      error,
    };
  });

  const { inspectionMediaTableViewKey } = tableViews;
  const { hasAccessDisplay, hasChecklistViewer } = useFeatureFlags();
  const views = useTableViews(inspectionMediaTableViewKey);

  const queryFilters = queryParamObj;
  // const categoryFilterOptions = ['office', 'image', 'video', 'archive', 'other'];

  const queryNameFilter = queryFilters?.name ? queryFilters.name.split(',') : [];
  const queryStateFilter = queryFilters?.state ? queryFilters.state.split(',') : [];
  const queryCategoryFilter = queryFilters?.category ? queryFilters.category.split(',') : [];

  const queryGreaterThanFilter = queryFilters?.size_min || undefined;
  const queryLessThanFilter = queryFilters?.size_max || undefined;

  const [categoryFilterList, setCategoryFilterList] = useState(queryCategoryFilter);
  const [stateFilterList, setStateFilterList] = useState(queryStateFilter);
  const [sizeFilter, setSizeFilter] = useState([queryGreaterThanFilter, queryLessThanFilter]);

  // default the file uploader to open if not ready only and there are no media files
  const [showFileUploader, setShowFileUploader] = useState(isReadOnly ? false : count == null || false);

  const handleThumbnailClick = id => {
    setMediaSlide(id);
  };

  /** @type {Column[]} */
  const columns = [
    {
      // This needs to be first and excluded so customBodyRender can access the id to create the link.
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      // This needs to be second and excluded so CustomToolbarSelectedMedia knows what files have been selected
      name: 'file',
      label: 'File',
      options: {
        filter: false,
        display: 'excluded',
        download: true,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        filterType: 'textField',
        filterField: 'name',
        filterList: queryNameFilter,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <StyledLink to={`/inspection-media/${tableMeta.rowData[columns.findIndexByName['id']]}`} value={value} />
          );
        },
      },
    },
    {
      name: 'thumbnail',
      label: 'Thumbnail',
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value, tableMeta) => {
          const src = noThumbnail(tableMeta.rowData[columns.findIndexByName['document_category']]);
          return value ? (
            <img
              src={value}
              className={classes.thumbnailImage}
              onClick={() => {
                handleThumbnailClick(tableMeta.rowData[columns.findIndexByName['id']]);
              }}
            />
          ) : (
            <img
              src={src}
              className={classes.thumbnailImage}
              onClick={() => {
                handleThumbnailClick(tableMeta.rowData[columns.findIndexByName['id']]);
              }}
            />
          );
        },
      },
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        filter: true,
        sort: true,
        filterType: 'textField',
        customBodyRender: value => {
          if (!value) return <></>;
          return <Description value={value} />;
        },
      },
    },

    {
      name: 'state',
      label: 'State',
      options: {
        filter: true,
        sort: true,
        filterType: 'custom',
        filterList: stateFilterList,
        customBodyRender: value => {
          return displayOption(value, mediaStateOptions);
        },
        customFilterListOptions: {
          render: values => {
            return values.map(v => displayOption(v, mediaStateOptions));
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            setStateFilterList(filterList[index]);
            return filterList;
          },
        },
        filterOptions: {
          names: mediaStateOptions.map(option => option.value),
          logic: (state, filters) => {
            if (filters.length) return !filters.includes(state);
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            const formatValue = item => displayOption(item, mediaStateOptions);
            filterData[index] = mediaStateOptions.map(option => option.value);
            return (
              <MultiSelectFilter
                title="State"
                filterList={filterList}
                localFilterList={stateFilterList}
                onChange={onChange}
                index={index}
                column={column}
                filterData={filterData}
                updateFilters={setStateFilterList}
                formatValue={formatValue}
              />
            );
          },
        },
      },
    },
    {
      name: 'geo_point',
      label: 'Geo Point',
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => <GeoPointDisplay geoPoint={value} />,
        downloadBody: value => new GeoPoint(value).toString(),
      },
    },
    {
      name: 'component_display',
      label: 'Component',
      options: {
        filterType: 'textField',
        sortField: 'component_display',
        filter: true,
        sort: true,
      },
    },
    {
      name: 'location_zone_display',
      label: 'Location Zone',
      options: {
        filterType: 'textField',
        sortField: 'location_zone_display',
        filter: true,
        sort: true,
      },
    },
    {
      name: 'location_code_display',
      label: 'Location Code',
      options: {
        filterType: 'textField',
        sortField: 'location_code_display',
        filter: true,
        sort: true,
      },
    },
    {
      name: 'access_display',
      label: 'Access',
      options: {
        filterType: 'textField',
        sortField: 'access_display',
        display: hasAccessDisplay ? 'true' : 'excluded',
        filter: true,
        sort: true,
      },
    },
    {
      name: 'attached_to',
      label: 'Attached To',
      options: {
        display: hasChecklistViewer ? 'true' : 'excluded',
        filter: false,
        sort: false,
        customBodyRender: value => {
          /// Return list of links to line items

          if (!value) {
            return null;
          }
          // ["472::secKey::lineKey"]
          return value.map(v => {
            const [checklistId, secKey, lineKey] = v.split('::');
            return (
              <StyledLink
                key={v}
                to={`/checklist-viewer/${checklistId}`}
                search={`line=${v}`}
                value={`${secKey} ${lineKey}`}
              />
            );
          });
        },
      },
    },
    {
      name: 'document_category',
      label: 'Category',
      options: {
        filter: true,
        sort: true,
        filterType: 'custom',
        filterList: categoryFilterList,
        // customBodyRender: value => {
        //   return displayOption(value, mediaStateOptions);
        // },
        customFilterListOptions: {
          render: values => {
            return values.map(v => displayOption(v, documentCategoryOptions));
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            setCategoryFilterList(filterList[index]);
            return filterList;
          },
        },
        filterOptions: {
          names: documentCategoryOptions.map(option => option.value),
          logic: (category, filters) => {
            if (filters.length) return !filters.includes(category);
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            const formatValue = item => displayOption(item, documentCategoryOptions);
            filterData[index] = documentCategoryOptions.map(option => option.value);
            return (
              <MultiSelectFilter
                title="Document Category"
                filterList={filterList}
                localFilterList={categoryFilterList}
                onChange={onChange}
                index={index}
                column={column}
                filterData={filterData}
                updateFilters={setCategoryFilterList}
                formatValue={formatValue}
              />
            );
          },
        },
      },
    },
    {
      name: 'size',
      label: 'Size',
      filterType: 'numberRange',
      options: {
        filter: true,
        sort: true,
        filterType: 'custom',
        display: 'true',
        filterList: sizeFilter,
        customBodyRender: value => {
          return <PrettyBytes num={value || 0} />;
        },
        customFilterListOptions: {
          render: v =>
            `Size ${
              v.includes(undefined)
                ? v[0]
                  ? `Greater Than ${prettyBytes(parseInt(v[0]))}`
                  : `Less Than ${prettyBytes(parseInt(v[1]))}`
                : `${v.map(item => prettyBytes(parseInt(item)))}`.replace(',', '-')
            }`,
          update: (filterList, filterPos, index) => {
            filterList[index] = [undefined, undefined];
            setSizeFilter(filterList[index]);
            return filterList;
          },
        },
        filterOptions: {
          fullWidth: true,
          display: (filterList, onChange, index, column) => {
            if (
              !filterList[index][0] &&
              !filterList[index][1] &&
              (sizeFilter[0] || sizeFilter[1]) &&
              (queryGreaterThanFilter || queryLessThanFilter)
            ) {
              setSizeFilter([undefined, undefined]);
            }

            return (
              <SizeRangeFilter
                title="Size"
                filter={filterList[index]}
                onChange={onChange}
                index={index}
                column={column}
                update={setSizeFilter}
              />
            );
          },
        },
      },
    },
    {
      name: 'updated_on',
      label: 'Updated',
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => apiDateToString(value, 'date'),
      },
    },
    useDateRangeColumnHook({
      name: 'captured_on',
      label: 'Captured On',
      queryParamObj,
      queryStartKey: 'captured_on_after',
      queryEndKey: 'captured_on_before',
    }),
    useDateRangeColumnHook({
      name: 'created_on',
      label: 'Created',
      queryParamObj,
      queryStartKey: 'created_on_after',
      queryEndKey: 'created_on_before',
    }),
    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        print: false,
        viewColumns: false,
        customBodyRender: (_, info) => {
          // 0 is the first element in column
          const id = info.rowData[columns.findIndexByName['id']];
          const url = info.rowData[columns.findIndexByName['file']];
          const name = info.rowData[columns.findIndexByName['name']];
          return (
            <>
              <ButtonGroup>
                <ButtonIcon
                  disabled={isReadOnly}
                  history={history}
                  icon={EditIcon}
                  location={location}
                  to={`/inspection-media/${id}/edit`}
                />
                <ButtonIcon
                  disabled={isReadOnly}
                  icon={DeleteIcon}
                  onClick={() => {
                    dispatch(
                      openDialog(
                        'Delete Inspection Media?',
                        <DeleteDialog
                          id={id}
                          name={name}
                          deleteAction={async () => {
                            try {
                              await inspectionMediaBulkDelete({ ids: [parseInt(id)] });
                              tableChangeHandler(queryParamObj); // refresh table
                            } catch (error) {
                              console.error('Error:', error);
                              dispatch(openSnackbar(error.error, 'error'));
                            }
                          }}
                        />
                      )
                    );
                  }}
                />
                <ButtonIcon icon={GetAppIcon} href={`${url}&response-content-disposition=attachment`} download />
              </ButtonGroup>
            </>
          );
        },
      },
    },
  ];

  // handle columns display
  setColumns(columns, views);
  const getInspectionMediaFiles = (selectedRows, displayData) => {
    const selected = [];
    const columnNames = columns.map(column => column.name);
    for (const row of selectedRows.data) {
      const rowData = displayData[row.index].data;
      const url = rowData[columnNames.indexOf('file')];
      const filename = rowData[columnNames.indexOf('name')].props.value;
      let dirname;
      if (projectId) {
        dirname = `project-${projectId}`;
      } else if (formId) {
        dirname = `form-${formId}`;
      }
      selected.push({ url, filename, dirname });
    }
    return selected;
  };

  // used by the handleUnzip function
  const getSelectedRowIds = (selectedRows, displayData) => {
    return selectedRows.data.map(row => {
      const columnNames = columns.map(column => column.name);
      const rowData = displayData[row.index].data;
      const id = rowData[columnNames.indexOf('id')];
      return id;
    });
  };

  const { tableOptions } = useTableRowSelectionManagerOptions();

  /** @type {TableOptions} */
  const options = {
    // if the column view changes, update redux with either 'add' or 'remove'
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setInspectionMediaTableView(changedColumn, action));
    },
    onChangeRowsPerPage: numberOfRows => {
      dispatch(setInspectionMediaTableSize(numberOfRows));
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      return (
        <InspectionMediaToolBarSelect
          selectedRows={selectedRows}
          displayData={displayData}
          getInspectionMediaFiles={getInspectionMediaFiles}
          getSelectedRowIds={getSelectedRowIds}
          setSelectedRows={setSelectedRows}
          columns={columns}
          tableChangeHandler={tableChangeHandler}
        />
      );
    },
    customToolbar: () => (
      <>
        {enableGallery && <CustomToolbarMediaGallery showGallery={showGallery} setShowGallery={setShowGallery} />}
        {!isReadOnly && (
          <CustomToolbarFileUploader
            projectId={projectId}
            showFileUploader={showFileUploader}
            setShowFileUploader={setShowFileUploader}
          />
        )}
      </>
    ),
    enableNestedDataAccess: '.',
    searchOpen: !embedded,
    ...tableOptions,
  };

  return (
    <div className={classes.tableMargin}>
      {showFileUploader && <FileUploader projectId={projectId} checklistId={formId} />}
      {error && (
        <Alert
          severity="error"
          sx={{
            marginTop: '1rem',
            marginBottom: '2rem',
            display: 'inline-flex',
            width: '100%',
            color: 'red',
          }}
        >
          {error}
        </Alert>
      )}
      <Table
        title={title}
        serverSide
        queryParamObj={queryParamObj}
        tableChangeHandler={tableChangeHandler}
        columns={columns}
        data={data}
        options={options}
        loading={loading}
        embedded={embedded}
        projectId={projectId}
        count={count}
        filename={filename}
        galleryOpen={showGallery || showSlides}
      />
    </div>
  );
};

InspectionMediaTable.defaultProps = {
  title: '',
  embedded: false,
  showGallery: false,
  setShowGallery: undefined,
  filename: null,
  enableGallery: true,
  queryParamObj: {},
};

InspectionMediaTable.propTypes = {
  title: PropTypes.string,
  setMediaSlide: PropTypes.func.isRequired,
  tableChangeHandler: PropTypes.func.isRequired,
  queryParamObj: PropTypes.object,
  embedded: PropTypes.bool,
  showGallery: PropTypes.bool,
  showSlides: PropTypes.bool,
  setShowGallery: PropTypes.func,
  projectId: PropTypes.number,
  formId: PropTypes.number,
  isReadOnly: PropTypes.bool,
  filename: PropTypes.string,
  enableGallery: PropTypes.bool,
};

export default InspectionMediaTable;
