export const CHECKLIST_TEMPLATES_REQUEST = 'CHECKLIST_TEMPLATES_REQUEST';
export const CHECKLIST_TEMPLATES_SUCCESS = 'CHECKLIST_TEMPLATES_SUCCESS';
export const CHECKLIST_TEMPLATES_FAILURE = 'CHECKLIST_TEMPLATES_FAILURE';
export const CHECKLIST_TEMPLATES_CREATE_REQUEST = 'CHECKLIST_TEMPLATES_CREATE_REQUEST';
export const CHECKLIST_TEMPLATES_CREATE_SUCCESS = 'CHECKLIST_TEMPLATES_CREATE_SUCCESS';
export const CHECKLIST_TEMPLATES_CREATE_FAILURE = 'CHECKLIST_TEMPLATES_CREATE_FAILURE';
export const CHECKLIST_TEMPLATES_UPDATE_REQUEST = 'CHECKLIST_TEMPLATES_UPDATE_REQUEST';
export const CHECKLIST_TEMPLATES_UPDATE_SUCCESS = 'CHECKLIST_TEMPLATES_UPDATE_SUCCESS';
export const CHECKLIST_TEMPLATES_UPDATE_FAILURE = 'CHECKLIST_TEMPLATES_UPDATE_FAILURE';
export const CHECKLIST_TEMPLATES_DELETE_REQUEST = 'CHECKLIST_TEMPLATES_DELETE_REQUEST';
export const CHECKLIST_TEMPLATES_DELETE_SUCCESS = 'CHECKLIST_TEMPLATES_DELETE_SUCCESS';
export const CHECKLIST_TEMPLATES_DELETE_FAILURE = 'CHECKLIST_TEMPLATES_DELETE_FAILURE';
export const CHECKLIST_TEMPLATES_ALL_REQUEST = 'CHECKLIST_TEMPLATES_ALL_REQUEST';
export const CHECKLIST_TEMPLATES_ALL_SUCCESS = 'CHECKLIST_TEMPLATES_ALL_SUCCESS';
export const CHECKLIST_TEMPLATES_ALL_FAILURE = 'CHECKLIST_TEMPLATES_ALL_FAILURE';
export const CHECKLIST_TEMPLATES_EXPORT_REQUEST = 'CHECKLIST_TEMPLATES_EXPORT_REQUEST';
export const CHECKLIST_TEMPLATES_EXPORT_SUCCESS = 'CHECKLIST_TEMPLATES_EXPORT_SUCCESS';
export const CHECKLIST_TEMPLATES_EXPORT_FAILURE = 'CHECKLIST_TEMPLATES_EXPORT_FAILURE';
export const CHECKLIST_TEMPLATES_DOWNLOAD_YAML_REQUEST = 'CHECKLIST_TEMPLATES_DOWNLOAD_YAML_REQUEST';
export const CHECKLIST_TEMPLATES_DOWNLOAD_YAML_SUCCESS = 'CHECKLIST_TEMPLATES_DOWNLOAD_YAML_SUCCESS';
export const CHECKLIST_TEMPLATES_DOWNLOAD_YAML_FAILURE = 'CHECKLIST_TEMPLATES_DOWNLOAD_YAML_FAILURE';
export const CHECKLIST_TEMPLATES_CLEAR_EACH_ERROR = 'CHECKLIST_TEMPLATES_CLEAR_EACH_ERROR';

export const getChecklistTemplates = id => {
  return {
    type: CHECKLIST_TEMPLATES_REQUEST,
    id,
  };
};

export const createChecklistTemplates = body => {
  return {
    type: CHECKLIST_TEMPLATES_CREATE_REQUEST,
    body,
  };
};

export const updateChecklistTemplates = (id, body) => {
  return {
    type: CHECKLIST_TEMPLATES_UPDATE_REQUEST,
    id,
    body,
  };
};

export const deleteChecklistTemplates = id => {
  return {
    type: CHECKLIST_TEMPLATES_DELETE_REQUEST,
    id,
  };
};

export const getAllChecklistTemplates = query => {
  return {
    type: CHECKLIST_TEMPLATES_ALL_REQUEST,
    query,
  };
};

export const exportChecklistTemplates = (id, filename) => {
  return {
    type: CHECKLIST_TEMPLATES_EXPORT_REQUEST,
    id,
    filename,
  };
};

export const downloadChecklistTemplateYAML = (id, filename) => {
  return {
    type: CHECKLIST_TEMPLATES_DOWNLOAD_YAML_REQUEST,
    id,
    filename,
  };
};

export const clearEachError = () => {
  return {
    type: CHECKLIST_TEMPLATES_CLEAR_EACH_ERROR,
  };
};
