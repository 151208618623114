// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import { all, put, takeLatest } from 'redux-saga/effects';

import { ASSETS_GETTER_REQUEST, ASSETS_GETTER_SUCCESS, ASSETS_GETTER_FAILURE } from './assetsGetterActions';

import { fetchAssets } from '../../api/getters/assets.js';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';
import { fetchListAll } from '../../utilities/sagaHelpers';

export function* assetsFetch(action) {
  const { query } = action;
  try {
    const { data, error, response } = yield fetchListAll(fetchAssets, query);
    if (data) {
      yield put({ type: ASSETS_GETTER_SUCCESS, data });
    } else if (error) {
      yield put({ type: ASSETS_GETTER_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, ASSETS_GETTER_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, ASSETS_GETTER_FAILURE);
  }
}

export function* watchAssetsFetch() {
  yield takeLatest(ASSETS_GETTER_REQUEST, assetsFetch);
}

export default function* assetsSaga() {
  yield all([watchAssetsFetch()]);
}
