import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Toolbar, Typography, AppBar, Link } from '@mui/material';

const ChecklistTemplateBuilderToolbar = props => {
  const logo = useSelector(state => state.settings.logo.src);
  const { title } = props;

  // const handleBack = () => history.push(backLink);

  return (
    <AppBar className="app-bar" elevation={1}>
      <Toolbar>
        <Link href="/">
          <img src={logo} height="32" style={{ marginRight: '5px' }} />
        </Link>
        <Typography align="left" variant="h6" noWrap style={{ flex: 1 }}>
          {' '}
          {title}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

ChecklistTemplateBuilderToolbar.propTypes = {
  title: PropTypes.object,
  // backLink: PropTypes.string,
};
ChecklistTemplateBuilderToolbar.defaultProps = {
  title: <></>,
  // backLink: '',
};

export default ChecklistTemplateBuilderToolbar;
