import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, Tabs, Tab, Divider, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { getChecklists } from '../../../store/features/checklistsActions';
import Loading from '../../shared/displays/Loading';
import Error from '../../shared/displays/Error';
import FormView from './FormView';
import FormSummary from './FormSummary';
import FormToolbar from './FormToolbar';
import { isEmpty } from '../../../utilities/objects';
import { useFeatureFlags, usePermissions } from '../../../hooks/settingsHooks';
import { getAllChecklistTasks } from '../../../store/features/checklistTasksActions';
import { getAllProjectTypes } from '../../../store/features/projectTypesActions';
import { useCachedProjectTypes } from '../../../hooks/projectTypesHooks';

import ChecklistTasksTable from '../../checklistTasks/ChecklistTasksTable';
import ReportsTable from '../../shared/ReportsTable';

import FormMediaGallery from './FormMediaGallery';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2, 'auto'),
    padding: theme.spacing(2),
    marginTop: theme.spacing(8),
    textAlign: 'left',
  },
  button: {
    padding: theme.spacing(0, 1),
  },
  table: {
    padding: theme.spacing(0, 3),
  },
  embeddedTableStyle: {
    margin: theme.spacing(0, 4),
  },
  tabPanel: {
    marginTop: 0,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.any.isRequired,
  index: PropTypes.any.isRequired,
};

const FormDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const relatedChecklistTasksQuery = { checklist: id };
  const [selectedReport, setReport] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const { hasChecklistTasks, hasFormsToProjects } = useFeatureFlags();
  const { hasChecklistEdit, hasProjectCreate } = usePermissions();
  const { projectTypeOptions: projectTypes } = useCachedProjectTypes();
  const { data, loading, error } = useSelector(state => {
    const { data, loading, error } = state.checklists.each;

    return {
      data,
      loading,
      error,
    };
  });

  const canShowProject = (checklist, projectTypes) => {
    // check feature flag
    if (!hasFormsToProjects) {
      return false;
    }

    // check permission
    if (!hasProjectCreate) {
      return false;
    }

    // check correct template type
    if (checklist.template.type === 'ANYTIME') {
      return false;
    }

    // check if already has project
    if (checklist.project) {
      return false;
    }

    // check has valid project types
    const projectTypeOptions = projectTypes.filter(
      projectType => projectType.checklistTemplate?.id?.toString() === checklist.template?.id?.toString()
    );
    if (projectTypeOptions.length === 0) {
      return false;
    }

    return true;
  };

  const classes = useStyles();

  const refresh = () => {
    dispatch(getChecklists(id));
    dispatch(getAllChecklistTasks(relatedChecklistTasksQuery));
    if (hasFormsToProjects) {
      dispatch(getAllProjectTypes());
    }
  };
  useEffect(() => {
    refresh();
  }, [dispatch, id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return <Loading />;
  }
  if (isEmpty(data) && !error) {
    return <Loading />;
  }

  if (error) return <Error error={error} style={{ marginTop: '56px', fontSize: '40px' }} />;

  // redirect to projects if form is primary and don't want to put in useEffect
  if (Number(id) === data?.id && data?.project_order_index === 0 && data?.project) {
    return <Redirect to={`/projects/${data.project.id}`} />;
  }
  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  return (
    <div className={classes.root}>
      <FormToolbar data={data} displayProjectButton={canShowProject(data, projectTypes)} />
      <Grid container>
        <Grid item xs={12}>
          <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary">
            <Tab label="Overview" />
            <Tab label="Media" />
          </Tabs>
          <Divider className={`${classes.divider} ${classes.tabPanel}`} />
          <TabPanel value={tabValue} index={0}>
            {hasChecklistEdit && <FormSummary form={data} />}
            <ReportsTable
              form={data.id}
              report_types={data.report_types}
              selectedReport={selectedReport}
              setSelectedReport={setReport}
              refresh={refresh}
            />
            {hasChecklistTasks && (
              <ChecklistTasksTable
                embedded
                title={'Tasks'}
                styleProjectDetail={classes.embeddedTableStyle}
                checklist={{ id: data.id, createdBy: { ...data.created_by } }}
              />
            )}
            <FormView id={id} data={data} loading={loading} error={error} />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <FormMediaGallery id={id} form={data} />
          </TabPanel>
        </Grid>
      </Grid>
    </div>
  );
};

export default FormDetail;
