import { apiURL } from '../api/base';

const unknownCondition = {
  value: -2,
  color: 'grey',
  description: 'Unknown Condition',
  note: 'Unknown Condition',
};
const disabledCondition = {
  value: -1,
  color: 'grey',
  description: 'Disabled',
  note: 'Disabled',
};
const notSetCondition = {
  value: 0,
  color: 'blue',
  description: 'Not set',
  note: 'Not set',
};

// https://github.com/huvrdata/huvr/blob/a8fc7c9813dbb493ffe5b0b2fc3d0f5887d1b270/huvr_api/huvr_api/assets/models.py#L167-L168
const ICON_URL = '/api-static/images/markers/pin-COLOR-11.png';

export const conditionLookup = (condition, assetConditions) => {
  const conditionInt = typeof condition === 'string' ? parseInt(condition) : condition;
  if (assetConditions[conditionInt]) {
    return assetConditions[conditionInt];
  }
  // handle system values and values missing from above.
  switch (conditionInt) {
    case 0:
      return notSetCondition;
    case -1:
      return disabledCondition;
    default:
      return unknownCondition;
  }
};

/**
 *
 * @param {Number} assetConditionValue
 * @param {Object} assetType
 * @param {Array} assetConditions
 *
 * looks at the `AssetType.icon_url` property
 *  (which is hard-coded to "/api-static/images/markers/pin-blue-11.png")
 *  replaces `blue` with the color associated with the condition
 *  see: https://github.com/huvrdata/huvr/pull/2782
 *
 * @returns {String} url
 */
export const iconUrlLookup = (assetConditionValue, assetConditions) => {
  const condition = conditionLookup(assetConditionValue, assetConditions);
  const assetTypeIconUrl = ICON_URL.replace(/COLOR/, condition.color);
  return apiURL + assetTypeIconUrl;
};

export const makeAssetConditionsMap = assetConditions => {
  const assetConditionsMap = {};
  for (const key in assetConditions) {
    assetConditionsMap[key] = assetConditions[key].description;
  }

  assetConditionsMap[-1] = 'Unknown Condition';
  return assetConditionsMap;
};
