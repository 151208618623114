/**
 * @param {string} endpointName
 */
export const buildEndpointActionTypes = endpointName => {
  return {
    request: `${endpointName}/REQUEST`,
    success: `${endpointName}/SUCCESS`,
    failure: `${endpointName}/FAILURE`,
    resetState: `${endpointName}/RESET_STATE`,
  };
};

/**
 * @param {String} endpointName
 */
export const buildEndpointActionCreators = endpointName => {
  const actionTypes = buildEndpointActionTypes(endpointName);

  /**
   * HINT - use the `useEndpoint` hook in your component
   *  which makes this available as `dispatchRequest`
   *  and provides Promise functionality
   *
   * for backwards compat, also export this as a function,
   *  and can manually add `resolve` / `reject` callbacks as needed
   *
   * @param  {Array=} apiRequestArgs
   * @param {Function=} resolve optional
   * @param {Function=} reject optional
   * @returns {Object} - the action
   */
  const request = (apiRequestArgs, resolve, reject) => ({
    type: actionTypes.request,
    apiRequestArgs: apiRequestArgs || [],
    resolve: resolve || (() => {}),
    reject: reject || (() => {}),
  });

  /**
   * @param {Object} data - the data returned from the apiRequest
   * @returns {Object} - the action
   */
  const success = data => ({
    type: actionTypes.success,
    data,
  });

  /**
   * @param {string} error - the error message
   * @param {Object=} formError - the error object from the apiRequest
   */
  const failure = (error, formError) => ({
    type: actionTypes.failure,
    error,
    formError,
  });

  /**
   * @returns {Object} - the action
   */
  const resetState = () => ({
    type: actionTypes.resetState,
  });

  return {
    request,
    success,
    failure,
    resetState,
  };
};
