import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { noThumbnail } from '../../utilities/files';
import CommonFormView from '../shared/form/CommonView';
import { jsonKeyToLabel } from '../../utilities/strings';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: 0,
  },
  thumbnailImage: {
    width: theme.spacing(10),
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const SelectedFindingView = props => {
  const { data } = props;
  const classes = useStyles();

  const formSettings = {
    fieldOrder: {
      detail: {
        display: 'Selected Finding',
        fields: ['id', 'asset', 'component_display', 'location_zone_display', 'location_code_display', 'thumbnail'],
      },
    },
    removeField: () => false,
    hideField: () => false,
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in formSettings.fieldOrder) {
    formSettings.fieldOrder[section].fields.forEach(key => {
      // cast to string because booleans do not show up in the field
      let displayLabel = jsonKeyToLabel(key); // eslint-disable-line prefer-const
      let displayValue = '';

      switch (key) {
        case 'id':
          fieldSettings[key] = {
            type: 'component',
            cellProps: {
              xs: 6,
            },
            fieldProps: {
              label: displayLabel,
              value: () => (
                <a href={`/findings/${data[key]}`} target="_blank" rel="noopener noreferrer">
                  <Typography color="textPrimary" variant="h7">
                    {data[key]}
                  </Typography>
                </a>
              ),
            },
          };
          return;
        case 'asset':
          displayValue = data[key].asset_path;
          break;
        case 'thumbnail':
          fieldSettings[key] = {
            type: 'component',
            fieldProps: {
              label: displayLabel,
              value: () =>
                Array.isArray(data?.overlays) && data.overlays.length > 0 ? (
                  <a href={`/findings/${data?.id}`} target="_blank" rel="noopener noreferrer">
                    <img
                      className={classes.thumbnailImage}
                      src={
                        data?.overlays[0]?.media?.thumbnail || noThumbnail(data?.overlays[0]?.media.document_category)
                      }
                    />
                  </a>
                ) : (
                  <></>
                ),
            },
          };
          return;
        default:
          displayValue = data[key].toString();
      }
      fieldSettings[key] = {
        type: 'text',
        fieldProps: {
          label: displayLabel,
          value: displayValue,
        },
      };
    });
  }
  return (
    <CommonFormView
      className={classes.root}
      bodyOnly
      embedded
      title={'Selected Finding'}
      values={data}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      loading={false}
      error={''}
    />
  );
};

SelectedFindingView.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SelectedFindingView;
