import React from 'react';
import PropTypes from 'prop-types';
import { Box, LinearProgress, Typography } from '@mui/material';

/**
 * The value of the progress indicator for the determinate and static variants.
 */

function LinearProgressWithLabel(props) {
  const { value } = props;
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} value={value} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.defaultProps = {
  value: 0,
};

LinearProgressWithLabel.propTypes = {
  /** Value between 0 and 100 */
  value: PropTypes.number,
};

export default LinearProgressWithLabel;
