// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import React from 'react';
import CommonRoutes from './utilities/CommonRoutes';
import RecommendedWorkPlanForm from '../../components/recommendedWorkPlan/RecommendedWorkPlanForm';
import RecommendedWorkPlanView from '../../components/recommendedWorkPlan/RecommendedWorkPlanView';
import RecommendedWorkPlanTablePage from '../../components/recommendedWorkPlan/RecommendedWorkPlanTablePage';
import MainLayout from '../layouts/MainLayout';

const RecommendedWorkPlanRoutes = props => {
  return (
    <CommonRoutes
      pageTitle="Recommended Work Plan"
      layout={MainLayout}
      Edit={<RecommendedWorkPlanForm update />}
      Create={<RecommendedWorkPlanForm />}
      View={<RecommendedWorkPlanView />}
      All={<RecommendedWorkPlanTablePage />}
    />
  );
};

export default RecommendedWorkPlanRoutes;
