import React from 'react';
import { Link } from 'react-router-dom';
import { Divider, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SecondaryButton from '../shared/buttons/SecondaryButton';
import { buildRoute } from '../../utilities/strings';

const ReservationSuccess = () => {
  const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(3, 4),
      margin: theme.spacing(9, 12),
    },
  }));

  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <h2>Reservation has been created successfully.</h2>
      <Divider />
      <h4>We will contact you with any questions.</h4>
      <Divider />
      <div>
        <Link className="link" to={buildRoute('/reservations')}>
          <SecondaryButton label="Go Back" onClick={() => {}} />
        </Link>
      </div>
    </Paper>
  );
};

export default ReservationSuccess;
