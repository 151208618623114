import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import PrimaryButton from '../buttons/PrimaryButton';
import { locationWithBack } from '../../../utilities/route';

const CustomToolbar = props => {
  const { addRoute, addAction } = props;
  const history = useHistory();
  const location = useLocation();

  const handleClick = () => {
    if (addRoute) {
      const route = addRoute.split('?');
      const newLocation = {
        pathname: route[0],
      };
      if (route[1]) {
        newLocation.search = `?${route[1]}`;
      }
      const next = locationWithBack(newLocation, location);
      history.push(next);
    } else {
      addAction();
    }
  };
  return (
    <React.Fragment>
      <PrimaryButton label="New" onClick={handleClick} icon Icon={AddIcon} />
    </React.Fragment>
  );
};
CustomToolbar.defaultProps = {
  addAction: () => {},
};

CustomToolbar.propTypes = {
  addRoute: PropTypes.string.isRequired,
  addAction: PropTypes.func,
};

export default CustomToolbar;
// export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(CustomToolbar);
