// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import { all, put, takeLatest } from 'redux-saga/effects';

import {
  PROJECT_SUMMARY_GETTER_REQUEST,
  PROJECT_SUMMARY_GETTER_SUCCESS,
  PROJECT_SUMMARY_GETTER_FAILURE,
} from './projectSummaryActions';

import { fetchProjectSummary } from '../../api/getters/projectSummary.js';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';

export function* projectSummaryFetch(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield fetchProjectSummary(id);
    if (data) {
      yield put({ type: PROJECT_SUMMARY_GETTER_SUCCESS, data });
    } else if (error) {
      yield put({ type: PROJECT_SUMMARY_GETTER_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, PROJECT_SUMMARY_GETTER_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, PROJECT_SUMMARY_GETTER_FAILURE);
  }
}

export function* watchProjectSummaryFetch() {
  yield takeLatest(PROJECT_SUMMARY_GETTER_REQUEST, projectSummaryFetch);
}

export default function* projectSummarySaga() {
  yield all([watchProjectSummaryFetch()]);
}
