export const LIBRARIES_REQUEST = 'LIBRARIES_REQUEST';
export const LIBRARIES_SUCCESS = 'LIBRARIES_SUCCESS';
export const LIBRARIES_FAILURE = 'LIBRARIES_FAILURE';
export const LIBRARIES_CREATE_REQUEST = 'LIBRARIES_CREATE_REQUEST';
export const LIBRARIES_CREATE_SUCCESS = 'LIBRARIES_CREATE_SUCCESS';
export const LIBRARIES_CREATE_FAILURE = 'LIBRARIES_CREATE_FAILURE';
export const LIBRARIES_UPDATE_REQUEST = 'LIBRARIES_UPDATE_REQUEST';
export const LIBRARIES_UPDATE_SUCCESS = 'LIBRARIES_UPDATE_SUCCESS';
export const LIBRARIES_UPDATE_FAILURE = 'LIBRARIES_UPDATE_FAILURE';
export const LIBRARIES_DELETE_REQUEST = 'LIBRARIES_DELETE_REQUEST';
export const LIBRARIES_DELETE_SUCCESS = 'LIBRARIES_DELETE_SUCCESS';
export const LIBRARIES_DELETE_FAILURE = 'LIBRARIES_DELETE_FAILURE';
export const LIBRARIES_ALL_REQUEST = 'LIBRARIES_ALL_REQUEST';
export const LIBRARIES_ALL_SUCCESS = 'LIBRARIES_ALL_SUCCESS';
export const LIBRARIES_ALL_FAILURE = 'LIBRARIES_ALL_FAILURE';
export const LIBRARIES_CLEAR = 'LIBRARIES_CLEAR';

export const getLibraries = id => {
  return {
    type: LIBRARIES_REQUEST,
    id,
  };
};

export const createLibraries = body => {
  return {
    type: LIBRARIES_CREATE_REQUEST,
    body,
  };
};

export const updateLibraries = (id, body) => {
  return {
    type: LIBRARIES_UPDATE_REQUEST,
    id,
    body,
  };
};

export const deleteLibraries = id => {
  return {
    type: LIBRARIES_DELETE_REQUEST,
    id,
  };
};

export const getAllLibraries = query => {
  return {
    type: LIBRARIES_ALL_REQUEST,
    query,
  };
};
