/**
 * fieldOrder
 *
 * An object where the keys define the sections of the form.   Each section requires the key display (string) and
 * fields (array of strings). Fields are the api keys (in snake case) for each field that will be displayed in the form.
 * Any fields that are never shown should not be added to this array.
 *
 * @return {object}
 */
export const fieldOrder = {
  info: {
    display: 'Info',
    fields: [
      'name',
      'asset',
      'type',
      'component',
      'component_display',
      'description',
      'installed_on',
      'planned_project',
    ],
  },
  location: {
    display: 'Location',
    fields: ['access', 'access_display', 'location_code', 'location_code_display', 'geo_point'],
  },
  extra: {
    display: 'Extra',
    fields: ['labels', 'labels_by_key'],
  },
  status: {
    display: 'Status',
    fields: ['created_on', 'updated_on', 'is_active'],
  },
  externalSources: {
    display: 'External Sources',
    fields: ['app_key', 'external_id'],
  },
};

/**
 *  removeField
 *
 *  removes a field depending on if a user is creating, updating or viewing.
 *
 *  @param {string} state - enum for creating, updating or viewing ['CREATE', 'UPDATE', 'VIEW']
 *  @param {string} item - api key (in snake case) to check whether it should be hidden.
 *  @return {boolean} - returns true if the item will not be shown
 */
export const removeField = state => item => {
  let fields = [];
  switch (state) {
    case 'CREATE':
      fields = [
        'access_display',
        'app_key',
        'component_display',
        'created_on',
        'location_code_display',
        'planned_project',
        'updated_on',
      ];
      break;
    case 'UPDATE':
      fields = [
        'access_display',
        'app_key',
        'asset',
        'component_display',
        'created_on',
        'location_code_display',
        'planned_project',
        'updated_on',
      ];
      break;
    case 'VIEW':
      fields = ['component', 'access', 'location_code'];
      break;
    default:
      return false;
  }
  return fields.includes(item);
};

/**
 * hideField
 *
 * This function is for hiding a field depending on the value of another field.
 *
 * @param {object} values - the values of all the fields in the form.
 * @param {string} item - api key (in snake case) to check whether it should be removed.
 * @param {boolean} hasAccessDisplay - whether to show access and access display fields (feature flag)
 * @return {boolean} - returns true if the item will not be shown
 */
export const hideField = (values, item, hasAccessDisplay) => {
  switch (item) {
    case 'access':
    case 'access_display':
      return !hasAccessDisplay;
    case 'labels': // hide labels in DetailView, we want to see labels_by_key
      return true;
    default:
      return false;
  }
};
