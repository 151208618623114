import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Button';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

/**
 * Loading component for displaying a loading message
 */

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    placeItems: 'center',
  },
  progress: {
    marginTop: '90px',
    display: 'grid',
    width: '80%',
    height: '100%',
  },
}));

const LoadingPage = props => {
  const { loading } = props;
  const classes = useStyles();
  return loading ? (
    <Backdrop open={loading} className={classes.root}>
      <Box className={classes.progress}>
        <LinearProgress />
        <Typography>Loading</Typography>
      </Box>
    </Backdrop>
  ) : (
    <></>
  );
};

LoadingPage.defaultProps = {
  loading: true,
  style: {},
};

LoadingPage.propTypes = {
  /** is loading if true */
  loading: PropTypes.bool,
  /** style override */
  style: PropTypes.object,
};

export default LoadingPage;
