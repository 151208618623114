import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(3, 'auto'),
    padding: theme.spacing(3),
    maxWidth: '90%',
  },
  body: {
    width: '100%',
    padding: theme.spacing(2, 7),
    textAlign: 'left',
    display: 'flex',
  },
  field: {
    margin: theme.spacing(2, 0),
  },
  header: {
    paddingBottom: theme.spacing(2),
    fontSize: '18px',
  },
  title: {
    fontSize: '14px',
  },
}));

const InspectionMediaExifView = props => {
  const classes = useStyles();
  const { data } = props;

  const renderExifItem = (data, key) => {
    switch (key) {
      case 'GPSInfo':
        return 'GPS Info Here';
      // case 'DateTime':
      //   return apiDateToString(data[key], 'date');
      case 'SubjectArea':
        return data[key].join(', ');
      case 'LensSpecification':
        return data[key].join(', ');
      default:
        return data[key] || '--';
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <strong>EXIF</strong>
      </div>
      {Object.keys(data).map((attr, index) => {
        return (
          <div key={index} className={classes.field}>
            <div className={classes.title}>
              <strong>{attr}</strong>
            </div>
            <div>{renderExifItem(data, attr)}</div>
          </div>
        );
      })}
    </div>
  );
};
InspectionMediaExifView.defaultProps = {
  data: {},
};

InspectionMediaExifView.propTypes = {
  data: PropTypes.object.isRequired,
};

export default InspectionMediaExifView;
