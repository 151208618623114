// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import { all, put, takeLatest } from 'redux-saga/effects';

import {
  PROJECT_REPORT_GETTER_REQUEST,
  PROJECT_REPORT_GETTER_SUCCESS,
  PROJECT_REPORT_GETTER_FAILURE,
} from './projectReportActions';

import { fetchProjectReport } from '../../api/getters/projectReport.js';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';

export function* projectReportFetch(action) {
  const { id, reportType } = action;
  try {
    const { data, error, response } = yield fetchProjectReport(id, reportType);
    if (data) {
      yield put({ type: PROJECT_REPORT_GETTER_SUCCESS, data });
    } else if (error) {
      yield put({ type: PROJECT_REPORT_GETTER_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, PROJECT_REPORT_GETTER_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, PROJECT_REPORT_GETTER_FAILURE);
  }
}

export function* watchProjectReportFetch() {
  yield takeLatest(PROJECT_REPORT_GETTER_REQUEST, projectReportFetch);
}

export default function* projectReportSaga() {
  yield all([watchProjectReportFetch()]);
}
