import variables from '../../config.module.scss';

const MuiContainer = darkMode => ({
  styleOverrides: {
    root: {
      position: 'absolute',
      minHeight: '100vh',
      margin: '0px',
      padding: '0px',
      backgroundColor: darkMode ? variables.baseDark : variables.baseLight,
      color: darkMode ? variables.textPrimaryDark : variables.textPrimaryLight,
    },
  },
});

const MuiPaper = darkMode => ({
  styleOverrides: {
    root: {
      margin: '0px',
      padding: '0px',
      borderRadius: '0px',
      backgroundColor: darkMode ? variables.backgroundDark : variables.backgroundLight,
    },
  },
});

const MuiAppBar = darkMode => ({
  styleOverrides: {
    colorPrimary: {
      backgroundColor: darkMode ? variables.backgroundDark : variables.backgroundLight,
    },
  },
});

const MuiToolbar = darkMode => ({
  styleOverrides: {
    root: {
      backgroundColor: darkMode ? variables.backgroundDark : variables.backgroundLight,
    },
  },
});

const MuiDrawer = {
  styleOverrides: {
    root: {
      minWidth: '16vw',
    },
    paper: {
      minWidth: '16vw',
    },
  },
};

const MuiCard = {
  styleOverrides: {
    root: {
      padding: '24px',
    },
  },
};

const MuiCardContent = {
  styleOverrides: {
    root: {
      margin: '24px 0 0 0',
      padding: '0',
      display: 'flex',
      flexDirection: 'column',
    },
  },
};

const MuiDivider = darkMode => ({
  styleOverrides: {
    root: {
      margin: '16px 0',
      borderColor: darkMode ? variables.darkestDark : variables.grey,
    },
  },
});

const layout = darkMode => ({
  MuiContainer: MuiContainer(darkMode),
  MuiPaper: MuiPaper(darkMode),
  MuiAppBar: MuiAppBar(darkMode),
  MuiToolbar: MuiToolbar(darkMode),
  MuiDrawer,
  MuiCard,
  MuiCardContent,
  MuiDivider: MuiDivider(darkMode),
});

export default layout;
