import React from 'react';

const UnzipOutLined = props => {
  return (
    <svg className="MuiSvgIcon-root" viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        d="M4.99787498,8.99999999 L4.99787498,0.999999992 L19.4999998,0.999999992 L22.9999998,4.50000005 L23,23 L4,23 M18,1 L18,6 L23,6
          M2,13 L2,19 L6,19 L6,13
          M8,13 L13,13 L13,14 L9,18 L9,19 L14,19
          M15,12 L15,20 L15,15 Z
          M17,13 L17,20 L17,14 Z M21,15 C21,12.895 19.105,13 19,13 L17,13 L17,17 L18,17 C21.105,17 21,15.10 21,15 Z"></path>
    </svg>
  );
};

export default UnzipOutLined;
