import React from 'react';

import { FormHelperText } from '@mui/material';
import { useField } from 'react-final-form';

export function ErrorMessage({ showError, meta, formHelperTextProps, helperText }) {
  if (showError) {
    return <FormHelperText {...formHelperTextProps}>{meta.error || meta.submitError}</FormHelperText>;
  } else if (Boolean(helperText)) {
    return <FormHelperText {...formHelperTextProps}>{helperText}</FormHelperText>;
  } else {
    return <></>;
  }
}

const config = {
  subscription: {
    error: true,
    submitError: true,
    dirtySinceLastSubmit: true,
    touched: true,
    modified: true,
  },
};

export function useFieldForErrors(name) {
  return useField(name, config);
}

export function showError({ meta: { submitError, dirtySinceLastSubmit, error, touched, modified } }) {
  return !!(((submitError && !dirtySinceLastSubmit) || error) && (touched || modified));
}
