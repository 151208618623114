import React from 'react';
import PropTypes from 'prop-types';
import StyledLink from '../StyledLink';

import { usePermissions } from '../../../hooks/settingsHooks';

// This logic is strewn across code base currently, this is quick attempt to consolidate.
export const ProjectLink = props => {
  const { id, name } = props;
  return <StyledLink to={`/projects/${id}`} value={name} />;
};
ProjectLink.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export const FormLink = props => {
  const { id, name, version } = props;
  return <StyledLink to={`/forms/${id}`} value={`${name} / ${version}`} />;
};
FormLink.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
};

export const AssetLink = props => {
  const { id, asset_path } = props; // eslint-disable-line camelcase
  const { hasAssetView } = usePermissions();
  if (hasAssetView) {
    return <StyledLink to={`/assets/${id}`} value={asset_path} />; // eslint-disable-line camelcase
  }
  return <>{asset_path}</>; // eslint-disable-line camelcase
};
AssetLink.propTypes = {
  id: PropTypes.string.isRequired,
  asset_path: PropTypes.string.isRequired,
};

export const EventLink = props => {
  const { id, name } = props;
  return <StyledLink to={`/schedule-events/${id}`} value={name} />;
};
EventLink.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export const PlanLink = props => {
  const { id, name } = props;
  return <StyledLink to={`/schedule-plan/${id}`} value={name} />;
};
PlanLink.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export const FindingProfileLink = props => {
  const { id, name, version } = props;
  const { hasConfigurationManagementView } = usePermissions();
  if (hasConfigurationManagementView) {
    return <StyledLink to={`/finding-profiles/${id}`} value={`${name} ${version}`} />;
  }
  return (
    <>
      {name} {version}
    </>
  );
};
FindingProfileLink.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
};

export const LocationLayerProfileLink = props => {
  const { id, name, version } = props;
  const { hasConfigurationManagementView } = usePermissions();
  if (hasConfigurationManagementView) {
    return <StyledLink to={`/location-layer-profiles/${id}`} value={`${name} ${version}`} />;
  }
  return (
    <>
      {name} {version}
    </>
  );
};
LocationLayerProfileLink.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
};

export const ChecklistTemplateLink = props => {
  const { id, name, version } = props;
  const { hasConfigurationManagementView } = usePermissions();
  if (hasConfigurationManagementView) {
    return <StyledLink to={`/checklist-templates/${id}`} value={`${name} ${version}`} />;
  }
  return (
    <>
      {name} {version}
    </>
  );
};
ChecklistTemplateLink.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
};

export const ReportTypeLink = props => {
  const { id, name } = props;
  const { hasConfigurationManagementView } = usePermissions();
  if (hasConfigurationManagementView) {
    return <StyledLink to={`/reports-types/${id}`} value={name} />;
  }
  return <>{name}</>;
};
ReportTypeLink.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export const UserLink = props => {
  const { id, name } = props;
  return <StyledLink to={`/users/${id}`} value={name} />;
};
UserLink.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export const CrewLink = props => {
  const { id, display_name: displayName } = props;
  return <StyledLink to={`/crews/${id}`} value={displayName} />;
};
CrewLink.propTypes = {
  id: PropTypes.string.isRequired,
  display_name: PropTypes.string.isRequired,
};
