import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import arrayMutators from 'final-form-arrays';
import makeStyles from '@mui/styles/makeStyles';
import { createInspectionMediaOverlay } from '../../store/features/inspectionMediaActions';
import CommonForm from '../shared/form/Common';
import InspectorDefectFormToolbar from './InspectorDefectFormToolbar';
import { isEmpty } from '../../utilities/objects';
import SelectedFindingView from './SelectedFindingView';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: 0,
  },
  thumbnailImage: {
    width: theme.spacing(10),
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const displayGeometry = ({ type, coordinates }) => {
  if (!type) {
    return '';
  }
  if (!coordinates.length) {
    return '';
  }
  const coordinatesDisplay = coordinates.map(item => `(${item[0]}, ${item[1]})`);
  return `${type}: ${coordinatesDisplay}`;
};

const InspectorDefectsForm = props => {
  const {
    assetId,
    projectId,
    cancel,
    resetDefect,
    onDelete,
    update,
    media,
    selectedOverlay,
    dirty,
    isDirty,
    onSubmit,
  } = props;
  const { loading, error, formError } = useSelector(state => state.defects.each);
  const { results } = useSelector(state => state.defects.all.dataAll);
  const [submittedValues, setSubmittedValues] = useState({});
  const [defectValue, setDefectValue] = useState({});
  const dispatch = useDispatch();
  const classes = useStyles();

  const getDefectObject = id => {
    if (results) {
      return results.find(item => item.id === Number(id));
    }
    return {};
  };
  const formSettings = {
    fieldOrder: {
      defect: { display: 'Finding', fields: ['defect'] },
    },
    removeField: () => false,
    hideField: () => false,
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {
    defect: {
      type: 'defect-autocomplete',
      fieldProps: {
        label: 'Finding',
        name: 'defect',
        disabled: loading,
        valueInitial: { label: '', value: '' },
        filterQuery: { asset: assetId, project: projectId },
        onChange: event => {
          if (event.type === 'click') {
            if (event.target?.textContent) {
              const id = event.target.textContent.split(' ')[0]; // id will be first on autocomplete label
              const selectedDefectObject = getDefectObject(id);
              setDefectValue(selectedDefectObject);
            }
          }
        },
      },
    },
    geometry: {
      type: 'text',
      fieldProps: {
        disabled: true,
        label: 'Geometry',
        name: 'geometry',
        value: selectedOverlay.geometry ? displayGeometry(selectedOverlay.geometry) : '',
      },
    },
  };

  let initialValues = {};
  if (update) {
    initialValues = { ...selectedOverlay, defect: '' };
  }

  const onSubmitLocal = changedValues => {
    if (onSubmit) {
      onSubmit(changedValues);
    }
    const body = { ...changedValues };
    delete body.defect;
    if (changedValues.defect) {
      body.defect = {};
      body.defect.id = changedValues.defect.value;
    }
    if (selectedOverlay.geometry) {
      body.geometry = selectedOverlay.geometry;
    }

    dispatch(createInspectionMediaOverlay(media.id, body));
    setSubmittedValues(changedValues);
    isDirty(false);
  };

  /**
   * Decorators are used for setting the values of other fields based off of a field.
   * Decorator format can be found here: src/components/shared/form/common.js
   */
  const decorators = [];

  /**
   *  Validations that are per field.
   *  Errors associated with the form are passed back through formError in Redux.
   */
  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        if (values[key] === submittedValues[key]) {
          errors[key] = value;
        } else {
          errors[key] = undefined;
        }
      }
    }
    return errors;
  };

  return (
    <>
      <CommonForm
        embed
        className={classes.root}
        update={update}
        initialValues={initialValues}
        fieldSettings={fieldSettings}
        formSettings={formSettings}
        onSubmit={onSubmitLocal}
        decorators={decorators}
        mutators={{ ...arrayMutators }}
        validate={validate}
        dirty={dirty}
        isDirty={isDirty}
        error={error}
        toolbar={InspectorDefectFormToolbar}
        customToolbarProps={{ cancel, resetDefect, onDelete, selectedOverlay }}
      />
      {!isEmpty(defectValue) && <SelectedFindingView className={classes.root} data={defectValue} />}
    </>
  );
};

InspectorDefectsForm.defaultTypes = {
  selectedOverlay: {},
};

InspectorDefectsForm.propTypes = {
  assetId: PropTypes.number.isRequired,
  projectId: PropTypes.number.isRequired,
  cancel: PropTypes.func,
  onDelete: PropTypes.func,
  update: PropTypes.bool,
  // asset: PropTypes.object.isRequired,
  media: PropTypes.object.isRequired,
  // defectProfile: PropTypes.object.isRequired,
  // locationLayerProfile: PropTypes.object.isRequired,
  selectedOverlay: PropTypes.object,
  resetDefect: PropTypes.func,
  dirty: PropTypes.bool,
  isDirty: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default InspectorDefectsForm;
