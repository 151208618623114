export const SCHEDULEWORK_REQUEST = 'SCHEDULEWORK_REQUEST';
export const SCHEDULEWORK_SUCCESS = 'SCHEDULEWORK_SUCCESS';
export const SCHEDULEWORK_FAILURE = 'SCHEDULEWORK_FAILURE';
export const SCHEDULEWORK_CREATE_REQUEST = 'SCHEDULEWORK_CREATE_REQUEST';
export const SCHEDULEWORK_CREATE_SUCCESS = 'SCHEDULEWORK_CREATE_SUCCESS';
export const SCHEDULEWORK_CREATE_FAILURE = 'SCHEDULEWORK_CREATE_FAILURE';
export const SCHEDULEWORK_UPDATE_REQUEST = 'SCHEDULEWORK_UPDATE_REQUEST';
export const SCHEDULEWORK_UPDATE_SUCCESS = 'SCHEDULEWORK_UPDATE_SUCCESS';
export const SCHEDULEWORK_UPDATE_FAILURE = 'SCHEDULEWORK_UPDATE_FAILURE';
export const SCHEDULEWORK_DELETE_REQUEST = 'SCHEDULEWORK_DELETE_REQUEST';
export const SCHEDULEWORK_DELETE_SUCCESS = 'SCHEDULEWORK_DELETE_SUCCESS';
export const SCHEDULEWORK_DELETE_FAILURE = 'SCHEDULEWORK_DELETE_FAILURE';
export const SCHEDULEWORK_ALL_REQUEST = 'SCHEDULEWORK_ALL_REQUEST';
export const SCHEDULEWORK_ALL_SUCCESS = 'SCHEDULEWORK_ALL_SUCCESS';
export const SCHEDULEWORK_ALL_FAILURE = 'SCHEDULEWORK_ALL_FAILURE';

export const getScheduleWork = id => {
  return {
    type: SCHEDULEWORK_REQUEST,
    id,
  };
};

export const createScheduleWork = (planId, body) => {
  return {
    type: SCHEDULEWORK_CREATE_REQUEST,
    planId,
    body,
  };
};

export const updateScheduleWork = (id, planId, body) => {
  return {
    type: SCHEDULEWORK_UPDATE_REQUEST,
    id,
    planId,
    body,
  };
};

export const deleteScheduleWork = id => {
  return {
    type: SCHEDULEWORK_DELETE_REQUEST,
    id,
  };
};

export const getAllScheduleWork = query => {
  return {
    type: SCHEDULEWORK_ALL_REQUEST,
    query,
  };
};
