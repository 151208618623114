import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { getAllChecklistTemplates } from '../../store/features/checklistTemplatesActions';
import ChecklistTemplatesTable from './ChecklistTemplatesTable';
import { useTableViewSettings } from '../../hooks/settingsHooks';
import { getParams } from '../../utilities/route';
import { tableViews } from '../../utilities/tables';
import { toQueryString } from '../../utilities/strings';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const ChecklistTemplatesTablePage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { checklistTemplatesTableViewKey } = tableViews;

  const {
    tableQuery: storedChecklistTemplatesQuery,
    page,
    views,
  } = useTableViewSettings(checklistTemplatesTableViewKey);

  // check for url
  const queryParams = getParams(location, storedChecklistTemplatesQuery);

  useEffect(() => {
    dispatch(getAllChecklistTemplates(queryParams));
  }, []); // eslint-disable-line

  const tableChangeHandler = query => {
    // no need to fetch data if the query is the same.
    const newSearch = toQueryString(query);
    if (location.search === newSearch) return;
    const newLocation = location;
    newLocation.search = newSearch;
    history.push(newLocation);
    dispatch(getAllChecklistTemplates(query));
  };

  return (
    <div className={classes.root}>
      <ChecklistTemplatesTable
        title="Checklist Templates"
        queryParamObj={queryParams}
        tableChangeHandler={tableChangeHandler}
        views={views}
        page={page}
      />
    </div>
  );
};

export default ChecklistTemplatesTablePage;
