// lib
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// store
import { getProjectSelectedFindings } from '../../store/getters/projectSelectedFindingsActions';

// util
import { tableViews } from '../../utilities/tables';
import { useTableViews } from '../../hooks/settingsHooks';
import { scopeModes } from '../projects/projectsShared';

// components
import { _DefectsTable } from '../defects/DefectsTable';

/**
 * @param {*} props
 *
 * @returns {React.Component}
 */
const ProjectSelectedFindingsTable = props => {
  const { project } = props;

  const dispatch = useDispatch();

  // state / store
  const views = useTableViews(tableViews.projectSelectedFindingsTableViewKey);
  const {
    loading,
    data: { results: data, count },
  } = useSelector(state => state.projectSelectedFindings);
  // TODO - make available in URL query params
  //  see https://github.com/huvrdata/huvr/issues/6406
  const [queryParamObj, setQueryParamObj] = useState({});

  // handlers
  const onChangeProjectId = () => {
    setQueryParamObj({ project_selected_findings: project.id });
  };

  const onChangeQueryParamObj = () => {
    dispatch(getProjectSelectedFindings(queryParamObj));
  };

  const onChangeDefectTableQuery = query => {
    setQueryParamObj({
      ...queryParamObj,
      ...query,
      project_selected_findings: project.id, // (cannot selected different project...)
    });
  };

  // effects
  useEffect(onChangeProjectId, [project.id]);
  useEffect(onChangeQueryParamObj, [dispatch, queryParamObj]);

  return (
    <_DefectsTable
      scopeMode={scopeModes.DEFAULT}
      count={count}
      data={data}
      embedded={true}
      filename={`Project {project.id} Selected Findings`}
      isProject={true}
      isReadOnly={true}
      loading={loading}
      tableChangeHandler={onChangeDefectTableQuery}
      title="Selected Findings"
      disableNewProject
      views={views}
      // -- queries not required yet --
      queryParamObj={queryParamObj}
      // queryStateFilters={}
      // queryAssetParentInFilters={}
      // queryAssetTypeInFilters={}
      // queryCreatedStartFilter={}
      // queryCreatedEndFilter={}
    />
  );
};

ProjectSelectedFindingsTable.propTypes = {
  project: PropTypes.object,
};

export default ProjectSelectedFindingsTable;
