import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardActions, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { TextField } from 'mui-rff';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import PrimaryButton from '../shared/buttons/PrimaryButton';
import Error from '../shared/displays/Error';
import { resetLookup, requestLookup } from '../../store/authActions';
import { locationShape } from '../../utilities/propTypes';

const useStyles = makeStyles(theme => ({
  card: {
    margin: theme.spacing(0, 'auto'),
    padding: theme.spacing(0, 3),
    width: '352px',
  },
  cardContent: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
}));

const Lookup = props => {
  const { email: propsEmail, route } = props;
  const classes = useStyles();
  const { isLookingUp, error, formError } = useSelector(state => state.auth.lookup);
  const [submittedEmail, setSubmittedEmail] = useState(propsEmail || '');
  const dispatch = useDispatch();

  useEffect(() => {
    // We could get in a state where request has been dispatched, but somehow success or error wasn't
    // isLookingUp will be true and there's no way to go to the next page.
    // Reset lookup so isLookingUp is false again.  Only do this once when the page is loaded
    dispatch(resetLookup());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = values => {
    setSubmittedEmail(values.email);
    dispatch(requestLookup(values.email, route));
  };

  const validate = values => {
    const errors = {};
    if (formError) {
      for (const [key, value] of Object.entries(formError)) {
        // this only works because there is only one field in the form.  key will only be email.
        // only show the error if the current value is what was submitted
        if (values[key] === submittedEmail) {
          errors[key] = value[0];
        } else {
          errors[key] = undefined;
        }
      }
    }
    return errors;
  };

  const renderError = () => (
    <>
      <Error error={error} />
      <Typography>Please contact support@huvrdata.com if you believe this to be an error.</Typography>
    </>
  );

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <TextField variant="outlined" margin="normal" id="email" label="Email Address" name="email" />
                {error && renderError()}
                <CardActions>
                  <div style={{ flexGrow: '1' }} />
                  <PrimaryButton label="Next" type="submit" disabled={isLookingUp} />
                </CardActions>
              </form>
            );
          }}
        />
      </CardContent>
    </Card>
  );
};

Lookup.defaultProps = {
  email: '',
  route: { pathName: '' },
};

Lookup.propTypes = {
  email: PropTypes.string,
  route: locationShape,
};

export default Lookup;
