import { requestFactory, responseHandlerForm } from '../base';

const get = requestFactory('get');
// const post = requestFactory('post', { handler: responseHandlerForm });
const post = requestFactory('post', { headers: {}, handler: responseHandlerForm });
const patch = requestFactory('patch', { handler: responseHandlerForm });
const del = requestFactory('del');

export const fetchBulkImportsList = async query => get('/api/imports/', query);
export const fetchBulkImports = async id => get(`/api/imports/${id}/`);
export const createBulkImports = async body => post('/api/imports/', body, { formBody: true });
export const updateBulkImports = async (id, body) => patch(`/api/imports/${id}/`, body);
export const deleteBulkImports = async id => del(`/api/imports/${id}/`);
