import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import ChecklistTemplateSectionsTable from './ChecklistTemplateSectionsTable';
import ChecklistTemplateLinesTable from './ChecklistTemplateLinesTable';

const flattenChecklist = sections => {
  const flatLines = {};
  const flatSections = [];

  let count = 0;
  let lineCount = 0;
  for (const sec of sections) {
    count++;
    const secKey = sec['key'];
    flatSections.push({
      order: count,
      key: secKey,
      label: sec['label'],
      description: sec['description'],
      lineCount: sec['lines'].length,
    });
    flatLines[secKey] = [];
    lineCount = 0;
    for (const line of sec['lines']) {
      lineCount++;
      flatLines[secKey].push({
        order: lineCount,
        key: line['key'],
        label: line['label'],
        description: line['description'],
        lineType: line['line_type'],
      });
    }
  }
  return { flatSections, flatLines };
};

const ChecklistTemplateDetailDisplay = props => {
  const { data, loading } = props;
  const [currentSection, setCurrentSection] = useState(null);

  const sections = useMemo(() => (data['sections'] ? data['sections'] : []), [data]);
  const { flatSections, flatLines } = useMemo(() => flattenChecklist(sections), [sections]);
  if (loading) {
    return <></>;
  }

  const getTitle = () => {
    if (currentSection) {
      const selected = flatSections.find(item => item['key'] === currentSection);
      return selected['label'];
    }
    return 'Select a Section';
  };

  const lineTitle = getTitle();

  const lines = currentSection ? flatLines[currentSection] : [];

  // className={styleProjectDetail}
  return (
    <div>
      <ChecklistTemplateSectionsTable sections={flatSections} setCurrentSection={setCurrentSection} />
      <ChecklistTemplateLinesTable title={lineTitle} lines={lines} />
    </div>
  );
};

ChecklistTemplateDetailDisplay.defaultProps = {
  title: 'Lines',
  data: {},
  embedded: true,
  loading: false,
};

ChecklistTemplateDetailDisplay.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
  embedded: PropTypes.bool,
  loading: PropTypes.bool,
};

export default ChecklistTemplateDetailDisplay;
