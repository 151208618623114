import { requestFactory, responseHandlerForm } from '../base';

const get = requestFactory('get');
const post = requestFactory('post', { handler: responseHandlerForm });
const patch = requestFactory('patch', { handler: responseHandlerForm });
const del = requestFactory('del');
const bulkDel = requestFactory('bulkDel');

export const fetchMeasurementsList = async query => get('/api/measurements/', query);
export const fetchMeasurements = async id => get(`/api/measurements/${id}/`);
export const createMeasurements = async body => post('/api/measurements/', body);
export const updateMeasurements = async (id, body) => patch(`/api/measurements/${id}/`, body);
export const deleteMeasurements = async id => del(`/api/measurements/${id}/`);
export const deleteMeasurementsBulk = async ids => bulkDel(`/api/measurements/bulk-delete/`, ids);

export const createImportMeasurements = async body => post('/api/measurements/import/', body);
