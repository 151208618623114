// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import { all, put, takeLatest } from 'redux-saga/effects';

import { AUDIT_REQUEST, AUDIT_SUCCESS, AUDIT_FAILURE } from './auditActions';

import { fetchAudit } from '../../api/getters/audit.js';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';

export function* auditFetch(action) {
  const { query } = action;
  try {
    const { data, error, response } = yield fetchAudit(query);
    if (data) {
      yield put({ type: AUDIT_SUCCESS, data });
    } else if (error) {
      yield put({ type: AUDIT_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, AUDIT_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, AUDIT_FAILURE);
  }
}

export function* watchAuditFetch() {
  yield takeLatest(AUDIT_REQUEST, auditFetch);
}

export default function* auditSaga() {
  yield all([watchAuditFetch()]);
}
