// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
export const PROJECT_SUMMARY_GETTER_REQUEST = 'PROJECT_SUMMARY_GETTER_REQUEST';
export const PROJECT_SUMMARY_GETTER_SUCCESS = 'PROJECT_SUMMARY_GETTER_SUCCESS';
export const PROJECT_SUMMARY_GETTER_FAILURE = 'PROJECT_SUMMARY_GETTER_FAILURE';

export const getProjectSummary = id => {
  return {
    type: PROJECT_SUMMARY_GETTER_REQUEST,
    id,
  };
};
