// lib
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Paper, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// components
import InspectorMap from '../visualInspector/InspectorMap';
import InspectionMediaView from '../inspectionMedia/InspectionMediaView';

const useStyles = makeStyles(theme => ({
  projectMap: {
    margin: theme.spacing(1, 4, 3),
    padding: theme.spacing(1),
  },
  infoBox: {
    // height: '390px',
    // overflow: 'scroll',
  },
  media: {
    width: '100%',
    objectFit: 'contain',
    height: '300px',
  },
  attached: {
    textAlign: 'center',
    fontSize: '16px',
  },
  attached_key: {
    margin: theme.spacing(2),
  },
}));

const ChecklistMap = props => {
  const { project, checklist } = props;
  const classes = useStyles();

  const { data } = useSelector(state => {
    const data = state.apiV2.inspectionMediaList.data.results;
    const count = state.apiV2.inspectionMediaList.data.count;
    return {
      data,
      count,
    };
  });

  // const [selectedMedia, setSelectedMedia] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const mediaWithGPS = useMemo(() => {
    return data?.reduce((mediaWithGPS, m) => {
      if (m.geo_point?.coordinates) {
        mediaWithGPS.push({
          id: m.id,
          geo_point: m.geo_point,
          exif: m.file_meta?.exif,
          isMedia: true,
          attached_to: m.attached_to,
          thumbnail: m.thumbnail,
        });
      }
      return mediaWithGPS;
    }, []);
  }, [data]);

  const templateLinesWithLocation = useMemo(() => {
    const lines = [];

    checklist.template.sections.forEach(section => {
      section.lines.forEach(line => {
        if (line.line_type === 'collect-position') {
          // this makes lots of copies of lines[] but it's fine for now
          lines.push({ section: section, line: line });
        }
      });
    });
    return lines;
  }, [checklist]);

  const locationLines = useMemo(() => {
    return templateLinesWithLocation.reduce((locationLines, line) => {
      try {
        const value = checklist.results[line.section.key][line.line.key]['value']['geometry'];
        console.log('Checklist Line Value:', value);
        if (value?.coordinates) {
          locationLines.push({
            id: line.line.key,
            geo_point: value,
            exif: { GPSInfo: {} },
            isMedia: false,
          });
        }
      } catch (e) {
        console.error(e);
      }
      return locationLines;
    }, []);
  }, [checklist, templateLinesWithLocation]);

  const itemsWithGPS = [...mediaWithGPS, ...locationLines];

  const selectItem = id => {
    const item = itemsWithGPS.find(i => i.id === id);
    setSelectedItem(item);
  };

  const renderAttachedTo = str => {
    const [checklistId, secKey, lineKey] = str?.split('::'); // eslint-disable-line
    return (
      <>
        <div>
          <span className={classes.attached_key}>
            <b>Section:</b>
            {` ${secKey}`}
          </span>
          <span className={classes.attached_key}>
            <b>Line Item:</b>
            {` ${lineKey}`}
          </span>
        </div>
      </>
    );
  };

  return (
    <Grid container direction="column">
      <Grid item xs={9}>
        <Paper className={classes.projectMap}>
          <Grid container>
            <InspectorMap
              mediaWithGPS={itemsWithGPS}
              onMediaCircleClick={selectItem}
              activeMedia={selectedItem}
              project={project}
              mapContainerStyle={{
                height: '60vh',
                width: '95%',
                margin: 'auto',
                borderRadius: '3px',
              }}
            />
          </Grid>
        </Paper>
      </Grid>
      <Grid container item xs={3} direction="column" spacing={2}>
        {selectedItem && (
          <>
            <Grid item className={classes.imageBox}>
              <img src={selectedItem?.thumbnail} className={classes.media} />
            </Grid>
            {selectedItem.attached_to.length ? (
              <Grid item className={classes.attached}>
                {selectedItem.attached_to.map((item, i) => {
                  return <>{renderAttachedTo(item)}</>;
                })}
              </Grid>
            ) : (
              <div className={classes.attached}>Not attached to a line item.</div>
            )}
            {selectedItem?.isMedia && (
              <Grid item className={classes.infoBox}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">View Media Details</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {selectedItem?.isMedia ? <InspectionMediaView data={selectedItem} /> : null}
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

ChecklistMap.defaultProps = {
  project: {},
  checklist: {},
};

ChecklistMap.propTypes = {
  project: PropTypes.object,
  checklist: PropTypes.object,
};

export default ChecklistMap;
