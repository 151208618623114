import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText } from '@mui/material';
import { Field } from 'react-final-form';
import makeStyles from '@mui/styles/makeStyles';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import { useFeatureFlags } from '../../../hooks/settingsHooks';
import { isEmpty } from '../../../utilities/objects';
import { apiDateToString, dateToApiDateTime } from '../../../utilities/strings';
import Markdown from '../../shared/Markdown';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'start',
  },
  setLocationButton: {
    margin: theme.spacing(1, 7),
  },
  divider: {
    margin: '0',
  },
  positionData: {
    margin: theme.spacing(1, 2),
  },
  status: {
    fontSize: theme.spacing(2.5),
    margin: theme.spacing(2, 1, 0, 1),
    color: 'red',
  },
}));

const CollectPositionLineItem = props => {
  const { name, label, helperText } = props;
  const { hasChecklistCollectPositionWeb } = useFeatureFlags();
  const classes = useStyles();
  const [status, setStatus] = useState(null);

  const positionData = {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [],
    },
    properties: {
      timestamp: '',
    },
  };
  const displayGeo = geoData => {
    if (isEmpty(geoData)) {
      return (
        <div className={classes.positionData}>
          <p>Position:</p>
          <p>Collected on:</p>
        </div>
      );
    }
    return (
      <div className={classes.positionData}>
        <p>
          Position: {geoData.geometry.coordinates[1].toFixed(6)}, {geoData.geometry.coordinates[0].toFixed(6)}
        </p>
        <p>Collected on: {apiDateToString(geoData.properties.timestamp)}</p>
      </div>
    );
  };

  const handleCollect = input => {
    const { onChange } = input;
    navigator.geolocation.getCurrentPosition(
      position => {
        positionData.geometry.coordinates.push(position.coords.longitude);
        positionData.geometry.coordinates.push(position.coords.latitude);
        positionData.properties.timestamp = dateToApiDateTime(position.timestamp);
        setStatus(null);
        onChange(positionData);
      },
      error => {
        console.error('Error getting location:', error);
        setStatus('Unable to retrieve your location. Please check your location settings.');
      }
    );
  };

  return (
    <>
      <FormControl fullWidth={true}>
        <Markdown>{label}</Markdown>
        <Field
          name={name}
          label={label}
          render={({ input }) => {
            return (
              <>
                {status ? <span className={classes.status}>{status}</span> : <></>}
                <div className={classes.root}>
                  {displayGeo(input.value)}
                  {hasChecklistCollectPositionWeb && (
                    <div className={classes.setLocationButton}>
                      <PrimaryButton label="Set Location" onClick={() => handleCollect(input)} />
                    </div>
                  )}
                </div>
              </>
            );
          }}
        />
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </>
  );
};

CollectPositionLineItem.defaultProps = {
  helperText: '',
};

CollectPositionLineItem.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
};

export default CollectPositionLineItem;
