import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Form } from 'react-final-form';
import PrimaryButton from '../../components/shared/buttons/PrimaryButton';
import SecondaryButton from '../../components/shared/buttons/SecondaryButton';
import { closeDialog } from '../../store/dialogActions';
import { updateDefects } from '../../store/features/defectsActions';
import FieldEditString from '../shared/form/FieldEditString';
import FieldEditSelect from '../shared/form/FieldEditSelect';
import FindingStateDisplay, { findingStates } from './FindingStateDisplay';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    height: '100%',
    textAlign: 'left',
  },
  form: {
    margin: theme.spacing(1),
    height: '90%',
  },
  buttonGroup: {
    marginTop: theme.spacing(2),
  },
}));

const FindingStateForm = props => {
  const { id, state } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { formError } = useSelector(state => state.projects.each);

  const handleCloseDialog = () => {
    dispatch(closeDialog());
  };

  const handleDialogSubmit = values => {
    const next = `/findings/${id}`;
    dispatch(updateDefects(id, values, next));
  };

  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        errors[key] = value;
      }
    }
    return errors;
  };

  const options = findingStates.map(f => ({ label: <FindingStateDisplay state={f} />, value: f }));

  return (
    <Form
      onSubmit={handleDialogSubmit}
      validate={validate}
      initialValues={{ state }}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Grid container direction="column" justifyContent="space-between" className={classes.formControl}>
              <Grid item>
                <Grid container spacing={2} direction="column">
                  <Grid item>
                    <FieldEditSelect
                      label="State"
                      name="state"
                      options={options}
                      labelwidth={40}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <FieldEditString
                      label="Notes"
                      multiline
                      name="state_note"
                      helperText="Reason for state change (optional)."
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid className={classes.buttonGroup} item>
                <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
                  <Grid item xs={2}>
                    <SecondaryButton label="Cancel" onClick={handleCloseDialog}></SecondaryButton>
                  </Grid>
                  <Grid item xs={2}>
                    <PrimaryButton type="submit" label="Submit">
                      Submit
                    </PrimaryButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};

FindingStateForm.propTypes = {
  id: PropTypes.number.isRequired,
  state: PropTypes.string.isRequired,
};

export default FindingStateForm;
