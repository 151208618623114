import React from 'react';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const useStyles = makeStyles(theme => ({
  dialogBox: {
    width: theme.spacing(100),
    position: 'absolute',
    top: theme.spacing(5),
  },
  dialogBoxFullScreen: {},
  title: {
    fontSize: '30px',
    textAlign: 'left',
  },
}));

/**
 * To display Dialog,
 *  call `openDialog` / `closeDialog` (imported from "store/dialogActions")
 *
 *  ex:
 *    openDialog(
 *      "My Title",
 *      <MyComponent />,
 *      { fullscreen: true },
 *    )
 */
export const FormDialog = () => {
  const { open, title, body, options } = useSelector(state => state.dialog);
  const classes = useStyles();

  return (
    <>
      <Dialog
        open={open}
        fullScreen={options?.fullScreen}
        aria-labelledby="form-dialog-title"
        classes={{
          paper: options?.fullScreen ? classes.dialogBoxFullScreen : classes.dialogBox,
        }}>
        <DialogTitle id="form-dialog-title">
          <p className={classes.title}>{title}</p>
        </DialogTitle>
        <DialogContent>{body}</DialogContent>
      </Dialog>
    </>
  );
};

export default FormDialog;
