import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import ProjectsTable from './ProjectsTable';
import { getAllProjects } from '../../store/features/projectsActions';
import { useTableViewSettings } from '../../hooks/settingsHooks';
import { getParams } from '../../utilities/route';
import { tableViews } from '../../utilities/tables';
import { toQueryString } from '../../utilities/strings';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const ProjectsTablePage = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const { projectsTableViewKey } = tableViews;
  const { tableQuery: projectsQuery, page, views } = useTableViewSettings(projectsTableViewKey);

  // check for url
  const queryParams = getParams(location, projectsQuery);

  useEffect(() => {
    dispatch(getAllProjects(queryParams));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const tableChangeHandler = query => {
    // no need to fetch data if the query is the same.
    const newSearch = toQueryString(query);
    if (location.search === newSearch) return;
    const newLocation = location;
    newLocation.search = newSearch;
    history.push(newLocation);
    dispatch(getAllProjects(query));
  };

  return (
    <div className={classes.root}>
      <ProjectsTable
        title="Projects"
        queryParamObj={queryParams}
        tableChangeHandler={tableChangeHandler}
        page={page}
        views={views}
      />
    </div>
  );
};

export default ProjectsTablePage;
