import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ButtonGroup } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '../shared/table/Table';
import { setTableSize, setTableView } from '../../store/settings/tableActions';
import { deleteMeasurements } from '../../store/features/measurementsActions';
import { openDialog } from '../../store/dialogActions';
import { locationWithBack } from '../../utilities/route';
import StyledLink from '../shared/StyledLink';
import DeleteDialog from '../shared/Dialog/DeleteDialog';
import { ProjectLink } from '../shared/links/InternalLinks';
import ViewDisplayChips from '../shared/form/ViewDisplayChips';
import { setColumns } from '../shared/table/columns';
import TableImportToolbar from '../shared/table/TableImportToolbar';
import { apiDateToString } from '../../utilities/strings';
import { getCustomCSVData, tableViews } from '../../utilities/tables';
import { useFeatureFlags } from '../../hooks/settingsHooks';
import MeasurementsTableToolbar from './MeasurementsTableToolbarSelect';
import { measurementStateOptions } from '../../api/features/constants';
import { getLabel } from '../../utilities/objects';
import ButtonIcon from '../shared/buttons/ButtonIcon';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const MeasurementsTable = props => {
  const {
    title,
    queryParamObj,
    tableChangeHandler,
    embedded,
    styleProjectDetail,
    isReadOnly,
    filename,
    page,
    views,
    hideCML,
    hideProject,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { loading, data, count } = useSelector(state => ({
    loading: state.measurements.all.loading,
    data: state.measurements.all.dataAll.results,
    count: state.measurements.all.dataAll.count,
  }));
  const { measurementsTableViewKey } = tableViews;
  const { hasAccessDisplay, hasMeasurementImport } = useFeatureFlags();
  const queryFilters = queryParamObj;
  // Use optional chaining and fallbacks to prevent the following error when filtering (#10227):
  // TypeError: Cannot read properties of undefined (reading 'call')
  const queryCmlFilter = [].concat(queryFilters?.cml || []);
  const queryOffsetFilter = [].concat(queryFilters?.measurement_offset || []);
  const queryProjectFilter = [].concat(queryFilters?.project || []);

  /** @type {Column[]} */
  const columns = [
    {
      // This needs to be first and excluded so customBodyRender can access the id to create the link.
      name: 'id',
      label: 'ID',
      options: {
        display: 'excluded',
        filter: false,
        sort: false,
      },
    },
    {
      name: 'displayId',
      label: 'ID',
      options: {
        filter: false,
        download: false,
        sort: true,
        sortField: 'id',
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.rowData[columns.findIndexByName['id']];
          return <StyledLink to={`/measurements/${id}`} value={id} />;
        },
      },
    },
    {
      name: 'cml',
      label: 'CML',
      options: {
        display: !hideCML,
        filter: !hideCML,
        sort: true,
        sortField: 'cml__name',
        filterType: 'textField',
        filterList: queryCmlFilter,
        downloadBody: value => getCustomCSVData('simple', value),
        customBodyRender: value => {
          if (!value) return <></>;
          return <StyledLink to={`/cmls/${value.id}`} value={value.name} />;
        },
      },
    },
    {
      name: 'offset',
      label: 'Offset',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'measurement_offset', // column for filtering purposes only
      label: 'Offset',
      options: {
        display: 'excluded',
        download: false,
        filter: true,
        filterType: 'textField',
        filterList: queryOffsetFilter,
      },
    },
    {
      name: 'value',
      label: 'Value',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'calculations.previous.value',
      label: 'Previous',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'units',
      label: 'Units',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'calculations.CRLT',
      label: 'LTCR',
      options: {
        filter: false,
        sort: false,
        sortField: 'calculations__CRLT',
        customBodyRender: value => <span>{value?.toFixed(2)}</span>,
      },
    },
    {
      name: 'calculations.CRST',
      label: 'STCR',
      options: {
        filter: false,
        sortField: 'calculations__CRST',
        sort: true,
        customBodyRender: value => <span>{value?.toFixed(2)}</span>,
      },
    },
    {
      name: 'calculations.RLOP',
      label: 'Remaining Life',
      options: {
        filter: false,
        sort: true,
        sortField: 'calculations__RLOP',
        customBodyRender: value => <span>{value?.toFixed(2)}</span>,
      },
    },
    {
      name: 'calculations.EOL',
      label: 'EOL',
      options: {
        filter: false,
        sort: true,
        sortField: 'calculations__EOL',
      },
    },
    {
      name: 'calculations.timedelta',
      label: 'Time Difference',
      options: {
        filter: false,
        sort: true,
        sortField: 'calculations__timedelta',
      },
    },
    {
      name: 'state',
      label: 'State',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => getLabel(value, measurementStateOptions),
        customFilterListOptions: {
          render: value => getLabel(value, measurementStateOptions),
        },
        filterOptions: {
          names: measurementStateOptions.map(r => r.value),
          renderValue: value => getLabel(value, measurementStateOptions),
        },
      },
    },
    {
      name: 'readings',
      label: 'Readings',
      options: {
        filter: false,
        sort: false,
        downloadBody: values => getCustomCSVData('simpleArray', values),
        customBodyRender: values => <ViewDisplayChips value={values} />,
      },
    },
    {
      name: 'cml.asset.asset_path',
      label: 'Asset Path',
      options: {
        filter: false,
        sort: true,
        sortField: 'cml__asset__asset_path_cache__path',
      },
    },
    {
      name: 'cml.component_display',
      label: 'Component',
      options: {
        filter: false,
        sort: true,
        sortField: 'cml__component_display',
      },
    },
    {
      name: 'cml.access_display',
      label: 'Access',
      options: {
        filter: false,
        sort: true,
        display: hasAccessDisplay ? true : 'excluded',
        sortField: 'cml__access_display',
      },
    },
    {
      name: 'comment',
      label: 'Comment',
      options: {
        filter: true,
        filterType: 'textField',
        sort: true,
      },
    },
    {
      name: 'collected_on',
      label: 'Collected On',
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => apiDateToString(value, 'date'),
      },
    },
    {
      name: 'collected_by',
      label: 'Collected By',
      options: {
        filter: true,
        filterType: 'dropdown',
        sort: true,
        sortField: 'collected_by__name',
        customBodyRender: value => value?.name,
      },
    },
    {
      name: 'project',
      label: 'Project',
      options: {
        display: !hideProject,
        filter: !hideProject,
        filterType: 'textField',
        filterList: queryProjectFilter,
        sort: true,
        sortField: 'project__name',
        customBodyRender: (value, info) => {
          if (!value) return <>--</>;
          return <ProjectLink id={value.id} name={value.name} />;
        },
        downloadBody: value => getCustomCSVData('simple', value, 'id'),
      },
    },
    {
      name: 'project.status', // needed for table select toolbar logic
      label: 'Project Status',
      options: {
        display: 'excluded',
        download: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        print: false,
        viewColumns: false,
        customBodyRender: (_, info) => {
          const id = info.rowData[columns.findIndexByName['id']];
          const routeTo = location.pathname; // the measurements table is always embedded
          const projectStatus = info.rowData[columns.findIndexByName['project.status']];
          const measurementIsReadOnly = projectStatus === 'PUBLISHED' || isReadOnly;

          return (
            <ButtonGroup>
              <ButtonIcon
                icon={EditIcon}
                disabled={measurementIsReadOnly}
                fontSize="small"
                onClick={() => {
                  history.push(locationWithBack({ pathname: `/measurements/${id}/edit` }, location));
                }}
              />
              <ButtonIcon
                icon={DeleteIcon}
                fontSize="small"
                disabled={measurementIsReadOnly}
                onClick={() => {
                  dispatch(
                    openDialog(
                      'Delete Measurements?',
                      <DeleteDialog id={id} deleteAction={deleteMeasurements} routeTo={routeTo} />
                    )
                  );
                }}
              />
            </ButtonGroup>
          );
        },
      },
    },
  ];

  // handle columns display
  setColumns(columns, views);

  /** @type {TableOptions} */
  const options = {
    // if the column view changes, update redux with either 'add' or 'remove'
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setTableView(changedColumn, action, page, measurementsTableViewKey));
    },
    onChangeRowsPerPage: numberOfRows => {
      dispatch(setTableSize(numberOfRows, page, measurementsTableViewKey));
    },
    enableNestedDataAccess: '.',
    searchOpen: !embedded,
    customToolbar: () => {
      if (hasMeasurementImport && !isReadOnly) {
        return (
          <TableImportToolbar
            importAction={'IMPORT_MEASUREMENTS'}
            addRoute={'/measurements/new'}
            featureFlag={hasMeasurementImport}
            onlyImport={true}
          />
        );
      }
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      return (
        <MeasurementsTableToolbar
          displayData={displayData}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          columnIndicesByName={columns.findIndexByName}
        />
      );
    },
  };

  return (
    <div className={styleProjectDetail}>
      <Table
        title={title}
        serverSide
        columns={columns}
        data={data}
        count={count}
        options={options}
        loading={loading}
        embedded={embedded}
        filename={filename}
        queryParamObj={queryParamObj}
        tableChangeHandler={tableChangeHandler}
        page={page}
        views={views}
      />
    </div>
  );
};

MeasurementsTable.defaultProps = {
  queryParamObj: {},
  embedded: false,
  styleProjectDetail: '',
  isReadOnly: true,
  filename: null,
  page: '',
  views: {},
  hideCML: false,
  hideProject: false,
};

MeasurementsTable.propTypes = {
  title: PropTypes.string.isRequired,
  embedded: PropTypes.bool,
  styleProjectDetail: PropTypes.string,
  isReadOnly: PropTypes.bool,
  projectId: PropTypes.number,
  filename: PropTypes.string,
  queryParamObj: PropTypes.object,
  tableChangeHandler: PropTypes.func.isRequired,
  page: PropTypes.string,
  views: PropTypes.object,
  hideCML: PropTypes.bool, // hide CML column and filter
  hideProject: PropTypes.bool, // hide Project column and filter
};

export default MeasurementsTable;
