import React from 'react';
import PropTypes from 'prop-types';
import StopIcon from '@mui/icons-material/Stop';
import variables from '../../config.module.scss';

export const projectStatuses = ['INITIALIZED', 'ASSIGNED', 'IN_PROGRESS', 'READY_FOR_REVIEW', 'PUBLISHED'];

export const getStatus = projectStatus => {
  const statusOptions = {
    INITIALIZED: {
      label: 'Initialized',
      color: variables.textSecondaryLight,
    },
    ASSIGNED: {
      label: 'Assigned',
      color: variables.lightBlue,
    },
    IN_PROGRESS: {
      label: 'In Progress',
      color: variables.lightYellow,
    },
    READY_FOR_REVIEW: {
      label: 'Ready for Review',
      color: variables.orange,
    },
    PUBLISHED: {
      label: 'Published',
      color: variables.green,
    },
  };

  if (!statusOptions[projectStatus]) {
    return {
      color: variables.black,
      label: 'UNKNOWN LABEL',
    };
  }
  return statusOptions[projectStatus];
};

const ProjectStatusDisplay = props => {
  const display = getStatus(props.status);

  return (
    <span>
      <StopIcon style={{ color: display.color, verticalAlign: 'middle' }} /> {display.label}
    </span>
  );
};

ProjectStatusDisplay.defaultProps = {
  status: '',
};

ProjectStatusDisplay.propTypes = {
  status: PropTypes.string,
};

export default ProjectStatusDisplay;
