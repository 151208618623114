import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { DateTimePicker } from 'mui-rff';
import { requiredFn } from '../../../utilities/validators';

const FieldEditDateTime = props => {
  const { label, name, value, placeholder, loading, disabled, required, helperText, format } = props;

  const inputRef = useRef();
  const inputIsInvalid = inputRef.current?.ariaInvalid === 'true';

  const moreProps = {};
  if (value) moreProps.value = value;

  let modifiedLabel = label;
  if (required) {
    modifiedLabel = (
      <span>
        {label}
        <sup>*</sup>
      </span>
    );
  }

  return (
    <DateTimePicker
      label={modifiedLabel}
      name={name}
      placeholder={placeholder}
      required={required}
      loading={loading}
      disabled={disabled}
      inputVariant="outlined"
      format={format}
      helperText={inputIsInvalid ? 'Invalid Date' : helperText}
      InputLabelProps={{ shrink: true }}
      inputRef={inputRef}
      fieldProps={required ? { validate: requiredFn(required) } : {}}
      {...moreProps}
    />
  );
};

FieldEditDateTime.defaultProps = {
  value: undefined,
  placeholder: '',
  required: false,
  loading: false,
  disabled: false,
  helperText: '',
  // format: 'YYYY-MM-DD',
};

FieldEditDateTime.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  format: PropTypes.string,
};

export default FieldEditDateTime;
