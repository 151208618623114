import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from 'mui-rff';
import { Checkbox as MuiCheckbox } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

/**
 * Styling an autocomplete field for a form
 */

const useStyles = makeStyles(theme => ({
  // make menu positioning relative
  popperDisablePortal: {
    position: 'relative',
  },
}));

const FieldEditMultiSelect = props => {
  const classes = useStyles();
  const { disabled, required, name, label, value, options, loading, helperText } = props;
  const requiredFnMultiSelect = required => value => {
    if (required) return value.length !== 0 ? undefined : 'Required';
    return undefined;
  };

  const moreProps = {};
  const textFieldProps = {};
  // material-ui will show a warning if the value is loaded before the options
  if (options.length === 0) {
    // avoid crashing if there are no options set.
    moreProps.options = [{ label: '', value: '' }];
    moreProps.disabled = true;
    textFieldProps.placeholder = 'no options';
  }
  // if we need to display a value or format a value
  if (value) moreProps.value = value;

  let modifiedLabel = label;
  if (required) {
    modifiedLabel = (
      <span>
        {label}
        <sup>*</sup>
      </span>
    );
  }

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      disablePortal
      classes={classes}
      disabled={disabled || loading}
      name={name}
      label={modifiedLabel}
      options={options}
      helperText={helperText}
      getOptionValue={option => option.value}
      getOptionLabel={option => option.label}
      renderOption={(optionProps, option, { selected }) => (
        <li {...optionProps}>
          <MuiCheckbox style={{ marginRight: 8 }} checked={selected} />
          {option.label}
        </li>
      )}
      fieldProps={required ? { validate: requiredFnMultiSelect(required) } : {}}
      textFieldProps={{
        variant: 'outlined',
        InputLabelProps: { shrink: true },
        ...textFieldProps,
      }}
      {...moreProps}
    />
  );
};

FieldEditMultiSelect.defaultProps = {
  value: undefined,
  required: false,
  disabled: false,
  loading: false,
  helperText: '',
};

FieldEditMultiSelect.propTypes = {
  /** label for the autocomplete field */
  label: PropTypes.string.isRequired,
  /** sets the label width so the border does not overlap the label */
  labelwidth: PropTypes.number.isRequired,
  /** the api key for the feature */
  name: PropTypes.string.isRequired,
  /** possible values to select from in the shape of: */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  /** initial value for the field */
  value: PropTypes.string,
  /** sets the field as required for the form */
  required: PropTypes.bool,
  /** disables the field while true */
  disabled: PropTypes.bool,
  /** disables the field while true */
  loading: PropTypes.bool,
  /** adds helper text below the form field */
  helperText: PropTypes.string,
};

export default FieldEditMultiSelect;
