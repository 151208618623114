import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Menu, MenuItem } from '@mui/material';
import { openReportWindow } from '../../../utilities/reports';

const ReportsActionMenu = ({ options }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} variant="outlined">
        Reports
      </Button>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {options.map(o => (
          <MenuItem key={o.name} onClick={() => openReportWindow(o.url)}>
            {o.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

ReportsActionMenu.defaultProps = {
  options: [],
};
ReportsActionMenu.propTypes = {
  options: PropTypes.array,
};

export default ReportsActionMenu;
