export const galleryFilters = {
  ALL: 'all',
  DEFECTS_ONLY: 'defectsOnly',
};

/**
 * fieldOrder
 *
 * An object where the keys define the sections of the form.   Each section requires the key display (string) and
 * fields (array of strings). Fields are the api keys (in snake case) for each field that will be displayed in the form.
 * Any fields that are never shown should not be added to this array.
 *
 * @return {object}
 */

export const fieldOrder = {
  location: {
    display: 'Location',
    fields: ['asset', 'location_guide', 'component', 'location_zone', 'location_code', 'access'],
  },
  category: {
    display: 'Category',
    fields: ['defect_guide', 'severity', 'type', 'sub_type', 'labels', 'description'],
  },
  size: {
    display: 'Size',
    fields: ['length', 'width', 'area'],
  },
  priority: {
    display: 'Priority',
    fields: ['priority', 'repair_by', 'next_inspection_date'],
  },
  status: {
    display: 'Status',
    fields: ['state', 'created_on', 'updated_on', 'external_id', 'geometry'],
  },
};

/**
 *  removeField
 *
 *  removes a field depending on if a user is creating, updating or viewing.
 *
 *  @param {string} state - enum for creating, updating or viewing ['CREATE', 'UPDATE', 'VIEW']
 *  @param {string} item - api key (in snake case) to check whether it should be hidden.
 *  @return {boolean} - returns true if the item will not be shown
 */
export const removeField = state => item => {
  let fields = [];
  switch (state) {
    case 'CREATE':
      fields = ['created_on', 'updated_on'];
      break;
    case 'UPDATE':
      fields = ['created_on', 'updated_on'];
      break;
    case 'VIEW':
      fields = [];
      break;
    default:
      return false;
  }
  return fields.includes(item);
};

/**
 * hideField
 *
 * This function is for hiding a field depending on the value of another field.
 *
 * @param {object} values - the values of all the fields in the form.
 * @param {string} item - api key (in snake case) to check whether it should be removed.
 * @return {boolean} - returns true if the item will not be shown
 */
export const hideField = (
  values,
  item,
  defectProfile,
  locationLayerProfile,
  hasAccessDisplay,
  hasFindingsPrioritization,
  showAsset
) => {
  let shouldHide = false;
  if (defectProfile == null || locationLayerProfile == null) {
    shouldHide = true;
  } else {
    switch (item) {
      case 'asset':
        shouldHide = !showAsset;
        break;
      case 'location_code':
      case 'location_code_display':
        shouldHide = locationLayerProfile.code_mode === 'OFF';
        break;
      case 'location_zone':
      case 'location_zone_display':
        shouldHide = locationLayerProfile.zone_mode === 'OFF';
        break;
      case 'access':
      case 'access_display':
        shouldHide = !hasAccessDisplay || locationLayerProfile.access_mode === 'OFF';
        break;

      case 'severity':
      case 'severity_display':
        shouldHide = defectProfile.severity_mode === 'OFF';
        break;
      case 'type':
      case 'type_display':
        shouldHide = defectProfile.type_mode === 'OFF';
        break;
      case 'labels':
        shouldHide = defectProfile.label_mode === 'OFF';
        break;
      case 'sub_type':
      case 'sub_type_display':
        shouldHide = defectProfile.sub_type_mode === 'OFF';
        break;
      case 'priority':
      case 'repair_by':
      case 'next_inspection_date':
        shouldHide = !hasFindingsPrioritization;
        break;
    }
  }
  return shouldHide;
};
