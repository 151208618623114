import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { ButtonGroup, IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { openDialog } from '../../store/dialogActions';
import DeleteDialog from '../shared/Dialog/DeleteDialog';
import { deleteMeasurementsBulk } from '../../store/features/measurementsActions';

const MeasurementsTableToolbar = props => {
  const { selectedRows, displayData, setSelectedRows, columnIndicesByName } = props;
  const dispatch = useDispatch();
  const [confirm, setConfirm] = useState();
  const [hasPublishedProjects, setHasPublishedProjects] = useState(false);

  const getIds = (rows, displayData) => {
    const selected = { ids: [], names: [], dataIndices: [] };
    for (let i = 0; i < rows.data.length; i++) {
      selected.ids.push(displayData[rows.data[i].dataIndex]?.data[0]);
      selected.names.push(displayData[rows.data[i].dataIndex]?.data[1]?.props?.value);
      selected.dataIndices.push(rows.data[i]?.dataIndex);
    }
    return selected;
  };

  const checkForPublishedProjects = Array.isArray(selectedRows.data)
    ? selectedRows.data.some(
        item => displayData[item.dataIndex].data[columnIndicesByName['project.status']] === 'PUBLISHED'
      )
    : false;

  useEffect(() => {
    checkForPublishedProjects ? setHasPublishedProjects(true) : setHasPublishedProjects(false);
  }, [selectedRows, checkForPublishedProjects]);

  useEffect(() => {
    if (confirm) {
      setSelectedRows([]);
    }
  }, [confirm, setSelectedRows]);

  const handleDelete = () => {
    const rowInfo = getIds(selectedRows, displayData);
    const ids = {
      ids: rowInfo?.ids,
    };
    dispatch(
      openDialog(
        'Delete Projects?',
        <DeleteDialog
          id={ids}
          deleteAction={deleteMeasurementsBulk}
          confirm={setConfirm}
          name={`${Array.isArray(rowInfo?.names) ? rowInfo.names.map(name => `${name}`).join(', ') : ''} `}
        />
      )
    );
    setSelectedRows(rowInfo.dataIndices);
  };

  return (
    <>
      <ButtonGroup>
        <Tooltip
          title={hasPublishedProjects ? 'Cannot delete measurements from Published Projects' : 'Delete Measurements'}>
          <span>
            <IconButton onClick={handleDelete} disabled={hasPublishedProjects} size="large">
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
      </ButtonGroup>
    </>
  );
};

MeasurementsTableToolbar.defaultProps = {
  selectedRows: [],
  displayData: [],
  setSelectedRows: () => {},
};

MeasurementsTableToolbar.propTypes = {
  selectedRows: PropTypes.array,
  displayData: PropTypes.array,
  deleteAction: PropTypes.func.isRequired,
  setSelectedRows: PropTypes.func,
  columnIndicesByName: PropTypes.object.isRequired,
};

export default MeasurementsTableToolbar;
