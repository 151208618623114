import { useSelector } from 'react-redux';
import { defectProfilesListAllEndpoint } from '../store/apiV2/defectProfiles';
import { checklistTemplatesBatchListEndpoint } from '../store/apiV2/checklistTemplates';

export function useAssetCondition() {
  return useSelector(state => {
    const { assetConditions } = state.settings.features;
    return assetConditions
      ? Object.values(assetConditions).map(({ value, description }) => {
          return { value: value.toString(), label: description };
        })
      : [];
  });
}

export function useAssetTypes() {
  return useSelector(state => {
    const { results } = state.assetTypes.all.dataAll;
    return results
      ? results.map(({ name, id }) => {
          const label = name == null ? '' : name;
          const value = id.toString();
          return { label, value };
        })
      : [];
  });
}

export function useAssets() {
  return useSelector(state => {
    const { results } = state.assets.all.dataAll;
    return results
      ? results.map(result => {
          const { asset_path, id, type } = result; // eslint-disable-line camelcase
          const label = asset_path != null ? asset_path : ''; // eslint-disable-line camelcase
          const value = id.toString();
          return { label, value, type };
        })
      : [];
  });
}

export function useCompanies(roles = false) {
  return useSelector(state => {
    const { results } = state.companies.all.dataAll;
    if (!results) return [{ label: '', value: '' }];
    const companies = results.map(company => {
      const label = company.name == null ? '' : company.name;
      const value = company.id.toString();
      const ret = { label, value };
      if (roles) {
        ret.role = company.role;
      }
      return ret;
    });
    return companies;
  });
}

export function useCrews() {
  return useSelector(state => {
    const { results } = state.crews.all.dataAll;
    return results
      ? results.map(({ name, id, display_name: displayName }) => {
          const label = name == null ? '' : displayName;
          const value = id.toString();
          return { label, value };
        })
      : [{ label: '', value: '' }];
  });
}

export function useRules() {
  return useSelector(state => {
    const { results } = state.notificationRules.all.dataAll;
    return results
      ? results.map(({ name, description, id }) => {
          const label = `${name} ${description ? ` -- ${description}` : ''}`;
          const value = id.toString();
          return { label, value };
        })
      : [];
  });
}

export function useProjectTypes(filterFn) {
  const options = useSelector(state => {
    const { results } = state.projectTypes.all.dataAll;
    return results
      ? results
          .filter(projectType => projectType.is_active)
          .map(
            ({
              name,
              id,
              asset_types: assetTypes,
              project_children_mode: childrenMode,
              project_scope_settings: projectScopeSettings,
              checklist_template: checklistTemplate,
            }) => {
              const label = name != null ? name : '';
              const value = id.toString();
              return { label, value, assetTypes, childrenMode, projectScopeSettings, checklistTemplate };
            }
          )
      : [];
  });

  if (filterFn) {
    return options.filter(filterFn);
  }

  return options;
}

export function useReportTypes(isFilter = false) {
  const reportTypeMap = {};
  const reportTypeOptions = useSelector(state => {
    const { results } = state.apiV2.reportTypesListAll.data;
    return results
      ? results.map(({ name, id }) => {
          const label = name == null ? '' : name;
          const value = id.toString();
          reportTypeMap[value] = label;
          return isFilter ? value : { label, value };
        })
      : [];
  });
  return { reportTypeOptions, reportTypeMap };
}

const docStateMap = {
  DRAFT: 'Draft',
  ACTIVE: 'Active',
};
export function useChecklistTemplates() {
  const { data } = checklistTemplatesBatchListEndpoint.useEndpoint();
  if (!data?.results) {
    return [];
  }
  return data.results
    .filter(({ state }, index) => state !== 'ARCHIVED')
    .map(({ name, id, state, version }) => {
      const value = id.toString();
      const label = name == null ? '' : `${name} - ${version} [${docStateMap[state]}]`;
      return { label, value };
    });
}

export function useDefectProfiles() {
  const { data } = defectProfilesListAllEndpoint.useEndpoint();
  if (!data?.results) {
    return [];
  }
  return data.results
    .filter(({ state }, index) => state !== 'ARCHIVED')
    .map(({ name, id, state, version }) => {
      const value = id.toString();
      const label = name == null ? '' : `${name} - ${version} [${docStateMap[state]}]`;
      return { label, value };
    });
}

export function useLocationLayerProfiles() {
  return useSelector(state => {
    const { results } = state.locationLayerProfiles.all.dataAll;
    return results
      ? results
          .filter(({ state }, index) => state !== 'ARCHIVED')
          .map(({ name, id, state, version }) => {
            const value = id.toString();
            const label = name == null ? '' : `${name} - ${version} [${docStateMap[state]}]`;
            return { label, value };
          })
      : [];
  });
}
