export const BULK_IMPORTS_REQUEST = 'BULK_IMPORTS_REQUEST';
export const BULK_IMPORTS_SUCCESS = 'BULK_IMPORTS_SUCCESS';
export const BULK_IMPORTS_FAILURE = 'BULK_IMPORTS_FAILURE';
export const BULK_IMPORTS_CREATE_REQUEST = 'BULK_IMPORTS_CREATE_REQUEST';
export const BULK_IMPORTS_CREATE_SUCCESS = 'BULK_IMPORTS_CREATE_SUCCESS';
export const BULK_IMPORTS_CREATE_FAILURE = 'BULK_IMPORTS_CREATE_FAILURE';
export const BULK_IMPORTS_UPDATE_REQUEST = 'BULK_IMPORTS_UPDATE_REQUEST';
export const BULK_IMPORTS_UPDATE_SUCCESS = 'BULK_IMPORTS_UPDATE_SUCCESS';
export const BULK_IMPORTS_UPDATE_FAILURE = 'BULK_IMPORTS_UPDATE_FAILURE';
export const BULK_IMPORTS_DELETE_REQUEST = 'BULK_IMPORTS_DELETE_REQUEST';
export const BULK_IMPORTS_DELETE_SUCCESS = 'BULK_IMPORTS_DELETE_SUCCESS';
export const BULK_IMPORTS_DELETE_FAILURE = 'BULK_IMPORTS_DELETE_FAILURE';
export const BULK_IMPORTS_ALL_REQUEST = 'BULK_IMPORTS_ALL_REQUEST';
export const BULK_IMPORTS_ALL_SUCCESS = 'BULK_IMPORTS_ALL_SUCCESS';
export const BULK_IMPORTS_ALL_FAILURE = 'BULK_IMPORTS_ALL_FAILURE';

export const getBulkImports = id => {
  return {
    type: BULK_IMPORTS_REQUEST,
    id,
  };
};

export const createBulkImports = body => {
  return {
    type: BULK_IMPORTS_CREATE_REQUEST,
    body,
  };
};

export const updateBulkImports = (id, body) => {
  return {
    type: BULK_IMPORTS_UPDATE_REQUEST,
    id,
    body,
  };
};

export const deleteBulkImports = id => {
  return {
    type: BULK_IMPORTS_DELETE_REQUEST,
    id,
  };
};

export const getAllBulkImports = query => {
  return {
    type: BULK_IMPORTS_ALL_REQUEST,
    query,
  };
};
