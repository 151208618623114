import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '../shared/table/Table';
import {
  setScheduleWorkTableView,
  setScheduleWorkTableSize,
} from '../../store/settings/views/scheduleWorkTableViewRedux';
import { deleteScheduleWork } from '../../store/features/scheduleWorkActions';
import { openDialog } from '../../store/dialogActions';
import { locationWithBack } from '../../utilities/route';
import DeleteDialog from '../shared/Dialog/DeleteDialog';
import { setColumns } from '../shared/table/columns';
import StyledLink from '../shared/StyledLink';
import { usePermissions, useTableViews } from '../../hooks/settingsHooks';
import { taskCreateModes } from '../../api/features/constants';
import { displayOption } from '../../utilities/strings';
import { getCustomCSVData, tableViews } from '../../utilities/tables';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const ScheduleWorkTable = props => {
  const { title /** , queryParamStr, **/, embedded, planId } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { loading, data, count } = useSelector(state => ({
    loading: state.scheduleWork.all.loading,
    data: state.scheduleWork.all.dataAll.results,
    count: state.scheduleWork.all.dataAll.count,
  }));
  const { scheduleWorkTableViewKey } = tableViews;
  const views = useTableViews(scheduleWorkTableViewKey);
  const { hasSchedulePlanCreate } = usePermissions();

  /** @type {Column[]} */
  const columns = [
    {
      // This needs to be first and excluded so customBodyRender can access the id to create the link.
      name: 'id',
      label: 'Id',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          if (!value) return <></>;
          return <StyledLink to={`/schedule-work/${tableMeta.rowData[columns.findIndexByName['id']]}`} value={value} />;
        },
      },
    },
    {
      name: 'checklist_template',
      label: 'Checklist Template',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => {
          if (!value) return <></>;
          return <StyledLink to={`/checklist-templates/${value.id}`} value={value.name} />;
        },
        downloadBody: value => getCustomCSVData('simple', value),
      },
    },
    {
      name: 'project_type',
      label: 'Project Type',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => {
          if (!value) return <></>;
          return <StyledLink to={`/project-types/${value.id}`} value={value.name} />;
        },
      },
    },
    {
      name: 'crew',
      label: 'Crew',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => {
          if (!value) return <></>;
          return <StyledLink to={`/crews/${value.id}`} value={value.display_name} />;
        },

        downloadBody: value => getCustomCSVData('simple', value, 'display_name'),
      },
    },
    {
      name: 'task_create_mode',
      label: 'Task Create Mode',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => {
          return <>{displayOption(value, taskCreateModes)}</>;
        },
      },
    },

    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        print: false,
        viewColumns: false,
        customBodyRender: (_, info) => {
          // 0 is the first element in column
          const id = info.rowData[columns.findIndexByName['id']];
          const name = info.rowData[columns.findIndexByName['name']];
          return (
            <>
              <EditIcon
                fontSize="small"
                onClick={() => {
                  history.push(locationWithBack({ pathname: `/schedule-work/${id}/edit` }, location));
                }}
              />
              <DeleteIcon
                fontSize="small"
                onClick={() => {
                  dispatch(
                    openDialog(
                      'Delete Scheduled Work?',
                      <DeleteDialog id={id} deleteAction={deleteScheduleWork} name={name} />
                    )
                  );
                }}
              />
            </>
          );
        },
      },
    },
  ];

  // handle columns display
  setColumns(columns, views);

  /** @type {TableOptions} */
  const options = {
    // if the column view changes, update redux with either 'add' or 'remove'
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setScheduleWorkTableView(changedColumn, action));
    },
    onChangeRowsPerPage: numberOfRows => {
      dispatch(setScheduleWorkTableSize(numberOfRows));
    },
    rowsPerPage: views.pageSize,
    enableNestedDataAccess: '.',
    searchOpen: !embedded,
  };

  return (
    <Table
      title={title}
      simpleSearch
      columns={columns}
      addRoute={hasSchedulePlanCreate && `/schedule-work/new?planId=${planId}`}
      data={data}
      options={options}
      loading={loading}
      embedded={embedded}
      count={count}
    />
  );
};

ScheduleWorkTable.defaultProps = {
  queryParamStr: '',
  embedded: false,
  title: '',
};

ScheduleWorkTable.propTypes = {
  title: PropTypes.string,
  queryParamStr: PropTypes.string,
  embedded: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  planId: PropTypes.number.isRequired,
};

export default ScheduleWorkTable;
