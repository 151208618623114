import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import AssessmentDescendants from './AssessmentDescendants';
import AssessmentDetail from '../assessments/AssessmentDetail';
import AssessmentRecommendedWorkPlanTable from './AssessmentRecommendedWorkPlanTable';
import Loading from '../shared/displays/Loading';

import { getAssessments } from '../../store/features/assessmentsActions';
import { isEmpty } from '../../utilities/objects';
import { DISPLAY_MODES } from '../../api/features/constants';

const AssessmentDisplay = props => {
  const { assessmentId } = props;

  const dispatch = useDispatch();

  // Load data on mount
  useEffect(() => {
    dispatch(getAssessments(assessmentId));
  }, [dispatch, assessmentId]);

  //
  // Assessment data
  //
  const assessmentRequest = useSelector(state => state.assessments.each);
  if (assessmentRequest.loading || isEmpty(assessmentRequest.data)) {
    return <Loading />;
  }
  const { data: assessment } = assessmentRequest;

  //
  // Render based on assessment.display
  //  see https://github.com/huvrdata/huvr/discussions/8374
  //
  switch (assessment.display) {
    case DISPLAY_MODES.SUMMARY:
      return (
        <>
          <AssessmentDescendants assessment={assessment} />
          <AssessmentRecommendedWorkPlanTable assessment={assessment} />
        </>
      );
    case DISPLAY_MODES.DETAIL:
      return (
        <>
          <AssessmentDetail assessment={assessment} />
          <AssessmentRecommendedWorkPlanTable assessment={assessment} excludeAssetPath={true} />
        </>
      );
    case DISPLAY_MODES.DETAIL_SUMMARY:
      return (
        <>
          <AssessmentDetail assessment={assessment} />
          <AssessmentDescendants assessment={assessment} />
          <AssessmentRecommendedWorkPlanTable assessment={assessment} />
        </>
      );
    default:
      console.warn(`AssessmentDisplay: assessment.display not recognized: ${assessment.display}`);
      return <div>assessment display not available</div>;
  }
};

AssessmentDisplay.propTypes = {
  assessmentId: PropTypes.any.isRequired,
};

export default AssessmentDisplay;
