import React from 'react';
import CommonRoutes from './utilities/CommonRoutes';
import ReportTypesForm from '../../components/reportTypes/ReportTypesForm';
import ReportTypesView from '../../components/reportTypes/ReportTypesView';
import ReportTypesTable from '../../components/reportTypes/ReportTypesTable';
import MainLayout from '../layouts/MainLayout';

const ReportTypeRoutes = props => {
  return (
    <CommonRoutes
      pageTitle="ReportType"
      laayout={MainLayout}
      Edit={<ReportTypesForm update />}
      Create={<ReportTypesForm />}
      View={<ReportTypesView />}
      All={<ReportTypesTable />}
    />
  );
};

export default ReportTypeRoutes;
