import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Router } from 'react-router-dom';
import { getLogo } from '../store/settings/logoActions';
import { baseRoute } from '../utilities/strings';
import Routes from '../routes/Routes';

const RouterProvider = (props) => {
  const { history, children } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLogo());
  }, [dispatch]);

  class DebugRouter extends Router {
    constructor(props) {
      super(props);
      if (process.env.NODE_ENV !== 'production') {
        history.listen((location, action) => {
          // console.log({ info });
          // const { location, action } = info;
          console.log('The current location is: ', location);
          console.log(`The last navigation action was ${action}`);
        });
      }
    }
  }

  return (
    <DebugRouter history={history} basename={baseRoute}>
      <Routes />
      {children}
    </DebugRouter>
  );
};

RouterProvider.propTypes = {
  history: PropTypes.object,
  children: PropTypes.node,
};

export default RouterProvider;
