import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// util / store
import { useFileUploader } from '../../providers/FileUploaderProvider';
import { createInspectionMedia } from '../../api/features/inspectionMedia';
import { inspectionMediaListBatchFetchAllEndpoint } from '../../store/apiV2/inspectionMedia';

export const NEW_CHECKLIST_ID = 'new';
export const CHECKLIST = 'checklist';
export const PROJECT = 'project';

/**
 *
 */
export const ChecklistFileUploader = props => {
  const dispatch = useDispatch();

  const { uploadOptionsRef, renderDragNDrop } = useFileUploader();
  const { dispatchRequest: batchFetchAllMedia } = inspectionMediaListBatchFetchAllEndpoint.useEndpoint();

  const { containerId, containerType, onUploadSuccess, onUploadEachSuccess } = props;
  const isNewForm = containerId === NEW_CHECKLIST_ID && containerType !== PROJECT;

  /**
   * @name useEffect-setUploadOptions
   */
  useEffect(() => {
    uploadOptionsRef.current = {
      /**
       * @name getUploadParameters
       * @param {Object} file
       * @param {Function} setMediaObject - callback to set the inspection media object
       * @returns {Promise<{ method, url, fields, headers }>}
       * @description create a signed url for uploading to GCP bucket
       *   creates a record in the database for the file
       *
       * See:
       *  https://uppy.io/docs/aws-s3-multipart/#getuploadparametersfile-options
       */
      getUploadParameters: (file, setMediaObject) => {
        // Send a request to our signing endpoint.
        const payload = {
          filename: file.name,
          media_type: file.type,
          file_meta: {
            uppy_id: file.id, // uppy_id can be used to update the file in a checklist item
            source: file.source,
            type: file.type,
            name: file.name,
            size: file.size,
            relative_path: file.meta.relativePath,
          },
        };

        if (!isNewForm) {
          // checklist or project
          payload[containerType] = containerId;
        } else {
          // no source yet ...
          //  will be retroactively added after saving checklist
        }

        return createInspectionMedia(payload).then(resp => {
          setMediaObject(resp.data); // set the inspection media object, used for updating the checklist

          // Return an object in the correct shape.
          // save the database id, to update after upload
          file.remoteId = resp.data.id;
          return {
            method: 'PUT',
            url: resp.data.upload.url,
            fields: [],
            headers: resp.data.upload.headers,
          };
        });
      },

      /**
       * @name onUploadSuccess
       * @description callback to run after all files have been uploaded
       *    update the inspection media list
       */
      onUploadSuccess: result => {
        const query = {
          hidden: false,
          ordering: '-created_on',
        };

        if (isNewForm) {
          // only show files created by the
          //  current user while creating this checklist
          query.created_by_me = true;
          query.file = 'NOSOURCE';
          query.recent = 'last_24_hours';
        } else {
          // project or checklist
          query[containerType] = containerId;
        }

        batchFetchAllMedia({ ...query, limit: 200 }).then(resp => {
          if (onUploadSuccess) {
            onUploadSuccess(result, resp);
          }
        });
      },

      /**
       * @name onUploadEachSuccess
       * @description callback to run after each file has been uploaded
       * @param {Object} file
       * @param {Object} result
       * @param {Object} mediaObject - the inspection media object
       */
      onUploadEachSuccess: (file, result, mediaObject) => {
        if (onUploadEachSuccess) {
          onUploadEachSuccess(file, result, mediaObject);
        }
      },
    };

    return () => {
      // on unmount unset uploadOptions
      // uploadOptionsRef.current = {};
      // do NOT unset uploadOptionsRef.current
      //  so the onUploadSuccess callback can be called
      //  after the component unmounts
      // (will still overwrite onUploadSuccess callback if a _new_ uploader is mounted)
    };
  }, [
    dispatch,
    uploadOptionsRef,
    isNewForm,
    containerId,
    containerType,
    batchFetchAllMedia,
    onUploadEachSuccess,
    onUploadSuccess,
  ]);

  return <div>{renderDragNDrop({ note: 'Add media to the checklist' })}</div>;
};

ChecklistFileUploader.defaultProps = {
  containerType: PROJECT,
};

ChecklistFileUploader.propTypes = {
  containerId: PropTypes.string.isRequired,
  containerType: PropTypes.string, // project or checklist
  /** only supported for gallery 2 */
  onUploadSuccess: PropTypes.func,
  onUploadEachSuccess: PropTypes.func,
};

export default ChecklistFileUploader;
