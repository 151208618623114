import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import arrayMutators from 'final-form-arrays';
import makeStyles from '@mui/styles/makeStyles';
import { diff } from 'deep-object-diff';
import { jsonKeyToLabel, apiDateToString } from '../../utilities/strings';
import { defectStateOptions } from '../../api/features/constants';
import {
  createInspectionMediaOverlay,
  updateInspectionMediaOverlay,
} from '../../store/features/inspectionMediaActions';
import CommonForm from '../shared/form/Common2';
import { fieldOrder, removeField, hideField } from './defectsShared';
import InspectorDefectFormToolbar from './InspectorDefectFormToolbar';
import { integerValidator } from '../../utilities/validators';
import { useFeatureFlags } from '../../hooks/settingsHooks';
import { useAssets } from '../../hooks/optionsHooks';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: 0,
  },
}));

const displayGeometry = ({ type, coordinates }) => {
  if (!type) {
    return '';
  }
  if (!coordinates.length) {
    return '';
  }
  const coordinatesDisplay = coordinates.map(item => `(${item[0]}, ${item[1]})`);
  return `${type}: ${coordinatesDisplay}`;
};

const InspectorDefectsForm = props => {
  const {
    cancel,
    resetDefect,
    onDelete,
    update,
    locationLayerProfile,
    defectProfile,
    asset,
    showAsset,
    media,
    selectedOverlay,
    dirty,
    isDirty,
    onSubmit,
    heightOffset,
  } = props;
  const { loading, formError } = useSelector(state => state.inspectionMedia.each);
  const { results: defects } = useSelector(state => state.defects.all.dataAll);

  const [submittedValues, setSubmittedValues] = useState({});
  const [assetSelect, setAsset] = useState('');
  const dispatch = useDispatch();
  const assetOptions = useAssets();
  const classes = useStyles();
  const { hasAccessDisplay } = useFeatureFlags();
  const hasFindingsPrioritization = false; // not used in Visual Inspector

  const formSettings = {
    fieldOrder,
    removeField: removeField(update ? 'UPDATE' : 'CREATE'),
    hideField: (values, item) => {
      return hideField(
        values,
        item,
        defectProfile,
        locationLayerProfile,
        hasAccessDisplay,
        hasFindingsPrioritization,
        showAsset
      );
    },
  };

  const getSeverity = () => {
    const field = {
      type: 'text',
      fieldProps: {
        label: 'Severity',
        name: 'defect.severity',
        helperText: defectProfile?.severity_help || '',
        labelwidth: 50,
      },
    };
    if (defectProfile.severity_mode === 'LIST') {
      field.type = 'autocomplete';
      field.fieldProps['options'] = defectProfile.severity_options;
      return field;
    }
    if (defectProfile.severity_mode === 'FREE') {
      field.fieldProps['validator'] = value => integerValidator(value);
      return field;
    }
  };
  const getLocationZone = () => {
    const field = {
      type: 'text',
      fieldProps: {
        label: 'Location Zone',
        name: 'defect.location_zone',
        helperText: locationLayerProfile?.zone_help || '',
        labelwidth: 90,
      },
    };
    if (locationLayerProfile.zone_mode === 'LIST') {
      field.type = 'autocomplete';
      field.fieldProps['options'] = locationLayerProfile.zone_options;
      return field;
    }
    if (locationLayerProfile.zone_mode === 'FREE') {
      return field;
    }
  };

  const updateAsset = (_, value) => {
    if (value) {
      setAsset(value.value);
    } else {
      setAsset(`${asset?.id}`); // default to project asset
    }
  };
  const getLocationCode = () => {
    const field = {
      type: 'text',
      fieldProps: {
        label: 'Location Code',
        name: 'defect.location_code',
        helperText: locationLayerProfile?.code_help || '',
        labelwidth: 80,
      },
    };
    if (locationLayerProfile.code_mode === 'LIST') {
      field.type = 'autocomplete';
      field.fieldProps['options'] = locationLayerProfile.code_options;
      return field;
    }
    if (locationLayerProfile.code_mode === 'FREE') {
      return field;
    }
  };

  const getAccess = () => {
    const field = {
      type: 'text',
      fieldProps: {
        label: 'Access',
        name: 'defect.access',
        helperText: locationLayerProfile?.access_help || '',
        labelwidth: 80,
      },
    };
    if (locationLayerProfile.access_mode === 'LIST') {
      field.type = 'autocomplete';
      field.fieldProps['options'] = locationLayerProfile.access_options;
      return field;
    }
    if (locationLayerProfile.access_mode === 'FREE') {
      return field;
    }
  };

  const getComponent = () => {
    const field = {
      type: 'text',
      fieldProps: {
        label: 'Component',
        name: 'defect.component',
        helperText: locationLayerProfile?.component_help || '',
        labelwidth: 80,
      },
    };
    if (locationLayerProfile.component_mode === 'LIST') {
      field.type = 'autocomplete';
      field.fieldProps['options'] = locationLayerProfile.component_options;
      return field;
    }
    if (locationLayerProfile.component_mode === 'FREE') {
      return field;
    }
  };

  const getDefectType = () => {
    const field = {
      type: 'text',
      fieldProps: {
        label: 'Type',
        name: 'defect.type',
        helperText: defectProfile?.type_help || '',
        labelwidth: 40,
      },
    };
    if (defectProfile.type_mode === 'LIST') {
      field.type = 'autocomplete';
      field.fieldProps['options'] = defectProfile.type_options;
      return field;
    }
    if (defectProfile.type_mode === 'FREE') {
      return field;
    }
  };

  const getDefectSubType = () => {
    const field = {
      type: 'text',
      fieldProps: {
        label: 'Sub Type',
        name: 'defect.sub_type',
        helperText: defectProfile?.sub_type_help || '',
        labelwidth: 40,
      },
    };
    if (defectProfile.sub_type_mode === 'LIST') {
      field.type = 'autocomplete';
      field.fieldProps['options'] = defectProfile.sub_type_options;
      return field;
    }
    if (defectProfile.sub_type_mode === 'FREE') {
      return field;
    }
  };

  const getLocationGuide = () => {
    const field = {
      type: 'markdown',
      fieldProps: {
        source: locationLayerProfile?.guide || '',
      },
    };
    return field;
  };

  const getDefectGuide = () => {
    const field = {
      type: 'markdown',
      fieldProps: {
        label: 'Findings Guide',
        source: defectProfile?.guide || '',
      },
    };
    return field;
  };

  /**
   * Find the asset id for the defect asset in defects store rather than overlay
   * Returns stringified asset id for autocomplete
   */
  const findDefectAsset = initialValuesDefect => {
    if (!Array.isArray(defects) || !initialValuesDefect) {
      return `${asset?.id}`;
    }
    return `${defects.find(defect => defect.id === initialValuesDefect.id)?.asset?.id}`;
  };
  /**
   * NOTE: implicitly hiding form fields with defect type modes 'OFF' to user in form
   */

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      switch (key) {
        /* ---------- Required Fields ---------- */
        case 'component':
          fieldSettings[key] = getComponent();
          break;
        case 'location_zone':
          fieldSettings[key] = getLocationZone();
          break;
        case 'location_code':
          fieldSettings[key] = getLocationCode();
          break;
        case 'access':
          fieldSettings[key] = getAccess();
          break;
        case 'type':
          fieldSettings[key] = getDefectType();
          break;
        case 'severity':
          fieldSettings[key] = getSeverity();
          break;
        case 'sub_type':
          fieldSettings[key] = getDefectSubType();
          break;
        /* ---------- Regular Fields ---------- */
        case 'asset':
          fieldSettings[key] = {
            type: 'autocomplete',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: 'defect.asset',
              options: assetOptions,
              labelwidth: 40,
              onChange: updateAsset,
              value: `${assetSelect}`,
              helperText: "Defaults to project's asset if not set",
            },
          };
          break;
        case 'location_guide':
          fieldSettings[key] = getLocationGuide();
          break;
        case 'defect_guide':
          fieldSettings[key] = getDefectGuide();
          break;
        case 'labels':
          fieldSettings[key] = {
            type: 'key-value',
            fieldProps: {
              label: 'Labels',
              name: 'defect.labels',
            },
          };
          return;

        case 'state':
          fieldSettings[key] = {
            type: 'autocomplete',
            fieldProps: {
              label: 'State',
              name: 'defect.state',
              options: defectStateOptions,
              labelwidth: 40,
            },
          };
          return;

        /* ----------  Disabled Fields ---------- */
        case 'created_on':
        case 'updated_on':
          fieldSettings[key] = {
            type: 'display',
            fieldProps: {
              disabled: true,
              label: jsonKeyToLabel(key),
              name: `defect.${key}`,
              value: apiDateToString(selectedOverlay[key], 'date'),
            },
          };
          break;

        case 'description':
        case 'external_id':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: `defect.${key}`,
            },
          };
          break;
        case 'length':
        case 'width':
        case 'area':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: `defect.${key}`,
            },
          };
          break;

        case 'geometry':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              disabled: true,
              label: jsonKeyToLabel(key),
              name: key,
              value: selectedOverlay[key] ? displayGeometry(selectedOverlay[key]) : '',
            },
          };
          break;
        /* ----------  Default ---------- */
        default:
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
      }
    });
  }

  //  const title = update ? 'Edit Finding' : 'New Finding';

  let initialValues = {};

  if (update) {
    // The form cannot handle data coming in as objects.  (see ProjectsForm as an example)
    initialValues = { ...selectedOverlay };
    if (initialValues && !('defect' in initialValues)) {
      initialValues = { defect: {} };
    }
    // convert severity value to string for autocomplete field
    initialValues.defect.severity = `${initialValues.defect.severity}`;
    // grabbing this from defects instead of overlay since defect isn't always updated on overlay
    // https://github.com/huvrdata/huvr/issues/4492
    if (initialValues?.defect?.asset) {
      initialValues.defect.asset = findDefectAsset(initialValues.defect);
    }
    // if the media object has location data, copy to the defect
    const keys = ['component', 'location_zone', 'location_code', 'geo_point', 'access'];
    for (let i = 0, l = keys.length; i < l; i++) {
      if (media[keys[i]]) {
        initialValues.defect[keys[i]] = media[keys[i]];
      }
    }
  }

  const onSubmitLocal = changedValues => {
    if (onSubmit) {
      onSubmit();
    }
    // default to project asset if asset is removed from selection
    if (changedValues?.defect && 'asset' in changedValues?.defect) {
      changedValues.defect = {
        ...changedValues.defect,
        asset_id: changedValues.defect.asset ? Number(changedValues.defect.asset) : asset?.id,
      };
    }
    const moreChanges = diff(initialValues.defect, changedValues.defect);
    const updatedValues = {
      ...changedValues,
      defect: { ...moreChanges },
    };
    if (selectedOverlay.geometry) {
      updatedValues.geometry = selectedOverlay.geometry;
    }
    if (changedValues.defect?.labels) {
      updatedValues.defect.labels = changedValues.defect.labels;
    }
    setSubmittedValues(changedValues);
    isDirty(false);
    if (update) {
      dispatch(updateInspectionMediaOverlay(media.id, updatedValues));
    } else {
      dispatch(createInspectionMediaOverlay(media.id, updatedValues));
    }
  };

  /**
   * Decorators are used for setting the values of other fields based off of a field.
   * Decorator format can be found here: src/components/shared/form/common.js
   */
  const decorators = [];

  /**
   *  Validations that are per field.
   *  Errors associated with the form are passed back through formError in Redux.
   */
  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        if (values[key] === submittedValues[key]) {
          errors[key] = value;
        } else {
          errors[key] = undefined;
        }
      }
    }
    return errors;
  };

  return (
    <CommonForm
      embed
      keepDirtyOnReinitialize
      className={classes.root}
      update={update}
      initialValues={initialValues}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      onSubmit={onSubmitLocal}
      decorators={decorators}
      mutators={{ ...arrayMutators }}
      validate={validate}
      loading={loading}
      dirty={dirty}
      isDirty={isDirty}
      toolbar={InspectorDefectFormToolbar}
      customToolbarProps={{ cancel, resetDefect, onDelete, selectedOverlay }}
      heightOffset={heightOffset}
    />
  );
};

InspectorDefectsForm.defaultProps = {
  update: false,
  selectedOverlay: {},
  dirty: false,
  isDirty: undefined,
  cancel: undefined,
  resetDefect: undefined,
  onDelete: () => {},
  onSubmit: undefined,
  defectProfile: {},
  locationLayerProfile: {},
  heightOffset: '',
  showAsset: false, // allow project asset/sub-asset to be set on finding
};

InspectorDefectsForm.propTypes = {
  cancel: PropTypes.func,
  onDelete: PropTypes.func,
  update: PropTypes.bool,
  asset: PropTypes.object.isRequired,
  showAsset: PropTypes.bool, // allow project asset/sub-asset to be set on finding
  media: PropTypes.object.isRequired,
  defectProfile: PropTypes.object,
  locationLayerProfile: PropTypes.object,
  selectedOverlay: PropTypes.object,
  resetDefect: PropTypes.func,
  dirty: PropTypes.bool,
  isDirty: PropTypes.func,
  onSubmit: PropTypes.func,
  heightOffset: PropTypes.string,
};

export default InspectorDefectsForm;
