import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { Toolbar, Typography, AppBar, Link } from '@mui/material';

import ButtonIcon from '../shared/buttons/ButtonIcon';
import RefreshButton from '../shared/buttons/RefreshButton';
import ChecklistLock from '../checklists/ChecklistLock';
import { useFeatureFlags } from '../../hooks/settingsHooks';

const ChecklistToolbar = props => {
  const history = useHistory();
  const { hasChecklistLocking } = useFeatureFlags();

  const logo = useSelector(state => state.settings.logo.src);

  const { title, backLink, refresh, checklist } = props;

  const handleBack = () => history.push(backLink);

  return (
    <AppBar className="app-bar" elevation={1}>
      <Toolbar>
        {backLink ? (
          <ButtonIcon buttonSize="medium" iconSize="medium" icon={ArrowBackIosIcon} onClick={handleBack} />
        ) : (
          <Link href="/">
            <img src={logo} height="32" style={{ marginRight: '5px' }} />
          </Link>
        )}
        <Typography align="left" variant="h6" noWrap style={{ flex: 1 }}>
          {' '}
          {title}
        </Typography>
        {hasChecklistLocking && <ChecklistLock data={checklist} />}
        <RefreshButton showMenu refresh={refresh} />
      </Toolbar>
    </AppBar>
  );
};

ChecklistToolbar.propTypes = {
  title: PropTypes.object,
  checklist: PropTypes.object,
  refresh: PropTypes.func.isRequired,
  backLink: PropTypes.string,
};
ChecklistToolbar.defaultProps = {
  title: <></>,
  backLink: '',
};

export default ChecklistToolbar;
