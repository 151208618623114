import React, { useEffect, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ButtonGroup } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from '@mui/icons-material/Edit';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import Table, { SELECTABLE_ROWS_NONE } from '../shared/table/Table';
import { getAllProjectTypes, deleteProjectTypes, updateProjectTypes } from '../../store/features/projectTypesActions';
import {
  setProjectTypesTableView,
  setProjectTypesTableSize,
} from '../../store/settings/views/projectTypesTableViewRedux';
import { apiDateToString, compareFilterMapOptions } from '../../utilities/strings';
import ButtonIcon from '../shared/buttons/ButtonIcon';
import StyledLink from '../shared/StyledLink';
import { setColumns, sortByKey } from '../shared/table/columns';
import { openDialog } from '../../store/dialogActions';
import DeleteDialog from '../shared/Dialog/DeleteDialog';
import { usePermissions, useFeatureFlags, useTableViews } from '../../hooks/settingsHooks';
import { getCustomCSVData, tableViews } from '../../utilities/tables';
import { compare, ensureArray } from '../../utilities/arrays';
import { useCachedAssetTypes } from '../../hooks/assetTypesHooks';
import MultiSelectFilter from '../shared/table/MultiSelectFilter';
import { useReportTypes } from '../../hooks/optionsHooks';
import { reportTypesListAllEndpoint } from '../../store/apiV2/reportTypes';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const ProjectTypes = props => {
  const { queryParamObj, embedded, templateId } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { loading, data } = useSelector(state => ({
    loading: state.projectTypes.all.loading,
    data: state.projectTypes.all.dataAll.results,
  }));
  const { projectTypesTableViewKey } = tableViews;
  const views = useTableViews(projectTypesTableViewKey);
  const { hasConfigurationManagementBuild } = usePermissions();
  const { hasConditionMonitoring } = useFeatureFlags();
  const reportTypesListAll = reportTypesListAllEndpoint.useEndpoint();

  useEffect(() => {
    dispatch(getAllProjectTypes(queryParamObj));
    reportTypesListAll.dispatchRequest();
  }, []); // eslint-disable-line

  const { assetTypeOptions, assetTypeMap } = useCachedAssetTypes();
  const { reportTypeOptions, reportTypeMap } = useReportTypes(true);
  const [assetTypeFilterList, setAssetTypeFilterList] = useState();
  const [reportTypeFilterList, setReportTypeFilterList] = useState();

  const classes = useStyles();

  /** @type {Column[]} */
  const columns = [
    {
      // This needs to be first and excluded so customBodyRender can access the id to create the link.
      name: 'id',
      label: 'Id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        filterType: 'textField',
        customBodyRender: (value, tableMeta) => {
          return <StyledLink to={`/project-types/${tableMeta.rowData[columns.findIndexByName['id']]}`} value={value} />;
        },
      },
    },
    {
      name: 'asset_types',
      label: 'Asset Types',
      options: {
        filter: true,
        sort: false,
        filterType: 'custom',
        downloadBody: values => getCustomCSVData('array', ensureArray(values)),
        customBodyRender: values =>
          values.map((value, index) => {
            if (!value) return <></>;
            return (
              <Fragment key={value.id}>
                {index === 0 ? null : <span>, </span>}
                <StyledLink to={`/asset-types/${value.id}`} value={value.name} />
              </Fragment>
            );
          }),
        filterList: assetTypeFilterList,
        // chips functionality
        customFilterListOptions: {
          render: values => {
            return values.map(v => {
              return assetTypeMap[v];
            });
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            setAssetTypeFilterList(filterList[index]);
            return filterList;
          },
        },
        // filtering menu
        filterOptions: {
          names: assetTypeOptions,
          render: v => assetTypeMap[v],
          logic: (asset_types = [], filters) => {
            if (asset_types.length > 0) {
              const assetTypes = String(asset_types[0].id);
              if (filters.length) return !filters.includes(assetTypes);
            }
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            const formatValue = item => {
              return assetTypeMap[item];
            };
            const getComparable = value => compareFilterMapOptions(value, assetTypeMap);
            // Sort top level filter options
            filterData[index] = filterData[index].sort(compare(getComparable));

            return (
              <MultiSelectFilter
                title="Asset Types"
                filterList={filterList}
                localFilterList={[]}
                onChange={onChange}
                index={index}
                column={column}
                filterData={filterData}
                updateFilters={setAssetTypeFilterList}
                formatValue={formatValue}
              />
            );
          },
        },
      },
    },
    {
      name: 'report_types',
      label: 'Report Types',
      options: {
        filter: true,
        sort: false,
        filterType: 'custom',
        downloadBody: values => getCustomCSVData('array', ensureArray(values)),
        customBodyRender: values =>
          values.map((value, index) => {
            if (!value) return <></>;
            return (
              <Fragment key={value.id}>
                {index === 0 ? null : <span>, </span>}
                <StyledLink to={`/report-types/${value.id}`} value={value.name} />
              </Fragment>
            );
          }),
        filterList: reportTypeFilterList,
        // chips functionality
        customFilterListOptions: {
          render: values => {
            return values.map(v => {
              return reportTypeMap[v];
            });
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            setReportTypeFilterList(filterList[index]);
            return filterList;
          },
        },
        // filtering menu
        filterOptions: {
          names: reportTypeOptions,
          render: v => reportTypeMap[v],
          logic: (report_types = [], filters) => {
            const reportTypes = report_types.map(r => String(r.id));
            if (filters.length) {
              return !filters.some(x => reportTypes.includes(x));
            }
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            const formatValue = item => {
              return reportTypeMap[item];
            };
            const getComparable = value => compareFilterMapOptions(value, reportTypeMap);
            // Sort top level filter options
            filterData[index] = filterData[index].sort(compare(getComparable));

            return (
              <MultiSelectFilter
                title="Report Types"
                filterList={filterList}
                localFilterList={[]}
                onChange={onChange}
                index={index}
                column={column}
                filterData={filterData}
                updateFilters={setReportTypeFilterList}
                formatValue={formatValue}
                embedded={embedded}
              />
            );
          },
        },
      },
    },
    {
      name: 'checklist_template',
      label: 'Checklist Template',
      options: {
        filter: true,
        sort: true,
        sortCompare: order => {
          return (obj1, obj2) => {
            return sortByKey(order, obj1, obj2, 'name');
          };
        },
        downloadBody: value => getCustomCSVData('simple', value),
        customBodyRender: (value, info) => {
          const id = info.rowData[columns.findIndexByName['id']];
          const showUpgradeButton = Boolean(templateId && templateId.toString() !== value.id.toString());
          if (!value) return <></>;
          return (
            <>
              <StyledLink to={`/checklist-templates/${value.id}`} value={value.name} />
              {showUpgradeButton && (
                <ButtonIcon
                  icon={UpgradeIcon}
                  onClick={() =>
                    dispatch(updateProjectTypes(id, { checklist_template: templateId }, { noRoutePush: true }))
                  }
                  tooltip={`Upgrade Project Type to use selected Checklist Template (${templateId})`}
                />
              )}
            </>
          );
        },
      },
    },
    {
      name: 'is_active',
      label: 'Is Active',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => (value ? 'True' : 'False'),
        // custom render function for displaying booleans in filter chip
        customFilterListOptions: {
          render: value => {
            return 'Is Active: ' + value;
          },
        },
      },
    },
    {
      name: 'affects_asset_condition',
      label: 'Affects Asset Condition',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => (value ? 'True' : 'False'),
        // custom render function for displaying booleans in filter chip
        customFilterListOptions: {
          render: value => {
            return 'Affects Asset Condition: ' + value;
          },
        },
      },
    },
    {
      name: 'updated_on',
      label: 'Updated',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => apiDateToString(value, 'date'),
      },
    },
    {
      name: 'created_on',
      label: 'Created',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => apiDateToString(value, 'date'),
      },
    },
    {
      name: 'monitoring_mode',
      label: 'Monitoring Mode',
      options: {
        filter: true,
        sort: true,
        display: hasConditionMonitoring ? true : 'exclude',
      },
    },
    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        print: false,
        viewColumns: false,
        customBodyRender: (_, info) => {
          const id = info.rowData[columns.findIndexByName['id']];
          // const newProjectLocation = locationWithBack({ pathname: '/projects/new', search: `?type=${id}` }, location);
          const name = info.rowData[columns.findIndexByName['name']];
          const active = info.rowData[columns.findIndexByName['is_active']];
          return (
            <>
              <ButtonGroup>
                {hasConfigurationManagementBuild && (
                  <ButtonIcon history={history} icon={EditIcon} location={location} to={`/project-types/${id}/edit`} />
                )}
                <ButtonIcon
                  history={history}
                  icon={LibraryAddIcon}
                  location={location}
                  to={'/projects/new'}
                  search={`?type=${id}`}
                  disabled={!active}
                />
                {hasConfigurationManagementBuild && (
                  <ButtonIcon
                    icon={DeleteIcon}
                    onClick={() => {
                      dispatch(
                        openDialog(
                          'Delete Project Type?',
                          <DeleteDialog id={id} deleteAction={deleteProjectTypes} name={name} />
                        )
                      );
                    }}
                  />
                )}
              </ButtonGroup>
            </>
          );
        },
      },
    },
  ];

  // handle columns display
  setColumns(columns, views);

  /** @type {TableOptions} */
  const options = {
    // if the column view changes, update redux with either 'add' or 'remove'
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setProjectTypesTableView(changedColumn, action));
    },
    onChangeRowsPerPage: numberOfRows => {
      dispatch(setProjectTypesTableSize(numberOfRows));
    },
    rowsPerPage: views.pageSize,
    enableNestedDataAccess: '.',
    selectableRows: SELECTABLE_ROWS_NONE,
  };
  return (
    // workaround for embedded tables since Project Types doesn't have own Page
    <div className={embedded ? '' : classes.root}>
      <Table
        title="Project Types"
        simpleSearch
        columns={columns}
        addRoute={'/project-types/new'}
        data={data}
        options={options}
        loading={loading}
        embedded={embedded}
      />
    </div>
  );
};

ProjectTypes.defaultProps = {
  queryParamObj: {},
  embedded: false,
  templateId: undefined,
};

ProjectTypes.propTypes = {
  queryParamObj: PropTypes.object,
  embedded: PropTypes.bool,
  templateId: PropTypes.string,
};

export default ProjectTypes;
