import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Divider, Grid, Tabs, Tab } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import { userProfileEndpoint } from '../../store/apiV2/user';
import Title from '../shared/displays/Title';
import UserProfileView from './UserProfileView';
import UserSettings from './UserSettings';
import TabPanel from '../shared/TabPanel';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  settings: {
    margin: theme.spacing(1, 4),
    padding: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
}));

const UserProfile = () => {
  const classes = useStyles();
  const history = useHistory();
  const userProfileFetch = userProfileEndpoint.useEndpoint();
  const { tab: selectedTab = 'overview' } = useParams();

  useEffect(() => {
    userProfileFetch.dispatchRequest();
  }, []); // eslint-disable-line

  const { data, loading, error } = useSelector(state => state.apiV2.profile);

  const tabs = [
    {
      value: 'overview',
      label: 'Overview',
      Component: <UserProfileView data={data} loading={loading} error={error} />,
    },
    {
      value: 'settings',
      label: 'Settings',
      Component: <UserSettings />,
    },
  ];

  const handleTabChange = (_, value) => {
    history.push(`/profile/` + value);
  };

  let readyToRender = false;

  switch (selectedTab) {
    case 'overview':
    case 'settings':
      readyToRender = true;
      break;
    default:
      history.replace(`/profile`);
  }
  // the Tabs component doesn't handle value=undefined
  if (!readyToRender) return <></>;

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} className={classes.marginBottom}>
        <Title title="User Profile" />
      </Grid>
      <Grid item xs={12}>
        <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary">
          {tabs
            .filter(tab => !tab.hide)
            .map(tab => (
              <Tab
                id={`company-tab-${tab.value}`}
                aria-controls={`company-tabpanel-${tab.value}`}
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
        </Tabs>
        <Divider className={classes.divider} />
        {tabs.map(tab => (
          <TabPanel key={tab.value} value={tab.value} selectedTab={selectedTab} name={'profile'}>
            <Grid item className={classes.table}>
              {tab.Component}
            </Grid>
          </TabPanel>
        ))}
      </Grid>
    </Grid>
  );
};

export default UserProfile;
