import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import { useHistory, useLocation } from 'react-router';
import PrimaryButton from '../buttons/PrimaryButton';
import SplitButton from '../buttons/SplitButton';
import { locationWithBack } from '../../../utilities/route';

const TableImportToolbar = props => {
  const { addRoute, addAction, importAction, featureFlag, onlyImport } = props;
  const history = useHistory();
  const location = useLocation();

  const handleImportClick = () => {
    history.push(locationWithBack({ pathname: '/bulk-imports/new', search: `action=${importAction}` }, location));
  };

  if (onlyImport) {
    return <PrimaryButton label="Import" onClick={handleImportClick} icon Icon={AddIcon} testid="import-btn" />;
  }

  const handleNewClick = () => {
    if (addRoute) {
      const route = addRoute.split('?');
      const newLocation = {
        pathname: route[0],
      };
      if (route[1]) {
        newLocation.search = `?${route[1]}`;
      }
      const next = locationWithBack(newLocation, location);
      history.push(next);
    } else {
      addAction();
    }
  };
  const splitButtonOptions = [{ label: ` + Import `, action: () => handleImportClick() }];

  if (!featureFlag) {
    return <PrimaryButton label="New" onClick={handleNewClick} icon Icon={AddIcon} testid="new-cml-btn" />;
  }
  return (
    <SplitButton
      label="New"
      mainClick={handleNewClick}
      options={splitButtonOptions}
      icon
      Icon={AddIcon}
      color="primary"
    />
  );
};

TableImportToolbar.defaultProps = {
  addRoute: '',
  addAction: () => {},
  featureFlag: false,
  importAction: '',
  onlyImport: false,
};

TableImportToolbar.propTypes = {
  addRoute: PropTypes.string,
  addAction: PropTypes.func,
  featureFlag: PropTypes.bool,
  importAction: PropTypes.string,
  onlyImport: PropTypes.bool,
};

export default TableImportToolbar;
