import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Avatar, Divider, Grow, Paper, Popper, ClickAwayListener, MenuList, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import RefreshIcon from '@mui/icons-material/Refresh';

import { requestLogout } from '../../store/authActions';
import { useFeatureFlags } from '../../hooks/settingsHooks';
import { requestFeatures } from '../../store/settings/featuresActions';

const useStyles = makeStyles(theme => ({
  avatar: {
    height: theme.spacing(4.5),
    width: theme.spacing(4.5),
    margin: theme.spacing(0, 0.5),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  menuItem: {
    margin: theme.spacing(1, 0),
  },
  dividerMargin: {
    margin: theme.spacing(1, 0),
  },
  refreshIcon: {
    fontSize: '14px',
  },
}));

const AvatarMenu = props => {
  const { imageSrc } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const history = useHistory();
  const classes = useStyles();
  const { hasNotifyRuleManagement } = useFeatureFlags();

  const handleMenuClose = () => {
    setOpen(false);
  };
  const handleMenuToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };
  const handleListKeyDown = event => {
    setOpen(false);
  };
  const handleLogout = () => dispatch(requestLogout());
  const handleSyncSettings = () => dispatch(requestFeatures({ notify: true }));

  return (
    <>
      <Avatar
        className={classes.avatar}
        onClick={handleMenuToggle}
        src={imageSrc}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
      />
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
            <Paper>
              <ClickAwayListener onClickAway={handleMenuClose}>
                <MenuList id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => {
                      history.push('/profile');
                    }}>
                    Profile
                  </MenuItem>
                  {hasNotifyRuleManagement && (
                    <MenuItem
                      onClick={() => {
                        history.push('/notification-preferences');
                      }}>
                      Notification Preferences
                    </MenuItem>
                  )}
                  <Divider className={classes.dividerMargin} />
                  <MenuItem onClick={handleSyncSettings}>
                    Refresh Settings
                    <RefreshIcon className={classes.refreshIcon} />
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default AvatarMenu;

AvatarMenu.defaultProps = {
  imageSrc: '',
};

AvatarMenu.propTypes = {
  imageSrc: PropTypes.string,
};
