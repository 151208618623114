/**
 * fieldOrder
 *
 * An object where the keys define the sections of the form.   Each section requires the key display (string) and
 * fields (array of strings). Fields are the api keys (in snake case) for each field that will be displayed in the form.
 * Any fields that are never shown should not be added to this array.
 *
 * @return {object}
 */
export const fieldOrder = {
  info: {
    display: 'Info',
    fields: ['template.name', 'template.categories', 'asset', 'asset.owner', 'project', 'summary', 'flags'],
  },
  status: {
    display: 'Status',
    fields: ['created_by', 'work_done_on', 'created_on', 'updated_on', 'is_locked'],
  },
};

/**
 *  removeField
 *
 *  removes a field depending on if a user is creating, updating or viewing.
 *
 *  @param {string} state - enum for creating, updating or viewing ['CREATE', 'UPDATE', 'VIEW']
 *  @param {string} item - api key (in snake case) to check whether it should be hidden.
 *  @return {boolean} - returns true if the item will not be shown
 */
export const removeField = state => item => {
  let fields = [];
  switch (state) {
    case 'CREATE':
      fields = ['created_on', 'updated_on', 'flags'];
      break;
    case 'UPDATE':
      fields = ['created_on', 'updated_on', 'flags'];
      break;
    case 'VIEW':
      fields = [];
      break;
    default:
      return false;
  }
  return fields.includes(item);
};

/**
 * hideField
 *
 * This function is for hiding a field depending on the value of another field.
 *
 * @param {object} values - the values of all the fields in the form.
 * @param {string} item - api key (in snake case) to check whether it should be removed.
 * @return {boolean} - returns true if the item will not be shown
 */
export const hideField = (values, item, options = {}) => {
  const { hasChecklistLocking } = options;
  switch (item) {
    case 'is_locked':
      return !hasChecklistLocking;
    default:
      return false;
  }
};

export const tasksDone = values => {
  let taskComplete;
  for (const value of values) {
    if (taskComplete === undefined) {
      taskComplete = value.done;
    } else {
      taskComplete = value.done && taskComplete;
    }
  }
  return taskComplete;
};
