import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { jsonKeyToLabel, apiDateToString, dateToApiDateTime } from '../../utilities/strings';
import {
  getScheduleEvents,
  createScheduleEvents,
  updateScheduleEvents,
} from '../../store/features/scheduleEventsActions';
import CommonForm from '../shared/form/Common';
import { fieldOrder, removeField, hideField } from './scheduleEventsShared';

const ScheduleEventsForm = props => {
  const { update } = props;
  const params = useParams();
  const { data, loading, error, formError } = useSelector(state => state.scheduleEvents.each);
  const [submittedValues, setSubmittedValues] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (update && params.id) {
      dispatch(getScheduleEvents(params.id));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formSettings = {
    fieldOrder,
    removeField: removeField(update ? 'UPDATE' : 'CREATE'),
    hideField,
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      switch (key) {
        /* ---------- Required Fields ---------- */
        case 'start':
        case 'end':
          fieldSettings[key] = {
            type: 'datetime',
            fieldProps: {
              disabled: false,
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          break;

        /* ---------- Regular Fields ---------- */

        /* ----------  Disabled Fields ---------- */
        case 'created_on':
        case 'updated_on':
          fieldSettings[key] = {
            type: 'display',
            fieldProps: {
              disabled: true,
              label: jsonKeyToLabel(key),
              name: key,
              value: apiDateToString(data[key], 'date'),
            },
          };
          break;
        /* ----------  Default ---------- */
        default:
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
      }
    });
  }

  const title = update ? 'Edit Event' : 'New Event';

  let initialValues = {};
  if (update) {
    // The form cannot handle data coming in as objects.  (see ProjectsForm as an example)
    initialValues = { ...data };
  } else {
    // assign values from url queries here
    initialValues = {};
  }

  const onSubmit = changedValues => {
    setSubmittedValues(changedValues);
    const updatedValues = { ...changedValues };
    if ('start' in updatedValues) {
      updatedValues['start'] = dateToApiDateTime(updatedValues['start']);
    }
    if ('end' in updatedValues) {
      updatedValues['end'] = dateToApiDateTime(updatedValues['end']);
    }
    if (update) {
      dispatch(updateScheduleEvents(updatedValues.id, updatedValues));
    } else {
      dispatch(createScheduleEvents(updatedValues));
    }
  };

  /**
   * Decorators are used for setting the values of other fields based off of a field.
   * Decorator format can be found here: src/components/shared/form/common.js
   */
  const decorators = [];

  /**
   *  Validations that are per field.
   *  Errors associated with the form are passed back through formError in Redux.
   */
  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        if (values[key] === submittedValues[key]) {
          errors[key] = value;
        } else {
          errors[key] = undefined;
        }
      }
    }
    return errors;
  };

  return (
    <CommonForm
      update={update}
      title={title}
      initialValues={initialValues}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      onSubmit={onSubmit}
      decorators={decorators}
      validate={validate}
      loading={loading}
      error={error}
    />
  );
};

ScheduleEventsForm.defaultProps = {
  update: false,
};

ScheduleEventsForm.propTypes = {
  update: PropTypes.bool,
};

export default ScheduleEventsForm;
