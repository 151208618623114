export const SET_TABLE_QUERY = 'SET_TABLE_QUERY';
export const SET_TABLE_VIEW = 'SET_TABLE_VIEW';
export const SET_TABLE_SIZE = 'SET_TABLE_SIZE';

export const setTableQuery = (page, query, tableType) => {
  return {
    type: SET_TABLE_QUERY,
    page,
    query,
    tableType,
  };
};

export const setTableView = (column, action, page, tableType) => {
  return {
    type: SET_TABLE_VIEW,
    column,
    action,
    page,
    tableType,
  };
};

export const setTableSize = (size, page, tableType) => {
  return {
    type: SET_TABLE_SIZE,
    size,
    page,
    tableType,
  };
};
