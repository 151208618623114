// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import { all, put, takeLatest } from 'redux-saga/effects';

import {
  CHECKLIST_REVISIONS_GETTER_REQUEST,
  CHECKLIST_REVISIONS_GETTER_SUCCESS,
  CHECKLIST_REVISIONS_GETTER_FAILURE,
} from './revisionsActions';

import { fetchChecklistRevisions } from '../../api/getters/revisions.js';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';

export function* revisionsFetch(action) {
  const { checklistId, query } = action;
  try {
    const { data, error, response } = yield fetchChecklistRevisions(checklistId, query);
    if (data) {
      yield put({ type: CHECKLIST_REVISIONS_GETTER_SUCCESS, data });
    } else if (error) {
      yield put({ type: CHECKLIST_REVISIONS_GETTER_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, CHECKLIST_REVISIONS_GETTER_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, CHECKLIST_REVISIONS_GETTER_FAILURE);
  }
}

export function* watchRevisionsFetch() {
  yield takeLatest(CHECKLIST_REVISIONS_GETTER_REQUEST, revisionsFetch);
}

export default function* revisionsSaga() {
  yield all([watchRevisionsFetch()]);
}
