import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';

import AvatarMenu from './AvatarMenu';

/**
 * Component for the header on certain layouts
 */

const Header = props => {
  const { title, menu, back } = props;
  const logo = useSelector(state => state.settings.logo.src);
  const history = useHistory();
  const firebaseUser = JSON.parse(window.localStorage.getItem('firebaseui::rememberedAccounts'));

  const handleBack = () => {
    if (back) {
      history.push(back);
    } else {
      history.push('/');
    }
  };
  const showBack = back;
  let headerImg;
  if (showBack === '') {
    headerImg = <img src={logo} height="32" onClick={handleBack} />;
  } else {
    headerImg = <ArrowBackIos onClick={handleBack} />;
  }
  return (
    <AppBar className="app-bar" elevation={1}>
      <Toolbar>
        {headerImg}
        {title && (
          <Typography variant="h6" noWrap style={{ flex: 1 }}>
            {title}
          </Typography>
        )}
        <Typography variant="h6" style={{ flexGrow: 1 }}></Typography>
        {menu && { menu }}
        <AvatarMenu imageSrc={firebaseUser ? firebaseUser[0]['photoUrl'] : undefined} />
      </Toolbar>
    </AppBar>
  );
};

Header.defaultProps = {
  title: null,
  menu: null,
  back: null,
};

Header.propTypes = {
  title: PropTypes.node,
  menu: PropTypes.node,
  back: PropTypes.node,
};

export default Header;
