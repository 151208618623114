import { buildEndpoint, get, patch, post, del } from './util';

/**
 * Backwards compatibility - combining Read, Create, Update, and Delete to share state
 */
export const EACH_REDUCER = 'reportTypesEach';

/**
 * https://docs.huvrdata.app/reference/api_report-types_list
 */
export const reportTypesListAllEndpoint = buildEndpoint(
  'reportTypesListAll',
  query => get('/api/report-types/', query),
  {
    batchFetchAll: true,
  }
);

/**
 * https://docs.huvrdata.app/reference/api_report-types_create
 */
export const reportTypesCreateEndpoint = buildEndpoint('reportTypesCreate', data => post('/api/report-types/', data), {
  reducerKey: EACH_REDUCER,
});

/**
 * https://docs.huvrdata.app/reference/api_report-types_templates
 */
export const reportTypesTemplatesEndpoint = buildEndpoint(
  'reportTypesTemplates',
  () => get('/api/report-types/templates/'),
  { defaultData: [] }
);

/**
 * https://docs.huvrdata.app/reference/api_report-types_read
 */
export const reportTypesReadEndpoint = buildEndpoint('reportTypesRead', id => get(`/api/report-types/${id}/`), {
  reducerKey: EACH_REDUCER,
});

/**
 * https://docs.huvrdata.app/reference/api_report-types_partial_update
 */
export const reportTypesPartialUpdateEndpoint = buildEndpoint(
  'reportTypesPartialUpdate',
  (id, data) => patch(`/api/report-types/${id}/`, data),
  { reducerKey: EACH_REDUCER }
);

/**
 * https://docs.huvrdata.app/reference/api_report-types_delete
 */
export const reportTypesDeleteEndpoint = buildEndpoint('reportTypesDelete', id => del(`/api/report-types/${id}/`), {
  reducerKey: EACH_REDUCER,
});
