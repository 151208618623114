import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AssetsView from './AssetsView';
import AssetMap from './AssetMap';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 3, 4, 3),
  },
  title: {
    padding: theme.spacing(0, 0, 1, 1),
  },
}));

const AssetOverview = props => {
  const { id, data, loading, error } = props;
  const classes = useStyles();
  const hasMap = Boolean(data.geo_point);
  return (
    <Grid container direction="row" alignItems="stretch" className={classes.root}>
      <Grid item xs={hasMap ? 8 : 12}>
        <AssetsView id={id} data={data} loading={loading} error={error} />
      </Grid>
      {hasMap && (
        <Grid item xs={4}>
          <AssetMap id={id} location={data.geo_point} />
        </Grid>
      )}
    </Grid>
  );
};

AssetOverview.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
};

export default AssetOverview;
