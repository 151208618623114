/**
 * fieldOrder
 *
 * An object where the keys define the sections of the form.   Each section requires the key display (string) and
 * fields (array of strings). Fields are the api keys (in snake case) for each field that will be displayed in the form.
 * Any fields that are never shown should not be added to this array.
 *
 * @return {object}
 */
export const fieldOrder = {
  info: {
    display: 'Info',
    fields: ['name', 'description', 'version', 'categories'],
  },
  location: {
    display: 'Location Configuration',
    fields: [
      'geo_mode',
      'guide',
      'component_mode',
      'component_options',
      'component_help',
      'component_validators',
      'zone_mode',
      'zone_help',
      'zone_validators',
      'zone_options',
      'code_mode',
      'code_help',
      'code_validators',
      'code_options',
      'access_mode',
      'access_help',
      'access_validators',
      'access_options',
    ],
  },
  status: {
    display: 'Status',
    fields: ['state', 'created_on', 'updated_on'],
  },
};

/**
 *  removeField
 *
 *  removes a field depending on if a user is creating, updating or viewing.
 *
 *  @param {string} state - enum for creating, updating or viewing ['CREATE', 'UPDATE', 'VIEW']
 *  @param {string} item - api key (in snake case) to check whether it should be hidden.
 *  @return {boolean} - returns true if the item will not be shown
 */
export const removeField = state => item => {
  let fields = [];
  switch (state) {
    case 'CREATE':
      fields = ['created_on', 'updated_on'];
      break;
    case 'UPDATE':
      fields = [];
      break;
    case 'VIEW':
      fields = [];
      break;
    default:
      return false;
  }
  return fields.includes(item);
};

/**
 * hideField
 *
 * This function is for hiding a field depending on the value of another field.
 *
 * @param {object} values - the values of all the fields in the form.
 * @param {string} item - api key (in snake case) to check whether it should be removed.
 * @return {boolean} - returns true if the item will not be shown
 */
export const hideField = (values, item, hasAccessDisplay) => {
  switch (item) {
    case 'component_options':
    case 'component_validators':
    case 'component_help':
      if (values.component_mode === 'OFF') {
        return true;
      }
      if (item === 'component_options') {
        if (values.component_mode === 'LIST') {
          return false;
        }
        return true;
      }
      break;
    case 'zone_options':
    case 'zone_validators':
    case 'zone_help':
      if (values.zone_mode === 'OFF') {
        return true;
      }
      if (item === 'zone_options') {
        if (values.zone_mode === 'LIST') {
          return false;
        }
        return true;
      }
      break;
    case 'code_options':
    case 'code_validators':
    case 'code_help':
      if (values.code_mode === 'OFF') {
        return true;
      }
      if (item === 'code_options') {
        if (values.code_mode === 'LIST') {
          return false;
        }
        return true;
      }
      break;
    case 'access_mode':
      return !hasAccessDisplay;
    case 'access_options':
    case 'access_validators':
    case 'access_help':
      if (!hasAccessDisplay || values.access_mode === 'OFF') {
        return true;
      }
      if (item === 'access_options') {
        if (values.access_mode === 'LIST') {
          return false;
        }
        return true;
      }
      break;
    default:
      return false;
  }
};
