// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import React from 'react';
import CommonRoutes from './utilities/CommonRoutes';
import AssetsForm from '../../components/assets/AssetsForm';
import AssetDetail from '../../components/assets/AssetDetail';
import AssetsTablePage from '../../components/assets/AssetsTablePage';
import MainLayout from '../layouts/MainLayout';

const AssetsRoutes = props => {
  return (
    <CommonRoutes
      pageTitle="Asset"
      layout={MainLayout}
      Rename={<AssetsForm rename />}
      Edit={<AssetsForm update />}
      Create={<AssetsForm />}
      View={<AssetDetail />}
      All={<AssetsTablePage />}
    />
  );
};

export default AssetsRoutes;
