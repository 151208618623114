
import {
  requestFactory,
  responseHandlerForm,
} from '../base';

const get = requestFactory('get');
const post = requestFactory('post', { handler: responseHandlerForm });
const patch = requestFactory('patch', { handler: responseHandlerForm });
const del = requestFactory('del');

export const fetchCrewsList = async query => get('/api/crews/', query);
export const fetchCrews = async id => get(`/api/crews/${id}/`);
export const createCrews = async body => post('/api/crews/', body);
export const updateCrews = async (id, body) => patch(`/api/crews/${id}/`, body);
export const deleteCrews = async id => del(`/api/crews/${id}/`);

