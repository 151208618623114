import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import PrimaryButton from '../shared/buttons/PrimaryButton';
import SelectFindingsDialog from '../projects/SelectFindingsDialog';
import ScopeSelectionDialog from './CmlsDialog';
import { ProjectScopeContext } from '../projects/projectScope-context';
import { getAllCmls } from '../../store/features/cmlsActions';
import { getAllDefects } from '../../store/features/defectsActions';

const ScopeToolbar = ({ type }) => {
  const dispatch = useDispatch();
  const { setOpenScopeDialog, setOpenFindingsDialog, openScopeDialog, openFindingsDialog } =
    useContext(ProjectScopeContext);

  // fetch data on open
  const handleOpen = () => {
    if (type === 'CML') {
      if (!openScopeDialog) {
        dispatch(getAllCmls());
      }
      setOpenScopeDialog(true);
    }

    if (type === 'FINDINGS') {
      if (!openFindingsDialog) {
        dispatch(getAllDefects());
      }
      setOpenFindingsDialog(true);
    }
  };

  const renderDialog = () => {
    if (type === 'CML') {
      return <ScopeSelectionDialog />;
    }
    return <SelectFindingsDialog />;
  };

  return (
    <>
      <PrimaryButton label="Add" onClick={handleOpen} icon Icon={AddIcon} testid="add-scope-btn" />
      {renderDialog()}
    </>
  );
};

ScopeToolbar.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ScopeToolbar;
