import { useSelector } from 'react-redux';

export function useMediaStore() {
  const {
    query,
    loading,
    dataAll: { count, next, previous, results },
  } = useSelector(state => state.inspectionMedia.all);
  const disableNext = !next;
  const disablePrev = !previous;
  return {
    disableNext,
    disablePrev,
    next,
    previous,
    query,
    loading,
    total: count,
    media: results,
  };
}
