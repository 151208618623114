// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import { all, put, takeLatest } from 'redux-saga/effects';

import { USERS_GETTER_REQUEST, USERS_GETTER_SUCCESS, USERS_GETTER_FAILURE } from './usersGetterActions';

import { fetchUsers } from '../../api/getters/users.js';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';

export function* usersFetch(action) {
  const { query } = action;
  try {
    const { data, error, response } = yield fetchUsers(query);
    if (data) {
      yield put({ type: USERS_GETTER_SUCCESS, data });
    } else if (error) {
      yield put({ type: USERS_GETTER_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, USERS_GETTER_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, USERS_GETTER_FAILURE);
  }
}

export function* watchUsersFetch() {
  yield takeLatest(USERS_GETTER_REQUEST, usersFetch);
}

export default function* usersSaga() {
  yield all([watchUsersFetch()]);
}
