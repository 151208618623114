import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { jsonKeyToLabel, queriesFromString } from '../../utilities/strings';
import { createUsersBulk } from '../../store/features/usersActions';
import { getUserProfile } from '../../store/getters/profileActions';
import { getAllCompanies } from '../../store/features/companiesActions';
import CommonForm from '../shared/form/Common';
import { usePermissions, useFeatureFlags } from '../../hooks/settingsHooks';
import { fieldOrder, removeField, hideField, FIELDS } from './bulkUsersShared';
import { roleOptions } from './usersShared';
import { useCompanies } from '../../hooks/optionsHooks';
import { COMPANY_ROLES, ROLES } from '../../api/features/constants';

const BulkUsersForm = props => {
  const { update } = props;
  const location = useLocation();
  const { hasUserManageAll, hasUserRolesAll } = usePermissions();
  const { hasAssetAssessments } = useFeatureFlags();
  const dispatch = useDispatch();

  const [company, setCompany] = useState({ role: '' });
  const [dirty, setDirty] = useState();
  const [submittedValues, setSubmittedValues] = useState({});

  const companyOptions = useCompanies(true);

  const { data, loading, error, formError, editorCompany, userIsSubcontractor, userIsAdmin } = useSelector(state => {
    const { data, loading, error, formError } = state.users.bulk;
    const {
      data: { roles: editorRoles, company: editorCompany },
    } = state.profile;

    const userIsSubcontractor = editorRoles.includes(FIELDS.SUBCONTRACTOR) && !hasUserRolesAll;
    const userIsAdmin = editorRoles.includes(FIELDS.ADMIN) && hasUserRolesAll;
    return {
      data,
      loading: loading || state.companies.all.loading || state.profile.loading,
      error,
      formError,
      editorCompany,
      userIsSubcontractor,
      userIsAdmin,
    };
  });

  /**
   *  Gets current user profile to set editorCompany and editorRoles permissions
   */

  useEffect(() => {
    dispatch(getUserProfile());
    if (location.search) {
      const queries = queriesFromString(location.search);
      if (queries.company) {
        setCompany({ value: queries.company });
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Gets all companies to filter and show which roles should show / available
   */

  useEffect(() => {
    if (!userIsSubcontractor) {
      dispatch(getAllCompanies());
    }
  }, [dispatch, userIsSubcontractor]);

  const isSubcontractor =
    company.role === COMPANY_ROLES.SUBCONTRACTOR || (update && data.roles?.includes(ROLES.subcontractor));
  const roleOpts = roleOptions(isSubcontractor, { hasUserRolesAll, hasAssetAssessments });

  const updateCompany = e => {
    setDirty(true);
    const foundCo = companyOptions.find(co => co.value === e.target.value);
    if (foundCo) {
      setCompany(foundCo);
    }
  };

  const formSettings = {
    fieldOrder,
    removeField: removeField(update ? 'UPDATE' : 'CREATE', hasUserManageAll),
    hideField,
  };

  const bulkUsersEmail = `"Doe, John C" <dean.c.amato@example.com>,"Goodwin, Kurt M" <kurt.m.goodwin@example.com>,"Williams,Joseph E" <joseph.e.williams@example.com>`;

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      switch (key) {
        /* ---------- Required Fields ---------- */
        // Update this with a new fieldedit component that is text block and functionality can be added later
        case FIELDS.ADDRESS_LIST:
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: 'Comma Separated Emails',
              name: key,
              multiline: true,
              rows: 3,
              maxRows: 10,
              placeholder: bulkUsersEmail,
            },
          };
          break;
        /* ---------- Regular Fields ---------- */
        case FIELDS.ROLES:
          if (!userIsSubcontractor) {
            fieldSettings[key] = {
              type: 'multi',
              fieldProps: {
                label: jsonKeyToLabel(key),
                name: key,
                required: true,
                options: roleOpts,
                labelwidth: 80,
              },
            };
          }
          break;
        case FIELDS.COMPANY:
          if (!userIsSubcontractor) {
            fieldSettings[key] = {
              type: 'select',
              fieldProps: {
                label: jsonKeyToLabel(key),
                name: key,
                required: false,
                options: companyOptions,
                labelwidth: 60,
                onChange: updateCompany,
              },
            };
          }
          break;
        case FIELDS.IS_ACTIVE:
          fieldSettings[key] = {
            type: 'checkbox',
            cellProps: {
              xs: 12,
            },
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          break;
        case FIELDS.IS_ADMIN:
          if (!userIsAdmin) {
            fieldSettings[key] = {
              type: 'checkbox',
              fieldProps: {
                label: jsonKeyToLabel(key),
                name: key,
              },
            };
          }
          break;
        case FIELDS.SEND_WELCOME_EMAIL:
          fieldSettings[key] = {
            type: 'checkbox',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          break;
        /* ----------  Disabled Fields ---------- */
        /* ----------  Default ---------- */
        default:
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
      }
    });
  }

  const title = update ? 'Edit User' : 'New User';

  let initialValues = { roles: [], send_welcome_email: true };
  if (update) {
    initialValues = {
      ...data,
      roles: data.roles || [],
      company: data.company && data.company[0] ? data.company[0].id : '',
    };
  }

  const onSubmit = changedValues => {
    const updatedValues = { ...changedValues };
    setSubmittedValues(updatedValues);
    if (changedValues.company) {
      updatedValues.company_id = parseInt(updatedValues.company);
      delete updatedValues.company;
    }
    if (userIsSubcontractor) {
      dispatch(createUsersBulk({ ...updatedValues, company_id: editorCompany[0].id, roles: [FIELDS.SUBCONTRACTOR] }));
    } else {
      dispatch(createUsersBulk(updatedValues));
    }
  };

  /**
   * Decorators are used for setting the values of other fields based off of a field.
   * Decorator format can be found here: src/components/shared/form/common.js
   */
  const decorators = [];

  /**
   *  Validations that are per field.
   *  Errors associated with the form are passed back through formError in Redux.
   */
  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        if (values[key] === submittedValues[key]) {
          errors[key] = value;
        } else {
          errors[key] = undefined;
        }
      }
    }
    return errors;
  };

  return (
    <Box
      sx={{
        marginTop: '30px',
        paddingTop: '30px',
      }}>
      <CommonForm
        update={update}
        title={title}
        initialValues={initialValues}
        fieldSettings={fieldSettings}
        formSettings={formSettings}
        onSubmit={onSubmit}
        decorators={decorators}
        validate={validate}
        loading={loading}
        error={error}
        dirty={dirty}
        keepDirtyOnReinitialize
      />
    </Box>
  );
};

BulkUsersForm.defaultProps = {
  update: false,
};

BulkUsersForm.propTypes = {
  update: PropTypes.bool,
};

export default BulkUsersForm;
