import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import CrewsTable from './CrewsTable';
import { getAllCrews } from '../../store/features/crewsActions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const CrewsTablePage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(getAllCrews());
  }, []); // eslint-disable-line

  return (
    <div className={classes.root}>
      <CrewsTable title="Crews" />
    </div>
  );
};

export default CrewsTablePage;
