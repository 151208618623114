import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Form } from 'react-final-form';
import PrimaryButton from '../../components/shared/buttons/PrimaryButton';
import SecondaryButton from '../../components/shared/buttons/SecondaryButton';
import { closeDialog } from '../../store/dialogActions';
import FieldEditString from '../shared/form/FieldEditString';
import FieldEditUserAutocomplete from '../shared/form/FieldEditUserAutocomplete';
import { updateShareProject } from '../../store/features/projectsActions';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    height: '100%',
    textAlign: 'left',
  },
  form: {
    margin: theme.spacing(1),
    height: '90%',
  },
  buttonGroup: {
    marginTop: theme.spacing(2),
  },
}));

const ShareProjectForm = props => {
  const { id } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { formError } = useSelector(state => state.projects.each);
  const handleCloseDialog = () => {
    dispatch(closeDialog());
  };

  const handleDialogSubmit = values => {
    values.watch = true;
    dispatch(updateShareProject(id, values));
  };

  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        errors[key] = value;
      }
    }
    return errors;
  };
  const queryParams = { is_active: true, ordering: 'name' };
  const textFieldProps = { placeholder: 'user@example.com' };

  return (
    <Form
      onSubmit={handleDialogSubmit}
      validate={validate}
      initialValues={{}}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Grid container direction="column" justifyContent="space-between" className={classes.formControl}>
              <Grid item>
                <Grid container spacing={2} direction="column">
                  <Grid item>
                    <FieldEditUserAutocomplete
                      label={'Share with'}
                      name={'user_id'}
                      labelwidth={40}
                      initialUsers={[]}
                      queryParams={queryParams}
                      helperText={'Search by name or email to select recipient.'}
                      textFieldProps={textFieldProps}
                    />
                  </Grid>
                  <Grid item>
                    <FieldEditString label="Notes" multiline name="note" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.buttonGroup}>
                <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
                  <Grid item xs={2}>
                    <SecondaryButton label="Cancel" onClick={handleCloseDialog} />
                  </Grid>
                  <Grid item xs={2}>
                    <PrimaryButton type="submit" label="Submit" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};

ShareProjectForm.propTypes = {
  id: PropTypes.number.isRequired,
};

export default ShareProjectForm;
