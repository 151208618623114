import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Alert, ButtonGroup } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import EditIcon from '@mui/icons-material/Edit';
import UploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Description from '../shared/table/Description';
import Table, { SELECTABLE_ROWS_NONE } from '../shared/table/Table';
import { deleteChecklistTemplates, exportChecklistTemplates } from '../../store/features/checklistTemplatesActions';
import ButtonIcon from '../shared/buttons/ButtonIcon';
import StyledLink from '../shared/StyledLink';
import { setColumns } from '../shared/table/columns';
import ViewDisplayChips from '../shared/form/ViewDisplayChips';
import { openDialog } from '../../store/dialogActions';
import DeleteDialog from '../shared/Dialog/DeleteDialog';
import { usePermissions } from '../../hooks/settingsHooks';
import { compareTemplate } from './checklistTemplatesShared';
import { tableViews } from '../../utilities/tables';
import { setTableSize, setTableView } from '../../store/settings/tableActions';
import { DOCUMENT_STATES } from '../../api/features/constants';
import MultiSelectFilter from '../shared/table/MultiSelectFilter';
import useDateRangeColumnHook from '../../hooks/table/dateRangeColumnHook';
// You can not use this with the current implementation of the table it causes infinite loop
// https://github.com/huvrdata/huvr/issues/10063
// import { useCachedChecklistTemplates } from '../../hooks/checklistTemplatesHooks';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const ChecklistTemplatesTable = ({ title, compareTo, embedded, queryParamObj, page, views, tableChangeHandler }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // const { categoriesOptions } = useCachedChecklistTemplates(queryParamObj);
  const { loading, data, deleteError, count } = useSelector(state => {
    const loading = state.checklistTemplates.all.loading;
    const data = state.checklistTemplates.all.dataAll.results;
    const count = state.checklistTemplates.all.dataAll.count;
    const deleteError = state.checklistTemplates.each.error;

    return {
      loading,
      data,
      count,
      deleteError,
    };
  });

  const { checklistTemplatesTableViewKey } = tableViews;
  const { hasConfigurationManagementBuild } = usePermissions();

  const queryFilters = queryParamObj;

  const templateStates = Object.values(DOCUMENT_STATES);
  const queryStateFilters = queryFilters?.state ? [].concat(queryFilters.state.split(',')) : [];
  const [stateFilters, setStateFilters] = useState(queryStateFilters);

  // TODO: fix or remove categoriesOptions
  const templateCategories = [];
  const queryCategoryFilters = queryFilters?.categories ? [].concat(queryFilters.categories.split(',')) : [];
  const [categoryFilters, setCategoryFilters] = useState(queryCategoryFilters);

  const queryTypeFilters = queryFilters?.type ? [].concat(queryFilters.type.split(',')) : [];

  /** @type {Column[]} */
  const columns = [
    {
      // This needs to be first and excluded so customBodyRender can access the id to create the link.
      name: 'id',
      label: 'Id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        filterType: 'textField',
        customBodyRender: (value, tableMeta) => {
          return (
            <StyledLink to={`/checklist-templates/${tableMeta.rowData[columns.findIndexByName['id']]}`} value={value} />
          );
        },
      },
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => {
          if (!value) return <></>;
          return <Description value={value} />;
        },
      },
    },
    {
      name: 'version',
      label: 'Version',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'categories',
      label: 'Categories',
      options: {
        filter: false,
        sort: true,
        filterType: 'custom',
        filterList: categoryFilters,
        customFilterListOptions: {
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            setCategoryFilters(filterList[index]);
            return filterList;
          },
        },
        filterOptions: {
          names: templateCategories,
          logic: (state, filters) => {
            if (filters.length) return !filters.includes(state);
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            return (
              <MultiSelectFilter
                title="Categories"
                filterList={filterList}
                localFilterList={categoryFilters}
                onChange={onChange}
                index={index}
                column={column}
                filterData={filterData}
                updateFilters={setCategoryFilters}
              />
            );
          },
        },
        customBodyRender: value => <ViewDisplayChips value={value} />,
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        filter: true,
        sort: true,
        filterList: queryTypeFilters,
      },
    },
    {
      name: 'state',
      label: 'State',
      options: {
        filter: true,
        sort: true,
        filterType: 'custom',
        filterList: stateFilters,
        customFilterListOptions: {
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            setStateFilters(filterList[index]);
            return filterList;
          },
        },
        filterOptions: {
          names: templateStates,
          logic: (state, filters) => {
            if (filters.length) return !filters.includes(state);
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            return (
              <MultiSelectFilter
                title="State"
                filterList={filterList}
                localFilterList={stateFilters}
                onChange={onChange}
                index={index}
                column={column}
                filterData={filterData}
                updateFilters={setStateFilters}
              />
            );
          },
        },
      },
    },
    {
      name: 'schema_version',
      label: 'Schema Version',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'checklist_count',
      label: 'Checklist Count',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'project_type_count',
      label: 'Project Type Count',
      options: {
        filter: false,
        sort: false,
      },
    },
    useDateRangeColumnHook({
      name: 'created_on',
      label: 'Created On',
      queryParamObj,
      queryStartKey: 'created_on_after',
      queryEndKey: 'created_on_before',
    }),
    useDateRangeColumnHook({
      name: 'updated_on',
      label: 'Updated On',
      queryParamObj,
      queryStartKey: 'updated_on_after',
      queryEndKey: 'updated_on_before',
    }),
    {
      name: 'template_keys',
      label: 'Template Keys',
      options: {
        filter: false,
        display: 'excluded',
        download: false,
        print: false,
        viewColumns: false,
      },
    },
    {
      name: 'similarity',
      label: 'Similarity',
      options: {
        display: compareTo === null ? 'excluded' : true,
        filter: false,
        sort: false,
        empty: true,
        download: false,
        print: false,
        viewColumns: false,
        customBodyRender: (_, info) => {
          // 0 is the first element in column
          if (compareTo) {
            return <>{compareTemplate(compareTo, info.rowData[columns.findIndexByName['template_keys']])}</>;
          }
          return null;
        },
      },
    },
    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        print: false,
        viewColumns: false,
        customBodyRender: (_, info) => {
          // 0 is the first element in column
          const id = info.rowData[columns.findIndexByName['id']];
          const name = info.rowData[columns.findIndexByName['name']];
          const state = info.rowData[columns.findIndexByName['state']];
          const showPreview = state === 'ACTIVE' || state === 'DRAFT';

          return (
            <ButtonGroup>
              <>
                {/* need double-nested <></> for strange styling purposes */}
                {hasConfigurationManagementBuild && (
                  <>
                    <ButtonIcon
                      icon={DeleteIcon}
                      onClick={() => {
                        dispatch(
                          openDialog(
                            'Delete Checklist Template?',
                            <DeleteDialog id={id} deleteAction={deleteChecklistTemplates} name={name} />
                          )
                        );
                      }}
                      tooltip="Delete checklist template"
                    />
                    <ButtonIcon
                      history={history}
                      icon={EditIcon}
                      location={location}
                      to={`/checklist-templates/${id}/edit`}
                      tooltip="Edit checklist template meta data"
                    />
                    {state !== 'ACTIVE' && state !== 'ARCHIVED' ? (
                      <ButtonIcon
                        history={history}
                        icon={UploadIcon}
                        location={location}
                        to={`/checklist-templates/${id}/edit`}
                        search="?upload=true"
                        tooltip="Upload new version of checklist template"
                      />
                    ) : (
                      <ButtonIcon icon={UploadIcon} disabled tooltip="Template is not editable in current state" />
                    )}
                    <ButtonIcon
                      history={history}
                      icon={VisibilityIcon}
                      location={location}
                      disabled={!showPreview}
                      to={`/checklist-preview/${id}`}
                      tooltip="Preview checklist template"
                    />
                  </>
                )}
                <ButtonIcon
                  icon={CloudDownloadIcon}
                  onClick={() => {
                    dispatch(exportChecklistTemplates(id, name));
                  }}
                  tooltip="Export checklist template to XLSX"
                />
              </>
            </ButtonGroup>
          );
        },
      },
    },
  ];

  // handle columns display
  setColumns(columns, views);

  /** @type {TableOptions} */
  const options = {
    // if the column view changes, update redux with either 'add' or 'remove'
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setTableView(changedColumn, action, page, checklistTemplatesTableViewKey));
    },
    onChangeRowsPerPage: numberOfRows => {
      dispatch(setTableSize(numberOfRows, page, checklistTemplatesTableViewKey));
    },
    enableNestedDataAccess: '.',
    selectableRows: SELECTABLE_ROWS_NONE,
  };

  return (
    <>
      {deleteError && (
        <Alert
          severity="error"
          sx={{
            marginTop: '1.5rem',
            display: 'inline-flex',
            width: '100%',
            color: 'red',
          }}>
          {deleteError}
        </Alert>
      )}
      <Table
        title={title}
        serverSide
        columns={columns}
        addRoute={'/checklist-templates/new'}
        data={data}
        options={options}
        loading={loading}
        queryParamObj={queryParamObj}
        tableChangeHandler={tableChangeHandler}
        embedded={embedded}
        count={count}
        views={views}
      />
    </>
  );
};

ChecklistTemplatesTable.defaultProps = {
  title: 'Checklist Templates',
  compareTo: null,
  embedded: false,
};

ChecklistTemplatesTable.propTypes = {
  title: PropTypes.string,
  compareTo: PropTypes.object,
  embedded: PropTypes.bool,
  page: PropTypes.string,
  views: PropTypes.object,
  queryParamObj: PropTypes.object,
  tableChangeHandler: PropTypes.func,
};

export default ChecklistTemplatesTable;
