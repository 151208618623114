// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import { combineReducers } from 'redux';
import {
  PROJECTS_REQUEST,
  PROJECTS_SUCCESS,
  PROJECTS_FAILURE,
  PROJECTS_CREATE_REQUEST,
  PROJECTS_CREATE_SUCCESS,
  PROJECTS_CREATE_FAILURE,
  PROJECTS_UPDATE_REQUEST,
  PROJECTS_UPDATE_SUCCESS,
  PROJECTS_UPDATE_FAILURE,
  PROJECT_STATUS_UPDATE_REQUEST,
  PROJECT_STATUS_UPDATE_SUCCESS,
  PROJECT_STATUS_UPDATE_FAILURE,
  PROJECT_STATUS_BULK_UPDATE_REQUEST,
  PROJECT_STATUS_BULK_UPDATE_SUCCESS,
  PROJECT_STATUS_BULK_UPDATE_FAILURE,
  ASSET_CONDITION_UPDATE_REQUEST,
  ASSET_CONDITION_UPDATE_SUCCESS,
  ASSET_CONDITION_UPDATE_FAILURE,
  PROJECT_SHARE_UPDATE_REQUEST,
  PROJECT_SHARE_UPDATE_SUCCESS,
  PROJECT_SHARE_UPDATE_FAILURE,
  PROJECTS_DELETE_REQUEST,
  PROJECTS_DELETE_SUCCESS,
  PROJECTS_DELETE_FAILURE,
  PROJECTS_BULK_UPDATE_REQUEST,
  PROJECTS_BULK_UPDATE_SUCCESS,
  PROJECTS_BULK_UPDATE_FAILURE,
  PROJECTS_BULK_DELETE_REQUEST,
  PROJECTS_BULK_DELETE_SUCCESS,
  PROJECTS_BULK_DELETE_FAILURE,
  PROJECTS_ALL_REQUEST,
  PROJECTS_ALL_SUCCESS,
  PROJECTS_ALL_FAILURE,
  PROJECTS_COUNTS_BY_STATUS_REQUEST,
  PROJECTS_COUNTS_BY_STATUS_SUCCESS,
  PROJECTS_COUNTS_BY_STATUS_FAILURE,
} from './projectsActions';

import { mergeNewItemsIntoExisting } from '../../utilities/reducerHelpers';

export const initialStateProjects = {
  loading: false,
  error: '',
  formError: {},
  id: 0,
  body: {},
  data: {},
};

export const initialStateAllProjects = {
  loading: false,
  error: '',
  query: {},
  dataAll: { count: 0, next: null, previous: null, results: [] },
};

export const initialStateProjectsCountsByStatus = {
  loading: false,
  error: '',
  query: {},
  data: {
    assigned: 0,
    in_progress: 0,
    initialized: 0,
    published: 0,
    ready_for_review: 0,
    total: 0,
  },
};

export const projectsReducer = (state = initialStateProjects, action) => {
  switch (action.type) {
    case PROJECTS_REQUEST:
      return {
        ...initialStateProjects,
        loading: true,
        id: action.id,
      };
    case PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case PROJECTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case PROJECTS_CREATE_REQUEST:
      return {
        ...initialStateProjects,
        loading: true,
        body: action.body,
      };
    case PROJECTS_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case PROJECTS_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case PROJECTS_UPDATE_REQUEST:
      return {
        ...state,
        error: '',
        formError: {},
        loading: true,
        id: action.id,
        body: action.body,
      };
    case PROJECTS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case PROJECTS_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case PROJECT_STATUS_UPDATE_REQUEST:
      return {
        ...state,
        error: '',
        formError: {},
        loading: true,
        id: action.id,
        body: action.body,
      };
    case PROJECT_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          status: action.data.status,
        },
      };
    case PROJECT_STATUS_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case ASSET_CONDITION_UPDATE_REQUEST:
      return {
        ...state,
        error: '',
        formError: {},
        loading: true,
        id: action.id,
        body: action.body,
      };
    case ASSET_CONDITION_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ASSET_CONDITION_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case PROJECT_SHARE_UPDATE_REQUEST:
      return {
        ...state,
        error: '',
        formError: {},
        id: action.id,
        body: action.body,
      };
    case PROJECT_SHARE_UPDATE_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case PROJECT_SHARE_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case PROJECTS_DELETE_REQUEST:
      return {
        ...state,
        error: '',
        loading: true,
        id: action.id,
      };
    case PROJECTS_DELETE_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
      };
    case PROJECTS_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const projectsAllReducer = (state = initialStateAllProjects, action) => {
  switch (action.type) {
    case PROJECTS_ALL_REQUEST:
      return {
        ...initialStateAllProjects,
        query: { ...action.query },
        loading: true,
      };
    case PROJECTS_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        dataAll: action.data,
      };
    case PROJECTS_ALL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case PROJECTS_BULK_UPDATE_REQUEST:
      return {
        ...state,
        error: '',
        loading: true,
        ids: action.ids,
      };
    case PROJECTS_BULK_UPDATE_SUCCESS:
      return {
        ...state,
        dataAll: {
          ...state.dataAll,
          results: mergeNewItemsIntoExisting(state.dataAll.results, action.data),
        },
        error: '',
        loading: false,
      };
    case PROJECTS_BULK_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case PROJECT_STATUS_BULK_UPDATE_REQUEST:
      return {
        ...state,
        error: '',
        loading: true,
        ids: action.ids,
      };
    case PROJECT_STATUS_BULK_UPDATE_SUCCESS:
      return {
        ...state,
        dataAll: {
          ...state.dataAll,
          results: mergeNewItemsIntoExisting(state.dataAll.results, action.data, 'project'),
        },
        error: '',
        loading: false,
      };
    case PROJECT_STATUS_BULK_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case PROJECTS_BULK_DELETE_REQUEST:
      return {
        ...state,
        error: '',
        loading: true,
        ids: action.ids,
      };
    case PROJECTS_BULK_DELETE_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
      };
    case PROJECTS_BULK_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const projectsCountsByStatusReducer = (state = initialStateProjectsCountsByStatus, action) => {
  switch (action.type) {
    case PROJECTS_COUNTS_BY_STATUS_REQUEST:
      return {
        ...initialStateProjectsCountsByStatus,
        query: { ...action.query },
        loading: true,
      };
    case PROJECTS_COUNTS_BY_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case PROJECTS_COUNTS_BY_STATUS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export default combineReducers({
  each: projectsReducer,
  all: projectsAllReducer,
  countsByStatus: projectsCountsByStatusReducer,
});
