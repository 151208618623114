import palette from './palette';

const typography = darkMode => {
  return {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    subtitle1: {
      color: palette(darkMode).text.primary,
      fontSize: '16px',
      letterSpacing: '-0.05px',
      lineHeight: '25px',
    },
    subtitle2: {
      color: palette(darkMode).text.primary,
      fontWeight: 400,
      fontSize: '14px',
      letterSpacing: '-0.05px',
      lineHeight: '21px',
    },
    body1: {
      color: palette(darkMode).text.primary,
      fontSize: '14px',
      letterSpacing: '-0.05px',
      lineHeight: '21px',
    },
    body2: {
      color: palette(darkMode).text.primary,
      fontSize: '12px',
      letterSpacing: '-0.04px',
      lineHeight: '18px',
    },
    caption: {
      color: palette(darkMode).text.primary,
      fontSize: '11px',
      letterSpacing: '0.33px',
      lineHeight: '13px',
    },
    overline: {
      color: palette(darkMode).text.primary,
      fontSize: '11px',
      fontWeight: 500,
      letterSpacing: '0.33px',
      lineHeight: '13px',
      textTransform: 'uppercase',
    },
  };
};

export default typography;
