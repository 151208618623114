import { requestFactory, responseHandlerForm } from '../base';

const get = requestFactory('get');
const post = requestFactory('post', { handler: responseHandlerForm });
const patch = requestFactory('patch', { handler: responseHandlerForm });
const del = requestFactory('del');

export const fetchLibrariesList = async query => get('/api/libraries/', query);
export const fetchLibraries = async id => get(`/api/libraries/${id}/`);
export const createLibraries = async body => post('/api/libraries/', body);
export const updateLibraries = async (id, body) => patch(`/api/libraries/${id}/`, body);
export const deleteLibraries = async id => del(`/api/libraries/${id}/`);
