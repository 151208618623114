import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

const TabPanel = props => {
  const { children, value, selectedTab, name } = props;

  return (
    <Typography
      component="div"
      hidden={value !== selectedTab}
      role="tabpanel"
      id={`${name}-tabpanel-${value}`}
      aria-labelledby={`${name}-tab-${value}`}>
      {value === selectedTab && <Box>{children}</Box>}
    </Typography>
  );
};

TabPanel.defaultProps = {
  selectedTab: '',
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  selectedTab: PropTypes.string,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default TabPanel;
