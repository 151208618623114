import { all, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  LOCATION_LAYER_PROFILES_REQUEST,
  LOCATION_LAYER_PROFILES_SUCCESS,
  LOCATION_LAYER_PROFILES_FAILURE,
  LOCATION_LAYER_PROFILES_CREATE_REQUEST,
  LOCATION_LAYER_PROFILES_CREATE_SUCCESS,
  LOCATION_LAYER_PROFILES_CREATE_FAILURE,
  LOCATION_LAYER_PROFILES_UPDATE_REQUEST,
  LOCATION_LAYER_PROFILES_UPDATE_SUCCESS,
  LOCATION_LAYER_PROFILES_UPDATE_FAILURE,
  LOCATION_LAYER_PROFILES_DELETE_REQUEST,
  LOCATION_LAYER_PROFILES_DELETE_SUCCESS,
  LOCATION_LAYER_PROFILES_DELETE_FAILURE,
  LOCATION_LAYER_PROFILES_ALL_REQUEST,
  LOCATION_LAYER_PROFILES_ALL_SUCCESS,
  LOCATION_LAYER_PROFILES_ALL_FAILURE,
} from './locationLayerProfilesActions';

import {
  fetchLocationLayerProfiles,
  createLocationLayerProfiles,
  updateLocationLayerProfiles,
  deleteLocationLayerProfiles,
  fetchLocationLayerProfilesList,
} from '../../api/features/locationLayerProfiles';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';
import { fetchListAll } from '../../utilities/sagaHelpers';
import { DIALOG_CLOSE } from '../../store/dialogActions';

export function* locationLayerProfilesFetch(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield fetchLocationLayerProfiles(id);
    if (data) {
      yield put({ type: LOCATION_LAYER_PROFILES_SUCCESS, data });
    } else if (error) {
      yield put({ type: LOCATION_LAYER_PROFILES_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, LOCATION_LAYER_PROFILES_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, LOCATION_LAYER_PROFILES_FAILURE);
  }
}

export function* watchLocationLayerProfilesFetch() {
  yield takeLatest(LOCATION_LAYER_PROFILES_REQUEST, locationLayerProfilesFetch);
}

export function* locationLayerProfilesCreate(action) {
  const { body } = action;
  try {
    const { data, error, formError, response } = yield createLocationLayerProfiles(body);
    if (data) {
      yield put({ type: LOCATION_LAYER_PROFILES_CREATE_SUCCESS, data });
      yield put(push(`/location-layer-profiles/${data.id}`));
    } else if (error) {
      yield put({ type: LOCATION_LAYER_PROFILES_CREATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: LOCATION_LAYER_PROFILES_CREATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, LOCATION_LAYER_PROFILES_CREATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, LOCATION_LAYER_PROFILES_CREATE_FAILURE);
  }
}

export function* watchLocationLayerProfilesCreate() {
  yield takeLatest(LOCATION_LAYER_PROFILES_CREATE_REQUEST, locationLayerProfilesCreate);
}

export function* locationLayerProfilesUpdate(action) {
  const { id, body } = action;
  try {
    const { data, error, formError, response } = yield updateLocationLayerProfiles(id, body);
    if (data) {
      yield put({ type: LOCATION_LAYER_PROFILES_UPDATE_SUCCESS, data });
      yield put(push('/location-layer-profiles'));
    } else if (error) {
      yield put({ type: LOCATION_LAYER_PROFILES_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: LOCATION_LAYER_PROFILES_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, LOCATION_LAYER_PROFILES_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, LOCATION_LAYER_PROFILES_UPDATE_FAILURE);
  }
}

export function* watchLocationLayerProfilesUpdate() {
  yield takeLatest(LOCATION_LAYER_PROFILES_UPDATE_REQUEST, locationLayerProfilesUpdate);
}

export function* locationLayerProfilesDelete(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield deleteLocationLayerProfiles(id);
    if (data) {
      yield put({ type: LOCATION_LAYER_PROFILES_DELETE_SUCCESS });
      yield put({ type: LOCATION_LAYER_PROFILES_ALL_REQUEST });
      yield put({ type: DIALOG_CLOSE });
      yield put(push('/location-layer-profiles'));
    } else if (error) {
      yield put({ type: LOCATION_LAYER_PROFILES_DELETE_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, LOCATION_LAYER_PROFILES_DELETE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, LOCATION_LAYER_PROFILES_DELETE_FAILURE);
  }
}

export function* watchLocationLayerProfilesDelete() {
  yield takeLatest(LOCATION_LAYER_PROFILES_DELETE_REQUEST, locationLayerProfilesDelete);
}

export function* locationLayerProfilesFetchAll(action) {
  try {
    const { data, error, response } = yield fetchListAll(fetchLocationLayerProfilesList, action.query);
    if (data) {
      yield put({ type: LOCATION_LAYER_PROFILES_ALL_SUCCESS, data });
    } else if (error) {
      yield put({ type: LOCATION_LAYER_PROFILES_ALL_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, LOCATION_LAYER_PROFILES_ALL_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, LOCATION_LAYER_PROFILES_ALL_FAILURE);
  }
}

export function* watchLocationLayerProfilesFetchAll() {
  yield takeLatest(LOCATION_LAYER_PROFILES_ALL_REQUEST, locationLayerProfilesFetchAll);
}

export default function* locationLayerProfilesSaga() {
  yield all([
    watchLocationLayerProfilesFetch(),
    watchLocationLayerProfilesCreate(),
    watchLocationLayerProfilesUpdate(),
    watchLocationLayerProfilesFetchAll(),
    watchLocationLayerProfilesDelete(),
  ]);
}
