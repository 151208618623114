export const SCHEDULE_TASKS_REQUEST = 'SCHEDULE_TASKS_REQUEST';
export const SCHEDULE_TASKS_SUCCESS = 'SCHEDULE_TASKS_SUCCESS';
export const SCHEDULE_TASKS_FAILURE = 'SCHEDULE_TASKS_FAILURE';
export const SCHEDULE_TASKS_CREATE_REQUEST = 'SCHEDULE_TASKS_CREATE_REQUEST';
export const SCHEDULE_TASKS_CREATE_SUCCESS = 'SCHEDULE_TASKS_CREATE_SUCCESS';
export const SCHEDULE_TASKS_CREATE_FAILURE = 'SCHEDULE_TASKS_CREATE_FAILURE';
export const SCHEDULE_TASKS_UPDATE_REQUEST = 'SCHEDULE_TASKS_UPDATE_REQUEST';
export const SCHEDULE_TASKS_UPDATE_SUCCESS = 'SCHEDULE_TASKS_UPDATE_SUCCESS';
export const SCHEDULE_TASKS_UPDATE_FAILURE = 'SCHEDULE_TASKS_UPDATE_FAILURE';
export const SCHEDULE_TASKS_DELETE_REQUEST = 'SCHEDULE_TASKS_DELETE_REQUEST';
export const SCHEDULE_TASKS_DELETE_SUCCESS = 'SCHEDULE_TASKS_DELETE_SUCCESS';
export const SCHEDULE_TASKS_DELETE_FAILURE = 'SCHEDULE_TASKS_DELETE_FAILURE';
export const SCHEDULE_TASKS_ALL_REQUEST = 'SCHEDULE_TASKS_ALL_REQUEST';
export const SCHEDULE_TASKS_ALL_SUCCESS = 'SCHEDULE_TASKS_ALL_SUCCESS';
export const SCHEDULE_TASKS_ALL_FAILURE = 'SCHEDULE_TASKS_ALL_FAILURE';

export const getScheduleTasks = id => {
  return {
    type: SCHEDULE_TASKS_REQUEST,
    id,
  };
};

export const createScheduleTasks = body => {
  return {
    type: SCHEDULE_TASKS_CREATE_REQUEST,
    body,
  };
};

export const updateScheduleTasks = (id, body) => {
  return {
    type: SCHEDULE_TASKS_UPDATE_REQUEST,
    id,
    body,
  };
};

export const deleteScheduleTasks = id => {
  return {
    type: SCHEDULE_TASKS_DELETE_REQUEST,
    id,
  };
};

export const getAllScheduleTasks = query => {
  return {
    type: SCHEDULE_TASKS_ALL_REQUEST,
    query,
  };
};
