// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import {
  OBSERVATIONS_GETTER_REQUEST,
  OBSERVATIONS_GETTER_SUCCESS,
  OBSERVATIONS_GETTER_FAILURE,
} from './observationsActions';

export const initialStateObservations = {
  loading: false,
  error: '',
  data: {},
  query: {},
};

export const observationsReducer = (state = initialStateObservations, action) => {
  switch (action.type) {
    case OBSERVATIONS_GETTER_REQUEST:
      return {
        ...state,
        loading: true,
        query: action.query,
      };
    case OBSERVATIONS_GETTER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case OBSERVATIONS_GETTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        data: {},
        query: {},
      };
    default:
      return state;
  }
};

export default observationsReducer;
