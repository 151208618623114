import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { getLeadFilters } from '../../../store/getters/leadFiltersActions';
import Filters from '../FilterBarAssets';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0 3% 3% 3%',
  },
  title: {
    padding: theme.spacing(1, 0, 0, 3),
    fontSize: theme.spacing(5),
    textAlign: 'left',
  },
}));

const Lead = () => {
  const { leadFilters, count } = useSelector(state => {
    const { data } = state.leadFilters;
    return {
      leadFilters: data,
      count: data.count,
    };
  });
  const dispatch = useDispatch();

  const [selectedOwners, updateOwners] = useState([]);
  const [selectedAssetTypes, updateAssetTypes] = useState([]);
  const [selectedParent, updateParent] = useState([]);
  const [parentListFiltered, updateParentListFiltered] = useState(leadFilters.parent);

  useEffect(() => {
    dispatch(getLeadFilters());
  }, []); // eslint-disable-line

  useEffect(() => {
    // set initial values for parentListFiltered
    updateParentListFiltered(leadFilters.parent.filter(filter => filter.name));
  }, [leadFilters]);

  const filterParent = owners => {
    let filteredParent = leadFilters.parent.filter(filter => filter.name);

    if (owners.length !== 0) {
      filteredParent = filteredParent.filter(parent => {
        return owners.some(owner => owner.name === parent.owner_name);
      });

      // check that a parent that was already selected is valid.
      const newParent = selectedParent.filter(parent => filteredParent.indexOf(parent) > -1);
      updateParent(newParent);
    }
    updateParentListFiltered(filteredParent);
  };

  const filtersInfo = {
    owner: {
      selected: selectedOwners,
      options: leadFilters.owner.filter(filter => filter.name),
      update: value => {
        updateOwners(value);
        // updateDisableApplyButton(false);
        filterParent(value);
      },
    },
    asset_type: {
      selected: selectedAssetTypes,
      options: leadFilters.asset_type.filter(filter => filter.name),
      update: value => {
        updateAssetTypes(value);
        // updateDisableApplyButton(false);
      },
    },
    parent: {
      selected: selectedParent,
      options: parentListFiltered,
      update: value => {
        updateParent(value);
        // updateDisableApplyButton(false);
      },
    },
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper>
        <p className={`h7 ${classes.title}`}>LEAD Analytics</p>
        <Filters totalCount={count} filters={leadFilters} filtersInfo={filtersInfo} />
      </Paper>
    </div>
  );
};

export default Lead;
