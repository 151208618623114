import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

import Markdown from '../Markdown';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    textAlign: 'left',
  },
}));

const MarkdownPage = props => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Markdown>{props.data.content}</Markdown>
    </div>
  );
};

MarkdownPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MarkdownPage;
