import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const useStyles = makeStyles(() => ({
  center: {
    '& canvas': {
      margin: 'auto',
    },
  },
}));

const PDFViewer = props => {
  const { fileUrl } = props;
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const classes = useStyles();

  return (
    <div className={classes.pdfViewer}>
      <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess} className={classes.center}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
    </div>
  );
};

PDFViewer.propTypes = {
  fileUrl: PropTypes.string.isRequired,
};

export default PDFViewer;
