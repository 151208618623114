// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
export const OBSERVATIONS_GETTER_REQUEST = 'OBSERVATIONS_GETTER_REQUEST';
export const OBSERVATIONS_GETTER_SUCCESS = 'OBSERVATIONS_GETTER_SUCCESS';
export const OBSERVATIONS_GETTER_FAILURE = 'OBSERVATIONS_GETTER_FAILURE';

export const getObservations = (query = {}) => {
  return {
    type: OBSERVATIONS_GETTER_REQUEST,
    query,
  };
};
