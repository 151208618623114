import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ButtonGroup } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Table, { SELECTABLE_ROWS_NONE } from '../shared/table/Table';
import Description from '../shared/table/Description';
import ViewDisplayKeyValue from '../shared/form/ViewDisplayKeyValue';
import { getAllCompanies, deleteCompanies } from '../../store/features/companiesActions';
import { setCompaniesTableView, setCompaniesTableSize } from '../../store/settings/views/companiesTableViewRedux';
import { apiDateToString } from '../../utilities/strings';
import StyledLink from '../shared/StyledLink';
import { openDialog } from '../../store/dialogActions';
import { setColumns } from '../shared/table/columns';
import ButtonIcon from '../shared/buttons/ButtonIcon';
import DeleteDialog from '../shared/Dialog/DeleteDialog';
import { roleOptions } from './companiesShared';
import { usePermissions, useTableViews } from '../../hooks/settingsHooks';
import { getCustomCSVData, tableViews } from '../../utilities/tables';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const Companies = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { loading, data } = useSelector(state => ({
    loading: state.companies.all.loading,
    data: state.companies.all.dataAll.results,
  }));
  const { companiesTableViewKey } = tableViews;
  const views = useTableViews(companiesTableViewKey);
  useEffect(() => {
    dispatch(getAllCompanies());
  }, []); // eslint-disable-line

  const { hasCompanyCreate, hasCompanyEdit, hasCompanyDelete } = usePermissions();

  const classes = useStyles();

  /** @type {Column[]} */
  const columns = [
    {
      // This needs to be first and excluded so customBodyRender can access the id to create the link.
      name: 'id',
      label: 'Id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        filterType: 'textField',
        customBodyRender: (value, tableMeta) => {
          return <StyledLink to={`/companies/${tableMeta.rowData[columns.findIndexByName['id']]}`} value={value} />;
        },
      },
    },
    {
      name: 'role',
      label: 'Role',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => {
          let rolesLabel = '';
          roleOptions.forEach(roles => {
            if (roles.value === value) {
              rolesLabel = roles.label;
            }
          });
          return rolesLabel;
        },
      },
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        filter: true,
        sort: true,
        filterType: 'textField',
        customBodyRender: value => {
          if (!value) return <></>;
          return <Description value={value} />;
        },
      },
    },
    {
      name: 'is_active',
      label: 'Is Active',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => (value ? 'True' : 'False'),
      },
    },
    {
      name: 'labels',
      label: 'Labels',
      options: {
        filter: true,
        sort: true,
        downloadBody: values => getCustomCSVData('labels', values),
        customBodyRender: value => {
          return <ViewDisplayKeyValue value={value} />;
        },
      },
    },
    {
      name: 'updated_on',
      label: 'Updated',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => apiDateToString(value, 'date'),
      },
    },
    {
      name: 'created_on',
      label: 'Created',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => apiDateToString(value, 'date'),
      },
    },
    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        print: false,
        viewColumns: false,
        customBodyRender: (_, info) => {
          // 0 is the first element in column
          const id = info.rowData[columns.findIndexByName['id']];
          const name = info.rowData[columns.findIndexByName['name']];
          return (
            <>
              <ButtonGroup>
                {hasCompanyEdit && (
                  <ButtonIcon history={history} icon={EditIcon} location={location} to={`/companies/${id}/edit`} />
                )}
                {hasCompanyDelete && (
                  <ButtonIcon
                    icon={DeleteIcon}
                    onClick={() => {
                      dispatch(
                        openDialog(
                          'Delete Company?',
                          <DeleteDialog id={id} deleteAction={deleteCompanies} name={name} />
                        )
                      );
                    }}
                  />
                )}
              </ButtonGroup>
            </>
          );
        },
      },
    },
  ];

  // handle columns display
  setColumns(columns, views);

  /** @type {TableOptions} */
  const options = {
    // if the column view changes, update redux with either 'add' or 'remove'
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setCompaniesTableView(changedColumn, action));
    },
    onChangeRowsPerPage: numberOfRows => {
      dispatch(setCompaniesTableSize(numberOfRows));
    },
    enableNestedDataAccess: '.',
    rowsPerPage: views.pageSize,
    selectableRows: SELECTABLE_ROWS_NONE,
  };

  return (
    <div className={classes.root}>
      <Table
        title="Companies"
        simpleSearch
        columns={columns}
        addRoute={hasCompanyCreate && '/companies/new'}
        data={data}
        options={options}
        loading={loading}
      />
    </div>
  );
};

export default Companies;
