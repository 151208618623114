import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { Grid, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import Bookmark from './Bookmark';
import BookmarkForm from './BookmarkForm';
import StyledLink from '../StyledLink';
import ButtonIcon from '../buttons/ButtonIcon';
import { bookmarksListAllEndpoint } from '../../../store/apiV2/bookmarks';
import { openDialog } from '../../../store/dialogActions';
import { usePermissions } from '../../../hooks/settingsHooks';

const useStyles = makeStyles(theme => ({
  bookmark_name: {
    marginLeft: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  add_bookmark: {
    cursor: 'pointer',
  },
  add_bookmark_text: {
    fontSize: '14px',
  },
  bookmark_link: {
    padding: theme.spacing(1, 0, 0, 0),
    '&:visited': {
      color: 'white',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  bookmark_button: {
    padding: theme.spacing(1, 0, 0, 0),
    position: 'relative',
    top: '-5px',
  },
}));

const ASSET = 'asset';
const PROJECT = 'project';
const getContentType = ({ pathname }) => (pathname.startsWith('/assets') ? ASSET : PROJECT);

const Bookmarks = ({ id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  const bookmarksListAll = bookmarksListAllEndpoint.useEndpoint();
  const { results } = bookmarksListAll.data;
  const bookmarks = Array.isArray(results) ? results : [];

  const { hasProjectEdit, hasAssetEdit } = usePermissions();

  const contentType = getContentType(location);
  const showAddBookmark = (contentType === ASSET && hasAssetEdit) || (contentType === PROJECT && hasProjectEdit);

  useEffect(() => {
    bookmarksListAll.dispatchRequest({ content_type: contentType, object_id: id });
  }, [id, contentType]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleNewBookmark = () =>
    dispatch(openDialog('New Bookmark', <BookmarkForm id={id} contentType={contentType} />));

  // mismatched id passed in and data in store
  const hasOldData = bookmarks.some(b => String(b.object_id) !== String(id));

  if (hasOldData) {
    // prevent flash of old data
    return <Grid container item direction="row" spacing={4} className={classes.container}></Grid>;
  }

  const bookmarkList = bookmarks.map((b, i) => {
    return (
      <Bookmark
        key={i}
        className={classes.bookmark_link}
        bookmark={b}
        contentType={contentType}
        enableMenu={showAddBookmark}
      />
    );
  });

  return (
    <Grid container item direction="row" spacing={4}>
      {bookmarkList}
      {showAddBookmark && (
        <Grid item>
          <span className={classes.bookmark_button}>
            {!bookmarks.length ? (
              <StyledLink onClick={handleNewBookmark}>
                <Typography variant="h6" color="orangered">
                  <span className={classes.add_bookmark_text}>+ Add Bookmark </span>
                </Typography>
              </StyledLink>
            ) : (
              <ButtonIcon
                iconSize="small"
                icon={Add}
                tooltip={'Add Bookmark'}
                color="primary"
                onClick={handleNewBookmark}
              />
            )}
          </span>
        </Grid>
      )}
    </Grid>
  );
};

Bookmarks.propTypes = {
  id: PropTypes.number.isRequired,
};

export default Bookmarks;
