import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'react-final-form-arrays';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
// import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, ImageList, ImageListItem, ImageListItemBar, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { noThumbnail } from '../../../utilities/files';
import SlideshowViewer from '../../shared/slideshowViewer/SlideshowViewer';
import { getAllInspectionMedia } from '../../../store/features/inspectionMediaActions';
import ChecklistGallery2 from './ChecklistGallery2';
import FileUploaderDialog from './FileUploaderDialog';
import variables from '../../../config.module.scss';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'stretch',
    overflow: 'hidden',
  },
  removeIcon: {
    color: variables.backgroundLight,
  },
}));

const MediaLineItem = props => {
  const { name, showGallery, setShowGallery, showUploader, setShowUploader, containerType, containerId } = props;

  const classes = useStyles();

  const [showSlides, setShowSlides] = useState(false);
  const [startingId, setStartingId] = useState(null);
  const [mediaOnLine, setMediaOnLine] = useState([]);

  const handleImageClick = (index, fields) => {
    // Pull the values so the slideshowViewer can access
    setMediaOnLine([...fields.value]);
    setStartingId(fields.value[index]['id']);
    setShowSlides(true);
  };

  const renderGallery = fields => {
    return (
      <ChecklistGallery2
        pageHandler={getAllInspectionMedia}
        fields={fields}
        open={showGallery}
        setOpen={setShowGallery}
        galleryType={'checklist'}
        containerId={containerId}
        containerType={containerType}
      />
    );
  };

  const renderUploader = fields => {
    return (
      <FileUploaderDialog
        fields={fields}
        open={showUploader}
        setOpen={setShowUploader}
        containerId={containerId}
        containerType={containerType}
        checklistLineName={name}
      />
    );
  };

  const renderImageList = fields => {
    return (
      <ImageList cols={3}>
        {fields.map((_, index) => {
          const { thumbnail, name, description } = fields.value[index];
          return (
            <ImageListItem key={index}>
              <img src={thumbnail || noThumbnail('image')} onClick={() => handleImageClick(index, fields)} />
              <ImageListItemBar
                title={description || name}
                actionIcon={
                  <>
                    <IconButton onClick={() => fields.remove(index)} className={classes.removeIcon} size="large">
                      <Tooltip title="Remove attachment. Media is not deleted. ">
                        <RemoveCircleIcon />
                      </Tooltip>
                    </IconButton>
                  </>
                }
              />
            </ImageListItem>
          );
        })}
      </ImageList>
    );
  };

  return (
    <FieldArray name={name}>
      {({ fields }) => (
        <div className={classes.paper}>
          {renderGallery(fields)}
          {renderUploader(fields)}
          {renderImageList(fields)}
          {showSlides && (
            <SlideshowViewer
              mediaArray={mediaOnLine}
              showSlides={showSlides}
              setShowSlides={setShowSlides}
              startingId={startingId}
              project={{}}
              total={mediaOnLine.length}
            />
          )}
        </div>
      )}
    </FieldArray>
  );
};

MediaLineItem.defaultProps = {};

MediaLineItem.propTypes = {
  name: PropTypes.string.isRequired,
  showGallery: PropTypes.bool.isRequired,
  setShowGallery: PropTypes.func.isRequired,
  showUploader: PropTypes.bool.isRequired,
  setShowUploader: PropTypes.func.isRequired,
  containerId: PropTypes.string.isRequired,
  containerType: PropTypes.string.isRequired,
};

export default MediaLineItem;
