import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// lib
import makeStyles from '@mui/styles/makeStyles';

// util / store
import { useFileUploader } from '../../providers/FileUploaderProvider';
import { createInspectionMedia } from '../../api/features/inspectionMedia';
import { getAllInspectionMedia } from '../../store/features/inspectionMediaActions';

const useStyles = makeStyles(theme => ({
  dragNDropWrapper: {
    marginBottom: theme.spacing(2),
  },
}));

/**
 *
 */
const ProjectFileUploader = props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { projectId, checklistId } = props;
  const { uploadOptionsRef, renderDragNDrop } = useFileUploader();

  /**
   * @name useEffect-setUploadOptions
   */
  useEffect(() => {
    uploadOptionsRef.current = {
      /**
       * @name getUploadParameters
       * @param {Object} file
       * @returns {Promise<{ method, url, fields, headers }>}
       * @description create a signed url for uploading to GCP bucket
       *   creates a record in the database for the file
       *
       * See:
       *  https://uppy.io/docs/aws-s3-multipart/#getuploadparametersfile-options
       */
      getUploadParameters: file => {
        // Send a request to our signing endpoint.
        const payload = {
          filename: file.name,
          media_type: file.type,
          project: projectId,
          checklist: checklistId,
        };

        // if uploaded in folder structure, send that along
        if (file.meta.relativePath) {
          payload.file_meta = { relative_path: file.meta.relativePath };
        }

        return createInspectionMedia(payload).then(resp => {
          // Return an object in the correct shape.
          // save the database id, to update after upload

          file.remoteId = resp.data.id;
          return {
            method: 'PUT',
            url: resp.data.upload.url,
            fields: [],
            headers: resp.data.upload.headers,
          };
        });
      },

      /**
       * @name onUploadSuccess
       * @description callback to run after all files have been uploaded
       *    update the inspection media list
       */
      onUploadSuccess: () => {
        dispatch(
          getAllInspectionMedia({
            project: projectId,
            checklist: checklistId,
            hidden: false,
            ordering: 'name',
          })
        );
      },
    };

    return () => {
      // on unmount unset uploadOptions
      uploadOptionsRef.current = {};
    };
  }, [dispatch, uploadOptionsRef, projectId, checklistId]);

  return <div className={classes.dragNDropWrapper}>{renderDragNDrop({ note: 'Add media to the project' })}</div>;
};

ProjectFileUploader.defaultProps = {};

ProjectFileUploader.propTypes = {
  projectId: PropTypes.number,
  checklistId: PropTypes.number,
  //  One or the other not both
};

export default ProjectFileUploader;
