import { requestFactory, responseHandlerForm } from '../base';

const get = requestFactory('get');
const post = requestFactory('post', { handler: responseHandlerForm });
const patch = requestFactory('patch', { handler: responseHandlerForm });
const del = requestFactory('del');

export const fetchScheduleEventsList = async query => get('/api/schedule/events/', query);
export const fetchScheduleEvents = async id => get(`/api/schedule/events/${id}/`);
export const createScheduleEvents = async body => post('/api/schedule/events/', body);
export const updateScheduleEvents = async (id, body) => patch(`/api/schedule/events/${id}/`, body);
export const deleteScheduleEvents = async id => del(`/api/schedule/events/${id}/`);
