import React from 'react';
import PropTypes from 'prop-types';
import { eventTypeOptions } from '../../api/features/constants';
import { fieldOrder, removeField, hideField } from './notificationRulesShared';
import { buildRoute, apiDateToString, jsonKeyToLabel, displayOption } from '../../utilities/strings';
import CommonFormView from '../shared/form/CommonView';

const NotificationRulesView = props => {
  const { data, loading, error } = props;

  const formSettings = {
    fieldOrder,
    removeField: removeField('VIEW'),
    hideField,
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      // cast to string because booleans do not show up in the field
      let displayLabel = jsonKeyToLabel(key); // eslint-disable-line prefer-const
      let displayValue = '';
      if (data[key] != null) {
        switch (key) {
          case 'event_type':
            displayValue = displayOption(data[key], eventTypeOptions);
            break;
          case 'created_by':
            displayValue = data[key].name;
            break;
          // add cases for each key that needs to be customized.
          case 'updated_on':
          case 'created_on':
            displayValue = apiDateToString(data[key], 'date');
            break;
          default:
            displayValue = data[key].toString();
        }
      }
      fieldSettings[key] = {
        type: 'text',
        fieldProps: {
          label: displayLabel,
          value: displayValue,
        },
      };
    });
  }

  // TODO: Replace feature name with singular version.
  return (
    <CommonFormView
      title={'Notification Rule Details'}
      bodyOnly
      values={data}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      editLink={buildRoute(`/notification-rules/${data.id}/edit`)}
      loading={loading}
      error={error}
    />
  );
};

NotificationRulesView.defaultProps = {
  data: {},
  error: '',
  loading: false,
};

NotificationRulesView.propTypes = {
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.string,
};

export default NotificationRulesView;
