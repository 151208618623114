import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { reportTypesReadEndpoint, reportTypesTemplatesEndpoint } from '../../store/apiV2/reportTypes';
import { apiDateToString, jsonKeyToLabel, displayOption, capitalizeFirstChar } from '../../utilities/strings';
import { fieldOrder, removeField, hideField, getTemplateName, getPaperSize } from './reportTypesShared';
import {
  reportModes,
  titleModes,
  coverModes,
  measurementModes,
  densityOptions,
  layoutOptions,
  checklistMediaWidthOptions,
} from '../../api/features/constants';
import { useFeatureFlags } from '../../hooks/settingsHooks';
import CommonFormView from '../shared/form/CommonView';

const ReportType = () => {
  const id = useParams().id;

  const reportTypesRead = reportTypesReadEndpoint.useEndpoint();
  const reportTypesTemplates = reportTypesTemplatesEndpoint.useEndpoint();
  const { data, loading, error } = reportTypesRead;
  const { data: templates } = reportTypesTemplates;

  useEffect(() => {
    reportTypesTemplates.dispatchRequest();
    reportTypesRead.dispatchRequest(id);
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const { hasCustomThemes } = useFeatureFlags();
  const formSettings = {
    fieldOrder,
    removeField: removeField('VIEW'),
    hideField: (values, item) => {
      return hideField(values, item, hasCustomThemes);
    },
  };

  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      let displayLabel = jsonKeyToLabel(key); // eslint-disable-line  prefer-const
      let displayValue = '';
      if (data[key] != null) {
        switch (key) {
          case 'updated_on':
          case 'created_on':
            displayValue = apiDateToString(data[key], 'date');
            break;
          case 'mode':
            displayValue = displayOption(data[key], reportModes);
            break;
          case 'title_mode':
            displayValue = displayOption(data[key], titleModes);
            break;
          case 'measurement_mode':
            displayValue = displayOption(data[key], measurementModes);
            break;
          case 'density':
            displayValue = displayOption(data[key], densityOptions);
            break;
          case 'checklist_media_width':
            displayValue = displayOption(data[key], checklistMediaWidthOptions);
            break;
          case 'layout':
            displayValue = displayOption(data[key], layoutOptions);
            break;
          case 'cover_mode':
            displayValue = displayOption(data[key], coverModes);
            break;
          case 'appendices':
            displayValue = JSON.stringify(data[key]);
            break;
          case 'trim_work_instructions':
          case 'is_active':
            displayValue = capitalizeFirstChar(String(data[key]));
            break;
          default:
            displayValue = data[key].toString();
        }
      }
      // template_name is not returned form the api so it's not in data
      if (key === 'template_name') {
        displayValue = getTemplateName(data, templates);
      }
      if (key.includes('print_options')) {
        const option = key.replace('print_options.', '');
        displayLabel = jsonKeyToLabel(option);
        displayValue = data.print_options ? data.print_options[option]?.toString() : '';
      }
      if (key === 'print_options.paper_size') {
        if (data.print_options?.paper_height) {
          displayValue = getPaperSize(data.print_options.paper_height);
        }
      }
      fieldSettings[key] = {
        type: 'text',
        fieldProps: {
          label: displayLabel,
          value: displayValue,
        },
      };
    });
  }

  return (
    <CommonFormView
      title="Report Type Details"
      values={data}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      editLink={`/report-types/${id}/edit`}
      loading={loading}
      error={error}
    />
  );
};

export default ReportType;
