export const ASSETS_REQUEST = 'ASSETS_REQUEST';
export const ASSETS_SUCCESS = 'ASSETS_SUCCESS';
export const ASSETS_FAILURE = 'ASSETS_FAILURE';
export const ASSETS_CREATE_REQUEST = 'ASSETS_CREATE_REQUEST';
export const ASSETS_CREATE_SUCCESS = 'ASSETS_CREATE_SUCCESS';
export const ASSETS_CREATE_FAILURE = 'ASSETS_CREATE_FAILURE';
export const ASSETS_UPDATE_REQUEST = 'ASSETS_UPDATE_REQUEST';
export const ASSETS_UPDATE_SUCCESS = 'ASSETS_UPDATE_SUCCESS';
export const ASSETS_UPDATE_FAILURE = 'ASSETS_UPDATE_FAILURE';
export const ASSETS_DELETE_REQUEST = 'ASSETS_DELETE_REQUEST';
export const ASSETS_DELETE_SUCCESS = 'ASSETS_DELETE_SUCCESS';
export const ASSETS_DELETE_FAILURE = 'ASSETS_DELETE_FAILURE';
export const ASSETS_ALL_REQUEST = 'ASSETS_ALL_REQUEST';
export const ASSETS_ALL_SUCCESS = 'ASSETS_ALL_SUCCESS';
export const ASSETS_ALL_FAILURE = 'ASSETS_ALL_FAILURE';

// a "ASSETS_BATCH_ALL_*" request is made up of many _individual_ "ASSETS_BATCH_*" requests
//  currently they are used only for AssetCondition Map and have a specific, lighter serializer
//  should another use-case for batch arise, will need to re-visit
export const ASSETS_BATCH_SUCCESS = 'ASSETS_BATCH_SUCCESS';
export const ASSETS_BATCH_FAILURE = 'ASSETS_BATCH_FAILURE';
export const ASSETS_BATCH_ALL_REQUEST = 'ASSETS_BATCH_ALL_REQUEST';
export const ASSETS_BATCH_ALL_SUCCESS = 'ASSETS_BATCH_ALL_SUCCESS';
export const ASSETS_ABORT_BATCH_ALL = 'ASSETS_ABORT_BATCH_ALL';

export const getAssets = id => {
  return {
    type: ASSETS_REQUEST,
    id,
  };
};

export const createAssets = (body, nextRoute) => {
  return {
    type: ASSETS_CREATE_REQUEST,
    body,
    nextRoute,
  };
};

export const updateAssets = (id, body, nextRoute) => {
  return {
    type: ASSETS_UPDATE_REQUEST,
    id,
    body,
    nextRoute,
  };
};

export const deleteAssets = id => {
  return {
    type: ASSETS_DELETE_REQUEST,
    id,
  };
};

export const getAllAssets = query => {
  return {
    type: ASSETS_ALL_REQUEST,
    query,
  };
};

// used for asset condition map, has specific serializer response
export const getBatchAllAssets = query => {
  return {
    type: ASSETS_BATCH_ALL_REQUEST,
    query,
  };
};
