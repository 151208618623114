import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getScheduleEvents } from '../../store/features/scheduleEventsActions';
import { fieldOrder, removeField, hideField } from './scheduleEventsShared';
import { apiDateToString, jsonKeyToLabel } from '../../utilities/strings';
import CommonFormView from '../shared/form/CommonView';
import { AssetLink, PlanLink } from '../shared/links/InternalLinks';

const ScheduleEventsView = props => {
  const { loading } = props;
  const id = useParams().id;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getScheduleEvents(id));
  }, [dispatch, id]);

  const { data, error } = useSelector(state => state.scheduleEvents.each);

  const formSettings = {
    fieldOrder,
    removeField: removeField('VIEW'),
    hideField,
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      // cast to string because booleans do not show up in the field
      let displayLabel = jsonKeyToLabel(key); // eslint-disable-line prefer-const
      let displayValue = '';

      if (data[key] != null) {
        switch (key) {
          case 'asset':
            fieldSettings[key] = {
              type: 'component',
              cellProps: {
                xs: 6,
              },
              fieldProps: {
                label: displayLabel,
                value: () => <AssetLink {...data[key]} />,
              },
            };
            return;
          case 'crew':
            displayValue = data[key]['name'];
            break;
          case 'plan':
            fieldSettings[key] = {
              type: 'component',
              cellProps: {
                xs: 6,
              },
              fieldProps: {
                label: displayLabel,
                value: () => <PlanLink {...data[key]} />,
              },
            };
            return;
          case 'projects_published_count':
            displayValue = `${data[key]} of ${data['projects_count']} total Projects`;
            break;
          case 'task_completed_count':
            displayValue = `${data[key]} of ${data['task_count']} total Tasks`;
            break;
          case 'start':
          case 'end':
            displayValue = apiDateToString(data[key], 'date');
            break;

          // add cases for each key that needs to be customized.
          case 'updated_on':
          case 'created_on':
            displayValue = apiDateToString(data[key], 'date');
            break;
          default:
            displayValue = data[key].toString();
        }
      }
      fieldSettings[key] = {
        type: 'text',
        fieldProps: {
          label: displayLabel,
          value: displayValue,
        },
      };
    });
  }

  return (
    <CommonFormView
      bodyOnly
      title={'Event Details'}
      values={data}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      // editLink={`/schedule-events/${id}/edit`}
      loading={loading}
      error={error}
    />
  );
};

ScheduleEventsView.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
};

export default ScheduleEventsView;
