// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
export const HELP_REQUEST = 'HELP_REQUEST';
export const HELP_SUCCESS = 'HELP_SUCCESS';
export const HELP_FAILURE = 'HELP_FAILURE';
export const HELP_TIP_REQUEST = 'HELP_TIP_REQUEST';
export const HELP_TIP_SUCCESS = 'HELP_TIP_SUCCESS';
export const HELP_TIP_FAILURE = 'HELP_TIP_FAILURE';
export const getHelp = (query = {}) => {
  return {
    type: HELP_REQUEST,
    query,
  };
};
export const getHelpTip = () => {
  return {
    type: HELP_TIP_REQUEST,
    query: { help_type: 'TIP' },
  };
};
