// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import { combineReducers } from 'redux';
import {
  NOTIFICATION_RULE_SUBSCRIBERS_REQUEST,
  NOTIFICATION_RULE_SUBSCRIBERS_SUCCESS,
  NOTIFICATION_RULE_SUBSCRIBERS_FAILURE,
  NOTIFICATION_RULE_SUBSCRIBERS_CREATE_REQUEST,
  NOTIFICATION_RULE_SUBSCRIBERS_CREATE_SUCCESS,
  NOTIFICATION_RULE_SUBSCRIBERS_CREATE_FAILURE,
  NOTIFICATION_RULE_SUBSCRIBERS_UPDATE_REQUEST,
  NOTIFICATION_RULE_SUBSCRIBERS_UPDATE_SUCCESS,
  NOTIFICATION_RULE_SUBSCRIBERS_UPDATE_FAILURE,
  NOTIFICATION_RULE_SUBSCRIBERS_DELETE_REQUEST,
  NOTIFICATION_RULE_SUBSCRIBERS_DELETE_SUCCESS,
  NOTIFICATION_RULE_SUBSCRIBERS_DELETE_FAILURE,
  NOTIFICATION_RULE_SUBSCRIBERS_ALL_REQUEST,
  NOTIFICATION_RULE_SUBSCRIBERS_ALL_SUCCESS,
  NOTIFICATION_RULE_SUBSCRIBERS_ALL_FAILURE,
  NOTIFICATION_RULE_SUBSCRIBERS_CLEAR_EACH_ERROR,
} from './notificationRuleSubscribersActions';

export const initialStateNotificationRuleSubscribers = {
  loading: false,
  error: '',
  formError: {},
  id: 0,
  body: {},
  data: {},
};

export const initialStateAllNotificationRuleSubscribers = {
  loading: false,
  error: '',
  query: {},
  dataAll: { count: 0, next: null, previous: null, results: [] },
};

export const resetStateNotificationRuleSubscribers = {
  loading: false,
  error: '',
  formError: {},
  data: {},
};
export const resetStateAllNotificationRuleSubscribers = {
  loading: false,
  error: '',
  query: {},
  dataAll: [],
};

export const notificationRuleSubscribersEachReducer = (state = initialStateNotificationRuleSubscribers, action) => {
  switch (action.type) {
    case NOTIFICATION_RULE_SUBSCRIBERS_REQUEST:
      return {
        ...initialStateNotificationRuleSubscribers,
        loading: true,
        id: action.id,
      };
    case NOTIFICATION_RULE_SUBSCRIBERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case NOTIFICATION_RULE_SUBSCRIBERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case NOTIFICATION_RULE_SUBSCRIBERS_CREATE_REQUEST:
      return {
        ...initialStateNotificationRuleSubscribers,
        loading: true,
        body: action.body,
      };
    case NOTIFICATION_RULE_SUBSCRIBERS_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case NOTIFICATION_RULE_SUBSCRIBERS_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case NOTIFICATION_RULE_SUBSCRIBERS_UPDATE_REQUEST:
      return {
        ...initialStateNotificationRuleSubscribers,
        loading: true,
        id: action.id,
        body: action.body,
      };
    case NOTIFICATION_RULE_SUBSCRIBERS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case NOTIFICATION_RULE_SUBSCRIBERS_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case NOTIFICATION_RULE_SUBSCRIBERS_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        formError: {},
        id: action.id,
      };
    case NOTIFICATION_RULE_SUBSCRIBERS_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case NOTIFICATION_RULE_SUBSCRIBERS_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case NOTIFICATION_RULE_SUBSCRIBERS_CLEAR_EACH_ERROR:
      return {
        ...state,
        error: '',
        formError: {},
      };
    default:
      return state;
  }
};

export const notificationRuleSubscribersAllReducer = (state = initialStateAllNotificationRuleSubscribers, action) => {
  switch (action.type) {
    case NOTIFICATION_RULE_SUBSCRIBERS_ALL_REQUEST:
      return {
        ...initialStateAllNotificationRuleSubscribers,
        loading: true,
        query: action.query,
      };
    case NOTIFICATION_RULE_SUBSCRIBERS_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        dataAll: action.data,
      };
    case NOTIFICATION_RULE_SUBSCRIBERS_ALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default combineReducers({
  each: notificationRuleSubscribersEachReducer,
  all: notificationRuleSubscribersAllReducer,
});
