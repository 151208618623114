export const PROJECTS_REQUEST = 'PROJECTS_REQUEST';
export const PROJECTS_SUCCESS = 'PROJECTS_SUCCESS';
export const PROJECTS_FAILURE = 'PROJECTS_FAILURE';
export const PROJECTS_CREATE_REQUEST = 'PROJECTS_CREATE_REQUEST';
export const PROJECTS_CREATE_SUCCESS = 'PROJECTS_CREATE_SUCCESS';
export const PROJECTS_CREATE_FAILURE = 'PROJECTS_CREATE_FAILURE';
export const PROJECTS_UPDATE_REQUEST = 'PROJECTS_UPDATE_REQUEST';
export const PROJECTS_UPDATE_SUCCESS = 'PROJECTS_UPDATE_SUCCESS';
export const PROJECTS_UPDATE_FAILURE = 'PROJECTS_UPDATE_FAILURE';
export const PROJECT_STATUS_UPDATE_REQUEST = 'PROJECT_STATUS_UPDATE_REQUEST';
export const PROJECT_STATUS_UPDATE_SUCCESS = 'PROJECT_STATUS_UPDATE_SUCCESS';
export const PROJECT_STATUS_UPDATE_FAILURE = 'PROJECT_STATUS_UPDATE_FAILURE';
export const PROJECT_STATUS_BULK_UPDATE_REQUEST = 'PROJECT_STATUS_BULK_UPDATE_REQUEST';
export const PROJECT_STATUS_BULK_UPDATE_SUCCESS = 'PROJECT_STATUS_BULK_UPDATE_SUCCESS';
export const PROJECT_STATUS_BULK_UPDATE_FAILURE = 'PROJECT_STATUS_BULK_UPDATE_FAILURE';
export const ASSET_CONDITION_UPDATE_REQUEST = 'ASSET_CONDITION_UPDATE_REQUEST';
export const ASSET_CONDITION_UPDATE_SUCCESS = 'ASSET_CONDITION_UPDATE_SUCCESS';
export const ASSET_CONDITION_UPDATE_FAILURE = 'ASSET_CONDITION_UPDATE_FAILURE';
export const PROJECT_SHARE_UPDATE_REQUEST = 'PROJECT_SHARE_UPDATE_REQUEST';
export const PROJECT_SHARE_UPDATE_SUCCESS = 'PROJECT_SHARE_UPDATE_SUCCESS';
export const PROJECT_SHARE_UPDATE_FAILURE = 'PROJECT_SHARE_UPDATE_FAILURE';
export const PROJECTS_DELETE_REQUEST = 'PROJECTS_DELETE_REQUEST';
export const PROJECTS_DELETE_SUCCESS = 'PROJECTS_DELETE_SUCCESS';
export const PROJECTS_DELETE_FAILURE = 'PROJECTS_DELETE_FAILURE';
export const PROJECTS_BULK_UPDATE_REQUEST = 'PROJECTS_BULK_UPDATE_REQUEST';
export const PROJECTS_BULK_UPDATE_SUCCESS = 'PROJECTS_BULK_UPDATE_SUCCESS';
export const PROJECTS_BULK_UPDATE_FAILURE = 'PROJECTS_BULK_UPDATE_FAILURE';
export const PROJECTS_BULK_DELETE_REQUEST = 'PROJECTS_BULK_DELETE_REQUEST';
export const PROJECTS_BULK_DELETE_SUCCESS = 'PROJECTS_BULK_DELETE_SUCCESS';
export const PROJECTS_BULK_DELETE_FAILURE = 'PROJECTS_BULK_DELETE_FAILURE';
export const PROJECTS_ALL_REQUEST = 'PROJECTS_ALL_REQUEST';
export const PROJECTS_ALL_SUCCESS = 'PROJECTS_ALL_SUCCESS';
export const PROJECTS_ALL_FAILURE = 'PROJECTS_ALL_FAILURE';
export const PROJECTS_COUNTS_BY_STATUS_REQUEST = 'PROJECTS_COUNTS_BY_STATUS_REQUEST';
export const PROJECTS_COUNTS_BY_STATUS_SUCCESS = 'PROJECTS_COUNTS_BY_STATUS_SUCCESS';
export const PROJECTS_COUNTS_BY_STATUS_FAILURE = 'PROJECTS_COUNTS_BY_STATUS_FAILURE';

export const getProjects = id => {
  return {
    type: PROJECTS_REQUEST,
    id,
  };
};

export const createProjects = (body, nextRoute, options = {}) => {
  return {
    type: PROJECTS_CREATE_REQUEST,
    body,
    nextRoute,
    options,
  };
};

export const updateProjects = (id, body, nextRoute) => {
  return {
    type: PROJECTS_UPDATE_REQUEST,
    id,
    body,
    nextRoute,
  };
};

export const updateProjectsBulk = (ids, body, nextRoute) => {
  return {
    type: PROJECTS_BULK_UPDATE_REQUEST,
    ids,
    body,
    nextRoute,
  };
};
export const updateProjectStatus = (id, body) => {
  return {
    type: PROJECT_STATUS_UPDATE_REQUEST,
    id,
    body,
  };
};

export const updateProjectStatusBulk = (ids, body, nextRoute) => {
  return {
    type: PROJECT_STATUS_BULK_UPDATE_REQUEST,
    ids,
    body,
    nextRoute,
  };
};

export const updateAssetCondition = (id, body) => {
  return {
    type: ASSET_CONDITION_UPDATE_REQUEST,
    id,
    body,
  };
};

export const updateShareProject = (id, body) => {
  return {
    type: PROJECT_SHARE_UPDATE_REQUEST,
    id,
    body,
  };
};

export const deleteProjects = (id, query, routeTo, options = {}) => {
  return {
    type: PROJECTS_DELETE_REQUEST,
    id,
    options,
  };
};

export const deleteProjectsBulk = ids => {
  return {
    type: PROJECTS_BULK_DELETE_REQUEST,
    ids,
  };
};

export const getAllProjects = (query, embeddedInfo) => {
  return {
    type: PROJECTS_ALL_REQUEST,
    query,
    embeddedInfo,
  };
};

/**
 * makes request to https://github.com/huvrdata/huvr/blob/master/huvr_api/huvr_api/projects/viewsets.py#L163-L163
 * @param {*} query
 */
export const getProjectsCountsByStatus = query => {
  return {
    type: PROJECTS_COUNTS_BY_STATUS_REQUEST,
    query,
  };
};
