import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../store/dialogActions';

import ProjectsBulkShareForm from './ProjectsBulkShareForm';

const ProjectsBulkShareDialog = props => {
  const { projects, setSelectedRows } = props;
  const dispatch = useDispatch();

  return (
    <Grid container direction="column" justifyContent="space-between">
      <Grid item>Recipients for sharing/unsharing: {projects?.ids.length} projects</Grid>
      <Grid item>
        <ProjectsBulkShareForm
          projects={projects}
          onSubmit={isSuccess => {
            if (isSuccess) {
              // reset only if successful
              setSelectedRows([]);
            }
            dispatch(closeDialog());
          }}
        />
      </Grid>
    </Grid>
  );
};

ProjectsBulkShareDialog.defaultProps = {
  query: {},
  routeTo: '/',
  name: '',
};

ProjectsBulkShareDialog.propTypes = {
  projects: PropTypes.object.isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  name: PropTypes.string,
  query: PropTypes.object,
  routeTo: PropTypes.string,
  confirm: PropTypes.func,
};

export default ProjectsBulkShareDialog;
