import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useTheme } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Drawer, AppBar, Toolbar, Typography, Divider, IconButton, Container } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HelpIcon from '@mui/icons-material/Help';
import Banner from '../../components/shared/displays/Banner';
import { openDrawer, closeDrawer } from '../../store/drawerActions';
import AvatarMenu from '../../components/shared/AvatarMenu';
import RefreshHandler from '../../components/shared/RefreshHandler';
import variables from '../../config.module.scss';
import NavDynamic from '../../components/shared/NavDynamic';

export default function MainLayout(props) {
  const { pageTitle, children } = props;
  const workspace = useSelector(state => state.settings.features.workspace);
  const drawerWidth = 270;
  const appBarHeight = workspace.alert_status === 'OFF' ? 0 : 24;

  const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      minWidth: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      minWidth: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'flex-end',
      flexDirection: 'column',
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    iconButton: {
      margin: theme.spacing(1),
    },
    content: {
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
      width: '100%',
      marginTop: appBarHeight,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      width: 'calc(100% - 270px)',
    },
    noMarginDivider: {
      margin: 0,
    },
    notificationsIcon: {
      fontSize: '40px',
      margin: theme.spacing(0, 0.5),
    },
    helpIcon: {
      '& svg': {
        fontSize: theme.spacing(3.5),
        color: variables.orange,
      },
    },
    container: {
      flexDirection: 'column',
      // width: '100vw',
      height: '100vh',
      // overflow: 'hidden',
    },
  }));
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const logo = useSelector(state => state.settings.logo.src);
  const open = useSelector(state => state.drawer.open);
  const { alert_status, alert_message } = useSelector(state => state.settings.features.workspace); // eslint-disable-line camelcase
  const firebaseUser = JSON.parse(window.localStorage.getItem('firebaseui::rememberedAccounts'));

  // export const DrawerStateContext = React.createContext(null);

  const handleDrawerOpen = () => {
    dispatch(openDrawer());
  };
  const handleDrawerClose = () => {
    dispatch(closeDrawer());
  };

  const renderTitle = () => {
    if (pageTitle) {
      return (
        <Helmet>
          <title>{`${pageTitle} - HUVRdata`}</title>
        </Helmet>
      );
    }
    return <></>;
  };

  return (
    <Container maxWidth={false} disableGutters className={classes.container}>
      {renderTitle()}
      <div className={classes.root}>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
              size="large">
              <MenuIcon />
            </IconButton>
            <img
              src={logo}
              height="32"
              onClick={() => {
                history.push('/');
              }}
            />
            <Typography variant="h6" style={{ flexGrow: 1 }}></Typography>
            <IconButton
              className={classes.helpIcon}
              color="primary"
              onClick={() => {
                history.push('/support');
              }}
              size="large">
              <HelpIcon />
            </IconButton>
            <AvatarMenu imageSrc={firebaseUser ? firebaseUser[0]['photoUrl'] : undefined} />
          </Toolbar>
          <Banner alertStatus={alert_status} alertMessage={alert_message} /> {/* eslint-disable-line camelcase */}
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}>
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose} className={classes.iconButton} size="large">
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider className={classes.noMarginDivider} />
          <NavDynamic />
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}>
          <div className={classes.drawerHeader} />
          {children}
        </main>
      </div>
      <RefreshHandler />
    </Container>
  );
}

MainLayout.defaultProps = {
  pageTitle: '',
};

MainLayout.propTypes = {
  /** override for html title */
  pageTitle: PropTypes.string,
  /** for rendering the children of this component */
  children: PropTypes.node,
};
