// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import React from 'react';
import CommonRoutes from './utilities/CommonRoutes';
// import HeatDataForm from '../../components/heatData/HeatDataForm';
import HeatDataView from '../../components/heatData/HeatDataView';
import HeatDataTablePage from '../../components/heatData/HeatDataTablePage';
import MainLayout from '../layouts/MainLayout';

const HeatDataRoutes = props => {
  return (
    <CommonRoutes
      pageTitle="Heat Data"
      layout={MainLayout}
      // Edit={<HeatDataForm update />}
      // Create={<HeatDataForm />}
      View={<HeatDataView />}
      All={<HeatDataTablePage />}
    />
  );
};

export default HeatDataRoutes;
