import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import arrayMutators from 'final-form-arrays';
import { jsonKeyToLabel, apiDateToString } from '../../utilities/strings';
import { getCompanies, createCompanies, updateCompanies } from '../../store/features/companiesActions';
import CommonForm from '../shared/form/Common';
import { fieldOrder, removeField, hideField, roleOptions } from './companiesShared';

const CompaniesForm = props => {
  const { update } = props;
  const params = useParams();
  const [submittedValues, setSubmittedValues] = useState({});
  const { data, loading, error, formError } = useSelector(state => state.companies.each);
  const dispatch = useDispatch();

  useEffect(() => {
    if (update && params.id) {
      dispatch(getCompanies(params.id));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formSettings = {
    fieldOrder,
    removeField: removeField(update ? 'UPDATE' : 'CREATE'),
    hideField,
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      switch (key) {
        /* ---------- Required Fields ---------- */
        case 'name':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              required: true,
            },
          };
          break;
        case 'role':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: 'role',
              required: true,
              options: roleOptions,
              labelwidth: 36,
            },
          };
          break;

        /* ---------- Regular Fields ---------- */
        case 'description':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              multiline: true,
              rows: 3,
              maxRows: 10,
            },
          };
          break;
        case 'labels':
          fieldSettings[key] = {
            type: 'key-value',
            fieldProps: {
              label: 'Labels',
              name: 'labels',
            },
          };
          return;
        /* ----------  Disabled Fields ---------- */
        case 'created_on':
        case 'updated_on':
          fieldSettings[key] = {
            type: 'display',
            fieldProps: {
              disabled: true,
              label: jsonKeyToLabel(key),
              name: key,
              value: apiDateToString(data[key], 'date'),
            },
          };
          break;
        /* ----------  Default ---------- */
        default:
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
      }
    });
  }

  const title = update ? 'Edit Company' : 'New Company';

  let initialValues = {};
  if (update) {
    initialValues = { ...data };
  }

  const onSubmit = values => {
    const updatedValues = { ...values };
    setSubmittedValues(updatedValues);
    if (update) {
      dispatch(updateCompanies(updatedValues.id, updatedValues));
    } else {
      dispatch(createCompanies(updatedValues));
    }
  };

  /**
   * Decorators are used for setting the values of other fields based off of a field.
   * Decorator format can be found here: src/components/shared/form/common.js
   */
  const decorators = [];

  /**
   *  Validations that are per field.
   *  Errors associated with the form are passed back through formError in Redux.
   */
  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        if (values[key] === submittedValues[key]) {
          errors[key] = value;
        } else {
          errors[key] = undefined;
        }
      }
    }
    return errors;
  };

  return (
    <CommonForm
      update={update}
      title={title}
      initialValues={initialValues}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      onSubmit={onSubmit}
      decorators={decorators}
      mutators={{ ...arrayMutators }}
      validate={validate}
      loading={loading}
      error={error}
    />
  );
};

CompaniesForm.defaultProps = {
  update: false,
};

CompaniesForm.propTypes = {
  update: PropTypes.bool,
};

export default CompaniesForm;
