import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../shared/table/Table';
import { setBulkImportsTableView } from '../../store/settings/views/bulkImportsTableViewRedux';
import StyledLink from '../shared/StyledLink';
import { apiDateToString } from '../../utilities/strings';
import { setColumns } from '../shared/table/columns';
import { usePermissions, useTableViews } from '../../hooks/settingsHooks';
import { getCustomCSVData, tableViews } from '../../utilities/tables';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const BulkImportsTable = props => {
  const { title } = props;
  const dispatch = useDispatch();
  const { loading, data } = useSelector(state => ({
    loading: state.bulkImports.all.loading,
    data: state.bulkImports.all.dataAll.results,
  }));
  const { bulkImportsTableViewKey } = tableViews;
  const views = useTableViews(bulkImportsTableViewKey);

  const { hasUserEdit } = usePermissions();

  /** @type {Column[]} */
  const columns = [
    {
      // This needs to be first and excluded so customBodyRender can access the id to create the link.
      name: 'id',
      label: 'Id',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return <StyledLink to={`/bulk-imports/${tableMeta.rowData[columns.findIndexByName['id']]}`} value={value} />;
        },
      },
    },
    {
      name: 'state',
      label: 'State',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'messages',
      label: 'Info',
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          if (value) {
            return <>{value[0]}</>;
          }
          return '--';
        },
      },
    },
    {
      name: 'errors',
      label: 'Errors',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => {
          if (value) {
            return <>Errors {value.length}</>;
          }
          return '--';
        },
      },
    },

    {
      name: 'completed_on',
      label: 'Completed',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => (value ? apiDateToString(value) : '--'),
      },
    },
    {
      name: 'uploaded_by',
      label: 'Uploaded By',
      options: {
        filter: false,
        sort: true,
        downloadBody: value => getCustomCSVData('simple', value),
        customBodyRender: value => {
          if (!value) return <></>;
          if (!hasUserEdit) return <>{value.name}</>;
          return <StyledLink to={`/users/${value.id}`} value={value.name} />;
        },
      },
    },
    {
      name: 'created_on',
      label: 'Created',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => apiDateToString(value),
      },
    },
    {
      name: 'updated_on',
      label: 'Updated',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => apiDateToString(value),
      },
    },
  ];

  // handle columns display
  setColumns(columns, views);

  /** @type {TableOptions} */
  const options = {
    // if the column view changes, update redux with either 'add' or 'remove'
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setBulkImportsTableView(changedColumn, action));
    },
    enableNestedDataAccess: '.',
  };

  return (
    <Table
      title={title}
      simpleSearch
      columns={columns}
      addRoute="/bulk-imports/new"
      data={data}
      options={options}
      loading={loading}
    />
  );
};

BulkImportsTable.propTypes = {
  title: PropTypes.string.isRequired,
};

export default BulkImportsTable;
