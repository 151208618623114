import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import RecommendedWorkPlanTable from './RecommendedWorkPlanTable';
import { getAllRecommendedWorkPlan } from '../../store/features/recommendedWorkPlanActions';
import { getParams } from '../../utilities/route';
import { tableViews } from '../../utilities/tables';

import { useTableViewSettings } from '../../hooks/settingsHooks';
import { toQueryString } from '../../utilities/strings';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const RecommendedWorkPlanTablePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const { recommendedWorkPlansTableViewKey } = tableViews;
  const { tableQuery: recommendedWorkPlansQuery, page, views } = useTableViewSettings(recommendedWorkPlansTableViewKey);

  // check for url
  const queryParams = getParams(location, recommendedWorkPlansQuery);

  useEffect(() => {
    dispatch(getAllRecommendedWorkPlan(queryParams));
  }, []); // eslint-disable-line

  const tableChangeHandler = query => {
    // no need to fetch data if the query is the same.
    const newSearch = toQueryString(query);
    if (location.search === newSearch) {
      return;
    }
    const newLocation = location;
    newLocation.search = newSearch;
    history.push(newLocation);
    dispatch(getAllRecommendedWorkPlan(query));
  };

  return (
    <div className={classes.root}>
      <RecommendedWorkPlanTable
        title="Recommended Work Plan"
        queryParamObj={queryParams}
        tableChangeHandler={tableChangeHandler}
        page={page}
        views={views}
      />
    </div>
  );
};

export default RecommendedWorkPlanTablePage;
