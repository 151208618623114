import { all, put, takeLatest, select } from 'redux-saga/effects';
import {
  CHECKLIST_TASKS_REQUEST,
  CHECKLIST_TASKS_SUCCESS,
  CHECKLIST_TASKS_FAILURE,
  CHECKLIST_TASKS_CREATE_REQUEST,
  CHECKLIST_TASKS_CREATE_SUCCESS,
  CHECKLIST_TASKS_CREATE_FAILURE,
  CHECKLIST_TASKS_UPDATE_REQUEST,
  CHECKLIST_TASKS_UPDATE_SUCCESS,
  CHECKLIST_TASKS_UPDATE_FAILURE,
  CHECKLIST_TASKS_DELETE_REQUEST,
  CHECKLIST_TASKS_DELETE_SUCCESS,
  CHECKLIST_TASKS_DELETE_FAILURE,
  CHECKLIST_TASKS_ALL_REQUEST,
  CHECKLIST_TASKS_ALL_SUCCESS,
  CHECKLIST_TASKS_ALL_FAILURE,
} from './checklistTasksActions';

import {
  fetchChecklistTasks,
  createChecklistTasks,
  updateChecklistTasks,
  deleteChecklistTasks,
  fetchChecklistTasksList,
} from '../../api/features/checklistTasks';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';
import { fetchListAll } from '../../utilities/sagaHelpers';
import { DIALOG_CLOSE } from '../../store/dialogActions';

export function* checklistTasksFetch(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield fetchChecklistTasks(id);
    if (data) {
      yield put({ type: CHECKLIST_TASKS_SUCCESS, data });
    } else if (error) {
      yield put({ type: CHECKLIST_TASKS_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, CHECKLIST_TASKS_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, CHECKLIST_TASKS_FAILURE);
  }
}

export function* watchChecklistTasksFetch() {
  yield takeLatest(CHECKLIST_TASKS_REQUEST, checklistTasksFetch);
}

export function* checklistTasksCreate(action) {
  const { body } = action;
  try {
    const { data, error, formError, response } = yield createChecklistTasks(body);
    if (data) {
      yield put({ type: CHECKLIST_TASKS_CREATE_SUCCESS, data });
    } else if (error) {
      yield put({ type: CHECKLIST_TASKS_CREATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: CHECKLIST_TASKS_CREATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, CHECKLIST_TASKS_CREATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, CHECKLIST_TASKS_CREATE_FAILURE);
  }
}

export function* watchChecklistTasksCreate() {
  yield takeLatest(CHECKLIST_TASKS_CREATE_REQUEST, checklistTasksCreate);
}

export function* checklistTasksUpdate(action) {
  const { id, body } = action;
  try {
    const { data, error, formError, response } = yield updateChecklistTasks(id, body);
    if (data) {
      yield put({ type: CHECKLIST_TASKS_UPDATE_SUCCESS, data });
    } else if (error) {
      yield put({ type: CHECKLIST_TASKS_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: CHECKLIST_TASKS_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, CHECKLIST_TASKS_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, CHECKLIST_TASKS_UPDATE_FAILURE);
  }
}

export function* watchChecklistTasksUpdate() {
  yield takeLatest(CHECKLIST_TASKS_UPDATE_REQUEST, checklistTasksUpdate);
}

export function* checklistTasksDelete(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield deleteChecklistTasks(id);
    if (data) {
      yield put({ type: CHECKLIST_TASKS_DELETE_SUCCESS });
      const query = yield select(state => state.checklistTasks.all.query) || '';
      yield put({ type: CHECKLIST_TASKS_ALL_REQUEST, query });
      yield put({ type: DIALOG_CLOSE });
    } else if (error) {
      yield put({ type: CHECKLIST_TASKS_DELETE_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, CHECKLIST_TASKS_DELETE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, CHECKLIST_TASKS_DELETE_FAILURE);
  }
}

export function* watchChecklistTasksDelete() {
  yield takeLatest(CHECKLIST_TASKS_DELETE_REQUEST, checklistTasksDelete);
}

export function* checklistTasksFetchAll(action) {
  try {
    const { data, error, response } = yield fetchListAll(fetchChecklistTasksList, action.query);
    if (data) {
      yield put({ type: CHECKLIST_TASKS_ALL_SUCCESS, data });
    } else if (error) {
      yield put({ type: CHECKLIST_TASKS_ALL_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, CHECKLIST_TASKS_ALL_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, CHECKLIST_TASKS_ALL_FAILURE);
  }
}

export function* watchChecklistTasksFetchAll() {
  yield takeLatest(CHECKLIST_TASKS_ALL_REQUEST, checklistTasksFetchAll);
}

export default function* checklistTasksSaga() {
  yield all([
    watchChecklistTasksFetch(),
    watchChecklistTasksCreate(),
    watchChecklistTasksUpdate(),
    watchChecklistTasksFetchAll(),
    watchChecklistTasksDelete(),
  ]);
}
