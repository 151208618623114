import { useJsApiLoader } from '@react-google-maps/api';

const googleMapLibraries = ['geometry'];

export const useGoogleMapLoader = () => {
  // https://react-google-maps-api-docs.netlify.app/#usejsapiloader
  return useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: googleMapLibraries,
  });
};
