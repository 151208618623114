import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { AppBar, Button, ButtonGroup, Grid, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import EditIcon from '@mui/icons-material/Edit';
import { appBarHeightFinder } from '../../utilities/styling';
import { openDialog } from '../../store/dialogActions';
import AssetPathDisplay from '../assets/AssetPathDisplay';
import FindingStateDisplay from './FindingStateDisplay';
import FindingStateForm from './FindingStateForm';

const DefectToolbar = props => {
  const workspace = useSelector(state => state.settings.features.workspace);
  const drawerWidth = 270;
  const appBarHeight = appBarHeightFinder(workspace.alert_status);

  const useStyles = makeStyles(theme => ({
    appBar: {
      marginTop: appBarHeight,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      marginTop: appBarHeight,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    header: {
      padding: theme.spacing(0, 4),
      margin: theme.spacing(1, 0, 0, 0),
    },
    title: {
      width: theme.spacing(100),
    },
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const open = useSelector(state => state.drawer.open);
  const { id, asset, handleBack, namePath, hasDefectEdit, projectReadOnly, state } = props;

  const handleEdit = () => {
    history.push(`/findings/${id}/edit`);
  };

  const changeStatus = () => {
    dispatch(openDialog('Finding State', <FindingStateForm id={id} state={state} />));
  };

  return (
    <AppBar
      elevation={0}
      position="fixed"
      style={{ zIndex: 200 }}
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent="space-between" className={classes.header}>
            <Grid item container direction="row" className={classes.title}>
              <Grid item xs={1}>
                <IconButton onClick={handleBack} size="large">
                  <ArrowBackIosIcon className={classes.button} />
                </IconButton>
              </Grid>
              <Grid item xs={11} container direction="column">
                <Grid item>
                  <Typography variant="h5">{namePath()}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6" className={classes.assetLinks}>
                    <AssetPathDisplay asset={asset} allLinks />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {hasDefectEdit && (
              <ButtonGroup>
                <Button onClick={changeStatus} disabled={false}>
                  <FindingStateDisplay state={state} />
                </Button>
                <Button startIcon={<EditIcon />} onClick={handleEdit} disabled={projectReadOnly}>
                  Edit
                </Button>
              </ButtonGroup>
            )}
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
};

DefectToolbar.propTypes = {
  id: PropTypes.number.isRequired,
  asset: PropTypes.object.isRequired,
  handleBack: PropTypes.func.isRequired,
  namePath: PropTypes.func.isRequired,
  hasDefectEdit: PropTypes.bool.isRequired,
  projectReadOnly: PropTypes.bool.isRequired,
  state: PropTypes.string.isRequired,
};

export default DefectToolbar;
