import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Table, { SELECTABLE_ROWS_NONE } from '../shared/table/Table';
import MultiSelectFilter from '../shared/table/MultiSelectFilter';
import { deleteScheduleEvents } from '../../store/features/scheduleEventsActions';
import { openDialog } from '../../store/dialogActions';
import { useTopLevelAssets } from '../../hooks/assetHooks';
import { locationWithBack } from '../../utilities/route';
import { compare } from '../../utilities/arrays';
import { jsonKeyToLabel, compareFilterMapOptions } from '../../utilities/strings';
import StyledLink from '../shared/StyledLink';
import DeleteDialog from '../shared/Dialog/DeleteDialog';
import { setColumns } from '../shared/table/columns';
import { setTableSize, setTableView } from '../../store/settings/tableActions';
import useDateRangeColumnHook from '../../hooks/table/dateRangeColumnHook';
import { tableViews } from '../../utilities/tables';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const ScheduleEventsTable = props => {
  const { title, embedded, queryParamObj, tableChangeHandler, page, views } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { topLevelAssetOptions, topLevelAssetMap } = useTopLevelAssets();

  const { loading, data, count } = useSelector(state => {
    const loading = state.scheduleEvents.all.loading;
    const data = state.scheduleEvents.all.dataAll.results;
    return {
      loading,
      data,
      count: state.scheduleEvents.all.dataAll.count,
    };
  });
  const { scheduleEventsTableViewKey } = tableViews;

  const queryFilters = queryParamObj;
  const dateFilterOptions = ['hide_not_started', 'starting_soon', 'recently_finished'];
  const queryAssetFilters = queryFilters?.asset ? [].concat(queryFilters.asset.split(',')) : [];

  const [assetFilterList, setAssetFilterList] = useState(queryAssetFilters);

  /** @type {Column[]} */
  const columns = [
    {
      // This needs to be first and excluded so customBodyRender can access the id to create the link.
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        filterType: 'textField',
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <StyledLink to={`/schedule-events/${tableMeta.rowData[columns.findIndexByName['id']]}`} value={value} />
          );
        },
      },
    },
    {
      name: 'asset.asset_path',
      label: 'Asset Path',
      options: {
        filter: true,
        filterType: 'textField',
        sortField: 'asset__asset_path_cache__path',
        sort: true,
      },
    },
    {
      name: 'descendants',
      label: 'Asset',
      options: {
        filter: true,
        filterType: 'custom',
        display: 'excluded',
        filterList: assetFilterList,
        download: false,
        customFilterListOptions: {
          // customize filter display chips
          render: values => {
            return values.map(v => {
              return topLevelAssetMap[v];
            });
          },
          // customize deleting filter display chip
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            setAssetFilterList(filterList[index]);
            return filterList;
          },
        },
        filterOptions: {
          names: topLevelAssetOptions,
          // format values in the multiselect input
          render: v => topLevelAssetMap[v],
          logic: (asset, filters) => {
            if (filters.length) return !filters.includes(asset);
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            const formatValue = item => {
              // format values in the multiselect menu
              return topLevelAssetMap[item];
            };

            const getComparable = value => compareFilterMapOptions(value, topLevelAssetMap);
            // Sort top level filter options
            filterData[index] = filterData[index].sort(compare(getComparable));
            return (
              <MultiSelectFilter
                title="Top Level Asset"
                filterList={filterList}
                localFilterList={assetFilterList}
                onChange={onChange}
                index={index}
                column={column}
                filterData={filterData}
                updateFilters={setAssetFilterList}
                formatValue={formatValue}
              />
            );
          },
        },
      },
    },
    {
      name: 'crew',
      label: 'Crew',
      options: {
        filter: true,
        filterType: 'textField',
        sortField: 'crew__name',
        sort: true,
        customBodyRender: value => {
          if (!value) return <></>;
          return <StyledLink to={`/crews/${value.id}`} value={value.display_name} />;
        },
      },
    },
    {
      name: 'quick_date',
      label: 'Quick Date Filter',
      options: {
        filter: true,
        sort: false,
        display: 'excluded',
        filterType: 'dropdown',
        viewColumns: false,
        empty: false,
        download: false,
        customFilterListOptions: {
          render: value => jsonKeyToLabel(value),
        },
        filterOptions: {
          names: dateFilterOptions,
          renderValue: value => jsonKeyToLabel(value),
        },
      },
    },
    useDateRangeColumnHook({
      name: 'start',
      label: 'Started',
      queryParamObj,
      queryStartKey: 'start_after',
      queryEndKey: 'start_before',
    }),
    useDateRangeColumnHook({
      name: 'end',
      label: 'Ended',
      queryParamObj,
      queryStartKey: 'end_after',
      queryEndKey: 'end_before',
    }),
    {
      name: 'projects_count',
      label: 'Projects',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'projects_published_count',
      label: 'Published Projects',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'task_count',
      label: 'Tasks',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'task_completed_count',
      label: 'Tasks Completed',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        print: false,
        viewColumns: false,
        customBodyRender: (_, info) => {
          const id = info.rowData[columns.findIndexByName['id']];
          const name = info.rowData[columns.findIndexByName['name']];
          return (
            <>
              <EditIcon
                fontSize="small"
                onClick={() => {
                  history.push(locationWithBack({ pathname: `/schedule-events/${id}/edit` }, location));
                }}
              />
              <DeleteIcon
                fontSize="small"
                onClick={() => {
                  dispatch(
                    openDialog(
                      'Delete Event?',
                      <DeleteDialog id={id} deleteAction={deleteScheduleEvents} name={name} />
                    )
                  );
                }}
              />
            </>
          );
        },
      },
    },
  ];

  // handle columns display
  setColumns(columns, views);

  /** @type {TableOptions} */
  const options = {
    // if the column view changes, update redux with either 'add' or 'remove'
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setTableView(changedColumn, action, page, scheduleEventsTableViewKey));
    },
    onChangeRowsPerPage: numberOfRows => {
      dispatch(setTableSize(numberOfRows, page, scheduleEventsTableViewKey));
    },
    enableNestedDataAccess: '.',
    searchOpen: !embedded,
    selectableRows: SELECTABLE_ROWS_NONE,
  };

  return (
    <Table
      title={title}
      // simpleSearch
      serverSide
      columns={columns}
      // addRoute="/schedule-events/new"
      queryParamObj={queryParamObj}
      tableChangeHandler={tableChangeHandler}
      data={data}
      count={count}
      options={options}
      loading={loading}
      embedded={embedded}
    />
  );
};

ScheduleEventsTable.defaultProps = {
  embedded: false,
  queryParamObj: {},
  page: undefined,
};

ScheduleEventsTable.propTypes = {
  title: PropTypes.string.isRequired,
  queryParamObj: PropTypes.object,
  embedded: PropTypes.bool,
  tableChangeHandler: PropTypes.func.isRequired,
  page: PropTypes.string,
  views: PropTypes.object,
};

export default ScheduleEventsTable;
