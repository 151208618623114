import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTableViews } from '../../../hooks/settingsHooks';
import { setInspectionMediaTableView } from '../../../store/settings/views/inspectionMediaTableViewRedux';
import { tableViews } from '../../../utilities/tables';
import StyledLink from '../../shared/StyledLink';
import { setColumns } from '../../shared/table/columns';
import Table from '../../shared/table/Table';

/** @typedef {import('../../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../../shared/table/types').Column} Column */

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

/**
 *
 *  Super Light Table
 *    Used in conjunction with the Map View
 *  (loads the ENTIRE asset directory, so many columns are excluded)
 *
 *  See serializer used: https://github.com/huvrdata/huvr/blob/8373dba78f5332c96ef401356249ffc7b8ed56a4/huvr_api/huvr_api/assets/serializers.py#L311-L322
 *
 */
const InspectionMediaTable = props => {
  const { title, data, count, loading, setZoomToLocation } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { inspectionMediaTableViewKey } = tableViews;
  const views = useTableViews(inspectionMediaTableViewKey);
  // const inspectionMedias = useSelector(state => state.settings.features.inspectionMedias);
  // const inspectionMediasMap = makeInspectionMediasMap(inspectionMedias);
  // const [selectedInspectionMediaFilter, setSelectedInspectionMediaFilter] = useState([]);

  /** @type {Column[]} */
  const columns = [
    {
      name: 'id',
      label: 'Id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'zoom_to_location',
      label: 'Find',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          // use `geo_point`, not `location`
          //  https://github.com/huvrdata/huvr/pull/5402#issuecomment-1324006133
          const point = tableMeta.rowData[columns.findIndexByName['geo_point']];

          if (!point?.coordinates) {
            console.warn('Unexpected type for geo_point', { point });
            return;
          }

          return <button onClick={() => setZoomToLocation(point.coordinates)}>{point.coordinates.join(', ')}</button>;
        },
      },
    },

    {
      name: 'thumbnail',
      label: 'Thumbnail',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return <img src={value} height={48} />;
        },
      },
    },

    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <StyledLink to={`/inspection-media/${tableMeta.rowData[columns.findIndexByName['id']]}`} value={value} />
          );
        },
      },
    },

    {
      name: 'geo_point',
      label: 'Long/Lat',
      options: {
        filter: false,
        sort: true,
        display: 'excluded', // possibly different from `location`, (as location is address), but I think probably not valuable
      },
    },
    {
      name: 'location',
      label: 'Location',
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  // handle columns display
  setColumns(columns, views);

  /** @type {TableOptions} */
  const options = {
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setInspectionMediaTableView(changedColumn, action));
    },
    enableNestedDataAccess: '.',
  };

  return (
    <div className={classes.root}>
      <Table
        title={title}
        simpleSearch
        columns={columns}
        data={data}
        options={options}
        count={count}
        loading={loading}
      />
    </div>
  );
};

InspectionMediaTable.defaultProps = {
  title: '',
  loading: false,
};

InspectionMediaTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  count: PropTypes.number,
  setZoomToLocation: PropTypes.func.isRequired,
};

export default InspectionMediaTable;
