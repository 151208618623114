import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getScheduleWork } from '../../store/features/scheduleWorkActions';
import { fieldOrder, removeField, hideField } from './ScheduleWorkShared';
import { apiDateToString, jsonKeyToLabel, displayOption } from '../../utilities/strings';
import CommonFormView from '../shared/form/CommonView';
import { usePermissions } from '../../hooks/settingsHooks';
import { taskCreateModes, workTypeOptions } from '../../api/features/constants';

const ScheduleWorkView = () => {
  const id = useParams().id;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getScheduleWork(id));
  }, [dispatch, id]);

  const { hasSchedulePlanEdit } = usePermissions();

  const { data, loading, error } = useSelector(state => state.scheduleWork.each);

  const formSettings = {
    fieldOrder,
    removeField: removeField('VIEW'),
    hideField,
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      // cast to string because booleans do not show up in the field
      let displayLabel = jsonKeyToLabel(key); // eslint-disable-line prefer-const
      let displayValue = '';
      if (data[key] != null) {
        switch (key) {
          // add cases for each key that needs to be customized.
          case 'checklist_template':
            displayValue = data[key].name;
            break;
          case 'project_type':
            displayValue = data[key].name;
            break;
          case 'created_by':
            displayValue = data[key].name;
            break;
          case 'crew':
            displayValue = data[key].name;
            break;
          case 'owner':
            displayValue = data[key].name;
            break;
          case 'task_create_mode':
            displayValue = displayOption(data[key], taskCreateModes);
            break;
          case 'work_type':
            displayValue = displayOption(data[key], workTypeOptions);
            break;
          case 'instructions':
            displayValue = JSON.stringify(data[key]);
            break;
          case 'updated_on':
          case 'created_on':
            displayValue = apiDateToString(data[key], 'date');
            break;
          default:
            displayValue = data[key].toString();
        }
      }
      fieldSettings[key] = {
        type: 'text',
        fieldProps: {
          label: displayLabel,
          value: displayValue,
        },
      };
    });
  }

  return (
    <CommonFormView
      title={'Scheduled Work Details'}
      values={data}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      editLink={hasSchedulePlanEdit && `/schedule-work/${id}/edit`}
      loading={loading}
      error={error}
    />
  );
};

export default ScheduleWorkView;
