import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import arrayMutators from 'final-form-arrays';
import { jsonKeyToLabel, apiDateToString } from '../../utilities/strings';
import { updateInspectionMediaBulk } from '../../store/features/inspectionMediaActions';
import CommonBulkForm from '../shared/form/CommonBulkForm';
import { fieldOrder, removeField, hideField } from './inspectionMediaShared';

import Loading from '../shared/displays/Loading';

import { useFeatureFlags } from '../../hooks/settingsHooks';

const useStyles = makeStyles(theme => ({
  message: {
    color: 'red',
  },
  center: {
    textAlign: 'center',
  },
}));

const InspectionMediaBulkEditForm = props => {
  const { inspectionMediaIds } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { hasInspectionMediaBulkEdit } = useFeatureFlags();

  const [submittedValues, setSubmittedValues] = useState({});

  const { data: project, loading, error, formError } = useSelector(state => state.projects.each);
  const data = {};

  const formSettings = {
    fieldOrder,
    removeField: removeField('BULK-UPDATE'),
    hideField: (values, item) => {
      return hideField(values, item, hasInspectionMediaBulkEdit, false);
    },
  };

  const getLocationZone = () => {
    const field = {
      type: 'text',
      fieldProps: {
        label: 'Location Zone',
        name: 'location_zone',
        helperText: project.type?.layer_profile?.zone_help || '',
        labelwidth: 90,
      },
    };
    if (project.type?.layer_profile?.zone_mode === 'LIST') {
      field.type = 'autocomplete';
      field.fieldProps['options'] = project.type?.layer_profile?.zone_options;
    }
    return field;
  };

  const getLocationCode = () => {
    const field = {
      type: 'text',
      fieldProps: {
        label: 'Location Code',
        name: 'location_code',
        helperText: project.type?.layer_profile?.code_help || '',
        labelwidth: 80,
      },
    };
    if (project.type?.layer_profile?.code_mode === 'LIST') {
      field.type = 'autocomplete';
      field.fieldProps['options'] = project.type?.layer_profile?.code_options;
    }
    return field;
  };

  const getAccess = () => {
    const field = {
      type: 'text',
      fieldProps: {
        label: 'Access',
        name: 'access',
        helperText: project.type?.layer_profile?.access_help || '',
        labelwidth: 80,
      },
    };
    if (project.type?.layer_profile?.access_mode === 'LIST') {
      field.type = 'autocomplete';
      field.fieldProps['options'] = project.type?.layer_profile?.access_options;
    }
    return field;
  };

  const getComponent = () => {
    const field = {
      type: 'text',
      fieldProps: {
        label: 'Component',
        name: 'component',
        helperText: project.type?.layer_profile?.component_help || '',
        labelwidth: 80,
      },
    };
    if (project.type?.layer_profile?.component_mode === 'LIST') {
      field.type = 'autocomplete';
      field.fieldProps['options'] = project.type?.layer_profile?.component_options;
    }
    return field;
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      switch (key) {
        /* ---------- Required Fields ---------- */
        case 'component':
          fieldSettings[key] = getComponent();
          break;
        case 'location_zone':
          fieldSettings[key] = getLocationZone();
          break;
        case 'location_code':
          fieldSettings[key] = getLocationCode();
          break;
        case 'access':
          fieldSettings[key] = getAccess();
          break;
        /* ---------- Regular Fields ---------- */
        case 'description':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              multiline: true,
              rows: 3,
              maxRows: 10,
            },
          };
          break;
        case 'geo_point':
        case 'local_coordinates':
          fieldSettings[key] = {
            type: 'geo',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          break;
        case 'captured_on':
          fieldSettings[key] = {
            type: 'date',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          break;
        /* ----------  Disabled Fields ---------- */
        case 'created_on':
        case 'updated_on':
          fieldSettings[key] = {
            type: 'date',
            fieldProps: {
              disabled: true,
              label: jsonKeyToLabel(key),
              name: key,
              value: apiDateToString(data[key], 'short-timestamp'),
            },
          };
          break;
        /* ----------  Default ---------- */
        default:
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
      }
    });
  }

  const title = 'Edit Inspection Media Bulk';

  // The form cannot handle data coming in as objects.  Get id so select field can render the selected option
  // when new, check if we need to apply any values from the queries
  const initialValues = {};

  const convertToGeoJSONPoint = value => {
    if (value) {
      const [lat, lng] = value.split(',').map(parseFloat);
      return {
        type: 'Point',
        //  while [lat, long] is standard writing convention, math tools (geoJSON included) use: [long, lat, altitude] (x, y, z) as the order of coordinates
        coordinates: [lng, lat],
      };
    }
    return null;
  };

  const onSubmit = changedValues => {
    if ('geo_point' in changedValues) {
      changedValues.geo_point = convertToGeoJSONPoint(changedValues.geo_point);
    }
    if ('local_coordinates' in changedValues) {
      changedValues.local_coordinates = convertToGeoJSONPoint(changedValues.local_coordinates);
    }
    setSubmittedValues(changedValues);
    console.log('Updating Inspection Media -> ', inspectionMediaIds, changedValues);
    dispatch(updateInspectionMediaBulk(inspectionMediaIds, changedValues, location.pathname));
    props.onSubmit();
  };

  /**
   * Decorators are used for setting the values of other fields based off of a field.
   * Decorator format can be found here: src/components/shared/form/common.js
   */
  const decorators = [];

  /**
   *  Validations that are per field.
   *  Errors associated with the form are passed back through formError in Redux.
   */
  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        if (values[key] === submittedValues[key]) {
          errors[key] = value;
        } else {
          errors[key] = undefined;
        }
      }
    }
    return errors;
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={classes.root}>
      <CommonBulkForm
        title={title}
        initialValues={initialValues}
        fieldSettings={fieldSettings}
        formSettings={formSettings}
        onSubmit={onSubmit}
        decorators={decorators}
        validate={validate}
        loading={loading}
        // dirty={dirty}
        error={error}
        mutators={{ ...arrayMutators }}
        keepDirtyOnReinitialize
        customToolbarProps={{ cancel: true }}
      />
    </div>
  );
};

InspectionMediaBulkEditForm.defaultProps = {};

InspectionMediaBulkEditForm.propTypes = {
  inspectionMediaIds: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default InspectionMediaBulkEditForm;
