export const NOTIFICATION_RULE_SUBSCRIBERS_REQUEST = 'NOTIFICATION_RULE_SUBSCRIBERS_REQUEST';
export const NOTIFICATION_RULE_SUBSCRIBERS_SUCCESS = 'NOTIFICATION_RULE_SUBSCRIBERS_SUCCESS';
export const NOTIFICATION_RULE_SUBSCRIBERS_FAILURE = 'NOTIFICATION_RULE_SUBSCRIBERS_FAILURE';
export const NOTIFICATION_RULE_SUBSCRIBERS_CREATE_REQUEST = 'NOTIFICATION_RULE_SUBSCRIBERS_CREATE_REQUEST';
export const NOTIFICATION_RULE_SUBSCRIBERS_CREATE_SUCCESS = 'NOTIFICATION_RULE_SUBSCRIBERS_CREATE_SUCCESS';
export const NOTIFICATION_RULE_SUBSCRIBERS_CREATE_FAILURE = 'NOTIFICATION_RULE_SUBSCRIBERS_CREATE_FAILURE';
export const NOTIFICATION_RULE_SUBSCRIBERS_UPDATE_REQUEST = 'NOTIFICATION_RULE_SUBSCRIBERS_UPDATE_REQUEST';
export const NOTIFICATION_RULE_SUBSCRIBERS_UPDATE_SUCCESS = 'NOTIFICATION_RULE_SUBSCRIBERS_UPDATE_SUCCESS';
export const NOTIFICATION_RULE_SUBSCRIBERS_UPDATE_FAILURE = 'NOTIFICATION_RULE_SUBSCRIBERS_UPDATE_FAILURE';
export const NOTIFICATION_RULE_SUBSCRIBERS_DELETE_REQUEST = 'NOTIFICATION_RULE_SUBSCRIBERS_DELETE_REQUEST';
export const NOTIFICATION_RULE_SUBSCRIBERS_DELETE_SUCCESS = 'NOTIFICATION_RULE_SUBSCRIBERS_DELETE_SUCCESS';
export const NOTIFICATION_RULE_SUBSCRIBERS_DELETE_FAILURE = 'NOTIFICATION_RULE_SUBSCRIBERS_DELETE_FAILURE';
export const NOTIFICATION_RULE_SUBSCRIBERS_ALL_REQUEST = 'NOTIFICATION_RULE_SUBSCRIBERS_ALL_REQUEST';
export const NOTIFICATION_RULE_SUBSCRIBERS_ALL_SUCCESS = 'NOTIFICATION_RULE_SUBSCRIBERS_ALL_SUCCESS';
export const NOTIFICATION_RULE_SUBSCRIBERS_ALL_FAILURE = 'NOTIFICATION_RULE_SUBSCRIBERS_ALL_FAILURE';
export const NOTIFICATION_RULE_SUBSCRIBERS_CLEAR_EACH_ERROR = 'NOTIFICATION_RULE_SUBSCRIBERS_CLEAR_EACH_ERROR';

export const getNotificationRuleSubscribers = id => {
  return {
    type: NOTIFICATION_RULE_SUBSCRIBERS_REQUEST,
    id,
  };
};

export const createNotificationRuleSubscribers = body => {
  return {
    type: NOTIFICATION_RULE_SUBSCRIBERS_CREATE_REQUEST,
    body,
  };
};

export const updateNotificationRuleSubscribers = (id, body) => {
  return {
    type: NOTIFICATION_RULE_SUBSCRIBERS_UPDATE_REQUEST,
    id,
    body,
  };
};

export const deleteNotificationRuleSubscribers = id => {
  return {
    type: NOTIFICATION_RULE_SUBSCRIBERS_DELETE_REQUEST,
    id,
  };
};

export const getAllNotificationRuleSubscribers = query => {
  return {
    type: NOTIFICATION_RULE_SUBSCRIBERS_ALL_REQUEST,
    query,
  };
};

export const clearEachError = () => {
  return {
    type: NOTIFICATION_RULE_SUBSCRIBERS_CLEAR_EACH_ERROR,
  };
};
