import React from 'react';
import PropTypes from 'prop-types';
import Grow from '@mui/material/Grow';
import { Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import withStyles from '@mui/styles/withStyles';

function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

const defaultStyles = theme => ({
  main: {
    display: 'flex',
    flex: '1 0 auto',
  },
  searchIcon: {
    color: theme.palette.text.secondary,
    marginTop: '10px',
    marginRight: '8px',
  },
  searchText: {
    flex: '0.8 0',
  },
  clearIcon: {
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
});

class _DebounceTableSearch extends React.Component {
  handleTextChangeWrapper(debouncedSearch) {
    return function (event) {
      debouncedSearch(event.target.value);
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown, false);
  }

  onKeyDown(event) {
    if (event.keyCode === 27) {
      this.props.onHide();
    }
  }

  render() {
    const { classes, options, onHide, searchText, debounceWait } = this.props;

    const debouncedSearch = debounce(value => {
      this.props.onSearch(value);
    }, debounceWait);

    return (
      <Grow appear in={true} timeout={300}>
        <div className={classes.main}>
          <SearchIcon className={classes.searchIcon} />
          <TextField
            className={classes.searchText}
            InputProps={{
              'data-test-id': options.textLabels.toolbar.search,
              'aria-label': options.textLabels.toolbar.search,
            }}
            defaultValue={searchText}
            onChange={this.handleTextChangeWrapper(debouncedSearch)}
            fullWidth={true}
            inputRef={el => (this.searchField = el)}
            placeholder={options.searchPlaceholder}
            variant="standard"
            {...(options.searchProps ? options.searchProps : {})}
          />
          <IconButton className={classes.clearIcon} onClick={onHide} size="large">
            <ClearIcon />
          </IconButton>
        </div>
      </Grow>
    );
  }
}

_DebounceTableSearch.defaultProps = {
  options: {},
  onSearch: undefined,
  onHide: undefined,
  searchText: '',
  debounceWait: 2000,
};

_DebounceTableSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.object,
  onSearch: PropTypes.func,
  onHide: PropTypes.func,
  searchText: PropTypes.string,
  debounceWait: PropTypes.number,
};

const DebounceTableSearch = withStyles(defaultStyles, { name: 'MUIDataTableSearch' })(_DebounceTableSearch);
export { DebounceTableSearch };

export function debounceSearchRender(title, debounceWait = 200) {
  return (searchText, handleSearch, hideSearch, options) => {
    return (
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box>{title}</Box>
        <Box flexGrow="1" marginLeft="2rem">
          <DebounceTableSearch
            searchText={searchText}
            onSearch={handleSearch}
            onHide={hideSearch}
            options={options}
            debounceWait={debounceWait}
          />
        </Box>
      </Box>
    );
  };
}
