import React from 'react';
import PropTypes from 'prop-types';

import GeoPoint from './GeoPoint';
import GeoPointDisplay from './GeoPointDisplay';

const ViewDisplayGeoPoint = props => {
  const { label, value } = props;
  const geoPoint = new GeoPoint(value);
  const geoPointDisplay = !geoPoint.lat || !geoPoint.lon ? '--' : <GeoPointDisplay geoPoint={geoPoint} />;
  return (
    <>
      <div className="h7">{label}</div>
      {geoPointDisplay}
    </>
  );
};

ViewDisplayGeoPoint.defaultProps = {
  value: {},
};

ViewDisplayGeoPoint.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.object,
};

export default ViewDisplayGeoPoint;
