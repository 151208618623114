import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import MUIDataTable from 'mui-datatables';
import { Box, Grid, LinearProgress, IconButton, Tooltip, Badge } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AllOutIcon from '@mui/icons-material/AllOut';

import CustomToolbar from './CustomToolbar';
import Title from '../displays/Title';
import PrimaryButton from '../buttons/PrimaryButton';
import { queriesFromString, toQueryString } from '../../../utilities/strings';
import { debounceSearchRender } from './CustomDebounceTableSearch';
import { simpleSearchRender } from './CustomSimpleTableSearch';
import { loadingMessages } from './loading';
import FullScreenTable from '../fullscreenTable/FullScreenTable';
import { setQueryParamsSortField } from '../../../utilities/tables';

/** @typedef {import('./types').TableOptions} TableOptions */

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: 0,
    width: 'auto',
  },
  loadingIcon: {
    marginLeft: theme.spacing(2),
    position: 'relative',
    top: '3px',
  },
  cell: {
    margin: 0,
    padding: 0,
    // so the table in reservations does not go past it's parent
    maxWidth: '100%',
  },
  title: {
    fontSize: theme.spacing(3),
  },
  table: {
    paddingTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    // adds a small line below header,
    //  normal border-bottom does not work with sticky position
    //  neither does _outset_ box-shadow
    //  https://stackoverflow.com/questions/50361698/border-style-do-not-work-with-sticky-position-element
    '& th': {
      boxShadow: 'inset 0 -1px 0 0 #e0e0e0',
    },
  },
  fileUploader: {
    padding: theme.spacing(3),
  },
}));
const rowsPerPageOptions = [10, 30, 50, 100, 200];

export const SELECTABLE_ROWS_NONE = 'none';

//
// no scientific reason for these numbers.  Just eyeballed it.
//  using zoom in chrome to test
//  providing a little wiggle room for any alert banners / etc
const WINDOW_HEIGHT_TABLE_VH_MAPPING = [
  // ["max height of window (px)": "height of table (vh)"]
  [1000, '70vh'],
  [1200, '76vh'],
  [1400, '80vh'],
  [1600, '86vh'],
  [1800, '90vh'],
  [Infinity, '96vh'],
];

/**
 * @param {Object} props
 * @param {TableOptions} props.options
 */
const Table = props => {
  const {
    title,
    columns,
    addRoute,
    addAction,
    data,
    options,
    simpleSearch,
    loading,
    serverSide,
    queryParamStr,
    queryParamObj, // will replace queryParamStr
    tableChangeHandler,
    embedded,
    count,
    filename,
    galleryOpen,
    components,
  } = props;
  const classes = useStyles();
  const [expandFullScreen, setExpandFullScreen] = useState(false);
  const debug = false;
  const log = (label, data) => {
    // set debug to true to see logging for internal table events.
    // you can filter for 'table:' on the devTools console to isolate these logs.
    if (debug) {
      console.log(label, data);
    }
  };

  const {
    offset: initialOffset,
    limit: initialLimit,
    ordering: initialSortOrder,
    search,
    ...filters
  } = queryParamObj || queriesFromString(queryParamStr);

  const getSortOrder = sortStr => {
    const sortObj = {};
    if (sortStr) {
      sortObj.direction = sortStr[0] === '-' ? 'desc' : 'asc';
      sortObj.name = sortStr.replaceAll('-', '');

      const column = columns.find(col => col.options.sortField === sortObj.name);
      if (column) {
        sortObj.name = column.name;
      }
    }
    return sortObj;
  };
  const offset = initialOffset ? parseInt(initialOffset, 10) : 0;
  const limit = initialLimit ? parseInt(initialLimit, 10) : rowsPerPageOptions[2];
  // if the current page is greater than the number of rows
  const page = offset / limit ? Math.floor(offset / limit) : 0;
  const [pagination, setPagination] = useState(
    { page, rowsPerPage: limit } || { page: 0, rowsPerPage: rowsPerPageOptions[2] }
  );

  useEffect(() => {
    setPagination({ page, rowsPerPage: limit });
  }, [limit, page]);

  // MEDIA GALLERY SYNC updates pagination state if gallery is viewed
  const [internalState, setInternalState] = useState(galleryOpen);
  const previousValueRef = useRef();
  const previousValue = previousValueRef.current;
  if (galleryOpen !== previousValue && galleryOpen !== internalState) {
    setInternalState(galleryOpen);
    if (galleryOpen === false) {
      setPagination({ page, rowsPerPage: limit } || { page: 0, rowsPerPage: rowsPerPageOptions[2] });
    }
  }
  useEffect(() => {
    previousValueRef.current = galleryOpen;
  });

  const tableBodyMaxHeight = WINDOW_HEIGHT_TABLE_VH_MAPPING.find(([height]) => window.innerHeight < height)[1];

  // const [serversideFilters, setServersideFilters] = useState([])
  const sortOrder = getSortOrder(initialSortOrder);
  const filterList = columns.map((column, id) => {
    let key = column.name;
    const keyBefore = key.concat('_before');
    const keyAfter = key.concat('_after');
    const keyMin = key.concat('_min');
    const keyMax = key.concat('_max');
    if (column.options.sortField) {
      key = column.options.sortField;
    }
    // filterField is used if sortField has multiple fields to sort against
    if (column.options.filterField) {
      key = column.options.filterField;
    }
    if (filters[key] && column.options.filter) {
      if (Array.isArray(filters[key])) {
        return filters[key];
      } else {
        return filters[key].toString().split(',');
      }
    }
    if (filters[keyAfter] || filters[keyBefore]) {
      return [filters[keyAfter], filters[keyBefore]];
    } else if (filters[keyMin] || filters[keyMax]) {
      return [filters[keyMin], filters[keyMax]];
    }
    return [];
  });

  /** @type {TableOptions} */
  const baseOptions = {
    rowsPerPageOptions,
    setTableProps: () => ({
      size: 'small',
    }),
    search: false,
    responsive: 'standard',
    fixedSelectColumn: false,
    tableBodyMaxHeight,
    onDownload: (buildHead, buildBody, columns, data) => {
      // data = [...{index: indexNumber, data: [stuff]}]
      const csvData = data.map(({ index, data }) => {
        return {
          index: index,
          data: data.map((cell, index) => {
            if (columns[index].downloadBody) {
              return columns[index].downloadBody(cell, data, columns);
            }
            return cell;
          }),
        };
      });
      return buildHead(columns) + buildBody(csvData); // '\uFEFF' + buildHead(columns) + buildBody(csvData) if we want excel in future
    },
    downloadOptions: {
      filename: filename || title,
      filterOptions: {
        useDisplayedRowsOnly: true,
      },
    },
    print: false,
    // rowsSelected: [],
  };

  const handleTableExpand = () => {
    setExpandFullScreen(true);
  };

  // adds "New XYZ" button
  if (options.customToolbar) {
    // assume this will override addAction/addRoute
    baseOptions.customToolbar = options.customToolbar;
  } else if (addRoute) {
    baseOptions.customToolbar = () => {
      return <CustomToolbar addRoute={addRoute} />;
    };
  } else if (addAction) {
    baseOptions.customToolbar = () => {
      return <CustomToolbar addRoute={addRoute} addAction={addAction} />;
    };
  }

  // render the "full-screen" button
  if (!expandFullScreen) {
    const existingToolbar = baseOptions.customToolbar ? baseOptions.customToolbar() : null;
    baseOptions.customToolbar = () => {
      return (
        <>
          <Tooltip title="Expand Table">
            <IconButton onClick={handleTableExpand} size="large">
              <AllOutIcon />
            </IconButton>
          </Tooltip>
          {existingToolbar}
        </>
      );
    };
  }

  const filterListToQuery = filterList => {
    const filters = {};
    columns.forEach((column, index) => {
      if (column.options.filter === false) return;
      // if there is a filter for this column
      if (filterList[index].length > 0) {
        // check for special filter type
        if (column.filterType === 'dateRange' || column.filterType === 'numberRange') {
          // atleast one side of date range is set
          const isDateRange = column.filterType === 'dateRange';
          if (filterList[index][0] || filterList[index][1]) {
            // This is date range filtering
            if (filterList[index][0]) {
              filters[column.name.concat(isDateRange ? '_after' : '_min')] = filterList[index][0];
            }
            if (filterList[index][1]) {
              filters[column.name.concat(isDateRange ? '_before' : '_max')] = filterList[index][1];
            }
          }
        } else {
          // check if a special query needs to be used.
          // filterField means the query param is different than the column name and sortField
          if (column.options.filterField) {
            filters[column.options.filterField] = filterList[index].join(',');
          }
          if (column.options.sortField) {
            filters[column.options.sortField] = filterList[index].join(',');
          } else {
            filters[column.name] = filterList[index].join(',');
          }
        }
      }
    });
    return filters;
  };

  const filterSubmit = applyFilters => {
    const newFilters = applyFilters();
    const queryFilters = filterListToQuery(newFilters);
    log('Table:filterSubmit', { columns, queryFilters, newFilters, pagination });

    const { rowsPerPage } = pagination;
    const { ordering, search } = queryParamObj || queriesFromString(queryParamStr); // keep existing params

    const queryParams = { ...queryFilters, limit: rowsPerPage, offset: 0, search, ordering };
    setPagination({ page: 0, rowsPerPage });

    if (queryParamObj) {
      tableChangeHandler(queryParams);
    } else {
      const queryString = toQueryString(queryParams);
      tableChangeHandler(queryString);
    }
  };

  const renderTitle = () => {
    if (embedded) {
      return (
        <Box>
          {count > -1 ? (
            <Badge
              className={`h7 ${classes.title}`}
              badgeContent={count}
              max={9999}
              invisible={count === null}
              color="primary">
              {title}
            </Badge>
          ) : (
            <Title title={title} />
          )}
        </Box>
      );
    } else {
      return <Title title={title} />;
    }
  };

  if (serverSide) {
    baseOptions.serverSide = true;
    baseOptions.count = count;
    baseOptions.page = pagination.page;
    baseOptions.rowsPerPage = pagination.rowsPerPage;
    baseOptions.serverSideFilterList = filterList;
    baseOptions.sortOrder = sortOrder;
    baseOptions.search = true;
    baseOptions.searchOpen = true; // (hides the title...)
    baseOptions.confirmFilters = true;
    baseOptions.customSearchRender = debounceSearchRender(renderTitle(), 1000);
    baseOptions.searchText = search;

    // Calling the applyNewFilters parameter applies the selected filters to the table
    baseOptions.customFilterDialogFooter = (currentFilterList, applyNewFilters) => {
      log('Table:customFilterDialogFooter', { currentFilterList, applyNewFilters });
      return (
        <div style={{ marginTop: '40px' }}>
          <PrimaryButton label="Apply Filters" onClick={() => filterSubmit(applyNewFilters)} />
        </div>
      );
    };
    baseOptions.onFilterChipClose = (index, removeFilter, filterList) => {
      log('Table:onFilterChipClose', { index, columns, removeFilter, filterList });
      const column = columns[index];
      const name = column.options.sortField ? column.options.sortField : column.name;
      delete filters[name];
      const fieldFilters = filterList[index];
      const foundIndex = fieldFilters.indexOf(removeFilter);
      if (foundIndex > -1) {
        fieldFilters.splice(foundIndex, 1);
      }
      const applyFilters = () => filterList;
      filterSubmit(applyFilters);
    };

    baseOptions.onFilterConfirm = filterList => {
      log('Table:onFilterConfirm', { filterList });
      // this is handled through customFilterDialogFooter
    };
    baseOptions.onFilterChange = (changedColumn, filterList, type, changedColumnIndex, displayData) => {
      log('Table:onFilterChange', { changedColumn, filterList, type, changedColumnIndex, displayData });
      // this is for calling the api each time the filter is changed.  Not used since there's an apply filter button.
    };
    baseOptions.onSearchChange = searchText => {
      log('Table:onSearchChange', { searchText });
      // this is handled in onTableChange so we have access to the filterList
    };
    baseOptions.onChangeRowsPerPage = rowsPerPage => {
      log('Table:onChangeRowsPerPage', { rowsPerPage });
      // this is handled in onTableChange so we have access to the filterList
    };
    baseOptions.onChangePage = currentPage => {
      log('Table:onChangePage', { currentPage });
      // this is handled in onTableChange so we have access to the filterList
      // also for some reason, this is never called.
    };
    baseOptions.onTableChange = (action, tableState) => {
      log('Table:onTableChange', { action, tableState });
      const { page, rowsPerPage, filterList, searchText, displayData } = tableState;
      let queryParams = {};
      if (searchText !== '') {
        queryParams.search = searchText;
      }
      if (options.tableChange) {
        options.tableChange(action, displayData);
      }
      if (tableState.sortOrder.name) {
        // sort by name ascending: ?ordering=name
        // sort by id descending: ?ordering=-id
        const { name, direction } = tableState.sortOrder;
        // Optional allow the order_by to use different key than name
        const column = tableState.columns.find(item => item.name === name);
        queryParams.ordering = setQueryParamsSortField(column?.sortField, direction, name);
      }
      switch (action) {
        case 'propsUpdate':
        case 'onFilterDialogClose':
        case 'onSearchClose':
        case 'viewColumnsChange':
        case 'rowSelectionChange':
        case 'filterChange':
        case 'onFilterDialogOpen':
          log('Table:onTableChange', { action, tableState });
          // ignored actions
          return;
        case 'changePage':
          queryParams = { ...queryParams, limit: rowsPerPage, offset: page * rowsPerPage };
          setPagination({ page, rowsPerPage });
          break;
        case 'changeRowsPerPage':
        case 'search':
        case 'sort':
          // if the filters, page size, or sorting changes, go back to page 1
          queryParams = { ...queryParams, limit: rowsPerPage, offset: 0 };
          setPagination({ page: 0, rowsPerPage });
          break;
        case 'resetFilters':
          // if the filters or sorting changes, go back to page 1 and not have any filters in the query
          // for some reason this doesn't do anything.  When you click on reset on the filters menu,
          // it just clears the fields.  onTableChange isn't called.  This is how I imagine it would work if it did.
          queryParams = { ...queryParams, limit: rowsPerPage, offset: 0 };

          setPagination({ page: 0, rowsPerPage });

          if (queryParamObj) {
            tableChangeHandler(queryParams);
          } else {
            tableChangeHandler(toQueryString(queryParams));
          }
          return;
        default:
          console.warn(`!!! Action: ${action} needs to be accounted for in shared Table component.`);
      }
      // add filters that are set to the queryParams
      queryParams = { ...queryParams, ...filters, ...filterListToQuery(filterList) };
      if (queryParamObj) {
        tableChangeHandler(queryParams);
      } else {
        const queryString = toQueryString(queryParams);
        tableChangeHandler(queryString);
      }
    };
  }

  const customSearch = (query, row) => {
    const searchResult = row.some(cell => {
      if (!cell) return false;
      let value = cell;
      if (typeof cell === 'number') {
        value = cell.toString();
      }
      if (typeof cell === 'boolean') {
        value = value ? 'true' : 'false';
      }
      if (typeof cell === 'object') {
        // the object is expected to display the name.
        // we might need to change this if we start using something other than name to display in the table
        value = cell.name ? cell.name : '';
      }
      return value.toLowerCase().includes(query.toLowerCase());
    });
    return searchResult;
  };

  if (simpleSearch) {
    // customizes the search bar to do a case agnostic search for numbers or strings
    baseOptions.customSearch = customSearch;
    baseOptions.search = true;
    baseOptions.searchOpen = true;
    baseOptions.customSearchRender = simpleSearchRender(renderTitle());
  }

  /** @type {TableOptions} */
  const tableOptions = {
    ...baseOptions,
    ...options,
    customToolbar: baseOptions.customToolbar, // already handled above - need to pass it through
    textLabels: {
      body: {
        noMatch: 'No records found.',
      },
    },
  };

  /** @type {TableOptions} */
  const loadingTableOptions = {
    ...tableOptions,
    textLabels: {
      body: {
        noMatch: loadingMessages[Math.floor(Math.random() * loadingMessages.length)],
      },
    },
  };

  return (
    <>
      <Grid container direction="row" alignItems="stretch" className={classes.root}>
        <Grid item xs={12} className={classes.cell}>
          {loading && <LinearProgress size={18} color="primary" className={classes.loadingIcon} />}
          <MUIDataTable
            title={renderTitle()}
            data={data}
            columns={columns}
            options={loading ? loadingTableOptions : tableOptions}
            className={classes.table}
            components={components}
          />
        </Grid>
      </Grid>
      <FullScreenTable
        fullScreen={expandFullScreen}
        setFullScreen={setExpandFullScreen}
        // title={renderTitle()}  // (do NOT pass title - it is already included, no longer necessary)
        loading={loading}>
        <MUIDataTable
          title={renderTitle()}
          data={data}
          columns={columns}
          options={loading ? loadingTableOptions : tableOptions}
          className={classes.table}
          components={components}
        />
      </FullScreenTable>
    </>
  );
};

Table.defaultProps = {
  title: '',
  addRoute: undefined,
  addAction: undefined,
  data: [],
  options: {},
  simpleSearch: false,
  loading: false,
  params: '',
  serverSide: false,
  queryParamStr: '',
  tableChangeHandler: () => {},
  embedded: false,
  count: null,
  filename: null,
  queryParamObj: undefined,
};

Table.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.array.isRequired,
  addRoute: PropTypes.string,
  addAction: PropTypes.func,
  data: PropTypes.array,
  options: PropTypes.object,
  simpleSearch: PropTypes.bool,
  loading: PropTypes.bool,
  serverSide: PropTypes.bool,
  queryParamStr: PropTypes.string,
  tableChangeHandler: PropTypes.func,
  embedded: PropTypes.bool,
  count: PropTypes.number,
  filename: PropTypes.string,
  galleryOpen: PropTypes.bool,
  queryParamObj: PropTypes.object,
  components: PropTypes.any,
};

export default Table;
