import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { Container } from '@mui/material';

import Checklist from '../../checklistEditor/Checklist';
import LoadingPage from '../../shared/displays/LoadingPage';
import { getChecklists } from '../../../store/features/checklistsActions';
import { getRevisions } from '../../../store/getters/revisionsActions';
import { inspectionMediaListBatchFetchAllEndpoint } from '../../../store/apiV2/inspectionMedia';
import { ensureArray } from '../../../utilities/arrays';
import Error from '../../shared/displays/Error';
import { useQuery } from '../../../hooks/locationHooks';
import ChecklistBreadcrumbs from '../../checklistEditor/nav/ChecklistBreadcrumbs';

const FormChecklist = props => {
  const location = useLocation();
  const { formId } = useParams();
  const checklistState = useSelector(state => state.checklists.each);
  const { loading, error } = checklistState;
  const revisionsState = useSelector(state => state.revisions);
  const [query, setQuery] = useQuery();
  const revisionId = query.revisionId || 'latest';
  const { dispatchRequest: batchFetchAllMedia } = inspectionMediaListBatchFetchAllEndpoint.useEndpoint();
  const setRevisionId = id => {
    setQuery({ ...query, revisionId: id });
  };
  const getBackLink = () => {
    if (location.state?.back?.pathname) {
      return location.state.back.pathname; // pathname looks like: '/forms/<id>'
    } else if (location.search) {
      return location.pathname.match(/(.*?)\/\w+$/)[1]; // pathname looks like: '/forms/<id>/checklist'
    } else {
      return '/forms'; // no back link found, default
    }
  };

  const backLink = getBackLink();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getChecklists(formId));
    dispatch(getRevisions(formId));
    // getAllInspectionMedia must be called here, so the
    //  `ChecklistGallery` can make use of `useMediaQuery` hook
    //  see: https://github.com/huvrdata/huvr/pull/8144#discussion_r1230064527
    batchFetchAllMedia({ ordering: '-created_on', checklist: formId, hidden: false, limit: 200 });
  }, [formId, dispatch, revisionId, batchFetchAllMedia]);

  const refresh = () => {
    dispatch(getChecklists(formId));
    dispatch(getRevisions(formId));
  };

  if (loading || checklistState?.id === -1) {
    return <LoadingPage />;
  }
  if (error) {
    return <Error error={`Form: ${error}`} style={{ marginTop: '56px', fontSize: '40px' }} />;
  }
  if (checklistState?.error) {
    return <Error error={`Checklist: ${checklistState?.error}`} style={{ marginTop: '56px', fontSize: '40px' }} />;
  }

  const title = (
    <ChecklistBreadcrumbs
      checklist={checklistState?.data}
      checklistVersion={checklistState.data.template.version}
      isForm={true}
    />
  );

  return (
    <Container maxWidth={false} disableGutters>
      <div className="container">
        <Checklist
          parentId={formId}
          checklist={checklistState.data}
          title={title}
          refresh={refresh}
          revisions={ensureArray(revisionsState?.data)}
          selectedRevisionId={revisionId}
          setRevisionId={setRevisionId}
          backLink={backLink}
          isForm
        />
      </div>
    </Container>
  );
};

export default FormChecklist;
