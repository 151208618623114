import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import SecondaryButton from './SecondaryButton';

const useStyles = makeStyles(theme => ({
  menu: {
    zIndex: 10000,
  },
}));

const SplitButton = props => {
  const classes = useStyles();
  const { label, mainClick, options, color, arrowLabel, icon, Icon, popupColor } = props;
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleMenuItemClick = (event, index) => {
    options[index].action();
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const moreProps = {};
  if (icon) {
    const renderIcon = () => {
      const ButtonIcon = Icon;
      return <ButtonIcon />;
    };
    moreProps.startIcon = renderIcon();
  }

  return (
    <>
      <ButtonGroup variant="contained" color={color} ref={anchorRef} aria-label="split button">
        <Button onClick={mainClick} {...moreProps}>
          {label}
        </Button>
        <Button
          color={color}
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label={arrowLabel}
          aria-haspopup="menu"
          onClick={handleToggle}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        className={classes.menu}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <SecondaryButton
                      key={option.label}
                      label={option.label}
                      onClick={event => handleMenuItemClick(event, index)}
                      color={popupColor ? color : 'secondary'}
                    />
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

SplitButton.defaultProps = {
  color: 'primary',
  icon: false,
  Icon: null,
  popupColor: null,
  arrowLabel: '',
};

SplitButton.propTypes = {
  label: PropTypes.string.isRequired,
  arrowLabel: PropTypes.string,
  mainClick: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      action: PropTypes.func,
    })
  ).isRequired,
  color: PropTypes.string,
  icon: PropTypes.bool,
  Icon: PropTypes.object,
  popupColor: PropTypes.string,
};

export default SplitButton;
