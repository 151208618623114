import React from 'react';
import PropTypes from 'prop-types';
import { CssBaseline } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ErrorBoundary from './components/shared/displays/ErrorBoundary';
import StoreProvider, { history } from './providers/StoreProvider';
import ThemeProvider from './providers/ThemeProvider';
import RouterProvider from './providers/RouterProvider';
import FileUploaderProvider from './providers/FileUploaderProvider';
import Snackbar from './components/shared/Snackbar';
import Dialog from './components/shared/Dialog';

/**
 *  Main Application
 *
 *  @module App
 *  @summary Contains ThemeProvider, MuiPickerUtilsProvider and Router
 */

const App = props => {
  const { initializeGA } = props;
  initializeGA(history);
  console.log('ENV: ', process.env.NODE_ENV);
  console.log('BUILD_ID:', process.env.REACT_APP_BUILD_ID);

  return (
    <ErrorBoundary>
      <div className="App">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <StoreProvider>
            <ThemeProvider>
              <FileUploaderProvider>
                <CssBaseline />
                <RouterProvider history={history}>
                  <Snackbar />
                  <Dialog />
                </RouterProvider>
              </FileUploaderProvider>
            </ThemeProvider>
          </StoreProvider>
        </LocalizationProvider>
      </div>
    </ErrorBoundary>
  );
};

App.propTypes = {
  initializeGA: PropTypes.func.isRequired,
};

export default App;
