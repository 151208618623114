// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import { combineReducers } from 'redux';
import {
  RECOMMENDED_WORK_PLAN_REQUEST,
  RECOMMENDED_WORK_PLAN_SUCCESS,
  RECOMMENDED_WORK_PLAN_FAILURE,
  RECOMMENDED_WORK_PLAN_CREATE_REQUEST,
  RECOMMENDED_WORK_PLAN_CREATE_SUCCESS,
  RECOMMENDED_WORK_PLAN_CREATE_FAILURE,
  RECOMMENDED_WORK_PLAN_UPDATE_REQUEST,
  RECOMMENDED_WORK_PLAN_UPDATE_SUCCESS,
  RECOMMENDED_WORK_PLAN_UPDATE_FAILURE,
  RECOMMENDED_WORK_PLAN_DELETE_REQUEST,
  RECOMMENDED_WORK_PLAN_DELETE_SUCCESS,
  RECOMMENDED_WORK_PLAN_DELETE_FAILURE,
  RECOMMENDED_WORK_PLAN_BULK_UPDATE_REQUEST,
  RECOMMENDED_WORK_PLAN_BULK_UPDATE_SUCCESS,
  RECOMMENDED_WORK_PLAN_BULK_UPDATE_FAILURE,
  RECOMMENDED_WORK_PLAN_ALL_REQUEST,
  RECOMMENDED_WORK_PLAN_ALL_SUCCESS,
  RECOMMENDED_WORK_PLAN_ALL_FAILURE,
} from './recommendedWorkPlanActions';
import { mergeNewItemsIntoExisting } from '../../utilities/reducerHelpers';

export const initialStateRecommendedWorkPlan = {
  loading: false,
  error: '',
  formError: {},
  id: 0,
  body: {},
  data: {},
};

export const initialStateAllRecommendedWorkPlan = {
  loading: false,
  error: '',
  query: {},
  count: 0,
  dataAll: {},
};

export const resetStateRecommendedWorkPlan = {
  loading: false,
  error: '',
  formError: {},
  data: {},
};
export const resetStateAllRecommendedWorkPlan = {
  loading: false,
  error: '',
  query: {},
  dataAll: [],
};

export const recommendedWorkPlanEachReducer = (state = initialStateRecommendedWorkPlan, action) => {
  switch (action.type) {
    case RECOMMENDED_WORK_PLAN_REQUEST:
      return {
        ...initialStateRecommendedWorkPlan,
        loading: true,
        id: action.id,
      };
    case RECOMMENDED_WORK_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case RECOMMENDED_WORK_PLAN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case RECOMMENDED_WORK_PLAN_CREATE_REQUEST:
      return {
        ...initialStateRecommendedWorkPlan,
        loading: true,
        body: action.body,
      };
    case RECOMMENDED_WORK_PLAN_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case RECOMMENDED_WORK_PLAN_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case RECOMMENDED_WORK_PLAN_UPDATE_REQUEST:
      return {
        ...initialStateRecommendedWorkPlan,
        loading: true,
        id: action.id,
        body: action.body,
      };
    case RECOMMENDED_WORK_PLAN_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case RECOMMENDED_WORK_PLAN_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case RECOMMENDED_WORK_PLAN_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        formError: {},
        id: action.id,
      };
    case RECOMMENDED_WORK_PLAN_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case RECOMMENDED_WORK_PLAN_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    default:
      return state;
  }
};

export const recommendedWorkPlanAllReducer = (state = initialStateAllRecommendedWorkPlan, action) => {
  switch (action.type) {
    case RECOMMENDED_WORK_PLAN_ALL_REQUEST:
      return {
        ...initialStateAllRecommendedWorkPlan,
        loading: true,
        query: action.query,
      };
    case RECOMMENDED_WORK_PLAN_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        dataAll: action.data,
      };
    case RECOMMENDED_WORK_PLAN_ALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case RECOMMENDED_WORK_PLAN_BULK_UPDATE_REQUEST:
      return {
        ...state,
        error: '',
        loading: true,
        ids: action.ids,
      };
    case RECOMMENDED_WORK_PLAN_BULK_UPDATE_SUCCESS:
      return {
        ...state,
        dataAll: {
          ...state.dataAll,
          results: mergeNewItemsIntoExisting(state.dataAll.results, action.data),
        },
        error: '',
        loading: false,
      };
    case RECOMMENDED_WORK_PLAN_BULK_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default combineReducers({
  each: recommendedWorkPlanEachReducer,
  all: recommendedWorkPlanAllReducer,
});
