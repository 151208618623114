import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'mui-rff';
import { requiredFn } from '../../../utilities/validators';
import 'date-fns';

const FieldEditDate = props => {
  const { label, name, value, placeholder, loading, disabled, required, helperText, format } = props;

  const moreProps = {};
  if (value) moreProps.value = value;

  let modifiedLabel = label;
  if (required) {
    modifiedLabel = (
      <span>
        {label}
        <sup>*</sup>
      </span>
    );
  }

  // return <>{label} {name} {value} {format}</>;
  return (
    <DatePicker
      label={modifiedLabel}
      name={name}
      placeholder={placeholder}
      required={required}
      loading={loading}
      disabled={disabled}
      inputVariant="outlined"
      format={format}
      helperText={helperText}
      InputLabelProps={{ shrink: true }}
      fieldProps={required ? { validate: requiredFn(required) } : {}}
      {...moreProps}
    />
  );
};

FieldEditDate.defaultProps = {
  value: undefined,
  placeholder: '',
  required: false,
  loading: false,
  disabled: false,
  helperText: '',
  format: 'YYYY-MM-DD',
};

FieldEditDate.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  format: PropTypes.string,
};

export default FieldEditDate;
