import React from 'react';
import { useSelector } from 'react-redux';
import { fieldOrder, removeField, hideField } from './SchedulePlanShared';
import { apiDateToString, jsonKeyToLabel } from '../../utilities/strings';
import CommonFormView from '../shared/form/CommonView';
import { getNestedProperty } from '../../utilities/objects';

const SchedulePlanView = () => {
  const { data, loading, error } = useSelector(state => state.schedulePlan.each);

  const formSettings = {
    fieldOrder,
    removeField: removeField('VIEW'),
    hideField,
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      // cast to string because booleans do not show up in the field
      let displayLabel = jsonKeyToLabel(key); // eslint-disable-line prefer-const
      let displayValue = '';
      switch (key) {
        // add cases for each key that needs to be customized.
        case 'asset':
          displayValue = data[key]?.asset_path ?? '--';
          break;
        case 'crew':
          displayValue = data[key]?.display_name ?? '--';
          break;
        case 'created_by':
          displayValue = data[key]?.name ?? '--';
          break;
        case 'updated_on':
        case 'created_on':
          displayValue = data[key] ? apiDateToString(data[key], 'date') : '--';
          break;
        case 'next_event.start':
          displayLabel = 'Start';
          displayValue = apiDateToString(getNestedProperty(data, 'next_event.start'), 'short-timestamp');
          break;
        case 'next_event.create_after':
          displayLabel = 'Create After';
          displayValue = apiDateToString(getNestedProperty(data, 'next_event.create_after'), 'short-timestamp');
          break;
        default:
          displayValue = data[key] ? String(data[key]) : '--';
      }
      fieldSettings[key] = {
        type: 'text',
        fieldProps: {
          label: displayLabel,
          value: displayValue,
        },
      };
    });
  }

  return (
    <CommonFormView
      title={'Schedule Plan Details'}
      values={data}
      bodyOnly
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      loading={loading}
      error={error}
    />
  );
};

export default SchedulePlanView;
