import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useParams } from 'react-router-dom';
import LibrariesView from './LibrariesView';
import { getLibraries } from '../../store/features/librariesActions';
import LibraryMediaTable from '../libraryMedia/LibraryMediaTable';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 'auto'),
    padding: theme.spacing(2),
    textAlign: 'left',
    overflow: 'auto',
  },
  header: {
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
  },
  button: {
    padding: theme.spacing(0, 1),
  },
  table: {
    padding: theme.spacing(0, 6),
  },
}));

const LibrariesDetail = () => {
  const { data, loading, error } = useSelector(state => state.libraries.each);
  const id = useParams().id;
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(getLibraries(id));
  }, [dispatch, id]);

  return (
    <div>
      <Grid container direction="column" alignItems="stretch">
        <Grid item xs={12}>
          <LibrariesView data={data} loading={loading} error={error} />
        </Grid>
      </Grid>
      <Grid container direction="column" alignItems="stretch" className={classes.table}>
        <Grid item xs={12}>
          <LibraryMediaTable data={data} loading={loading} error={error} libraryId={id} />
        </Grid>
      </Grid>
    </div>
  );
};

export default LibrariesDetail;
