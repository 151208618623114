import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import arrayMutators from 'final-form-arrays';
import { jsonKeyToLabel } from '../../utilities/strings';
import { updateRecommendedWorkPlanBulk } from '../../store/features/recommendedWorkPlanActions';
import CommonBulkForm from '../shared/form/CommonBulkForm';
import { fieldOrder, removeField, hideField } from './recommendedWorkPlanShared';
import Loading from '../shared/displays/Loading';

import { useFeatureFlags } from '../../hooks/settingsHooks';
import { workStatusOptions } from '../../api/features/constants';

const useStyles = makeStyles(theme => ({
  message: {
    color: 'red',
  },
  center: {
    textAlign: 'center',
  },
}));

const RecommendedWorkPlanBulkEditForm = props => {
  const { recommendedWorkPlanIds } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { hasAssetAssessments } = useFeatureFlags();

  const [submittedValues, setSubmittedValues] = useState({});

  const { loading, error, formError } = useSelector(state => state.recommendedWorkPlan.each);

  const formSettings = {
    fieldOrder,
    removeField: removeField('BULK-UPDATE'),
    hideField: (values, item) => {
      return hideField(values, item, hasAssetAssessments, false);
    },
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      switch (key) {
        /* ---------- Regular Fields ---------- */
        case 'status':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              options: workStatusOptions,
              labelwidth: 40,
            },
          };
          break;
        case 'status_note':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              multiline: true,
              rows: 3,
              maxRows: 10,
            },
          };
          break;
        /* ----------  Default ---------- */
        default:
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
      }
    });
  }

  const title = 'Edit Recommended Work Plan Bulk';

  // The form cannot handle data coming in as objects.  Get id so select field can render the selected option
  // when new, check if we need to apply any values from the queries
  const initialValues = {};

  const onSubmit = changedValues => {
    setSubmittedValues(changedValues);
    dispatch(updateRecommendedWorkPlanBulk(recommendedWorkPlanIds, changedValues, location.pathname));
    props.onSubmit();
  };

  /**
   * Decorators are used for setting the values of other fields based off of a field.
   * Decorator format can be found here: src/components/shared/form/common.js
   */
  const decorators = [];

  /**
   *  Validations that are per field.
   *  Errors associated with the form are passed back through formError in Redux.
   */
  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        if (values[key] === submittedValues[key]) {
          errors[key] = value;
        } else {
          errors[key] = undefined;
        }
      }
    }
    return errors;
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={classes.root}>
      <CommonBulkForm
        title={title}
        initialValues={initialValues}
        fieldSettings={fieldSettings}
        formSettings={formSettings}
        onSubmit={onSubmit}
        decorators={decorators}
        validate={validate}
        loading={loading}
        // dirty={dirty}
        error={error}
        mutators={{ ...arrayMutators }}
        keepDirtyOnReinitialize
        customToolbarProps={{ cancel: true }}
      />
    </div>
  );
};

RecommendedWorkPlanBulkEditForm.defaultProps = {
  recommendedWorkPlanIds: [],
};

RecommendedWorkPlanBulkEditForm.propTypes = {
  recommendedWorkPlanIds: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default RecommendedWorkPlanBulkEditForm;
