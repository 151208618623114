import { getCookie } from './strings';
import { AUTH_COOKIE_NAME } from './auth';
import { makeURL } from '../api/base';

export const openReportWindow = url => {
  const fullUrl = makeURL(url);
  window.open(`${fullUrl}`, '_blank');
};
export async function generatePDF(instanceData = {}) {
  let url = '/report/generate-pdf/?';
  let payload = {};
  // Note: the query string is not required for our API, but it is included to
  //       make it easier to debug any issues with gcs's stackdriver logging
  if (instanceData.form && instanceData.type) {
    url += `checklist=${instanceData.form}&type=${instanceData.type}`;
    payload = JSON.stringify({ checklist: instanceData.form, type: instanceData.type });
  } else if (instanceData && instanceData.type) {
    url += `project=${instanceData.project}&type=${instanceData.type}`;
    payload = JSON.stringify({ project: instanceData.project, type: instanceData.type });
  } else if (instanceData.form) {
    url += `checklist=${instanceData.form}`;
    payload = JSON.stringify({ checklist: instanceData.form });
  }
  const fullUrl = makeURL(url);
  const auth = getCookie(AUTH_COOKIE_NAME);
  const fetchOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Cookie: `auth=${auth};`,
    },
    credentials: 'include',
    body: payload,
  };
  const response = await fetch(fullUrl, fetchOptions);
  if (response.ok) {
    const jsonData = await response.json();
    return jsonData;
  }
}
