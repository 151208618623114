export const SCHEDULEPLAN_REQUEST = 'SCHEDULEPLAN_REQUEST';
export const SCHEDULEPLAN_SUCCESS = 'SCHEDULEPLAN_SUCCESS';
export const SCHEDULEPLAN_FAILURE = 'SCHEDULEPLAN_FAILURE';
export const SCHEDULEPLAN_CREATE_REQUEST = 'SCHEDULEPLAN_CREATE_REQUEST';
export const SCHEDULEPLAN_CREATE_SUCCESS = 'SCHEDULEPLAN_CREATE_SUCCESS';
export const SCHEDULEPLAN_CREATE_FAILURE = 'SCHEDULEPLAN_CREATE_FAILURE';
export const SCHEDULEPLAN_UPDATE_REQUEST = 'SCHEDULEPLAN_UPDATE_REQUEST';
export const SCHEDULEPLAN_UPDATE_SUCCESS = 'SCHEDULEPLAN_UPDATE_SUCCESS';
export const SCHEDULEPLAN_UPDATE_FAILURE = 'SCHEDULEPLAN_UPDATE_FAILURE';
export const SCHEDULEPLAN_DELETE_REQUEST = 'SCHEDULEPLAN_DELETE_REQUEST';
export const SCHEDULEPLAN_DELETE_SUCCESS = 'SCHEDULEPLAN_DELETE_SUCCESS';
export const SCHEDULEPLAN_DELETE_FAILURE = 'SCHEDULEPLAN_DELETE_FAILURE';
export const SCHEDULEPLAN_ALL_REQUEST = 'SCHEDULEPLAN_ALL_REQUEST';
export const SCHEDULEPLAN_ALL_SUCCESS = 'SCHEDULEPLAN_ALL_SUCCESS';
export const SCHEDULEPLAN_ALL_FAILURE = 'SCHEDULEPLAN_ALL_FAILURE';
export const SCHEDULEPLAN_GENERATE_EVENT_REQUEST = 'SCHEDULEPLAN_GENERATE_EVENT_REQUEST';
export const SCHEDULEPLAN_GENERATE_EVENT_SUCCESS = 'SCHEDULEPLAN_GENERATE_EVENT_SUCCESS';
export const SCHEDULEPLAN_GENERATE_EVENT_FAILURE = 'SCHEDULEPLAN_GENERATE_EVENT_FAILURE';
export const SCHEDULEPLAN_RESET = 'SCHEDULEPLAN_RESET';

export const resetSchedulePlan = () => {
  return {
    type: SCHEDULEPLAN_RESET,
  };
};

export const getSchedulePlan = id => {
  return {
    type: SCHEDULEPLAN_REQUEST,
    id,
  };
};

export const createSchedulePlan = body => {
  return {
    type: SCHEDULEPLAN_CREATE_REQUEST,
    body,
  };
};

export const updateSchedulePlan = (id, body) => {
  return {
    type: SCHEDULEPLAN_UPDATE_REQUEST,
    id,
    body,
  };
};

export const deleteSchedulePlan = id => {
  return {
    type: SCHEDULEPLAN_DELETE_REQUEST,
    id,
  };
};

export const getAllSchedulePlan = query => {
  return {
    type: SCHEDULEPLAN_ALL_REQUEST,
    query,
  };
};

export const generateScheduleEvent = (id, body) => {
  return {
    type: SCHEDULEPLAN_GENERATE_EVENT_REQUEST,
    id,
    body,
  };
};
