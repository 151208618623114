import { useRef, useEffect, useState } from 'react';

export const useIsMounted = () => {
  const isMountedRef = useRef(true);
  useEffect(() => {
    isMountedRef.current = false;
  }, []);
  return isMountedRef.current;
};

/**
  stores initialized value in ref.current
  - ex: comparing new value on component re-render
*/
export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

/**
 * Use to track if a value has changed since the last render.
 *
 * @param {*} value
 * @returns boolean
 */
export const useValueChangeOnce = value => {
  const [valueChanged, setValueChanged] = useState(false);

  useEffect(() => {
    if (valueChanged) {
      setValueChanged(false);
    }
  }, [valueChanged]);

  useEffect(() => {
    setValueChanged(true);
  }, [value]);

  return valueChanged;
};
