import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';

import { Card, CardMedia, FormHelperText } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import Markdown from '../../shared/Markdown';
// TODO: turn signature pad on
// import SignaturePad from 'react-signature-canvas';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
}));

const SignatureLineItem = props => {
  const { label, helperText, name, validator } = props;
  const classes = useStyles();
  // TODO: Turn signature pad back on
  // let sigPad = {};
  // <SignaturePad
  //   canvasProps={{ className: classes.sigPad }}
  //   ref={ref => {
  //     sigPad = ref;
  //   }}
  // />
  // <div>
  //   <Button>Sign</Button>
  //   <Button onClick={() => sigPad.clear()}>Clear</Button>
  // </div>

  return (
    <div>
      <Markdown>{label}</Markdown>
      <FieldArray name={name}>
        {({ fields }) => (
          <div>
            {fields.map((name, index) => (
              <Field name={`${name}.file`} key={index} fieldProps={{ validate: validator }}>
                {({ input: { name, value } }) => {
                  return (
                    <Card className={classes.root}>
                      <CardMedia className={classes.media} image={value} />
                    </Card>
                  );
                }}
              </Field>
            ))}
          </div>
        )}
      </FieldArray>
      <FormHelperText>
        <Markdown>{helperText}</Markdown>
      </FormHelperText>
    </div>
  );
};

SignatureLineItem.defaultProps = {
  disabled: false,
  required: false,
  name: '',
  label: '',
  value: undefined,
  options: [],
  labelwidth: 50,
  loading: false,
  multiple: false,
  inputPropClasses: '',
  helperText: '',
  validator: () => {},
};

SignatureLineItem.propTypes = {
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array,
  labelwidth: PropTypes.number,
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
  inputPropClasses: PropTypes.string,
  helperText: PropTypes.string,
  media: PropTypes.array,
  validator: PropTypes.func,
};

export default SignatureLineItem;
