// export function compare(getValue) {
//   return function sort(a,b) {
//     if (getValue(a) < getValue(b)) {
//       return -1;
//     }
//     if (getValue(a) > getValue(b)) {
//       return 1;
//     }
//     return 0;
//   }
// };

// function to return whatever value is passed in.
const defaultFn = x => x;

// Reference for why we return these values.
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort#description
export const compare =
  (getValue = defaultFn) =>
  (a, b) => {
    if (getValue(a) < getValue(b)) {
      return -1;
    }
    if (getValue(a) > getValue(b)) {
      return 1;
    }
    return 0;
  };

export const ensureArray = x => {
  return !Array.isArray(x) ? [x] : x;
};

// check to see if we want to perform a function on array
export const arrayFunctionCheck = array => !(!Array.isArray(array) || !(Array.isArray(array) && array.length));

// Add value to values, by looking up the id
export const addToArray = (values, value) => {
  if (arrayFunctionCheck(values)) {
    const idx = values.findIndex(element => element.id === value.id);
    return [...values.slice(0, idx), value, ...values.slice(idx + 1)];
  }
  return [value];
};

export const maxArrayofArrayLength = arr => Math.max(...arr.map(el => el.length));

export const removeDuplicateObjects = (arr, property = 'id') => {
  try {
    return arr.filter((value, index, self) => index === self.findIndex(t => t[property] === value[property]));
  } catch {
    return arr;
  }
};

export const compareArrayEquality = (arr1, arr2) => {
  if (!arr1 || !arr2) return;
  const sortedArr1 = arr1.sort();
  const sortedArr2 = arr2.sort();
  return JSON.stringify(sortedArr1) === JSON.stringify(sortedArr2);
};
