import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import makeStyles from '@mui/styles/makeStyles';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CloseIcon from '@mui/icons-material/Close';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import variables from '../../../config.module.scss';

const useStyles = makeStyles(theme => ({
  container: {
    height: '120px',
    border: `1px ${variables.baseDark} solid`,
    borderRadius: '5px',
  },
  dropzone: {
    height: '100%',
  },
  icon: {
    margin: '0 0 0 3px',
  },
  thumbsContainer: {},
  thumb: {
    display: 'inline-flex',
    width: 100,
    height: 100,
    boxSizing: 'border-box',
    margin: '10px',
  },
  img: {
    display: 'block',
    width: '100%',
  },
  closeButton: {
    position: 'absolute',
    left: '80px',
    top: '15px',
    backgroundColor: 'white',
    borderRadius: '10px',
    opacity: 0.5,
  },
}));

function Dropzone(props) {
  const [files, setFiles] = useState([]);
  const classes = useStyles();
  const { accept, uploadText } = props;

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    multiple: false,
    onDrop: acceptedFiles => {
      const files = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFiles(files);
      if (props.onChange) {
        props.onChange(files);
      }
    },
  });

  const fileType = file => {
    const typeArr = file.type.split('/');
    return typeArr[0];
  };

  const removeFile = file => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };
  // switch depending on type image show thumb / pdf show file name
  const thumbs = files.map(file => {
    return (
      <div className={classes.thumb} key={file.name}>
        {fileType(file) === 'image' ? (
          <>
            <img src={file.preview} className={classes.img} alt="" />
            <CloseIcon className={classes.closeButton} onClick={removeFile(file)} />
          </>
        ) : (
          <div className={classes.filePreview}>
            <AttachFileIcon fontSize="large" />
            <span>{file.name}</span>
          </div>
        )}
      </div>
    );
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <div className={classes.container}>
      <div {...getRootProps({ className: `btn-dropzone ${classes.dropzone}` })}>
        <div className={classes.thumbsContainer}>{thumbs}</div>
        {files.length > 0 ? (
          <></>
        ) : (
          <div className={classes.fileUploadText}>
            <AddBoxIcon fontSize="small" className={classes.icon} />
            {uploadText}
          </div>
        )}
        <input {...getInputProps()} />
      </div>
    </div>
  );
}

Dropzone.defaultProps = {
  accept: '',
  uploadText: 'Click to upload file.',
};

Dropzone.propTypes = {
  onChange: PropTypes.func,
  accept: PropTypes.string,
  uploadText: PropTypes.string,
};

export default Dropzone;
