export const COMPANY_STATS_REQUEST = 'COMPANY_STATS_REQUEST';
export const COMPANY_STATS_SUCCESS = 'COMPANY_STATS_SUCCESS';
export const COMPANY_STATS_FAILURE = 'COMPANY_STATS_FAILURE';
export const COMPANIES_STATS_REQUEST = 'COMPANIES_STATS_REQUEST';
export const COMPANIES_STATS_SUCCESS = 'COMPANIES_STATS_SUCCESS';
export const COMPANIES_STATS_FAILURE = 'COMPANIES_STATS_FAILURE';

export const getCompanyStats = id => {
  return {
    type: COMPANY_STATS_REQUEST,
    id,
  };
};

export const getCompaniesStats = () => {
  return {
    type: COMPANIES_STATS_REQUEST,
  };
};
