import { requestFactory, responseHandlerForm } from '../base';

const get = requestFactory('get');
const post = requestFactory('post', { handler: responseHandlerForm });
const patch = requestFactory('patch', { handler: responseHandlerForm });
const del = requestFactory('del');

export const fetchScheduleTasksList = async query => get('/api/schedule/tasks/', query);
export const fetchScheduleTasks = async id => get(`/api/schedule/tasks/${id}/`);
export const createScheduleTasks = async body => post('/api/schedule/tasks/', body);
export const updateScheduleTasks = async (id, body) => patch(`/api/schedule/tasks/${id}/`, body);
export const deleteScheduleTasks = async id => del(`/api/schedule/tasks/${id}/`);
