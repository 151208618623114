/* eslint-disable camelcase */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, FormHelperText, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Title from '../shared/displays/Title';
import DarkModeToggle from '../shared/buttons/DarkModeToggle';
import Switch from '../shared/buttons/Switch';
import { userPreferencesUpdateEndpoint } from '../../store/apiV2/user';
import { USER_PREFERENCES } from '../../api/features/constants';
import { openSnackbar } from '../../store/snackbarActions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    textAlign: 'left',
  },
  settings: {
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
}));

const UserSettings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const updatePreferences = userPreferencesUpdateEndpoint.useEndpoint();

  const { preferences } = useSelector(state => {
    const profile = state.apiV2.profile.data;
    const preferences = profile?.preferences || {};
    return {
      preferences,
    };
  });
  const handleToggle = (val, key) => {
    let _val = val;
    // check value type for non-boolean settings
    if (typeof preferences[key] !== 'boolean') {
      _val = val ? 'ALL' : 'NONE';
    }
    const onSuccess = () => dispatch(openSnackbar('Preferences Updated.', 'success', null, 2000));
    updatePreferences.dispatchRequest({ [key]: _val }).then(onSuccess);
  };

  const handleValue = val => {
    if (!val || val === 'NONE') return false;
    return true;
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item className={classes.title}>
        <Title title="Preferences" />
      </Grid>
      <Grid container item direction="column">
        {USER_PREFERENCES.map(p => {
          const { label, key, helpText } = p;
          return (
            <Grid key={key} item className={classes.settings}>
              <Switch
                label={label}
                initialValue={handleValue(preferences[key])}
                onChange={val => handleToggle(val, key)}
              />
              <FormHelperText>{helpText}</FormHelperText>
            </Grid>
          );
        })}
      </Grid>
      <Divider />
      <Grid item container direction="column" xs={12}>
        <Grid item className={classes.title}>
          <Title title="Browser Settings" />
        </Grid>
        <Grid item className={classes.settings}>
          <DarkModeToggle />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserSettings;
