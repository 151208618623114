import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// lib
import makeStyles from '@mui/styles/makeStyles';
import { Grid } from '@mui/material';

// icon
import LengthIcon from '@mui/icons-material/Straighten';
import PanIcon from '@mui/icons-material/OpenWith';
import WwwcIcon from '@mui/icons-material/Brightness4';
import RotateIcon from '@mui/icons-material/ThreeSixty';
import SnapshotIcon from '@mui/icons-material/CameraEnhanceOutlined';

//
import ButtonIcon from '../buttons/ButtonIcon';
import { updateInspectionMedia } from '../../../store/features/inspectionMediaActions';
import { usePermissions } from '../../../hooks/settingsHooks';

const useStyles = makeStyles(theme => ({
  buttons: {
    '& span': {
      padding: theme.spacing(1),
    },
  },
  toolbar: {
    padding: theme.spacing(6),
  },
}));

const modeButtons = [
  { value: 'Length', icon: LengthIcon },
  { value: 'Pan', icon: PanIcon },
  { value: 'Rotate', icon: RotateIcon },
  { value: 'Wwwc', icon: WwwcIcon },
];

const DicomToolbar = ({ cornerstoneTools: cornerstone, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { hasChecklistEdit } = usePermissions();
  const [activeButton, setActiveButton] = useState(null);

  const handleToolClick = mode => {
    const handler = `set${mode}Active`;
    const setTool = cornerstone[handler];
    setTool();
    setActiveButton(mode);
  };

  const handleSnapshot = () => {
    const canvas = document.querySelector('.cornerstone-canvas');
    const dataUrl = canvas.toDataURL();
    dispatch(
      updateInspectionMedia(id, { preview: dataUrl, generate_thumbnail: 'true' }, undefined, { displaySnackbar: true })
    );
  };
  return (
    <Grid item container direction="row" justifyContent="flex-end" className={classes.toolbar} spacing={5}>
      <Grid item className={classes.buttons}>
        {modeButtons.map(({ value, icon }, i) => (
          <ButtonIcon
            key={i}
            color={activeButton === value ? 'primary' : 'default'}
            iconSize="large"
            icon={icon}
            tooltip={`${value} Tool`}
            onClick={() => handleToolClick(value)}
          />
        ))}
        {hasChecklistEdit && (
          <ButtonIcon iconSize="large" icon={SnapshotIcon} tooltip={`Capture`} onClick={handleSnapshot} />
        )}
      </Grid>
    </Grid>
  );
};
DicomToolbar.propTypes = {
  id: PropTypes.string.isRequired,
  cornerstoneTools: PropTypes.object.isRequired,
};

export default DicomToolbar;
