import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Paper, Tabs, Tab, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Stats from './Stats';
import ProjectUsage from './Usage/ProjectUsage';
import AssetUsage from './Usage/AssetUsage';
import Title from '../shared/displays/Title';
import TabPanel from '../shared/TabPanel';
import { getUsageStats } from '../../store/getters/usageStatsActions';
import { getCompaniesStats } from '../../store/getters/statsActions';
import { baseRoute } from '../../utilities/strings';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  tabStyles: {
    borderRadius: '3px',
  },
  divider: {
    margin: '0',
  },
  item: {
    paddingBottom: '15px',
  },
  usage: {
    marginTop: '30px',
  },
}));

const tabs = [
  { value: 'overview', label: 'Asset Types', Component: <AssetUsage />, show: true },
  { value: 'project', label: 'Project Types', Component: <ProjectUsage />, show: true },
];

const Workspace = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedTab = useParams().tab;

  useEffect(() => {
    dispatch(getCompaniesStats());
    dispatch(getUsageStats());
  }, [dispatch]);

  const handleTabChange = (event, value) => {
    history.push(baseRoute + '/workspace/usage/' + value);
  };

  const renderTabs = () => (
    <>
      <Grid container direction="column">
        <Grid item className={classes.item}>
          <Title title="Overview" />
        </Grid>
        <Grid item>
          <Stats />
        </Grid>
        <Grid item className={classes.usage}>
          <Paper>
            <Tabs className={classes.tabStyles} value={selectedTab} onChange={handleTabChange}>
              {tabs
                .filter(tab => tab.show)
                .map(tab => (
                  <Tab
                    id={`workspace-tab-${tab.value}`}
                    aria-controls={`workspace-tabpanel-${tab.value}`}
                    key={tab.value}
                    label={tab.label}
                    value={tab.value}
                  />
                ))}
            </Tabs>
            <Divider className={classes.divider} />
            {tabs.map(tab => (
              <TabPanel key={tab.value} value={tab.value} selectedTab={selectedTab} name="workspace">
                {tab.Component}
              </TabPanel>
            ))}
          </Paper>
        </Grid>
      </Grid>
    </>
  );

  return <div className={classes.root}>{renderTabs()}</div>;
};

export default Workspace;
