import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import authReducer from './authReducer';
import snackbarReducer from './snackbarReducer';
import settingsReducer from './settings/settingsReducer';
import featureReducers from './features/featureReducers';
import getterReducers from './getters/getterReducers';
import dialogReducer from './dialogReducer';
import drawerReducer from './drawerReducer';
import { reducer as apiV2Reducer } from './apiV2';

export const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    snackbar: snackbarReducer,
    settings: settingsReducer,
    dialog: dialogReducer,
    drawer: drawerReducer,
    ...getterReducers,
    ...featureReducers,
    apiV2: apiV2Reducer,
  });
