import React from 'react';
import PropTypes from 'prop-types';
import StyledLink from '../shared/StyledLink';

import { usePermissions } from '../../hooks/settingsHooks';
import { isEmpty } from '../../utilities/objects';
import PreviewTooltip from '../projects/PreviewTooltip';
import AssetsView from './AssetsView';

const AssetTooltip = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

const AssetPathDisplay = props => {
  const { asset, allLinks } = props;
  const { hasAssetView } = usePermissions();

  if (isEmpty(asset)) return <></>;

  return (
    <>
      {asset.asset_path_object.map((x, index) => {
        if ((index < asset.asset_path_object.length - 1 && hasAssetView) || (allLinks && hasAssetView)) {
          return (
            <AssetTooltip
              key={x.id}
              condition={x.id === asset.id}
              wrapper={children => (
                <PreviewTooltip
                  arrow
                  componentKey={x.id}
                  placement="right"
                  title={<AssetsView id={x.id} data={asset} />}>
                  {children}
                </PreviewTooltip>
              )}>
              <span>
                <StyledLink to={`/assets/${x.id}`} value={x.name} key={x.name} /> /
              </span>
            </AssetTooltip>
          );
        } else {
          return <>{x.name} /</>;
        }
      })}
    </>
  );
};

AssetPathDisplay.defaultProps = {
  allLinks: false,
};

AssetPathDisplay.propTypes = {
  asset: PropTypes.object.isRequired,
  allLinks: PropTypes.bool,
};

export default AssetPathDisplay;
