import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import LocationLayerProfilesTable from './LocationLayerProfilesTable';
import { getAllLocationLayerProfiles } from '../../store/features/locationLayerProfilesActions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const LocationLayerProfilesTablePage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(getAllLocationLayerProfiles());
  }, []); // eslint-disable-line

  return (
    <div className={classes.root}>
      <LocationLayerProfilesTable title="Location Layer Profiles" />
    </div>
  );
};

export default LocationLayerProfilesTablePage;
