import { useMemo, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { toQueryString, queriesFromString } from '../utilities/strings';
/**
 *
 * @returns {Array} [query, setQuery]
 */
export const useQuery = () => {
  const { search } = useLocation();
  const history = useHistory();

  const query = useMemo(() => queriesFromString(search), [search]);
  const setQuery = useCallback(
    query => {
      const queryString = toQueryString(query);
      history.push({ search: queryString });
    },
    [history]
  );
  return [query, setQuery];
};
