// lib
import React from 'react';
import { IconButton, Divider, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ReportIcon from '@mui/icons-material/Report';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// components
import PrimaryButton from '../../shared/buttons/PrimaryButton';

// util
import { openDialog, closeDialog } from '../../../store/dialogActions';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '20px',
    color: '#e6492d',
    position: 'absolute',
    top: '0',
    right: '0',
  },
  displayRoot: {
    color: 'black',
    textAlign: 'left',
  },
  errorMessage: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
}));

const CalculationLineItemExpressionError = ({ expressionError }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const openErrorDialog = () => {
    dispatch(
      openDialog(
        'Calculation Expression Error',
        <Grid className={classes.displayRoot}>
          <Grid>
            <Grid>
              Error when attempting to evaluate custom expression. This is likely due to sections of the checklist not
              being complete.
            </Grid>
            <Grid>
              Please contact your Configuration Builder if you believe this to be an error with the calculation
              expression
            </Grid>
            <Grid className={classes.errorMessage}>{expressionError.toString()}</Grid>
            <Divider />
            <Grid>
              <details>
                <summary>Stack Trace</summary>
                <pre>
                  <code>{expressionError.stack}</code>
                </pre>
              </details>
            </Grid>
            <Divider />
            <Divider />
            <Grid>
              <PrimaryButton onClick={() => dispatch(closeDialog())} label="Dismiss" />
            </Grid>
          </Grid>
        </Grid>
      )
    );
  };

  return (
    <Grid className={classes.root}>
      <IconButton color="red" onClick={openErrorDialog} size="large">
        <ReportIcon />
      </IconButton>
    </Grid>
  );
};

CalculationLineItemExpressionError.propTypes = {
  expressionError: PropTypes.object.isRequired,
};

export default CalculationLineItemExpressionError;
