import React from 'react';
import PropTypes from 'prop-types';
import StopIcon from '@mui/icons-material/Stop';
import variables from '../../config.module.scss';
import { riskScoreOptions } from '../../api/features/constants';

export const getRiskScore = score => {
  for (const riskScoreOption of riskScoreOptions) {
    if (riskScoreOption.value.toUpperCase() === score.toUpperCase()) {
      return riskScoreOption;
    }
  }

  return {
    color: variables.gray,
    label: score,
  };
};

const AssessmentRiskScoreDisplay = ({ score }) => {
  const display = getRiskScore(score);

  return (
    <span>
      <StopIcon style={{ color: display.color, verticalAlign: 'middle' }} /> {display.label}
    </span>
  );
};

AssessmentRiskScoreDisplay.defaultProps = {
  score: '',
};

AssessmentRiskScoreDisplay.propTypes = {
  score: PropTypes.string,
};

export default AssessmentRiskScoreDisplay;
