import { requestFactory, responseHandlerForm } from '../base';

const get = requestFactory('get');
const post = requestFactory('post', { handler: responseHandlerForm });
const patch = requestFactory('patch', { handler: responseHandlerForm });
const del = requestFactory('del');

export const fetchChecklistTasksList = async query => get('/api/checklist-tasks/', query);
export const fetchChecklistTasks = async id => get(`/api/checklist-tasks/${id}/`);
export const createChecklistTasks = async body => post('/api/checklist-tasks/', body);
export const updateChecklistTasks = async (id, body) => patch(`/api/checklist-tasks/${id}/`, body);
export const deleteChecklistTasks = async id => del(`/api/checklist-tasks/${id}/`);
