import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card, Typography, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Loading from '../shared/displays/Loading';
import StyledLink from '../shared/StyledLink';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  header: {
    padding: theme.spacing(4, 4, 6, 4),
  },
  stats: {
    textAlign: 'center',
  },
}));

const CompanyStats = props => {
  const { data, loading } = useSelector(state => ({
    data: state.stats.company.data.counts || {},
    loading: state.stats.company.loading,
  }));
  const classes = useStyles();
  const history = useHistory();
  const { id } = props;

  const handleLink = link => {
    history.push(link);
  };

  const renderCard = (label, value) => {
    let formattedLabel = '';
    let link = '';
    switch (label) {
      case 'crew':
        formattedLabel = 'Crews';
        break;
      case 'user':
        formattedLabel = 'Users';
        break;
      case 'asset':
        formattedLabel = 'Assets';
        link = `/assets?owner__in=${id}`;
        break;
      case 'project':
        formattedLabel = 'Projects';
        link = `/projects?management_company__in=${id}`;
        break;
      default:
        formattedLabel = label;
    }
    return (
      <Card>
        <Typography variant="h3">{value}</Typography>
        <Typography variant="h6">
          <StyledLink underline={link} onClick={() => handleLink(link)} value={formattedLabel} />
        </Typography>
      </Card>
    );
  };

  return (
    <>
      <Loading loading={loading} />
      <Grid container direction="column" alignItems="stretch" className={classes.root}>
        <Grid item>
          <Grid container direction="row" className={classes.stats}>
            {Object.keys(data).map(item => (
              <Grid item key={item} xs>
                {renderCard(item, data[item])}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

CompanyStats.propTypes = {
  id: PropTypes.number.isRequired,
};

export default CompanyStats;
