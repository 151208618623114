import { all, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  RESERVATION_REQUEST,
  RESERVATION_SUCCESS,
  RESERVATION_FAILURE,
  RESERVATION_CREATE_REQUEST,
  RESERVATION_CREATE_SUCCESS,
  RESERVATION_CREATE_FAILURE,
  RESERVATION_UPDATE_REQUEST,
  RESERVATION_UPDATE_SUCCESS,
  RESERVATION_UPDATE_FAILURE,
  RESERVATION_DELETE_REQUEST,
  RESERVATION_DELETE_SUCCESS,
  RESERVATION_DELETE_FAILURE,
  RESERVATION_ALL_REQUEST,
  RESERVATION_ALL_SUCCESS,
  RESERVATION_ALL_FAILURE,
} from './reservationsActions';

import {
  fetchReservation,
  createReservation,
  updateReservation,
  deleteReservation,
  fetchReservationList,
} from '../../api/features/reservations';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';
import { buildRoute } from '../../utilities/strings';
import { fetchListAll } from '../../utilities/sagaHelpers';
import { DIALOG_CLOSE } from '../../store/dialogActions';

export function* reservationFetch(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield fetchReservation(id);
    if (data) {
      yield put({ type: RESERVATION_SUCCESS, data });
    } else if (error) {
      yield put({ type: RESERVATION_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, RESERVATION_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, RESERVATION_FAILURE);
  }
}

export function* watchReservationFetch() {
  yield takeLatest(RESERVATION_REQUEST, reservationFetch);
}

export function* reservationCreate(action) {
  const { body } = action;
  try {
    const { data, error, formError, response } = yield createReservation(body);
    if (data) {
      yield put({ type: RESERVATION_CREATE_SUCCESS, data });
      yield put(push(buildRoute('/reservations/success')));
    } else if (error) {
      yield put({ type: RESERVATION_CREATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: RESERVATION_CREATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, RESERVATION_CREATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, RESERVATION_CREATE_FAILURE);
  }
}

export function* watchReservationCreate() {
  yield takeLatest(RESERVATION_CREATE_REQUEST, reservationCreate);
}

export function* reservationUpdate(action) {
  const { id, body } = action;
  try {
    const { data, error, formError, response } = yield updateReservation(id, body);
    if (data) {
      yield put({ type: RESERVATION_UPDATE_SUCCESS, data });
      yield put(push(buildRoute('/reservations')));
    } else if (error) {
      yield put({ type: RESERVATION_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: RESERVATION_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, RESERVATION_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, RESERVATION_UPDATE_FAILURE);
  }
}

export function* watchReservationUpdate() {
  yield takeLatest(RESERVATION_UPDATE_REQUEST, reservationUpdate);
}

export function* reservationDelete(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield deleteReservation(id);
    if (data) {
      yield put({ type: RESERVATION_DELETE_SUCCESS });
      yield put({ type: RESERVATION_ALL_REQUEST });
      yield put({ type: DIALOG_CLOSE });
      yield put(push(buildRoute('/reservations')));
    } else if (error) {
      yield put({ type: RESERVATION_DELETE_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, RESERVATION_DELETE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, RESERVATION_DELETE_FAILURE);
  }
}

export function* watchReservationDelete() {
  yield takeLatest(RESERVATION_DELETE_REQUEST, reservationDelete);
}

export function* reservationFetchAll(action) {
  try {
    // get everything to paginate/filter/sort locally
    const { data, error, response } = yield fetchListAll(fetchReservationList, action.query);
    if (data) {
      yield put({ type: RESERVATION_ALL_SUCCESS, data });
    } else if (error) {
      yield put({ type: RESERVATION_ALL_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, RESERVATION_ALL_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, RESERVATION_ALL_FAILURE);
  }
}

export function* watchReservationFetchAll() {
  yield takeLatest(RESERVATION_ALL_REQUEST, reservationFetchAll);
}

export default function* reservationsSaga() {
  yield all([
    watchReservationFetch(),
    watchReservationCreate(),
    watchReservationUpdate(),
    watchReservationDelete(),
    watchReservationFetchAll(),
  ]);
}
