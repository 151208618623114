import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LoadingPage from '../shared/displays/LoadingPage';
import { useGoogleMapLoader } from '../../hooks/mapHooks';

const useStyles = makeStyles(theme => ({
  root: {
    height: `100%`,
  },
}));

const AssetMap = props => {
  const { location } = props;
  const classes = useStyles();
  const [mapRef, setMapRef] = useState(null);

  const latlng = {
    lat: location.coordinates[1],
    lng: location.coordinates[0],
  };

  useEffect(() => {
    if (mapRef) {
      mapRef.setCenter(latlng);
      mapRef.setZoom(15);
    }
    return () => {
      setMapRef(null);
    };
  }, [mapRef]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadMapHandler = map => {
    setMapRef(map);
  };

  const { isLoaded } = useGoogleMapLoader();

  const mapContainerStyle = {
    height: '100%',
  };

  const mapOptions = {
    MapTypeId: 'satellite',
    streetViewControl: false,
  };

  if (!isLoaded) {
    return <LoadingPage />;
  }

  return (
    <Paper className={classes.root}>
      <GoogleMap
        id="map"
        onLoad={loadMapHandler}
        mapContainerStyle={mapContainerStyle}
        center={latlng}
        options={mapOptions}>
        <Marker position={latlng} />
      </GoogleMap>
    </Paper>
  );
};

AssetMap.propTypes = {
  id: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
};

export default AssetMap;
