import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { IconButton, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
// import { locationWithBack } from '../../../utilities/route';

// components
import CustomToolbarSelectMedia from '../shared/table/CustomToolbarSelectMedia';
import ButtonIcon from '../shared/buttons/ButtonIcon';
import InspectionMediaBulkEditDialog from './InspectionMediaBulkEditDialog';
import DeleteDialog from '../shared/Dialog/DeleteDialog';

// util
import { openDialog } from '../../store/dialogActions';
import { useFeatureFlags, usePermissions } from '../../hooks/settingsHooks';
import { getIdFromRow } from '../shared/table/columns';
import { inspectionMediaBulkDeleteEndpoint } from '../../store/apiV2/inspectionMedia';
import { openSnackbar } from '../../store/snackbarActions';

const useStyles = makeStyles(theme => ({
  inheritDisplay: {
    display: 'inherit',
  },
}));

const InspectionMediaToolBar = props => {
  const {
    selectedRows,
    setSelectedRows,
    displayData,
    getInspectionMediaFiles,
    getSelectedRowIds,
    columns,
    tableChangeHandler,
  } = props;
  const dispatch = useDispatch();
  const { dispatchRequest: inspectionMediaBulkDelete } = inspectionMediaBulkDeleteEndpoint.useEndpoint();

  const classes = useStyles();
  const { hasInspectionMediaBulkEdit } = useFeatureFlags();
  const { hasInspectionMediaBulkEdit: hasInspectionMediaBulkEditPermission } = usePermissions();

  // Get array of inspection media IDs out of the table data
  const selectedInspectionMediaIds = selectedRows.data.map(item => getIdFromRow(item, displayData, columns));

  const handleDelete = () => {
    const ids = {
      ids: selectedInspectionMediaIds,
    };
    const onDelete = async () => {
      try {
        await inspectionMediaBulkDelete(ids);
        tableChangeHandler(); // refresh table
      } catch (error) {
        console.error('Error:', error);
        dispatch(openSnackbar(error.error, 'error'));
      }
      setSelectedRows([]);
    };
    dispatch(openDialog('Delete Media?', <DeleteDialog id={ids} onDelete={onDelete} name={'media items'} />));
  };
  const handleBulkEdit = () => {
    dispatch(
      openDialog('Edit Media', <InspectionMediaBulkEditDialog inspectionMediaIds={selectedInspectionMediaIds} />, {
        fullScreen: true,
      })
    );
  };

  const canEditMedia = hasInspectionMediaBulkEdit && hasInspectionMediaBulkEditPermission;

  return (
    <div className={classes.inheritDisplay}>
      {canEditMedia && (
        <ButtonIcon
          icon={EditIcon}
          tooltip="Edit Inspection Media"
          onClick={handleBulkEdit}
          buttonSize="medium"
          iconSize="medium"
        />
      )}
      <CustomToolbarSelectMedia
        selectedRows={selectedRows}
        displayData={displayData}
        getInspectionMediaFiles={getInspectionMediaFiles}
        getSelectedRowIds={getSelectedRowIds}
      />
      {hasInspectionMediaBulkEditPermission && (
        <Tooltip title="Delete Media">
          <IconButton onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};
InspectionMediaToolBar.defaultProps = {
  // disableNewProject: false,
  getInspectionMediaFiles: () => {}, // TODO can we build a default that looks for `file`?
};

InspectionMediaToolBar.propTypes = {
  selectedRows: PropTypes.shape({
    data: PropTypes.array,
    dataIndex: PropTypes.Integer,
  }).isRequired,
  displayData: PropTypes.array.isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  getInspectionMediaFiles: PropTypes.func, // function to extract files for download or zip
  getSelectedRowIds: PropTypes.func, // function to extract zip files
  columns: PropTypes.arrayOf(PropTypes.object).isRequired, // Table column definitions
  tableChangeHandler: PropTypes.func,
};

export default InspectionMediaToolBar;
