import React from 'react';
import PropTypes from 'prop-types';
import Markdown from '../Markdown';

const FieldDisplayString = props => {
  const { label, value } = props;
  return (
    <>
      <div className="h7">{label}</div>
      {value ? <Markdown>{value}</Markdown> : <p>--</p>}
    </>
  );
};

FieldDisplayString.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default FieldDisplayString;
