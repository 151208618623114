import { SNACKBAR_OPEN, SNACKBAR_CLOSE } from './snackbarActions';

const initialState = {
  open: false,
  message: '',
  variant: 'info',
  closeSnackbar: null,
  duration: 10000,
  action: null,
  link: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SNACKBAR_OPEN:
      return {
        open: action.open,
        message: action.message,
        variant: action.variant,
        closeSnackbar: action.closeSnackbar,
        duration: action.duration,
        action: action.action,
        link: action.link,
      };
    case SNACKBAR_CLOSE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
