import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { jsonKeyToLabel, apiDateToString } from '../../utilities/strings';
import { getAssetTypes, createAssetTypes, updateAssetTypes } from '../../store/features/assetTypesActions';
import CommonForm from '../shared/form/Common';
import { fieldOrder, removeField, hideField } from './assetTypesShared';
import { cmlModeOptions, geoModeOptions, libraryModeOptions } from '../../api/features/constants';
import { useFeatureFlags } from '../../hooks/settingsHooks';

const AssetTypesForm = props => {
  const { update } = props;
  const params = useParams();
  const { data, loading, error, formError } = useSelector(state => state.assetTypes.each);
  const [submittedValues, setSubmittedValues] = useState({});
  const dispatch = useDispatch();
  const { hasConditionMonitoring, hasAssetLibrary } = useFeatureFlags();

  useEffect(() => {
    if (update && params.id) {
      dispatch(getAssetTypes(params.id));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formSettings = {
    fieldOrder,
    removeField: removeField(update ? 'UPDATE' : 'CREATE'),
    hideField: (values, item) => {
      return hideField(values, item, { hasConditionMonitoring, hasAssetLibrary });
    },
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      switch (key) {
        /* ---------- Required Fields ---------- */
        case 'name':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          break;
        /* ---------- Regular Fields ---------- */
        case 'manufacturer':
        case 'model':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          break;
        case 'geo_mode':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: 'geo_mode',
              options: geoModeOptions,
              labelwidth: 64,
            },
          };
          break;
        case 'cml_mode':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: 'cml_mode',
              options: cmlModeOptions,
              labelwidth: 64,
            },
          };
          break;
        case 'library_mode':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: 'library_mode',
              options: libraryModeOptions,
              labelwidth: 78,
            },
          };
          break;
        case 'track_asset_condition':
        case 'is_active':
          fieldSettings[key] = {
            type: 'checkbox',
            cellProps: {
              xs: 12,
            },
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          break;

        /* ----------  Disabled Fields ---------- */
        case 'created_on':
        case 'updated_on':
          fieldSettings[key] = {
            type: 'display',
            fieldProps: {
              disabled: true,
              label: jsonKeyToLabel(key),
              name: key,
              value: apiDateToString(data[key], 'date'),
            },
          };
          break;
        /* ----------  Default ---------- */
        default:
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
      }
    });
  }

  const title = update ? 'Edit Asset Type' : 'New Asset Type';

  let initialValues = {};
  if (update) {
    initialValues = { ...data };
  }

  const onSubmit = changedValues => {
    const updatedValues = { ...changedValues };
    setSubmittedValues(updatedValues);
    if (update) {
      dispatch(updateAssetTypes(updatedValues.id, updatedValues));
    } else {
      dispatch(createAssetTypes(updatedValues));
    }
  };

  /**
   * Decorators are used for setting the values of other fields based off of a field.
   * Decorator format can be found here: src/components/shared/form/common.js
   */
  const decorators = [];

  /**
   *  Validations that are per field.
   *  Errors associated with the form are passed back through formError in Redux.
   */
  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        if (values[key] === submittedValues[key]) {
          errors[key] = value;
        } else {
          errors[key] = undefined;
        }
      }
    }
    return errors;
  };

  return (
    <CommonForm
      update={update}
      title={title}
      initialValues={initialValues}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      onSubmit={onSubmit}
      decorators={decorators}
      validate={validate}
      loading={loading}
      error={error}
    />
  );
};

AssetTypesForm.defaultProps = {
  update: false,
};

AssetTypesForm.propTypes = {
  update: PropTypes.bool,
};

export default AssetTypesForm;
