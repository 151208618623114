import React, { useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getAllInspectionMedia } from '../../../store/features/inspectionMediaActions';
import { assetsFiltersEndpoint } from '../../../store/apiV2/assets';
import Error from '../../shared/displays/Error';
import Loading from '../../shared/displays/Loading';
import InspectionMediaMap from './InspectionMediaMap';
import InspectionMediaTable from './InspectionMediaTable';

// constant - to filter out any assets without valid geo points
// const FULL_EARTH_BOUNDING_BOX = [-180, -90, 180, 90].join(',');

// context for passing stuff to InspectionMediaTable
export const InspectionMediaContext = React.createContext(null);

const InspectionMedia = () => {
  // -----
  //
  // State
  //

  const dispatch = useDispatch();
  const assetsFilters = assetsFiltersEndpoint.useEndpoint();

  // gets entire asset directory, super light response
  const { loading, error, results, count } = useSelector(state => {
    const { dataAll, loading, error, isBatchLoading } = state.inspectionMedia.all; // FIXME
    // const { assetFilter } = state;
    return {
      results: dataAll ? dataAll.results : [],
      loading: loading || isBatchLoading,
      error,
      // assetFilter,
      // count: assetFilter.count,
    };
  });

  // set zoom to location to override map
  const [zoomToLocation, setZoomToLocation] = useState(null);

  // const filtersInfo = Object.keys(assetFilter)
  //   .filter(key => !['count', 'loading', 'error', 'updated_on'].includes(key))
  //   .reduce((result, key) => {
  //     result[key] = {
  //       selected: [],
  //       // options: assetFilter[key],
  //       dependencies: [],
  //       label: '',
  //       inFilter: true, // most asset condition filters use __in except labels
  //     };
  //     return result;
  //   }, {});
  // filtersInfo['owner'].dependencies = { ...filtersInfo.owner, dependencies: ['parent'] };
  // filtersInfo['labels'] = { ...filtersInfo.labels, inFilter: false };

  // ------
  //
  // Effects
  //

  useEffect(() => {
    assetsFilters.dispatchRequest();
    dispatch(getAllInspectionMedia());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // ------
  //
  // Render
  //
  //

  if (error) return <Error error={error} />;

  const renderData = () => {
    if (results && results.length === 0) {
      if (loading) {
        return <Loading />;
      }
      return <p>No Data. Please clear your filters.</p>;
    }

    return (
      <>
        <Paper>
          <InspectionMediaMap results={results} zoomToLocation={zoomToLocation} setZoomToLocation={setZoomToLocation} />
        </Paper>
        <Paper>
          <InspectionMediaTable
            title="Inspection Media"
            loading={loading}
            data={results}
            count={count}
            setZoomToLocation={setZoomToLocation}
          />
        </Paper>
      </>
    );
  };

  return <InspectionMediaContext.Provider value={setZoomToLocation}>{renderData()}</InspectionMediaContext.Provider>;
};

export default InspectionMedia;
