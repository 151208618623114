import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import { useHistory, useLocation } from 'react-router';

import Table, { SELECTABLE_ROWS_NONE } from '../shared/table/Table';
import StyledLink from '../shared/StyledLink';
import { setColumns } from '../shared/table/columns';
import { useFeatureFlags, usePermissions } from '../../hooks/settingsHooks';
import { getCustomCSVData, getTableRowDataObject, tableViews } from '../../utilities/tables';
import { heatDataListEndpoint } from '../../store/apiV2/heatData';
import { setTableSize, setTableView, setTableQuery } from '../../store/settings/tableActions';
import { FIELDS } from './heatDataShared';
import { apiDateToString } from '../../utilities/strings';
import { locationWithBack } from '../../utilities/route';
import PrimaryButton from '../shared/buttons/PrimaryButton';
import useDateRangeColumnHook from '../../hooks/table/dateRangeColumnHook';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const HeatDataTable = props => {
  const { title, queryParamObj, embedded, page, views } = props;
  const dispatch = useDispatch();
  const { hasUserEdit, hasProcessDataManageAll } = usePermissions();

  const { hasProcessDataImport } = useFeatureFlags();
  const { data, loading } = heatDataListEndpoint.useEndpoint();
  const { heatDataTableViewKey } = tableViews;
  const location = useLocation();
  const history = useHistory();

  const canImportHeatData = hasProcessDataManageAll && hasProcessDataImport;
  /** @type {Column[]} */
  const columns = [
    {
      // This needs to be first and excluded so customBodyRender can access the id to create the link.
      name: FIELDS.id,
      label: 'Id',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: FIELDS.source.name,
      label: 'Source Asset',
      options: {
        filter: true,
        sort: true,
        filterField: 'source__name',
        customBodyRender: (value, tableMeta) => {
          if (!value) {
            return null;
          }
          const lineObj = getTableRowDataObject(data.results, tableMeta.rowData);
          return <StyledLink to={`/assets/${lineObj.source.id}`}>{lineObj.source.asset_path}</StyledLink>;
        },
      },
    },
    {
      name: FIELDS.heat_number,
      label: 'Heat Number',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: FIELDS.crew, // may not be the same as HUVR crew
      label: 'Crew',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: FIELDS.shift,
      label: 'Shift',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => apiDateToString(value, 'date'),
      },
    },
    {
      name: FIELDS.tap_start,
      label: 'Tap Start',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: FIELDS.grade,
      label: 'Grade',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: FIELDS.grade_type,
      label: 'Grade Type',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: FIELDS.billet_weight,
      label: 'Billet Weight',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: FIELDS.charge_weight,
      label: 'Charge Weight',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: FIELDS.ladle,
      label: 'Ladle',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: FIELDS.uploaded_by.name,
      label: 'Uploaded By',
      options: {
        filter: false,
        sort: true,
        sortField: 'uploaded_by__name',
        downloadBody: value => getCustomCSVData('simple', value),
        customBodyRender: (value, tableMeta) => {
          const lineObj = getTableRowDataObject(data.results, tableMeta.rowData);
          if (!value) return <></>;
          if (!hasUserEdit) return <>{lineObj.uploaded_by.name}</>;
          return <StyledLink to={`/users/${lineObj.uploaded_by.id}`} value={lineObj.uploaded_by.name} />;
        },
      },
    },
    useDateRangeColumnHook({
      name: FIELDS.created_on,
      label: 'Created On',
      queryParamObj,
      queryStartKey: 'created_on_after',
      queryEndKey: 'created_on_before',
    }),
    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        print: false,
        viewColumns: false,
        customBodyRender: (_, info) => {
          return (
            <>
              {/* <EditIcon
                fontSize="small"
                onClick={() => {
                  history.push(locationWithBack({ pathname: `/heat-data/${id}/edit` }, location));
              }}/> */}
              {/* <DeleteIcon
                fontSize="small"
                onClick={() => {
                  dispatch(
                    openDialog('Delete Heat Data?', <DeleteDialog id={id} name={name} deleteAction={async () => {
                      await heatDataDelete.dispatchRequest(id)
                      await heatDataListEndpoint.dispatchRequest()
                    }} />)
                  );
                }}
              /> */}
            </>
          );
        },
      },
    },
  ];

  // handle columns display
  setColumns(columns, views);

  /** @type {TableOptions} */
  const options = {
    // if the column view changes, update redux with either 'add' or 'remove'
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setTableView(changedColumn, action, page, heatDataTableViewKey));
    },
    onChangeRowsPerPage: numberOfRows => {
      dispatch(setTableSize(numberOfRows, page, heatDataTableViewKey));
    },
    customToolbar: () => {
      if (canImportHeatData) {
        return (
          <>
            <PrimaryButton
              label="Import"
              onClick={() => {
                history.push(
                  locationWithBack(
                    { pathname: '/heat-data-imports/new', search: 'action=IMPORT_HEATDATA&flavor=775' },
                    location
                  )
                );
              }}
              icon
              Icon={AddIcon}
            />
          </>
        );
      }
      return null;
    },

    enableNestedDataAccess: '.',
    selectableRows: SELECTABLE_ROWS_NONE,
  };
  const tableChangeHandler = query => {
    if (props.tableChangeHandler) {
      props.tableChangeHandler(query);
    }
    dispatch(setTableQuery(page, query, heatDataTableViewKey));
  };

  return (
    <Table
      title={title}
      serverSide
      columns={columns}
      data={data.results}
      count={data.count}
      options={options}
      loading={loading}
      embedded={embedded}
      queryParamObj={queryParamObj}
      tableChangeHandler={tableChangeHandler}
      views={views}
    />
  );
};

HeatDataTable.defaultProps = {
  embedded: false,
  queryParamObj: {},
  page: '',
  views: {},
};

HeatDataTable.propTypes = {
  title: PropTypes.string.isRequired,
  tableChangeHandler: PropTypes.func.isRequired,
  embedded: PropTypes.bool,
  queryParamObj: PropTypes.object,
  page: PropTypes.string,
  views: PropTypes.object,
};

export default HeatDataTable;
