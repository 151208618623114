import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { TextField } from '@mui/material';
import variables from '../../../config.module.scss';

/**
 * Styles a form field when displaying a string
 */

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: variables.lightGrey,
        borderWidth: '1px',
      },
    },
    '& .MuiFormLabel-root': {
      color: variables.mediumGrey,
      '&:focus': {
        color: variables.mediumGrey,
      },
      '&:hover': {
        borderColor: variables.lightGrey,
      },
    },
  },
}));

const FieldDisplayString = props => {
  const { label, value, multiline } = props;
  const classes = useStyles();
  const fieldProps = {};
  return (
    <TextField
      label={label}
      value={value}
      multiline={multiline}
      {...fieldProps}
      fullWidth
      variant="outlined"
      className={classes.root}
      InputProps={{
        readOnly: true,
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

FieldDisplayString.defaultProps = {
  multiline: false,
};

FieldDisplayString.propTypes = {
  /** sets the label for the form field */
  label: PropTypes.string.isRequired,
  /** sets the value to display in the form field */
  value: PropTypes.string.isRequired,
  multiline: PropTypes.bool,
};

export default FieldDisplayString;
