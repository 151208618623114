import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import GetAppIcon from '@mui/icons-material/GetApp';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteDialog from '../shared/Dialog/DeleteDialog';
import { openDialog } from '../../store/dialogActions';
import { deleteInspectionMedia } from '../../store/features/inspectionMediaActions';
import { downloadFile } from '../../utilities/strings';

const useStyles = makeStyles(theme => ({
  title: {
    color: 'white',
  },
}));

const MoreVertMenu = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { media, isReadOnly } = props;
  const handleClick = e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = e => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick} size="large">
        <MoreVertIcon className={classes.title} />
      </IconButton>
      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuItem
          onClick={e => {
            e.stopPropagation();
            downloadFile(`${media.project.id}-${media.name}`, media.file);
          }}>
          <ListItemIcon>
            <GetAppIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Download" />
        </MenuItem>
        {!isReadOnly && (
          <MenuItem
            onClick={e => {
              e.stopPropagation();
              dispatch(
                openDialog(
                  'Delete Inspection Media?',
                  <DeleteDialog id={media.id} deleteAction={deleteInspectionMedia} name={media.name} />
                )
              );
            }}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Delete" />
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};
MoreVertMenu.defaultProps = { isReadOnly: false };
MoreVertMenu.propTypes = {
  media: PropTypes.object,
  isReadOnly: PropTypes.bool,
};

export default MoreVertMenu;
