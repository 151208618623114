import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import arrayMutators from 'final-form-arrays';
import { jsonKeyToLabel, apiDateToString, dateToApiDateTime } from '../../utilities/strings';
import { updateFindingsBulk } from '../../store/features/defectsActions';
import CommonBulkForm from '../shared/form/CommonBulkForm';
import GeoPoint from '../shared/form/GeoPoint';
import Loading from '../shared/displays/Loading';
import { fieldOrder, removeField, hideField, helperText } from './defectsShared';
import { useFeatureFlags } from '../../hooks/settingsHooks';
import { defectStateOptions } from '../../api/features/constants';
import { numberValidator } from '../../utilities/validators';
import { getLocationLayerProfiles } from '../../store/features/locationLayerProfilesActions';
import { defectProfilesReadEndpoint } from '../../store/apiV2/defectProfiles';

const useStyles = makeStyles(theme => ({
  message: {
    color: 'red',
  },
  center: {
    textAlign: 'center',
  },
}));

const FindingsBulkEditForm = props => {
  const { findingsIds, selectedRows } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const { hasAccessDisplay, hasFindingsPrioritization } = useFeatureFlags();

  const [submittedValues, setSubmittedValues] = useState({});

  const { dataAll, loading, error, formError } = useSelector(state => state.defects.all);
  const defectProfilesRead = defectProfilesReadEndpoint.useEndpoint();
  const defectProfile = defectProfilesRead.data;
  const locationLayerProfile = useSelector(state => state.locationLayerProfiles.each.data);
  const data = {};

  const getUniqueId = property => {
    if ((!Array.isArray(selectedRows?.data) && selectedRows.data.length > 0) || !Array.isArray(dataAll?.results)) {
      return undefined;
    }
    const ids = selectedRows.data.map(row => dataAll.results[row.dataIndex]?.[property]);
    const uniqueId = ids.every(id => id === ids[0]); // if we are only dealing with one, assume we can edit profile and layer
    return uniqueId ? ids[0] : undefined;
  };
  const defectProfileId = getUniqueId('profile');
  const locationLayerProfileId = getUniqueId('layer');

  useEffect(() => {
    if (defectProfileId && defectProfileId !== defectProfile?.id) {
      defectProfilesRead.dispatchRequest(defectProfileId);
    }
  }, [defectProfileId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (locationLayerProfileId && locationLayerProfileId !== locationLayerProfile?.id) {
      dispatch(getLocationLayerProfiles(locationLayerProfileId));
    }
  }, [locationLayerProfileId]); // eslint-disable-line react-hooks/exhaustive-deps

  const formSettings = {
    fieldOrder,
    removeField: removeField('BULK-UPDATE'),
    hideField: (values, item) => {
      return hideField(values, item, {
        hideProtectedFields: false, // hideProtectedFields
        defectProfile,
        locationLayerProfile,
        hasAccessDisplay,
        hasFindingsPrioritization,
        multiple: !defectProfileId || !locationLayerProfileId,
      });
    },
  };
  const getSeverity = () => {
    const field = {
      type: 'text',
      fieldProps: {
        label: 'Severity',
        name: 'severity',
        helperText: defectProfile?.severity_help || '',
        labelwidth: 50,
      },
    };
    if (defectProfile.severity_mode === 'LIST') {
      field.type = 'autocomplete';
      field.fieldProps['options'] = defectProfile.severity_options;
      return field;
    }
    if (defectProfile.severity_mode === 'FREE') {
      return field;
    }
  };
  const getLocationZone = () => {
    const field = {
      type: 'text',
      fieldProps: {
        label: 'Location Zone',
        name: 'location_zone',
        helperText: locationLayerProfile?.zone_help || '',
        labelwidth: 90,
      },
    };
    if (locationLayerProfile.zone_mode === 'LIST') {
      field.type = 'autocomplete';
      field.fieldProps['options'] = locationLayerProfile.zone_options;
      return field;
    }
    if (locationLayerProfile.zone_mode === 'FREE') {
      return field;
    }
  };
  const getLocationCode = () => {
    const field = {
      type: 'text',
      fieldProps: {
        label: 'Location Code',
        name: 'location_code',
        helperText: locationLayerProfile?.code_help || '',
        labelwidth: 80,
      },
    };
    if (locationLayerProfile.code_mode === 'LIST') {
      field.type = 'autocomplete';
      field.fieldProps['options'] = locationLayerProfile.code_options;
      return field;
    }
    if (locationLayerProfile.code_mode === 'FREE') {
      return field;
    }
  };

  const getAccess = () => {
    const field = {
      type: 'text',
      fieldProps: {
        label: 'Access',
        name: 'access',
        helperText: locationLayerProfile?.access_help || '',
        labelwidth: 80,
      },
    };
    if (locationLayerProfile.access_mode === 'LIST') {
      field.type = 'autocomplete';
      field.fieldProps['options'] = locationLayerProfile.access_options;
      return field;
    }
    if (locationLayerProfile.access_mode === 'FREE') {
      return field;
    }
  };

  const getComponent = () => {
    const field = {
      type: 'text',
      fieldProps: {
        label: 'Component',
        name: 'component',
        helperText: locationLayerProfile?.component_help || '',
        labelwidth: 80,
      },
    };
    if (locationLayerProfile.component_mode === 'LIST') {
      field.type = 'autocomplete';
      field.fieldProps['options'] = locationLayerProfile.component_options;
      return field;
    }
    if (locationLayerProfile.component_mode === 'FREE') {
      return field;
    }
  };

  const getDefectType = () => {
    const field = {
      type: 'text',
      fieldProps: {
        label: 'Type',
        name: 'type',
        helperText: defectProfile?.defect_help || '',
        labelwidth: 40,
      },
    };
    if (defectProfile.type_mode === 'LIST') {
      field.type = 'autocomplete';
      field.fieldProps['options'] = defectProfile.type_options;
      return field;
    }
    if (defectProfile.type_mode === 'FREE') {
      return field;
    }
  };

  const getDefectSubType = () => {
    const field = {
      type: 'text',
      fieldProps: {
        label: 'Sub Type',
        name: 'sub_type',
        helperText: defectProfile?.sub_type_help || '',
        labelwidth: 40,
      },
    };
    if (defectProfile.sub_type_mode === 'LIST') {
      field.type = 'autocomplete';
      field.fieldProps['options'] = defectProfile.sub_type_options;
      return field;
    }
    if (defectProfile.sub_type_mode === 'FREE') {
      return field;
    }
  };

  const getLocationGuide = () => {
    const field = {
      type: 'markdown',
      fieldProps: {
        source: locationLayerProfile?.guide || '',
      },
    };
    if (!locationLayerProfile) {
      field.disabled = true;
      return field;
    }
    return field;
  };

  const getDefectGuide = () => {
    const field = {
      type: 'markdown',
      fieldProps: {
        source: defectProfile?.guide || '',
      },
    };
    if (!defectProfile) {
      field.disabled = true;
      return field;
    }
    return field;
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      switch (key) {
        /* ---------- Required Fields ---------- */
        case 'component':
          fieldSettings[key] = getComponent();
          break;
        case 'location_zone':
          fieldSettings[key] = getLocationZone();
          break;
        case 'location_code':
          fieldSettings[key] = getLocationCode();
          break;
        case 'access':
          fieldSettings[key] = getAccess();
          break;
        case 'type':
          fieldSettings[key] = getDefectType();
          break;
        case 'severity':
          fieldSettings[key] = getSeverity();
          break;
        case 'sub_type':
          fieldSettings[key] = getDefectSubType();
          break;
        case 'location_guide':
          fieldSettings[key] = getLocationGuide();
          break;
        case 'defect_guide':
          fieldSettings[key] = getDefectGuide();
          break;
        case 'state':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              options: defectStateOptions,
              labelwidth: 34,
              helperText: helperText[key],
            },
          };
          return;

        /* ---------- Regular Fields ---------- */
        case 'labels':
          fieldSettings[key] = {
            type: 'key-value',
            fieldProps: {
              label: 'Labels',
              name: 'labels',
              helperText: helperText[key],
            },
          };
          return;
        case 'length':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: 'Length',
              name: 'length',
              validator: value => numberValidator(value),
              helperText: helperText[key],
            },
          };
          return;
        case 'width':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: 'Width',
              name: 'width',
              validator: value => numberValidator(value),
              helperText: helperText[key],
            },
          };
          return;
        case 'area':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: 'Area',
              name: 'area',
              validator: value => numberValidator(value),
              helperText: helperText[key],
            },
          };
          return;
        case 'repair_by':
        case 'next_inspection_date':
        case 'captured_on':
        case 'resolved_on':
          fieldSettings[key] = {
            type: 'datetime',
            cellProps: {
              xs: 12,
            },
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              helperText: helperText[key],
            },
          };
          return;
        case 'geo_point':
          fieldSettings[key] = {
            type: 'geo',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              helperText: helperText[key],
            },
          };
          break;
        /* ----------  Disabled Fields ---------- */
        case 'created_on':
        case 'updated_on':
          fieldSettings[key] = {
            type: 'display',
            fieldProps: {
              disabled: true,
              label: jsonKeyToLabel(key),
              name: key,
              value: apiDateToString(data[key], 'date'),
            },
          };
          break;
        /* ----------  Default ---------- */
        default:
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              helperText: helperText[key],
            },
          };
      }
    });
  }

  const title = 'Edit Findings Bulk';

  // The form cannot handle data coming in as objects.  Get id so select field can render the selected option
  // when new, check if we need to apply any values from the queries
  const initialValues = {};

  const onSubmit = changedValues => {
    const updatedValues = { ...changedValues };
    if ('work_done_on' in updatedValues)
      updatedValues['work_done_on'] = dateToApiDateTime(updatedValues['work_done_on']);
    if ('geo_point' in updatedValues) {
      updatedValues['geo_point'] = new GeoPoint(updatedValues['geo_point']).toGeoJSON();
    }

    setSubmittedValues(updatedValues);

    console.log('Updating Findings -> ', findingsIds, changedValues);
    dispatch(updateFindingsBulk(findingsIds, updatedValues, location.pathname));
    props.onSubmit();
  };

  /**
   * Decorators are used for setting the values of other fields based off of a field.
   * Decorator format can be found here: src/components/shared/form/common.js
   */
  const decorators = [];

  /**
   *  Validations that are per field.
   *  Errors associated with the form are passed back through formError in Redux.
   */
  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        if (values[key] === submittedValues[key]) {
          errors[key] = value;
        } else {
          errors[key] = undefined;
        }
      }
    }
    return errors;
  };

  if (loading) {
    return <Loading />;
  }

  // To prevent users accessing through url '/edit'
  const permissionCallback = data => {
    if (data.status === 'PUBLISHED') {
      return { message: 'Published projects cannot be edited.', backLink: `/projects/${data.id}` };
    }
  };

  return (
    <div className={classes.root}>
      <CommonBulkForm
        title={title}
        initialValues={initialValues}
        fieldSettings={fieldSettings}
        formSettings={formSettings}
        onSubmit={onSubmit}
        decorators={decorators}
        validate={validate}
        loading={loading}
        // dirty={dirty}
        error={error}
        mutators={{ ...arrayMutators }}
        keepDirtyOnReinitialize
        customToolbarProps={{ cancel: true }}
        permissionCallback={() => permissionCallback(false, data)}
      />
    </div>
  );
};

FindingsBulkEditForm.defaultProps = {};

FindingsBulkEditForm.propTypes = {
  findingsIds: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedRows: PropTypes.array.isRequired,
};

export default FindingsBulkEditForm;
