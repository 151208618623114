import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../store/dialogActions';

import ProjectsBulkEditForm from './ProjectsBulkEditForm';

const ProjectsBulkEditDialog = props => {
  const { projects } = props;

  const dispatch = useDispatch();

  return (
    <Grid container direction="column" justifyContent="space-between">
      <Grid item>Select the fields you wish to update on the {projects?.ids.length} selected projects.</Grid>
      <Grid item>
        <ProjectsBulkEditForm
          projects={projects}
          onSubmit={() => {
            dispatch(closeDialog());
          }}
        />
      </Grid>
    </Grid>
  );
};

ProjectsBulkEditDialog.defaultProps = {
  query: {},
  routeTo: '/',
  name: '',
};

ProjectsBulkEditDialog.propTypes = {
  projects: PropTypes.object.isRequired,
  name: PropTypes.string,
  query: PropTypes.string,
  routeTo: PropTypes.string,
  confirm: PropTypes.func,
};

export default ProjectsBulkEditDialog;
