import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, IconButton, ImageList, ImageListItemBar, ImageListItem } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { useMediaStore } from '../../../hooks/mediaHooks';
import ButtonIcon from '../buttons/ButtonIcon';
import PreviewTooltip from '../../projects/PreviewTooltip';
import MoreVertMenu from '../../projects/MoreVertMenu';
import Loading from '../displays/Loading';

import { noThumbnail } from '../../../utilities/files';
import { queriesFromString } from '../../../utilities/strings';

const maxTiles = 10;
const useStyles = galleryType =>
  makeStyles(theme => ({
    root: {
      // inspector
      display: 'flex',
      overflow: 'hidden',
    },
    gridList: {
      width: '100%',
    },
    gridListInspector: {
      // marginBotton: 12,
      height: 350,
      // width: '99%',
      transform: 'translateZ(0)',
      flexWrap: 'nowrap',
      alignItems: 'flex-start',
      '&::-webkit-scrollbar': {
        height: '10px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'darkgrey',
      },
      margin: 0,
    },
    gridItem: {
      margin: galleryType !== 'inspector' ? '1% 1%' : '.3rem .3rem',
      width: galleryType !== 'inspector' ? '150px !important' : '100%',
      '&:hover': {
        cursor: 'pointer',
      },
      maxWidth: '150px',
      minWidth: '150px',
    },
    naviButtons: {
      '& button': {
        top: '43%',
        '& svg': {
          fontSize: theme.spacing(3.5),
        },
      },
      margin: 0,
    },
    titleBar: {
      background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    thumbnail: {
      width: '100%',
      height: 'auto',
      maxHeight: '150px',
      maxWidth: '150px',
    },
    preview: {
      objectFit: 'contain',
      width: '100%',
    },
  }));

const InspectorTooltip = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

const MediaGallery = props => {
  const { disableNext, disablePrev, loading, next, previous, media } = useMediaStore();
  const {
    galleryType,
    pageHandler,
    preview,
    selectMedia,
    selected,
    isReadOnly,
    showSelected,
    handleImageClick,
    setMediaSlide,
  } = props;
  const classes = useStyles(galleryType)();

  const handlePageChange = direction => {
    const query = direction ? queriesFromString(direction.split('?')[1]) : {};
    pageHandler(query);
  };

  const namePath = media => {
    if (!media) return <></>;
    const items = [];
    if (media.component) {
      items.push(<>{media.component_display}</>);
    }
    if (media.location_zone) {
      items.push(<>{media.location_zone_display}</>);
    }
    if (media.location_code) {
      items.push(<>{media.location_code_display}</>);
    }
    items.push(<>{media.name}</>);
    return items.map((item, index) => <React.Fragment key={index}>/ {item}</React.Fragment>);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Grid container direction="row" wrap="nowrap">
      <Grid item className={classes.naviButtons}>
        <ButtonIcon icon={ArrowBackIos} onClick={() => handlePageChange(previous)} disabled={disablePrev} />
      </Grid>
      <Grid item container>
        <ImageList
          className={galleryType === 'inspector' ? classes.gridListInspector : classes.gridList}
          cols={media.length >= maxTiles ? maxTiles : media.length}
          rowHeight={160}
          // cellHeight={250}
        >
          {media.map((item, index) => (
            <InspectorTooltip
              key={item.id}
              condition={galleryType === 'inspector'}
              wrapper={children => (
                <PreviewTooltip
                  arrow
                  title={
                    preview ? (
                      <>
                        {item.preview && <img className={classes.preview} src={item.preview} />}
                        <b>{'Findings:'}</b> {item.overlays.length}
                        <br />
                        {namePath(item)}
                      </>
                    ) : (
                      ''
                    )
                  }>
                  {children}
                </PreviewTooltip>
              )}>
              <ImageListItem key={item.id} className={classes.gridItem}>
                <img
                  className={classes.thumbnail}
                  src={item.thumbnail || noThumbnail(item.document_category)}
                  alt={item.name}
                  onClick={
                    galleryType === 'inspector'
                      ? () => {
                          selectMedia(item.id);
                        }
                      : () => {
                          setMediaSlide(item.id);
                        }
                  }
                />
                <ImageListItemBar
                  title={`${item.name} ${item.overlays.length > 0 ? '*' : ''}`}
                  classes={{
                    root: classes.titleBar,
                    title: classes.title,
                  }}
                  className={selected === item.id ? classes.selected : null}
                  actionIcon={
                    galleryType === 'checklist' ? (
                      <IconButton
                        aria-label={`select ${item.name}`}
                        onClick={() => handleImageClick(item, index)}
                        size="large">
                        {showSelected(item)}
                      </IconButton>
                    ) : (
                      <MoreVertMenu media={item} isReadOnly={isReadOnly} />
                    )
                  }
                />
              </ImageListItem>
            </InspectorTooltip>
          ))}
        </ImageList>
      </Grid>
      <Grid item className={classes.naviButtons}>
        <ButtonIcon icon={ArrowForwardIos} onClick={() => handlePageChange(next)} disabled={disableNext} />
      </Grid>
    </Grid>
  );
};

MediaGallery.propTypes = {
  media: PropTypes.array,
  galleryType: PropTypes.string,
  pageHandler: PropTypes.func,
  setMediaSlide: PropTypes.func,
  preview: PropTypes.bool,
  selectMedia: PropTypes.func,
  selected: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  showSelected: PropTypes.func,
  handleImageClick: PropTypes.func,
};

MediaGallery.defaultProps = {
  media: [],
  galleryType: '',
  pageHandler: () => {},
  setMediaSlide: () => {},
  preview: false,
  selectMedia: () => {},
  selected: false,
  isReadOnly: false,
  showSelected: () => {},
  handleImageClick: () => {},
};

export default MediaGallery;
