import { requestFactory, responseHandlerForm } from '../base';

const get = requestFactory('get');
const post = requestFactory('post', { handler: responseHandlerForm });
const patch = requestFactory('patch', { handler: responseHandlerForm });
const del = requestFactory('del');

export const fetchRecommendedWorkPlanList = async query => get('/api/recommended-work-plans/', query);
export const fetchRecommendedWorkPlan = async id => get(`/api/recommended-work-plans/${id}/`);
export const createRecommendedWorkPlan = async body => post('/api/recommended-work-plans/', body);
export const updateRecommendedWorkPlan = async (id, body) => patch(`/api/recommended-work-plans/${id}/`, body);
export const updateRecommendedWorkPlanBulk = async (ids, body) =>
  post('/api/recommended-work-plans/bulk-edit/', { ids, edit: body });
export const deleteRecommendedWorkPlan = async id => del(`/api/recommended-work-plans/${id}/`);
