import { all, put, takeLatest, select, take, fork, cancel } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  INSPECTION_MEDIA_REQUEST,
  INSPECTION_MEDIA_SUCCESS,
  INSPECTION_MEDIA_FAILURE,
  INSPECTION_MEDIA_CREATE_REQUEST,
  INSPECTION_MEDIA_CREATE_SUCCESS,
  INSPECTION_MEDIA_CREATE_FAILURE,
  INSPECTION_MEDIA_UPDATE_REQUEST,
  INSPECTION_MEDIA_UPDATE_SUCCESS,
  INSPECTION_MEDIA_UPDATE_FAILURE,
  INSPECTION_MEDIA_DELETE_REQUEST,
  INSPECTION_MEDIA_DELETE_SUCCESS,
  INSPECTION_MEDIA_DELETE_FAILURE,
  INSPECTION_MEDIA_BULK_UPDATE_REQUEST,
  INSPECTION_MEDIA_BULK_UPDATE_SUCCESS,
  INSPECTION_MEDIA_BULK_UPDATE_FAILURE,
  INSPECTION_MEDIA_BULK_DELETE_REQUEST,
  INSPECTION_MEDIA_BULK_DELETE_SUCCESS,
  INSPECTION_MEDIA_BULK_DELETE_FAILURE,
  INSPECTION_MEDIA_OVERLAY_CREATE_REQUEST,
  INSPECTION_MEDIA_OVERLAY_CREATE_SUCCESS,
  INSPECTION_MEDIA_OVERLAY_CREATE_FAILURE,
  INSPECTION_MEDIA_OVERLAY_UPDATE_REQUEST,
  INSPECTION_MEDIA_OVERLAY_UPDATE_SUCCESS,
  INSPECTION_MEDIA_OVERLAY_UPDATE_FAILURE,
  INSPECTION_MEDIA_OVERLAY_DELETE_REQUEST,
  INSPECTION_MEDIA_OVERLAY_DELETE_SUCCESS,
  INSPECTION_MEDIA_OVERLAY_DELETE_FAILURE,
  INSPECTION_MEDIA_ALL_REQUEST,
  INSPECTION_MEDIA_ALL_SUCCESS,
  INSPECTION_MEDIA_ALL_FAILURE,
  INSPECTION_MEDIA_BATCH_ALL_REQUEST,
  INSPECTION_MEDIA_BATCH_ALL_SUCCESS,
  INSPECTION_MEDIA_BATCH_SUCCESS,
  INSPECTION_MEDIA_ABORT_BATCH_ALL,
  INSPECTION_MEDIA_ALL_UPDATE_DATA,
} from './inspectionMediaActions';

import {
  fetchInspectionMedia,
  createInspectionMedia,
  updateInspectionMedia,
  updateInspectionMediaBulk,
  deleteInspectionMedia,
  deleteInspectionMediaBulk,
  fetchInspectionMediaList,
  createInspectionMediaOverlay,
  updateInspectionMediaOverlay,
  deleteInspectionMediaOverlay,
} from '../../api/features/inspectionMedia';
import { DEFECTS_ALL_REQUEST } from './defectsActions';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';
import { batchFetchAll } from '../../utilities/sagaHelpers';
import { tableViews } from '../../utilities/tables';
import { DIALOG_CLOSE } from '../../store/dialogActions';
import { SET_TABLE_QUERY } from '../../store/settings/tableActions';
import { pathToPage } from '../../utilities/strings';
import { SNACKBAR_OPEN } from '../snackbarActions';
const { inspectionMediaTableViewKey: tableType } = tableViews;

export function* inspectionMediaFetch(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield fetchInspectionMedia(id);
    if (data) {
      yield put({ type: INSPECTION_MEDIA_SUCCESS, data });
    } else if (error) {
      yield put({ type: INSPECTION_MEDIA_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, INSPECTION_MEDIA_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, INSPECTION_MEDIA_FAILURE);
  }
}

export function* watchInspectionMediaFetch() {
  yield takeLatest(INSPECTION_MEDIA_REQUEST, inspectionMediaFetch);
}

export function* inspectionMediaCreate(action) {
  const { body, options } = action;
  try {
    const { data, error, formError, response } = yield createInspectionMedia(body);
    if (data) {
      yield put({ type: INSPECTION_MEDIA_CREATE_SUCCESS, data });
    } else if (error) {
      yield put({ type: INSPECTION_MEDIA_CREATE_FAILURE, error });
      if (options.displaySnackbar) {
        yield put({
          type: SNACKBAR_OPEN,
          message: 'Error creating media file.',
          variant: 'error',
          duration: 5000,
          open: true,
        });
      }
    } else if (formError) {
      yield put({ type: INSPECTION_MEDIA_CREATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, INSPECTION_MEDIA_CREATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, INSPECTION_MEDIA_CREATE_FAILURE);
  }
}

export function* watchInspectionMediaCreate() {
  yield takeLatest(INSPECTION_MEDIA_CREATE_REQUEST, inspectionMediaCreate);
}

export function* inspectionMediaUpdate(action) {
  const { id, body, nextRoute, options } = action;
  try {
    const { data, error, formError, response } = yield updateInspectionMedia(id, body);
    if (data) {
      yield put({ type: INSPECTION_MEDIA_UPDATE_SUCCESS, data });
      if (options.displaySnackbar) {
        yield put({
          type: SNACKBAR_OPEN,
          message: 'Media updated successfully!',
          variant: 'success',
          duration: 5000,
          open: true,
        });
      }
      yield put({ type: INSPECTION_MEDIA_ALL_UPDATE_DATA, data });
      if (nextRoute) {
        yield put(push(nextRoute));
      }
    } else if (error) {
      yield put({ type: INSPECTION_MEDIA_UPDATE_FAILURE, error });
      if (options.displaySnackbar) {
        yield put({
          type: SNACKBAR_OPEN,
          message: error,
          variant: 'error',
          duration: 5000,
          open: true,
        });
      }
    } else if (formError) {
      yield put({ type: INSPECTION_MEDIA_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, INSPECTION_MEDIA_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, INSPECTION_MEDIA_UPDATE_FAILURE);
  }
}

export function* watchInspectionMediaUpdate() {
  yield takeLatest(INSPECTION_MEDIA_UPDATE_REQUEST, inspectionMediaUpdate);
}

export function* inspectionMediaDelete(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield deleteInspectionMedia(id);
    if (data) {
      yield put({ type: INSPECTION_MEDIA_DELETE_SUCCESS });
      const query = yield select(state => state.inspectionMedia.all.query) || '';
      yield put({ type: INSPECTION_MEDIA_ALL_REQUEST, query });
    } else if (error) {
      yield put({ type: INSPECTION_MEDIA_DELETE_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, INSPECTION_MEDIA_DELETE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, INSPECTION_MEDIA_DELETE_FAILURE);
  }
}

export function* watchInspectionMediaDelete() {
  yield takeLatest(INSPECTION_MEDIA_DELETE_REQUEST, inspectionMediaDelete);
}
export function* inspectionMediaBulkUpdate(action) {
  const { ids, body } = action;
  try {
    const { data, error, formError, response } = yield updateInspectionMediaBulk(ids, body);
    if (data) {
      yield put({ type: INSPECTION_MEDIA_BULK_UPDATE_SUCCESS, data });
    } else if (error) {
      yield put({ type: INSPECTION_MEDIA_BULK_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: INSPECTION_MEDIA_BULK_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, INSPECTION_MEDIA_BULK_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, INSPECTION_MEDIA_BULK_UPDATE_FAILURE);
  }
}

export function* watchInspectionMediaBulkUpdate() {
  yield takeLatest(INSPECTION_MEDIA_BULK_UPDATE_REQUEST, inspectionMediaBulkUpdate);
}

export function* inspectionMediaBulkDelete(action) {
  const { ids } = action;
  try {
    const { data, error, response } = yield deleteInspectionMediaBulk(ids);
    if (data) {
      yield put({ type: INSPECTION_MEDIA_BULK_DELETE_SUCCESS });
      const query = yield select(state => state.inspectionMedia.all.query);
      yield put({ type: INSPECTION_MEDIA_ALL_REQUEST, query });
      yield put({ type: DIALOG_CLOSE });
    } else if (error) {
      yield put({ type: INSPECTION_MEDIA_BULK_DELETE_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, INSPECTION_MEDIA_BULK_DELETE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, INSPECTION_MEDIA_BULK_DELETE_FAILURE);
  }
}

export function* watchInspectionMediaBulkDelete() {
  yield takeLatest(INSPECTION_MEDIA_BULK_DELETE_REQUEST, inspectionMediaBulkDelete);
}

export function* inspectionMediaOverlayCreate(action) {
  const { id, body } = action;
  try {
    const { data, error, formError, response } = yield createInspectionMediaOverlay(id, body);
    if (data) {
      yield put({ type: INSPECTION_MEDIA_OVERLAY_CREATE_SUCCESS, data });
      yield put({ type: INSPECTION_MEDIA_ALL_UPDATE_DATA, data });
      // fetch defects after creation
      const query = yield select(state => state.defects.all.query);
      yield put({ type: DEFECTS_ALL_REQUEST, query });
    } else if (error) {
      yield put({ type: INSPECTION_MEDIA_OVERLAY_CREATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: INSPECTION_MEDIA_OVERLAY_CREATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, INSPECTION_MEDIA_OVERLAY_CREATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, INSPECTION_MEDIA_OVERLAY_CREATE_FAILURE);
  }
}

export function* watchInspectionMediaOverlayCreate() {
  yield takeLatest(INSPECTION_MEDIA_OVERLAY_CREATE_REQUEST, inspectionMediaOverlayCreate);
}

export function* inspectionMediaOverlayUpdate(action) {
  const { id, body } = action;
  try {
    const { data, error, formError, response } = yield updateInspectionMediaOverlay(id, body);
    if (data) {
      yield put({ type: INSPECTION_MEDIA_OVERLAY_UPDATE_SUCCESS, data });
      yield put({ type: INSPECTION_MEDIA_ALL_UPDATE_DATA, data });
      // fetch defects after update
      const query = yield select(state => state.defects.all.query);
      yield put({ type: DEFECTS_ALL_REQUEST, query });
    } else if (error) {
      yield put({ type: INSPECTION_MEDIA_OVERLAY_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: INSPECTION_MEDIA_OVERLAY_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, INSPECTION_MEDIA_OVERLAY_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, INSPECTION_MEDIA_OVERLAY_UPDATE_FAILURE);
  }
}

export function* watchInspectionMediaOverlayUpdate() {
  yield takeLatest(INSPECTION_MEDIA_OVERLAY_UPDATE_REQUEST, inspectionMediaOverlayUpdate);
}

export function* inspectionMediaOverlayDelete(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield deleteInspectionMediaOverlay(id);
    if (data) {
      yield put({ type: INSPECTION_MEDIA_OVERLAY_DELETE_SUCCESS });
      // INSPECTION_MEDIA_OVERLAY_UPDATE_REQUEST must not clear the query in the reducer
      const inspectionMediaQuery = yield select(state => state.inspectionMedia.all.query) || '';
      yield put({ type: INSPECTION_MEDIA_ALL_REQUEST, query: inspectionMediaQuery });
      // fetch defects after delete
      const defectsQuery = yield select(state => state.defects.all.query);
      yield put({ type: DEFECTS_ALL_REQUEST, query: defectsQuery });
    } else if (error) {
      yield put({ type: INSPECTION_MEDIA_OVERLAY_DELETE_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, INSPECTION_MEDIA_OVERLAY_DELETE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, INSPECTION_MEDIA_OVERLAY_DELETE_FAILURE);
  }
}

export function* watchInspectionMediaOverlayDelete() {
  yield takeLatest(INSPECTION_MEDIA_OVERLAY_DELETE_REQUEST, inspectionMediaOverlayDelete);
}

export function* inspectionMediaFetchAll(action) {
  const { query } = action;
  try {
    const { data, error, response } = yield fetchInspectionMediaList(action.query);
    if (data) {
      yield put({ type: INSPECTION_MEDIA_ALL_SUCCESS, data });
    } else if (error) {
      yield put({ type: INSPECTION_MEDIA_ALL_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, INSPECTION_MEDIA_ALL_FAILURE);
    }
    // save the query in the store
    if (pathToPage(location.pathname)) {
      const page = pathToPage(location.pathname);
      yield put({ type: SET_TABLE_QUERY, page, query, tableType });
    }
  } catch (error) {
    yield defaultErrorCatch(error, INSPECTION_MEDIA_ALL_FAILURE);
  }
}

export function* watchInspectionMediaFetchAll() {
  yield takeLatest(INSPECTION_MEDIA_ALL_REQUEST, inspectionMediaFetchAll);
}

export function* inspectionMediaBatchFetchAll(action) {
  try {
    const { data, error, response } = yield batchFetchAll(
      fetchInspectionMediaList,
      action.query,
      INSPECTION_MEDIA_BATCH_SUCCESS
    );
    if (data) {
      // yield put({ type: INSPECTION_MEDIA_ALL_SUCCESS });
      yield put({ type: INSPECTION_MEDIA_BATCH_ALL_SUCCESS });
    } else if (error) {
      yield put({ type: INSPECTION_MEDIA_ALL_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, INSPECTION_MEDIA_ALL_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, INSPECTION_MEDIA_ALL_FAILURE);
  } finally {
    // stops the task after success or failure
    yield put({ type: INSPECTION_MEDIA_ABORT_BATCH_ALL });
  }
}

export function* watchInspectionMediaBatchFetchAll() {
  /*
  this pattern deviation handles the cancellation of a batch request
  if another related action type is fired. in this case, a batch all request
  is canceled if 'running' when assetsFetchAll is called
  */
  while (true) {
    const action = yield take(INSPECTION_MEDIA_BATCH_ALL_REQUEST);
    const task = yield fork(inspectionMediaBatchFetchAll, action);
    // task stops running on ASSETS_ALL_REQUEST or ASSETS_ABORT_BATCH_ALL
    const cancelAction = yield take([INSPECTION_MEDIA_ALL_REQUEST, INSPECTION_MEDIA_ABORT_BATCH_ALL]);
    if (cancelAction.type === INSPECTION_MEDIA_ALL_REQUEST) {
      yield cancel(task);
    }
  }
}

export default function* inspectionMediaSaga() {
  yield all([
    watchInspectionMediaFetch(),
    watchInspectionMediaCreate(),
    watchInspectionMediaUpdate(),
    watchInspectionMediaBulkUpdate(),
    watchInspectionMediaFetchAll(),
    watchInspectionMediaDelete(),
    watchInspectionMediaBulkDelete(),
    watchInspectionMediaOverlayCreate(),
    watchInspectionMediaOverlayUpdate(),
    watchInspectionMediaOverlayDelete(),
    watchInspectionMediaBatchFetchAll(),
  ]);
}
