// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
export const LEAD_FILTERS_REQUEST = 'LEAD_FILTERS_REQUEST';
export const LEAD_FILTERS_SUCCESS = 'LEAD_FILTERS_SUCCESS';
export const LEAD_FILTERS_FAILURE = 'LEAD_FILTERS_FAILURE';

export const getLeadFilters = (query = {}) => {
  return {
    type: LEAD_FILTERS_REQUEST,
    query,
  };
};
