import { put } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';
import 'firebase/compat/auth';
import { HANDLE_UNAUTHORIZED } from '../store/authActions';
import { SNACKBAR_OPEN } from '../store/snackbarActions';
import { setServerBuildId } from '../utilities/app.js';
import { getAuthTokenId } from '../utilities/auth';
import { toQueryString } from '../utilities/strings';
/**
 * library for api calls
 * @namespace api/base
 * @public
 */

export const apiURL = process.env.REACT_APP_API_URL_DJANGO;

export const makeURL = path => {
  return apiURL + path;
};

function _getPathFromUrl(url) {
  try {
    // https://regex101.com/r/8ID1o3/3
    return url.match(/https?:\/\/[^/]+([^?#]+).*/)[1] || '';
  } catch (e) {
    return '';
  }
}

const responseHandlerBase = async response => {
  const { status } = response;
  setServerBuildId(response.headers);
  // defaultErrorHandler checks for status === 401 and will logout the user if true
  if (status === 401) return {};
  if (status >= 200 && status < 300) {
    if (status === 204) {
      // when deleting and receiving a 204 status, return data so the saga knows there are no errors.
      return { data: {} };
    }
    const contentType = response.headers.get('content-type');
    if (contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      return { body: await response.blob() };
    }
    if (!contentType || !contentType.includes('application/json')) {
      return { body: await response.text() };
    }
    return { data: await response.json() };
  }

  if (status === 400) {
    const body = await response.clone().json();
    return { error: body.error || body.message || body.non_field_errors };
  }

  if (status >= 405 && status < 500) {
    const contentType = response.headers.get('content-type');
    if (!contentType || !contentType.includes('application/json')) {
      return { body: await response.text() };
    }
    const body = await response.clone().json();
    return { error: body.error || body.message || body.non_field_errors };
  }

  if (status >= 500 && status < 600) {
    const message = `${_getPathFromUrl(response.url)} | Server Error: ${response.status} - ${response.statusText}`;
    Sentry.captureMessage(message);
    return { error: message };
  }
  // return empty object so other handlers can use this as a base
  return {};
};

export const responseHandlerDefault = async response => {
  const { data, error, body } = await responseHandlerBase(response);
  if (data || error) return { data, error };
  if (body || error) return { body, error };
  // return an empty object so the saga knows to use the error in the response.
  return {};
};

export const responseHandlerForm = async response => {
  const { body, data, error } = await responseHandlerBase(response);
  if (data || error) return { data, error };
  if (body || error) return { body, error };

  if (response.status === 400 && response.json) {
    const formError = await response.json();
    // This could be really chatty, but it could let us know what fields users are having trouble with.
    Sentry.captureMessage(
      `${_getPathFromUrl(response.url)} | Error updating form (validation): ${JSON.stringify(formError)}`
    );
    return { formError };
  }
  // return an empty object so the saga knows to use the error in the response.
  return {};
};

export const responseHandlerFile = async response => {
  const { body, data, error } = await responseHandlerBase(response);
  if (response.status === 500) {
    return {
      error: 'Unable to download file.',
    };
  }
  if (body || error) return { body, error };
  if (data || error) return { data, error };

  // return an empty object so the saga knows to use the error in the response.
  return {};
};

//
// Anything passed into factoryOptions
//  will override values passed to their requests
//
export const requestFactory = (method, factoryOptions = {}) => {
  const headers = factoryOptions.headers || { 'Content-Type': 'application/json' };
  const baseOptions = { headers };

  const requestOptions = { ...factoryOptions };
  delete requestOptions.headers;

  const makeRequest = async (endpoint, options, makeRequestOptions = {}) => {
    const handler = makeRequestOptions.handler || responseHandlerDefault;
    const type = makeRequestOptions.type || 'private';
    // _makeRequestOptions is passed directly into fetch
    const _makeRequestOptions = { ...makeRequestOptions };
    delete _makeRequestOptions.handler;
    delete _makeRequestOptions.type;

    const validTypes = ['private', 'public'];
    if (!validTypes.includes(type)) {
      const message = `Invalid requestFactory type: ${type}.  Must be one of [${validTypes}].`;
      console.error(message);
      return {};
    }

    if (type === 'private') {
      let authMode = '';
      try {
        /* ----------------------------------------------------------------------------------------- */
        // The mobile app embeds parts of the website in a webview.
        // The webview cannot easily refresh the idToken.
        // If an api call is made within a webview, the app will inject the inWebview function,
        // which will signal makeRquest to use cookies instead of the idToken
        // This is in a try block so if the function doesn't exist, the error can be ignored
        authMode = setAuthMode('Connected to HUVR.'); // eslint-disable-line no-inner-declarations, no-undef
        /* ----------------------------------------------------------------------------------------- */
      } catch (error) {
        // HUVR_AUTH_MODE doesn't exist so authMode is not updated
      }
      if (authMode === 'cookie') {
        options.headers.credentials = true;
      } else {
        // we grab the token here to make sure we have the latest value before making the fetch call.
        const idToken = getAuthTokenId();
        options.headers.Authorization = `Token ${idToken}`;
      }
    }

    try {
      const response = await fetch(apiURL + endpoint, { ...options, ..._makeRequestOptions });
      const { data, error, body, formError } = await handler(response);
      // if data, error and formError are undefined, we need response to know what to do.
      return { data, error, body, formError, response };
    } catch (error) {
      if (error.message === 'Failed to fetch') {
        // if fetch cannot connect to the api, it will throw an error.
        // passing this along to the defaultErrorHandler so a snackbar can be shown.
        const response = {};
        response.status = 0;
        response.statusText = error.message;
        return { response };
      }
      return { error };
    }
  };

  switch (method) {
    case 'get':
      return async (endpoint, params = '', options = {}) => {
        // when passing in params, it needs to include the '?'
        let paramStr;
        if (typeof params === 'object') {
          paramStr = toQueryString(params);
        } else {
          paramStr = params;
        }
        const path = `${endpoint}${paramStr}`;
        const method = 'GET';
        return makeRequest(path, { method, ...baseOptions, ...options }, requestOptions);
      };
    case 'post':
      return async (endpoint, body, options = {}) => {
        const method = 'POST';
        let bodyContent;
        if (options.formBody) {
          bodyContent = body;
        } else {
          bodyContent = JSON.stringify(body);
        }
        return makeRequest(endpoint, { method, body: bodyContent, ...baseOptions, ...options }, requestOptions);
      };
    case 'patch':
      return async (endpoint, body, options = {}) => {
        const method = 'PATCH';
        let bodyContent;
        if (options.formBody) {
          bodyContent = body;
        } else {
          bodyContent = JSON.stringify(body);
        }
        return makeRequest(endpoint, { method, body: bodyContent, ...baseOptions, ...options }, requestOptions);
      };
    case 'del':
      return async (endpoint, options = {}) => {
        return makeRequest(endpoint, { method: 'DELETE', ...baseOptions, ...options });
      };
    case 'bulkDel':
      return async (endpoint, body, options = {}) => {
        const bodyContent = JSON.stringify(body);
        return makeRequest(endpoint, { method: 'POST', body: bodyContent, ...baseOptions, ...options }, requestOptions);
      };
    default:
      throw new Error(`requestFactory fetch method unsupported: ${method}.`);
  }
};

export function* defaultErrorHandler(response, type) {
  const { status, statusText } = response;
  // if a status needs to do an action through the saga, add an else if after (status === 401)
  let resError = '';
  switch (status) {
    case 0:
      yield put({
        type: SNACKBAR_OPEN,
        open: true,
        message: 'Failed to fetch.  Please check your internet connection.',
        variant: 'error',
      });
      break;
    case 401:
      yield put({ type: HANDLE_UNAUTHORIZED });
      break;
    default:
      // if an error status needs a custom error message, update responseHandlerBase
      resError = `${_getPathFromUrl(response.url)} | ${status} - ${statusText}`;
      Sentry.captureMessage(resError);
      yield put({ type, error: resError });
  }
}

export function defaultErrorCatch(error, type) {
  let errorStr = '';
  if (typeof error === 'string') {
    errorStr = error;
  } else {
    errorStr = error.message;
  }

  Sentry.captureException(errorStr);
  return put({ type, error: errorStr });
}

// possible response statuses for future reference.
// 1×× Informational
// 100 Continue
// 101 Switching Protocols
// 102 Processing

// 2×× Success
// 200 OK
// 201 Created
// 202 Accepted
// 203 Non-authoritative Information
// 204 No Content
// 205 Reset Content
// 206 Partial Content
// 207 Multi-Status
// 208 Already Reported
// 226 IM Used

// 3×× Redirection
// 300 Multiple Choices
// 301 Moved Permanently
// 302 Found
// 303 See Other
// 304 Not Modified
// 305 Use Proxy
// 307 Temporary Redirect
// 308 Permanent Redirect

// 4×× Client Error
// 400 Bad Request
// 401 Unauthorized
// 402 Payment Required
// 403 Forbidden
// 404 Not Found
// 405 Method Not Allowed
// 406 Not Acceptable
// 407 Proxy Authentication Required
// 408 Request Timeout
// 409 Conflict
// 410 Gone
// 411 Length Required
// 412 Precondition Failed
// 413 Payload Too Large
// 414 Request-URI Too Long
// 415 Unsupported Media Type
// 416 Requested Range Not Satisfiable
// 417 Expectation Failed
// 418 I’m a teapot
// 421 Misdirected Request
// 422 Unprocessable Entity
// 423 Locked
// 424 Failed Dependency
// 426 Upgrade Required
// 428 Precondition Required
// 429 Too Many Requests
// 431 Request Header Fields Too Large
// 444 Connection Closed Without Response
// 451 Unavailable For Legal Reasons
// 499 Client Closed Request

// 5×× Server Error
// 500 Internal Server Error
// 501 Not Implemented
// 502 Bad Gateway
// 503 Service Unavailable
// 504 Gateway Timeout
// 505 HTTP Version Not Supported
// 506 Variant Also Negotiates
// 507 Insufficient Storage
// 508 Loop Detected
// 510 Not Extended
// 511 Network Authentication Required
// 599 Network Connect Timeout Error
