import { requestFactory, responseHandlerForm } from '../base';

const get = requestFactory('get');
const post = requestFactory('post', { handler: responseHandlerForm });
const patch = requestFactory('patch', { handler: responseHandlerForm });
const del = requestFactory('del');

export const fetchAssetsList = async query => get('/api/assets/', query);
export const fetchAssetConditionMap = async query => get('/api/assets/asset-condition-map/', query);
export const fetchAssets = async id => get(`/api/assets/${id}/`);
export const createAssets = async body => post('/api/assets/', body);
export const updateAssets = async (id, body) => patch(`/api/assets/${id}/`, body);
export const deleteAssets = async id => del(`/api/assets/${id}/`);
