import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line node/handle-callback-err
  static getDerivedStateFromError(error) {
    // esline-disable-line
    // update state so next render will show the fallback ui
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('ErrorBoundary::logging error', error, errorInfo);
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ 'text-align': 'center' }}>
          <h1>{this.props.message}</h1>
          <h6>For assistance please contact us at support@huvrdata.com</h6>
        </div>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.defaultProps = {
  message: 'Something went wrong.',
};

ErrorBoundary.propTypes = {
  children: PropTypes.object,
  message: PropTypes.string,
};

export default ErrorBoundary;
