import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { fabric } from 'fabric';
import clsx from 'clsx';
import { Divider, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import PanToolIcon from '@mui/icons-material/PanTool';
import CropFreeIcon from '@mui/icons-material/CropFree';
import CreateIcon from '@mui/icons-material/Create';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import GetAppIcon from '@mui/icons-material/GetApp';

import './imageAnnotator.scss';
import { diff } from 'deep-object-diff';
import { fabricRectToCoords, coordsToFabricRect } from './overlaysShared';
import variables from '../../../config.module.scss';
import { changeExtension } from '../../../utilities/files';
import ButtonIcon from '../buttons/ButtonIcon';

// accessed through this.props.classes
const styles = theme => {
  return {
    root: {
      width: '100%',
      height: '100%',
      flexWrap: 'nowrap',
    },
    canvasViewContainer: {
      backgroundColor: theme.palette.mode === 'light' ? variables.white : variables.black,
      width: '100%',
      height: '100%',
    },
    canvasContainer: {
      backgroundColor: theme.palette.mode === 'light' ? variables.white : variables.black,
      width: '100%',
      height: '100%',
    },
    canvasWithButtons: {
      flexGrow: 1,
    },
    sideBar: {
      margin: theme.spacing(2, 3),
    },
    active: {
      color: variables.primary,
    },
  };
};

// DEFAULT - can zoom and pan image.  Annotations can't be drawn or edited
// EDIT - select and modify annotations.  Keep the user from accidentally drawing a new annotation.
// DRAW - Can draw, select and modify annotations.  Each annotation has a note.  Color changes based on defect category
const Modes = {
  DEFAULT: 'DEFAULT',
  EDIT: 'EDIT',
  DRAW: 'DRAW',
};

class ImageAnnotator extends Component {
  constructor(props) {
    super(props);
    this.canvasContainerRef = createRef();
    this.canvasRef = createRef();
    this.noteRef = createRef();
    this.objProps = {
      stroke: 'red',
      strokeWidth: 1,
      strokeUniform: true,
      fill: '',
      cornerColor: 'darkgrey',
      borderColor: 'grey',
      cornerSize: 6,
    };
    this.labelProps = {
      fontSize: 12,
      strokeUniform: true,
      fill: 'white',
      backgroundColor: 'red',
      textAlign: 'justify-center',
      width: 12,
      hasRotatingPoint: false,
      lockScalingX: true,
      lockScalingY: true,
      lockScalingFlip: true,
    };
    // fabric state flags
    this.debug = false; // set this to true to see some debugging console.logs
    this.bounds = {};
    this.initialPos = {};
    this.lastPosX = 0;
    this.lastPosY = 0;
    this.objectSelected = false;
    this.objectMoving = false;
    this.mouseDown = false;
    this.selected = [];
    this.history = [];
    this.savedCanvas = {};

    this.applyOverlays = this.applyOverlays.bind(this);
    this.setBackgroundImage = this.setBackgroundImage.bind(this);
    this.handleUndo = this.handleUndo.bind(this);
    this.handleResetZoom = this.handleResetZoom.bind(this);
    this.handleClearCanvas = this.handleClearCanvas.bind(this);
    this.handleExport = this.handleExport.bind(this);
    this.setCallback = this.setCallback.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
    this.eventHandler = this.eventHandler.bind(this);

    this.state = {
      mode: Modes.DEFAULT,
      scaleFactor: 1,
      offset: {},
      height: 0,
      width: 0,
    };
  }

  /** Helpers ********************/
  convertAndUpdateOverlay(obj) {
    const { overlays, overlayIndex, updateDefect } = this.props;
    const { scaleFactor, offset } = this.state;
    const updatedOverlay = { ...overlays[overlayIndex].overlay };
    updatedOverlay.geometry = fabricRectToCoords(obj, scaleFactor, offset);
    updateDefect(overlayIndex, updatedOverlay);
  }

  selectCurrentOverlay() {
    this.canvas.getObjects().forEach(object => {
      if (object.id === this.props.overlayIndex + 1) {
        this.canvas.setActiveObject(object);
      }
    });
  }

  logEvents(event, opt) {
    // update mouse:move log to only log once while moving
    if (this.debug) {
      console.log(event, opt);
    }
  }

  /** Event Listeners ********************/
  defaultModeEventHandler(event, opt) {
    // only handle events when in DEFAULT mode
    if (this.state.mode !== Modes.DEFAULT) {
      return;
    }
    switch (event) {
      case 'mouse:down':
        this.mouseDown = true;
        this.objectSelected = false;
        this.canvas.setCursor('grab');
        this.lastPosX = opt.e.clientX;
        this.lastPosY = opt.e.clientY;
        break;
      case 'mouse:move':
        if (this.mouseDown) {
          this.canvas.setCursor('grab');
          const delta = {
            x: 0,
            y: 0,
          };
          if (this.lastPosX) delta.x = opt.e.clientX - this.lastPosX;
          if (this.lastPosY) delta.y = opt.e.clientY - this.lastPosY;
          this.lastPosX = opt.e.clientX;
          this.lastPosY = opt.e.clientY;

          const point = new fabric.Point(delta.x, delta.y);
          this.canvas.relativePan(point);
        }
        break;
      case 'mouse:up':
        this.canvas.setViewportTransform(this.canvas.viewportTransform);
        this.canvas.setCursor('pointer');
        this.objectSelected = false;
        this.mouseDown = false;
        break;
      case 'selection:created':
      case 'selection:updated':
        break;
      default:
        console.error(`Event (${event}) is not a handled event in DEFAULT mode.`);
    }
  }

  editModeEventHandler(event, opt) {
    if (this.state.mode !== Modes.EDIT) {
      return;
    }
    switch (event) {
      case 'mouse:down':
        if (opt.selected[0]) {
          this.objectSelected = true;
        }
        break;
      case 'mouse:move':
      case 'mouse:up':
        this.objectSelected = false;
        break;
      case 'selection:created':
        if (this.props.flags.overlayDirty) {
          this.selectCurrentOverlay();
          return;
        }
        // each annotation should have an id.
        // if there is no id, the user most likely selected multiple annotations.  do nothing in this case.
        if (!opt.selected[0].id) {
          this.selectCurrentOverlay();
        } else {
          this.props.setOverlayInfo(prev => ({ ...prev, index: opt.selected[0].id - 1 }));
        }
        break;
      case 'selection:updated':
        if (this.props.flags.overlayDirty) {
          this.selectCurrentOverlay();
          return;
        }
        // each annotation should have an id.
        // if there is no id, the user most likely selected multiple annotations.  do nothing in this case.
        if (!opt.selected[0].id) return;
        this.props.setOverlayInfo(prev => ({ ...prev, index: opt.selected[0].id - 1 }));
        if (!this.props.flags.overlayDirty) {
          this.props.setFlags(prevState => ({ overlayDirty: true }));
        }
        break;
      default:
        console.error(`Event (${event}) is not a handled event in EDIT mode.`);
    }
  }

  drawModeEventHandler(event, opt) {
    if (this.state.mode !== Modes.DRAW) {
      return;
    }
    const { overlays, overlayIndex, addDefect, flags, setShowDirtyError, maxDefects } = this.props;
    /* eslint-disable no-case-declarations */
    switch (event) {
      case 'mouse:down':
        this.mouseDown = true;
        this.objectSelected = false;
        this.initialPos = { ...opt.absolutePointer };
        this.bounds = {};
        break;
      case 'mouse:move':
        if (this.mouseDown) {
          // draw object as the user change the size
          requestAnimationFrame(() => {
            const { x, y } = opt.absolutePointer;
            if (this.initialPos.x > x) {
              this.bounds.x = Math.max(0, x);
              this.bounds.width = this.initialPos.x - x;
            } else {
              this.bounds.x = this.initialPos.x;
              this.bounds.width = x - this.initialPos.x;
            }
            if (this.initialPos.y > y) {
              this.bounds.y = Math.max(0, y);
              this.bounds.height = this.initialPos.y - y;
            } else {
              this.bounds.y = this.initialPos.y;
              this.bounds.height = y - this.initialPos.y;
            }
          });
        }
        break;
      case 'mouse:up':
        // click and drag creation of object
        // firgure out if we're drawing a new annotation or adding to a defect
        let label;
        let newOverlay;
        if (overlays[overlayIndex]) {
          if (overlays[overlayIndex].overlay.geometry === null) {
            // overlay exists, update geometry
            if (flags.overlayDirty) {
              setShowDirtyError({
                state: true,
                from: 'InspectorSidebar:handleTabChange',
                formDirty: flags.formDirty,
                overlayDirty: flags.overlayDirty,
                formNew: flags.formNew,
              });
              return;
            }
            label = overlayIndex + 1;
            newOverlay = false;
            if (!flags.overlayDirty) {
              this.props.setFlags(prevState => ({ overlayDirty: true }));
            }
          } else {
            // the current defect has an overlay, try to create a new defect with this overlay
            if (flags.overlayDirty) {
              setShowDirtyError({
                state: true,
                from: 'InspectorSidebar:handleTabChange',
                message: "Can't draw new annotation - Unsaved Changes",
                formDirty: flags.formDirty,
                overlayDirty: flags.overlayDirty,
                formNew: flags.formNew,
              });
              return;
            }
            label = overlays.length + 1;
            newOverlay = true;
          }
        } else {
          // create a new overlay
          // this should only happen if overlays is an empty array
          // create a new overlay with the values label and newOverlay are declared with.

          label = overlays.length + 1;
          newOverlay = true;
        }
        if (label === undefined || newOverlay === undefined) {
          console.error(
            `Invalid state.  label and newOverlay must have a value (label: ${label}, newOverlay: ${newOverlay}`
          );
          return;
        }

        if (overlays.length >= maxDefects && newOverlay) return;

        // Create Fabric Annotation
        const options = {
          ...this.bounds,
          left: this.bounds.x,
          top: this.bounds.y,
        };
        const rect = new fabric.Rect(options);
        const text = new fabric.Textbox(label.toString(), {
          top: rect.top,
          left: rect.left,
          ...this.labelProps,
        });
        const group = new fabric.Group([rect, text]);
        group.lockRotation = true;
        group.setControlVisible('mtr', false);
        group.id = label;
        this.canvas.add(group);
        this.canvas.setActiveObject(group);
        this.canvas.renderAll();
        // Update ProjectInspector
        const { scaleFactor, offset } = this.state;
        if (newOverlay) {
          addDefect({ geometry: fabricRectToCoords(group, scaleFactor, offset) });
        } else {
          this.convertAndUpdateOverlay(group);
        }
        this.mouseDown = false;
        this.setMode(Modes.EDIT);
        break;
      case 'selection:created':
        // in draw mode don't select overlays
        this.canvas.discardActiveObject();
        break;
      case 'selection:updated':
        break;
      default:
        console.error(`Event (${event}) is not a handled event in DRAW mode.`);
    }
    /* eslint-enable no-case-declarations */
  }

  onMouseDown() {
    this.canvas.on('mouse:down', opt => {
      this.logEvents('mouse:down', opt);
      this.defaultModeEventHandler('mouse:down', opt);
      this.drawModeEventHandler('mouse:down', opt);
    });
  }

  onMouseMove() {
    this.canvas.on('mouse:move', opt => {
      // this.logEvents('mouse:move', opt);
      if (this.objectSelected) return;
      this.defaultModeEventHandler('mouse:move', opt);
      this.drawModeEventHandler('mouse:move', opt);
    });
  }

  onMouseUp() {
    this.canvas.on('mouse:up', opt => {
      this.logEvents('mouse:up', opt);
      if (this.objectSelected) return;
      this.defaultModeEventHandler('mouse:up', opt);
      this.drawModeEventHandler('mouse:up', opt);
    });
  }

  onMouseWheel() {
    this.canvas.on('mouse:wheel', opt => {
      this.logEvents('mouse:wheel', opt);
      const delta = opt.e.deltaY;
      let zoom = this.canvas.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.1) zoom = 0.1;
      this.canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
    });
  }

  eventHandler(event) {
    if (event.defaultPrevented) {
      return; // Should do nothing if the default action has been cancelled
    }

    let handled = false;
    if (event.key === 'ArrowRight') {
      this.handleNext();
      handled = true;
    } else if (event.key === 'ArrowLeft') {
      this.handlePrev();
      handled = true;
    }

    if (handled) {
      // Suppress "double action" if event handled
      event.preventDefault();
    }
  }

  onSelection() {
    this.canvas.on('selection:created', opt => {
      this.logEvents('selection:created', opt, opt.selected[0].id);
      this.drawModeEventHandler('selection:created', opt);
      this.editModeEventHandler('selection:created', opt);
    });
    this.canvas.on('selection:updated', opt => {
      this.logEvents('selection:updated', opt);
      this.drawModeEventHandler('selection:updated', opt);
      this.editModeEventHandler('selection:updated', opt);
    });
  }

  onObjectModified() {
    this.canvas.on('object:modified', opt => {
      this.logEvents('object:modified', opt);
      this.convertAndUpdateOverlay(opt.target, this.state.scaleSettings);
    });
  }

  onObjectMoving() {
    this.canvas.on('object:moving', opt => {
      this.logEvents('object:moving', opt);
    });
  }

  /**  Helper functions ********************/
  setCallback(canvas) {
    canvas.renderAll(canvas);
    this.applyOverlays(this.props.overlays);
  }

  setBackgroundImage(imageURL) {
    // Display a loading indicator
    const loadingIndicator = new fabric.Text('Loading...', {
      left: this.canvas.width / 2,
      top: this.canvas.height / 2,
      originX: 'center',
      originY: 'center',
      fill: 'gray', // Works for both themes
      fontSize: 30, // Set the font size
      fontWeight: 'bold', // Set the font weight
      fontFamily: 'Arial', // Set the font family
    });
    this.canvas.add(loadingIndicator);
    // if there's no image or an error loading the image, don't set the background
    if (!imageURL) {
      this.setState({ error: 'No Image Provided!' });
      return;
    }
    fabric.Image.fromURL(imageURL, (image, error) => {
      // if successful, remove the loading indicator
      if (image) {
        this.canvas.remove(loadingIndicator);
      }
      if (error) {
        // If the image fails to load, display an error message
        loadingIndicator.set({ text: 'Failed to load image.' });
        this.canvas.add(loadingIndicator); // Add the loading indicator back to the canvas with the error message
        return;
      }
      let left = 0;
      let top = 0;
      let scaleFactor = 1;

      const xScale = this.canvas.width / image.width;
      const yScale = this.canvas.height / image.height;

      // figure out which dimension needs to scale less.
      // multiply the scale with the opposide and make sure it's smaller than the canvas size
      // apply equal space around the side that is not scaled to fit the canvas size
      if (xScale * image.height > this.canvas.height) {
        scaleFactor = yScale;
        left = (this.canvas.width - image.width * scaleFactor) / 2;
      } else {
        scaleFactor = xScale;
        top = (this.canvas.height - image.height * scaleFactor) / 2;
      }

      // store scaleFactor and offset of the image to use in overlay coordinate calculations
      this.setState(prevState => ({ ...prevState, scaleFactor, offset: { top, left } }));

      this.canvas.setBackgroundImage(imageURL, this.setCallback.bind(this, this.canvas), {
        top: top,
        left: left,
        originX: 'left',
        originY: 'top',
        scaleX: scaleFactor,
        scaleY: scaleFactor,
        backgroundImageStretch: false,
        crossOrigin: 'Anonymous',
      });
    });
  }

  applyOverlays(overlays) {
    const { scaleFactor, offset } = this.state;

    // if overlays exist, run function to apply coordinates to render fabric objects
    if (overlays.length <= 0) {
      return;
    }

    // make sure scaleFactor and offSet values are stored (set in setBackgroundImage) before drawing overlays
    if (this.canvas.backgroundImage) {
      overlays.forEach((overlay, index) => {
        if (overlay.overlay.geometry && overlay.overlay.geometry.coordinates) {
          const rect = new fabric.Rect(coordsToFabricRect(overlay.overlay.geometry, scaleFactor, offset));
          const text = new fabric.Textbox((index + 1).toString(), {
            top: rect.top,
            left: rect.left,
            ...this.labelProps,
          });

          const group = new fabric.Group([rect, text], {
            id: index + 1,
            lockRotation: true,
          });
          group.setControlVisible('mtr', false);

          this.canvas.add(group);
        }
      });
    }
  }

  /** Lifecycle functions ********************/
  componentDidMount() {
    fabric.Object.prototype.set(this.objProps);
    this.canvas = new fabric.Canvas(this.canvasRef.current, {
      allowTouchScrolling: true,
      stopContextMenu: true,
      freeDrawingCursor: 'none',
      freeDrawingLineWidth: this.lineWidth,
      // fit canvas to parent width and then set height with an aspect ratio
      // TODO: fit canvas in parent
      width: this.canvasContainerRef.current.clientWidth,
      height: this.canvasContainerRef.current.clientHeight,
    });
    // register event listeners
    this.onMouseDown();
    this.onMouseMove();
    this.onMouseUp();
    this.onMouseWheel();
    this.onSelection();
    this.onObjectModified();
    this.onObjectMoving();

    this.setBackgroundImage(this.props.image);

    // apply canvas settings for the default state
    this.setMode(Modes.DEFAULT);

    document.addEventListener('keydown', this.eventHandler);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.eventHandler);
  }

  shouldComponentUpdate(nextProps, nextState) {
    // if the image changes, reset the selected tab to 0
    if (nextProps.image !== this.props.image) {
      this.canvas.clear();
      this.setBackgroundImage(nextProps.image);
      this.props.setOverlayInfo(prev => ({ ...prev, index: 0 }));
      return true;
    }

    // redraw overlays if they have changed.
    const overlaysDiff = diff(this.props.overlays, nextProps.overlays);
    if (Object.keys(overlaysDiff).length !== 0) {
      this.canvas.remove(...this.canvas.getObjects());
      this.applyOverlays(nextProps.overlays);
      return true;
    }

    // if tabs change, select corresponding annotation
    if (nextProps.overlayIndex !== this.props.overlayIndex) {
      this.canvas.discardActiveObject();
      const objects = this.canvas.getObjects();
      objects.forEach(object => {
        if (object.id === nextProps.overlayIndex + 1) {
          this.canvas.setActiveObject(object);
        }
      });
      this.canvas.renderAll();
      return true;
    }

    if (nextState.mode !== this.state.mode) return true;

    return false;
  }

  /** Handlers ********************/
  handleUndo(event) {
    const obj = this.history.pop();
    this.canvas.remove(obj);
  }

  handleClearCanvas(event) {
    this.canvas.clear();
    this.setBackgroundImage(this.props.image);
    this.setMode(this.state.mode);
  }

  handleResetZoom(event) {
    this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
  }

  handleExport(event) {
    const link = document.createElement('a');

    // the output is always PNG from the annotator make it clear
    link.href = this.canvas.toDataURL('image/png', 1.0);
    link.download = changeExtension(this.props.fileName, 'png');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  setScaleSettings(scaleX, scaleY) {
    this.setState(prevState => ({ ...prevState, scaleSettings: { scaleX, scaleY } }));
  }

  setMode(mode) {
    // this.canvas.forEachObject(obj => {
    //   obj.selectable = mode !== Modes.MOVE;
    // });
    switch (mode) {
      case Modes.DEFAULT:
        this.canvas.selection = false;
        this.canvas.skipTargetFind = true;
        this.canvas.renderAll();
        break;
      case Modes.EDIT:
        this.canvas.selection = true;
        this.canvas.skipTargetFind = false;
        this.canvas.renderAll();
        break;
      case Modes.DRAW:
        this.canvas.selection = true;
        this.canvas.skipTargetFind = false;
        this.canvas.renderAll();
        break;
      default:
        console.error(`${mode} is not a valid mode.  ImageAnnotator mode must be either [DEFAULT, EDIT, DRAW].`);
    }
    this.setState(prevState => ({ ...prevState, mode }));
  }

  handleNext() {
    const { flags, setShowDirtyError, nextMedia } = this.props;
    if (flags.formDirty || flags.overlayDirty || flags.formNew) {
      setShowDirtyError({
        state: true,
        from: 'InspectorSidebar:handleTabChange',
        formDirty: flags.formDirty,
        overlayDirty: flags.overlayDirty,
        formNew: flags.formNew,
      });
      return;
    }
    this.setMode(Modes.DEFAULT);
    nextMedia();
  }

  handlePrev() {
    const { flags, setShowDirtyError, prevMedia } = this.props;
    if (flags.formDirty || flags.overlayDirty || flags.formNew) {
      setShowDirtyError({
        state: true,
        from: 'InspectorSidebar:handleTabChange',
        formDirty: flags.formDirty,
        overlayDirty: flags.overlayDirty,
        formNew: flags.formNew,
      });
      return;
    }
    this.setMode(Modes.DEFAULT);
    prevMedia();
  }

  renderCanvas(classes) {
    return (
      <div
        ref={this.canvasContainerRef}
        className={this.props.view ? classes.canvasViewContainer : classes.canvasContainer}>
        <canvas key="test" ref={this.canvasRef} onClick={this.handleCanvasClick} />
      </div>
    );
  }

  renderNav(classes) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        wrap="nowrap"
        className={classes.canvasWithButtons}>
        <Grid item>
          <ButtonIcon
            icon={ArrowBackIos}
            buttonSize="medium"
            iconSize="large"
            onClick={this.handlePrev}
            disabled={this.props.navDisabled}
            tooltip="Previous Image"
          />
        </Grid>
        <Grid item>
          <ButtonIcon
            icon={ArrowForwardIos}
            buttonSize="medium"
            iconSize="large"
            onClick={this.handleNext}
            disabled={this.props.navDisabled}
            tooltip="Next Image"
          />
        </Grid>
      </Grid>
    );
  }

  renderTools(classes) {
    return (
      <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
        <Divider orientation="vertical" flexItem />
        <Grid item>
          <ButtonIcon
            buttonSize="medium"
            iconSize="large"
            icon={PanToolIcon}
            onClick={() => this.setMode(Modes.DEFAULT)}
            tooltip="Pan Tool"
            iconclass={clsx(this.state.mode === Modes.DEFAULT && classes.active)}
          />
        </Grid>
        <Grid item>
          <ButtonIcon
            buttonSize="medium"
            iconSize="large"
            icon={CropFreeIcon}
            onClick={this.handleResetZoom}
            tooltip="Reset Zoom"
          />
        </Grid>
        <Grid item>
          <ButtonIcon
            buttonSize="medium"
            iconSize="large"
            icon={CreateIcon}
            onClick={() => this.setMode(Modes.DRAW)}
            tooltip="Draw Tool"
            iconclass={clsx(this.state.mode === Modes.DRAW && classes.active)}
          />
        </Grid>
        <Grid item>
          <ButtonIcon
            buttonSize="medium"
            iconSize="large"
            icon={OpenWithIcon}
            onClick={() => this.setMode(Modes.EDIT)}
            tooltip="Edit Tool"
            iconclass={clsx(this.state.mode === Modes.EDIT && classes.active)}
          />
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item>
          <ButtonIcon
            label="Export"
            buttonSize="medium"
            iconSize="large"
            icon={GetAppIcon}
            onClick={this.handleExport}
            tooltip="Export Image"
          />
        </Grid>
      </Grid>
    );
  }

  render() {
    const { view, classes, noTools } = this.props;

    if (view) {
      // render without tools
      return this.renderCanvas(classes);
    }
    return (
      <Grid container direction="column" justifyContentContent="flex-start" className={classes.root}>
        <Grid item style={{ flexGrow: 0 }}>
          {this.props.showNavArrows && this.renderNav(classes)}
        </Grid>
        <Grid item style={{ flexGrow: 0 }}>
          {!noTools && this.renderTools(classes)}
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          {this.renderCanvas(classes)}
        </Grid>
      </Grid>
    );
  }
}

/**
 * When this.props.view is set to true, you only need to pass in the image and overlay prop
 * otherwise all the props are required.
 */

ImageAnnotator.defaultProps = {
  maxDefects: 4, // number of tabs that currently fit on the InspectorSidebar
  view: false,
  image: '',
  overlays: [],
  overlayIndex: 0,
  setOverlayInfo: () => {},
  addDefect: () => {},
  updateDefect: () => {},
  nextMedia: () => {},
  prevMedia: () => {},
  showNavArrows: false,
  flags: { formNew: false, formDirty: false, overlayDirty: false },
  setShowDirtyError: () => {},
  noTools: false,
  navDisabled: false,
  setFlags: () => {},
  fileName: '',
};

ImageAnnotator.propTypes = {
  maxDefects: PropTypes.number,
  view: PropTypes.bool,
  image: PropTypes.string,
  overlays: PropTypes.array,
  overlayIndex: PropTypes.number,
  setOverlayInfo: PropTypes.func,
  addDefect: PropTypes.func,
  updateDefect: PropTypes.func,
  nextMedia: PropTypes.func,
  prevMedia: PropTypes.func,
  showNavArrows: PropTypes.bool,
  navDisabled: PropTypes.bool,
  flags: PropTypes.shape({
    formNew: PropTypes.bool,
    formDirty: PropTypes.bool,
    overlayDirty: PropTypes.bool,
  }),
  setShowDirtyError: PropTypes.func,
  classes: PropTypes.object.isRequired,
  noTools: PropTypes.bool,
  setFlags: PropTypes.func,
  fileName: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(ImageAnnotator);
