import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Menu, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from 'react-redux';
import BookmarkForm from './BookmarkForm';
import StyledLink from '../StyledLink';
import { openDialog } from '../../../store/dialogActions';
import { bookmarksDeleteEndpoint, bookmarksListAllEndpoint } from '../../../store/apiV2/bookmarks';
import DeleteDialog from '../Dialog/DeleteDialog';

const useStyles = makeStyles(theme => ({
  bookmark_name: {
    marginLeft: theme.spacing(1),

    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  bookmarkLink: {
    padding: 0,
  },
}));

const favicon = domain => `https://www.google.com/s2/favicons?sz=16&domain_url=${domain}`;

const Bookmark = ({ bookmark, contentType, enableMenu }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const bookmarksDelete = bookmarksDeleteEndpoint.useEndpoint();
  const bookmarksListAll = bookmarksListAllEndpoint.useEndpoint();

  const onRightClick = e => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
    setOpenMenu(true);
  };

  const handleEdit = () => {
    dispatch(
      openDialog(
        'Edit Bookmark',
        <BookmarkForm id={bookmark.object_id} bookmark={bookmark} contentType={contentType} />
      )
    );
  };
  const handleDelete = () => {
    const onDelete = () =>
      bookmarksDelete
        .dispatchRequest(bookmark.id)
        .then(bookmarksListAll.dispatchRequest({ content_type: contentType, object_id: bookmark.object_id }));
    dispatch(openDialog('Delete Bookmark', <DeleteDialog id={bookmark.id} name={bookmark.name} onDelete={onDelete} />));
  };

  return (
    <Grid item className={classes.bookmarkLink}>
      <StyledLink onClick={() => window.open(bookmark.link, '_blank')} onContextMenu={enableMenu ? onRightClick : null}>
        <img src={favicon(bookmark.link)} />
        <span className={classes.bookmark_name}>{bookmark.name}</span>
      </StyledLink>
      {/* using onClick prop in Menu closes menu when selecting an item or clicking away */}
      <Menu open={openMenu} anchorEl={anchorEl} onClick={() => setOpenMenu(false)}>
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    </Grid>
  );
};

Bookmark.propTypes = {
  bookmark: PropTypes.object.isRequired,
  contentType: PropTypes.oneOf(['asset', 'project']).isRequired,
  enableMenu: PropTypes.bool.isRequired,
};

export default Bookmark;
