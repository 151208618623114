import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { TextField } from 'mui-rff';
import PreviewTooltip from '../../projects/PreviewTooltip';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    height: '100%',
  },
}));

const TextTooltip = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

const TextLineItem = props => {
  const { name, label, value, placeholder, loading, disabled, multiline, helperText, inputPropClasses, validator } =
    props;
  const classes = useStyles();
  // to use the form values, the value prop needs to be missing (can't be undefined or empty string)
  // only show the value if it is passed in as a prop.
  const moreProps = {};
  if (value) moreProps.value = value;
  return (
    <TextTooltip
      condition={label.length > 130}
      wrapper={children => (
        <PreviewTooltip arrow title={label}>
          {children}
        </PreviewTooltip>
      )}>
      <TextField
        {...moreProps}
        label={label}
        name={name}
        placeholder={placeholder}
        multiline={multiline}
        helperText={helperText}
        disabled={disabled || loading}
        variant="filled"
        fieldProps={{ validate: validator }}
        InputProps={{
          classes: {
            input: inputPropClasses,
          },
        }}
        className={classes.root}
      />
    </TextTooltip>
  );
};

TextLineItem.defaultProps = {
  disabled: false,
  required: false,
  multiline: false,
  value: undefined,
  placeholder: '',
  loading: false,
  inputPropClasses: '',
  validator: () => {},
};

TextLineItem.propTypes = {
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  multiline: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  loading: PropTypes.bool,
  inputPropClasses: PropTypes.string,
  validator: PropTypes.func,
};

export default TextLineItem;
