import { all, call, takeLatest, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import * as Sentry from '@sentry/browser';
import { history } from '../providers/StoreProvider';

import {
  LOOKUP_RESET,
  LOOKUP_REQUEST,
  LOOKUP_SUCCESS,
  setLandingUrl,
  LOOKUP_CLEAR_ROUTE,
  LOOKUP_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_FAILURE,
  HANDLE_UNAUTHORIZED,
  HANDLE_UNAUTHORIZED_COMPLETED,
  REFRESH_SESSION_COOKIE_FAILURE,
  REFRESH_SESSION_COOKIE_SUCCESS,
  REFRESH_SESSION_COOKIE_REQUEST,
} from './authActions';
import { requestFeatures, clearFeatures } from './settings/featuresActions';
import { lookupUser, sessionLogin, sessionLogout } from '../api/auth';
import { defaultErrorHandler, defaultErrorCatch } from '../api/base';
import { setAuthTokenId, getAuthTokenId, AUTH_COOKIE_NAME, openMobileDeeplink } from '../utilities/auth';
import { deleteCookie } from '../utilities/strings';

export function* lookupFirebase(action) {
  const { email, route } = action;
  try {
    const { data, error, formError, response } = yield lookupUser(email);
    if (data) {
      // check that the user has a way to login
      if (data.providers.length === 0) {
        yield put({
          type: LOOKUP_FAILURE,
          error:
            'This user has no options for logging in set.  Please ensure the domain is listed in your Identity Providers',
        });
      }

      yield put({
        type: LOOKUP_SUCCESS,
        email,
        route,
        apiKey: data.api_key,
        authDomain: data.auth_domain,
        projectId: data.project_id,
        tenantId: data.tenant_id,
        providers: data.providers,
      });
    } else if (error) {
      yield put({ type: LOOKUP_FAILURE, error });
    } else if (formError) {
      yield put({ type: LOOKUP_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, LOOKUP_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, LOOKUP_FAILURE);
  }
}

function* watchLookupFirebase() {
  yield takeLatest(LOOKUP_REQUEST, lookupFirebase);
}

export function* lookupReset() {
  // Delete the DEFAULT Firebase app if it exists to reset the auth lookup providers
  let app;
  try {
    app = firebase.app();
  } catch (error) {
    console.log(`No Default Firebase App to delete`);
    return;
  }

  app
    .delete()
    .then(() => {
      console.log(`Firebase App ${app.container.name} deleted.`);
    })
    .catch(error => {
      Sentry.captureMessage('lookupReset() failed - ' + error);
      console.log('Error deleting Firebase App.', error);
    });
}

function* watchLookupReset() {
  yield takeLatest(LOOKUP_RESET, lookupReset);
}

async function getFirebaseUserIdToken() {
  const idToken = await firebase.auth().currentUser.getIdToken();
  const userId = firebase.auth().currentUser.uid;
  setAuthTokenId(idToken);
  Sentry.setUser({ id: userId });

  return idToken;
  // This is the firebase observer that is supposed run when token changed but it's not working. Created stayLoggedIn() in FeatureRoute.js to mimic functionality.
  // firebase.auth().onIdTokenChanged(async function(user) {
  //   if (user) {
  //     window.localStorage.setItem('idToken', await user.getIdToken(true))
  //   }
  // });
}

export function* login(action) {
  try {
    yield call(getFirebaseUserIdToken);
    /* ------------------------------------------------------------ */

    if (action?.route === 'login-loading-mobile') {
      const idToken = getAuthTokenId();

      try {
        // mobileCallback was used in earlier versions of the IF+ login flow
        // This function will not exist in newer versions of IF+
        // Delete this block after IF+ versions less than 3.23 are no longer in use
        mobileCallback(idToken); // eslint-disable-line no-undef
      } catch (error) {
        // Attempt to launch the deep link for mobile login
        openMobileDeeplink(idToken);
      }
    } else {
      // Normal web (non-mobile) login flow
      yield put(requestFeatures());
      yield put({ type: LOGIN_SUCCESS });
      yield refreshSessionCookie();
    }
    const landingUrl = yield select(state => state.auth.logInOut.landingUrl); // Get the landing URL from Redux state
    if (landingUrl) {
      yield put(setLandingUrl(null));
      if (landingUrl.includes('/admin')) {
        // need to trigger a full page reload to get the admin page to load correctly
        window.open(landingUrl, '_self');
      } else {
        yield put(push(landingUrl));
      }
    } else if (action.route) {
      yield put(push(action.route));
    }
  } catch (error) {
    yield defaultErrorCatch(error, LOGIN_FAILURE);
  }
}

function* watchLogin() {
  yield takeLatest(LOGIN_REQUEST, login);
}

export function* logout(action) {
  // stayLoggedIn, which downloads a new idToken, runs every 5 seconds.
  // need to make sure setting the token to null in localstorage happens after firebase signout
  // otherwise, the idToken will still be in localstorage and the user can still access the website
  const next = '/login';
  try {
    const interval = yield select(state => state.settings.local.refreshInterval);
    yield clearInterval(interval);
    yield put({ type: LOOKUP_RESET });
    yield put({ type: LOOKUP_CLEAR_ROUTE });
    yield put(clearFeatures());
    yield firebase.auth().signOut();
    const idToken = getAuthTokenId();
    sessionLogout(idToken);
    deleteCookie(AUTH_COOKIE_NAME);
    setAuthTokenId(null);
    yield put(push({ pathname: next }));
  } catch (error) {
    yield put({ type: LOGOUT_FAILURE, error });
    setAuthTokenId(null);
    yield put(push({ pathname: next }));
  }
}

function* watchLogout() {
  yield takeLatest(LOGOUT_REQUEST, logout);
}

export function* handleUnauthorized(action) {
  const next = '/login';
  try {
    const location = history.location;
    const pathWithParams = window.location.href.slice(window.location.origin.length);
    // some pages have multiple api calls.  We only want to send them to the login page on the first 401 error
    // otherwise we will lose the path for deep linking since location.pathname will become '/login'
    if (pathWithParams !== '/login') {
      console.warn('Unauthorized 401 - Logging User Out');
      yield put(setLandingUrl(pathWithParams)); // Save the landing URL to the Redux state
      yield put({ type: LOGOUT_REQUEST });
    } else {
      yield put(push({ pathname: next, state: location.state }));
    }
  } catch (error) {
    console.error('Error while handling unauthorized', error);
    yield put({ type: HANDLE_UNAUTHORIZED_COMPLETED, error });
    yield put({ type: LOOKUP_RESET });
    yield put(push({ pathname: next }));
  }
}

function* watchHandleUnauthorized() {
  yield takeLatest(HANDLE_UNAUTHORIZED, handleUnauthorized);
}

export function* refreshSessionCookie() {
  try {
    const idToken = getAuthTokenId();

    const { data, error, response } = yield sessionLogin(idToken);

    if (data) {
      console.debug('Session cookie refreshed successfully', { data });
      yield put({ type: REFRESH_SESSION_COOKIE_SUCCESS });
    } else {
      console.warn('Unable to refresh session cookie: ', { error, data });
      yield defaultErrorHandler(response, REFRESH_SESSION_COOKIE_FAILURE);
    }
  } catch (error) {
    console.error('Unable to refresh session cookie: ', { error });
    yield defaultErrorCatch(error, REFRESH_SESSION_COOKIE_FAILURE);
  }
}

function* watchRequestRefreshSessionCookieRequest() {
  yield takeLatest(REFRESH_SESSION_COOKIE_REQUEST, refreshSessionCookie);
}

export default function* authSaga() {
  yield all([
    watchLookupFirebase(),
    watchLookupReset(),
    watchLogin(),
    watchLogout(),
    watchHandleUnauthorized(),
    watchRequestRefreshSessionCookieRequest(),
  ]);
}
