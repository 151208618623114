import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { useLocation } from 'react-router';
import { getExtensionFromUrl } from '../../../utilities/files';
import VideoPlayer from '../../inspectionMedia/VideoPlayer';
import KmlLayerMap from '../../inspectionMedia/KmlLayerMap';
import ImageAnnotator from '../imageTools/ImageAnnotator';
import GTLFViewer from '../modelViewers/GTLFViewer';
import OBJViewer from '../modelViewers/OBJViewer';
import PointCloudViewer from '../modelViewers/PointCloudViewer';
import PDFViewer from '../PDFViewer/PDFViewer';
import DicomViewer from '../dicomViewer/DicomViewer';
import Error from '../displays/Error';
import { oneDayAgo } from '../../../utilities/strings';
import { getParams } from '../../../utilities/route';
import NoPreview from '../displays/NoPreview';

const useStyles = makeStyles(theme => ({
  notSupported: {
    width: theme.spacing(50),
  },
  notSupportedText: {
    textAlign: 'center',
    fontSize: 16,
    margin: theme.spacing(2),
  },
}));

const CREATED = 'CREATED';
// Alternatively try to display files based on MIME/media type
const fileViewerByMediaType = (mediaToDisplay, overlays, classes) => {
  switch (mediaToDisplay.media_type) {
    case 'video/mp3':
    case 'video/webm':
    case 'video/mp4':
    case 'video/m4v':
    case 'video/mov':
      return <VideoPlayer media={mediaToDisplay} />;
    case 'image/jpg':
    case 'image/jpeg':
    case 'image/gif':
    case 'image/bmp':
    case 'image/png':
      return <ImageAnnotator view image={mediaToDisplay.file} overlays={overlays} />;
    case 'application/vnd.google-earth.kml+xml':
    case 'application/vnd.google-earth.kmz':
      if (mediaToDisplay.size < 3000000) {
        return <KmlLayerMap file={mediaToDisplay.file} />;
      }
      return <div className={classes.notSupportedText}>File is too big to preview. Download to view.</div>;
    case 'model/obj':
      return <OBJViewer file={mediaToDisplay.file} />;
    case 'model/gltf-binary':
    case 'model/gltf+json':
      return <GTLFViewer file={mediaToDisplay.file} />;
    case 'pcd':
      return <PointCloudViewer file={mediaToDisplay.file} />;
    case 'application/pdf':
      return <PDFViewer fileUrl={mediaToDisplay.file} />;
    case 'application/dicom':
      return <DicomViewer file={mediaToDisplay.file} id={mediaToDisplay.id} />;
    default:
      return <NoPreview url={mediaToDisplay.file} />;
  }
};

const FileViewer = ({ media }) => {
  const location = useLocation();
  const query = getParams(location);
  const timestamp = query?.t ? parseInt(query.t) : 0; // timestamp is in seconds
  const classes = useStyles();

  const getDisplay = media => {
    if (media.state === CREATED && media.created_on) {
      if (Date.parse(media.created_on) < oneDayAgo()) {
        return { errorMessage: 'Upload failed. Please upload again.' };
      } else {
        return { errorMessage: 'Uploading.' };
      }
    }

    if (media?.alternate_media?.file) {
      if (media.alternate_media.state === CREATED) {
        return { errorMessage: 'File is still processing. Try again later.' };
      } else {
        return {
          ...media,
          file: media.alternate_media.file,
          alternate: true,
        };
      }
    }
    return { ...media, alternate: false };
  };

  // ProjectInspector reshapes the overlay array so the ImageAnnotator knows how to handle it.
  let overlays = [];
  if (media && media.overlays) {
    // prevents crash when handling shallow overlay arrays ([id, id, id]) PR #8186
    overlays = media.overlays.map(overlay =>
      typeof overlay === 'object' && 'overlay' in overlay ? overlay : { overlay }
    );
  }

  const mediaToDisplay = getDisplay(media);

  if (mediaToDisplay.errorMessage) {
    return <Error message={mediaToDisplay.errorMessage} style={{ textAlign: 'center' }} />; // returns status message
  }

  const fileExt = getExtensionFromUrl(mediaToDisplay.file).toLowerCase();
  switch (fileExt) {
    case 'mp3':
    case 'webm':
    case 'mp4':
    case 'm4v':
    case 'mov':
      return <VideoPlayer media={mediaToDisplay} timestamp={timestamp} />;
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'bmp':
    case 'png':
      return <ImageAnnotator view image={mediaToDisplay.file} overlays={overlays} />;
    case 'tif':
    case 'tiff':
      // show preview instead of file, tiff no viewed in browser
      return <ImageAnnotator view image={mediaToDisplay.preview} overlays={overlays} />;
    case 'kml':
    case 'kmz':
      if (media.size < 3000000) {
        return <KmlLayerMap file={mediaToDisplay.file} />;
      }
      return <div className={classes.notSupportedText}>File is too big to preview. Download to view.</div>;
    case 'obj':
      return <OBJViewer file={mediaToDisplay.file} />;
    case 'glb':
    case 'gltf':
      return <GTLFViewer file={mediaToDisplay.file} />;
    case 'pcd':
      return <PointCloudViewer file={mediaToDisplay.file} />;
    case 'pdf':
      return <PDFViewer fileUrl={mediaToDisplay.file} />;
    case 'dcm':
      return <DicomViewer file={mediaToDisplay.file} id={mediaToDisplay.id} />;
    default:
      return fileViewerByMediaType(mediaToDisplay, overlays, classes);
  }
};

FileViewer.propTypes = {
  media: PropTypes.object,
};
FileViewer.defaultProps = { media: {} };

export default FileViewer;
