import { all, put, takeLatest, select } from 'redux-saga/effects';
import {
  NOTIFICATION_RULE_SUBSCRIBERS_REQUEST,
  NOTIFICATION_RULE_SUBSCRIBERS_SUCCESS,
  NOTIFICATION_RULE_SUBSCRIBERS_FAILURE,
  NOTIFICATION_RULE_SUBSCRIBERS_CREATE_REQUEST,
  NOTIFICATION_RULE_SUBSCRIBERS_CREATE_SUCCESS,
  NOTIFICATION_RULE_SUBSCRIBERS_CREATE_FAILURE,
  NOTIFICATION_RULE_SUBSCRIBERS_UPDATE_REQUEST,
  NOTIFICATION_RULE_SUBSCRIBERS_UPDATE_SUCCESS,
  NOTIFICATION_RULE_SUBSCRIBERS_UPDATE_FAILURE,
  NOTIFICATION_RULE_SUBSCRIBERS_DELETE_REQUEST,
  NOTIFICATION_RULE_SUBSCRIBERS_DELETE_SUCCESS,
  NOTIFICATION_RULE_SUBSCRIBERS_DELETE_FAILURE,
  NOTIFICATION_RULE_SUBSCRIBERS_ALL_REQUEST,
  NOTIFICATION_RULE_SUBSCRIBERS_ALL_SUCCESS,
  NOTIFICATION_RULE_SUBSCRIBERS_ALL_FAILURE,
} from './notificationRuleSubscribersActions';

import {
  fetchNotificationRuleSubscribers,
  createNotificationRuleSubscribers,
  updateNotificationRuleSubscribers,
  deleteNotificationRuleSubscribers,
  fetchNotificationRuleSubscribersList,
} from '../../api/features/notificationRuleSubscribers';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';
import { fetchListAll } from '../../utilities/sagaHelpers';
import { DIALOG_CLOSE } from '../../store/dialogActions';

export function* notificationRuleSubscribersFetch(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield fetchNotificationRuleSubscribers(id);
    if (data) {
      yield put({ type: NOTIFICATION_RULE_SUBSCRIBERS_SUCCESS, data });
    } else if (error) {
      yield put({ type: NOTIFICATION_RULE_SUBSCRIBERS_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, NOTIFICATION_RULE_SUBSCRIBERS_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, NOTIFICATION_RULE_SUBSCRIBERS_FAILURE);
  }
}

export function* watchNotificationRuleSubscribersFetch() {
  yield takeLatest(NOTIFICATION_RULE_SUBSCRIBERS_REQUEST, notificationRuleSubscribersFetch);
}

export function* notificationRuleSubscribersCreate(action) {
  const { body } = action;
  try {
    const { data, error, formError, response } = yield createNotificationRuleSubscribers(body);
    if (data) {
      yield put({ type: NOTIFICATION_RULE_SUBSCRIBERS_CREATE_SUCCESS, data });
      yield put({ type: DIALOG_CLOSE });
      const query = yield select(state => state.notificationRuleSubscribers.all.query);
      yield put({ type: NOTIFICATION_RULE_SUBSCRIBERS_ALL_REQUEST, query });
    } else if (error) {
      yield put({ type: NOTIFICATION_RULE_SUBSCRIBERS_CREATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: NOTIFICATION_RULE_SUBSCRIBERS_CREATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, NOTIFICATION_RULE_SUBSCRIBERS_CREATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, NOTIFICATION_RULE_SUBSCRIBERS_CREATE_FAILURE);
  }
}

export function* watchNotificationRuleSubscribersCreate() {
  yield takeLatest(NOTIFICATION_RULE_SUBSCRIBERS_CREATE_REQUEST, notificationRuleSubscribersCreate);
}

export function* notificationRuleSubscribersUpdate(action) {
  const { id, body } = action;
  try {
    const { data, error, formError, response } = yield updateNotificationRuleSubscribers(id, body);
    if (data) {
      yield put({ type: NOTIFICATION_RULE_SUBSCRIBERS_UPDATE_SUCCESS, data });
    } else if (error) {
      yield put({ type: NOTIFICATION_RULE_SUBSCRIBERS_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: NOTIFICATION_RULE_SUBSCRIBERS_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, NOTIFICATION_RULE_SUBSCRIBERS_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, NOTIFICATION_RULE_SUBSCRIBERS_UPDATE_FAILURE);
  }
}

export function* watchNotificationRuleSubscribersUpdate() {
  yield takeLatest(NOTIFICATION_RULE_SUBSCRIBERS_UPDATE_REQUEST, notificationRuleSubscribersUpdate);
}

export function* notificationRuleSubscribersDelete(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield deleteNotificationRuleSubscribers(id);
    if (data) {
      yield put({ type: NOTIFICATION_RULE_SUBSCRIBERS_DELETE_SUCCESS });
      const query = yield select(state => state.notificationRuleSubscribers.all.query);
      yield put({ type: NOTIFICATION_RULE_SUBSCRIBERS_ALL_REQUEST, query });
      yield put({ type: DIALOG_CLOSE });
    } else if (error) {
      yield put({ type: NOTIFICATION_RULE_SUBSCRIBERS_DELETE_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, NOTIFICATION_RULE_SUBSCRIBERS_DELETE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, NOTIFICATION_RULE_SUBSCRIBERS_DELETE_FAILURE);
  }
}

export function* watchNotificationRuleSubscribersDelete() {
  yield takeLatest(NOTIFICATION_RULE_SUBSCRIBERS_DELETE_REQUEST, notificationRuleSubscribersDelete);
}

export function* notificationRuleSubscribersFetchAll(action) {
  try {
    const { data, error, response } = yield fetchListAll(fetchNotificationRuleSubscribersList, action.query);
    if (data) {
      yield put({ type: NOTIFICATION_RULE_SUBSCRIBERS_ALL_SUCCESS, data });
    } else if (error) {
      yield put({ type: NOTIFICATION_RULE_SUBSCRIBERS_ALL_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, NOTIFICATION_RULE_SUBSCRIBERS_ALL_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, NOTIFICATION_RULE_SUBSCRIBERS_ALL_FAILURE);
  }
}

export function* watchNotificationRuleSubscribersFetchAll() {
  yield takeLatest(NOTIFICATION_RULE_SUBSCRIBERS_ALL_REQUEST, notificationRuleSubscribersFetchAll);
}

export default function* notificationRuleSubscribersSaga() {
  yield all([
    watchNotificationRuleSubscribersFetch(),
    watchNotificationRuleSubscribersCreate(),
    watchNotificationRuleSubscribersUpdate(),
    watchNotificationRuleSubscribersFetchAll(),
    watchNotificationRuleSubscribersDelete(),
  ]);
}
