// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
export const USERS_GETTER_REQUEST = 'USERS_GETTER_REQUEST';
export const USERS_GETTER_SUCCESS = 'USERS_GETTER_SUCCESS';
export const USERS_GETTER_FAILURE = 'USERS_GETTER_FAILURE';
export const USERS_GETTER_CLEAR = 'USERS_GETTER_CLEAR';

export const getUsers = (query = {}) => {
  return {
    type: USERS_GETTER_REQUEST,
    query,
  };
};

export const clearUsers = () => {
  return {
    type: USERS_GETTER_CLEAR,
  };
};
