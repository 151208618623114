import React from 'react';
import PropTypes from 'prop-types';

import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';

import { tasksDone } from './forms/FormsShared';

const ChecklistTaskColumn = props => {
  const { tasks } = props;
  if (!tasks) return <></>;
  const taskDone = tasksDone(tasks);
  if (taskDone === undefined) {
    return null;
  }
  if (taskDone) {
    return <CheckBoxOutlinedIcon />;
  }
  return <CheckBoxOutlineBlankOutlinedIcon />;
};

ChecklistTaskColumn.defaultProps = {};

ChecklistTaskColumn.propTypes = {
  tasks: PropTypes.array.isRequired,
};

export default ChecklistTaskColumn;
