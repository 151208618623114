import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, ButtonGroup } from '@mui/material';

import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import EditIcon from '@mui/icons-material/Edit';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import Description from '../shared/table/Description';
import Table, { SELECTABLE_ROWS_NONE } from '../shared/table/Table';

import ButtonIcon from '../shared/buttons/ButtonIcon';
import StyledLink from '../shared/StyledLink';
import { setColumns } from '../shared/table/columns';

import { jsonKeyToLabel, compareFilterMapOptions, toQueryString } from '../../utilities/strings';
import { useTopLevelAssets } from '../../hooks/assetHooks';
import { useCachedChecklistTemplates } from '../../hooks/checklistTemplatesHooks';
import { useCachedProjectTypes } from '../../hooks/projectTypesHooks';
import { useFeatureFlags, usePermissions } from '../../hooks/settingsHooks';
import useDateRangeColumnHook from '../../hooks/table/dateRangeColumnHook';
import ViewDisplayChips from '../shared/form/ViewDisplayChips';
import MultiSelectFilter from '../shared/table/MultiSelectFilter';
import { compare, ensureArray } from '../../utilities/arrays';

import ReportsMenu from './forms/ReportsMenu';
import ChecklistFlagsColumn from './ChecklistFlagsColumn';
import ChecklistTaskColumn from './ChecklistTaskColumn';
import ChecklistToolBarSelect from './ChecklistToolBarSelect';
import ProjectLinkDisplay from './ProjectLinkDisplay';
import { useTableRowSelectionManagerOptions } from '../../hooks/tableHooks';
import DeleteDialog from '../shared/Dialog/DeleteDialog';
import { getCustomCSVData, tableViews } from '../../utilities/tables';
import Switch from '../shared/buttons/Switch';
import { setTableSize, setTableView } from '../../store/settings/tableActions';
import { openDialog } from '../../store/dialogActions';
import { openSnackbar } from '../../store/snackbarActions';
import { checklistsDeleteEndpoint } from '../../store/apiV2/checklists';
import { getAllChecklists } from '../../store/features/checklistsActions';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const ChecklistTable = props => {
  const {
    createQuery,
    title,
    queryParamObj,
    tableChangeHandler,
    embedded,
    isReadOnly,
    data,
    filename,
    page,
    views,
    projectDetail,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { topLevelAssetOptions, topLevelAssetMap } = useTopLevelAssets();
  const { checklistTemplateOptions, checklistTemplateMap, categoriesOptions } = useCachedChecklistTemplates();
  const { dispatchRequest: deleteChecklists } = checklistsDeleteEndpoint.useEndpoint();

  const { hasChecklistTasks, hasFormsToProjects, hasChecklistLocking, hasChecklistDelete } = useFeatureFlags();
  const enableRowSelect = hasChecklistLocking; // add more conditions as needed
  const { projectTypeOptions: _projectTypes } = useCachedProjectTypes({ ordering: 'name' });
  const projectTypes = hasFormsToProjects ? _projectTypes : [];

  const { checklistsData, checklistsQuery, count, loading } = useSelector(state => {
    const checklistsData = state.checklists.all.dataAll.results;
    const checklistsQuery = state.checklists.all.query;
    return {
      loading: state.checklists.all.loading,
      count: state.checklists.all.dataAll.count,
      checklistsQuery,
      checklistsData,
    };
  });
  const { checklistsTableViewKey } = tableViews;
  const { hasAssetView, hasCompanyEdit, hasUserEdit, hasChecklistEdit, hasChecklistCreate, hasProjectCreate } =
    usePermissions();

  const queryFilters = queryParamObj;

  // const queryCreatedByFilter = queryFilters?.created_by || undefined;  // will be needed eventually
  const queryTypeFilters = queryFilters?.template__id__in ? [].concat(queryFilters.template__id__in.split(',')) : [];
  const queryCategoriesFilters = queryFilters?.template__categories
    ? [].concat(queryFilters.template__categories.split(','))
    : [];
  const queryTopLevelAssetFilters = queryFilters?.descendants ? [].concat(queryFilters.descendants.split(',')) : [];
  // const [createdByFilterList, setCreatedByFilterList] = useState(queryCreatedByFilter); // will be needed eventually

  // switch filters
  const queryNoProjectsFilter = queryFilters?.project ? [].concat(queryFilters.project) : [];

  const [typeFilterList, setTypeFilterList] = useState(queryTypeFilters);
  const [categoriesFilterList, setCategoriesFilterList] = useState(queryCategoriesFilters);
  const [topLevelAssetFilterList, setTopLevelAssetFilterList] = useState(queryTopLevelAssetFilters);

  const recentOptions = ['ytd', 'this_month', 'last_month', 'last_year'];
  const taskFilterOptions = ['any', 'open', 'closed'];
  const [hideProjects, setHideProjects] = useState(queryNoProjectsFilter);

  const canShowProject = ({ template }, projectTypes) => {
    const isValidType = template.type !== 'ANYTIME';
    const templateId = template.id;

    const projectTypeOptions = projectTypes.filter(
      projectType => projectType.checklistTemplate?.id?.toString() === templateId.toString()
    );

    const hasProjectTypeOptions = !!projectTypeOptions.length;

    return hasProjectCreate && isValidType && hasProjectTypeOptions;
  };

  /** @type {Column[]} */
  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          if (!hasChecklistEdit) {
            return <>{value}</>;
          }
          return (
            <StyledLink to={`/forms/${tableMeta.rowData[columns.findIndexByName['id']]}`} value={value.toString()} />
          );
        },
      },
    },
    {
      name: 'template.name',
      label: 'Type',
      options: {
        filter: false,
        sort: true,
        sortField: 'template__name',
      },
    },
    {
      name: 'template__id__in',
      label: 'Type',
      options: {
        filter: true,
        filterType: 'custom',
        sort: false,
        display: 'excluded',
        download: false,
        filterList: typeFilterList,
        // chips functionality
        customFilterListOptions: {
          render: values => {
            return values.map(v => {
              return checklistTemplateMap[v];
            });
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            setTypeFilterList(filterList[index]);
            return filterList;
          },
        },
        // filtering menu
        filterOptions: {
          names: checklistTemplateOptions,
          render: v => checklistTemplateMap[v],
          logic: (type, filters) => {
            if (filters.length) return !filters.includes(type);
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            const formatValue = item => {
              return checklistTemplateMap[item];
            };
            const getComparable = value => compareFilterMapOptions(value, checklistTemplateMap);
            // Sort top level filter options
            filterData[index] = filterData[index].sort(compare(getComparable));

            return (
              <MultiSelectFilter
                title="Types"
                filterList={filterList}
                localFilterList={typeFilterList}
                onChange={onChange}
                index={index}
                column={column}
                filterData={filterData}
                updateFilters={setTypeFilterList}
                formatValue={formatValue}
              />
            );
          },
        },
      },
    },
    {
      name: 'template.categories',
      label: 'Categories',
      options: {
        filter: false,
        sort: true,
        sortField: 'template__categories',
        downloadBody: values => getCustomCSVData('array', ensureArray(values)),
        customBodyRender: values => {
          return values && Array.isArray(values) ? (
            values.map(value => {
              return (
                <Fragment key={value}>
                  <ViewDisplayChips value={value} />
                </Fragment>
              );
            })
          ) : (
            <></>
          );
        },
      },
    },
    {
      name: 'template__categories',
      label: 'Categories',
      options: {
        filter: true,
        sort: false,
        display: 'excluded',
        viewColumns: false,
        filterType: 'custom',
        download: false,
        filterList: categoriesFilterList,
        customFilterListOptions: {
          // customize deleting filter display chip
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            setCategoriesFilterList(filterList[index]);
            return filterList;
          },
        },
        filterOptions: {
          names: categoriesOptions,

          // format values in the multiselect input
          render: v => categoriesOptions.find(v),
          logic: (category, filters) => {
            if (filters.length) return !filters.includes(category);
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            const formatValue = item => item;

            // Sort top level filter options
            filterData[index] = filterData[index].sort();
            return (
              <MultiSelectFilter
                title="Categories"
                filterList={filterList}
                localFilterList={categoriesFilterList}
                onChange={onChange}
                index={index}
                column={column}
                filterData={filterData}
                updateFilters={setCategoriesFilterList}
                formatValue={formatValue}
              />
            );
          },
        },
      },
    },
    {
      name: 'asset.owner',
      label: 'Company',
      options: {
        filter: false,
        sort: false,
        downloadBody: value => getCustomCSVData('simple', value),
        customBodyRender: value => {
          if (!value) return <></>;
          if (!hasCompanyEdit) return <>{value.name}</>;
          return <StyledLink to={`/companies/${value.id}`} value={value.name} />;
        },
      },
    },
    {
      name: 'asset',
      label: 'Asset',
      options: {
        filter: false,
        sort: true,
        sortField: 'asset__asset_path_cache__path',
        downloadBody: value => getCustomCSVData('simple', value),
        customBodyRender: value => {
          if (!value) return <></>;
          if (!hasAssetView) return <>{value.asset_path}</>;
          return <StyledLink to={`/assets/${value.id}`} value={value.asset_path} />;
        },
      },
    },
    {
      // this column is only for adding a filter in the filter menu.
      name: 'descendants',
      label: 'Top Level Asset',
      options: {
        filter: true,
        sort: false,
        display: 'excluded',
        filterType: 'custom',
        download: false,
        filterList: topLevelAssetFilterList,
        customFilterListOptions: {
          // customize filter display chips
          render: values => {
            return values.map(v => {
              return topLevelAssetMap[v];
            });
          },
          // customize deleting filter display chip
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            setTopLevelAssetFilterList(filterList[index]);
            return filterList;
          },
        },
        filterOptions: {
          names: topLevelAssetOptions,
          // format values in the multiselect input
          render: v => topLevelAssetMap[v],
          logic: (topLevelAsset, filters) => {
            if (filters.length) return !filters.includes(topLevelAsset);
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            const formatValue = item => {
              // format values in the multiselect menu
              return topLevelAssetMap[item];
            };

            const getComparable = value => compareFilterMapOptions(value, topLevelAssetMap);
            // Sort top level filter options
            filterData[index] = filterData[index].sort(compare(getComparable));
            return (
              <MultiSelectFilter
                title="Top Level Asset"
                filterList={filterList}
                localFilterList={topLevelAssetFilterList}
                onChange={onChange}
                index={index}
                column={column}
                filterData={filterData}
                updateFilters={setTopLevelAssetFilterList}
                formatValue={formatValue}
              />
            );
          },
        },
      },
    },
    {
      name: 'summary',
      label: 'Summary',
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          if (!value) return <></>;
          return <Description value={value} />;
        },
      },
    },
    {
      name: 'created_by',
      label: 'Created By',
      options: {
        filter: true,
        sort: true,
        filterType: 'textField', // will eventually be multiselect
        downloadBody: value => getCustomCSVData('simple', value),
        customBodyRender: (value, tableMeta) => {
          if (!value) return <></>;
          if (!hasUserEdit) return <>{value.name}</>;
          return <StyledLink to={`/users/${value.id}`} value={value.name} />;
        },
        // customFilterListOptions: {
        //   // customize filter display chips
        //   render: values => {
        //     return values.map(v => {
        //       return createdByMap[v];
        //     });
        //   },
        //   // customize deleting filter display chip
        //   update: (filterList, filterPos, index) => {
        //     filterList[index].splice(filterPos, 1);
        //     setAssetFilterList(filterList[index]);
        //     return filterList;
        //   },
        // },
        // filterOptions: {
        //   names: createdByListOptions,
        //   render: values => {
        //     return createdByMap[values];
        //   },
        //   logic: (user, filters) => {
        //     if (filters.length) return !filters.includes(user);
        // //     return false;
        //   },

        //   display: (filterList, onChange, index, column, filterData) => {
        //     const formatValue = item => {
        //       // format values in the multiselect menu
        //       return createdByMap[item];
        //     };
        //     const getComparable = value => compareFilterMapOptions(value, createdByMap);
        //     // Sort top level filter options
        //     filterData[index] = filterData[index].sort(compare(getComparable));

        //       return (
        //         <MultiSelectFilter
        //           title="Created By"
        //           filterList={filterList}
        //           localFilterList={createdByFilterList}
        //           onChange={onChange}
        //           index={index}
        //           column={column}
        //           filterData={filterData}
        //           updateFilters={setCreatedByFilterList}
        //           formatValue={formatValue}
        //         />
        //       );
        //     },
        //   },
      },
    },
    useDateRangeColumnHook({
      name: 'created_on',
      label: 'Created',
      queryParamObj,
      queryStartKey: 'created_on_after',
      queryEndKey: 'created_on_before',
    }),
    useDateRangeColumnHook({
      name: 'work_done_on',
      label: 'Work Done On',
      queryParamObj,
      queryStartKey: 'work_done_on_after',
      queryEndKey: 'work_done_on_before',
    }),
    {
      name: 'recent',
      label: 'Show Recently Created',
      options: {
        filter: true,
        sort: false,
        display: 'excluded',
        filterType: 'dropdown',
        viewColumns: false,
        empty: false,
        download: false,
        customFilterListOptions: {
          render: value => (value === 'ytd' ? 'Year to Date' : jsonKeyToLabel(value)),
        },
        filterOptions: {
          names: recentOptions,
          renderValue: value => (value === 'ytd' ? 'Year to Date' : jsonKeyToLabel(value)),
        },
      },
    },
    {
      name: 'project',
      label: 'Project',
      options: {
        filter: !projectDetail, // if table is in project, we don't care about this filter
        display: projectDetail ? 'excluded' : true,
        filterType: 'custom',
        sort: false,
        downloadBody: value => getCustomCSVData('simple', value),
        customBodyRender: (value, info) => {
          const id = info.rowData[columns.findIndexByName['id']];
          const obj = checklistsData[info.rowIndex];
          const templateId = obj.template.id;
          const showNewProject = canShowProject(obj, projectTypes);

          const handleNewProjectButton = () => {
            const search = `?checklist_id=${id}&template_id=${templateId}`;
            history.push({
              pathname: '/projects/new',
              search: search,
            });
          };
          if (value) {
            return <ProjectLinkDisplay orderIndex={obj.project_order_index} id={value.id} name={value.name} />;
          } else {
            if (hasFormsToProjects && showNewProject) {
              return (
                <Button startIcon={<AddBoxIcon />} onClick={handleNewProjectButton} style={{ fontSize: '12px' }}>
                  New Project
                </Button>
              );
            } else {
              return <></>;
            }
          }
        },
        customFilterListOptions: {
          render: v => `Hide Projects`,
          update: (filterList, filterPos, index) => {
            filterList[index] = [];
            setHideProjects(filterList[index]);
            return filterList;
          },
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            const handleOnChange = bool => {
              if (bool) {
                setHideProjects(['none']);
              } else setHideProjects([]);

              filterList[index] = bool ? ['none'] : [];
              onChange(filterList[index], index, column);
            };
            return (
              <Switch
                label="Hide Projects"
                initialValue={hideProjects[0] === 'none'}
                onChange={handleOnChange}
                checked={hideProjects[0] === 'none'}
              />
            );
          },
        },
      },
    },
    {
      name: 'tasks',
      label: 'Tasks',
      options: {
        filter: true,
        sort: false,
        filterType: 'dropdown',
        downloadBody: value => getCustomCSVData('completed', value),
        customFilterListOptions: {
          render: value => jsonKeyToLabel(value),
        },
        filterOptions: {
          names: taskFilterOptions,
          renderValue: value => jsonKeyToLabel(value),
        },
        customBodyRender: value => {
          return <ChecklistTaskColumn tasks={value} />;
        },
      },
    },
    {
      name: 'is_locked',
      label: 'Locked',
      options: {
        filter: hasChecklistLocking,
        sort: false,
        filterOptions: {
          names: ['False', 'True'],
        },
        display: !!hasChecklistLocking,
        viewColumns: !!hasChecklistLocking,
        download: !!hasChecklistLocking,
        customBodyRender: value => {
          return value ? <LockIcon fontSize="small" /> : <LockOpenIcon fontSize="small" />;
        },
      },
    },
    {
      name: 'flags',
      label: 'Flags',
      options: {
        filter: false,
        sort: false,
        downloadBody: value => getCustomCSVData('key-value', value),
        customBodyRender: value => {
          if (!value) return null;
          return <ChecklistFlagsColumn flags={value} />;
        },
      },
    },
    /** {
      name: 'assigned_to',
      label: 'Follow Up By',
      options: {
        filter: false,
        sort: true,
        filterType: 'textField',
        customBodyRender: (value, tableMeta) => {
          if (!value) return <></>;
          if (!hasAllUsersView) return <>{value}</>;
          return <StyledLink to={`/users/${tableMeta.rowData[columns.findIndexByName['id']]}`} value={value} />;
        },
      },
    },
    {
      name: 'follow_up_on',
      label: 'Follow Up On',
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => {
          return apiDateToString(value, 'date');
        },
      },
    }, **/
    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        print: false,
        viewColumns: false,
        customBodyRender: (_, info) => {
          // 0 is the first element in column
          const id = info.rowData[columns.findIndexByName['id']];
          const obj = checklistsData[info.rowIndex];
          const reportsList = obj.reports;
          return (
            <>
              <ButtonGroup>
                {hasChecklistEdit && (
                  <ButtonIcon
                    history={history}
                    icon={EditIcon}
                    location={location}
                    to={`/forms/${id}`}
                    disabled={isReadOnly}
                  />
                )}
                {hasChecklistDelete && (
                  <ButtonIcon
                    icon={DeleteIcon}
                    onClick={() => {
                      dispatch(
                        openDialog(
                          'Delete Form?',
                          <DeleteDialog
                            id={id}
                            name={`Form ID: ${id}`}
                            deleteAction={async () => {
                              try {
                                await deleteChecklists(id);
                                dispatch(getAllChecklists(checklistsQuery));
                              } catch (error) {
                                console.error('Error:', error);
                                dispatch(openSnackbar(error.error, 'error'));
                              }
                            }}
                          />
                        )
                      );
                    }}
                  />
                )}
                <div style={{ marginLeft: '5px' }}>
                  <ReportsMenu options={reportsList} />
                </div>
              </ButtonGroup>
            </>
          );
        },
      },
    },
  ];

  const getCreateFormRoute = () => {
    const withQuery = createQuery ? toQueryString(createQuery) : '';
    return `/forms/new${withQuery}`;
  };

  const getAddRoute = () => {
    if (hasChecklistCreate && !isReadOnly) return getCreateFormRoute();
    return null;
  };

  const columnsToExclude = { tasks: !hasChecklistTasks };
  // handle columns display
  setColumns(columns, views, columnsToExclude);

  /** @type {TableOptions} */
  const { tableOptions } = useTableRowSelectionManagerOptions();
  const options = {
    // if the column view changes, update redux with either 'add' or 'remove'
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setTableView(changedColumn, action, page, checklistsTableViewKey));
    },
    onChangeRowsPerPage: numberOfRows => {
      dispatch(setTableSize(numberOfRows, page, checklistsTableViewKey));
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      return (
        <ChecklistToolBarSelect
          selectedRows={selectedRows}
          displayData={displayData}
          setSelectedRows={setSelectedRows}
          columns={columns}
        />
      );
    },
    enableNestedDataAccess: '.',
    searchOpen: !embedded,
    ...(!enableRowSelect && { selectableRows: SELECTABLE_ROWS_NONE }), // disable row select if conditions not met
    ...tableOptions, // these options somehow slow down the checkbox click noticeably
  };

  return (
    <Table
      title={title}
      serverSide
      columns={columns}
      data={data && data.length > 0 ? data : checklistsData}
      options={options}
      loading={loading}
      addRoute={getAddRoute()}
      queryParamObj={queryParamObj}
      tableChangeHandler={tableChangeHandler}
      embedded={embedded}
      count={count}
      filename={filename}
      views={views}
    />
  );
};

ChecklistTable.defaultProps = {
  queryParamObj: {},
  embedded: false,
  data: [],
  title: 'Forms',
  filename: null,
  projectDetail: false,
  createQuery: undefined,
  isReadOnly: false,
};

ChecklistTable.propTypes = {
  title: PropTypes.string,
  queryParamStr: PropTypes.string,
  tableChangeHandler: PropTypes.func.isRequired,
  embedded: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object),
  filename: PropTypes.string,
  queryParamObj: PropTypes.object,
  views: PropTypes.object,
  page: PropTypes.string,
  projectDetail: PropTypes.bool,
  createQuery: PropTypes.object,
};

export default ChecklistTable;
