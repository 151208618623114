// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import {
  HELP_REQUEST,
  HELP_SUCCESS,
  HELP_FAILURE,
  HELP_TIP_REQUEST,
  HELP_TIP_SUCCESS,
  HELP_TIP_FAILURE,
} from './helpActions';

export const initialStateHelp = {
  loading: false,
  error: '',
  data: {},
  dataTIP: {},
  query: {},
};

export const helpReducer = (state = initialStateHelp, action) => {
  switch (action.type) {
    case HELP_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        query: action.query,
      };
    case HELP_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case HELP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        data: {},
        query: {},
      };
    case HELP_TIP_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        query: action.query,
      };
    case HELP_TIP_SUCCESS:
      return {
        ...state,
        loading: false,
        dataTIP: action.data,
      };
    case HELP_TIP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        dataTIP: {},
        query: {},
      };
    default:
      return state;
  }
};

export default helpReducer;
