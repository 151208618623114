import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { default as MuiAutocomplete } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import debounce from 'lodash/debounce';
import { Field } from 'react-final-form';
import makeStyles from '@mui/styles/makeStyles';
import { showError } from './Util';
import { fetchAssetsList } from '../../../api/features/assets';
import { clearFlag } from '../../../api/features/constants';

const useStyles = makeStyles(theme => ({
  // make menu positioning relative
  popperDisablePortal: {
    position: 'relative',
  },
}));

/**
 * Select component copied from mui-rff.
 * Added onChange prop to get the selected value from the Field component
 */

const FieldEditAssetAutocomplete = props => {
  const { name, fieldProps, ...rest } = props;
  return (
    <Field
      name={name}
      render={fieldRenderProps => <FieldEditAssetAutocompleteWrapper {...fieldRenderProps} {...rest} />}
      {...fieldProps}
    />
  );
};

const FieldEditAssetAutocompleteWrapper = props => {
  const classes = useStyles();
  const {
    input: { name, onChange, value },
    meta,
    label,
    required,
    valueInitial,
    filterQuery,
    showError,
    onChange: onChangeCallback,
    helperText,
    ...rest
  } = props;
  const [inputValue, setInputValue] = useState(valueInitial ? valueInitial.label : '');
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true); // default `true` to prevent initial "No Options" message

  /**
   * @description Fetch the assets from the backend
   * @param {string} inputValue
   * @param {object} filterQuery
   * @returns {Promise<void>}
   */
  const _fetchAssets = useCallback(async (inputValue, filterQuery) => {
    setLoading(true);
    const queryParams = {
      ...filterQuery,
      asset_path_search: inputValue,
      ordering: 'asset_path_cache__path',
    };
    const response = await fetchAssetsList(queryParams);
    const options = response.data.results.map(asset => ({
      label: asset.asset_path,
      value: asset.id.toString(),
    }));
    setOptions(options);
    setLoading(false);
  }, []);

  /**
   * @description Debounce the fetchAssets function
   *         so that it doesn't fire on every keystroke
   */
  const fetchAssets = useMemo(() => debounce(_fetchAssets, 2000, { leading: false, trailing: true }), [_fetchAssets]);

  /**
   * @description Fetch the options when the field is opened or the input value changes
   */
  useEffect(() => {
    fetchAssets(inputValue, filterQuery);
  }, [fetchAssets, inputValue, filterQuery]);

  /**
   * @description Clear the options when the field is closed
   */
  useEffect(() => {
    if (!open && !value) {
      setOptions([]);
    }
  }, [open, value]);

  /**
   * @description Set the initial values of the field on load
   */
  useEffect(() => {
    // (check !value so that it doesn't override the value selected)
    if (!value && (valueInitial?.label || valueInitial?.value)) {
      // set initial `react-final-form` field value
      onChange(valueInitial.value);
    }
  }, [valueInitial?.label, valueInitial?.value, value, onChange]);

  /**
   * @description Handle the change event
   */
  const onChangeFunc = (event, value, reason, details) => {
    if (reason === 'clear') {
      setOptions([]);
      onChange(clearFlag); // set a value that is not null so react final form will save
    } else {
      onChange(value?.value);
      setInputValue(value?.label);
    }

    if (onChangeCallback) {
      onChangeCallback(event, value, reason, details);
    }
  };
  const { error, submitError } = meta;
  const isError = showError({ meta });

  return (
    <MuiAutocomplete
      id={name}
      classes={classes}
      autoHighlight
      autoSelect
      name={name}
      blurOnSelect
      clearOnBlur
      selectOnFocus={true}
      disablePortal
      // override default client-side options filtering since we get options from backend
      filterOptions={options => options}
      loading={loading}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={onChangeFunc}
      onInputChange={(event, newInputValue) => {
        if (!event) {
          return;
        }
        setInputValue(newInputValue);
      }}
      getOptionLabel={option => option.label || ''}
      isOptionEqualToValue={(option, value) => option?.value === value}
      options={options}
      value={value || null} // use `null` to specify it is a controlled component, but not selected
      inputValue={inputValue}
      renderInput={params => {
        return (
          <TextField
            {...params}
            label={label}
            required={required}
            helperText={isError ? error || submitError : helperText}
            error={isError}
            name={name}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading && open ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            InputLabelProps={{ shrink: true }}
            placeholder="Search assets"
            fullWidth={true}
          />
        );
      }}
      {...rest}
    />
  );
};

FieldEditAssetAutocompleteWrapper.defaultProps = {
  required: false,
  valueInitial: null,
  onChange: null,
  filterQuery: {},
};

FieldEditAssetAutocompleteWrapper.propTypes = {
  /** Equivalent is FieldInputProps in FieldRenderProps */
  input: PropTypes.object.isRequired,
  /** meta is a FieldMetaState object in FieldRenderProps */
  meta: PropTypes.object.isRequired,
  /** possible values to select from in the shape of: */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  /** filter used for query */
  filterQuery: PropTypes.object,
  /** initial value object for the field */
  valueInitial: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func,
  helperText: PropTypes.string,
  /** label for the autocomplete field */
  label: PropTypes.string.isRequired,
  /** sets the field as required for the form */
  required: PropTypes.bool,
  onChangeCallback: PropTypes.func,
  showError: PropTypes.func,
};

FieldEditAssetAutocomplete.defaultProps = {
  valueInitial: undefined,
  required: false,
  disabled: false,
  filterQuery: [],
  fieldProps: {},
  onChange: null,
  showError: showError,
};

FieldEditAssetAutocomplete.propTypes = {
  /** the api key for the feature */
  name: PropTypes.string.isRequired,
  /** sets the field as required for the form */
  required: PropTypes.bool,
  /** disables the field while true */
  disabled: PropTypes.bool,
  /** onChange for controlled components */
  onChange: PropTypes.func,
  showError: PropTypes.func,
  helperText: PropTypes.string,
  fieldProps: PropTypes.object,
};

export default FieldEditAssetAutocomplete;
