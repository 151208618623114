export const ASSESSMENTS_REQUEST = 'ASSESSMENTS_REQUEST';
export const ASSESSMENTS_SUCCESS = 'ASSESSMENTS_SUCCESS';
export const ASSESSMENTS_FAILURE = 'ASSESSMENTS_FAILURE';
export const ASSESSMENTS_ALL_REQUEST = 'ASSESSMENTS_ALL_REQUEST';
export const ASSESSMENTS_ALL_SUCCESS = 'ASSESSMENTS_ALL_SUCCESS';
export const ASSESSMENTS_ALL_FAILURE = 'ASSESSMENTS_ALL_FAILURE';
export const ASSESSMENT_REVISION_CREATE_FAILURE = 'ASSESSMENT_REVISION_CREATE_FAILURE';
export const ASSESSMENT_REVISION_CREATE_REQUEST = 'ASSESSMENT_REVISION_CREATE_REQUEST';
export const ASSESSMENT_REVISION_CREATE_SUCCESS = 'ASSESSMENT_REVISION_CREATE_SUCCESS';

export const getAssessments = id => {
  return {
    type: ASSESSMENTS_REQUEST,
    id,
  };
};

export const getAllAssessments = query => {
  return {
    type: ASSESSMENTS_ALL_REQUEST,
    query,
  };
};

export const createAssessmentRevision = (id, options = {}) => {
  return {
    type: ASSESSMENT_REVISION_CREATE_REQUEST,
    id,
    options,
  };
};
