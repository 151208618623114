import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { jsonKeyToLabel } from '../../utilities/strings';
import { getUserOptionLabel } from '../../utilities/users';
import { getCrews, createCrews, updateCrews, clearEachError, clearCrews } from '../../store/features/crewsActions';
import { getUserProfile } from '../../store/getters/profileActions';
import { getAllCompanies } from '../../store/features/companiesActions';
import CommonForm from '../shared/form/Common';
import { isEmpty } from '../../utilities/objects';
import { usePermissions } from '../../hooks/settingsHooks';
import { fieldOrder, removeField, hideField } from './crewsShared';
import { useCompanies } from '../../hooks/optionsHooks';

const CrewsForm = props => {
  const { update } = props;
  const params = useParams();
  const dispatch = useDispatch();
  const { hasUserRolesAll } = usePermissions();
  const companyOptions = useCompanies(true);

  const { data, loading, error, formError, editorCompany, initialUsers, userIsSubcontractor } = useSelector(state => {
    const { data, loading, error, formError } = state.crews.each;
    const {
      data: { roles: editorRoles, company: editorCompany },
    } = state.profile;

    const initialUsers = data.users?.map(getUserOptionLabel) ?? [];
    const userIsSubcontractor = editorRoles.includes('subcontractor') && !hasUserRolesAll;
    return {
      data,
      loading: loading || state.companies.all.loading || state.profile.loading,
      error,
      formError,
      editorCompany,
      initialUsers,
      userIsSubcontractor,
    };
  });

  const [submittedValues, setSubmittedValues] = useState({});
  useEffect(() => {
    dispatch(getUserProfile());

    if (update && params.id) {
      dispatch(getCrews(params.id));
    }
    // to clear selected users that get saved in create new
    if (!update) {
      dispatch(clearCrews());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!userIsSubcontractor) {
      dispatch(getAllCompanies());
    }
  }, [dispatch, userIsSubcontractor]);

  useEffect(() => {
    if (formError || error) {
      dispatch(clearEachError());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (update && isEmpty(data)) {
    // handle some extra crashes before there is data on autocomplete
    return null;
  }

  const getUserQuery = () => ({
    is_active: true,
    ordering: 'name',
    ...(data.company && { company: data.company.id }),
  });

  const formSettings = {
    fieldOrder,
    removeField: removeField(update ? 'UPDATE' : 'CREATE'),
    hideField,
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */

  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      switch (key) {
        /* ---------- Required Fields ---------- */
        case 'name':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              required: true,
            },
          };
          break;
        case 'company':
          if (!userIsSubcontractor) {
            fieldSettings[key] = {
              type: 'select',
              fieldProps: {
                label: jsonKeyToLabel(key),
                name: key,
                options: companyOptions,
                labelwidth: 72,
              },
            };
          }
          break;
        case 'users':
          fieldSettings[key] = {
            type: 'user-autocomplete',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              required: true,
              multiple: true,
              initialUsers,
              labelwidth: 40,
              queryParams: getUserQuery(),
              helperText: 'Search by name or email to select the crew.',
            },
          };
          break;
        /* ---------- Regular Fields ---------- */
        case 'is_active':
          fieldSettings[key] = {
            type: 'checkbox',
            cellProps: {
              xs: 6,
            },
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
          break;
        /* ----------  Disabled Fields ---------- */
        /* ----------  Default ---------- */
        default:
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
      }
    });
  }

  const title = update ? 'Edit Crew' : 'New Crew';

  let initialValues = { users: [] };
  if (update) {
    initialValues = {
      ...data,
      // format data to set the initial value of select and autocomplete fields
      company: data.company && data.company.id.toString(),
      users: data.users ? data.users.map(user => user.id.toString()) : [],
    };
  }
  const onSubmit = changedValues => {
    const updatedValues = { ...changedValues };
    setSubmittedValues(changedValues);
    if (update) {
      dispatch(updateCrews(updatedValues.id, updatedValues));
    } else {
      if (userIsSubcontractor) {
        dispatch(createCrews({ ...updatedValues, company: editorCompany[0].id }));
      } else {
        dispatch(createCrews(updatedValues));
      }
    }
  };

  /**
   * Decorators are used for setting the values of other fields based off of a field.
   * Decorator format can be found here: src/components/shared/form/common.js
   */
  const decorators = [];

  /**
   *  Validations that are per field.
   *  Errors associated with the form are passed back through formError in Redux.
   */
  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        if (values[key] === submittedValues[key]) {
          errors[key] = value;
        } else {
          errors[key] = undefined;
        }
      }
    }
    return errors;
  };

  return (
    <CommonForm
      update={update}
      title={title}
      initialValues={initialValues}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      onSubmit={onSubmit}
      decorators={decorators}
      validate={validate}
      loading={loading}
      error={error}
    />
  );
};

CrewsForm.defaultProps = {
  update: false,
};

CrewsForm.propTypes = {
  update: PropTypes.bool,
};

export default CrewsForm;
