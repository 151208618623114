import {
  RESERVATION_STATE_REQUEST,
  RESERVATION_STATE_SUCCESS,
  RESERVATION_STATE_FAILURE,
} from './reservationStateActions';

export const initialStateReservationState = {
  loading: false,
  error: '',
  data: [],
};

export const reservationStateReducer = (state = initialStateReservationState, action) => {
  switch (action.type) {
    case RESERVATION_STATE_REQUEST:
      return {
        loading: true,
        error: '',
        data: [],
      };
    case RESERVATION_STATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case RESERVATION_STATE_FAILURE:
      return {
        ...initialStateReservationState,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reservationStateReducer;