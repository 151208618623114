export const ASSET_TYPES_REQUEST = 'ASSET_TYPES_REQUEST';
export const ASSET_TYPES_SUCCESS = 'ASSET_TYPES_SUCCESS';
export const ASSET_TYPES_FAILURE = 'ASSET_TYPES_FAILURE';
export const ASSET_TYPES_CREATE_REQUEST = 'ASSET_TYPES_CREATE_REQUEST';
export const ASSET_TYPES_CREATE_SUCCESS = 'ASSET_TYPES_CREATE_SUCCESS';
export const ASSET_TYPES_CREATE_FAILURE = 'ASSET_TYPES_CREATE_FAILURE';
export const ASSET_TYPES_UPDATE_REQUEST = 'ASSET_TYPES_UPDATE_REQUEST';
export const ASSET_TYPES_UPDATE_SUCCESS = 'ASSET_TYPES_UPDATE_SUCCESS';
export const ASSET_TYPES_UPDATE_FAILURE = 'ASSET_TYPES_UPDATE_FAILURE';
export const ASSET_TYPES_DELETE_REQUEST = 'ASSET_TYPES_DELETE_REQUEST';
export const ASSET_TYPES_DELETE_SUCCESS = 'ASSET_TYPES_DELETE_SUCCESS';
export const ASSET_TYPES_DELETE_FAILURE = 'ASSET_TYPES_DELETE_FAILURE';
export const ASSET_TYPES_ALL_REQUEST = 'ASSET_TYPES_ALL_REQUEST';
export const ASSET_TYPES_ALL_SUCCESS = 'ASSET_TYPES_ALL_SUCCESS';
export const ASSET_TYPES_ALL_FAILURE = 'ASSET_TYPES_ALL_FAILURE';

export const getAssetTypes = id => {
  return {
    type: ASSET_TYPES_REQUEST,
    id,
  };
};

export const createAssetTypes = body => {
  return {
    type: ASSET_TYPES_CREATE_REQUEST,
    body,
  };
};

export const updateAssetTypes = (id, body) => {
  return {
    type: ASSET_TYPES_UPDATE_REQUEST,
    id,
    body,
  };
};

export const deleteAssetTypes = id => {
  return {
    type: ASSET_TYPES_DELETE_REQUEST,
    id,
  };
};

export const getAllAssetTypes = query => {
  return {
    type: ASSET_TYPES_ALL_REQUEST,
    query,
  };
};
