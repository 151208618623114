// lib
import React, { useEffect } from 'react';
import { FormControl, FormLabel, FormHelperText, Divider, Grid, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { useForm, useFormState, Field } from 'react-final-form';
import { evaluate } from '@huvrdata/evaluate';

// components
import CalculationLineItemExpressionError from './CalculationLineItemErrorDisplay';
import Markdown from '../../shared/Markdown';

// util
import { buildExpressionContext } from '../../../utilities/checklist';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    padding: '12px',
  },
  valueCode: {
    fontSize: '20px',
  },
  valueCodeError: {
    fontSize: '20px',
    color: '#e6492d',
  },
}));

/**
 *
 * Allow displaying calulated values based on existing values in form
 *
 * For additional context, see:
 *    - https://github.com/huvrdata/huvr/issues/6702
 *    - https://github.com/huvrdata/evaluate
 *
 */
const CalculationLineItem = props => {
  const { name, label, expression, value, helperText } = props;

  // ------- hooks / state ------

  const classes = useStyles();
  const { values } = useFormState(); // https://final-form.org/docs/final-form/types/FormState
  const form = useForm(); // https://final-form.org/docs/final-form/types/FormApi

  // -------- evaluate expression ----

  const context = buildExpressionContext(values);
  let calculatedValue;
  let expressionError = null;
  try {
    calculatedValue = evaluate(expression, context).toString();
  } catch (e) {
    expressionError = e;
    calculatedValue = `${e.type}`; // "ExpressionError"
  }

  // --------- effects --------

  useEffect(() => {
    if (calculatedValue !== value) {
      form.mutators.setFieldValue(name, calculatedValue);
    }
  }, [calculatedValue, value]); // eslint-disable-line react-hooks/exhaustive-deps

  // --------- render ----------

  return (
    <Grid>
      <Field
        label={label}
        name={name}
        render={({ input }) => {
          return (
            <Paper>
              <Grid className={classes.root}>
                {expressionError && <CalculationLineItemExpressionError expressionError={expressionError} />}
                <FormControl>
                  <FormLabel error={expressionError}>
                    <Markdown>{label}</Markdown>
                  </FormLabel>
                  <FormHelperText>
                    <Markdown>{helperText}</Markdown>
                  </FormHelperText>
                  <Divider />
                  <code className={expressionError ? classes.valueCodeError : classes.valueCode}>{input.value}</code>
                </FormControl>
              </Grid>
            </Paper>
          );
        }}
      />
    </Grid>
  );
};

CalculationLineItem.defaultProps = {
  value: undefined,
  placeholder: '',
  loading: false,
  inputPropClasses: '',
  showExpressionEditor: false,
};

CalculationLineItem.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  expression: PropTypes.string,
  value: PropTypes.string,
  helperText: PropTypes.string,
  loading: PropTypes.bool,
  showExpressionEditor: PropTypes.bool,
  inputPropClasses: PropTypes.string,
};

export default CalculationLineItem;
