// lib
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import { GoogleMap, MarkerClusterer, Marker, InfoWindow } from '@react-google-maps/api';

// hooks / utils / api
import { useGoogleMapLoader } from '../../../hooks/mapHooks';
import { conditionLookup, iconUrlLookup } from '../../../utilities/assetCondition';
import { queriesFromString, toQueryString } from '../../../utilities/strings';
import { apiURL } from '../../../api/base';

// components
import AssetConditionDisplay from './AssetConditionDisplay';
import LoadingPage from '../../shared/displays/LoadingPage';
import StyledLink from '../../shared/StyledLink';

// style
import variables from '../../../config.module.scss';

const useStyles = makeStyles(theme => ({
  basediv: {
    overflow: 'hidden !important',
  },
  root: {},
  paper: {
    margin: 'auto',
    textAlign: 'left',
    backgroundColor: 'white',
    color: variables.textPrimaryLight,
  },
  papercounts: {
    margin: 'auto',
    maxWidth: 100,
    textAlign: 'left',
    backgroundColor: 'white',
    color: variables.textPrimaryLight,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

const AssetConditionMap = ({ zoomToLocation, setZoomToLocation, results }) => {
  const classes = useStyles();

  const [mapRef, setMapRef] = useState(null);
  const [reFitMap, setRefitMap] = useState(false);
  const [zoom, setZoom] = useState(10);
  const [center, setCenter] = useState({ lat: 26.583578, lng: -97.827817 });

  // Managing Marker InfoWindow State
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [infoMarkerOpen, setInfoMarkerOpen] = useState(false);

  // Managing Cluster InfoWindow State
  const [clusterInfoWindowData, setClusterInfoWindowData] = useState({});

  const assetConditions = useSelector(state => state.settings.features.assetConditions);
  // get condition by icon, as we cannot pass custom attr to Marker
  //    set when creating locations
  //    see available props https://react-google-maps-api-docs.netlify.app/#marker
  const assetConditionsByIconUrl = {};

  const location = useLocation();
  const history = useHistory();
  const locations = [];

  useEffect(() => {
    const parsed = queriesFromString(location.search, ['map']);
    if (parsed.map) {
      setCenter({ lat: parseFloat(parsed.map[0]), lng: parseFloat(parsed.map[1]) });
      setZoom(parseInt(parsed.map[2]));
    }
    return () => {
      setMapRef(null);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { isLoaded } = useGoogleMapLoader();

  results.forEach((item, index) => {
    if (item['geo_point'] != null) {
      const condition = conditionLookup(item['asset_condition'], assetConditions);
      const iconUrl = iconUrlLookup(item['asset_condition'], assetConditions);
      const location = {
        title: item['name'],
        asset_condition: item['asset_condition'],
        asset_condition_set_by: item['asset_condition_set_by'],
        lat: item['geo_point'].coordinates[1],
        lng: item['geo_point'].coordinates[0],
        zIndex: index + 1000,
        id: item['id'],
        icon: iconUrl,
        color: condition.color, // color used for InfoWindow
      };
      locations.push(location);
      assetConditionsByIconUrl[iconUrl] = condition;
    }
  });

  const recalculateBounds = () => {
    // There is no way to reset or shrink the boundaries of LatLngBounds.  A new one has to be created.
    const newMapBounds = new window.google.maps.LatLngBounds();
    if (locations.length > 0) {
      locations.map(loc => {
        const gloc = new window.google.maps.LatLng(loc.lat, loc.lng);
        newMapBounds.extend(gloc);
        return loc.id;
      });
    } else {
      newMapBounds.extend({ lat: 26.583578, lng: -97.827817 }); // Mexican Border
    }
    return newMapBounds;
  };

  // Handles the initial render of the map.  Without this, the map will not show anything when it loads.
  useEffect(() => {
    if (mapRef) {
      const parsed = queriesFromString(location.search, ['map']);
      if (parsed.map) {
        mapRef.setCenter({ lat: parseFloat(parsed.map[0]), lng: parseFloat(parsed.map[1]) });
        mapRef.setZoom(parseInt(parsed.map[2]));
      } else {
        mapRef.fitBounds(recalculateBounds());
      }
    }
  }, [mapRef]); // eslint-disable-line react-hooks/exhaustive-deps

  // A work around because CenterControl memoizes the variables so anything passed into addEventListener does not
  // reflect the current values.  Instead, the event listener, sets reFitMap to true and then useEffect sets reFitMap
  // back to false after we recalculate the bounds and apply them.
  // Possible refactor: This causes the component to render a couple of times since we have to reset setRefitMap for
  // the map button to continue working.
  useEffect(() => {
    if (mapRef) {
      mapRef.fitBounds(recalculateBounds());
      setRefitMap(false);
      const newLocation = history.location;
      const queryObj = queriesFromString(newLocation.search);
      delete queryObj.map;
      newLocation.search = toQueryString(queryObj);
      history.replace(newLocation);
    }
  }, [reFitMap]); // eslint-disable-line react-hooks/exhaustive-deps

  const CenterControl = controlDiv => {
    // Set CSS for the control border.
    const controlUI = document.createElement('div');
    controlUI.style.backgroundColor = '#fff';
    controlUI.style.border = '2px solid #fff';
    controlUI.style.borderRadius = '3px';
    controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    controlUI.style.cursor = 'pointer';
    controlUI.style.marginRight = '10px';
    controlUI.style.marginBottom = '15px';
    controlUI.style.textAlign = 'center';
    controlUI.title = 'Zoom To All';
    controlDiv.appendChild(controlUI);

    // Set CSS for the control interior.
    const controlText = document.createElement('div');
    controlText.style.color = 'rgb(90,90,90)';
    controlText.style.paddingLeft = '5px';
    controlText.style.paddingRight = '5px';
    controlText.innerHTML =
      '<svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M15 3l2.3 2.3-2.89 2.87 1.42 1.42L18.7 6.7 21 9V3h-6zM3 9l2.3-2.3 2.87 2.89 1.42-1.42L6.7 5.3 9 3H3v6zm6 12l-2.3-2.3 2.89-2.87-1.42-1.42L5.3 17.3 3 15v6h6zm12-6l-2.3 2.3-2.87-2.89-1.42 1.42 2.89 2.87L15 21h6v-6z"></path></svg>';
    controlUI.appendChild(controlText);

    // Setup the click event listener for the zoom to bounds control.
    controlUI.addEventListener('click', function () {
      setRefitMap(true);
    });
  };

  const loadMapHandler = map => {
    // Store a reference to the google map instance in state
    setMapRef(map);

    const centerControlDiv = document.createElement('div');
    CenterControl(centerControlDiv);

    centerControlDiv.index = 1;
    map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(centerControlDiv);
  };

  const mapOptions = {
    MapTypeId: 'satellite',
    streetViewControl: false,
  };
  const markerInfoWindowOptions = {
    pixelOffset: { width: 0, height: -30 },
  };
  const clusterInfoWindowOptions = {
    pixelOffset: { width: 0, height: -30 },
    maxWidth: 400,
  };

  const mouseOverClusterHandler = event => {
    const rollups = {};
    setInfoMarkerOpen(false);

    event.markers.forEach(function (item, index, array) {
      const assetCondition = assetConditionsByIconUrl[item.icon].value;
      if (assetCondition in rollups) {
        rollups[assetCondition]++;
      } else {
        rollups[assetCondition] = 1;
      }
    });
    const rollupsFinal = [];
    const rupkeys = Object.keys(rollups);
    rupkeys.reverse();
    rupkeys.forEach(function (item) {
      rollupsFinal.push({ assetCondition: parseInt(item), count: parseInt(rollups[item]) });
    });

    setClusterInfoWindowData({
      title: 'Summary',
      rollups: rollupsFinal,
      marker: event.markers[0],
      totalCount: event.markers.length,
    });
  };

  const mouseClickClusterHander = () => {
    setClusterInfoWindowData({ title: null });
  };

  const mouseOutClusterHandler = () => {
    setClusterInfoWindowData({ title: null });
  };

  const markerClusterOptions = {
    imagePath: apiURL + '/api-static/images/markers/mcluster',
    averageCenter: false,
    maxZoom: 13,
    minimumClusterSize: 2,
    title: 'Multiple Assets',
  };

  const markerClickHandler = (event, place) => {
    setSelectedPlace({ place: place });
    if (infoMarkerOpen) {
      setInfoMarkerOpen(false);
    }
    setInfoMarkerOpen(true);
  };

  const markerDoubleClickHandler = event => {
    mapRef.setZoom(16);
    mapRef.panTo(event.latLng.toJSON());
  };

  if (zoomToLocation && mapRef) {
    mapRef.setZoom(16);
    mapRef.panTo({ lat: zoomToLocation[1], lng: zoomToLocation[0] });
    setZoomToLocation(null);
  }

  const updateUrlMapQuery = text => {
    if (!mapRef) return;
    const { center, zoom } = mapRef;
    const newLocation = history.location;
    const queryObj = queriesFromString(newLocation.search);
    queryObj.map = [center.lat(), center.lng(), zoom];
    newLocation.search = toQueryString(queryObj);
    history.replace(newLocation);
  };

  if (!isLoaded) {
    return <LoadingPage />;
  }

  return (
    <GoogleMap
      id="map"
      center={center}
      zoom={zoom}
      onLoad={loadMapHandler}
      onUnmount={() => setMapRef(null)}
      mapContainerStyle={{
        height: '60vh',
        width: '90%',
        margin: 'auto',
        borderRadius: '3px',
      }}
      options={mapOptions}
      onDragEnd={() => updateUrlMapQuery('onDrag')}
      onZoomChanged={() => updateUrlMapQuery('onZoom')}>
      <MarkerClusterer
        options={markerClusterOptions}
        onMouseOver={mouseOverClusterHandler}
        onClick={mouseClickClusterHander}
        onMouseOut={mouseOutClusterHandler}>
        {clusterer =>
          locations.map((location, i) => (
            <Marker
              key={location['id']}
              position={{ lat: location.lat, lng: location.lng }}
              clusterer={clusterer}
              title={location['title']}
              onClick={event => markerClickHandler(event, location)}
              onDblClick={event => markerDoubleClickHandler(event)}
              icon={location['icon']}
              color={location['color']}
              zIndex={location['zIndex']}
              asset_condition={location['asset_condition']}
            />
          ))
        }
      </MarkerClusterer>
      {infoMarkerOpen && selectedPlace && (
        <InfoWindow
          // anchor={markerMap[selectedPlace.id]}
          // anchor={selectedPlace.position}
          // position={selectedPlace.position.toJSON()}
          position={{ lat: selectedPlace.place.lat, lng: selectedPlace.place.lng }}
          options={markerInfoWindowOptions}
          onCloseClick={() => setInfoMarkerOpen(false)}>
          <div style={{ color: variables.textPrimaryLight }}>
            <h3>
              <StyledLink to={`/assets/${selectedPlace.place.id}/`} value={selectedPlace.place.title} />
            </h3>
            <p>
              <AssetConditionDisplay condition={selectedPlace.place.asset_condition} />
            </p>
            {selectedPlace.place.asset_condition_set_by != null ? (
              <p>
                Set By:{' '}
                <StyledLink
                  to={`/projects/${selectedPlace.place.asset_condition_set_by.id}/`}
                  value={selectedPlace.place.asset_condition_set_by.name}
                />
              </p>
            ) : (
              <></>
            )}
          </div>
        </InfoWindow>
      )}
      {clusterInfoWindowData.marker && (
        <InfoWindow
          // position={{ lat: clusterInfoWindowData.marker.getPosition().toJSON().lat, lng: clusterInfoWindowData.marker.getPosition().toJSON().lng }}
          position={clusterInfoWindowData.marker.position}
          // position={{ lat: clusterInfoWindowData.center.toJSON().lat, lng: clusterInfoWindowData.center.toJSON().lng }}
          options={clusterInfoWindowOptions}>
          <div className={classes.basediv} style={{ color: variables.textPrimaryLight }}>
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12}>
                <h3>{clusterInfoWindowData.title}</h3>
              </Grid>
              {clusterInfoWindowData.rollups.map((value, index) => (
                <Grid
                  container
                  spacing={2}
                  key={`${value.assetCondition}-${index}`}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center">
                  <Grid key={value.assetCondition} item zeroMinWidth xs={6}>
                    <Paper key={value.assetCondition + 1000} elevation={0} className={classes.paper}>
                      <AssetConditionDisplay
                        key={value.assetCondition + 1050}
                        className={classes.paperone}
                        condition={value.assetCondition}></AssetConditionDisplay>
                    </Paper>
                  </Grid>
                  <Grid key={value.assetCondition + 110} item xs={6}>
                    <Paper key={value.assetCondition + 1150} elevation={0} className={classes.papercounts}>
                      Count: {value.count}
                    </Paper>
                  </Grid>
                </Grid>
              ))}
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <Paper className={classes.papercounts} elevation={0}>
                  Total Count: {clusterInfoWindowData.totalCount}
                </Paper>
              </Grid>
            </Grid>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
};

AssetConditionMap.defaultProps = {
  zoomToLocation: null,
  results: [],
};

AssetConditionMap.propTypes = {
  results: PropTypes.array,
  zoomToLocation: PropTypes.string,
  setZoomToLocation: PropTypes.func.isRequired,
};

export default AssetConditionMap;
