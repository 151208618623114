import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';

import Table from '../shared/table/Table';
import {
  setScheduleTasksTableView,
  setScheduleTasksTableSize,
} from '../../store/settings/views/scheduleTasksTableViewRedux';
import { deleteScheduleTasks } from '../../store/features/scheduleTasksActions';
import { openDialog } from '../../store/dialogActions';
import { locationWithBack } from '../../utilities/route';
import { apiDateToString } from '../../utilities/strings';
import { usePermissions, useTableViews } from '../../hooks/settingsHooks';
import ButtonIcon from '../shared/buttons/ButtonIcon';
import DeleteDialog from '../shared/Dialog/DeleteDialog';
import StyledLink from '../shared/StyledLink';
import { setColumns } from '../shared/table/columns';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const ScheduleTasksTable = props => {
  const { title, embedded, linkName } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { loading, data } = useSelector(state => ({
    loading: state.scheduleTasks.all.loading,
    data: state.scheduleTasks.all.dataAll.results,
  }));
  const views = useTableViews('scheduleTasksTableView');

  const { hasScheduleEventManage, hasScheduleEventView } = usePermissions();

  /** @type {Column[]} */
  const columns = [
    {
      // This needs to be first and excluded so customBodyRender can access the id to create the link.
      name: 'id',
      label: 'Id',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'checklist_instance',
      label: 'Checklist Instance',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'done',
      label: 'Done',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.rowData[columns.findIndexByName['id']];
          const checklistInstance = tableMeta.rowData[columns.findIndexByName['checklist_instance']];
          const checklistTemplate = tableMeta.rowData[columns.findIndexByName['checklist_template']].id;
          const toChecklistInstance = `/forms/${checklistInstance}`;
          const toChecklistEdit = `/forms/new/${checklistTemplate}/?schedule_task=${id}`;
          return value ? (
            <ButtonIcon
              buttonSize="medium"
              iconSize="medium"
              icon={CheckIcon}
              onClick={() => history.push(locationWithBack({ pathname: toChecklistInstance }, location))}
            />
          ) : (
            <ButtonIcon
              buttonSize="medium"
              iconSize="medium"
              icon={CheckBoxOutlineBlankIcon}
              onClick={() => history.push(locationWithBack({ pathname: toChecklistEdit }, location))}
            />
          );
        },
      },
    },
    {
      name: 'checklist_template',
      label: 'Task',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.rowData[columns.findIndexByName['id']];
          const isDone = tableMeta.rowData[columns.findIndexByName['done']];
          const checklistInstance = tableMeta.rowData[columns.findIndexByName['checklist_instance']];
          const checklistTemplate = tableMeta.rowData[columns.findIndexByName['checklist_template']].id;
          const toChecklistInstance = `/forms/${checklistInstance}`;
          const toChecklistEdit = `/forms/new/${checklistTemplate}/?schedule_task=${id}`;
          return <StyledLink to={isDone ? toChecklistInstance : toChecklistEdit} value={value ? value.name : '--'} />;
        },
      },
    },
    {
      name: 'event.start',
      label: 'Start',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return apiDateToString(value); // date
        },
      },
    },
    {
      name: 'event.end',
      label: 'End',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return apiDateToString(value); // date
        },
      },
    },
    {
      name: 'assigned_to',
      label: 'User',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return value ? value.name : '--';
        },
      },
    },
    {
      name: 'assigned_crew',
      label: 'Crew',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return value ? value.name : '--';
        },
      },
    },
    {
      name: 'event',
      label: 'Event',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => {
          return hasScheduleEventView && linkName ? (
            <StyledLink to={`/schedule-events/${value.id}`} value={value.name} />
          ) : (
            value.name
          );
        },
      },
    },
    {
      name: 'event.asset',
      label: 'Asset',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return value ? value.name : '--';
        },
      },
    },
    {
      name: 'completed_by',
      label: 'Completed By',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return value ? value.name : '--';
        },
      },
    },
    {
      name: 'completed_on',
      label: 'Completed On',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return apiDateToString(value); // date
        },
      },
    },
    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: true,
        display: hasScheduleEventManage || 'excluded',
        download: false,
        print: false,
        viewColumns: false,
        customBodyRender: (_, info) => {
          // 0 is the first element in column
          const id = info.rowData[columns.findIndexByName['id']];
          const isDone = info.rowData[columns.findIndexByName['done']];
          const checklistTemplate = info.rowData[columns.findIndexByName['checklist_template']].id;
          const checklistInstance = info.rowData[columns.findIndexByName['checklist_instance']];
          const toChecklistEdit = `/forms/new/${checklistTemplate}/?schedule_task=${id}`;
          const toChecklistInstance = `/forms/${checklistInstance}`;
          return (
            <>
              <PlaylistAddCheckIcon
                fontSize="small"
                onClick={() =>
                  history.push(locationWithBack({ pathname: isDone ? toChecklistInstance : toChecklistEdit }, location))
                }
              />
              <DeleteIcon
                fontSize="small"
                onClick={() => {
                  dispatch(
                    openDialog(
                      'Delete Schedule Tasks?',
                      <DeleteDialog id={id} deleteAction={deleteScheduleTasks} name={id} />
                    )
                  );
                }}
              />
            </>
          );
        },
      },
    },
  ];

  // handle columns display
  setColumns(columns, views);
  /** @type {TableOptions} */
  const options = {
    // if the column view changes, update redux with either 'add' or 'remove'
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setScheduleTasksTableView(changedColumn, action));
    },
    onChangeRowsPerPage: numberOfRows => {
      dispatch(setScheduleTasksTableSize(numberOfRows));
    },
    rowsPerPage: views.pageSize,
    enableNestedDataAccess: '.',
    selectableRowsHideCheckboxes: true,
    searchOpen: !embedded,
  };

  return (
    <Table embedded title={title} simpleSearch columns={columns} data={data} options={options} loading={loading} />
  );
};
ScheduleTasksTable.defaultProps = {
  data: [],
  embedded: false,
  isReadOnly: false,
  queryParamStr: '',
  title: 'Scheduled Tasks',
  linkName: true,
};

ScheduleTasksTable.propTypes = {
  title: PropTypes.string,
  queryParamStr: PropTypes.string,
  embedded: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  linkName: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object),
};

export default ScheduleTasksTable;
