import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { Divider, Grid, Tab, Tabs } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import TabPanel from '../shared/TabPanel';

import { getAllChecklistTemplates, getChecklistTemplates } from '../../store/features/checklistTemplatesActions';
import Error from '../shared/displays/Error';
import Loading from '../shared/displays/Loading';
import ChecklistTemplateToolbar from './ChecklistTemplateToolbar';
import ChecklistTemplatesView from './ChecklistTemplatesView';
import ProjectTypesTable from '../../components/projectTypes/ProjectTypesTable';
import ChecklistTemplatesTable from './ChecklistTemplatesTable';
import ChecklistTemplateDetailDisplay from './ChecklistTemplateDetailDisplay';
import ChecklistTemplateYAMLEditor from './ChecklistTemplateYAMLEditor';
import { useFeatureFlags, useTableViewSettings } from '../../hooks/settingsHooks';
import { tableViews } from '../../utilities/tables';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2, 'auto'),
    padding: theme.spacing(2),
    textAlign: 'left',
  },
  tabPanel: {
    marginTop: 0,
  },
}));

const ChecklistTemplateDetail = () => {
  const classes = useStyles();
  const { id, tab: selectedTab = 'view' } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { hasChecklistTemplateEditYaml } = useFeatureFlags();

  const { data, loading, error } = useSelector(state => state.checklistTemplates.each);
  const relatedProjectTypesQuery = { checklist_template__name: data?.name };
  const versionsQuery = { name: data?.name };

  const {
    embeddedQuery: checklistTemplatesEmbeddedQuery,
    page: checklistTemplatesPage,
    views: checklistTemplatesView,
  } = useTableViewSettings(tableViews.checklistTemplatesTableViewKey, versionsQuery);
  useEffect(() => {
    dispatch(getChecklistTemplates(id));
  }, [dispatch, id, data.checklist]);

  useEffect(() => {
    if (data?.name) {
      dispatch(getAllChecklistTemplates(versionsQuery));
    }
  }, [data?.name, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderChecklistTemplateView = () => {
    return <ChecklistTemplatesView data={data} loading={loading} error={error} />;
  };

  const renderChecklistTemplateDetailDisplay = () => {
    return <ChecklistTemplateDetailDisplay data={data} loading={loading} />;
  };

  const checklistTemplatesTableHandler = query => {
    const updatedQuery = { ...checklistTemplatesEmbeddedQuery, ...query };
    dispatch(getAllChecklistTemplates(updatedQuery));
  };

  const renderChecklistTemplateTable = () => {
    return (
      <>
        <ProjectTypesTable queryParamObj={relatedProjectTypesQuery} templateId={id} embedded />
        <ChecklistTemplatesTable
          page={checklistTemplatesPage}
          views={checklistTemplatesView}
          queryParamObj={versionsQuery}
          compareTo={data.template_keys}
          tableChangeHandler={checklistTemplatesTableHandler}
          embedded
        />
      </>
    );
  };

  const renderChecklistTemplateYAMLEditor = () => {
    // stop user from accessing url if they don't have the feature flag and manually type in the url
    if (!hasChecklistTemplateEditYaml) return <Redirect to={`/checklist-templates/${id}/`} />;
    return <ChecklistTemplateYAMLEditor templateId={id} />;
  };

  const tabs = [
    {
      value: 'view',
      label: 'View',
      Component: renderChecklistTemplateView(),
      show: true,
    },
    {
      value: 'detail',
      label: 'Detail',
      Component: renderChecklistTemplateDetailDisplay(),
      show: true,
    },
    {
      value: 'versions',
      label: 'Versions',
      Component: renderChecklistTemplateTable(),
      show: true,
    },
    {
      value: 'edit-yaml',
      label: 'Edit YAML',
      Component: renderChecklistTemplateYAMLEditor(),
      show: hasChecklistTemplateEditYaml,
    },
  ];

  let readyToRender = false;

  const handleTabChange = (event, value) => {
    history.push(`/checklist-templates/${id}/` + value);
  };

  switch (selectedTab) {
    case 'view':
    case 'detail':
    case 'versions':
    case 'edit-yaml':
      readyToRender = true;
      break;
    default:
      history.replace(`/checklist-templates/${id}`);
  }

  // the Tabs component doesn't handle value=undefined
  if (!readyToRender) return <></>;

  if (loading) {
    return <Loading />;
  }
  if (Object.keys(data).length === 0 && data.constructor === Object && !error) {
    return <Loading />;
  }
  if (error) return <Error error={error} />;

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <ChecklistTemplateToolbar id={id} data={data} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary">
          {tabs
            .filter(tab => tab.show)
            .map(tab => (
              <Tab
                id={`checklist-template-tab-${tab.value}`}
                aria-controls={`checklist-template-tabpanel-${tab.value}`}
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
        </Tabs>
        <Divider className={`${classes.divider} ${classes.tabPanel}`} />
        {tabs.map(tab => (
          <TabPanel key={tab.value} value={tab.value} selectedTab={selectedTab} name="checklist-templates">
            <Grid item className={classes.table}>
              {tab.Component}
            </Grid>
          </TabPanel>
        ))}
      </Grid>
    </div>
  );
};

export default ChecklistTemplateDetail;
