export const CHECKLIST_TASKS_REQUEST = 'CHECKLIST_TASKS_REQUEST';
export const CHECKLIST_TASKS_SUCCESS = 'CHECKLIST_TASKS_SUCCESS';
export const CHECKLIST_TASKS_FAILURE = 'CHECKLIST_TASKS_FAILURE';
export const CHECKLIST_TASKS_CREATE_REQUEST = 'CHECKLIST_TASKS_CREATE_REQUEST';
export const CHECKLIST_TASKS_CREATE_SUCCESS = 'CHECKLIST_TASKS_CREATE_SUCCESS';
export const CHECKLIST_TASKS_CREATE_FAILURE = 'CHECKLIST_TASKS_CREATE_FAILURE';
export const CHECKLIST_TASKS_UPDATE_REQUEST = 'CHECKLIST_TASKS_UPDATE_REQUEST';
export const CHECKLIST_TASKS_UPDATE_SUCCESS = 'CHECKLIST_TASKS_UPDATE_SUCCESS';
export const CHECKLIST_TASKS_UPDATE_FAILURE = 'CHECKLIST_TASKS_UPDATE_FAILURE';
export const CHECKLIST_TASKS_DELETE_REQUEST = 'CHECKLIST_TASKS_DELETE_REQUEST';
export const CHECKLIST_TASKS_DELETE_SUCCESS = 'CHECKLIST_TASKS_DELETE_SUCCESS';
export const CHECKLIST_TASKS_DELETE_FAILURE = 'CHECKLIST_TASKS_DELETE_FAILURE';
export const CHECKLIST_TASKS_ALL_REQUEST = 'CHECKLIST_TASKS_ALL_REQUEST';
export const CHECKLIST_TASKS_ALL_SUCCESS = 'CHECKLIST_TASKS_ALL_SUCCESS';
export const CHECKLIST_TASKS_ALL_FAILURE = 'CHECKLIST_TASKS_ALL_FAILURE';

export const getChecklistTasks = id => {
  return {
    type: CHECKLIST_TASKS_REQUEST,
    id,
  };
};

export const createChecklistTasks = body => {
  return {
    type: CHECKLIST_TASKS_CREATE_REQUEST,
    body,
  };
};

export const updateChecklistTasks = (id, body) => {
  return {
    type: CHECKLIST_TASKS_UPDATE_REQUEST,
    id,
    body,
  };
};

export const deleteChecklistTasks = id => {
  return {
    type: CHECKLIST_TASKS_DELETE_REQUEST,
    id,
  };
};

export const getAllChecklistTasks = query => {
  return {
    type: CHECKLIST_TASKS_ALL_REQUEST,
    query,
  };
};
