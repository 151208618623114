import { buildEndpoint, get, post } from './util';

const EACH_REDUCER = 'companiesEach';

export const companiesAssignEndpoint = buildEndpoint(
  'companiesAssign',
  (id, data) => post(`/api/companies/${id}/membership/`, data),
  { reducerKey: EACH_REDUCER }
);

export const companiesListAllEndpoint = buildEndpoint('companiesListAll', query => get('/api/companies', query));
