import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid, Paper, Tabs, Tab } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import InspectorDefectForm from './InspectorDefectForm';
import InspectorMediaForm from './InspectorMediaForm';
import InspectorLinkDefectForm from './InspectorLinkDefectForm';

import { useValueChangeOnce } from '../../hooks/renderHooks';
import Error from '../shared/displays/Error';
import StyledLink from '../shared/StyledLink';
import SplitButton from '../shared/buttons/SplitButton';
import InspectionMediaFormToolbar from './InspectorMediaFormToolbar';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: theme.spacing(59),
  },
  tab: {
    minWidth: theme.spacing(8),
  },
}));

const InspectorSidebar = props => {
  const {
    maxDefects,
    media,
    overlayInfo,
    addDefect,
    removeDefect,
    resetDefect,
    deleteDefect,
    setOverlayInfo,
    flags,
    setFlags,
    showDirtyError,
    setShowDirtyError,
    selectMedia,
    heightOffset,
  } = props;
  const classes = useStyles();
  const { projectId, asset, showAsset, defectProfile, locationLayerProfile } = useSelector(state => {
    return {
      projectId: state.projects.each.data?.id,
      asset: state.projects.each.data?.asset,
      showAsset: state.projects.each.data?.type?.allow_asset_selection_on_findings,
      defectProfile: state.projects.each.data?.type.defect_profile,
      locationLayerProfile: state.projects.each.data?.type.layer_profile,
    };
  });
  const mediaChanged = useValueChangeOnce(media.id);

  const [addDefectMode, setAddDefectMode] = useState(0);

  const { overlays, index: overlayIndex } = overlayInfo;

  if (!media) {
    return <Paper>Select media to get started.</Paper>;
  }
  // hack - reset the form to trigger reset of the mui-rff form.
  //  this is due to bug in outdated version of mui-rff.
  if (mediaChanged) {
    return <> </>;
  }

  const handleTabChange = (event, value) => {
    if (value === 'addTab') {
      // The + button is not a selectable tab
      return;
    }
    if (flags.formDirty || flags.overlayDirty || flags.formNew) {
      setShowDirtyError({
        state: true,
        from: 'InspectorSidebar:handleTabChange',
        formDirty: flags.formDirty,
        overlayDirty: flags.overlayDirty,
        formNew: flags.formNew,
      });
      return;
    }
    setOverlayInfo(prev => ({ ...prev, index: value }));
    // setFlags(prevState => ({ ...prevState, formDirty: false, overlayDirty: false }));
  };

  const handleAddDefect = selectedIndex => {
    if (overlays.length >= maxDefects) {
      return;
    }

    if (flags.formDirty || flags.overlayDirty || flags.formNew) {
      setShowDirtyError({
        state: true,
        from: 'InspectorSidebar:handleAddDefect',
        formDirty: flags.formDirty,
        overlayDirty: flags.overlayDirty,
        formNew: flags.formNew,
      });
      return;
    }
    setAddDefectMode(selectedIndex);
    setFlags(prevState => ({ ...prevState, formNew: true }));
    addDefect({ geometry: null });
  };

  const handleDelete = id => {
    // reset the overlay index so we don't accidentally select an invalid tab.
    setOverlayInfo(prev => ({ ...prev, index: 0 }));
    setShowDirtyError({
      state: false,
      from: 'InspectorSidebar:handleDelete',
      formDirty: flags.formDirty,
      overlayDirty: flags.overlayDirty,
      formNew: flags.formNew,
    });
    deleteDefect(id);
  };

  const handleCancel = event => {
    setShowDirtyError({
      state: false,
      from: 'InspectorSidebar:handleCancel',
      formDirty: flags.formDirty,
      overlayDirty: flags.overlayDirty,
      formNew: flags.formNew,
    });
    setFlags(prevState => ({ formNew: false }));
    removeDefect(overlayIndex);
  };

  const handleMediaCancel = event => {
    setShowDirtyError({
      state: false,
      from: 'InspectorSidebar:handleMediaCancel',
      formDirty: flags.formDirty,
      overlayDirty: flags.overlayDirty,
      formNew: flags.formNew,
    });
    setFlags(prevState => ({ formDirty: false }));
  };

  const isDirty = dirty => {
    setFlags(prevState => ({ ...prevState, formDirty: dirty }));
  };

  const onSubmit = () => {
    setShowDirtyError({
      state: false,
      from: 'InspectorSidebar:onSubmit',
      formDirty: flags.formDirty,
      overlayDirty: flags.overlayDirty,
      formNew: flags.formNew,
    });
    setFlags({ formNew: false, formDirty: false, overlayDirty: false });
  };

  const addDefectButtonOptions = [{ label: 'Link Finding', action: () => handleAddDefect(1) }];

  const renderUnsavedChangesError = () => {
    const defaultError = 'Unsaved Changes.  Please save your changes or click cancel.';
    if (showDirtyError.state) {
      const msg = showDirtyError.message ? showDirtyError.message : defaultError;
      return <Error message={msg} />;
    }
    return null;
  };

  return (
    <Paper>
      <Tabs
        // set Tabs value to false when nothing is selected to avoid console errors.
        value={overlays.length ? overlayIndex : false}
        indicatorColor="primary"
        textcolor="primary"
        aria-label="overlays"
        onChange={handleTabChange}>
        {overlays.map((overlay, index) => {
          return (
            <Tab
              id={`overlay-tab-${index}`}
              aria-controls={`overlay-tab-${index}`}
              wrapped
              key={index}
              label={`${index + 1}${
                (flags.formNew || flags.formDirty || flags.overlayDirty) && index === overlayIndex ? '*' : ''
              }`}
              value={index}
              classes={{ root: classes.tab }}
            />
          );
        })}
        {media && (
          <Tab
            label="Media"
            id={`inspection-media-${media.id}`}
            value={overlays.length}
            classes={{
              root: classes.tab,
            }}
          />
        )}
        <Grid container direction="column" alignItems="flex-end">
          <Grid item xs={12}>
            <SplitButton
              arrowLabel="Link Finding"
              color="secondary"
              label="New Finding"
              mainClick={() => handleAddDefect(0)}
              options={addDefectButtonOptions}
            />
          </Grid>
        </Grid>
      </Tabs>
      {renderUnsavedChangesError()}
      {overlays.map((overlay, index) => {
        let others;
        const hasLinkedDefect = overlay.overlay.defect && overlay.overlay.defect.overlays.length > 1;
        if (hasLinkedDefect) {
          others = overlay.overlay.defect.overlays.filter(obj => obj.media.id !== overlay.overlay.media);
        }
        const inspectorDefectFormProps = {
          dirty: flags.overlayDirty,
          media,
          asset,
          showAsset,
          projectId,
          defectProfile,
          locationLayerProfile,
          currentOverlay: overlay,
          cancel: handleCancel,
          selectedOverlay: overlay.overlay,
          isDirty,
          onSubmit,
          heightOffset,
        };
        return (
          <div
            key={index}
            component="div"
            hidden={index !== overlayIndex}
            role="tabpanel"
            id={`overlay-tabpanel-${index}`}
            aria-labelledby={`overlay-tab-${index}`}>
            {hasLinkedDefect && (
              <div style={{ padding: 7 }}>
                {`This finding is also shown on `}
                {others.map(obj => {
                  return (
                    <>
                      <StyledLink
                        underline
                        key={obj.media.id}
                        value={obj.media.name}
                        onClick={() => selectMedia(obj.media.id)}
                      />
                      {others.indexOf(obj) === others.length - 1 ? '.' : ', '}
                    </>
                  );
                })}
              </div>
            )}
            {overlayIndex === index && overlay && overlay.overlay.id ? (
              <InspectorDefectForm
                update
                key={overlay.overlay.id}
                onDelete={() => handleDelete(overlay.overlay.id)}
                resetDefect={() => resetDefect(overlayIndex)}
                {...inspectorDefectFormProps}
              />
            ) : addDefectMode ? (
              <InspectorLinkDefectForm assetId={asset.id} projectId={projectId} {...inspectorDefectFormProps} />
            ) : (
              <InspectorDefectForm {...inspectorDefectFormProps} />
            )}
          </div>
        );
      })}
      {overlayIndex === overlays.length && (
        <div id={`inspection-media-${media.id}`}>
          <InspectorMediaForm
            dirty={flags.formDirty}
            isDirty={isDirty}
            update
            media={media}
            toolbar={InspectionMediaFormToolbar}
            locationLayerProfile={locationLayerProfile}
            cancel={handleMediaCancel}
            onSubmit={onSubmit}
            heightOffset={heightOffset}
          />
        </div>
      )}
    </Paper>
  );
};

InspectorSidebar.defaultProps = {
  overlayInfo: {
    overlays: [],
    index: 0,
  },
  media: undefined,
  asset: undefined,
  heightOffset: '',
};

InspectorSidebar.propTypes = {
  maxDefects: PropTypes.number.isRequired,
  media: PropTypes.object,
  asset: PropTypes.object,
  defectProfile: PropTypes.object.isRequired,
  locationLayerProfile: PropTypes.object.isRequired,
  overlayInfo: PropTypes.shape({
    overlays: PropTypes.array,
    index: PropTypes.number,
  }),
  addDefect: PropTypes.func.isRequired,
  removeDefect: PropTypes.func.isRequired,
  resetDefect: PropTypes.func.isRequired,
  deleteDefect: PropTypes.func.isRequired,
  setOverlayInfo: PropTypes.func.isRequired,
  flags: PropTypes.shape({
    formNew: PropTypes.bool,
    formDirty: PropTypes.bool,
    overlayDirty: PropTypes.bool,
  }).isRequired,
  setFlags: PropTypes.func.isRequired,
  showDirtyError: PropTypes.object.isRequired,
  setShowDirtyError: PropTypes.func.isRequired,
  selectMedia: PropTypes.func.isRequired,
  heightOffset: PropTypes.string,
};

export default InspectorSidebar;
