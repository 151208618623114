import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';

import DefectsTable from './DefectsTable';

import { getAllDefects } from '../../store/features/defectsActions';
import { toQueryString } from '../../utilities/strings';
import { getParams } from '../../utilities/route';

import { useTableViewSettings } from '../../hooks/settingsHooks';

import { tableViews } from '../../utilities/tables';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const DefectsTablePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const { defectsTableViewKey } = tableViews;
  const coreDefectsQuery = { latest: true }; // always use this query on this page
  const {
    tableQuery: defectsQuery,
    embeddedQuery: defectsEmbeddedQuery,
    page,
    views,
  } = useTableViewSettings(defectsTableViewKey, coreDefectsQuery);

  // check for url
  const queryParams = getParams(location, defectsQuery);

  useEffect(() => {
    dispatch(getAllDefects(queryParams));
  }, []); // eslint-disable-line

  const tableChangeHandler = query => {
    // no need to fetch data if the query is the same.
    const newSearch = toQueryString(query);
    if (location.search === newSearch) return;
    const newLocation = location;
    newLocation.search = newSearch;
    history.push(newLocation);

    const updatedQuery = { ...defectsEmbeddedQuery, ...query };
    dispatch(getAllDefects(updatedQuery));
  };

  return (
    <div className={classes.root}>
      <DefectsTable
        title="Findings"
        queryParamObj={queryParams}
        tableChangeHandler={tableChangeHandler}
        page={page}
        views={views}
      />
    </div>
  );
};

export default DefectsTablePage;
