// lib
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// components
import DateLineItem from './lineItems/DateLineItem';
import TextLineItem from './lineItems/TextLineItem';
import ChoiceLineItem from './lineItems/ChoiceLineItem';
import CheckboxLineItem from './lineItems/CheckboxLineItem';
import RadioLineItem from './lineItems/RadioLineItem';
import SignatureLineItem from './lineItems/SignatureLineItem';
import MediaPanel from './lineItems/MediaPanel';
import CalculationLineItem from './lineItems/CalculationLineItem';
import CalculationExpressionEditorLineItem from './lineItems/CalculationExpressionEditorLineItem';
import CollectPositionLineItem from './lineItems/CollectPositionLineItem';
import CollectMediaLineItem from './lineItems/CollectMediaLineItem';
import NumberLineItem from './lineItems/NumberLineItem';
import NumberListLineItem from './lineItems/NumberListLineItem';
import Markdown from '../shared/Markdown';

// utils / styles
import { useFeatureFlags, usePermissions } from '../../hooks/settingsHooks';
import { actionsEnabled, CHECKLIST_ACTIONS, CHECKLIST_LINE_TYPES, nestLineGroups } from '../../utilities/checklist';
import './Checklist.scss';
import { composeValidators, getValidators } from '../../utilities/validators';

const useStyles = makeStyles(theme => ({
  resize: {
    fontSize: '18px',
    lineHeight: '1.6',
    textAlign: 'left',
  },
  imgResize: {
    width: '100%',
  },
  wrappedText: {
    whiteSpace: 'normal',
  },
}));

function ChecklistSection(props) {
  const { lines, defaultActions, description, readOnly, override } = props;
  const title = props.label;
  const classes = useStyles();
  const { hasConfigurationManagementBuild } = usePermissions();
  const { hasChecklistCalculationLineType } = useFeatureFlags();
  const prefix = props.sectionId;
  const MIN_CHOICE_SIZE_FOR_SELECT = 5;
  const renderActions = line => {
    const noActions = ['line-group', 'line-group-end', 'text block', 'image', 'signature']; // display only items

    if (noActions.includes(line.line_type)) {
      return <></>;
    }
    const cellProps = {};
    const noteFieldProps = {
      label: 'Notes',
      name: `${prefix}.${line.key}.notes`,
      placeholder: 'Add a note',
      multiline: true,
      variant: 'outlined',
      inputPropClasses: classes.resize,
    };
    const mediaFieldProps = {
      name: `${prefix}.${line.key}.media`,
    };
    const mediaEnabled =
      actionsEnabled(CHECKLIST_ACTIONS.CAMERA, line, defaultActions) ||
      actionsEnabled(CHECKLIST_ACTIONS.GALLERY, line, defaultActions);
    return (
      <React.Fragment>
        {actionsEnabled(CHECKLIST_ACTIONS.NOTES, line, defaultActions) && (
          <Grid item {...cellProps} className="grid-item" key={`${line.key}.actions`}>
            <TextLineItem {...noteFieldProps} />
          </Grid>
        )}
        {mediaEnabled && (
          <Grid item {...cellProps} className="grid-item">
            <MediaPanel {...mediaFieldProps} />
          </Grid>
        )}
      </React.Fragment>
    );
  };
  const renderLineItem = line => {
    const { line_type: lineType } = line;
    const label = line.label.replace(/<[^>]+>/g, ''); // remove html tags
    // Yup validation which is difficult to get working currently
    // need to pass this in: {sec1: {line1: {value: "blah" } } }
    // const schema = yup.object().shape({
    //   [line.key]: yup.string().required("Required"),
    // });
    // const validate = makeValidate(schema);

    const fieldProps = {
      key: line.key,
      label: label,
      name: `${prefix}.${line.key}.value`,
      placeholder: '',
      inputPropClasses: classes.resize,
      validator: override ? () => undefined : composeValidators(...getValidators(line?.validator_objects, lineType)),
    };
    switch (lineType) {
      case CHECKLIST_LINE_TYPES.NUMBER_LIST:
        return (
          <>
            <Markdown>{label}</Markdown>
            <NumberListLineItem
              {...fieldProps}
              validator={
                override
                  ? () => undefined
                  : composeValidators(
                      ...getValidators(
                        line?.validator_objects
                          ? [...line.validator_objects, { type: 'number' }]
                          : [{ type: 'number' }],
                        lineType
                      )
                    )
              }
              helperText={<Markdown className={classes.wrappedText}>{line.description}</Markdown>}
            />
          </>
        );
      case CHECKLIST_LINE_TYPES.TEXT_BLOCK:
        return (
          <>
            <Markdown>{label}</Markdown>
            <Markdown multilineBlockMargins>{line.description}</Markdown>
          </>
        );
      case CHECKLIST_LINE_TYPES.IMAGE:
        return <img className={classes.imgResize} src={line.url} title={line.description} />;
      case CHECKLIST_LINE_TYPES.TEXT:
        return (
          <TextLineItem
            {...fieldProps}
            helperText={<Markdown className={classes.wrappedText}>{line.description}</Markdown>}
          />
        );
      case CHECKLIST_LINE_TYPES.NUMBER:
        return (
          <NumberLineItem
            {...fieldProps}
            validator={
              override
                ? () => undefined
                : composeValidators(
                    ...getValidators(
                      line?.validator_objects ? [...line.validator_objects, { type: 'number' }] : [{ type: 'number' }],
                      lineType
                    )
                  )
            }
            helperText={<Markdown className={classes.wrappedText}>{line.description}</Markdown>}
          />
        );
      case CHECKLIST_LINE_TYPES.TEXTAREA:
        return (
          <TextLineItem
            {...fieldProps}
            helperText={<Markdown className={classes.wrappedText}>{line.description}</Markdown>}
            multiline
          />
        );
      case CHECKLIST_LINE_TYPES.TEXT_DATE:
      case CHECKLIST_LINE_TYPES.DATE:
        return <DateLineItem {...fieldProps} helperText={line.description} />;

      case CHECKLIST_LINE_TYPES.CHOICE:
        return line.choices.length > MIN_CHOICE_SIZE_FOR_SELECT ? (
          <ChoiceLineItem {...fieldProps} options={line.choices} helperText={line.description} />
        ) : (
          <RadioLineItem {...fieldProps} options={line.choices} helperText={line.description} />
        );
      case CHECKLIST_LINE_TYPES.MULTIPLE_CHOICE:
        return line.choices.length > MIN_CHOICE_SIZE_FOR_SELECT ? (
          <ChoiceLineItem {...fieldProps} options={line.choices} helperText={line.description} multiple={true} />
        ) : (
          <CheckboxLineItem {...fieldProps} options={line.choices} helperText={line.description} />
        );
      case CHECKLIST_LINE_TYPES.SIGNATURE:
        delete fieldProps['name']; // remove name to reset
        return <SignatureLineItem name={`${prefix}.${line.key}.media`} {...fieldProps} />;
      case CHECKLIST_LINE_TYPES.COLLECT_MEDIA:
        return (
          <CollectMediaLineItem
            {...fieldProps}
            name={`${prefix}.${line.key}.media`}
            helperText={<Markdown className={classes.wrappedText}>{line.description}</Markdown>}
          />
        );
      case CHECKLIST_LINE_TYPES.COLLECT_POSITION:
        return <CollectPositionLineItem {...fieldProps} helperText={line.description} />;
      case CHECKLIST_LINE_TYPES.LINE_GROUP: // handle this outside of this function
      case CHECKLIST_LINE_TYPES.LINE_GROUP_END:
        return;
      case CHECKLIST_LINE_TYPES.CALCULATION:
        if (readOnly && hasConfigurationManagementBuild && hasChecklistCalculationLineType) {
          return (
            <CalculationExpressionEditorLineItem
              {...fieldProps}
              expression={line.expression}
              helperText={line.description}
            />
          );
        }
        return <CalculationLineItem {...fieldProps} expression={line.expression} helperText={line.description} />;
      default:
        console.log('**** UNHANDLED LINE TYPE: ', line.label, lineType);
        return <Markdown>{`**Unsupported line type**: ${lineType} for ${line.label}`}</Markdown>;
    }
  };

  const groupLines = nestLineGroups(lines);
  const formattedLines = lines ? lines.filter(x => !groupLines.includes(x)) : []; // only use lines that are not nested in a group
  const lineItems = formattedLines.map((line, index) => {
    const cellProps = {};
    return (
      <Grid container justifyContent="center" {...cellProps} key={line.key} className="line-items">
        <Grid item xs={10}>
          {line.line_type === CHECKLIST_LINE_TYPES.LINE_GROUP ? (
            <Accordion>
              <AccordionSummary id={line.label} {...line} expandIcon={<ExpandMoreIcon />}>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item>
                    <Markdown>{line.label}</Markdown>
                    <Markdown>{line.description}</Markdown>
                  </Grid>
                </Grid>
              </AccordionSummary>

              {line.groupLines.map(line => (
                <AccordionDetails key={line.label}>
                  <Grid container direction="row" alignItems="stretch">
                    <Grid item xs={12}>
                      {renderLineItem(line)}
                    </Grid>

                    <Grid item xs>
                      {renderActions(line)}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              ))}
            </Accordion>
          ) : (
            renderLineItem(line)
          )}
        </Grid>
        <Grid item xs={10}>
          {renderActions(line)}
        </Grid>
      </Grid>
    );
  });

  return (
    <>
      <h1 className="add-margin-top-bottom">{title}</h1>
      <h4 className="margin-sides">{description}</h4>
      <Divider />
      {lineItems}
    </>
  );
}

ChecklistSection.propTypes = {
  sectionId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  lines: PropTypes.array.isRequired,
  defaultActions: PropTypes.array,
  description: PropTypes.string,
  readOnly: PropTypes.bool.isRequired,
  override: PropTypes.bool.isRequired,
};
ChecklistSection.defaultProps = { defaultActions: [] };

export default ChecklistSection;
