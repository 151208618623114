import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLastFetchAt, setLastFetchAt, shouldFetchState } from './shared';
import { getAllChecklistTemplates } from '../store/features/checklistTemplatesActions';
import { capitalizeFirstChar } from '../utilities/strings';

const checklistTypeQuery = { ordering: 'name' };
const localStorageKey = 'lastChecklistsTime';

export const useCachedChecklistTemplates = (query = checklistTypeQuery) => {
  const dispatch = useDispatch();
  const { dataAll: { results } = { results: [] }, query: checklistTypesQuery } = useSelector(
    state => state.checklistTemplates.all
  );
  useEffect(() => {
    if (!query) return;

    const lastFetch = getLastFetchAt(localStorageKey);

    const shouldFetch = shouldFetchState(query, checklistTypesQuery, lastFetch);
    if (shouldFetch) {
      dispatch(getAllChecklistTemplates(checklistTypeQuery));
      setLastFetchAt(localStorageKey);
    }
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  const checklistTemplateMap = {};
  const checklistTemplateOptions = results
    ? [
        ...new Set(
          results.flatMap(({ name, id, version, state }) => {
            const value = id.toString();
            const stateStr = state ? ' [ ' + capitalizeFirstChar(state.toLowerCase()) + ' ]' : '';
            checklistTemplateMap[value] = name + ' - ' + version + stateStr;
            return value;
          })
        ),
      ]
    : [];

  const categoriesOptions = results
    ? [
        ...new Set(
          results.flatMap(item => {
            if (item.categories) {
              return item.categories;
            }
            return undefined;
          })
        ),
      ]
    : [];

  return {
    results,
    checklistTemplateOptions,
    checklistTemplateMap,
    categoriesOptions,
  };
};
