import { isEmpty } from './objects';
import { capitalizeFirstChar, removeQuotes } from './strings';

export const DEFAULT_PAGE_SIZE = 50;

export const tableViews = {
  activityTableViewKey: 'activityTableView',
  assessmentsTableViewKey: 'assessmentsTableView',
  assessmentTypeTableViewKey: 'assessmentTypeTableView',
  assetConditionTableViewKey: 'assetConditionTableView',
  assetTypesTableViewKey: 'assetTypesTableView',
  assetsTableViewKey: 'assetsTableView',
  bulkImportsTableViewKey: 'bulkImportsTableView',
  checklistResultLinesTableViewKey: 'checklistsResultLinesTableView',
  checklistTasksTableViewKey: 'checklistTasksTableView',
  checklistTemplatesTableViewKey: 'checklistTemplatesTableView',
  checklistsTableViewKey: 'checklistsTableView',
  checklistViewerTableViewKey: 'checklistViewerTableView',
  cmlsTableViewKey: 'cmlsTableView',
  companiesTableViewKey: 'companiesTableView',
  crewsTableViewKey: 'crewsTableView',
  defectProfilesTableViewKey: 'defectProfilesTableView',
  defectsTableViewKey: 'defectsTableView',
  heatDataTableViewKey: 'heatDataTableView',
  inspectionMediaTableViewKey: 'inspectionMediaTableView',
  librariesTableViewKey: 'librariesTableView',
  libraryMediaTableViewKey: 'libraryMediaTableView',
  locationLayerProfilesTableViewKey: 'locationLayerProfilesTableView',
  measurementsTableViewKey: 'measurementsTableView',
  notificationRuleSubscribersTableViewKey: 'notificationRuleSubscribersTableView',
  notificationRulesTableViewKey: 'notificationRulesTableView',
  projectTypesTableViewKey: 'projectTypesTableView',
  projectsTableViewKey: 'projectsTableView',
  projectSelectedCmlsTableViewKey: 'projectSelectedCmlsTableViewKey',
  projectSelectedFindingsTableViewKey: 'projectSelectedFindingsTableViewKey',
  recommendedWorkPlanTableViewKey: 'recommendedWorkPlanTableView',
  reportTypesTableViewKey: 'reportTypesTableView',
  reservationsTableViewKey: 'reservationsTableView',
  scheduleEventsTableViewKey: 'scheduleEventsTableView',
  schedulePlanTableViewKey: 'schedulePlanTableView',
  scheduleTasksTableViewKey: 'scheduleTasksTableView',
  scheduleWorkTableViewKey: 'scheduleWorkTableView',
  userNavViewKey: 'userNavView',
  usersTableViewKey: 'usersTableView',
  watchersTableViewKey: 'watchersTableView',
};

/**
 * @param {String} type
 * @param {*} value
 * @param {String} key
 * @param {Object?} extraData - required when type `objectArrayToString`
 * @param {Array?} columns - required when type `objectArrayToString`
 * @returns {String}
 */
export const getCustomCSVData = (type, value, key = 'name', extraData, columns) => {
  if (!value || (typeof value === 'object' && isEmpty(value))) return '';
  switch (type) {
    case 'simple':
      if (typeof value === 'string') return value;
      return !(key in value) ? '' : value[key];
    case 'simpleArray':
      // comma separated list of values, e.g. ['value1', 'value2', 'value3']
      return value.join(', ');
    case 'array':
      // This is really array of objects and we have to account for different React Fragments
      return value.map(value => {
        if (value?.props?.children) {
          if (Array.isArray(value.props.children) && !!value.props.children.length) {
            // might be an array of Styled Links
            return value.props.children.flatMap(child => {
              if (child?.props?.value) {
                return child.props.value;
              } else if (child?.value) {
                return child.value;
              } else if (typeof child === 'string') {
                return child;
              } else if (child?.props?.children) {
                return child.props.children;
              }
              return [];
            });
          } else if (typeof value.props.children === 'object' && 'props' in value.props.children) {
            return value.props.children.props.value;
          }
        }
        return value[key] || '';
      });
    case 'labels':
      return value.map(item => `${item.name}:${removeQuotes(item.value)}`);
    case 'key-value':
      return Object.entries(value).map(
        ([key, value]) => capitalizeFirstChar(key) + ':' + (capitalizeFirstChar(value) || 'Other')
      );
    case 'objectArrayToString': {
      if (!isEmpty(extraData) && !isEmpty(columns)) {
        const id = extraData[columns.findIndex(item => item?.name === 'id')];
        // construct an asset path excluding the current path
        return value
          .map(item => (item?.id !== id ? item?.name : ''))
          .slice(0, -1)
          .join('/');
      }
      return '';
    }
    case 'completed':
      if (Array.isArray(value)) {
        return value.some(item => !item.done) ? 'Incomplete' : 'Complete';
      }
      return '';
    default:
      return value;
  }
};

export const getColumnNames = columns =>
  columns.reduce((acc, item, index) => {
    acc[item.name] = index;
    return acc;
  }, {});

/**
 * Description
 * @param {string} sortField
 * @param {string} direction
 * @param {string} name
 * @returns {string}
 */
export const setQueryParamsSortField = (sortField, direction, name) => {
  // If sortField is present, use it to determine the field to sort on
  if (sortField) {
    const sortFields = sortField.split(','); // some sortFields can have multiple fields listed
    return sortFields.map(item => `${direction === 'desc' ? '-' : ''}${item}`).join(','); // label each ordering parameter with '-' if descending
  }
  // Otherwise, use the name argument to determine the field to sort on
  else {
    return `${direction === 'desc' ? '-' : ''}${name}`;
  }
};

/**
 * @description Get data object corresponding to a table row,
 *                NOTE - assumes table has `id` field
 * @param {Array} data - data used for the table (e.g. const { data } = useSelector(...); data.results)
 * @param {Object} rowData - tableMeta.rowData object from MUI Datatables (i.e supplied to the customBodyRender function)
 * @param {String} idColumnIndex - table column index for the id field
 * @returns {Object} - the raw data used for the table row
 * @example
 *    const { data } = useSelector(...);
 *   const columns = [
 *     ...
 *      options: {
 *        customBodyRender: (value, tableMeta) => {
 *          const obj = getTableRowDataObject(data, tableMeta.rowData);
 *         return obj.name + ' ' + obj.description;
 */
export const getTableRowDataObject = (data, rowData, idColumnIndex = 0) => {
  const id = rowData[idColumnIndex];
  return data.find(item => item.id === id);
};
