import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ButtonGroup } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Description from '../shared/table/Description';
import Table, { SELECTABLE_ROWS_NONE } from '../shared/table/Table';
import { reportTypesListAllEndpoint, reportTypesDeleteEndpoint } from '../../store/apiV2/reportTypes';
import { setReportTypesTableView, setReportTypesTableSize } from '../../store/settings/views/reportTypesTableViewRedux';
import { buildRoute, apiDateToString } from '../../utilities/strings';
import ButtonIcon from '../shared/buttons/ButtonIcon';
import StyledLink from '../shared/StyledLink';
import { setColumns } from '../shared/table/columns';
import { openDialog } from '../../store/dialogActions';
import DeleteDialog from '../shared/Dialog/DeleteDialog';
import { usePermissions, useTableViews } from '../../hooks/settingsHooks';
import { tableViews } from '../../utilities/tables';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const ReportTypesTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const reportTypesListAll = reportTypesListAllEndpoint.useEndpoint();
  const { data, loading } = reportTypesListAll;

  const { reportTypesTableViewKey } = tableViews;
  const views = useTableViews(reportTypesTableViewKey);
  const { hasConfigurationManagementBuild } = usePermissions();

  useEffect(() => {
    reportTypesListAll.dispatchRequest();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const classes = useStyles();

  /** @type {Column[]} */
  const columns = [
    {
      // This needs to be first and excluded so customBodyRender can access the id to create the link.
      name: 'id',
      label: 'Id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        filterType: 'textField',
        customBodyRender: (value, tableMeta) => {
          return <StyledLink to={`/report-types/${tableMeta.rowData[columns.findIndexByName['id']]}`} value={value} />;
        },
      },
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        filter: true,
        sort: true,
        filterType: 'textField',
        customBodyRender: value => {
          if (!value) return <></>;
          return <Description value={value} />;
        },
      },
    },
    {
      name: 'uri',
      label: 'URI',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'template',
      label: 'Template',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'version',
      label: 'Version',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'filter_section',
      label: 'Section Filter',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'filter_line',
      label: 'Line Filter',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'filter_image',
      label: 'Image Filter',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'created_on',
      label: 'Created',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => apiDateToString(value, 'short-timestamp'),
      },
    },
    {
      name: 'updated_on',
      label: 'Updated',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => apiDateToString(value, 'short-timestamp'),
      },
    },
    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        print: false,
        viewColumns: false,
        customBodyRender: (_, info) => {
          // 0 is the first element in column
          const id = info.rowData[columns.findIndexByName['id']];
          const name = info.rowData[columns.findIndexByName['name']];
          return (
            <>
              {hasConfigurationManagementBuild && (
                <ButtonGroup>
                  <ButtonIcon history={history} icon={EditIcon} location={location} to={`/report-types/${id}/edit`} />
                  <ButtonIcon
                    icon={DeleteIcon}
                    onClick={() => {
                      dispatch(
                        openDialog(
                          'Delete Report Type?',
                          <DeleteDialog
                            id={id}
                            deleteAction={(...args) => {
                              // expects plain action, not dispatched action
                              return reportTypesDeleteEndpoint.actionCreators.request(args, () =>
                                reportTypesListAll.dispatchRequest()
                              );
                            }}
                            name={name}
                          />
                        )
                      );
                    }}
                  />
                </ButtonGroup>
              )}
            </>
          );
        },
      },
    },
  ];

  // handle columns display
  setColumns(columns, views);

  /** @type {TableOptions} */
  const options = {
    // if the column view changes, update redux with either 'add' or 'remove'
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setReportTypesTableView(changedColumn, action));
    },
    onChangeRowsPerPage: numberOfRows => {
      dispatch(setReportTypesTableSize(numberOfRows));
    },
    rowsPerPage: views.pageSize,
    enableNestedDataAccess: '.',
    selectableRows: SELECTABLE_ROWS_NONE,
  };

  return (
    <div className={classes.root}>
      <Table
        title="Report Types"
        simpleSearch
        columns={columns}
        addRoute={buildRoute('/report-types/new')}
        data={data?.results || []}
        options={options}
        loading={loading}
      />
    </div>
  );
};

export default ReportTypesTable;
