// lib
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import CallMergeIcon from '@mui/icons-material/CallMerge';
import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import * as Sentry from '@sentry/browser';

// components
import PrimaryButton from '../shared/buttons/PrimaryButton';
import ButtonIcon from '../shared/buttons/ButtonIcon';
import CustomToolbarSelectMedia from '../shared/table/CustomToolbarSelectMedia';
import ConfirmDialog from '../shared/Dialog/ConfirmDialog';
import FindingsBulkEditDialog from './FindingsBulkEditDialog';

// util
import { locationWithBack } from '../../utilities/route';
import { openDialog } from '../../store/dialogActions';
import { linkDefects } from '../../store/features/defectsActions';
import { useFeatureFlags, usePermissions } from '../../hooks/settingsHooks';
import { getIdFromRow } from '../shared/table/columns';
import { getProjects } from '../../store/features/projectsActions';
import { projectsScopeUpdateEndpoint } from '../../store/apiV2/projects';
import { ProjectScopeContext } from '../projects/projectScope-context';
import { openSnackbar } from '../../store/snackbarActions';

const useStyles = makeStyles(theme => ({
  inheritDisplay: {
    display: 'inherit',
    marginRight: theme.spacing(2),
  },
  saveMessage: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
}));

const getAssetIdFromRow = (rowObj, displayData, columns) => {
  const columnIdx = columns.findIndexByName['asset.id'];
  if (columnIdx !== undefined) {
    return displayData[rowObj.dataIndex].data[columnIdx];
  } else {
    // someone took my column!
    Sentry.captureMessage('DefectToolbarSelect missing column asset.id');
  }
};

const DefectToolBarSelect = props => {
  const { selectedRows, displayData, disableNewProject, getFilesFn, columns, scopeMode } = props;

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { hasFindingsLinking, hasFindingsBulkEdit, hasFindingsToProjects } = useFeatureFlags();
  const { hasDefectEdit, hasProjectCreate } = usePermissions();
  const projectsScopeUpdate = projectsScopeUpdateEndpoint.useEndpoint();
  const { scopeData, projectId, setOpenFindingsDialog } = useContext(ProjectScopeContext);

  // New project depends on table settings, feature flag AND permissions
  const showNewProject = !disableNewProject && hasFindingsToProjects && hasProjectCreate;

  // Bulk Findings is behind a feature flag + permission
  const showFindingsBulkEdit = hasFindingsBulkEdit && hasDefectEdit;

  // Merge/Linking of Findings is possible if there multiple findings all on the same Finding
  const showFindingsMerge = hasFindingsLinking && hasDefectEdit;

  const canMergeTheseFindings = (selectedRows, displayData, columns) => {
    // Findings can be merged if they are on the same asset and there has to be more than 1
    if (selectedRows.data.length < 2) return false;

    try {
      const firstAsset = getAssetIdFromRow(selectedRows.data[0], displayData, columns);
      return selectedRows.data.every(item => getAssetIdFromRow(item, displayData, columns) === firstAsset);
    } catch (error) {
      Sentry.captureMessage('DefectToolbarSelect::canMergeTheseFindings error: ', error);
      return false;
    }
  };

  const disableMerge = !canMergeTheseFindings(selectedRows, displayData, columns);

  // Get array of defect IDs out of the table data
  const selectedFindingIds = selectedRows.data.map(item => getIdFromRow(item, displayData, columns));

  const handleNewProjectClick = () => {
    if (showNewProject) {
      const newLocation = {
        pathname: '/findings/add-to-project',
        search: `?findings=${selectedFindingIds.join()}`,
      };
      const next = locationWithBack(newLocation, location);
      history.push(next);
    }
  };

  const confirmMerge = () => {
    const nextRoute = ''; // Use default
    dispatch(linkDefects(selectedFindingIds, nextRoute));
  };

  const handleMergeClick = () => {
    const notes = (
      <span>
        <b>Do you want to merge these {selectedFindingIds.length} findings?</b>
        <br />
        <br />
        Merging will mark these findings as the same issue and they will be tracked together. Only the newest findings
        will displayed with the Asset. No data is lost and it can be undone later.
      </span>
    );

    dispatch(openDialog(<ConfirmDialog notes={notes} confirmAction={confirmMerge} />));
  };

  const updateProjectScope = ids => {
    projectsScopeUpdate
      .dispatchRequest(projectId, { selected_findings: [...ids] })
      .then(() => setOpenFindingsDialog(false))
      .then(() => dispatch(openSnackbar('Project has been updated.', 'success', null, 2000)))
      .then(() => dispatch(getProjects(projectId)))
      .catch(error => {
        console.error(error);
        dispatch(openSnackbar('Error updating project', 'error', null, 2000));
      });
  };

  const existingFindingIds = scopeData?.selected_findings.map(f => f.id) || [];

  const handleRemove = () => {
    const payloadIds = existingFindingIds.filter(x => !selectedFindingIds.includes(x)); // remove selected ids
    updateProjectScope(payloadIds);
  };

  const handleSave = () => {
    updateProjectScope([...existingFindingIds, ...selectedFindingIds]);
  };

  const handleBulkEdit = () => {
    dispatch(
      openDialog(
        'Edit Findings',
        <FindingsBulkEditDialog findingsIds={selectedFindingIds} selectedRows={selectedRows} />,
        {
          fullScreen: true,
        }
      )
    );
  };

  const renderToolbarButtons = () => {
    switch (scopeMode) {
      case 'SELECT':
        return (
          <>
            <div className={classes.saveMessage}>
              After saving, anyone working on this project will need to sync again to get the latest changes.
            </div>
            <PrimaryButton
              icon
              label="Save"
              aria-label="Add Selected Findings"
              tooltip="Add Selected Findings"
              onClick={handleSave}
            />
          </>
        );
      case 'DEFAULT':
        return (
          <ButtonIcon
            iconSize="large"
            icon={RemoveCircleOutlineIcon}
            onClick={handleRemove}
            aria-label="Remove Findings from existing project"
            tooltip="Remove Findings from existing project."
          />
        );
      default:
        return (
          <>
            {showNewProject && (
              <PrimaryButton
                icon
                Icon={AddIcon}
                label="New Project"
                tooltip="New Project(s) using Selected Findings"
                onClick={handleNewProjectClick}
              />
            )}
            {showFindingsMerge && (
              <ButtonIcon
                icon={CallMergeIcon}
                aria-label="Merge Findings"
                tooltip="Merge Findings"
                onClick={handleMergeClick}
                disabled={disableMerge}
                buttonSize="medium"
                iconSize="medium"
              />
            )}
            {showFindingsBulkEdit && (
              <ButtonIcon
                icon={EditIcon}
                aria-label="Edit Findings"
                tooltip="Edit Findings"
                onClick={handleBulkEdit}
                buttonSize="medium"
                iconSize="medium"
              />
            )}
            <CustomToolbarSelectMedia
              selectedRows={selectedRows}
              displayData={displayData}
              getInspectionMediaFiles={getFilesFn}
              hideUnzip
            />
          </>
        );
    }
  };

  return <div className={classes.inheritDisplay}>{renderToolbarButtons()}</div>;
};

DefectToolBarSelect.defaultProps = {
  disableNewProject: false,
  getFilesFn: () => {}, // TODO can we build a default that looks for `file`?
};

DefectToolBarSelect.propTypes = {
  selectedRows: PropTypes.shape({
    data: PropTypes.array,
    dataIndex: PropTypes.Integer,
  }).isRequired,
  displayData: PropTypes.array.isRequired,
  disableNewProject: PropTypes.bool, // override the feature flag to hide new project button
  getFilesFn: PropTypes.func, // function to extract files for download or zip
  columns: PropTypes.arrayOf(PropTypes.object).isRequired, // Table column definitions
  scopeMode: PropTypes.string.isRequired,
};

export default DefectToolBarSelect;
