import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

// components
import AssessmentsTable from './AssessmentsTable';

// util
import { setTableParamObj } from '../../utilities/objects';
import { getAllAssessments } from '../../store/features/assessmentsActions';
import { toQueryString } from '../../utilities/strings';
import { useTableViews } from '../../hooks/settingsHooks';
import { tableViews } from '../../utilities/tables';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(7, '3vw'),
    padding: theme.spacing(2),
  },
}));

const AssessmentsTablePage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { assessmentsTableViewKey } = tableViews;
  const { pageSize } = useTableViews(assessmentsTableViewKey);

  // check for page size settings
  const queryParams = setTableParamObj(location, pageSize);

  useEffect(() => {
    dispatch(getAllAssessments(queryParams));
  }, []); // eslint-disable-line

  const tableChangeHandler = query => {
    // no need to fetch data if the query is the same.
    const newSearch = toQueryString(query);
    if (location.search === newSearch) return;
    const newLocation = location;
    newLocation.search = newSearch;
    history.push(newLocation);
    dispatch(getAllAssessments(query));
  };

  return (
    <Paper className={classes.root}>
      <AssessmentsTable title="Assessments" queryParamObj={queryParams} tableChangeHandler={tableChangeHandler} />
    </Paper>
  );
};

export default AssessmentsTablePage;
