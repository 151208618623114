// For some features, we will fetch everything and do searches, filtering and pagination on the client side
// The api automatically paginates even if you don't send a limit parameter in the GET.
// The response will have a next in the body that contains a url for getting the next page of data
import { put } from 'redux-saga/effects';

export function* fetchListAll(fetchFn, query = '') {
  const { data, error, response } = yield fetchFn(query);
  if (data) {
    if (data.results && data.next) {
      let { results, next } = data;
      while (next !== null) {
        const query = `?${next.split('?')[1]}`;
        const { data } = yield fetchFn(query);
        if (data) {
          next = data.next;
          results = [...results, ...data.results];
        } else {
          throw Error('Retrieving all data failed.');
        }
      }
      data.next = null;
      data.results = results;
    }
    return { data };
  }
  if (error) return { error };
  return { response };
}

export function* batchFetchAll(fetchFn, query = '', batchSuccess) {
  const { data, error, response } = yield fetchFn(query);
  if (data) {
    yield put({ type: batchSuccess, data });
    if (data.results && data.next) {
      let { next } = data;
      while (next !== null) {
        const query = `?${next.split('?')[1]}`;
        const { data: batchedData } = yield fetchFn(query);
        if (batchedData) {
          yield put({ type: batchSuccess, data: batchedData });
          next = batchedData.next;
        } else {
          throw Error('Retrieving all data failed.');
        }
      }
    }
    return { data };
  }
  if (error) return { error };
  return { response };
}
