import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import PrimaryButton from '../shared/buttons/PrimaryButton';
import SecondaryButton from '../shared/buttons/SecondaryButton';

const useStyles = makeStyles(theme => ({
  buttonGroup: {
    padding: theme.spacing(2, 2, 0, 2),
  },
}));

export default function StepperToolbar(props) {
  const { title, back, next } = props;
  const classes = useStyles();
  const [submitDisabled, setSubmitDisabled] = useState(false);

  return (
    <Grid container justifyContent="space-between" className={classes.buttonGroup}>
      <Grid item>{!back.hide && <SecondaryButton {...back} />}</Grid>
      <Grid item>
        <h3>{title}</h3>
      </Grid>
      <Grid item>
        {!next.hide && (
          <PrimaryButton
            {...next}
            disabled={next.disabled ?? submitDisabled} // sometimes disabled is passed in through next
            onClick={() => {
              setSubmitDisabled(true);
              next.onClick();
            }}
          />
        )}
      </Grid>
    </Grid>
  );
}

StepperToolbar.defaultProps = { title: '', hideNext: false };

StepperToolbar.propTypes = {
  back: PropTypes.object.isRequired,
  next: PropTypes.object.isRequired,
  title: PropTypes.string,
};
