import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fieldOrder, removeField, hideField } from './inspectionMediaShared';
import { apiDateToString, convertToSeconds, jsonKeyToLabel } from '../../utilities/strings';
import CommonFormView from '../shared/form/CommonView';
import StyledLink from '../shared/StyledLink';
import { useFeatureFlags } from '../../hooks/settingsHooks';

const InspectionMediaView = props => {
  const { data, loading, error, fullHeight } = props;
  const { hasAccessDisplay } = useFeatureFlags();
  const history = useHistory();
  const { template: { name: templateName } = { templateName: '' }, id: checklistId } = useSelector(
    state => state.checklists.each.data
  );

  const formSettings = {
    fieldOrder,
    removeField: removeField('VIEW'),
    hideField: (values, item) => {
      return hideField(values, item, hasAccessDisplay);
    },
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      // cast to string because booleans do not show up in the field
      let displayLabel = jsonKeyToLabel(key); // eslint-disable-line prefer-const

      // Change the heading even if the field is empty
      switch (key) {
        case 'component_display':
          displayLabel = 'Component';
          break;
        case 'location_zone_display':
          displayLabel = 'Location Zone';
          break;
        case 'location_code_display':
          displayLabel = 'Location Code';
          break;
        case 'access_display':
          displayLabel = 'Access';
          break;
      }
      let displayValue = '';
      if (data[key] != null) {
        switch (key) {
          case 'file':
            fieldSettings[key] = {
              type: 'component',
              cellProps: {
                xs: 6,
              },
              fieldProps: {
                label: '',
                value: () => (
                  <IconButton
                    aria-label="download file"
                    href={`${data[key]}&response-content-disposition=attachment`}
                    download
                    size="large">
                    <GetAppIcon />
                  </IconButton>
                ),
              },
            };
            return;
          case 'size':
            fieldSettings[key] = {
              type: 'size',
              cellProps: {
                xs: 6,
              },
              fieldProps: {
                label: 'Size',
                num: data[key],
              },
            };
            return;
          case 'geo_point':
          case 'local_coordinates':
            fieldSettings[key] = {
              type: 'geo',
              cellProps: {
                md: 6,
                xs: 12,
              },
              fieldProps: {
                label: displayLabel,
                value: data[key],
              },
            };
            return;
          case 'updated_on':
          case 'created_on':
            displayValue = apiDateToString(data[key], 'date');
            break;
          case 'captured_on':
            displayValue = apiDateToString(data[key], 'short-timestamp');
            break;
          case 'layer':
            displayValue = data[key].name;
            break;
          case 'project':
            fieldSettings[key] = {
              type: 'component',
              cellProps: {
                xs: 6,
              },
              fieldProps: {
                label: 'Project',
                value: () => <StyledLink to={`/projects/${data[key].id}/`} value={data[key].name} />,
              },
            };
            return;
          // data[key] can sometimes return different values depending on if the media is newly added or not so pulling the data from redux
          case 'checklist':
            fieldSettings[key] = {
              type: 'component',
              cellProps: {
                xs: 6,
              },
              fieldProps: {
                label: 'Checklist',
                value: () => <StyledLink to={checklistId ? `/forms/${checklistId}` : ''} value={templateName} />,
              },
            };
            return;
          case 'source_media':
            fieldSettings[key] = {
              type: 'component',
              cellProps: {
                xs: 6,
              },
              fieldProps: {
                label: 'Source',
                value: () => {
                  if (data[key] && data['source_media_timestamp']) {
                    const timestamp = convertToSeconds(data['source_media_timestamp']);
                    const routeToTimestamp = () => history.push(`/inspection-media/${data[key].id}?t=${timestamp}`);
                    return <StyledLink onClick={routeToTimestamp} value={data[key].name} underline />;
                  }
                  return <StyledLink to={`/inspection-media/${data[key].id}/`} value={data[key].name} />;
                },
              },
            };
            return;
          default:
            displayValue = data[key].toString();
        }
      }
      fieldSettings[key] = {
        type: 'text',
        cellProps: {
          xs: 12,
        },
        fieldProps: {
          label: displayLabel,
          value: displayValue,
        },
      };
    });
  }

  return (
    <CommonFormView
      bodyOnly
      title={'Inspection Media Details'}
      values={data}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      loading={loading}
      error={error}
      fullHeight={fullHeight}
    />
  );
};
InspectionMediaView.defaultProps = {
  data: {},
  error: '',
  loading: false,
  fullHeight: false,
};

InspectionMediaView.propTypes = {
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.string,
  fullHeight: PropTypes.bool,
};

export default InspectionMediaView;
