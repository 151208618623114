import { combineReducers } from 'redux';

import {
  SET_DARK_MODE,
  SET_CURRENT_LIBRARY,
  SAVE_REFRESH_INTERVAL,
  SET_ASSET_TREE_VIEW,
  SELECT_ASSET_TREE_ASSET,
  EXPAND_ASSET_TREE,
} from './settingsActions';
import featuresReducer from './featuresReducer';
import logoReducer from './logoReducer';
import tableSettingsReducer from './tableReducer.js';
import viewsReducer from './views/viewsReducer';

// Initial state is dark mode is -1 so that we know if it hasn't been set yet.
// If dark mode has not been set, use the system dark mode setting.
const paletteInitialState = { darkMode: -1 };

const localInitialState = { libraryId: '' };

const assetTreeViewInitialState = { assetTreeView: false, selected: { id: undefined, name: undefined }, expanded: [] };

// since the user reducer is white listed, it's possible to rehydrate the store and the initial state for dark mode
// is not set.
export const selectDarkMode = state => {
  if (state.settings.palette) return state.settings.palette.darkMode;
  return paletteInitialState.darkMode;
};

function localReducer(state = localInitialState, action) {
  switch (action.type) {
    case SET_CURRENT_LIBRARY:
      return {
        ...state,
        libraryId: action.libraryId,
        libraryName: action.libraryName,
      };
    case SAVE_REFRESH_INTERVAL:
      return {
        ...state,
        refreshInterval: action.refreshInterval,
      };
    default:
      return state;
  }
}

function palletteReducer(state = paletteInitialState, action) {
  switch (action.type) {
    case SET_DARK_MODE:
      return {
        ...state,
        darkMode: action.darkMode,
      };
    default:
      return state;
  }
}

function assetTreeViewReducer(state = assetTreeViewInitialState, action) {
  switch (action.type) {
    case SET_ASSET_TREE_VIEW:
      return {
        ...state,
        assetTreeView: action.assetTreeView,
      };
    case SELECT_ASSET_TREE_ASSET:
      return {
        ...state,
        selected: action.selected,
      };
    case EXPAND_ASSET_TREE:
      return {
        ...state,
        expanded: action.expanded,
      };
    default:
      return state;
  }
}

export default combineReducers({
  local: localReducer,
  palette: palletteReducer,
  features: featuresReducer,
  logo: logoReducer,
  tables: tableSettingsReducer, // new reducer
  assetTreeView: assetTreeViewReducer,
  ...viewsReducer,
});
