import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import BulkImportsTable from './BulkImportsTable';
import { getAllBulkImports } from '../../store/features/bulkImportsActions';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(7, '3vw'),
    padding: theme.spacing(2),
  },
}));

const BulkImportsTablePage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(getAllBulkImports());
  }, []); // eslint-disable-line

  return (
    <div className={classes.root}>
      <BulkImportsTable title="Imports" />
    </div>
  );
};

export default BulkImportsTablePage;
