import { all, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  COMPANIES_REQUEST,
  COMPANIES_SUCCESS,
  COMPANIES_FAILURE,
  COMPANIES_CREATE_REQUEST,
  COMPANIES_CREATE_SUCCESS,
  COMPANIES_CREATE_FAILURE,
  COMPANIES_UPDATE_REQUEST,
  COMPANIES_UPDATE_SUCCESS,
  COMPANIES_UPDATE_FAILURE,
  COMPANIES_DELETE_REQUEST,
  COMPANIES_DELETE_SUCCESS,
  COMPANIES_DELETE_FAILURE,
  COMPANIES_ALL_REQUEST,
  COMPANIES_ALL_SUCCESS,
  COMPANIES_ALL_FAILURE,
} from './companiesActions';

import {
  fetchCompanies,
  createCompanies,
  updateCompanies,
  deleteCompanies,
  fetchCompaniesList,
} from '../../api/features/companies';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';
import { fetchListAll } from '../../utilities/sagaHelpers';
import { DIALOG_CLOSE } from '../../store/dialogActions';

export function* companiesFetch(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield fetchCompanies(id);
    if (data) {
      yield put({ type: COMPANIES_SUCCESS, data });
    } else if (error) {
      yield put({ type: COMPANIES_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, COMPANIES_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, COMPANIES_FAILURE);
  }
}

export function* watchCompaniesFetch() {
  yield takeLatest(COMPANIES_REQUEST, companiesFetch);
}

export function* companiesCreate(action) {
  const { body } = action;
  try {
    const { data, error, formError, response } = yield createCompanies(body);
    if (data) {
      yield put({ type: COMPANIES_CREATE_SUCCESS, data });
      yield put(push(`/companies/${data.id}`));
    } else if (error) {
      yield put({ type: COMPANIES_CREATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: COMPANIES_CREATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, COMPANIES_CREATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, COMPANIES_CREATE_FAILURE);
  }
}

export function* watchCompaniesCreate() {
  yield takeLatest(COMPANIES_CREATE_REQUEST, companiesCreate);
}

export function* companiesUpdate(action) {
  const { id, body } = action;
  try {
    const { data, error, formError, response } = yield updateCompanies(id, body);
    if (data) {
      yield put({ type: COMPANIES_UPDATE_SUCCESS, data });
      yield put(push('/companies'));
    } else if (error) {
      yield put({ type: COMPANIES_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: COMPANIES_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, COMPANIES_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, COMPANIES_UPDATE_FAILURE);
  }
}

export function* watchCompaniesUpdate() {
  yield takeLatest(COMPANIES_UPDATE_REQUEST, companiesUpdate);
}

export function* companiesDelete(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield deleteCompanies(id);
    if (data) {
      yield put({ type: COMPANIES_DELETE_SUCCESS });
      yield put({ type: COMPANIES_ALL_REQUEST });
      yield put({ type: DIALOG_CLOSE });
      yield put(push('/companies'));
    } else if (error) {
      yield put({ type: COMPANIES_DELETE_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, COMPANIES_DELETE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, COMPANIES_DELETE_FAILURE);
  }
}

export function* watchCompaniesDelete() {
  yield takeLatest(COMPANIES_DELETE_REQUEST, companiesDelete);
}

export function* companiesFetchAll(action) {
  try {
    const { data, error, response } = yield fetchListAll(fetchCompaniesList, action.query);
    if (data) {
      yield put({ type: COMPANIES_ALL_SUCCESS, data });
    } else if (error) {
      yield put({ type: COMPANIES_ALL_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, COMPANIES_ALL_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, COMPANIES_ALL_FAILURE);
  }
}

export function* watchCompaniesFetchAll() {
  yield takeLatest(COMPANIES_ALL_REQUEST, companiesFetchAll);
}

export default function* companiesSaga() {
  yield all([
    watchCompaniesFetch(),
    watchCompaniesCreate(),
    watchCompaniesUpdate(),
    watchCompaniesDelete(),
    watchCompaniesFetchAll(),
  ]);
}
