import React, { useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Grid, Tabs, Tab, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { usePermissions, useTableViewSettings } from '../../hooks/settingsHooks';
import ScheduleTasksTable from '../scheduleTasks/ScheduleTasksTable';
import ScheduleEventsView from './ScheduleEventsView';
import ProjectsTable from '../projects/ProjectsTable';
import ButtonIcon from '../shared/buttons/ButtonIcon';
import PrimaryButton from '../shared/buttons/PrimaryButton';
import TabPanel from '../shared/TabPanel';
import { getAllProjects } from '../../store/features/projectsActions';
import { getAllScheduleTasks } from '../../store/features/scheduleTasksActions';
import { tableViews } from '../../utilities/tables';
import { PlanLink } from '../shared/links/InternalLinks';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2, 'auto'),
    padding: theme.spacing(2),
    textAlign: 'left',
  },
  embeddedTableStyle: {
    margin: theme.spacing(0, 4),
  },
  title: {
    flexGrow: 1,
    // justify: 'left',
  },
  assetNameTitle: {
    fontSize: theme.spacing(5),
  },
  table: {
    padding: theme.spacing(2, 0),
  },
  textWarning: {
    margin: theme.spacing(1, 2),
  },
}));

const ScheduleEventsDetail = () => {
  let { id, tab: selectedTab } = useParams();
  selectedTab = selectedTab || 'overview'; // default to overview tab
  const { hasScheduleEventManage } = usePermissions();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();

  const { projectsTableViewKey } = tableViews;
  const projectQuery = { event: id };
  const {
    tableQuery: projectsQuery,
    embeddedQuery,
    page,
    views,
  } = useTableViewSettings(projectsTableViewKey, projectQuery);

  const { data, loading, error } = useSelector(state => {
    const { data, loading, error } = state.scheduleEvents.each;
    return {
      data,
      loading,
      error,
    };
  });

  useEffect(() => {
    dispatch(getAllProjects(projectsQuery));
  }, []); // eslint-disable-line

  useEffect(() => {
    dispatch(getAllScheduleTasks({ event: id }));
  }, []); // eslint-disable-line

  const projectTableChangeHandler = query => {
    const updatedQuery = { ...embeddedQuery, ...query };
    dispatch(getAllProjects(updatedQuery));
  };

  const handleBack = () => {
    if (location.state && location.state.back) {
      history.push(location.state.back);
    } else {
      const next = location.pathname.match(/(.*?)\/\w+$/)[1];
      history.push(next);
    }
  };

  const handleEdit = () => {
    history.push(`/schedule-events/${id}/edit`);
  };

  const tabs = [
    {
      value: 'overview',
      label: 'Overview',
      Component: <ScheduleEventsView loading={loading} error={error} />,
    },
    {
      value: 'projects',
      label: 'Projects',
      Component: (
        <ProjectsTable
          embedded
          title="Projects"
          tableChangeHandler={projectTableChangeHandler}
          queryParamObj={projectsQuery}
          views={views}
          page={page}
        />
      ),
    },
    {
      value: 'schedule-tasks',
      label: 'Schedule Tasks',
      Component: <ScheduleTasksTable embedded title="Scheduled Tasks" linkName={false} />,
    },
  ];

  let readyToRender = false;

  const handleTabChange = (event, value) => {
    history.push(`/schedule-events/${id}/` + value);
  };

  const constructTitle = () => {
    if (data?.name) {
      if (data?.plan) {
        return (
          <span className={`h7 ${classes.assetNameTitle}`}>
            <PlanLink {...data?.plan} />
            {` / ${data?.name}`}
          </span>
        );
      } else {
        return <p className={`h7 ${classes.assetNameTitle}`}>{data?.name}</p>;
      }
    }
    return <></>;
  };

  switch (selectedTab) {
    case 'overview':
    case 'projects':
    case 'schedule-tasks':
      readyToRender = true;
      break;
    default:
      history.replace(`/schedule-events/${id}`);
  }
  // the Tabs component doesn't handle value=undefined
  if (!readyToRender) return <></>;

  return (
    <div className={classes.root}>
      <Grid container justifyContent="flex-start" alignItems="stretch">
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.header}>
            <Grid item>
              <ButtonIcon buttonSize="medium" iconSize="medium" icon={ArrowBackIosIcon} onClick={handleBack} />
            </Grid>
            <Grid item className={classes.title}>
              {constructTitle()}
            </Grid>
            <Grid item>
              {hasScheduleEventManage && <PrimaryButton label="Edit" type="button" onClick={handleEdit} />}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary">
            {tabs
              .filter(tab => !tab.hide)
              .map(tab => (
                <Tab
                  id={`schedule-event-tab-${tab.value}`}
                  aria-controls={`schedule-event-tabpanel-${tab.value}`}
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                />
              ))}
          </Tabs>
          <Divider className={classes.divider} />
          {tabs.map(tab => (
            <TabPanel key={tab.value} value={tab.value} selectedTab={selectedTab} name="schedule-event">
              <Grid item className={classes.table}>
                {tab.Component}
              </Grid>
            </TabPanel>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default ScheduleEventsDetail;
