import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import makeStyles from '@mui/styles/makeStyles';
import { GroupAdd, GroupRemove } from '@mui/icons-material';
import ButtonIcon from '../shared/buttons/ButtonIcon';
import { getIdFromRow } from '../shared/table/columns';
import { openDialog } from '../../store/dialogActions';
import { usePermissions } from '../../hooks/settingsHooks';
import AssignUsersForm from './AssignUsersForm';

const useStyles = makeStyles(theme => ({
  buttonGroup: {
    display: 'inherit',
    margin: theme.spacing(2),
  },
}));

const UsersToolbarSelect = ({ columns, displayData, selectedRows, clearSelections }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { pathname } = useLocation();
  const { hasCompanyEdit } = usePermissions();
  const inCompanyDetail = pathname.startsWith('/companies');

  const selectedUserIds = selectedRows.data.map(item => getIdFromRow(item, displayData, columns));

  const handleAssignment = ({ remove }) => {
    const title = remove ? 'Remove Users' : 'Assign Users';
    const defaultCompany = inCompanyDetail && id ? id : null; // check to populate company field
    dispatch(
      openDialog(
        title,
        <AssignUsersForm
          selectedUsers={selectedUserIds}
          remove={remove}
          defaultCompany={defaultCompany}
          onFormSuccess={clearSelections}
        />
      )
    );
  };

  const showAssignUsers = !inCompanyDetail;

  return (
    <div data-testid="users_toolbar_select">
      {hasCompanyEdit && (
        <div className={classes.buttonGroup}>
          {showAssignUsers && (
            <ButtonIcon icon={GroupAdd} onClick={handleAssignment} iconSize="medium" tooltip="Assign Users" />
          )}
          <ButtonIcon
            icon={GroupRemove}
            onClick={() => handleAssignment({ remove: true })}
            iconSize="medium"
            tooltip="Remove Users from Company"
          />
        </div>
      )}
    </div>
  );
};

UsersToolbarSelect.propTypes = {
  columns: PropTypes.array.isRequired,
  displayData: PropTypes.array.isRequired,
  selectedRows: PropTypes.object.isRequired,
  clearSelections: PropTypes.func.isRequired,
};

export default UsersToolbarSelect;
