import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import ImageAnnotator from '../shared/imageTools/ImageAnnotator';
import { getInspectionMedia } from '../../store/features/inspectionMediaActions';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(7, 'auto'),
    padding: theme.spacing(2),
  },
}));

const InspectionMediaAnnotate = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const id = useParams().id;
  const { data } = useSelector(state => state.inspectionMedia.each);
  useEffect(() => {
    if (!data.file) {
      dispatch(getInspectionMedia(id));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!data.file) {
    // handle loading this page directly.  Need to make sure data.field is valid before rendering ImageAnnotator
    return <div className={classes.root} />;
  }
  return (
    <div className={classes.root}>
      <ImageAnnotator image={data.file} overlays={data.overlays} />
    </div>
  );
};

export default InspectionMediaAnnotate;
