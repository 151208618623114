import { all } from 'redux-saga/effects';

import authSaga from './authSaga';
import commonFormSagas from './features/featureSagas';
import getterSagas from './getters/getterSagas';
import settingsSaga from './settings/settingsSaga';
import { sagas as apiV2Sagas } from './apiV2';

export default function* rootSaga() {
  yield all([authSaga(), ...commonFormSagas, ...getterSagas, ...settingsSaga, ...apiV2Sagas]);
}
