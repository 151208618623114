//  * GCloud ID Token
//  *  https://firebase.google.com/docs/reference/js/v8/firebase.User#getidtoken
// key for localstorage to set the short-lived access token
//  obtained from firebase login
export const AUTH_TOKEN_ID = 'idToken';

// see: https://github.com/huvrdata/huvr/blob/baf7c3c447a5a8ec5a5898095b0a6490b9117709/huvr_api/huvr_api/auth/settings.py#L24-L24
export const AUTH_COOKIE_NAME = 'auth';

/**
 * @param {String} idToken pass idToken retrieved from firebase
 */
export function setAuthTokenId(idToken) {
  if (!window.localStorage) {
    console.warn('local storage not supported - unable to set localStorage');
    return;
  }
  window.localStorage.setItem(AUTH_TOKEN_ID, idToken);
}

/**
 * @returns string of auth token id - assumes already set
 */
export function getAuthTokenId() {
  return window.localStorage?.getItem(AUTH_TOKEN_ID);
}

/**
 * @param {String} idToken retrieved from firebase
 */
export function openMobileDeeplink(idToken) {
  window.open(`huvrdata://?intent=login&idToken=${idToken}`);
}
