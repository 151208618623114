// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
export const CHECKLIST_REVISIONS_GETTER_REQUEST = 'CHECKLIST_REVISIONS_GETTER_REQUEST';
export const CHECKLIST_REVISIONS_GETTER_SUCCESS = 'CHECKLIST_REVISIONS_GETTER_SUCCESS';
export const CHECKLIST_REVISIONS_GETTER_FAILURE = 'CHECKLIST_REVISIONS_GETTER_FAILURE';
export const CHECKLIST_REVISIONS_SET = 'CHECKLIST_REVISIONS_SET';

export const getRevisions = checklistId => {
  return {
    type: CHECKLIST_REVISIONS_GETTER_REQUEST,
    checklistId,
  };
};

export const setRevision = id => {
  return {
    type: CHECKLIST_REVISIONS_SET,
    id,
  };
};
