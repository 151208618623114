import { SET_TABLE_QUERY, SET_TABLE_VIEW, SET_TABLE_SIZE } from './tableActions';

export const initialStateTableSettings = {};

export const tableSettingsReducer = (state = initialStateTableSettings, action) => {
  switch (action.type) {
    case SET_TABLE_QUERY:
      return {
        ...state,
        [action.tableType]: state[action.tableType]
          ? {
              ...state[action.tableType],
              [action.page]: {
                ...state[action.tableType][action.page],
                query: { ...action.query },
              },
            }
          : {
              [action.page]: { query: { ...action.query } },
            },
      };
    case SET_TABLE_VIEW:
      return {
        ...state,
        [action.tableType]: state[action.tableType]
          ? {
              ...state[action.tableType],
              [action.page]: {
                ...state[action.tableType][action.page],
                [action.column]: action.action,
              },
            }
          : {
              [action.page]: { [action.column]: action.action },
            },
      };
    case SET_TABLE_SIZE:
      return {
        ...state,
        [action.tableType]: state[action.tableType]
          ? {
              ...state[action.tableType],
              [action.page]: {
                ...state[action.tableType][action.page],
                pageSize: action.size,
              },
            }
          : {
              [action.page]: { pageSize: action.size },
            },
      };
    default:
      return state;
  }
};

export default tableSettingsReducer;
