import React from 'react';
import PropTypes from 'prop-types';
import { Paper, IconButton } from '@mui/material';
import JSONTree from 'react-json-tree';
import { DeveloperMode } from '@mui/icons-material';

function ResultsDisplay(props) {
  const { values } = props;
  const [showResults, setShowResults] = React.useState(false);

  return (
    <>
      <IconButton aria-label="toggle dev mode" onClick={() => setShowResults(!showResults)} size="large">
        <DeveloperMode />
      </IconButton>
      {showResults && (
        <Paper>
          <h1>Results</h1>
          <JSONTree data={values} />
        </Paper>
      )}
    </>
  );
}

ResultsDisplay.propTypes = {
  values: PropTypes.object,
};
ResultsDisplay.defaultProps = { values: {} };

export default ResultsDisplay;
