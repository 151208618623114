import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'mui-rff';
import { validateLatLongFn, composeValidators, requiredFn } from '../../../utilities/validators';

/**
 * Styling a geo point field for a form
 */
const FieldEditGeoPoint = props => {
  const { name, label, value, placeholder, loading, disabled, required, helperText } = props;

  // to use the form values, the value prop needs to be missing (can't be undefined or empty string)
  // only show the value if it is passed in as a prop.
  const moreProps = {};
  if (value) moreProps.value = value;
  moreProps.placeholder = '(-)NN.NNNNNN, (-)NNN.NNNNNN';

  let modifiedLabel = label;
  if (required) {
    modifiedLabel = (
      <span>
        {label}
        <sup>*</sup>
      </span>
    );
  }

  return (
    <TextField
      label={modifiedLabel}
      name={name}
      placeholder={placeholder}
      disabled={disabled || loading}
      variant="outlined"
      helperText={helperText}
      InputLabelProps={{ shrink: true }}
      fieldProps={{ validate: composeValidators(requiredFn(required), validateLatLongFn) }}
      {...moreProps}
    />
  );
};

FieldEditGeoPoint.defaultProps = {
  disabled: false,
  required: false,
  validator: null,
  value: undefined,
  placeholder: '',
  helperText: '',
  loading: false,
};

FieldEditGeoPoint.propTypes = {
  /** label for the string field */
  label: PropTypes.string.isRequired,
  /** the api key for the feature */
  name: PropTypes.string.isRequired,
  /** initial value for the field */
  value: PropTypes.string,
  /** sets the placeholder for the string field */
  placeholder: PropTypes.string,
  /** sets the field as required for the form */
  required: PropTypes.bool,
  /** sets optional field validation function */
  validator: PropTypes.func,
  /** disables the field while true */
  disabled: PropTypes.bool,
  /** adds helper text below the form field */
  helperText: PropTypes.string,
  /** disables the filed while true */
  loading: PropTypes.bool,
};

export default FieldEditGeoPoint;
