export const SET_NOTIFICATION_RULE_SUBSCRIBERS_TABLE_VIEW = 'SET_NOTIFICATION_RULE_SUBSCRIBERS_TABLE_VIEW';

export const setNotificationRuleSubscribersTableView = (column, action) => {
  return {
    type: SET_NOTIFICATION_RULE_SUBSCRIBERS_TABLE_VIEW,
    column,
    action,
  };
};

export const notificationRuleSubscribersTableViewReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_NOTIFICATION_RULE_SUBSCRIBERS_TABLE_VIEW:
      return {
        ...state,
        [action.column]: action.action,
      };
    default:
      return state;
  }
};
