import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import FieldEditSelect from '../shared/form/FieldEditSelect';
import PrimaryButton from '../shared/buttons/PrimaryButton';
import SecondaryButton from '../shared/buttons/SecondaryButton';
import { closeDialog } from '../../store/dialogActions';
import { useCompanies } from '../../hooks/optionsHooks';
import { companiesAssignEndpoint, companiesListAllEndpoint } from '../../store/apiV2/companies';
import { getAllUsers } from '../../store/features/usersActions';
import { openSnackbar } from '../../store/snackbarActions';
import { formatApiError } from '../../utilities/strings';

const useStyles = makeStyles(theme => ({
  form: {
    margin: theme.spacing(1),
    height: '90%',
  },
  buttonGroup: {
    marginTop: theme.spacing(3),
  },
}));

const AssignUsersForm = ({ selectedUsers, remove, defaultCompany, onFormSuccess }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [submittedValues, setSubmittedValues] = useState({});
  const handleCloseDialog = () => dispatch(closeDialog());
  const companies = useCompanies();
  const { query: usersQuery } = useSelector(state => state.users.all); // need for the fetch after submission
  const { dispatchRequest: assignUsers, formError } = companiesAssignEndpoint.useEndpoint();
  const { dispatchRequest: listCompanies } = companiesListAllEndpoint.useEndpoint();

  useEffect(() => {
    listCompanies();
  }, []); // eslint-disable-line

  const initialValues = {};

  if (defaultCompany) {
    initialValues.company = defaultCompany;
  }

  const handleOnSubmit = values => {
    setSubmittedValues({ ...values });
    const { company: id } = values;

    const onSuccess = () => {
      onFormSuccess();
      handleCloseDialog();
      dispatch(getAllUsers(usersQuery));
    };
    const onError = ({ error }) => {
      dispatch(openSnackbar(`Error: ${formatApiError(error)}`, 'error'));
      console.error('Error submitting the form: ', error);
    };

    const action = remove ? 'remove' : 'add';
    assignUsers(id, { [action]: selectedUsers })
      .then(onSuccess)
      .catch(onError);
  };

  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        if (values[key] === submittedValues[key]) {
          errors[key] = value;
        } else {
          errors[key] = undefined;
        }
      }
    }
    return errors;
  };

  return (
    <Form
      onSubmit={handleOnSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, pristine }) => {
        return (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Grid container direction="column" justifyContent="flex-start" spacing={3}>
              <Grid item>
                <FieldEditSelect
                  label="Company"
                  name="company"
                  options={companies}
                  labelwidth={40}
                  helperText={'Select company.'}
                />
              </Grid>
              <Grid className={classes.buttonGroup} container justifyContent="flex-end" alignItems="flex-end">
                <Grid item xs={2}>
                  <SecondaryButton label="Cancel" onClick={handleCloseDialog} />
                </Grid>
                <Grid item xs={2}>
                  <PrimaryButton type="submit" label="Submit" disabled={submitting || pristine} />
                </Grid>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};

AssignUsersForm.defaultProps = {
  remove: undefined,
  defaultCompany: null,
  onFormSuccess: () => {},
};

AssignUsersForm.propTypes = {
  selectedUsers: PropTypes.array.isRequired,
  remove: PropTypes.bool,
  defaultCompany: PropTypes.string,
  onFormSuccess: PropTypes.func,
};

export default AssignUsersForm;
