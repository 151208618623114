import React from 'react';
import PropTypes from 'prop-types';
import { Switch, useRouteMatch } from 'react-router-dom';
import FeatureRoute from '../../FeatureRoute';
import NotFound from '../../NotFound';
import MainLayout from '../../layouts/MainLayout';

/**
 * Shared component mainly used for creating routes for components created from the codegen:feature script<br>
 * If there's a route not handled by this component, it will return the NotFound component
 *
 * children are expected to have a path that is unique or more specific than paths in CommonRoutes
 * they are a way of adding routes that are not automatically generated by codegen.
 */

const CommonRoutes = props => {
  const { pageTitle, layout, permissions, Edit, View, Create, All, Success, children, Rename } = props;
  const { path } = useRouteMatch();
  return (
    <Switch>
      {children}
      <FeatureRoute
        exact
        path={`${path}/:id/edit`}
        pageTitle={`Edit ${pageTitle}`}
        layout={layout}
        permissions={permissions}>
        {Edit}
      </FeatureRoute>
      <FeatureRoute
        exact
        path={`${path}/:id/rename`}
        pageTitle={`Rename ${pageTitle}`}
        layout={layout}
        permissions={permissions}>
        {Rename}
      </FeatureRoute>
      <FeatureRoute
        exact
        path={`${path}/new`}
        pageTitle={`Create ${pageTitle}`}
        layout={layout}
        permissions={permissions}>
        {Create}
      </FeatureRoute>
      <FeatureRoute
        exact
        path={`${path}/success`}
        pageTitle={`${pageTitle} Success`}
        layout={layout}
        permissions={permissions}>
        {Success}
      </FeatureRoute>
      <FeatureRoute
        exact
        path={[`${path}/:id`, `${path}/:id/:tab`]}
        pageTitle={`${pageTitle} Details`}
        layout={layout}
        permissions={permissions}>
        {View}
      </FeatureRoute>
      <FeatureRoute path={`${path}`} pageTitle={`${pageTitle} Table`} layout={layout} permissions={permissions}>
        {All}
      </FeatureRoute>
    </Switch>
  );
};

CommonRoutes.defaultProps = {
  pageTitle: '',
  layout: MainLayout,
  permissions: '',
  Success: <NotFound />,
  Edit: <NotFound />,
  Rename: <NotFound />,
  View: <NotFound />,
  Create: <NotFound />,
  All: <NotFound />,
  children: null,
};

CommonRoutes.propTypes = {
  /** custom title for <html><title> */
  pageTitle: PropTypes.string,
  /** wrapper for the component displayed by the route */
  layout: PropTypes.func,
  /** pass through permissions set by the parent.
   * if we need to set permission individually here, we would need to refactor.
   */
  permissions: PropTypes.string,
  /** success component for successfully creating/editing a feature */
  Success: PropTypes.node,
  /** edit component for updating an instance of a feature */
  Edit: PropTypes.node,
  /** rename component for updating an instance of a feature */
  Rename: PropTypes.node,
  /** view component for displaying the contents an instance of a feature */
  View: PropTypes.node,
  /** create component for creating a new instance of a feature */
  Create: PropTypes.node,
  /** table component for displaying all instances of a feature */
  All: PropTypes.node,
  children: PropTypes.node,
};

export default CommonRoutes;
