// eslint-disable-next-line no-unused-vars
import { buildEndpoint, get, patch, post, del } from './util';

export const EACH_REDUCER = 'checklistsEach';

/**
 * https://docs.huvrdata.app/reference/api_checklists_bulk_lock
 */
export const checklistsBulkLockEndpoint = buildEndpoint(
  'checklistsBulkLock',
  (ids, lockAction) => post(`/api/checklists/bulk-lock/`, { ids, action: lockAction }),
  { reducerKey: EACH_REDUCER }
);

/**
 * https://docs.huvrdata.app/reference/api_checklists_delete
 */
export const checklistsDeleteEndpoint = buildEndpoint('checklistsDelete', id => del(`/api/checklists/${id}/`), {
  reducerKey: EACH_REDUCER,
});

// export const checklistsListAllEndpoint = buildEndpoint(
//   'checklistsListAll',
//   query => get('/api/checklists/', query),
// );
