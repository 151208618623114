import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, ListItemText, Checkbox, Select, MenuItem, InputLabel } from '@mui/material';

const MultiSelectFilter = props => {
  const { title, index, filterList, localFilterList, onChange, column, filterData, updateFilters, formatValue } = props;

  if (filterList[index] !== localFilterList) {
    // when the resetbutton is clicked, it doesn't call updateFilters
    // the next time the filter menu is opened, it will show the old values.
    // sync the table local state with the mui datatables filterList.
    updateFilters(filterList[index]);
  }
  const isChecked = item => {
    if (item === 'All') {
      return filterList[index].length === 0;
    } else {
      return filterList[index].indexOf(item) > -1;
    }
  };
  return (
    <FormControl>
      <InputLabel variant="standard" shrink>
        {title}
      </InputLabel>
      <Select
        // variant="outlined"
        variant="standard"
        MenuProps={{ variant: 'menu', getContentAnchorEl: null }} // stop field from jumping around
        value={filterList[index]}
        multiple
        displayEmpty
        label={title}
        renderValue={() => {
          if (filterList[index].length > 0) {
            const filterListLabels = filterList[index].map(item => {
              return formatValue(item);
            });
            return filterListLabels.length > 1 ? filterListLabels.join(', ') : filterListLabels;
          } else return 'All';
        }}
        onChange={event => {
          const maxSelectionLength = filterData[index].length;
          if (event.target.value.includes('All')) {
            // check if all was pressed with all selected
            if (filterList[index].length === maxSelectionLength) {
              // this prevents infinitely adding the selections to filterList when clicking on "All"
              event.target.value = event.target.value.filter(item => item === 'All');
              filterList[index] = [];
              updateFilters && updateFilters(filterList[index]);
            } else {
              // nothing is selected, select all
              filterList[index] = [];
              filterData[index].map(item => filterList[index].push(item));
              updateFilters && updateFilters(filterList[index]);
            }
          } else {
            filterList[index] = event.target.value.filter(item => item !== 'All');
            updateFilters && updateFilters(filterList[index]);
          }
          onChange(filterList[index], index, column);
        }}>
        <MenuItem key="All" value="All">
          <Checkbox color="primary" checked={isChecked('All')} />
          <ListItemText primary="All" />
        </MenuItem>
        {filterData[index].map((item, index) => {
          return (
            <MenuItem key={index} value={item}>
              <Checkbox color="primary" checked={isChecked(item)} />
              {formatValue(item)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
MultiSelectFilter.defaultProps = {
  title: 'Filter',
  formatValue: item => item,
  updateFilters: undefined,
};

MultiSelectFilter.propTypes = {
  title: PropTypes.string,
  index: PropTypes.number.isRequired,
  column: PropTypes.object.isRequired,
  filterList: PropTypes.arrayOf(PropTypes.array).isRequired,
  localFilterList: PropTypes.array.isRequired,
  filterData: PropTypes.arrayOf(PropTypes.array).isRequired,
  onChange: PropTypes.func.isRequired,
  updateFilters: PropTypes.func,
  /** returns a display value as a string */
  formatValue: PropTypes.func,
};

export default MultiSelectFilter;
