export const appBarHeightFinder = status => {
  switch (status) {
    case 'OFF':
      return 63;
    default:
      return 105;
  }
};

export const commonTable = theme => ({
  margin: theme.spacing(7, '3vw'),
  padding: theme.spacing(2),
});
