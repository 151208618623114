import React from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  FormHelperText,
  FormControlLabel,
  FormGroup,
  FormControl,
  FormLabel,
  Checkbox as MuiCheckbox,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Field } from 'react-final-form';

import Markdown from '../../shared/Markdown';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
    width: '100%',
  },
  divider: {
    margin: '0',
  },
  checkboxes: {
    margin: theme.spacing(1, 2),
  },
}));

const CheckboxLineItem = props => {
  const { required, name, label, value, options, helperText, validator } = props;
  const classes = useStyles();
  const moreProps = {};

  // material-ui will show a warning if the value is loaded before the options
  if (options.length === 0) moreProps.value = '';
  // if we need to display a value or format the a value
  if (value) moreProps.value = value;

  return (
    <FormControl required={required} className={classes.root}>
      {label ? (
        <FormLabel>
          <Markdown>{label}</Markdown>
        </FormLabel>
      ) : (
        <></>
      )}
      <FormGroup>
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            name={name}
            label={option.label}
            value={option.value}
            className={classes.checkboxes}
            control={
              <Field
                type="checkbox"
                name={name}
                render={({ input: { name, value, onChange, checked, ...restInput } }) => {
                  return (
                    <MuiCheckbox
                      name={name}
                      value={value}
                      color="primary"
                      onChange={onChange}
                      checked={checked}
                      inputProps={{ required, ...restInput }}
                      {...moreProps}
                    />
                  );
                }}
                fieldProps={{ validate: validator }}
              />
            }
          />
        ))}
      </FormGroup>
      <Divider className={classes.divider} />
      <FormHelperText>
        <Markdown>{helperText}</Markdown>
      </FormHelperText>
    </FormControl>
  );
};

CheckboxLineItem.defaultProps = {
  disabled: false,
  required: false,
  name: '',
  label: '',
  value: undefined,
  options: [],
  inputPropClasses: '',
  helperText: '',
  validator: () => {},
};

CheckboxLineItem.propTypes = {
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array,
  inputPropClasses: PropTypes.string,
  helperText: PropTypes.string,
  validator: PropTypes.func,
};

export default CheckboxLineItem;
