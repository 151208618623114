import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { Grid } from '@mui/material';
import PrimaryButton from '../shared/buttons/PrimaryButton';
import Loading from '../shared/displays/Loading';

import Error from '../shared/displays/Error';
import SchedulePlanView from './SchedulePlanView';
import EventForm from './EventForm';

import { isEmpty } from '../../utilities/objects';
import { useTableViewSettings } from '../../hooks/settingsHooks';
import { getAllScheduleWork } from '../../store/features/scheduleWorkActions';
import { getAllScheduleEvents } from '../../store/features/scheduleEventsActions';
import { getSchedulePlan } from '../../store/features/schedulePlanActions';
import { openDialog } from '../../store/dialogActions';
import ScheduleWorkTable from '../scheduleWork/ScheduleWorkTable';
import ScheduleEventsTable from '../scheduleEvents/ScheduleEventsTable';
import ButtonIcon from '../shared/buttons/ButtonIcon';
import { handleBack, historyPushWithBack } from '../../utilities/route';
import { tableViews } from '../../utilities/tables';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 'auto'),
    padding: theme.spacing(2),
    textAlign: 'left',
    overflow: 'auto',
  },
  header: {
    padding: theme.spacing(3),
  },
  overview: {
    margin: theme.spacing(4),
  },
  table: {
    margin: theme.spacing(2, 4),
  },
  title: {
    flexGrow: 1,
  },
  embeddedTableStyle: {
    margin: theme.spacing(0, 4),
  },
  textWarning: {
    margin: theme.spacing(1, 2),
  },
  button: {
    padding: theme.spacing(0.5),
  },
  planNameTitle: {
    fontSize: theme.spacing(5),
  },
}));

const SchedulePlanDetail = () => {
  const id = useParams().id;
  const editPath = `/schedule-plan/${id}/edit`;
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { data, loading, error, generateEventIsLoading } = useSelector(state => {
    const { data, loading, error, generateEventIsLoading } = state.schedulePlan.each;
    return {
      data,
      loading,
      error,
      generateEventIsLoading,
    };
  });

  const relatedScheduleEventsQuery = { plan: id };
  const { scheduleEventsTableViewKey } = tableViews;

  const {
    tableQuery: storedScheduleEventsQuery,
    embeddedQuery: scheduleEventsEmbeddedQuery,
    page: scheduleEventsPage,
    views: scheduleEventsView,
  } = useTableViewSettings(scheduleEventsTableViewKey, relatedScheduleEventsQuery);

  useEffect(() => {
    dispatch(getSchedulePlan(id));
    dispatch(getAllScheduleWork({ plan: id }));
    dispatch(getAllScheduleEvents(storedScheduleEventsQuery));
  }, [dispatch, id]); // eslint-disable-line

  const scheduleEventsTableChangeHandler = query => {
    const updatedQuery = { ...scheduleEventsEmbeddedQuery, ...query };
    dispatch(getAllScheduleEvents(updatedQuery));
  };
  if (loading) {
    return <Loading />;
  }
  if (isEmpty(data) && !error) {
    return <Loading />;
  }

  if (error) return <Error error={error} style={{ marginTop: '56px', fontSize: '40px' }} />;
  const handleEdit = () => {
    historyPushWithBack(editPath, history, location);
  };
  const handleNewEvent = () => {
    dispatch(openDialog('Generate Events', <EventForm id={id} />));
  };

  return (
    <div className={classes.root}>
      <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.header}>
            <Grid item>
              <ButtonIcon
                buttonSize="medium"
                iconSize="medium"
                icon={ArrowBackIosIcon}
                onClick={() => handleBack(history, location)}
              />
            </Grid>
            <Grid item className={classes.title}>
              <p className={`h7 ${classes.planNameTitle}`}>{data.name ? `${data.name}` : ''}</p>
            </Grid>
            <Grid item>
              <span className={classes.button}>
                <PrimaryButton
                  label="Generate Events"
                  icon
                  Icon={AddIcon}
                  onClick={handleNewEvent}
                  loading={generateEventIsLoading}
                />
              </span>
              <span className={classes.button}>
                <PrimaryButton label="Edit" icon Icon={EditIcon} onClick={handleEdit} />
              </span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.overview}>
          <SchedulePlanView id={id} data={data} loading={loading} error={error} />
        </Grid>
        <Grid item xs={12} className={classes.table}>
          <ScheduleWorkTable
            embedded
            title={'Plan Work Items'}
            planId={id}
            styleSchedulePlanDetail={classes.embeddedTableStyle}
          />
        </Grid>
        <Grid item xs={12} className={classes.table}>
          <ScheduleEventsTable
            embedded
            title={'Schedule Events'}
            styleSchedulePlanDetail={classes.embeddedTableStyle}
            tableChangeHandler={scheduleEventsTableChangeHandler}
            queryParamObj={storedScheduleEventsQuery}
            page={scheduleEventsPage}
            views={scheduleEventsView}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default SchedulePlanDetail;
