import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { ButtonGroup } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import LoupeIcon from '@mui/icons-material/Loupe';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import AddIcon from '@mui/icons-material/Add';
import Divider from '@mui/material/Divider';

import { usePermissions } from '../../hooks/settingsHooks';

import { deleteAssets } from '../../store/features/assetsActions';
import ButtonIcon from '../shared/buttons/ButtonIcon';
import { openDialog } from '../../store/dialogActions';
import DeleteDialog from '../shared/Dialog/DeleteDialog';
import ConfirmDialog from '../shared/Dialog/ConfirmDialog';

export default function AssetActionBar({ id, name, size, extraActions }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const confirmAction = () => {
    history.push(`/assets/${id}/rename`);
  };

  const handleRename = () => {
    const notes =
      'Changing this asset’s name, owner, or parent will also affect all of its current and past projects. Are you sure you want to continue?';
    dispatch(openDialog('Confirm Changes', <ConfirmDialog id={id} notes={notes} confirmAction={confirmAction} />));
  };

  const { hasAssetCreate, hasAssetEdit, hasAssetDelete, hasProjectCreate } = usePermissions();

  const onDetailPage = !location.pathname.includes(`/assets/${id}`);

  const actions = [
    { key: 'view-asset', icon: LoupeIcon, to: `/assets/${id}`, tooltip: 'View Asset Details', show: onDetailPage },
    {
      key: 'edit-asset',
      icon: EditIcon,
      to: `/assets/${id}/edit`,
      tooltip: 'Edit Asset Information',
      show: hasAssetEdit,
    },
    {
      key: 'rename-asset',
      icon: DriveFileMoveIcon,
      onClick: handleRename,
      tooltip: 'Move or Rename Asset',
      show: hasAssetEdit,
    },
    {
      key: 'asset-create-project',
      icon: LibraryAddIcon,
      to: '/projects/new',
      search: `?asset_id=${id}`,
      tooltip: 'Create a Project with this Asset',
      show: hasProjectCreate,
    },
    {
      key: 'new-asset',
      icon: AddIcon,
      to: '/assets/new',
      search: `?asset_id=${id}`,
      tooltip: 'Add a new Asset',
      show: hasAssetCreate,
    },
    {
      key: 'divider',
      show: true,
    },
    {
      key: 'delete-asset',
      icon: DeleteIcon,
      onClick: () => {
        dispatch(openDialog('Delete Asset?', <DeleteDialog id={id} deleteAction={deleteAssets} name={name} />));
      },
      tooltip: 'Delete this Asset. This action cannot be undone.',
      show: hasAssetDelete,
    },
  ];

  if (extraActions) {
    actions.push(...extraActions);
  }

  const buttons = actions
    .filter(action => action.show)
    .map(action => {
      if (action.key === 'divider') return <Divider orientation="vertical" flexItem={true} />;
      return (
        <ButtonIcon
          key={action.key}
          history={history}
          icon={action.icon}
          location={location}
          to={action.to}
          search={action.search}
          onClick={action.onClick}
          buttonSize={size}
          iconSize={size}
          tooltip={action.tooltip}
        />
      );
    });

  return <ButtonGroup>{buttons}</ButtonGroup>;
}
AssetActionBar.defaultProps = {
  size: 'medium',
  extraActions: [],
};

AssetActionBar.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  extraActions: PropTypes.array,
};
