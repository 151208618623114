// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import { combineReducers } from 'redux';
import {
  MEASUREMENTS_REQUEST,
  MEASUREMENTS_SUCCESS,
  MEASUREMENTS_FAILURE,
  MEASUREMENTS_CREATE_REQUEST,
  MEASUREMENTS_CREATE_SUCCESS,
  MEASUREMENTS_CREATE_FAILURE,
  MEASUREMENTS_UPDATE_REQUEST,
  MEASUREMENTS_UPDATE_SUCCESS,
  MEASUREMENTS_UPDATE_FAILURE,
  MEASUREMENTS_DELETE_REQUEST,
  MEASUREMENTS_DELETE_SUCCESS,
  MEASUREMENTS_DELETE_FAILURE,
  MEASUREMENTS_ALL_REQUEST,
  MEASUREMENTS_ALL_SUCCESS,
  MEASUREMENTS_ALL_FAILURE,
  IMPORT_MEASUREMENTS_CREATE_REQUEST,
  IMPORT_MEASUREMENTS_CREATE_SUCCESS,
  IMPORT_MEASUREMENTS_CREATE_FAILURE,
  CLEAR_IMPORT_ERROR,
} from './measurementsActions';

export const initialStateMeasurements = {
  loading: false,
  error: '',
  formError: {},
  id: 0,
  body: {},
  data: {},
};

export const initialStateAllMeasurements = {
  loading: false,
  error: '',
  query: {},
  dataAll: { count: 0, next: null, previous: null, results: [] },
};

export const initialStateImportMeasurements = {
  loading: false,
  error: '',
  query: {},
  dataAll: { count: 0, next: null, previous: null, results: [] },
};

export const resetStateMeasurements = {
  loading: false,
  error: '',
  formError: {},
  data: {},
};
export const resetStateAllMeasurements = {
  loading: false,
  error: '',
  query: {},
  dataAll: [],
};
export const resetStateImportMeasurements = {
  loading: false,
  error: '',
  query: {},
  dataAll: [],
};

export const measurementsEachReducer = (state = initialStateMeasurements, action) => {
  switch (action.type) {
    case MEASUREMENTS_REQUEST:
      return {
        ...initialStateMeasurements,
        loading: true,
        id: action.id,
      };
    case MEASUREMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case MEASUREMENTS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case MEASUREMENTS_CREATE_REQUEST:
      return {
        ...initialStateMeasurements,
        loading: true,
        body: action.body,
      };
    case MEASUREMENTS_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case MEASUREMENTS_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case MEASUREMENTS_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        id: action.id,
        body: action.body,
        error: '',
        formError: {},
      };
    case MEASUREMENTS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case MEASUREMENTS_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case MEASUREMENTS_DELETE_REQUEST:
      return {
        ...state,
        error: '',
        formError: {},
        id: action.id,
      };
    case MEASUREMENTS_DELETE_SUCCESS:
      return {
        ...state,
      };
    case MEASUREMENTS_DELETE_FAILURE:
      return {
        ...state,
        error: action.error,
        formError: action.formError,
      };
    default:
      return state;
  }
};

export const measurementsAllReducer = (state = initialStateAllMeasurements, action) => {
  switch (action.type) {
    case MEASUREMENTS_ALL_REQUEST:
      return {
        ...initialStateAllMeasurements,
        loading: true,
        query: action.query,
      };
    case MEASUREMENTS_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        dataAll: action.data,
      };
    case MEASUREMENTS_ALL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export const measurementsImportReducer = (state = initialStateImportMeasurements, action) => {
  switch (action.type) {
    case IMPORT_MEASUREMENTS_CREATE_REQUEST:
      return {
        ...initialStateImportMeasurements,
        loading: true,
        id: action.id,
        body: action.body,
      };
    case IMPORT_MEASUREMENTS_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case IMPORT_MEASUREMENTS_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case CLEAR_IMPORT_ERROR:
      return {
        ...state.import,
        error: '',
      };
    default:
      return state;
  }
};

export default combineReducers({
  each: measurementsEachReducer,
  all: measurementsAllReducer,
  import: measurementsImportReducer,
});
