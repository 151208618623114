import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDarkMode } from '../../../store/settings/settingsActions';
import { selectDarkMode } from '../../../store/settings/settingsReducer';
import Switch from './Switch';

/**
 * Switch component for setting users local darkmode setting
 *
 * Darkmode will pull from the system settings if it has not been set yet.
 */

const DarkModeToggle = () => {
  const darkModeValue = useSelector(state => selectDarkMode(state));
  const dispatch = useDispatch();

  const handleToggle = value => dispatch(setDarkMode(!!value));

  return (
    <div>
      <Switch label="Dark Mode" initialValue={darkModeValue} onChange={handleToggle} />
    </div>
  );
};

export default DarkModeToggle;
