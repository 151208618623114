import React, { useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Badge } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useField } from 'react-final-form';

import MediaLineItem from './MediaLineItem';
import SecondaryButton from '../../shared/buttons/SecondaryButton';
import { useFeatureFlags } from '../../../hooks/settingsHooks';
import { ChecklistContext as ChecklistContextEditorBeta } from '../../checklistEditorV2/checklist-context';
import { ChecklistContext } from '../checklist-context';

const MediaPanel = props => {
  const attachmentContainer = useRef(null);
  const [showGallery, setShowGallery] = useState(false);
  const [showUploader, setShowUploader] = useState(false);
  const field = useField(props.name);
  const { hasChecklistEditorBeta } = useFeatureFlags();
  const imageCount = field.input.value?.length || 0;
  const checklistContextModule = hasChecklistEditorBeta ? ChecklistContextEditorBeta : ChecklistContext;

  const checklistContext = useContext(checklistContextModule);

  const handleClickShowGallery = event => {
    event.stopPropagation();
    setShowGallery(true);
  };

  const handleClickShowUploader = event => {
    event.stopPropagation();
    setShowUploader(true);
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography variant="h6">
          <Badge badgeContent={imageCount} invisible={imageCount < 1} color="primary">
            Media
          </Badge>
          &nbsp; &nbsp; &nbsp;
          <>
            <SecondaryButton
              label="Upload"
              onClick={handleClickShowUploader}
              variant="text"
              icon
              Icon={AttachFileIcon}
            />
            &nbsp; &nbsp;
          </>
          <SecondaryButton
            label="Gallery"
            onClick={handleClickShowGallery}
            variant="text"
            icon
            Icon={PhotoLibraryIcon}
          />
        </Typography>
      </AccordionSummary>
      <AccordionDetails ref={attachmentContainer}>
        <MediaLineItem
          name={props.name}
          showGallery={showGallery}
          setShowGallery={setShowGallery}
          showUploader={showUploader}
          setShowUploader={setShowUploader}
          {...checklistContext}
        />
      </AccordionDetails>
    </Accordion>
  );
};
MediaPanel.defaultProps = {};

MediaPanel.propTypes = {
  name: PropTypes.string.isRequired,
};

export default MediaPanel;
