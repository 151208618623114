import React, { useState } from 'react';

import { Grid, Checkbox } from '@mui/material';
import CommonForm from './Common';
import CommonField, { getEmptyValueForType } from './CommonField';

/**
 * Extends the CommonForm
 *  to provide bulk editing
 *
 * example usage: `ProjectsBulkEditForm`, see Issue #6608
 *
 */
const CommonBulkForm = props => {
  const { fieldSettings, mutators, decorators } = props;
  // save checked fields into bulkFields
  const [bulkFields, setBulkFields] = useState([]);
  const hasAnyFieldsChecked = Object.values(bulkFields).some(field => field);

  const handleCheckClick = fieldName => {
    setBulkFields({
      ...bulkFields,
      [fieldName]: !bulkFields[fieldName],
    });
  };

  const buildBulk = values => {
    const bulkValues = {};
    for (const [fieldName, checked] of Object.entries(bulkFields)) {
      if (checked) {
        // explicitly set to empty if checked and not set (see #6676)
        const value =
          typeof values[fieldName] === 'undefined'
            ? getEmptyValueForType(fieldSettings[fieldName].type)
            : values[fieldName];

        bulkValues[fieldName] = value;
      }
    }
    return bulkValues;
  };

  /**
   *
   * @param {*} key
   * @param {*} index
   * @param {*} value
   */
  const renderField = (key, index, value) => {
    // do not show any keys from the api that does not have a setting passed in.
    if (!fieldSettings[key]) return null;
    const { type, cellProps } = fieldSettings[key];

    const fieldProps = { ...fieldSettings[key].fieldProps }; // copy before mutating

    // if a fieldSettings needs to set a value for the field,
    // pass in a function that accepts the current value from the form.
    if (typeof fieldProps.value === 'function') fieldProps.value = fieldProps.value(value);

    // disable field until checked
    if (!bulkFields[fieldProps.name]) {
      fieldProps._disabled = fieldProps.disabled; // in case required
      fieldProps.disabled = true;
    }
    return (
      <Grid item container xs={12} alignItems="center" {...cellProps} key={index}>
        <Grid xs={1}>
          <Checkbox
            onClick={() => {
              handleCheckClick(fieldProps.name);
            }}
            checked={bulkFields[fieldProps.name]}
            color="primary"
          />
        </Grid>
        <Grid xs={11}>
          <CommonField type={type} {...fieldProps} />
        </Grid>
      </Grid>
    );
  };

  const onSubmit = values => {
    props.onSubmit(buildBulk(values));
  };

  return (
    <CommonForm
      {...props}
      onSubmit={onSubmit}
      dirty={hasAnyFieldsChecked}
      renderField={renderField}
      disableSubmit={!hasAnyFieldsChecked}
      mutators={mutators}
      decorators={decorators}
    />
  );
};

CommonBulkForm.propTypes = {
  // pass all into common form
  ...CommonForm.propTypes,
};

export default CommonBulkForm;
