import { buildEndpoint, get } from './util';

/**
 * https://docs.huvrdata.app/reference/api_checklist-result-lines_list
 */
export const checklistResultLinesListEndpoint = buildEndpoint(
  'checklistResultLinesList',
  query => get('/api/checklist-result-lines/', query),
  {}
);

/**
 * https://docs.huvrdata.app/reference/api_checklist-result-lines_read
 */
export const checklistResultLinesReadEndpoint = buildEndpoint(
  'checklistResultLinesRead',
  id => get(`/api/checklist-result-lines/${id}/`),
  {}
);
