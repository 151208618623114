export const SET_WATCHERS_TABLE_VIEW = 'SET_WATCHERS_TABLE_VIEW';
export const SET_WATCHERS_TABLE_SIZE = 'SET_WATCHERS_TABLE_SIZE';

export const setWatchersTableView = (column, action) => {
  return {
    type: SET_WATCHERS_TABLE_VIEW,
    column,
    action,
  };
};

export const setWatchersTableSize = size => {
  return {
    type: SET_WATCHERS_TABLE_SIZE,
    size,
  };
};

export const watchersTableViewReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_WATCHERS_TABLE_VIEW:
      return {
        ...state,
        [action.column]: action.action,
      };
    case SET_WATCHERS_TABLE_SIZE:
      return {
        ...state,
        pageSize: action.size,
      };
    default:
      return state;
  }
};
