// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import { combineReducers } from 'redux';
import {
  LIBRARIES_REQUEST,
  LIBRARIES_SUCCESS,
  LIBRARIES_FAILURE,
  LIBRARIES_CREATE_REQUEST,
  LIBRARIES_CREATE_SUCCESS,
  LIBRARIES_CREATE_FAILURE,
  LIBRARIES_UPDATE_REQUEST,
  LIBRARIES_UPDATE_SUCCESS,
  LIBRARIES_UPDATE_FAILURE,
  LIBRARIES_DELETE_REQUEST,
  LIBRARIES_DELETE_SUCCESS,
  LIBRARIES_DELETE_FAILURE,
  LIBRARIES_ALL_REQUEST,
  LIBRARIES_ALL_SUCCESS,
  LIBRARIES_ALL_FAILURE,
  LIBRARIES_CLEAR,
} from './librariesActions';

export const initialStateLibraries = {
  loading: false,
  error: '',
  formError: {},
  id: 0,
  body: {},
  data: {},
};

export const initialStateAllLibraries = {
  loading: false,
  error: '',
  query: {},
  dataAll: { count: 0, next: null, previous: null, results: [] },
};

export const librariesReducer = (state = initialStateLibraries, action) => {
  switch (action.type) {
    case LIBRARIES_REQUEST:
      return {
        ...initialStateLibraries,
        loading: true,
        id: action.id,
      };
    case LIBRARIES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case LIBRARIES_FAILURE:
      return {
        ...initialStateLibraries,
        error: action.error,
      };
    case LIBRARIES_CREATE_REQUEST:
      return {
        ...initialStateLibraries,
        loading: true,
        body: action.body,
      };
    case LIBRARIES_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case LIBRARIES_CREATE_FAILURE:
      return {
        ...initialStateLibraries,
        error: action.error,
        formError: action.formError,
      };
    case LIBRARIES_UPDATE_REQUEST:
      return {
        ...state,
        error: '',
        formError: {},
        loading: true,
        id: action.id,
        body: action.body,
      };
    case LIBRARIES_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case LIBRARIES_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case LIBRARIES_DELETE_REQUEST:
      return {
        ...initialStateLibraries,
        error: '',
        formError: {},
        deleting: true,
        id: action.id,
      };
    case LIBRARIES_DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
      };
    case LIBRARIES_DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        error: action.error,
        formError: action.formError,
      };
    default:
      return state;
  }
};

export const librariesAllReducer = (state = initialStateAllLibraries, action) => {
  switch (action.type) {
    case LIBRARIES_ALL_REQUEST:
      return {
        ...initialStateAllLibraries,
        loading: true,
        query: action.query,
      };
    case LIBRARIES_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        dataAll: action.data,
      };
    case LIBRARIES_ALL_FAILURE:
      return {
        ...initialStateAllLibraries,
        error: action.error,
      };
    case LIBRARIES_CLEAR:
      return initialStateAllLibraries;
    default:
      return state;
  }
};

export default combineReducers({
  each: librariesReducer,
  all: librariesAllReducer,
});
