// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import { combineReducers } from 'redux';
import {
  SCHEDULEPLAN_REQUEST,
  SCHEDULEPLAN_SUCCESS,
  SCHEDULEPLAN_FAILURE,
  SCHEDULEPLAN_CREATE_REQUEST,
  SCHEDULEPLAN_CREATE_SUCCESS,
  SCHEDULEPLAN_CREATE_FAILURE,
  SCHEDULEPLAN_UPDATE_REQUEST,
  SCHEDULEPLAN_UPDATE_SUCCESS,
  SCHEDULEPLAN_UPDATE_FAILURE,
  SCHEDULEPLAN_DELETE_REQUEST,
  SCHEDULEPLAN_DELETE_SUCCESS,
  SCHEDULEPLAN_DELETE_FAILURE,
  SCHEDULEPLAN_ALL_REQUEST,
  SCHEDULEPLAN_ALL_SUCCESS,
  SCHEDULEPLAN_ALL_FAILURE,
  SCHEDULEPLAN_GENERATE_EVENT_REQUEST,
  SCHEDULEPLAN_GENERATE_EVENT_SUCCESS,
  SCHEDULEPLAN_GENERATE_EVENT_FAILURE,
  SCHEDULEPLAN_RESET,
} from './schedulePlanActions';

export const initialStateSchedulePlan = {
  loading: false,
  generateEventIsLoading: false,
  error: '',
  formError: {},
  id: 0,
  body: {},
  data: {},
};

export const initialStateAllSchedulePlan = {
  loading: false,
  error: '',
  query: {},
  dataAll: { count: 0, next: null, previous: null, results: [] },
};

export const resetStateSchedulePlan = {
  loading: false,
  generateEventIsLoading: false,
  error: '',
  formError: {},
};
export const resetStateAllSchedulePlan = {
  loading: false,
  error: '',
  query: {},
  dataAll: [],
};

export const schedulePlanEachReducer = (state = initialStateSchedulePlan, action) => {
  switch (action.type) {
    case SCHEDULEPLAN_RESET:
      return {
        ...resetStateSchedulePlan,
        data: state.data,
      };
    case SCHEDULEPLAN_REQUEST:
      return {
        ...initialStateSchedulePlan,
        loading: true,
        id: action.id,
      };
    case SCHEDULEPLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case SCHEDULEPLAN_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case SCHEDULEPLAN_CREATE_REQUEST:
      return {
        ...initialStateSchedulePlan,
        loading: true,
        body: action.body,
      };
    case SCHEDULEPLAN_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case SCHEDULEPLAN_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case SCHEDULEPLAN_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        id: action.id,
        body: action.body,
      };
    case SCHEDULEPLAN_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case SCHEDULEPLAN_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case SCHEDULEPLAN_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        formError: {},
        id: action.id,
      };
    case SCHEDULEPLAN_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SCHEDULEPLAN_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case SCHEDULEPLAN_GENERATE_EVENT_REQUEST:
      return {
        ...state,
        generateEventIsLoading: true,
      };
    case SCHEDULEPLAN_GENERATE_EVENT_SUCCESS:
      return {
        ...state,
        generateEventIsLoading: false,
      };
    case SCHEDULEPLAN_GENERATE_EVENT_FAILURE:
      return {
        ...state,
        generateEventIsLoading: false,
        error: action.error,
        formError: action.formError,
      };
    default:
      return state;
  }
};

export const schedulePlanAllReducer = (state = initialStateAllSchedulePlan, action) => {
  switch (action.type) {
    case SCHEDULEPLAN_ALL_REQUEST:
      return {
        ...initialStateAllSchedulePlan,
        loading: true,
        query: action.query,
      };
    case SCHEDULEPLAN_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        dataAll: action.data,
      };
    case SCHEDULEPLAN_ALL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export default combineReducers({
  each: schedulePlanEachReducer,
  all: schedulePlanAllReducer,
});
