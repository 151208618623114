import { DIALOG_OPEN, DIALOG_CLOSE } from './dialogActions';

const initialState = {
  open: false,
  title: '',
  body: null,
  options: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DIALOG_OPEN:
      return {
        open: true,
        title: action.title,
        body: action.body,
        options: action.options || {},
      };
    case DIALOG_CLOSE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
