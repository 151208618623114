// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import React from 'react';
import CommonRoutes from './utilities/CommonRoutes';
import CrewsForm from '../../components/crews/CrewsForm';
import CrewsView from '../../components/crews/CrewsView';
import CrewsTablePage from '../../components/crews/CrewsTablePage';
import MainLayout from '../layouts/MainLayout';

const CrewsRoutes = props => {
  return (
    <CommonRoutes
      pageTitle="Crew"
      layout={MainLayout}
      Edit={<CrewsForm update />}
      Create={<CrewsForm />}
      View={<CrewsView />}
      All={<CrewsTablePage />}
    />
  );
};

export default CrewsRoutes;
