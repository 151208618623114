import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import Table from '../shared/table/Table';
import StyledLink from '../shared/StyledLink';
import Description from '../shared/table/Description';
import { setColumns } from '../shared/table/columns';
import ButtonIcon from '../shared/buttons/ButtonIcon';
import { riskScoreOptions, workStatusOptions } from '../../api/features/constants';
import { setTableView, setTableSize } from '../../store/settings/tableActions';
import useDateRangeColumnHook from '../../hooks/table/dateRangeColumnHook';
import MultiSelectFilter from '../shared/table/MultiSelectFilter';
import { getCustomCSVData, tableViews } from '../../utilities/tables';
import RecommendedWorkPlanToolBarSelect from './RecommendedWorkPlanToolBarSelect';
import { usePermissions } from '../../hooks/settingsHooks';
import AssessmentRiskScoreDisplay from '../assessments/AssessmentRiskScore';
import { compareFilterMapOptions } from '../../utilities/strings';
import { compare } from '../../utilities/arrays';
import { useCachedAssetTypes } from '../../hooks/assetTypesHooks';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const RecommendedWorkPlanTable = props => {
  const { title, queryParamObj, tableChangeHandler, embedded, page, views, excludeAssetPath } = props;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { results: data, loading, count } = useSelector(state => state.recommendedWorkPlan.all.dataAll);
  const { assetTypeOptions, assetTypeMap } = useCachedAssetTypes();

  const { hasAssetView, hasConfigurationManagementView } = usePermissions();

  const { recommendedWorkPlanTableViewKey } = tableViews;

  const queryFilters = queryParamObj;

  const queryWorkStatusInFilters = queryFilters?.status__in ? [].concat(queryFilters.status__in.split(',')) : [];
  const queryRiskScoreDisplayFilters = queryFilters?.risk_score__display
    ? [].concat(queryFilters.risk_score__display.split(','))
    : [];
  const queryAssetTypeFilters = queryFilters?.asset__type__in ? [].concat(queryFilters.asset__type__in.split(',')) : [];
  const [workStatusFilterList, setWorkStatusFilterList] = useState(queryWorkStatusInFilters);
  const [riskScoreDisplayFilterList, setRiskScoreDisplayFilterList] = useState(queryRiskScoreDisplayFilters);
  const [assetTypeFilterList, setAssetTypeFilterList] = useState(queryAssetTypeFilters);

  /** @type {Column[]} */
  const columns = [
    {
      // This needs to be first and excluded so customBodyRender can access the id to create the link.
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <StyledLink
              to={`/recommended-work-plan/${tableMeta.rowData[columns.findIndexByName['id']]}`}
              value={value}
            />
          );
        },
      },
    },
    {
      name: 'asset',
      label: 'Asset Path',
      options: {
        display: excludeAssetPath ? 'excluded' : true,
        filter: true,
        sort: true,
        filterType: 'textField',
        sortField: 'assessment_revision__assessment__asset__asset_path_cache__path',
        downloadBody: value => getCustomCSVData('simple', value, 'asset_path'),
        customBodyRender: value => {
          if (!value) return <></>;
          if (!hasAssetView) return <>{value.asset_path}</>;
          return <StyledLink to={`/assets/${value.id}/assessments`} value={value.asset_path} />;
        },
      },
    },
    {
      name: 'asset.type',
      label: 'Asset Type',
      options: {
        filter: false,
        sort: true,
        sortField: 'assessment_revision__assessment__asset__type__name',
        downloadBody: value => getCustomCSVData('simple', value, 'name'),
        customBodyRender: value => {
          if (!value) return <></>;
          return hasConfigurationManagementView ? (
            <StyledLink to={`/asset-types/${value.id}`} value={value.name} />
          ) : (
            <>{value.name}</>
          );
        },
      },
    },
    {
      name: 'asset__type__in',
      label: 'Asset Type',
      options: {
        filter: true,
        filterType: 'custom',
        sort: false,
        display: 'excluded',
        download: false,
        filterList: assetTypeFilterList,
        // chips functionality
        customFilterListOptions: {
          render: values => {
            return values.map(v => {
              return assetTypeMap[v];
            });
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            setAssetTypeFilterList(filterList[index]);
            return filterList;
          },
        },
        // filtering menu
        filterOptions: {
          names: assetTypeOptions,
          render: v => assetTypeMap[v],
          logic: (type, filters) => {
            if (filters.length) return !filters.includes(type);
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            const formatValue = item => {
              return assetTypeMap[item];
            };
            const getComparable = value => compareFilterMapOptions(value, assetTypeMap);
            // Sort top level filter options
            filterData[index] = filterData[index].sort(compare(getComparable));

            return (
              <MultiSelectFilter
                title="Asset Types"
                filterList={filterList}
                localFilterList={assetTypeFilterList}
                onChange={onChange}
                index={index}
                column={column}
                filterData={filterData}
                updateFilters={setAssetTypeFilterList}
                formatValue={formatValue}
              />
            );
          },
        },
      },
    },
    {
      name: 'risk_score',
      label: 'Risk',
      options: {
        filter: true,
        sort: true,
        sortField: 'assessment_revision__assessment__risk_score__level',
        filterField: 'risk_score__display',
        filterType: 'custom',
        downloadBody: value => getCustomCSVData('simple', value, 'display'),
        customBodyRender: value => (value.display ? <AssessmentRiskScoreDisplay score={value.display} /> : null),
        filterList: riskScoreDisplayFilterList,
        // chips functionality
        customFilterListOptions: {
          render: values => {
            return values.map(v => {
              return riskScoreOptions.find(o => o.value === v)?.label;
            });
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            setRiskScoreDisplayFilterList(filterList[index]);
            return filterList;
          },
        },
        // filtering menu
        filterOptions: {
          names: riskScoreOptions,
          render: v => riskScoreOptions.find(o => o.value === String(v.display)).label,
          logic: (type, filters) => {
            if (filters.length) return !filters.includes(type);
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            const formatValue = item => {
              return riskScoreOptions.find(o => o.value === item)?.label;
            };

            filterData[index] = riskScoreOptions.map(item => item.value); // need to maintain order of assessment status
            return (
              <MultiSelectFilter
                title="Risk Score"
                filterList={filterList}
                localFilterList={riskScoreDisplayFilterList}
                onChange={onChange}
                index={index}
                column={column}
                filterData={filterData}
                updateFilters={setRiskScoreDisplayFilterList}
                formatValue={formatValue}
              />
            );
          },
        },
      },
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        filter: false,
        sort: true,
        filterType: 'textField',
        customBodyRender: value => {
          if (!value) return <></>;
          return <Description value={value} />;
        },
      },
    },
    {
      name: 'category',
      label: 'Category',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'method',
      label: 'Method',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'technique',
      label: 'Technique',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'frequency',
      label: 'Frequency',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'start',
      label: 'Start Date',
      options: {
        filter: false,
        sort: true,
      },
    },
    useDateRangeColumnHook({
      name: 'due',
      label: 'Due Date',
      queryParamObj,
      queryStartKey: 'due_after',
      queryEndKey: 'due_before',
    }),
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'status__in',
      label: 'Status',
      options: {
        filter: true,
        filterType: 'custom',
        sort: false,
        display: 'excluded',
        download: false,
        filterList: workStatusFilterList,
        // chips functionality
        customFilterListOptions: {
          render: values => {
            return values.map(v => {
              return workStatusOptions.find(o => o.value === v)?.label;
            });
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            setWorkStatusFilterList(filterList[index]);
            return filterList;
          },
        },
        // filtering menu
        filterOptions: {
          names: workStatusOptions,
          render: v => workStatusOptions.find(o => o.label === v).label,
          logic: (type, filters) => {
            if (filters.length) return !filters.includes(type);
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            const formatValue = item => {
              return workStatusOptions.find(o => o.value === item)?.label;
            };

            filterData[index] = workStatusOptions.map(item => item.value); // need to maintain order of work status
            return (
              <MultiSelectFilter
                title="Work Status"
                filterList={filterList}
                localFilterList={workStatusFilterList}
                onChange={onChange}
                index={index}
                column={column}
                filterData={filterData}
                updateFilters={setWorkStatusFilterList}
                formatValue={formatValue}
              />
            );
          },
        },
      },
    },
    {
      name: 'status_note',
      label: 'Status Note',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        print: false,
        viewColumns: false,
        customBodyRender: (_, info) => {
          // 0 is the first element in column
          const id = info.rowData[columns.findIndexByName['id']];
          // const name = info.rowData[columns.findIndexByName['name']];
          return (
            <>
              <ButtonIcon
                data-testid="edit-button"
                history={history}
                icon={EditIcon}
                location={location}
                to={`/recommended-work-plan/${id}/edit`}
              />
            </>
          );
        },
      },
    },
  ];

  // handle columns display
  setColumns(columns, views);

  /** @type {TableOptions} */
  const options = {
    // if the column view changes, update redux with either 'add' or 'remove'
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setTableView(changedColumn, action, page, recommendedWorkPlanTableViewKey));
    },
    onChangeRowsPerPage: numberOfRows => {
      dispatch(setTableSize(numberOfRows, page, recommendedWorkPlanTableViewKey));
    },
    customToolbarSelect: (selectedRows, displayData) => {
      return (
        <RecommendedWorkPlanToolBarSelect selectedRows={selectedRows} displayData={displayData} columns={columns} />
      );
    },
    enableNestedDataAccess: '.',
  };

  return (
    <Table
      title={title}
      columns={columns}
      // addRoute="/recommended-work-plan/new"
      data={data}
      options={options}
      loading={loading}
      embedded={embedded}
      serverSide
      queryParamObj={queryParamObj}
      tableChangeHandler={tableChangeHandler}
      views={views}
      count={count}
    />
  );
};

RecommendedWorkPlanTable.defaultProps = {
  embedded: false,
  queryParamObj: {},
  page: '',
  views: {},
  excludeAssetPath: false,
};

RecommendedWorkPlanTable.propTypes = {
  title: PropTypes.string.isRequired,
  tableChangeHandler: PropTypes.func.isRequired,
  embedded: PropTypes.bool,
  queryParamObj: PropTypes.object,
  page: PropTypes.string,
  views: PropTypes.object,
  excludeAssetPath: PropTypes.bool,
};

export default RecommendedWorkPlanTable;
