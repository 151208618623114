import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  iframeStyle: {
    width: '100%',
    height: 'calc(100vh - 70px)',
  },
}));

const LookerIFramePage = props => {
  const classes = useStyles();
  const lookerRef = useRef(null);

  useEffect(() => {
    const lookerElement = lookerRef.current;
    console.log('Looker Iframe element is loaded: ' + !!lookerElement);
    if (!lookerElement) return;

    const listenerFunction = event => {
      if (event.source === lookerElement.contentWindow && event.origin === 'https://huvrdata.cloud.looker.com') {
        console.log(JSON.parse(event.data).type);
      }
    };

    try {
      lookerElement.addEventListener('load', () => {
        window.addEventListener('message', listenerFunction);
      });
    } catch (error) {
      console.log('Error:', error.message);
    }

    return () => {
      window.removeEventListener('message', listenerFunction);
    };
  }, [lookerRef]);

  return (
    <div className={classes.root}>
      <iframe
        ref={lookerRef}
        className={classes.iframeStyle}
        title="Looker Embed"
        src={props.data.source_url}
        allowFullScreen
        data-testid="looker-iframe-element"></iframe>
    </div>
  );
};

LookerIFramePage.propTypes = {
  data: PropTypes.object.isRequired,
  filterName: PropTypes.string,
  filterValue: PropTypes.string,
  lookerTheme: PropTypes.string,
};

export default LookerIFramePage;
