import { all, put, takeLatest } from 'redux-saga/effects';

import { USER_PROFILE_REQUEST, USER_PROFILE_SUCCESS, USER_PROFILE_FAILURE } from './profileActions';

import { fetchUser } from '../../api/getters/profile';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';

export function* userDataFetch() {
  try {
    const { data, error, response } = yield fetchUser();
    if (data) {
      yield put({ type: USER_PROFILE_SUCCESS, data });
    } else if (error) {
      yield put({ type: USER_PROFILE_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, USER_PROFILE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, USER_PROFILE_FAILURE);
  }
}

export function* watchUserDataFetch() {
  yield takeLatest(USER_PROFILE_REQUEST, userDataFetch);
}

export default function* profileSaga() {
  yield all([watchUserDataFetch()]);
}
