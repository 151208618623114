import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, Button, Typography } from '@mui/material';
import arrayMutators from 'final-form-arrays';
import { documentStateOptions } from '../../api/features/constants';

import { jsonKeyToLabel, apiDateToString, stringToArray, queriesFromString } from '../../utilities/strings';
import {
  getChecklistTemplates,
  createChecklistTemplates,
  updateChecklistTemplates,
  clearEachError,
  exportChecklistTemplates,
  downloadChecklistTemplateYAML,
} from '../../store/features/checklistTemplatesActions';
import CommonForm from '../shared/form/Common';
import { FIELD_TYPES } from '../shared/form/CommonField';
import { fieldOrder, removeField, hideField, FORM_TYPE, typeOptions } from './checklistTemplatesShared';
import { isEmpty } from '../../utilities/objects';

const ChecklistTemplatesForm = props => {
  const { update } = props;
  const params = useParams();
  const location = useLocation();
  const { data, loading, error, formError } = useSelector(state => state.checklistTemplates.each);
  const [submittedValues, setSubmittedValues] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (update && params.id) {
      dispatch(getChecklistTemplates(params.id));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (formError || error) {
      dispatch(clearEachError());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formType = (() => {
    if (update && queriesFromString(location.search).upload === 'true') {
      return FORM_TYPE.UPLOAD_UPDATE_VERSION;
    } else if (update) {
      return FORM_TYPE.EDIT_META;
    } else {
      return FORM_TYPE.UPLOAD_INITIAL;
    }
  })();

  const formSettings = {
    fieldOrder,
    removeField: removeField(formType),
    hideField,
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      switch (key) {
        case 'description':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: 'Description',
              name: 'description',
              multiline: true,
              rows: 3,
              maxRows: 10,
            },
          };
          break;
        case 'type':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: 'Type',
              name: 'type',
              options: typeOptions,
              labelwidth: 40,
            },
          };
          break;
        case 'state':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: 'State',
              name: 'state',
              options: documentStateOptions,
              labelwidth: 40,
            },
          };
          break;
        case 'categories':
          fieldSettings[key] = {
            type: 'array',
            fieldProps: {
              label: 'Categories',
              name: 'categories',
            },
          };
          break;
        case 'download_xlsx':
          fieldSettings[key] = {
            type: FIELD_TYPES.COMPONENT,
            fieldProps: {
              value: () => (
                <Button
                  variant="link"
                  onClick={() => {
                    dispatch(exportChecklistTemplates(data.id, data.name));
                  }}>
                  Download Current (Excel)
                </Button>
              ),
            },
          };
          break;
        case 'download_yaml':
          fieldSettings[key] = {
            type: FIELD_TYPES.COMPONENT,
            fieldProps: {
              value: () => (
                <Button
                  variant="link"
                  onClick={() => {
                    dispatch(downloadChecklistTemplateYAML(data.id, data.name));
                  }}>
                  Download Current (YAML)
                </Button>
              ),
            },
          };
          break;
        case 'import_file':
          fieldSettings[key] = {
            type: 'file',
            fieldProps: {
              label: 'Import Checklist',
              name: 'import_file',
              accept: '.xls, .xlsx, .yaml',
              uploadText: 'Click to upload checklist.',
            },
          };
          break;
        /* ----------  Disabled Fields ---------- */
        case 'created_at':
        case 'updated_at':
          fieldSettings[key] = {
            type: 'display',
            fieldProps: {
              disabled: true,
              label: jsonKeyToLabel(key),
              name: key,
              value: apiDateToString(data[key], 'date'),
            },
          };
          break;
        /* ----------  Default ---------- */
        default:
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
            },
          };
      }
    });
  }

  const title = (() => {
    switch (formType) {
      case FORM_TYPE.UPLOAD_INITIAL:
        return 'New Checklist Template';
      case FORM_TYPE.UPLOAD_UPDATE_VERSION:
      case FORM_TYPE.EDIT_META:
        return 'Edit Checklist Template';
      default:
        console.warn('ChecklistTemplatesForm: unknown formType', formType);
    }
  })();

  let categories = data.categories;
  // handle data.categories coming in as a string
  if (typeof data.categories === 'string') {
    categories = stringToArray(data.categories, ',');
  }

  let initialValues = {};
  if (update) {
    initialValues = { ...data, categories };
  }

  const onSubmit = changedValues => {
    const updatedValues = { ...changedValues };
    setSubmittedValues(updatedValues);
    if (update) {
      if (!updatedValues.import_file) {
        dispatch(updateChecklistTemplates(updatedValues.id, updatedValues));
      } else {
        // update the existing checklist template but with all new data from file
        const formData = new FormData();
        const file = changedValues.import_file[0];
        formData.append('id', updatedValues.id);
        formData.append('action', 'IMPORT');
        formData.append('import_file', file);
        dispatch(createChecklistTemplates(formData));
      }
    } else {
      // create new checklist template
      const formData = new FormData();
      const file = changedValues.import_file[0];
      formData.append('action', 'IMPORT');
      formData.append('import_file', file);
      dispatch(createChecklistTemplates(formData));
    }
  };

  /**
   * Decorators are used for setting the values of other fields based off of a field.
   * Decorator format can be found here: src/components/shared/form/common.js
   */
  const decorators = [];

  /**
   *  Validations that are per field.
   *  Errors associated with the form are passed back through formError in Redux.
   */
  const validate = values => {
    const errors = {};
    // if (!values) return errors;
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        if (values[key] === submittedValues[key]) {
          errors[key] = value;
        } else {
          errors[key] = undefined;
        }
      }
    }
    return errors;
  };

  const helperText = (() => {
    if (loading) {
      return 'Loading...';
    }
    switch (formType) {
      case FORM_TYPE.EDIT_META:
        return <a href="?upload=true">Upload New Version</a>;
      case FORM_TYPE.UPLOAD_UPDATE_VERSION:
        return (
          <>
            <Typography color="textPrimary">
              Template: {data.name}
              <br />
              Version: {data.version}
            </Typography>
            <Typography color="primary">
              Warning: using Excel import will update ALL template meta data
              <br />
              (version number, name, etc.)
            </Typography>
          </>
        );
      default:
        return '';
    }
  })();

  return (
    <>
      {!isEmpty(formError) && (
        <Alert
          severity="error"
          sx={{
            marginTop: '18px',
            display: 'inline-flex',
            width: '90%',
          }}>
          {'Error: ' + JSON.stringify(formError, '', 2)}
        </Alert>
      )}
      <CommonForm
        update={update}
        title={title}
        initialValues={initialValues}
        fieldSettings={fieldSettings}
        formSettings={formSettings}
        onSubmit={onSubmit}
        decorators={decorators}
        mutators={{ ...arrayMutators }}
        validate={validate}
        loading={loading}
        helperText={helperText}
        // check for upload errors
        error={formError && formError.errors ? formError.errors : error}
      />
      {[FORM_TYPE.UPLOAD_INITIAL, FORM_TYPE.UPLOAD_UPDATE_VERSION].includes(formType) && (
        <Typography color="textPrimary">
          Click <a href="/templates/HUVR-checklist-template.xlsx"> here </a>to get the latest Excel checklist template.
        </Typography>
      )}
    </>
  );
};

ChecklistTemplatesForm.defaultProps = {
  update: false,
};

ChecklistTemplatesForm.propTypes = {
  update: PropTypes.bool,
};

export default ChecklistTemplatesForm;
