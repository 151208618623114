import React from 'react';
import PropTypes from 'prop-types';
import Grow from '@mui/material/Grow';
import { Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import withStyles from '@mui/styles/withStyles';

const defaultStyles = theme => ({
  main: {
    display: 'flex',
    flex: '1 0 auto',
  },
  searchIcon: {
    color: theme.palette.text.secondary,
    marginTop: '10px',
    marginRight: '8px',
  },
  searchText: {
    flex: '0.8 0',
  },
  clearIcon: {
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
});

class _SimpleTableSearch extends React.Component {
  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown, false);
  }

  onKeyDown(event) {
    if (event.keyCode === 27) {
      this.props.onHide();
    }
  }

  render() {
    const { classes, options, onHide, onSearch, searchText } = this.props;

    return (
      <Grow appear in={true} timeout={300}>
        <div className={classes.main}>
          <SearchIcon className={classes.searchIcon} />
          <TextField
            className={classes.searchText}
            InputProps={{
              'data-test-id': options.textLabels.toolbar.search,
              'aria-label': options.textLabels.toolbar.search,
            }}
            defaultValue={searchText}
            onChange={e => onSearch(e.target.value)}
            fullWidth={true}
            inputRef={el => (this.searchField = el)}
            variant="standard"
            placeholder={options.searchPlaceholder}
            {...(options.searchProps ? options.searchProps : {})}
          />
          <IconButton className={classes.clearIcon} onClick={onHide} size="large">
            <ClearIcon />
          </IconButton>
        </div>
      </Grow>
    );
  }
}

_SimpleTableSearch.defaultProps = {
  options: {},
  onSearch: undefined,
  onHide: undefined,
  searchText: '',
};

_SimpleTableSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.object,
  onSearch: PropTypes.func,
  onHide: PropTypes.func,
  searchText: PropTypes.string,
};

const SimpleTableSearch = withStyles(defaultStyles, { name: 'MUIDataTableSearch' })(_SimpleTableSearch);
export { SimpleTableSearch };

export function simpleSearchRender(title) {
  return (searchText, handleSearch, hideSearch, options) => {
    return (
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box>{title}</Box>
        <Box flexGrow="1" marginLeft="2rem">
          <SimpleTableSearch searchText={searchText} onSearch={handleSearch} onHide={hideSearch} options={options} />
        </Box>
      </Box>
    );
  };
}
