import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { IconButton, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

// components
import ConfirmDialog from '../shared/Dialog/ConfirmDialog';

// util
import { openDialog } from '../../store/dialogActions';
import { usePermissions } from '../../hooks/settingsHooks';
import { getIdFromRow } from '../shared/table/columns';
import { bulkLockChecklists } from '../../store/features/checklistsActions';

const useStyles = makeStyles(theme => ({
  inheritDisplay: {
    display: 'inherit',
  },
}));

const ChecklistToolBarSelect = props => {
  const { selectedRows, displayData, setSelectedRows, columns } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { hasChecklistLock, hasChecklistUnlock } = usePermissions();

  const lockAction = 'LOCK';
  const unlockAction = 'UNLOCK';

  const handleBulkLock = action => {
    const selectedRowsIds = selectedRows.data.map(item => getIdFromRow(item, displayData, columns)?.props?.value);

    const bulkLock = async () => {
      dispatch(bulkLockChecklists(selectedRowsIds, action));
      setSelectedRows([]);
    };

    const forms = selectedRowsIds.length === 1 ? 'form' : 'forms';
    const title = `${action === lockAction ? 'Lock' : 'Unlock'} ${selectedRowsIds.length} ${forms}?`;
    const actionWord = action === lockAction ? 'locked' : 'unlocked';
    const note = `Note: Primary forms (checklists) of Projects will not be ${actionWord}.\n\n`;
    const message = `${note}Form IDs selected: ${selectedRowsIds.join(', ')}`;
    dispatch(openDialog(title, <ConfirmDialog notes={message} confirmAction={bulkLock} />));
  };

  return (
    <div className={classes.inheritDisplay}>
      {hasChecklistLock && (
        <Tooltip title="Lock forms">
          <IconButton onClick={() => handleBulkLock(lockAction)}>
            <LockIcon />
          </IconButton>
        </Tooltip>
      )}
      {hasChecklistUnlock && (
        <Tooltip title="Unlock forms">
          <IconButton onClick={() => handleBulkLock(unlockAction)}>
            <LockOpenIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};
ChecklistToolBarSelect.defaultProps = {};

ChecklistToolBarSelect.propTypes = {
  selectedRows: PropTypes.shape({
    data: PropTypes.array,
    dataIndex: PropTypes.Integer,
  }).isRequired,
  displayData: PropTypes.array.isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired, // Table column definitions
};

export default ChecklistToolBarSelect;
