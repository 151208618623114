import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fieldOrder, removeField, hideField } from './cmlsShared';
import { buildRoute, capitalizeFirstChar, apiDateToString, jsonKeyToLabel } from '../../utilities/strings';
import CommonFormView from '../shared/form/CommonView';
import { useFeatureFlags } from '../../hooks/settingsHooks';
import { AssetLink, ProjectLink } from '../shared/links/InternalLinks';

const CmlsView = () => {
  const id = useParams().id;

  const { data, loading, error } = useSelector(state => state.cmls.each);
  const { hasAccessDisplay } = useFeatureFlags();
  const formSettings = {
    fieldOrder,
    removeField: removeField('VIEW'),
    hideField: (values, item) => {
      return hideField(values, item, hasAccessDisplay);
    },
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      // cast to string because booleans do not show up in the field
      let displayLabel = jsonKeyToLabel(key); // eslint-disable-line prefer-const
      let displayValue = '';
      if (data[key] != null) {
        switch (key) {
          case 'is_active':
            displayValue = capitalizeFirstChar(String(data[key]));
            break;
          case 'asset':
            fieldSettings[key] = {
              type: 'component',
              cellProps: {
                xs: 6,
              },
              fieldProps: {
                label: displayLabel,
                value: () => <AssetLink {...data[key]} />,
              },
            };
            return;
          case 'access_display':
            displayValue = data[key].toString();
            displayLabel = 'Access';
            break;

          case 'component_display':
            displayValue = data[key].toString();
            displayLabel = 'Component';
            break;
          case 'location_code_display':
            displayValue = data[key].toString();
            displayLabel = 'Location';
            break;
          case 'geo_point':
            fieldSettings[key] = {
              type: 'geo',
              cellProps: {
                md: 6,
                xs: 12,
              },
              fieldProps: {
                label: displayLabel,
                value: data[key],
              },
            };
            return;
          case 'labels_by_key':
            fieldSettings[key] = {
              type: 'key-value',
              fieldProps: {
                label: 'Labels', // rename Labels By Key -> Labels for display
                value: data[key],
              },
            };
            return;
          case 'installed_on':
          case 'updated_on':
          case 'created_on':
            displayValue = apiDateToString(data[key], 'date');
            break;
          case 'planned_project':
            fieldSettings[key] = {
              type: 'component',
              cellProps: {
                xs: 6,
              },
              fieldProps: {
                label: 'Planned Inspection',
                value: () => <ProjectLink {...data[key]} />,
              },
            };
            return;
          default:
            displayValue = data[key].toString();
        }
      }
      fieldSettings[key] = {
        type: 'text',
        fieldProps: {
          label: displayLabel,
          value: displayValue,
        },
      };
    });
  }

  return (
    <CommonFormView
      bodyOnly
      title={'CML Details'}
      values={data}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      editLink={buildRoute(`/cmls/${id}/edit`)}
      loading={loading}
      error={error}
    />
  );
};

export default CmlsView;
