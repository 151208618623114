import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Add from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';

import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { getAllChecklistTasks, updateChecklistTasks } from '../../store/features/checklistTasksActions';
import { openDialog, closeDialog } from '../../store/dialogActions';
import Title from '../shared/displays/Title';
import ConfirmDialog from '../shared/Dialog/ConfirmDialog';
import ButtonIcon from '../shared/buttons/ButtonIcon';
import ChecklistTasksForm from './ChecklistTasksForm';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(3, 4),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const TaskList = ({ checklist, projectId }) => {
  const dispatch = useDispatch();
  const handleListItemClick = event => {};
  const classes = useStyles();

  useEffect(() => {
    if (projectId) {
      dispatch(getAllChecklistTasks({ project: projectId }));
    } else {
      dispatch(getAllChecklistTasks({ checklist: checklist.id }));
    }
  }, [projectId]); // eslint-disable-line

  const markTask = (taskId, done) => {
    dispatch(updateChecklistTasks(taskId, { done }));
  };

  const handleClose = () => dispatch(closeDialog());

  const handleNewTask = () => {
    dispatch(
      openDialog(
        <ChecklistTasksForm
          projectId={projectId}
          handleBack={handleClose}
          handleNext={handleClose}
          checklist={checklist}
        />
      )
    );
  };

  const handleEditTask = taskId => {
    dispatch(
      openDialog(
        'Edit Task',
        <ChecklistTasksForm
          update
          taskId={taskId}
          projectId={projectId}
          checklist={checklist}
          handleBack={handleClose}
          handleNext={handleClose}
        />
      )
    );
  };

  const confirmIncompleteTask = (taskId, done) => {
    const notes = 'Are you sure you want to mark this task as incomplete?';
    dispatch(openDialog(<ConfirmDialog notes={notes} confirmAction={() => markTask(taskId, done)} />));
  };

  const { data: tasks } = useSelector(state => ({
    loading: state.checklistTasks.all.loading,
    data: state.checklistTasks.all.dataAll.results,
  }));

  const listTasks = tasks.map(task => (
    <ListItem
      key={task.id}
      secondaryAction={
        <IconButton edge="end" onClick={() => handleEditTask(task.id)}>
          <Edit />
        </IconButton>
      }
    >
      <ListItemButton test-id={`task-${task.id}`} onClick={event => handleListItemClick(event, task.id)}>
        <ListItemIcon>
          {task.done ? (
            <Tooltip title="Task Completed">
              <CheckBoxIcon onClick={() => confirmIncompleteTask(task.id, !task.done)} />
            </Tooltip>
          ) : (
            <CheckBoxOutlineBlankIcon onClick={() => markTask(task.id, !task.done)} />
          )}
        </ListItemIcon>
        <ListItemText primary={task.task} />
        <ListItemText primary="Assigned" secondary={task.assigned_to?.name || task.assigned_crew?.name || '--'} />
      </ListItemButton>
    </ListItem>
  ));

  return (
    <div className={classes.root}>
      <List dense="true">
        <div className={classes.header}>
          <Title title="Tasks" />
          <ButtonIcon icon={Add} title="Add Task" onClick={handleNewTask} iconSize="large" />
        </div>
        <Divider />
        {tasks.length === 0 ? 'No Tasks Assigned' : [...listTasks].reverse()}
      </List>
    </div>
  );
};

TaskList.defaultProps = {
  projectId: null,
  checklist: { createdBy: {} },
};

TaskList.propTypes = {
  projectId: PropTypes.number,
  checklist: PropTypes.object,
};

export default TaskList;
