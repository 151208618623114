import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ChecklistTasksTable from './ChecklistTasksTable';
import { getAllChecklistTasks } from '../../store/features/checklistTasksActions';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(7, '3vw'),
    padding: theme.spacing(2),
  },
}));

const ChecklistTasksTablePage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const defaultQuery = {};

  useEffect(() => {
    dispatch(getAllChecklistTasks(defaultQuery));
  }, []); // eslint-disable-line

  return (
    <Paper className={classes.root}>
      <ChecklistTasksTable title="Tasks" />
    </Paper>
  );
};

export default ChecklistTasksTablePage;
