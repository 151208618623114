import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Paper, Divider, Menu, MenuItem, Button /**  , Checkbox, FormControlLabel */ } from '@mui/material';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import createDecorator from 'final-form-calculate';

import Banner from '../../components/shared/displays/Banner';
import Error from '../../components/shared/displays/Error';

import { createChecklists, updateChecklists } from '../../store/features/checklistsActions';
import { openSnackbar } from '../../store/snackbarActions';
import { generateEmbeddedUrl, getChecklistErrorLabels, prepareResults } from '../../utilities/checklist';
import SectionNav from './nav/SectionNav';
import ChecklistToolbar from './ChecklistToolbar';
import ChecklistPanel from './ChecklistPanel';
import GettingStarted from './GettingStarted';
import ResultsDisplay from './ResultsDisplay';
import PrimaryButton from '../shared/buttons/PrimaryButton';
import SecondaryButton from '../shared/buttons/SecondaryButton';
import { ChecklistContext } from './checklist-context';

import './Checklist.scss';
import { setFieldValue } from '../shared/form/mutators';

import { useQuery } from '../../hooks/locationHooks';
import SubmitWithErrorsDialog from '../shared/Dialog/SubmitWithErrorsDialog';
import { isEmpty } from '../../utilities/objects';
import { queriesFromString } from '../../utilities/strings';
import { useFileUploader } from '../../providers/FileUploaderProvider';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
    width: '100vw',
    textAlign: 'left',
    fontSize: theme.spacing(3),
  },
  mainContent: {
    width: '100%',
    maxWidth: '900px',
    margin: 'auto',
  },
  bannerCss: {
    position: 'relative',
  },
  errorLink: {
    fontSize: theme.spacing(1),
  },
  imitateLink: {
    color: 'red',
    backgroundColor: 'transparent',
    fontSize: theme.spacing(2),
    textDecoration: 'underline',
    padding: '0',
    height: 'auto',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
}));

const Checklist = props => {
  const { backLink, isForm, parentId, readOnly, refresh, revisions, setRevisionId } = props;

  const { id: checklistId, template, results } = props.checklist;
  const { sections, default_actions: defaultActions } = template;
  const [anchorEl, setAnchorEl] = useState(null);
  const [checklistErrorLabels, setChecklistErrorLabels] = useState([]);
  const [openSections, setOpenSections] = useState([]);
  const [query, setQuery] = useQuery();
  const { isUploading: isUploadingMedia } = useFileUploader();

  // TODO: implement revert
  // const [revert, setRevert] = useState();   put in revert later

  const classes = useStyles();
  const dispatch = useDispatch();

  const formError = {};

  /**
   * get the selected revision from the revisions array
   *  - if no revision is selected, use the last revision
   *  - if no revisions exist, use the default edit data
   */
  const selectedRevision = useMemo(() => {
    if (!revisions?.length || props.selectedRevisionId === 'new') {
      return { id: 'new', edit: {} };
    }
    if (!props.selectedRevisionId || props.selectedRevisionId === 'latest') {
      return revisions[revisions.length - 1];
    }
    const found = revisions.find(rev => rev.id.toString() === props.selectedRevisionId.toString()); // use toString() in case read from URL
    if (!found && revisions.length > 0) {
      console.warn(`Revision ${props.selectedRevisionId} not found in revisions array`);
      return revisions[revisions.length - 1];
    } else if (!found && revisions.length === 0) {
      console.warn(
        `Revision ${props.selectedRevisionId} not found in revisions array and no revisions exist (using default edit data)`
      );
      return { id: 'loading', edit: {} }; // (assume loading - this should hopefully never happen)
    }

    return found;
  }, [props.selectedRevisionId, revisions]);

  // (NOT zero-)
  const revisionNumber = (revisions.findIndex(rev => rev.id === selectedRevision.id) || 0) + 1;
  const isLatestRevision = revisionNumber === revisions.length;

  const payloadExtras = useMemo(() => {
    const legalExtrasIds = ['project_id', 'asset_id', 'schedule_task'];
    const extras = {};
    if (location.search) {
      const queries = queriesFromString(location.search);
      for (const [key, value] of Object.entries(queries)) {
        if (legalExtrasIds.includes(key)) {
          extras[key] = parseInt(value);
        } else if (key === 'summary') {
          extras['summary'] = value;
        }
      }
    }
    return extras;
  }, []);

  /**
   * get the initial values for the form
   *  - if the selected revision is the latest, use the full results
   */
  const initialValues = useMemo(() => {
    const prefill = {};
    if (location.search) {
      const queries = queriesFromString(location.search);
      for (const [key, value] of Object.entries(queries)) {
        if (key.startsWith('prefill__')) {
          let currentObject = prefill;
          const keysString = key.replace('prefill__', '');
          const keys = keysString.split('.');
          for (const subKey of keys) {
            // support settings value or notes as prefilled
            if (['value', 'notes'].includes(subKey)) {
              currentObject[subKey] = value;
            } else if (!currentObject[subKey]) {
              currentObject[subKey] = {};
              currentObject = currentObject[subKey];
            } else {
              currentObject = currentObject[subKey];
            }
          }
        } else {
          // console.log('Skipping query param', key, value);
        }
      }
    }
    if (selectedRevision.id === revisions[revisions.length - 1]?.id) {
      return results;
    } else {
      return { ...selectedRevision.edit, ...prefill };
    }
  }, [selectedRevision, results, revisions]);

  /**
   * sections are stored in the query params so we can maintain state after refresh
   */
  const setSection = useCallback(
    // useCallback to prevent useEffect from running on every render
    section => {
      setQuery({ ...query, section });
    },
    [setQuery, query]
  );

  const currentSection = query.section || '';

  /**
   * refreshing page should set default to the first section
   *  - also make sure the section is in the openSections array
   */
  useEffect(() => {
    if (sections?.length > 0) {
      if (!currentSection || !sections.find(s => s.key === currentSection)) {
        setSection(sections[0].key);
      } else if (currentSection && !openSections.find(s => s === currentSection)) {
        setOpenSections([...openSections, currentSection]);
      }
    }
  }, [currentSection, setSection, sections, openSections, setOpenSections]);

  const onSubmit = (values, formApi) => {
    const formState = formApi.getState();

    if (readOnly) {
      dispatch(openSnackbar('Save is not connected in PREVIEW mode.', 'warning'));

      // display a message and embedded url with pre-filled in values
      const newUrl = generateEmbeddedUrl(template, formState, location);
      if (newUrl) {
        dispatch(openSnackbar(`Embed Url: ${newUrl}`, 'info'));
      }
      return;
    }
    if (isUploadingMedia) {
      dispatch(openSnackbar('Please wait for all files to finish uploading.', 'warning'));
      return;
    }
    if (checklistId === 'new') {
      const payload = { template_id: template.id, edit: prepareResults(formState, template, true), ...payloadExtras };
      dispatch(createChecklists(payload));
    } else {
      const updated = { edit: prepareResults(formState, template) };
      dispatch(updateChecklists(checklistId, updated, () => refresh()));
    }
    setRevisionId('latest');
  };

  // Will put revert capability back in later
  /** const handleRevertChange = (event) => {
    setRevert(event.target.checked);
  }; */
  const [override, setOverride] = useState(false);
  const decorators = [];
  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        errors[key] = value;
      }
    }
    return errors;
  };

  // decorators need to be unchanging across renders.
  const rffDecorators = useMemo(
    // decorators are in the form:
    // {
    //   field: <name of field to check>
    //   updates: {
    //     <name of field to update>: (<value of field to check>, <form values>) => { return <updated field value> }
    //   }
    // }
    () => decorators.map(decorator => createDecorator(decorator)),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const checklistSections = sections.map((section, index) => {
    if (openSections.indexOf(section.key) > -1) {
      return (
        <ChecklistPanel
          key={section.key}
          value={section.key}
          currentSection={currentSection}
          index={index}
          section={section}
          defaultActions={defaultActions}
          readOnly={readOnly}
          override={override}
        />
      );
    }
    return null;
  });

  const handleClearClick = event => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClearClose = () => {
    setAnchorEl(null);
  };

  const context = { containerType: isForm ? 'checklist' : 'project', containerId: parentId };

  return (
    <ChecklistContext.Provider value={context}>
      <div className="header">
        <ChecklistToolbar
          title={props.title}
          setRevisionId={setRevisionId}
          checklist={props.checklist}
          refresh={refresh}
          selectedRevision={selectedRevision}
          currentSection={currentSection}
          setSection={setSection}
          openSections={openSections}
          setOpenSections={setOpenSections}
          revisions={revisions}
          backLink={backLink}
        />
      </div>
      <Paper className={classes.root}>
        {!isLatestRevision && (
          <Banner
            alertStatus={'WARN'}
            alert
            alertMessage={
              <>
                Currently editing revision {revisionNumber} of {props.revisions.length}
                <Button
                  variant="outlined"
                  onClick={() => setRevisionId('latest')}
                  style={{ float: 'right', color: 'white' }}>
                  Edit Latest
                </Button>
              </>
            }
            className={classes.bannerCss}
            addingProp
          />
        )}{' '}
        <Divider className="no-margin-top" />
        <div className={classes.mainContent}>
          <GettingStarted checklist={props.checklist} />
        </div>
        <Form
          initialValues={{ ...initialValues }}
          onSubmit={onSubmit}
          decorators={rffDecorators}
          mutators={{ ...arrayMutators, setFieldValue }}
          validate={validate}
          render={({ handleSubmit, form, submitting, pristine, values, errors }) => {
            // clear any checklist errors if form has not yet been modified
            const handleValidateThenSubmit = () => {
              if (!isEmpty(errors)) {
                setOverride(true);
                setChecklistErrorLabels(getChecklistErrorLabels(errors, sections));
              } else {
                handleSubmit();
              }
            };

            return (
              <>
                <form onSubmit={handleValidateThenSubmit} className={classes.mainContent}>
                  {/* <FormControlLabel
            control={<Checkbox checked={revert} onChange={handleRevertChange} name="revert" />}
            label="Revert this revision?"
          /> */}
                  <SectionNav
                    currentSection={currentSection}
                    setSection={setSection}
                    openSections={openSections}
                    setOpenSections={setOpenSections}
                    checklist={props.checklist}
                    submitting={submitting}
                    pristine={pristine}
                    formSubmit={handleValidateThenSubmit}
                    isUploadingMedia={isUploadingMedia}
                  />

                  <Grid container className="add-margin-top-bottom.remove-left-margin" justifyContent="space-between">
                    <Grid item xs={12}>
                      <Error />
                    </Grid>
                    <Grid item xs={12}>
                      {checklistSections}
                    </Grid>

                    {!pristine ? (
                      <>
                        <Grid item>
                          <PrimaryButton
                            label="Save"
                            onClick={handleValidateThenSubmit}
                            disabled={submitting || pristine || isUploadingMedia}
                          />
                        </Grid>
                        <Grid item>
                          <SecondaryButton
                            label="Clear"
                            type="text"
                            disabled={submitting || pristine}
                            onClick={handleClearClick}
                          />
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <ResultsDisplay values={values} />
                  <Menu
                    id="clear-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClearClose}>
                    <MenuItem disabled={true}>Clear Changes?</MenuItem>
                    <MenuItem
                      onClick={() => {
                        form.reset();
                        handleClearClose();
                      }}>
                      Clear
                    </MenuItem>
                  </Menu>
                </form>
                <SubmitWithErrorsDialog
                  isOpen={override}
                  cancelAction={() => setOverride(false)}
                  confirmAction={handleSubmit}
                  errors={checklistErrorLabels}
                />
              </>
            );
          }}
        />
      </Paper>
    </ChecklistContext.Provider>
  );
};
Checklist.propTypes = {
  title: PropTypes.object.isRequired,
  setRevisionId: PropTypes.func.isRequired,
  selectedRevisionId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string, // ('latest', 'new')
  ]),
  revisions: PropTypes.array,
  parentId: PropTypes.string.isRequired,
  checklist: PropTypes.object.isRequired,
  refresh: PropTypes.func.isRequired,
  isForm: PropTypes.bool,
  readOnly: PropTypes.bool,
  backLink: PropTypes.string,
};
Checklist.defaultProps = {
  isForm: false,
  revisions: [],
  selectedRevisionId: 'latest',
  readOnly: false,
};

export default Checklist;
