import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { assetsTreeEndpoint } from '../store/apiV2/assets';
import { getLastFetchAt, setLastFetchAt, shouldFetchState } from './shared';

const treeQuery = { is_active: true };
const localStorageKey = 'lastAssetsTreeFetch';

export const useCachedAssetsTree = (query = treeQuery) => {
  const dispatch = useDispatch();
  const {
    loading,
    data: { results: assets },
    error,
  } = useSelector(state => assetsTreeEndpoint.selector(state));

  const lastFetch = getLastFetchAt(localStorageKey);
  const shouldFetch = shouldFetchState(query, treeQuery, lastFetch);

  useEffect(() => {
    if (shouldFetch) {
      setLastFetchAt(localStorageKey);
      dispatch(assetsTreeEndpoint.actionCreators.request([{ is_active: true }]));
    }
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    assets,
    loading,
    error,
    lastFetch,
  };
};
