import { requestFactory, responseHandlerForm } from '../base';

const get = requestFactory('get');
const post = requestFactory('post', { handler: responseHandlerForm });
const patch = requestFactory('patch', { handler: responseHandlerForm });
const del = requestFactory('del');

export const fetchDefectsList = async query => get('/api/defects/', query);
export const fetchDefects = async id => get(`/api/defects/${id}/`);
export const createDefects = async body => post('/api/defects/', body);
export const updateDefects = async (id, body) => patch(`/api/defects/${id}/`, body);
export const updateFindingsBulk = async (ids, body) => post('/api/defects/bulk-edit/', { ids, edit: body });
export const deleteDefects = async id => del(`/api/defects/${id}/`);
export const linkDefects = async defectIds => post('/api/defects/link/', { defects: defectIds });
