export const PAGES_GETTER_REQUEST = 'PAGES_GETTER_REQUEST';
export const PAGES_GETTER_SUCCESS = 'PAGES_GETTER_SUCCESS';
export const PAGES_GETTER_FAILURE = 'PAGES_GETTER_FAILURE';
export const PAGE_BY_PATH_GETTER_REQUEST = 'PAGE_BY_PATH_GETTER_REQUEST';
export const PAGE_BY_PATH_GETTER_SUCCESS = 'PAGE_BY_PATH_GETTER_SUCCESS';
export const PAGE_BY_PATH_GETTER_FAILURE = 'PAGE_BY_PATH_GETTER_FAILURE';

export const getPages = (query = '') => {
  return {
    type: PAGES_GETTER_REQUEST,
    query,
  };
};

export const getPageByPath = path => {
  return {
    type: PAGE_BY_PATH_GETTER_REQUEST,
    path,
  };
};
