import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ButtonGroup } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '../shared/table/Table';
import MultiSelectFilter from '../shared/table/MultiSelectFilter';
import ButtonIcon from '../shared/buttons/ButtonIcon';
import StyledLink from '../shared/StyledLink';
import ProjectsStatusDisplay, { getStatus, projectStatuses } from './ProjectsStatusDisplay';
import AssetConditionDisplay from '../analytics/assetCondition/AssetConditionDisplay';
import { deleteProjects, deleteProjectsBulk } from '../../store/features/projectsActions';
import { getAllCompanies } from '../../store/features/companiesActions';
import { getAllScheduleEvents } from '../../store/features/scheduleEventsActions';
import { setTableSize, setTableView } from '../../store/settings/tableActions';
import { apiDateToString, compareFilterMapOptions, jsonKeyToLabel } from '../../utilities/strings';
import { openDialog } from '../../store/dialogActions';
import DeleteDialog from '../shared/Dialog/DeleteDialog';
import { useTopLevelAssets } from '../../hooks/assetHooks';
import { useTableRowSelectionManagerOptions } from '../../hooks/tableHooks';
import { useFeatureFlags, usePermissions } from '../../hooks/settingsHooks';
import ChecklistTaskColumn from '../checklists/ChecklistTaskColumn';
import { useCachedProjectTypes } from '../../hooks/projectTypesHooks';
import ProjectTableToolbarSelect from './ProjectTableToolbarSelect';
import Description from '../shared/table/Description';
import TableImportToolbar from '../shared/table/TableImportToolbar';
import { compare, ensureArray, removeDuplicateObjects } from '../../utilities/arrays';
import { conditionLookup, makeAssetConditionsMap } from '../../utilities/assetCondition';
import { setColumns } from '../shared/table/columns';
import { getCustomCSVData, tableViews } from '../../utilities/tables';
import Error from '../shared/displays/Error';
import { ProjectLink } from '../shared/links/InternalLinks';
import Switch from '../shared/buttons/Switch';
import useDateRangeColumnHook from '../../hooks/table/dateRangeColumnHook';
import ViewDisplayKeyValue from '../shared/form/ViewDisplayKeyValue';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const Projects = props => {
  const { title, queryParamObj, tableChangeHandler, createWithAsset, embedded, filename, page, views } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { topLevelAssetOptions, topLevelAssetMap } = useTopLevelAssets();
  const { results: projectTypes, projectTypesMap, projectTypeOptions } = useCachedProjectTypes({ ordering: 'name' });

  useEffect(() => {
    dispatch(getAllCompanies({ role: 'SUBCONTRACTOR' }));
    dispatch(getAllScheduleEvents());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    loading,
    data,
    count,
    error,
    companiesOptions,
    companiesMap,
    assetConditions,
    assetTypeOptions,
    assetTypeMap,
    scheduleEventsOptions,
    scheduleEventsMap,
  } = useSelector(state => {
    const { results: companies } = state.companies.all.dataAll;
    const { results: scheduleEvents } = state.scheduleEvents.all.dataAll;
    const assetConditions = state.settings.features.assetConditions;

    const assetTypes = projectTypes ? removeDuplicateObjects(projectTypes.flatMap(result => result.asset_types)) : [];

    const scheduleEventsMap = {};
    const scheduleEventsOptions = scheduleEvents
      ? scheduleEvents.map(result => {
          const { name, id } = result;
          const value = id.toString();
          scheduleEventsMap[value] = name;
          return value;
        })
      : [];

    const companiesMap = {};
    const companiesOptions = companies
      ? companies.map(result => {
          const { name, id } = result;
          const value = id.toString();
          companiesMap[value] = name;
          return value;
        })
      : [];

    const assetTypeMap = {};
    const assetTypeOptions = assetTypes
      ? assetTypes.map(result => {
          const { name, id } = result;
          const value = id.toString();
          assetTypeMap[value] = name;
          return value;
        })
      : [];
    return {
      loading: state.projects.all.loading,
      data: state.projects.all.dataAll.results,
      count: state.projects.all.dataAll.count,
      error: state.projects.all.error,
      assetTypeOptions,
      assetTypeMap,
      assetConditions,
      companiesOptions,
      companiesMap,
      scheduleEventsOptions,
      scheduleEventsMap,
    };
  });
  const { projectsTableViewKey } = tableViews;
  const assetConditionsMap = makeAssetConditionsMap(assetConditions);
  const assetConditionsOptions = Object.keys(assetConditionsMap);

  const {
    hasAssetView,
    hasCompanyEdit,
    hasCrewEdit,
    hasProjectCreate,
    hasProjectEdit,
    hasProjectDelete,
    hasConfigurationManagementBuild,
  } = usePermissions();
  const {
    hasAssetCondition,
    hasProjectImport,
    hasProjectPriority,
    hasProjectChildren,
    hasProjectLabels,
    hasChecklistTasks,
    hasSchedule,
  } = useFeatureFlags();

  const queryFilters = queryParamObj;

  const taskFilterOptions = ['any', 'open', 'closed'];

  // multiselect filters
  const queryAssetConditionFilters = queryFilters?.asset_condition__in
    ? [].concat(queryFilters.asset_condition__in.split(','))
    : [];
  const queryProjectTypeFilters = queryFilters?.type__in ? [].concat(queryFilters.type__in.split(',')) : [];
  const queryAssetTypeFilters = queryFilters?.asset__type__in ? [].concat(queryFilters.asset__type__in.split(',')) : [];
  const queryStatusFilters = queryFilters?.status ? [].concat(queryFilters.status.split(',')) : [];
  const queryAssetParentInFilters = queryFilters?.descendants ? [].concat(queryFilters.descendants.split(',')) : [];
  const queryManagementCompanyFilter = queryFilters?.management_company__in
    ? [].concat(queryFilters.management_company__in.split(','))
    : [];

  // textfield filters
  const queryProjectNameFilter = queryFilters?.name ? [].concat(queryFilters.name) : [];
  const queryCrewNameFilter = queryFilters?.crews__name ? [].concat(queryFilters.crews__name.split(',')) : [];
  const queryAssetPathFilter = queryFilters?.asset__asset_path_cache__path
    ? [queryFilters.asset__asset_path_cache__path]
    : [];

  // switch filters
  const queryChildProjectsFilter = queryFilters?.parent ? [].concat(queryFilters.parent) : [];

  const [statusFilterList, setStatusFilterList] = useState(queryStatusFilters); // make an array if only one string is supplied
  const [assetConditionFilterList, setAssetConditionFilterList] = useState(queryAssetConditionFilters);
  const [topLevelAssetFilterList, setTopLevelAssetFilterList] = useState(queryAssetParentInFilters);
  const [projectTypeFilterList, setProjectTypeFilterList] = useState(queryProjectTypeFilters);

  const [managementCompanyFilterList, setManagementCompanyFilterList] = useState(queryManagementCompanyFilter);
  const [assetTypeFilterList, setAssetTypeFilterList] = useState(queryAssetTypeFilters);

  const [hideChildProjects, setHideChildProjects] = useState(queryChildProjectsFilter);

  // we may want to disable filters depending on table location
  const enableTopLevelAssetFilter = !embedded && !location?.pathname.includes('/assets/');

  /** @type {Column[]} */
  const columns = [
    {
      // This needs to be first and excluded so customBodyRender can access the id to create the link.
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        filterField: 'name',
        filterType: 'textField',
        filterList: queryProjectNameFilter,
        customBodyRender: (value, tableMeta) => {
          return <StyledLink to={`/projects/${tableMeta.rowData[columns.findIndexByName['id']]}`} value={value} />;
        },
      },
    },
    {
      name: 'parent',
      label: 'Parent',
      options: {
        filter: false,
        sort: true,
        filterType: 'textField',
        display: hasProjectChildren ? true : 'excluded',
        download: hasProjectChildren,
        downloadBody: value => getCustomCSVData('simple', value, 'name'),
        customBodyRender: value => {
          if (!value) return <></>;
          return <ProjectLink {...value} />;
        },
      },
    },
    {
      name: 'priority',
      label: 'Priority',
      options: {
        filter: hasProjectPriority,
        filterType: 'textField',
        sort: true,
        display: hasProjectPriority ? true : 'excluded',
        download: hasProjectPriority,
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        filter: false,
        sort: true,
        sortField: 'type__name',
        downloadBody: value => getCustomCSVData('simple', value),
        customBodyRender: value => {
          if (!value) return <></>;
          if (!hasConfigurationManagementBuild) return <div>{value.name}</div>;
          return <StyledLink to={`/project-types/${value.id}`} value={value.name} />;
        },
      },
    },
    {
      name: 'type__in',
      label: 'Type',
      options: {
        filter: true,
        filterType: 'custom',
        sort: false,
        display: 'excluded',
        download: false,
        filterList: projectTypeFilterList,
        // chips functionality
        customFilterListOptions: {
          render: values => {
            return values.map(v => {
              return projectTypesMap[v];
            });
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            setProjectTypeFilterList(filterList[index]);
            return filterList;
          },
        },
        // filtering menu
        filterOptions: {
          names: projectTypeOptions.map(({ value }) => value), // pull out ids only
          render: v => projectTypesMap[v],
          logic: (type, filters) => {
            if (filters.length) return !filters.includes(type);
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            const formatValue = item => {
              return projectTypesMap[item];
            };
            const getComparable = value => compareFilterMapOptions(value, projectTypesMap);
            // Sort top level filter options
            filterData[index] = filterData[index].sort(compare(getComparable));

            return (
              <MultiSelectFilter
                title="Project Types"
                filterList={filterList}
                localFilterList={projectTypeFilterList}
                onChange={onChange}
                index={index}
                column={column}
                filterData={filterData}
                updateFilters={setProjectTypeFilterList}
                formatValue={formatValue}
              />
            );
          },
        },
      },
    },
    {
      name: 'asset',
      label: 'Asset',
      options: {
        filter: true,
        sort: true,
        sortField: 'asset__asset_path_cache__path',
        filterType: 'textField',
        filterList: queryAssetPathFilter,
        downloadBody: value => getCustomCSVData('simple', value, 'asset_path'),
        customBodyRender: value => {
          if (!value) return <></>;
          if (!hasAssetView) return <>{value.asset_path}</>;
          return <StyledLink to={`/assets/${value.id}`} value={value.asset_path} />;
        },
      },
    },
    {
      name: 'crews',
      label: 'Crews',
      options: {
        filter: true,
        sort: true,
        filterType: 'textField',
        filterList: queryCrewNameFilter,
        sortField: 'crews__name',
        downloadBody: values => getCustomCSVData('array', ensureArray(values)),
        customBodyRender: values => {
          if (!values) return <></>;
          const joinedCrewLinks = values.map((value, index) => {
            return (
              <Fragment key={value.id}>
                {!hasCrewEdit ? <>{value.name}</> : <StyledLink to={`/crews/${value.id}`} value={value.name} />}
                {index === values.length - 1 ? '' : ', '}
              </Fragment>
            );
          });

          return <>{joinedCrewLinks}</>;
        },
      },
    },
    {
      // this column is only for adding a filter in the filter menu.
      name: 'descendants',
      label: 'Top Level Asset',
      options: {
        filter: enableTopLevelAssetFilter,
        sort: false,
        display: 'excluded',
        filterType: 'custom',
        download: false,
        filterList: topLevelAssetFilterList,
        customFilterListOptions: {
          // customize filter display chips
          render: values => {
            return values.map(v => {
              return topLevelAssetMap[v];
            });
          },
          // customize deleting filter display chip
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            setTopLevelAssetFilterList(filterList[index]);
            return filterList;
          },
        },
        filterOptions: {
          names: topLevelAssetOptions,
          // format values in the multiselect input
          render: v => topLevelAssetMap[v],
          logic: (topLevelAsset, filters) => {
            if (filters.length) return !filters.includes(topLevelAsset);
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            const formatValue = item => {
              // format values in the multiselect menu
              return topLevelAssetMap[item];
            };

            const getComparable = value => compareFilterMapOptions(value, topLevelAssetMap);
            // Sort top level filter options
            filterData[index] = filterData[index].sort(compare(getComparable));
            return (
              <MultiSelectFilter
                title="Top Level Asset"
                filterList={filterList}
                localFilterList={topLevelAssetFilterList}
                onChange={onChange}
                index={index}
                column={column}
                filterData={filterData}
                updateFilters={setTopLevelAssetFilterList}
                formatValue={formatValue}
              />
            );
          },
        },
      },
    },
    {
      name: 'asset__type__in',
      label: 'Asset Type',
      options: {
        filter: true,
        filterType: 'custom',
        sort: false,
        display: 'excluded',
        download: false,
        filterList: assetTypeFilterList,
        // chips functionality
        customFilterListOptions: {
          render: values => {
            return values.map(v => {
              return assetTypeMap[v];
            });
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            setAssetTypeFilterList(filterList[index]);
            return filterList;
          },
        },
        // filtering menu
        filterOptions: {
          names: assetTypeOptions,
          render: v => assetTypeMap[v],
          logic: (type, filters) => {
            if (filters.length) return !filters.includes(type);
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            const formatValue = item => {
              return assetTypeMap[item];
            };
            const getComparable = value => compareFilterMapOptions(value, assetTypeMap);
            // Sort top level filter options
            filterData[index] = filterData[index].sort(compare(getComparable));

            return (
              <MultiSelectFilter
                title="Asset Types"
                filterList={filterList}
                localFilterList={assetTypeFilterList}
                onChange={onChange}
                index={index}
                column={column}
                filterData={filterData}
                updateFilters={setAssetTypeFilterList}
                formatValue={formatValue}
              />
            );
          },
        },
      },
    },
    {
      name: 'management_company__in',
      label: 'Management Company',
      options: {
        filter: true,
        sort: false,
        display: 'excluded',
        filterType: 'custom',
        download: false,
        filterList: managementCompanyFilterList,
        customFilterListOptions: {
          render: values => {
            return values.map(v => {
              return companiesMap[v];
            });
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            setManagementCompanyFilterList(filterList[index]);
            return filterList;
          },
        },
        filterOptions: {
          names: companiesOptions,
          render: values => {
            return companiesMap[values];
          },
          logic: (selected, filters) => {
            if (filters.length) return !filters.includes(selected);
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            const formatValue = item => {
              return companiesMap[item];
            };
            const getComparable = value => compareFilterMapOptions(value, companiesMap);
            // Sort management company filter options
            filterData[index] = filterData[index].sort(compare(getComparable));
            return (
              <MultiSelectFilter
                title="Management Company"
                filterList={filterList}
                localFilterList={managementCompanyFilterList}
                onChange={onChange}
                index={index}
                column={column}
                filterData={filterData}
                updateFilters={setManagementCompanyFilterList}
                formatValue={formatValue}
              />
            );
          },
        },
      },
    },
    {
      name: 'management_company',
      label: 'Management Company',
      options: {
        filter: false,
        sort: true,
        sortField: 'management_company__name',
        downloadBody: value => getCustomCSVData('simple', value),
        customBodyRender: value => {
          if (!value) return <></>;
          if (!hasCompanyEdit) return <>{value.name}</>;
          return <StyledLink to={`/companies/${value.id}`} value={value.name} />;
        },
      },
    },
    {
      name: 'labels', // only used for CSV download
      label: 'Labels',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
        download: hasProjectLabels,
        customBodyRender: value => <ViewDisplayKeyValue value={value} />,
        downloadBody: values => getCustomCSVData('labels', values),
      },
    },
    {
      name: 'labels_by_key',
      label: 'Labels',
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => <ViewDisplayKeyValue value={value} />,
        download: false,
        display: hasProjectLabels ? true : 'excluded',
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
        display: 'true',
        filterType: 'custom',
        filterList: statusFilterList,
        customFilterListOptions: {
          render: values => {
            return values.map(v => {
              return <ProjectsStatusDisplay key={v} status={v} />;
            });
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            setStatusFilterList(filterList[index]);
            return filterList;
          },
        },
        customBodyRender: value => {
          return <ProjectsStatusDisplay key={value} status={value} />;
        },
        filterOptions: {
          names: projectStatuses,
          render: v => <ProjectsStatusDisplay status={v} />,
          logic: (status, filters) => {
            if (filters.length) return !filters.includes(status);
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            const formatValue = item => {
              return getStatus(item).label;
            };
            filterData[index] = projectStatuses; // need to maintain order of project statuses
            return (
              <MultiSelectFilter
                title="Status"
                filterList={filterList}
                localFilterList={statusFilterList}
                onChange={onChange}
                index={index}
                column={column}
                filterData={filterData}
                updateFilters={setStatusFilterList}
                formatValue={formatValue}
              />
            );
          },
        },
      },
    },
    {
      name: 'asset_condition',
      label: 'Asset Condition',
      options: {
        filter: false,
        sort: true,
        display: hasAssetCondition ? true : 'excluded',
        customBodyRender: value => {
          return <AssetConditionDisplay condition={value} />;
        },
      },
    },
    {
      name: 'asset_condition__in',
      label: 'Asset Condition',
      options: {
        filter: hasAssetCondition,
        sort: false,
        display: 'excluded',
        filterType: 'custom',
        download: false,
        filterList: assetConditionFilterList,
        // chips functionality
        customFilterListOptions: {
          render: values => {
            return values.map(v => {
              return <AssetConditionDisplay key={v} condition={v} />;
            });
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            setAssetConditionFilterList(filterList[index]);
            return filterList;
          },
        },
        // filtering menu
        filterOptions: {
          name: assetConditionsOptions,
          render: values => {
            return values.map(v => {
              return <AssetConditionDisplay key={v} condition={v} />;
            });
          },
          logic: (selectedAssetCondition, filters) => {
            if (filters.length) return !filters.includes(selectedAssetCondition);
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            const formatValue = item => {
              return conditionLookup(item, assetConditions).description;
            };
            filterData[index] = assetConditionsOptions;
            return (
              <MultiSelectFilter
                title="Asset Conditions"
                filterList={filterList}
                localFilterList={assetConditionFilterList}
                onChange={onChange}
                index={index}
                column={column}
                filterData={filterData}
                updateFilters={setAssetConditionFilterList}
                formatValue={formatValue}
              />
            );
          },
        },
      },
    },
    useDateRangeColumnHook({
      name: 'work_done_on',
      label: 'Work Done On',
      queryParamObj,
      queryStartKey: 'work_done_on_after',
      queryEndKey: 'work_done_on_before',
    }),
    useDateRangeColumnHook({
      name: 'start',
      label: 'Start Date',
      queryParamObj,
      queryStartKey: 'start_after',
      queryEndKey: 'start_before',
      isDate: true,
      options: {
        display: 'true',
      },
    }),
    // Put filter object here to preserve Filter Dialog ordering
    useDateRangeColumnHook({
      name: 'end',
      label: 'End Date',
      queryParamObj,
      queryStartKey: 'end_after',
      queryEndKey: 'end_before',
      isDate: true,
    }),
    {
      name: 'updated_on',
      label: 'Updated',
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => apiDateToString(value, 'date'),
      },
    },
    useDateRangeColumnHook({
      name: 'created_on',
      label: 'Created',
      queryParamObj,
      queryStartKey: 'created_on_after',
      queryEndKey: 'created_on_before',
    }),
    {
      name: 'description',
      label: 'Description',
      options: {
        filter: false,
        sort: true,
        filterType: 'textField',
        customBodyRender: value => {
          if (!value) return <></>;
          return <Description value={value} />;
        },
      },
    },
    {
      name: 'event',
      label: 'Event',
      options: {
        filter: false,
        sort: true,
        sortField: 'event__name',
        display: hasSchedule ? 'true' : 'excluded',
        download: false,
        customBodyRender: value => {
          if (!value) return <></>;
          return <StyledLink to={`/schedule-events/${value.id}`} value={value.name} />;
        },
      },
    },
    {
      name: 'event',
      label: 'Event',
      options: {
        filter: true,
        sort: true,
        filterType: 'dropdown',
        display: 'excluded',
        downloadBody: value => getCustomCSVData('simple', value), // display name in csv
        customFilterListOptions: {
          render: v => scheduleEventsMap[v],
        },
        filterOptions: {
          names: scheduleEventsOptions,
          renderValue: v => scheduleEventsMap[v],
        },
      },
    },
    {
      name: 'event',
      label: 'Event ID',
      options: {
        display: 'excluded',
        filter: false,
        sort: false,
        sortField: 'event__id',
        customBodyRender: value => {
          return value ? value.id : '';
        },
        downloadBody: value => getCustomCSVData('simple', value.toString()),
      },
    },
    {
      name: 'tasks',
      label: 'Tasks',
      options: {
        filter: true,
        sort: false,
        filterType: 'dropdown',
        downloadBody: value => getCustomCSVData('completed', value),
        customFilterListOptions: {
          render: value => jsonKeyToLabel(value),
        },
        filterOptions: {
          names: taskFilterOptions,
          renderValue: value => jsonKeyToLabel(value),
        },
        customBodyRender: value => {
          return <ChecklistTaskColumn tasks={value} />;
        },
      },
    },
    {
      name: 'parent',
      label: 'Hide Child Projects',
      options: {
        filter: hasProjectChildren && page !== 'PROJECTS_DETAIL',
        filterType: 'custom',
        filterList: hideChildProjects,
        filterField: 'parent',
        sort: false,
        display: 'excluded',
        download: false,
        customFilterListOptions: {
          render: v => `Hide Child Projects`,
          update: (filterList, filterPos, index) => {
            filterList[index] = [];
            setHideChildProjects(filterList[index]);
            return filterList;
          },
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            const handleOnChange = bool => {
              if (bool) {
                setHideChildProjects(['none']);
              } else setHideChildProjects([]);

              filterList[index] = bool ? ['none'] : [];
              onChange(filterList[index], index, column);
            };
            return (
              <Switch
                label="Hide Child Projects"
                initialValue={hideChildProjects[0] === 'none'}
                onChange={handleOnChange}
                checked={hideChildProjects[0] === 'none'}
              />
            );
          },
        },
      },
    },

    // Disable for now eventually move this to actions to allow reports to be opened
    // {
    //   name: 'reports',
    //   label: 'Reports',
    //   options: {
    //     filter: false,
    //     sort: false,
    //   },
    // },
    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        print: false,
        viewColumns: false,
        customBodyRender: (_, info) => {
          const id = info.rowData[columns.findIndexByName['id']];
          const name = info.rowData[columns.findIndexByName['name']];
          const isReadOnly = info.rowData[columns.findIndexByName['status']] === 'PUBLISHED';
          return (
            <>
              <ButtonGroup>
                {hasProjectEdit && (
                  <ButtonIcon
                    disabled={isReadOnly}
                    history={history}
                    icon={EditIcon}
                    location={location}
                    to={`/projects/${id}/edit`}
                  />
                )}
                {hasProjectDelete && (
                  <ButtonIcon
                    disabled={isReadOnly}
                    icon={DeleteIcon}
                    onClick={() => {
                      dispatch(
                        openDialog(
                          'Delete Project?',
                          <DeleteDialog id={id} deleteAction={deleteProjects} name={name} displaySnackbar />
                        )
                      );
                    }}
                  />
                )}
              </ButtonGroup>
            </>
          );
        },
      },
    },
  ];
  // columns.findIndexByName = getColumnNames(columns)

  const columnsToExclude = { tasks: !hasChecklistTasks };
  // handle columns display
  setColumns(columns, views, columnsToExclude);

  const { tableOptions } = useTableRowSelectionManagerOptions();

  const getCreateProjectRoute = () => {
    if (createWithAsset) {
      const addRoute = `/projects/new?${createWithAsset}`;
      return addRoute;
    }
    return '/projects/new';
  };
  /** @type {TableOptions} */
  const options = {
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setTableView(changedColumn, action, page, projectsTableViewKey));
    },
    onChangeRowsPerPage: numberOfRows => {
      dispatch(setTableSize(numberOfRows, page, projectsTableViewKey));
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      return (
        <ProjectTableToolbarSelect
          selectedRows={selectedRows.data}
          displayData={displayData}
          deleteAction={deleteProjectsBulk}
          setSelectedRows={setSelectedRows}
          columnIndicesByName={columns.findIndexByName}
        />
      );
    },
    customToolbar: () => {
      if (hasProjectCreate) {
        return (
          <TableImportToolbar
            importAction={'IMPORT_PROJECTS'}
            addRoute={getCreateProjectRoute()}
            featureFlag={hasProjectImport}
          />
        );
      }
      return null;
    },
    enableNestedDataAccess: '.',
    ...tableOptions,
  };

  return (
    <>
      <Error error={error} style={{ textAlign: 'left', paddingLeft: '8px' }} />
      <Table
        title={title}
        serverSide
        columns={columns}
        addRoute={hasProjectCreate && getCreateProjectRoute()}
        data={data}
        count={count}
        options={options}
        loading={loading}
        queryParamObj={queryParamObj}
        tableChangeHandler={tableChangeHandler}
        embedded={embedded}
        filename={filename}
      />
    </>
  );
};

Projects.defaultProps = {
  // queryParamStr: '',
  createWithAsset: '',
  embedded: false,
  filename: null,
  page: undefined,
};

Projects.propTypes = {
  title: PropTypes.string.isRequired,
  queryParamStr: PropTypes.string,
  tableChangeHandler: PropTypes.func.isRequired,
  createWithAsset: PropTypes.string,
  embedded: PropTypes.bool,
  filename: PropTypes.string,
  page: PropTypes.string,
  views: PropTypes.object,
  queryParamObj: PropTypes.object,
};

export default Projects;
