import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import variables from '../../config.module.scss';

/** menu item inside the navigation bar */
const useStyles = makeStyles(theme => ({
  listItem: {
    width: '100%',
  },
  active: {
    /** icon */
    '& div': { color: variables.primary },
    /** text */
    '& span': { color: variables.primary },
  },
}));

const ListItemLink = props => {
  const { icon, primary, to, callback, exact } = props;
  const classes = useStyles();
  const renderLink = useMemo(
    () =>
      forwardRef((linkProps, ref) => {
        return (
          <NavLink exact={exact} activeClassName={classes.active} to={to} {...linkProps} ref={ref} onClick={callback} />
        );
      }),
    [to, callback, classes.active, exact]
  );
  return (
    <li className={classes.listItem}>
      <ListItem button component={renderLink}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
};

ListItemLink.defaultProps = {
  exact: false,
  icon: null,
  primary: '',
  to: '',
  callback: () => {},
};

ListItemLink.propTypes = {
  /** icon shown before the menu label */
  icon: PropTypes.node,
  /** the main content element */
  primary: PropTypes.string,
  /** location to route to */
  to: PropTypes.string,
  callback: PropTypes.func,
  exact: PropTypes.bool,
};

export default ListItemLink;
