import React from 'react';
import PropTypes from 'prop-types';

/**
 * UserDisplay component that renders user.
 */

const UserDisplay = props => {
  const { name, email } = props;
  return <span>{name || email ? `${name ?? ''} (${email ?? ''})` : '--'}</span>;
};

UserDisplay.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default UserDisplay;
