// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import {
  CHECKLIST_REVISIONS_GETTER_REQUEST,
  CHECKLIST_REVISIONS_GETTER_SUCCESS,
  CHECKLIST_REVISIONS_GETTER_FAILURE,
  CHECKLIST_REVISIONS_SET,
} from './revisionsActions';

export const initialStateRevisions = {
  loading: false,
  error: '',
  data: {},
  query: {},
  selected: 'latest',
};

export const revisionsReducer = (state = initialStateRevisions, action) => {
  switch (action.type) {
    case CHECKLIST_REVISIONS_GETTER_REQUEST:
      return {
        ...state,
        loading: true,
        query: action.query,
      };
    case CHECKLIST_REVISIONS_GETTER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case CHECKLIST_REVISIONS_GETTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        data: {},
        query: {},
      };
    case CHECKLIST_REVISIONS_SET:
      return {
        ...state,
        selected: action.id,
      };
    default:
      return state;
  }
};

export default revisionsReducer;
