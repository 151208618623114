// eslint-disable-next-line no-unused-vars
import { buildEndpoint, get, patch, post, del } from './util';

/**
 * Backwards compatibility - combining Read, Create, Update, and Delete to share state
 */
export const EACH_REDUCER = 'assetsEach';

// /**
//  * https://docs.huvrdata.app/reference/api_assets_list
//  */
// export const assetsListEndpoint = buildEndpoint(
//     'assetsList',
//     query => get('/api/assets/', query),
//     {},
// );

// /**
//  * https://docs.huvrdata.app/reference/api_assets_create
//  */
// export const assetsCreateEndpoint = buildEndpoint(
//     'assetsCreate',
//     data => post('/api/assets/', data),
//     {reducerKey: EACH_REDUCER},
// );

// /**
//  * https://docs.huvrdata.app/reference/api_assets_asset_condition_map
//  */
// export const assetsAssetConditionMapEndpoint = buildEndpoint(
//     'assetsAssetConditionMap',
//     () => get('/api/assets/asset-condition-map/'),
//     {},
// );

/**
 * https://docs.huvrdata.app/reference/api_assets_filters
 */
export const assetsFiltersEndpoint = buildEndpoint('assetsFilters', () => get('/api/assets/filters/'), {
  defaultData: {
    asset_condition: [],
    owner: [],
    asset_type: [],
    parent: [],
    labels: [],
  },
});

/**
 * https://docs.huvrdata.app/reference/api_assets_tree
 */
export const assetsTreeEndpoint = buildEndpoint('assetsTree', () => get('/api/assets/tree/'), {
  defaultData: { results: [] },
});

// /**
//  * https://docs.huvrdata.app/reference/api_assets_read
//  */
// export const assetsReadEndpoint = buildEndpoint(
//     'assetsRead',
//     id => get(`/api/assets/${id}/`),
//     {reducerKey: EACH_REDUCER},
// );

// /**
//  * https://docs.huvrdata.app/reference/api_assets_partial_update
//  */
// export const assetsPartialUpdateEndpoint = buildEndpoint(
//     'assetsPartialUpdate',
//     (id, data) => patch(`/api/assets/${id}/`, data),
//     {reducerKey: EACH_REDUCER},
// );

// /**
//  * https://docs.huvrdata.app/reference/api_assets_delete
//  */
// export const assetsDeleteEndpoint = buildEndpoint(
//     'assetsDelete',
//     id => del(`/api/assets/${id}/`),
//     {reducerKey: EACH_REDUCER},
// );
