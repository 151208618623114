import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ButtonGroup, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Table, { SELECTABLE_ROWS_NONE } from '../shared/table/Table';
import { getAllLibraries, deleteLibraries } from '../../store/features/librariesActions';
import { setLibrariesTableView, setLibrariesTableSize } from '../../store/settings/views/librariesTableViewRedux';
import { buildRoute } from '../../utilities/strings';
import ButtonIcon from '../shared/buttons/ButtonIcon';
import StyledLink from '../shared/StyledLink';
import { setColumns } from '../shared/table/columns';
import { useTableViews } from '../../hooks/settingsHooks';
import { tableViews } from '../../utilities/tables';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const LibrariesTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [deleteCalled, setDeleteCalled] = useState(false);
  // change use selector based on type for different library data
  const { loading, deleting, data } = useSelector(state => ({
    loading: state.libraries.all.loading,
    data: state.libraries.all.dataAll.results,
    darkMode: state.settings.palette.darkMode,
  }));
  const { librariesTableViewKey } = tableViews;
  const views = useTableViews(librariesTableViewKey);
  useEffect(() => {
    dispatch(getAllLibraries());
  }, []); // eslint-disable-line

  useEffect(() => {
    if (deleteCalled && !deleting) {
      dispatch(getAllLibraries());
      setDeleteCalled(false);
    }
  }, [deleteCalled, deleting, dispatch]);

  const classes = useStyles();

  /** @type {Column[]} */
  const columns = [
    {
      // This needs to be first and excluded so customBodyRender can access the id to create the link.
      name: 'id',
      label: 'Id',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return <StyledLink to={`/libraries/${tableMeta.rowData[columns.findIndexByName['id']]}`} value={value} />;
        },
      },
    },
    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        print: false,
        customBodyRender: (_, info) => {
          // 0 is the first element in column
          const id = info.rowData[columns.findIndexByName['id']];
          return (
            <>
              <ButtonGroup>
                <ButtonIcon history={history} icon={EditIcon} location={location} to={`/libraries/${id}/edit`} />
                <ButtonIcon
                  icon={DeleteIcon}
                  onClick={() => {
                    dispatch(deleteLibraries(id));
                    setDeleteCalled(true);
                  }}
                />
              </ButtonGroup>
            </>
          );
        },
      },
    },
  ];

  // handle columns display
  setColumns(columns, views);

  /** @type {TableOptions} */
  const options = {
    // if the column view changes, update redux with either 'add' or 'remove'
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setLibrariesTableView(changedColumn, action));
    },
    onChangeRowsPerPage: numberOfRows => {
      dispatch(setLibrariesTableSize(numberOfRows));
    },
    rowsPerPage: views.pageSize,
    enableNestedDataAccess: '.',
    selectableRows: SELECTABLE_ROWS_NONE,
  };
  return (
    <Paper className={classes.root}>
      <Table
        title="Library"
        columns={columns}
        addRoute={buildRoute('/library-media')}
        data={data}
        options={options}
        loading={loading}
      />
    </Paper>
  );
};

LibrariesTable.propTypes = {
  type: PropTypes.string,
};

export default LibrariesTable;
