import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  iframeStyle: {
    width: '100%',
    height: 'calc(100vh - 70px)',
  },
}));

const IFramePage = props => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <iframe
        className={classes.iframeStyle}
        title="Generic Embed"
        src={props.data.source_url}
        data-testid="generic-iframe-element"></iframe>
    </div>
  );
};

IFramePage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default IFramePage;
