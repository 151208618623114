import { FEATURES_SUCCESS, FEATURES_FAILURE, FEATURES_REQUEST, FEATURES_CLEAR } from './featuresActions';

export const initialState = {
  workspace: { permissions: {}, features: {} },
  assetConditions: {},
  isLoaded: false,
};

const toCamelCase = name => {
  const splitName = name.toLowerCase().split('_');
  const namePascal = splitName.map(str => str[0].toUpperCase() + str.slice(1)).join('');
  const nameCamel = namePascal[0].toLowerCase() + namePascal.slice(1);
  return nameCamel;
};

const reshapePermissions = permissions => {
  const permsOut = {};
  try {
    permissions.forEach(perm => {
      permsOut[toCamelCase(`has_${perm}`)] = true;
    });
  } catch (err) {
    console.log('FEATURES_SUCCESS: error reshaping permisssions ' + err);
  }
  return permsOut;
};

const reshapeFeatures = features => {
  const featuresOut = {};
  try {
    features.forEach(value => {
      featuresOut[toCamelCase(`has_${value}`)] = true;
    });
  } catch (err) {
    console.log('FEATURES_SUCCESS: error reshaping features ' + err);
  }
  return featuresOut;
};

const reshapeAssetCondition = conditions => {
  const assetConditions = {};
  try {
    conditions.forEach(condition => {
      assetConditions[condition.value] = condition;
    });
  } catch (err) {
    console.log('FEATURES_SUCCESS: error reshaping assetCondition ' + err);
  }
  return assetConditions;
};

export const featuresReducer = (state = initialState, action) => {
  switch (action.type) {
    case FEATURES_SUCCESS:
      // Reshape the data, the API returns arrays when the data is more naturally used as an object
      return {
        ...state,
        isLoaded: true,
        workspace: {
          ...action.features.workspace,
          features: reshapeFeatures(action.features.workspace.features),
          permissions: reshapePermissions(action.features.workspace.permissions),
        },
        assetConditions: reshapeAssetCondition(action.features.workspace.asset_conditions),
      };
    case FEATURES_REQUEST:
      return {
        ...state,
        isLoaded: false,
      };
    case FEATURES_FAILURE:
    case FEATURES_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default featuresReducer;
