import { all, put, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  SCHEDULEPLAN_REQUEST,
  SCHEDULEPLAN_SUCCESS,
  SCHEDULEPLAN_FAILURE,
  SCHEDULEPLAN_CREATE_REQUEST,
  SCHEDULEPLAN_CREATE_SUCCESS,
  SCHEDULEPLAN_CREATE_FAILURE,
  SCHEDULEPLAN_UPDATE_REQUEST,
  SCHEDULEPLAN_UPDATE_SUCCESS,
  SCHEDULEPLAN_UPDATE_FAILURE,
  SCHEDULEPLAN_DELETE_REQUEST,
  SCHEDULEPLAN_DELETE_SUCCESS,
  SCHEDULEPLAN_DELETE_FAILURE,
  SCHEDULEPLAN_ALL_REQUEST,
  SCHEDULEPLAN_ALL_SUCCESS,
  SCHEDULEPLAN_ALL_FAILURE,
  SCHEDULEPLAN_GENERATE_EVENT_REQUEST,
  SCHEDULEPLAN_GENERATE_EVENT_SUCCESS,
  SCHEDULEPLAN_GENERATE_EVENT_FAILURE,
} from './schedulePlanActions';
import { SCHEDULE_EVENTS_ALL_REQUEST } from './scheduleEventsActions';

import {
  fetchSchedulePlan,
  createSchedulePlan,
  updateSchedulePlan,
  deleteSchedulePlan,
  fetchSchedulePlanList,
  generateSchedulePlanEvent,
} from '../../api/features/schedulePlan';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';
import { fetchListAll } from '../../utilities/sagaHelpers';
import { DIALOG_CLOSE } from '../dialogActions';
import { SNACKBAR_OPEN } from '../snackbarActions';

export const mainRedirectPath = '/schedule-plan';

export function* schedulePlanFetch(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield fetchSchedulePlan(id);
    if (data) {
      yield put({ type: SCHEDULEPLAN_SUCCESS, data });
    } else if (error) {
      yield put({ type: SCHEDULEPLAN_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, SCHEDULEPLAN_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, SCHEDULEPLAN_FAILURE);
  }
}

export function* watchSchedulePlanFetch() {
  yield takeLatest(SCHEDULEPLAN_REQUEST, schedulePlanFetch);
}

export function* schedulePlanCreate(action) {
  const { body } = action;
  try {
    const { data, error, formError, response } = yield createSchedulePlan(body);
    if (data) {
      yield put({ type: SCHEDULEPLAN_CREATE_SUCCESS, data });
      yield put(push(mainRedirectPath));
    } else if (error) {
      yield put({ type: SCHEDULEPLAN_CREATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: SCHEDULEPLAN_CREATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, SCHEDULEPLAN_CREATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, SCHEDULEPLAN_CREATE_FAILURE);
  }
}

export function* watchSchedulePlanCreate() {
  yield takeLatest(SCHEDULEPLAN_CREATE_REQUEST, schedulePlanCreate);
}

export function* schedulePlanUpdate(action) {
  const { id, body } = action;
  try {
    const { data, error, formError, response } = yield updateSchedulePlan(id, body);
    if (data) {
      yield put({ type: SCHEDULEPLAN_UPDATE_SUCCESS, data });
      yield put(push(mainRedirectPath));
    } else if (error) {
      yield put({ type: SCHEDULEPLAN_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: SCHEDULEPLAN_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, SCHEDULEPLAN_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, SCHEDULEPLAN_UPDATE_FAILURE);
  }
}

export function* watchSchedulePlanUpdate() {
  yield takeLatest(SCHEDULEPLAN_UPDATE_REQUEST, schedulePlanUpdate);
}

export function* schedulePlanDelete(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield deleteSchedulePlan(id);
    if (data) {
      yield put({ type: SCHEDULEPLAN_DELETE_SUCCESS });
      yield put({ type: SCHEDULEPLAN_ALL_REQUEST });
      yield put({ type: DIALOG_CLOSE });
      yield put(push(mainRedirectPath));
    } else if (error) {
      yield put({ type: SCHEDULEPLAN_DELETE_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, SCHEDULEPLAN_DELETE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, SCHEDULEPLAN_DELETE_FAILURE);
  }
}

export function* watchSchedulePlanDelete() {
  yield takeLatest(SCHEDULEPLAN_DELETE_REQUEST, schedulePlanDelete);
}

export function* schedulePlanFetchAll(action) {
  try {
    const { data, error, response } = yield fetchListAll(fetchSchedulePlanList, action.query);
    if (data) {
      yield put({ type: SCHEDULEPLAN_ALL_SUCCESS, data });
    } else if (error) {
      yield put({ type: SCHEDULEPLAN_ALL_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, SCHEDULEPLAN_ALL_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, SCHEDULEPLAN_ALL_FAILURE);
  }
}

export function* watchSchedulePlanFetchAll() {
  yield takeLatest(SCHEDULEPLAN_ALL_REQUEST, schedulePlanFetchAll);
}

export function* schedulePlanGenerateEvent(action) {
  const { id, body } = action;
  try {
    const { data, error, formError, response } = yield generateSchedulePlanEvent(id, body);
    if (data) {
      yield put({ type: SCHEDULEPLAN_GENERATE_EVENT_SUCCESS, data });
      yield put({ type: DIALOG_CLOSE });
      const query = yield select(state => state.scheduleEvents.all.query);
      yield put({ type: SCHEDULE_EVENTS_ALL_REQUEST, query });
      const numEvents = data.events.length;
      yield put({
        type: SNACKBAR_OPEN,
        message: `Generated ${numEvents} Events`,
        duration: 5000,
        variant: numEvents === 0 ? 'info' : 'success',
        open: true,
      });
      // Reload the schedule plan to get the new next event
      if (numEvents > 0) {
        yield put({ type: SCHEDULEPLAN_REQUEST, id });
      }
      // yield put(push(mainRedirectPath));
    } else if (error) {
      yield put({ type: SCHEDULEPLAN_GENERATE_EVENT_FAILURE, error });
      yield put({
        type: SNACKBAR_OPEN,
        message: error,
        variant: 'error',
        duration: 5000,
        open: true,
      });
    } else if (formError) {
      yield put({ type: SCHEDULEPLAN_GENERATE_EVENT_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, SCHEDULEPLAN_GENERATE_EVENT_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, SCHEDULEPLAN_GENERATE_EVENT_FAILURE);
  }
}

export function* watchSchedulePlanGenerateEvent() {
  yield takeLatest(SCHEDULEPLAN_GENERATE_EVENT_REQUEST, schedulePlanGenerateEvent);
}

export default function* schedulePlanSaga() {
  yield all([
    watchSchedulePlanFetch(),
    watchSchedulePlanCreate(),
    watchSchedulePlanUpdate(),
    watchSchedulePlanFetchAll(),
    watchSchedulePlanDelete(),
    watchSchedulePlanGenerateEvent(),
  ]);
}
