// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import { combineReducers } from 'redux';
import {
  INSPECTION_MEDIA_REQUEST,
  INSPECTION_MEDIA_SUCCESS,
  INSPECTION_MEDIA_FAILURE,
  INSPECTION_MEDIA_CREATE_REQUEST,
  INSPECTION_MEDIA_CREATE_SUCCESS,
  INSPECTION_MEDIA_CREATE_FAILURE,
  INSPECTION_MEDIA_UPDATE_REQUEST,
  INSPECTION_MEDIA_UPDATE_SUCCESS,
  INSPECTION_MEDIA_UPDATE_FAILURE,
  INSPECTION_MEDIA_DELETE_REQUEST,
  INSPECTION_MEDIA_DELETE_SUCCESS,
  INSPECTION_MEDIA_DELETE_FAILURE,
  INSPECTION_MEDIA_BULK_UPDATE_REQUEST,
  INSPECTION_MEDIA_BULK_UPDATE_SUCCESS,
  INSPECTION_MEDIA_BULK_UPDATE_FAILURE,
  INSPECTION_MEDIA_BULK_DELETE_REQUEST,
  INSPECTION_MEDIA_BULK_DELETE_SUCCESS,
  INSPECTION_MEDIA_BULK_DELETE_FAILURE,
  INSPECTION_MEDIA_OVERLAY_CREATE_REQUEST,
  INSPECTION_MEDIA_OVERLAY_CREATE_SUCCESS,
  INSPECTION_MEDIA_OVERLAY_CREATE_FAILURE,
  INSPECTION_MEDIA_OVERLAY_UPDATE_REQUEST,
  INSPECTION_MEDIA_OVERLAY_UPDATE_SUCCESS,
  INSPECTION_MEDIA_OVERLAY_UPDATE_FAILURE,
  INSPECTION_MEDIA_OVERLAY_DELETE_REQUEST,
  INSPECTION_MEDIA_OVERLAY_DELETE_SUCCESS,
  INSPECTION_MEDIA_OVERLAY_DELETE_FAILURE,
  INSPECTION_MEDIA_ALL_REQUEST,
  INSPECTION_MEDIA_ALL_SUCCESS,
  INSPECTION_MEDIA_ALL_FAILURE,
  INSPECTION_MEDIA_BATCH_ALL_REQUEST,
  INSPECTION_MEDIA_BATCH_ALL_SUCCESS,
  INSPECTION_MEDIA_BATCH_SUCCESS,
  INSPECTION_MEDIA_ALL_UPDATE_DATA,
} from './inspectionMediaActions';
import { mergeNewItemsIntoExisting } from '../../utilities/reducerHelpers';

export const initialStateInspectionMedia = {
  loading: false,
  error: '',
  formError: {},
  id: 0,
  body: {},
  data: {},
};

export const initialStateAllInspectionMedia = {
  loading: false,
  error: '',
  query: {},
  dataAll: { count: -1, next: null, previous: null, results: [] },
};

const _updateDataAll = (state, action) => {
  const dataAll = {
    ...state.dataAll,
    results: mergeNewItemsIntoExisting(state.dataAll.results, action.data),
  };
  dataAll.count = dataAll.results.length;
  return dataAll;
};

export const inspectionMediaReducer = (state = initialStateInspectionMedia, action) => {
  switch (action.type) {
    case INSPECTION_MEDIA_REQUEST:
      return {
        ...initialStateInspectionMedia,
        loading: true,
        id: action.id,
        query: state.query,
      };
    case INSPECTION_MEDIA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case INSPECTION_MEDIA_FAILURE:
      return {
        ...initialStateInspectionMedia,
        error: action.error,
      };
    case INSPECTION_MEDIA_CREATE_REQUEST:
      return {
        ...initialStateInspectionMedia,
        loading: true,
        body: action.body,
      };
    case INSPECTION_MEDIA_OVERLAY_CREATE_REQUEST:
      return {
        ...state,
        id: action.id,
        body: action.body,
      };
    case INSPECTION_MEDIA_OVERLAY_CREATE_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case INSPECTION_MEDIA_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case INSPECTION_MEDIA_CREATE_FAILURE:
    case INSPECTION_MEDIA_OVERLAY_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case INSPECTION_MEDIA_UPDATE_REQUEST:
    case INSPECTION_MEDIA_OVERLAY_UPDATE_REQUEST:
      return {
        ...state,
        error: '',
        formError: {},
        loading: action.id !== state.id,
        id: action.id,
        body: action.body,
        nextRoute: action.nextRoute,
      };
    case INSPECTION_MEDIA_UPDATE_SUCCESS:
    case INSPECTION_MEDIA_OVERLAY_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case INSPECTION_MEDIA_UPDATE_FAILURE:
    case INSPECTION_MEDIA_OVERLAY_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case INSPECTION_MEDIA_DELETE_REQUEST:
    case INSPECTION_MEDIA_OVERLAY_DELETE_REQUEST:
      return {
        ...state,
        error: '',
        formError: {},
        deleting: true,
        id: action.id,
      };
    case INSPECTION_MEDIA_DELETE_SUCCESS:
    case INSPECTION_MEDIA_OVERLAY_DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
      };
    case INSPECTION_MEDIA_DELETE_FAILURE:
    case INSPECTION_MEDIA_OVERLAY_DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        error: action.error,
        formError: action.formError,
      };
    default:
      return state;
  }
};

export const inspectionMediaAllReducer = (state = initialStateAllInspectionMedia, action) => {
  switch (action.type) {
    case INSPECTION_MEDIA_BATCH_SUCCESS:
      return {
        ...state,
        dataAll: {
          ...state.dataAll,
          count: action.data.count,
          next: action.data.next,
          previous: action.data.previous,
          results: state.dataAll.results.concat(action.data.results),
        },
      };
    case INSPECTION_MEDIA_BATCH_ALL_REQUEST:
    case INSPECTION_MEDIA_ALL_REQUEST:
      return {
        ...initialStateAllInspectionMedia,
        loading: true,
        query: action.query,
      };
    case INSPECTION_MEDIA_BATCH_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case INSPECTION_MEDIA_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        dataAll: action.data,
      };
    case INSPECTION_MEDIA_ALL_FAILURE:
      return {
        ...initialStateAllInspectionMedia,
        error: action.error,
      };
    case INSPECTION_MEDIA_ALL_UPDATE_DATA:
      return {
        ...state,
        dataAll: _updateDataAll(state, action),
      };
    case INSPECTION_MEDIA_BULK_UPDATE_REQUEST:
      return {
        ...state,
        error: '',
        loading: true,
        ids: action.ids,
      };
    case INSPECTION_MEDIA_BULK_UPDATE_SUCCESS:
      return {
        ...state,
        dataAll: {
          ...state.dataAll,
          results: mergeNewItemsIntoExisting(state.dataAll.results, action.data),
        },
        error: '',
        loading: false,
      };
    case INSPECTION_MEDIA_BULK_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case INSPECTION_MEDIA_BULK_DELETE_REQUEST:
      return {
        ...state,
        error: '',
        formError: {},
        loading: true,
        ids: action.ids,
      };
    case INSPECTION_MEDIA_BULK_DELETE_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
      };
    case INSPECTION_MEDIA_BULK_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default combineReducers({
  each: inspectionMediaReducer,
  all: inspectionMediaAllReducer,
});
