import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Table, { SELECTABLE_ROWS_NONE } from '../shared/table/Table';
import Description from '../shared/table/Description';
import MultiSelectFilter from '../shared/table/MultiSelectFilter';
import ViewDisplayKeyValue from '../shared/form/ViewDisplayKeyValue';
import AssetConditionDisplay from '../analytics/assetCondition/AssetConditionDisplay';
import { setTableSize, setTableView } from '../../store/settings/tableActions';
import { apiDateToString, compareFilterMapOptions, capitalizeFirstChar } from '../../utilities/strings';
import StyledLink from '../shared/StyledLink';
import { setColumns } from '../shared/table/columns';
import TableImportToolbar from '../shared/table/TableImportToolbar';
import { useTopLevelAssets } from '../../hooks/assetHooks';
import { useCachedAssetTypes } from '../../hooks/assetTypesHooks';
import { useFeatureFlags, usePermissions } from '../../hooks/settingsHooks';
import { compare } from '../../utilities/arrays';
import { conditionLookup, makeAssetConditionsMap } from '../../utilities/assetCondition';
import { getCustomCSVData, tableViews } from '../../utilities/tables';
import AssetActionBar from './AssetActionBar';
import GeoPoint from '../shared/form/GeoPoint';
import GeoPointDisplay from '../shared/form/GeoPointDisplay';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const Assets = props => {
  const { title, tableChangeHandler, createWithAsset, embedded, filename, queryParamObj, page, views } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const { topLevelAssetOptions, topLevelAssetMap, ownerOptions, ownerMap } = useTopLevelAssets();
  const { assetTypeOptions, assetTypeMap } = useCachedAssetTypes();

  // fix
  const { loading, data, count, assetConditions } = useSelector(state => {
    const assetConditions = state.settings.features.assetConditions;
    return {
      loading: state.assets.all.loading,
      data: state.assets.all.dataAll.results,
      count: state.assets.all.dataAll.count,
      assetConditions,
    };
  });
  const { assetsTableViewKey } = tableViews;
  const assetConditionsMap = makeAssetConditionsMap(assetConditions);
  const assetConditionsOptions = Object.keys(assetConditionsMap);

  const [selectedAssetConditionFilter, setSelectedAssetConditionFilter] = useState([]);
  const { hasAssetImport, hasAssetCondition } = useFeatureFlags();
  const { hasAssetCreate, hasConfigurationManagementView, hasCompanyManageAll } = usePermissions();
  const queryFilters = queryParamObj;

  const queryAssetParentInFilters = queryFilters?.descendants ? [].concat(queryFilters.descendants.split(',')) : [];
  const queryOwnerInFilters = queryFilters?.owner__in ? [].concat(queryFilters.owner__in.split(',')) : [];
  const queryAssetTypeFilters = queryFilters?.asset_type__in ? [].concat(queryFilters.asset_type__in.split(',')) : [];
  const [topLevelAssetFilterList, setTopLevelAssetFilterList] = useState(queryAssetParentInFilters);
  const [ownerFilterList, setOwnerFilterList] = useState(queryOwnerInFilters);
  const [assetTypeFilterList, setAssetTypeFilterList] = useState(queryAssetTypeFilters);

  // we may want to disable filters depending on table location
  const enableTopLevelAssetFilter = !embedded && !location?.pathname.includes('/assets/');

  /** @type {Column[]} */
  const columns = [
    {
      // This needs to be first and excluded so customBodyRender can access the id to create the link.
      name: 'id',
      label: 'Id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        filterType: 'textField',
        customBodyRender: (value, tableMeta) => {
          return <StyledLink to={`/assets/${tableMeta.rowData[columns.findIndexByName['id']]}`} value={value} />;
        },
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        filter: false,
        sort: true,
        sortField: 'type__name',
        downloadBody: value => getCustomCSVData('simple', value),
        customBodyRender: value => {
          if (!value) return <></>;
          return hasConfigurationManagementView ? (
            <StyledLink to={`/asset-types/${value.id}`} value={value.name} />
          ) : (
            <>{value.name}</>
          );
        },
      },
    },
    {
      name: 'manufacturer',
      label: 'Manufacturer',
      options: {
        filter: true,
        sort: true,
        filterType: 'textField',
        sortField: 'manufacturer',
        downloadBody: value => getCustomCSVData('simple', value),
        customBodyRender: value => {
          if (!value) return <></>;
          return <>{value}</>;
        },
      },
    },
    {
      name: 'model',
      label: 'Model',
      options: {
        filter: true,
        sort: true,
        filterType: 'textField',
        sortField: 'model',
        downloadBody: value => getCustomCSVData('simple', value),
        customBodyRender: value => {
          if (!value) return <></>;
          return <>{value}</>;
        },
      },
    },
    {
      name: 'asset_type__in',
      label: 'Type',
      options: {
        filter: true,
        filterType: 'custom',
        sort: false,
        display: 'excluded',
        download: false,
        filterList: assetTypeFilterList,
        // chips functionality
        customFilterListOptions: {
          render: values => {
            return values.map(v => {
              return assetTypeMap[v];
            });
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            setAssetTypeFilterList(filterList[index]);
            return filterList;
          },
        },
        // filtering menu
        filterOptions: {
          names: assetTypeOptions,
          render: v => assetTypeMap[v],
          logic: (type, filters) => {
            if (filters.length) return !filters.includes(type);
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            const formatValue = item => {
              return assetTypeMap[item];
            };
            const getComparable = value => compareFilterMapOptions(value, assetTypeMap);
            // Sort top level filter options
            filterData[index] = filterData[index].sort(compare(getComparable));

            return (
              <MultiSelectFilter
                title="Asset Types"
                filterList={filterList}
                localFilterList={assetTypeFilterList}
                onChange={onChange}
                index={index}
                column={column}
                filterData={filterData}
                updateFilters={setAssetTypeFilterList}
                formatValue={formatValue}
              />
            );
          },
        },
      },
    },
    {
      name: 'owner',
      label: 'Owner',
      options: {
        filter: false,
        sort: true,
        filterType: 'textField',
        sortField: 'owner__name',
        downloadBody: value => getCustomCSVData('simple', value),
        customBodyRender: value => {
          if (!value) return <></>;
          return hasCompanyManageAll ? (
            <StyledLink to={`/companies/${value.id}`} value={value.name} />
          ) : (
            <>{value.name}</>
          );
        },
      },
    },
    {
      name: 'owner__in',
      label: 'Owner',
      options: {
        filter: true,
        sort: false,
        display: 'excluded',
        filterType: 'custom',
        download: false,
        filterList: ownerFilterList,
        customFilterListOptions: {
          // customize filter display chips
          render: values => {
            return values.map(v => {
              return ownerMap[v];
            });
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            setOwnerFilterList(filterList[index]);
            return filterList;
          },
        },

        filterOptions: {
          names: ownerOptions,
          logic: (owner, filters) => {
            if (filters.length) return !filters.includes(owner);
            return false;
          },
          // format values in the multiselect input
          render: v => ownerMap[v],
          display: (filterList, onChange, index, column, filterData) => {
            const formatValue = item => {
              // format values in the multiselect menu
              return ownerMap[item];
            };
            const getComparable = value => ownerMap[value].toLowerCase();
            // Sort top level filter options
            filterData[index] = filterData[index].sort(compare(getComparable));

            return (
              <MultiSelectFilter
                title="Owner"
                filterList={filterList}
                localFilterList={ownerFilterList}
                onChange={onChange}
                index={index}
                column={column}
                filterData={filterData}
                updateFilters={setOwnerFilterList}
                formatValue={formatValue}
              />
            );
          },
        },
      },
    },
    {
      name: 'parent',
      label: 'Parent',
      options: {
        filter: false,
        sort: true,
        filterType: 'textField',
        sortField: 'parent__name',
        download: false,
        customBodyRender: value => {
          if (!value) return <></>;
          return <StyledLink to={`/assets/${value.id}`} value={value.name} />;
        },
      },
    },
    {
      // this column is only for adding a filter in the filter menu.
      name: 'descendants',
      label: 'Top Level Asset',
      options: {
        filter: enableTopLevelAssetFilter,
        sort: false,
        display: 'excluded',
        filterType: 'custom',
        download: false,
        filterList: topLevelAssetFilterList,
        customFilterListOptions: {
          // customize filter display chips
          render: values => {
            return values.map(v => {
              return topLevelAssetMap[v];
            });
          },
          // customize deleting filter display chip
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            setTopLevelAssetFilterList(filterList[index]);
            return filterList;
          },
        },
        filterOptions: {
          names: topLevelAssetOptions,
          // format values in the multiselect input
          render: v => topLevelAssetMap[v],
          logic: (topLevelAsset, filters) => {
            if (filters.length) return !filters.includes(topLevelAsset);
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            const formatValue = item => {
              // format values in the multiselect menu
              return topLevelAssetMap[item];
            };

            const getComparable = value => topLevelAssetMap[value].toLowerCase();
            // Sort top level filter options
            filterData[index] = filterData[index].sort(compare(getComparable));

            return (
              <MultiSelectFilter
                title="Top Level Asset"
                filterList={filterList}
                localFilterList={topLevelAssetFilterList}
                onChange={onChange}
                index={index}
                column={column}
                filterData={filterData}
                updateFilters={setTopLevelAssetFilterList}
                formatValue={formatValue}
              />
            );
          },
        },
      },
    },
    {
      name: 'asset_path',
      label: 'Asset Path',
      options: {
        filter: true,
        sort: true,
        filterType: 'textField',
        sortField: 'asset_path_cache__path',
      },
    },
    {
      name: 'asset_path_object',
      label: 'Parent Asset Path',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
        download: true,
        downloadBody: (values, data, columns) => getCustomCSVData('objectArrayToString', values, '', data, columns),
      },
    },
    {
      name: 'project_count',
      label: 'Project Count',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'location',
      label: 'Location',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'geo_point',
      label: 'Geo Point',
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => <GeoPointDisplay geoPoint={value} />,
        downloadBody: value => new GeoPoint(value).toString(),
      },
    },
    {
      name: 'serial_number',
      label: 'Serial Number',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'asset_condition',
      label: 'Asset Condition',
      options: {
        filter: false,
        sort: true,
        display: hasAssetCondition ? true : 'excluded',
        customBodyRender: value => {
          return <AssetConditionDisplay condition={value} />;
        },
      },
    },
    {
      name: 'asset_condition__in',
      label: 'Asset Condition',
      options: {
        filter: hasAssetCondition,
        sort: false,
        filterType: 'custom',
        display: 'excluded',
        download: false,
        filterList: selectedAssetConditionFilter,
        // chips functionality
        customFilterListOptions: {
          render: values => {
            return values.map(v => {
              return <AssetConditionDisplay key={v} condition={v} />;
            });
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            setSelectedAssetConditionFilter(filterList[index]);
            return filterList;
          },
        },
        // filtering menu
        filterOptions: {
          name: assetConditionsOptions,
          render: values => {
            return values.map(v => {
              return <AssetConditionDisplay key={v} condition={v} />;
            });
          },
          logic: (selectedAssetCondition, filters) => {
            if (filters.length) return !filters.includes(selectedAssetCondition);
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            const formatValue = item => {
              // format values in the multiselect menu
              return conditionLookup(item, assetConditions).description;
            };
            filterData[index] = assetConditionsOptions;

            return (
              <MultiSelectFilter
                title="Asset Condition"
                filterList={filterList}
                localFilterList={selectedAssetConditionFilter}
                onChange={onChange}
                index={index}
                column={column}
                filterData={filterData}
                updateFilters={setSelectedAssetConditionFilter}
                formatValue={formatValue}
              />
            );
          },
        },
      },
    },
    {
      name: 'asset_condition_set_by',
      label: 'Asset Condition Set By',
      options: {
        filter: false,
        sort: false,
        display: hasAssetCondition ? true : 'excluded',
        downloadBody: value => getCustomCSVData('simple', value),
        customBodyRender: value => {
          if (value == null) {
            return <></>;
          }
          return <StyledLink to={`/projects/${value.id}/`} value={value.name} />;
        },
      },
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        filter: true,
        sort: true,
        filterType: 'textField',
        customBodyRender: value => {
          if (!value) return <></>;
          return <Description value={value} />;
        },
      },
    },
    {
      name: 'labels',
      label: 'Labels',
      options: {
        filter: false,
        sort: true,
        downloadBody: values => getCustomCSVData('labels', values),
        customBodyRender: value => <ViewDisplayKeyValue value={value} />,
      },
    },
    {
      name: 'app_key',
      label: 'App Key',
      options: {
        display: false, // hidden by default, but can be toggled on
        filter: true,
        sort: true,
        downloadBody: value => getCustomCSVData('simple', value),
      },
    },
    {
      name: 'external_id',
      label: 'External ID',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updated_on',
      label: 'Updated',
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => apiDateToString(value, 'date'),
      },
    },
    {
      name: 'created_on',
      label: 'Created',
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => apiDateToString(value, 'date'),
      },
    },
    {
      name: 'manufactured_on',
      label: 'Manufactured On',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'installed_on',
      label: 'Installed On',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'age',
      label: 'Age',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'is_active',
      label: 'Is Active?',
      options: {
        filter: true,
        sort: true,
        downloadBody: value => getCustomCSVData('simple', value),
        customBodyRender: value => capitalizeFirstChar(String(value)),
        filterOptions: {
          names: ['False', 'True'],
        },
      },
    },
    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        print: false,
        viewColumns: false,
        customBodyRender: (_, info) => {
          // 0 is the first element in column
          const id = info.rowData[columns.findIndexByName['id']];
          const name = info.rowData[columns.findIndexByName['name']];
          return <AssetActionBar id={id} name={name} size="small" />;
        },
      },
    },
  ];

  // handle columns display
  setColumns(columns, views);

  const getCreateAssetRoute = () => {
    if (createWithAsset) {
      const addRoute = `/assets/new?${createWithAsset}`;
      return addRoute;
    }
    return '/assets/new';
  };

  /** @type {TableOptions} */
  const options = {
    // if the column view changes, update redux with either 'add' or 'remove'
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setTableView(changedColumn, action, page, assetsTableViewKey));
    },
    onChangeRowsPerPage: numberOfRows => {
      dispatch(setTableSize(numberOfRows, page, assetsTableViewKey));
    },
    customToolbar: () => {
      if (hasAssetCreate) {
        return (
          <TableImportToolbar
            importAction={'IMPORT_ASSETS'}
            addRoute={getCreateAssetRoute()}
            featureFlag={hasAssetImport}
          />
        );
      }
    },
    enableNestedDataAccess: '.',
    selectableRows: SELECTABLE_ROWS_NONE,
  };

  return (
    <Table
      title={title}
      serverSide
      columns={columns}
      addRoute={hasAssetCreate && getCreateAssetRoute()}
      data={data}
      count={count}
      options={options}
      loading={loading}
      queryParamObj={queryParamObj}
      tableChangeHandler={tableChangeHandler}
      embedded={embedded}
      filename={filename}
    />
  );
};

Assets.defaultProps = {
  queryParamObj: {},
  embedded: false,
  createWithAsset: '',
  filename: null,
};

Assets.propTypes = {
  title: PropTypes.string.isRequired,
  queryParamObj: PropTypes.object,
  tableChangeHandler: PropTypes.func.isRequired,
  createWithAsset: PropTypes.string,
  page: PropTypes.string,
  views: PropTypes.object,
  embedded: PropTypes.bool,
  filename: PropTypes.string,
};

export default Assets;
