// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import React from 'react';
import CommonRoutes from './utilities/CommonRoutes';
import SchedulePlanForm from '../../components/schedulePlan/SchedulePlanForm';
import SchedulePlanDetail from '../../components/schedulePlan/SchedulePlanDetail';
import SchedulePlanTablePage from '../../components/schedulePlan/SchedulePlanTablePage';
import MainLayout from '../layouts/MainLayout';

const SchedulePlanRoutes = props => {
  return (
    <CommonRoutes
      pageTitle="Schedule Plan"
      layout={MainLayout}
      Edit={<SchedulePlanForm update />}
      Create={<SchedulePlanForm />}
      View={<SchedulePlanDetail />}
      All={<SchedulePlanTablePage />}
    />
  );
};

export default SchedulePlanRoutes;
