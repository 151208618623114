import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { FormControl, FormGroup, FormControlLabel, Switch, InputLabel, Select, MenuItem } from '@mui/material';
import PrettyBytes from '../displays/PrettyBytes';

const useStyles = makeStyles(theme => ({
  inputLabel: {
    margin: theme.spacing(-3, 0),
  },
  select: {
    width: 200,
    marginRight: 10,
  },
}));

const SizeRangeFilter = props => {
  const { title, filter, onChange, index, column, update: updateFilter } = props;
  const classes = useStyles();

  const optionValues = [1000000, 10000000, 30000000, 50000000];

  const handleChange = () => {
    const { name, checked } = event.target;

    console.log({ name, checked });

    if (name.match(/greater/i)) {
      const greaterThanValue = checked ? optionValues[0] : undefined;
      filter[0] = greaterThanValue;
    }
    if (name.match(/less/i)) {
      const lessThanValue = checked ? optionValues[0] : undefined;
      filter[1] = lessThanValue;
    }
    onChange(filter, index, column);
    updateFilter(filter);
  };

  return (
    <FormControl>
      <InputLabel shrink className={classes.inputLabel}>
        {title}
      </InputLabel>
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch size="small" checked={!!filter[0]} onChange={handleChange} name="greaterThan" color="primary" />
          }
          label="Greater Than"
        />
        {!!filter[0] && (
          <Select
            disablePortal
            displayEmpty
            className={classes.select}
            defaultValue={optionValues[0]}
            value={filter[0]}
            onChange={event => {
              filter[0] = event.target.value;
              onChange(filter, index, column);
            }}>
            {optionValues.map(item => (
              <MenuItem key={item} value={item}>
                <PrettyBytes num={item} />
              </MenuItem>
            ))}
          </Select>
        )}
        <FormControlLabel
          control={
            <Switch size="small" checked={!!filter[1]} onChange={handleChange} name="lessThan" color="primary" />
          }
          label="Less Than"
        />
        {!!filter[1] && (
          <Select
            disablePortal
            displayEmpty
            className={classes.select}
            defaultValue={optionValues[3]}
            value={filter[1]}
            onChange={event => {
              filter[1] = event.target.value;
              onChange(filter, index, column);
            }}>
            {optionValues.map(item => (
              <MenuItem key={item} value={item}>
                <PrettyBytes num={item} />
              </MenuItem>
            ))}
          </Select>
        )}
      </FormGroup>
    </FormControl>
  );
};

export default SizeRangeFilter;

SizeRangeFilter.propTypes = {
  title: PropTypes.string,
  index: PropTypes.number.isRequired,
  column: PropTypes.object.isRequired,
  filter: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
};
