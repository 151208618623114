// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import CommonRoutes from './utilities/CommonRoutes';
import InspectionMediaForm from '../../components/inspectionMedia/InspectionMediaForm';
import InspectionMediaDetail from '../../components/inspectionMedia/InspectionMediaDetail';
import InspectionMediaTablePage from '../../components/inspectionMedia/InspectionMediaTablePage';
import InspectionMediaAnnotate from '../../components/inspectionMedia/InspectionMediaAnnotate';
import MainLayout from '../layouts/MainLayout';
import FeatureRoute from '../FeatureRoute';

const InspectionMediaRoutes = props => {
  const { path } = useRouteMatch();
  return (
    <CommonRoutes
      pageTitle="Inspection Media"
      layout={MainLayout}
      Edit={<InspectionMediaForm update />}
      Create={<InspectionMediaForm />}
      View={<InspectionMediaDetail />}
      All={<InspectionMediaTablePage />}>
      <FeatureRoute path={`${path}/:id/annotate`} pageTitle="Inspection Media Findings" layout={MainLayout}>
        <InspectionMediaAnnotate />
      </FeatureRoute>
    </CommonRoutes>
  );
};

export default InspectionMediaRoutes;
