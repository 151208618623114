import React from 'react';
import PropTypes from 'prop-types';
import { Toc } from '@mui/icons-material';
import HistoryIcon from '@mui/icons-material/History';
import MapIcon from '@mui/icons-material/Map';
import GradingIcon from '@mui/icons-material/Grading';

import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { Toolbar, Badge, Typography, AppBar } from '@mui/material';
import Button from '@mui/material/Button';
import RevisionDrawer from './RevisionDrawer';
import SectionDrawer from './nav/SectionDrawer';

import RefreshButton from '../shared/buttons/RefreshButton';

const ChecklistToolbar = props => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [revisionCount, setRevisionCount] = React.useState(0);

  const { selectedRevision, setRevisionId, title, checklist, setSecondaryPanelMode } = props;

  const toggleDrawer = (anchor, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  return (
    <AppBar className="app-bar" elevation={1}>
      <Toolbar>
        <Button aria-label="Show Table of Contents" color="inherit" onClick={toggleDrawer('left', true)}>
          <Toc />
        </Button>
        <Typography align="left" variant="h6" noWrap style={{ flex: 1 }}>
          {' '}
          {title} (BETA)
        </Typography>
        <Button aria-label="Display view" color="inherit" onClick={() => setSecondaryPanelMode('compare')}>
          <GradingIcon />
        </Button>
        <Button aria-label="Display view" color="inherit" onClick={() => setSecondaryPanelMode('map')}>
          <MapIcon />
        </Button>
        <Button aria-label="Display view" color="inherit" onClick={() => setSecondaryPanelMode('tasks')}>
          <CheckBoxIcon />
        </Button>
        <Button aria-label="Show Version History" color="inherit" onClick={toggleDrawer('right', true)}>
          <Badge badgeContent={revisionCount} invisible={revisionCount === 1} color="primary">
            <HistoryIcon />
          </Badge>
        </Button>
        <RefreshButton showMenu refresh={props.refresh} />
      </Toolbar>
      {checklist && (
        <RevisionDrawer
          checklistId={checklist?.id}
          selectedRevision={selectedRevision}
          setRevisionId={setRevisionId}
          anchor="right"
          open={state['right']}
          closeDrawer={() => toggleDrawer('right', false)}
          setRevisionCount={setRevisionCount}
        />
      )}
      {checklist && (
        <SectionDrawer
          anchor="left"
          open={state['left']}
          closeDrawer={() => toggleDrawer('left', false)}
          checklist={checklist}
          currentSection={props.currentSection}
          setSection={props.setSection}
        />
      )}
    </AppBar>
  );
};

ChecklistToolbar.propTypes = {
  selectedRevision: PropTypes.object.isRequired,
  revisions: PropTypes.array.isRequired,
  setRevisionId: PropTypes.func.isRequired,
  title: PropTypes.object,
  checklist: PropTypes.object,
  currentSection: PropTypes.string,
  setSection: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  setSecondaryPanelMode: PropTypes.func,
};
ChecklistToolbar.defaultProps = {
  selectedRevision: { id: 'latest' },
  revisions: [],
  title: <></>,
  currentSection: '',
  setSecondaryPanelMode: () => {},
};

export default ChecklistToolbar;
