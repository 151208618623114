import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Alert, Box, Button, Grid, TextField } from '@mui/material';
import { useTheme } from '@mui/styles';

import { isEmpty } from '../../utilities/objects';
import { getChecklistTemplateYAML, importChecklistTemplates } from '../../api/features/checklistTemplates';
import { CHECKLIST_TEMPLATES_CREATE_SUCCESS, clearEachError } from '../../store/features/checklistTemplatesActions';

const ChecklistTemplateYAMLEditor = ({ templateId }) => {
  const [YAMLData, setYAMLData] = useState('');
  const [submitError, setSubmitError] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();

  const backgroundColor =
    theme.palette.mode === 'light' ? theme.palette.background.secondary : theme.palette.background.secondary;

  useEffect(() => {
    const fetchData = async () => {
      const { error, body } = await getChecklistTemplateYAML(templateId);
      if (!error && body) {
        setYAMLData(body);
      } else {
        console.error(error);
      }
    };

    fetchData();
  }, [templateId]);

  useEffect(() => {
    if (submitError) {
      dispatch(clearEachError());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSaveChecklistTemplate = async () => {
    // taken from ChecklistTemplatesForm.js onSubmit to use the same file-based flow but with text
    // also duplicated here to avoid using the form actions as this is not a form
    const formData = new FormData();
    const file = new File([new Blob([YAMLData], { type: 'application/yaml' })], 'checklist-template.yaml');
    formData.append('id', templateId);
    formData.append('action', 'IMPORT');
    formData.append('import_file', file);
    const { data, error } = await importChecklistTemplates(formData);
    if (!error && data) {
      dispatch({ type: CHECKLIST_TEMPLATES_CREATE_SUCCESS, data });
      history.push(`/checklist-templates/${data.checklist.id}`);
    } else {
      setSubmitError(error);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        {!isEmpty(submitError) && <Alert severity="error">{'Error: ' + JSON.stringify(submitError, '', 2)}</Alert>}
      </Grid>
      <Grid item xs={3}>
        <Box display="flex" justifyContent="flex-end">
          <Button variant="contained" color="primary" onClick={handleSaveChecklistTemplate}>
            Save changes
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <TextField
          multiline
          fullWidth
          minRows={5}
          maxRows={30}
          value={YAMLData}
          onChange={event => setYAMLData(event.target.value)}
          style={{ backgroundColor: backgroundColor }}
          InputProps={{
            style: { fontFamily: 'monaco, monospace' },
          }}
        />
      </Grid>
    </Grid>
  );
};

ChecklistTemplateYAMLEditor.propTypes = {
  templateId: PropTypes.string.isRequired,
};

export default ChecklistTemplateYAMLEditor;
