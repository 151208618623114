import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import { default as MuiAutocomplete } from '@mui/material/Autocomplete';
import debounce from 'lodash/debounce';
import { Field } from 'react-final-form';
import makeStyles from '@mui/styles/makeStyles';
import { showError } from './Util';
import { getAllDefects, clearAllDefects } from '../../../store/features/defectsActions';

const useStyles = makeStyles(theme => ({
  // make menu positioning relative
  popperDisablePortal: {
    position: 'relative',
  },
}));

const FieldEditDefectAutocomplete = props => {
  const { name, fieldProps, ...rest } = props;
  return (
    <Field
      name={name}
      render={fieldRenderProps => {
        return <AutocompleteWrapper {...fieldRenderProps} {...fieldProps} {...rest} />;
      }}
    />
  );
};

const AutocompleteWrapper = props => {
  const classes = useStyles();
  const {
    input: { name, onChange, value },
    meta,
    label,
    required,
    valueInitial,
    multiple,
    getOptionValue,
    filterQuery,
    showError,
    onChange: onChangeCallback,
    ...rest
  } = props;
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState(valueInitial ? valueInitial.label : '');
  const [open, setOpen] = useState(false);

  const renderLabel = defect => {
    const label = [];
    [
      'id',
      'component_display',
      'location_zone_display',
      'location_code_display',
      'severity_display',
      'type_display',
      'access_display',
    ].forEach(key => {
      if (defect[key] != null) {
        label.push(defect[key]);
      }
    });
    if (label.length) {
      return label.join(' - ');
    }
    return '---';
  };

  const { loading, options } = useSelector(state => {
    const { loading, dataAll } = state.defects.all;
    const options = dataAll.results
      ? dataAll.results.map(defect => ({ label: renderLabel(defect), value: defect.id.toString() }))
      : [];
    return {
      loading,
      options,
    };
  });

  useEffect(() => {
    setInputValue(valueInitial.label);
  }, [valueInitial.label]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchDefects = useCallback(
    debounce(
      query => {
        dispatch(getAllDefects(query));
      },
      1000,
      { leading: false, trailing: true }
    ),
    []
  );

  function getValue(values) {
    if (!getOptionValue) {
      if (values) {
        return values.value;
      } else {
        return undefined;
      }
    }
    return multiple ? (values ? values.map(getOptionValue) : null) : values ? getOptionValue(values) : null;
  }

  const moreProps = {};
  const { helperText, ...lessrest } = rest;

  let defaultValue;
  if (!getOptionValue) {
    defaultValue = defaultValue || valueInitial?.value;
  } else if (value) {
    options.forEach(option => {
      const optionValue = getOptionValue(option);
      if (multiple) {
        if (!defaultValue) {
          defaultValue = [];
        }
        value.forEach(v => {
          if (v === optionValue) {
            defaultValue.push(option);
          }
        });
      } else {
        if (value === optionValue) {
          defaultValue = option;
        }
      }
    });
  }

  const onInputChangeFunc = (event, value, reason, details) => {
    setInputValue(value);
    const queryParams = { ...filterQuery };
    if (reason === 'input' && value) {
      queryParams.search = value;
      fetchDefects(queryParams);
    }

    if (reason === 'clear') {
      dispatch(clearAllDefects());
      setInputValue(valueInitial ? valueInitial.label : undefined);
      fetchDefects(queryParams);
    } else {
      const gotValue = getValue(value);
      onChange(gotValue);
    }

    if (onChangeCallback) {
      onChangeCallback(event, value, reason, details);
    }
  };

  const onChangeFunc = (event, value, reason) => {
    onChange(value);
  };

  const getPlaceholder = () => {
    if (Array.isArray(options) && options?.length > 0) {
      return 'Type to search for findings.';
    } else {
      return 'No findings available.';
    }
  };

  const { error, submitError } = meta;
  const isError = showError({ meta });
  const placeholder = getPlaceholder();
  return (
    <MuiAutocomplete
      freeSolo
      autoHighlight
      autoSelect
      blurOnSelect
      clearOnBlur
      disablePortal
      selectOnFocus
      loadingText="Loading..."
      classes={classes}
      name={name}
      loading={loading}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={onChangeFunc}
      onInputChange={onInputChangeFunc}
      getOptionLabel={option => option.label || ''}
      getOptionSelected={(option, value) => option === value}
      options={options}
      value={defaultValue}
      inputValue={inputValue}
      {...moreProps}
      renderInput={params => {
        return (
          <TextField
            {...params}
            label={label}
            required={required}
            helperText={isError ? error || submitError : helperText}
            error={isError}
            name={name}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            placeholder={placeholder}
            fullWidth={true}
          />
        );
      }}
      {...lessrest}
    />
  );
};

AutocompleteWrapper.defaultProps = {
  required: false,
  getOptionValue: null,
  valueInitial: null,
  onChange: null,
  filterQuery: {},
};

AutocompleteWrapper.propTypes = {
  /** Equivalent is FieldInputProps in FieldRenderProps */
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }).isRequired,
  /** meta is a FieldMetaState object in FieldRenderProps */
  meta: PropTypes.object.isRequired,
  /** possible values to select from in the shape of: */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  /** filter used for query */
  filterQuery: PropTypes.object,
  /** initial value object for the field */
  valueInitial: PropTypes.object,
  value: PropTypes.string,
  /** Optional multiple */
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  /** label for the autocomplete field */
  label: PropTypes.string.isRequired,
  /** sets the field as required for the form */
  required: PropTypes.bool,
  getOptionValue: PropTypes.func,
  onChangeCallback: PropTypes.func,
  showError: PropTypes.func,
};

FieldEditDefectAutocomplete.defaultProps = {
  valueInitial: undefined,
  required: false,
  disabled: false,
  filterQuery: [],
  data: [],
  children: null,
  multiple: false,
  fieldProps: {},
  onChange: null,
  showError: showError,
};

FieldEditDefectAutocomplete.propTypes = {
  /** the api key for the feature */
  name: PropTypes.string.isRequired,
  /** sets the field as required for the form */
  required: PropTypes.bool,
  /** disables the field while true */
  disabled: PropTypes.bool,
  /** onChange for controlled components */
  onChange: PropTypes.func,
  getOptionValue: PropTypes.func,
  showError: PropTypes.func,
  /** rendering text field */
  renderInput: PropTypes.func,
  data: PropTypes.array,
  children: PropTypes.element,
  helperText: PropTypes.string,
  fieldProps: PropTypes.object,
};

export default FieldEditDefectAutocomplete;
