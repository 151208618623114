import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocation } from 'react-router-dom';
import { Form } from 'react-final-form';
import PrimaryButton from '../../components/shared/buttons/PrimaryButton';
import SecondaryButton from '../../components/shared/buttons/SecondaryButton';
import { closeDialog } from '../../store/dialogActions';
import { updateProjectStatusBulk } from '../../store/features/projectsActions';
import FieldEditString from '../shared/form/FieldEditString';
import FieldEditSelect from '../shared/form/FieldEditSelect';
import ProjectsStatusDisplay from './ProjectsStatusDisplay';
import { usePermissions } from '../../hooks/settingsHooks';
import { PROJECT_STATUS } from '../../api/features/constants';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    height: '100%',
    textAlign: 'left',
  },
  form: {
    margin: theme.spacing(1),
    height: '90%',
  },
  buttonGroup: {
    marginTop: theme.spacing(2),
  },
}));

const ProjectStatusBulkForm = props => {
  const { ids } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const { formError } = useSelector(state => state.projects.all);
  const { hasProjectStatusPublish } = usePermissions();
  const [submittedValues, setSubmittedValues] = useState({});

  const handleCloseDialog = () => {
    dispatch(closeDialog());
  };

  const handleDialogSubmit = values => {
    setSubmittedValues(values);
    dispatch(updateProjectStatusBulk(ids, values, location.pathname));
  };

  const validate = values => {
    const errors = {};
    if (formError) {
      
    for (const [key, value] of Object.entries(formError)) {
      if (values[key] === submittedValues[key]) {
        errors[key] = value;
      } else {
        errors[key] = undefined;
      }
    }
  }
    return errors;
  };

  const options = [
    { label: <ProjectsStatusDisplay status={PROJECT_STATUS.INITIALIZED} />, value: 'INITIALIZED' },
    { label: <ProjectsStatusDisplay status={PROJECT_STATUS.ASSIGNED} />, value: 'ASSIGNED' },
    { label: <ProjectsStatusDisplay status={PROJECT_STATUS.IN_PROGRESS} />, value: 'IN_PROGRESS' },
    { label: <ProjectsStatusDisplay status={PROJECT_STATUS.READY_FOR_REVIEW} />, value: 'READY_FOR_REVIEW' },
  ];
  if (hasProjectStatusPublish) {
    options.push({ label: <ProjectsStatusDisplay status={PROJECT_STATUS.PUBLISHED} />, value: 'PUBLISHED' });
  }

  return (
    <Form
      onSubmit={handleDialogSubmit}
      validate={validate}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Grid container direction="column" justifyContent="space-between" className={classes.formControl}>
              <Grid item>
                <Grid container spacing={2} direction="column">
                  <Grid item>
                    <FieldEditSelect
                      label="Status"
                      name="status"
                      options={options}
                      labelwidth={40}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getcontentanchorel: null,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <FieldEditString label="Notes" multiline name="note" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid className={classes.buttonGroup} item>
                <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
                  <Grid item xs={2}>
                    <SecondaryButton label="Cancel" onClick={handleCloseDialog}></SecondaryButton>
                  </Grid>
                  <Grid item xs={2}>
                    <PrimaryButton type="submit" label="Submit">
                      Submit
                    </PrimaryButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};

ProjectStatusBulkForm.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default ProjectStatusBulkForm;
