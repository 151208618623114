/**
  -- Project Overview (Summary, Reports, Tasks, Details)
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import ProjectSummary from './ProjectSummary';
import ProjectsTable from './ProjectsTable';
import ProjectsView from './ProjectsView';
import ChecklistTasksTable from '../checklistTasks/ChecklistTasksTable';
import ReportsTable from '../shared/ReportsTable';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 3, 4, 3),
  },
  embeddedTableStyle: {
    margin: theme.spacing(2, 4),
  },
  projectsTable: {
    margin: theme.spacing(0, 4, 5),
  },
}));

const ProjectOverview = props => {
  const {
    id,
    data,
    loading,
    error,
    refresh,
    overviewPermissions: { displayProjectChildren, hasChecklistEdit, isReadOnly, trackTasks },
    projectTableProps: { projectTableChangeHandler, storedProjectsQuery, parentIdQuery, projectsPage, projectsView },
  } = props;
  const classes = useStyles();
  const isPublished = data.status === 'PUBLISHED';
  return (
    <Grid container direction="row" alignItems="stretch" className={classes.root}>
      {hasChecklistEdit && (
        <Grid item xs={12}>
          <ProjectSummary project={data} isReadOnly={isReadOnly} />
        </Grid>
      )}
      <Grid item xs={12}>
        <ReportsTable project={data.id} isPublished={isPublished} report_types={data.report_types} refresh={refresh} />
      </Grid>
      {displayProjectChildren && (
        <Grid item xs={12}>
          <div className={classes.projectsTable}>
            <ProjectsTable
              title="Projects"
              tableChangeHandler={projectTableChangeHandler}
              queryParamObj={storedProjectsQuery}
              createWithAsset={parentIdQuery}
              page={projectsPage}
              views={projectsView}
              embedded
              filename={`${data.name} Project Children`}
            />
          </div>
        </Grid>
      )}
      {trackTasks && (
        <Grid item xs={12}>
          <ChecklistTasksTable
            embedded
            title={'Tasks'}
            styleProjectDetail={classes.embeddedTableStyle}
            isReadOnly={isReadOnly}
            filename={`${data.name} Tasks`}
            projectId={data.id}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <ProjectsView id={id} data={data} loading={loading} error={error} />
      </Grid>
    </Grid>
  );
};

ProjectOverview.defaultProps = {};
ProjectOverview.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  overviewPermissions: PropTypes.object.isRequired,
  projectTableProps: PropTypes.object.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default ProjectOverview;
