import { requestFactory, responseHandlerForm } from '../base';

const get = requestFactory('get');
const post = requestFactory('post', { handler: responseHandlerForm });
const patch = requestFactory('patch', { handler: responseHandlerForm });
const del = requestFactory('del');
const bulkDel = requestFactory('bulkDel');

export const fetchProjectsList = async query => get('/api/projects/', query);
export const fetchProjects = async id => get(`/api/projects/${id}/`);
export const fetchProjectCountsByStatus = async query => get('/api/projects/counts-by-status/', query);
export const createProjects = async body => post('/api/projects/', body);
export const updateProjects = async (id, body) => patch(`/api/projects/${id}/`, body);
export const updateProjectsBulk = async (ids, body) => post('/api/projects/bulk-edit/', { ids, edit: body });
export const updateProjectStatus = async (id, body) => post(`/api/projects/${id}/status/`, body);
export const updateProjectStatusBulk = async (ids, body) => post(`/api/projects/status/`, { ids, ...body });
export const updateAssetCondition = async (id, body) => post(`/api/projects/${id}/asset-condition/`, body);
export const updateProjectShare = async (id, body) => post(`/api/projects/${id}/watch/`, body);
export const deleteProjects = async id => del(`/api/projects/${id}/`);
export const deleteProjectsBulk = async ids => bulkDel(`/api/projects/bulk-delete/`, ids);
