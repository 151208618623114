import { queriesFromString, toQueryString } from './strings';

export const locationWithBack = ({ pathname, search, hash }, currentLocation) => {
  const newLocation = {
    pathname,
    search: search || '',
    hash: hash || '',
  };
  if (currentLocation) {
    newLocation.state = {
      back: {
        ...currentLocation,
        key: '',
      },
    };
  }
  return newLocation;
};

export const historyPushWithBack = (pathname, history, location) => {
  history.push(locationWithBack({ pathname }, location));
};

export const handleBack = (history, location) => {
  if (location.state && location.state.back) {
    history.push(location.state.back);
  } else {
    const next = location.pathname?.match(/(.*?)\/\w+$/)[1];
    history.push(next);
  }
};
export const getRoute = (location = {}, defaultRoute, query, forceDefaultRoute) => {
  if (!forceDefaultRoute && location.state && location.state.back) {
    const pathname = location.state.back.pathname;
    const generatedLocation = query ? pathname.concat(toQueryString(query)) : pathname;
    return generatedLocation;
  }
  return defaultRoute;
};

export const getParams = (location, query) => {
  if (location.search.length) {
    return queriesFromString(location.search, [], true); // returns query object without arrays
  }
  return query;
};
