import React from 'react';
import PropTypes from 'prop-types';
import { fieldOrder, removeField, hideField } from './assetsShared';
import { apiDateToString, jsonKeyToLabel, capitalizeFirstChar } from '../../utilities/strings';
import CommonFormView from '../shared/form/CommonView';
import StyledLink from '../shared/StyledLink';
import { useFeatureFlags } from '../../hooks/settingsHooks';

const AssetsView = props => {
  const { id, data, loading, error } = props;
  const { hasAssetCondition } = useFeatureFlags();
  const formSettings = {
    fieldOrder,
    removeField: removeField('VIEW'),
    hideField: (values, item) => {
      return hideField(values, item, hasAssetCondition);
    },
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      // cast to string because booleans do not show up in the field
      let displayLabel = jsonKeyToLabel(key); // eslint-disable-line  prefer-const
      let displayValue = '';
      if (data[key] != null) {
        switch (key) {
          // add cases for each key that needs to be customized.
          case 'description':
            fieldSettings[key] = {
              type: 'text',
              cellProps: {
                xs: 12,
              },
              fieldProps: {
                label: displayLabel,
                value: data[key],
              },
            };
            return;
          case 'type':
          case 'owner':
          case 'parent':
            displayValue = data[key].name;
            break;
          case 'geo_point':
            fieldSettings[key] = {
              type: 'geo',
              cellProps: {
                md: 6,
                xs: 12,
              },
              fieldProps: {
                label: displayLabel,
                value: data[key],
              },
            };
            return;
          case 'updated_on':
          case 'created_on':
          case 'deactivated_on':
            displayValue = apiDateToString(data[key], 'date');
            break;
          case 'asset_condition':
            fieldSettings[key] = {
              type: 'asset-condition',
              cellProps: {
                xs: 6,
              },
              fieldProps: {
                label: displayLabel,
                value: data[key],
              },
            };
            return;
          case 'asset_condition_set_by':
            fieldSettings[key] = {
              type: 'component',
              cellProps: {
                xs: 6,
              },
              fieldProps: {
                label: displayLabel,
                value: () => <StyledLink to={`/projects/${data[key].id}/`} value={data[key].name} />,
              },
            };
            return;
          case 'labels':
            fieldSettings[key] = {
              type: 'key-value',
              cellProps: {
                xs: 12,
              },
              fieldProps: {
                label: displayLabel,
                value: data[key],
              },
            };
            return;
          case 'is_active':
            displayValue = capitalizeFirstChar(String(data[key]));
            break;
          case 'external_id':
          case 'location':
          default:
            displayValue = data[key].toString();
        }
      }
      fieldSettings[key] = {
        type: 'text',
        cellProps: {
          xs: 6,
        },
        fieldProps: {
          label: displayLabel,
          value: displayValue,
        },
      };
    });
  }

  return (
    <CommonFormView
      bodyOnly
      title={'Asset Details'}
      values={data}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      editLink={`/assets/${id}/edit`}
      loading={loading}
      error={error}
    />
  );
};

AssetsView.defaultProps = {
  id: undefined,
  data: {},
  loading: false,
  error: '',
};

AssetsView.propTypes = {
  id: PropTypes.number,
  data: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
};

export default AssetsView;
