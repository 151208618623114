import React from 'react';
import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
// import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(7, '3vw'),
    padding: theme.spacing(2),
  },
}));

const History = props => {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <h1>History</h1>
    </Paper>
  );
};

// History.propTypes = {

// };

export default History;
