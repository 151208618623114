// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import { combineReducers } from 'redux';
import {
  BULK_IMPORTS_REQUEST,
  BULK_IMPORTS_SUCCESS,
  BULK_IMPORTS_FAILURE,
  BULK_IMPORTS_CREATE_REQUEST,
  BULK_IMPORTS_CREATE_SUCCESS,
  BULK_IMPORTS_CREATE_FAILURE,
  BULK_IMPORTS_UPDATE_REQUEST,
  BULK_IMPORTS_UPDATE_SUCCESS,
  BULK_IMPORTS_UPDATE_FAILURE,
  BULK_IMPORTS_DELETE_REQUEST,
  BULK_IMPORTS_DELETE_SUCCESS,
  BULK_IMPORTS_DELETE_FAILURE,
  BULK_IMPORTS_ALL_REQUEST,
  BULK_IMPORTS_ALL_SUCCESS,
  BULK_IMPORTS_ALL_FAILURE,
} from './bulkImportsActions';

export const initialStateBulkImports = {
  loading: false,
  error: '',
  formError: {},
  id: 0,
  body: {},
  data: {},
};

export const initialStateAllBulkImports = {
  loading: false,
  error: '',
  query: {},
  count: 0,
  dataAll: {},
};

export const resetStateBulkImports = {
  loading: false,
  error: '',
  formError: {},
  data: {},
};
export const resetStateAllBulkImports = {
  loading: false,
  error: '',
  query: {},
  dataAll: [],
};

export const bulkImportsEachReducer = (state = initialStateBulkImports, action) => {
  switch (action.type) {
    case BULK_IMPORTS_REQUEST:
      return {
        ...initialStateBulkImports,
        loading: true,
        id: action.id,
      };
    case BULK_IMPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case BULK_IMPORTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case BULK_IMPORTS_CREATE_REQUEST:
      return {
        ...initialStateBulkImports,
        loading: true,
        body: action.body,
      };
    case BULK_IMPORTS_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case BULK_IMPORTS_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case BULK_IMPORTS_UPDATE_REQUEST:
      return {
        ...initialStateBulkImports,
        loading: true,
        id: action.id,
        body: action.body,
      };
    case BULK_IMPORTS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case BULK_IMPORTS_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case BULK_IMPORTS_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        formError: {},
        id: action.id,
      };
    case BULK_IMPORTS_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case BULK_IMPORTS_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    default:
      return state;
  }
};

export const bulkImportsAllReducer = (state = initialStateAllBulkImports, action) => {
  switch (action.type) {
    case BULK_IMPORTS_ALL_REQUEST:
      return {
        ...initialStateAllBulkImports,
        loading: true,
        query: action.query,
      };
    case BULK_IMPORTS_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        dataAll: action.data,
      };
    case BULK_IMPORTS_ALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default combineReducers({
  each: bulkImportsEachReducer,
  all: bulkImportsAllReducer,
});
