import React, { useEffect, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { ButtonGroup, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import MailIcon from '@mui/icons-material/Mail';
import BlockIcon from '@mui/icons-material/Block';
import PersonIcon from '@mui/icons-material/Person';
import ButtonIcon from '../shared/buttons/ButtonIcon';
import { openSnackbar } from '../../store/snackbarActions';
import Table, { SELECTABLE_ROWS_NONE } from '../shared/table/Table';
import { updateUsers } from '../../store/features/usersActions';
import { rolesOptions } from '../../api/features/constants';
import { getAllCompanies } from '../../store/features/companiesActions';
import { setUsersTableView, setUsersTableSize } from '../../store/settings/views/usersTableViewRedux';
import { displayRole } from './usersShared';
import StyledLink from '../shared/StyledLink';
import { setColumns } from '../shared/table/columns';
import { openDialog } from '../../store/dialogActions';
import ConfirmDialog from '../shared/Dialog/ConfirmDialog';
import { usePermissions, useTableViews, useFeatureFlags } from '../../hooks/settingsHooks';
import { useTableRowSelectionManagerOptions } from '../../hooks/tableHooks';
import { getCustomCSVData, tableViews } from '../../utilities/tables';
import { compare, ensureArray } from '../../utilities/arrays';
import { compareFilterMapOptions } from '../../utilities/strings';
import UsersToolbarSelect from './UsersToolbarSelect';
import UsersImportToolbar from '../shared/table/UsersImportToolbar';
import useDateRangeColumnHook from '../../hooks/table/dateRangeColumnHook';
import MultiSelectFilter from '../shared/table/MultiSelectFilter';
import ViewDisplayChips from '../shared/form/ViewDisplayChips';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const Users = props => {
  const { title, company, queryParamObj, tableChangeHandler } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { hasUsersAssignCompany, hasUsersBulkCreate } = useFeatureFlags();
  const queryFilters = queryParamObj;

  const queryCompanyFilters = queryFilters?.company ? [].concat(queryFilters.company.split(',')) : [];
  const [companyFilterList, setCompanyFilterList] = useState(queryCompanyFilters);

  const { loading, data, count, companiesMap, companiesOptions } = useSelector(state => {
    const loading = state.users.all.loading;
    const data = state.users.all.dataAll.results;

    const count = state.users.all.dataAll.count;
    const { results: companies } = state.companies.all.dataAll;
    const companiesMap = {};
    /* eslint-disable no-unused-vars */
    const companiesOptions = companies
      ? companies.map(result => {
          const { name, id } = result;
          const value = id.toString();
          companiesMap[value] = name;
          return value;
        })
      : [];
    /* eslint-enable no-unused-vars */
    return {
      count,
      data,
      loading,
      companies,
      companiesMap,
      companiesOptions,
    };
  });
  const { usersTableViewKey } = tableViews;
  const views = useTableViews(usersTableViewKey);
  useEffect(() => {
    dispatch(getAllCompanies());
  }, []); // eslint-disable-line

  const { hasUserCreate, hasUserEdit, hasUserDelete } = usePermissions();

  const confirmAction = (id, value) => {
    const updatedValues = { id, is_active: value };
    dispatch(updateUsers(id, updatedValues, true));
  };

  const sendPasswordReset = email => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        dispatch(openSnackbar('Password reset email sent', 'success', null, 2000));
      })
      .catch(error => {
        console.error(error);
        dispatch(openSnackbar('Error sending password reset email', 'error', null, 2000));
      });
  };

  /** @type {Column[]} */
  const columns = [
    {
      // This needs to be first and excluded so customBodyRender can access the id to create the link.
      name: 'id',
      label: 'Id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        filterType: 'textField',
        customBodyRender: (value, tableMeta) => {
          return <StyledLink to={`/users/${tableMeta.rowData[columns.findIndexByName['id']]}`} value={value} />;
        },
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: true,
        filterType: 'textField',
      },
    },
    {
      name: 'first_name',
      label: 'First Name',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'last_name',
      label: 'Last Name',
      options: {
        filter: false,
        sort: true,
      },
    },
    useDateRangeColumnHook({
      name: 'last_login',
      label: 'Last Login',
      queryParamObj,
      queryStartKey: 'last_login_after',
      queryEndKey: 'last_login_before',
    }),
    {
      name: 'is_active',
      label: 'Is Active',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => (value ? 'True' : 'False'),
      },
    },
    {
      name: 'company',
      label: 'Company',
      options: {
        filter: false,
        sort: true,
        sortField: 'company__name',
        customBodyRender: values => <ViewDisplayChips value={values} link="/companies" />,
        downloadBody: values => getCustomCSVData('array', ensureArray(values)),
      },
    },
    {
      name: 'company',
      label: 'Company',
      options: {
        filter: true,
        sort: false,
        display: 'excluded',
        filterType: 'custom',
        filterList: companyFilterList,
        customFilterListOptions: {
          render: values => {
            return values.map(v => {
              return companiesMap[v];
            });
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            setCompanyFilterList(filterList[index]);
            return filterList;
          },
        },
        filterOptions: {
          names: companiesOptions,
          render: v => companiesMap[v],
          logic: (selected, filters) => {
            if (filters.length) return !filters.includes(selected);
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            const formatValue = item => {
              return companiesMap[item];
            };
            const getComparable = value => compareFilterMapOptions(value, companiesMap);
            // Sort company filter options
            filterData[index] = filterData[index].sort(compare(getComparable));
            return (
              <MultiSelectFilter
                title="Companies"
                filterList={filterList}
                localFilterList={companyFilterList}
                onChange={onChange}
                index={index}
                column={column}
                filterData={filterData}
                updateFilters={setCompanyFilterList}
                formatValue={formatValue}
              />
            );
          },
        },
      },
    },
    {
      name: 'roles',
      label: 'Role',
      options: {
        filter: true,
        sort: false,
        downloadBody: values => getCustomCSVData('array', ensureArray(values)),
        customBodyRender: values => {
          if (!values) return <></>;
          const modifiedValues = values.map(value => displayRole(value));
          return <ViewDisplayChips value={modifiedValues} />;
        },
        customFilterListOptions: {
          render: value => displayRole(value),
        },
        filterOptions: {
          names: rolesOptions.map(r => r.value),
          renderValue: value => displayRole(value),
        },
      },
    },
    useDateRangeColumnHook({
      name: 'date_joined',
      label: 'Date Joined',
      queryParamObj,
      queryStartKey: 'date_joined_after',
      queryEndKey: 'date_joined_before',
    }),
    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        print: false,
        viewColumns: false,
        customBodyRender: (_, info) => {
          const id = info.rowData[columns.findIndexByName['id']];
          const name = info.rowData[columns.findIndexByName['name']];
          const isActive = info.rowData[columns.findIndexByName['is_active']];
          const email = info.rowData[columns.findIndexByName['email']];

          return (
            <>
              <ButtonGroup>
                {hasUserEdit && (
                  <ButtonIcon history={history} icon={EditIcon} location={location} to={`/users/${id}/edit`} />
                )}
                {hasUserDelete && (
                  <ButtonIcon
                    icon={isActive ? BlockIcon : PersonIcon}
                    onClick={() => {
                      dispatch(
                        openDialog(
                          `${isActive ? 'Suspend' : 'Unsuspend'} ${name}?`,
                          <ConfirmDialog
                            id={id}
                            confirmAction={() => {
                              confirmAction(id, !isActive);
                            }}
                            name={name}
                          />
                        )
                      );
                    }}
                  />
                )}
                {hasUserCreate && (
                  <Tooltip title="Send Password Reset Email">
                    <ButtonIcon
                      icon={MailIcon}
                      onClick={() => {
                        sendPasswordReset(email);
                      }}
                    />
                  </Tooltip>
                )}
              </ButtonGroup>
            </>
          );
        },
      },
    },
  ];

  // handle columns display
  setColumns(columns, views);

  const { tableOptions, clearSelections } = useTableRowSelectionManagerOptions();

  const newRoute = company ? `/users/new?company=${company}` : '/users/new';

  /** @type {TableOptions} */
  const options = {
    // if the column view changes, update redux with either 'add' or 'remove'
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setUsersTableView(changedColumn, action));
    },
    onChangeRowsPerPage: numberOfRows => {
      dispatch(setUsersTableSize(numberOfRows));
    },
    customToolbar: () => {
      return <UsersImportToolbar addRoute={newRoute} featureFlag={hasUsersBulkCreate} />;
    },
    customToolbarSelect: (selectedRows, displayData) => {
      return (
        <UsersToolbarSelect
          selectedRows={selectedRows}
          displayData={displayData}
          columns={columns}
          clearSelections={clearSelections}
        />
      );
    },
    enableNestedDataAccess: '.',
    selectableRows: hasUsersAssignCompany ? 'multiple' : SELECTABLE_ROWS_NONE,
    ...tableOptions,
  };

  return (
    <Table
      serverSide
      title={title}
      columns={columns}
      addRoute={hasUserCreate && newRoute}
      data={data}
      count={count}
      options={options}
      loading={loading}
      queryParamObj={queryParamObj}
      tableChangeHandler={tableChangeHandler}
    />
  );
};

Users.defaultProps = {
  queryParamObj: {},
};

Users.propTypes = {
  title: PropTypes.string.isRequired,
  company: PropTypes.number,
  queryParamObj: PropTypes.object,
  tableChangeHandler: PropTypes.func.isRequired,
};

export default Users;
