
export const SET_ASSETS_CONDITION_TABLE_VIEW = 'SET_ASSETS_CONDITION_TABLE_VIEW';

export const setAssetConditionTableView = (column, action) => {
  return {
    type: SET_ASSETS_CONDITION_TABLE_VIEW,
    column,
    action,
  };
};

export const assetConditionTableViewReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_ASSETS_CONDITION_TABLE_VIEW:
      return {
        ...state,
        [action.column]: action.action,
      };
    default:
      return state;
  }
};

