import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid, Typography, Box, Badge } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { TreeView, TreeItem } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { getProjectSummary } from '../../store/getters/projectSummaryActions';
import ButtonIcon from '../shared/buttons/ButtonIcon';
import { isEmpty } from '../../utilities/objects';
import Loading from '../shared/displays/Loading';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  treeItem: {
    width: '100%',
    textAlign: 'left',
  },
  treeLabel: {
    flexGrow: 1,
  },
  header: {
    paddingLeft: theme.spacing(1),
  },
  count: {
    borderRadius: 50,
  },
  zeroState: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0, 0, 0),
  },
}));

const StyledBadge = withStyles(theme => ({
  badge: {
    right: 11,
    top: 11,
    padding: '0 4px',
    backgroundColor: '#d5d5dc', // variables.grey  TODO: figure out why it won't allow me to import config.modules.scss
  },
}))(Badge);

const InspectorSummary = props => {
  const { projectId, setShowSummary } = props;
  const { data, loading } = useSelector(state => state.projectSummary);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(getProjectSummary(projectId));
  }, []); // eslint-disable-line

  const expanded = [];
  let index = 0;
  const renderTree = nodes => {
    const isNotLeaf = Array.isArray(nodes.children) && nodes.children.length > 0;
    // make each nodeId unique
    const nodeId = `${index++}-${nodes.key}-${nodes.name}`;
    expanded.push(nodeId);
    return (
      <TreeItem
        expanded
        key={nodes.key}
        nodeId={nodeId}
        label={
          <Box sx={{ display: 'flex', alignItems: 'left', p: 0.5, pr: 0 }}>
            <Typography variant="body1" className={classes.treeLabel}>
              {nodes.name}
            </Typography>
            <StyledBadge badgeContent={nodes.count} />
          </Box>
        }>
        {isNotLeaf ? nodes.children.map(node => renderTree(node)) : null}
      </TreeItem>
    );
  };

  const renderEmptyData = () => {
    if (!isEmpty(data)) {
      return null;
    }

    if (loading) {
      return <Loading loading={loading} />;
    } else {
      return (
        <>
          <Typography variant="h6">No Summary Data Available.</Typography>
          <Typography variant="body1">The profiles used in this project cannot be summarized.</Typography>
        </>
      );
    }
  };

  return (
    <Paper className={classes.root}>
      <Grid container direction="column" alignItems="flex-start">
        <Grid item className={clsx(classes.treeItem, classes.header)}>
          <Grid container alignItems="center">
            <Grid item style={{ flexGrow: 1 }}>
              <Typography variant="h6">Location Summary</Typography>
            </Grid>
            <Grid>
              <ButtonIcon icon={ArrowBackIosIcon} onClick={() => setShowSummary(false)} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.treeItem}>
          {renderEmptyData()}
          {data.map((tree, index) => (
            <TreeView
              key={index}
              disableSelection
              disabledItemsFocusable
              className={classes.root}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpanded={expanded}
              defaultExpandIcon={<ChevronRightIcon />}>
              {renderTree(tree)}
            </TreeView>
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
};

InspectorSummary.defaultProps = {
  setShowSummary: null,
};

InspectorSummary.propTypes = {
  projectId: PropTypes.string.isRequired,
  setShowSummary: PropTypes.func,
};

export default InspectorSummary;
