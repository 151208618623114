import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Form } from 'react-final-form';
import FieldEditString from '../../shared/form/FieldEditString';
import PrimaryButton from '../../../components/shared/buttons/PrimaryButton';
import SecondaryButton from '../../../components/shared/buttons/SecondaryButton';
import { closeDialog } from '../../../store/dialogActions';
import { updateInspectionMedia } from '../../../store/features/inspectionMediaActions';

const useStyles = makeStyles(theme => ({
  form: {
    margin: theme.spacing(1),
  },
}));

const MediaDescriptionForm = ({ description, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const formError = '';

  const handleCloseDialog = () => dispatch(closeDialog());

  const handleDialogSubmit = values => {
    dispatch(updateInspectionMedia(id, values));
    handleCloseDialog();
  };

  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        errors[key] = value;
      }
    }
    return errors;
  };

  return (
    <Form
      onSubmit={handleDialogSubmit}
      validate={validate}
      initialValues={{ description }}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <FieldEditString label="Description" multiline name="description" />
              </Grid>
              <Grid container item direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                <Grid item>
                  <SecondaryButton label="Cancel" onClick={handleCloseDialog} />
                </Grid>
                <Grid item>
                  <PrimaryButton type="submit" label="Submit" />
                </Grid>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};

MediaDescriptionForm.propTypes = {
  description: PropTypes.string,
  id: PropTypes.number.isRequired,
};

export default MediaDescriptionForm;
