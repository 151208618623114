import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Chip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '3px',
  },
}));

const makeItems = value => {
  if (value === undefined) {
    return [];
  }
  // handle case when data is string vs array
  if (typeof value === 'string') {
    return value.split(',').map(item => ({
      key: item,
      name: item,
      value: item,
    }));
  } else if (typeof value === 'object' && value !== null) {
    // value is an object, convert it to an array
    // Ex: {key1: {"name": "key 1", "value": 123}} => [{name: 'key 1', value: 123}]
    return Object.values(value);
  }

  return value;
};

const ViewDisplayKeyValue = props => {
  const { label, value, placeholder } = props;
  const classes = useStyles();
  const items = makeItems(value);

  return (
    <>
      {label ? <div className="h7">{label}</div> : <></>}
      <Grid container spacing={1} className={classes.root}>
        {items.length ? (
          items.map((item, index) => (
            <Grid item key={`${item.key}-${index}`}>
              <Chip size="small" variant="outlined" label={`${item.name}: ${item.value}`} />
            </Grid>
          ))
        ) : (
          <Grid item>
            <Chip size="small" variant="outlined" label={placeholder} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

ViewDisplayKeyValue.defaultProps = {
  label: '',
  value: [],
  placeholder: '--',
};

ViewDisplayKeyValue.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  ]),
  placeholder: PropTypes.string,
};

export default ViewDisplayKeyValue;
