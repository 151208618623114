export const MEASUREMENTS_REQUEST = 'MEASUREMENTS_REQUEST';
export const MEASUREMENTS_SUCCESS = 'MEASUREMENTS_SUCCESS';
export const MEASUREMENTS_FAILURE = 'MEASUREMENTS_FAILURE';
export const MEASUREMENTS_CREATE_REQUEST = 'MEASUREMENTS_CREATE_REQUEST';
export const MEASUREMENTS_CREATE_SUCCESS = 'MEASUREMENTS_CREATE_SUCCESS';
export const MEASUREMENTS_CREATE_FAILURE = 'MEASUREMENTS_CREATE_FAILURE';
export const MEASUREMENTS_UPDATE_REQUEST = 'MEASUREMENTS_UPDATE_REQUEST';
export const MEASUREMENTS_UPDATE_SUCCESS = 'MEASUREMENTS_UPDATE_SUCCESS';
export const MEASUREMENTS_UPDATE_FAILURE = 'MEASUREMENTS_UPDATE_FAILURE';
export const MEASUREMENTS_DELETE_REQUEST = 'MEASUREMENTS_DELETE_REQUEST';
export const MEASUREMENTS_DELETE_SUCCESS = 'MEASUREMENTS_DELETE_SUCCESS';
export const MEASUREMENTS_DELETE_FAILURE = 'MEASUREMENTS_DELETE_FAILURE';
export const MEASUREMENTS_ALL_REQUEST = 'MEASUREMENTS_ALL_REQUEST';
export const MEASUREMENTS_ALL_SUCCESS = 'MEASUREMENTS_ALL_SUCCESS';
export const MEASUREMENTS_ALL_FAILURE = 'MEASUREMENTS_ALL_FAILURE';
export const IMPORT_MEASUREMENTS_CREATE_REQUEST = 'IMPORT_MEASUREMENTS_CREATE_REQUEST';
export const IMPORT_MEASUREMENTS_CREATE_SUCCESS = 'IMPORT_MEASUREMENTS_CREATE_SUCCESS';
export const IMPORT_MEASUREMENTS_CREATE_FAILURE = 'IMPORT_MEASUREMENTS_CREATE_FAILURE';
export const MEASUREMENTS_BULK_DELETE_REQUEST = 'MEASUREMENTS_BULK_DELETE_REQUEST';
export const MEASUREMENTS_BULK_DELETE_SUCCESS = 'MEASUREMENTS_BULK_DELETE_SUCCESS';
export const MEASUREMENTS_BULK_DELETE_FAILURE = 'MEASUREMENTS_BULK_DELETE_FAILURE';
export const CLEAR_IMPORT_ERROR = 'CLEAR_IMPORT_ERROR';

export const getMeasurements = id => {
  return {
    type: MEASUREMENTS_REQUEST,
    id,
  };
};

export const createMeasurements = (body, nextRoute) => {
  return {
    type: MEASUREMENTS_CREATE_REQUEST,
    body,
    nextRoute,
  };
};

export const updateMeasurements = (id, body, nextRoute) => {
  return {
    type: MEASUREMENTS_UPDATE_REQUEST,
    id,
    body,
    nextRoute,
  };
};

export const deleteMeasurements = (id, _, nextRoute) => {
  return {
    type: MEASUREMENTS_DELETE_REQUEST,
    id,
    nextRoute,
  };
};

export const deleteMeasurementsBulk = ids => {
  return {
    type: MEASUREMENTS_BULK_DELETE_REQUEST,
    ids,
  };
};

export const getAllMeasurements = query => {
  return {
    type: MEASUREMENTS_ALL_REQUEST,
    query,
  };
};

export const createImportMeasurements = (id, body) => {
  return {
    type: IMPORT_MEASUREMENTS_CREATE_REQUEST,
    id,
    body,
  };
};

export const clearImportError = () => {
  return {
    type: CLEAR_IMPORT_ERROR,
  };
};
