import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  cell: {
    fontWeight: 'bold',
    fontSize: '15px',
  },
}));

const DataCell = ({ value }) => {
  const classes = useStyles();
  return <div className={classes.cell}>{value === 0 ? ' - ' : value}</div>;
};

DataCell.propTypes = {
  value: PropTypes.number.isRequired,
};

export default DataCell;
