import { all, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  LIBRARY_MEDIA_REQUEST,
  LIBRARY_MEDIA_SUCCESS,
  LIBRARY_MEDIA_FAILURE,
  LIBRARY_MEDIA_CREATE_REQUEST,
  LIBRARY_MEDIA_CREATE_SUCCESS,
  LIBRARY_MEDIA_CREATE_FAILURE,
  LIBRARY_MEDIA_UPDATE_REQUEST,
  LIBRARY_MEDIA_UPDATE_SUCCESS,
  LIBRARY_MEDIA_UPDATE_FAILURE,
  LIBRARY_MEDIA_DELETE_REQUEST,
  LIBRARY_MEDIA_DELETE_SUCCESS,
  LIBRARY_MEDIA_DELETE_FAILURE,
  LIBRARY_MEDIA_ALL_REQUEST,
  LIBRARY_MEDIA_ALL_SUCCESS,
  LIBRARY_MEDIA_ALL_FAILURE,
} from './libraryMediaActions';

import {
  fetchLibraryMedia,
  createLibraryMedia,
  updateLibraryMedia,
  deleteLibraryMedia,
  fetchLibraryMediaList,
} from '../../api/features/libraryMedia';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';
import { fetchListAll } from '../../utilities/sagaHelpers';
import { DIALOG_CLOSE } from '../../store/dialogActions';

export function* libraryMediaFetch(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield fetchLibraryMedia(id);
    if (data) {
      yield put({ type: LIBRARY_MEDIA_SUCCESS, data });
    } else if (error) {
      yield put({ type: LIBRARY_MEDIA_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, LIBRARY_MEDIA_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, LIBRARY_MEDIA_FAILURE);
  }
}

export function* watchLibraryMediaFetch() {
  yield takeLatest(LIBRARY_MEDIA_REQUEST, libraryMediaFetch);
}

export function* libraryMediaCreate(action) {
  const { body } = action;
  try {
    const { data, error, formError, response } = yield createLibraryMedia(body);
    if (data) {
      yield put({ type: LIBRARY_MEDIA_CREATE_SUCCESS, data });
      yield put(push(`/library-media/list/${data.library.id}`));
    } else if (error) {
      yield put({ type: LIBRARY_MEDIA_CREATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: LIBRARY_MEDIA_CREATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, LIBRARY_MEDIA_CREATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, LIBRARY_MEDIA_CREATE_FAILURE);
  }
}

export function* watchLibraryMediaCreate() {
  yield takeLatest(LIBRARY_MEDIA_CREATE_REQUEST, libraryMediaCreate);
}

export function* libraryMediaUpdate(action) {
  const { id, body } = action;
  try {
    const { data, error, formError, response } = yield updateLibraryMedia(id, body);
    if (data) {
      yield put({ type: LIBRARY_MEDIA_UPDATE_SUCCESS, data });
      yield put(push(`/library-media/${data.id}`));
    } else if (error) {
      yield put({ type: LIBRARY_MEDIA_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: LIBRARY_MEDIA_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, LIBRARY_MEDIA_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, LIBRARY_MEDIA_UPDATE_FAILURE);
  }
}

export function* watchLibraryMediaUpdate() {
  yield takeLatest(LIBRARY_MEDIA_UPDATE_REQUEST, libraryMediaUpdate);
}

export function* libraryMediaDelete(action) {
  const { id, query } = action;
  try {
    const { data, error, response } = yield deleteLibraryMedia(id);
    if (data) {
      yield put({ type: LIBRARY_MEDIA_DELETE_SUCCESS });
      yield put({ type: LIBRARY_MEDIA_ALL_REQUEST, query });
      yield put({ type: DIALOG_CLOSE });
    } else if (error) {
      yield put({ type: LIBRARY_MEDIA_DELETE_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, LIBRARY_MEDIA_DELETE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, LIBRARY_MEDIA_DELETE_FAILURE);
  }
}

export function* watchLibraryMediaDelete() {
  yield takeLatest(LIBRARY_MEDIA_DELETE_REQUEST, libraryMediaDelete);
}

export function* libraryMediaFetchAll(action) {
  try {
    const { data, error, response } = yield fetchListAll(fetchLibraryMediaList, action.query);
    if (data) {
      yield put({ type: LIBRARY_MEDIA_ALL_SUCCESS, data });
    } else if (error) {
      yield put({ type: LIBRARY_MEDIA_ALL_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, LIBRARY_MEDIA_ALL_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, LIBRARY_MEDIA_ALL_FAILURE);
  }
}

export function* watchLibraryMediaFetchAll() {
  yield takeLatest(LIBRARY_MEDIA_ALL_REQUEST, libraryMediaFetchAll);
}

export default function* libraryMediaSaga() {
  yield all([
    watchLibraryMediaFetch(),
    watchLibraryMediaCreate(),
    watchLibraryMediaUpdate(),
    watchLibraryMediaDelete(),
    watchLibraryMediaFetchAll(),
  ]);
}
