import { combineReducers } from 'redux';
import {
  RESERVATION_REQUEST,
  RESERVATION_SUCCESS,
  RESERVATION_FAILURE,
  RESERVATION_CREATE_REQUEST,
  RESERVATION_CREATE_SUCCESS,
  RESERVATION_CREATE_FAILURE,
  RESERVATION_UPDATE_REQUEST,
  RESERVATION_UPDATE_SUCCESS,
  RESERVATION_UPDATE_FAILURE,
  RESERVATION_DELETE_REQUEST,
  RESERVATION_DELETE_SUCCESS,
  RESERVATION_DELETE_FAILURE,
  RESERVATION_ALL_REQUEST,
  RESERVATION_ALL_SUCCESS,
  RESERVATION_ALL_FAILURE,
} from './reservationsActions';

export const initialStateReservation = {
  loading: false,
  error: '',
  formError: {},
  id: 0,
  body: {},
  data: {},
};

export const initialStateAllReservation = {
  loading: false,
  error: '',
  query: {},
  dataAll: { count: 0, next: null, previous: null, results: [] },
};

export const reservationsReducer = (state = initialStateReservation, action) => {
  switch (action.type) {
    case RESERVATION_REQUEST:
      return {
        ...initialStateReservation,
        loading: true,
        id: action.id,
      };
    case RESERVATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case RESERVATION_FAILURE:
      return {
        ...initialStateReservation,
        error: action.error,
      };
    case RESERVATION_CREATE_REQUEST:
      return {
        ...initialStateReservation,
        loading: true,
        body: action.body,
      };
    case RESERVATION_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case RESERVATION_CREATE_FAILURE:
      return {
        ...initialStateReservation,
        error: action.error,
        formError: action.formError,
      };
    case RESERVATION_UPDATE_REQUEST:
      return {
        ...state,
        error: '',
        formError: {},
        loading: true,
        id: action.id,
        body: action.body,
      };
    case RESERVATION_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case RESERVATION_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case RESERVATION_DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case RESERVATION_DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
      };
    case RESERVATION_DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        error: action.error,
        formError: action.formError,
      };
    default:
      return state;
  }
};

export const reservationsAllReducer = (state = initialStateAllReservation, action) => {
  switch (action.type) {
    case RESERVATION_ALL_REQUEST:
      return {
        ...initialStateAllReservation,
        loading: true,
        serverPaginate: action.serverPaginate,
        query: action.query,
      };
    case RESERVATION_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        dataAll: action.data,
      };
    case RESERVATION_ALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default combineReducers({
  each: reservationsReducer,
  all: reservationsAllReducer,
});
