export const DEFECTS_REQUEST = 'DEFECTS_REQUEST';
export const DEFECTS_SUCCESS = 'DEFECTS_SUCCESS';
export const DEFECTS_FAILURE = 'DEFECTS_FAILURE';
export const DEFECTS_CREATE_REQUEST = 'DEFECTS_CREATE_REQUEST';
export const DEFECTS_CREATE_SUCCESS = 'DEFECTS_CREATE_SUCCESS';
export const DEFECTS_CREATE_FAILURE = 'DEFECTS_CREATE_FAILURE';
export const DEFECTS_LINKING_REQUEST = 'DEFECTS_LINKING_REQUEST';
export const DEFECTS_LINKING_SUCCESS = 'DEFECTS_LINKING_SUCCESS';
export const DEFECTS_LINKING_FAILURE = 'DEFECTS_LINKING_FAILURE';
export const DEFECTS_UPDATE_REQUEST = 'DEFECTS_UPDATE_REQUEST';
export const DEFECTS_UPDATE_SUCCESS = 'DEFECTS_UPDATE_SUCCESS';
export const DEFECTS_UPDATE_FAILURE = 'DEFECTS_UPDATE_FAILURE';
export const DEFECTS_DELETE_REQUEST = 'DEFECTS_DELETE_REQUEST';
export const DEFECTS_DELETE_SUCCESS = 'DEFECTS_DELETE_SUCCESS';
export const DEFECTS_DELETE_FAILURE = 'DEFECTS_DELETE_FAILURE';
export const FINDINGS_BULK_UPDATE_REQUEST = 'FINDINGS_BULK_UPDATE_REQUEST';
export const FINDINGS_BULK_UPDATE_SUCCESS = 'FINDINGS_BULK_UPDATE_SUCCESS';
export const FINDINGS_BULK_UPDATE_FAILURE = 'FINDINGS_BULK_UPDATE_FAILURE';
export const DEFECTS_ALL_REQUEST = 'DEFECTS_ALL_REQUEST';
export const DEFECTS_ALL_SUCCESS = 'DEFECTS_ALL_SUCCESS';
export const DEFECTS_ALL_CLEAR = 'DEFECTS_ALL_CLEAR';
export const DEFECTS_ALL_FAILURE = 'DEFECTS_ALL_FAILURE';

export const getDefects = id => {
  return {
    type: DEFECTS_REQUEST,
    id,
  };
};

export const createDefects = body => {
  return {
    type: DEFECTS_CREATE_REQUEST,
    body,
  };
};

export const linkDefects = (defectIds, nextRoute) => {
  return {
    type: DEFECTS_LINKING_REQUEST,
    defectIds,
    nextRoute,
  };
};

export const updateDefects = (id, body, location = '') => {
  return {
    type: DEFECTS_UPDATE_REQUEST,
    id,
    body,
    location,
  };
};

export const updateFindingsBulk = (ids, body, nextRoute) => {
  return {
    type: FINDINGS_BULK_UPDATE_REQUEST,
    ids,
    body,
    nextRoute,
  };
};

export const deleteDefects = id => {
  return {
    type: DEFECTS_DELETE_REQUEST,
    id,
  };
};

export const getAllDefects = query => {
  return {
    type: DEFECTS_ALL_REQUEST,
    query,
  };
};

export const clearAllDefects = () => {
  return {
    type: DEFECTS_ALL_CLEAR,
  };
};
