export function formatProp(str) {
  return str.replace(/ /g, '_').toLowerCase();
}

export const generateUniqueArray = arr => {
  return [...new Set([].concat.apply([], arr))];
};

export function calculateSum(dataArray) {
  return (key, value) => {
    return dataArray.reduce((acc, obj) => {
      if (!key) {
        return acc + obj.count;
      }
      return obj[key] !== value ? acc : acc + obj.count;
    }, 0);
  };
}
