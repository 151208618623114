import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CompaniesView from './CompaniesView';
import CompanyStats from './CompanyStats';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 3, 4, 3),
  },
  cell: {
    flexGrow: 1,
  },
}));

const CompanyOverview = props => {
  const { id, data, loading, error } = props;
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={3}
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      className={classes.root}>
      <Grid item className={classes.cell}>
        <CompanyStats id={id} />
      </Grid>
      <Grid item>
        <CompaniesView id={id} data={data} loading={loading} error={error} />
      </Grid>
    </Grid>
  );
};

CompanyOverview.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
};

export default CompanyOverview;
