import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import Description from '../shared/table/Description';
import Table, { SELECTABLE_ROWS_NONE } from '../shared/table/Table';
import { tableViews } from '../../utilities/tables';
import { setColumns } from '../shared/table/columns';
import { useTableViews } from '../../hooks/settingsHooks';
import Markdown from '../shared/Markdown';
import ChecklistValue from '../shared/checklist/ChecklistValue';
import ChecklistViewerMedia from './ChecklistViewerMedia';

import { setchecklistViewerTableView } from '../../store/settings/views/checklistViewerTableViewRedux';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const renderNotes = notes => {
  return notes ? <Markdown>{notes}</Markdown> : null;
};

const ID_ROW_ID = 0; // UPDATE this if you change the order of the columns
const MEDIA_ROW_ID = 9; // UPDATE this if you change the order of the columns

const ChecklistFlatTable = props => {
  const dispatch = useDispatch();
  const views = useTableViews(tableViews.checklistViewerTableViewKey);
  const { title, data, loading, embedded, selectedLine } = props;

  const refSelected = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (refSelected.current) {
        refSelected.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 500);
  }, [data, selectedLine]);

  /** @type {Column[]} */
  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: 'order',
      label: 'Order',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'section',
      label: 'Section',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          if (!value) return <></>;

          const lineId = tableMeta.rowData[columns.findIndexByName['id']];
          if (lineId.toLowerCase() === selectedLine) {
            return <span ref={refSelected}>{value}</span>;
          }
          return <>{value}</>;
        },
      },
    },

    {
      name: 'label',
      label: 'Label',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'lineType',
      label: 'Line Type',
      options: {
        filter: false,
        sort: true,
        display: 'exclude',
      },
    },
    {
      name: 'value',
      label: 'Value',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const lineType = tableMeta.rowData[columns.findIndexByName['lineType']];

          return <ChecklistValue value={value} lineType={lineType} />;
        },
      },
    },
    {
      name: 'notes',
      label: 'Notes',
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => renderNotes(value),
      },
    },

    {
      name: 'description',
      label: 'Description',
      options: {
        filter: false,
        sort: true,
        filterType: 'textField',
        customBodyRender: value => {
          if (!value) return <></>;
          return <Description value={value} />;
        },
      },
    },
    {
      name: 'mediaCount',
      label: 'Media Count',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'media',
      label: 'Media',
      options: {
        filter: false,
        sort: false,
        display: 'exclude',
        viewColumns: false,
        downloadBody: values => values.map(mediaObj => mediaObj.file),
      },
    },
  ];

  // handle columns display
  setColumns(columns, views);

  /** @type {TableOptions} */
  const options = {
    enableNestedDataAccess: '.',
    searchOpen: false,
    customToolbar: () => {
      return null;
    },
    search: true,
    filter: true,
    print: false,
    download: true,
    viewColumns: true,
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setchecklistViewerTableView(changedColumn, action));
    },
    selectableRows: SELECTABLE_ROWS_NONE,
    rowsPerPage: 500,
    rowsPerPageOptions: [],
    setRowProps: row => {
      // Highlight the row if the id matches
      // our find column index function is not available here (no tableMeta)
      if (row[ID_ROW_ID].toLowerCase() === selectedLine) {
        return {
          style: { border: '3px solid yellow' },
        };
      }
    },
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    isRowExpandable: (dataIndex, expandedRows) => {
      if (data[dataIndex]['media'].length > 0) {
        return true;
      }
      return false;
    },
    renderExpandableRow: (rowData, rowMeta) => {
      // our find column index function is not available here (no tableMeta)
      const media = rowData[MEDIA_ROW_ID];
      const colSpan = rowData.length + 1;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <ChecklistViewerMedia media={media} />
          </TableCell>
        </TableRow>
      );
    },
  };

  return (
    <div>
      <Table
        title={title}
        simpleSearch
        columns={columns}
        data={data}
        options={options}
        loading={loading}
        embedded={embedded}
      />
    </div>
  );
};

ChecklistFlatTable.defaultProps = {
  title: 'Checklist',
  data: [],
  embedded: false,
  loading: false,
};

ChecklistFlatTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  embedded: PropTypes.bool,
  loading: PropTypes.bool,
  selectedLine: PropTypes.string,
};

export default ChecklistFlatTable;
