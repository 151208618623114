export const RESERVATION_REQUEST = 'RESERVATION_REQUEST';
export const RESERVATION_SUCCESS = 'RESERVATION_SUCCESS';
export const RESERVATION_FAILURE = 'RESERVATION_FAILURE';
export const RESERVATION_CREATE_REQUEST = 'RESERVATION_CREATE_REQUEST';
export const RESERVATION_CREATE_SUCCESS = 'RESERVATION_CREATE_SUCCESS';
export const RESERVATION_CREATE_FAILURE = 'RESERVATION_CREATE_FAILURE';
export const RESERVATION_UPDATE_REQUEST = 'RESERVATION_UPDATE_REQUEST';
export const RESERVATION_UPDATE_SUCCESS = 'RESERVATION_UPDATE_SUCCESS';
export const RESERVATION_UPDATE_FAILURE = 'RESERVATION_UPDATE_FAILURE';
export const RESERVATION_DELETE_REQUEST = 'RESERVATION_DELETE_REQUEST';
export const RESERVATION_DELETE_SUCCESS = 'RESERVATION_DELETE_SUCCESS';
export const RESERVATION_DELETE_FAILURE = 'RESERVATION_DELETE_FAILURE';
export const RESERVATION_ALL_REQUEST = 'RESERVATION_ALL_REQUEST';
export const RESERVATION_ALL_SUCCESS = 'RESERVATION_ALL_SUCCESS';
export const RESERVATION_ALL_FAILURE = 'RESERVATION_ALL_FAILURE';

export const getReservation = id => {
  return {
    type: RESERVATION_REQUEST,
    id,
  };
};

export const createReservation = body => {
  return {
    type: RESERVATION_CREATE_REQUEST,
    body,
  };
};

export const updateReservation = (id, body) => {
  return {
    type: RESERVATION_UPDATE_REQUEST,
    id,
    body,
  };
};

export const deleteReservation = id => {
  return {
    type: RESERVATION_DELETE_REQUEST,
    id,
  };
};

export const getAllReservation = query => {
  return {
    type: RESERVATION_ALL_REQUEST,
    query,
  };
};
