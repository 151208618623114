export const SET_CHECKLIST_RESULT_LINES_TABLE_VIEW = 'SET_CHECKLIST_RESULT_LINES_TABLE_VIEW';
export const SET_CHECKLIST_RESULT_LINES_TABLE_SIZE = 'SET_CHECKLIST_RESULT_LINES_TABLE_SIZE';

export const setChecklistResultLinesTableView = (column, action) => {
  return {
    type: SET_CHECKLIST_RESULT_LINES_TABLE_VIEW,
    column,
    action,
  };
};

export const setChecklistResultLinesTableSize = size => {
  return {
    type: SET_CHECKLIST_RESULT_LINES_TABLE_SIZE,
    size,
  };
};

export const checklistResultLinesTableViewReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_CHECKLIST_RESULT_LINES_TABLE_VIEW:
      return {
        ...state,
        [action.column]: action.action,
      };
    case SET_CHECKLIST_RESULT_LINES_TABLE_SIZE:
      return {
        ...state,
        pageSize: action.size,
      };
    default:
      return state;
  }
};
