import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getMeasurements } from '../../store/features/measurementsActions';
import { fieldOrder, removeField, hideField } from './measurementsShared';
import { buildRoute, apiDateToString, jsonKeyToLabel } from '../../utilities/strings';
import CommonFormView from '../shared/form/CommonView';
import StyledLink from '../shared/StyledLink';
import { ProjectLink } from '../shared/links/InternalLinks';
// TODO Make generic column pill
import ChecklistFlagsColumn from '../checklists/ChecklistFlagsColumn';

const MeasurementsView = () => {
  const id = useParams().id;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMeasurements(id));
  }, [dispatch, id]);

  const { data, loading, error } = useSelector(state => state.measurements.each);

  const formSettings = {
    fieldOrder,
    removeField: removeField('VIEW'),
    hideField,
  };
  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};

  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      // cast to string because booleans do not show up in the field
      let displayLabel = jsonKeyToLabel(key); // eslint-disable-line prefer-const
      let displayValue = '';

      if (data[key] != null) {
        switch (key) {
          // add cases for each key that needs to be customized.
          case 'app_key':
          case 'external_id':
          case 'offset':
          case 'state':
            displayValue = data[key];
            break;
          case 'collected_on':
          case 'created_on':
          case 'updated_on':
            displayValue = apiDateToString(data[key], 'date');
            break;
          case 'collected_by':
            displayValue = data.collected_by_display_name;
            break;
          case 'cml':
            fieldSettings[key] = {
              type: 'component',
              cellProps: {
                xs: 6,
              },
              fieldProps: {
                label: displayLabel,
                value: () => <StyledLink to={`/cmls/${data[key].id}`} value={data[key].name} />,
              },
            };
            return;
          case 'extras':
            // TODO make ChecklistFlagsColumn generic
            fieldSettings[key] = {
              type: 'component',
              cellProps: {
                xs: 6,
              },
              fieldProps: {
                label: 'Extras',
                value: () => <ChecklistFlagsColumn flags={data[key]} />,
              },
            };
            return;
          case 'project':
            fieldSettings[key] = {
              type: 'component',
              cellProps: {
                xs: 6,
              },
              fieldProps: {
                label: displayLabel,
                value: () => <ProjectLink {...data[key]} />,
              },
            };
            return;
          case 'readings':
            displayValue = data[key].join(', ');
            break;
          case 'source_checklist':
            displayValue = `${data[key].template_name} - ${apiDateToString(data[key].created_on, 'date')}`;
            break;
          case 'value':
            displayValue = String(data[key]);
            break;
          default:
            displayValue = data[key].toString();
        }
      } else {
        // These fields are not on the top level
        switch (key) {
          case 'asset_path':
            displayValue = data.cml?.asset?.asset_path || '';
            break;
        }
      }
      fieldSettings[key] = {
        type: 'text',
        fieldProps: {
          label: displayLabel,
          value: displayValue,
        },
      };
    });
  }
  return (
    <CommonFormView
      title={'Measurement Details'}
      values={data}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      editLink={buildRoute(`/measurements/${id}/edit`)}
      loading={loading}
      error={error}
    />
  );
};

export default MeasurementsView;
