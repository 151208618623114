import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAssets } from '../store/getters/assetsGetterActions';
import { getLastFetchAt, setLastFetchAt, shouldFetchState } from './shared';

const topLevelAssetsQuery = { no_parent: true, is_active: true };
const localStorageKey = 'lastAssetsTime';

export const useTopLevelAssets = (query = topLevelAssetsQuery) => {
  const dispatch = useDispatch();
  const {
    data: { results } = { results: {} }, // setting default in case data is an empty object
    query: assetsQuery,
  } = useSelector(state => state.assetsGetter);

  useEffect(() => {
    if (!query) return;

    const lastFetch = getLastFetchAt(localStorageKey);

    const shouldFetch = shouldFetchState(query, assetsQuery, lastFetch);

    if (shouldFetch) {
      dispatch(getAssets(topLevelAssetsQuery));
      setLastFetchAt(localStorageKey);
    }
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  const topLevelAssetMap = {};
  const ownerMap = {};

  const options = results?.reduce(
    (acc, { id, name, owner }) => {
      const value = id.toString();
      topLevelAssetMap[value] = name;
      acc.topLevelAssetOptions = [...acc.topLevelAssetOptions, value];

      if (owner) {
        const ownerId = owner.id.toString();
        ownerMap[ownerId] = owner.name;
        acc.ownerOptions = [...new Set([...acc.ownerOptions, ownerId])];
      }

      return acc;
    },
    { topLevelAssetOptions: [], ownerOptions: [] }
  );

  const { topLevelAssetOptions, ownerOptions } = { ...options };

  return {
    results,
    topLevelAssetOptions,
    topLevelAssetMap,
    ownerOptions,
    ownerMap,
  };
};
