import { requestFactory, responseHandlerForm } from './base';

const post = requestFactory('post', { type: 'public', handler: responseHandlerForm });

// https://stackoverflow.com/questions/69524232/django-set-cookie-does-not-set-cookie-in-browser
const postCredentials = requestFactory('post', {
  handler: responseHandlerForm,
  credentials: 'include',
});

export const lookupUser = async email => post('/api/auth/lookup/', { email });

// https://docs.huvrdata.app/reference/api_auth_session-login_create
export const sessionLogin = async idToken => postCredentials('/api/auth/session-login/', { id_token: idToken });

export const sessionLogout = async idToken => post('/api/auth/session-logout/', { id_token: idToken });
