import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { useSelector, useDispatch } from 'react-redux';
import { Card, CardContent, CardActions, Grid } from '@mui/material';
import * as Sentry from '@sentry/browser';
import { locationShape } from '../../utilities/propTypes';
import { resetLookup, requestLogin, loginFailure, resetLogInOut, setRoute } from '../../store/authActions';
import SecondaryButton from '../shared/buttons/SecondaryButton';
import Error from '../shared/displays/Error';
import FirebaseResetPassword from './FirebaseResetPassword';
import StyledFirebaseAuth from './StyledFirebaseAuth';

const useStyles = makeStyles(theme => ({
  card: {
    margin: theme.spacing(0, 'auto'),
    padding: theme.spacing(0, 3),
    width: '352px',
  },
  cardContent: {
    margin: theme.spacing(2, 0),
    '&:last-child': {
      marginTop: theme.spacing(2),
      paddingBottom: 0,
    },
  },
}));

/**
 *
 * Login to GCP Identity Platform
 *
 * Firebase UI Web:
 *    https://github.com/firebase/firebaseui-web
 *
 */
const FirebaseLogin = props => {
  const { route: routeProp } = props;
  const theme = useTheme();
  const classes = useStyles();
  const { apiKey, authDomain, projectId, tenantId, providers, error, email, route } = useSelector(
    state => state.auth.lookup
  );
  const dispatch = useDispatch();
  const [tenantError, setTenantError] = useState('');
  const [isPasswordResetCard, setIsPasswordResetCard] = useState(false);

  const isPasswordEnabled = providers ? providers.includes('password') : false;

  useEffect(() => {
    if (theme.palette.mode === 'dark') {
      import('./firebaseDark.scss').then();
    } else {
      import('./firebaseLight.scss').then();
    }
  }, []); // eslint-disable-line

  // if the route is set in lookup, just use that one.
  // if a user goes directly to FirebaseLogin, there will be no route, so use a new route
  let nextRoute = route;
  if (!route && routeProp) {
    nextRoute = routeProp;
    dispatch(setRoute(routeProp));
  }

  const landingUrl = useSelector(state => state.auth.logInOut.landingUrl);

  // Change the login button if it is SAML.
  const modifyProviders = providers => {
    providers = providers.map(provider => {
      if (provider.indexOf('saml.') > -1) {
        return {
          provider,
          providerName: provider.split('saml.')[1].toUpperCase(),
          buttonColor: '#12569a',
        };
      }
      if (provider === 'password') {
        // Disable signing up with password
        //  (GCP-IP shouldn't allow anyway, but this shows a helpful screen...)
        //
        // docs: https://github.com/firebase/firebaseui-web#configure-email-provider
        //
        return {
          provider,
          disableSignUp: {
            status: true,
            adminEmail: 'support@huvrdata.com',
            // helpLink: 'https://huvrdata.com', // optional url for more info
          },
        };
      }
      return provider;
    });
    return providers;
  };

  const uiConfig = {
    // i.e. ['password', 'google.com', 'saml-xxxx']
    signInOptions: modifyProviders(providers),
    credentialHelper: 'none',
    adminRestrictedOperation: {
      status: true,
      adminEmail: 'support@huvrdata.com',
      // helpLink: 'https://huvrdata.com', // optional url for more info
    },
    callbacks: {
      signInSuccessWithAuthResult: (authResult, redirectURL) => {
        try {
          // it is possible to go directly to the FirebaseLogin component with nothing in route
          dispatch(requestLogin(landingUrl || nextRoute));
        } catch (error) {
          dispatch(loginFailure(error));
        }
        return false;
      },
      signInFailure: function (error) {
        Sentry.captureMessage('Firebase login failure: ' + error);
        return false;
      },
      uiShown: function () {
        const emailElement = document.querySelector('.firebaseui-idp-text');
        const fillEmailInput = value => {
          // slight delay for ui to render to grab element and inject value;
          setTimeout(() => {
            const element = document.getElementsByName('email')[0];
            if (element) {
              document.getElementsByName('email')[0].value = value;
            }
          }, '1000');
        };

        // autofill for email
        if (emailElement) {
          emailElement.addEventListener('click', () => {
            fillEmailInput(email);
          });
        }

        // autofill for google email
        if (document.getElementsByName('email').length > 0) {
          fillEmailInput(email);
        }
      },
    },
  };

  const handleBack = () => {
    dispatch(resetLookup());
    dispatch(resetLogInOut());
  };

  const toggleCard = () => {
    setIsPasswordResetCard(prevIsFlipped => !prevIsFlipped);
  };

  try {
    firebase.initializeApp({ apiKey, authDomain, projectId });
  } catch (error) {
    // do nothing if the firebase app already exists
  }

  try {
    // If tenantId isn't valid or set, set to null to revert to project-level IdP
    if (!tenantError) {
      firebase.auth().tenantId = tenantId || null;
    }
  } catch (error) {
    Sentry.captureMessage('No workspace provided ' + error);
    setTenantError(' No tenant provided or invalid subdomain, please check your url');
  }

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Grid container direction="column" justifyContent="center" spacing={1}>
          <Grid item>
            {!isPasswordResetCard ? (
              <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
            ) : (
              <FirebaseResetPassword toggleCard={toggleCard} />
            )}
            <Error error={error || tenantError} />
          </Grid>
          <Grid container direction="row" spacing={8}>
            <Grid item>
              <CardActions>
                <SecondaryButton label="Back" onClick={handleBack} />
              </CardActions>
            </Grid>
            <Grid item>
              <CardActions>
                {isPasswordEnabled && !isPasswordResetCard && (
                  <SecondaryButton label="Forgot Password?" onClick={toggleCard} />
                )}
              </CardActions>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

FirebaseLogin.propTypes = {
  route: locationShape,
};

export default FirebaseLogin;
