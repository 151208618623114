import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link as ScrollLink } from 'react-scroll';
import { locationWithBack } from '../../utilities/route';
import variables from '../../config.module.scss';

const useStyles = makeStyles(theme => ({
  scrollLink: {
    fontSize: theme.spacing(2),
    textAlign: 'left',
    cursor: 'pointer',
  },
}));
const StyledLink = props => {
  const darkMode = useSelector(state => state.settings.palette.darkMode);
  const { to, value, hash, search, children, newTab } = props;
  const location = useLocation();
  const color = { color: darkMode ? variables.textPrimaryDark : variables.textPrimaryLight };
  const classes = useStyles();
  if (!value && !children) {
    return <></>;
  }
  if (to) {
    const toLocation = locationWithBack({ pathname: to, ...(search ? { search } : {}) }, location);
    const newTabProps = newTab ? { target: '_blank', rel: 'noopener noreferrer' } : {};
    return (
      <Link to={toLocation} style={color} {...newTabProps}>
        {props.children || value}
      </Link>
    );
  }
  if (hash) {
    return (
      <ScrollLink
        to={hash}
        className={classes.scrollLink}
        smooth={true}
        duration={300} // time of animation, can also be a function
        style={{ color: 'red', textDecoration: props.underline ? 'underline' : 'none' }}
        offset={-300}
      >
        {props.children || value}
      </ScrollLink>
    );
  }

  return (
    <span
      onClick={props.onClick}
      onContextMenu={props.onContextMenu}
      style={{
        cursor: 'pointer',
        color: darkMode ? variables.textPrimaryDark : variables.textPrimaryLight,
        textDecoration: props.underline ? 'underline' : 'none',
      }}
    >
      {props.children || value}
    </span>
  );
};

StyledLink.defaultProps = {
  value: '',
  to: '',
  hash: '',
  onClick: () => {},
  onContextMenu: undefined,
  underline: false,
  search: undefined,
  newTab: false,
};

StyledLink.propTypes = {
  to: PropTypes.string,
  hash: PropTypes.string,
  value: PropTypes.string.isRequired,
  children: PropTypes.node,
  onClick: PropTypes.func,
  onContextMenu: PropTypes.func,
  underline: PropTypes.bool,
  search: PropTypes.string,
  newTab: PropTypes.bool,
};

export default StyledLink;
