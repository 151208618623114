import { all, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  LIBRARIES_REQUEST,
  LIBRARIES_SUCCESS,
  LIBRARIES_FAILURE,
  LIBRARIES_CREATE_REQUEST,
  LIBRARIES_CREATE_SUCCESS,
  LIBRARIES_CREATE_FAILURE,
  LIBRARIES_UPDATE_REQUEST,
  LIBRARIES_UPDATE_SUCCESS,
  LIBRARIES_UPDATE_FAILURE,
  LIBRARIES_DELETE_REQUEST,
  LIBRARIES_DELETE_SUCCESS,
  LIBRARIES_DELETE_FAILURE,
  LIBRARIES_ALL_REQUEST,
  LIBRARIES_ALL_SUCCESS,
  LIBRARIES_ALL_FAILURE,
} from './librariesActions';

import {
  fetchLibraries,
  createLibraries,
  updateLibraries,
  deleteLibraries,
  fetchLibrariesList,
} from '../../api/features/libraries';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';
import { buildRoute } from '../../utilities/strings';
import { fetchListAll } from '../../utilities/sagaHelpers';

export function* librariesFetch(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield fetchLibraries(id);
    if (data) {
      yield put({ type: LIBRARIES_SUCCESS, data });
    } else if (error) {
      yield put({ type: LIBRARIES_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, LIBRARIES_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, LIBRARIES_FAILURE);
  }
}

export function* watchLibrariesFetch() {
  yield takeLatest(LIBRARIES_REQUEST, librariesFetch);
}

export function* librariesCreate(action) {
  const { body } = action;
  try {
    const { data, error, formError, response } = yield createLibraries(body);
    if (data) {
      yield put({ type: LIBRARIES_CREATE_SUCCESS, data });
      yield put(push(buildRoute('/libraries')));
    } else if (error) {
      yield put({ type: LIBRARIES_CREATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: LIBRARIES_CREATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, LIBRARIES_CREATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, LIBRARIES_CREATE_FAILURE);
  }
}

export function* watchLibrariesCreate() {
  yield takeLatest(LIBRARIES_CREATE_REQUEST, librariesCreate);
}

export function* librariesUpdate(action) {
  const { id, body } = action;
  try {
    const { data, error, formError, response } = yield updateLibraries(id, body);
    if (data) {
      yield put({ type: LIBRARIES_UPDATE_SUCCESS, data });
      yield put(push(buildRoute('/libraries')));
    } else if (error) {
      yield put({ type: LIBRARIES_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: LIBRARIES_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, LIBRARIES_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, LIBRARIES_UPDATE_FAILURE);
  }
}

export function* watchLibrariesUpdate() {
  yield takeLatest(LIBRARIES_UPDATE_REQUEST, librariesUpdate);
}

export function* librariesDelete(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield deleteLibraries(id);
    if (data) {
      yield put({ type: LIBRARIES_DELETE_SUCCESS });
      yield put(push(buildRoute('/libraries')));
    } else if (error) {
      yield put({ type: LIBRARIES_DELETE_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, LIBRARIES_DELETE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, LIBRARIES_DELETE_FAILURE);
  }
}

export function* watchLibrariesDelete() {
  yield takeLatest(LIBRARIES_DELETE_REQUEST, librariesDelete);
}

export function* librariesFetchAll(action) {
  try {
    const { data, error, response } = yield fetchListAll(fetchLibrariesList, action.query);
    if (data) {
      yield put({ type: LIBRARIES_ALL_SUCCESS, data });
    } else if (error) {
      yield put({ type: LIBRARIES_ALL_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, LIBRARIES_ALL_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, LIBRARIES_ALL_FAILURE);
  }
}

export function* watchLibrariesFetchAll() {
  yield takeLatest(LIBRARIES_ALL_REQUEST, librariesFetchAll);
}

export default function* librariesSaga() {
  yield all([
    watchLibrariesFetch(),
    watchLibrariesCreate(),
    watchLibrariesUpdate(),
    watchLibrariesDelete(),
    watchLibrariesFetchAll(),
  ]);
}
