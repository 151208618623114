import { requestFactory } from '../base';

const get = requestFactory('get');
const post = requestFactory('post');
const del = requestFactory('del');

const options = (env = process.env.NODE_ENV) => ({
  headers: { 'Content-Type': 'application/json', Accept: 'application/json; version=2.0' },
  credentials: env === 'development' ? 'include' : 'same-origin',
});

export const fetchChecklists = async id => get(`/api/checklists/${id}/`, '', options);
export const createChecklists = async body => post('/api/checklists/', body);
export const updateChecklists = async (id, body) => post(`/api/checklists/${id}/edit/`, body);
export const deleteChecklists = async id => del(`/api/checklists/${id}/`);
export const fetchChecklistsList = async query => get('/api/checklists/', query);
export const updateChecklistsLock = async (id, lockAction) => post(`/api/checklists/${id}/${lockAction}/`);
export const bulkLockChecklists = async (ids, lockAction) =>
  post(`/api/checklists/bulk-lock/`, { ids, action: lockAction });
