// lib
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import arrayMutators from 'final-form-arrays';
import { Alert, AlertTitle } from '@mui/material';

// components
import Error from '../shared/displays/Error';
import Loading from '../shared/displays/Loading';
import CommonForm from '../shared/form/Common';
import GeoPoint from '../shared/form/GeoPoint';

// util
import { defectStateOptions } from '../../api/features/constants';
import { useFeatureFlags, usePermissions } from '../../hooks/settingsHooks';
import { createDefects, getDefects, updateDefects } from '../../store/features/defectsActions';
import { predefinedErrors } from '../../utilities/errors';
import { isEmpty } from '../../utilities/objects';
import { apiDateToString, jsonKeyToLabel } from '../../utilities/strings';
import { numberValidator } from '../../utilities/validators';
import { fieldOrder, helperText, hideField, removeField, shouldHideProtectedFields } from './defectsShared';

const DefectsForm = props => {
  const { update } = props;
  const params = useParams();
  const { data, loading, error, formError } = useSelector(state => state.defects.each);
  const [submittedValues, setSubmittedValues] = useState({});
  const dispatch = useDispatch();
  const location = useLocation();
  const { hasAccessDisplay, hasFindingsPrioritization, hasDefectDashboard } = useFeatureFlags();
  const { hasDefectEdit, hasDefectEditProtected, hasDashboardView, hasDashboardViewAll } = usePermissions();
  const hasFindingsEnabled = hasDefectDashboard && hasDefectEdit;
  const showFindings = hasFindingsEnabled && (hasDashboardView || hasDashboardViewAll);

  useEffect(() => {
    if (update && params.id) {
      dispatch(getDefects(params.id));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const defectProfile = data?.profile;
  const locationLayerProfile = data?.layer;
  if (isEmpty(data)) {
    return <Loading />;
  }
  if (!defectProfile || !locationLayerProfile) {
    return (
      <Error
        style={{ display: 'flex', 'margin-top': '10%', 'justify-content': 'center' }}
        error={predefinedErrors.missingProfileError}
      />
    );
  }

  const formSettings = {
    fieldOrder,
    removeField: removeField(update ? 'UPDATE' : 'CREATE'),
    hideField: (values, item) => {
      return hideField(values, item, {
        hideProtectedFields: shouldHideProtectedFields(data, update, hasDefectEditProtected),
        defectProfile,
        locationLayerProfile,
        hasAccessDisplay,
        hasFindingsPrioritization,
      });
    },
  };

  const getSeverity = () => {
    const field = {
      type: 'text',
      fieldProps: {
        label: 'Severity',
        name: 'severity',
        helperText: defectProfile?.severity_help || '',
        labelwidth: 50,
      },
    };
    if (defectProfile.severity_mode === 'LIST') {
      field.type = 'autocomplete';
      field.fieldProps['options'] = defectProfile.severity_options;
      return field;
    }
    if (defectProfile.severity_mode === 'FREE') {
      return field;
    }
  };
  const getLocationZone = () => {
    const field = {
      type: 'text',
      fieldProps: {
        label: 'Location Zone',
        name: 'location_zone',
        helperText: locationLayerProfile?.zone_help || '',
        labelwidth: 90,
      },
    };
    if (locationLayerProfile.zone_mode === 'LIST') {
      field.type = 'autocomplete';
      field.fieldProps['options'] = locationLayerProfile.zone_options;
      return field;
    }
    if (locationLayerProfile.zone_mode === 'FREE') {
      return field;
    }
  };
  const getLocationCode = () => {
    const field = {
      type: 'text',
      fieldProps: {
        label: 'Location Code',
        name: 'location_code',
        helperText: locationLayerProfile?.code_help || '',
        labelwidth: 80,
      },
    };
    if (locationLayerProfile.code_mode === 'LIST') {
      field.type = 'autocomplete';
      field.fieldProps['options'] = locationLayerProfile.code_options;
      return field;
    }
    if (locationLayerProfile.code_mode === 'FREE') {
      return field;
    }
  };

  const getAccess = () => {
    const field = {
      type: 'text',
      fieldProps: {
        label: 'Access',
        name: 'access',
        helperText: locationLayerProfile?.access_help || '',
        labelwidth: 80,
      },
    };
    if (locationLayerProfile.access_mode === 'LIST') {
      field.type = 'autocomplete';
      field.fieldProps['options'] = locationLayerProfile.access_options;
      return field;
    }
    if (locationLayerProfile.access_mode === 'FREE') {
      return field;
    }
  };

  const getComponent = () => {
    const field = {
      type: 'text',
      fieldProps: {
        label: 'Component',
        name: 'component',
        helperText: locationLayerProfile?.component_help || '',
        labelwidth: 80,
      },
    };
    if (locationLayerProfile.component_mode === 'LIST') {
      field.type = 'autocomplete';
      field.fieldProps['options'] = locationLayerProfile.component_options;
      return field;
    }
    if (locationLayerProfile.component_mode === 'FREE') {
      return field;
    }
  };

  const getDefectType = () => {
    const field = {
      type: 'text',
      fieldProps: {
        label: 'Type',
        name: 'type',
        helperText: defectProfile?.defect_help || '',
        labelwidth: 40,
      },
    };
    if (defectProfile.type_mode === 'LIST') {
      field.type = 'autocomplete';
      field.fieldProps['options'] = defectProfile.type_options;
      return field;
    }
    if (defectProfile.type_mode === 'FREE') {
      return field;
    }
  };

  const getDefectSubType = () => {
    const field = {
      type: 'text',
      fieldProps: {
        label: 'Sub Type',
        name: 'sub_type',
        helperText: defectProfile?.sub_type_help || '',
        labelwidth: 40,
      },
    };
    if (defectProfile.sub_type_mode === 'LIST') {
      field.type = 'autocomplete';
      field.fieldProps['options'] = defectProfile.sub_type_options;
      return field;
    }
    if (defectProfile.sub_type_mode === 'FREE') {
      return field;
    }
  };

  const getLocationGuide = () => {
    const field = {
      type: 'markdown',
      fieldProps: {
        source: locationLayerProfile?.guide || '',
      },
    };
    if (!locationLayerProfile) {
      field.disabled = true;
      return field;
    }
    return field;
  };

  const getDefectGuide = () => {
    const field = {
      type: 'markdown',
      fieldProps: {
        source: defectProfile?.guide || '',
      },
    };
    if (!defectProfile) {
      field.disabled = true;
      return field;
    }
    return field;
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */

  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach(key => {
      switch (key) {
        /* ---------- Required Fields ---------- */
        case 'component':
          fieldSettings[key] = getComponent();
          break;
        case 'location_zone':
          fieldSettings[key] = getLocationZone();
          break;
        case 'location_code':
          fieldSettings[key] = getLocationCode();
          break;
        case 'access':
          fieldSettings[key] = getAccess();
          break;
        case 'type':
          fieldSettings[key] = getDefectType();
          break;
        case 'severity':
          fieldSettings[key] = getSeverity();
          break;
        case 'sub_type':
          fieldSettings[key] = getDefectSubType();
          break;
        case 'location_guide':
          fieldSettings[key] = getLocationGuide();
          break;
        case 'defect_guide':
          fieldSettings[key] = getDefectGuide();
          break;
        case 'state':
          fieldSettings[key] = {
            type: 'select',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              options: defectStateOptions,
              labelwidth: 34,
              helperText: helperText[key],
            },
          };
          return;

        /* ---------- Regular Fields ---------- */
        case 'labels':
          fieldSettings[key] = {
            type: 'key-value',
            fieldProps: {
              label: 'Labels',
              name: 'labels',
            },
          };
          return;
        case 'length':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: 'Length',
              name: 'length',
              validator: value => numberValidator(value),
            },
          };
          return;
        case 'width':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: 'Width',
              name: 'width',
              validator: value => numberValidator(value),
            },
          };
          return;
        case 'area':
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: 'Area',
              name: 'area',
              validator: value => numberValidator(value),
            },
          };
          return;
        case 'geo_point':
          fieldSettings[key] = {
            type: 'geo',
            fieldProps: {
              label: 'Geo Point',
              name: 'geo_point',
              helperText: helperText[key],
              placeholder: '37.7749, -122.4194',
            },
          };
          return;

        case 'repair_by':
        case 'next_inspection_date':
        case 'captured_on':
        case 'resolved_on':
          fieldSettings[key] = {
            type: 'datetime',
            cellProps: {
              xs: 12,
            },
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              helperText: helperText[key],
            },
          };
          return;
        /* ----------  Disabled Fields ---------- */
        case 'created_on':
        case 'updated_on':
          fieldSettings[key] = {
            type: 'display',
            fieldProps: {
              disabled: true,
              label: jsonKeyToLabel(key),
              name: key,
              value: apiDateToString(data[key], 'date'),
            },
          };
          break;
        /* ----------  Default ---------- */
        default:
          fieldSettings[key] = {
            type: 'text',
            fieldProps: {
              label: jsonKeyToLabel(key),
              name: key,
              helperText: helperText[key],
            },
          };
      }
    });
  }

  const title = update ? 'Edit Finding' : 'New Finding';

  let initialValues = {};
  if (update) {
    // The form cannot handle data coming in as objects.  (see ProjectsForm as an example)
    initialValues = { ...data };
    // convert severity value to string for autocomplete field
    initialValues.severity = `${initialValues.severity}`;
    initialValues.geo_point = data.geo_point ? new GeoPoint(data.geo_point).toString() : undefined;
  } else {
    // assign values from url queries here
    initialValues = {};
  }

  const onSubmit = changedValues => {
    const updatedValues = { ...changedValues };
    if ('geo_point' in updatedValues) {
      updatedValues['geo_point'] = new GeoPoint(updatedValues['geo_point']).toGeoJSON();
    }

    setSubmittedValues(changedValues);
    if (update) {
      let next = showFindings ? '/findings' : '/projects'; // default redirect since location.state is not always available
      if (location?.state?.back) {
        next = location.state.back;
      }
      dispatch(updateDefects(updatedValues.id, updatedValues, next));
    } else {
      dispatch(createDefects(updatedValues));
    }
  };

  /**
   * Decorators are used for setting the values of other fields based off of a field.
   * Decorator format can be found here: src/components/shared/form/common.js
   */
  const decorators = [];

  /**
   *  Validations that are per field.
   *  Errors associated with the form are passed back through formError in Redux.
   */
  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        if (values[key] === submittedValues[key]) {
          errors[key] = value;
        } else {
          errors[key] = undefined;
        }
      }
    }
    return errors;
  };

  // ---- Render

  const _LockedAlert = () => {
    // https://github.com/huvrdata/huvr/issues/6723#issuecomment-1329417107
    return (
      <Alert severity="warning">
        <AlertTitle>Finding Is Locked</AlertTitle>
        {hasDefectEditProtected && (
          <>
            We do not recommended making changes fields other than Status and Priority, as this may result in{' '}
            <strong>loss of historic data</strong>.
            <br />
          </>
        )}
        <>
          If new information exists, <strong>create a new Finding</strong>, then link it to this finding
        </>
      </Alert>
    );
  };

  return (
    <>
      {data?.is_locked && <_LockedAlert />}
      <CommonForm
        update={update}
        title={title}
        initialValues={initialValues}
        fieldSettings={fieldSettings}
        formSettings={formSettings}
        onSubmit={onSubmit}
        decorators={decorators}
        mutators={{ ...arrayMutators }}
        validate={validate}
        loading={loading}
        error={error}
      />
    </>
  );
};

DefectsForm.defaultProps = {
  update: false,
};

DefectsForm.propTypes = {
  update: PropTypes.bool,
};

export default DefectsForm;
