import _ from 'lodash';
import { flattenContext } from '@huvrdata/evaluate';
import { apiDateToString, toQueryString } from './strings';
import { isEmpty, getLabel } from './objects';

/**
 *
 * @typedef {import('final-form').FormState} FinalFormState
 */

// not included in line count for checklist progress calculation
export const DISPLAY_LINE_TYPES = ['line-group', 'line-group-end', 'text block', 'image'];

// maps to `CHECKLIST_ACTIONS`
// https://github.com/huvrdata/huvr/blob/1cc334716755b02d1eec497cf116d265ecb21b44/huvr_api/huvr_api/checklists/choices.py#L14-L20
export const CHECKLIST_ACTIONS = {
  CAMERA: 'camera',
  GALLERY: 'gallery',
  NOTES: 'notes',
  // ANY: '*',  (assume BE will expand ANY to include ^)
  //  see: https://github.com/huvrdata/huvr/issues/6212
};

export const CHECKLIST_LINE_TYPES = {
  CALCULATION: 'calculation',
  CHOICE: 'choice',
  COLLECT_MEDIA: 'collect-media',
  COLLECT_POSITION: 'collect-position',
  DATE: 'date',
  IMAGE: 'image',
  LINE_GROUP: 'line-group',
  LINE_GROUP_END: 'line-group-end',
  MULTIPLE_CHOICE: 'multiple choice',
  SIGNATURE: 'signature',
  TEXT_BLOCK: 'text block',
  TEXT_DATE: 'text date',
  TEXT_NUMBER: 'text number',
  NUMBER: 'number',
  NUMBER_LIST: 'number-list',
  TEXT: 'text',
  TEXTAREA: 'textarea',
};

// Check if a particular action/accessory is enabled for this line item.
export const actionsEnabled = (action, lineItem, defaultActions = []) => {
  let enabled = new Set(defaultActions);
  const actionsOn = lineItem.actions_on || [];
  const actionsOff = lineItem.actions_off || [];
  enabled = union(enabled, new Set(actionsOn));
  enabled = difference(enabled, new Set(actionsOff));

  return enabled.has(action);
};

function union(setA, setB) {
  const _union = new Set(setA);
  for (const elem of setB) {
    _union.add(elem);
  }
  return _union;
}

export function intersection(setA, setB) {
  const _intersection = new Set();
  for (const elem of setB) {
    if (setA.has(elem)) {
      _intersection.add(elem);
    }
  }
  return _intersection;
}

export function symmetricDifference(setA, setB) {
  const _difference = new Set(setA);
  for (const elem of setB) {
    if (_difference.has(elem)) {
      _difference.delete(elem);
    } else {
      _difference.add(elem);
    }
  }
  return _difference;
}

function difference(setA, setB) {
  const _difference = new Set(setA);
  for (const elem of setB) {
    _difference.delete(elem);
  }
  return _difference;
}

export function object2array(data) {
  // Converts a dict { '0': 'A', '1': 'B'} to Array ['A', 'B']
  // removes any undefined
  const value = [];
  const keys = Object.keys(data).sort();
  for (const key of keys) {
    if (data[key] !== undefined) {
      value.push(data[key]);
    }
  }
  return value;
}

export function calculateSectionProgress(section, results) {
  let resultCount = 0;
  let lineCount = 0;
  let noteCount = 0;
  let mediaCount = 0;
  for (const line of section.lines) {
    if (!DISPLAY_LINE_TYPES.includes(line.line_type)) {
      lineCount++;
      try {
        const result = results[section.key][line.key]['value'];
        if (result) {
          resultCount++;
        }
      } catch (err) {
        // console.log(err);
      }
      try {
        const result = results[section.key][line.key]['notes'];
        if (result) {
          noteCount++;
        }
      } catch (err) {
        // console.log(err);
      }
      try {
        const result = results[section.key][line.key]['media'];
        if (result) {
          mediaCount = result.length + mediaCount;
        }
      } catch (err) {
        // console.log(err);
      }
    }
  }
  const progress = (resultCount / lineCount) * 100;
  return { lineCount, resultCount, progress, noteCount, mediaCount };
}

export function calculateChecklistProgress(sections, results) {
  let resultCount = 0;
  let lineCount = 0;
  let noteCount = 0;
  let mediaCount = 0;
  for (const section of sections) {
    const p = calculateSectionProgress(section, results);
    resultCount = resultCount + p.resultCount;
    lineCount = lineCount + p.lineCount;
    noteCount = noteCount + p.noteCount;
    mediaCount = mediaCount + p.mediaCount;
  }
  let progress = 0;
  if (lineCount > 0) {
    progress = (resultCount / lineCount) * 100;
  }
  return { lineCount, resultCount, progress, noteCount, mediaCount };
}

/**
 * @description Prepare the results to be sent to the server
 * - only send the values that have changed
 * - set empty values for any values that have been removed
 *
 * @param {FinalFormState} formState - react-final-form state (formApi.getState())
 * @param {Object} template - checklist template
 * @returns {Object} payload
 */
export function prepareResults(formState, template, isNew = false) {
  const payload = {};

  if (isNew) {
    // for new checklists, send all values initial values and any dirty values
    return formState.values;
  }

  for (const [fieldName, isDirty] of Object.entries(formState.dirtyFields)) {
    if (!isDirty) {
      continue;
    }
    if (fieldName.includes('media[')) {
      // we will update media separately / whole array
      continue;
    }

    // fields -> values look like:
    //   "sectionKey.lineKey.value" -> { sectionKey: { lineKey: { value } } }
    //   "sectionKey.lineKey.notes" -> { sectionKey: { lineKey: { notes } } }
    //   "sectionKey.lineKey.media" -> { sectionKey: { lineKey: { media } } }
    //
    // using lodash get/set to handle
    //  https://docs-lodash.com/v4/set/
    //  https://docs-lodash.com/v4/get/
    //

    const initialValue = _.get(formState.initialValues, fieldName);
    let newValue = _.get(formState.values, fieldName);

    // handle empty values
    if (newValue == null) {
      // If form value is dirty, but not set,
      //  must explicitly set to empty
      if (typeof initialValue === 'string') {
        newValue = '';
      } else if (typeof initialValue === 'boolean') {
        newValue = false;
      } else if (initialValue instanceof Array) {
        newValue = [];
      }
    }

    // handle value transform based on line type
    const [sectionKey, lineKey, valueKey] = fieldName.split('.');
    const lineTemplate = getLineTemplate(template, sectionKey, lineKey);
    switch (lineTemplate?.line_type) {
      case CHECKLIST_LINE_TYPES.COLLECT_MEDIA:
        switch (valueKey) {
          case 'value':
            // do not set value for collect-media line type (handle below)
            break;
          case 'media':
            _.set(payload, fieldName, newValue);
            _.set(
              payload,
              `${sectionKey}.${lineKey}.value`,
              newValue.length > 0 ? 'true' : '' // string value 'true' if media is present
            );
            break;
          default:
            _.set(payload, fieldName, newValue);
            break;
        }
        break;
      default:
        _.set(payload, fieldName, newValue);
        break;
    }
  }

  return payload;
}

export function generateEmbeddedUrl(template, formState, location) {
  // generate the embedded url for the checklist with prefill values for a given template
  // a url support prefilling values for a checklist
  // https://origin/forms/new/<TEMPLATE_ID>?prefill__sectionKey.lineKey.value=<value>&prefill__sectionKey.lineKey.notes=<value>&prefill__sectionKey.lineKey.media=<value>

  const prefillQuery = {};
  for (const [fieldName] of Object.entries(formState.dirtyFields)) {
    const newValue = _.get(formState.values, fieldName);
    const pk = `prefill__${fieldName}`;
    prefillQuery[pk] = newValue;
  }

  if (isEmpty(prefillQuery)) {
    return null;
  }
  const newUrl = `${location.origin}/forms/new/${template.id}${toQueryString(prefillQuery)}`;
  return newUrl;
}
export function getSelectedRevisionIndex(revisions, selectedRevision) {
  for (let i = 0; i < revisions.length; i++) {
    if (revisions[i].id === selectedRevision) {
      return i;
    }
  }
  return revisions.length - 1;
}

export function getInitialValues(revIndex, revisions, results, revisionResults) {
  if (revIndex === revisions.length - 1) {
    return results; // if last revision is selected, display entire checklist
  }
  return revisionResults;
}

// convert num values to string if needed
export const formatNumToStringValue = options =>
  options.map(option => {
    return {
      ...option,
      ...(!isNaN(option.value) && { value: option.value.toString() }),
    };
  });

/**
 *
 * @param {Object} values - react-final-form values
 *
 * @returns {Object}
 */
export function buildExpressionContext(values) {
  return {
    $values: {
      ...flattenContext(values),
      /*
        my_section__my_line__my_value: '<value>',
        my_section__my_line__my_value: '<value>',
      */
    },
  };
}

/**
 * @param {Array} lineItems
 *     ex: [{ type: "text" }, { type: "line-group" }, { type: "text }, { type: "line-group-end" }]
 * @return {Array} nestedLineItems - updated line items
 *     ex: [{ type: "text" }, { type: "line-group", groupLines: [{ type: "text }] }]
 */
export function nestLineGroups(lineItems) {
  const nestedLineItems = []; // note - will potentially be shorter length array than passed
  if (lineItems) {
    // group lines together if preceded by a line-group and followed by a line-group-end
    lineItems.forEach((line, index, array) => {
      if (line.line_type === CHECKLIST_LINE_TYPES.LINE_GROUP) {
        line.groupLines = [];
        const lineGroupEndIndex = array.findIndex(
          (item, currentIndex) => currentIndex > index && item.line_type === 'line-group-end'
        );
        nestedLineItems.push(array[lineGroupEndIndex]); // don't want to include the line-group-end in the display
        for (const groupLine of array.slice(index + 1, lineGroupEndIndex)) {
          nestedLineItems.push(groupLine); // determine which lines are nested in a group and exclude them from processing twice
          line.groupLines.push(groupLine); // subgrouping of non line group types attached to main line group
        }
      }
    });
  }
  return nestedLineItems;
}

/** Extract for the ChecklistResults object with option to set a defaultValue */
export function extractFromResults(data, secKey, lineKey, valueKey, defaultValue) {
  if (data[secKey]) {
    if (data[secKey][lineKey]) {
      return data[secKey][lineKey][valueKey] ? data[secKey][lineKey][valueKey] : defaultValue;
    }
  }
  return defaultValue;
}

/**
 * Stringify the checklist results value for display
 *
 **/
export function stringifyValue(value, templateLine, lineType) {
  // if the key is missing in results you get an empty object
  if (isEmpty(value)) {
    return ''; // we want default to be empty string instead of {}
  }
  switch (lineType) {
    case CHECKLIST_LINE_TYPES.TEXT_BLOCK:
    case CHECKLIST_LINE_TYPES.IMAGE:
    case CHECKLIST_LINE_TYPES.LINE_GROUP_END:
    case CHECKLIST_LINE_TYPES.LINE_GROUP:
      return ''; // No value for these line types
    case CHECKLIST_LINE_TYPES.CALCULATION:
    case CHECKLIST_LINE_TYPES.TEXT:
    case CHECKLIST_LINE_TYPES.NUMBER:
    case CHECKLIST_LINE_TYPES.TEXTAREA:
      return value;
    case CHECKLIST_LINE_TYPES.TEXT_DATE:
    case CHECKLIST_LINE_TYPES.DATE:
      return apiDateToString(value, 'date');
    case CHECKLIST_LINE_TYPES.COLLECT_POSITION:
      return value.geometry.coordinates ? `${value.geometry.coordinates[1]}, ${value.geometry.coordinates[0]}` : '';
    case CHECKLIST_LINE_TYPES.CHOICE:
      return getLabel(value, templateLine.choices);
    case CHECKLIST_LINE_TYPES.MULTIPLE_CHOICE: {
      // PULL choice from template display choice label vs value that is saved.
      const choices = templateLine.choices;
      return value
        .map(v => {
          return getLabel(v, choices);
        })
        .join(', ');
    }
    case CHECKLIST_LINE_TYPES.NUMBER_LIST:
      return value.map(v => v).join(', ');
    // not sure if/how yet we need to handle these
    case CHECKLIST_LINE_TYPES.SIGNATURE:
    default:
  }
}

export function flattenChecklist(checklist, revision = undefined) {
  const sections = checklist['template']['sections'];
  const results = revision && revision['edit'] ? revision['edit'] : checklist['results']; // use revision edit results if passed
  const checklistId = checklist['id'];

  const flatLines = [];
  let lineCount = 0;
  for (const sec of sections) {
    const secKey = sec['key'];
    for (const line of sec['lines']) {
      const lineKey = line['key'];
      lineCount++;

      const media = extractFromResults(results, secKey, lineKey, 'media', []);
      const valueObj = extractFromResults(results, secKey, lineKey, 'value', {});

      flatLines.push({
        id: `${checklistId}::${secKey}::${lineKey}`,
        section: sec['label'],
        order: lineCount,
        key: line['key'],
        label: line['label'],
        description: line['description'],
        lineType: line['line_type'],
        valueObj: valueObj,
        value: stringifyValue(valueObj, line, line['line_type']),
        notes: extractFromResults(results, secKey, lineKey, 'notes', ''),
        media: media,
        mediaCount: media.length,
        url: line['url'],
      });
    }
  }
  return { flatLines };
}

/**
 * @description gets all of the error labels from the checklist.
 * @param {Object} errors - { section_key: { line_key: message } }
 * @param {Array} sections - from checklist instance fillable
 * @return {Array} error labels in the format of: '<section label> / <line label>'
 */
export const getChecklistErrorLabels = (errors = {}, sections = []) => {
  if (isEmpty(errors || {}) || isEmpty(sections || [])) {
    return [];
  }
  const errorLabels = sections.flatMap(section => {
    const sectionKey = section?.key;
    const errorKeys = errors[sectionKey] ? Object.keys(errors[sectionKey]) : [];
    return errorKeys.map(errorKey => {
      const line = section.lines.find(line => line.key === errorKey);

      return `${section.label} / ${line.label}`;
    });
  });
  return errorLabels;
};

/**
 * @param {Object} checklistTemplate
 * @param {String} sectionKey
 * @param {String} lineKey
 * @returns {Object} lineTemplate
 *    { key, label, description, line_type, choices, url, validator_objects }
 */
export function getLineTemplate(checklistTemplate, sectionKey, lineKey) {
  for (const section of checklistTemplate?.sections || []) {
    if (section.key === sectionKey) {
      for (const line of section.lines) {
        if (line.key === lineKey) {
          return line;
        }
      }
    }
  }

  console.warn(`Line template not found for ${sectionKey} ${lineKey}`);
  return {};
}
