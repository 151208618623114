import { all, put, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  MEASUREMENTS_REQUEST,
  MEASUREMENTS_SUCCESS,
  MEASUREMENTS_FAILURE,
  MEASUREMENTS_CREATE_REQUEST,
  MEASUREMENTS_CREATE_SUCCESS,
  MEASUREMENTS_CREATE_FAILURE,
  MEASUREMENTS_UPDATE_REQUEST,
  MEASUREMENTS_UPDATE_SUCCESS,
  MEASUREMENTS_UPDATE_FAILURE,
  MEASUREMENTS_DELETE_REQUEST,
  MEASUREMENTS_DELETE_SUCCESS,
  MEASUREMENTS_DELETE_FAILURE,
  MEASUREMENTS_ALL_REQUEST,
  MEASUREMENTS_ALL_SUCCESS,
  MEASUREMENTS_ALL_FAILURE,
  MEASUREMENTS_BULK_DELETE_FAILURE,
  MEASUREMENTS_BULK_DELETE_REQUEST,
  MEASUREMENTS_BULK_DELETE_SUCCESS,
  IMPORT_MEASUREMENTS_CREATE_REQUEST,
  IMPORT_MEASUREMENTS_CREATE_SUCCESS,
  IMPORT_MEASUREMENTS_CREATE_FAILURE,
} from './measurementsActions';

import {
  fetchMeasurements,
  createMeasurements,
  updateMeasurements,
  deleteMeasurements,
  fetchMeasurementsList,
  createImportMeasurements,
  deleteMeasurementsBulk,
} from '../../api/features/measurements';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';
import { pathToPage } from '../../utilities/strings';
import { tableViews } from '../../utilities/tables';
import { DIALOG_CLOSE } from '../../store/dialogActions';
import { SET_TABLE_QUERY } from '../../store/settings/tableActions';

const { measurementsTableViewKey: tableType } = tableViews;

export function* measurementsFetch(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield fetchMeasurements(id);
    if (data) {
      yield put({ type: MEASUREMENTS_SUCCESS, data });
    } else if (error) {
      yield put({ type: MEASUREMENTS_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, MEASUREMENTS_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, MEASUREMENTS_FAILURE);
  }
}

export function* watchMeasurementsFetch() {
  yield takeLatest(MEASUREMENTS_REQUEST, measurementsFetch);
}

export function* measurementsCreate(action) {
  const { body, nextRoute } = action;
  try {
    const { data, error, formError, response } = yield createMeasurements(body);
    if (data) {
      yield put({ type: MEASUREMENTS_CREATE_SUCCESS, data });
      if (nextRoute) {
        yield put(push(nextRoute));
      }
    } else if (error) {
      yield put({ type: MEASUREMENTS_CREATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: MEASUREMENTS_CREATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, MEASUREMENTS_CREATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, MEASUREMENTS_CREATE_FAILURE);
  }
}

export function* watchMeasurementsCreate() {
  yield takeLatest(MEASUREMENTS_CREATE_REQUEST, measurementsCreate);
}

export function* measurementsUpdate(action) {
  const { id, body, nextRoute } = action;
  try {
    const { data, error, formError, response } = yield updateMeasurements(id, body);
    if (data) {
      yield put({ type: MEASUREMENTS_UPDATE_SUCCESS, data });
      if (nextRoute) {
        yield put(push(nextRoute));
      }
    } else if (error) {
      yield put({ type: MEASUREMENTS_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: MEASUREMENTS_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, MEASUREMENTS_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, MEASUREMENTS_UPDATE_FAILURE);
  }
}

export function* watchMeasurementsUpdate() {
  yield takeLatest(MEASUREMENTS_UPDATE_REQUEST, measurementsUpdate);
}

export function* measurementsDelete(action) {
  const { id, nextRoute } = action;
  try {
    const { data, error, response } = yield deleteMeasurements(id);
    if (data) {
      yield put({ type: MEASUREMENTS_DELETE_SUCCESS });
      const query = yield select(state => state.measurements.all.query) || '';
      yield put({ type: MEASUREMENTS_ALL_REQUEST, query });
      yield put({ type: DIALOG_CLOSE });
      yield put(push(nextRoute));
    } else if (error) {
      yield put({ type: MEASUREMENTS_DELETE_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, MEASUREMENTS_DELETE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, MEASUREMENTS_DELETE_FAILURE);
  }
}

export function* watchMeasurementsDelete() {
  yield takeLatest(MEASUREMENTS_DELETE_REQUEST, measurementsDelete);
}

export function* measurementsBulkDelete(action) {
  const { ids } = action;
  try {
    const { data, error, response } = yield deleteMeasurementsBulk(ids);
    if (data) {
      yield put({ type: MEASUREMENTS_BULK_DELETE_SUCCESS });
      const query = yield select(state => state.measurements.all.query);
      yield put({ type: MEASUREMENTS_ALL_REQUEST, query });
      yield put({ type: DIALOG_CLOSE });
    } else if (error) {
      yield put({ type: MEASUREMENTS_BULK_DELETE_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, MEASUREMENTS_BULK_DELETE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, MEASUREMENTS_BULK_DELETE_FAILURE);
  }
}

export function* watchMeasurementsBulkDelete() {
  yield takeLatest(MEASUREMENTS_BULK_DELETE_REQUEST, measurementsBulkDelete);
}

export function* measurementsFetchAll(action) {
  const { query } = action;
  try {
    const { data, error, response } = yield fetchMeasurementsList(action.query);
    if (data) {
      yield put({ type: MEASUREMENTS_ALL_SUCCESS, data });
    } else if (error) {
      yield put({ type: MEASUREMENTS_ALL_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, MEASUREMENTS_ALL_FAILURE);
    }
    // save the query in the store
    if (pathToPage(location.pathname)) {
      const page = pathToPage(location.pathname);
      yield put({ type: SET_TABLE_QUERY, page, query, tableType });
    }
  } catch (error) {
    yield defaultErrorCatch(error, MEASUREMENTS_ALL_FAILURE);
  }
}

export function* watchMeasurementsFetchAll() {
  yield takeLatest(MEASUREMENTS_ALL_REQUEST, measurementsFetchAll);
}

export function* importMeasurementsCreate(action) {
  const { id, body } = action;
  try {
    const { data, error, formError, response } = yield createImportMeasurements(body);
    if (data) {
      yield put({ type: IMPORT_MEASUREMENTS_CREATE_SUCCESS, data });
      yield put(push(`/projects/${id}`));
    } else if (error) {
      yield put({ type: IMPORT_MEASUREMENTS_CREATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: IMPORT_MEASUREMENTS_CREATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, IMPORT_MEASUREMENTS_CREATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, IMPORT_MEASUREMENTS_CREATE_FAILURE);
  }
}

export function* watchImportMeasurementsCreate() {
  yield takeLatest(IMPORT_MEASUREMENTS_CREATE_REQUEST, importMeasurementsCreate);
}

export default function* measurementsSaga() {
  yield all([
    watchMeasurementsFetch(),
    watchMeasurementsCreate(),
    watchMeasurementsUpdate(),
    watchMeasurementsFetchAll(),
    watchMeasurementsDelete(),
    watchImportMeasurementsCreate(),
    watchMeasurementsBulkDelete(),
  ]);
}
