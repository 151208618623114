import { USER_PROFILE_REQUEST, USER_PROFILE_SUCCESS, USER_PROFILE_FAILURE } from './profileActions';

export const initialStateProfile = {
  loading: false,
  error: '',
  data: { company: {}, roles: [], preferences: {} },
};

export const profileReducer = (state = initialStateProfile, action) => {
  switch (action.type) {
    case USER_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case USER_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        data: {},
      };
    default:
      return state;
  }
};

export default profileReducer;
