import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import { Form, FormSpy } from 'react-final-form';
import createDecorator from 'final-form-calculate';
import moment from 'moment';
import { diff } from 'deep-object-diff';

import { Paper, Grid, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FieldEditAssetAutocomplete from './FieldEditAssetAutocomplete';
import FieldEditDefectAutocomplete from './FieldEditDefectAutocomplete';
import FieldEditAutocomplete from './FieldEditAutocomplete';
import FieldEditUserAutocomplete from './FieldEditUserAutocomplete';
import FieldEditMultiSelect from './FieldEditMultiSelect';
import FieldEditString from './FieldEditString';
import FieldEditGeoPoint from './FieldEditGeoPoint';
import FieldEditFile from './FieldEditFile';
import FieldParseFile from './FieldParseFile';
import FieldEditSelect from './FieldEditSelect';
import FieldEditCheckbox from './FieldEditCheckbox';
import FieldEditDate from './FieldEditDate';
import FieldEditDateTime from './FieldEditDateTime';
import FieldEditKeyValue from './FieldEditKeyValue';
import FieldEditArray from './FieldEditArray';
import FieldDisplayTable from './FieldDisplayTable';
import FieldDisplayString from './FieldDisplayString';
import ViewDisplayImage from './ViewDisplayImage';
import DefaultFormToolbar from './DefaultFormToolbar';
import Error from '../displays/Error';
import StatusOverlay from '../displays/StatusOverlay';
import { usePermissions } from '../../../hooks/settingsHooks';
import Denied from '../displays/Denied';
import Markdown from '../Markdown';
/**
 * Shared component for simple forms that either creates or updates a feature.<br>
 * This allows forms to be created by passing in objects that defines how the form is displayed and handled<br><br>
 * Examples: Reservations.js and ReportTypes.js
 */

const useStyles = heightOffset =>
  makeStyles(theme => ({
    root: {
      margin: theme.spacing(4, 'auto'),
      padding: theme.spacing(4, 2),
      maxWidth: theme.spacing(75),
    },
    embed: {
      margin: 0,
      padding: 0,
    },
    form: {
      maxHeight: `calc(100vh - ${theme.spacing(20)})`,
    },
    embedForm: {
      maxHeight: `calc(100vh - ${heightOffset || theme.spacing(24)})`,
    },
    title: {
      paddingTop: theme.spacing(2),
    },
    body: {
      padding: theme.spacing(2, 4),
      textAlign: 'left',
    },
    bodyScroll: {
      flexGrow: 1,
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
    },
    sectionHeader: {
      padding: theme.spacing(1, 0),
    },
    buttonGroup: {
      padding: theme.spacing(2),
    },
  }));

export const getNonFieldError = (errors = {}) => {
  if (errors && 'non_field_errors' in errors) {
    return errors.non_field_errors;
  }
  return '';
};

// For edits to an object we want to send the difference
export const buildPatch = (initialValues, values) => {
  const changedValues = {
    id: values.id,
  };
  const changes = diff(initialValues, values);
  Object.keys(changes).forEach(key => {
    if (moment.isMoment(values[key])) {
      changedValues[key] = moment(values[key]).format('YYYY-MM-DD');
    } else if (values[key] === undefined) {
      // for some reason a cleared field does not show up in values in the onSubmit function for React Final Form
      // apparently there are issues for wanting the field there and others that want the field removed:
      // https://github.com/final-form/react-final-form/issues/430
      if (typeof initialValues[key] === 'string') {
        changedValues[key] = '';
      } else if (typeof initialValues[key] === 'boolean') {
        changedValues[key] = false;
      } else if (typeof initialValues[key] === 'number') {
        changedValues[key] = null;
      } else {
        console.error(`Handle Common Form returning undefined value for ${typeof initialValues[key]}`);
      }
    } else {
      // the diff returns objects vs arrays, so send the whole array
      // we do not currently support patching parts of an array on the server.
      changedValues[key] = values[key];
    }
  });
  return changedValues;
};

const CommonForm = props => {
  const {
    update,
    rename,
    title,
    initialValues,
    fieldSettings,
    formSettings,
    onSubmit,
    decorators,
    mutators,
    validate,
    loading,
    dirty,
    isDirty,
    error,
    backLink,
    keepDirtyOnReinitialize,
    toolbar: Toolbar,
    customToolbarProps,
    embed,
    helperText,
    createPermissionName,
    updatePermissionName,
    permissionCallback,
    heightOffset,
  } = props;
  const { fieldOrder, removeField, hideField } = formSettings;
  const classes = useStyles(heightOffset)();

  const permissions = usePermissions();

  const checkPermissions = () => {
    if (update && updatePermissionName) {
      return permissions[updatePermissionName];
    }
    if (createPermissionName) {
      return permissions[createPermissionName];
    }
    return true;
  };

  const isFormAllowed = checkPermissions();
  const permissionError = permissionCallback();

  // decorators need to be unchanging across renders.
  const rffDecorators = useMemo(
    // decorators are in the form:
    // {
    //   field: <name of field to check>
    //   updates: {
    //     <name of field to update>: (<value of field to check>, <form values>) => { return <updated field value> }
    //   }
    // }
    () => decorators.map(decorator => createDecorator(decorator)),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (!isFormAllowed || permissionError) {
    return <Denied message={permissionError.message} backLink={backLink || permissionError.backLink} />;
  }

  const renderField = (key, index, value) => {
    // do not show any keys from the api that does not have a setting passed in.
    if (!fieldSettings[key]) return null;
    const { type, fieldProps, cellProps } = fieldSettings[key];

    // if a fieldSettings needs to set a value for the field,
    // pass in a function that accepts the current value from the form.
    if (typeof fieldProps.value === 'function') fieldProps.value = fieldProps.value(value);

    switch (type) {
      case 'multi':
        return (
          <Grid item xs={12} {...cellProps} key={index}>
            <FieldEditMultiSelect {...fieldProps} />
          </Grid>
        );
      case 'text':
        return (
          <Grid item xs={12} {...cellProps} key={index}>
            <FieldEditString {...fieldProps} />
          </Grid>
        );
      case 'geo':
        return (
          <Grid item xs={12} {...cellProps} key={index}>
            <FieldEditGeoPoint {...fieldProps} />
          </Grid>
        );
      case 'file':
        return (
          <Grid item xs={12} {...cellProps} key={index}>
            <FieldEditFile {...fieldProps} />
          </Grid>
        );
      case 'parse-file':
        return (
          <Grid item xs={12} {...cellProps} key={index}>
            <FieldParseFile {...fieldProps} />
          </Grid>
        );
      case 'autocomplete':
        return (
          <Grid item xs={12} {...cellProps} key={index}>
            <FieldEditAutocomplete {...fieldProps} />
          </Grid>
        );
      case 'asset-autocomplete':
        return (
          <Grid item xs={12} {...cellProps} key={index}>
            <FieldEditAssetAutocomplete {...fieldProps} />
          </Grid>
        );
      case 'defect-autocomplete':
        return (
          <Grid item xs={12} {...cellProps} key={index}>
            <FieldEditDefectAutocomplete {...fieldProps} />
          </Grid>
        );
      case 'select':
        return (
          <Grid item xs={12} {...cellProps} key={index}>
            <FieldEditSelect {...fieldProps} />
          </Grid>
        );
      case 'checkbox':
        return (
          <Grid item xs={12} {...cellProps} key={index}>
            <FieldEditCheckbox {...fieldProps} />
          </Grid>
        );
      case 'date':
        return (
          <Grid item xs={12} {...cellProps} key={index}>
            <FieldEditDate {...fieldProps} />
          </Grid>
        );
      case 'datetime':
        return (
          <Grid item xs={12} {...cellProps} key={index}>
            <FieldEditDateTime {...fieldProps} />
          </Grid>
        );
      case 'key-value':
        return (
          <Grid item xs={12} {...cellProps} key={index}>
            <FieldEditKeyValue {...fieldProps} />
          </Grid>
        );
      case 'array':
        return (
          <Grid item xs={12} {...cellProps} key={index}>
            <FieldEditArray {...fieldProps} />
          </Grid>
        );
      case 'table':
        return (
          <Grid item xs={12} {...cellProps} key={index}>
            <FieldDisplayTable {...fieldProps} />
          </Grid>
        );
      case 'display':
        return (
          <Grid item xs={12} {...cellProps} key={index}>
            <FieldDisplayString {...fieldProps} />
          </Grid>
        );
      case 'user-autocomplete':
        return (
          <Grid item xs={12} {...cellProps} key={index}>
            <FieldEditUserAutocomplete {...fieldProps} />
          </Grid>
        );
      case 'image':
        return (
          <Grid item xs={12} {...cellProps} key={index}>
            <ViewDisplayImage {...fieldProps} />
          </Grid>
        );
      case 'component':
        // generic case where the View component can pass in a component.
        // needs to be in the format `value: () => <FieldComponent />` in the fieldProps
        const Component = fieldProps.component(); // eslint-disable-line no-case-declarations
        return (
          <Grid item xs={12} {...cellProps} key={index}>
            <Component {...fieldProps} />
          </Grid>
        );
      case 'markdown':
        return (
          <Grid item xs={12} {...cellProps} key={index}>
            <Markdown linkTarget="_blank">{fieldProps.source}</Markdown>
          </Grid>
        );
      default:
        return null;
    }
  };

  const handleSpyChange = values => {
    isDirty(!values.pristine);
  };

  const localOnSubmit = (values, form) => {
    if (update || rename) {
      // determine what fields have changed and only pass along those fields
      // the api expects to be called with a PATCH and only pass the updated fields in the body
      // if updating, pass along the id, otherwise id will be undefined
      const changedValues = buildPatch(initialValues, values);
      onSubmit(changedValues);
    } else {
      for (const [key, value] of Object.entries(values)) {
        if (moment.isMoment(value)) {
          values[key] = moment(value).format('YYYY-MM-DD');
        }
      }
      onSubmit(values);
    }
  };

  const renderForm = () => {
    return (
      <Form
        keepDirtyOnReinitialize={keepDirtyOnReinitialize}
        initialValues={initialValues}
        onSubmit={localOnSubmit}
        decorators={rffDecorators}
        mutators={mutators}
        validate={validate}
        render={({ handleSubmit, submitting, pristine, values, errors, submitFailed, form }) => {
          const checkValidAfterSubmit = submitFailed && Object.entries(errors).length !== 0;
          const nonFieldError = getNonFieldError(errors);
          const disableSubmitButton = submitting || (pristine && !dirty) || loading || checkValidAfterSubmit;
          const defaultToolbarProps = {
            title,
            values,
            submitDisabled: disableSubmitButton,
            backLink,
            reset: form.reset,
          };
          let toolbarProps = defaultToolbarProps;
          if (customToolbarProps) {
            toolbarProps = { ...defaultToolbarProps, ...customToolbarProps };
          }
          return (
            <>
              <Prompt
                // when={(!pristine || dirty) && !submitting}
                when={false}
                message="You have unsubmitted changes, are you sure you want to continue?"
              />
              <form onSubmit={handleSubmit}>
                <Grid
                  container
                  direction="column"
                  alignItems="stretch"
                  wrap="nowrap"
                  className={embed ? classes.embedForm : classes.form}>
                  <Grid item>
                    <FormSpy subscription={{ pristine: true }} onChange={handleSpyChange} />
                    <Toolbar {...toolbarProps} />
                    {helperText !== '' && <div className={classes.body}>{helperText}</div>}
                    <Divider />
                  </Grid>
                  <Grid item className={classes.bodyScroll}>
                    <Error error={error || nonFieldError} />
                    <Grid container alignItems="flex-start" className={classes.body}>
                      {Object.keys(fieldOrder).map(fieldKey => {
                        const formFields = fieldOrder[fieldKey].fields
                          .filter(item => {
                            const keepField = !removeField(item) && !hideField(values, item);
                            return keepField;
                          })
                          .map((item, index) => renderField(item, index, values[item]));
                        if (formFields.length === 0) return null;
                        return (
                          <Grid key={fieldKey} xs={12} item className={classes.section}>
                            <h4 className={classes.sectionHeader}>
                              <strong>{fieldOrder[fieldKey].display}</strong>
                            </h4>
                            <Grid container alignItems="flex-start" spacing={2}>
                              {formFields}
                            </Grid>
                          </Grid>
                        );
                      })}
                      {Array.isArray(error) ? error.map(e => <Error key={e} error={e} />) : <Error error={error} />}
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </>
          );
        }}
      />
    );
  };

  return (
    <Paper className={embed ? classes.embed : classes.root}>
      <StatusOverlay on={loading} dual>
        {renderForm()}
      </StatusOverlay>
    </Paper>
  );
};

CommonForm.defaultProps = {
  update: false,
  rename: false,
  title: '',
  initialValues: {},
  fieldSettings: {},
  decorators: [],
  mutators: {},
  loading: false,
  dirty: false,
  isDirty: () => {},
  error: '',
  backLink: '',
  keepDirtyOnReinitialize: true,
  toolbar: DefaultFormToolbar,
  customToolbarProps: undefined,
  embed: false,
  helperText: '',
  createPermissionName: undefined,
  updatePermissionName: undefined,
  permissionCallback: () => {},
  heightOffset: '',
};

CommonForm.propTypes = {
  /** if the form is updating the feature */
  update: PropTypes.bool,
  /** if the form is renaming the feature */
  rename: PropTypes.bool,
  /** title for the form */
  title: PropTypes.string,
  /** object for defining the field order, fields to remove and fields to hide */
  formSettings: PropTypes.object.isRequired,
  /** callback for submit event */
  onSubmit: PropTypes.func.isRequired,
  /** if updating, pull in the current values of the feature */
  initialValues: PropTypes.object,
  /** object for defining how each field is displayed (does not show the field by default) */
  fieldSettings: PropTypes.object,
  /** defines fields that change depending on other fields */
  decorators: PropTypes.array,
  /** passed in mutators (i.e. arrayMutators) */
  mutators: PropTypes.object,
  /** shows loading message and disables submit button when true */
  loading: PropTypes.bool,
  /** override internal pristine setting
   * (i.e. user has changed a field, which causes a re-render and the submit button should be active) */
  dirty: PropTypes.bool,
  /** callback to determine if the form is dirty.
   * allows setting the status of something outside the form if the form is dirty */
  isDirty: PropTypes.func,
  /** shows error message if not empty string */
  error: PropTypes.string,
  /** defines custom validations per field */
  validate: PropTypes.func.isRequired,
  backLink: PropTypes.string,
  /** if the form needs to be rerendered, this keeps the form values between renders */
  keepDirtyOnReinitialize: PropTypes.bool,
  /** Allows for custom toolbar component */
  toolbar: PropTypes.func,
  /** Allows for custom toolbar component props */
  customToolbarProps: PropTypes.object,
  /** Switch the style from standalone to embedded inside a page */
  embed: PropTypes.bool,
  /** Custom text above the main form content */
  helperText: PropTypes.string,
  /** Permission name for creating instances (projects, assets, checklists, etc. )**/
  createPermissionName: PropTypes.string,
  /** Permission name for updating instance (projects, assets, checklists, etc. )**/
  updatePermissionName: PropTypes.string,
  /** Optional function to return error message based on more data **/
  permissionCallback: PropTypes.func,
  /** pixels to shrink the form to fit the screen. */
  heightOffset: PropTypes.string,
};

export default CommonForm;
