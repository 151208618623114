import { DRAWER_OPEN, DRAWER_CLOSE } from './drawerActions';

const initialState = {
  open: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DRAWER_OPEN:
      return {
        ...initialState,
      };
    case DRAWER_CLOSE:
      return {
        open: false,
      };
    default:
      return state;
  }
}
