// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import React from 'react';
import CommonRoutes from './utilities/CommonRoutes';
import AssessmentTypesForm from '../../components/assessmentTypes/AssessmentTypesForm';
import AssessmentTypesView from '../../components/assessmentTypes/AssessmentTypesView';
import AssessmentTypesTablePage from '../../components/assessmentTypes/AssessmentTypesTablePage';
import MainLayout from '../layouts/MainLayout';

const AssessmentTypesRoutes = props => {
  return (
    <CommonRoutes
      pageTitle="Assessment Types"
      layout={MainLayout}
      Edit={<AssessmentTypesForm update />}
      Create={<AssessmentTypesForm />}
      View={<AssessmentTypesView />}
      All={<AssessmentTypesTablePage />}
    />
  );
};

export default AssessmentTypesRoutes;
