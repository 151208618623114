// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import React from 'react';
import CommonRoutes from './utilities/CommonRoutes';
import CmlsForm from '../../components/cmls/CmlsForm';
import CmlsDetail from '../../components/cmls/CmlsDetail';
import CmlsTablePage from '../../components/cmls/CmlsTablePage';
import MainLayout from '../layouts/MainLayout';

const CmlsRoutes = props => {
  return (
    <CommonRoutes
      pageTitle="Cmls"
      layout={MainLayout}
      Edit={<CmlsForm update />}
      Create={<CmlsForm />}
      View={<CmlsDetail />}
      All={<CmlsTablePage />}
    />
  );
};

export default CmlsRoutes;
