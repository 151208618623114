import { requestFactory, responseHandlerForm } from '../base';

const get = requestFactory('get');
const post = requestFactory('post', { handler: responseHandlerForm });
const patch = requestFactory('patch', { handler: responseHandlerForm });
const del = requestFactory('del');
const bulkDel = requestFactory('bulkDel');

export const fetchInspectionMediaList = async query => get('/api/inspection-media/', query);
export const fetchInspectionMedia = async id => get(`/api/inspection-media/${id}/`);
export const createInspectionMedia = async body => post('/api/inspection-media/', body);
export const updateInspectionMedia = async (id, body) => patch(`/api/inspection-media/${id}/`, body);
export const updateInspectionMediaBulk = async (ids, body) => post('/api/inspection-media/bulk-edit/', { ids, edit: body });
export const deleteInspectionMedia = async id => del(`/api/inspection-media/${id}/`);
export const deleteInspectionMediaBulk = async ids => bulkDel(`/api/inspection-media/bulk-delete/`, ids);

export const createInspectionMediaOverlay = async (mediaId, body) =>
  post(`/api/inspection-media/${mediaId}/overlays/`, body);
export const updateInspectionMediaOverlay = async (mediaId, body) =>
  patch(`/api/inspection-media/${mediaId}/overlays/`, body);
export const deleteInspectionMediaOverlay = async id => del(`/api/inspection-media-overlays/${id}/`);
