import { requestFactory, responseHandlerForm } from '../base';

const get = requestFactory('get');
const post = requestFactory('post', { handler: responseHandlerForm });
const patch = requestFactory('patch', { handler: responseHandlerForm });
const del = requestFactory('del');

export const fetchLibraryMediaList = async query => get('/api/library-media/', query);
export const fetchLibraryMedia = async id => get(`/api/library-media/${id}/`);
export const createLibraryMedia = async body => post('/api/library-media/', body);
export const updateLibraryMedia = async (id, body) => patch(`/api/library-media/${id}/`, body);
export const deleteLibraryMedia = async id => del(`/api/library-media/${id}/`);
