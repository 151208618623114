import { all, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  SCHEDULE_EVENTS_REQUEST,
  SCHEDULE_EVENTS_SUCCESS,
  SCHEDULE_EVENTS_FAILURE,
  SCHEDULE_EVENTS_CREATE_REQUEST,
  SCHEDULE_EVENTS_CREATE_SUCCESS,
  SCHEDULE_EVENTS_CREATE_FAILURE,
  SCHEDULE_EVENTS_UPDATE_REQUEST,
  SCHEDULE_EVENTS_UPDATE_SUCCESS,
  SCHEDULE_EVENTS_UPDATE_FAILURE,
  SCHEDULE_EVENTS_DELETE_REQUEST,
  SCHEDULE_EVENTS_DELETE_SUCCESS,
  SCHEDULE_EVENTS_DELETE_FAILURE,
  SCHEDULE_EVENTS_ALL_REQUEST,
  SCHEDULE_EVENTS_ALL_SUCCESS,
  SCHEDULE_EVENTS_ALL_FAILURE,
} from './scheduleEventsActions';

import {
  fetchScheduleEvents,
  createScheduleEvents,
  updateScheduleEvents,
  deleteScheduleEvents,
  fetchScheduleEventsList,
} from '../../api/features/scheduleEvents';
import { history } from '../../providers/StoreProvider';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';
import { tableViews } from '../../utilities/tables';
import { DIALOG_CLOSE } from '../../store/dialogActions';
import { SET_TABLE_QUERY } from '../../store/settings/tableActions';
import { pathToPage } from '../../utilities/strings';

const { scheduleEventsTableViewKey: tableType } = tableViews;

export function* scheduleEventsFetch(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield fetchScheduleEvents(id);
    if (data) {
      yield put({ type: SCHEDULE_EVENTS_SUCCESS, data });
    } else if (error) {
      yield put({ type: SCHEDULE_EVENTS_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, SCHEDULE_EVENTS_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, SCHEDULE_EVENTS_FAILURE);
  }
}

export function* watchScheduleEventsFetch() {
  yield takeLatest(SCHEDULE_EVENTS_REQUEST, scheduleEventsFetch);
}

export function* scheduleEventsCreate(action) {
  const { body } = action;
  try {
    const { data, error, formError, response } = yield createScheduleEvents(body);
    if (data) {
      yield put({ type: SCHEDULE_EVENTS_CREATE_SUCCESS, data });
      yield put(push('/schedule-events'));
    } else if (error) {
      yield put({ type: SCHEDULE_EVENTS_CREATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: SCHEDULE_EVENTS_CREATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, SCHEDULE_EVENTS_CREATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, SCHEDULE_EVENTS_CREATE_FAILURE);
  }
}

export function* watchScheduleEventsCreate() {
  yield takeLatest(SCHEDULE_EVENTS_CREATE_REQUEST, scheduleEventsCreate);
}

export function* scheduleEventsUpdate(action) {
  const { id, body } = action;
  try {
    const { data, error, formError, response } = yield updateScheduleEvents(id, body);
    if (data) {
      yield put({ type: SCHEDULE_EVENTS_UPDATE_SUCCESS, data });
      yield put(push(`/schedule-events/${data.id}`));
    } else if (error) {
      yield put({ type: SCHEDULE_EVENTS_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: SCHEDULE_EVENTS_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, SCHEDULE_EVENTS_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, SCHEDULE_EVENTS_UPDATE_FAILURE);
  }
}

export function* watchScheduleEventsUpdate() {
  yield takeLatest(SCHEDULE_EVENTS_UPDATE_REQUEST, scheduleEventsUpdate);
}

export function* scheduleEventsDelete(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield deleteScheduleEvents(id);
    if (data) {
      yield put({ type: SCHEDULE_EVENTS_DELETE_SUCCESS });
      yield put({ type: DIALOG_CLOSE });
      const query = yield select(state => state.scheduleEvents.all.query);
      yield put({ type: SCHEDULE_EVENTS_ALL_REQUEST, query });
    } else if (error) {
      yield put({ type: SCHEDULE_EVENTS_DELETE_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, SCHEDULE_EVENTS_DELETE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, SCHEDULE_EVENTS_DELETE_FAILURE);
  }
}

export function* watchScheduleEventsDelete() {
  yield takeLatest(SCHEDULE_EVENTS_DELETE_REQUEST, scheduleEventsDelete);
}

export function* scheduleEventsFetchAll(action) {
  const { query } = action;
  const location = history.location;

  try {
    const { data, error, response } = yield fetchScheduleEventsList(action.query);
    if (data) {
      yield put({ type: SCHEDULE_EVENTS_ALL_SUCCESS, data });
    } else if (error) {
      yield put({ type: SCHEDULE_EVENTS_ALL_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, SCHEDULE_EVENTS_ALL_FAILURE);
    }
    // save the query in the store
    if (pathToPage(location.pathname)) {
      const page = pathToPage(location.pathname);
      yield put({ type: SET_TABLE_QUERY, page, query, tableType });
    }
  } catch (error) {
    yield defaultErrorCatch(error, SCHEDULE_EVENTS_ALL_FAILURE);
  }
}

export function* watchScheduleEventsFetchAll() {
  yield takeLatest(SCHEDULE_EVENTS_ALL_REQUEST, scheduleEventsFetchAll);
}

export default function* scheduleEventsSaga() {
  yield all([
    watchScheduleEventsFetch(),
    watchScheduleEventsCreate(),
    watchScheduleEventsUpdate(),
    watchScheduleEventsFetchAll(),
    watchScheduleEventsDelete(),
  ]);
}
