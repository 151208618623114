import { requestFactory, responseHandlerForm } from '../base';

const get = requestFactory('get');
const post = requestFactory('post', { handler: responseHandlerForm });
const patch = requestFactory('patch', { handler: responseHandlerForm });
const del = requestFactory('del');

export const fetchAssessmentTypesList = async query => get('/api/assessment-types/', query);
export const fetchAssessmentTypes = async id => get(`/api/assessment-types/${id}/`);
export const createAssessmentTypes = async body => post('/api/assessment-types/', body);
export const updateAssessmentTypes = async (id, body) => patch(`/api/assessment-types/${id}/`, body);
export const deleteAssessmentTypes = async id => del(`/api/assessment-types/${id}/`);
