import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import UsersTable from './UsersTable';
import { getAllUsers } from '../../store/features/usersActions';
import { toQueryString } from '../../utilities/strings';
import { setTableParamObj } from '../../utilities/objects';
import { useTableViews } from '../../hooks/settingsHooks';
import { tableViews } from '../../utilities/tables';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const UsersTablePage = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const { usersTableViewKey } = tableViews;
  const { pageSize } = useTableViews(usersTableViewKey);

  // check for page size settings
  const queryParams = setTableParamObj(location, pageSize);

  useEffect(() => {
    dispatch(getAllUsers(queryParams));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const tableChangeHandler = query => {
    // no need to fetch data if the query is the same.
    const newSearch = toQueryString(query);
    if (location.search === newSearch) return;
    const newLocation = location;
    newLocation.search = newSearch;
    history.push(newLocation);
    dispatch(getAllUsers(query));
  };

  return (
    <div className={classes.root}>
      <UsersTable title="Users" queryParamObj={queryParams} tableChangeHandler={tableChangeHandler} />
    </div>
  );
};

export default UsersTablePage;
