import { useMemo, useState } from 'react';

export const useTableRowSelectionManagerOptions = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  // const [selectedIds, setSelectedIds] = useState([]);

  const clearSelections = () => {
    setSelectedRows([]);
    // setSelectedIds([]);
  };

  const tableOptions = useMemo(() => {
    return {
      rowsSelected: selectedRows,
      onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
        setSelectedRows(rowsSelected);
      },
      tableChange: (action, tableData) => {
        switch (action) {
          case 'changePage':
          case 'changeRowsPerPage':
            clearSelections();
            break;
          default:
            break;
        }
      },
    };
  }, [selectedRows, setSelectedRows]);

  // return tableOptions;
  return {
    clearSelections,
    tableOptions,
  };
};
