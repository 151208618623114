export const LIBRARY_MEDIA_REQUEST = 'LIBRARY_MEDIA_REQUEST';
export const LIBRARY_MEDIA_SUCCESS = 'LIBRARY_MEDIA_SUCCESS';
export const LIBRARY_MEDIA_FAILURE = 'LIBRARY_MEDIA_FAILURE';
export const LIBRARY_MEDIA_CREATE_REQUEST = 'LIBRARY_MEDIA_CREATE_REQUEST';
export const LIBRARY_MEDIA_CREATE_SUCCESS = 'LIBRARY_MEDIA_CREATE_SUCCESS';
export const LIBRARY_MEDIA_CREATE_FAILURE = 'LIBRARY_MEDIA_CREATE_FAILURE';
export const LIBRARY_MEDIA_UPDATE_REQUEST = 'LIBRARY_MEDIA_UPDATE_REQUEST';
export const LIBRARY_MEDIA_UPDATE_SUCCESS = 'LIBRARY_MEDIA_UPDATE_SUCCESS';
export const LIBRARY_MEDIA_UPDATE_FAILURE = 'LIBRARY_MEDIA_UPDATE_FAILURE';
export const LIBRARY_MEDIA_DELETE_REQUEST = 'LIBRARY_MEDIA_DELETE_REQUEST';
export const LIBRARY_MEDIA_DELETE_SUCCESS = 'LIBRARY_MEDIA_DELETE_SUCCESS';
export const LIBRARY_MEDIA_DELETE_FAILURE = 'LIBRARY_MEDIA_DELETE_FAILURE';
export const LIBRARY_MEDIA_ALL_REQUEST = 'LIBRARY_MEDIA_ALL_REQUEST';
export const LIBRARY_MEDIA_ALL_SUCCESS = 'LIBRARY_MEDIA_ALL_SUCCESS';
export const LIBRARY_MEDIA_ALL_FAILURE = 'LIBRARY_MEDIA_ALL_FAILURE';

export const getLibraryMedia = id => {
  return {
    type: LIBRARY_MEDIA_REQUEST,
    id,
  };
};

export const createLibraryMedia = body => {
  return {
    type: LIBRARY_MEDIA_CREATE_REQUEST,
    body,
  };
};

export const updateLibraryMedia = (id, body) => {
  return {
    type: LIBRARY_MEDIA_UPDATE_REQUEST,
    id,
    body,
  };
};

export const deleteLibraryMedia = (id, query) => {
  return {
    type: LIBRARY_MEDIA_DELETE_REQUEST,
    id,
    query,
  };
};

export const getAllLibraryMedia = query => {
  return {
    type: LIBRARY_MEDIA_ALL_REQUEST,
    query,
  };
};
