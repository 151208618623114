import React, { useEffect } from 'react';

// import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LoadingPage from '../shared/displays/LoadingPage';

import { getChecklists } from '../../store/features/checklistsActions';
import { isEmpty } from '../../utilities/objects';
import ChecklistToolbar from './ChecklistToolbar';
import ChecklistViewer from './ChecklistViewer';
import { useQuery } from '../../hooks/locationHooks';
import Error from '../shared/displays/Error';
import ChecklistBreadcrumbs from '../checklistEditor/nav/ChecklistBreadcrumbs';
import FormView from '../checklists/forms/FormView';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(10),
  },
  table: {
    padding: theme.spacing(4),
  },
  thumbnail: {
    height: '75px',
  },
}));

const ChecklistViewerPage = props => {
  const classes = useStyles();
  const location = useLocation();
  const { id: checklistId } = useParams();
  const checklistState = useSelector(state => state.checklists.each);

  const [query] = useQuery();
  const dispatch = useDispatch();

  const isLoading = (() => {
    if (checklistState.loading) {
      return true;
    }
    return false;
  })();

  /**
   * Refresh checklist if it is empty or if the id does not match the current id
   */
  const shouldRefresh = (() => {
    if (isLoading) {
      return false; // do not trigger refresh if loading
    }
    if (isEmpty(checklistState.data)) {
      return true;
    }
    if (checklistState.id.toString() !== checklistId.toString()) {
      return true;
    }
    return false;
  })();

  useEffect(() => {
    if (shouldRefresh) {
      dispatch(getChecklists(checklistId));
    }
  }, [checklistId, dispatch, shouldRefresh]);

  const refresh = () => {
    dispatch(getChecklists(checklistId));
  };

  if (isLoading || shouldRefresh) {
    return <LoadingPage />;
  }

  if (checklistState?.error) {
    return <Error error={`Checklist: ${checklistState.error}`} style={{ marginTop: '56px', fontSize: '40px' }} />;
  }

  const title = <ChecklistBreadcrumbs checklist={checklistState.data} />;
  const backLink = location?.state?.back ? location.state.back.pathname : undefined;

  return (
    <Container maxWidth={false} disableGutters>
      <div className="container">
        <ChecklistToolbar
          checklist={checklistState.data}
          revisions={[]}
          title={title}
          refresh={refresh}
          backLink={backLink}
        />
        <Grid container direction="column" className={classes.root}>
          <Grid item className={classes.table}>
            <ChecklistViewer checklist={checklistState.data} selectedLine={query.line} />
          </Grid>
          <Grid item>
            <FormView data={checklistState.data} />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default ChecklistViewerPage;
