export const SET_BULK_IMPORTS_TABLE_VIEW = 'SET_BULK_IMPORTS_TABLE_VIEW';

export const setBulkImportsTableView = (column, action) => {
  return {
    type: SET_BULK_IMPORTS_TABLE_VIEW,
    column,
    action,
  };
};

export const bulkImportsTableViewReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_BULK_IMPORTS_TABLE_VIEW:
      return {
        ...state,
        [action.column]: action.action,
      };
    default:
      return state;
  }
};
