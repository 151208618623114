import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Form } from 'react-final-form';
import PrimaryButton from '../../components/shared/buttons/PrimaryButton';
import SecondaryButton from '../../components/shared/buttons/SecondaryButton';
import { closeDialog } from '../../store/dialogActions';
import { getAllNotificationRules } from '../../store/features/notificationRulesActions';
import FieldEditAutocomplete from '../shared/form/FieldEditAutocomplete';
import { useRules } from '../../hooks/optionsHooks';
import {
  createNotificationRuleSubscribers,
  clearEachError,
} from '../../store/features/notificationRuleSubscribersActions';
import { getUserProfile } from '../../store/getters/profileActions';
import Error from '../shared/displays/Error';
import { getNonFieldError } from '../shared/form/Common';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    height: '100%',
    textAlign: 'left',
  },
  form: {
    margin: theme.spacing(1),
    height: '90%',
  },
  buttonGroup: {
    marginTop: theme.spacing(2),
  },
}));

const SubscribeForm = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ruleOptions = useRules();

  useEffect(() => {
    dispatch(getAllNotificationRules());
    dispatch(getUserProfile());
  }, []); // eslint-disable-line

  const {
    data: { id },
  } = useSelector(state => state.profile);
  const { error, formError } = useSelector(state => state.notificationRuleSubscribers.each);
  const handleCloseDialog = () => {
    dispatch(closeDialog());
  };

  useEffect(() => {
    if (formError || error) {
      dispatch(clearEachError());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDialogSubmit = values => {
    values.user = id;
    dispatch(createNotificationRuleSubscribers(values));
  };

  const validate = values => {
    const errors = {};
    if (formError) {
      // for all of the errors returned from the form - display them by key
      for (const [key, value] of Object.entries(formError)) {
        errors[key] = value;
      }
    }
    return errors;
  };

  return (
    <Form
      onSubmit={handleDialogSubmit}
      validate={validate}
      initialValues={{}}
      render={({ handleSubmit, errors }) => {
        const nonFieldError = getNonFieldError(errors);
        return (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Grid container direction="column" justifyContent="space-between" className={classes.formControl}>
              <Grid item>
                <Grid container spacing={2} direction="column">
                  <Error error={error} />
                  <Error error={nonFieldError} />
                  <Grid item>
                    <FieldEditAutocomplete
                      label={'Rule'}
                      name={'rule'}
                      labelwidth={40}
                      options={ruleOptions}
                      helperText={'Search by name.'}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.buttonGroup}>
                <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
                  <Grid item xs={2}>
                    <SecondaryButton label="Cancel" onClick={handleCloseDialog} />
                  </Grid>
                  <Grid item xs={2}>
                    <PrimaryButton type="submit" label="Submit" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};

SubscribeForm.propTypes = {
  id: PropTypes.number.isRequired,
};

export default SubscribeForm;
