// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import React from 'react';
import CommonRoutes from './utilities/CommonRoutes';
import LocationLayerProfilesForm from '../../components/locationLayerProfiles/LocationLayerProfilesForm';
import LocationLayerProfilesView from '../../components/locationLayerProfiles/LocationLayerProfilesView';
import LocationLayerProfilesTablePage from '../../components/locationLayerProfiles/LocationLayerProfilesTablePage';
import MainLayout from '../layouts/MainLayout';

const LocationLayerProfilesRoutes = props => {
  return (
    <CommonRoutes
      pageTitle="Location Layer Profiles"
      layout={MainLayout}
      Edit={<LocationLayerProfilesForm update />}
      Create={<LocationLayerProfilesForm />}
      View={<LocationLayerProfilesView />}
      All={<LocationLayerProfilesTablePage />}
    />
  );
};

export default LocationLayerProfilesRoutes;
