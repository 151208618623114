// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import React from 'react';
import CommonRoutes from './utilities/CommonRoutes';
import ChecklistTemplatesForm from '../../components/checklistTemplates/ChecklistTemplatesForm';
import ChecklistTemplatesDetail from '../../components/checklistTemplates/ChecklistTemplateDetail';
import ChecklistTemplatesTablePage from '../../components/checklistTemplates/ChecklistTemplatesTablePage';
import MainLayout from '../layouts/MainLayout';

const ChecklistTemplatesRoutes = props => {
  return (
    <CommonRoutes
      pageTitle="Checklist Template"
      layout={MainLayout}
      Edit={<ChecklistTemplatesForm update />}
      Create={<ChecklistTemplatesForm />}
      View={<ChecklistTemplatesDetail />}
      All={<ChecklistTemplatesTablePage />}
    />
  );
};

export default ChecklistTemplatesRoutes;
