// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import React from 'react';
import CommonRoutes from './utilities/CommonRoutes';
import AssetTypesForm from '../../components/assetTypes/AssetTypesForm';
import AssetTypesView from '../../components/assetTypes/AssetTypesView';
import AssetTypesTable from '../../components/assetTypes/AssetTypesTable';
import MainLayout from '../layouts/MainLayout';

const AssetTypesRoutes = props => {
  return (
    <CommonRoutes
      pageTitle="Asset Type"
      layout={MainLayout}
      Edit={<AssetTypesForm update />}
      Create={<AssetTypesForm />}
      View={<AssetTypesView />}
      All={<AssetTypesTable />}
    />
  );
};

export default AssetTypesRoutes;
