import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Lookup from './Lookup';
import FirebaseLogin from './FirebaseLogin';
import { defaultLocation, queriesFromString } from '../../utilities/strings';
import { setLandingUrl } from '../../store/authActions';

import './Login.scss';

const Login = () => {
  const { isDone } = useSelector(state => state.auth.lookup);
  const logo = useSelector(state => state.settings.logo.src);
  const location = useLocation();
  const dispatch = useDispatch();

  const { nextUrl } = queriesFromString(location.search);

  // If a user is unauthenticated, they will be brought here with location.state.from containing the previous route
  // After logging in take the user to that route or the base route if there is no from route.

  useEffect(() => {
    if (nextUrl && nextUrl !== 'login') {
      dispatch(setLandingUrl(nextUrl));
    }
  }, [nextUrl, dispatch]);

  let route = defaultLocation;
  if (location.state?.from?.pathname && location.state.from.pathname !== '/login') {
    route = location.state.from;
  }

  return (
    <>
      <div className="logo">
        <img src={logo} width="275" />
      </div>
      {!isDone ? <Lookup route={route} /> : <FirebaseLogin route={route} />}
    </>
  );
};

export default Login;
