import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { Paper, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LinearProgressWithLabel from '../../shared/displays/LinearProgressWithLabel';
import { locationWithBack } from '../../../utilities/route';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import { useFeatureFlags } from '../../../hooks/settingsHooks';

const useStyles = makeStyles(theme => ({
  formSummary: {
    margin: theme.spacing(1, 4, 3),
    padding: theme.spacing(1),
  },
  checklists: {
    margin: theme.spacing(1, 4),
    fontSize: theme.spacing(3),
  },
  list: {
    '& li': {
      margin: '5px 0',
    },
    listStyle: 'none',
    padding: theme.spacing(0, 2),
  },
  checklistInstance: {
    width: theme.spacing(30),
    marginRight: theme.spacing(2),
  },
  viewButton: {
    margin: theme.spacing(2),
  },
}));

const FormSummary = props => {
  const { form, isReadOnly } = props;

  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();

  const { hasChecklistViewer } = useFeatureFlags();

  const handleChecklistEdit = function (id) {
    history.push(locationWithBack({ pathname: `/forms/${id}/checklist` }, location));
  };
  const handleChecklistView = function (id) {
    history.push(locationWithBack({ pathname: `/checklist-viewer/${id}` }, location));
  };

  const showV1 = !hasChecklistViewer;
  const editDisabled = isReadOnly || form?.is_locked;
  // TODO: remove this switch once we are good with the new checklist viewer
  let gridLabel = 'Checklist';
  let editLabel = form.template.name;
  let showViewButton = false;
  if (!showV1) {
    gridLabel = form.template.name;
    editLabel = 'Edit';
    showViewButton = true;
  }

  const progress = form.progress?.progress * 100 || 0;
  const progressLabel = `Sections: ${form.template?.sections?.length}, Notes: ${
    form.progress?.note_count || 0
  }, Media: ${form.progress?.media_count || 0}`;

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <p className={`h7 ${classes.checklists}`}>Form Summary</p>
          <Paper className={classes.formSummary}>
            <Grid container>
              <Grid
                container
                direction="column"
                key={form.id}
                className={classes.checklistInstance}
                justifyContent="space-between">
                <Grid item>
                  <p className="h7">{gridLabel}</p>
                  <PrimaryButton
                    onClick={() => {
                      handleChecklistEdit(form.id);
                    }}
                    label={editLabel}
                    icon
                    Icon={PlaylistAddCheckIcon}
                    disabled={editDisabled}
                  />
                  {showViewButton && (
                    <span className={classes.viewButton}>
                      <PrimaryButton
                        onClick={() => {
                          handleChecklistView(form.id);
                        }}
                        label="View"
                        icon
                        Icon={VisibilityIcon}
                      />
                    </span>
                  )}
                </Grid>
                <Grid item className={classes.progressBar}>
                  {progressLabel}
                  <LinearProgressWithLabel value={progress} />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

FormSummary.defaultProps = {
  form: {},
};

FormSummary.propTypes = {
  form: PropTypes.object,
  isReadOnly: PropTypes.bool,
};

export default FormSummary;
