import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getUsers } from '../../store/features/usersActions';
import { fieldOrder, removeField, hideField, displayRole } from './usersShared';
import { jsonKeyToLabel } from '../../utilities/strings';
import CommonFormView from '../shared/form/CommonView';
import { usePermissions } from '../../hooks/settingsHooks';

const Users = () => {
  const id = useParams().id;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUsers(id));
  }, [dispatch, id]);

  const { data, loading, error } = useSelector((state) => state.users.each);
  const { hasUserEdit } = usePermissions();

  const formSettings = {
    fieldOrder,
    removeField: removeField('VIEW'),
    hideField,
  };

  /**
   * Set field type and props here.
   * Add a case for each key from the api response that needs to be handled.
   * Possible types can be found here: src/components/shared/form/
   */
  const fieldSettings = {};
  for (const section in fieldOrder) {
    fieldOrder[section].fields.forEach((key) => {
      // cast to string because booleans do not show up in the field
      let displayLabel = jsonKeyToLabel(key); // eslint-disable-line prefer-const
      let displayValue = '';
      if (data && data[key] != null) {
        switch (key) {
          // add cases for each key that needs to be customized.
          case 'company':
            fieldSettings[key] = {
              type: 'chips',
              fieldProps: {
                label: displayLabel,
                value: data[key],
                display: (value) => {
                  return value.name;
                },
              },
            };
            return;

          case 'roles':
            fieldSettings[key] = {
              type: 'chips',
              fieldProps: {
                label: displayLabel,
                value: data[key],
                display: (value) => {
                  return displayRole(value);
                },
              },
            };
            return;

          default:
            displayValue = data[key].toString();
        }
      }
      fieldSettings[key] = {
        type: 'text',
        fieldProps: {
          label: displayLabel,
          value: displayValue,
        },
      };
    });
  }

  return (
    <CommonFormView
      title={'User Details'}
      values={data}
      fieldSettings={fieldSettings}
      formSettings={formSettings}
      editLink={hasUserEdit && `/users/${id}/edit`}
      loading={loading}
      error={error}
    />
  );
};

export default Users;
