import PropTypes from 'prop-types';
import { REQUIRED, SHOW, HIDE } from './strings';

export const headCellsType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    display: PropTypes.oneOf([REQUIRED, SHOW, HIDE]).isRequired,
  })
);

export const locationShape = PropTypes.shape({
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  hash: PropTypes.string.isRequired,
  state: PropTypes.object,
});