// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import { combineReducers } from 'redux';
import {
  CREWS_REQUEST,
  CREWS_SUCCESS,
  CREWS_FAILURE,
  CREWS_CREATE_REQUEST,
  CREWS_CREATE_SUCCESS,
  CREWS_CREATE_FAILURE,
  CREWS_UPDATE_REQUEST,
  CREWS_UPDATE_SUCCESS,
  CREWS_UPDATE_FAILURE,
  CREWS_DELETE_REQUEST,
  CREWS_DELETE_SUCCESS,
  CREWS_DELETE_FAILURE,
  CREWS_ALL_REQUEST,
  CREWS_ALL_SUCCESS,
  CREWS_ALL_FAILURE,
  CREWS_CLEAR_EACH_ERROR,
  CREWS_EACH_CLEAR,
} from './crewsActions';

export const initialStateCrews = {
  loading: false,
  error: '',
  formError: {},
  id: 0,
  body: {},
  data: {},
};

export const initialStateAllCrews = {
  loading: false,
  error: '',
  query: {},
  dataAll: { count: 0, next: null, previous: null, results: [] },
};

export const crewsReducer = (state = initialStateCrews, action) => {
  switch (action.type) {
    case CREWS_REQUEST:
      return {
        ...initialStateCrews,
        loading: true,
        id: action.id,
      };
    case CREWS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case CREWS_FAILURE:
      return {
        ...initialStateCrews,
        error: action.error,
      };
    case CREWS_CREATE_REQUEST:
      return {
        ...initialStateCrews,
        loading: true,
        body: action.body,
      };
    case CREWS_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case CREWS_CREATE_FAILURE:
      return {
        ...initialStateCrews,
        error: action.error,
        formError: action.formError,
      };
    case CREWS_UPDATE_REQUEST:
      return {
        ...state,
        error: '',
        formError: {},
        loading: true,
        id: action.id,
        body: action.body,
      };
    case CREWS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case CREWS_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        formError: action.formError,
      };
    case CREWS_DELETE_REQUEST:
      return {
        ...state,
        error: '',
        formError: {},
        deleting: true,
        id: action.id,
      };
    case CREWS_DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
      };
    case CREWS_DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        error: action.error,
        formError: action.formError,
      };
    case CREWS_CLEAR_EACH_ERROR:
      return {
        ...state,
        error: '',
        formError: {},
      };
    case CREWS_EACH_CLEAR:
      return {
        ...initialStateCrews,
      };
    default:
      return state;
  }
};

export const crewsAllReducer = (state = initialStateAllCrews, action) => {
  switch (action.type) {
    case CREWS_ALL_REQUEST:
      return {
        ...initialStateAllCrews,
        loading: true,
        query: action.query,
      };
    case CREWS_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        dataAll: action.data,
      };
    case CREWS_ALL_FAILURE:
      return {
        ...initialStateAllCrews,
        error: action.error,
      };
    default:
      return state;
  }
};

export default combineReducers({
  each: crewsReducer,
  all: crewsAllReducer,
});
