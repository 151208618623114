// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
import React from 'react';
import CommonRoutes from './utilities/CommonRoutes';
import UsersForm from '../../components/users/UsersForm';
import UsersView from '../../components/users/UsersView';
import UsersTablePage from '../../components/users/UsersTablePage';
import MainLayout from '../layouts/MainLayout';

const UsersRoutes = props => {
  return (
    <CommonRoutes
      pageTitle="User"
      layout={MainLayout}
      Edit={<UsersForm update />}
      Create={<UsersForm />}
      View={<UsersView />}
      All={<UsersTablePage />}
    />
  );
};

export default UsersRoutes;
