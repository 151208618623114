import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Alert, ButtonGroup, FormControl, ListItemText, Checkbox, Select, MenuItem, InputLabel } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Table, { SELECTABLE_ROWS_NONE } from '../shared/table/Table';
import { deleteCrews } from '../../store/features/crewsActions';
import { setCrewsTableView, setCrewsTableSize } from '../../store/settings/views/crewsTableViewRedux';
import { apiDateToString } from '../../utilities/strings';
import ButtonIcon from '../shared/buttons/ButtonIcon';
import StyledLink from '../shared/StyledLink';
import { setColumns, sortByKey } from '../shared/table/columns';
import { openDialog } from '../../store/dialogActions';
import DeleteDialog from '../shared/Dialog/DeleteDialog';
import { usePermissions, useTableViews } from '../../hooks/settingsHooks';
import { getCustomCSVData, tableViews } from '../../utilities/tables';
import { ensureArray } from '../../utilities/arrays';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const Crews = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { loading, data, deleteError } = useSelector(state => ({
    loading: state.crews.all.loading,
    data: state.crews.all.dataAll.results,
    deleteError: state.crews.each.error,
  }));
  const { crewsTableViewKey } = tableViews;
  const views = useTableViews(crewsTableViewKey);

  const { hasCrewEdit, hasCrewCreate, hasCrewDelete, hasCompanyManageAll } = usePermissions();

  /** @type {Column[]} */
  const columns = [
    {
      // This needs to be first and excluded so customBodyRender can access the id to create the link.
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        filterType: 'textField',
        customBodyRender: (value, tableMeta) => {
          return <StyledLink to={`/crews/${tableMeta.rowData[columns.findIndexByName['id']]}`} value={value} />;
        },
      },
    },
    {
      name: 'company',
      label: 'Company',
      options: {
        filter: true,
        sort: true,
        sortCompare: order => {
          return (obj1, obj2) => {
            return sortByKey(order, obj1, obj2, 'name');
          };
        },
        downloadBody: value => getCustomCSVData('simple', value),
        customBodyRender: value => {
          if (!value) return <></>;
          return <StyledLink to={hasCompanyManageAll ? `/companies/${value.id}` : null} value={value.name} />;
        },
      },
    },
    {
      name: 'display_name',
      label: 'Display Name',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'users',
      label: 'Users',
      options: {
        filter: true,
        sort: false,
        display: 'true',
        filterType: 'custom',
        downloadBody: values => getCustomCSVData('array', ensureArray(values)),
        customBodyRender: values =>
          values.map((value, index) => {
            if (!value) return null;
            return (
              <Fragment key={value.id}>
                {index === 0 ? null : <span>, </span>}
                <StyledLink to={`/users/${value.id}`} value={value.name} />
              </Fragment>
            );
          }),
        filterOptions: {
          logic: (users, filters) => {
            if (filters.length) return !users.some(user => filters.includes(user.name));
            return false;
          },
          display: (filterList, onChange, index, column) => {
            // getting users from all crews since this is a filter
            const users = data
              ? data
                  .flatMap(item => item.users)
                  .sort((a, b) => {
                    const nameA = a.name.toLowerCase();
                    const nameB = b.name.toLowerCase();
                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;
                    return 0;
                  })
              : [];
            const optionValues =
              users.length > 0 ? [...new Set(users.map(value => (value.name ? value.name : 'No name set')))] : [];
            const allChecked = !filterList[index].length;
            return (
              <FormControl>
                <InputLabel shrink>Users</InputLabel>
                <Select
                  value={filterList[index]}
                  multiple
                  displayEmpty
                  disablePortal
                  renderValue={selected => {
                    if (selected.length === 0) {
                      return ['All'];
                    }
                    return selected.join(', ');
                  }}
                  onChange={event => {
                    if (event.target.value[event.target.value.length - 1] === 'All') {
                      filterList[index] = [];
                    } else {
                      filterList[index] = event.target.value.filter(item => item !== 'All');
                    }
                    onChange(filterList[index], index, column);
                  }}>
                  <MenuItem key="All" value="All">
                    <Checkbox color="primary" checked={allChecked} />
                    <ListItemText primary="All" />
                  </MenuItem>
                  {optionValues.map(item => (
                    <MenuItem key={item} value={item}>
                      <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          },
        },
      },
    },
    {
      name: 'updated_on',
      label: 'Updated',
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => apiDateToString(value, 'date'),
      },
    },
    {
      name: 'created_on',
      label: 'Created',
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => apiDateToString(value, 'date'),
      },
    },
    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        print: false,
        viewColumns: false,
        customBodyRender: (_, info) => {
          // 0 is the first element in column
          const id = info.rowData[columns.findIndexByName['id']];
          const name = info.rowData[columns.findIndexByName['name']];
          return (
            <>
              <ButtonGroup>
                {hasCrewEdit && (
                  <ButtonIcon history={history} icon={EditIcon} location={location} to={`/crews/${id}/edit`} />
                )}
                {hasCrewDelete && (
                  <ButtonIcon
                    icon={DeleteIcon}
                    onClick={() => {
                      dispatch(
                        openDialog('Delete Crew?', <DeleteDialog id={id} deleteAction={deleteCrews} name={name} />)
                      );
                    }}
                  />
                )}
              </ButtonGroup>
            </>
          );
        },
      },
    },
  ];

  // handle columns display
  setColumns(columns, views);

  /** @type {TableOptions} */
  const options = {
    // if the column view changes, update redux with either 'add' or 'remove'
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setCrewsTableView(changedColumn, action));
    },
    onChangeRowsPerPage: numberOfRows => {
      dispatch(setCrewsTableSize(numberOfRows));
    },
    rowsPerPage: views.pageSize,
    enableNestedDataAccess: '.',
    selectableRows: SELECTABLE_ROWS_NONE,
  };

  return (
    <div>
      {deleteError && (
        <Alert
          severity="error"
          sx={{
            marginTop: '1.5rem',
            display: 'inline-flex',
            width: '100%',
            color: 'red',
          }}>
          {deleteError}
        </Alert>
      )}
      <Table
        title="Crews"
        simpleSearch
        columns={columns}
        addRoute={hasCrewCreate && '/crews/new'}
        data={data}
        options={options}
        loading={loading}
      />
    </div>
  );
};

export default Crews;
