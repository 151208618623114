import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > :first-child': {
      margin: theme.spacing(3, 0),
    },
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function HorizontalLinearStepper(props) {
  const { getSteps, getStepContent, isStepOptional, activeStep } = props;
  const classes = useStyles();
  const steps = getSteps();

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = <Typography variant="caption">Optional</Typography>;
          }

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        <div>
          <div className={classes.instructions}>{getStepContent(activeStep)}</div>
        </div>
      </div>
    </div>
  );
}

HorizontalLinearStepper.defaultProps = {};

HorizontalLinearStepper.propTypes = {
  getSteps: PropTypes.func.isRequired,
  getStepContent: PropTypes.func.isRequired,
  isStepOptional: PropTypes.func.isRequired,
  activeStep: PropTypes.number.isRequired,
};
