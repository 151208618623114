export const SET_CHECKLIST_VIEWER_TABLE_VIEW = 'SET_CHECKLIST_VIEWER_TABLE_VIEW';

export const setchecklistViewerTableView = (column, action) => {
  return {
    type: SET_CHECKLIST_VIEWER_TABLE_VIEW,
    column,
    action,
  };
};

export const checklistViewerTableViewReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_CHECKLIST_VIEWER_TABLE_VIEW:
      return {
        ...state,
        [action.column]: action.action,
      };
    default:
      return state;
  }
};
