import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Card, Typography } from '@mui/material';

const Stats = props => {
  const stats = useSelector(state => state.stats.companies.data || {});

  // formats order
  const data = useMemo(() => {
    return {
      user: stats.user,
      company: stats.company,
      asset: stats.asset,
      project: stats.project,
      asset_type: stats.asset_type,
      project_type: stats.project_type,
      crew: stats.crew,
    };
  }, [stats]);

  const renderCard = (label, value) => {
    let formattedLabel = '';
    const formattedValue = value;
    switch (label) {
      case 'user':
        formattedLabel = 'Users';
        break;
      case 'company':
        formattedLabel = 'Companies';
        break;
      case 'asset':
        formattedLabel = 'Assets';
        break;
      case 'asset_type':
        formattedLabel = 'Asset Types';
        break;
      case 'project':
        formattedLabel = 'Projects';
        break;
      case 'project_type':
        formattedLabel = 'Project Types';
        break;
      case 'crew':
        formattedLabel = 'Crews';
        break;
      default:
        console.error(`Add display string for api key ${label}`);
    }

    return (
      <Card>
        <Typography variant="h3">{formattedValue}</Typography>
        <Typography variant="h6">{formattedLabel}</Typography>
      </Card>
    );
  };

  return (
    <>
      <Grid container direction="row">
        {Object.keys(data).map(item => (
          <Grid item key={item} xs>
            {renderCard(item, data[item])}
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Stats;
