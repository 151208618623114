import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import RecommendedWorkPlanTable from '../recommendedWorkPlan/RecommendedWorkPlanTable';

import { getAllRecommendedWorkPlan } from '../../store/features/recommendedWorkPlanActions';
import { tableViews } from '../../utilities/tables';
import { useTableViewSettings } from '../../hooks/settingsHooks';

/**
 * Recommended Work Table
 *  By Asset - to use in AssessmentDetail
 */
const AssessmentDisplayRecommendedWorkPlanTable = props => {
  const { assessment, excludeAssetPath } = props;
  const assetId = assessment.asset.id;

  const dispatch = useDispatch();

  const { recommendedWorkPlanTableViewKey } = tableViews;
  const defaultRecommendedWorkPlanQuery = {
    asset__descendants: assetId,
  };
  const {
    tableQuery: storedRecommendedWorkPlanQuery,
    embeddedQuery: recommendedWorkPlanEmbeddedQuery,
    page: recommendedWorkPlanPage,
    views: recommendedWorkPlanView,
  } = useTableViewSettings(recommendedWorkPlanTableViewKey, defaultRecommendedWorkPlanQuery);
  useEffect(() => {
    // load recommended work plan data on mount
    dispatch(getAllRecommendedWorkPlan(storedRecommendedWorkPlanQuery));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    assetId,
    /* storedRecommendedWorkPlanQuery (manually handled by recommendedWorkPlanTableChangeHandler), */
  ]);
  const recommendedWorkPlanTableChangeHandler = query => {
    const updatedQuery = { ...recommendedWorkPlanEmbeddedQuery, ...query };
    dispatch(getAllRecommendedWorkPlan(updatedQuery));
  };

  return (
    <RecommendedWorkPlanTable
      title="Recommended Work"
      embedded
      tableChangeHandler={recommendedWorkPlanTableChangeHandler}
      queryParamObj={storedRecommendedWorkPlanQuery}
      views={recommendedWorkPlanView}
      page={recommendedWorkPlanPage}
      excludeAssetPath={excludeAssetPath}
      // className={classes.table}  TODO
    />
  );
};

AssessmentDisplayRecommendedWorkPlanTable.propTypes = {
  assessment: PropTypes.any.isRequired,
  excludeAssetPath: PropTypes.bool,
};

export default AssessmentDisplayRecommendedWorkPlanTable;
