import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { GoogleMap, Circle, Polygon, InfoBox, Marker } from '@react-google-maps/api';
import { useGoogleMapLoader } from '../../hooks/mapHooks';
import { queriesFromString, toQueryString } from '../../utilities/strings';
import LoadingPage from '../shared/displays/LoadingPage';
import { iconUrlLookup } from '../../utilities/assetCondition';

const MediaCircle = props => {
  /* eslint-disable camelcase */
  const { id, isActive, geo_point, onMediaCircleClick, radius = 3, exif = {} } = props;
  const { GimbalYawDegree, FlightYawDegree, RelativeAltitude, GPSInfo } = exif;
  const headingStr = GimbalYawDegree ?? FlightYawDegree ?? GPSInfo?.GPSImgDirection;
  const headingDegrees = headingStr ? parseFloat(headingStr) : null;
  const [lng, lat] = geo_point.coordinates;
  const center = { lat: lat, lng: lng };
  const [showInfoBox, setShowInfoBox] = useState(false);

  const circleOptions = isActive => ({
    strokeColor: 'white',
    strokeOpacity: 1,
    strokeWeight: 1,
    fillColor: isActive ? '#f26822' : 'blue',
    fillOpacity: 0.85,
    radius: radius,
    zIndex: isActive ? 2 : 1,
    scale: true,
  });

  const polygonOptions = isActive => ({
    fillColor: '#f26822',
    fillOpacity: 0.85,
    strokeColor: 'white',
    strokeOpacity: 1,
    strokeWeight: 1,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: isActive ? 2 : 1,
    scale: true,
  });

  const infoBoxOptions = showInfoBox => ({
    visible: showInfoBox,
    maxWidth: 150,
    pixelOffset: new window.google.maps.Size(0, 8),
    closeBoxURL: '',
  });

  const paths = [];

  if (isActive && headingDegrees !== null) {
    const computeOffset = window.google.maps.geometry.spherical.computeOffset;
    // Base left
    paths.push(computeOffset(center, radius * 3, headingDegrees - 30));
    // Apex
    paths.push(computeOffset(center, radius * 0.25, headingDegrees));
    // Base right
    paths.push(computeOffset(center, radius * 3, headingDegrees + 30));
  }

  const onClick = mapMouseEvent => {
    onMediaCircleClick(id);
  };

  const onCircleMouseOver = () => {
    setShowInfoBox(true);
  };

  const onCircleMouseOut = () => {
    setShowInfoBox(false);
  };

  return (
    <>
      <Circle
        key={id}
        center={center}
        onClick={onClick}
        options={circleOptions(isActive)}
        onMouseOver={onCircleMouseOver}
        onMouseOut={onCircleMouseOut}
      />
      <Polygon paths={paths} options={polygonOptions(isActive)} />
      {RelativeAltitude && (
        <InfoBox options={infoBoxOptions(showInfoBox)} position={center}>
          <div style={{ backgroundColor: 'white', opacity: 0.75, padding: 5 }}>
            <div style={{ fontSize: 16, fontColor: `#08233B` }}>{RelativeAltitude}</div>
          </div>
        </InfoBox>
      )}
    </>
  );
};

MediaCircle.propTypes = {
  id: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  geo_point: PropTypes.object.isRequired,
  onMediaCircleClick: PropTypes.func.isRequired,
  exif: PropTypes.object.isRequired,
  radius: PropTypes.number.isRequired,
};

const InspectorMap = props => {
  // mediaWithGPS is verified as an array with atleast one element in the parent
  const { mediaWithGPS, onMediaCircleClick, activeMedia, project, mapContainerStyle } = props;
  const location = useLocation();
  const history = useHistory();
  const [mapRef, setMapRef] = useState(null);
  const [refitMap, setRefitMap] = useState(false);
  const [activeMediaAltitude, setActiveMediaAltitude] = useState(null);
  const [circleRadius, setCircleRadius] = useState(1);

  const asset = project.asset ?? {};
  const assetCoordinates = asset.geo_point?.coordinates ?? [];
  const showAsset = assetCoordinates.length > 0;
  const iconUrl = iconUrlLookup(0, [{ value: 0, description: 'Not Set', note: '', color: 'blue' }]);

  const assetMarkerProps = {
    key: asset.id ?? '',
    position: { lat: assetCoordinates[1] ?? 0, lng: assetCoordinates[0] ?? 0 },
    title: asset.name ?? '',
    icon: iconUrl,
  };

  const recalculateBounds = () => {
    // There is no way to reset or shrink the boundaries of LatLngBounds.  A new one has to be created.
    const newMapBounds = new window.google.maps.LatLngBounds();
    mediaWithGPS.forEach(loc => {
      const [lng, lat] = loc.geo_point.coordinates;
      const gloc = new window.google.maps.LatLng(lat, lng);
      newMapBounds.extend(gloc);
    });
    if (showAsset) {
      const [assetLng, assetLat] = assetCoordinates;
      const assetloc = new window.google.maps.LatLng(assetLat, assetLng);
      newMapBounds.extend(assetloc);
    }
    return newMapBounds;
  };

  // Handles the initial render of the map.  Without this, the map will not show anything when it loads.
  useEffect(() => {
    if (mapRef) {
      const parsed = queriesFromString(location.search, ['map']);
      if (parsed.map) {
        mapRef.setCenter({ lat: parseFloat(parsed.map[0]), lng: parseFloat(parsed.map[1]) });
        mapRef.setZoom(parseInt(parsed.map[2]));
      } else {
        mapRef.fitBounds(recalculateBounds());
      }
    }
  }, [mapRef]); // eslint-disable-line react-hooks/exhaustive-deps

  // A work around because CenterControl memoizes the variables so anything passed into addEventListener does not
  // reflect the current values.  Instead, the event listener, sets reFitMap to true and then useEffect sets reFitMap
  // back to false after we recalculate the bounds and apply them.
  // Possible refactor: This causes the component to render a couple of times since we have to reset setRefitMap for
  // the map button to continue working.
  useEffect(() => {
    if (mapRef && refitMap) {
      mapRef.fitBounds(recalculateBounds());
      setRefitMap(false);
      // handle any queries in the url.  Because we recalculated the bounds, remove the map query.
      const newLocation = location;
      const queryObj = queriesFromString(newLocation.search);
      delete queryObj.map;
      newLocation.search = toQueryString(queryObj);
      history.replace(newLocation);
    }
  }, [refitMap]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const exif = activeMedia?.file_meta?.exif;
    const altitude = exif?.RelativeAltitude ?? exif?.GPSInfo?.GPSAltitude ?? null;
    if (altitude !== null) {
      setActiveMediaAltitude(`AGI: ${altitude}`);
    } else {
      setActiveMediaAltitude(null);
    }
  }, [activeMedia]);

  const calculateRadiusForZoom = zoomLevel => {
    const radius = 1;
    // Increase the radius as the zoom level decreases
    const zoomDiff = 19 - zoomLevel;
    return radius * 2 ** zoomDiff;
  };

  // https://developers.google.com/maps/documentation/javascript/controls#CustomDrawing
  const CenterControl = controlDiv => {
    // Set CSS for the control border.
    controlDiv.style.height = '40px';
    controlDiv.style.width = '40px';
    controlDiv.style.border = '0px';
    controlDiv.style.margin = '10px';
    controlDiv.style.borderRadius = '2px';
    controlDiv.style.background = 'none rgb(255, 255, 255)';

    const controlUI = document.createElement('div');
    controlUI.style.backgroundColor = '#fff';
    controlUI.style.border = '2px solid #fff';
    controlUI.style.borderRadius = '3px';
    controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    controlUI.style.color = 'rgb(25,25,25)';
    controlUI.style.cursor = 'pointer';
    controlUI.style.fontFamily = 'Roboto,Arial,sans-serif';

    controlUI.style.textAlign = 'center';
    controlUI.title = 'Zoom To All';
    controlUI.type = 'button';

    controlDiv.appendChild(controlUI);

    // Set CSS for the control interior.
    const controlText = document.createElement('div');
    controlText.style.color = 'rgb(90,90,90)';
    controlText.innerHTML =
      '<svg class="MuiSvgIcon-root" focusable="true" style="fill: gray" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M15 3l2.3 2.3-2.89 2.87 1.42 1.42L18.7 6.7 21 9V3h-6zM3 9l2.3-2.3 2.87 2.89 1.42-1.42L6.7 5.3 9 3H3v6zm6 12l-2.3-2.3 2.89-2.87-1.42-1.42L5.3 17.3 3 15v6h6zm12-6l-2.3 2.3-2.87-2.89-1.42 1.42 2.89 2.87L15 21h6v-6z"></path></svg>';
    controlUI.appendChild(controlText);

    // Setup the click event listener for the zoom to bounds control.
    controlUI.addEventListener('click', function () {
      setRefitMap(true);
    });
  };

  const loadMapHandler = map => {
    // Store a reference to the google map instance in state
    setMapRef(map);

    const centerControlDiv = document.createElement('button');
    CenterControl(centerControlDiv);

    centerControlDiv.index = 1;
    map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(centerControlDiv);
  };

  const mapOptions = {
    mapTypeId: 'satellite',
    tilt: 0,
    streetViewControl: false,
    clickableIcons: true,
  };

  const agiButtonOptions = {
    variant: 'contained',
    style: {
      bottom: 15,
      left: 10,
      position: 'absolute',
      backgroundColor: '#E3E3E3BF',
      padding: '5px 10px',
    },
  };

  const { isLoaded } = useGoogleMapLoader();
  if (!isLoaded) {
    return <LoadingPage />;
  }

  const updateUrlMapQuery = text => {
    // only set the map query if
    if (!mapRef) return;
    const { center, zoom } = mapRef;
    const newLocation = location;
    const queryObj = queriesFromString(newLocation.search);
    queryObj.map = [center.lat(), center.lng(), zoom];
    newLocation.search = toQueryString(queryObj);
    history.replace(newLocation);
    const newRadius = calculateRadiusForZoom(mapRef.getZoom());
    setCircleRadius(newRadius);
  };

  return (
    <GoogleMap
      id="location-map"
      mapContainerStyle={mapContainerStyle}
      onLoad={loadMapHandler}
      onUnmount={() => setMapRef(null)}
      options={mapOptions}
      onDragEnd={() => updateUrlMapQuery('onDrag')}
      onZoomChanged={() => updateUrlMapQuery('onZoom')}>
      {showAsset && <Marker {...assetMarkerProps} />}

      {mediaWithGPS?.length &&
        mediaWithGPS.map(media => (
          <MediaCircle
            key={media.id}
            radius={circleRadius}
            {...media}
            onMediaCircleClick={onMediaCircleClick}
            isActive={media?.id === activeMedia?.id}
          />
        ))}
      {activeMediaAltitude && <Button {...agiButtonOptions}>{activeMediaAltitude}</Button>}
    </GoogleMap>
  );
};

InspectorMap.defaultProps = {
  mapContainerStyle: {
    height: '300px',
    width: '100%',
  },
};

InspectorMap.propTypes = {
  mediaWithGPS: PropTypes.array.isRequired,
  onMediaCircleClick: PropTypes.func.isRequired,
  activeMedia: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  mapContainerStyle: PropTypes.object,
};

export default InspectorMap;
