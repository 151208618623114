import { all, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  ASSESSMENT_TYPES_REQUEST,
  ASSESSMENT_TYPES_SUCCESS,
  ASSESSMENT_TYPES_FAILURE,
  ASSESSMENT_TYPES_CREATE_REQUEST,
  ASSESSMENT_TYPES_CREATE_SUCCESS,
  ASSESSMENT_TYPES_CREATE_FAILURE,
  ASSESSMENT_TYPES_UPDATE_REQUEST,
  ASSESSMENT_TYPES_UPDATE_SUCCESS,
  ASSESSMENT_TYPES_UPDATE_FAILURE,
  ASSESSMENT_TYPES_DELETE_REQUEST,
  ASSESSMENT_TYPES_DELETE_SUCCESS,
  ASSESSMENT_TYPES_DELETE_FAILURE,
  ASSESSMENT_TYPES_ALL_REQUEST,
  ASSESSMENT_TYPES_ALL_SUCCESS,
  ASSESSMENT_TYPES_ALL_FAILURE,
} from './assessmentTypesActions';

import {
  fetchAssessmentTypes,
  createAssessmentTypes,
  updateAssessmentTypes,
  deleteAssessmentTypes,
  fetchAssessmentTypesList,
} from '../../api/features/assessmentTypes';
import { defaultErrorHandler, defaultErrorCatch } from '../../api/base';
import { buildRoute } from '../../utilities/strings';
import { fetchListAll } from '../../utilities/sagaHelpers';
import { DIALOG_CLOSE } from '../../store/dialogActions';

export function* assessmentTypesFetch(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield fetchAssessmentTypes(id);
    if (data) {
      yield put({ type: ASSESSMENT_TYPES_SUCCESS, data });
    } else if (error) {
      yield put({ type: ASSESSMENT_TYPES_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, ASSESSMENT_TYPES_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, ASSESSMENT_TYPES_FAILURE);
  }
}

export function* watchAssessmentTypesFetch() {
  yield takeLatest(ASSESSMENT_TYPES_REQUEST, assessmentTypesFetch);
}

export function* assessmentTypesCreate(action) {
  const { body } = action;
  try {
    const { data, error, formError, response } = yield createAssessmentTypes(body);
    if (data) {
      yield put({ type: ASSESSMENT_TYPES_CREATE_SUCCESS, data });
      yield put(push(buildRoute('/assessment-types')));
    } else if (error) {
      yield put({ type: ASSESSMENT_TYPES_CREATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: ASSESSMENT_TYPES_CREATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, ASSESSMENT_TYPES_CREATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, ASSESSMENT_TYPES_CREATE_FAILURE);
  }
}

export function* watchAssessmentTypesCreate() {
  yield takeLatest(ASSESSMENT_TYPES_CREATE_REQUEST, assessmentTypesCreate);
}

export function* assessmentTypesUpdate(action) {
  const { id, body } = action;
  try {
    const { data, error, formError, response } = yield updateAssessmentTypes(id, body);
    if (data) {
      yield put({ type: ASSESSMENT_TYPES_UPDATE_SUCCESS, data });
      yield put(push(buildRoute('/assessment-types')));
    } else if (error) {
      yield put({ type: ASSESSMENT_TYPES_UPDATE_FAILURE, error });
    } else if (formError) {
      yield put({ type: ASSESSMENT_TYPES_UPDATE_FAILURE, formError });
    } else {
      yield defaultErrorHandler(response, ASSESSMENT_TYPES_UPDATE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, ASSESSMENT_TYPES_UPDATE_FAILURE);
  }
}

export function* watchAssessmentTypesUpdate() {
  yield takeLatest(ASSESSMENT_TYPES_UPDATE_REQUEST, assessmentTypesUpdate);
}

export function* assessmentTypesDelete(action) {
  const { id } = action;
  try {
    const { data, error, response } = yield deleteAssessmentTypes(id);
    if (data) {
      yield put({ type: ASSESSMENT_TYPES_DELETE_SUCCESS });
      yield put({ type: ASSESSMENT_TYPES_ALL_REQUEST });
      yield put({ type: DIALOG_CLOSE });
      yield put(push(buildRoute('/assessment-types')));
    } else if (error) {
      yield put({ type: ASSESSMENT_TYPES_DELETE_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, ASSESSMENT_TYPES_DELETE_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, ASSESSMENT_TYPES_DELETE_FAILURE);
  }
}

export function* watchAssessmentTypesDelete() {
  yield takeLatest(ASSESSMENT_TYPES_DELETE_REQUEST, assessmentTypesDelete);
}

export function* assessmentTypesFetchAll(action) {
  try {
    const { data, error, response } = yield fetchListAll(fetchAssessmentTypesList, action.query);
    if (data) {
      yield put({ type: ASSESSMENT_TYPES_ALL_SUCCESS, data });
    } else if (error) {
      yield put({ type: ASSESSMENT_TYPES_ALL_FAILURE, error });
    } else {
      yield defaultErrorHandler(response, ASSESSMENT_TYPES_ALL_FAILURE);
    }
  } catch (error) {
    yield defaultErrorCatch(error, ASSESSMENT_TYPES_ALL_FAILURE);
  }
}

export function* watchAssessmentTypesFetchAll() {
  yield takeLatest(ASSESSMENT_TYPES_ALL_REQUEST, assessmentTypesFetchAll);
}

export default function* assessmentTypesSaga() {
  yield all([
    watchAssessmentTypesFetch(),
    watchAssessmentTypesCreate(),
    watchAssessmentTypesUpdate(),
    watchAssessmentTypesFetchAll(),
    watchAssessmentTypesDelete(),
  ]);
}
