import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button, Tooltip } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { checklistsBulkLockEndpoint } from '../../store/apiV2/checklists';
import { getChecklists } from '../../store/features/checklistsActions';
import { usePermissions } from '../../hooks/settingsHooks';

const LOCK = 'Lock';
const UNLOCK = 'Unlock';

const ChecklistLock = ({ data }) => {
  const dispatch = useDispatch();
  const { dispatchRequest: updateChecklistsLock } = checklistsBulkLockEndpoint.useEndpoint();
  const { hasChecklistLock, hasChecklistUnlock } = usePermissions();
  const lockAction = data?.is_locked ? UNLOCK : LOCK;
  const isChecklistPrimary = data?.project_order_index === 0; // don't allow locking primary checklists

  const handleLock = data => {
    updateChecklistsLock([data.id], lockAction.toUpperCase()).then(() => dispatch(getChecklists(data.id)));
  };

  const renderLockButtons = () => {
    if (isChecklistPrimary) return null;
    if (!data.is_locked && hasChecklistLock) {
      return (
        <Button sx={{ boxShadow: 2 }} onClick={() => handleLock(data)}>
          <span>
            <LockIcon />
          </span>
        </Button>
      );
    } else if (data.is_locked && hasChecklistUnlock) {
      return (
        <Button sx={{ boxShadow: 2 }} onClick={() => handleLock(data)}>
          <span>
            <LockOpenIcon />
          </span>
        </Button>
      );
    } else return null;
  };

  return <Tooltip title={data.is_locked ? UNLOCK : LOCK}>{renderLockButtons()}</Tooltip>;
};

ChecklistLock.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ChecklistLock;
