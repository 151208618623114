import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { apiDateToString } from '../../utilities/strings';
import FieldDisplayTable from '../shared/form/FieldDisplayTable';
import AssetConditionDisplay from '../analytics/assetCondition/AssetConditionDisplay';
import UserDisplay from '../shared/displays/UserDisplay';

const useStyles = makeStyles(theme => ({
  assetConditionHistory: {
    margin: theme.spacing(1, 4, 5),
  },
}));

const AssetConditionHistory = props => {
  const { history } = props;
  const colOrder = ['condition', 'modified_by', 'created_on', 'note'];
  const classes = useStyles();
  const data = history
    ? history.map(row => ({
        note: row.note,
        modified_by: <UserDisplay {...row.modified_by} />,
        condition: <AssetConditionDisplay condition={row.asset_condition} />,
        created_on: apiDateToString(row.created_on, 'date'),
      }))
    : [];

  return (
    <div className={classes.assetConditionHistory}>
      <FieldDisplayTable title label={'Asset Condition History'} data={data} colOrder={colOrder} />
    </div>
  );
};

AssetConditionHistory.propTypes = {
  history: PropTypes.array.isRequired,
};
export default AssetConditionHistory;
