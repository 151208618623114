import { grey } from '@mui/material/colors';
import config from '../config.module.scss';

// This is a work around for a really weird bug.  When testing, config is imported as an empty object
// However, config.primary resolves to 'primary' instead of undefined.
// Using this hack to make sure variables.primary returns undefined, so we can use grey[500] as test values.
const temp = JSON.stringify(config);
const variables = JSON.parse(temp);

const palette = darkMode => {
  const palette = {
    mode: darkMode ? 'dark' : 'light',
    primary: {
      main: variables.primary || grey[500],
      contrastText: (darkMode ? variables.textPrimaryDark : variables.textPrimaryLight) || grey[500],
    },
    secondary: {
      main: (darkMode ? variables.secondaryDark : variables.secondaryLight) || grey[500],
      contrastText: (darkMode ? variables.textSecondaryDark : variables.textSecondaryLight) || grey[500],
    },
    warning: {
      main: variables.yellow || grey[500],
    },
    error: {
      main: variables.red || grey[500],
    },
    text: {
      primary: (darkMode ? variables.textPrimaryDark : variables.textPrimaryLight) || grey[500],
      secondary: (darkMode ? variables.textSecondaryDark : variables.textSecondaryLight) || grey[500],
    },
    tonalOffset: 0.25,
    constrastThreshold: 3,
    background: {
      default: darkMode ? variables.baseDark : variables.baseLight,
      secondary: darkMode ? variables.backgroundDark : variables.backgroundLight,
    },
  };

  return palette;
};

export default palette;
