import React from 'react';
import CommonRoutes from './utilities/CommonRoutes';
import ReservationsForm from '../../components/reservations/ReservationsForm';
import ReservationsView from '../../components/reservations/ReservationsView';
import ReservationSuccess from '../../components/reservations/ReservationSuccess';
import ReservationsTable from '../../components/reservations/ReservationsTable';
import MainLayout from '../layouts/MainLayout';

const ReservationsRoutes = props => {
  return (
    <CommonRoutes
      pageTitle="Reservation"
      layout={MainLayout}
      Edit={<ReservationsForm update={true} />}
      Create={<ReservationsForm />}
      View={<ReservationsView />}
      All={<ReservationsTable />}
      Success={<ReservationSuccess />}
    />
  );
};

export default ReservationsRoutes;
