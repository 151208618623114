// --------------------------------------------------------------------------------
//
// Generated File
//
// --------------------------------------------------------------------------------
export const ASSETS_GETTER_REQUEST = 'ASSETS_GETTER_REQUEST';
export const ASSETS_GETTER_SUCCESS = 'ASSETS_GETTER_SUCCESS';
export const ASSETS_GETTER_FAILURE = 'ASSETS_GETTER_FAILURE';

export const getAssets = (query = {}) => {
  return {
    type: ASSETS_GETTER_REQUEST,
    query,
  };
};
