import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Table, { SELECTABLE_ROWS_NONE } from '../shared/table/Table';
import {
  setChecklistTasksTableView,
  setChecklistTasksTableSize,
} from '../../store/settings/views/checklistTasksTableViewRedux';
import { deleteChecklistTasks, updateChecklistTasks } from '../../store/features/checklistTasksActions';
import { openDialog, closeDialog } from '../../store/dialogActions';
import StyledLink from '../shared/StyledLink';
import ConfirmDialog from '../shared/Dialog/ConfirmDialog';
import DeleteDialog from '../shared/Dialog/DeleteDialog';
import { setColumns, sortByKey } from '../shared/table/columns';
import { apiDateToString } from '../../utilities/strings';
import ChecklistTasksForm from './ChecklistTasksForm';
import { usePermissions, useTableViews } from '../../hooks/settingsHooks';
import { getCustomCSVData, tableViews } from '../../utilities/tables';
import { getUserProfile } from '../../store/getters/profileActions';

/** @typedef {import('../shared/table/types').TableOptions} TableOptions */
/** @typedef {import('../shared/table/types').Column} Column */

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
  },
  checkboxIcon: {
    marginRight: 5,
    cursor: 'pointer',
  },
  actionButtonRow: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const ChecklistTasksTable = props => {
  const { title, styleProjectDetail, projectId, checklist, dashboardMode, embedded } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, data } = useSelector(state => ({
    loading: state.checklistTasks.all.loading,
    data: state.checklistTasks.all.dataAll.results,
  }));
  const { id: currentUserId } = useSelector(state => state.profile.data);
  const { checklistTasksTableViewKey } = tableViews;
  const views = useTableViews(checklistTasksTableViewKey);
  const markTask = (taskId, done) => {
    dispatch(updateChecklistTasks(taskId, { done }));
  };
  useEffect(() => {
    dispatch(getUserProfile());
  }, []); // eslint-disable-line
  const { hasCrewEdit, hasTaskManage } = usePermissions();

  const confirmIncompleteTask = (taskId, done) => {
    const notes = 'Are you sure you want to mark this task as incomplete?';
    dispatch(openDialog(<ConfirmDialog notes={notes} confirmAction={() => markTask(taskId, done)} />));
  };

  const closeTaskDialog = () => {
    dispatch(closeDialog());
  };
  const displayNewTask = () => {
    dispatch(
      openDialog(
        <ChecklistTasksForm
          projectId={projectId}
          handleBack={closeTaskDialog}
          handleNext={closeTaskDialog}
          checklist={checklist}
        />
      )
    );
  };
  const displayEditTask = taskId => {
    dispatch(
      openDialog(
        'Edit Task',
        <ChecklistTasksForm
          update
          taskId={taskId}
          projectId={projectId}
          checklist={checklist}
          handleBack={closeTaskDialog}
          handleNext={closeTaskDialog}
        />
      )
    );
  };

  const hideInDashboard = dashboardMode => {
    if (dashboardMode) {
      return 'excluded';
    }
    return 'true';
  };
  const taskSource = (checklist, project) => {
    if (checklist) {
      return { link: `/forms/${checklist.id}`, name: `${checklist.template.name} (${checklist.id})` };
    }
    if (project) {
      return { link: `/projects/${project.id}`, name: project.name };
    }
    return {};
  };

  // check if task can be deleted if created by current user or has task manage permission
  const hasDelete = rowId => {
    if (hasTaskManage) {
      return true;
    }
    if (Array.isArray(data) && data.length > 0) {
      const createdById = data.find(item => item?.id === rowId)?.created_by?.id;
      return createdById === currentUserId;
    }

    return false;
  };

  /** @type {Column[]} */
  const columns = [
    {
      // Do not change the 1st 4 columns, the are used in reference to build other
      // columns.
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: 'checklist',
      label: 'Checklist',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: 'project',
      label: 'Project',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: 'task',
      label: 'Task',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: 'done',
      label: 'Task',
      options: {
        filter: true,
        filterOptions: {
          renderValue: v => (v ? 'Closed' : 'Open'),
        },
        customFilterListOptions: {
          render: v => (v ? 'Closed' : 'Open'), // filter chips
        },
        sort: true,
        sortCompare: order => {
          return (obj1, obj2) => {
            return sortByKey(order, obj1, obj2, 'task'); // use task name to sort
          };
        },
        customBodyRender: (value, tableMeta) => {
          const taskId = tableMeta.rowData[columns.findIndexByName['id']];
          const taskText = tableMeta.rowData[columns.findIndexByName['task']]; // text of the task
          return (
            <Typography className={classes.wrapper}>
              {value ? (
                <CheckBoxIcon
                  className={classes.checkboxIcon}
                  fontSize="small"
                  onClick={() => {
                    confirmIncompleteTask(taskId, !value);
                  }}
                />
              ) : (
                <CheckBoxOutlineBlankIcon
                  className={classes.checkboxIcon}
                  fontSize="small"
                  onClick={() => {
                    markTask(taskId, !value);
                  }}
                />
              )}
              {taskText}
            </Typography>
          );
        },
      },
    },
    {
      name: 'source',
      label: 'Source',
      options: {
        filter: true,
        filterType: 'multiselect',
        sort: true,
        sortCompare: order => {
          return (obj1, obj2) => {
            return sortByKey(order, obj1, obj2, 'name');
          };
        },
        customBodyRender: (value, tableMeta) => {
          const checklist = tableMeta.rowData[columns.findIndexByName['checklist']];
          const project = tableMeta.rowData[columns.findIndexByName['project']];
          const { name, link } = taskSource(checklist, project);

          return <StyledLink to={link} value={name} />;
        },
      },
    },
    {
      name: 'assigned_to',
      label: 'Assigned To',
      options: {
        filter: true,
        filterType: 'multiselect',
        sort: true,
        sortCompare: order => {
          return (obj1, obj2) => {
            return sortByKey(order, obj1, obj2, 'name');
          };
        },
        customBodyRender: value => value?.name,
      },
    },
    {
      name: 'assigned_crew',
      label: 'Assigned Crew',
      options: {
        filter: true,
        filterType: 'multiselect',
        sort: true,
        sortCompare: order => {
          return (obj1, obj2) => {
            return sortByKey(order, obj1, obj2, 'name');
          };
        },
        downloadBody: value => getCustomCSVData('simple', value, 'name'),
        customBodyRender: value => {
          if (!value) return <></>;
          return !hasCrewEdit ? <>{value.name}</> : <StyledLink to={`/crews/${value.id}`} value={value.name} />;
        },
      },
    },
    {
      name: 'created_on',
      label: 'Created',
      options: {
        filter: true,
        filterType: 'multiselect', // should use dateRange hook
        sort: true,
        customBodyRender: value => apiDateToString(value, 'date'),
      },
    },
    {
      name: 'created_by',
      label: 'Created By',
      options: {
        filter: true,
        filterType: 'multiselect',
        sort: true,
        sortCompare: order => {
          return (obj1, obj2) => {
            return sortByKey(order, obj1, obj2, 'name');
          };
        },
        customBodyRender: value => value?.name,
      },
    },
    {
      name: 'completed_by',
      label: 'Completed By',
      options: {
        filter: true,
        filterType: 'multiselect',
        sort: true,
        sortCompare: order => {
          return (obj1, obj2) => {
            return sortByKey(order, obj1, obj2, 'name');
          };
        },
        customBodyRender: value => value?.name,
      },
    },
    {
      name: 'completed_on',
      label: 'Completed On',
      options: {
        filter: true,
        filterType: 'multiselect', // should use dateRange hook
        sort: true,
        customBodyRender: value => apiDateToString(value, 'date'),
      },
    },
    {
      name: 'Actions',
      options: {
        display: hideInDashboard(dashboardMode),
        filter: false,
        sort: false,
        empty: true,
        download: false,
        print: false,
        viewColumns: false,
        customBodyRender: (_, info) => {
          // 0 is the first element in column
          const id = info.rowData[columns.findIndexByName['id']];
          const name = info.rowData[columns.findIndexByName['name']];
          const hasDeleteCapability = hasDelete(id);
          return (
            <div className={classes.actionButtonRow}>
              <EditIcon fontSize="small" onClick={() => displayEditTask(id)} />
              <IconButton
                size="small"
                disabled={!hasDeleteCapability}
                onClick={() => {
                  dispatch(
                    openDialog('Delete Task?', <DeleteDialog id={id} deleteAction={deleteChecklistTasks} name={name} />)
                  );
                }}
                aria-label="delete task">
                <DeleteIcon />
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

  // handle columns display
  setColumns(columns, views);

  /** @type {TableOptions} */
  const options = {
    enableNestedDataAccess: '.',
    searchOpen: !embedded,
    search: true,
    filter: true,
    print: false,
    download: false,
    viewColumns: true,
    selectableRows: SELECTABLE_ROWS_NONE,
    // if the column view changes, update redux with either 'add' or 'remove'
    onViewColumnsChange: (changedColumn, action) => {
      dispatch(setChecklistTasksTableView(changedColumn, action));
    },
    onChangeRowsPerPage: numberOfRows => {
      dispatch(setChecklistTasksTableSize(numberOfRows));
    },
    rowsPerPage: views.pageSize,
  };

  return (
    <div className={styleProjectDetail}>
      <Table
        title={title}
        simpleSearch
        columns={columns}
        data={data}
        options={options}
        loading={loading}
        addAction={dashboardMode ? null : displayNewTask}
        embedded={embedded}
      />
    </div>
  );
};

ChecklistTasksTable.defaultProps = {
  embedded: false,
  styleProjectDetail: '',
  isReadOnly: true,
  projectId: null,
  checklist: {},
  dashboardMode: false,
};

ChecklistTasksTable.propTypes = {
  title: PropTypes.string.isRequired,
  embedded: PropTypes.bool,
  styleProjectDetail: PropTypes.string,
  isReadOnly: PropTypes.bool,
  projectId: PropTypes.number,
  checklist: PropTypes.object,
  dashboardMode: PropTypes.bool,
};

export default ChecklistTasksTable;
