/**
 * fieldOrder
 *
 * An object where the keys define the sections of the form.   Each section requires the key display (string) and
 * fields (array of strings). Fields are the api keys (in snake case) for each field that will be displayed in the form.
 * Any fields that are never shown should not be added to this array.
 *
 * @return {object}
 */
export const fieldOrder = {
  info: {
    display: 'Info',
    fields: [
      'name',
      'description',
      'owner',
      'parent',
      'asset_path',
      'type',
      'manufacturer',
      'model',
      'serial_number',
      'location',
      'geo_point',
      'manufactured_on',
      'installed_on',
      'age',
      'labels',
    ],
  },
  status: {
    display: 'Status',
    fields: ['is_active', 'deactivated_on', 'asset_condition', 'asset_condition_set_by'],
  },
  externalSources: {
    display: 'External Sources',
    fields: ['app_key', 'external_id'],
  },
};

/**
 *  removeField
 *
 *  removes a field depending on if a user is creating, updating or viewing.
 *
 *  @param {string} state - enum for creating, updating or viewing ['CREATE', 'UPDATE', 'VIEW']
 *  @param {string} item - api key (in snake case) to check whether it should be hidden.
 *  @return {boolean} - returns true if the item will not be shown
 */
export const removeField = state => item => {
  let fields = [];
  switch (state) {
    case 'CREATE':
      fields = [
        'asset_condition',
        'asset_condition_set_by',
        'age',
        'created_on',
        'updated_on',
        'asset_path',
        'is_active',
        'deactivated_on',
        'app_key',
      ];
      break;
    case 'UPDATE':
      fields = [
        'asset_condition',
        'asset_condition_set_by',
        'age',
        'created_on',
        'updated_on',
        'asset_path',
        'name',
        'owner',
        'parent',
        'deactivated_on',
        'app_key',
        'type',
      ];
      break;
    case 'RENAME':
      fields = [
        'description',
        'manufacturer',
        'model',
        'asset_condition',
        'asset_condition_set_by',
        'age',
        'created_on',
        'updated_on',
        'asset_path',
        'type',
        'serial_number',
        'location',
        'geo_point',
        'manufactured_on',
        'installed_on',
        'external_id',
        'labels',
        'deactivated_on',
        'app_key',
      ];
      break;
    case 'VIEW':
      fields = ['created_on', 'updated_on', 'name'];
      break;
    default:
      return false;
  }
  return fields.includes(item);
};

/**
 * hideField
 *
 * This function is for hiding a field depending on the value of another field.
 *
 * @param {object} values - the values of all the fields in the form.
 * @param {string} item - api key (in snake case) to check whether it should be removed.
 * @return {boolean} - returns true if the item will not be shown
 */
export const hideField = (values, item, hasAssetCondition) => {
  switch (item) {
    case 'asset_condition':
    case 'asset_condition_set_by':
      return !hasAssetCondition;
    default:
      return false;
  }
};
