import React from 'react';
import PropTypes from 'prop-types';
import StopIcon from '@mui/icons-material/Stop';
import variables from '../../config.module.scss';

export const findingStates = ['PENDING', 'OPEN', 'MONITOR', 'REPAIR', 'REPAIRED', 'CLOSED'];

const stateOptions = {
  PENDING: {
    label: 'PENDING',
    color: variables.textSecondaryLight,
  },
  OPEN: {
    label: 'OPEN',
    color: variables.lightBlue,
  },
  MONITOR: {
    label: 'MONITOR',
    color: variables.lightYellow,
  },
  REPAIR: {
    label: 'REPAIR',
    color: variables.orange,
  },
  REPAIRED: {
    label: 'REPAIRED',
    color: variables.green,
  },
  CLOSED: {
    label: 'CLOSED',
    color: variables.green,
  },
};

export const getFindingState = findingState => {
  const option = stateOptions[findingState];
  if (!option) {
    return {
      color: variables.black,
      label: 'UNKNOWN LABEL',
    };
  }
  return option;
};

export const FindingStateDisplay = props => {
  const display = getFindingState(props.state);

  return (
    <span>
      <StopIcon style={{ color: display.color, verticalAlign: 'middle' }} /> {display.label}
    </span>
  );
};

FindingStateDisplay.defaultProps = {
  state: '',
};

FindingStateDisplay.propTypes = {
  state: PropTypes.string,
};

export default FindingStateDisplay;
