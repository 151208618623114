import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Container } from '@mui/material';
import Header from '../../components/shared/Header';
import Banner from '../../components/shared/displays/Banner';
import './SimpleLayout.scss';
import RefreshHandler from '../../components/shared/RefreshHandler';

/**
 * Layout with only a header
 * Will request features from the server when loaded and won't display the body and nav until done loading (we may want to change that).
 */

const SimpleLayout = props => {
  const location = useLocation();
  const { pageTitle, children, customBack, customHeader: CustomHeader } = props;
  const { alert_status: alertStatus, alert_message: alertMessage } = useSelector(
    state => state.settings.features.workspace
  );
  const bannerCss = {
    position: 'relative',
    top: '65px',
  };

  const backLink = customBack && location.state ? location.state.back : undefined;

  const renderTitle = () => {
    if (pageTitle) {
      return (
        <Helmet>
          <title>{`${pageTitle} - HUVRdata`}</title>
        </Helmet>
      );
    }
    return <></>;
  };

  const renderHeader = () => {
    if (CustomHeader) {
      return <CustomHeader />;
    }
    return <Header back={backLink} />;
  };

  return (
    <Container maxWidth={false} disableGutters>
      {renderTitle()}
      <div className="container">
        <div className="header">
          {renderHeader()}
          <Banner alertStatus={alertStatus} alertMessage={alertMessage} bannerCss={bannerCss} />{' '}
        </div>
        <div className="body">{children}</div>
      </div>
      <RefreshHandler />
    </Container>
  );
};

SimpleLayout.defaultProps = {
  pageTitle: '',
  customBack: undefined,
  customHeader: undefined,
};

SimpleLayout.propTypes = {
  /** custom page title */
  pageTitle: PropTypes.string,
  /** for rendering the children of this component */
  children: PropTypes.node.isRequired,
  /** for specified routing with back button */
  customBack: PropTypes.string,
  /** for specified routing with back button */
  customHeader: PropTypes.func,
};

export default SimpleLayout;
